import React, { useEffect, useState } from "react";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from '../../API/FetchApi';
import { Link, useNavigate } from "react-router-dom";
import { CommonAPi } from '../../API/CommonApi';

export default function SuggestedJob() {
  const [data, setData] = useState([]);
  const Navigate = useNavigate();
  useEffect(() => {
    getrecommendationsUser();
  }, []);
  const getrecommendationsUser = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.recommendationsUser);
      if (resp && resp.success === true) {
        setData(resp?.data?.jobRecommendations);
      }
    }
    catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error('Session Expired! Please login again.');
          localStorage.clear();
          Navigate('/signin');
        } else {
          ToastMessage.Error(e.response.data.message);
        }

      }
    }
  };

 
  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "year ago";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon ago";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + " day ago";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + " h ago";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min ago";
    return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec ago" : "0 sec ago";
};

  return (
    <>
      {data?.length > 0 ?
        <div className="sidebar-widget mt-4">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="medium">Suggested Job</h4>
            <Link to={"/schools"} className="text-brown fs-12 medium">
              See all{" "}
              <img src="../assets/img/icon/icon-right-arrow-brown.svg" class="ps-1 text-brown" alt="" />
            </Link>
          </div>

          <div className="pt-3 mt-2 border-top">
            <ul>

              {data.map((item, index) => {
                if (index > 3) return null;
                return (
                  <li className="d-flex align-items-center pb-3">
                    <div className="position-relative">
                    <Link to={"/jobs/" + item?.id}>
                    {item?.page?.pageImage === null ?
                        <img src="../assets/img/comany-dp.png" alt="" width="58" height="48" className="rounded-circle" />
                        :
                        <img src={Endpoints.baseUrl + item?.page?.pageImage} alt="" width="58" height="48" className="rounded-circle" />
                    }
                    </Link>
                    </div>
                    <div className="ps-2 w-100 align-items-center justify-content-between ">

                      <Link to={"/jobs/" + item?.id}>
                        <h5 className="semibold">{item?.title}</h5>
                      </Link>

                      <h5 className="medium text-brown fs-14 pt-1">{item?.page?.title}</h5>
                      <div className="d-flex py-2 align-items-center">
                        <span className="fs-10 text-darkgray pe-2 d-flex align-items-center">
                          <img src="../assets/img/icon/icon-location.svg" alt="" className="align-middle me-1" />
                          <span>{item?.state?.state}</span>
                        </span>

                        <span className="fs-10 text-darkgray d-flex align-items-center">
                          <img src="../assets/img/icon/icon-date-time.svg" alt="" className="align-middle me-1" />
                          <span>{timeSince(new Date(item.createdAt))}</span>
                        </span>
                      </div>

                      <div className="d-flex request-btn gap-2 mb-2">
                        <div className="job-btn light-brown-btn px-2 medium">{item?.jobType}</div>
                        <div className="job-btn light-brown-btn px-2 medium">{item?.workMode}</div>
                      </div>




                    </div>
                  </li>
                )
              })}


            </ul>
          </div>
        </div>
        : ""}
    </>
  )
}
