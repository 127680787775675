import React, { useEffect, useState } from "react";
import { FetchApi } from "../../../API/FetchApi";
import { PatchApi } from "../../../API/PatchApi";
import { Endpoints } from "../../../API/Endpoints";
import ToastMessage from "../../../Utils/ToastMessage";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Profile from "./Profile";
import Sidebar from "./Sidebar";
import { Helmet } from "react-helmet";
export default function Block() {
  const [blockData, setBlockData] = useState([]);
  const [count, setCount] = useState(0);
  const param = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getBlocked();
  }, []);

  const getBlocked = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetBlock + "?groupId=" + param?.id);
      if (resp && resp.success === true) {
        setBlockData(resp?.data?.requests);
        setCount(resp?.data?.requestCount);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleUnblock = async (id) => {
    let data = {
      action: "approve",
      groupId: param?.id,
      userId: id,
    };
    try {
      let resp = await PatchApi(Endpoints.GroupBlockAction, data);
      if (resp && resp.success === true) {
        getBlocked();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + " year ago";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon ago";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + " days ago";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + " h ago";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min ago";
    return Math.floor(seconds) >= 0
      ? Math.floor(seconds) + " sec ago"
      : "0 sec ago";
  };
  return (
    <Container header>
      <Helmet>
        <title>Block Group Member</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div class="container py-lg-4 py-3">
            <Profile />
            <div class="row">
              <Sidebar />

              <div class="col-lg-9 col-md-8">
                <div class="bg-white box-shadow rounded-15 p-md-3 p-2">
                  <div class="pb-2 d-md-flex align-items-center justify-content-between">
                    <h3 class="medium fs-20">
                      Blocked <span class="text-gray">- {count}</span>
                    </h3>
                  </div>

                  <div class="row m-0 pt-3 gx-md-50">
                    {blockData.map((item, index) => {
                      return (
                        <div class="col-md-6  ps-0" key={index}>
                          <div class="pb-3 d-flex align-items-start w-100">
                            <Link
                              to={"/timeline/" + item?.group_members?.username}
                            >
                              {item?.user_profile?.profileImg === null ? (
                                <img
                                  src="../assets/img/profile6.png"
                                  alt=""
                                  width="48"
                                  height="48"
                                  class="rounded-circle"
                                />
                              ) : (
                                <img
                                  src={
                                    Endpoints.baseUrl +
                                    item?.user_profile?.profileImg
                                  }
                                  alt=""
                                  width="48"
                                  height="48"
                                  class="rounded-circle"
                                />
                              )}
                            </Link>
                            <div class="ps-2">
                              <Link
                                to={
                                  "/timeline/" + item?.group_members?.username
                                }
                              >
                                <h6 class="medium text-black">
                                  {item?.fullName}
                                </h6>
                              </Link>

                              <h6 class="fs-12 py-1 regular text-darkgray">
                                {item?.user_profile?.profileTagline}
                              </h6>
                              <h6 class="fs-10 regular text-darkgray">
                                {item.mutualFriends} mutual friends
                              </h6>
                            </div>

                            <div class="ps-2 text-end">
                              <div class="d-flex align-items-center justify-content-between w-100 pb-3">
                                <h6 class="fs-10 regular text-darkgray pe-2 text-nowrap">
                                  {timeSince(
                                    new Date(item?.group_members?.createdAt)
                                  )}
                                </h6>
                                {/* <div class="position-relative">
                                                                    <a href="#" data-bs-toggle="dropdown" class="d-flex align-items-center justify-content-center " id="request_1">
                                                                        <svg viewBox="0 0 24 24" width="18" height="18" stroke="#000" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                                                                    </a>

                                                                    <ul class="dropdown-menu text-start  dropdown-menu-end shadow rounded-3" role="menu" aria-hidden="true" tabindex="-1" aria-labelledby="request_1">
                                                                        <li><a class="dropdown-item fs-12" href="#">
                                                                            <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" class="css-i6dzq1"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                                                            Delete
                                                                        </a></li>
                                                                        <li><a class="dropdown-item fs-12" href="#">
                                                                            <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line></svg>
                                                                            Restricted
                                                                        </a></li>

                                                                    </ul>
                                                                </div> */}
                              </div>

                              <Link
                                onClick={() => {
                                  handleUnblock(item?.group_members?.userId);
                                }}
                                class="btn butn-danger py-1"
                              >
                                Unblock
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
