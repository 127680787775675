import React, { useEffect, useState, useRef } from "react";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import ProfileNavigation from "../../Components/ProfileNavigation";
import Container from "../../Components/Container";
import Profile from "../ProfileAbout/Profile";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from "../../API/FetchApi";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import CreateAlbumItem from "./CreateAlbumItem";
import AlbumItem from "./AlbumItem";
import PostImageModal from "../../Components/PostImageModal/PostImageModal";

export default function PhotoAlbum() {
  const [image, setImage] = useState([]);
  const [count, setCount] = useState(0);
  const [Profilecount, setProfileCount] = useState(0);
  const [Albumcount, setAlbumCount] = useState(0);
  const [post, setPost] = useState("");
  const [loadStatus, setLoadStatus] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [limit, setLimit] = useState(15);
  const Navigate = useNavigate();
  const location = useLocation();
  const param = useParams();
  const userId = localStorage.getItem("userId");
  const username = localStorage.getItem("userName");

  useEffect(() => {
    getUserImages();
  }, [location.pathname, param?.id]);

  const getUserImages = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.GetAlbum + "?size=" + limit + "&id=" + param?.id
      );
      if (resp && resp.success === true) {
        setImage(resp?.data?.data);
        setCount(resp?.data?.count);
        setAlbumCount(resp?.data?.albumCount);
        setProfileCount(resp?.data?.profilePictureCount);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleLoadMore = async () => {
    setLoadStatus(true);
    let newLimit = limit + 12;
    setLimit(newLimit);

    try {
      let resp = await FetchApi(
        Endpoints.GetAlbum + "?size=" + newLimit + "&id=" + param?.id
      );
      if (resp && resp.success === true) {
        setLoadStatus(false);
        setImage(resp?.data?.data);
        setCount(resp?.data?.count);
        setAlbumCount(resp?.data?.albumCount);
        setProfileCount(resp?.data?.profilePictureCount);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoadStatus(false);
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSearch = async (value) => {
    try {
      let resp = await FetchApi(
        Endpoints.GetAlbum +
          "?size=" +
          limit +
          "&id=" +
          param?.id +
          "&term=" +
          value
      );
      if (resp && resp.success === true) {
        setImage(resp?.data?.data);
        setCount(resp?.data?.count);
        setAlbumCount(resp?.data?.albumCount);
        setProfileCount(resp?.data?.profilePictureCount);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleSorting = async (value) => {
    try {
      let resp = await FetchApi(
        Endpoints.GetAlbum +
          "?size=" +
          limit +
          "&id=" +
          param?.id +
          "&sort=" +
          value
      );
      if (resp && resp.success === true) {
        setImage(resp?.data?.data);
        setCount(resp?.data?.count);
        setAlbumCount(resp?.data?.albumCount);
        setProfileCount(resp?.data?.profilePictureCount);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Container header>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-lg-3 py-3">
            <div className="profile-banner-top mb-3  rounded-15 overflow-hidden">
              <Profile />

              <ProfileNavigation />
            </div>
            <div class="bg-white main-common">
              <div class="main-top-row d-md-flex justify-content-between align-items-center pb-3">
                <h3 class="medium">Photos</h3>
                {/* {username === param?.id ? */}
                <div class="d-sm-flex gap-3 pb-3">
                  <form
                    action=""
                    class="mainmenu-page-search d-flex flex-row-reverse align-items-center"
                  >
                    <input
                      type="text"
                      placeholder="Search for photos"
                      name="search"
                      class="bg-transparent fs-14 medium  text-darkgray"
                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                    />
                    <button type="submit" class="bg-transparent border-0">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="#949494"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        class="css-i6dzq1"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </button>
                  </form>
                  <select
                    name="sortby"
                    id="sortby"
                    class="text-darkgray"
                    onChange={(e) => {
                      handleSorting(e.target.value);
                    }}
                  >
                    <option value="">Sort by</option>
                    <option value="popularity">Popular </option>
                    <option value="recent">recent</option>
                  </select>
                </div>
                {/* : ""} */}
              </div>
              {/* {username == param?.id ? */}
              <div class="mainmenu-filter-row pb-3">
                <ul class="filter d-flex flex-nowrap align-items-center gap-2 fs-14 medium">
                  <li
                    class={
                      location.pathname.split("/")[1] === "profile-photos"
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      Navigate("/profile-photos/" + param?.id);
                    }}
                  >
                    All Photos <span class="fs-10 text-brown">{count}</span>
                  </li>
                  <li
                    class={activeTab === "profilePhoto" ? "active" : ""}
                    onClick={() => {
                      Navigate("/user-profile-photos/" + param?.id);
                    }}
                  >
                    Profile Photos{" "}
                    <span class="fs-10 text-brown">{Profilecount}</span>
                  </li>
                  <li
                    class={
                      location.pathname.split("/")[1] === "profile-albums"
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      Navigate("/profile-albums/" + param?.id);
                    }}
                  >
                    Albums <span class="fs-10 text-brown">{Albumcount}</span>
                  </li>
                </ul>
              </div>
              {/* : ""} */}

              <div class="row mainmenu-filter-conatiner">
                {username === param?.id ? <CreateAlbumItem /> : ""}

                {image.map((item, index) => {
                  return (
                    <AlbumItem
                      {...{
                        albumData: item,
                        index: index,
                        post: item,
                        onSuccess: getUserImages,
                      }}
                    />
                  );
                })}
              </div>

              {image.length >= 8 && limit <= Albumcount ? (
                <div className="text-center">
                  <Link
                    href="#"
                    className="text-blue"
                    onClick={() => handleLoadMore()}
                  >
                    {loadStatus ? (
                      <img src="../assets/img/icon/icon-loading.svg" alt="" />
                    ) : (
                      "Load More photos"
                    )}
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
