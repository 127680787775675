import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import ToastMessage from "../../Utils/ToastMessage";
import PostReactions from "../../Components/PostReactions/PostReactions";
import Post from "../../Components/Post/Post";
import PostShare from "../../Components/PostShare/PostShare";
import BlogCard from "../../Components/BlogCard/BlogCard";
import PostLoader from "../../Components/PostLoader/PostLoader";
import SharedPost from "../../Components/SharedPost/SharedPost";
import SharedMedia from "../../Components/SharedMedia/SharedMedia";
import SharedBlogCard from "../../Components/SharedBlogCard/SharedBlogCard";
import LinkPost from "../../Components/LinkPost/LinkPost";
import SuggestedFriends from "../SuggestedFriends/Index";
import SuggestedTeachers from "../SuggestedTeachers";
import SuggestedCourses from "../SuggestedCourses";
import PostStatistics from "../MainFeed/PostStatistics";
import SuggestedGroup from "../SuggestedGroup/Index";
import SuggestedCompany from "../SuggestedCompany/Index";
import SuggestedPage from "../SuggestedPage/Index";
import { Helmet } from "react-helmet";
const $ = window.jQuery;


function SingleHashtag() {
  const history = useNavigate();
  const [mouserHovering, setMouserHovering] = useState(false);
  const ProfileCompletion = localStorage.getItem("ProfileCompletion");
  const userId = localStorage.getItem("userId");
  const user = JSON.parse(localStorage.getItem("user"));
  const userProfile = localStorage.getItem("userProfile");
  const userProfileCoverImage = localStorage.getItem("userProfileCoverImage");
  const [timelinePosts, setTimelinePosts] = useState([]);
  const [postPage, setPostPage] = useState(1);
  const listInnerRef = useRef();
  const [loading, setLoading] = useState(false);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [hashtag, setHashtag] = useState('');
  const containerRef = useRef(null);

  const hashtagId = new URLSearchParams(useLocation().search).get("tag");

  const handleScroll = (e) => {
    if (loading) return;
    const scrollTop = listInnerRef.current.scrollTop;
    const offsetHeight = listInnerRef.current.offsetHeight;
    const scrollHeight = listInnerRef.current.scrollHeight;
    const remaining = scrollHeight - offsetHeight - scrollTop;

    if (remaining < 100) {
      setLoading(true);
      fetchNext();
    }
  };

  const getUserPosts = async (createPage = null) => {
    if (!hasMorePosts) return;
    try {
      let resp = await CommonAPi(Endpoints.HashtagsFeed + `?tag=${hashtagId}` + `&page=${postPage}`);
      if (resp && resp.success === true) {
        if (resp.data.length === 0) {
          setLoading(false);
          setHasMorePosts(false);
        } else {
          if (createPage == null) {
            setTimelinePosts([...timelinePosts, ...resp.data?.allPosts]);
          } else {
            setTimelinePosts(resp.data?.allPosts);
          }
        }
        setHashtag({
          id: hashtagId,
          hashtag: resp.data?.totalPeopleUsing[0]?.tag,
          isFollow: resp.data?.isFollow,
          totalPeopleUsing: resp.data?.totalPeopleUsing[0]?.totalPeople,
        });
        console.log(timelinePosts);
        setLoading(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const fetchNext = () => {
    setTimeout(() => {
      setPostPage(postPage + 1);
    }, 50);
  };

  useEffect(() => {
    if (postPage < 1) return;
    getUserPosts();
  }, [postPage]);





  const showReactionsBox = (event) => {
    setMouserHovering(true);
  };
  const hideReactionsBox = (event) => {
    setTimeout(() => {
      setMouserHovering(false);
    }, 1400);
  };
  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "y";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + "d";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + "h";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min";
    return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec" : "0 sec";
  };
  function formatDateTime(postDate) {
    let date = new Date(postDate);
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    let formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }

  const handleProfileCompletion = () => {
    if (Math.round(ProfileCompletion) < 25) {
      history("/complete-profile-step1" + "/" + userId);
    } else if (Math.round(ProfileCompletion) < 50) {
      history("/complete-profile-step2" + "/" + userId);
    } else if (Math.round(ProfileCompletion) < 75) {
      history("/complete-profile-step3" + "/" + userId);
    } else if (Math.round(ProfileCompletion) < 100) {
      history("/complete-profile-step4" + "/" + userId);
    }
  };

  const handleFollow = async (id) => {
    let data = {
      hashtagId: id
    }
    try {
      let resp = await CommonAPi(Endpoints.HashtagsFollow, data);
      if (resp && resp.success === true) {
        getUserPosts();
        ToastMessage.Success(resp.message)
      }
    }
    catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {

          ToastMessage.Error('Session Expired! Please login again.')
          localStorage.clear();
          history('/signin');
        } else {
          ToastMessage.Error(e.response.data.message)
        }

      }
    }
  };
  const handleUnfollow = async (id) => {
    let data = {
      hashtagId: id
    }
    try {
      let resp = await CommonAPi(Endpoints.HashtagsUnfollow, data);
      if (resp && resp.success === true) {
        getUserPosts();
        ToastMessage.Success(resp.message)
      }
    }
    catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {

          ToastMessage.Error('Session Expired! Please login again.')
          localStorage.clear();
          history('/signin');
        } else {
          ToastMessage.Error(e.response.data.message)
        }

      }
    }
  };

  return (
    <Container
      header
      setLoading={setLoading}
      loading={loading}
      setPostPage={setPostPage}
      getUserPosts={getUserPosts}
      fromMainfeed={true}
      setTimelinePosts={setTimelinePosts}
      containerRef={containerRef}
    >
      <Helmet>
        <title>Singal Hashtags</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main" onScroll={handleScroll} ref={listInnerRef}>
          <LeftNavigation />
          {/* <?php include 'include/left-navigation.php'; ?> */}

          <div className="container py-3 home" ref={containerRef}>
            <div className="row">
              <div className="col-md-3 feed-left pb-3">
                <div className="sidebar-widget">
                  <div className="profile-widget">
                    <div className="profile-widget-head">
                      {userProfileCoverImage === null ||
                        userProfileCoverImage === "undefined" ||
                        userProfileCoverImage === "" ? (
                        <img
                          src="assets/img/feed-head.png"
                          alt=""
                          height="80"
                          width="100%"
                          style={{ objectFit: "cover" }}
                          className="rounded-2"
                        />
                      ) : (
                        <img
                          src={Endpoints.baseUrl + userProfileCoverImage}
                          alt=""
                          height="80"
                          width="100%"
                          style={{ objectFit: "cover" }}
                          className="rounded-2"
                        />
                      )}
                      <div className="d-flex align-items-end gap-1">
                        <div
                          className="profile-circular-bar position-relative rounded-circle"
                          style={{ "--value": Math.round(ProfileCompletion) }}
                        >
                          {userProfile === null ||
                            userProfile === "undefined" ||
                            userProfile === "" ? (
                            <img
                              src="assets/img/profile.png"
                              alt=""
                              height="54"
                              width="54"
                              className="rounded-circle"
                            />
                          ) : (
                            <img
                              src={Endpoints.baseUrl + userProfile}
                              alt=""
                              height="54"
                              width="54"
                              className="rounded-circle"
                            />
                          )}
                        </div>

                        <div className="ps-2">
                          <h6 className="medium pb-1">{user.fullName}</h6>
                          <h6 className="fs-12 text-gray">{user?.usersType}</h6>
                        </div>
                      </div>
                      {Math.round(ProfileCompletion) < 99 ? (
                        <div className="border-top pt-3 mt-3">
                          <div className="d-flex justify-content-between pb-1">
                            <h5 className="medium">
                              Profile Progress
                              {Math.round(ProfileCompletion) > 99 ? (
                                <div className="fs-10 d-block text-brown p-2">
                                  Completed
                                </div>
                              ) : (
                                <div
                                  className="fs-10 d-block text-brown p-2"
                                  onClick={handleProfileCompletion}
                                >
                                  Set up now
                                </div>
                              )}
                            </h5>
                            <span className="text-blue medium">
                              {Math.round(ProfileCompletion)}%
                            </span>
                          </div>

                          <div className="profile-progess">
                            <h6
                              className={
                                Math.round(ProfileCompletion) < 25
                                  ? "medium text-gray pb-3 incomplete"
                                  : "medium text-blue pb-3 complete"
                              }
                            >
                              <span className="d-inline-block bg-white">
                                {Math.round(ProfileCompletion) < 25 ? (
                                  <img
                                    src="assets/img/icon/icon-indeterminate.svg"
                                    alt=""
                                    className="me-2"
                                  />
                                ) : (
                                  <img
                                    src="assets/img/icon/icon-checkbox.svg"
                                    alt=""
                                    className="me-2"
                                  />
                                )}
                              </span>
                              Personal Information
                              <span className="fs-12 light ms-auto">25%</span>
                            </h6>

                            <h6
                              className={
                                Math.round(ProfileCompletion) < 50
                                  ? "medium text-gray pb-3 incomplete"
                                  : "medium text-blue pb-3 complete"
                              }
                            >
                              <span className="d-inline-block bg-white">
                                {Math.round(ProfileCompletion) < 50 ? (
                                  <img
                                    src="assets/img/icon/icon-indeterminate.svg"
                                    alt=""
                                    className="me-2"
                                  />
                                ) : (
                                  <img
                                    src="assets/img/icon/icon-checkbox.svg"
                                    alt=""
                                    className="me-2"
                                  />
                                )}
                              </span>
                              Image Upload{" "}
                              <span className="fs-12 light ms-auto">25%</span>
                            </h6>

                            <h6
                              className={
                                Math.round(ProfileCompletion) < 75
                                  ? "medium text-gray pb-3 incomplete"
                                  : "medium text-blue pb-3 complete mediator"
                              }
                            >
                              <span className="d-inline-block bg-white">
                                {Math.round(ProfileCompletion) < 75 ? (
                                  <img
                                    src="assets/img/icon/icon-indeterminate.svg"
                                    alt=""
                                    className="me-2"
                                  />
                                ) : (
                                  <img
                                    src="assets/img/icon/icon-checkbox.svg"
                                    alt=""
                                    className="me-2"
                                  />
                                )}
                              </span>
                              Verifications{" "}
                              <span className="fs-12 light ms-auto">25%</span>
                            </h6>

                            {/* <h6 className="medium text-gray pb-3 incomplete">
                                              <span className="d-inline-block bg-white"><img src="assets/img/icon/icon-indeterminate.svg" alt="" className="me-2"/></span>                                   
                                                  <span>Verifications
                                                      <span className="fs-10 d-block text-brown">Verify now</span>
                                                  </span>
                                                  <span className="fs-12 light ms-auto">20%</span>
                                              </h6> */}

                            {/* <h6 className="medium text-gray pb-3 incomplete">
                                              <span className="d-inline-block bg-white"><img src="assets/img/icon/icon-indeterminate.svg" alt="" className="me-2"/> </span>                                   
                                                  <span>Portfolio
                                                      <span className="fs-10 d-block text-brown">Update now</span>
                                                  </span>
                                                  <span className="fs-12 light ms-auto">20%</span>
                                              </h6> */}

                            <h6
                              className={
                                Math.round(ProfileCompletion) < 100
                                  ? "medium text-gray pb-3 incomplete"
                                  : "medium text-blue pb-3 incomplete"
                              }
                            >
                              <span className="d-inline-block bg-white">
                                {Math.round(ProfileCompletion) < 100 ? (
                                  <img
                                    src="assets/img/icon/icon-indeterminate.svg"
                                    alt=""
                                    className="me-2"
                                  />
                                ) : (
                                  <img
                                    src="assets/img/icon/icon-checkbox.svg"
                                    alt=""
                                    className="me-2"
                                  />
                                )}
                              </span>
                              <span>
                                Academics & Experience
                                {/* <span className="fs-10 d-block text-brown">set up now</span> */}
                              </span>
                              <span className="fs-12 light ms-auto">25%</span>
                            </h6>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>


              </div>

              {/* story */}

              <div className="col-md-6 feed-mid-column pb-3">
                <div class="hashtag-item d-flex align-items-center bg-white w-100">
                  <span class="hastag-icon">
                    <img src="../assets/img/icon/Hashtags.svg" alt="" />
                  </span>
                  <div class="ps-3 d-flex align-items-end justify-content-between gap-2 w-100">
                    <div>
                      <h4 class="semibold pb-1">#{hashtag?.hashtag}</h4>
                      <h6 class="fs-14 text-darkgray regular">{hashtag?.totalPeopleUsing} people used this hashtag</h6>
                    </div>
                    {hashtag?.isFollow === "false" ?
                      <Link onClick={() => {
                        handleFollow(hashtag?.id)
                      }} class="btn py-2 fs-14">Follow</Link>
                      :
                      <Link onClick={() => {
                        handleUnfollow(hashtag?.id)
                      }} class="btn py-2 fs-14">Unfollow</Link>
                    }
                  </div>
                </div>

                {/* status box */}
                <div className="mt-3">
                  <PostShare
                    setPostPage={setPostPage}
                    setTimelinePosts={setTimelinePosts}
                    postPage={postPage}
                    getUserPosts={getUserPosts}
                  />
                </div>

                <ul className="timeline-feed feed-list">
                  {timelinePosts.map((post, i) => {
                    if (i === 5) {
                      return <SuggestedFriends />;
                    }

                    if (i === 10) {
                      return <SuggestedTeachers />;
                    }

                    if (i === 15) {
                      return <SuggestedCourses />;
                    }
                    if (i === 20) {
                      return <SuggestedGroup />;
                    }
                    if (i === 25) {
                      return <SuggestedCompany />;
                    }
                    if (i === 30) {
                      return <SuggestedPage />;
                    }

                    if (
                      post.category === "Blog" &&
                      post.isShared === 0 &&
                      post.isMediaShared === 0
                    )
                      return (
                        <BlogCard
                          post={post}
                          key={post.id}
                          getUserPosts={getUserPosts}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          setTimelinePosts={setTimelinePosts}
                          setPostPage={setPostPage}
                          postPage={postPage}
                          listInnerRef={listInnerRef}
                          formatDateTime={formatDateTime}
                        />
                      );
                    else if (
                      post.isShared &&
                      post.isMediaShared === 0 &&
                      post.category !== "Blog"
                    )
                      return (
                        <SharedPost
                          post={post}
                          getUserPosts={getUserPosts}
                          key={post.id}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          setTimelinePosts={setTimelinePosts}
                          setPostPage={setPostPage}
                          postPage={postPage}
                          listInnerRef={listInnerRef}
                          formatDateTime={formatDateTime}
                        />
                      );
                    else if (
                      post.isShared &&
                      post.isMediaShared &&
                      post.category !== "Blog"
                    )
                      return (
                        <SharedMedia
                          post={post}
                          getUserPosts={getUserPosts}
                          key={post.id}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          setTimelinePosts={setTimelinePosts}
                          setPostPage={setPostPage}
                          postPage={postPage}
                          listInnerRef={listInnerRef}
                          formatDateTime={formatDateTime}
                        />
                      );
                    else if (
                      post.isShared === 0 &&
                      post.isMediaShared === 0 &&
                      post.category !== "Blog"
                    )
                      return (
                        <Post
                          post={post}
                          getUserPosts={getUserPosts}
                          key={post.id}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          setTimelinePosts={setTimelinePosts}
                          setPostPage={setPostPage}
                          postPage={postPage}
                          listInnerRef={listInnerRef}
                          formatDateTime={formatDateTime}
                        />
                      );
                    // else if (post.category === "Link")
                    //   return (
                    //     <LinkPost
                    //       post={post}
                    //       key={post.id}
                    //       getUserPosts={getUserPosts}
                    //       mouserHovering={mouserHovering}
                    //       showReactionsBox={showReactionsBox}
                    //       hideReactionsBox={hideReactionsBox}
                    //       timeSince={timeSince}
                    //       setTimelinePosts={setTimelinePosts}
                    //       setPostPage={setPostPage}
                    //       postPage={postPage}
                    //       formatDateTime={formatDateTime}
                    //       listInnerRef={listInnerRef}
                    //     />
                    //   );
                    else if (post.category === "Blog" && post.isShared !== 0)
                      return (
                        <SharedBlogCard
                          post={post}
                          key={post.id}
                          getUserPosts={getUserPosts}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          setTimelinePosts={setTimelinePosts}
                          setPostPage={setPostPage}
                          postPage={postPage}
                          formatDateTime={formatDateTime}
                          listInnerRef={listInnerRef}
                        />
                      );
                  })}
                  {loading === true && hasMorePosts === true && <PostLoader />}
                </ul>
              </div>
              <div className="col-md-3 feed-right">

                <PostStatistics />
              </div>
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
      {/* <script src="assets/js/footer.js"></script> */}


    </Container>
  )
}

export default SingleHashtag;