import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import StickyChat from "./StickyChat";
import { getSocket } from "../socket/socket";
import ToastMessage from "../Utils/ToastMessage";
import { CommonAPi } from "../API/CommonApi";
import { Endpoints } from "../API/Endpoints";

export const StickyChatContext = createContext();

export default function StickyChatWrapper({ children }) {
  const user = useMemo(() => JSON.parse(localStorage.getItem("user")), []);
  // const user =JSON.parse(localStorage.getItem("user"));

  const socket = useMemo(() => getSocket(), []);

  const activeChatIdInFullView = useRef();

  const [onlineUsers, setOnlineUsers] = useState([]);

  const [activeChats, setActiveChats] = useState([]);

  const [chats, setChats] = useState([]);

  const handleAddChat = useCallback((chat, showMessage = true) => {
    // when chat is in full view mode
    if (chat.id === activeChatIdInFullView.current) {
      if (showMessage)
        ToastMessage.Info(
          "This conversation is already open in full view mode !"
        );
      return;
    }
    setActiveChats((currentChats) => {
      const newChats = JSON.parse(JSON.stringify(currentChats));
      const incomingChatIndex = newChats.findIndex(
        (searchChat) => searchChat.id === chat.id
      );
      // if not exist, only then add
      if (incomingChatIndex === -1) {
        if (newChats.length === 3) {
          newChats.splice(0, 1);
        }
        newChats.push(chat);
      }
      // saving to local storage
      localStorage.setItem("activeChats", JSON.stringify(newChats));
      return newChats;
    });
  }, []);

  const checkOnlineStatus = useCallback(
    (chat) => {
      if (chat) {
        const currentMember = chat.chat_room_members.find(
          (each) => each?.user?.id !== user.id
        );
        const isOnline =
          !!currentMember &&
          onlineUsers.find((user) => user?.userId === currentMember?.user.id);
        return !!isOnline;
      }
    },
    [onlineUsers, user.id]
  );

  const handleClose = useCallback((index) => {
    setActiveChats((currentChats) => {
      const newChats = structuredClone(currentChats);
      newChats.splice(index, 1);
      // saving to local storage
      localStorage.setItem("activeChats", JSON.stringify(newChats));
      return newChats;
    });
  }, []);

  const setActiveChatIdInFullView = useCallback(
    (data) => {
      if (data) {
        // closing sticky chat, when fullview is open
        const index = activeChats.findIndex((chatItem) => chatItem.id === data);
        if (index !== -1) {
          handleClose(index);
        }
      }
      activeChatIdInFullView.current = data;
    },
    [activeChats, handleClose]
  );

  const getOnlineUsers = useCallback(async () => {
    try {
      const res = await CommonAPi(Endpoints.getOnlineFriends());
      setOnlineUsers(res.data.map((item) => ({ userId: item.id })));
    } catch (e) {
      console.log("gettting online users failed: " + e);
    }
  }, []);

  const getChats = useCallback(async () => {
    try {
      const res = await CommonAPi(Endpoints.getUserChatRooms + user.id);
      // sorting based on last message
      setChats(
        res.data.sort(
          (a, b) =>
            new Date(b?.lastMessage?.createdAt) -
            new Date(a?.lastMessage?.createdAt)
        )
      );
    } catch (e) {
      console.log(e);
    }
  }, [user.id]);

  // componentDidMount
  useEffect(() => {
    getChats();
    // getOnlineUsers();
    // loading local storage chats
    const savedChats = localStorage.getItem("activeChats");
    if (savedChats) setActiveChats(JSON.parse(savedChats));
  }, []);

  useEffect(() => {
    if (user.id) {
      socket.connect();
      socket.emit("identity", user.id);
    }

    function onConnect(evt) {
      socket.emit("identity", user.id);
    }

    function onDisconnect(evt) {}

    function onError(message) {}

    function onIdentities(data) {
      setOnlineUsers(data);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", onError);
    socket.on("reconnect_error", onError);
    socket.on("identities", onIdentities);

    socket.on("subscribed", (data) => {
      console.log("Subscribed ", data);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("connect_error");
      socket.off("reconnect_error");
      socket.off("identities");
      socket.off("subscribed");
    };
  }, []);

  // listening to messsages
  const onMessage = (data) => {
    const chatIndex = chats.findIndex(
      (searchElem) => searchElem.id === data.chatRoomId
    );
    const newChats = structuredClone(chats);
    newChats.splice(chatIndex, 1, {
      ...newChats[chatIndex],
      lastMessage: data,
    });
    newChats.sort(
      (a, b) =>
        new Date(b?.lastMessage?.createdAt) -
        new Date(a?.lastMessage?.createdAt)
    );
    setChats(newChats);
    if (chatIndex !== -1) handleAddChat(chats[chatIndex], false);
  };

  useEffect(() => {
    socket.on("messages", onMessage);
    return () => {
      socket.off("message");
    };
  }, [chats]);

  const memoizedContextValue = useMemo(
    () => ({
      handleAddChat,
      socket,
      onlineUsers,
      setActiveChatIdInFullView,
      chats,
      setChats,
    }),
    [handleAddChat, onlineUsers, socket, chats]
  );

  const memoizedActiveChats = useMemo(
    () =>
      activeChats.map((chat, index) => {
        const isOnline = chat.roomType !== "course" && checkOnlineStatus(chat);
        return (
          <StickyChat
            key={chat.id}
            chat={chat}
            isOnline={isOnline}
            index={index}
            onClose={handleClose}
          />
        );
      }),
    [activeChats, checkOnlineStatus, handleClose]
  );

  return (
    <StickyChatContext.Provider value={memoizedContextValue}>
      {children}
      <div
        style={{
          position: "fixed",
          bottom: 0,
          right: 80,
          display: "flex",
          flexWrap: "nowrap",
          gap: 15,
          zIndex: 1000,
        }}
      >
        {memoizedActiveChats}
      </div>
    </StickyChatContext.Provider>
  );
}
