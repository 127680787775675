import { createPortal } from "react-dom";

export default function CustomModal({
  modalHeader = "Modal",
  children,
  show,
  onClose,
}) {
  if (!show) return null;

  return createPortal(
    <div className="custom-modal-container">
      <div className="custom-modal-content-container">
        <div className="custom-modal-header">
          <p className="custom-modal-header-title">{modalHeader}</p>
          <button
            onClick={onClose}
            className="custom-modal-header-close-button"
          >
            <CloseIcon />
          </button>
        </div>
        <div className="custom-modal-content">{children}</div>
      </div>
    </div>,
    document.body
  );
}

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="#949494"
      width="22"
      height="22"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
};
