import React from 'react'
import "./PostReaction.css"
import { Tooltip as ReactTooltip } from "react-tooltip";

const ModalImageReaction = ({handleReact,postReactions, id, isOpen, isCommentReaction, setCommentReact,setReactionsCount,commentReact}) => { 
  return (
    <div className={`post-reactions-container ${isOpen ? "open"  : "close"}
       ${isCommentReaction ? "commentReaction" :  ""}`}>
      {
        postReactions.map((postReaction,idx)=>(
          <span key={postReaction.id} id={postReaction.id} 
            onClick={(e)=> {
               handleReact(e,id,postReaction.id); 
            if(commentReact === postReaction.id) {
              setCommentReact("React");
              setReactionsCount((prev)=>prev-1);
            }
            else if(commentReact === "React") {      
                setReactionsCount((prev)=>prev+1);
                setCommentReact(postReaction.id);
            }else if(commentReact !== postReaction.id){
                setCommentReact(postReaction.id);
            } 
            }}>
            <img src={postReaction.img} id={"Media-"+postReaction.id} alt="react-icon"/>
            <ReactTooltip
                anchorId={"Media-"+postReaction.id}
                place="top"
                content={postReaction.id}
            />    
          </span>
        ))
      }
    </div>
  )
}

export default ModalImageReaction