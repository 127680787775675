import React, { useEffect, useState } from 'react'
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { CommonAPi } from '../../API/CommonApi';
import { Endpoints } from "../../API/Endpoints";
import ProfileImage from '../ProfileUpdate/ProfileImage';
import Sidebar from '../ProfileUpdate/Sidebar';
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";
export default function ActivityLog() {
    const [ActivityFeed, setActivityFeed] = useState([]);
    const [Limit, setLimit] = useState(9);
    const [count, setCount] = useState(0);
    const [loadStatus, setLoadStatus] = useState(false);

    const userId = localStorage.getItem("userId");
    const history = useNavigate();
    useEffect(() => {
        getActivity();
    }, []);

    const getActivity = async () => {

        try {
            let resp = await CommonAPi(Endpoints.ActivityLog + '?size=' +Limit );
            if (resp && resp.success === true) {
                setActivityFeed(resp.data?.allActivity);
                setCount(resp.data?.totalRecord);
                

            }
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error("Session Expired! Please login again.");
                    localStorage.clear();
                    history("/signin");
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }
    };
    const handleLoadMore = async () => {
        const newLimit = Limit + 3;
        setLimit(newLimit);
        setLoadStatus(true);
        try {
            let resp = await CommonAPi(Endpoints.ActivityLog + '?size=' +Limit );
            if (resp && resp.success === true) {
                setActivityFeed(resp.data?.allActivity);
                setCount(resp.data?.totalRecord);
                setLoadStatus(false);

            }
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                setLoadStatus(false);
                if (e.response.status === 401) {
                    ToastMessage.Error("Session Expired! Please login again.");
                    localStorage.clear();
                    history("/signin");
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }
    };

    const timeSince = (date, isCurrent = false) => {
        if (isCurrent) return 0 + " sec";
        let seconds = Math.floor((new Date() - date) / 1000);
        let interval = seconds / 31536000;
    
        if (interval > 1) return Math.floor(interval) + "y";
        interval = seconds / 2592000;
    
        if (interval > 1) return Math.floor(interval) + " mon";
        interval = seconds / 86400;
    
        if (interval > 1) return Math.floor(interval) + "d";
        interval = seconds / 3600;
    
        if (interval > 1) return Math.floor(interval) + "h";
        interval = seconds / 60;
    
        if (interval > 1) return Math.floor(interval) + " min";
        return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec" : "0 sec";
      };
    return (
        <Container
            header
        >
        <Helmet>
          <title>Activity Log</title>
        </Helmet>
            <div className="wrapper wrapper-bg">
                <div className="main">
                    <LeftNavigation />

                    <div className="container py-lg-4 py-3">

                        <ProfileImage />

                        <div className="row">

                            <Sidebar />
                            <div className="col-md-9">
                                <div className="bg-white box-shadow rounded-15 p-md-3 p-2">
                                    <div className="pt-md-2 pb-3 mb-3 px-lg-3 border-bottom">
                                        <h3 className="medium">Activity Log</h3>
                                        <span>This allow you to check your activities on our platform.</span>
                                    </div>
                                    {ActivityFeed.map((item, index) => {
                                        return (
                                            <div className="d-flex align-items-start gap-2 pb-3" key={index}>
                                                  
                                                {item?.ProfileImage === null ?
                                                    <img
                                                        src="../assets/img/picture.png"
                                                        height="48"
                                                        width="48"
                                                        className="rounded-circle"
                                                        alt=""
                                                    />
                                                    :
                                                    <img
                                                        src={Endpoints.baseUrl + item?.ProfileImage}
                                                        height="48"
                                                        width="48"
                                                        className="rounded-circle"
                                                        alt=""
                                                    />

                                                }
                                                
                                                <div>
                                                    <h6 className="fs-14 text-darkgray regular">
                                                       
                                                    {userId ==  item?.userId ? "You" : item?.username} {item?.TYPE == "comment"? "commented on": item?.TYPE == "reaction" && item?.reactionType !="Like"? "reacted to" : "liked"} {" "}
                                                        <Link to={"/timeline/" + item?.post_owner_username}>
                                                        <span className="text-brown bold">
                                                            {item?.postOwner}'s
                                                        </span>
                                                        </Link>
                                                        {" "}
                                                        <Link to={"/post-detail/" + item?.postId}>
                                                            post
                                                        </Link>
                                                       
                                                    </h6>
                                                    <h6 className="text-mediumgray regular fs-10">
                                                        {timeSince(new Date(item?.createdAt))}
                                                    </h6>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {ActivityFeed.length >= 8 && Limit < count ?
                                    <div className="text-center">

                                    <Link href="#" className="text-blue" onClick={() => handleLoadMore()}>

                                        {loadStatus ?
                                        <img src="../assets/img/icon/icon-loading.svg" alt="" />
                                        : "Load More"}

                                    </Link>

                                    </div>
                                    : ""}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <OnlineFriends />
            </div>
        </Container>
    )
}
