import React, { useRef } from "react";

const PostDiscardPopup = ({
  type,
  title,
  showDiscardPopup,
  setShowDiscardPopup,
  setShowModal,
  setPostMedia,
  setPostTitle,
  setPreviewData,
  setLinkError,
  setError,
  postMedia,
  postTitle,
  compressTimeout,
  onSuccess,
  setEditPostModal,
}) => {
  const closeModalRef = useRef();

  return (
    <div>
      <div className="d-none">
        <a
          href="#"
          className="btn btn-br-blue fs-14 bg-transparent"
          data-bs-toggle="modal"
          data-bs-target="#kidmodal"
        >
          discard post
          <img src="assets/img/icon/profile.svg" alt="" />
        </a>
      </div>
      {showDiscardPopup && (
        <div
          className={`modal-backdrop fade ${showDiscardPopup ? "show" : ""}`}
        ></div>
      )}
      <div
        className={`modal fade ${showDiscardPopup ? "show" : ""}`}
        id={`discardPost-${type}`}
        tabindex="-1"
        aria-labelledby={`discardPost-${type}`}
        aria-hidden="true"
      >
        <div className="modal-dialog rounded-10 box-shadow p-lg-3 p-md-3 p-2 bg-white">
          <div className="modal-content border-0">
            <div className="d-flex align-items-center justify-content-between border-bottom w-100">
              <h3 className="medium text-dark" style={{ fontSize: "1rem" }}>
                Discard {type === "Status" ? "Post" : "Project"}
              </h3>
              <button
                type="button"
                className="btn-close kids-close-icon cursor-pointer"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeModalRef}
                onClick={() => {
                  setShowDiscardPopup(false);
                  setShowModal(true);
                  clearTimeout(compressTimeout);
                }}
              >
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#949494"
                  width="22"
                  height="22"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-body p-0">
              <h4
                className="medium text-danger pb-3"
                style={{ fontSize: "1rem" }}
              >
                {title} {type === "Status" ? "Post" : "Project"}?
              </h4>

              <div className="col-md-12 text-end">
                <button
                  type="button"
                  className="px-3 py-2 rounded rounded-pill fs-12 btn-danger"
                  onClick={() => {
                    setShowModal(false);
                    setShowDiscardPopup(false);
                    setPostMedia([]);
                    setPostTitle("");
                    setPreviewData(null);
                    setLinkError("");
                    setError("");
                    onSuccess?.(true);
                    setEditPostModal?.(false);
                  }}
                >
                  Yes Discard!
                </button>
                <button
                  onClick={() => {
                    setShowDiscardPopup(false);
                    setShowModal(true);
                  }}
                  style={{ marginLeft: "4px" }}
                  className="px-3 py-2 rounded rounded-pill fs-12 btn"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostDiscardPopup;
