import React, { useEffect, useRef, useState } from "react";
import { CommonAPi } from "../../API/CommonApi";
import { DeleteApi } from "../../API/DeleteApi";
import { Endpoints } from "../../API/Endpoints";
import PostReactions from "../PostReactions/PostReactions";
import CommentReplyBox from "../CommentReplyBox/CommentReplyBox";
import { Link, useNavigate } from "react-router-dom";
import "./PostComment.css";
import PostLoader from "../PostLoader/PostLoader";
import ToastMessage from "../../Utils/ToastMessage";
const $ = window.jQuery;
const postReactions = [
  { id: "Like", img: "../assets/img/icon/Like.svg" },
  { id: "Love", img: "../assets/img/icon/Love.svg" },
  { id: "Haha", img: "../assets/img/icon/Haha.svg" },
  { id: "Care", img: "../assets/img/icon/Care.svg" },
  { id: "Wow", img: "../assets/img/icon/Wow.svg" },
  { id: "Sad", img: "../assets/img/icon/Sad.svg" },
  { id: "Angry", img: "../assets/img/icon/Angry.svg" },
];
const PostComment = ({
  comment,
  handleCommentReact,
  replies,
  // getReplies,
  setActiveComment,
  timeSince,
  activeComment,
  parentId = null,
  addComment,
  isCurrentUserComment = false,
  userCreatedReply,
  setUserCreatedReply,
  fromBlog,
  formatDateTime,
}) => {
  const [mouserHovering, setMouserHovering] = useState(false);
  const [commentReact, setCommentReact] = useState(
    comment.userReaction ? comment.userReaction : "React"
  );
  const [commentReplies, setCommentRepliles] = useState([]);
  const [repliesCount, setRepliesCount] = useState(comment.totalReplies || 0);
  const [reactionsCount, setReactionsCount] = useState(
    comment.totalReactions || 0
  );
  const [totalReactions, setTotalReactions] = useState([]);
  const userProfile = localStorage.getItem("userProfile");
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [parentComment, setParentComment] = useState();
  const currentUserProfileTagline = localStorage.getItem("ProfileTagline");
  const [commentReactionsModalState, setCommentReactionsModalState] =
    useState("hide");
  const [commentReactionModalOpen, setCommentReactionModalOpen] =
    useState("hide");
  const [commentloading, setCommentloading] = useState(false);
  const [hasMoreCommentsReactions, setHasMoreCommentsReactions] =
    useState(false);
  const [commentReactionsPage, setCommentReactionsPage] = useState(1);
  const [currentCommentId, setCurrentCommentId] = useState();
  const [reactionModalVar, setReactionModalVar] = useState(true);
  const [isOpenNav, setIsOpenNav] = useState(false);
  const commentReactionsModalRef = useRef();
  const replyRef = useRef();
  const currentUserID = localStorage.getItem("userId");
  const closeCommentModal = useRef();
  const endRef = useRef(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const history = useNavigate();
  const toggleMenu = () => {
    setIsOpenNav(!isOpenNav);
  };

  // console.log("Replies coutn "+repliesCount)

  const getReplies = async (id) => {
    try {
      let resp = await CommonAPi(Endpoints.GetRepliesOfComment + id);
      if (resp && resp.success === true) {
        if (isCurrentUserComment === true) setUserCreatedReply([]);
        setCommentRepliles([...resp.data]);
        // console.log(comment)
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const isReplying =
    activeComment &&
    activeComment.id === comment.id &&
    activeComment.type === "replying";
  const replyId = parentId ? parentId : comment.id;
  const showReactionsBox = () => {
    setTimeout(() => {
      setMouserHovering(true);
    }, 200);
  };
  const hideReactionsBox = () => {
    setTimeout(() => {
      setMouserHovering(false);
    }, 400);
  };
  const backdropHandle = (e) => {
    setCommentReactionsModalState("hide");
    setCommentReactionModalOpen("hide");
  };
  // get post reactions
  const allReactions = async (id, reactionType = "") => {
    setCommentReactionsModalState("hide");
    setCommentReactionModalOpen("show");
    // setCurrentCommentId(id);
    if (id == null) return;
    try {
      let resp = await CommonAPi(
        Endpoints.GetReactionsOfAComment +
          id +
          `?page=${commentReactionsPage}&reaction_type=${reactionType}`
      );
      if (resp && resp.success === true) {
        if (resp.data.length === 0) {
          setCommentloading(false);
          setHasMoreCommentsReactions(false);
        }
        if (reactionType) setTotalReactions(resp.data);
        else {
          setTotalReactions([...totalReactions, ...resp.data]);
        }
        setCommentloading(false);
      }
    } catch (e) {
      console.log(e.response);
      if (e && e.response && e.response.data && e.response.data.message) {
        ToastMessage.Error(e.response.data.message);
      }
    }
  };
  const handleScroll = (e) => {
    if (commentloading) return;
    const scrollTop = commentReactionsModalRef.current.scrollTop;
    const offsetHeight = commentReactionsModalRef.current.offsetHeight;
    const scrollHeight = commentReactionsModalRef.current.scrollHeight;
    const remaining = scrollHeight - offsetHeight - scrollTop;
    console.log(remaining);
    if (remaining <= 3) {
      commentloading(true);
      fetchNext();
    }
  };
  const fetchNext = () => {
    setTimeout(() => {
      setCommentReactionsPage(commentReactionsPage + 1);
    }, 200);
  };
  useEffect(() => {
    if (reactionModalVar) allReactions(currentCommentId);
  }, [commentReactionsPage]);

  const deleteComment = async (id) => {
    try {
      let resp = await DeleteApi(Endpoints.DeleteComment + id);
      if (resp && resp.success === true) {
        ToastMessage.Success(resp.message);
        setIsDeleted(id);
        closeCommentModal.current.click();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const comeIntoView = (id) => {
    // endRef.current.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      replyRef?.current?.focus();
    }, 300);
  };

  return (
    <div>
      <div
        className={`comment-container ${
          isDeleted === comment.id ? "d-none" : ""
        }`}
        key={comment.id}
      >
        <Link to={"/timeline/" + comment.username}>
          {isCurrentUserComment ? (
            <img src={Endpoints.baseUrl + userProfile} alt="" />
          ) : (
            <img src={Endpoints.baseUrl + comment.commentUserImage} alt="" />
          )}
        </Link>
        <div className="comment-box w-100">
          <div className="d-flex align-items-center justify-content-between">
            <Link to={"/timeline/" + comment.username}>
              <p className="comment-user">
                {comment.commentUser || currentUser.fullName}
              </p>
            </Link>

            <p className="">
              <span
                className="comment-time d-flex align-items-center"
                title={formatDateTime(comment.createdAt)}
              >
                {isCurrentUserComment
                  ? timeSince(new Date(comment.createdAt), true)
                  : timeSince(new Date(comment.createdAt))}{" "}
                ago
                <div
                  className="position-relative"
                  style={{ marginLeft: "10px" }}
                >
                  {comment.userId == currentUserID && (
                    <a
                      onClick={toggleMenu}
                      href="#user_feed_1"
                      aria-haspopup="true"
                      aria-label="user_feed_1"
                      data-bs-toggle="dropdown"
                      className="d-flex align-items-center justify-content-center "
                    >
                      <svg
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        stroke="#616161"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        class="css-i6dzq1"
                      >
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="19" cy="12" r="1"></circle>
                        <circle cx="5" cy="12" r="1"></circle>
                      </svg>
                    </a>
                  )}

                  {isOpenNav ? (
                    <ul
                      className="popup-menu text-start shadow rounded-1"
                      role="menu"
                      id="user_feed_1"
                    >
                      {comment.userId == currentUserID && (
                        <li
                          onClick={() => {
                            toggleMenu();
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#deleteCommentModal"
                        >
                          <a className="dropdown-item gap-2" href="#">
                            <svg
                              viewBox="0 0 24 24"
                              width="14"
                              height="14"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="css-i6dzq1"
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                            Delete
                          </a>
                        </li>
                      )}
                      {/* <li onClick={toggleMenu}><a className="dropdown-item" href="#">
                                <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line></svg>
                                Restricted
                            </a></li> */}
                    </ul>
                  ) : null}
                </div>
              </span>
            </p>
          </div>
          <span className="profileTagline">
            {comment.commentUserProfileTagline || currentUserProfileTagline}
          </span>
          <p className="user-comment" style={{ wordBreak: "break-all" }}>
            {comment.comment}
          </p>
          {comment.commentMedia && (
            <img
              style={{
                width: `${fromBlog ? "40%" : "50%"}`,
                height: `${fromBlog ? "11rem" : "6rem"}`,
              }}
              src={
                isCurrentUserComment
                  ? URL.createObjectURL(comment.commentMedia)
                  : Endpoints.baseUrl + comment.commentMedia
              }
              alt="comment-media"
              id="comment-media"
            />
          )}
          <div className="comment-like-reply-box">
            <PostReactions
              handleReact={handleCommentReact}
              postReactions={postReactions}
              id={comment.id}
              isOpen={mouserHovering}
              isCommentReaction={true}
              setCommentReact={setCommentReact}
              setReactionsCount={setReactionsCount}
              commentReact={commentReact}
            />
            {reactionsCount > 0 && (
              <span
                onClick={(e) => {
                  allReactions(comment.id);
                }}
              >
                <span style={{ marginRight: "3px", height: "14px" }}>
                  {reactionsCount}
                </span>
                {comment.likeReactions > 0 && commentReact !== "Like" && (
                  <img
                    src={`../assets/img/icon/Like.svg`}
                    alt="comment-reply-reaction"
                    style={{
                      width: "14px",
                      height: "14px",
                      marginRight: "-4px",
                      border: "1px solid white",
                    }}
                  />
                )}
                {comment.hahaReactions > 0 && commentReact !== "Haha" && (
                  <img
                    src={`../assets/img/icon/Haha.svg`}
                    alt="comment-reply-reaction"
                    style={{
                      width: "14px",
                      height: "14px",
                      marginRight: "-4px",
                      border: "1px solid white",
                    }}
                  />
                )}
                {comment.wowReactions > 0 && commentReact !== "Wow" && (
                  <img
                    src={`../assets/img/icon/Wow.svg`}
                    alt="comment-reply-reaction"
                    style={{
                      width: "14px",
                      height: "14px",
                      marginRight: "-4px",
                      border: "1px solid white",
                    }}
                  />
                )}
                {comment.careReactions > 0 && commentReact !== "Care" && (
                  <img
                    src={`../assets/img/icon/Care.svg`}
                    alt="comment-reply-reaction"
                    style={{
                      width: "14px",
                      height: "14px",
                      marginRight: "-4px",
                      border: "1px solid white",
                    }}
                  />
                )}
                {comment.loveReactions > 0 && commentReact !== "Love" && (
                  <img
                    src={`../assets/img/icon/Love.svg`}
                    alt="comment-reply-reaction"
                    style={{
                      width: "14px",
                      height: "14px",
                      marginRight: "-4px",
                      border: "1px solid white",
                    }}
                  />
                )}

                {comment.angryReactions > 0 && commentReact !== "Angry" && (
                  <img
                    src={`../assets/img/icon/Angry.svg`}
                    alt="comment-reply-reaction"
                    style={{
                      width: "14px",
                      height: "14px",
                      marginRight: "-4px",
                      border: "1px solid white",
                    }}
                  />
                )}

                {comment.sadReactions > 0 && commentReact !== "Sad" && (
                  <img
                    src={`../assets/img/icon/Sad.svg`}
                    alt="comment-reply-reaction"
                    style={{
                      width: "14px",
                      height: "14px",
                      marginRight: "-4px",
                      border: "1px solid white",
                    }}
                  />
                )}
              </span>
            )}

            {/* REACTIONS MODAL */}

            {totalReactions.length > 0 && "show" ? (
              <div
                className={`modal-backdrop fade ${commentReactionModalOpen}`}
                onClick={backdropHandle}
              ></div>
            ) : (
              ""
            )}
            {totalReactions.length > 0 && (
              <div
                className={`modal fade ${
                  commentReactionModalOpen === "show" ? "show" : "hide"
                }`}
                id="exampleModal123"
                tabindex="-1"
                style={{ zIndex: "1200" }}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Comment Reactions
                      </h5>
                      <button
                        type="button"
                        onClick={() => {
                          setCommentReactionsPage(1);
                          setReactionModalVar(false);
                          setCommentReactionModalOpen("hide");
                          setTotalReactions([]);
                          setCommentReactionsModalState("hide");
                        }}
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      {/* df */}
                      <div className="d-flex align-items-center">
                        <div>
                          <span
                            className="text-dark"
                            style={{
                              cursor: "pointer",
                              marginRight: "30px",
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              allReactions(comment.id, "all");
                            }}
                          >
                            All
                          </span>
                        </div>

                        <div className="d-flex w-100">
                          {totalReactions.at(-1).likeReactions > 0 && (
                            <span
                              style={{ cursor: "pointer", width: "3rem" }}
                              className="d-flex align-items-center"
                              onClick={() => {
                                allReactions(comment.id, "like");
                              }}
                            >
                              <img
                                src={`../assets/img/icon/Like.svg`}
                                style={{
                                  width: "22px",
                                  height: "22px",
                                  objectFit: "cover",
                                }}
                                alt="comment-reply-reaction"
                              />
                              <span
                                style={{ fontSize: "14px" }}
                                className="text-dark"
                              >
                                {totalReactions.at(-1).likeReactions}
                              </span>
                            </span>
                          )}
                          {totalReactions.at(-1).hahaReactions > 0 && (
                            <span
                              style={{ cursor: "pointer", width: "3rem" }}
                              className="d-flex align-items-cente text-darkr"
                              onClick={() => {
                                allReactions(comment.id, "haha");
                              }}
                            >
                              <img
                                src={`../assets/img/icon/Haha.svg`}
                                style={{ width: "22px", height: "22px" }}
                                alt="comment-reply-reaction"
                              />
                              <span
                                style={{ fontSize: "14px" }}
                                className="text-dark"
                              >
                                {totalReactions.at(-1).hahaReactions}
                              </span>
                            </span>
                          )}
                          {totalReactions.at(-1).loveReactions > 0 && (
                            <span
                              style={{ cursor: "pointer", width: "3rem" }}
                              className="d-flex align-items-center text-dark"
                              onClick={() => {
                                allReactions(comment.id, "love");
                              }}
                            >
                              <img
                                src={`../assets/img/icon/Love.svg`}
                                style={{ width: "22px", height: "22px" }}
                                alt="comment-reply-reaction"
                              />
                              <span
                                style={{ fontSize: "14px" }}
                                className="text-dark"
                              >
                                {totalReactions.at(-1).loveReactions}
                              </span>
                            </span>
                          )}
                          {totalReactions.at(-1).careReactions > 0 && (
                            <span
                              style={{ cursor: "pointer", width: "3rem" }}
                              className="d-flex align-items-center text-dark"
                              onClick={() => {
                                allReactions(comment.id, "care");
                              }}
                            >
                              <img
                                src={`../assets/img/icon/Care.svg`}
                                style={{ width: "22px", height: "22px" }}
                                alt="comment-reply-reaction"
                              />
                              <span
                                style={{ fontSize: "14px" }}
                                className="text-dark"
                              >
                                {totalReactions.at(-1).careReactions}
                              </span>
                            </span>
                          )}
                          {totalReactions.at(-1).wowReactions > 0 && (
                            <span
                              style={{ cursor: "pointer", width: "3rem" }}
                              className="d-flex align-items-center text-dark"
                              onClick={() => {
                                allReactions(comment.id, "wow");
                              }}
                            >
                              <img
                                src={`../assets/img/icon/Wow.svg`}
                                style={{ width: "22px", height: "22px" }}
                                alt="comment-reply-reaction"
                              />
                              <span
                                style={{ fontSize: "14px" }}
                                className="text-dark"
                              >
                                {totalReactions.at(-1).wowReactions}
                              </span>
                            </span>
                          )}
                          {totalReactions.at(-1).sadReactions > 0 && (
                            <span
                              style={{ cursor: "pointer", width: "3rem" }}
                              className="d-flex align-items-center text-dark"
                              onClick={() => {
                                allReactions(comment.id, "sad");
                              }}
                            >
                              <img
                                src={`../assets/img/icon/Sad.svg`}
                                style={{ width: "22px", height: "22px" }}
                                alt="comment-reply-reaction"
                              />
                              <span
                                style={{ fontSize: "14px" }}
                                className="text-dark"
                              >
                                {totalReactions.at(-1).sadReactions}
                              </span>
                            </span>
                          )}
                          {totalReactions.at(-1).angryReactions > 0 && (
                            <span
                              style={{ cursor: "pointer", width: "3rem" }}
                              className="d-flex align-items-center text-dark"
                              onClick={() => {
                                allReactions(comment.id, "angry");
                              }}
                            >
                              <img
                                src={`../assets/img/icon/Angry.svg`}
                                style={{ width: "22px", height: "22px" }}
                                alt="comment-reply-reaction"
                              />
                              <span
                                style={{ fontSize: "14px" }}
                                className="text-dark"
                              >
                                {totalReactions.at(-1).angryReactions}
                              </span>
                            </span>
                          )}
                        </div>
                      </div>

                      <hr />
                      <div>
                        <div
                          className="reactions-box"
                          ref={commentReactionsModalRef}
                          onScroll={handleScroll}
                        >
                          {totalReactions.length > 0 &&
                            totalReactions.map(
                              (reaction, idx) =>
                                reaction.id > 0 && (
                                  <div className="reaction-container my-3">
                                    {reaction.userImage ? (
                                      <img
                                        src={
                                          Endpoints.baseUrl + reaction.userImage
                                        }
                                        style={{
                                          width: "35px",
                                          height: "35px",
                                          borderRadius: "50%",
                                          marginRight: "8px",
                                          objectFit: "cover",
                                        }}
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src={`../assets/img/picture.png`}
                                        style={{
                                          width: "35px",
                                          height: "35px",
                                          objectFit: "cover",
                                          borderRadius: "50%",
                                          marginRight: "8px",
                                        }}
                                        alt=""
                                      />
                                    )}

                                    <img
                                      src={`../assets/img/icon/${reaction.reactionType}.svg`}
                                      className="user-post-reaction"
                                      alt=""
                                    />
                                    <span
                                      className="text-dark"
                                      style={{ fontSize: ".88rem" }}
                                    >
                                      {reaction.user}
                                    </span>
                                  </div>
                                )
                            )}
                          {commentloading === true &&
                            hasMoreCommentsReactions === true && <PostLoader />}
                        </div>
                      </div>
                      {/* df */}
                    </div>
                    <div class="modal-footer">
                      {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>                                                 */}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {commentReact !== "React" &&
              comment.totalReactions === undefined && (
                <span
                  onClick={(e) => {
                    allReactions(comment.id);
                  }}
                >
                  1
                </span>
              )}
            <span
              onMouseEnter={showReactionsBox}
              onMouseLeave={hideReactionsBox}
              className={` react-reply-btns ${comment.userReaction}`}
            >
              {commentReact === "React" ? (
                commentReact != undefined ? (
                  <>
                    <span>React</span>
                  </>
                ) : (
                  "s"
                )
              ) : (
                <>
                  <span
                    style={{ fontWeight: "bold", marginRight: "2px" }}
                    className={`${commentReact}`}
                  >
                    {commentReact}
                  </span>
                  <img
                    src={`../assets/img/icon/${commentReact}.svg`}
                    alt="comment-reply-reaction"
                    style={{ width: "12px", height: "12px", marginTop: "-2px" }}
                  />
                </>
              )}
            </span>
            {comment.commentId === "0" && (
              <span
                className="react-reply-btns"
                onClick={() => {
                  setActiveComment({ id: comment.id, type: "replying" });
                  setParentComment(comment.id);
                  comeIntoView(comment.id);
                }}
              >
                Reply
              </span>
            )}
            <span
              className="totalReplies"
              onClick={() => {
                getReplies(comment.id);
              }}
            >
              {repliesCount > 0 && comment.commentId === "0" && (
                <span>{repliesCount} replies</span>
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="replies">
        <span ref={endRef}></span>
        {commentReplies.length > 0 &&
          commentReplies.map((reply) => (
            <PostComment
              comment={reply}
              key={reply.id}
              replies={[]}
              handleCommentReact={handleCommentReact}
              setActiveComment={setActiveComment}
              parentId={comment.id}
              addComment={addComment}
              timeSince={timeSince}
              formatDateTime={formatDateTime}
              userCreatedReply={userCreatedReply}
              setUserCreatedReply={setUserCreatedReply}
            />
          ))}

        {userCreatedReply?.length > 0 &&
          comment.id === parentComment &&
          userCreatedReply.reverse().map((each) => (
            <PostComment
              key={each.id}
              comment={each}
              handleCommentReact={handleCommentReact}
              replies={[]}
              activeComment={activeComment}
              setActiveComment={setActiveComment}
              getReplies={getReplies}
              timeSince={timeSince}
              formatDateTime={formatDateTime}
              commentLikeCount={0}
              // setCommentLikeCount={setCommentLikeCount}
              selectedCommentReaction={null}
              isCurrentUserComment={true}
              userCreatedReply={userCreatedReply}
              setUserCreatedReply={setUserCreatedReply}
            />
          ))}
        {isReplying && (
          <CommentReplyBox
            replyRef={replyRef}
            handleSubmit={(text, commentMedia) => {
              addComment(text, commentMedia, comment.postId, replyId);
              setRepliesCount((prev) => prev + 1);
            }}
          />
        )}
      </div>
      <div>
        <div className="d-none">
          <a
            href="#"
            className="btn btn-br-blue fs-14 bg-transparent"
            data-bs-toggle="modal"
            data-bs-target="#kidmodal"
          >
            Add Kid Profile <img src="assets/img/icon/profile.svg" alt="" />
          </a>
        </div>
        <div
          className="modal fade"
          id="deleteCommentModal"
          tabindex="-1"
          aria-labelledby="deleteCommentModal"
          aria-hidden="true"
        >
          <div className="modal-dialog rounded-10 box-shadow p-lg-3 p-md-3 p-2 bg-white">
            <div className="modal-content border-0">
              <div className="d-flex align-items-center justify-content-between border-bottom w-100">
                <h3 className="medium text-dark" style={{ fontSize: "1rem" }}>
                  Delete Comment
                </h3>
                <button
                  type="button"
                  className="btn-close kids-close-icon cursor-pointer"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeCommentModal}
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="#949494"
                    width="22"
                    height="22"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="modal-body p-0">
                <h4
                  className="medium text-danger pb-3"
                  style={{ fontSize: "1rem" }}
                >
                  Do you want to delete this comment?
                </h4>

                <div className="col-md-12 text-end">
                  <button
                    type="button"
                    className="btn fs-12"
                    onClick={() => deleteComment(comment.id)}
                  >
                    Yes Delete!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostComment;
