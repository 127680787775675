import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { Endpoints } from "../../../API/Endpoints";
import ToastMessage from "../../../Utils/ToastMessage";
import { CommonAPi } from "../../../API/CommonApi";
import { DeleteApi } from "../../../API/DeleteApi";
import { PatchApi } from "../../../API/PatchApi";
import { FetchApi } from "../../../API/FetchApi";
import { getOrientation } from "get-orientation/browser";
import {
  getCroppedImg,
  getRotatedImage,
} from "../../../Utils/Canvas/canvas_util";
import "../Profile.css";
import Cropper from "react-easy-crop";
import PageProfileCropper from "../../../Components/ImageCropperModal/PageProfileCropper";
import ConfirmDialog from "../../../Components/confirmDialog/ConfirmDialog";
const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};
export default function Profiles() {
  const location = useLocation();
  const param = useParams();
  const [aboutData, setAboutData] = useState();
  const userId = localStorage.getItem("userId");

  const navigate = useNavigate();
  const [coverImageSrc, setCoverImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [file, setFile] = useState();
  const [getProfilePicture, setGetProfilePicture] = useState("");
  const [profileImageSrc, setProfileImageSrc] = useState(null);
  const [ProfilePicture, setProfilePicture] = useState("");
  const [show, setShow] = useState(false);
  const [ogCover, setOgCover] = useState("");

  const profileRef = useRef(null);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const hadnleCoverPicture = async (e) => {
    let imageDataURL = await readFile(e.target.files[0]);
    const orientation = await getOrientation(e.target.files[0]);
    const rotation = ORIENTATION_TO_ANGLE[orientation];
    if (rotation) {
      imageDataURL = await getRotatedImage(imageDataURL, rotation);
    }
    setCoverImageSrc(imageDataURL);
  };
  const uploadCoverPicture = async () => {
    let newFile = new File([file], "image.jpg", { type: "image/jpg" });
    const formData = new FormData();
    formData.append("page_image", newFile);
    formData.append("id", aboutData?.id);

    try {
      let resp = await PatchApi(Endpoints.PageCoverImage, formData);
      if (resp && resp.message) {
        setCoverImageSrc(null);
        getCompanyAbout();
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  useEffect(() => {
    getCompanyAbout();
  }, []);

  const getCompanyAbout = async () => {
    try {
      let resp = await FetchApi(Endpoints.JobPreview + "/" + param?.id);
      if (resp && resp.success === true) {
        setAboutData(resp?.data?.page);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      const { file, url } = await getCroppedImg(
        coverImageSrc,
        croppedAreaPixels
      );
      setFile(file);
    },
    [coverImageSrc]
  );

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const handleFollow = async (Id) => {
    let data = {
      page_id: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.PageFollow, data);
      if (resp && resp.message) {
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleUnfollow = async (Id) => {
    let data = {
      page_id: Id,
    };
    try {
      let resp = await DeleteApi(Endpoints.PageUnfollow, data);
      if (resp.success === true && resp.message) {
        ToastMessage.Success("Page unfollowed successfully");
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const hadnleProfilePicture = async (e) => {
    setShow(true);

    setProfilePicture({
      fileBytes: e.target.files[0],
      fileUrl: URL.createObjectURL(e.target.files[0]),
    });
    let imageDataURL = await readFile(e.target.files[0]);
    const orientation = await getOrientation(e.target.files[0]);
    const rotation = ORIENTATION_TO_ANGLE[orientation];
    if (rotation) {
      imageDataURL = await getRotatedImage(imageDataURL, rotation);
    }

    setProfileImageSrc(imageDataURL);
  };

  const handleEdit = useCallback(
    (e) => {
      e.preventDefault();
      navigate(`/update-page/${aboutData.pageType}/${aboutData.slug}`);
    },
    [aboutData, navigate]
  );

  const handleDelete = useCallback(
    async (e) => {
      e.preventDefault();
      setShowDeleteDialog(false);
      if (!aboutData.id) return;
      try {
        const res = await DeleteApi(Endpoints.pagesDelete, {
          id: aboutData.id,
          type: aboutData.pageType,
        });
        if (res.success) {
          navigate("/mainfeed", { replace: true });
          ToastMessage.Success(res.message);
        } else {
          ToastMessage.Error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [aboutData, navigate]
  );

  return (
    <>
      {aboutData ? (
        <div className="profile-banner-top mb-3 bg-white rounded-15 overflow-hidden">
          <div
            className="position-relative banner"
            id="profile-cover-container"
            style={{ height: "20.3rem" }}
          >
            {aboutData?.coverImage === null ? (
              <img
                src="../assets/img/edu_default.jpg"
                alt=""
                className="profile-coverpic"
              />
            ) : (
              <img
                src={Endpoints.baseUrl + aboutData?.coverImage}
                alt=""
                className="profile-coverpic"
              />
            )}

            <>
              {coverImageSrc === null ? (
                ""
              ) : (
                <Cropper
                  image={coverImageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={3.84 / 1.1}
                  showGrid={false}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              )}
            </>

            <div className="upload-coverpic position-absolute top-0 end-0 m-3">
              {aboutData?.userId == userId ? (
                <>
                  {coverImageSrc ? (
                    <div className="">
                      <label
                        style={{ marginRight: "5px" }}
                        for="coverupload"
                        className="fs-12 medium bg-white rounded-pill px-1 py-1 cursor-pointer"
                        onClick={uploadCoverPicture}
                      >
                        Done
                      </label>
                      <span
                        onClick={() => {
                          setCoverImageSrc(null);
                        }}
                        className="fs-12 medium bg-danger rounded-pill px-1 py-1 cursor-pointer"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="14"
                          height="14"
                          stroke="#fff"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          class="css-i6dzq1"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                      </span>
                    </div>
                  ) : (
                    <>
                      <input
                        type="file"
                        accept="image/*"
                        id="coverupload"
                        className="d-none"
                        onChange={(e) => {
                          hadnleCoverPicture(e);
                        }}
                      />
                      <label
                        for="coverupload"
                        className="fs-14 medium bg-white rounded-pill px-3 py-2 cursor-pointer"
                      >
                        Update Cover
                        <img
                          src="../assets/img/icon/upload-icon.svg"
                          alt=""
                          className="mx-1"
                        />
                      </label>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="row mx-0 align-items-center ">
            <div className="col-lg-auto col-md-3 px-3">
              <div className="profile-dp">
                {aboutData?.pageImage === null ? (
                  <img src="../assets/img/comany-dp.png" alt="" />
                ) : (
                  <img src={Endpoints.baseUrl + aboutData?.pageImage} alt="" />
                )}
                {userId == aboutData?.userId ? (
                  <>
                    <input
                      type="file"
                      id="profilepic"
                      accept="image/*"
                      ref={profileRef}
                      className="d-none"
                      onChange={(e) => {
                        hadnleProfilePicture(e);
                        setShow(true);
                      }}
                    />
                    <label
                      for="profilepic"
                      className="cursor-pointer d-flex profile-edit-icon"
                    >
                      <img src="../assets/img/icon/icon-camera4.svg" alt="" />
                    </label>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div
              className="col-lg-5 col-md-4 py-2 profile-name"
              style={{ display: "grid", width: "auto" }}
            >
              {coverImageSrc && (
                <div className="cover-slider-container">
                  <span>Zoom</span>
                  <div className="cover-slider">
                    <span className="slider-icons">-</span>
                    <input
                      type="range"
                      min="1"
                      max="3"
                      step="0.1"
                      value={zoom}
                      onChange={(e) => setZoom(e.target.value)}
                    />
                    <span className="slider-icons">+</span>
                  </div>
                </div>
              )}

              <h3 className="bold pb-2">{aboutData?.title}</h3>
              <h5 className="regular text-darkgray">{aboutData?.tagline}</h5>

              <h5 className="regular text-darkgray fs-14 pt-2">
                {aboutData?.industry}

                {aboutData?.locations[0] && (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="6"
                      viewBox="0 0 24 24"
                      fill="#757575"
                      stroke="#757575"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="mx-1 feather feather-circle text-darkgray"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                    </svg>

                    {aboutData?.locations[0]?.streetAddress +
                      "," +
                      aboutData?.locations[0]?.state}
                  </>
                )}
              </h5>
            </div>

            <div className="col-lg-4 col-md-5 py-md-2 profile-cover-btn ms-md-auto">
              <div className="d-flex align-items-center gap-2 justify-content-md-end justify-content-between">
                {userId == aboutData?.userId ? (
                  ""
                ) : (
                  <div>
                    {aboutData?.isFollowing === false ? (
                      <Link
                        onClick={() => {
                          handleFollow(aboutData?.id);
                        }}
                        className="btn btn-light-blue px-3 medium fs-14 py-2"
                      >
                        Follow
                        <img
                          src="../assets/img/icon/follow2.svg"
                          alt=""
                          width="12"
                        />
                      </Link>
                    ) : (
                      <Link
                        onClick={() => {
                          handleUnfollow(aboutData?.id);
                        }}
                        className="btn btn-light-blue px-3 medium fs-14 py-2"
                      >
                        Unfollow
                        {/* <img src="../assets/img/icon/follow2.svg" alt="" width="12" /> */}
                      </Link>
                    )}
                    <Link
                      to={"#"}
                      className="btn light-brown-btn px-3 medium fs-14 py-2 mx-xl-2"
                    >
                      Visit Website
                      <img
                        src="../assets/img/icon/website.svg"
                        alt=""
                        width="12"
                      />
                    </Link>
                  </div>
                )}

                <div className="position-relative">
                  <a
                    href={"#"}
                    aria-haspopup="true"
                    aria-label="more-profile"
                    data-bs-toggle="dropdown"
                    className="d-flex align-items-center justify-content-center rounded-circle bg-f9f9f9 p-2"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      width="22"
                      height="22"
                      stroke="#616161"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="css-i6dzq1"
                    >
                      <circle cx="12" cy="12" r="1"></circle>
                      <circle cx="19" cy="12" r="1"></circle>
                      <circle cx="5" cy="12" r="1"></circle>
                    </svg>
                  </a>

                  <ul
                    className="dropdown-menu shadow rounded-1"
                    role="menu"
                    id="more-profile"
                    aria-hidden="true"
                  >
                    {aboutData.userId === parseInt(userId) && (
                      <>
                        <li>
                          <a
                            className="dropdown-item"
                            href={"#"}
                            onClick={handleEdit}
                          >
                            <img
                              src="../assets/img/icon/blue-edit.svg"
                              width={"12"}
                            />{" "}
                            Edit
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href={"#"}
                            onClick={() => {
                              setShowDeleteDialog(true);
                            }}
                          >
                            <img
                              src="../assets/img/icon/blue-delete.svg"
                              width={"10"}
                            />{" "}
                            Delete
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="px-lg-4 px-3 px-xl-5 pt-2">
            <ul className="profile-menu d-flex flex-wrap gap-lg-3">
              <li
                className={
                  location.pathname.split("/")[1] === "page-profile"
                    ? "active"
                    : ""
                }
              >
                <Link to={"/page-profile/" + aboutData?.slug}>Home</Link>
              </li>

              <li
                className={
                  location.pathname.split("/")[1] === "page-about"
                    ? "active"
                    : ""
                }
              >
                <Link to={"/page-about/" + aboutData?.slug}>About</Link>
              </li>
              {parseInt(aboutData.userId) === parseInt(userId) ? (
                <li
                  className={
                    location.pathname.split("/")[1] === "admin-jobs-list"
                      ? "active"
                      : ""
                  }
                >
                  <Link to={"/admin-jobs-list/" + aboutData?.id}>Jobs</Link>
                </li>
              ) : (
                <li
                  className={
                    location.pathname.split("/")[1] === "page-jobs"
                      ? "active"
                      : ""
                  }
                >
                  <Link to={"/page-jobs/" + aboutData?.slug}>Jobs</Link>
                </li>
              )}

              <li
                className={
                  location.pathname.split("/")[1] === "page-post"
                    ? "active"
                    : ""
                }
              >
                <Link to={"/page-post/" + aboutData?.slug}>Posts</Link>
              </li>

              <li
                className={
                  location.pathname.split("/")[1] === "page-people"
                    ? "active"
                    : ""
                }
              >
                <Link to={"/page-people/" + aboutData?.slug}>People</Link>
              </li>

              <li
                className={
                  location.pathname.split("/")[1] === "page-followers"
                    ? "active"
                    : ""
                }
              >
                <Link to={"/page-followers/" + aboutData?.slug}>Followers</Link>
              </li>

              {/* <li className={location.pathname === "/page-video" ? "active" : ""}>
               <Link to={"/page-video/"+ param?.slug}>Videos</Link>
            </li> */}
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}

      {profileImageSrc && (
        <PageProfileCropper
          setShow={setShow}
          show={show}
          profileImageSrc={profileImageSrc}
          setGetProfilePicture={setGetProfilePicture}
          profileRef={profileRef}
          pageId={aboutData?.id}
          onSuccess={getCompanyAbout}
        />
      )}
      <ConfirmDialog
        show={showDeleteDialog}
        buttonText="Delete"
        body="Are you sure want to delete?"
        danger
        header="Confirm Delete"
        onCancel={() => {
          setShowDeleteDialog(false);
        }}
        onConfirm={handleDelete}
      />
    </>
  );
}
