import React, { useEffect, useState, useCallback } from 'react';
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate, useParams } from 'react-router-dom';
import JobModel from '../Jobs/JobModel';
import Category from './Category';
import SearchJob from './SearchJob';
import TopEmployee from '../Jobs/TopEmployee';
import { Helmet } from "react-helmet";
const $ = window.jQuery;

function Internship() {
    const [jobData, setJobData] = useState([]);
    const [limit, setLimit] = useState(9);
    const [count, setCount] = useState(0);
    const [jobId, setjobId] = useState(0);
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');
    useEffect(() => {
        getCompanyJobs();
    }, [limit])
    const getCompanyJobs = async () => {

        try {
            let resp = await FetchApi(Endpoints.GetJobs + "?size=" + limit + "&scope=featuredInternship");
            if (resp && resp.success === true) {
                setJobData(resp?.data?.allJob);
                setCount(resp?.data?.totalRecord);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }

    };

    const timeSince = (date, isCurrent = false) => {
        if (isCurrent) return 0 + " sec";
        let seconds = Math.floor((new Date() - date) / 1000);
        let interval = seconds / 31536000;

        if (interval > 1) return Math.floor(interval) + "year ago";
        interval = seconds / 2592000;

        if (interval > 1) return Math.floor(interval) + " mon ago";
        interval = seconds / 86400;

        if (interval > 1) return Math.floor(interval) + " day ago";
        interval = seconds / 3600;

        if (interval > 1) return Math.floor(interval) + " h ago";
        interval = seconds / 60;

        if (interval > 1) return Math.floor(interval) + " min ago";
        return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec ago" : "0 sec ago";
    };

    const handleApplyJobs = (Id) => {
        setjobId(Id);
        $('#applyjobs').modal('show');
    };
    const JobSave = async (Id) => {
        let data = {
            jobId: Id
        }
        try {
            let resp = await CommonAPi(Endpoints.JobSave, data);
            if (resp && resp.success === true) {
                getCompanyJobs();
                ToastMessage.Success(resp.message);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }
    };
    return (
        <Container
            header
        >
            <Helmet>
                <title> Internship</title>
            </Helmet>
            <div class="wrapper wrapper-bg">

                <div class="main">
                    <LeftNavigation />

                    <div class="container py-3">

                        <SearchJob {...{
                            type: "Internship"
                        }} />

                        <Category  {...{
                            type: "Internship"
                        }} />

                        {jobData.length > 0 ?
                            <div class="bg-white main-common mt-4">
                                <div class="text-center pb-3">
                                    <h3 class="bold pb-2">Feature Internship</h3>
                                    <h5 class="medium text-darkgray ">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h5>
                                </div>

                                <div class="row">
                                    {jobData.map((item, index) => {
                                        return (
                                            <div class="col-md-4 mb-3" key={index}>
                                                <div class="job-featured-item d-flex gap-2 align-items-start w-100">
                                                    <span className="circular--landscape pic-size-64-flex">
                                                    <Link to={"/jobs/" + item?.id}>
                                                        {item?.page?.pageImage === null ?
                                                            <img src="assets/img/company.svg" alt="" width="64" height="64" class="rounded-circle" />
                                                            :
                                                            <img src={Endpoints.baseUrl + item?.page?.pageImage} alt="" width="64" height="64" class="rounded-circle" />
                                                        }
                                                    </Link>
                                                    </span>
                                                    <div class="w-100">
                                                        <div class="d-flex justify-content-between w-100">
                                                            <Link to={"/jobs/" + item?.id}>
                                                                <h5 class="semibold">{item?.title}</h5>
                                                            </Link>

                                                            {item?.isSaved === "true" ?
                                                                <Link onClick={() => {
                                                                    JobSave(item?.id)
                                                                }}><img src="assets/img/icon/icon-wishlist-fill.svg" width="19" alt="" /></Link>
                                                                :
                                                                <Link onClick={() => {
                                                                    JobSave(item?.id)
                                                                }}><img src="assets/img/icon/icon-wishlist.svg" width="19" alt="" /></Link>
                                                            }
                                                        </div>
                                                        {/* <h5 class="medium text-brown ">{item?.page?.title}</h5> */}
                                                        <Link to={"/page-profile/" + item?.page?.slug}>
                                                        <h5 class="medium text-brown ">{item?.page?.title}</h5>
                                                        </Link>
                                                        <div class="d-flex py-2 align-items-center">
                                                            <span class="fs-10 text-darkgray pe-2 d-flex align-items-center">
                                                                <img src="assets/img/icon/icon-location.svg" alt="" class="align-middle me-1" />
                                                                <span>{item?.city?.city}{item?.city?.city ? "," : ""} {item?.state?.state}</span>
                                                            </span>

                                                            <span class="fs-10 text-darkgray d-flex align-items-center">
                                                                <img src="assets/img/icon/icon-date-time.svg" alt="" class="align-middle me-1" />
                                                                <span>{timeSince(new Date(item.createdAt))}</span>
                                                            </span>
                                                        </div>

                                                        <div class="d-flex request-btn gap-2 mb-2">
                                                            <a href="#" class="btn light-brown-btn px-2 medium">{item?.jobType}</a>
                                                            <a href="#" class="btn light-brown-btn px-2 medium">{item?.workMode}</a>
                                                        </div>
                                                        {
                                                            userId !== item.userId ?
                                                                item?.alreadyApplied == "true" ?
                                                                    <div class="btn px-4 py-2 fs-14 gray-btn ">Applied</div>
                                                                    :
                                                                    <Link onClick={() => {
                                                                        handleApplyJobs(item?.id)
                                                                    }} class="btn px-4 py-2 fs-14 ">Apply Now <img src="assets/img/icon/icon-briefcase.svg" class="ps-2" /></Link>
                                                                : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}




                                </div>

                                <div class="text-center py-3">
                                    <Link to={"/featured-listing?scope=featuredInternship"} class="btn px-4">View All Jobs</Link>
                                </div>


                            </div>
                            : ""}

                        <TopEmployee />

                    </div>

                    <OnlineFriends />
                </div>
                <JobModel {...{
                    jobId: jobId,
                    onSuccess: getCompanyJobs
                }} />

            </div>
        </Container>
    )
}

export default Internship;