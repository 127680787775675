import React, { useCallback, useState } from "react";
import { Endpoints } from "../../API/Endpoints";
import { Link, useNavigate } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
import { DeleteApi } from "../../API/DeleteApi";

import ToastMessage from "../../Utils/ToastMessage";
import ConfirmDialog from "../../Components/confirmDialog/ConfirmDialog";

export default function PageItem({
  item,
  index,
  onSuccess,
  pageName,
  activePage,
}) {
  const Navigate = useNavigate();
  const userId = localStorage.getItem("userId");

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleFollow = async (Id) => {
    let data = {
      page_id: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.PageFollow, data);
      if (resp && resp.message) {
        onSuccess(true);
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleUnfollow = async (Id) => {
    let data = {
      page_id: Id,
    };
    try {
      let resp = await DeleteApi(Endpoints.PageUnfollow, data);
      if (resp.success === true && resp.message) {
        onSuccess(true);
        ToastMessage.Success("Page unfollowed successfully");
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleEdit = useCallback(
    (e) => {
      e.preventDefault();
      Navigate(`/update-page/${activePage}/${item.slug}`);
    },
    [Navigate, item, activePage]
  );

  const handleDelete = useCallback(
    async (e) => {
      e.preventDefault();
      setShowDeleteDialog(false);
      try {
        const res = await DeleteApi(Endpoints.pagesDelete, {
          id: item.id,
        });
        if (res.success) {
          onSuccess(true);
          ToastMessage.Success(res.message);
        } else {
          ToastMessage.Error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [item, onSuccess]
  );

  return (
    <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={index}>
      <div className="mainmenu-page-item">
        <div className="text-center position-relative">
          <div className="mainmenu-item-action position-absolute end-0 top-0 m-2 p-1">
            {item?.userId === parseInt(userId) && (
              <a
                href="#"
                aria-haspopup="true"
                aria-label="university_1"
                data-bs-toggle="dropdown"
                className="d-flex align-items-center justify-content-center rounded-circle bg-white p-1"
              >
                <svg
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  stroke="#000"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="css-i6dzq1"
                >
                  <circle cx="12" cy="12" r="1"></circle>
                  <circle cx="19" cy="12" r="1"></circle>
                  <circle cx="5" cy="12" r="1"></circle>
                </svg>
              </a>
            )}
            <ul
              className="dropdown-menu shadow rounded-1 text-start"
              role="menu"
              id="university_1"
              aria-hidden="true"
              style={{ minWidth: "unset" }}
            >
              <li>
                <Link
                  to={`/update-page/${activePage}/${item?.slug}`}
                  className="dropdown-item"
                >
                  Edit
                </Link>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => {
                    setShowDeleteDialog(true);
                  }}
                >
                  Delete
                </a>
              </li>
            </ul>
          </div>
          <Link to={"/page-profile/" + item?.slug}>
            {item?.coverImage === null ? (
              <img
                src="assets/img/edu_default.jpg"
                className="mainmenu-page-banner"
                alt=""
              />
            ) : (
              <img
                src={Endpoints.baseUrl + item?.coverImage}
                className="mainmenu-page-banner"
                alt=""
                style={{ objectFit: "cover" }}
              />
            )}
          </Link>
          <Link to={"/page-profile/" + item?.slug}>
            {item?.pageImage === null ? (
              <span className="suggested-user">
                <img
                  src="assets/img/university.png"
                  alt=""
                  width="96"
                  height="96"
                  className="rounded-circle"
                />
              </span>
            ) : (
              <span className="suggested-user">
                <img
                  src={Endpoints.baseUrl + item?.pageImage}
                  alt=""
                  width="96"
                  height="96"
                  className="rounded-circle"
                />
              </span>
            )}
          </Link>

          <Link to={"/page-profile/" + item?.slug}>
            <h5 className="semibold pt-3 pb-2">{item?.title}</h5>
          </Link>
          {pageName === "Page" ? (
            <h6 className="fs-14 text-darkgray medium pb-1">
              {item?.industry}
            </h6>
          ) : item?.locations?.length > 0 ? (
            <h6 className="fs-14 text-darkgray medium pb-1">
              {item.locations[0]?.city}, {item?.locations[0]?.state}
            </h6>
          ) : (
            ""
          )}
          {pageName === "Company" || pageName === "Pages" ? (
            ""
          ) : (
            <>
              <h6 className="fs-14 text-darkgray regular pb-1">
                {item?.totalStudents} Students
              </h6>
              <h6 className="fs-14 text-darkgray regular pb-2">
                {item?.totalTeachers} Teachers
              </h6>
            </>
          )}
        </div>
        {pageName === "Pages" ? (
          userId == item?.userId ? (
            ""
          ) : (
            <div className="text-center py-3 px-2">
              {item?.isFollowing === false ? (
                <Link
                  className="btn btn-light-blue w-100 medium"
                  onClick={() => {
                    handleFollow(item.id);
                  }}
                >
                  Join
                  <img src="../assets/img/icon/follow.svg" className="ps-2" />
                </Link>
              ) : (
                <Link
                  className="btn btn-light-blue w-100 medium"
                  onClick={() => {
                    handleUnfollow(item?.id);
                  }}
                >
                  Leave Page
                </Link>
              )}
            </div>
          )
        ) : userId == item?.userId ? (
          ""
        ) : (
          <div className="text-center py-3 px-2">
            {item?.isFollowing === false ? (
              <Link
                className="btn btn-light-blue w-100 medium"
                onClick={() => {
                  handleFollow(item?.id);
                }}
              >
                Follow
                <img src="../assets/img/icon/follow.svg" className="ps-2" />
              </Link>
            ) : (
              <Link
                className="btn btn-light-blue w-100 medium"
                onClick={() => {
                  handleUnfollow(item?.id);
                }}
              >
                Unfollow
              </Link>
            )}
          </div>
        )}
      </div>
      <ConfirmDialog
        show={showDeleteDialog}
        buttonText="Delete"
        body="Are you sure want to delete?"
        danger
        header="Confirm Delete"
        onCancel={() => {
          setShowDeleteDialog(false);
        }}
        onConfirm={handleDelete}
      />
    </div>
  );
}
