import React, { useEffect, useState } from 'react';
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import CompanyProfiles from './CompanyProfiles';
import LeftStatistics from './LeftStatistics';
import PeopleItem from './PeopleItem';
import {Helmet} from "react-helmet";
export default function CompanyPeople() {
    const [peopleData, setPeopledata] = useState([]);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(9);
    const [loadStatus, setLoadStatus] = useState(false);
    const [statistics, setStatistics] = useState('');
    const param = useParams();
    const { search } = useLocation();
    const [searchParams] = useSearchParams(search);

    const queryParam = searchParams.get('filter') ?? null;
    const navigate = useNavigate();

    useEffect(() => {
        getCompanyPeople();
    }, [queryParam]);
    const getCompanyPeople = async () => {

        try {
            console.log(queryParam);
            let requestURL = Endpoints.PagePeople + "?slug=" + param?.slug + "&size=" + limit;
            if (queryParam === 'location') {
                requestURL += '&location=1'
            }
            if (queryParam === 'profession') {
                requestURL += '&profession=1'
            }
            if (queryParam === 'academic') {
                requestURL += '&academic=1'
            }

            let resp = await FetchApi(requestURL);
            if (resp && resp.success === true) {
                setPeopledata(resp?.data?.employees);
                setCount(resp?.data?.totalFollowers);
                setStatistics({
                    totalEmployees: resp?.data?.EmployeesCount,
                    totalFollowers: resp?.data?.totalFollowers,
                    totalJobs: 0,
                    empByLocation: resp?.data?.totalEmployeesFromSameLocation,
                    empByProfession: resp?.data?.totalEmployeesFromSameProfession,
                    empByAcademic: resp?.data?.totalEmployeesFromSameAcademic
                });
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }

    };
    const handleLoadMore = async () => {
        const newLimit = limit + 9;
        setLimit(newLimit);
        try {
            let requestURL = Endpoints.PagePeople + "?slug=" + param?.slug + "&size=" + newLimit;
            // let requestURL = Endpoints.PagePeople + "?slug=" + param?.slug + "&size=" + limit;
            if (queryParam === 'location') {
                requestURL += '&location=1'
            }
            if (queryParam === 'profession') {
                requestURL += '&profession=1'
            }
            if (queryParam === 'academic') {
                requestURL += '&academic=1'
            }

            let resp = await FetchApi(requestURL);
            if (resp && resp.success === true) {
                setPeopledata(resp?.data?.employees);
                setCount(resp?.data?.totalFollowers);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };
    return (
        <Container
            header
        >
             <Helmet>
                <title>People</title>
            </Helmet>
            <div className="wrapper wrapper-bg">
                <div className="main">
                    <LeftNavigation />
                    <div className="container py-lg-4 py-3">
                        <CompanyProfiles />
                        <div className="row" >
                            <LeftStatistics {...{
                                statistics: statistics
                            }} />
                            <div className="col-md-9">
                                <div className="bg-white main-common">

                                    <div className="main-top-row d-md-flex justify-content-between align-items-center pb-3">
                                        <h3 className="medium">Total {count} employees</h3>


                                    </div>

                                    <div className="mainmenu-filter-row pb-3">
                                        <ul className="filter d-flex flex-nowrap align-items-center gap-2 fs-14 medium">
                                            
                                            <li className={queryParam == null && 'active'}>
                                                <Link to={"/page-people/" + param?.slug} className={queryParam === null && 'text-white'}>All Employees</Link>
                                            </li>
                                            <li className={queryParam === 'location' && 'active'}>
                                                <Link to={"/page-people/" + param?.slug + "?filter=location"} className={queryParam === 'location' && 'text-white'}>By Location <span className="fs-10 text-brown">{statistics.empByLocation}</span></Link>
                                            </li>
                                            <li className={queryParam === 'academic' && 'active'}>
                                                <Link to={"/page-people/" + param?.slug + "?filter=academic"} className={queryParam === 'academic' && 'text-white'}>By School/College <span className="fs-10 text-brown">{statistics.empByAcademic}</span></Link>
                                            </li>
                                            <li className={queryParam === 'profession' && 'active'}>
                                                <Link to={"/page-people/" + param?.slug + "?filter=profession"} className={queryParam === 'profession' && 'text-white'}>By Profession <span className="fs-10 text-brown">{statistics.empByProfession}</span></Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="row mainmenu-filter-conatiner">

                                        {peopleData.map((item, index) => {
                                            return (
                                                <PeopleItem
                                                    {...{
                                                        item: item,
                                                        index: index,
                                                        onSuccess: getCompanyPeople
                                                    }}
                                                />
                                            )
                                        })}



                                    </div>
                                    {count > limit ?
                                        <div className="text-center">

                                            <Link href="#" className="text-blue" onClick={() => handleLoadMore()}>

                                                {loadStatus ?
                                                    <img src="../assets/img/icon/icon-loading.svg" alt="" />
                                                    : "Load More People "}

                                            </Link>

                                        </div>
                                        : ""}

                                </div>
                            </div>

                        </div>
                    </div>



                    <OnlineFriends />
                </div>
            </div>
        </Container >
    )
}
