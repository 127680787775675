import React, { useState, useCallback, useEffect } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from "../../API/FetchApi";
import { PatchApi } from "../../API/PatchApi";
import { useNavigate, Link, useParams } from "react-router-dom";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import { Helmet } from "react-helmet";
const $ = window.jQuery;

export default function UpdateGroup() {
  const [groupName, setGroupName] = useState("");
  const [groupType, setGroupType] = useState("");
  const [Industry, setIndustry] = useState("");
  const [Location, setLocation] = useState("");
  const [Tagline, setTagline] = useState("");
  const [id, setId] = useState("");
  const [Description, setDescription] = useState("");
  const [IndustryKey, setIndustryKey] = useState(
    Math.ceil(Math.random() * 999999)
  );
  const [IndustryOption, setIndustryOption] = useState([]);
  const Navigate = useNavigate();
  const param = useParams();
  const [groupNameError, setGroupNameError] = useState("");
  const [groupTypeError, setGroupTypeError] = useState("");
  const [IndustryError, setIndustryError] = useState("");
  const [LocationError, setLocationError] = useState("");
  const [TaglineError, setTaglineError] = useState("");
  const [DescriptionError, setDescriptionError] = useState("");

  useEffect(() => {
    GetGroup();
  }, []);

  const GetGroup = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.GetGroupsAbout + "?groupId=" + param?.id
      );
      if (resp && resp.success === true) {
        setId(resp?.data?.groupData?.id);
        setGroupName(resp?.data?.groupData?.groupName);
        setGroupType(resp?.data?.groupData?.groupType);
        setIndustryKey(Math.ceil(Math.random() * 999999));
        setIndustry(resp?.data?.groupData?.industry);
        setIndustryOption([
          {
            id: resp?.data?.groupData?.industry,
            name: resp?.data?.groupData?.industry,
          },
        ]);
        setLocation(resp?.data?.groupData?.location);
        setTagline(resp?.data?.groupData?.tagline);
        setDescription(resp?.data?.groupData?.description);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    if (!groupName) {
      setGroupNameError("Please enter group name");
    } else {
      setGroupNameError("");
    }

    if (!groupType) {
      setGroupTypeError("Please enter group type");
    } else {
      setGroupTypeError("");
    }

    if (!Description) {
      setDescriptionError("Please enter description");
    } else {
      setDescriptionError("");
    }
    if (!Industry) {
      setIndustryError("Please enter Insdustry");
    } else {
      setIndustryError("");
    }
    if (!Tagline) {
      setTaglineError("Please enter tagline");
    } else {
      setTaglineError("");
    }
    if (!Location) {
      setLocationError("Please enter location");
    } else {
      setLocationError("");
    }

    if (
      groupName &&
      groupType &&
      Description &&
      Industry &&
      Tagline &&
      Location
    ) {
      let data = {
        id: id,
        title: groupName,
        type: groupType,
        description: Description,
        industry: Industry,
        tagline: Tagline,
        location: Location,
      };
      try {
        let resp = await PatchApi(Endpoints.GroupUpdate, data);
        if (resp && resp.success === true) {
          ToastMessage.Success(resp.message);
          Navigate("/group-home/" + resp?.data?.slug);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleOnSearchIndustry = async (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string) {
      try {
        let resp = await FetchApi(
          Endpoints.SearchIndustry + "?title=" + string
        );
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                name: item?.value,
              };
            });
            setIndustryOption(newData);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleOnSelectIndustry = (item) => {
    if (item.length > 0) {
      setIndustry(item[0].name);
    } else {
      setIndustry("");
    }
  };

  const RenderIndustry = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-StudyArea"
        defaultInputValue={Industry}
        key={IndustryKey}
        onSearch={handleOnSearchIndustry}
        onChange={handleOnSelectIndustry}
        // onBlur={(e) => { handleValidation(e, e.target.value, "study") }}
        options={IndustryOption}
        placeholder="For Ex: IT Services and IT Consulting "
        labelKey="name"
        minLength={1}
        emptyLabel={"Searching..."}
        className={
          IndustryError
            ? "border rounded-2 fs-14 p-2 text-gray"
            : "border rounded-2 fs-14 p-2 text-gray"
        }
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [IndustryOption, IndustryError, Industry, IndustryKey]);

  return (
    <Container header>
      <Helmet>
        <title>Edit Group</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-lg-3 py-3">
            <div className="bg-white rounded-10 shadow-lg">
              <div className="py-3 px-3 row align-items-center">
                <div className="col-md-3">
                  <Link to={"/group-member/" + id} className="btn">
                    <svg
                      viewBox="0 0 24 24"
                      width="16"
                      height="16"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="css-i6dzq1"
                    >
                      <line x1="19" y1="12" x2="5" y2="12"></line>
                      <polyline points="12 19 5 12 12 5"></polyline>
                    </svg>
                    Back
                  </Link>
                </div>

                <div className="col-md-9">
                  <p className="medium  text-gray m-0">
                    Let’s get started with a few details about your group.
                  </p>
                </div>
              </div>

              <div className="border-top py-md-5 px-md-5 p-3">
                <p className="text-darkgray fs-12 medium">
                  * All fields are required.
                </p>

                <form onSubmit={handleSave} className="company-form">
                  <div className="row gx-md-50">
                    <div className="col-md-6 mb-4">
                      <label for="" className="fs-15 medium d-block pb-2">
                        Group Name*
                      </label>
                      <input
                        type="text"
                        placeholder="Add your group name"
                        className="border rounded-2 fs-14 p-3 text-gray"
                        value={groupName}
                        onChange={(e) => {
                          setGroupName(e.target.value);
                        }}
                      />
                      {groupNameError ? (
                        <div
                          className="form-error"
                          style={{ color: "white", margintop: "-10px" }}
                        >
                          {groupNameError}
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <span className="field-error fs-12 text-red">This group name is already in use. Kindly use different name.</span> */}
                    </div>

                    <div className="col-md-6 mb-4">
                      <label for="" className="fs-15 medium d-block pb-2">
                        Group Type *
                      </label>
                      <select
                        name=""
                        className="fs-14 border rounded-2 p-3 text-gray"
                        id=""
                        onChange={(e) => {
                          setGroupType(e.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        <option
                          selected={groupType == "Public" ? true : false}
                          value="Public"
                        >
                          Public
                        </option>
                        <option
                          selected={groupType == "Private" ? true : false}
                          value="Private"
                        >
                          Private
                        </option>
                      </select>
                      {groupTypeError ? (
                        <div
                          className="form-error"
                          style={{ color: "white", margintop: "-10px" }}
                        >
                          {groupTypeError}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 mb-4">
                      <label for="" className="fs-15 medium d-block pb-2">
                        Industry *
                      </label>

                      {RenderIndustry()}
                      {IndustryError ? (
                        <div
                          className="form-error"
                          style={{ color: "white", margintop: "-10px" }}
                        >
                          {IndustryError}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 mb-4">
                      <label for="" className="fs-15 medium d-block pb-2">
                        Location *
                      </label>

                      <input
                        type="text"
                        placeholder="Enter Location"
                        className="border rounded-2 fs-14 p-3 text-gray"
                        value={Location}
                        onChange={(e) => {
                          setLocation(e.target.value);
                        }}
                      />
                      {LocationError ? (
                        <div
                          className="form-error"
                          style={{ color: "white", margintop: "-10px" }}
                        >
                          {LocationError}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 mb-4">
                      <label for="" className="fs-15 medium d-block pb-2">
                        Tagline *
                      </label>
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        className="border rounded-2 fs-14 p-3 textarea1 text-gray"
                        placeholder="Add Page Tagline"
                        value={Tagline}
                        onChange={(e) => {
                          setTagline(e.target.value);
                        }}
                      ></textarea>
                      {TaglineError ? (
                        <div
                          className="form-error"
                          style={{ color: "white", margintop: "-10px" }}
                        >
                          {TaglineError}
                        </div>
                      ) : (
                        ""
                      )}
                      <div id="count1" className="fs-12 text-darkgray text-end">
                        <span id="current1">{Tagline.length}</span>
                        <span id="maximum1">/ 200</span>
                      </div>
                    </div>

                    <div className="col-md-6 mb-3">
                      <label for="" className="fs-15 medium d-block pb-2">
                        Description *
                      </label>

                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        placeholder="Add about your organization in detail."
                        className="border rounded-2 fs-14 p-3  textarea2 text-gray"
                        value={Description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      ></textarea>
                      {DescriptionError ? (
                        <div
                          className="form-error"
                          style={{ color: "white", margintop: "-10px" }}
                        >
                          {DescriptionError}
                        </div>
                      ) : (
                        ""
                      )}
                      <div id="count2" className="fs-12 text-darkgray text-end">
                        <span id="current2">{Description.length}</span>
                        <span id="maximum2">/ 200</span>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <button type="submit" className="btn fs-14 w-auto px-4">
                        Update Group
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <OnlineFriends />
          </div>
        </div>
      </div>
    </Container>
  );
}
