import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CommonAPi } from "../../../API/CommonApi";
import { Endpoints } from "../../../API/Endpoints";
import { FetchApi } from "../../../API/FetchApi";
import { DeleteApi } from "../../../API/DeleteApi";
import { UpdateAPi } from "../../../API/UpdateApi";
import "../Student/ChatBox.css";
import { timeSince } from "../../../Utils/helpers";
import { StickyChatContext } from "../../../Components/StickyChatWrapper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmojiPickerFloatingWindow from "../../../Components/emojiPicker/EmojiPickerFloatingWindow";
import ToastMessage from "../../../Utils/ToastMessage";
import ViewMember from "./ViewMember";
import EditGroupName from "./EditGroupName";
import ImagePreviewModel from "../../../Components/Post/ImagePreviewModal";
import { IoPlayCircle } from "react-icons/io5";

const FriendChatBox = ({
  currentUser,
  chat,
  online,
  receivedMsg,
  getUserChats,
  chats,
  setActiveChatRoom,
  onSendMessage = (id) => {},
}) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const { socket, setChats } = useContext(StickyChatContext);

  const location = useLocation();

  const chatContainerRef = useRef(null);

  const savedScrollPosition = useRef();

  const fileRef = useRef();

  const filePreviewBox = useRef();

  const emojiButtonRef = useRef();

  const [newMsg, setNewMsg] = useState("");
  const [file, setFile] = useState(null);
  const [messages, setMessages] = useState([]);
  const [fileSelectionError, setFileSelectionError] = useState("");
  const inputRef = useRef();
  const navigate = useNavigate();

  const [sendingStatus, setSendingStatus] = useState("");

  const [currentPage, setCurrentPage] = useState(0);

  const [loadingMessages, setLoadingMessages] = useState(false);

  const [totalPages, setTotalPages] = useState();

  const [showEmojiSelector, setShowEmojiSelector] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedMsg, setSelectedMsg] = useState("");

  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showEditGroupNameModal, setShowEditGroupNameModal] = useState(false);
  const [GroupName, setGroupName] = useState("");
  const [EditGroupId, setEditGroupId] = useState("");

  const handleEmojiSelect = useCallback((emoji) => {
    setShowEmojiSelector(false);
    setNewMsg((currentMsg) => currentMsg + emoji);
    inputRef?.current?.focus();
  }, []);

  const handleEmojiSelectionOpen = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowEmojiSelector(true);
  }, []);

  const handleEmojiSelectionClose = useCallback(() => {
    setShowEmojiSelector(false);
  }, []);

  const getMessages = useCallback(
    async (suppliedPageNumber) => {
      savedScrollPosition.current =
        chatContainerRef.current.scrollHeight -
        chatContainerRef.current.scrollTop;
      const nextPage = suppliedPageNumber ?? currentPage + 1;
      if (!suppliedPageNumber && totalPages && nextPage > totalPages) return;
      setLoadingMessages(true);
      try {
        const resp = await FetchApi(
          `${Endpoints.getChatByRoom}${chat.id}?page=${nextPage}&size=20`
        );
        setLoadingMessages(false);
        if (resp?.success) {
          // read un-read message
          const chatIndex = chats.findIndex(
            (searchElem) => searchElem.id === chat.id
          );
          const newChats = structuredClone(chats);
          if (newChats[chatIndex].unreadMsgCount)
            newChats[chatIndex].unreadMsgCount = 0;
          setChats(newChats);

          setCurrentPage(nextPage);
          setMessages((currentMessages) => [
            ...resp.data.messages.reverse(),
            ...currentMessages,
          ]);
          setTotalPages(resp.data.totalPages);
        } else {
          setMessages([]);
        }
      } catch (error) {
        setLoadingMessages(false);
        console.log(error);
      }
    },
    [chat, chats, currentPage, setChats, totalPages]
  );

  const checkScrollPosition = useCallback(() => {
    if (chatContainerRef.current.scrollTop < 20 && !loadingMessages) {
      getMessages();
    }
  }, [getMessages, loadingMessages]);

  useEffect(() => {
    if (chat !== null) {
      setCurrentPage(0);
      setTotalPages(0);
      setMessages([]);
      getMessages(1);
      inputRef?.current?.focus();
    }
    setNewMsg("");
  }, [chat]);

  const handleFileChange = async (e) => {
    // console.log(e.target.files[0]);
    setFileSelectionError("");
    // if (
    //   e.target.files[0].type.includes("image") ||
    //   e.target.files[0].type.includes("pdf") ||
    //   e.target.files[0].type.includes("doc") ||
    //   e.target.files[0].type.includes("docx")
    // ) {
    setFile({ file: e.target.files[0], type: e.target.files[0].type });
    // } else {
    //   setFileSelectionError("Please select image, pdf, doc files only");
    // }
  };

  const handleNewMsg = async (e) => {
    e.preventDefault();
    if (sendingStatus === "sending") return;
    setFileSelectionError("");
    if (newMsg || file) {
      setSendingStatus("sending");
      const formdata = new FormData();
      formdata.append("sender_id", currentUser);
      formdata.append("message", newMsg);
      if (file) formdata.append("file", file.file);
      formdata.append("chat_room_id", chat.id);

      try {
        const resp = await CommonAPi(Endpoints.newMessage, formdata);
        if (resp?.success) {
          setFileSelectionError("");
          // getUserChats();
          onSendMessage(chat.id, resp.data);
          setMessages([...messages, resp.data]);
          setNewMsg("");
          setFile("");
          fileRef.current.value = "";

          // sorting chat
          const chatIndex = chats.findIndex(
            (searchElem) => searchElem.id === resp.data.chatRoomId
          );
          if (chatIndex !== -1) {
            const newChats = structuredClone(chats);
            newChats[chatIndex].lastMessage = resp.data;
            newChats.sort((a, b) => {
              let date1 = new Date().getMilliseconds(),
                date2 = new Date().getMilliseconds();
              if (b?.lastMessage) date1 = Date.parse(b.lastMessage.createdAt);
              if (a?.lastMessage) date2 = Date.parse(a.lastMessage.createdAt);
              return date1 - date2;
            });
            setChats(newChats);
          }
          // to socket
          const arrayOfUsers = chat.chat_room_members
            .filter((searchUser) => searchUser.userId !== user?.id)
            .map((item) => item.userId);
          socket.emit("subscribe", chat.id, arrayOfUsers);
          socket.emit("message", chat.id, resp.data);

          setSendingStatus("");
        }
      } catch (error) {
        console.log(error);
        setFileSelectionError("");
        setSendingStatus("failed");
      }
    }
  };

  useEffect(() => {
    const onMessage = (data) => {
      setMessages((currentMessages) => [...currentMessages, data]);
    };
    socket.on("messages", onMessage);
  }, []);

  useEffect(() => {
    if (chat)
      if (receivedMsg != null && receivedMsg.chat_room_id === chat.id) {
        setMessages([...messages, receivedMsg]);
      }
  }, [receivedMsg]);

  // scrolling to bottom, when message changes
  useEffect(() => {
    if (chatContainerRef.current && currentPage === 1) {
      chatContainerRef.current.scrollTo(
        0,
        chatContainerRef.current.scrollHeight
      );
    }
  }, [messages]);

  // restoring chat message position
  useLayoutEffect(() => {
    if (savedScrollPosition.current)
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight - savedScrollPosition.current;
  }, [messages]);

  useLayoutEffect(() => {
    if (filePreviewBox.current) {
      filePreviewBox.current.style.top = `-${filePreviewBox.current.offsetHeight}px`;
    }
  }, [file]);

  const otherChatUserInRoom = useMemo(() => {
    if (chat) {
      if (location.pathname === "/friend/messages") {
        const searchedUser = chat.chat_room_members?.find(
          (searchUser) => searchUser.user.id !== user?.id
        );

        if (searchedUser) return searchedUser?.user;
        return null;
      }
      return chat?.user;
    }
    return null;
  }, [chat, location.pathname, user]);

  const IsGroupAdmin = useMemo(() => {
    if (chat) {
      if (location.pathname === "/friend/messages") {
        const searchedUser = chat.chat_room_members?.find(
          (searchUser) => searchUser?.user?.id === user?.id
        );

        if (searchedUser) return searchedUser;
        return null;
      }
      return chat?.user;
    }
    return null;
  }, [chat, location.pathname, user]);

  const handleDeleteGroup = async (Id) => {
    let data = {
      chatRoomId: Id,
    };
    try {
      let resp = await DeleteApi(Endpoints.GroupChatDelete, data);
      if (resp && resp.message) {
        getUserChats();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleLeaveGroup = async (Id, userId) => {
    let data = {
      chatRoomId: Id,
      userId: userId,
    };
    try {
      let resp = await DeleteApi(Endpoints.GroupChatLeave, data);
      if (resp && resp.message) {
        getUserChats();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleDeleteChat = async (Id) => {
    let data = {
      chatRoomId: Id,
    };
    try {
      let resp = await DeleteApi(Endpoints.ClearMessageChat, data);
      if (resp && resp.message) {
        getUserChats();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleClick = (e, msg) => {
    if (e.target === e.currentTarget) {
      setShow(true);
      setSelectedMsg(msg);
    }
  };

  const handlePlay = (e, msg) => {
    setShow(true);
    setSelectedMsg(msg);
  };

  const imageToRender = useMemo(() => {
    if (!chat) {
      return;
    }
    if (chat.roomName !== null) {
      const person1 = chat.chat_room_members[0];
      const person2 = chat.chat_room_members[1];

      return (
        <div className="group-chat-image-container">
          <img
            src={`${
              Endpoints.baseUrl + person1?.user?.user_profile.profileImg
            }`}
            alt=""
            width="36"
            height="36"
            className="group-chat-image"
          />
          <img
            src={`${
              Endpoints.baseUrl + person2?.user?.user_profile.profileImg
            }`}
            alt=""
            width="36"
            height="36"
            className="group-chat-image"
          />
        </div>
      );
    }
    if (otherChatUserInRoom?.user_profile?.profileImg)
      return (
        <img
          src={`${
            Endpoints.baseUrl + otherChatUserInRoom?.user_profile?.profileImg
          }`}
          alt=""
          width="48"
          height="48"
          className="rounded-circle"
        />
      );
    return (
      <img
        src="/assets/img/picture.png"
        alt=""
        width="48"
        height="48"
        className="rounded-circle"
      />
    );
  }, [chat, otherChatUserInRoom]);

  const handleOpenMember = () => {
    setShowMemberModal(true);
  };

  const handleEditGroup = (chat) => {
    console.log(chat);
    setEditGroupId(chat?.id);
    setGroupName(chat?.roomName);
    setShowEditGroupNameModal(true);
  };

  return (
    <>
      <div
        className={` ${
          location.pathname === "/friend/messages"
            ? "col-md-8 px-0 d-flex flex-column overflow-auto h-100"
            : "col-md-8 px-0 d-flex"
        }`}
      >
        {chat ? (
          <>
            <div className="p-3 border-bottom">
              <div className="d-flex align-items-center gap-3 justify-content-between">
                <div className="d-flex align-items-center">
                  <img
                    src="../assets/img/icon/arrow-left-brown.svg"
                    alt=""
                    className="d-md-none d-sm-block"
                    onClick={() => {
                      setActiveChatRoom(false);
                    }}
                    width="30px"
                  />
                  <div
                    className="position-relative"
                    style={{ marginRight: ".66rem" }}
                  >
                    {imageToRender}

                    {!chat.roomName && online && (
                      <span className="user-status">
                        <svg
                          viewBox="0 0 24 24"
                          width="16"
                          height="16"
                          stroke="#fff"
                          stroke-width="1"
                          fill="#57CB6C"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                        </svg>
                      </span>
                    )}
                  </div>
                  <div>
                    <h6 className="medium text-black fs-16">
                      {chat.roomName ? (
                        chat.roomName
                      ) : (
                        <>
                          {otherChatUserInRoom.fullName}
                          {online ? (
                            <p className="text-success fs-14">Online</p>
                          ) : (
                            <p className="text-danger fs-14">Offline</p>
                          )}
                        </>
                      )}
                    </h6>
                    {/* <h6 className="fs-12 regular text-darkgray">Active Now</h6> */}
                    <h6 className="text-brown fs-14 medium">
                      {chat.roomName
                        ? ""
                        : otherChatUserInRoom?.user_profile?.profileTagline}
                    </h6>
                  </div>
                </div>

                <div className="position-relative">
                  <a
                    href="#"
                    id="message_option"
                    data-bs-toggle="dropdown"
                    className="d-flex align-items-center justify-content-center rounded-circle bg-eee p-2"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      stroke="#616161"
                      stroke-width="2"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="css-i6dzq1"
                    >
                      <circle cx="12" cy="12" r="1"></circle>
                      <circle cx="19" cy="12" r="1"></circle>
                      <circle cx="5" cy="12" r="1"></circle>
                    </svg>
                  </a>
                  <div
                    className="dropdown-menu shadow rounded-1"
                    aria-labelledby="message_option"
                  >
                    {IsGroupAdmin?.isAdmin === true &&
                    IsGroupAdmin?.user?.id === user?.id ? (
                      <ul className="fs-14">
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              handleEditGroup(chat);
                            }}
                          >
                            <img
                              src="../assets/img/icon/chat-edit.svg"
                              width={"15"}
                              class=""
                            />{" "}
                            Edit Group Name
                          </a>
                        </li>
                        {chat.chat_room_members.length > 2 ? (
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={handleOpenMember}
                            >
                              <img
                                src="../assets/img/icon/chat-member.svg"
                                width={"15"}
                                class=""
                              />{" "}
                              View Members
                            </a>
                          </li>
                        ) : (
                          <li>
                            <Link
                              className="dropdown-item"
                              to={"/timeline/" + otherChatUserInRoom.username}
                              onClick={handleOpenMember}
                            >
                              <img
                                src="../assets/img/icon/chat-member.svg"
                                width={"15"}
                              />{" "}
                              View Profile
                            </Link>
                          </li>
                        )}
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              handleDeleteGroup(chat?.id);
                            }}
                          >
                            <img
                              src="../assets/img/icon/delete-chat.svg"
                              width={"15"}
                              class=""
                            />{" "}
                            Delete Group
                          </a>
                        </li>
                      </ul>
                    ) : (
                      <ul className="fs-14">
                        {chat.chat_room_members.length > 2 ? (
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={handleOpenMember}
                            >
                              <img
                                src="../assets/img/icon/chat-member.svg"
                                width={"15"}
                              />{" "}
                              View Members
                            </a>
                          </li>
                        ) : (
                          <li>
                            <Link
                              className="dropdown-item"
                              to={"/timeline/" + otherChatUserInRoom.username}
                              onClick={handleOpenMember}
                            >
                              <img
                                src="../assets/img/icon/chat-member.svg"
                                width={"15"}
                              />{" "}
                              View Profile
                            </Link>
                          </li>
                        )}
                        <li>
                          {chat.chat_room_members.length > 2 ? (
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                handleLeaveGroup(
                                  chat?.id,
                                  IsGroupAdmin?.user?.id
                                );
                              }}
                            >
                              <img
                                src="../assets/img/icon/leave-group.svg"
                                width={"15"}
                              />{" "}
                              Leave Group
                            </a>
                          ) : (
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                handleDeleteChat(chat?.id);
                              }}
                            >
                              <img
                                src="../assets/img/icon/delete-chat.svg"
                                width={"15"}
                              />{" "}
                              Delete Chat
                            </a>
                          )}
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="p-3 msg-listing"
              ref={chatContainerRef}
              onScroll={checkScrollPosition}
              style={
                location.pathname !== "/friend/messages"
                  ? undefined
                  : {
                      height: "unset",
                      flex: "1 1 auto",
                    }
              }
            >
              <div className="messages">
                {loadingMessages && (
                  <div className="d-flex justify-content-center my-3">
                    <div
                      class="spinner-border text-dark spinner-border-sm"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
                {messages.length > 0 &&
                  messages.map((msg, i) => (
                    <div>
                      <div>
                        <div>
                          {/* {msg.senderId === currentUser ? "" :
                      <img src="https://8innet.dignitech.com/user/profile-picture/0b764219-6951-46bf-b7fd-b806290ca421-d4b65e4d20aa51cd87620e8badf43dfa.jpg" alt="" width="40" height="40" class="rounded-circle" />
                      } */}
                        </div>
                        <div>
                          {msg.senderId === currentUser ? (
                            ""
                          ) : (
                            <small
                              style={{
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              {msg?.user?.fullName}
                            </small>
                          )}
                          <div
                            key={msg.id}
                            className={`msg-item mb-2 ${msg.senderId} ${
                              msg.senderId === currentUser && "reply-msg"
                            }`}
                            style={{
                              maxWidth: "max-content",
                              wordBreak: "break-word",
                            }}
                          >
                            <div className="pb-2">
                              {msg?.new === true ? (
                                <div className="msg-file-container">
                                  {msg?.file &&
                                    (msg?.msgType.includes("jpg") ||
                                      msg?.msgType.includes("jpeg") ||
                                      msg?.msgType.includes("png")) && (
                                      <img
                                        src={msg?.file}
                                        alt="1"
                                        // onClick={() => {
                                        //   setShow(true);
                                        //   setSelectedMsg(msg);
                                        // }}
                                      />
                                    )}
                                  {msg?.file &&
                                    !msg?.msgType.includes("jpg") &&
                                    !msg?.msgType.includes("jpeg") &&
                                    !msg?.msgType.includes("png") && (
                                      <a
                                        href={msg?.file}
                                        style={{ fontWeight: "bold" }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {
                                          //    msg?.file.substr(50)
                                          msg.msgType.endsWith(".pdf") && (
                                            <img
                                              src="/assets/img/icon/pdf_icon.png"
                                              alt="chat-icon"
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                              }}
                                            />
                                          )
                                        }

                                        {
                                          //    msg?.file.substr(50)
                                          (msg.msgType.endsWith(".doc") ||
                                            msg.msgType.endsWith(".docx")) && (
                                            <img
                                              src="/assets/img/icon/word_icon.png"
                                              alt="chat-icon"
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                              }}
                                            />
                                          )
                                        }

                                        {
                                          //    msg?.file.substr(50)
                                          msg.msgType.endsWith(".xlsx") && (
                                            <img
                                              src="/assets/img/icon/excel_icon.png"
                                              alt="chat-icon"
                                              style={{
                                                width: "100%",
                                                height: "100%",
                                              }}
                                            />
                                          )
                                        }
                                      </a>
                                    )}
                                </div>
                              ) : (
                                <div
                                  style={{ position: "relative" }}
                                  className="msg-file-container"
                                >
                                  {msg?.course_message_media?.file &&
                                    (msg?.course_message_media?.file?.includes(
                                      "jpg"
                                    ) ||
                                      msg?.course_message_media?.file?.includes(
                                        "jpeg"
                                      ) ||
                                      msg?.course_message_media?.file?.includes(
                                        "png"
                                      )) && (
                                      <img
                                        onClick={() => {
                                          setShow(true);
                                          setSelectedMsg(msg);
                                        }}
                                        src={
                                          Endpoints?.baseUrl +
                                          msg?.course_message_media?.file
                                        }
                                        alt="2"
                                      />
                                    )}
                                  {msg?.course_message_media?.file &&
                                    msg?.course_message_media?.file?.includes(
                                      "mp4"
                                    ) && (
                                      <div>
                                        <video
                                          style={{
                                            height: "8rem",
                                            width: "8rem",
                                            borderRadius: "8px",
                                            objectFit: "cover",
                                          }}
                                          src={
                                            Endpoints?.baseUrl +
                                            msg?.course_message_media?.file
                                          }
                                          alt=""
                                          onClick={() => {
                                            setShow(true);
                                            setSelectedMsg(msg);
                                          }}
                                        ></video>
                                        <div
                                          style={{
                                            position: "absolute",
                                            right: "50%",
                                            top: "50%",
                                          }}
                                        >
                                          <IoPlayCircle
                                            onClick={() => {
                                              setShow(true);
                                              setSelectedMsg(msg);
                                            }}
                                            className="text-white cursor-pointer"
                                            size={25}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  {msg?.course_message_media?.file &&
                                    !msg?.course_message_media?.file?.includes(
                                      "jpg"
                                    ) &&
                                    !msg?.course_message_media?.file?.includes(
                                      "jpeg"
                                    ) &&
                                    !msg?.course_message_media?.file?.includes(
                                      "png"
                                    ) && (
                                      <a
                                        href={
                                          Endpoints.baseUrl +
                                          msg?.course_message_media?.file
                                        }
                                        style={{ fontWeight: "bold" }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {msg?.course_message_media?.file.endsWith(
                                          ".pdf"
                                        ) && (
                                          <img
                                            src="/assets/img/icon/pdf_icon.png"
                                            alt="chat-icon"
                                            style={{
                                              width: "60%",
                                              borderRadius: "0",
                                              height: "100%",
                                            }}
                                          />
                                        )}

                                        {(msg?.course_message_media?.file.endsWith(
                                          ".doc"
                                        ) ||
                                          msg?.course_message_media?.file.endsWith(
                                            ".docx"
                                          )) && (
                                          <img
                                            src="/assets/img/icon/word_icon.png"
                                            alt="chat-icon"
                                            style={{
                                              width: "60%",
                                              borderRadius: "0",
                                              height: "100%",
                                            }}
                                          />
                                        )}

                                        {msg?.course_message_media?.file.endsWith(
                                          ".xlsx"
                                        ) && (
                                          <img
                                            src="/assets/img/icon/excel_icon.png"
                                            alt="chat-icon"
                                            style={{
                                              width: "60%",
                                              borderRadius: "0",
                                              height: "100%",
                                            }}
                                          />
                                        )}
                                      </a>
                                    )}
                                </div>
                              )}

                              <p>{msg.message} </p>
                            </div>
                            <div className="text-end">
                              <h6 className="fs-12 text-darkgray regular">
                                <img
                                  src="/assets/img/icon/icon-date-time.svg"
                                  alt=""
                                />
                                {" " + timeSince(new Date(msg.createdAt))} ago
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {show && (
                  <ImagePreviewModel
                    show={show}
                    onHide={() => setShow(false)}
                    type={"friend"}
                    message={selectedMsg}
                  />
                )}
                {sendingStatus === "failed" && (
                  <p
                    style={{
                      color: "red",
                      fontSize: 11,
                      fontWeight: 500,
                      margin: "15px 0 0px 0",
                      textAlign: "center",
                    }}
                  >
                    Failed to send new message
                  </p>
                )}
              </div>
            </div>

            <form
              className="msg-add-form d-flex align-items-center ps-4 position-relative"
              onSubmit={handleNewMsg}
              encType="multipart/form-data"
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                <input
                  // accept=".pdf, .doc, .docx, .xlsx, .webp, .jpg, .jpeg, .png"
                  type="file"
                  name="file"
                  id="file"
                  className="d-none"
                  ref={fileRef}
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="file"
                  style={{ cursor: "pointer" }}
                  id="message_option"
                  // data-bs-toggle="dropdown"
                  className="d-flex align-items-center justify-content-center rounded-circle bg-eee flex-fill"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/img/icon/file icon.svg`}
                    alt=""
                    style={{ height: 16, width: 16, maxWidth: "unset" }}
                  />
                </label>
                <button
                  type="button"
                  ref={emojiButtonRef}
                  style={{
                    border: "none",
                    background: "transparent",
                    padding: 0,
                    height: 16,
                    width: 16,
                    display: "flex",
                  }}
                  onClick={handleEmojiSelectionOpen}
                >
                  <img
                    class="post-image flex-fill"
                    src={`${process.env.PUBLIC_URL}/assets/img/icon/emoji_icon.png`}
                    alt=""
                    style={{ height: 16, width: 16 }}
                  />
                </button>
              </div>

              {file && (
                <div className="file-preview-box" ref={filePreviewBox}>
                  <div className="svg-container">
                    <svg
                      height="15px"
                      width="15px"
                      id="Layer_1"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (sendingStatus !== "sending") {
                          setFile("");
                          fileRef.current.value = "";
                        }
                      }}
                      version="1.1"
                      viewBox="0 0 512 512"
                    >
                      <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                    </svg>
                  </div>
                  <div className="preview-container">
                    {file.type.includes("image") && (
                      <img
                        src={URL.createObjectURL(file.file)}
                        alt="img-preview"
                      />
                    )}
                    {file.type.includes("video") && (
                      <video
                        src={URL.createObjectURL(file.file)}
                        controls
                        style={{
                          width: "200px",
                        }}
                      ></video>
                    )}
                    {(file.file.name.endsWith(".doc") ||
                      file.file.name.endsWith(".docx")) && (
                      <img
                        src={`/assets/img/icon/word_icon.png`}
                        className="chat-file-icons"
                        alt="img-preview"
                      />
                    )}
                    {file.file.name.endsWith(".xlsx") && (
                      <img
                        src={`/assets/img/icon/excel_icon.png`}
                        className="chat-file-icons"
                        alt="img-preview"
                      />
                    )}

                    {file.file.name.endsWith(".pdf") && (
                      <img
                        src="/assets/img/icon/pdf_icon.png"
                        className="chat-file-icons"
                        alt="img-preview"
                      />
                    )}
                    {!file.type.includes("image") &&
                      !file.type.includes("video") && (
                        <a
                          href={URL.createObjectURL(file.file)}
                          style={{ fontWeight: "bold" }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {file.file.name}
                        </a>
                      )}
                    {fileSelectionError && (
                      <span className="text-danger fs-12">
                        {fileSelectionError}
                      </span>
                    )}
                  </div>
                </div>
              )}
              {fileSelectionError && (
                <span
                  className="text-danger fs-12"
                  style={{
                    position: "absolute",
                    fontWeight: "600",
                    bottom: "2.8rem",
                  }}
                >
                  {fileSelectionError}
                </span>
              )}

              <input
                disabled={sendingStatus === "sending"}
                type="text"
                ref={inputRef}
                value={newMsg}
                onChange={(e) => {
                  setNewMsg(e.target.value);
                }}
                placeholder="Write message here..."
                className="fs-14 py-3 ps-2 bg-transparent"
              />

              <button
                type="submit"
                className="d-flex gap-2 btn fs-14 radius-0 py-3 px-5 justify-content-center"
                style={{ minWidth: 144 }}
              >
                {sendingStatus === "sending" ? (
                  <div
                    class="spinner-border text-light spinner-border-sm"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>
                    <img
                      src="/assets/img/icon/icon-send-message.svg"
                      alt="msg-svg"
                    />{" "}
                    Send
                  </>
                )}
              </button>
            </form>
          </>
        ) : (
          <div className="h-100 w-100 d-flex justify-content-center pt-2">
            <h5>Tap on user to chat</h5>
          </div>
        )}
        {showEmojiSelector && (
          <EmojiPickerFloatingWindow
            anchorRef={emojiButtonRef}
            onSelect={handleEmojiSelect}
            onClose={handleEmojiSelectionClose}
          />
        )}
        {showMemberModal && (
          <ViewMember
            {...{
              member: chat.chat_room_members,
              setShowMemberModal: setShowMemberModal,
            }}
          />
        )}

        {showEditGroupNameModal && (
          <EditGroupName
            {...{
              EditGroupId: EditGroupId,
              setEditGroupId: setEditGroupId,
              GroupName: GroupName,
              setGroupName: setGroupName,
              setShowEditGroupNameModal: setShowEditGroupNameModal,
              onSuccess: getUserChats,
            }}
          />
        )}
      </div>
    </>
  );
};

export default FriendChatBox;
