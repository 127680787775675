import React, { useState } from 'react'
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CommonAPi } from "../../API/CommonApi";
import Swal from "sweetalert2";
const $ = window.jQuery;
export default function JobModel({jobId, onSuccess}) {
    const [FullName, setFullName] = useState('');
    const [Email, setEmail] = useState('');
    const [Mobile, setMobile] = useState('');
    const [resume, setResume] = useState('');
    const [resumeName, setResumeName] = useState('');
    const [Message, setMessage] = useState('');
    const [status, setStatus] = useState(false);
    const navigate = useNavigate();

    const [FullNameError, setFullNameError] = useState('');
    const [EmailError, setEmailError] = useState('');
    const [MobileError, setMobileError] = useState('');
    const [resumeError, setResumeError] = useState('');
    const [MessageError, setMessageError] = useState('');

    const handleUploadResume = (e) => {
        setResume(e.target.files[0]);
        setResumeName(e.target.files[0]?.name);
    };

    const handleSubmit  = async(e) =>{
        e.preventDefault();
        

        if(!FullName){
            setFullNameError('Full name is required');
        }else{
            setFullNameError('');
        }
        if(!Email){
            setEmailError('Email is required');
        }else{
            setEmailError('');
        }
        if(!Mobile){
            setMobileError('Mobile is required');
        }else{
            setMobileError('');
        }
        if(!resume){
            setResumeError('Resume is required');
        }else{
            setResumeError('');
        }
        if(!Message){
            setMessageError('Message is required');
        }else{
            setMessageError('');
        }

      
        if(FullName && Email && Mobile && resume && Message){
        setStatus(true);
        const formData = new FormData();
        formData.append("username", FullName);
        formData.append("email", Email);
        formData.append("mobileNumber", Mobile);
        formData.append("resumefile", resume);
        formData.append("message", Message);
        formData.append("jobId", jobId);
        try {
            let resp = await CommonAPi(Endpoints.JobApply, formData);
            if (resp && resp.success === true) {
                setFullName("");
                setMobile("");
                setResume("");
                setMessage("");
                setEmail("");
               
                $('#applyjobs').modal('hide');
                setStatus(false);
                onSuccess(true);
                ToastMessage.Success(resp.message);
            }
        }
        catch (e) {

            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {

                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }


            }
        }
        }
    };

    const handleBackButtonClick = () => {
        if(FullName || Email || Mobile || resume || Message
            ||MessageError ||resumeError ||MobileError ||EmailError ||FullNameError
            ){
          Swal.fire({
            text: "You have unsaved changes, do you still want to exit?",
            icon: "question",
            customClass: {
              confirmButton: "btn px-3 mx-2",
              cancelButton: "btn btn-br-blue px-3",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Yes, discard it!",
          }).then((result) => {
            console.log(result);
            if (result.isConfirmed) {
                setFullName('');
                setEmail('');
                setMobile('');
                setResume('');
                setMessage('');
                setMessageError('');
                setResumeError('');
                setMobileError('');
                setEmailError('');
                setFullNameError('');
            }
          });
        }
      };

    
    return (
        <div class="modal" id="applyjobs" tabindex="-1">
            <div class="modal-dialog modal-lg px-lg-5 modal-dialog-centered modal-dialog-scrollable modal-md">
                <div class="modal-content">
                    <button type="button" class="btn-close ms-auto p-3 position-absolute end-0 " data-bs-dismiss="modal" aria-label="Close" onClick={handleBackButtonClick}></button>
                    <div class="text-center py-4 border-bottom">
                        <h5 class="modal-title text-center fs-20 medium text-black">Apply for this job!</h5>

                    </div>
                    <div class="modal-body">

                        <form action="" onSubmit={handleSubmit} class="site-form">
                            <span class="text-darkgray medium fs-12 pb-3 d-block">* All fields are required.</span>
                            <div class="form-field mb-3">
                                <label for="" class="pb-1 fs-14 medium">Full Name *</label>
                                <input
                                    type="text"
                                    placeholder="Add your full name *"
                                    class="fs-14 border rounded-3 py-2 px-3 w-100"
                                    value={FullName}
                                    onChange={(e) => {
                                        setFullName(e.target.value)
                                    }}
                                    
                                />
                                {FullNameError ? 
                                <div className="field-error fs-12 text-red">
                                    {FullNameError}
                                </div>                      
                                : ""}
                            </div>
                            <div class="form-field mb-3">
                                <label for="" class="pb-1 fs-14 medium">Email Id  *</label>
                                <input
                                    type="text"
                                    placeholder="Your email id *"
                                    class="fs-14 border rounded-3 py-2 px-3 w-100"
                                    value={Email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                    
                                />
                                {EmailError ? 
                                <div className="field-error fs-12 text-red">
                                    {EmailError}
                                </div>                      
                                : ""}
                            </div>
                            <div class="form-field mb-3">
                                <label for="" class="pb-1 fs-14 medium">Mobile No. *</label>
                                <input
                                    type="text"
                                    placeholder="Your mobile number *"
                                    class="fs-14 border rounded-3 py-2 px-3 w-100"
                                    value={Mobile}
                                    onChange={(e) => {
                                        setMobile(e.target.value)
                                    }}
                                    
                                />
                                {MobileError ? 
                                <div className="field-error fs-12 text-red">
                                    {MobileError}
                                </div>                      
                                : ""}
                            </div>
                            <div class="form-field mb-3">
                                <label for="" class="pb-1 fs-14 medium">Upload Resume *</label>
                                <input type="file" name=""  id="logo_upload" className="d-none" onChange={(e) => {
                                    handleUploadResume(e)
                                }} />
                                            
                                <label for="logo_upload" className=" rounded-2 text-center py-4 px-2 w-100 com-logo">
                                    <img src="../assets/img/icon/icon-file-upload.svg" width="25" alt="" />
                                    <span className="d-block fs-14 medium pt-2 pb-1 text-black">Upload CV(doc, docx, pdf)</span>
                                    <span className="d-block fs-14 ">Drag and drop a file or {" "}
                                        <span  className="text-black text-decoration-underline">browse a file</span>
                                    </span>
                                </label>
                                {resume ? 
                                    <Link to={URL.createObjectURL(resume)} target="_blank" style={{color:"var(--bs-link-color)"}}>
                                        <div className="fs-12">
                                            <h6>{resumeName}</h6> 
                                        </div>
                                    </Link>
                                : "" }
                                {resumeError ? 
                                <div className="field-error fs-12 text-red">
                                    {resumeError}
                                </div>                      
                                : ""}
                            </div>
                            <div class="form-field mb-3">
                                <label for="" class="pb-1 fs-14 medium">Message *</label>
                                <textarea
                                    value={Message}
                                    cols="30"
                                    rows="5"
                                    placeholder="add about yourself *"
                                    class="fs-14 border rounded-3 py-2 px-3 w-100 textarea1"
                                    onChange={(e) => {
                                        setMessage(e.target.value)
                                    }}
                                    
                                ></textarea>
                                {MessageError ? 
                                <div className="field-error fs-12 text-red">
                                    {MessageError}
                                </div>                      
                                : ""}
                                 <div id="count1" className="fs-12 text-darkgray text-end">
                                    <span id="current1">{Message?.length}</span>
                                    <span id="maximum1">/ 2000  </span>
                                </div>
                                
                            </div>
                            
                            <input type="submit" disabled={status ? true : false} value="Send" class="btn fs-14 medium px-5" />
                            
                          
                            {/* <a href="#" class="btn fs-14 medium px-5">Post Job</a> */}

                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}
