import React from "react";
import CustomHeader from "../CustomHeader";
// import CustomHeader from './CustomHeader';

function Container(props) {
  return (
    <div>
      {props.header ? (
        <CustomHeader
          setPostPage={props.setPostPage}
          postPage={props.postPage}
          getUserPosts={props.getUserPosts}
          setTimelinePosts={props.setTimelinePosts}
          containerRef={props.containerRef}
          fromMainfeed={props.fromMainfeed}
          setLoading={props.setLoading}
          loading={props.loading}
          refreshCartcount={props.refreshCartcount}
          refreshNotificationcount={props.refreshNotificationcount}
        />
      ) : null}

      {props.children}
    </div>
  );
}

export default Container;
