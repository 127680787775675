import React, { useEffect, useState, useCallback, useContext } from "react";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from "../../API/FetchApi";
import { Link, useNavigate } from "react-router-dom";
import { StickyChatContext } from "../../Components/StickyChatWrapper";
import IsOnline from "../../Components/IsOnline";
export default function Friend() {
  const userId = localStorage.getItem("userId");
  const username = localStorage.getItem("userName");
  const [friendData, setFriendData] = useState([]);
  const Navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const { handleAddChat, onlineUsers, chats } = useContext(StickyChatContext);
  // console.log("chat", chats);
  const checkOnlineStatus = useCallback(
    (UserId) => {
      if (UserId) {
        const isOnline =
          !!UserId && onlineUsers.find((user) => user.userId === UserId);
        return !!isOnline;
      }
    },
    [onlineUsers, user]
  );

  useEffect(() => {
    getFriend();
  }, []);
  const getFriend = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetAllFriend + "?id=" + userId);
      if (resp && resp.success === true) {
        setFriendData(resp?.data?.friends);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  return (
    <>
      {friendData.length > 0 ? (
        <div className="sidebar-widget mt-4">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="medium">My Friends</h4>
            <Link
              to={"/friend-profile/" + username}
              className="text-brown fs-12 medium"
            >
              {/* <Link to={"/friend-profile/" + username} className="text-brown fs-12 medium"> */}
              See all{" "}
              <img
                src="../assets/img/icon/icon-right-arrow-brown.svg"
                class="ps-1 text-brown"
                alt=""
              />
            </Link>
          </div>

          <div className="pt-3 mt-2 border-top">
            <ul>
              {friendData.length > 0 ? (
                friendData.map((item, index) => {
                  if (index > 4) {
                    return null;
                  }
                  return (
                    <li className="d-flex align-items-center pb-3" key={index}>
                      <div className="position-relative">
                        {item?.user_profile?.profileImg === null ? (
                          <Link to={"/timeline/" + item?.username}>
                            <img
                              src="../assets/img/picture.png"
                              alt=""
                              width="48"
                              height="48"
                              class="rounded-circle"
                            />
                          </Link>
                        ) : (
                          <Link to={"/timeline/" + item?.username}>
                            <img
                              src={
                                Endpoints.baseUrl +
                                item?.user_profile?.profileImg
                              }
                              alt=""
                              width="58"
                              height="48"
                              class="rounded-circle"
                            />
                          </Link>
                        )}
                        <span className="user-status">
                          {checkOnlineStatus(item?.id) && <IsOnline />}
                        </span>
                      </div>
                      <div className="ps-2 w-100 d-flex align-items-center justify-content-between ">
                        <div className="">
                          <Link to={"/timeline/" + item?.username}>
                            <h6 className="medium text-black">
                              {item?.fullName}
                            </h6>
                          </Link>
                          <h6 className="fs-12 regular text-darkgray pt-1">
                            {" "}
                            {item?.mutualFriends} mutual friends
                          </h6>
                        </div>

                        {/* <div className="position-relative">
                                                            <a
                                                                href="#"
                                                                aria-haspopup="true"
                                                                aria-label="firends1"
                                                                data-bs-toggle="dropdown"
                                                                className="d-flex align-items-center justify-content-center rounded-circle bg-eee p-2"
                                                            >
                                                                <svg
                                                                    viewBox="0 0 24 24"
                                                                    width="20"
                                                                    height="20"
                                                                    stroke="#000"
                                                                    strokeWidth="2"
                                                                    fill="none"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    className="css-i6dzq1"
                                                                >
                                                                    <circle cx="12" cy="12" r="1"></circle>
                                                                    <circle cx="19" cy="12" r="1"></circle>
                                                                    <circle cx="5" cy="12" r="1"></circle>
                                                                </svg>
                                                            </a>

                                                            <ul
                                                                className="popup-menu text-start shadow rounded-1"
                                                                role="menu"
                                                                id="firends1"
                                                                aria-hidden="true"
                                                                tabIndex="-1"
                                                            >
                                                                <li>
                                                                    <a className="dropdown-item" href="#">
                                                                        <svg
                                                                            viewBox="0 0 24 24"
                                                                            width="14"
                                                                            height="14"
                                                                            stroke="currentColor"
                                                                            strokeWidth="2"
                                                                            fill="none"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            className="css-i6dzq1"
                                                                        >
                                                                            <polyline points="3 6 5 6 21 6"></polyline>
                                                                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                                            <line
                                                                                x1="10"
                                                                                y1="11"
                                                                                x2="10"
                                                                                y2="17"
                                                                            ></line>
                                                                            <line
                                                                                x1="14"
                                                                                y1="11"
                                                                                x2="14"
                                                                                y2="17"
                                                                            ></line>
                                                                        </svg>
                                                                        Delete
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a className="dropdown-item" href="#">
                                                                        <svg
                                                                            viewBox="0 0 24 24"
                                                                            width="14"
                                                                            height="14"
                                                                            stroke="currentColor"
                                                                            strokeWidth="2"
                                                                            fill="none"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            className="css-i6dzq1"
                                                                        >
                                                                            <circle cx="12" cy="12" r="10"></circle>
                                                                            <line
                                                                                x1="4.93"
                                                                                y1="4.93"
                                                                                x2="19.07"
                                                                                y2="19.07"
                                                                            ></line>
                                                                        </svg>
                                                                        Restricted
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div> */}
                      </div>
                    </li>
                  );
                })
              ) : (
                <li className="d-flex align-items-center pb-3">
                  No any active friend
                </li>
              )}
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
