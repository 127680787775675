import React, { useState, useEffect } from "react";
import { Endpoints } from "../../../API/Endpoints";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import { FetchApi } from "../../../API/FetchApi";
import ToastMessage from "../../../Utils/ToastMessage";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function CoursesPreview() {
  const param = useParams();
  const [courseDetails, setCourseDetails] = useState([]);
  const [activeNavigation, setActiveNavigation] = useState("objective");
  const Navigate = useNavigate();

  const getCourseDetails = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetCoursesPreview + "/" + param?.id);
      if (resp && resp.success === true) {
        setCourseDetails([...courseDetails, resp.data?.course]);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data?.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data?.message);
        }
      }
    }
  };

  useEffect(() => {
    getCourseDetails();
  }, []);

  function printTime(seconds) {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const remainingSeconds = seconds % 60;
    const parts = [];

    if (days > 0) {
      parts.push(`${days} day${days > 1 ? "s" : ""}`);
    }
    if (hours > 0) {
      parts.push(`${hours} hr${hours > 1 ? "s" : ""}`);
    }
    if (minutes > 0) {
      parts.push(`${minutes} min${minutes > 1 ? "s" : ""}`);
    }
    if (remainingSeconds > 0) {
      parts.push(`${remainingSeconds} sec${remainingSeconds > 1 ? "s" : ""}`);
    }

    if (parts.length === 0) {
      return "0 sec";
    } else {
      return parts.join(", ");
    }
  }

  return (
    <Container header>
      <Helmet>
        <title>Course Preview</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />

          {courseDetails.map((data, i) => {
            return (
              <div className="container py-3" key={i}>
                {/* <div>
                                    <Link to={"/update-courses-basic-information" + '/' + param.id} className="btn btn-br-blue ms-3 py-2">Back Edit course</Link>
                                </div>
                                <br /> */}

                <div className="bg-white rounded-10 box-shadow p-3 mb-3">
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <div className="feed-video position-relative">
                        {/* <img src={Endpoints.baseUrl + data?.thumbnail} alt="" width="48" height="48" className="w-100 h-100" /> */}
                        <img
                          src={Endpoints.baseUrl + data?.thumbnail}
                          className="play-video w-100 h-100 align-items-center justify-content-center top-0"
                        />
                        <img
                          src="../assets/img/icon/icon-play-video4.svg"
                          style={{
                            position: "absolute",
                            zIndex: 10,
                            left: "40%",
                            top: "40%",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <h3
                        className="medium pb-3"
                        style={{
                          wordBreak: "break-all",
                        }}
                      >
                        {data?.title}
                      </h3>
                      <h5
                        style={{
                          wordBreak: "break-all",
                        }}
                      >
                        {data?.subtitle}
                      </h5>

                      <h6 className="regular  fs-18">
                        Created by{" "}
                        <span className="text-brown">
                          {data?.creatorId?.fullName}
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="bg-white rounded-10 box-shadow pt-3 px-2 mb-3">
                  <ul className="course-dashboard-menu profile-menu d-flex flex-wrap justify-content-between">
                    <li
                      className={
                        activeNavigation === "objective" ? "active" : ""
                      }
                    >
                      <Link
                        onClick={() => {
                          setActiveNavigation("objective");
                        }}
                      >
                        What You’ll Learn
                      </Link>
                    </li>

                    <li
                      className={
                        activeNavigation === "description" ? "active" : ""
                      }
                    >
                      <Link
                        onClick={() => {
                          setActiveNavigation("description");
                        }}
                      >
                        Description
                      </Link>
                    </li>

                    <li
                      className={
                        activeNavigation === "audience" ? "active" : ""
                      }
                    >
                      <Link
                        onClick={() => {
                          setActiveNavigation("audience");
                        }}
                      >
                        Who This Course For
                      </Link>
                    </li>

                    <li
                      className={
                        activeNavigation === "requirement" ? "active" : ""
                      }
                    >
                      <Link
                        onClick={() => {
                          setActiveNavigation("requirement");
                        }}
                      >
                        Requirements
                      </Link>
                    </li>

                    <li
                      className={activeNavigation === "content" ? "active" : ""}
                    >
                      <Link
                        onClick={() => {
                          setActiveNavigation("content");
                        }}
                      >
                        Course Content
                      </Link>
                    </li>

                    <li
                      className={
                        activeNavigation === "instructor" ? "active" : ""
                      }
                    >
                      <Link
                        onClick={() => {
                          setActiveNavigation("instructor");
                        }}
                      >
                        Instructor
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="row">
                  <div className="col-md-9">
                    {activeNavigation === "objective" ? (
                      <div
                        className="bg-white rounded-15 py-3 px-3 mb-3"
                        id="learn"
                      >
                        <h4 className="pb-3 medium">What You’ll Learn</h4>
                        <div className="row listing-arrow">
                          {data?.objective !== null &&
                            data?.objective.map((item, i) => {
                              return (
                                <>
                                  {i % 2 === 0 ? (
                                    <div className="col-md-6 fs-14">
                                      <ul key={i}>
                                        <li className="d-flex gap-2 align-items-start pb-3">
                                          <img
                                            src="../assets/img/icon/icon-check-round-sm.svg"
                                            alt=""
                                          />
                                          {item}
                                        </li>
                                      </ul>
                                    </div>
                                  ) : (
                                    <div className="col-md-6 fs-14">
                                      <ul key={i}>
                                        <li className="d-flex gap-2 align-items-start pb-3">
                                          <img
                                            src="../assets/img/icon/icon-check-round-sm.svg"
                                            alt=""
                                          />
                                          {item}
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {activeNavigation === "description" ? (
                      <div
                        className="bg-white rounded-15 py-3 px-3 mb-3"
                        id="description"
                      >
                        <h4 className="pb-3 medium">Description</h4>
                        <div className="fs-14">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data?.description,
                            }}
                          ></p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {activeNavigation === "audience" ? (
                      <div
                        className="bg-white rounded-15 py-3 px-3 mb-3"
                        id="audience"
                      >
                        <h4 className="pb-3 medium">Who This Course For</h4>
                        <div className="row fs-14 course-listing">
                          {data?.audience !== null &&
                            data?.audience.map((item, i) => {
                              return (
                                <>
                                  {i % 2 === 0 ? (
                                    <div className="col-md-6">
                                      <ul key={i}>
                                        <li>{item}</li>
                                      </ul>
                                    </div>
                                  ) : (
                                    <div className="col-md-6">
                                      <ul key={i}>
                                        <li>{item}</li>
                                      </ul>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {activeNavigation === "requirement" ? (
                      <div
                        className="bg-white rounded-15 py-3 px-3 mb-3"
                        id="requirement"
                      >
                        <h4 className="pb-3">Requirements</h4>
                        <div className="row fs-14 course-listing">
                          {data?.audience !== null &&
                            data?.requirement.map((item, i) => {
                              return (
                                <>
                                  {i % 2 === 0 ? (
                                    <div className="col-md-6">
                                      <ul key={i}>
                                        <li>{item}</li>
                                      </ul>
                                    </div>
                                  ) : (
                                    <div className="col-md-6">
                                      <ul key={i}>
                                        <li>{item}</li>
                                      </ul>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {activeNavigation === "content" ? (
                      <div
                        className="course-content-list bg-white rounded-15 py-3 px-3 mb-3"
                        id="content"
                      >
                        <div className="d-md-flex align-items-center justify-content-between pb-3">
                          <div className="pb-md-0 pb-2">
                            <h4 className="medium">Course Content</h4>
                          </div>
                          <div className="d-flex">
                            <h6 className="fs-14 me-3">
                              <img
                                src="../assets/img/icon/folder.svg"
                                className="me-2"
                                alt=""
                              />
                              {data?.totalSections} Sections
                            </h6>
                            <h6 className="fs-14 me-3">
                              <img
                                src="../assets/img/icon/play.svg"
                                className="me-2"
                                alt=""
                              />
                              {data?.totalLectures.length} Total Lectures
                            </h6>
                            <h6 className="fs-14 me-3">
                              <img
                                src="../assets/img/icon/time.svg"
                                className="me-2"
                                alt=""
                              />
                              {printTime(data?.totalTimeLength)}
                            </h6>
                          </div>
                        </div>
                        {data?.course_sections !== null &&
                          data?.course_sections.map((section, index) => {
                            return (
                              <div className="course-content-list">
                                <div id="accordion">
                                  <div className="card border-0 border-bottom rounded-0">
                                    <div
                                      key={index}
                                      className="card-header px-0 d-md-flex justify-content-between bg-white border-0 py-3"
                                      id={"heading" + index}
                                    >
                                      <div>
                                        <h4
                                          className="mb-0 semibold"
                                          data-bs-toggle="collapse"
                                          data-bs-target={"#collapse" + index}
                                          aria-expanded="true"
                                          aria-controls={"collapse" + index}
                                        >
                                          {section.sectionTitle}
                                        </h4>
                                      </div>
                                      <div className="d-flex">
                                        <h6 className="fs-14 me-3">
                                          <img
                                            src="../assets/img/icon/play.svg"
                                            className="me-2"
                                            alt=""
                                          />
                                          {section.totalLectures} Lectures
                                        </h6>
                                        <h6 className="fs-14 ">
                                          <img
                                            src="../assets/img/icon/time.svg"
                                            className="me-2"
                                            alt=""
                                          />
                                          {printTime(section.totalTimeLength)}
                                        </h6>
                                      </div>
                                    </div>
                                    {section.course_lectures !== null &&
                                      section.course_lectures.map(
                                        (lecture, i) => {
                                          return (
                                            <div
                                              id={"collapse" + index}
                                              className="collapse show"
                                              aria-labelledby={
                                                "heading" + index
                                              }
                                              data-parent="#accordion"
                                            >
                                              <div className="card-body pe-0 pt-md-0 ps-md-4 text-darkgray">
                                                {lecture.lectureMedia !==
                                                  null &&
                                                lecture.lectureMedia.includes(
                                                  ".mp4"
                                                ) ? (
                                                  <div
                                                    className="d-flex align-items-center justify-content-between pb-md-3 pb-2"
                                                    key={i}
                                                  >
                                                    <h6 className="fs-14 me-3 text-darkgray">
                                                      <img
                                                        src="../assets/img/icon/gray-play.svg"
                                                        className="me-2"
                                                        alt=""
                                                      />
                                                      {lecture.lectureName}
                                                    </h6>
                                                    <h4 className="fs-14 text-darkgray">
                                                      {printTime(
                                                        lecture.totalTimeLength
                                                      )}
                                                    </h4>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="d-flex align-items-center justify-content-between pb-md-3 pb-2"
                                                    key={i}
                                                  >
                                                    <h6 className="fs-14 me-3">
                                                      <img
                                                        src="../assets/img/icon/gray-d.svg"
                                                        className="me-2"
                                                        alt=""
                                                      />
                                                      {lecture.lectureName}
                                                    </h6>
                                                    <h4 className="fs-14">
                                                      {printTime(
                                                        lecture.totalTimeLength
                                                      )}
                                                    </h4>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      ""
                    )}

                    {activeNavigation === "instructor" ? (
                      <div
                        className="bg-white rounded-15 py-3 px-3 mb-3"
                        id="instructor"
                      >
                        <h4 className="medium pb-3">Instructor</h4>
                        {data?.instructors.map((instruct, i) => {
                          return (
                            <div
                              className={
                                data?.instructors.length === i + 1
                                  ? "row pb-3"
                                  : "row  border-bottom pb-3"
                              }
                            >
                              <div className="col-md-2">
                                {instruct.user_profile === null ? (
                                  <img
                                    src="../assets/img/instuctor-img1.png"
                                    width="118"
                                    height="118"
                                    className="rounded-circle"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={
                                      Endpoints.baseUrl +
                                      instruct.user_profile.profileImg
                                    }
                                    width="118"
                                    height="118"
                                    className="rounded-circle"
                                    alt=""
                                  />
                                )}
                              </div>
                              <div className="col-md-10">
                                <h4 className="pb-2 semibold">
                                  {instruct.fullName}
                                </h4>
                                <h5 className="fs-14 text-darkgray pb-2">
                                  {instruct.user_profile.profileTagline}
                                </h5>
                                <div className="d-md-flex align-items-center justify-content-between pb-2">
                                  <h4 className="fs-14 regular">
                                    <img
                                      src="../assets/img/icon/star.svg"
                                      className="me-2"
                                      alt=""
                                    />{" "}
                                    {instruct?.instructor_details
                                      ?.overallRating === null
                                      ? 0
                                      : instruct?.instructor_details?.overallRating?.toFixed(
                                          2
                                        )}{" "}
                                    Instructor Rating
                                  </h4>
                                  <h4 className="fs-14 regular">
                                    <img
                                      src="../assets/img/icon/review.svg"
                                      className="me-2"
                                      alt=""
                                    />{" "}
                                    {instruct.instructor_details.totalCourses}{" "}
                                    Reviews
                                  </h4>
                                  <h4 className="fs-14 regular">
                                    <img
                                      src="../assets/img/icon/students.svg"
                                      className="me-2"
                                      alt=""
                                    />{" "}
                                    {instruct.instructor_details.totalReviews}{" "}
                                    Students
                                  </h4>
                                  <h4 className="fs-14 regular">
                                    <img
                                      src="../assets/img/icon/course-icon.svg"
                                      className="me-2"
                                      alt=""
                                    />{" "}
                                    {instruct.instructor_details.totalStudents}{" "}
                                    Courses
                                  </h4>
                                </div>
                                <p className="fs-14 mb-0 pb-2 text-darkgray">
                                  {instruct.user_profile?.aboutMe}
                                </p>
                                <Link
                                  to={
                                    "/instructor-profile/" + instruct.username
                                  }
                                  className="text-blue fs-16 semibold"
                                >
                                  View Profile
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-3">
                    <div className="box-shadow  rounded-15 bg-white">
                      {data?.courseType === "Paid" ? (
                        <div class="d-flex justify-content-between align-items-center pb-2 p-3 border-bottom">
                          <div class="d-flex gap-1">
                            <h4 class="text-blue medium fs-18">
                              ₹ {data?.discountedPrice}
                            </h4>
                            <h4 class="medium text-decoration-line-through text-lightgray fs-18 pe-1">
                              ₹ {data?.price}
                            </h4>
                          </div>
                          <div>
                            <span class="bg-lightbrown text-brown fs-10 medium py-1 px-2 rounded-pill">
                              {data?.discountPercent?.toFixed(2)}% off
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div class="d-flex justify-content-between align-items-center pb-2 p-3 border-bottom">
                          <div class="d-flex">
                            <h4 className="text-blue medium fs-18">
                              {" "}
                              {data?.courseType}
                            </h4>
                          </div>
                        </div>
                      )}

                      <div className="py-3 border-bottom px-3">
                        {/* <div className="d-flex justify-content-between gap-2 pb-2">
                                                    <h5 className="fs-12 text-darkgray regular"><img src="../assets/img/icon/gray-watch.svg" className="me-2" alt="" />Course Duration</h5>
                                                    <h5 className="fs-12 text-darkgray regular">{data?.duration}</h5>
                                                </div> */}

                        <div className="d-flex justify-content-between gap-2 pb-2">
                          <h5 className="fs-12 text-darkgray regular">
                            <img
                              src="../assets/img/icon/gray-lavel.svg"
                              className="me-2"
                              alt=""
                            />
                            Course Level
                          </h5>
                          <h5 className="fs-12 text-darkgray regular">
                            {data?.level}
                          </h5>
                        </div>

                        <div className="d-flex justify-content-between gap-2 pb-2">
                          <h5 className="fs-12 text-darkgray regular">
                            <img
                              src="../assets/img/icon/gray-student.svg"
                              className="me-2"
                              alt=""
                            />
                            Students Enrolled
                          </h5>
                          <h5 className="fs-12 text-darkgray regular">
                            {data?.totalStudents}
                          </h5>
                        </div>

                        <div className="d-flex justify-content-between gap-2 pb-2">
                          <h5 className="fs-12 text-darkgray regular">
                            <img
                              src="../assets/img/icon/gray-lang.svg"
                              className="me-2"
                              alt=""
                            />
                            Language
                          </h5>
                          <h5 className="fs-12 text-darkgray regular">
                            {data?.course_language?.language}
                          </h5>
                        </div>
                        {data?.subtitleLanguageId?.language ? (
                          <div className="d-flex justify-content-between gap-2 pb-2">
                            <h5 className="fs-12 text-darkgray regular">
                              <img
                                src="../assets/img/icon/gray-lang2.svg"
                                className="me-2"
                                alt=""
                              />
                              Subtitle Language
                            </h5>
                            <h5 className="fs-12 text-darkgray regular">
                              {data?.subtitleLanguageId?.language}
                            </h5>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="py-3 px-3 border-bottom courseinclude-list">
                        <h4 className="fs-14 pb-3 medium text-black">
                          This course includes:
                        </h4>
                        <h5 className="fs-12 regular pb-2">
                          <img
                            src="../assets/img/icon/time.svg"
                            className="me-2"
                            alt=""
                          />
                          Lifetime access
                        </h5>
                        {/* <h5 className="fs-12 regular pb-2"><img src="../assets/img/icon/blue-dolor.svg" className="me-2" alt="" />30-days money-back guarantee</h5> */}
                        {/* <h5 className="fs-12 regular pb-2"><img src="../assets/img/icon/blue-download.svg" className="me-2" alt="" />Free exercises file &amp; downloadable resources</h5> */}
                        <h5 className="fs-12 regular pb-2">
                          <img
                            src="../assets/img/icon/blue-download.svg"
                            className="me-2"
                            alt=""
                          />
                          Free downloadable resources
                        </h5>
                        {/* <h5 className="fs-12 regular pb-2"><img src="../assets/img/icon/blue-trofi.svg" className="me-2" alt="" />Shareable certificate of completion</h5> */}
                        <h5 className="fs-12 regular pb-2">
                          <img
                            src="../assets/img/icon/blue-tv.svg"
                            className="me-2"
                            alt=""
                          />
                          Access on mobile , tablet and TV
                        </h5>
                        {/* <h5 className="fs-12 regular pb-2"><img src="../assets/img/icon/blue-file.svg" className="me-2" alt="" />English subtitles</h5> */}
                        <h5 className="fs-12 regular pb-2">
                          <img
                            src="../assets/img/icon/play2.svg"
                            className="me-2"
                            alt=""
                          />
                          100% online course
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <OnlineFriends />
      </div>
    </Container>
  );
}
