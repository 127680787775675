import React, { useEffect, useState, useCallback } from "react";
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import { BloodGroup } from "../../constants/BloodGroup";
import ToastMessage from "../../Utils/ToastMessage";
import { useNavigate } from "react-router-dom";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
export default function EditProfileModel({ onSuccess }) {
  const userId = localStorage.getItem("userId");
  const userRoleType = localStorage.getItem("userRoleType");
  const [gender, setGender] = useState("");
  const [nickname, setNickName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [birthplace, setBirthPlace] = useState("");
  const [livesin, setLivesin] = useState("");
  const [country, setCountry] = useState("");
  const [countryName, setCountryName] = useState("");
  const [countryKey, setCountryKey] = useState(
    Math.ceil(Math.random() * 999999)
  );
  const [bloodGroup, setBloodGroup] = useState("");
  const [about, setAbout] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [profileTagline, setProfileTagline] = useState("");
  const [countryData, setCountryData] = useState([]);

  const [genderError, setGenderError] = useState("");
  const [nicknameError, setNickNameError] = useState("");
  const [birthdayError, setBirthdayError] = useState("");
  const [birthplaceError, setBirthPlaceError] = useState("");
  const [livesinError, setLivesinError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [bloodGroupError, setBloodGroupError] = useState("");
  const [aboutError, setAboutError] = useState("");
  const [profileTaglineError, setProfileTaglineError] = useState("");
  const Navigate = useNavigate();

  useEffect(() => {
    getProfile();
    futureDateDisabled();
    getCountry();
  }, [userId]);

  const futureDateDisabled = () => {
    const date = new Date();
    let day = "";
    let days = date.getDate();
    if (days > 9) {
      day = days;
    } else {
      day = "0" + days;
    }
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    setCurrentDate(`${year}-${month}-${day}`);
  };

  const getProfile = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetProfile + "?id=" + userId);
      if (resp && resp.success === true) {
        setAbout(resp?.data?.user_profile?.aboutMe);
        setBloodGroup(resp?.data?.user_profile?.bloodGroup);
        setCountry(resp?.data?.user_profile?.countryId);
        setCountryKey(Math.ceil(Math.random() * 999999));
        setCountryName(resp?.data?.user_profile?.country);
        setLivesin(resp?.data?.user_profile?.livesIn);
        setBirthPlace(resp?.data?.user_profile?.birthPlace);

        setBirthday(resp?.data?.user_profile?.birthday);
        setNickName(resp?.data?.user_profile?.nickname);
        setGender(resp?.data?.user_profile?.gender);
        setProfileTagline(resp?.data?.user_profile?.profileTagline);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const getUpdatedProfile = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetProfile + "?id=" + userId);
      if (resp && resp.success === true) {
        if (userRoleType != "child") {
          window.localStorage.setItem("parentData", JSON.stringify(resp?.data));
        }
        window.localStorage.setItem("user", JSON.stringify(resp?.data));
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  // const handleCloseModel = () =>{
  //     setProfileTaglineError("");
  //     setAboutError("");
  //     setBloodGroupError("");
  //     setCountryError("");
  //     setLivesinError("");
  //     setLivesinError("");
  //     setBirthPlaceError("");
  //     setBirthdayError("");
  //     setNickNameError("");
  //     setGenderError("");
  //     getProfile();
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!nickname) {
      setNickNameError("Please enter nickname");
    } else if (nickname) {
      setNickNameError("");
    }
    if (!profileTagline) {
      setProfileTaglineError("Please enter profile tagline");
    } else if (profileTagline) {
      setProfileTaglineError("");
    }
    if (!birthday) {
      setBirthdayError("Please enter date of birth");
    } else if (birthday) {
      setBirthdayError("");
    }
    if (!birthplace) {
      setBirthPlaceError("Please enter birthplace");
    } else if (birthplace) {
      setBirthPlaceError("");
    }
    if (!livesin) {
      setLivesinError("Please enter lives in");
    } else if (livesin) {
      setLivesinError("");
    }
    if (!gender) {
      setGenderError("Please select gender");
    } else if (gender) {
      setGenderError("");
    }
    // if (!bloodGroup) {
    //     setBloodGroupError("Please select blood group");
    // } else if (bloodGroup) {
    //     setBloodGroupError("");
    // }
    if (!country) {
      setCountryError("Please select country");
    } else if (country) {
      setCountryError("");
    }
    if (!about) {
      setAboutError("Please enter about");
    } else if (about) {
      setAboutError("");
    }
    if (
      nickname &&
      profileTagline &&
      birthday &&
      birthplace &&
      livesin &&
      gender &&
      country &&
      about
    ) {
      let data;
      if (bloodGroup) {
        data = {
          about_me: about,
          birthday: birthday,
          nickname: nickname,
          profile_tagline: profileTagline,
          birth_place: birthplace,
          lives_in: livesin,
          country: country,
          gender: gender,
          blood_group: bloodGroup,
        };
      } else {
        data = {
          about_me: about,
          birthday: birthday,
          nickname: nickname,
          profile_tagline: profileTagline,
          birth_place: birthplace,
          lives_in: livesin,
          country: country,
          gender: gender,
        };
      }

      try {
        let resp = await CommonAPi(Endpoints.CreateUpdateProfile, data);
        if (resp && resp.message) {
          // $("#editProfilemodal").hide();
          // profileTagline(resp?.data?.profileTagline)
          window.localStorage.setItem(
            "ProfileTagline",
            resp?.data?.profileTagline
          );
          const button = document.getElementsByClassName("kids-close-icon")[0];
          button.click();
          onSuccess(resp);
          getUpdatedProfile();
          ToastMessage.Success(resp.message);
          // console.log(resp);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  const getCountry = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetCountry);
      if (resp && resp.success === true) {
        const newData = resp.data.map((item, index) => {
          return {
            id: item.id,
            name: item.countryName,
          };
        });
        setCountryData(newData);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleValidation = (e, value, field) => {
    e.preventDefault();
    if (!value && field === "nickname") {
      setNickNameError("Please enter nickname");
    } else if (value && field === "nickname") {
      setNickNameError("");
    }
    if (!value && field === "profiletagline") {
      setProfileTaglineError("Please enter profile tagline");
    } else if (value && field === "profiletagline") {
      setProfileTaglineError("");
    }
    if (!value && field === "dateOfBirth") {
      setBirthdayError("Please enter date of birth");
    } else if (value && field === "dateOfBirth") {
      setBirthdayError("");
    }
    if (!value && field === "birthplace") {
      setBirthPlaceError("Please enter birthplace");
    } else if (value && field === "birthplace") {
      setBirthPlaceError("");
    }
    if (!value && field === "livesIn") {
      setLivesinError("Please enter lives in");
    } else if (value && field === "livesIn") {
      setLivesinError("");
    }
    if (!value && field === "gender") {
      setGenderError("Please select gender");
    } else if (value && field === "gender") {
      setGenderError("");
    }
    // if (!value && field === "bloodGroup") {
    //     setBloodGroupError("Please select blood group");
    // } else if (value && field === "bloodGroup") {
    //     setBloodGroupError("");
    // }
    if (!value && field === "country") {
      setCountryError("Please select country");
    } else if (value && field === "country") {
      setCountryError("");
    }
    if (!value && field === "about") {
      setAboutError("Please enter about");
    } else if (value && field === "about") {
      setAboutError("");
    }
  };

  const handleOnSearch = (string) => {
    console.log("string", string);
  };

  const handleOnSelect = (item) => {
    if (item.length > 0) {
      setCountry(item[0].id);
    }
  };

  const RenderCountryName = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example"
        defaultInputValue={countryName}
        key={countryKey}
        onSearch={handleOnSearch}
        onChange={handleOnSelect}
        onBlur={(e) => {
          handleValidation(e, e.target.value, "country");
        }}
        options={countryData}
        placeholder="Search by Country name"
        labelKey="name"
        minLength={1}
        emptyLabel={"Searching..."}
        className={
          countryError ? "is-danger  text-gray border" : " text-gray border"
        }
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [countryName, countryKey, countryData, countryError]);

  return (
    <div
      className="modal fade"
      id="editProfilemodal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog rounded-10 box-shadow p-lg-4 p-md-3 p-2 bg-white modal-650p">
        <div className="modal-content border-0">
          <div className="d-flex align-items-center justify-content-between pb-3 border-bottom mb-3 w-100">
            <h3 className="medium text-blue"> Edit Profile</h3>
            <button
              type="button"
              className="btn-close kids-close-icon cursor-pointer"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="#949494"
                width="22"
                height="22"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div className="modal-body">
            <h4 className="medium text-black pb-3">
              {" "}
              Fill the form below to update profile
            </h4>

            <form onSubmit={handleSubmit} className="kids-form generic-form">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label>Nickname</label>
                  <input
                    type="text"
                    placeholder="Nickname"
                    className={
                      nicknameError
                        ? "is-danger  text-gray px-2 py-2 border"
                        : " text-gray px-2 py-2 border"
                    }
                    value={nickname}
                    onBlur={(e) => {
                      handleValidation(e, e.target.value, "nickname");
                    }}
                    onChange={(e) => {
                      setNickName(e.target.value);
                    }}
                  />
                  {nicknameError ? (
                    <div
                      className="form-error"
                      style={{ color: "white", margintop: "-10px" }}
                    >
                      {nicknameError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label>Profile Tagline</label>
                  <input
                    type="text"
                    placeholder="Profile Tagline"
                    className={
                      profileTaglineError
                        ? "is-danger  text-gray px-2 py-2 border"
                        : " text-gray px-2 py-2 border"
                    }
                    value={profileTagline}
                    onBlur={(e) => {
                      handleValidation(e, e.target.value, "profiletagline");
                    }}
                    onChange={(e) => {
                      setProfileTagline(e.target.value);
                    }}
                  />
                  {profileTaglineError ? (
                    <div
                      className="form-error"
                      style={{ color: "white", margintop: "-10px" }}
                    >
                      {profileTaglineError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-6 mb-3">
                  <label>Date of Birth</label>
                  <input
                    type="date"
                    placeholder="date"
                    className={
                      birthdayError
                        ? "is-danger  text-gray px-2 py-2 border"
                        : " text-gray px-2 py-2 border"
                    }
                    value={birthday}
                    max={`${currentDate}`}
                    onBlur={(e) => {
                      handleValidation(e, e.target.value, "dateOfBirth");
                    }}
                    onChange={(e) => {
                      setBirthday(e.target.value);
                    }}
                  />
                  {birthdayError ? (
                    <div
                      className="form-error"
                      style={{ color: "white", margintop: "-10px" }}
                    >
                      {birthdayError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-6 mb-3">
                  <label>Birth Place</label>
                  <input
                    type="text"
                    placeholder="Birth Place"
                    className={
                      birthplaceError
                        ? "is-danger  text-gray px-2 py-2 border"
                        : " text-gray px-2 py-2 border"
                    }
                    value={birthplace}
                    onBlur={(e) => {
                      handleValidation(e, e.target.value, "birthplace");
                    }}
                    onChange={(e) => {
                      setBirthPlace(e.target.value);
                    }}
                  />
                  {birthplaceError ? (
                    <div
                      className="form-error"
                      style={{ color: "white", margintop: "-10px" }}
                    >
                      {birthplaceError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-6 mb-3">
                  <label>Lives In</label>
                  <input
                    type="text"
                    placeholder="Enter your City"
                    className={
                      livesinError
                        ? "is-danger  text-gray px-2 py-2 border"
                        : " text-gray px-2 py-2 border"
                    }
                    value={livesin}
                    onBlur={(e) => {
                      handleValidation(e, e.target.value, "livesIn");
                    }}
                    onChange={(e) => {
                      setLivesin(e.target.value);
                    }}
                  />
                  {livesinError ? (
                    <div
                      className="form-error"
                      style={{ color: "white", margintop: "-10px" }}
                    >
                      {livesinError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-6 mb-3 form-field">
                  <label>Gender</label>

                  <select
                    onBlur={(e) => {
                      handleValidation(e, e.target.value, "gender");
                    }}
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                    className={
                      genderError
                        ? "is-danger  text-gray px-2 py-2 border"
                        : " text-gray px-2 py-2 border"
                    }
                  >
                    <option value={""}>Please select gender</option>
                    <option
                      selected={gender === "Male" ? true : false}
                      value="Male"
                    >
                      Male
                    </option>
                    <option
                      selected={gender === "Female" ? true : false}
                      value="Female"
                    >
                      Female
                    </option>
                    <option
                      selected={gender === "Other" ? true : false}
                      value={"Other"}
                    >
                      Other
                    </option>
                  </select>
                  {genderError ? (
                    <div
                      className="form-error"
                      style={{ color: "white", margintop: "-10px" }}
                    >
                      {genderError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label>Blood Group</label>
                  <select
                    onBlur={(e) => {
                      handleValidation(e, e.target.value, "bloodGroup");
                    }}
                    onChange={(e) => {
                      setBloodGroup(e.target.value);
                    }}
                    className={
                      bloodGroupError
                        ? "is-danger  text-gray px-2 py-2 border"
                        : " text-gray px-2 py-2 border"
                    }
                  >
                    <option value={""}>Please select blood group</option>
                    {BloodGroup.map((item) => {
                      return (
                        <option
                          selected={bloodGroup === item.value ? true : false}
                          value={item.value}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                  {bloodGroupError ? (
                    <div
                      className="form-error"
                      style={{ color: "white", margintop: "-10px" }}
                    >
                      {bloodGroupError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label>Country</label>
                  {RenderCountryName()}
                  {/* < select
                                    onBlur={(e) => {
                                        handleValidation(e, e.target.value, "country")
                                    }}
                                        onChange={(e) => { setCountry(e.target.value) }}
                                        className={countryError ?
                                            "is-danger  text-gray px-2 py-2 border"
                                            :
                                            " text-gray px-2 py-2 border"
                                        } >
                                        <option value={""}>Please select Country</option>
                                        <option value={""}>
                                        <input type="search" id="gsearch" name="gsearch" />
                                        </option>
                                        {
                                            countryData.map((item, index) => {
                                                return (
                                                    <option key={index} selected={parseInt(country) === item.id ? true : false} value={item.id}>{item.countryName}</option>
                                                )
                                            })
                                        }
                                    </select > */}
                  {countryError ? (
                    <div
                      className="form-error"
                      style={{ color: "white", margintop: "-10px" }}
                    >
                      {countryError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-12 mb-3">
                  <label>About</label>
                  <textarea
                    value={about}
                    onBlur={(e) => {
                      handleValidation(e, e.target.value, "about");
                    }}
                    onChange={(e) => {
                      setAbout(e.target.value);
                    }}
                    className={
                      aboutError
                        ? "is-danger text-gray px-4 py-4 border"
                        : "text-gray px-4 py-4 border"
                    }
                    placeholder="About me"
                  ></textarea>
                  {aboutError ? (
                    <div
                      className="form-error"
                      style={{ color: "white", margintop: "-10px" }}
                    >
                      {aboutError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-12 text-end">
                  <button type="submit" className="btn fs-14 ">
                    {" "}
                    Update{" "}
                    <img src="../assets/img/icon/icon-right-arrow.svg" alt="" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
