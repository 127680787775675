import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import PostLoader from "../../Components/PostLoader/PostLoader";
import ToastMessage from "../../Utils/ToastMessage";
import { cashfreeSandbox } from "cashfree-pg-sdk-javascript";
import ReactStars from "react-rating-stars-component";
import { Helmet } from "react-helmet";
const Checkout = () => {
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = JSON.parse(localStorage.getItem("user"));
  const [isPaying, setIsPaying] = useState(false);
  const [btnText, setBtnText] = useState("Complete Checkout");
  const [Coupon, setCoupon] = useState("");
  const [CouponCourse, setCouponCourse] = useState("");
  const [CouponStatus, setCouponStatus] = useState(false);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [cartSummary, setCartSummary] = useState("");
  const navigate = useNavigate();
  const getCartItems = async () => {
    setIsLoading(true);
    try {
      const resp = await CommonAPi(Endpoints.GetCartItems);
      setIsLoading(false);
      // if (!resp.data.length > 0) navigate("/cart")
      if (resp && resp.data.cartItems.length > 0) {
        setCartItems(resp.data.cartItems);
        setCartSummary(resp.data.priceDetails);

        let newsubTotalAmountData = 0;
        let newTotalAmountData = 0;
        resp.data.map((item) => {
          let newSubTotalAmount =
            parseInt(newsubTotalAmountData) + parseInt(item.course.price);
          newsubTotalAmountData = newSubTotalAmount;
          setSubTotalAmount(newSubTotalAmount);

          let newTotalAmount =
            parseInt(newTotalAmountData) +
            parseInt(item.course.discountedPrice);
          newTotalAmountData = newTotalAmount;
          setTotalAmount(newTotalAmount);

          if (item.course.couponDetails !== null) {
            setCouponStatus(true);
            setCouponCourse(item.course.id);
            setCoupon(item.course.couponDetails.couponCode);
            let discountAmount =
              item.course.discountedPrice -
              item.course.couponDetails?.finalPrice?.toFixed(2);
            setCouponDiscount(discountAmount);
            // newTotalAmount = newTotalAmount - discountAmount;
            // newTotalAmountData = newTotalAmount;
            // setTotalAmount(newTotalAmount);
          }
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCartItems();
  }, []);

  const createOrder = async () => {
    setIsPaying(true);
    setBtnText("Please wait...");
    try {
      const data = {
        course_id: cartItems.map((each) => each.course.id),
        name: user.fullName,
        mobile: user.mobileNumber,
        email: user.email,
        price: (cartSummary?.overallTotal).toFixed(2),
      };
      const resp = await CommonAPi(Endpoints.createOrder, data);
      if (resp && resp.payment_session_id) {
        const paymentSessionId = resp.payment_session_id;
        let cashfree = new cashfreeSandbox.Cashfree(paymentSessionId);
        cashfree.redirect();
      }
    } catch (error) {
      console.log(error);
      setIsPaying(false);
      setBtnText("Complete Checkout");
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Checkout</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-3 home ">
            {isLoading ? (
              <PostLoader />
            ) : (
              <div className="row">
                <div className="col-lg-9 mx-auto">
                  <div className="bg-white rounded-10 border ">
                    <div className="d-flex justify-content-between border-bottom py-3 px-3 mb-3">
                      <div className="">
                        <h3 className="fs-22 medium pb-2">Order Details</h3>
                        <h6 className="regular text-333">
                          Review the order details as below:
                        </h6>
                      </div>
                      <div className="text-end ">
                        <NavLink
                          to={"/cart"}
                          className={"btn btn-gray fs-12 mb-5 text-white"}
                          style={{ fontWeight: "bold", marginBottom: "1rem" }}
                        >
                          Go Back to Cart
                        </NavLink>
                      </div>
                    </div>
                    <div className="px-3 border-bottom">
                      {cartItems.length > 0 &&
                        cartItems.map((item) => (
                          <div className="d-flex align-items-center gap-lg-3 gap-2 pb-3">
                            <Link to={"/course-detail/" + item.course.slug}>
                              <img
                                src={`${
                                  Endpoints.baseUrl + item.course.thumbnail
                                }`}
                                className="rounded-2 object-cover"
                                width="96"
                                height="96"
                              />
                            </Link>
                            <div>
                              <Link to={"/course-detail/" + item.course.slug}>
                                <h5 className="medium">{item.course.title}</h5>
                              </Link>
                              <div className="py-2 d-flex w-100 align-items-center fs-14 ">
                                <span className="text-brown ">
                                  {item.course.avgRating}
                                </span>
                                <div className="d-flex mx-2">
                                  <ReactStars
                                    count={5}
                                    edit={false}
                                    value={item.course.avgRating}
                                    size={24}
                                    isHalf={true}
                                    char={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        alt=""
                                        width="17"
                                      />
                                    }
                                    emptyIcon={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        alt=""
                                        width="17"
                                      />
                                    }
                                    halfIcon={
                                      <img
                                        src="../assets/img/icon/state-half.svg"
                                        alt=""
                                        width="17"
                                      />
                                    }
                                    filledIcon={
                                      <img
                                        src="../assets/img/icon/State-fill.svg"
                                        alt=""
                                        width="17"
                                      />
                                    }
                                    activeColor="#ffc107"
                                  />
                                </div>
                                <span className="text-brown ">
                                  ({item.course.totalRatings} reviews)
                                </span>
                                <span className="medium text-black ps-3">
                                  {item.course.totalStudents}{" "}
                                  <span className="text-gray">Students</span>
                                </span>
                              </div>
                              <div className=" course-price pb-2 medium ">
                                <ins className="text-blue fs-18">
                                  ₹ {item.course.discountedPrice}
                                </ins>
                                <del className="text-lightgray fs-16">
                                  ₹ {item.course.price}
                                </del>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="py-3 px-3">
                      <h4 className="medium ">Order Summary</h4>
                      <div className="pt-3 fs-14 text-darkgray ">
                        <span className="medium  d-flex align-items-center justify-content-between">
                          Subtotal{" "}
                          <span className="text-black">
                            ₹
                            {/* {
                                                                cartItems.length > 0 && cartItems.reduce((acc, curr) => acc + curr.course.discountedPrice, 0)
                                                            } */}
                            {cartSummary?.overallSubtotal?.toFixed(2)}
                          </span>
                        </span>
                        <span className="medium  d-flex align-items-center justify-content-between py-2">
                          Discount{" "}
                          <span className="text-black">
                            - ₹ {cartSummary?.overallDiscount?.toFixed(2)}
                          </span>
                        </span>
                        <span className="medium  d-flex align-items-center justify-content-between py-2">
                          Coupon Discount{" "}
                          <span className="text-black">
                            - ₹ {cartSummary?.couponDiscount?.toFixed(2)}
                          </span>
                        </span>
                        <span className="medium  d-flex align-items-center justify-content-between">
                          Tax{" "}
                          <span className="text-black">
                            {cartSummary?.GST} (₹ {cartSummary?.taxAmt})
                          </span>
                        </span>
                        <div className="border-top mt-3 py-3">
                          <h3 className="medium d-flex align-items-center justify-content-between text-black">
                            Total:{" "}
                            <span className="">
                              ₹ {(cartSummary?.overallTotal).toFixed(2)}
                            </span>
                          </h3>
                        </div>
                        <p className="text-gray fs-14 text-center">
                          By completing your purchase you agree to these{" "}
                          <a href="#" className="text-blue">
                            Terms of Service.
                          </a>
                        </p>
                        <a
                          href="javascript:void(0)"
                          className="btn w-100 fs-22 py-3"
                          onClick={() => {
                            createOrder();
                          }}
                          style={{
                            cursor: `${isPaying ? "not-allowed" : "pointer"}`,
                          }}
                        >
                          {btnText}
                        </a>
                        <p className="fs-14 text-center text-black m-0 pt-3">
                          <img
                            src="assets/img/icon/icon-secure-payment.svg"
                            alt=""
                          />{" "}
                          Secure Payment
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="dropin-parent" id="drop_in_container"></div> */}
              </div>
            )}
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
};
export default Checkout;
