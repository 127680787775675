import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import PageItem from "./PageItem";
import PostLoader from "../../Components/PostLoader/PostLoader";

export default function PageTab({ ActivePage }) {
  const location = useLocation();
  const [pageData, setPageData] = useState([]);
  const [pageName, setPageName] = useState("");
  const [activeTab, setActiveTab] = useState("All");
  const [followerCount, setFollowerCount] = useState(0);
  const [selfPagesCount, setSelfPagesCount] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [TotalCount, setTotalCount] = useState(0);
  const [loadStatus, setLoadStatus] = useState(false);
  const [limit, setLimit] = useState(12);

  const param = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    getPages();
    if (ActivePage === "School") {
      setPageName("Schools");
    } else if (ActivePage === "University") {
      setPageName("Universities");
    } else if (ActivePage === "Company") {
      setPageName("Company");
    } else if (ActivePage === "Page") {
      setPageName("Pages");
    }
  }, []);
  const getPages = async (e) => {
    setLoadStatus(true);
    if (param?.slug === "follower") {
      setActiveTab("follower");
      try {
        let resp = await FetchApi(
          Endpoints.GetPages +
            "?type=" +
            ActivePage +
            "&following=1" +
            "&size=" +
            limit
        );
        if (resp && resp.success === true) {
          setPageData(resp?.data?.pages);
          setFollowerCount(resp?.data?.followingPagesCount);
          setSelfPagesCount(resp?.data?.selfPagesCount);
          setTotalPagesCount(resp?.data?.allPageCount);
          setTotalCount(resp?.data?.pagesCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else if (param?.slug === "created") {
      setActiveTab("self");
      try {
        let resp = await FetchApi(
          Endpoints.GetPages +
            "?type=" +
            ActivePage +
            "&self=1" +
            "&size=" +
            limit
        );
        if (resp && resp.success === true) {
          setPageData(resp?.data?.pages);
          setFollowerCount(resp?.data?.followingPagesCount);
          setSelfPagesCount(resp?.data?.selfPagesCount);
          setTotalPagesCount(resp?.data?.allPageCount);
          setTotalCount(resp?.data?.pagesCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      setActiveTab("All");
      try {
        let resp = await FetchApi(
          Endpoints.GetPages + "?type=" + ActivePage + "&size=" + limit
        );
        if (resp && resp.success === true) {
          setPageData(resp?.data?.pages);
          setFollowerCount(resp?.data?.followingPagesCount);
          setSelfPagesCount(resp?.data?.selfPagesCount);
          setTotalPagesCount(resp?.data?.allPageCount);
          setTotalCount(resp?.data?.pagesCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
    setLoadStatus(false);
  };
  const handlesorting = async (value) => {
    if (param?.slug === "follower") {
      setActiveTab("follower");
      try {
        let resp = await FetchApi(
          Endpoints.GetPages +
            "?type=" +
            ActivePage +
            "&following=1" +
            "&size=" +
            limit +
            "&sort=" +
            value
        );
        if (resp && resp.success === true) {
          setPageData(resp?.data?.pages);
          setFollowerCount(resp?.data?.followingPagesCount);
          setSelfPagesCount(resp?.data?.selfPagesCount);
          setTotalPagesCount(resp?.data?.allPageCount);
          setTotalCount(resp?.data?.pagesCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else if (param?.slug === "created") {
      setActiveTab("self");
      try {
        let resp = await FetchApi(
          Endpoints.GetPages +
            "?type=" +
            ActivePage +
            "&self=1" +
            "&size=" +
            limit +
            "&sort=" +
            value
        );
        if (resp && resp.success === true) {
          setPageData(resp?.data?.pages);
          setFollowerCount(resp?.data?.followingPagesCount);
          setSelfPagesCount(resp?.data?.selfPagesCount);
          setTotalPagesCount(resp?.data?.allPageCount);
          setTotalCount(resp?.data?.pagesCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      setActiveTab("All");
      try {
        let resp = await FetchApi(
          Endpoints.GetPages +
            "?type=" +
            ActivePage +
            "&size=" +
            limit +
            "&sort=" +
            value
        );
        if (resp && resp.success === true) {
          setPageData(resp?.data?.pages);
          setFollowerCount(resp?.data?.followingPagesCount);
          setSelfPagesCount(resp?.data?.selfPagesCount);
          setTotalPagesCount(resp?.data?.allPageCount);
          setTotalCount(resp?.data?.pagesCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handlePage = async (type, self = false) => {
    setLoadStatus(true);
    if (type === "All") {
      setActiveTab("All");
      if (ActivePage === "School") {
        navigate("/schools");
      } else if (ActivePage === "University") {
        navigate("/university");
      } else if (ActivePage === "Company") {
        navigate("/company");
      }

      try {
        let resp = await FetchApi(
          Endpoints.GetPages + "?type=" + ActivePage + "&size=" + limit
        );
        if (resp && resp.success === true) {
          setPageData(resp?.data?.pages);
          setFollowerCount(resp?.data?.followingPagesCount);
          setTotalPagesCount(resp?.data?.allPageCount);
          setTotalCount(resp?.data?.pagesCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else if (type === "self") {
      setActiveTab("self");
      if (ActivePage === "School") {
        navigate("/schools/created");
      } else if (ActivePage === "University") {
        navigate("/university/created");
      } else if (ActivePage === "Company") {
        navigate("/company/created");
      }

      try {
        let resp = await FetchApi(
          Endpoints.GetPages +
            "?type=" +
            ActivePage +
            "&size=" +
            limit +
            "&self=1"
        );
        if (resp && resp.success === true) {
          setPageData(resp?.data?.pages);
          setFollowerCount(resp?.data?.followingPagesCount);
          setSelfPagesCount(resp?.data?.selfPagesCount);
          setTotalPagesCount(resp?.data?.allPageCount);
          setTotalCount(resp?.data?.pagesCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      setActiveTab("follower");
      if (ActivePage === "School") {
        navigate("/schools/follower");
      } else if (ActivePage === "University") {
        navigate("/university/follower");
      } else if (ActivePage === "Company") {
        navigate("/company/follower");
      }

      try {
        let resp = await FetchApi(
          Endpoints.GetPages +
            "?type=" +
            ActivePage +
            "&following=1" +
            "&size=" +
            limit
        );
        if (resp && resp.success === true) {
          setPageData(resp?.data?.pages);
          setFollowerCount(resp?.data?.followingPagesCount);
          setSelfPagesCount(resp?.data?.selfPagesCount);
          setTotalPagesCount(resp?.data?.allPageCount);
          setTotalCount(resp?.data?.pagesCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
    setLoadStatus(false);
  };

  const handleSearch = async (value) => {
    if (value) {
      try {
        let resp = await FetchApi(
          Endpoints.GetPages +
            "?term=" +
            value +
            "&type=" +
            ActivePage +
            "&size=" +
            limit
        );
        if (resp && resp.success === true) {
          setPageData(resp?.data?.pages);
          setFollowerCount(resp?.data?.followingPagesCount);
          setSelfPagesCount(resp?.data?.selfPagesCount);
          setTotalPagesCount(resp?.data?.allPageCount);
          setTotalCount(resp?.data?.pagesCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      try {
        let resp = await FetchApi(
          Endpoints.GetPages + "?type=" + ActivePage + "&size=" + limit
        );
        if (resp && resp.success === true) {
          setPageData(resp?.data?.pages);
          setFollowerCount(resp?.data?.followingPagesCount);
          setSelfPagesCount(resp?.data?.selfPagesCount);
          setTotalPagesCount(resp?.data?.allPageCount);
          setTotalCount(resp?.data?.pagesCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleLoadMore = async () => {
    const newLimit = limit + 12;
    setLimit(newLimit);
    try {
      let requestURL =
        Endpoints.GetPages + "?type=" + ActivePage + "&size=" + newLimit;

      if (activeTab === "self") {
        requestURL += "&self=1";
      } else if (activeTab === "follower") {
        requestURL += "&following=1";
      }

      let resp = await FetchApi(requestURL);

      if (resp && resp.success === true) {
        setPageData(resp?.data?.pages);
        setFollowerCount(resp?.data?.followingPagesCount);
        setSelfPagesCount(resp?.data?.selfPagesCount);
        setTotalPagesCount(resp?.data?.allPageCount);
        setTotalCount(resp?.data?.pagesCount);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <div className="bg-white main-common">
      <div className="main-top-row d-md-flex justify-content-between align-items-center pb-3">
        {/* <h3 className="medium">{ActivePage === "School" ? "Schools" : ActivePage === "University" ? "Universities" : "Company"} </h3> */}
        <h3 className="medium">{pageName} </h3>

        <div className="d-sm-flex gap-3 pb-3">
          <form
            action=""
            className="mainmenu-page-search d-flex flex-row-reverse align-items-center"
          >
            <input
              type="text"
              placeholder={"Search for " + pageName}
              name="search"
              className="bg-transparent fs-14 medium  text-darkgray"
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
            />
            <button type="submit" className="bg-transparent border-0">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="#949494"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="css-i6dzq1"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </button>
          </form>
          <select
            name="sortby"
            id="sortby"
            className="text-darkgray mb-sm-0 mb-2 d-sm-block d-inline-block"
            onChange={(e) => {
              handlesorting(e.target.value);
            }}
          >
            <option value="">Sort by</option>
            <option value="popular">Popular</option>
            <option value="recent">Recent</option>
          </select>
          <div className="d-inline-block float-end">
            <Link to={"/select-page-type"} className="btn btn-br-blue">
              Create Page +
            </Link>
          </div>
        </div>
      </div>

      <div className="mainmenu-filter-row pb-3">
        <ul className="filter d-flex flex-nowrap align-items-center gap-2 fs-14 medium">
          <li
            className={activeTab === "All" ? "active" : ""}
            onClick={() => {
              handlePage("All");
            }}
          >
            All {pageName}{" "}
            <span className="fs-10 text-brown">{totalPagesCount}</span>
          </li>
          <li
            className={activeTab === "follower" ? "active" : ""}
            onClick={() => {
              handlePage("follower");
            }}
          >
            {" "}
            {pageName === "Pages" ? "Joined" : "Followed"} {pageName}{" "}
            <span className="fs-10 text-brown">{followerCount}</span>
          </li>
          <li
            className={activeTab === "self" ? "active" : ""}
            onClick={() => {
              handlePage("self");
            }}
          >
            Created {pageName}{" "}
            <span className="fs-10 text-brown">{selfPagesCount}</span>
          </li>
        </ul>
      </div>
      {loadStatus ? (
        <PostLoader />
      ) : pageData?.length > 0 ? (
        <div className="row mainmenu-filter-conatiner mainmenu-school-list">
          {pageData?.map((item, index) => {
            return (
              <PageItem
                {...{
                  item: item,
                  index: index,
                  pageName: pageName,
                  onSuccess: getPages,
                  activePage: ActivePage,
                }}
              />
            );
          })}
        </div>
      ) : pageName === "Pages" ? (
        <div className="row mainmenu-filter-conatiner mainmenu-school-list">
          {activeTab === "self" ? "No Created Pages Found" : " "}
          {activeTab === "follower" ? "No Joined Pages Found" : ""}
          {activeTab === "All" ? "No Pages Found" : ""}
        </div>
      ) : (
        <div className="row mainmenu-filter-conatiner mainmenu-school-list">
          {activeTab === "All" ? "No " + pageName + " Found" : ""}
          {activeTab === "follower" ? "No Followed " + pageName + " Found" : ""}
          {activeTab === "self" ? "No Created " + pageName + " Found" : ""}
        </div>
      )}

      {pageData.length > 8 ? (
        <div className="text-center">
          <Link href="#" className="text-blue" onClick={() => handleLoadMore()}>
            {loadStatus ? (
              <img src="../assets/img/icon/icon-loading.svg" alt="" />
            ) : (
              "Load More " + pageName
            )}
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
