import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { CommonAPi } from "../../API/CommonApi";
import ToastMessage from "../../Utils/ToastMessage";
import { Endpoints } from "../../API/Endpoints";
import { Link, NavLink } from "react-router-dom";
import "./PostShare.css";
import PostDiscardPopup from "../PostDiscardPopup/PostDiscardPopup";
import Loader from "../PostLoader/PostLoader";
import EmojiPicker from "emoji-picker-react";
import Resizer from "react-image-file-resizer";
import { EditorState, convertToRaw } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createMentionPlugin from "@draft-js-plugins/mention";
import createEmojiPlugin, { defaultTheme } from "@draft-js-plugins/emoji";

import "@draft-js-plugins/mention/lib/plugin.css";
import "@draft-js-plugins/emoji/lib/plugin.css";
import "@draft-js-plugins/linkify/lib/plugin.css";
import EntryComponent from "./EntryComponent";
import FriendsSpecificModal from "../FriendsSpecificModal";

const postVisibilites = [
  {
    name: "Friends",
    value: "Friends",
    image: "../assets/img/icon/group-friend.svg",
    text: "Your connections on 8inNet",
  },
  {
    name: "Public",
    value: "Public",
    image: "../assets/img/icon/public.svg",
    text: "Anyone on or off 8inNet",
  },
  {
    name: "Friends Specific",
    value: "Friends Specific",
    image: "../assets/img/icon/friend.svg",
    text: "Only Show to some friends",
  },
];

const $ = window.jQuery;

export const CustomHastagComp = (hashtagProps) => {
  console.log({ hashtagProps });
  return (
    <a
      href={hashtagProps.mention.link}
      style={{
        padding: "3px 5px",
        backgroundColor: "white",
        color: "var(--blue)",
      }}
    >
      {hashtagProps.children}
    </a>
  );
};

const PostShare = ({
  setPostPage,
  setTimelinePosts,
  postPage,
  getUserPosts,
  pageId,
  groupId,
}) => {
  const previewTimeout = useRef();

  const [postTitle, setPostTitle] = useState("");
  const [postMedia, setPostMedia] = useState([]);
  const [postVisibility, setPostVisibility] = useState("Public");
  const [activePostCategory, setActivePostCategory] = useState("Status");
  const [isPostCreating, setIsPostCreating] = useState(true);
  const userProfile = localStorage.getItem("userProfile");
  const user = JSON.parse(localStorage.getItem("user"));
  const [btnValue, setBtnValue] = useState("");
  const imageRef = useRef();
  const inputRef = useRef(null);
  const modalRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const modalClickRef = useRef();
  const modalOutsideRef = useRef();
  const [showDiscardPopup, setShowDiscardPopup] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [previewDataLoader, setPreviewDataLoader] = useState(false);
  const [type, setType] = useState("");
  const [error, setError] = useState("");
  const [linkError, setLinkError] = useState("");
  const linkImageRef = useRef();
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [compressedImages, setCompressedImages] = useState([]);
  const [specificFrined, setSpecificFrined] = useState([]);
  let compressTimeout = null;
  const emojiRef = useRef(null);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleContentChange = useCallback(
    (newContent) => {
      // const editorData = convertToRaw(newContent.getCurrentContent());
      // console.log({ editorData });
      setEditorState(newContent);
      setPostTitle(newContent.getCurrentContent().getPlainText());
    },
    [setPostTitle]
  );

  // mention plugin implementation
  const [mentionOpen, setMentionOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  // hashtag plugin implementation
  const [hashtagOpen, setHashtagOpen] = useState(false);
  const [hashtagSuggestions, setHashtagSuggestions] = useState([]);

  const {
    MentionSuggestions,
    plugins,
    EmojiSuggestions,
    EmojiSelect,
    HashtagSuggestions,
  } = useMemo(() => {
    // @ mention
    const mentionPlugin = createMentionPlugin({
      mentionTrigger: ["@"],
      mentionPrefix: "",
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // # hashtag
    const hashtagPlugin = createMentionPlugin({
      mentionTrigger: ["#"],
      mentionComponent: CustomHastagComp,
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions: HashtagSuggestions } = hashtagPlugin;

    const emojiPlugin = createEmojiPlugin({
      theme: {
        ...defaultTheme,
        emojiSelect: "custom-emoji-select",
        emojiSelectButton: "custom-emoji-select-button",
        emojiSelectButtonPressed: "custom-emoji-select-button",
        emojiSelectPopover:
          defaultTheme.emojiSelectPopover + " custom-emoji-select-popover",
        emojiSelectPopoverEntry: "uc-emoji",
        emojiSelectPopoverEntryFocused: "uc-emoji-focused",
      },
      useNativeArt: true,
      selectButtonContent: (
        <>
          <img
            className="post-image"
            src="../assets/img/icon/emoji_icon.png"
            alt=""
          />
          Emoji
        </>
      ),
    });
    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

    const linkify = createLinkifyPlugin();

    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin, emojiPlugin, hashtagPlugin, linkify];
    return {
      plugins,
      MentionSuggestions,
      EmojiSuggestions,
      EmojiSelect,
      HashtagSuggestions,
    };
  }, []);

  const onOpenChange = useCallback((_open) => {
    setMentionOpen(_open);
  }, []);
  const onSearchChange = useCallback(({ value }) => {
    CommonAPi(Endpoints.MentionSearch + "?mention=" + value).then((res) => {
      if (res.data) {
        // setSuggestions(
        const userFriends = res?.data?.userFriends.map((item) => ({
          name: item.fullName,
          avatar: Endpoints.baseUrl + item.user_profile.profileImg,
          link: `/timeline/${item.username}`,
          id: `${item.id}`,
          type: "profile",
        }));
        const group = res?.data?.group.map((item) => ({
          name: item.groupName,
          avatar: Endpoints.baseUrl + item.logo,
          link: `/group-about/${item.slug}`,
          id: `${item.id}`,
          type: "group",
        }));
        const page = res?.data?.page.map((item) => ({
          name: item.title,
          avatar: Endpoints.baseUrl + item.pageImage,
          link: `/page-profile/${item.slug}`,
          id: `${item.id}`,
          type: "page",
        }));
        setSuggestions([...userFriends, ...group, ...page]);

        // );
      }
    });
    // setSuggestions(defaultSuggestionsFilter(value, mentions));
  }, []);

  const onHashtagOpenChange = useCallback((_open) => {
    setHashtagOpen(_open);
  }, []);
  const onHashtagSearchChange = useCallback(({ value }) => {
    CommonAPi(Endpoints.searchHashtag + value).then((res) => {
      if (res.data) {
        let mappedResponse = res.data.map((item) => ({
          name: `#${item.tag}`,
          link: `/singlehashtag?tag=${item.tag}`,
        }));
        const index = mappedResponse.findIndex(
          (item) =>
            item.name.trim().slice(1).toLowerCase() ===
            value.trim().toLowerCase()
        );
        if (value && index === -1)
          mappedResponse = [
            { name: `#${value}`, link: `/singlehashtag?tag=${value}` },
            ...mappedResponse,
          ];
        setHashtagSuggestions(mappedResponse);
      }
    });
    // setHashtagSuggestions(defaultSuggestionsFilter(value, hashtags));
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutSideEmoji);
    return () => {
      document.removeEventListener("click", handleClickOutSideEmoji);
    };
  }, []);

  function handleClickOutSideEmoji(e) {
    if (emojiRef.current && !emojiRef.current.contains(e.target)) {
      setEmojiPickerOpen(false);
    }
  }

  useEffect(() => {
    if (showModal) {
      const handleClickOutSide = (e) => {
        if (
          modalClickRef.current &&
          !modalClickRef.current.contains(e.target)
        ) {
          setShowModal(false);
          setEmojiPickerOpen(false);
          if (postTitle !== "" || postMedia.length > 0) {
            setShowDiscardPopup(true);
          } else {
            setShowDiscardPopup(false);
          }
        }
      };
      modalOutsideRef?.current?.addEventListener("click", handleClickOutSide);
      return () => {
        modalOutsideRef?.current?.removeEventListener(
          "click",
          handleClickOutSide
        );
      };
    }
  }, [showModal, postTitle, postMedia.length]);

  useEffect(() => {
    if (postTitle !== "" || postMedia.length > 0) {
      setIsPostCreating(false);
    } else {
      setIsPostCreating(true);
    }
  }, [postTitle, postMedia.length]);

  useEffect(() => {
    if (postTitle === "") {
      setPreviewDataLoader(false);
      previewTimeout.current = {
        url: "",
        timeout: null,
      };
    } else {
      const urlRegex = /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;
      const urlArray = urlRegex.exec(postTitle);
      if (urlArray?.length && urlArray[0].length) {
        async function fetchMeta() {
          try {
            const resp = await CommonAPi(Endpoints.LinkPreview, {
              url: urlArray[0],
            });
            if (resp && resp.success) {
              setPreviewData(resp.data);
              setPreviewDataLoader(false);
            }
          } catch (error) {
            setPreviewDataLoader(false);
            setPreviewData(null);
            console.log(error);
          }
        }
        if (
          previewData === null ||
          urlArray[0] !== previewTimeout.current.url
        ) {
          previewTimeout.current = {
            url: urlArray[0],
            timeout: setTimeout(() => {
              setPreviewDataLoader(true);
              fetchMeta();
            }, 1000),
          };
        }
      }
    }
    return () => {
      if (previewTimeout.current.timeout) {
        clearTimeout(previewTimeout.current.timeout);
        previewTimeout.current.timeout = null;
      }
    };
  }, [postTitle]);

  async function compressImage() {
    let promises = [];
    const quality = 70;
    for (const element of postMedia) {
      if (element.type.includes("image")) {
        let maxHeight, maxWidth;
        const image = new Image();
        const url = URL.createObjectURL(element);
        image.src = url;
        image.onload = () => {
          const { naturalWidth, naturalHeight } = image;
          maxHeight = naturalHeight;
          maxWidth = naturalWidth;
          URL.revokeObjectURL(url);
        };
        promises.push(
          new Promise((resolve) => {
            Resizer.imageFileResizer(
              element,
              maxWidth,
              maxHeight,
              "JPEG",
              quality,
              0,
              (file) => {
                // console.log(file)
                resolve(file);
              },
              "file"
            );
          })
        );
      }
    }
    const results = await Promise.all(promises);
    const compressed = [
      ...postMedia.filter((each) => each.type.includes("video")),
      ...results,
    ];
    return compressed;
  }

  const handleEmojiClick = (emojiData, MouseEvent) => {
    setSelectedEmoji(emojiData.unified);
    setPostTitle((prev) => prev + emojiData.emoji);
  };

  useEffect(() => {
    if (postMedia.length > 0) {
      setBtnValue("Add More ");
    } else {
      setBtnValue("Add ");
    }
  }, [postMedia.length]);
  const onImageSelection = (e) => {
    setError("");
    if (!showModal) {
      setShowModal(true);
    }
    setPreviewData(null);
    if (e.target.files) {
      if (e.target.files.length > 0) {
        let files = [];
        for (const element of e.target.files) {
          let file = element;
          if (file.type.includes("image") && type === "video") {
            setError("Please select videos only");
            imageRef.current.value = "";
            return;
          } else if (file.type.includes("video") && type === "image") {
            setError("Please select images only");
            imageRef.current.value = "";
            return;
          }
          if (
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/webp" ||
            file.type === "video/mp4"
          ) {
            files.push(file);
          } else {
            ToastMessage.Error("PELASE CHOOSE VALID FILE TYPES");
            return;
          }
        }
        setPostMedia([...postMedia, ...files]);
      } else {
        let imgs = e.target.files[0];
        if (!imgs) return;
        let file = [];
        if (imgs.type.includes("image") && type === "video") {
          setError("Please select videos only");
          imageRef.current.value = "";
          return;
        } else if (imgs.type.includes("video") && type === "image") {
          setError("Please select images only");
          imageRef.current.value = "";
          return;
        }
        if (
          imgs.type === "image/png" ||
          imgs.type === "image/jpg" ||
          imgs.type === "image/jpg" ||
          imgs.type === "image/webp" ||
          imgs.type === "video/mp4"
        ) {
          file.push(imgs);
        }
        // console.log(file)
        setPostMedia([...postMedia, ...file]);
      }
    }
    imageRef.current.value = "";
    // console.log(postMedia)
  };

  const uploadPost = async (e) => {
    setIsCreating(true);
    e.preventDefault();
    if (postTitle === "" && postMedia.length <= 0) {
      return ToastMessage.Error("PLEASE UPLOAD A POST OR A TITLE");
    }
    const result = await compressImage();
    const formData = new FormData();

    const editorData = convertToRaw(editorState.getCurrentContent());
    formData.append("title", JSON.stringify(editorData));
    formData.append("post_visibility", postVisibility);
    if (previewData && postMedia.length === 0) {
      formData.append("link_preview_data", JSON.stringify(previewData));
      formData.append("category", previewData ? "Link" : "Status");
    } else {
      formData.append("category", activePostCategory);
    }
    if (pageId) {
      formData.append("page_id", pageId);
    }
    if (groupId) {
      formData.append("group_id", groupId);
    }

    result.forEach((item) => formData.append("media", item));
    if (postVisibility === "Friends Specific") {
      specificFrined.forEach((item) => formData.append("specificFriend", item));
    }

    try {
      setIsPostCreating(true);
      let resp = await CommonAPi(Endpoints.createPost, formData);
      if (resp && resp.success === true) {
        setEditorState(EditorState.createEmpty());
        setIsCreating(false);
        setPostMedia([]);
        setPostTitle("");
        setTimelinePosts([]);
        setPreviewData(null);
        setEmojiPickerOpen(false);
        setLinkError("");
        imageRef.current.value = "";
        ToastMessage.Success(resp.message);
        if (pageId || groupId) {
          setPostPage(1);
          getUserPosts(1);
        } else {
          if (postPage !== 1) setPostPage(1);
          else getUserPosts(1);
        }
        modalRef.current.click();
        setShowDiscardPopup(false);
        setIsPostCreating(false);
      }
    } catch (e) {
      console.log(e);
      setIsCreating(false);
      setIsPostCreating(false);
      if (e?.response?.data?.message) {
        ToastMessage.Error(e.response.data.message);
      }
    }
  };

  function dataURLToFile(dataURL, filename) {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const removeSinglePicture = (i) => {
    setPostMedia(postMedia.filter((media, idx) => idx !== i));
    imageRef.current.value = "";
  };

  const handleClick = (e) => {
    e.preventDefault();
    window.open("https://" + postTitle, "_blank");
  };

  function extractURL(text) {
    const pattern = /\b((?:https?:\/\/|www\.)\S+)\b/g;
    const urls = [];

    text.replace(pattern, function (match, url) {
      urls.push(url);
    });

    return urls;
  }

  const handleLink = (e) => {
    if (!/^[a-zA-Z/:\\]+$/i.test(e.key)) return;
    if (previewData) return;
    setLinkError("");
    let url = postTitle;
    if (url.startsWith("www.")) url = url.split("www.")[1];
    if (url.startsWith("http")) {
      url = new URL(url).hostname.replace("www.", "");
    }
    console.log(url);

    if (url === "") {
      setPreviewDataLoader(false);
      return;
    }
    const urlRegex =
      /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    // const urlRegex = /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/i
    // const urlRegex =/\b((?:https?:\/\/|www\.)\S+)\b/
    if (urlRegex.test(url)) {
      if (url.split(".")[1].length < 2) return;
      async function fetchMeta() {
        try {
          const resp = await CommonAPi(Endpoints.LinkPreview, { url: url });
          if (resp?.success) {
            setPreviewData(resp.data);
            setPreviewDataLoader(false);
            setLinkError("");
          }
        } catch (error) {
          setPreviewDataLoader(false);
          setLinkError("Cannot display the content of link");
          console.log(error);
        }
      }
      setTimeout(() => {
        setPreviewDataLoader(true);
        fetchMeta();
      }, 1800);
    }
  };

  const handlePrivacyStatus = (status) => {
    setPostVisibility(status);
    if (status === "Friends Specific") {
      $("#FriendsSpecificModal").modal("show");
      setShowModal(false);
    }
  };

  const handleRemovePostTitle = () => {
    setEditorState(EditorState.createEmpty());
  };
  return (
    <>
      <div className="status-box px-4 py-3 bg-white mb-3">
        <div className="status-category pt-2 pb-3">
          <a href="#" className="active">
            <img src="../assets/img/icon/status.svg" className="me-1" />
            Status
          </a>
          {pageId ? (
            <NavLink to={"/page/blog/" + pageId}>
              <img src="../assets/img/icon/blog.svg" className="me-1" />
              Blog
            </NavLink>
          ) : (
            ""
          )}

          {groupId ? (
            <NavLink to={"/group/blog/" + groupId}>
              <img src="../assets/img/icon/blog.svg" className="me-1" />
              Blog
            </NavLink>
          ) : (
            ""
          )}

          {pageId || groupId ? (
            ""
          ) : (
            <NavLink to="/post/blog">
              <img src="../assets/img/icon/blog.svg" className="me-1" />
              Blog
            </NavLink>
          )}

          <a
            href="#"
            onClick={(e) => {
              setShowModal(true);
              setActivePostCategory("Projects");
              setTimeout(() => {
                inputRef.current.focus();
              }, 700);
            }}
          >
            <img src="../assets/img/icon/news.svg" className="me-1" />
            Projects
          </a>
        </div>

        <div className="status-message">
          <div className="d-flex flex-column">
            <div
              className="mb-3 d-flex justify-content-between align-items-center"
              onClick={(e) => {
                setError("");
                setShowModal(true);
                setActivePostCategory("Status");
                setTimeout(() => {
                  inputRef.current.focus();
                }, 700);
              }}
            >
              <div>
                <img
                  src={Endpoints.baseUrl + userProfile}
                  alt=""
                  style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                />
                <span
                  className="fs-14"
                  style={{
                    fontWeight: "300",
                    marginLeft: "5px",
                    color: "#BAC4CE",
                  }}
                >
                  Share here what you are thinking...
                </span>
              </div>
            </div>

            <textarea
              name=""
              id=""
              // placeholder="What's on your mind?"
              rows="2"
              className="border-0 fs-14 text-darkgrey"
              onClick={(e) => {
                setError("");
                setShowModal(true);
                setActivePostCategory("Status");
                setTimeout(() => {
                  inputRef.current.focus();
                }, 700);
              }}
            />
          </div>
        </div>

        {/* post modal */}
        {showModal && (
          <div
            className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          ></div>
        )}
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          ref={modalOutsideRef}
          id="kidmodal"
          tabIndex="-1"
          aria-labelledby="kidmodal"
          aria-hidden="true"
          style={{ overflow: "auto" }}
        >
          <div
            ref={modalClickRef}
            className="modal-dialog  rounded-10 box-shadow  bg-white mt-md-5 post-share-container"
          >
            <div className="modal-content border-0 pb-lg-3">
              <div className="modal-header">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <h3 className="medium text-blue">
                    {activePostCategory === "Status"
                      ? "Add a post"
                      : "Add Project"}
                  </h3>
                  <button
                    type="button"
                    className="btn-close kids-close-icon cursor-pointer"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={modalRef}
                    onClick={() => {
                      setShowModal(false);
                      clearTimeout(compressTimeout);
                      setShowDiscardPopup(true);
                      setEmojiPickerOpen(false);
                      if (postTitle !== "" || postMedia.length > 0) {
                        setShowDiscardPopup(true);
                      } else {
                        setShowDiscardPopup(false);
                      }
                    }}
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="#949494"
                      width="22"
                      height="22"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <form
                action=""
                className="kids-form"
                encType="multipart/form-data"
                onSubmit={uploadPost}
              >
                <div className="modal-body pb-0">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <img
                            src={Endpoints.baseUrl + userProfile}
                            alt=""
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                            }}
                          />
                          <span
                            className="fs-14"
                            style={{ fontWeight: "400", marginLeft: "5px" }}
                          >
                            {user.fullName}
                          </span>
                        </div>
                        <div className="privacy-box fs-12">
                          <span className="ms-auto d-flex align-items-center">
                            {pageId || groupId ? (
                              <Link className="scope-options">
                                <img
                                  src="../assets/img/icon/icon-privacy.svg"
                                  alt="privacy"
                                />{" "}
                                {postVisibility}
                              </Link>
                            ) : (
                              <div className="w-100">
                                <Link
                                  className="dropdown-toggle w-100 scope-options"
                                  type="button"
                                  id="defaultDropdown"
                                  data-bs-toggle="dropdown"
                                  data-bs-auto-close="true"
                                  aria-expanded="false"
                                >
                                  <img
                                    src="../assets/img/icon/icon-privacy.svg"
                                    alt="privacy"
                                  />{" "}
                                  {postVisibility}
                                </Link>
                                <ul
                                  className="dropdown-menu dropdown-menu-end"
                                  aria-labelledby="defaultDropdown"
                                >
                                  {postVisibilites?.map((postVisible, i) => (
                                    <li
                                      key={postVisible.name}
                                      className={
                                        postVisibility == postVisible.value
                                          ? "audience-status py-2"
                                          : "py-2"
                                      }
                                    >
                                      <Link
                                        className="dropdown-item py-2 d-flex gap-2"
                                        onClick={() => {
                                          handlePrivacyStatus(
                                            postVisible.value
                                          );
                                        }}
                                      >
                                        <div className="d-flex gap-3">
                                          <img
                                            src={postVisible?.image}
                                            alt="privacy"
                                            style={{
                                              width: "32px",
                                            }}
                                          />
                                          <span className="fs-16">
                                            {postVisible.name}
                                            <br />
                                            <small className="fs-12">
                                              {postVisible.text}
                                            </small>
                                          </span>
                                        </div>
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}

                            {/* <select
                                name=""
                                id="status_privacy"
                                onChange={(e) => {
                                  handlePrivacyStatus(e.target.value);
                                }}
                              >
                                {postVisibilites.map((postVisible, i) => (
                                  <option key={postVisible.name} value={postVisible.value} selected={postVisibility == postVisible.value ? true : false }>
                                    {postVisible.name}
                                  </option>
                                ))}
                              </select> */}
                          </span>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          if (inputRef.current) inputRef.current.focus();
                        }}
                        className="py-3 post-editor-container postEditor"
                        style={{ cursor: "text" }}
                      >
                        <Editor
                          editorKey={"editor"}
                          editorState={editorState}
                          onChange={handleContentChange}
                          plugins={plugins}
                          ref={inputRef}
                          placeholder={"What's on your mind..."}
                        />
                        <MentionSuggestions
                          open={mentionOpen}
                          onOpenChange={onOpenChange}
                          suggestions={suggestions}
                          onSearchChange={onSearchChange}
                          entryComponent={EntryComponent}
                          onAddMention={() => {
                            // get the mention object selected
                          }}
                        />
                        <HashtagSuggestions
                          open={hashtagOpen}
                          onOpenChange={onHashtagOpenChange}
                          suggestions={hashtagSuggestions}
                          onSearchChange={onHashtagSearchChange}
                          entryComponent={EntryComponent}
                          onAddMention={() => {
                            // get the mention object selected
                          }}
                        />
                        <EmojiSuggestions />
                      </div>

                      {/* hello {
                        // selectedEmoji && <Emoji emojiStyle={EmojiStyle.APPLE} unified={selectedEmoji} size={22}/>
                        } */}

                      {previewDataLoader ? (
                        <div className="link-preview-container prviewBox mt-3">
                          <Loader />
                        </div>
                      ) : (
                        previewData && (
                          <div className="link-preview-container prviewBox mt-3">
                            <NavLink
                              to={`${previewData.url}`}
                              target="_blank"
                              // onClick={handleClick}
                            >
                              <div>
                                {(previewData.image || previewData.icon) && (
                                  <img
                                    className="img-fluid text-center mx-auto link-image"
                                    src={previewData.image || previewData.icon}
                                    ref={linkImageRef}
                                    alt=""
                                  />
                                )}

                                <h4 className="my-3">{previewData.title}</h4>
                                <p className="fs-14">
                                  {previewData.description ||
                                    previewData.description2}
                                </p>
                                <p className="text-uppercase fs-12 font-weight-bold">
                                  {previewData.url}
                                </p>
                              </div>
                            </NavLink>
                            <div className="d-flex justify-content-end align-items-start preview-cross">
                              <svg
                                height="20px"
                                width="20px"
                                fill="#777"
                                id="link-preview-cross"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setPreviewData(null);
                                }}
                                version="1.1"
                                viewBox="0 0 512 512"
                              >
                                <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                              </svg>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    {linkError && (
                      <span className="fs-12 text-danger">{linkError}</span>
                    )}
                  </div>
                </div>
                <div className="modal-footer p-0">
                  <div className="row w-100">
                    <div className="col-12">
                      <div className="status-attachment d-flex pb-2 pt-2 fs-12 mb-0 justify-content-between align-item-center">
                        <span
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="file"
                            id="gallery"
                            ref={imageRef}
                            className="d-none"
                            onChange={onImageSelection}
                            multiple
                            accept={type === "video" ? "video/mp4" : "image/*"}
                          />
                          <label
                            onClick={() => {
                              setType("image");
                            }}
                            htmlFor="gallery"
                            className="cursor-pointer add-media-post  px-2 py-1 rounded-15"
                          >
                            <img
                              src="../assets/img/icon/icon-image.svg"
                              alt="share"
                              className="post-image"
                            />{" "}
                            Image
                          </label>
                          <label
                            onClick={() => {
                              setType("video");
                            }}
                            htmlFor="gallery"
                            className="cursor-pointer add-media-post  px-2 py-1 rounded-15 mx-3"
                          >
                            <img
                              src="../assets/img/icon/icon-video-stream.svg"
                              alt="share"
                              className="post-image"
                            />{" "}
                            Video
                          </label>
                          <EmojiSelect />

                          {emojiPickerOpen && (
                            <EmojiPicker
                              onEmojiClick={handleEmojiClick}
                              ref={emojiRef}
                              lazyLoadEmojis={true}
                            />
                          )}
                        </span>
                        <button
                          type="submit"
                          className="btn"
                          disabled={
                            isPostCreating === true
                              ? true
                              : isCreating === true
                              ? true
                              : false
                          }
                        >
                          {isCreating
                            ? "Posting ..."
                            : activePostCategory === "Status"
                            ? "Add Post"
                            : "Add Project"}

                          {!isCreating ? (
                            <img
                              src="../../assets/img/icon/icon-right-arrow.svg"
                              alt=""
                              className="ms-2 d-inline-flex"
                            />
                          ) : (
                            <div
                              style={{ width: "1rem", height: "1rem" }}
                              className="spinner-border text-secondary"
                              role="status"
                            >
                              <span className="sr-only"></span>
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="col-12">
                      {error && (
                        <div>
                          <p className="text-danger">{error}</p>
                        </div>
                      )}

                      {postMedia?.length >= 1 && (
                        <div
                          className="prviewBox mt-3"
                          style={{ overflow: "auto", height: "300px" }}
                        >
                          <div className="d-flex justify-content-end">
                            <svg
                              height="15px"
                              width="15px"
                              id="Layer_1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setPostMedia("");
                                imageRef.current.value = "";
                              }}
                              version="1.1"
                              viewBox="0 0 512 512"
                            >
                              <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                            </svg>
                          </div>

                          <div className="d-flex gap-2 flex-wrap">
                            {postMedia.map((media, i) => {
                              if (media.type === "video/mp4") {
                                return (
                                  <div
                                    key={media.filename}
                                    className="single-post-images"
                                    style={{
                                      flex:
                                        (postMedia.length === 1 ||
                                          postMedia.length === 3) &&
                                        i === 0
                                          ? "1 0 100%"
                                          : i > 0 && i === postMedia.length - 1
                                          ? "0 0 50%"
                                          : "1 0 45%",
                                    }}
                                  >
                                    <video
                                      controls
                                      height="100%"
                                      width="100%"
                                      style={{ objectFit: "cover" }}
                                    >
                                      <source
                                        src={URL.createObjectURL(media)}
                                      />
                                    </video>
                                    <div className="d-flex justify-content-end mt-1">
                                      <svg
                                        height="15px"
                                        width="15px"
                                        id="Layer_1"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          removeSinglePicture(i);
                                        }}
                                        version="1.1"
                                        viewBox="0 0 512 512"
                                      >
                                        <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                                      </svg>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    key={media.filename}
                                    className="single-post-images"
                                    style={{
                                      flex:
                                        (postMedia.length === 1 ||
                                          postMedia.length === 3) &&
                                        i === 0
                                          ? "1 0 100%"
                                          : i > 0 && i === postMedia.length - 1
                                          ? "0 0 50%"
                                          : "1 0 45%",
                                    }}
                                  >
                                    <img
                                      src={URL.createObjectURL(media)}
                                      alt="preview-img"
                                      className="post-media mt-1 w-100 img-fluid post-preview-img"
                                    />
                                    <div className="d-flex justify-content-end">
                                      <svg
                                        height="15px"
                                        width="15px"
                                        id="Layer_1"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          removeSinglePicture(i);
                                          console.log("HELLO");
                                        }}
                                        version="1.1"
                                        viewBox="0 0 512 512"
                                      >
                                        <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                                      </svg>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="status-attachment pb-2 border-top pt-2 fs-12">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setType("image");
                  setActivePostCategory("Status");
                  setTimeout(() => {
                    inputRef.current.focus();
                  }, 700);
                }}
              >
                <label
                  htmlFor="gallery"
                  className="cursor-pointer add-media-post px-2 py-1 rounded-15"
                >
                  <img
                    src="../assets/img/icon/icon-image.svg"
                    alt="share-icon"
                    className="post-image"
                  />{" "}
                  Image
                </label>
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setType("video");
                  setActivePostCategory("Status");
                  setTimeout(() => {
                    inputRef.current.focus();
                  }, 700);
                }}
              >
                <label
                  htmlFor="gallery"
                  className="cursor-pointer add-media-post px-2 py-1 mx-2 rounded-15"
                >
                  <img
                    src="../assets/img/icon/icon-video-stream.svg"
                    alt="share-icon"
                    className="post-image"
                  />{" "}
                  Video
                </label>
              </span>
            </div>
            <div className="privacy-box fs-12">
              <span className="ms-auto d-flex align-items-center">
                {pageId || groupId ? (
                  <Link className="scope-options">
                    <img
                      src="../assets/img/icon/icon-privacy.svg"
                      alt="privacy"
                    />{" "}
                    {postVisibility}
                  </Link>
                ) : (
                  <div className="w-100">
                    <Link
                      className="dropdown-toggle w-100 scope-options"
                      type="button"
                      id="defaultDropdown"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="true"
                      aria-expanded="false"
                    >
                      <img
                        src="../assets/img/icon/icon-privacy.svg"
                        alt="privacy"
                      />{" "}
                      {postVisibility}
                    </Link>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="defaultDropdown"
                    >
                      {postVisibilites.map((postVisible, i) => (
                        <li
                          key={i}
                          className={
                            postVisibility == postVisible.value
                              ? "audience-status py-2"
                              : "py-2"
                          }
                        >
                          <Link
                            className="dropdown-item py-2 d-flex gap-2"
                            onClick={() => {
                              handlePrivacyStatus(postVisible.value);
                            }}
                          >
                            <div className="d-flex gap-3">
                              <img
                                src={postVisible?.image}
                                alt="privacy"
                                style={{
                                  width: "32px",
                                }}
                              />
                              <span className="fs-16 ">
                                {postVisible.name}
                                <br />
                                <small className="fs-12">
                                  {postVisible.text}
                                </small>
                              </span>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </span>
            </div>
          </div>
        </div>

        {showDiscardPopup && (
          <PostDiscardPopup
            type={activePostCategory}
            title={"Are you sure you want to discard this "}
            modalRef={modalRef}
            showModal={showModal}
            showDiscardPopup={showDiscardPopup}
            setShowDiscardPopup={setShowDiscardPopup}
            setShowModal={setShowModal}
            setPostTitle={setPostTitle}
            setPostMedia={setPostMedia}
            setPreviewData={setPreviewData}
            setLinkError={setLinkError}
            compressTimeout={compressTimeout}
            setError={setError}
            postTitle={postTitle}
            postMedia={postMedia}
            onSuccess={handleRemovePostTitle}
          />
        )}
      </div>
      <FriendsSpecificModal
        {...{
          setShowModal: setShowModal,
          setPostVisibility: setPostVisibility,
          specificFrined: specificFrined,
          setSpecificFrined: setSpecificFrined,
        }}
      />
    </>
  );
};

export default PostShare;
