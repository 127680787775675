import React from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import PageTab from "../SchoolsMenu/PageTab";
import { Helmet } from "react-helmet";

function UniversityMenu() {
  return (
    <Container header>
      <Helmet>
        <title>University</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-3">
            <PageTab
              {...{
                ActivePage: "University",
              }}
            />
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}

export default UniversityMenu;
