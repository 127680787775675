import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
import { DeleteApi } from "../../API/DeleteApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import ReactStars from "react-rating-stars-component";
import { OverlayTrigger, Popover } from "react-bootstrap";
const $ = window.jQuery;
export default function Item({ item, index, onSuccess }) {
  const location = useLocation();
  const Navigate = useNavigate();
  const [show, setShow] = useState(false);
  const userId = localStorage.getItem("userId");

  const handleWishlist = async (Id, categoryId) => {
    const obj = {
      course_id: Id,
    };

    try {
      let resp = await CommonAPi(Endpoints.AddToWishlist, obj);
      if (resp && resp.message) {
        // Navigate("/student-wishlist");
        ToastMessage.Success(resp.message);
        onSuccess(categoryId);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleAddToCart = async (id) => {
    console.log(id);

    const obj = {
      course_id: id,
    };

    try {
      let resp = await CommonAPi(Endpoints.AddToCart, obj);
      if (resp && resp.message) {
        Navigate("/cart");
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else if (
          e.response.data.message === "COURSE ALREADY ADDED THE CART"
        ) {
          Navigate("/cart");
          ToastMessage.Success(e.response.data.message);
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleRemoveWishlist = async (Id, categoryId) => {
    try {
      let resp = await DeleteApi(Endpoints.RemoveToWishlist + "/" + Id);
      if (resp && resp.message) {
        onSuccess(categoryId);
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const FormatSeconds = (seconds) => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const remainingSeconds = seconds % 60;
    const parts = [];

    if (days > 0) {
      parts.push(`${days} day${days > 1 ? "s" : ""}`);
    }
    if (hours > 0) {
      parts.push(`${hours} hr${hours > 1 ? "s" : ""}`);
    }
    if (minutes > 0) {
      parts.push(`${minutes} min${minutes > 1 ? "s" : ""}`);
    }
    if (remainingSeconds > 0) {
      parts.push(`${remainingSeconds} sec${remainingSeconds > 1 ? "s" : ""}`);
    }

    if (parts.length === 0) {
      return "0 sec";
    } else {
      return parts.join(", ");
    }
  };

  const handleOnMouseEnter = (event) => {
    setShow(true);
  };
  const handleOnMouseLeave = () => {
    setShow(false);
  };

  const ref = useRef(null);
  return (
    <>
      <OverlayTrigger
        //trigger={['hover', 'focus']}
        show={show} // Control trigger behavior with show instead of trigger.
        placement="auto-end"
        container={ref}
        overlay={
          <Popover
            id={"#a" + index}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          >
            <Popover.Body>
              <div className=" popover-box rounded-15 border bg-white">
                <div className="p-3">
                  <div className="d-flex align-items-center justify-content-between ">
                    <span className="bg-lightbrown text-brown fs-12 medium py-1 px-3 rounded-pill">
                      {item?.course_category?.name}
                    </span>
                    <div className="d-flex w-100 gap-2 justify-content-end align-items-center">
                      <div className="course-rating d-flex ">
                        <ReactStars
                          count={5}
                          edit={false}
                          value={item?.avgRating}
                          size={18}
                          isHalf={true}
                          char={
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="16px"
                              alt=""
                            />
                          }
                          emptyIcon={
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="16px"
                              alt=""
                            />
                          }
                          halfIcon={
                            <img
                              src="../assets/img/icon/state-half.svg"
                              width="16px"
                              alt=""
                            />
                          }
                          filledIcon={
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="16px"
                              alt=""
                            />
                          }
                          activeColor="#ffd700"
                          activeBackgroundColor="#ffd700"
                        />
                      </div>
                      <span className="fs-12 medium text-gray d-inline-block">
                        ({item.totalRatings})
                      </span>
                    </div>
                  </div>
                  <h5 className="medium py-2 px-0">{item.title}</h5>
                  <div className="d-flex gap-2 align-items-center pb-2">
                    {item?.creatorId === null ? (
                      <img
                        src="/assets/img/profile.png"
                        alt=""
                        width="30"
                        height="30"
                        className="rounded-circle"
                      />
                    ) : (
                      <img
                        src={
                          Endpoints.baseUrl +
                          item?.creatorId?.user_profile?.profileImg
                        }
                        alt=""
                        width="30"
                        height="30"
                        className="rounded-circle"
                      />
                    )}
                    <div>
                      <span className="text-gray fs-10 d-block">Course by</span>
                      <span className="fs-12 text-brown d-block">
                        {item?.creatorId !== null
                          ? item?.creatorId?.fullName
                          : " Rakesh Kumar"}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center gap-1">
                      <img
                        src="../assets/img/icon/icon-students-blue.svg"
                        width="13"
                        height="13"
                        className="text-blue"
                        alt=""
                      />
                      <h6 className="fs-12 medium"> {item.totalStudents} </h6>
                      <span className="text-gray fs-12">Students</span>
                    </div>
                    <div className="d-flex align-items-center gap-1">
                      <img
                        src="../assets/img/icon/icon-levels-blue.svg"
                        width="13"
                        height="13"
                        className="text-blue"
                        alt=""
                      />
                      <h6 className="fs-12 medium"> Level </h6>
                      <span className="text-gray fs-12">{item.level}</span>
                    </div>
                    <div className="d-flex align-items-center gap-1">
                      <img
                        src="../assets/img/icon/icon-time-blue.svg"
                        width="13"
                        height="13"
                        className="text-blue"
                        alt=""
                      />
                      <h6 className="fs-12 medium"> Time </h6>
                      <span className="text-gray fs-12">
                        {FormatSeconds(item.totalTimeLength)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="border-top pt-3 px-3">
                  <h6 className="fs-14 pb-3 medium">What you’ll learn:</h6>
                  {item?.objective?.length > 0 ? (
                    <ul>
                      {item?.objective?.map((item, index) => {
                        if (index > 2) {
                          return false;
                        }
                        return <li key={index}>{item}</li>;
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
                <div className="border-top p-3">
                  <div className="course-price pb-2 medium fs-18 text-center pb-3">
                    {item?.courseType === "Paid" ? (
                      <>
                        <span className="text-blue">
                          ₹ {item.discountedPrice}
                        </span>
                        <span className="ms-1 text-lightgray text-decoration-line-through">
                          ₹ {item?.price}
                        </span>
                      </>
                    ) : (
                      <div
                        className=" course-price pb-2 medium fs-18"
                        style={{ textAlign: "left" }}
                      >
                        <ins className="text-blue">Free</ins>
                      </div>
                    )}

                    {item?.discountPercent !== null &&
                    item?.courseType === "Paid" ? (
                      <span className="ms-2 bg-lightbrown text-brown fs-10 medium py-1 px-2 rounded-pill">
                        {item?.discountPercent?.toFixed(2)}% Off
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {userId == item?.user?.id ? (
                    ""
                  ) : (
                    <>
                      {item?.hasPurchased == true ? (
                        <Link
                          to={"/start-learning/" + item?.id}
                          className="btn fs-14 medium w-100 mb-2"
                        >
                          Start Learning
                        </Link>
                      ) : (
                        <a
                          onClick={() => {
                            handleAddToCart(item?.id);
                          }}
                          className="btn fs-14 medium w-100 mb-2"
                        >
                          <img
                            src="assets/img/icon/icon-cart-white.svg"
                            width="14"
                            alt=""
                          />{" "}
                          Add to Cart
                        </a>
                      )}
                    </>
                  )}

                  <Link
                    to={"/course-detail/" + item.slug}
                    target={"_blank"}
                    className="btn bg-lightbrown text-brown fs-14 medium rounded-pill w-100"
                  >
                    View Course Detail
                  </Link>
                </div>
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <div
          ref={ref}
          className={
            location.pathname.split("/")[1] === "course-listing"
              ? "col-md-4 col-sm-6 mb-4"
              : "col-lg-3 col-md-4 col-sm-6 mb-4"
          }
        >
          <Link
            to={"/course-detail/" + item.slug}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          >
            <div className="course-item mainmenu-page-item">
              <div className="mainmenu-item-action position-absolute end-0 top-0 m-2 p-1">
                {item.isWishlisted > 0 ? (
                  <a
                    href="#"
                    onClick={() => {
                      handleRemoveWishlist(item.isWishlisted, item.categoryId);
                    }}
                    aria-haspopup="true"
                    aria-label="teacher_1"
                    data-bs-toggle="dropdown"
                    className="d-flex align-items-center justify-content-center rounded-circle bg-eee p-2 "
                  >
                    <img
                      src="../assets/img/icon/icon-wishlist-fill.svg"
                      width="12"
                      alt=""
                    />
                  </a>
                ) : (
                  <a
                    href="#"
                    onClick={() => {
                      handleWishlist(item?.id, item.categoryId);
                    }}
                    aria-haspopup="true"
                    aria-label="teacher_1"
                    data-bs-toggle="dropdown"
                    className="d-flex align-items-center justify-content-center rounded-circle bg-eee p-2"
                  >
                    <img
                      src="../assets/img/icon/icon-wishlist.svg"
                      width="12"
                      alt=""
                    />
                  </a>
                )}
                <ul
                  className="popup-menu shadow rounded-1 text-start"
                  role="menu"
                  id="teacher_1"
                  aria-hidden="true"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      <svg
                        viewBox="0 0 24 24"
                        width="15"
                        height="15"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="css-i6dzq1"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                      Add
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <svg
                        viewBox="0 0 24 24"
                        width="15"
                        height="15"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="css-i6dzq1"
                      >
                        <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                        <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                      </svg>
                      Delete
                    </a>
                  </li>
                </ul>
              </div>
              {item.thumbnail === null ? (
                <img
                  src="../assets/img/course.png"
                  alt=""
                  className="course-img course-image"
                />
              ) : (
                <img
                  src={Endpoints.baseUrl + item.thumbnail}
                  alt=""
                  className="course-img course-image"
                />
              )}
              <div className="p-2">
                <div className="d-flex align-items-center justify-content-between ">
                  <span className="bg-lightbrown text-brown fs-12 medium py-1 px-3 rounded-pill">
                    {item?.course_category?.name}
                  </span>
                  <div className="d-flex w-100 gap-2 justify-content-end align-items-center">
                    <div className="course-rating d-flex ">
                      {item.avgRating !== null ? (
                        <ReactStars
                          count={5}
                          edit={false}
                          value={item.avgRating}
                          size={18}
                          isHalf={true}
                          char={
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="16px"
                              alt=""
                            />
                          }
                          emptyIcon={
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="16px"
                              alt=""
                            />
                          }
                          halfIcon={
                            <img
                              src="../assets/img/icon/state-half.svg"
                              width="16px"
                              alt=""
                            />
                          }
                          filledIcon={
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="16px"
                              alt=""
                            />
                          }
                          activeColor="#ffd700"
                          activeBackgroundColor="#ffd700"
                        />
                      ) : (
                        <ReactStars
                          count={5}
                          edit={false}
                          value={0}
                          size={18}
                          isHalf={true}
                          char={
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="16px"
                              alt=""
                            />
                          }
                          emptyIcon={
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="16px"
                              alt=""
                            />
                          }
                          halfIcon={
                            <img
                              src="../assets/img/icon/state-half.svg"
                              width="16px"
                              alt=""
                            />
                          }
                          filledIcon={
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="16px"
                              alt=""
                            />
                          }
                          activeColor="#ffd700"
                          activeBackgroundColor="#ffd700"
                        />
                      )}
                    </div>

                    <span className="fs-12 medium text-gray d-inline-block">
                      ({item.totalRatings})
                    </span>
                  </div>
                </div>

                <h5 className="medium py-2 px-0">
                  {item?.title?.length <= 22
                    ? item?.title
                    : item?.title.substr(0, 22) + "..."}
                </h5>

                <div className="d-flex gap-1 align-items-center">
                  {item?.creatorId === null ? (
                    <img
                      src=" ../assets/img/picture.png"
                      alt=""
                      width="16"
                      height="16"
                      className="rounded-circle"
                    />
                  ) : item?.creatorId?.user_profile?.profileImg === null ? (
                    <img
                      src=" ../assets/img/picture.png"
                      alt=""
                      width="16"
                      height="16"
                      className="rounded-circle"
                    />
                  ) : (
                    <img
                      src={
                        Endpoints.baseUrl +
                        item?.creatorId?.user_profile?.profileImg
                      }
                      alt=""
                      width="16"
                      height="16"
                      className="rounded-circle"
                    />
                  )}
                  <span className="fs-12 text-brown">
                    {item?.creatorId === null
                      ? "Rakesh kumar"
                      : item?.creatorId?.fullName}
                  </span>
                </div>
              </div>
              <div className="p-2 border-top">
                <div className="text-center">
                  {item?.courseType === "Paid" ? (
                    <div
                      className=" course-price pb-2 medium fs-18"
                      style={{ textAlign: "left" }}
                    >
                      <ins className="text-blue">
                        ₹{" "}
                        {item?.discountedPrice === null
                          ? item?.price === null
                            ? "0"
                            : item?.price
                          : item?.discountedPrice}
                      </ins>

                      {item?.discountedPrice === null ? (
                        ""
                      ) : (
                        <del
                          style={{ fontSize: ".9rem" }}
                          className="text-lightgray ps-2"
                        >
                          ₹ {item?.price === null ? "0" : item?.price}
                        </del>
                      )}
                    </div>
                  ) : (
                    <div
                      className=" course-price pb-2 medium fs-18"
                      style={{ textAlign: "left" }}
                    >
                      <ins className="text-blue">Free</ins>
                    </div>
                  )}
                  {item?.coupon?.length > 0
                    ? item?.coupon.map((item, index) => {
                        return (
                          <div className="d-flex coupon-landing-layout p-2 justify-content-between mt-1">
                            <div className="">
                              {item.type === "PERCENTAGE" ? (
                                <div className="fs-14">
                                  {item.value}% Coupon Inside
                                </div>
                              ) : (
                                <div className="fs-14">
                                  ₹ {item.value} Off Coupon Inside
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })
                    : ""}
                  {/* <Link to={"/course-detail/"+item.id} className="btn" target={"_blank"}>View Details</Link> */}
                </div>
              </div>
            </div>
          </Link>
        </div>
      </OverlayTrigger>
    </>
  );
}
