import React from "react";
import { useNavigate } from "react-router-dom";
import { Endpoints } from "../../../API/Endpoints";
import ReactStars from "react-rating-stars-component";

export default function CourseItem({ item, index, setItem }) {
  const navigate = useNavigate();
  const handleEditCourses = (Id) => {
    navigate("/update-courses-basic-information/" + Id);
  };
  const handleViewCourses = (Id) => {
    navigate("/mycourses-detail/" + Id);
    // window.open("/mycourses-detail/" + Id, '_blank');
  };
  const handleViewCoursesDetail = (Id) => {
    // navigate("/course-preview/" + Id);
    window.open("/course-preview/" + Id, "_blank");
  };
  return (
    <div class="col-lg-3 col-md-4 col-sm-6 mb-4" key={index}>
      <div class="course-item mainmenu-page-item">
        {item.thumbnail === null ? (
          <img
            src="../assets/img/course.png"
            alt=""
            class="course-img course-image"
          />
        ) : (
          <img
            src={Endpoints.baseUrl + item.thumbnail}
            alt=""
            class="course-img course-image"
          />
        )}
        <div class="p-2">
          <span class="bg-lightbrown text-brown fs-12 medium py-1 px-2 rounded-pill">
            {item?.course_category?.name}
          </span>
          <a
            href="#"
            onClick={() => {
              handleViewCoursesDetail(item.id);
            }}
            class="text-black medium"
          >
            <h5 class="medium pt-2 px-0">
              {item?.title?.length <= 20
                ? item?.title
                : item?.title.substr(0, 20) + "..."}
            </h5>
          </a>
        </div>

        <div class="border-top border-bottom p-2">
          <div class="d-flex w-100 gap-2  align-items-center">
            <span class="fs-14">
              {item.avgRating === null ? 0 : parseInt(item.avgRating)}
            </span>
            <div class="course-rating d-flex mycourse-rating">
              <ReactStars
                count={5}
                edit={false}
                value={parseInt(item.avgRating)}
                size={24}
                isHalf={true}
                char={
                  <img
                    src="../assets/img/icon/state-null.svg"
                    width="15"
                    alt=""
                  />
                }
                emptyIcon={
                  <img
                    src="../assets/img/icon/state-null.svg"
                    alt=""
                    width="15"
                  />
                }
                halfIcon={
                  <img
                    src="../assets/img/icon/state-half.svg"
                    salt=""
                    width="15"
                  />
                }
                filledIcon={
                  <img
                    src="../assets/img/icon/State-fill.svg"
                    alt=""
                    width="15"
                  />
                }
                activeColor="#ffd700"
              />
            </div>
            <span class="fs-12 medium text-gray d-inline-block">
              ({parseInt(item.totalReviews)})
            </span>
          </div>

          <div class="pt-1">
            <img src="../assets/img/icon/icon-students.svg" alt="" />
            <span class="fs-12 mx-1">{parseInt(item.totalStudents)}</span>
            <span class="fs-12 medium text-gray d-inline-block">Students</span>
          </div>
        </div>

        <div class="p-2 d-flex align-items-center justify-content-between pb-2">
          {item?.courseType === "Paid" ? (
            <div class=" course-price  medium ">
              <del class="text-lightgray fs-16">
                ₹ {item?.price ? item.price : 0}
              </del>
              <ins class="text-blue fs-18 ps-1">
                ₹ {item?.discountedPrice ? item.discountedPrice : 0}
              </ins>
            </div>
          ) : (
            <ins class="text-blue fs-18 ps-1">Free</ins>
          )}

          <div>
            <a href="#" id="course_menu1" data-bs-toggle="dropdown">
              <svg
                viewBox="0 0 24 24"
                width="18"
                height="18"
                stroke="#000"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="css-i6dzq1"
              >
                <circle cx="12" cy="12" r="1"></circle>
                <circle cx="19" cy="12" r="1"></circle>
                <circle cx="5" cy="12" r="1"></circle>
              </svg>
            </a>

            <div
              class="dropdown-menu bg-white p-0"
              aria-labelledby="course_menu1"
            >
              <ul class="fs-14">
                <li class="p-3 border-bottom">
                  <a
                    href="#"
                    onClick={() => {
                      handleViewCoursesDetail(item.id);
                    }}
                    class="text-black medium"
                  >
                    <img src="../assets/img/icon/computer-black.svg" alt="" />
                    &nbsp; View Details
                  </a>
                </li>
                <li class="p-3 border-bottom">
                  <a
                    href="#"
                    onClick={() => {
                      handleViewCourses(item.id);
                    }}
                    class="text-black medium"
                  >
                    <img src="../assets/img/icon/computer-black.svg" alt="" />
                    &nbsp; View Statistics Details
                  </a>
                </li>

                <li class="p-3 border-bottom">
                  <a
                    href="#"
                    onClick={() => {
                      handleEditCourses(item.id);
                    }}
                    class="text-black medium"
                  >
                    <img src="../assets/img/icon/icon-edit-black.svg" alt="" />
                    &nbsp; Edit Course
                  </a>
                </li>

                <li class="p-3 ">
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteInfoModal-Course"
                    onClick={() => {
                      setItem(item.id);
                    }}
                    class="text-danger medium"
                  >
                    <img src="../assets/img/icon/icon-delete-red.svg" alt="" />
                    &nbsp; Delete Course
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
