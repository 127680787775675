import React, { useState, useRef, useEffect } from "react";
import { CommonAPi } from "../../API/CommonApi";
import ToastMessage from "../../Utils/ToastMessage";
import { Endpoints } from "../../API/Endpoints";
import { NavLink } from "react-router-dom";
import "../../Components/PostShare/PostShare";
import PostDiscardPopup from "../../Components/PostDiscardPopup/PostDiscardPopup";
import Loader from "../../Components/PostLoader/PostLoader";
import EmojiPicker from "emoji-picker-react";
import Resizer from "react-image-file-resizer";
const postVisibilites = [
  {
    name: "Friends",
    value: "Friends",
  },
  {
    name: "Public",
    value: "Public",
  },
  {
    name: "Friends Specific",
    value: "Friends Specific",
  },
];

export default function CreatePhotoItem({ onSuccess }) {
  const [postTitle, setPostTitle] = useState("");
  const [postMedia, setPostMedia] = useState([]);
  const [postVisibility, setPostVisibility] = useState("Public");
  const [activePostCategory, setActivePostCategory] = useState("Status");
  const [isPostCreating, setIsPostCreating] = useState(true);
  const userProfile = localStorage.getItem("userProfile");
  const user = JSON.parse(localStorage.getItem("user"));
  const [btnValue, setBtnValue] = useState("");
  const imageRef = useRef();
  const inputRef = useRef(null);
  const modalRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const modalClickRef = useRef();
  const modalOutsideRef = useRef();
  const [showDiscardPopup, setShowDiscardPopup] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [previewDataLoader, setPreviewDataLoader] = useState(false);
  const [type, setType] = useState("");
  const [error, setError] = useState("");
  const [linkError, setLinkError] = useState("");
  const linkImageRef = useRef();
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  let compressTimeout = null;
  const emojiRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutSideEmoji);
    return () => {
      document.removeEventListener("click", handleClickOutSideEmoji);
    };
  }, []);

  function handleClickOutSideEmoji(e) {
    if (emojiRef.current && !emojiRef.current.contains(e.target)) {
      setEmojiPickerOpen(false);
    }
  }

  useEffect(() => {
    if (showModal) {
      const handleClickOutSide = (e) => {
        if (
          modalClickRef.current &&
          !modalClickRef.current.contains(e.target)
        ) {
          setShowModal(false);
          setEmojiPickerOpen(false);
          if (postTitle !== "" || postMedia.length > 0) {
            setShowDiscardPopup(true);
          } else {
            setShowDiscardPopup(false);
          }
        }
      };
      modalOutsideRef?.current?.addEventListener("click", handleClickOutSide);
      return () => {
        modalOutsideRef?.current?.removeEventListener(
          "click",
          handleClickOutSide
        );
      };
    }
  }, [showModal, postTitle, postMedia.length]);

  useEffect(() => {
    if (postTitle !== "" || postMedia.length > 0) {
      setIsPostCreating(false);
    } else {
      setIsPostCreating(true);
    }
  }, [postTitle, postMedia.length]);

  async function compressImage() {
    let promises = [];
    const quality = 70;
    for (const element of postMedia) {
      if (element.type.includes("image")) {
        let maxHeight, maxWidth;
        const image = new Image();
        const url = URL.createObjectURL(element);
        image.src = url;
        image.onload = () => {
          const { naturalWidth, naturalHeight } = image;
          maxHeight = naturalHeight;
          maxWidth = naturalWidth;
          URL.revokeObjectURL(url);
        };
        promises.push(
          new Promise((resolve) => {
            Resizer.imageFileResizer(
              element,
              maxWidth,
              maxHeight,
              "JPEG",
              quality,
              0,
              (file) => {
                // console.log(file)
                resolve(file);
              },
              "file"
            );
          })
        );
      }
    }
    const results = await Promise.all(promises);
    const compressed = [
      ...postMedia.filter((each) => each.type.includes("video")),
      ...results,
    ];
    return compressed;
  }

  const handleEmojiClick = (emojiData, MouseEvent) => {
    setSelectedEmoji(emojiData.unified);
    setPostTitle((prev) => prev + emojiData.emoji);
  };

  useEffect(() => {
    if (postMedia.length > 0) {
      setBtnValue("Add More ");
    } else {
      setBtnValue("Add ");
    }
  }, [postMedia.length]);
  const onImageSelection = (e) => {
    setError("");
    if (!showModal) {
      setShowModal(true);
    }
    setPreviewData(null);
    if (e.target.files) {
      if (e.target.files.length > 0) {
        let files = [];
        for (const element of e.target.files) {
          let file = element;
          if (file.type.includes("image") && type === "video") {
            setError("Please select videos only");
            imageRef.current.value = "";
            return;
          } else if (file.type.includes("video") && type === "image") {
            setError("Please select images only");
            imageRef.current.value = "";
            return;
          }
          if (
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/webp" ||
            file.type === "video/mp4"
          ) {
            files.push(file);
          } else {
            ToastMessage.Error("PELASE CHOOSE VALID FILE TYPES");
            return;
          }
        }
        setPostMedia([...postMedia, ...files]);
      } else {
        let imgs = e.target.files[0];
        if (!imgs) return;
        let file = [];
        if (imgs.type.includes("image") && type === "video") {
          setError("Please select videos only");
          imageRef.current.value = "";
          return;
        } else if (imgs.type.includes("video") && type === "image") {
          setError("Please select images only");
          imageRef.current.value = "";
          return;
        }
        if (
          imgs.type === "image/png" ||
          imgs.type === "image/jpg" ||
          imgs.type === "image/jpg" ||
          imgs.type === "image/webp" ||
          imgs.type === "video/mp4"
        ) {
          file.push(imgs);
        }
        // console.log(file)
        setPostMedia([...postMedia, ...file]);
      }
    }
    imageRef.current.value = "";
    // console.log(postMedia)
  };

  const uploadPost = async (e) => {
    setIsCreating(true);
    e.preventDefault();
    if (postTitle === "" && postMedia.length <= 0) {
      return ToastMessage.Error("PLEASE UPLOAD A POST OR A TITLE");
    }
    const result = await compressImage();
    const formData = new FormData();
    formData.append("title", postTitle);
    formData.append("post_visibility ", postVisibility);
    if (previewData && postMedia.length === 0) {
      formData.append("link_preview_data", JSON.stringify(previewData));
      formData.append("category", "Link");
    } else {
      formData.append("category", activePostCategory);
    }
    result.forEach((item) => formData.append("media", item));
    try {
      setIsPostCreating(true);
      let resp = await CommonAPi(Endpoints.createPost, formData);
      if (resp && resp.success === true) {
        setIsCreating(false);
        onSuccess(true);
        setPostMedia([]);
        setPostTitle("");
        setPreviewData(null);
        setEmojiPickerOpen(false);
        setLinkError("");
        imageRef.current.value = "";
        ToastMessage.Success(resp.message);
        modalRef.current.click();
        setShowDiscardPopup(false);
        setIsPostCreating(false);
      }
    } catch (e) {
      console.log(e);
      setIsCreating(false);
      setIsPostCreating(false);
      if (e?.response?.data?.message) {
        ToastMessage.Error(e.response.data.message);
      }
    }
  };

  const removeSinglePicture = (i) => {
    setPostMedia(postMedia.filter((media, idx) => idx !== i));
    imageRef.current.value = "";
  };

  const handleClick = (e) => {
    e.preventDefault();
    window.open("https://" + postTitle, "_blank");
  };

  const handleLink = (e) => {
    if (!/^[a-zA-Z/:\\]+$/i.test(e.key)) return;
    if (previewData) return;
    setLinkError("");
    let url = postTitle;
    if (url.startsWith("www.")) url = url.split("www.")[1];
    if (url.startsWith("http")) {
      url = new URL(url).hostname.replace("www.", "");
    }
    console.log(url);

    if (url === "") {
      setPreviewDataLoader(false);
      return;
    }
    const urlRegex =
      /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    // const urlRegex = /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/i
    // const urlRegex =/\b((?:https?:\/\/|www\.)\S+)\b/
    if (urlRegex.test(url)) {
      if (url.split(".")[1].length < 2) return;
      async function fetchMeta() {
        try {
          const resp = await CommonAPi(Endpoints.LinkPreview, { url: url });
          if (resp?.success) {
            setPreviewData(resp.data);
            setPreviewDataLoader(false);
            setLinkError("");
          }
        } catch (error) {
          setPreviewDataLoader(false);
          setLinkError("Cannot display the content of link");
          console.log(error);
        }
      }
      setTimeout(() => {
        setPreviewDataLoader(true);
        fetchMeta();
      }, 1800);
    }
  };

  return (
    <div class="col-lg-3 col-md-4 col-sm-6 mb-3">
      {/* post modal */}
      {showModal && (
        <div className={`modal-backdrop fade ${showModal ? "show" : ""}`}></div>
      )}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        ref={modalOutsideRef}
        id="kidmodal"
        tabindex="-1"
        aria-labelledby="kidmodal"
        aria-hidden="true"
        style={{ overflow: "auto" }}
      >
        <div
          ref={modalClickRef}
          className="modal-dialog  rounded-10 box-shadow  bg-white mt-md-5 post-share-container"
        >
          <div className="modal-content border-0 p-lg-4 pb-lg-3 p-md-3 p-2">
            <div className="d-flex align-items-center justify-content-between pb-3 border-bottom mb-1 w-100">
              <h3 className="medium text-blue">
                {activePostCategory === "Status" ? "Add a post" : "Add Project"}
              </h3>
              <button
                type="button"
                className="btn-close kids-close-icon cursor-pointer"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={modalRef}
                onClick={() => {
                  setShowModal(false);
                  clearTimeout(compressTimeout);
                  setShowDiscardPopup(true);
                  setEmojiPickerOpen(false);
                  if (postTitle !== "" || postMedia.length > 0) {
                    setShowDiscardPopup(true);
                  } else {
                    setShowDiscardPopup(false);
                  }
                }}
              >
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#949494"
                  width="22"
                  height="22"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="modal-body pb-0">
              <form
                action=""
                className="kids-form"
                enctype="multipart/form-data"
                onSubmit={uploadPost}
              >
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="mb-3">
                        <img
                          src={Endpoints.baseUrl + userProfile}
                          alt=""
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                          }}
                        />
                        <span
                          className="fs-14"
                          style={{ fontWeight: "500", marginLeft: "5px" }}
                        >
                          {user.fullName}
                        </span>
                      </div>
                      <div
                        className="privacy-box fs-12"
                        style={{ marginTop: "-20px" }}
                      >
                        <span className="ms-auto d-flex align-items-center">
                          <img
                            src="../assets/img/icon/icon-privacy.svg"
                            alt="privacy"
                          />
                          <select
                            name=""
                            id="status_privacy"
                            onChange={(e) => {
                              setPostVisibility(e.target.value);
                            }}
                          >
                            {postVisibilites.map((postVisible, i) => (
                              <option
                                key={postVisible.name}
                                value={postVisible.value}
                              >
                                {postVisible.name}
                              </option>
                            ))}
                          </select>
                        </span>
                      </div>
                    </div>
                    <textarea
                      name="input-text"
                      id="input-text"
                      placeholder={
                        activePostCategory === "Status"
                          ? "What's on your mind?"
                          : "Tell something about your project"
                      }
                      rows="3"
                      ref={inputRef}
                      value={postTitle}
                      style={{ border: "1px solid black !important" }}
                      onChange={(e) => setPostTitle(e.target.value)}
                      onKeyUp={handleLink}
                      className="border-0 fs-14 text-dark"
                    ></textarea>

                    {/* hello {
                        // selectedEmoji && <Emoji emojiStyle={EmojiStyle.APPLE} unified={selectedEmoji} size={22}/>
                        } */}

                    {previewDataLoader ? (
                      <Loader />
                    ) : (
                      previewData && (
                        <div className="link-preview-container prviewBox">
                          <NavLink
                            to={`${previewData.url}`}
                            onClick={handleClick}
                          >
                            <div>
                              {(previewData.image || previewData.icon) && (
                                <img
                                  className="img-fluid text-center mx-auto link-image"
                                  src={previewData.image || previewData.icon}
                                  ref={linkImageRef}
                                  alt=""
                                />
                              )}

                              <h4 className="my-3">{previewData.title}</h4>
                              <p className="fs-14">
                                {previewData.description ||
                                  previewData.description2}
                              </p>
                              <p className="text-uppercase fs-12 font-weight-bold">
                                {previewData.url}
                              </p>
                            </div>
                          </NavLink>
                          <div className="d-flex justify-content-end align-items-start preview-cross">
                            <svg
                              height="20px"
                              width="20px"
                              fill="#777"
                              id="link-preview-cross"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setPreviewData(null);
                              }}
                              version="1.1"
                              viewBox="0 0 512 512"
                            >
                              <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                            </svg>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  {linkError && (
                    <span className="fs-12 text-danger">{linkError}</span>
                  )}

                  <div className="status-attachment d-flex pb-2 border-top pt-2 fs-12 mb-0 mt-3">
                    <span style={{ cursor: "pointer" }}>
                      <input
                        type="file"
                        id="gallery"
                        ref={imageRef}
                        className="d-none"
                        onChange={onImageSelection}
                        multiple
                        accept={type === "video" ? "video/mp4" : "image/*"}
                      />
                      <label
                        onClick={() => {
                          setType("image");
                        }}
                        htmlFor="gallery"
                        className="cursor-pointer add-media-post  px-2 py-1 rounded-15"
                      >
                        <img
                          src="../assets/img/icon/icon-image.svg"
                          alt="share"
                          className="post-image"
                        />{" "}
                        Image
                      </label>
                      <label
                        onClick={() => {
                          setType("video");
                        }}
                        htmlFor="gallery"
                        className="cursor-pointer add-media-post  px-2 py-1 rounded-15 mx-3"
                      >
                        <img
                          src="../assets/img/icon/icon-video-stream.svg"
                          alt="share"
                          className="post-image"
                        />{" "}
                        Video
                      </label>
                      <label
                        onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}
                        className="cursor-pointer add-media-post  px-2 py-1 rounded-15"
                      >
                        <img
                          style={{ marginRight: "4px" }}
                          className="post-image"
                          src="../assets/img/icon/emoji_icon.png"
                          alt=""
                        />
                        Emoji
                      </label>

                      {emojiPickerOpen && (
                        <EmojiPicker
                          onEmojiClick={handleEmojiClick}
                          ref={emojiRef}
                        />
                      )}
                    </span>
                  </div>

                  {error && (
                    <div>
                      <p className="text-danger">{error}</p>
                    </div>
                  )}

                  {postMedia?.length >= 1 && (
                    <div
                      className="prviewBox mt-3"
                      style={{ overflow: "auto", height: "300px" }}
                    >
                      <div className="d-flex justify-content-end">
                        <svg
                          height="15px"
                          width="15px"
                          id="Layer_1"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setPostMedia("");
                            imageRef.current.value = "";
                          }}
                          version="1.1"
                          viewBox="0 0 512 512"
                        >
                          <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                        </svg>
                      </div>

                      <div className="d-flex gap-2 flex-wrap">
                        {postMedia.map((media, i) => {
                          if (media.type === "video/mp4") {
                            return (
                              <div
                                key={media.filename}
                                className="single-post-images"
                                style={{
                                  flex:
                                    (postMedia.length === 1 ||
                                      postMedia.length === 3) &&
                                    i === 0
                                      ? "1 0 100%"
                                      : i > 0 && i === postMedia.length - 1
                                      ? "0 0 50%"
                                      : "1 0 45%",
                                }}
                              >
                                <video
                                  controls
                                  height="100%"
                                  width="100%"
                                  style={{ objectFit: "cover" }}
                                >
                                  <source src={URL.createObjectURL(media)} />
                                </video>
                                <div className="d-flex justify-content-end mt-1">
                                  <svg
                                    height="15px"
                                    width="15px"
                                    id="Layer_1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      removeSinglePicture(i);
                                    }}
                                    version="1.1"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                                  </svg>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                key={media.filename}
                                className="single-post-images"
                                style={{
                                  flex:
                                    (postMedia.length === 1 ||
                                      postMedia.length === 3) &&
                                    i === 0
                                      ? "1 0 100%"
                                      : i > 0 && i === postMedia.length - 1
                                      ? "0 0 50%"
                                      : "1 0 45%",
                                }}
                              >
                                <img
                                  src={URL.createObjectURL(media)}
                                  alt="preview-img"
                                  className="post-media mt-1 w-100 img-fluid"
                                />
                                <div className="d-flex justify-content-end">
                                  <svg
                                    height="15px"
                                    width="15px"
                                    id="Layer_1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      removeSinglePicture(i);
                                      console.log("HELLO");
                                    }}
                                    version="1.1"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                                  </svg>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  )}

                  <div className="col-md-12 text-end">
                    <button
                      type="submit"
                      className="btn fs-14 mt-4"
                      disabled={
                        isPostCreating === true
                          ? true
                          : isCreating === true
                          ? true
                          : false
                      }
                    >
                      {isCreating
                        ? "Please wait..."
                        : activePostCategory === "Status"
                        ? "Add Post"
                        : "Add Project"}

                      {!isCreating ? (
                        <img
                          src="../../assets/img/icon/icon-right-arrow.svg"
                          alt=""
                        />
                      ) : (
                        <div
                          style={{ width: "1rem", height: "1rem" }}
                          className="spinner-border text-secondary"
                          role="status"
                        >
                          <span className="sr-only"></span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="status-attachment d-flex pb-2  pt-2 fs-12 upload-profile-photos px-md-3 px-2 ">
        <span
          className="text-center"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setType("image");
            setActivePostCategory("Status");
            setTimeout(() => {
              inputRef.current.focus();
            }, 700);
          }}
        >
          <label
            for="gallery"
            className="cursor-pointer add-media-post px-2 py-1 rounded-15"
          >
            <img
              src="../assets/img/icon/icon-add-image.svg"
              alt=""
              class=" cursor-pointer"
            />
            <h6 class="fs-14 medium text-brown py-2">
              Upload an image or album
            </h6>
            <h6 class="fs-14 regular text-gray">
              It’s only take a few seconds!
            </h6>
          </label>
        </span>

        {/* <div class="upload-profile-photos px-md-3 px-2  cursor-pointer">
        <div class="text-center" >
            <input type="file" id="upload-profile" class="d-none" />
            <label for="upload-profile">
                <img src="../assets/img/icon/icon-add-image.svg" alt="" class=" cursor-pointer" />
                <h6 class="fs-14 medium text-brown py-2">Upload an image or album</h6>
                <h6 class="fs-14 regular text-gray">It’s only take a few seconds!</h6>
            </label>
        </div>
    </div> */}
        {/* <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setType("video");
            setActivePostCategory("Status");
            setTimeout(() => {
              inputRef.current.focus();
            }, 700);
          }}
        >
          <label
            for="gallery"
            className="cursor-pointer add-media-post px-2 py-1 mx-2 rounded-15"
          >
            <img
              src="../assets/img/icon/icon-video-stream.svg"
              alt="share-icon"
              className="post-image"
            />{" "}
            Video
          </label>
        </span> */}
      </div>

      {showDiscardPopup && (
        <PostDiscardPopup
          type={activePostCategory}
          title={"Are you sure you want to discard this "}
          modalRef={modalRef}
          showModal={showModal}
          showDiscardPopup={showDiscardPopup}
          setShowDiscardPopup={setShowDiscardPopup}
          setShowModal={setShowModal}
          setPostTitle={setPostTitle}
          setPostMedia={setPostMedia}
          setPreviewData={setPreviewData}
          setLinkError={setLinkError}
          compressTimeout={compressTimeout}
          setError={setError}
          postTitle={postTitle}
          postMedia={postMedia}
        />
      )}
    </div>
  );
}
