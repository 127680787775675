import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { useGoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import jwtDecode from "jwt-decode";
import validator from 'validator';
import "./Signup.css";
import { useEffect } from "react";
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import {Helmet} from "react-helmet";
const $ = window.jQuery;

function Signup() {
  const [fname, setFname] = useState("");
  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [usertype, setUsertype] = useState("");
  const [email, setEmail] = useState("");
  const [hidden, setHidden] = useState(true);
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [userExistResponse, setUserExistResponse] = useState("");
  const [googleReqData, setGoogleReqData] = useState("");
  const [facebookReqData, setFacebookReqData] = useState("");
  const [linkedinReqData, setLinkedinReqData] = useState("");
  const [modalButtonValue, setModalButtonValue] = useState("Save");
  const [users_type, setUsersType] = useState("");
  const closeModal = useRef();
  const userDetailsRef = useRef();
  const openPopupRef = useRef();

  const [fullnameError, setFullnameError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [usertypeError, setUsertypeError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [Error, setError] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [hasOneSplChar, setHasOneSplChar] = useState(false);
  const [hasOneNumber, setHasOneNumber] = useState(false);
  const [isOneLower, setIsOneLower] = useState(false);
  const [isOneUpper, setIsOneUpper] = useState(false);
  const [isEightLength, setIsEightLength] = useState(false);
  const [greenBorder, setGreenBorder] = useState({username: false, email: false, mobile: false })
  const style = {
    border: "1px solid red",
  };

  const greenBorderStyle = {
    border: "3px solid lime"
  }

  const history = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.exp < Date.now() / 1000) {
        localStorage.clear();
        history("/signin");
      } else {
        history("/mainfeed")
      }
    }
  }, []);

  const validatePassword = () => {
    if (password.length >= 8) {
      setIsEightLength(true)
    } else {
      setIsEightLength(false)
    }

    if (password.match(/[a-z]/g)) {
      setIsOneLower(true)
    } else {
      setIsOneLower(false)
    }

    if (password.match(/[A-Z]/g)) {
      setIsOneUpper(true)
    } else {
      setIsOneUpper(false)
    }

    if (password.match(/[0-9]/g)) {
      setHasOneNumber(true)
    } else {
      setHasOneNumber(false)
    }

    if (password.match(/[!@#\$%\^\&*\)\(+=._-]/g)) {
      setHasOneSplChar(true)
    } else {
      setHasOneSplChar(false)
    }
  }


  const handleValidation = async (e, value, field) => {
    e.preventDefault();

    if (!value && field === "name") {
      setFullnameError("Please enter name");
    } else if (value && field === "name") {
      setFullnameError("");
    }


    if (!value && field === "username") {
      setUsernameError("Please enter username");
    } else if (value && field === "username") {
      checkUserName();
      setUsernameError("");
    }

    if (!value && field === "usertype") {
      setUsertypeError("Please select user type");
    } else if (value && field === "usertype") {
      setUsertypeError("");
    }


    if (!value && field === "email") {
      setEmailError("Please enter email");
    } else if (value && field === "email") {

      if (validator.isEmail(value, {})) {
        checkEmail();
        setEmailError("");
      } else {
        setEmailError("Please enter valid email");
      }

    }


    if (!value && field === "mobile") {
      setMobileError("Please enter mobile number");
    } else if (value && field === "mobile") {
      if(value.length > 10){
        setMobileError("Please enter valid mobile number");
      }else{
        checkMobile();
        setMobileError("");
      }
     

    }

    if (!value && field === "password") {
      setPasswordError("Please enter password");
    } else if (value && field === "password") {
      if (validator.isStrongPassword(value, {
        minLength: 8, minLowercase: 1,
        minUppercase: 1, minNumbers: 1, minSymbols: 1
      })) {
        setPasswordError("");
      } else {
        setPasswordError("Your password must be at least 8 characters including a lowercase letter, an uppercase letter, and a number");
      }
    }
  };

  const handleSubmit = async (e) => {
    try {
      setModalButtonValue("Please wait..");
      e.preventDefault();
      if (username === "" || mobile === "" || users_type === "") {
        ToastMessage.Error("PLEASE ENTER USERNAME AND MOBILE AND USERS_TYPE");
        setModalButtonValue("Save");
        return;
      } else {
        if (googleReqData) {
          setFacebookReqData(null);
          setLinkedinReqData(null);
          const resp = await CommonAPi(Endpoints.GoogleAuth, {
            googleResponse: userExistResponse,
            userdetails: {
              username,
              mobile,
              users_type,
            },
          });
          setGoogleReqData(null);
          setModalButtonValue("Save");
          if (resp.success === false) {
            console.log(resp);
            let errors = "";
            setTimeout(() => {
              for (let i = 0; i < resp.message.length; i++) {
                errors += resp.message[i] + ", ";
              }
              ToastMessage.Error(errors);
            }, 10);
          }
          if (resp.success === true) {
            closeModal.current.click();
            window.localStorage.setItem("token", resp.data.access_token);
            window.localStorage.setItem(
              "user",
              JSON.stringify(resp?.data?.user)
            );
            window.localStorage.setItem(
              "parentData",
              JSON.stringify(resp?.data?.user)
            );
            window.localStorage.setItem("userId", resp?.data?.user?.id);
            window.localStorage.setItem(
              "ProfileCompletion",
              resp?.data?.user?.profileCompleteness
            );
            window.localStorage.setItem(
              "userProfile",
              resp?.data?.user?.user_profile?.profileImg
            );
            window.localStorage.setItem(
              "userProfileCoverImage",
              resp?.data?.user?.user_profile?.coverImg
            );
            if (resp.data.user?.children?.length > 0) {
              history("/select-profile" + "/" + resp.data.user.id);
            } else if (resp.data.user?.hasInterest === false) {
              history("/select-interests" + "/" + resp.data.user.id);
            } else {
              history("/mainfeed");
            }
          }
        } else if (facebookReqData) {
          setGoogleReqData(null);
          setLinkedinReqData(null);
          console.log(facebookReqData);
          const data = await CommonAPi(Endpoints.facebookAuth, {
            userID: facebookReqData.userID,
            accessToken: facebookReqData.accessToken,
            userdetails: {
              username,
              mobile,
              users_type,
            },
          });
          setFacebookReqData(null);
          if (data.success === true) {
            closeModal.current.click();
            window.localStorage.setItem("token", data.data.access_token);
            window.localStorage.setItem(
              "user",
              JSON.stringify(data?.data?.user)
            );
            window.localStorage.setItem(
              "parentData",
              JSON.stringify(data?.data?.user)
            );
            window.localStorage.setItem("userId", data?.data?.user?.id);
            window.localStorage.setItem(
              "ProfileCompletion",
              data?.data?.user?.profileCompleteness
            );
            window.localStorage.setItem(
              "userProfile",
              data?.data?.user?.user_profile?.profileImg
            );
            window.localStorage.setItem(
              "userProfileCoverImage",
              data?.data?.user?.user_profile?.coverImg
            );
            if (data.data.user?.children?.length > 0) {
              history("/select-profile" + "/" + data.data.user.id);
            } else if (data.data.user?.hasInterest === false) {
              history("/select-interests" + "/" + data.data.user.id);
            } else {
              history("/mainfeed");
            }
          } else if (linkedinReqData) {
            setFacebookReqData(null);
            setGoogleReqData(null);
            console.log(linkedinReqData);
            const data = await CommonAPi(Endpoints.LinkedinAuth, {
              linkedinData: userExistResponse,
              userdetails: {
                username,
                mobile,
                users_type,
              },
            });
            setFacebookReqData(null);
            if (data.success === true) {
              closeModal.current.click();
              window.localStorage.setItem("token", data.data.access_token);
              window.localStorage.setItem(
                "user",
                JSON.stringify(data?.data?.user)
              );
              window.localStorage.setItem(
                "parentData",
                JSON.stringify(data?.data?.user)
              );
              window.localStorage.setItem("userId", data?.data?.user?.id);
              window.localStorage.setItem(
                "ProfileCompletion",
                data?.data?.user?.profileCompleteness
              );
              window.localStorage.setItem(
                "userProfile",
                data?.data?.user?.user_profile?.profileImg
              );
              window.localStorage.setItem(
                "userProfileCoverImage",
                data?.data?.user?.user_profile?.coverImg
              );
              if (data.data.user?.children?.length > 0) {
                history("/select-profile" + "/" + data.data.user.id);
              } else if (data.data.user?.hasInterest === false) {
                history("/select-interests" + "/" + data.data.user.id);
              } else {
                history("/mainfeed");
              }
            }
          }
        }
      }
    } catch (error) {
      setModalButtonValue("Save");
      console.log(error.message);
      let errors = "";
      for (let i = 0; i < error.response.data.message.length; i++) {
        errors += error.response.data.message[i] + "\n";
      }
      ToastMessage.Error(errors);
    }
  };

  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      setGoogleReqData(tokenResponse);
      if (!tokenResponse) return;
      try {
        const userResponse = await CommonAPi(Endpoints.GoogleAuth, {
          googleResponse: tokenResponse,
        });

        setModalButtonValue("Save");
        if (userResponse.success === false) {
          setTimeout(() => {
            openPopupRef.current.click();
          }, 10);
        }

        // console.log(userResponse);
        if (userResponse.success === true) {
          closeModal.current.click();
          window.localStorage.setItem("token", userResponse.data.access_token);
          window.localStorage.setItem(
            "user",
            JSON.stringify(userResponse?.data?.user)
          );
          window.localStorage.setItem(
            "parentData",
            JSON.stringify(userResponse?.data?.user)
          );
          window.localStorage.setItem("userId", userResponse?.data?.user?.id);
          window.localStorage.setItem(
            "ProfileCompletion",
            userResponse?.data?.user?.profileCompleteness
          );
          window.localStorage.setItem(
            "userProfile",
            userResponse?.data?.user?.user_profile?.profileImg
          );
          window.localStorage.setItem(
            "userProfileCoverImage",
            userResponse?.data?.user?.user_profile?.coverImg
          );
          if (userResponse.data.user?.children?.length > 0) {
            history("/select-profile" + "/" + userResponse.data.user.id);
          } else if (userResponse.data.user?.hasInterest === false) {
            history("/select-interests" + "/" + userResponse.data.user.id);
          } else {
            history("/mainfeed");
          }
        }
        setUserExistResponse(userResponse);
        if (userResponse.success === false) {
          openPopupRef.current.click();
        }
      } catch (e) {
        console.log(e);
        setModalButtonValue("Save");
      }
    },
    scope: "openid profile email phone",
  });

  const facebookLogin = async (response) => {
    console.log(response);
    if (!response) return null;
    setFacebookReqData(response);
    const userResponse = await CommonAPi(Endpoints.facebookAuth, response);
    if (userResponse.success === true) {
      closeModal.current.click();
      window.localStorage.setItem("token", userResponse.data.access_token);
      window.localStorage.setItem(
        "user",
        JSON.stringify(userResponse?.data?.user)
      );
      window.localStorage.setItem(
        "parentData",
        JSON.stringify(userResponse?.data?.user)
      );
      window.localStorage.setItem("userId", userResponse?.data?.user?.id);
      window.localStorage.setItem(
        "ProfileCompletion",
        userResponse?.data?.user?.profileCompleteness
      );
      window.localStorage.setItem(
        "userProfile",
        userResponse?.data?.user?.user_profile?.profileImg
      );
      window.localStorage.setItem(
        "userProfileCoverImage",
        userResponse?.data?.user?.user_profile?.coverImg
      );
      if (userResponse.data.user?.children?.length > 0) {
        history("/select-profile" + "/" + userResponse.data.user.id);
      } else if (userResponse.data.user?.hasInterest === false) {
        history("/select-interests" + "/" + userResponse.data.user.id);
      } else {
        history("/mainfeed");
      }
    }
    setUserExistResponse(userResponse);
    if (userResponse.success === false) {
      openPopupRef.current.click();
    }
  };

  const handleAppleResponse = async (response) => {
    console.log(response);
    if (!response) return;
    try {
      if (!response.error) {
        const resp = await CommonAPi(Endpoints.AppleAuth, response);
        if (resp.success) {
          console.log(resp);
        }
      }
    } catch (e) {
      console.log(e);
      setModalButtonValue("Save");
      setFacebookReqData(null);
      setLinkedinReqData(null);
      setGoogleReqData(null);
    }
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: process.env.REACT_APP_LINKEDIN_REDIRECT_URI,
    scope: "r_emailaddress r_liteprofile",
    onSuccess: async (code) => {
      console.log(code);
      setLinkedinReqData(code);
      try {
        const userResponse = await CommonAPi(Endpoints.LinkedinAuth, { code });
        if (userResponse.success === true) {
          closeModal.current.click();
          window.localStorage.setItem("token", userResponse.data.access_token);
          window.localStorage.setItem(
            "user",
            JSON.stringify(userResponse?.data?.user)
          );
          window.localStorage.setItem(
            "parentData",
            JSON.stringify(userResponse?.data?.user)
          );
          window.localStorage.setItem("userId", userResponse?.data?.user?.id);
          window.localStorage.setItem(
            "ProfileCompletion",
            userResponse?.data?.user?.profileCompleteness
          );
          window.localStorage.setItem(
            "userProfile",
            userResponse?.data?.user?.user_profile?.profileImg
          );
          window.localStorage.setItem(
            "userProfileCoverImage",
            userResponse?.data?.user?.user_profile?.coverImg
          );
          if (userResponse.data.user?.children?.length > 0) {
            history("/select-profile" + "/" + userResponse.data.user.id);
          } else if (userResponse.data.user?.hasInterest === false) {
            history("/select-interests" + "/" + userResponse.data.user.id);
          } else {
            history("/mainfeed");
          }
        }
        setUserExistResponse(userResponse);
        if (userResponse.success === false) {
          openPopupRef.current.click();
        }
      } catch (e) {
        console.log(e);
        setModalButtonValue("Save");
        ToastMessage.Error(e.response.data.message);
        setFacebookReqData(null);
        setLinkedinReqData(null);
        setGoogleReqData(null);
      }
    },
    onError: (error) => {
      console.log(error);
      setModalButtonValue("Save");
    },
  });
  const register = async (e) => {
    e.preventDefault();
    if (!fullname) {
      setFullnameError("Please enter name");
    } else {
      setFullnameError("");
    }

    if (!username) {

      setUsernameError("Please enter username");
    } else {

      setUsernameError("");
    }

    if (!usertype) {
      setUsertypeError("Please select user type");
    } else {
      setUsertypeError("");
    }


    if (!email) {
      setEmailError(" Please enter email ");
    } else {
      if (validator.isEmail(email, {})) {

        setEmailError("");
      } else {
        setEmailError("Please enter valid email");
        return false;
      }
    }


    if (!mobile) {
      setMobileError("Please enter mobile number");
    } else {
      if(mobile.length > 10){
        setMobileError("Please enter valid mobile number");
      }else{
        setMobileError("");
      }
    }

    if (!password) {
      setPasswordError("Please enter password");
    } else if (password) {

      if (validator.isStrongPassword(password, {
        minLength: 8, minLowercase: 1,
        minUppercase: 1, minNumbers: 1, minSymbols: 1
      })) {
        setPasswordError("");
      } else {
        setPasswordError("Your password must be at least 8 characters including a lowercase letter, an uppercase letter, and a number");
        return false;
      }

    }

    if (fullname && username && email && mobile && usertype && password) {
      let obj = {
        full_name: fullname,
        user_name: username,
        email: email,
        mobile_number: mobile,
        users_type: usertype,
        password: password
      }
      try {
        let resp = await CommonAPi(Endpoints.register, obj);
        if (resp && resp.success === true) {

          history('/signin')
          ToastMessage.Register();

        }

      } catch (e) {
        console.log("registerErr__", JSON.stringify(e.response, null, 4));
        if (e && e.response && e.response.data && e.response.data.message) {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const checkEmail = async () => {

    let obj = {
      email: email,
    }
    try {
      let resp = await CommonAPi(Endpoints.checkUser, obj);
      console.log(resp);
      if (resp && resp.success === false) {
        setEmailError(resp.message);
      }

      if(resp.success=== true){
        setEmailError("");
        setGreenBorder({ ...greenBorder, email: true})
      }

    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setEmailError(e.response.data.message);
      }
    }
  };
  const checkMobile = async () => {

    let obj = {
      mobile_number: mobile,
    }
    try {
      let resp = await CommonAPi(Endpoints.checkUser, obj);
      console.log(resp);
      if (resp && resp.success === false) {
        setMobileError(resp.message);
      }
       if(resp.success=== true){
        setMobileError("");
        setGreenBorder({ ...greenBorder,mobile: true})
      }

    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setMobileError(e.response.data.message);
      }
    }
  };

  const checkUserName = async () => {

    let obj = {
      username: username,
    }
    try {
      let resp = await CommonAPi(Endpoints.checkUser, obj);
      console.log(resp);
      if (resp && resp.success === false) {
        setUsernameError(resp.message);
      }
      if(resp.success=== true){
        setUsernameError("");
        setGreenBorder({ ...greenBorder,username: true})
      }

    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setUsernameError(e.response.data.message);
      }
    }
  };
  return (
    <div className="wrapper">
        <Helmet>
          <title>Signup</title>
      </Helmet>
      <div className="header fix-header position-fixed top-0 w-100 ">
        <div className="container ">
          <div className="row m-0">
            <div className="col-md-6 col-6">
              <div className="site-logo">
                <Link to="/">
                  <img src="assets/img/8inNet_logo 1.svg" alt="" />
                </Link>
              </div>
            </div>

            <div className="col-md-6 col-6 text-end">
              <div className="d-flex align-items-center justify-content-end">
                <h5 className="font-15 text-white regular pe-3 d-none d-sm-block">
                  Already have an account?
                </h5>
                <Link
                  className="btn btn-brown px-4"
                  to={"/signin"}
                // to={'/mainfeed'}
                // replace
                >
                  {" "}
                  Sign In
                </Link>
                {/* <a href="signin.php" className="btn btn-brown px-4">Sign In</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="signin-bg">
        <div className="container p-0">
          <div className="row m-0">
            <div className="col-md-6">
              <div className="py-5">
                <img src="assets/img/signup.png" alt="" />
              </div>
            </div>

            <div className="col-md-6 bg-primary py-md-5 py-4 signup-right">
              <div className="pt-md-5 px-md-5">
                <h1 className="semibold text-white">Sign Up</h1>
                <h5 className="text-white pb-3 regular">
                  Let’s create your account!!
                </h5>

                <form
                  action=""
                  className="signup-form needs-validation"
                  noValidate
                  onSubmit={register}
                  autoComplete="off"

                >
                  <div className="form-field">
                    <label className="text-white">I am a*</label>
                    <select name=""
                      className={usertypeError ? "is-danger" : ""}
                      id=""
                      onBlur={(e) => { handleValidation(e, e.target.value, "userType") }}
                      placeholder="I am a*"
                      onChange={(e) => setUsertype(e.target.value)}>
                      <option value="">I am a*</option>
                      <option value="Student">Student</option>
                      <option value="Parent">Parent</option>
                      <option value="Teacher">Teacher</option>
                      <option value="Professional">Professional</option>
                    </select>
                    {usertypeError && (
                      <div className="form-error">
                        {usertypeError}
                      </div>
                    )}
                  </div>

                  <div className="form-field">
                    <label className="text-white">Name*</label>
                    <input
                      type="text"
                      placeholder="Name*"
                      className={fullnameError ? "is-danger" : ""}
                      onBlur={(e) => { handleValidation(e, e.target.value, "name") }}
                      onChange={(e) => {
                        setFullname(e.target.value);
                      }}
                    />

                    {fullnameError && (
                      <div className="form-error">
                        {fullnameError}
                      </div>
                    )}
                  </div>

                  <div className="form-field">
                    <label className="text-white">Username*</label>
                    <input
                      type="text"
                      placeholder="Username*"
                      style={greenBorder.username === true ? greenBorderStyle : {}}
                      className={usernameError ? "is-danger" : ""}
                      onBlur={(e) => { handleValidation(e, e.target.value, "username") }}
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                    />
                    {usernameError && (
                      <div className="form-error">
                        {usernameError}
                      </div>
                    )}
                  </div>

                  <div className="form-field">
                    <label className="text-white">Email*</label>
                    <input
                      type="email"
                      placeholder="Email*"
                      style={greenBorder.email === true ? greenBorderStyle : {}}
                      className={emailError ? "is-danger" : ""}
                      onBlur={(e) => { handleValidation(e, e.target.value, "email") }}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    {emailError && (
                      <div className="form-error">
                        {emailError}
                      </div>
                    )}
                  </div>

                  <div className="form-field">
                    <label className="text-white">Mobile Number*</label>
                    <input
                      type="number"
                      placeholder="Mobile Number*"
                      style={greenBorder.mobile === true ? greenBorderStyle : {}}
                      className={mobileError ? "is-danger" : ""}
                      onBlur={(e) => { handleValidation(e, e.target.value, "mobile") }}
                      onChange={(e) => {
                        setMobile(e.target.value);
                      }}
                    />
                    {mobileError && (
                      <div className="form-error">
                        {mobileError}
                      </div>
                    )}
                  </div>

                  <div className="form-field password-container">
                    <label className="text-white">Password*</label>                  
                    <div className='d-flex align-items-center' style={{ position: 'relative' }}>
                      <input
                        type={hidden ? "password" : "text"}
                        placeholder="Password*"
                        data-toggle="popover"
                        autoComplete="off"
                        title="Password Strength"
                        id="password"
                        data-content="Enter Password..."
                        className={passwordError ? "is-danger" : ""}
                        onKeyUp={() => { validatePassword() }}
                        onFocus={() => setIsVisible(true)}
                        onBlur={(e) => { handleValidation(e, e.target.value, "password"); setIsVisible(false) }}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />

                      <div style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: 10
                      }} onClick={() => setHidden(!hidden)}>
                        {
                          hidden ?
                            <AiFillEyeInvisible size={25} />
                            :
                            <AiFillEye size={25} />
                        }
                      </div>
                    </div>
                    {passwordError && (
                      <div className="form-error">
                        {passwordError}
                      </div>
                    )}
                    {isVisible && (
                      <div className="password-tooltip">
                        <h6 className="mb-2">Your password must have:</h6>
                        <p className={`${isEightLength ? "text-green" : ""}`}>atleast 8 characters</p>
                        <p className={`${isOneUpper ? "text-green" : ""}`}>atleast 1 uppercase</p>
                        <p className={`${isOneLower ? "text-green" : ""}`}>atleast 1 lowercase</p>
                        <p className={`${hasOneNumber ? "text-green" : ""}`}>atleast 1 number</p>
                        <p className={`${hasOneSplChar ? "text-green" : ""}`}>atleast 1 speical character</p>
                      </div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-brown w-100 mt-2"
                  >
                    Register
                  </button>
                  <p className="fs-13 text-white pt-3 light">
                    By registering, you agree to the 8innet User Agreement,
                    Privacy Policy and Cookie Policy.
                  </p>

                  <p className="text-center fs-13 text-white pt-3 light">
                    Or continue with
                  </p>
                </form>
                <div className="text center d-flex align-items-center justify-content-center gap-3">
                  <a
                    href="#"
                    className="sign-with-icon"
                    onClick={() => login()}
                  >
                    <img src="assets/img/google.svg" alt="" />
                  </a>
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    callback={facebookLogin}
                    render={(renderProps) => (
                      <a
                        href="#"
                        onClick={renderProps.onClick}
                        className="sign-with-icon"
                      >
                        <img src="assets/img/facebook.svg" alt="" />
                      </a>
                    )}
                  />
                  <AppleLogin
                    clientId={process.env.REACT_APP_APPLE_CLIENT_ID}
                    redirectURI={process.env.REACT_APP_APPLE_REDIRECT_URI}
                    callback={handleAppleResponse}
                    onError={(error) => console.error(error)}
                    responseType={"code"}
                    responseMode={"query"}
                    render={(renderProps) => (
                      <a
                        href="#"
                        onClick={renderProps.onClick}
                        className="sign-with-icon"
                      >
                        <img src="assets/img/apple.svg" alt="" />
                      </a>
                    )}
                  />

                  <a
                    href="javascript:void(0)"
                    className="sign-with-icon"
                    onClick={linkedInLogin}
                  >
                    <img
                      src="assets/img/linkedin.svg"
                      alt="Sign in with LinkedIn"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>     
      </div>
    </div>
  );
}

export default Signup;
