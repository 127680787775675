import React, { useRef } from "react";

const DeletePrompt = ({ type, deleteType, item, key }) => {
  const closeModalRef = useRef();
  let value = null;
  if (type.includes("Post")) {
    value = "Post";
  } else if (type.includes("Blog")) {
    value = "Blog";
  } else if (type.includes("Project")) {
    value = "Project";
  }

  return (
    <div key={key}>
      <div className="d-none">
        <a
          href="#"
          className="btn btn-br-blue fs-14 bg-transparent"
          data-bs-toggle="modal"
          data-bs-target="#kidmodal"
        >
          Add Kid Profile <img src="assets/img/icon/profile.svg" alt="" />
        </a>
      </div>
      <div
        className="modal fade"
        id={`deleteInfoModal-${type}`}
        tabIndex="-1"
        aria-labelledby={`deleteInfoModal-${type}`}
        aria-hidden="true"
      >
        <div className="modal-dialog rounded-10 box-shadow p-lg-3 p-md-3 p-2 bg-white">
          <div className="modal-content border-0">
            <div className="d-flex align-items-center justify-content-between border-bottom w-100">
              <h3 className="medium text-dark" style={{ fontSize: "1rem" }}>
                Delete{" "}
                {type === "TestScore"
                  ? "Test Score"
                  : value == null
                  ? type
                  : value}
              </h3>
              <button
                type="button"
                className="btn-close kids-close-icon cursor-pointer"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeModalRef}
              >
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#949494"
                  width="22"
                  height="22"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-body p-0">
              <h4
                className="medium text-danger pb-3"
                style={{ fontSize: "1rem" }}
              >
                Do you want to delete this{" "}
                {type === "TestScore"
                  ? "Test Score"
                  : value == null
                  ? type
                  : value}
                ?
              </h4>

              <div className="col-md-12 text-end">
                <button
                  type="button"
                  className="btn fs-12"
                  onClick={() => {
                    deleteType(item, type);
                    closeModalRef.current.click();
                  }}
                >
                  Yes Delete!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePrompt;
