import React, { useEffect, useState } from "react";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { CommonAPi } from "../../API/CommonApi";
import { FetchApi } from "../../API/FetchApi";
import { Link, useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
const $ = window.jQuery;

export default function SuggestedTeachers() {
  const Navigate = useNavigate();
  const [teacherData, setTeacherData] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      $(".suggested-teacher").owlCarousel({
        loop: true,
        margin: 15,
        items: 2,
        nav: true,
        dots: true,
        autoplay: false,
        autoplayTimeout: 3000,
        navText: [
          "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' stroke-linecap='round' strokeLinejoin='round' class='feather feather-chevron-left'><polyline points='15 18 9 12 15 6'></polyline></svg>",
          "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' stroke-linecap='round' strokeLinejoin='round' class='feather feather-chevron-right'><polyline points='9 18 15 12 9 6'></polyline></svg>",
        ],
        responsive: {
          0: {
            items: 1,
            margin: 0,
          },
          768: {
            items: 2,
          },
        },
      });
    }, 200);
  }, []);

  useEffect(() => {
    getrecommendationsUser();
  }, []);

  const sendFriendRequest = async (Id) => {
    let data = {
      receiverId: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.SendFriendRequest, data);
      if (resp && resp.message) {
        getrecommendationsUser();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getrecommendationsUser = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.recommendationsUser);
      if (resp && resp.success === true) {
        setTeacherData(resp?.data?.teacherRecommendations);
        setTimeout(() => {
          $(".suggested-teacher").owlCarousel({
            loop: true,
            margin: 15,
            items: 2,
            nav: true,
            dots: true,
            autoplay: false,
            autoplayTimeout: 3000,
            navText: [
              "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' stroke-linecap='round' strokeLinejoin='round' class='feather feather-chevron-left'><polyline points='15 18 9 12 15 6'></polyline></svg>",
              "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' stroke-linecap='round' strokeLinejoin='round' class='feather feather-chevron-right'><polyline points='9 18 15 12 9 6'></polyline></svg>",
            ],
            responsive: {
              0: {
                items: 1,
                margin: 0,
              },
              768: {
                items: 2,
              },
            },
          });
        }, 200);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <>
      {teacherData?.length > 0 ? (
        <div class="sidebar-widget mt-4 mb-4">
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="medium">Suggested Teachers</h4>
          </div>

          <div class="pt-3 mt-2 border-top">
            <div class="suggested-teacher slider-item-border owl-theme owl-carousel">
              {teacherData.map((item, index) => {
                return (
                  <div class="teacher-item text-center" key={index}>
                    <div className="suggested-friend-banner">
                      {item.user_profile?.coverImg === null ? (
                        <img src="../assets/img/teacher-banner.png" alt="" />
                      ) : (
                        <img
                          src={Endpoints.baseUrl + item.user_profile?.coverImg}
                          alt=""
                        />
                      )}
                    </div>

                    <span class="suggested-user profile-img">
                      <Link to={"/timeline/" + item.username}>
                        {item.user_profile?.profileImg === null ? (
                          <img
                            src="../assets/img/teacher1.png"
                            alt=""
                            width="80"
                            height="80"
                            className="rounded-circle"
                          />
                        ) : (
                          <img
                            src={
                              Endpoints.baseUrl + item.user_profile?.profileImg
                            }
                            alt=""
                            width="80"
                            height="80"
                            className="rounded-circle"
                          />
                        )}
                      </Link>
                    </span>
                    <Link to={"/timeline/" + item.username}>
                      <h5 class="semibold pt-3 pb-2">{item.fullName}</h5>
                    </Link>
                    {/* <h6 class="fs-10 text-gray regular pb-1">Science Teacher at</h6> */}
                    <h6 class="fs-10 text-gray medium pb-1">
                      {item.user_profile?.profileTagline}
                    </h6>
                    <h6 class="fs-10 text-gray regular pb-2">
                      {item.totalStudents} Students
                    </h6>

                    <div class="d-flex justify-content-center align-items-center">
                      <div class="d-flex me-2">
                        <ReactStars
                          count={5}
                          edit={false}
                          value={item?.rating}
                          size={24}
                          isHalf={true}
                          char={
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width={18}
                              alt=""
                            />
                          }
                          emptyIcon={
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width={18}
                              alt=""
                            />
                          }
                          halfIcon={
                            <img
                              src="../assets/img/icon/state-half.svg"
                              width={18}
                              alt=""
                            />
                          }
                          filledIcon={
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width={18}
                              alt=""
                            />
                          }
                          activeColor="#ffc107"
                        />
                      </div>
                      <span class="fs-12 medium">{item?.rating}</span>
                    </div>

                    <div class="text-center py-3 px-2">
                      <Link
                        onClick={() => {
                          sendFriendRequest(item.id);
                        }}
                        class="btn w-100"
                      >
                        <img
                          src="assets/img/icon/icon-add-friend.svg"
                          class="pe-2"
                        />
                        Add Friend
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
