import React, { useEffect, useState, useRef } from "react";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import ProfileNavigation from "../../Components/ProfileNavigation";
import Container from "../../Components/Container";
import Profile from "../ProfileAbout/Profile";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";

import { FetchApi } from "../../API/FetchApi";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import PhotoItem from "./PhotoItem";
import CreatePhotoItem from "./CreatePhotoItem";
import CreateAlbumItem from "./CreateAlbumItem";
import PostLoader from "../../Components/PostLoader/PostLoader";
import { Helmet } from "react-helmet";
const postReactions = [
  { id: "Like", img: "../assets/img/icon/Like.svg" },
  { id: "Love", img: "../assets/img/icon/Love.svg" },
  { id: "Haha", img: "../assets/img/icon/Haha.svg" },
  { id: "Care", img: "../assets/img/icon/Care.svg" },
  { id: "Wow", img: "../assets/img/icon/Wow.svg" },
  { id: "Sad", img: "../assets/img/icon/Sad.svg" },
  { id: "Angry", img: "../assets/img/icon/Angry.svg" },
];

const postVisibilites = [
  {
    name: "Friends",
    value: "Friends",
  },
  {
    name: "Public",
    value: "Public",
  },
  {
    name: "Friends Except",
    value: "Friends Except",
  },
  {
    name: "Friends Specific",
    value: "Friends Specific",
  },
  {
    name: "Only Me",
    value: "Only Me",
  },
];

const fileTypes = {
  png: "image/",
  jpg: "image/",
  jpeg: "image/",
  mp4: "video/",
};
export default function Photo() {
  const [image, setImage] = useState([]);
  const [count, setCount] = useState(0);
  const [Profilecount, setProfileCount] = useState(0);
  const [Albumcount, setAlbumCount] = useState(0);
  const [post, setPost] = useState("");
  const [loadStatus, setLoadStatus] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [limit, setLimit] = useState(15);
  const Navigate = useNavigate();
  const location = useLocation();
  const param = useParams();
  const userId = localStorage.getItem("userId");
  const username = localStorage.getItem("userName");

  useEffect(() => {
    getUserImages();
  }, [location.pathname, param?.id]);

  const getUserImages = async () => {
    if (location.pathname.split("/")[1] === "profile-photos") {
      try {
        let resp = await FetchApi(
          Endpoints.UserPhotoVideo +
            "?size=" +
            limit +
            "&type=photos&id=" +
            param?.id
        );
        if (resp && resp.success === true) {
          setImage(resp?.data?.data);
          setCount(resp?.data?.count);
          setAlbumCount(resp?.data?.albumCount);
          setProfileCount(resp?.data?.profilePictureCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else if (location.pathname.split("/")[1] === "user-profile-photos") {
      try {
        let resp = await FetchApi(
          Endpoints.UserPhotoVideo +
            "?size=" +
            limit +
            "&type=photos&scope=profile&id=" +
            param?.id
        );
        if (resp && resp.success === true) {
          setImage(resp?.data?.data);
          setCount(resp?.data?.count);
          setAlbumCount(resp?.data?.albumCount);
          setProfileCount(resp?.data?.profilePictureCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      try {
        let resp = await FetchApi(
          Endpoints.UserPhotoVideo +
            "?size=" +
            limit +
            "&type=album&id=" +
            param?.id
        );
        if (resp && resp.success === true) {
          setImage(resp?.data?.data);
          setCount(resp?.data?.count);
          setAlbumCount(resp?.data?.albumCount);
          setProfileCount(resp?.data?.profilePictureCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  const handleLoadMore = async () => {
    setLoadStatus(true);
    let newLimit = limit + 12;
    setLimit(newLimit);

    if (location.pathname.split("/")[1] === "profile-photos") {
      try {
        let resp = await FetchApi(
          Endpoints.UserPhotoVideo +
            "?size=" +
            newLimit +
            "&type=photos&id=" +
            param?.id
        );
        if (resp && resp.success === true) {
          setImage(resp?.data?.data);
          setCount(resp?.data?.count);
          setAlbumCount(resp?.data?.albumCount);
          setProfileCount(resp?.data?.profilePictureCount);
          setLoadStatus(false);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          setLoadStatus(false);
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else if (location.pathname.split("/")[1] === "user-profile-photos") {
      try {
        let resp = await FetchApi(
          Endpoints.UserPhotoVideo +
            "?size=" +
            newLimit +
            "&type=photos&scope=profile&id=" +
            param?.id
        );
        if (resp && resp.success === true) {
          setImage(resp?.data?.data);
          setCount(resp?.data?.count);
          setAlbumCount(resp?.data?.albumCount);
          setProfileCount(resp?.data?.profilePictureCount);
          setLoadStatus(false);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          setLoadStatus(false);
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      try {
        let resp = await FetchApi(
          Endpoints.UserPhotoVideo +
            "?size=" +
            newLimit +
            "&type=album&id=" +
            param?.id
        );
        if (resp && resp.success === true) {
          setImage(resp?.data?.data);
          setCount(resp?.data?.count);
          setAlbumCount(resp?.data?.albumCount);
          setProfileCount(resp?.data?.profilePictureCount);
          setLoadStatus(false);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          setLoadStatus(false);
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleSearch = async (value) => {
    if (location.pathname.split("/")[1] === "profile-photos") {
      try {
        let resp = await FetchApi(
          Endpoints.UserPhotoVideo +
            "?size=" +
            limit +
            "&type=photos&id=" +
            param?.id +
            "&term=" +
            value
        );
        if (resp && resp.success === true) {
          setImage(resp?.data?.data);
          setCount(resp?.data?.count);
          setAlbumCount(resp?.data?.albumCount);
          setProfileCount(resp?.data?.profilePictureCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else if (location.pathname.split("/")[1] === "user-profile-photos") {
      try {
        let resp = await FetchApi(
          Endpoints.UserPhotoVideo +
            "?size=" +
            limit +
            "&type=photos&scope=profile&id=" +
            param?.id +
            "&term=" +
            value
        );
        if (resp && resp.success === true) {
          setImage(resp?.data?.data);
          setCount(resp?.data?.count);
          setAlbumCount(resp?.data?.albumCount);
          setProfileCount(resp?.data?.profilePictureCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      try {
        let resp = await FetchApi(
          Endpoints.UserPhotoVideo +
            "?size=" +
            limit +
            "&type=album&id=" +
            param?.id +
            "&term=" +
            value
        );
        if (resp && resp.success === true) {
          setImage(resp?.data?.data);
          setCount(resp?.data?.count);
          setAlbumCount(resp?.data?.albumCount);
          setProfileCount(resp?.data?.profilePictureCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  const handleSorting = async (value) => {
    if (location.pathname.split("/")[1] === "profile-photos") {
      try {
        let resp = await FetchApi(
          Endpoints.UserPhotoVideo +
            "?size=" +
            limit +
            "&type=photos&id=" +
            param?.id +
            "&sort=" +
            value
        );
        if (resp && resp.success === true) {
          setImage(resp?.data?.data);
          setCount(resp?.data?.count);
          setAlbumCount(resp?.data?.albumCount);
          setProfileCount(resp?.data?.profilePictureCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else if (location.pathname.split("/")[1] === "user-profile-photos") {
      try {
        let resp = await FetchApi(
          Endpoints.UserPhotoVideo +
            "?size=" +
            limit +
            "&type=photos&scope=profile&id=" +
            param?.id +
            "&sort=" +
            value
        );
        if (resp && resp.success === true) {
          setImage(resp?.data?.data);
          setCount(resp?.data?.count);
          setAlbumCount(resp?.data?.albumCount);
          setProfileCount(resp?.data?.profilePictureCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      try {
        let resp = await FetchApi(
          Endpoints.UserPhotoVideo +
            "?size=" +
            limit +
            "&type=album&id=" +
            param?.id +
            "&sort=" +
            value
        );
        if (resp && resp.success === true) {
          setImage(resp?.data?.data);
          setCount(resp?.data?.count);
          setAlbumCount(resp?.data?.albumCount);
          setProfileCount(resp?.data?.profilePictureCount);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Photos</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-lg-3 py-3">
            <div className="profile-banner-top mb-3  rounded-15 overflow-hidden">
              <Profile />

              <ProfileNavigation />
            </div>
            {loadStatus ? (
              <div class="bg-white main-common">
                <PostLoader />
              </div>
            ) : (
              <div class="bg-white main-common">
                <div class="main-top-row d-md-flex justify-content-between align-items-center pb-3">
                  <h3 class="medium">Photos</h3>
                  {/* {username === param?.id ? */}
                  <div class="d-sm-flex gap-3 pb-3">
                    <form
                      action=""
                      class="mainmenu-page-search d-flex flex-row-reverse align-items-center"
                    >
                      <input
                        type="text"
                        placeholder="Search for photos"
                        name="search"
                        class="bg-transparent fs-14 medium  text-darkgray"
                        onChange={(e) => {
                          handleSearch(e.target.value);
                        }}
                      />
                      <button type="submit" class="bg-transparent border-0">
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          stroke="#949494"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          class="css-i6dzq1"
                        >
                          <circle cx="11" cy="11" r="8"></circle>
                          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                      </button>
                    </form>
                    <select
                      name="sortby"
                      id="sortby"
                      class="text-darkgray"
                      onChange={(e) => {
                        handleSorting(e.target.value);
                      }}
                    >
                      <option value="">Sort by</option>
                      <option value="popularity">Popular </option>
                      <option value="recent">Recent</option>
                    </select>
                  </div>
                  {/* :""} */}
                </div>
                {/* {username == param?.id ?  */}
                <div class="mainmenu-filter-row pb-3">
                  <ul class="filter d-flex flex-nowrap align-items-center gap-2 fs-14 medium">
                    <li
                      class={
                        location.pathname.split("/")[1] === "profile-photos"
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        Navigate("/profile-photos/" + param?.id);
                      }}
                    >
                      All Photos <span class="fs-10 text-brown">{count}</span>{" "}
                    </li>
                    <li
                      class={
                        location.pathname.split("/")[1] ===
                        "user-profile-photos"
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        Navigate("/user-profile-photos/" + param?.id);
                      }}
                    >
                      Profile Photos{" "}
                      <span class="fs-10 text-brown">{Profilecount}</span>
                    </li>
                    <li
                      class={
                        location.pathname.split("/")[1] === "profile-albums"
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        Navigate("/profile-albums/" + param?.id);
                      }}
                    >
                      Albums <span class="fs-10 text-brown">{Albumcount}</span>
                    </li>
                  </ul>
                </div>
                {/* // : ""} */}

                <div class="row mainmenu-filter-conatiner">
                  {username === param?.id ? (
                    location.pathname.split("/")[1] === "profile-albums" ? (
                      <CreateAlbumItem />
                    ) : (
                      <CreatePhotoItem
                        {...{
                          onSuccess: getUserImages,
                          type: activeTab,
                        }}
                      />
                    )
                  ) : (
                    ""
                  )}

                  {image.map((item, index) => {
                    return (
                      <PhotoItem
                        {...{
                          item: item,
                          index: index,
                          post: item.post,
                          onSuccess: getUserImages,
                        }}
                      />
                    );
                  })}
                </div>

                {image.length >= 8 && limit < count ? (
                  <div className="text-center">
                    <Link
                      href="#"
                      className="text-blue"
                      onClick={() => handleLoadMore()}
                    >
                      {loadStatus ? <PostLoader /> : "Load More photos"}
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
