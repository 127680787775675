import Editor from "@draft-js-plugins/editor";
import "@draft-js-plugins/emoji/lib/plugin.css";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import "@draft-js-plugins/linkify/lib/plugin.css";
import createMentionPlugin from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import createEmojiPlugin, { defaultTheme } from "@draft-js-plugins/emoji";
import EntryComponent from "../PostShare/EntryComponent";
import { CustomHastagComp } from "../PostShare/PostShare";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import { NavLink } from "react-router-dom";
import PostLoader from "../PostLoader/PostLoader";

export default function PostTitleDraftEdit({ title, onChange }) {
  const inputRef = useRef(null);
  const previewTimeout = useRef({
    url: "",
    timeout: null,
  });
  const linkImageRef = useRef();

  const [editorState, setEditorState] = useState(() => {
    if (title) {
      console.log(title);
      if (title.startsWith("{")) {
        return EditorState.createWithContent(convertFromRaw(JSON.parse(title)));
      }
      return EditorState.createWithContent(ContentState.createFromText(title));
    }
    return EditorState.createEmpty();
  });

  // mention plugin implementation
  const [mentionOpen, setMentionOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  // hashtag plugin implementation
  const [hashtagOpen, setHashtagOpen] = useState(false);
  const [hashtagSuggestions, setHashtagSuggestions] = useState([]);

  const [previewData, setPreviewData] = useState(null);
  const [previewDataLoader, setPreviewDataLoader] = useState(false);

  const handleContentChange = useCallback(
    (newState) => {
      setEditorState(newState);
      onChange(JSON.stringify(convertToRaw(newState.getCurrentContent())));
      //   setPostTitle(newState.getCurrentContent().getPlainText());
    },
    [onChange]
  );




  const {
    MentionSuggestions,
    plugins,
    EmojiSuggestions,
    EmojiSelect,
    HashtagSuggestions
  } = useMemo(() => {
    // @ mention
    const mentionPlugin = createMentionPlugin({
      mentionTrigger: ["@"],
      mentionPrefix: "",
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // # hashtag
    const hashtagPlugin = createMentionPlugin({
      mentionTrigger: ["#"],
      mentionComponent: CustomHastagComp,
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions: HashtagSuggestions } = hashtagPlugin;

    const linkify = createLinkifyPlugin();
    const emojiPlugin = createEmojiPlugin({
      theme: {
        ...defaultTheme,
        emojiSelect: "custom-emoji-select",
        emojiSelectButton: "custom-emoji-select-button",
        emojiSelectPopover:
          defaultTheme.emojiSelectPopover + " custom-emoji-select-popover",
      },
      selectButtonContent: (
        <>
          <img
            className="post-image"
            src="../assets/img/icon/emoji_icon.png"
            alt=""
          />
          Emoji
        </>
      ),
    });
    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin, emojiPlugin, hashtagPlugin, linkify];
    return {
      plugins,
      MentionSuggestions,
      EmojiSuggestions,
      EmojiSelect,
      HashtagSuggestions,
    };
  }, []);

  const onOpenChange = useCallback((_open) => {
    setMentionOpen(_open);
  }, []);
  const onSearchChange = useCallback(({ value }) => {
    CommonAPi(Endpoints.searchFriend + value).then((res) => {
      if (res.data) {
        setSuggestions(
          res.data.map((item) => ({
            name: item.fullName,
            avatar: Endpoints.baseUrl + item.user_profile.profileImg,
            link: `/timeline/${item.username}`,
          }))
        );
      }
    });
    // setSuggestions(defaultSuggestionsFilter(value, mentions));
  }, []);

  const onHashtagOpenChange = useCallback((_open) => {
    setHashtagOpen(_open);
  }, []);
  const onHashtagSearchChange = useCallback(({ value }) => {
    CommonAPi(Endpoints.searchHashtag + value).then((res) => {
      if (res.data) {
        let mappedResponse = res.data.map((item) => ({
          name: `#${item.tag}`,
          link: `/singlehashtag?tag=${item.tag}`,
        }));
        const index = mappedResponse.findIndex(
          (item) =>
            item.name.trim().slice(1).toLowerCase() ===
            value.trim().toLowerCase()
        );
        if (value && index === -1)
          mappedResponse = [
            { name: `#${value}`, link: `/singlehashtag?tag=${value}` },
            ...mappedResponse,
          ];
        setHashtagSuggestions(mappedResponse);
      }
    });
    // setHashtagSuggestions(defaultSuggestionsFilter(value, hashtags));
  }, []);

  useEffect(() => {
    const postTitle = editorState.getCurrentContent().getPlainText();
    if (postTitle === "") {
      setPreviewDataLoader(false);
      previewTimeout.current = {
        url: "",
        timeout: null,
      };
    } else {
      const urlRegex = /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;
      const urlArray = urlRegex.exec(postTitle);
      if (urlArray?.length && urlArray[0].length) {
        async function fetchMeta() {
          try {
            const resp = await CommonAPi(Endpoints.LinkPreview, {
              url: urlArray[0],
            });
            if (resp && resp.success) {
              setPreviewData(resp.data);
              setPreviewDataLoader(false);
            }
          } catch (error) {
            setPreviewDataLoader(false);
            setPreviewData(null);
            console.log(error);
          }
        }
        if (
          previewData === null ||
          urlArray[0] !== previewTimeout.current.url
        ) {
          previewTimeout.current = {
            url: urlArray[0],
            timeout: setTimeout(() => {
              setPreviewDataLoader(true);
              fetchMeta();
            }, 1000),
          };
        }
      }
    }
    return () => {
      if (previewTimeout.current.timeout) {
        clearTimeout(previewTimeout.current.timeout);
        previewTimeout.current.timeout = null;
      }
    };
  }, [editorState]);

  return (
    <div>
      <Editor
        editorState={editorState}
        onChange={handleContentChange}
        plugins={plugins}
        ref={inputRef}
      />
      <MentionSuggestions
        open={mentionOpen}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        entryComponent={EntryComponent}
      />
      <HashtagSuggestions
        open={hashtagOpen}
        onOpenChange={onHashtagOpenChange}
        suggestions={hashtagSuggestions}
        onSearchChange={onHashtagSearchChange}
        entryComponent={EntryComponent}
      />
      {/* preview data */}
      {previewDataLoader ? (
        <div className="link-preview-container prviewBox mt-3">
          <PostLoader />
        </div>
      ) : (
        previewData && (
          <div className="link-preview-container prviewBox mt-3">
            <NavLink
              to={`${previewData.url}`}
              target="_blank"
            // onClick={handleClick}
            >
              <div>
                {(previewData.image || previewData.icon) && (
                  <img
                    className="img-fluid text-center mx-auto link-image"
                    src={previewData.image || previewData.icon}
                    ref={linkImageRef}
                    alt=""
                  />
                )}

                <h4 className="my-3">{previewData.title}</h4>
                <p className="fs-14">
                  {previewData.description || previewData.description2}
                </p>
                <p className="text-uppercase fs-12 font-weight-bold">
                  {previewData.url}
                </p>
              </div>
            </NavLink>
            <div className="d-flex justify-content-end align-items-start preview-cross">
              <svg
                height="20px"
                width="20px"
                fill="#777"
                id="link-preview-cross"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setPreviewData(null);
                }}
                version="1.1"
                viewBox="0 0 512 512"
              >
                <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
              </svg>
            </div>
          </div>
        )
      )}
    </div>
  );
}
