import React, { useEffect, useState } from 'react';
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CommonAPi } from '../../API/CommonApi';

export default function FollowItem({ type, setTotalFollowers, SearchText, setStatistics }) {
    const [otherFollowData, setOtherFollowData] = useState([]);
    const [count, setCount] = useState(0);
    const [friendCount, setFriendCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const param = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        if (SearchText) {
            getSearchFollower();
        } else {
            getCompanyFollowOther();
        }

    }, [SearchText])

    const getCompanyFollowOther = async () => {
        if (type === "friend") {
            try {
                let resp = await FetchApi(Endpoints.PageFollowers + "?slug=" + param?.slug + "&friends=1" + "&size=" + limit);
                if (resp && resp.success === true) {
                    setOtherFollowData(resp?.data?.followers);
                    setFriendCount(resp?.data?.totalFriendsFollowers);
                    setTotalFollowers(resp?.data?.totalFollowers);
                    setStatistics({
                        totalEmployees: resp?.data?.EmployeesCount,
                        totalFollowers: resp?.data?.totalFollowers,
                        totalJobs: 0,
                    });

                }
            }
            catch (e) {
                if (e && e.response && e.response.data && e.response.data.message) {
                    if (e.response.status === 401) {
                        ToastMessage.Error('Session Expired! Please login again.');
                        localStorage.clear();
                        navigate('/signin');
                    } else {
                        ToastMessage.Error(e.response.data.message);
                    }

                }
            }
        } else {

            try {
                let resp = await FetchApi(Endpoints.PageFollowers + "?slug=" + param?.slug + "&size=" + limit);
                if (resp && resp.success === true) {
                    setOtherFollowData(resp?.data?.followers);
                    setCount(resp?.data?.totalNonFriendFollowers);
                    setTotalFollowers(resp?.data?.totalFollowers);
                }
            }
            catch (e) {
                if (e && e.response && e.response.data && e.response.data.message) {
                    if (e.response.status === 401) {
                        ToastMessage.Error('Session Expired! Please login again.');
                        localStorage.clear();
                        navigate('/signin');
                    } else {
                        ToastMessage.Error(e.response.data.message);
                    }

                }
            }
        }
    };
    const getSearchFollower = async () => {
        if (type === "friend") {
            try {
                let resp = await FetchApi(Endpoints.PageFollowers + "?slug=" + param?.slug + "&friends=1" + "&size=" + limit + "&term=" + SearchText);
                if (resp && resp.success === true) {
                    setOtherFollowData(resp?.data?.followers);
                    setFriendCount(resp?.data?.totalFriendsFollowers);
                    setTotalFollowers(resp?.data?.totalFollowers);

                }
            }
            catch (e) {
                if (e && e.response && e.response.data && e.response.data.message) {
                    if (e.response.status === 401) {
                        ToastMessage.Error('Session Expired! Please login again.');
                        localStorage.clear();
                        navigate('/signin');
                    } else {
                        ToastMessage.Error(e.response.data.message);
                    }

                }
            }
        } else {

            try {
                let resp = await FetchApi(Endpoints.PageFollowers + "?slug=" + param?.slug + "&size=" + limit + "&term=" + SearchText);
                if (resp && resp.success === true) {
                    setOtherFollowData(resp?.data?.followers);
                    setCount(resp?.data?.totalNonFriendFollowers);
                    setTotalFollowers(resp?.data?.totalFollowers);
                }
            }
            catch (e) {
                if (e && e.response && e.response.data && e.response.data.message) {
                    if (e.response.status === 401) {
                        ToastMessage.Error('Session Expired! Please login again.');
                        localStorage.clear();
                        navigate('/signin');
                    } else {
                        ToastMessage.Error(e.response.data.message);
                    }

                }
            }
        }
    };

    const handleLoadMore = async (userType) => {
        const newLimit = limit + 10;
        setLimit(newLimit);
        if (userType === "friend") {
            try {
                let resp = await FetchApi(Endpoints.PageFollowers + "?slug=" + param?.slug + "&friends=1" + "&size=" + newLimit);
                if (resp && resp.success === true) {
                    setOtherFollowData(resp?.data?.followers);
                    setFriendCount(resp?.data?.totalFriendsFollowers);
                    setTotalFollowers(resp?.data?.totalFollowers);

                }
            }
            catch (e) {
                if (e && e.response && e.response.data && e.response.data.message) {
                    if (e.response.status === 401) {
                        ToastMessage.Error('Session Expired! Please login again.');
                        localStorage.clear();
                        navigate('/signin');
                    } else {
                        ToastMessage.Error(e.response.data.message);
                    }

                }
            }
        } else {

            try {
                let resp = await FetchApi(Endpoints.PageFollowers + "?slug=" + param?.slug + "&size=" + newLimit);
                if (resp && resp.success === true) {
                    setOtherFollowData(resp?.data?.followers);
                    setCount(resp?.data?.totalNonFriendFollowers);
                    setTotalFollowers(resp?.data?.totalFollowers);
                }
            }
            catch (e) {
                if (e && e.response && e.response.data && e.response.data.message) {
                    if (e.response.status === 401) {
                        ToastMessage.Error('Session Expired! Please login again.');
                        localStorage.clear();
                        navigate('/signin');
                    } else {
                        ToastMessage.Error(e.response.data.message);
                    }

                }
            }
        }

    };

    const sendFriendRequest = async (Id) => {
        let data = {
            receiverId: Id
        }
        try {
            let resp = await CommonAPi(Endpoints.SendFriendRequest, data);
            if (resp && resp.message) {
                getCompanyFollowOther();
                ToastMessage.Success(resp.message)
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }

    };

    return (
        <>
            <div class="mb-4 mt-3">
                <div className="mb-2 pb-3">
                    {type === "friend" ? "Friends who follow - " + friendCount : "Other Followers - " + count}
                </div>

                {otherFollowData.length > 0 ?

                    otherFollowData.map((item, index) => {
                        return (
                            <div class="pb-3 d-flex align-items-center" key={index}>
                                {item?.user_profile?.profileImg === null ?
                                     <Link to={"/timeline/" + item?.username}>
                                    <img src="../assets/img/profile6.png" alt="" width="64" height="64" class="rounded-circle" />
                                    </Link>
                                    :
                                    <Link to={"/timeline/" + item?.username}>
                                    <img src={Endpoints.baseUrl + item?.user_profile?.profileImg} alt="" width="64" height="64" class="rounded-circle" />
                                    </Link>
                                }
                                <div class="d-flex align-items-center justify-content-between w-100 ps-2">
                                    <div>
                                        <Link to={"/timeline/" + item?.username}>
                                            <h6 class="medium text-black fs-14">{item.fullName}</h6>
                                        </Link>
                                        
                                        <h6 class="fs-12 regular text-darkgray">{item?.user_profile?.profileTagline}</h6>
                                        <h6 class="fs-10 regular text-darkgray">{item?.mutualFriends > 0 ? item?.mutualFriends + " mutual friends" : ""}</h6>
                                    </div>

                                    <div class="position-relative text-end d-flex gap-2 align-items-center">
                                        {item.isFriend === "NA" ?
                                            <Link onClick={() => sendFriendRequest(item.id)} class="btn fs-12 py-1 px-3 w-100">Add Friend<img src="../assets/img/icon/icon-add-friend.svg" class="ms-1" width="10" /></Link>
                                            :
                                            item.isFriend === "ACCEPTED" ?
                                                "FRIEND" : item.isFriend
                                        }
                                    </div>

                                </div>
                            </div>
                        )
                    })
                    : type === "friend" ? "No friend followers found" : "No other followers found"
                }

            </div>

            {type === "friend" ?
                friendCount > limit ?
                    <Link
                        onClick={() => {
                            handleLoadMore("friend");
                        }}
                        class="btn w-100 bg-eee text-black fs-14 medium"
                    >See All Members</Link>
                    : ""
                :
                count > limit ?
                    <Link
                        onClick={() => {
                            handleLoadMore("unfriend");
                        }}
                        class="btn w-100 bg-eee text-black fs-14 medium"
                    >See All Members</Link>
                    : ""
            }
        </>
    )
}
