import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import ToastMessage from "../../Utils/ToastMessage";
import { StickyChatContext } from "../StickyChatWrapper";
import StoryOverlay from "./StoryOverlay";
import ReactPlayer from "react-player";
import CustomAlert from "../../Utils/CustomAlert/CustomAlert";

const tickTime = 100;

export default function StoriesModal({
  stories = {
    records: [],
    totalPages: 0,
    totalRecords: 0,
  },
  selectedStoryIndex = 0,
  onClose = () => {},
}) {
  const user = JSON.parse(localStorage.getItem("user"));

  const { socket } = useContext(StickyChatContext);

  const interval = useRef();

  const videoRef = useRef();

  const [playing, setPlaying] = useState(true);

  const [muted, setMuted] = useState(true);

  const [countDown, setCountDown] = useState(0);

  const [comment, setComment] = useState("");

  const [chats, setChats] = useState([]);

  const [showStoryViews, setShowStoryViews] = useState();

  const [currentStoriesIndex, setCurrentStoriesIndex] =
    useState(selectedStoryIndex);

  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);

  const [activity, setActivity] = useState();

  const [storyDuration, setStoryDuration] = useState(4.999);

  const [videoBuffering, setVideoBuffering] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const [alertMessage, setAlertMessage] = useState(false);

  const cancelInterval = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = undefined;
    }
  }, []);

  const startInterval = useCallback(() => {
    cancelInterval();
    const intervalCallback = () => {
      // set countdown
      setCountDown((currentTime) => currentTime + tickTime);
      // end of set countdown
    };
    interval.current = setInterval(intervalCallback, tickTime);
  }, [cancelInterval]);

  const handlePause = useCallback(() => {
    cancelInterval();
    setPlaying(false);
  }, [cancelInterval]);

  const handlePlay = useCallback(() => {
    startInterval();
    setPlaying(true);
  }, [startInterval]);

  const handleMuteUnmute = useCallback(() => {
    if (muted) setMuted(false);
    else setMuted(true);
  }, [muted]);

  const handlePreviousStory = useCallback(() => {
    setShowStoryViews(false);
    cancelInterval();
    setCountDown(0);
    setPlaying(true);
    const nextStoriesIndex = currentStoriesIndex - 1;
    const nextStoryIndex = currentStoryIndex - 1;
    if (nextStoryIndex > -1) {
      setCurrentStoryIndex(nextStoryIndex);
      startInterval();
    } else if (nextStoriesIndex > -1) {
      setCurrentStoriesIndex(nextStoriesIndex);
      setCurrentStoryIndex(0);
      startInterval();
    }
  }, [cancelInterval, currentStoriesIndex, currentStoryIndex, startInterval]);

  const handleNextStory = useCallback(() => {
    setShowStoryViews(false);
    cancelInterval();
    setCountDown(0);
    setPlaying(true);
    const nextStoryIndex = currentStoryIndex + 1;
    if (stories.records[currentStoriesIndex].stories.length > nextStoryIndex) {
      setCurrentStoryIndex(nextStoryIndex);
      startInterval();
    } else {
      const nextStoriesIndex = currentStoriesIndex + 1;
      if (stories.records.length > nextStoriesIndex) {
        setCurrentStoriesIndex(nextStoriesIndex);
        setCurrentStoryIndex(0);
        startInterval();
      }
    }
  }, [
    cancelInterval,
    currentStoriesIndex,
    currentStoryIndex,
    startInterval,
    stories.records,
  ]);

  const handleReaction = useCallback(
    (reaction) => async () => {
      if (user?.id != stories.records[currentStoriesIndex].id) {
        try {
          const res = await CommonAPi(Endpoints.reactToStory, {
            id: stories.records[currentStoriesIndex].stories[currentStoryIndex]
              .id,
            reaction_type: reaction,
          });
          if (res.success) {
            // ToastMessage.Success(res.message);
            setAlertMessage(res.message);
            setShowAlert(true);
          }
        } catch (e) {
          setAlertMessage("Reacting to the story failed");
          setShowErrorAlert(true);
        }
      }
    },
    [currentStoriesIndex, currentStoryIndex, stories.records]
  );

  const handleCommentSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (comment) {
        const chat = chats.find(
          (searchChat) =>
            searchChat.roomType === "general" &&
            searchChat.chat_room_members.some(
              (roomUser) =>
                roomUser.user.id === stories.records[currentStoriesIndex].id
            )
        );
        if (!chat) {
          ToastMessage.Error("Something went wrong while sending comment!");
          return;
        }

        const storyId =
          stories.records[currentStoriesIndex].stories[currentStoryIndex].id;

        const formdata = new FormData();
        formdata.append("sender_id", user.id);
        formdata.append("message", comment);
        formdata.append("chat_room_id", chat.id);
        formdata.append("story_id", storyId);
        try {
          const resp = await CommonAPi(Endpoints.newMessage, formdata);
          if (resp?.success) {
            ToastMessage.Success(resp.message);
            setComment("");

            // to socket
            const arrayOfUsers = chat.chat_room_members
              .filter((searchUser) => searchUser.user.id !== user.id)
              .map((item) => item.user.id);
            socket.emit("subscribe", chat.id, arrayOfUsers);
            socket.emit("message", chat.id, resp.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    [
      chats,
      comment,
      currentStoriesIndex,
      currentStoryIndex,
      socket,
      stories.records,
      user,
    ]
  );

  const getChats = useCallback(async () => {
    try {
      const res = await CommonAPi(Endpoints.getUserChatRooms + user.id);
      // sorting based on last message
      setChats(
        res.data.sort(
          (a, b) => new Date(b?.msgTimeStamp) - new Date(a?.msgTimeStamp)
        )
      );
    } catch (e) {
      console.log(e);
    }
  }, [user]);

  const getViews = useCallback(async (storyId) => {
    try {
      const res = await CommonAPi(Endpoints.getViews + storyId);
      setActivity(res.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const captureView = useCallback(async (storyId) => {
    try {
      await CommonAPi(
        Endpoints.captureStoryView + storyId,
        undefined,
        undefined,
        true
      );
    } catch (e) {
      console.log(e);
    }
  }, []);

  // componentDidMount
  useEffect(() => {
    startInterval();
    getChats();
    return () => {
      clearInterval(interval.current);
      if (interval.current) {
        interval.current = undefined;
      }
    };
  }, []);

  // capture story view when story changes
  useEffect(() => {
    captureView(
      stories.records[currentStoriesIndex].stories[currentStoryIndex].id
    );
  }, [currentStoriesIndex, currentStoryIndex]);

  useEffect(() => {
    const isLastStory =
      currentStoriesIndex === stories.records.length - 1 &&
      currentStoryIndex ===
        stories.records[currentStoriesIndex].stories.length - 1;

    if (countDown / 1000 >= storyDuration) {
      if (isLastStory) {
        cancelInterval();
      }
      const newStoryIndex = currentStoryIndex + 1;
      if (stories.records[currentStoriesIndex].stories.length > newStoryIndex) {
        setCurrentStoryIndex(newStoryIndex);
        setCountDown(0);
      } else {
        const newStoriesIndex = currentStoriesIndex + 1;
        if (stories.records.length > newStoriesIndex) {
          setCurrentStoriesIndex(newStoriesIndex);
          setCurrentStoryIndex(0);
          setCountDown(0);
        }
      }
    }
  }, [countDown]);

  useEffect(() => {
    if (stories.records[currentStoriesIndex].id === user.id) {
      getViews(
        stories.records[currentStoriesIndex].stories[currentStoryIndex].id
      );
    } else {
      setActivity();
    }
  }, [currentStoriesIndex, currentStoryIndex]);

  // story duration to video duration or 5sec
  useEffect(() => {
    setStoryDuration(4.99);
    setVideoBuffering(false);
    if (
      stories.records[currentStoriesIndex].stories[currentStoryIndex]
        .storyType === "video"
    ) {
      // handlePause();
      cancelInterval();
    } else {
      startInterval();
    }
  }, [currentStoriesIndex, currentStoryIndex, stories]);

  const isFirstStory = useMemo(
    () => currentStoriesIndex === 0 && currentStoryIndex === 0,
    [currentStoriesIndex, currentStoryIndex]
  );

  const isLastStory = useMemo(
    () =>
      currentStoriesIndex === stories.records.length - 1 &&
      currentStoryIndex ===
        stories.records[currentStoriesIndex].stories.length - 1,
    [currentStoriesIndex, currentStoryIndex, stories.records]
  );

  const isCurerntUserStory = useMemo(() => {
    return stories.records[currentStoriesIndex].id === user?.id;
  }, [currentStoriesIndex, stories.records, user]);

  return createPortal(
    <div className="position-fixed top-0 start-0 bottom-0 end-0 stories-container">
      <div className="stories-modal">
        <div className="stories-modal-header">
          <p className="stories-modal-title">Stories</p>
          <button onClick={onClose} className="stories-modal-close-button ">
            <img
              src={`${process.env.PUBLIC_URL}/assets/svgs/close-modal.svg`}
              alt="close-modal"
            />
          </button>
        </div>

        <div className="stories-modal-body">
          <button
            disabled={isFirstStory}
            onClick={handlePreviousStory}
            className="stories-control-button left"
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/svgs/previous.svg`}
              alt="previous"
              style={{ visibility: isFirstStory ? "hidden" : undefined }}
            />
          </button>
          <div className="stories-middle-section ">
            <div className="story-content-container ">
              <div className="story-content ">
                {stories.records[currentStoriesIndex].stories[currentStoryIndex]
                  .storyType !== "video" ? (
                  <img
                    src={
                      Endpoints.baseUrl +
                      stories.records[currentStoriesIndex].stories[
                        currentStoryIndex
                      ].media
                    }
                    alt="story"
                    className="story-image"
                  />
                ) : (
                  stories.records[currentStoriesIndex].stories[
                    currentStoryIndex
                  ].media && (
                    <ReactPlayer
                      ref={videoRef}
                      url={
                        Endpoints.baseUrl +
                        stories.records[currentStoriesIndex].stories[
                          currentStoryIndex
                        ].media
                      }
                      onDuration={(duration) => {
                        setStoryDuration(duration);
                      }}
                      onPause={handlePause}
                      onPlay={handlePlay}
                      onProgress={(state) => {
                        setCountDown(state.playedSeconds * 1000);
                      }}
                      onReady={(player) => {
                        handlePlay();
                        setVideoBuffering(false);
                      }}
                      onBuffer={() => {
                        setVideoBuffering(true);
                      }}
                      onBufferEnd={() => {
                        setVideoBuffering(false);
                      }}
                      stopOnUnmount
                      height="100%"
                      width="100%"
                      volume={muted ? 0 : 1}
                      muted={muted}
                      controls={false}
                      playing={playing}
                      className="story-video-player"
                    />
                  )
                )}
              </div>

              <StoryOverlay
                {...{
                  showStoryViews,
                  stories,
                  currentStoriesIndex,
                  currentStoryIndex,
                  isCurerntUserStory,
                  setShowStoryViews,
                  activity,
                  reactions,
                  countDown,
                  user,
                  handlePlay,
                  handlePause,
                  playing,
                  muted,
                  handleMuteUnmute,
                  storyDuration,
                  videoBuffering,
                  onClose,
                }}
              />
            </div>
            {showAlert && (
              <div
                className="position-absolute"
                style={{
                  top: "30%",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <CustomAlert
                  text={alertMessage}
                  onClose={() => setShowAlert(false)}
                  show={showAlert}
                  type={showErrorAlert ? "error" : "success"}
                />
              </div>
            )}
            {/* reaction and comment */}
            <div className="stories-reactions">
              {reactions.map((reaction) => {
                return (
                  <button key={reaction} onClick={handleReaction(reaction)}>
                    <img
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/svgs/${reaction.toLowerCase()}.svg`}
                      alt={reaction}
                    />
                  </button>
                );
              })}
            </div>
            {!isCurerntUserStory &&
              stories.records[currentStoriesIndex].isFriend === "ACCEPTED" && (
                <form
                  onSubmit={handleCommentSubmit}
                  className="stories-comment-form"
                >
                  <input
                    className="stories-comment-input"
                    placeholder={`Reply to ${stories.records[currentStoriesIndex].fullName}`}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    onFocus={handlePause}
                    onBlur={handlePlay}
                  />
                </form>
              )}
          </div>
          <button
            disabled={isLastStory}
            onClick={handleNextStory}
            className="stories-control-button right"
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/svgs/next.svg`}
              alt="next"
              style={{ visibility: isLastStory ? "hidden" : undefined }}
            />
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
}

export const reactions = [
  "Like",
  "Love",
  "Wow",
  "Haha",
  "Care",
  "Sad",
  "Angry",
];
