import React, { useEffect, useRef, useState } from "react";
import { Endpoints } from "../../../API/Endpoints";
import { CommonAPi } from "../../../API/CommonApi";
import PostLoader from "../../../Components/PostLoader/PostLoader";
import "./TeacherLIstModel.css";
import { useNavigate } from "react-router-dom";
const TeacherListModal = ({ getUserChats }) => {
  const closeModalRef = useRef();
  const [teachers, setTeachers] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [show, setShow] = useState("show");
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  //  get all teachers
  useEffect(() => {
    setIsLoading(true);
    const getChatTeachers = async () => {
      try {
        const resp = await CommonAPi(Endpoints.GetChatTeachers);
        if (resp && resp.success) {
          setTeachers(resp.data);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    getChatTeachers();
  }, []);

  const handleClick = async (e, id) => {
    try {
      const resp = await CommonAPi(Endpoints.CreateChat, {
        student_id: localStorage.getItem("userId"),
        teacher_id: id,
      });
      if (resp && resp.success) {
        closeModalRef.current.click();
        setErrorMsg("");
        getUserChats();
      } else {
        setErrorMsg(resp.message);
      }
    } catch (error) {
      console.log(error);
      setErrorMsg("");
    }
  };
  return (
    <>
      {" "}
      <div>
        <div className="d-none">
          <a
            href="#"
            className="btn btn-br-blue fs-14 bg-transparent"
            data-bs-toggle="modal"
            data-bs-target="#kidmodal"
          >
            Teacher
            <img src="assets/img/icon/profile.svg" alt="" />
          </a>
        </div>
        <div
          className={`modal fade ${show}`}
          id={`teacher-list-model`}
          tabindex="-1"
          aria-labelledby={`teacher-list-model`}
          aria-hidden="true"
        >
          <div className="modal-dialog rounded-10 box-shadow p-lg-3 p-md-3 p-2 bg-white">
            <div className="modal-content border-0">
              <div className="d-flex align-items-center justify-content-between border-bottom w-100">
                <h3 className="medium text-dark" style={{ fontSize: "1rem" }}>
                  My Teachers
                </h3>
                <button
                  type="button"
                  className="btn-close kids-close-icon cursor-pointer"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeModalRef}
                  onClick={() => {
                    setShow("");
                    setErrorMsg("");
                  }}
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="#949494"
                    width="22"
                    height="22"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              {isLoading ? (
                <PostLoader />
              ) : (
                <div className="modal-body px-0 py-3">
                  {teachers.length > 0 &&
                    teachers.map((t, i) => (
                      <div className="teacher-container">
                        <div
                          className="d-flex teacher-box"
                          onClick={(e) => {
                            handleClick(e, t.id);
                          }}
                        >
                          {t.user_profile?.profileImg ? (
                            <img
                              src={
                                Endpoints.baseUrl + t.user_profile?.profileImg
                              }
                              alt=""
                              className="img-fluid"
                            />
                          ) : (
                            <img src={"assets/img/picture.png"} alt="" />
                          )}
                          <div>
                            <h5 className="fs-14">{t.fullName}</h5>
                            <p className="fs-12">
                              {t.user_profile?.profileTagline}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
              <span className="text-danger fs-12">{errorMsg}</span>
            </div>
          </div>
        </div>
      </div>
      {show !== "" && (
        <div
          className={`modal-backdrop fade ${show}`}
          //   onClick={}
        ></div>
      )}
    </>
  );
};

export default TeacherListModal;
