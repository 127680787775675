import React, { useEffect, useState, useCallback } from "react";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import CompanyProfiles from "../CompanyProfiles";
import { FetchApi } from "../../../API/FetchApi";
import { Endpoints } from "../../../API/Endpoints";
import ToastMessage from "../../../Utils/ToastMessage";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CommonAPi } from "../../../API/CommonApi";
import { PatchApi } from "../../../API/PatchApi";
import { DeleteApi } from "../../../API/DeleteApi";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import moment from "moment";
import Profiles from "./Profiles";
import AdminJobProfiles from "./AdminJobProfiles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import ConfirmDialog from "../../../Components/confirmDialog/ConfirmDialog";
const $ = window.jQuery;
export default function AdminJobs() {
  const [jobTitle, setJobTitle] = useState("");
  const [jobType, setJobType] = useState("");
  const [location, setLocation] = useState("");
  const [Defaultlocation, setDefaultLocation] = useState("");
  const [Qualification, setQualification] = useState("");
  const [Description, setDescription] = useState("");
  const [Industry, setIndustry] = useState("");
  const [WorkLevel, setWorkLevel] = useState("");
  const [WorkModel, setWorkModel] = useState("");
  const [SalaryMin, setSalaryMin] = useState("");
  const [SalaryMax, setSalaryMax] = useState("");
  const [ExperinceMax, setExperinceMax] = useState("");
  const [ExperinceMin, setExperinceMin] = useState("");
  const [IndustryKey, setIndustryKey] = useState(
    Math.ceil(Math.random() * 999999)
  );
  const [IndustryOption, setIndustryOption] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [pageData, sePageData] = useState("");
  const [activeTab, setActiveTab] = useState("jobs");
  const [status, setStatus] = useState(false);
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const param = useParams();

  const [countryKey, setCountryKey] = useState(
    Math.ceil(Math.random() * 999999)
  );
  const [countryError, setCountryError] = useState("");
  const [countryOption, setCountryOption] = useState([]);

  const [jobTitleError, setJobTitleError] = useState("");
  const [jobTypeError, setJobTypeError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [QualificationError, setQualificationError] = useState("");
  const [DescriptionError, setDescriptionError] = useState("");
  const [IndustryError, setIndustryError] = useState("");
  const [WorkLevelError, setWorkLevelError] = useState("");
  const [WorkModelError, setWorkModelError] = useState("");
  const [SalaryMinError, setSalaryMinError] = useState("");
  const [SalaryMaxError, setSalaryMaxError] = useState("");
  const [ExperinceMaxError, setExperinceMaxError] = useState("");
  const [ExperinceMinError, setExperinceMinError] = useState("");

  const [cityOptions, setCityOptions] = useState([]);
  const [city, setCity] = useState("");
  const [defultCity, setDefultCity] = useState("");
  const [defultIndustry, setDefultIndustry] = useState("");
  const [cityKey, setCityKey] = useState(Math.ceil(Math.random() * 999999));
  const [cityError, setCityError] = useState("");

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");

  useEffect(() => {
    getCompanyJobs();
  }, [activeTab, limit]);

  const handleEdit = (item) => {
    setSalaryMax(item?.maxSalary);
    setSalaryMin(item?.minSalary);
    setExperinceMin(item?.minExperience);
    setExperinceMax(item?.maxExperience);
    setJobTitle(item?.title);
    setJobType(item?.jobType);
    setLocation(item?.state?.id);
    setDefaultLocation(item?.state?.state);
    setQualification(item?.qualification);
    setDescription(item?.description);
    setIndustry(item?.industryData?.id);
    setDefultIndustry(item?.industryData?.value);
    setIndustryOption([
      {
        id: item?.industryData?.id,
        name: item?.industryData?.value,
      },
    ]);

    setCountryOption([
      {
        id: item?.state?.id,
        name: item?.state?.name,
      },
    ]);
    setCountryKey(Math.ceil(Math.random() * 999999));
    setIndustryKey(Math.ceil(Math.random() * 999999));
    setCityOptions([
      {
        id: item?.city?.id,
        name: item?.city?.city,
      },
    ]);
    setCityKey(Math.ceil(Math.random() * 999999));
    setDefultCity(item?.city?.city);
    setCity(item?.city?.id);
    setWorkLevel(item?.workLevel);
    setWorkModel(item?.workMode);
    setId(item?.id);
    $("#internship").modal("show");
  };
  const getCompanyJobs = async () => {
    setStatus(true);
    if (activeTab === "jobs") {
      try {
        let resp = await FetchApi(
          Endpoints.GetJobPostedAll +
            "?pageId=" +
            param?.id +
            "&size=" +
            limit +
            "&scope=Job"
        );
        if (resp && resp.success === true) {
          setJobData(resp?.data?.jobs);
          sePageData(resp?.data?.page);
          setStatus(false);
          setCount(resp?.data?.totalRecord);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      try {
        let resp = await FetchApi(
          Endpoints.GetJobPostedAll +
            "?pageId=" +
            param?.id +
            "&size=" +
            limit +
            "&scope=Internship"
        );
        if (resp && resp.success === true) {
          setJobData(resp?.data?.jobs);
          sePageData(resp?.data?.page);
          setStatus(false);
          setCount(resp?.data?.totalRecord);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!jobType) {
      setJobTypeError("Job type is required");
    } else {
      setJobTypeError("");
    }
    if (!jobTitle) {
      setJobTitleError("Job title is required");
    } else {
      setJobTitleError("");
    }
    if (!location) {
      setLocationError("Country is required");
    } else {
      setLocationError("");
    }
    if (!Qualification) {
      setQualificationError("Qualification is required");
    } else {
      setQualificationError("");
    }
    if (!Description) {
      setDescriptionError("Description is required");
    } else {
      setDescriptionError("");
    }
    if (!Industry) {
      setIndustryError("Industry is required");
    } else {
      setIndustryError("");
    }
    if (!WorkLevel) {
      setWorkLevelError("Work level is required");
    } else {
      setWorkLevelError("");
    }
    if (!WorkModel) {
      setWorkModelError("Work model is required");
    } else {
      setWorkModelError("");
    }
    if (!SalaryMin) {
      setSalaryMinError("Salary min is required");
    } else {
      setSalaryMinError("");
    }
    if (!SalaryMax) {
      setSalaryMaxError("Salary max  is required");
    } else {
      setSalaryMaxError("");
    }
    if (!ExperinceMax) {
      setExperinceMaxError("Experince max is required");
    } else {
      setExperinceMaxError("");
    }
    if (!ExperinceMin) {
      setExperinceMinError("Experince min is required");
    } else {
      setExperinceMinError("");
    }
    if (!city) {
      setCityError("City is required");
    } else {
      setCityError("");
    }

    if (SalaryMax) {
      if (Number(SalaryMin) > Number(SalaryMax)) {
        setSalaryMaxError("Salary max grater then salary min");
        return false;
      } else {
        setSalaryMaxError("");
      }
    }
    if (ExperinceMax) {
      if (ExperinceMin > ExperinceMax) {
        setExperinceMaxError("Experince max grater then experince min");
        return false;
      } else {
        setExperinceMaxError("");
      }
    }
    if (
      jobTitle &&
      jobType &&
      location &&
      Qualification &&
      Description &&
      Industry &&
      WorkLevel &&
      WorkModel &&
      SalaryMin &&
      SalaryMax &&
      ExperinceMax &&
      ExperinceMin &&
      SalaryMin <= SalaryMax &&
      ExperinceMin <= ExperinceMax &&
      city
    ) {
      let data = {
        title: jobTitle,
        pageId: pageData?.id,
        jobType: jobType,
        location: location,
        qualification: Qualification,
        description: Description,
        industry: Industry,
        workLevel: WorkLevel,
        workMode: WorkModel,
        minSalary: SalaryMin,
        maxSalary: SalaryMax,
        minExperience: ExperinceMin,
        maxExperience: ExperinceMax,
        cityId: city,
      };
      if (id) {
        try {
          let resp = await PatchApi(Endpoints.JobEdit + "/" + id, data);
          if (resp && resp.message) {
            $("#internship").modal("hide");
            getCompanyJobs();
            setSalaryMax("");
            setSalaryMin("");
            setExperinceMin("");
            setExperinceMax("");
            setJobTitle("");
            setJobType("");
            setLocation("");
            setQualification("");
            setDescription("");
            setIndustry("");
            setIndustryOption([]);
            setCountryKey([]);
            setIndustryKey(Math.ceil(Math.random() * 999999));
            setCountryKey(Math.ceil(Math.random() * 999999));
            setWorkLevel("");
            setWorkModel("");
            setCityOptions([]);
            setCityError("");
            setCityKey(Math.ceil(Math.random() * 999999));
            setDefultCity("");
            setCity("");
            setId("");
            ToastMessage.Success(resp.message);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      } else {
        try {
          let resp = await CommonAPi(Endpoints.CreateJobs, data);
          if (resp && resp.message) {
            $("#internship").modal("hide");
            getCompanyJobs();
            setSalaryMax("");
            setSalaryMin("");
            setExperinceMin("");
            setExperinceMax("");
            setJobTitle("");
            setJobType("");
            setLocation("");
            setQualification("");
            setDescription("");
            setIndustry("");
            setIndustryOption([]);
            setCountryKey([]);
            setIndustryKey(Math.ceil(Math.random() * 999999));
            setCountryKey(Math.ceil(Math.random() * 999999));
            setWorkLevel("");
            setWorkModel("");
            setCityOptions([]);
            setCityError("");
            setCityKey(Math.ceil(Math.random() * 999999));
            setDefultCity("");
            setCity("");
            ToastMessage.Success(resp.message);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      }
    }
  };
  const handleOnSearchIndustry = async (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string) {
      try {
        let resp = await FetchApi(
          Endpoints.SearchIndustry + "?title=" + string
        );
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                name: item?.value,
              };
            });
            setIndustryOption(newData);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  const handleOnSelectIndustry = (item) => {
    if (item.length > 0) {
      setIndustry(item[0].id);
    } else {
      setIndustry("");
    }
  };

  const RenderIndustry = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-StudyArea"
        defaultInputValue={defultIndustry}
        key={IndustryKey}
        onSearch={handleOnSearchIndustry}
        onChange={handleOnSelectIndustry}
        // onBlur={(e) => { handleValidation(e, e.target.value, "study") }}
        options={IndustryOption}
        placeholder={
          param?.type === "School"
            ? "For Ex: High School"
            : "For Ex: IT Services and IT Consulting"
        }
        labelKey="name"
        minLength={1}
        emptyLabel={"No result found"}
        className={
          IndustryError
            ? " border rounded-2 fs-14 p-2 "
            : " border rounded-2 fs-14 p-2 "
        }
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [IndustryOption, IndustryError]);

  const handleOnSelectLocation = (item) => {
    if (item.length > 0) {
      setLocation(item[0].id);
    } else {
      setLocation("");
    }
  };

  const handleOnSearchLocation = async (string) => {
    try {
      let resp = await FetchApi(Endpoints.StateSearch + "?term=" + string);
      if (resp && resp.success === true) {
        const newData = resp.data.map((item, index) => {
          return {
            id: item.id,
            name: item.state,
          };
        });
        setCountryOption(newData);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const RenderCountry = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-StudyArea"
        defaultInputValue={Defaultlocation}
        key={countryKey}
        onSearch={handleOnSearchLocation}
        onChange={handleOnSelectLocation}
        options={countryOption}
        placeholder={"Enter State"}
        labelKey="name"
        minLength={1}
        emptyLabel={"No result found"}
        className={
          countryError
            ? " border rounded-2 fs-14 p-2 "
            : " border rounded-2 fs-14 p-2 "
        }
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [countryOption, countryError]);
  const RenderCity = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-StudyArea"
        defaultInputValue={defultCity}
        key={cityKey}
        onSearch={handleOnSearchCity}
        onChange={handleOnSelectCity}
        options={cityOptions}
        placeholder={"Enter City"}
        labelKey="name"
        minLength={1}
        emptyLabel={"No result found"}
        className={
          cityError
            ? " border rounded-2 fs-14 p-2 "
            : " border rounded-2 fs-14 p-2 "
        }
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [cityOptions, cityError]);

  const handleOnSelectCity = (item) => {
    if (item.length > 0) {
      setCity(item[0].id);
    } else {
      setCity("");
    }
  };

  const handleOnSearchCity = async (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string) {
      try {
        let resp = await FetchApi(Endpoints.SearchCities + "?term=" + string);
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                name: item?.city,
              };
            });
            setCityOptions(newData);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "year ago";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon ago";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + " day ago";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + " h ago";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min ago";
    return Math.floor(seconds) >= 0
      ? Math.floor(seconds) + " sec ago"
      : "0 sec ago";
  };

  const handleChangeStatus = async (Id, action) => {
    let data = "";
    if (action === false) {
      data = {
        action: "false",
      };
    } else {
      data = {
        action: "true",
      };
    }

    try {
      let resp = await PatchApi(Endpoints.JobStatus + "/" + Id, data);
      if (resp && resp.message) {
        ToastMessage.Success(resp.message);
        getCompanyJobs();
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleDelete = async (Id) => {
    let data = {
      id: Id,
    };
    setShowDeleteDialog(false);
    try {
      let resp = await DeleteApi(Endpoints.JobDelete, data);
      if (resp && resp.message) {
        ToastMessage.Success(resp.message);
        getCompanyJobs();
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleClsoseModel = () => {
    if (
      jobTitle ||
      jobType ||
      location ||
      Defaultlocation ||
      Qualification ||
      Description ||
      Industry ||
      WorkLevel ||
      WorkModel ||
      SalaryMin ||
      SalaryMax ||
      ExperinceMax ||
      ExperinceMin ||
      IndustryOption.length > 0 ||
      countryOption.length > 0
    ) {
      Swal.fire({
        text: "You have unsaved changes, do you still want to exit?",
        icon: "question",
        customClass: {
          confirmButton: "btn px-3 mx-2",
          cancelButton: "btn btn-br-blue px-3",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Yes, discard it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setSalaryMax("");
          setSalaryMin("");
          setExperinceMin("");
          setExperinceMax("");
          setJobTitle("");
          setJobType("Internship");
          setLocation("");
          setDefaultLocation("");
          setQualification("");
          setDescription("");
          setIndustry("");
          setIndustryOption([]);
          setCountryOption([]);
          setCountryKey(Math.ceil(Math.random() * 999999));
          setIndustryKey(Math.ceil(Math.random() * 999999));
          setWorkLevel("");
          setWorkModel("");
          setId("");
          setJobTypeError("");
          setJobTitleError("");
          setLocationError("");
          setQualificationError("");
          setDescriptionError("");
          setIndustryError("");
          setWorkLevelError("");
          setWorkModelError("");
          setSalaryMinError("");
          setSalaryMaxError("");
          setExperinceMaxError("");
          setExperinceMinError("");
          setCityError("");
          $("#internship").modal("hide");
        } else {
          // setId("");
          $("#internship").modal("show");
        }
      });
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Jobs</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-lg-4 py-3">
            <AdminJobProfiles
              {...{
                aboutData: pageData,
              }}
            />
            <div className="row">
              <div className="col-md-12 pb-3">
                <div className="rounded-15 px-4 py-3 bg-white mb-3">
                  <h4 className="fs-20 medium pb-4 text-center">
                    Manage Jobs/Internships
                  </h4>
                  <ul className="profile-menu d-flex justify-content-center flex-wrap gap-lg-3">
                    <li className={activeTab === "jobs" ? "active" : ""}>
                      <Link
                        onClick={() => {
                          setActiveTab("jobs");
                        }}
                      >
                        Jobs
                      </Link>
                    </li>

                    <li className={activeTab === "internships" ? "active" : ""}>
                      <Link
                        onClick={() => {
                          setActiveTab("internships");
                          setJobType("Internship");
                        }}
                      >
                        Internships
                      </Link>
                    </li>
                  </ul>
                  <div className="d-flex justify-content-between align-items-center py-3">
                    <h4 className="medium">
                      My <span className="job-internship">{activeTab}</span>{" "}
                      Listings
                    </h4>
                    <a
                      href="#"
                      className="btn medium fs-14"
                      data-bs-toggle="modal"
                      data-bs-target="#internship"
                    >
                      Post a New{" "}
                      <span className="job-internship">{activeTab}</span>
                    </a>
                  </div>

                  {status === false ? (
                    <table className="table job-table job-profile-table">
                      <thead>
                        <tr>
                          <th width="30%">Job Title</th>
                          <th>Applications</th>
                          <th>Shortlisted</th>
                          <th>Created</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobData?.length > 0 ? (
                          jobData?.map((item, index) => {
                            return (
                              <tr>
                                <td>
                                  <div className="p-lg-3 d-flex gap-2 align-items-center ">
                                    <img
                                      src="../assets/img/icon/digital-ocean.svg"
                                      className="rounded-circle"
                                      width="60"
                                      height="60"
                                      alt=""
                                    />
                                    <div>
                                      <h5 className="semibold">
                                        {item?.title}
                                      </h5>
                                      <div className="d-flex py-2 align-items-center">
                                        <span className="fs-10 text-darkgray pe-2 d-flex align-items-center">
                                          <img
                                            src="../assets/img/icon/icon-location.svg"
                                            alt=""
                                            className="align-middle me-1"
                                          />
                                          <span>
                                            {item?.city?.city}{" "}
                                            {item?.city?.city ? "," : ""}{" "}
                                            {item?.state?.state}
                                          </span>
                                        </span>
                                        <span className="fs-10 text-darkgray d-flex align-items-center">
                                          <img
                                            src="../assets/img/icon/icon-date-time.svg"
                                            alt=""
                                            className="align-middle me-1"
                                          />
                                          <span>
                                            {timeSince(
                                              new Date(item.createdAt)
                                            )}
                                          </span>
                                        </span>
                                      </div>
                                      <div className="d-flex request-btn gap-2 mb-2">
                                        <a
                                          href="#"
                                          className="btn light-brown-btn px-2 medium"
                                        >
                                          {item?.jobType}
                                        </a>
                                        <a
                                          href="#"
                                          className="btn light-brown-btn px-2 medium"
                                        >
                                          {item?.workMode}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="p-lg-3">
                                  <Link
                                    to={"/admin-jobs-application/" + item.id}
                                    target={"_blank"}
                                  >
                                    <span className="btn btn-light-blue px-3 bold rounded-0 fs-14 py-2 btn-br-blue">
                                      {item?.totalApplication}
                                    </span>
                                  </Link>
                                </td>
                                <td className="p-lg-3">
                                  <Link
                                    to={
                                      "/admin-jobs-shortlisted-application/" +
                                      item.id
                                    }
                                    target={"_blank"}
                                  >
                                    <span className="btn px-3 bold rounded-0 fs-14 py-2 btn-lightgreen">
                                      {item?.totalShortlisted}
                                    </span>
                                  </Link>
                                </td>
                                <td className="p-lg-3">
                                  <h4 className="fs-15 medium">
                                    {moment(item.createdAt).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </h4>
                                </td>
                                <td className="p-lg-3">
                                  {item.status === true ? (
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        onChange={() => {
                                          handleChangeStatus(item.id, false);
                                        }}
                                        checked
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                  ) : (
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        onChange={() => {
                                          handleChangeStatus(item.id, true);
                                        }}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                  )}
                                </td>
                                <td className="action-btn p-lg-3">
                                  <div className="d-flex gap-2">
                                    <Link
                                      to={"/admin-jobs-view/" + item?.id}
                                      className="bg-lightblue rounded-10"
                                    >
                                      <img
                                        src="../assets/img/icon/eye.svg"
                                        width="15"
                                        className=""
                                      />
                                    </Link>
                                    <Link
                                      onClick={() => {
                                        handleEdit(item);
                                      }}
                                      className="bg-lightblue rounded-10"
                                    >
                                      <img
                                        src="../assets/img/icon/blue-edit.svg"
                                        className="icon-green"
                                      />
                                    </Link>
                                    <Link
                                      onClick={() => {
                                        setDeleteItem(item);
                                        setShowDeleteDialog(true);
                                      }}
                                      className="bg-lightblue rounded-10"
                                    >
                                      <img
                                        src="../assets/img/icon/blue-delete.svg"
                                        className=" icon-red"
                                      />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : activeTab === "jobs" ? (
                          <tr>
                            <td colSpan="6">No jobs added</td>
                          </tr>
                        ) : (
                          <tr>
                            <td colSpan="6">No internship added</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  ) : (
                    ""
                  )}

                  {count > limit ? (
                    <div className="text-center pt-4">
                      <Link
                        onClick={() => {
                          setLimit(limit + 10);
                        }}
                        className="btn btn-light-blue px-3 medium fs-14 py-2"
                      >
                        Load More {activeTab}{" "}
                        <img
                          src="../assets/img/icon/icon-loading.svg"
                          className="ms-1"
                          alt=""
                        />
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <OnlineFriends />

            <div className="modal" id="internship" tabIndex="-1">
              <div className="modal-dialog modal-lg px-lg-5 modal-dialog-centered modal-dialog-scrollable modal-md">
                <div className="modal-content">
                  <button
                    type="button"
                    className="btn-close ms-auto p-3 position-absolute end-0 "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleClsoseModel}
                  ></button>
                  <div className="text-center py-4 border-bottom">
                    <h5 className="modal-title text-center fs-20 medium text-black">
                      {/* {id ? "Edit" : "Create"}{" "} */}
                      {id ? "Update" : "Create"}{" "}
                      <span className="job-internship">{activeTab} </span>
                    </h5>
                  </div>
                  <div className="modal-body">
                    <form
                      action=""
                      onSubmit={handleSubmit}
                      className="site-form"
                    >
                      <span className="text-darkgray medium fs-12 pb-3 d-block">
                        * All fields are required.
                      </span>
                      <div className="form-field mb-3">
                        <label htmlFor="" className="pb-1 fs-14 medium">
                          Job Title *
                        </label>
                        <input
                          type="text"
                          placeholder="Senior UI/UX Designer"
                          className="fs-14 border rounded-3 py-2 px-3 w-100"
                          value={jobTitle}
                          onChange={(e) => {
                            setJobTitle(e.target.value);
                          }}
                          maxLength={"300"}
                        />
                        <div
                          id="count1"
                          className="fs-12 text-darkgray text-end"
                        >
                          <span id="current1">{jobTitle?.length}</span>
                          <span id="maximum1">/ 300</span>
                        </div>
                        {jobTitleError ? (
                          <span className="field-error fs-12 text-red">
                            {jobTitleError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-field mb-3">
                        <label htmlFor="" className="pb-1 fs-14 medium">
                          Job Type *
                        </label>
                        {activeTab == "jobs" ? (
                          <select
                            className=" text-darkgray border rounded-3 py-2 px-3 w-100"
                            onChange={(e) => {
                              setJobType(e.target.value);
                            }}
                          >
                            <option value="">Select</option>
                            <option
                              value="Full-time"
                              selected={jobType == "Full-time" ? true : false}
                            >
                              Full-time
                            </option>
                            <option
                              value="Part-time"
                              selected={jobType == "Part-time" ? true : false}
                            >
                              Part-time
                            </option>
                            <option
                              value="Contract"
                              selected={jobType == "Contract" ? true : false}
                            >
                              {" "}
                              Contract
                            </option>
                            <option
                              value="Temporary"
                              selected={jobType == "Temporary" ? true : false}
                            >
                              Temporary
                            </option>
                            <option
                              value="Freelance"
                              selected={jobType == "Freelance" ? true : false}
                            >
                              Freelance
                            </option>
                            {/* <option value="Internship" selected={jobType == "Internship" ? true : false}>Internship</option> */}
                          </select>
                        ) : (
                          <select
                            className="  text-darkgray border rounded-3 py-2 px-3 w-100"
                            disabled
                          >
                            <option value="Internship" selected={true}>
                              Internship
                            </option>
                          </select>
                        )}

                        {jobTypeError ? (
                          <span className="field-error fs-12 text-red">
                            {jobTypeError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-field mb-3">
                        <label htmlFor="" className="pb-1 fs-14 medium">
                          Location *
                        </label>
                        <div className="row">
                          <div className="col-md-6">
                            {RenderCity()}
                            {cityError ? (
                              <span className="field-error fs-12 text-red">
                                {cityError}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            {RenderCountry()}
                            {locationError ? (
                              <span className="field-error fs-12 text-red">
                                {locationError}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-field mb-3">
                        <label htmlFor="" className="pb-1 fs-14 medium">
                          Qualification *
                        </label>
                        <select
                          className=" text-darkgray border rounded-3 py-2 px-3 w-100"
                          onChange={(e) => {
                            setQualification(e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          <option
                            value="10th Pass"
                            selected={
                              Qualification == "10th Pass" ? true : false
                            }
                          >
                            10th Pass
                          </option>
                          <option
                            value="12th Pass"
                            selected={
                              Qualification == "12th Pass" ? true : false
                            }
                          >
                            12th Pass
                          </option>
                          <option
                            value="Diploma or Equivalent"
                            selected={
                              Qualification == "Diploma or Equivalent"
                                ? true
                                : false
                            }
                          >
                            Diploma or Equivalent
                          </option>
                          <option
                            value="Associate's Degree"
                            selected={
                              Qualification == "Associate's Degree"
                                ? true
                                : false
                            }
                          >
                            Associate's Degree
                          </option>
                          <option
                            value="Bachelor's Degree"
                            selected={
                              Qualification == "Bachelor's Degree"
                                ? true
                                : false
                            }
                          >
                            Bachelor's Degree
                          </option>
                          <option
                            value="Master's Degree"
                            selected={
                              Qualification == "Master's Degree" ? true : false
                            }
                          >
                            Master's Degree
                          </option>
                          <option
                            value="Ph.D"
                            selected={Qualification == "Ph.D" ? true : false}
                          >
                            Ph.D
                          </option>
                          <option
                            value="Professional Degree"
                            selected={
                              Qualification == "Professional Degree"
                                ? true
                                : false
                            }
                          >
                            Professional Degree
                          </option>
                          <option
                            value="Apprenticeships"
                            selected={
                              Qualification == "Apprenticeships" ? true : false
                            }
                          >
                            Apprenticeships
                          </option>
                          <option
                            value="Internships"
                            selected={
                              Qualification == "Internships" ? true : false
                            }
                          >
                            Internships
                          </option>
                        </select>
                        {QualificationError ? (
                          <span className="field-error fs-12 text-red">
                            {QualificationError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-field mb-3">
                        <label htmlFor="" className="pb-1 fs-14 medium">
                          Job Description
                        </label>
                        {/* <textarea name=""
                                                    id=""
                                                    cols="30"
                                                    rows="5"
                                                    className="fs-14 border rounded-3 py-2 px-3 w-100"
                                                    placeholder="Detailed job Description"
                                                    value={Description}
                                                    onChange={(e) => {
                                                        setDescription(e.target.value)
                                                    }}
                                                ></textarea> */}
                        <CKEditor
                          data={Description}
                          className={"editor text-gray px-3 py-3 border"}
                          cols="30"
                          rows="10"
                          onChange={(event, editor) => {
                            setDescription(editor.getData());
                          }}
                          editor={ClassicEditor}
                          config={{
                            toolbar: [
                              "bold",
                              "italic",
                              "link",
                              "blockQuote",
                              "numberedList",
                              "bulletedList",
                              "|",
                              "undo",
                              "redo",
                            ],
                          }}
                        />
                        {DescriptionError ? (
                          <span className="field-error fs-12 text-red">
                            {DescriptionError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-field mb-3">
                        <label htmlFor="" className="pb-1 fs-14 medium">
                          Industry *
                        </label>
                        {RenderIndustry()}
                        {IndustryError ? (
                          <span className="field-error fs-12 text-red">
                            {IndustryError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-field mb-3">
                        <label htmlFor="" className="pb-1 fs-14 medium">
                          Work Level *
                        </label>
                        <select
                          className=" text-darkgray border rounded-3 py-2 px-3 w-100"
                          onChange={(e) => {
                            setWorkLevel(e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          <option
                            value="Entry-Level"
                            selected={WorkLevel == "Entry-Level" ? true : false}
                          >
                            Entry-Level
                          </option>
                          <option
                            value="Junior-Level"
                            selected={
                              WorkLevel == "Junior-Level" ? true : false
                            }
                          >
                            Junior-Level
                          </option>
                          <option
                            value="Mid-Level"
                            selected={WorkLevel == "Mid-Level" ? true : false}
                          >
                            Mid-Level
                          </option>
                          <option
                            value="Senior-Level"
                            selected={
                              WorkLevel == "Senior-Level" ? true : false
                            }
                          >
                            Senior-Level
                          </option>
                          <option
                            value="Managerial/Supervisory"
                            selected={
                              WorkLevel == "Managerial/Supervisory"
                                ? true
                                : false
                            }
                          >
                            Managerial/Supervisory
                          </option>
                          <option
                            value="Director-Level"
                            selected={
                              WorkLevel == "Director-Level" ? true : false
                            }
                          >
                            Director-Level
                          </option>
                          <option
                            value="Executive-Level"
                            selected={
                              WorkLevel == "Executive-Level" ? true : false
                            }
                          >
                            Executive-Level
                          </option>
                        </select>
                        {WorkLevelError ? (
                          <span className="field-error fs-12 text-red">
                            {WorkLevelError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-field mb-3">
                        <label htmlFor="" className="pb-1 fs-14 medium">
                          Work Model *
                        </label>
                        <select
                          className=" text-darkgray border rounded-3 py-2 px-3 w-100"
                          onChange={(e) => {
                            setWorkModel(e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          <option
                            value="On-site"
                            selected={WorkModel == "On-site" ? true : false}
                          >
                            On-site
                          </option>
                          <option
                            value="Remote"
                            selected={WorkModel == "Remote" ? true : false}
                          >
                            Remote
                          </option>
                          <option
                            value="Hybrid"
                            selected={WorkModel == "Hybrid" ? true : false}
                          >
                            Hybrid
                          </option>
                          <option
                            value="Work from home"
                            selected={
                              WorkModel == "Work from home" ? true : false
                            }
                          >
                            Work from home
                          </option>
                        </select>
                        {WorkModelError ? (
                          <span className="field-error fs-12 text-red">
                            {WorkModelError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        <h5 className="fs-12 medium pb-2 ">
                          Salary *{" "}
                          <span className="text-lightgray">(per month)</span>
                        </h5>
                        <div className="row">
                          <div className="col-4">
                            <div className="form-field mb-3">
                              <label htmlFor="" className="pb-1 fs-14 medium">
                                Min
                              </label>
                              <input
                                type="number"
                                placeholder="Rs. 10,000"
                                className="custom-number-input fs-14 border rounded-3 py-2 px-3 w-100"
                                value={SalaryMin}
                                onKeyDown={(e) => {
                                  if (e.key === "e" || e.key === "E") {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) => {
                                  setSalaryMin(e.target.value);
                                }}
                              />
                            </div>
                            {SalaryMinError ? (
                              <span className="field-error fs-12 text-red">
                                {SalaryMinError}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-4">
                            <div className="form-field mb-3">
                              <label htmlFor="" className="pb-1 fs-14 medium">
                                Max
                              </label>
                              <input
                                type="number"
                                onKeyDown={(e) => {
                                  if (e.key === "e" || e.key === "E") {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder="Rs. 25,000"
                                className=" custom-number-input fs-14 border rounded-3 py-2 px-3 w-100"
                                value={SalaryMax}
                                onChange={(e) => {
                                  setSalaryMax(e.target.value);
                                }}
                              />
                              {SalaryMaxError ? (
                                <span className="field-error fs-12 text-red">
                                  {SalaryMaxError}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <h5 className="fs-12 medium pb-2 ">Experience *</h5>
                        <div className="row">
                          <div className="col-4">
                            <div className="form-field mb-3">
                              <label htmlFor="" className="pb-1 fs-14 medium">
                                Min
                              </label>
                              <input
                                type="text"
                                placeholder="1 Yr"
                                className="fs-14 border rounded-3 py-2 px-3 w-100"
                                value={ExperinceMin}
                                onChange={(e) => {
                                  setExperinceMin(e.target.value);
                                }}
                              />
                              {ExperinceMinError ? (
                                <span className="field-error fs-12 text-red">
                                  {ExperinceMinError}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-field mb-3">
                              <label htmlFor="" className="pb-1 fs-14 medium">
                                Max
                              </label>
                              <input
                                type="text"
                                placeholder="4 Yr"
                                className="fs-14 border rounded-3 py-2 px-3 w-100"
                                value={ExperinceMax}
                                onChange={(e) => {
                                  setExperinceMax(e.target.value);
                                }}
                              />
                              {ExperinceMaxError ? (
                                <span className="field-error fs-12 text-red">
                                  {ExperinceMaxError}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <input
                        type="submit"
                        value={
                          id
                            ? jobType == "Internship"
                              ? " Update Internship"
                              : "Update Job"
                            : jobType == "Internship"
                            ? "Post Internship"
                            : "Post Job"
                        }
                        className="btn fs-14 medium px-5"
                      />
                      {/* <a href="#" className="btn fs-14 medium px-5">Post Job</a> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <ConfirmDialog
              show={showDeleteDialog}
              buttonText="Delete"
              body="Are you sure want to delete?"
              danger
              header="Confirm Delete"
              onCancel={() => {
                setShowDeleteDialog(false);
              }}
              onConfirm={() => handleDelete(deleteItem?.id)}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
