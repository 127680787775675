import React, { useCallback, useEffect, useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import { DeleteApi } from "../../API/DeleteApi";
import { UpdateAPi } from "../../API/UpdateApi";
import ToastMessage from "../../Utils/ToastMessage";
import { CommonAPi } from "../../API/CommonApi";
import { Link, useNavigate } from "react-router-dom";
import DiscardPopup from "../../Components/DiscardPopup/DiscardPopup";
import DeletePrompt from "../../Components/DeletePrompt/DeletePrompt";
import Item from "./Item";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import { Helmet } from "react-helmet";
let jQuery = window.jQuery;
const $ = window.jQuery;
function HonorAwards() {
  const [awardName, setAwardName] = useState("");
  const [allChilds, setAllChilds] = useState([]);
  const [instituteName, setInstituteName] = useState("");
  const [type, setType] = useState("");
  const [Id, setId] = useState("");
  const [recognitionLevel, setRecognitionLevel] = useState("");
  const [className, setclassName] = useState("");
  const [recognitionLevelData, setRecognitionLevelData] = useState("");
  const [pageId, setpageId] = useState(0);
  const [options, setOptions] = useState([]);
  const [honorAwards, setHonorAwards] = useState([]);
  const [status, setStatus] = useState("Honor");
  const [userid, setUserId] = useState("");
  const userId = localStorage.getItem("userId");

  const [typeError, setTypeError] = useState("");
  const [awardNameError, setAwardNameError] = useState("");
  const [recognitionLevelError, setRecognitionLevelError] = useState("");
  const [classNameError, setclassNameError] = useState("");
  const [useridError, setUserIdError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [item, setItem] = useState(null);
  const [deleteType, setDeleteType] = useState(null);
  const [instituteNameKey, setInstituteNameKey] = useState("instituteNameKey");
  const [Search, setSearch] = useState("");
  const Navigate = useNavigate();
  useEffect(() => {
    GetHonorsAwards();
    getUserChilds();
  }, []);

  const handleOnSelect = (item) => {
    if (item.length > 0) {
      setpageId(item[0].id);
      setRecognitionLevel(item[0].name);
    } else {
      setpageId(0);
      setRecognitionLevel("");
    }
  };

  const handleOnSearch = async (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setSearch(string);
    setRecognitionLevel(string);
    if (string) {
      try {
        let resp = await FetchApi(Endpoints.SearchPages + "?title=" + string);
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                image: item?.pageImage,
                name: item?.title,
              };
            });
            setOptions(newData);
          } else {
            const newData = [
              {
                id: 0,
                image: "",
                name: string,
              },
            ];
            setOptions(newData);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const RenderInstituteName = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example"
        defaultInputValue={instituteName}
        key={instituteNameKey}
        onSearch={handleOnSearch}
        onChange={handleOnSelect}
        onBlur={(e) => {
          handleValidation(e, e.target.value, "institute");
        }}
        options={options}
        placeholder="Search by institute"
        labelKey="name"
        minLength={1}
        emptyLabel={"Searching..."}
        className={
          recognitionLevelError
            ? "is-danger active text-gray px-1 py-1 border"
            : " active text-gray px-1 py-1 border"
        }
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <img
              alt={option.name}
              src={
                option.image
                  ? Endpoints.baseUrl + option.image
                  : "../assets/img/icon/briefcase.png"
              }
              style={{
                height: "24px",
                marginRight: "10px",
                width: "24px",
              }}
            />
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [instituteName, options, recognitionLevelError, recognitionLevelError]);

  useEffect(() => {
    if (
      awardName !== "" &&
      recognitionLevel !== "" &&
      pageId !== "" &&
      type !== "" &&
      className !== "" &&
      userid !== ""
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [awardName, recognitionLevel, pageId, type, className, userid]);

  const GetHonorsAwards = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetHonors);
      if (resp && resp.message) {
        setHonorAwards(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getUserChilds = async () => {
    const options = {
      profile: userId,
      authorization: "Bearer " + localStorage.getItem("token"),
    };
    try {
      let resp = await CommonAPi(Endpoints.GetUserChilds, null, options);
      if (resp) {
        setAllChilds(resp.data);
      }
    } catch (e) {
      console.log(e.response.data);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!type) {
      setTypeError("Please select type");
    } else if (type) {
      setTypeError("");
    }
    if (!awardName) {
      setAwardNameError("Please enter" + type + "title");
    } else if (awardName) {
      setAwardNameError("");
    }
    if (!userid) {
      setUserIdError("Please select user type");
    } else if (userid) {
      setUserIdError("");
    }
    if (!recognitionLevel) {
      setRecognitionLevelError("Please enter institute");
    } else if (recognitionLevel) {
      setRecognitionLevelError("");
    }
    if (!className) {
      setclassNameError("Please enter class name");
    } else if (className) {
      setclassNameError("");
    }
    if (awardName && recognitionLevel && type && className && userid) {
      let data = {
        award_name: awardName,
        institute: recognitionLevel,
        page_id: pageId,
        type: type,
        award_for: className,
        user_id: userid,
      };

      try {
        let resp = "";
        if (Id) {
          resp = await UpdateAPi(Endpoints.UpdateHonor + "/" + Id, data);
        } else {
          resp = await CommonAPi(Endpoints.CreateHonors, data);
        }
        if (resp && resp.message) {
          // console.log("hello");
          document.getElementById("myform").reset();
          setRecognitionLevel("");
          setAwardName("");
          setpageId(0);
          setType("");
          setclassName("");
          setInstituteName("");
          setRecognitionLevelData("");
          setInstituteNameKey(Math.ceil(Math.random() * 999999));
          setOptions([]);
          $("#Honormodal").modal("hide");
          GetHonorsAwards();
          // console.log("hello");
          ToastMessage.Success(resp.message);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleCloseModel = () => {
    if (
      awardName !== "" ||
      instituteName !== "" ||
      recognitionLevel !== "" ||
      className !== "" ||
      type !== "" ||
      userid !== ""
    ) {
      $("#discardInfoModal-Honor").modal("show");
    }
  };

  const handleDisCard = () => {
    setRecognitionLevel("");
    setAwardName("");
    setpageId(0);
    setType("");
    setclassName("");
    setId("");
    setRecognitionLevelData("");
    setOptions([]);
    setInstituteName("");
    setInstituteNameKey(Math.ceil(Math.random() * 999999));
    setUserIdError("");
    setclassNameError("");
    setRecognitionLevelError("");
    setAwardNameError("");
    setTypeError("");
    $("#discardInfoModal-Honor").modal("hide");
    $("#Honormodal").modal("hide");
  };

  const handleModel = () => {
    $("#discardInfoModal-Honor").modal("hide");
    $("#Honormodal").modal("show");
  };

  const handleDelete = async (Id, type) => {
    try {
      let resp = await DeleteApi(Endpoints.DeleteHonors + "/" + Id);
      if (resp && resp.message) {
        GetHonorsAwards();
        ToastMessage.Success(type + " deleted successfully");
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleValidation = (e, value, field) => {
    e.preventDefault();
    if (!value && field === "type") {
      setTypeError("Please select type");
    } else if (value && field === "type") {
      setTypeError("");
    }
    if (!value && field === "title") {
      setAwardNameError("Please enter title");
    } else if (value && field === "title") {
      setAwardNameError("");
    }
    if (!value && field === "userType") {
      setUserIdError("Please select user type");
    } else if (value && field === "userType") {
      setUserIdError("");
    }
    if (!value && field === "institute") {
      if (!recognitionLevelData) {
        setRecognitionLevelError("Please enter institute");
      } else {
        setRecognitionLevelError("");
      }
    } else if (value && field === "institute") {
      setRecognitionLevelError("");
    }
    if (!value && field === "class") {
      setclassNameError("Please enter class name");
    } else if (value && field === "class") {
      setclassNameError("");
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Honor & Awards</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />

          <div className="container py-3">
            <div className="bg-white main-common">
              <div className="main-top-row d-md-flex justify-content-between align-items-center pb-3">
                <h3 className="medium">Honors & Awards</h3>

                <div className="">
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#Honormodal"
                    className="btn btn-br-blue"
                  >
                    Add Honor/Reward +
                  </a>
                </div>
              </div>

              <div className="mainmenu-filter-row pb-3">
                <ul className="filter d-flex flex-nowrap align-items-center gap-2 fs-14 medium">
                  <li
                    className={status === "Honor" ? "active" : ""}
                    onClick={() => {
                      setStatus("Honor");
                    }}
                  >
                    My Honors
                  </li>
                  <li
                    className={status === "Awards" ? "active" : ""}
                    onClick={() => {
                      setStatus("Awards");
                    }}
                  >
                    My Awards
                  </li>
                </ul>
              </div>
              {honorAwards.length > 0 ? (
                <div className="row">
                  {honorAwards.map((item, i) => {
                    return (
                      <>
                        {status === "Honor" ? (
                          item.type === "Honor" ? (
                            <Item
                              {...{
                                item: item,
                                setItem: setItem,
                                setDeleteType: setDeleteType,
                                setpageId,
                                setId,
                                setUserId,
                                setRecognitionLevelData,
                                setInstituteName,
                                setRecognitionLevel,
                                setclassName,
                                setType,
                                setAwardName,
                                setInstituteNameKey,
                                setOptions,
                              }}
                            />
                          ) : (
                            ""
                          )
                        ) : item.type === "Award" ? (
                          <Item
                            {...{
                              item: item,
                              setItem: setItem,
                              setDeleteType: setDeleteType,
                              setpageId,
                              setId,
                              setUserId,
                              setRecognitionLevelData,
                              setInstituteName,
                              setRecognitionLevel,
                              setclassName,
                              setType,
                              setAwardName,
                              setInstituteNameKey,
                              setOptions,
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
                </div>
              ) : (
                <div className="row">No {status} found</div>
              )}
            </div>
          </div>

          <OnlineFriends />
        </div>
      </div>

      <div
        className="modal fade"
        id="Honormodal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog rounded-10 box-shadow p-lg-4 p-md-3 p-2 bg-white modal-650p">
          <div className="modal-content border-0">
            <div className="d-flex align-items-center justify-content-between pb-3 border-bottom mb-3 w-100">
              <h3 className="medium text-blue">Honor & Awards</h3>
              <button
                type="button"
                onClick={handleCloseModel}
                className="btn-close kids-close-icon honorsButton cursor-pointer"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#949494"
                  width="22"
                  height="22"
                >
                  <path
                    stroke-linecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="modal-body">
              <h4 className="medium text-black pb-3">
                Fill the form below to {Id ? "edit" : "create"} Honor & Awards
              </h4>

              <form
                onSubmit={handleSubmit}
                className="kids-form generic-form"
                id="myform"
              >
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label>Type</label>
                    <select
                      onBlur={(e) => {
                        handleValidation(e, e.target.value, "type");
                      }}
                      className={
                        typeError
                          ? "is-danger  text-gray px-3 py-3 border"
                          : " text-gray px-3 py-3 border"
                      }
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    >
                      <option value={""}>Select Type</option>
                      <option
                        selected={type === "Honor" ? true : false}
                        value={"Honor"}
                      >
                        Honor
                      </option>
                      <option
                        selected={type === "Award" ? true : false}
                        value={"Award"}
                      >
                        Award
                      </option>
                    </select>
                    {typeError ? (
                      <div
                        className="form-error"
                        style={{ color: "white", margintop: "-10px" }}
                      >
                        {typeError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label>Title</label>
                    <input
                      type="text"
                      placeholder={type + " title"}
                      onBlur={(e) => {
                        handleValidation(e, e.target.value, "title");
                      }}
                      className={
                        awardNameError
                          ? "is-danger  text-gray px-3 py-3 border"
                          : " text-gray px-3 py-3 border"
                      }
                      value={awardName}
                      onChange={(e) => {
                        setAwardName(e.target.value);
                      }}
                      maxLength={"120"}
                    />
                    <div id="count1" className="fs-12 text-darkgray text-end">
                      <span id="current1">{awardName?.length}</span>
                      <span id="maximum1">/ 120</span>
                    </div>
                    {awardNameError ? (
                      <div
                        className="form-error"
                        style={{ color: "white", margintop: "-10px" }}
                      >
                        {awardNameError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>User type</label>
                    <select
                      onChange={(e) => {
                        setUserId(e.target.value);
                      }}
                      onBlur={(e) => {
                        handleValidation(e, e.target.value, "userType");
                      }}
                      className={
                        useridError
                          ? "is-danger  text-gray px-3 py-3 border"
                          : " text-gray px-3 py-3 border"
                      }
                    >
                      <option value={""}>Select User Type</option>
                      <option
                        selected={
                          Math.round(userid) === Math.round(userId)
                            ? true
                            : false
                        }
                        value={userId}
                      >
                        Myself
                      </option>
                      {allChilds.map((item, index) => {
                        return (
                          <option
                            selected={
                              Math.round(userid) === Math.round(item.id)
                                ? true
                                : false
                            }
                            key={index}
                            value={item.id}
                          >
                            {item.fullName}
                          </option>
                        );
                      })}
                    </select>
                    {useridError ? (
                      <div
                        className="form-error"
                        style={{ color: "white", margintop: "-10px" }}
                      >
                        {useridError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>Class name</label>
                    <input
                      type="text"
                      placeholder="Class name"
                      onBlur={(e) => {
                        handleValidation(e, e.target.value, "class");
                      }}
                      className={
                        classNameError
                          ? "is-danger  text-gray px-3 py-3 border"
                          : " text-gray px-3 py-3 border"
                      }
                      value={className}
                      onChange={(e) => {
                        setclassName(e.target.value);
                      }}
                    />
                    {classNameError ? (
                      <div
                        className="form-error"
                        style={{ color: "white", margintop: "-10px" }}
                      >
                        {classNameError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 mb-3">
                    <label>School/College/University/Institute</label>

                    {RenderInstituteName()}
                    {recognitionLevelError ? (
                      <div
                        className="form-error"
                        style={{ color: "white", margintop: "-10px" }}
                      >
                        {recognitionLevelError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-12 text-end">
                    <button
                      disabled={isDisabled}
                      type="submit"
                      className="btn fs-14 "
                    >
                      {" "}
                      {Id ? "Update" : "Create"} {type}{" "}
                      <img
                        src="../assets/img/icon/icon-right-arrow.svg"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <DeletePrompt type={"Honor"} item={item} deleteType={handleDelete} />
      <DeletePrompt type={"Award"} item={item} deleteType={handleDelete} />
      <DiscardPopup
        {...{
          type: "Honor",
          onSuccess: handleModel,
          onDisCard: handleDisCard,
        }}
      />
    </Container>
  );
}

export default HonorAwards;
