import React, { useEffect, useState, useRef } from "react";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { CommonAPi } from "../../API/CommonApi";
import { DeleteApi } from "../../API/DeleteApi";
import PostReactions from "../../Components/PostReactions/PostReactions";
import { Link, useNavigate, useParams } from "react-router-dom";
import SingleImagePostmodal from "../../Components/SingleImagePstmodal/SingleImagePostmodal";
import Swal from "sweetalert2";
const postReactions = [
  { id: "Like", img: "../assets/img/icon/Like.svg" },
  { id: "Love", img: "../assets/img/icon/Love.svg" },
  { id: "Haha", img: "../assets/img/icon/Haha.svg" },
  { id: "Care", img: "../assets/img/icon/Care.svg" },
  { id: "Wow", img: "../assets/img/icon/Wow.svg" },
  { id: "Sad", img: "../assets/img/icon/Sad.svg" },
  { id: "Angry", img: "../assets/img/icon/Angry.svg" },
];

export default function VideoItem({ item, index, post, onSuccess }) {
  const [postComments, setPostComments] = useState([]);
  const [currentPostId, setCurrentPostId] = useState(null);
  const [rootComments, setRootComments] = useState([]);
  const [sharePost, setSharePost] = useState(null);
  const [modalState, setModalState] = useState("hide");
  const [totalPostReactions, setTotalPostReactions] = useState([]);
  const [reactionModalOpen, setReactionModalOpen] = useState("hide");

  const [timelinePosts, setTimelinePosts] = useState([]);
  const [postPage, setPostPage] = useState(1);
  const [mouserHovering, setMouserHovering] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMorePosts, setHasMorePosts] = useState(false);
  const [page, setPage] = useState(1);
  const [mediaComments, setMediaComments] = useState([]);
  const [inputValue, setInputValue] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [likeCount, setLikeCount] = useState(post.totalReactions);
  const [selectedReaction, setSelectedReaction] = useState(post.userReaction);
  const [postSaved, setPostSaved] = useState(post.isSaved);
  const [postSavedCounter, setPostSavedCounter] = useState(post.totalSaves);
  const [commentCounter, setCommentCounter] = useState(post.totalComments);
  const [shareCounter, setShareCounter] = useState(post.totalShares);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const username = localStorage.getItem("userName");
  const params = useParams();
  const history = useNavigate();

  const [reactionModalVar, setReactionModalVar] = useState(true);
  const [vControl, setVControl] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [mediaId, setMediaId] = useState(0);

  const showReactionsBox = (event) => {
    setMouserHovering(true);
  };
  const hideReactionsBox = (event) => {
    setTimeout(() => {
      setMouserHovering(false);
    }, 1400);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "y";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + "d";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + "h";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min";
    return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec" : "0 sec";
  };

  function formatDateTime(postDate) {
    let date = new Date(postDate);
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    let formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [allShow, setAllShow] = useState([]);
  const getSinglePostData = async () => {
    let id = post.id;
    try {
      let resp = await CommonAPi(Endpoints.GetSinglePost + id);
      if (resp && resp.success === true) {
        setAllShow([...resp.data.post_medias]);
        console.log("setAllShow data", allShow);
        // setAllShow(resp.data);
        // console.log("setAllShow data", allShow)
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  let selectpostArr = allShow;

  useEffect(() => {
    if (reactionModalVar) allReactions(currentPostId);
  }, [page]);

  useEffect(() => {
    setRootComments(
      postComments.filter((comment) => {
        return comment.commentId == "0";
      })
    );
  }, [postComments]);

  const getUserPosts = async (createPage = null) => {
    if (!hasMorePosts) return;
    try {
      let resp = await CommonAPi(Endpoints.GetFeedPosts + `?page=${postPage}`);
      if (resp && resp.success === true) {
        if (resp.data.length === 0) {
          setLoading(false);
          setHasMorePosts(false);
        } else {
          if (createPage == null) {
            setTimelinePosts([...timelinePosts, ...resp.data]);
          } else {
            setTimelinePosts(resp.data);
          }
        }
        console.log(timelinePosts);
        setLoading(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handlePostReact = async (e, post_id, reaction_type) => {
    if (selectedReaction == null) {
      setLikeCount(likeCount + 1);
      setSelectedReaction(reaction_type);
    } else if (selectedReaction === reaction_type) {
      setSelectedReaction(null);
      setLikeCount(likeCount - 1);
    } else {
      setSelectedReaction(reaction_type);
    }
    try {
      let data = {
        post_id: String(post_id),
        reaction_type: reaction_type,
      };
      // alert("creating..")
      let resp = await CommonAPi(Endpoints.CreatePostReaction, data);
      if (resp && resp.success === true) {
        // getUserPosts();
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  // get post reactions
  const allReactions = async (id, reactionType = "") => {
    setModalState("hide");
    if (id == null) return;
    setCurrentPostId(id);
    try {
      let resp = await CommonAPi(
        Endpoints.GetReactionsOfAPost +
          id +
          `?page=${page}&reaction_type=${reactionType}`
      );
      if (resp && resp.success === true) {
        if (resp.data.length === 0) {
          setLoading(false);
          setHasMorePosts(false);
        }
        if (reactionType) setTotalPostReactions(resp.data);
        else {
          setTotalPostReactions([...totalPostReactions, ...resp.data]);
        }
        setLoading(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleImgClick = (item) => {
    setMediaId(item?.id);
  };

  useEffect(() => {
    setSharePost("");
    setReactionModalOpen("hide");
  }, []);

  const handleDeleteVideo = async (item) => {
    Swal.fire({
      text: "Are you sure you want to delete this post ?",
      icon: "question",
      customClass: {
        confirmButton: "btn px-3 mx-2",
        cancelButton: "btn btn-br-blue px-3",
      },
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(item);
      }
    });
  };

  const handleDelete = async (item) => {
    try {
      let resp = await DeleteApi(
        Endpoints.DeleteMedia +
          "?postId=" +
          item?.postId +
          "&mediaId=" +
          item?.id +
          "&type=videos"
      );
      if (resp && resp.success === true) {
        ToastMessage.Success(resp.message);
        onSuccess(true);
        setModalState("hide");
        setReactionModalOpen("hide");
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <>
      <div class="col-lg-3 col-md-4 col-sm-6 mb-3" key={index}>
        <div class="rounded-15 overflow-hidden profile-videos position-relative">
          <Link>
            <div
              style={{
                minWith: "500px",
                minHeight: "300",
                maxWidth: "500px",
                maxHeight: "300px",
              }}
            >
              <video class="d-block">
                <source
                  src={Endpoints.baseUrl + item.filePath}
                  type="video/mp4"
                />
              </video>
            </div>

            <div class="mainmenu-item-action position-absolute end-1 top-0 m-2 p-1">
              <span class="text-white">
                <img src="../assets/img/icon/icon-views.svg" alt="" />{" "}
                {item?.views}
              </span>
            </div>

            <div class="profile-top d-flex align-items-center justify-content-center pt-5">
              <div class="d-flex align-items-center text-white medium w-100 justify-content-center gap-4">
                <span class="text-white d-flex align-items-center  ">
                  <img src="../assets/img/icon/icon-love.svg" alt="" />
                  <h3 class="medium">{item?.totalReactions}</h3>
                </span>

                <span class="text-white d-flex align-items-center ">
                  <img src="../assets/img/icon/icon-comment2.svg" alt="" />
                  <h3 class="medium">{item?.totalComments}</h3>
                </span>
              </div>
            </div>
          </Link>
          {username == params?.slug ? (
            <div class="mainmenu-item-action position-absolute end-0 top-0 m-2 p-1">
              <a
                href="#"
                id="teacher_1"
                data-bs-toggle="dropdown"
                class="d-flex align-items-center justify-content-center rounded-circle bg-eee p-1"
                aria-expanded="false"
              >
                <svg
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  stroke="#000"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  class="css-i6dzq1"
                >
                  <circle cx="12" cy="12" r="1"></circle>
                  <circle cx="19" cy="12" r="1"></circle>
                  <circle cx="5" cy="12" r="1"></circle>
                </svg>
              </a>
              <ul
                class="popup-menu dropdown-menu shadow rounded-1 text-start"
                aria-labelledby="teacher_1"
              >
                <li>
                  <a
                    class="dropdown-item py-2 d-flex gap-2"
                    href="#"
                    onClick={() => {
                      openModal();
                      setIsModalOpen(true);
                      handleImgClick(item);
                      setIsLoading(true);
                      setVControl(false);
                    }}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      width="15"
                      height="15"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      class="css-i6dzq1"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                    Edit
                  </a>
                </li>
                <li>
                  <Link
                    class="dropdown-item py-2 d-flex gap-2"
                    onClick={() => {
                      handleDeleteVideo(item);
                    }}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      width="15"
                      height="15"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      class="css-i6dzq1"
                    >
                      <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                      <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                    </svg>
                    Delete
                  </Link>
                </li>
              </ul>
            </div>
          ) : (
            ""
          )}

          <Link
            onClick={() => {
              openModal();
              setIsModalOpen(true);
              handleImgClick(item);
              setIsLoading(true);
              setVControl(false);
            }}
            className="play-video d-flex position-absolute translate-middle top-50 start-50 align-items-center justify-content-center top-0"
          >
            <img
              src="../assets/img/icon/video-play.svg"
              width="30"
              alt="alt"
              height="30"
            />
          </Link>
        </div>

        <PostReactions
          handleReact={handlePostReact}
          postReactions={postReactions}
          id={post.id}
          isOpen={mouserHovering}
        />
        {isModalOpen && (
          <div>
            <SingleImagePostmodal
              closeModal={closeModal}
              creator={post.postCreator}
              title={post.title}
              postImg={post.profileImg}
              selectpostArr={selectpostArr}
              totalShares={post.totalShares}
              timeSince={timeSince}
              formatDateTime={formatDateTime}
              mouserHovering={mouserHovering}
              showReactionsBox={showReactionsBox}
              hideReactionsBox={hideReactionsBox}
              setMediaComments={setMediaComments}
              mediaComments={mediaComments}
              postCreatedAt={post.createdAt}
              post={post}
              postReactions={postReactions}
              getSinglePostData={getSinglePostData}
              getUserPosts={getUserPosts}
              setInputValue={setInputValue}
              inputValue={inputValue}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              setLikeCount={setLikeCount}
              setSelectedReaction={setSelectedReaction}
              setMainPostSaved={setPostSaved}
              setMainPostSavedCounter={setPostSavedCounter}
              setCommentCounter={setCommentCounter}
              setMainShareCounter={setShareCounter}
              selectedReaction={selectedReaction}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              setIsModalOpen={setIsModalOpen}
              setVControl={setVControl}
              clickType={"Profile"}
              mediaId={mediaId}
              onSuccess={true}
            />
          </div>
        )}
      </div>
    </>
  );
}
