import React from 'react'
import { Endpoints } from '../../API/Endpoints'

import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate } from 'react-router-dom';
import { CommonAPi } from '../../API/CommonApi';
export default function PeopleItem({ item, index, onSuccess }) {
    const navigate = useNavigate();
    const sendFriendRequest = async (Id) => {
        let data = {
            receiverId: Id
        }
        try {
            let resp = await CommonAPi(Endpoints.SendFriendRequest, data);
            if (resp && resp.message) {
                onSuccess(true);
                ToastMessage.Success(resp.message)
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }

    };
    return (
        <div class="col-lg-4 col-md-4 col-sm-6 mb-4" key={index}>
            <div class="mainmenu-page-item"><div class="text-center position-relative">
                {item.user_profile?.coverImg === null ?
                    <img src="../assets/img/university-banner.png" class="mainmenu-page-banner" alt="" />
                    :
                    <img src={Endpoints.baseUrl + item.user_profile?.coverImg} class="mainmenu-page-banner" alt="" />
                }
                <span class="suggested-user">
                <Link to={"/timeline/" + item.username}>
                    {item.user_profile?.profileImg === null ?
                        <img src="../assets/img/university.png" alt="" width="96" height="96" class="rounded-circle" />
                        :
                        <img src={Endpoints.baseUrl + item.user_profile?.profileImg} alt="" width="96" height="96" class="rounded-circle" />
                    }
                </Link>
                </span>
                <Link to={"/timeline/" + item.username}>
                    <h5 class="semibold pt-3 pb-2">{item.fullName}</h5>
                </Link>

                <h6 class="fs-14 text-darkgray medium pb-1">{item.user_profile?.profileTagline}</h6>
            </div>

                <div class="text-center py-3 px-2">
                    {item.isFriend === "NA" ?
                        <Link onClick={() => {
                            sendFriendRequest(item.id)
                        }} class="btn btn-br-blue w-100 medium">Connect<img src="../assets/img/icon/follow.svg" class="ps-2" /></Link>
                        : item.isFriend === null ? "" :
                            item.isFriend === "ACCEPTED" ? 
                            <Link class="btn btn-br-blue w-100 medium">Friend</Link>
                        : 
                            <Link class="btn btn-br-blue w-100 medium">{item.isFriend}</Link>
                        
                    }
                </div>
            </div>
        </div>
    )
}
