import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { CommonAPi } from "../../API/CommonApi";
import ToastMessage from "../../Utils/ToastMessage";
import { ValidateList, ValidationTypes } from "../../Utils/ValidationHelper";
import { Endpoints } from "../../API/Endpoints";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Helmet } from "react-helmet";
import { FetchApi } from "../../API/FetchApi";

const BecomeInstructor = () => {
  const user = useMemo(() => JSON.parse(localStorage.getItem("user")), []);
  const [teachingType, setTeachingType] = useState();
  const [videoCreationKnowledge, setvideoCreationKnowledge] = useState();
  const [audience, setAudience] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetProfile + "?id=" + user?.id);
      if (resp && resp.success === true) {
        console.log("#@22", resp);
        localStorage.setItem("user", JSON.stringify(resp?.data));
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleSubmit = async () => {
    let validationArr = [
      [
        teachingType,
        ValidationTypes.Empty,
        "Please select what kind of teaching have you done before",
      ],
      [
        videoCreationKnowledge,
        ValidationTypes.Empty,
        "Please select how much video creation knowledge you have",
      ],
      [
        audience,
        ValidationTypes.Empty,
        "Please select do you have an audience to share your course with",
      ],
    ];
    let validate = await ValidateList(validationArr);
    if (!validate) {
      return;
    }

    let data = {
      teaching_type: teachingType,
      video_creation_knowledge: videoCreationKnowledge,
      audience: audience,
    };
    try {
      let resp = await CommonAPi(Endpoints.BecomeInstructor, data);
      if (resp && resp.message) {
        document.getElementById("becomeInstructorform").reset();
        await getProfile();
        ToastMessage.Success(resp.message);
        navigate("/mainfeed");
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (user?.instructorStatus === "Pending") {
        document.getElementById("become-instr").style.left = "0px";
      }
    }, 1000);
  }, []);

  return (
    <Container header>
      <Helmet>
        <title>Become Instructor</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />

          <div className="container py-lg-4 py-3">
            {user?.instructorStatus === "Pending" ? (
              <div
                style={{ minHeight: "20vh" }}
                className="d-flex justify-content-center align-items-center bg-white"
              >
                Your instructor form is in process!!
              </div>
            ) : user?.instructorStatus === "Complete" ? (
              <div
                style={{ minHeight: "20vh" }}
                className="d-flex justify-content-center align-items-center bg-white"
              >
                You are already an Instructor!!
              </div>
            ) : (
              <form id="becomeInstructorform">
                <div className="bg-white rounded-10 box-shadow p-3 mb-3">
                  <h2>Become Instructor</h2>

                  <div className="" id="app-title-commission">
                    <h6 className="fs-15 fs-sm-10 d-flex align-items-center gap-1">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="#fff"
                        strokeWidth="2"
                        fill="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="css-i6dzq1"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="16" x2="12" y2="12"></line>
                        <line x1="12" y1="8" x2="12.01" y2="8"></line>
                      </svg>
                      5% Commission will be charged
                    </h6>
                  </div>

                  <ReactTooltip
                    id="become-instr"
                    anchorId="app-title-commission"
                    place="bottom"
                    content="5% Commission charged on every course sale. For example, If you have listed your course with Rs. 100 then you will get 100 - {5% commission + tax (18% GST)} = Rs. 94.10 "
                  />

                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div style={{ marginTop: "20px" }}>
                        <h4>What kind of teaching have you done before</h4>
                        <div>
                          {[
                            {
                              label: "In person online/offline",
                              value: "In person",
                            },
                            { label: "Online", value: "Online" },
                            { label: "In School/University", value: "School" },
                          ].map((option) => (
                            <div key={option.value}>
                              <label className="d-flex align-items-center gap-1">
                                <input
                                  type="radio"
                                  name="teaching_type"
                                  value={option.label}
                                  onClick={() => setTeachingType(option.value)}
                                />
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div style={{ marginTop: "50px" }}>
                        <h4>How much video creation knowledge you have</h4>
                        <div>
                          {[
                            { label: "Beginner", value: "Beginner" },
                            { label: "Pro", value: "Pro" },
                            { label: "Advance", value: "Advance" },
                          ].map((option) => (
                            <div key={option.value}>
                              <label className="d-flex align-items-center gap-1">
                                <input
                                  type="radio"
                                  name="video_creation_knowledge"
                                  value={option.value}
                                  onClick={() =>
                                    setvideoCreationKnowledge(option.value)
                                  }
                                />
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div style={{ marginTop: "50px" }}>
                        <h4>
                          Do you have an audience to share your course with
                        </h4>
                        <div>
                          {[
                            { label: "No", value: "No" },
                            { label: "Yes small", value: "Small" },
                            { label: "Yes very large", value: "Large" },
                          ].map((option) => (
                            <div key={option.value}>
                              <label className="d-flex align-items-center gap-2">
                                <input
                                  type="radio"
                                  name="audience"
                                  value={option.value}
                                  onClick={() => setAudience(option.value)}
                                />
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img src="../assets/img/Group.png" alt="" />
                    </div>
                    <div className="text-center">
                      <Link
                        onClick={handleSubmit}
                        className="btn blue-btn fs-14"
                      >
                        Save
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
        <OnlineFriends />
      </div>
    </Container>
  );
};

export default BecomeInstructor;
