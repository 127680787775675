import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Base64 } from 'js-base64';
import jwtDecode from "jwt-decode";
import {Helmet} from "react-helmet";

function ForgotPassword() {
    const [identity, setIdentity] = useState('');
    const navigate = useNavigate();
    const [emailError, setEmailError] = useState('');
      const token = localStorage.getItem("token");
  useEffect(() => {
    if(token){
        const decoded = jwtDecode(token);
        if(decoded.exp < Date.now()/1000){
            localStorage.clear();
            navigate("/signin");
        }else{
            navigate("/mainfeed")
        }
    }
  }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!identity) {
            setEmailError("Please enter mobile number or email address");
        } else if (identity) {
            setEmailError("");
        }
        if (identity) {
            let obj = {
                identity: identity,
            }
            try {
                let resp = await CommonAPi(Endpoints.SendForgotPasswordOtp, obj);
                if (resp && resp.message) {
                    navigate("/verify-otp" + "/" + Base64.encode(identity));
                    ToastMessage.Success(resp.message)
                }
            }
            catch (e) {
                if (e && e.response && e.response.data && e.response.data.message) {
                    ToastMessage.Error(e.response.data.message)
                }
            }
        }
    };
    const handleValidation = async (e, value, field) => {
        e.preventDefault();
        if (!value && field === "email") {
            setEmailError("Please enter mobile number or email address");
        } else if (value && field === "email") {
            setEmailError("");
        }
    };

    return (
        <div class="wrapper">
   <Helmet>
            <title>Forgot Password</title>
        </Helmet>
            <div class="header fix-header position-fixed top-0 w-100 ">
                <div class="container ">
                    <div class="row m-0">
                        <div class="col-md-6 col-6">

                            <div class="site-logo">
                                <Link to="/">
                                    <img src="assets/img/8inNet_logo 1.svg" alt="" />
                                </Link>
                            </div>

                        </div>

                        <div class="col-md-6 col-6 text-end">
                            <div class="d-flex align-items-center justify-content-end">
                                <h6 class="font-15 text-white regular pe-3 d-none d-sm-block">Don’t have an account?</h6>
                                <Link to="/signup" class="btn btn-brown px-4">Sign Up</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="signin-bg">
                <div class="container p-0">
                    <div class="row m-0">
                        <div class="col-md-6">

                            <div class="py-5">
                                <img src="assets/img/forgot.png" alt="" />
                            </div>

                        </div>

                        <div class="col-md-6 bg-primary py-md-5 py-4 signup-right">
                            <div class="pt-md-5 px-md-5 my-md-5">
                                <h1 class="semibold text-white pb-1">Forgot Password</h1>
                                <h4 class="text-white pb-3">Please enter your mobile or email to recieve verification code</h4>

                                <form onSubmit={handleSubmit} class="signup-form">


                                    <div class="form-field">
                                        <label className="text-white">Mobile Number or Email Address*</label>
                                        <input type="text"
                                            placeholder="Mobile Number or Email Address"
                                            className={emailError ? "is-danger" : ""}
                                            onBlur={(e) => { handleValidation(e, e.target.value, "email") }}
                                            value={identity}
                                            onChange={(e) => {
                                                setIdentity(e.target.value)
                                            }}
                                        />

                                        {emailError && (
                                            <div className="form-error">
                                                {emailError}
                                            </div>
                                        )}

                                    </div>
                                    <button type="submit" class="btn btn-brown rounded-pill w-100 my-3">Continue</button>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ForgotPassword;