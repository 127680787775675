import React from 'react';
import { Link } from 'react-router-dom';
import { Endpoints } from '../../../API/Endpoints';


export default function ApplicationModel({singleJobsData}) {
 
  
  return (
    <div class="modal" id="applyjobs" tabindex="-1">
            <div class="modal-dialog modal-lg px-lg-5 modal-dialog-centered modal-dialog-scrollable modal-md">
                <div class="modal-content">
                    <button type="button" class="btn-close ms-auto p-3 position-absolute end-0 " data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="text-center py-4 border-bottom">
                        <h5 class="modal-title text-center fs-20 medium text-black">Candidate Details!</h5>

                    </div>
                    <div class="modal-body">

                        <form action="" class="site-form">
                        
                            <div class="form-field mb-3">
                                <label for="" class="pb-1 fs-14 medium">Full Name</label>
                                <div>
                                {singleJobsData?.username}
                                </div>
                            </div>
                            <div class="form-field mb-3">
                                <label for="" class="pb-1 fs-14 medium">Email Id </label>
                                    <div>
                                    {singleJobsData?.email}
                                    </div>
                            </div>
                            <div class="form-field mb-3">
                                <label for="" class="pb-1 fs-14 medium">Mobile No.</label>
                                    <div>
                                    {singleJobsData?.mobileNumber}
                                    </div>
                            </div>
                            <div class="form-field mb-3">
                                <label for="" class="pb-1 fs-14 medium">Uploaded Resume</label>
                                <div>
                                <Link to={Endpoints.baseUrl + singleJobsData?.resume} target={"_blank"}>
                                    {singleJobsData?.resume?.split("/")[1]}
                                </Link>
                                </div>
                              
                            </div>
                            <div class="form-field mb-3">
                                <label for="" class="pb-1 fs-14 medium">Message</label>
                                <div>
                                {singleJobsData?.message}
                                </div>
                              
                                
                            </div>
                            <div class="form-field mb-3">
                                <label for="" class="pb-1 fs-14 medium">Status</label>
                                <div>
                                {singleJobsData?.status}
                                </div>
                              
                                
                            </div>
                            
                           

                        </form>
                    </div>

                </div>
            </div>
        </div>
  )
}
