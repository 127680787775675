import React, { useEffect, useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import TeacherItem from "../Courses/TeacherItem";
import PostLoader from "../../Components/PostLoader/PostLoader";
import { Helmet } from "react-helmet";
function TeachersMenu() {
  const recommended = JSON.parse(
    new URLSearchParams(useLocation().search).get("recommended")
  );
  const most_rated = JSON.parse(
    new URLSearchParams(useLocation().search).get("most_rated")
  );

  // const slug = JSON.parse(new URLSearchParams(useLocation().search).get());
  const [topInstructor, setTopInstructor] = useState([]);
  const [loadStatus, setLoadStatus] = useState(false);
  const [limit, setLimit] = useState(8);
  const [activeTab, setActiveTab] = useState("Teacher");
  const [status, setStatus] = useState(false);
  const [count, setCount] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    getTopInstructor();
  }, []);

  const getTopInstructor = async () => {
    setStatus(true);
    if (most_rated === true) {
      setActiveTab("mostrated");
      try {
        let resp = await FetchApi(
          Endpoints.topInstructor + "?size=" + limit + "&most_rated=true"
        );
        console.log(resp);
        if (resp) {
          setStatus(false);
          setTopInstructor(resp.data);
          setCount(resp.counts);
        }
      } catch (e) {
        setStatus(false);
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else if (recommended === true) {
      setActiveTab("Recommended");
      try {
        let resp = await FetchApi(
          Endpoints.topInstructor + "?size=" + limit + "&recommended=true"
        );
        console.log("65", resp);
        if (resp && resp.success === true) {
          setStatus(false);
          setTopInstructor(resp.data);
          setCount(resp.counts);
        }
        if (resp.data?.length === 0) {
          setStatus(false);
        }
      } catch (e) {
        setStatus(false);
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      setActiveTab("Teacher");

      try {
        let resp = await FetchApi(Endpoints.topInstructor + "?size=" + limit);
        if (resp && resp.success === true) {
          setStatus(false);
          setTopInstructor(resp.data);
          setCount(resp.counts);
        }
      } catch (e) {
        setStatus(false);
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleSearch = async (SearchText) => {
    setSearchKey(SearchText);
    try {
      let url =
        Endpoints.topInstructor + "?size=" + limit + "&keyword=" + SearchText;
      if (activeTab === "Recommended") {
        url += "&recommended=true";
      } else if (activeTab === "mostrated") {
        url += "&most_rated=true";
      }
      let resp = await FetchApi(url);
      if (resp && resp.data) {
        console.log(resp.data);
        setTopInstructor(resp?.data);
        setCount(resp?.counts);
      }
    } catch (e) {
      const errorMessage = e?.response?.data?.message || "An error occurred!";
      const errorStatus = e?.response?.status;

      if (errorStatus === 401) {
        ToastMessage.Error("Session Expired! Please login again.");
        localStorage.clear();
        navigate("/signin");
      } else {
        ToastMessage.Error(errorMessage);
      }
    }
  };

  const handleSorting = async (sorting) => {
    setStatus(true);
    try {
      if (activeTab === "Teacher") {
        let resp = await FetchApi(
          Endpoints.topInstructor + "?size=" + limit + "&sort=" + sorting
        );
        if (resp && resp.success === true) {
          setStatus(false);
          setTopInstructor(resp.data);
          setCount(resp.counts);
        }
      } else if (activeTab === "Recommended") {
        let resp = await FetchApi(
          Endpoints.topInstructor +
            "?size=" +
            limit +
            "&sort=" +
            sorting +
            "&recommended=true"
        );
        if (resp && resp.success === true) {
          setStatus(false);
          setTopInstructor(resp.data);
          setCount(resp.counts);
        }
      } else if (activeTab === "mostrated") {
        let resp = await FetchApi(
          Endpoints.topInstructor +
            "?size=" +
            limit +
            "&sort=" +
            sorting +
            "&most_rated=true"
        );
        if (resp && resp.success === true) {
          setStatus(false);
          setTopInstructor(resp.data);
          setCount(resp.counts);
        }
      }
    } catch (e) {
      setStatus(false);
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleLoadMore = async () => {
    let newLimit = limit + 8;
    setLimit(newLimit);
    setLoadStatus(true);

    try {
      if (activeTab === "Teacher") {
        let resp = await FetchApi(
          Endpoints.topInstructor + "?size=" + newLimit
        );
        if (resp && resp.success === true) {
          setTopInstructor(resp.data);
          setCount(resp.counts);
          setLoadStatus(false);
        }
      } else if (activeTab === "Recommended") {
        let resp = await FetchApi(
          Endpoints.topInstructor + "?size=" + newLimit + "&recommended=true"
        );
        if (resp && resp.success === true) {
          setTopInstructor(resp.data);
          setCount(resp.counts);
          setLoadStatus(false);
        }
      } else if (activeTab === "mostrated") {
        let resp = await FetchApi(
          Endpoints.topInstructor + "?size=" + newLimit + "&most_rated=true"
        );
        if (resp && resp.success === true) {
          setTopInstructor(resp.data);
          setCount(resp.counts);
          setLoadStatus(false);
        }
      }
    } catch (e) {
      setLoadStatus(false);
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleAllTeacher = async () => {
    setActiveTab("Teacher");
    setStatus(true);
    setSearchKey("");
    navigate("/teachers");
    try {
      let resp = await FetchApi(Endpoints.topInstructor + "?size=" + limit);
      if (resp && resp.success === true) {
        setStatus(false);
        setTopInstructor(resp.data);
        setCount(resp.counts);
      }
    } catch (e) {
      setStatus(false);
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleGetRecommendedTeacher = async () => {
    setActiveTab("Recommended");
    setStatus(true);
    setSearchKey("");
    navigate("/teachers?recommended=true");
    try {
      let resp = await FetchApi(
        Endpoints.topInstructor + "?size=" + limit + "&recommended=true"
      );
      console.log("290", resp);
      if (resp) {
        setStatus(false);
        setTopInstructor(resp.data);
        setCount(resp.counts);
      }
    } catch (e) {
      setStatus(false);
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleGetTopReviewedTeacher = async () => {
    setActiveTab("mostrated");
    setStatus(true);
    setSearchKey("");

    navigate("/teachers?most_rated=true");
    try {
      let resp = await FetchApi(
        Endpoints.topInstructor + "?size=" + limit + "&most_rated=true"
      );
      if (resp) {
        setStatus(false);
        setTopInstructor(resp.data);
        setCount(resp.counts);
      }
    } catch (e) {
      setStatus(false);
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Teachers</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />

          <div className="container py-3">
            <div className="bg-white main-common">
              <div className="main-top-row d-md-flex justify-content-between align-items-center pb-3">
                <h3 className="medium">Teachers</h3>

                <div className="d-sm-flex gap-3 pb-3">
                  <form
                    action=""
                    className="mainmenu-page-search d-flex flex-row-reverse align-items-center"
                  >
                    <input
                      type="text"
                      placeholder="Search for teachers"
                      name="search"
                      value={searchKey}
                      className="bg-transparent fs-14 medium  text-darkgray"
                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                    />
                    <button type="submit" className="bg-transparent border-0">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="#949494"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="css-i6dzq1"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </button>
                  </form>
                  <select
                    name="sortby"
                    id="sortby"
                    onChange={(e) => {
                      handleSorting(e.target.value);
                    }}
                    className="text-darkgray"
                  >
                    <option value="">Sort by</option>
                    <option value="latest">Recently</option>
                    <option value="a-z">Ascending</option>
                    <option value="z-a">Descending</option>
                    <option value="old">Last</option>
                  </select>
                </div>
              </div>

              <div className="mainmenu-filter-row pb-3">
                <ul className="filter d-flex flex-nowrap align-items-center gap-2 fs-14 medium">
                  <li
                    className={activeTab === "Teacher" ? "active " : ""}
                    onClick={() => {
                      handleAllTeacher();
                    }}
                    data-filter="all"
                  >
                    All Teachers
                    <span className="fs-10 text-brown">
                      {count?.allTeacherCount ? count?.allTeacherCount : 0}
                    </span>
                  </li>
                  <li
                    data-filter="mostrated"
                    onClick={() => {
                      handleGetTopReviewedTeacher();
                    }}
                    className={activeTab === "mostrated" ? "active " : ""}
                  >
                    Most Rated Teachers
                    <span className="fs-10 text-brown">
                      {count?.ratedTeacherCount ? count?.ratedTeacherCount : 0}
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      handleGetRecommendedTeacher();
                    }}
                    className={activeTab === "Recommended" ? "active " : ""}
                  >
                    Recommended For You
                    <span className="fs-10 text-brown">
                      {count?.recommendedTeacherCount
                        ? count?.recommendedTeacherCount
                        : 0}
                    </span>
                  </li>
                </ul>
              </div>

              <div className="row mainmenu-filter-conatiner">
                {status === false ? (
                  <>
                    {topInstructor?.length === 0 ? (
                      <>No instructor's found</>
                    ) : (
                      topInstructor?.map((item, index) => {
                        return (
                          <TeacherItem
                            key={index}
                            {...{
                              item: item,
                              index: index,
                            }}
                          />
                        );
                      })
                    )}
                  </>
                ) : (
                  <PostLoader />
                )}
              </div>

              {topInstructor.length >= 8 ? (
                <div className="text-center">
                  <Link
                    href="#"
                    className="text-blue"
                    onClick={() => handleLoadMore()}
                  >
                    {loadStatus ? (
                      <img src="../assets/img/icon/icon-loading.svg" alt="" />
                    ) : (
                      "Load More Teacher "
                    )}
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}

export default TeachersMenu;
