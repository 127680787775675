import React, { useCallback, useEffect, useState } from 'react';
import { BloodGroup } from "../../constants/BloodGroup";
import { CommonAPi } from '../../API/CommonApi';
import { FetchApi } from '../../API/FetchApi';
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate } from "react-router-dom";
import TopHeader from '../../Components/TopHeader';

import Welcome from './Welcome';
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { Helmet } from "react-helmet";
const $ = window.jQuery;

export default function CompleteProfile() {
    const userId = localStorage.getItem("userId");
    const userRoleType = localStorage.getItem("userRoleType");
    const Navigate = useNavigate();
    const [gender, setGender] = useState('');
    const [nickname, setNickName] = useState('');
    const [birthday, setBirthday] = useState('');
    const [birthplace, setBirthPlace] = useState('');
    const [livesin, setLivesin] = useState('');
    const [country, setCountry] = useState('');
    const [bloodGroup, setBloodGroup] = useState('');
    const [about, setAbout] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [currentDate, setCurrentDate] = useState('');
    const [profileTagline, setProfileTagline] = useState('');

    const [countryName, setCountryName] = useState('');
    const [countryKey, setCountryKey] = useState('');

    const [genderError, setGenderError] = useState('');
    const [nicknameError, setNickNameError] = useState('');
    const [birthdayError, setBirthdayError] = useState('');
    const [birthplaceError, setBirthPlaceError] = useState('');
    const [livesinError, setLivesinError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [bloodGroupError, setBloodGroupError] = useState('');
    const [aboutError, setAboutError] = useState('');
    const [profileTaglineError, setProfileTaglineError] = useState('');
    const [years, setYears] = useState('');
    const [months, setMonths] = useState('');

    function* range(start, end) {
        for (let i = start; i <= end; i++) {
            yield i;
        }
    }


    useEffect(() => {
        // var percent = 40;
        if (userRoleType === 'child') {
            var percent = 66;
        } else {
            var percent = 40;

        }
        percent = percent.toFixed();
        $(".progress-bar")
            .css("width", percent + "%")
        setYears([...range(1920, new Date().getFullYear() + 1)]);
        setMonths([
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ]);

    }, []);



    useEffect(
        function () {
            let mounted = true;
            getCountry();
            getProfile();
            futureDateDisabled();
            return () => {
                mounted = false;
            };
        },
        []
    );

    const getProfile = async (e) => {
        try {
            let resp = await FetchApi(Endpoints.GetProfile + "?id=" + userId);
            if (resp && resp.success === true) {
                setProfileTagline(resp.data.user_profile.profileTagline);
                setAbout(resp.data.user_profile.aboutMe);
                setBloodGroup(resp.data.user_profile.bloodGroup);
                setCountry(resp.data.user_profile.countryId);
                setCountryKey(Math.ceil(Math.random() * 999999));
                setCountryName(resp?.data?.user_profile?.country);
                setLivesin(resp.data.user_profile.livesIn);
                setBirthPlace(resp.data.user_profile.birthPlace);
                setBirthday(new Date(resp.data.user_profile.birthday));
                setNickName(resp.data.user_profile.nickname);
                setGender(resp.data.user_profile.gender);
            }
        }
        catch (e) {
            console.log("loginwpErr__", JSON.stringify(e.response, null, 4));
            if (e && e.response && e.response.data && e.response.data.message) {
                ToastMessage.Error(e.response.data.message)
            }
        }
    };


    const futureDateDisabled = () => {
        const date = new Date();
        let day = '';
        let days = date.getDate();
        if (days > 9) {
            day = days;
        } else {
            day = '0' + days;
        }
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        setCurrentDate(`${year}-${month}-${day}`);
    };

    const getCountry = async (e) => {
        try {
            let resp = await FetchApi(Endpoints.GetCountry);
            if (resp && resp.success === true) {
                // setCountryData(resp.data);
                const newData = resp.data.map((item, index) => {
                    return ({
                        id: item.id,
                        name: item.countryName,
                    });
                })
                setCountryData(newData);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };


    const handleBasicProfile = async (event) => {
        event.preventDefault();

        if (!nickname) {
            setNickNameError("Please enter nickname ");
        } else if (nickname) {
            setNickNameError("");
        }
        if (!profileTagline) {
            setProfileTaglineError("Please enter profile tagline ");
        } else if (profileTagline) {
            setProfileTaglineError("");
        }
        if (!birthplace) {
            setBirthPlaceError("Please enter birth place");
        } else if (birthplace) {
            setBirthPlaceError("");
        }
        if (!birthday) {
            setBirthdayError("Please enter birthday");
        } else if (birthday) {
            setBirthdayError("");
        }
        if (!gender) {
            setGenderError("Please select gender");
        } else if (gender) {
            setGenderError("");
        }
        if (!livesin) {
            setLivesinError("Please enter lives in");
        } else if (livesin) {
            setLivesinError("");
        }
        // if (!bloodGroup) {
        //     setBloodGroupError("Please select blood group");
        // } else if (bloodGroup) {
        //     setBloodGroupError("");
        // }
        if (!country) {
            setCountryError("Please select country");
        } else if (country) {
            setCountryError("");
        }
        if (!about) {
            setAboutError("Please enter about");
        } else if (about) {
            setAboutError("");
        }
        if (about && country && livesin && gender && profileTagline && birthplace && nickname) {
            let data;
            if (bloodGroup) {
                data = {
                    about_me: about,
                    birthday: birthday,
                    nickname: nickname,
                    profile_tagline: profileTagline,
                    birth_place: birthplace,
                    lives_in: livesin,
                    country: country,
                    gender: gender,
                    blood_group: bloodGroup
                };
            } else {
                data = {
                    about_me: about,
                    birthday: birthday,
                    nickname: nickname,
                    profile_tagline: profileTagline,
                    birth_place: birthplace,
                    lives_in: livesin,
                    country: country,
                    gender: gender,
                };
            }

            try {
                let resp = await CommonAPi(Endpoints.CreateUpdateProfile, data);
                if (resp && resp.message) {
                    // ToastMessage.Success(resp.message)
                    window.localStorage.setItem('ProfileCompletion', "25.00");
                    Navigate("/complete-profile-step2" + '/' + userId)
                }
            }
            catch (e) {

                if (e && e.response && e.response.data && e.response.data.message) {
                    ToastMessage.Error(e.response.data.message)
                }
            }
        }
    };

    const handleValidation = (e, value, field) => {
        e.preventDefault();
        if (!value && field === "nickname") {
            setNickNameError("Please enter nickname ");
        } else if (value && field === "nickname") {
            setNickNameError("");
        }
        if (!value && field === "profileTagline") {
            setProfileTaglineError("Please enter profile tagline ");
        } else if (value && field === "profileTagline") {
            setProfileTaglineError("");
        }
        if (!value && field === "birthPlace") {
            setBirthPlaceError("Please enter birth place");
        } else if (value && field === "birthPlace") {
            setBirthPlaceError("");
        }
        if (!value && field === "birthday") {
            setBirthdayError("Please enter birthday");
        } else if (value && field === "birthday") {
            setBirthdayError("");
        }
        if (!value && field === "gender") {
            setGenderError("Please select gender");
        } else if (value && field === "gender") {
            setGenderError("");
        }
        if (!value && field === "livesin") {
            setLivesinError("Please enter lives in");
        } else if (value && field === "livesin") {
            setLivesinError("");
        }
        // if (!value && field === "bloodGroup") {
        //     setBloodGroupError("Please select blood group");
        // } else if (value && field === "bloodGroup") {
        //     setBloodGroupError("");
        // }
        if (!value && field === "country") {
            setCountryError("Please select country");
        } else if (value && field === "country") {
            setCountryError("");
        }
        if (!value && field === "about") {
            setAboutError("Please enter about");
        } else if (value && field === "about") {
            setAboutError("");
        }
    };

    const handleOnSearch = (string) => {
        console.log("string", string);

    };

    const handleOnSelect = (item) => {

        if (item.length > 0) {
            setCountry(item[0].id)
        }
    };

    const RenderCountryName = useCallback(() => {
        return (
            <AsyncTypeahead
                id="async-pagination-example1"
                defaultInputValue={countryName ? countryName : ''}
                key={countryKey}
                onSearch={handleOnSearch}
                onChange={handleOnSelect}
                onBlur={(e) => {
                    handleValidation(e, e.target.value, "country")
                }}
                options={countryData ? countryData : ''}
                placeholder="Search by Country name"
                labelKey="name"
                minLength={1}
                emptyLabel={"Searching..."}
                className={countryError ?
                    "is-danger text-gray border"
                    :
                    " text-gray border"
                }
                renderMenuItemChildren={(option, props) => (
                    <div key={option.id}>
                        <Highlighter search={props.text}>
                            {option[props.labelKey]}
                        </Highlighter>
                    </div>

                )}

                useCache={false}
            />
        )
    }, [countryName, countryKey, countryData, countryError])

    return (
        <>
            <Helmet>
                <title>Basic Details</title>
            </Helmet>
            <TopHeader />

            <div className="container-fluid bg-f5f5f5">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8">
                        <div className="card  bg-f5f5f5">
                            <Welcome />

                            <div id="msform">

                                <ul id="progressbar" style={{ '--value': userRoleType === 'child' ? '33%' : '20%' }}>
                                    <li className="active" id="personal"><strong>Interests</strong></li>
                                    <li className="active" id="personal"><strong>Basic Details</strong></li>
                                    <li id="payment"><strong>Image Upload</strong></li>
                                    {userRoleType === 'child' ? "" :
                                        <li id="account"><strong>Verification</strong></li>
                                    }
                                    {userRoleType === 'child' ? "" : userRoleType === 'student' ? (
                                        <li id="confirm"><strong>Academics</strong></li>
                                    ) :
                                        <li id="confirm"><strong>Experience</strong></li>
                                    }
                                </ul>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100">
                                        {userRoleType === 'child' ? '66 % ' : '40 %'}
                                    </div>
                                </div>
                                <br />

                                <fieldset>
                                    <form onSubmit={handleBasicProfile} className="signup-form">
                                        <div>
                                            <div className="row">
                                                <div className="col-7">
                                                    <h2 className="fs-title">Basic Details:</h2>
                                                </div>
                                                <div className="col-5">
                                                    {userRoleType === 'child' ?
                                                        <h2 className="steps">Step 2 - 3</h2>
                                                        :
                                                        <h2 className="steps">Step 2 - 5</h2>
                                                    }
                                                    {/* <h2 className="steps">Step 2 - 5</h2> */}
                                                </div>
                                            </div>
                                            <div className="form-field">
                                                <label>Nickname: *</label>
                                                <input type="text"
                                                    name="nickname"
                                                    value={nickname}
                                                    onBlur={(e) => { handleValidation(e, e.target.value, "nickname") }}
                                                    onChange={(e) => { setNickName(e.target.value) }}
                                                    placeholder="Nickname"
                                                    className={nicknameError ? "is-danger" : "border"}
                                                />
                                                {nicknameError && (
                                                    <div className="form-error">
                                                        {nicknameError}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-field">
                                                <div className="justify-content-end">
                                                    <label htmlFor="">Profile Tagline: *</label>
                                                    <span className='px-1 py-1'
                                                        id="app-title">
                                                        <i class="fa fa-info-circle"></i>
                                                    </span>
                                                    <ReactTooltip
                                                        anchorId="app-title"
                                                        place="right"
                                                        content="Tagline appears with your name on posts you create"
                                                    />
                                                </div>

                                                <input type="text"
                                                    name="profileTagline"
                                                    value={profileTagline}
                                                    onChange={(e) => { setProfileTagline(e.target.value) }}
                                                    placeholder="Profile Tagline"
                                                    onBlur={(e) => { handleValidation(e, e.target.value, "profileTagline") }}
                                                    className={profileTaglineError ? "is-danger" : "border"}
                                                />
                                                {profileTaglineError && (
                                                    <div className="form-error">
                                                        {profileTaglineError}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="row">
                                                <div className="col-12 col-md-4">
                                                    <div className="form-field date-birth-field">
                                                        <label>Date of Birth: *</label>

                                                        {/* <DatePicker
                                                            selected={birthday}
                                                            onChange={(date) => setBirthday(date)}
                                                            className={birthdayError ? "is-danger" : "border"}
                                                        /> */}
                                                        <DatePicker
                                                            renderCustomHeader={({
                                                                date,
                                                                changeYear,
                                                                changeMonth,
                                                                decreaseMonth,
                                                                increaseMonth,
                                                                prevMonthButtonDisabled,
                                                                nextMonthButtonDisabled,
                                                            }) => (
                                                                <div
                                                                    style={{
                                                                        margin: 5,
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    <a className='btn btn-outline-primary btn-sm rounded' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                                        {"<"}
                                                                    </a>
                                                                    <select
                                                                        value={new Date(date).getFullYear()}
                                                                        onChange={({ target: { value } }) => changeYear(value)}
                                                                    >
                                                                        {years.map((option) => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>

                                                                    <select
                                                                        value={months[new Date(date).getMonth()]}
                                                                        onChange={({ target: { value } }) =>
                                                                            changeMonth(months.indexOf(value))
                                                                        }
                                                                    >
                                                                        {months.map((option) => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </select>

                                                                    <a className='btn btn-outline-primary btn-sm rounded' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                                        {">"}
                                                                    </a>
                                                                </div>
                                                            )}
                                                            selected={birthday}
                                                            onChange={(date) => setBirthday(date)}
                                                            className={birthdayError ? "is-danger" : "border"}
                                                        />

                                                        {birthdayError && (
                                                            <div className="form-error">
                                                                {birthdayError}
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <div className="form-field">
                                                        <label>Birth Place: *</label>
                                                        <input type="text"
                                                            name="birth_place"
                                                            placeholder="Birth Place"
                                                            value={birthplace}
                                                            onChange={(e) => { setBirthPlace(e.target.value) }}
                                                            onBlur={(e) => { handleValidation(e, e.target.value, "birthPlace") }}
                                                            className={birthplaceError ? "is-danger" : "border"}
                                                        />

                                                        {birthplaceError && (
                                                            <div className="form-error">
                                                                {birthplaceError}
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <div className="form-field">
                                                        <label>Lives In: *</label>
                                                        <input type="text"
                                                            name="lives_in"
                                                            placeholder="Enter your City"
                                                            value={livesin}
                                                            onChange={(e) => { setLivesin(e.target.value) }}
                                                            onBlur={(e) => { handleValidation(e, e.target.value, "livesin") }}
                                                            className={livesinError ? "is-danger" : "border"}
                                                        />
                                                        {livesinError && (
                                                            <div className="form-error">
                                                                {livesinError}
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>






                                            <div className="row">
                                                <div className="col-12 col-md-4">
                                                    <div className="form-field">
                                                        <label>Gender: *</label>
                                                        <select
                                                            onBlur={(e) => { handleValidation(e, e.target.value, "gender") }}
                                                            className={genderError ? "is-danger" : "border"}
                                                            onChange={(e) => { setGender(e.target.value) }}
                                                        >
                                                            <option value="">Select Gender</option>
                                                            <option selected={gender === "Male" ? true : false} value={'Male'}>Male</option>
                                                            <option selected={gender === "Female" ? true : false} value={'Female'}>Female</option>
                                                            <option selected={gender === "Other" ? true : false} value={'Other'}>Other</option>

                                                        </select>
                                                        {genderError && (
                                                            <div className="form-error">
                                                                {genderError}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <div className="form-field">
                                                        <label>Blood Group:</label>
                                                        <select
                                                            onBlur={(e) => { handleValidation(e, e.target.value, "bloodGroup") }}
                                                            className={bloodGroupError ? "is-danger" : "border"}
                                                            onChange={(e) => { setBloodGroup(e.target.value) }}
                                                        >
                                                            <option value="">Select Blood Group</option>
                                                            {BloodGroup.map((item) => {
                                                                return (
                                                                    <option selected={bloodGroup === item.value ? true : false} value={item.value}>{item.label}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        {bloodGroupError && (
                                                            <div className="form-error">
                                                                {bloodGroupError}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <div className="form-field">
                                                        <label>Country: *</label>
                                                        {RenderCountryName()}
                                                        {/* <select
                                                            onBlur={(e) => { handleValidation(e, e.target.value, "country") }}
                                                            className={countryError ? "is-danger" : "border"}
                                                            onChange={(e) => { setCountry(e.target.value) }}
                                                        >
                                                            <option value="">Select Country</option>
                                                            {countryData.map((item, index) => {
                                                                return (
                                                                    <option key={index} selected={Math.round(country) === Math.round(item.id) ? true : false} value={item.id}>{item.countryName}</option>
                                                                )
                                                            })}
                                                        </select> */}

                                                        {countryError && (
                                                            <div className="form-error">
                                                                {countryError}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-field">
                                                <label>About Me: *</label>
                                                <textarea
                                                    value={about}
                                                    onBlur={(e) => { handleValidation(e, e.target.value, "about") }}
                                                    className={aboutError ? "is-danger" : "border"}

                                                    onChange={(e) => { setAbout(e.target.value) }}
                                                    placeholder="About me" ></textarea>

                                                {aboutError && (
                                                    <div className="form-error">
                                                        {aboutError}
                                                    </div>
                                                )}

                                            </div>

                                        </div>
                                        <div className="d-flex justify-content-between mt-3">
                                            <Link to={"/select-interests" + "/" + userId} className="previous btn" >
                                                Previous
                                            </Link>
                                            <button type="submit" className='next btn px-4' name='next'>Next</button>
                                        </div>

                                    </form>
                                </fieldset>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    )
}
