import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
import { DeleteApi } from "../../API/DeleteApi";
import { UpdateAPi } from "../../API/UpdateApi";
import { Endpoints } from "../../API/Endpoints";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import PostLoader from "../../Components/PostLoader/PostLoader";
import ToastMessage from "../../Utils/ToastMessage";
import CartEmpty from "./CartEmpty";
import ReactStars from "react-rating-stars-component";
import { Helmet } from "react-helmet";
const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [wishlistItems, setWishlistItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [Coupon, setCoupon] = useState("");
  const [CouponCourse, setCouponCourse] = useState("");
  const [CouponCourseError, setCouponCourseError] = useState("");
  const [CouponCourseSuccess, setCouponCourseSuccess] = useState("");
  const [CouponStatus, setCouponStatus] = useState(false);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [cartSummary, setCartSummary] = useState("");
  const [refreshCartcountStatus, setRefreshCartcountStatus] = useState(false);
  const userId = parseInt(localStorage.getItem("userId"));
  const Navigate = useNavigate();
  const getCartItems = async () => {
    setIsLoading(true);
    try {
      const resp = await CommonAPi(Endpoints.GetCartItems);
      setIsLoading(false);
      setCartItems(resp.data.cartItems.length > 0 ? resp.data.cartItems : []);
      if (resp && resp.data.cartItems.length > 0) {
        setCartSummary(resp.data.priceDetails);
        let newsubTotalAmountData = 0;
        let newTotalAmountData = 0;
        resp.data.cartItems.map((item) => {
          let newSubTotalAmount =
            parseInt(newsubTotalAmountData) + parseInt(item.course.price);
          newsubTotalAmountData = newSubTotalAmount;
          setSubTotalAmount(newSubTotalAmount);

          let newTotalAmount =
            parseInt(newTotalAmountData) +
            parseInt(item.course.discountedPrice);
          newTotalAmountData = newTotalAmount;
          setTotalAmount(newTotalAmount);

          if (item.course.couponDetails !== null) {
            setCouponStatus(true);
            setCouponCourse(item.course.id);
            setCoupon(item.course.couponDetails.couponCode);
            let discountAmount =
              item.course.discountedPrice -
              item.course.couponDetails?.finalPrice?.toFixed(2);
            setCouponDiscount(discountAmount);
            // newTotalAmount = newTotalAmount - discountAmount;
            // newTotalAmountData = newTotalAmount;
            // setTotalAmount(newTotalAmount);
          }
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleWishlist = async (Id) => {
    const obj = {
      course_id: Id,
    };

    try {
      let resp = await CommonAPi(Endpoints.AddToWishlist, obj);
      if (resp && resp.message) {
        ToastMessage.Success(resp.message);
        getCartItems();
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleAddToCart = async (Id) => {
    const obj = {
      course_id: Id,
    };

    try {
      let resp = await CommonAPi(Endpoints.AddToCart, obj);
      if (resp && resp.message) {
        setRefreshCartcountStatus(true);
        getCartItems();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getWishlistItems = async () => {
    try {
      const resp = await CommonAPi(Endpoints.GetWishlistItems);
      setWishlistItems(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCartItems();
    getWishlistItems();
  }, []);

  const handleRemove = async (id, couseId) => {
    setIsLoading(true);
    try {
      const resp = await DeleteApi(
        Endpoints.DeleteCartItem + id + "/" + couseId
      );
      if (resp && resp.success) {
        setRefreshCartcountStatus(true);
        setCouponStatus(false);
        setCouponCourse("");
        getCartItems();
        setIsLoading(false);
        ToastMessage.Success(resp.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const HandleApplyCoupon = async (event) => {
    event.preventDefault();
    const coursesId = cartItems.map((item) => {
      return item.courseId;
    });
    let obj = {
      course_id: coursesId,
      coupon_code: Coupon,
    };
    try {
      let resp = await CommonAPi(Endpoints.ApplyCoupon, obj);
      if (resp && resp.success === true) {
        getCartItems();
        setCouponCourseSuccess(resp.message);
      } else {
        setCouponCourseError(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleRemoveCoupon = async () => {
    setIsLoading(true);
    let data = {
      course_id: CouponCourse,
      coupon_code: Coupon,
    };
    try {
      const resp = await UpdateAPi(Endpoints.RemoveCoupon, data);
      if (resp && resp.success) {
        getCartItems();
        setCouponStatus(false);
        setCouponCourse("");
        setIsLoading(false);
        ToastMessage.Success(resp.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  return (
    <Container header refreshCartcount={refreshCartcountStatus}>
      <Helmet>
        <title>Cart</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-3 home ">
            {isLoading ? (
              <PostLoader />
            ) : cartItems.length > 0 ? (
              <div className="row">
                <div className="col-lg-9 pe-lg-3">
                  <div className="bg-white rounded-10 box-shadow py-3">
                    <div className=" medium px-3 pb-3 border-bottom fs-22">
                      <h2 className="medium px-3 pt-2 pb-3 fs-22">
                        Shopping Cart{" "}
                      </h2>

                      <h6 className="medium px-3 pb-3 ml-0 fs-15">
                        Items in your cart{" "}
                        <span className="shopping-cart-count fs-14">
                          {cartItems.length}
                        </span>
                      </h6>
                      <div></div>
                    </div>

                    <div className="py-3 px-3">
                      {cartItems.length > 0 &&
                        cartItems.map((item) => (
                          <div
                            className="cart-item pb-3"
                            key={`cart-${item?.course?.id}`}
                          >
                            <div className="row align-items-center">
                              <div className="col-md-auto d-flex align-items-md-center gap-2 justify-content-between flex-md-row-reverse pb-md-0 pb-2">
                                <Link
                                  to={"/course-detail/" + item.course.slug}
                                  target="_blank"
                                >
                                  <img
                                    src={`${
                                      Endpoints.baseUrl +
                                      item?.course?.thumbnail
                                    }`}
                                    className="rounded-2 object-cover"
                                    width="96"
                                    height="96"
                                    alt="cart-item-img"
                                  />
                                </Link>
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => {
                                    handleRemove(item?.id, item?.course?.id);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    width="32"
                                    height="32"
                                    strokeWidth="1.3"
                                    stroke="#9e9e9e"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                  </svg>
                                </a>
                              </div>
                              <div className="col-md-5">
                                <Link
                                  to={"/course-detail/" + item?.course?.slug}
                                  target="_blank"
                                >
                                  <h5 className="medium">
                                    {item?.course?.title}
                                  </h5>
                                </Link>
                                <div className="py-2 d-flex w-100 align-items-center fs-14 ">
                                  <span className="text-brown mt-1 ">
                                    {item?.course?.avgRating}
                                  </span>
                                  <div className="d-flex mx-2 mt-0">
                                    <ReactStars
                                      count={5}
                                      edit={false}
                                      value={item.course.avgRating}
                                      isHalf={true}
                                      char={
                                        <img
                                          src="../assets/img/icon/state-null.svg"
                                          alt=""
                                          width="18"
                                          height="16"
                                        />
                                      }
                                      emptyIcon={
                                        <img
                                          src="../assets/img/icon/state-null.svg"
                                          alt=""
                                          width="18"
                                          height="16"
                                        />
                                      }
                                      halfIcon={
                                        <img
                                          src="../assets/img/icon/state-half.svg"
                                          alt=""
                                          width="18"
                                          height="16"
                                        />
                                      }
                                      filledIcon={
                                        <img
                                          src="../assets/img/icon/State-fill.svg"
                                          alt=""
                                          width="18"
                                          height="16"
                                        />
                                      }
                                      activeColor="#ffc107"
                                    />
                                  </div>
                                  <span className="text-brown mt-1">
                                    ({item.course.totalRatings} reviews)
                                  </span>
                                  <span className="medium text-black ps-3 mt-1">
                                    {item.course.totalStudents}{" "}
                                    <span className="text-gray">Students</span>
                                  </span>
                                </div>
                                <h6 className="regular fs-14">
                                  Created by{" "}
                                  <span className="text-brown">
                                    {item.course.creatorId.fullName}
                                  </span>
                                </h6>
                              </div>
                              <div className="col-md-4 ms-lg-4  d-sm-flex align-items-center gap-3 ms-auto1 cart-btn-group text-center">
                                {/* <h4 className="text-blue semibold text-nowrap py-md-0 py-2">₹ {item.course.discountedPrice}</h4> */}
                                <div
                                  className=" course-price py-md-0 py-2  medium fs-18"
                                  style={{ textAlign: "left" }}
                                >
                                  <ins className="text-blue">
                                    ₹{" "}
                                    {item.course?.discountedPrice === null
                                      ? item.course?.price === null
                                        ? "0"
                                        : item.course?.price
                                      : item.course?.discountedPrice}
                                  </ins>
                                  {item.course?.discountedPrice === null ? (
                                    ""
                                  ) : (
                                    <del
                                      style={{ fontSize: ".9rem" }}
                                      className="text-lightgray"
                                    >
                                      ₹{" "}
                                      {item.course?.price === null
                                        ? "0"
                                        : item.course?.price}
                                    </del>
                                  )}
                                </div>
                                <div>
                                  <a
                                    href="#"
                                    onClick={() => {
                                      handleWishlist(item.courseId);
                                    }}
                                    className="btn btn-br-blue py-1 fs-12 mb-md-2"
                                  >
                                    Wishlist &nbsp;
                                    {item.wishlistId === 0 ? (
                                      <img
                                        src="assets/img/icon/heart-fill.svg"
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src="assets/img/icon/icon-wishlist-fill.svg"
                                        alt=""
                                      />
                                    )}
                                  </a>
                                </div>
                              </div>
                              {item.course?.couponDetails === null ? (
                                ""
                              ) : (
                                <div
                                  className="col-md-4 offset-8 text-end text-gray"
                                  style={{ textAlign: "left" }}
                                >
                                  <h6 style={{ float: "left" }}>
                                    Coupon Applied :
                                    <span className="text-blue">
                                      {item?.course?.couponDetails?.couponCode}
                                    </span>
                                  </h6>
                                  <h6 style={{ float: "left" }}>
                                    Amount :
                                    <span className="text-blue">
                                      {(
                                        item.course?.discountedPrice?.toFixed(
                                          2
                                        ) -
                                        item?.course?.couponDetails?.finalPrice?.toFixed(
                                          2
                                        )
                                      )?.toFixed(2)}
                                    </span>
                                  </h6>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

                  {wishlistItems.length > 0 && (
                    <div className="bg-white rounded-10 box-shadow py-3 mt-3">
                      <h2 className="medium px-3 pt-2 pb-3 border-bottom fs-22">
                        Recently Wishlisted Courses
                      </h2>
                      <div className="py-3 px-3">
                        {wishlistItems.map((item) => (
                          <div className="cart-item pb-3">
                            <div className="row align-items-center">
                              <div className="col-md-2 pb-md-0 pb-2">
                                <Link
                                  to={"/course-detail/" + item.course.slug}
                                  target="_blank"
                                >
                                  <img
                                    src={`${
                                      Endpoints.baseUrl +
                                      item?.course?.thumbnail
                                    }`}
                                    className="rounded-2 object-cover"
                                    width="96"
                                    height="96"
                                    alt=""
                                  />
                                </Link>
                              </div>
                              <div className="col-md-6">
                                <Link
                                  to={"/course-detail/" + item.course.slug}
                                  target="_blank"
                                >
                                  <h5 className="medium">
                                    {item?.course?.title}
                                  </h5>
                                </Link>
                                <div className="py-2 d-flex w-100 align-items-center fs-14 ">
                                  <span className="text-brown ">
                                    {item.course.avgRating}
                                  </span>

                                  <ReactStars
                                    count={5}
                                    edit={false}
                                    value={item.course.avgRating}
                                    size={24}
                                    isHalf={true}
                                    char={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        alt=""
                                        width="17"
                                      />
                                    }
                                    emptyIcon={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        alt=""
                                        width="17"
                                      />
                                    }
                                    halfIcon={
                                      <img
                                        src="../assets/img/icon/state-half.svg"
                                        alt=""
                                        width="17"
                                      />
                                    }
                                    filledIcon={
                                      <img
                                        src="../assets/img/icon/State-fill.svg"
                                        alt=""
                                        width="17"
                                      />
                                    }
                                    activeColor="#ffc107"
                                  />

                                  <span className="text-brown ">
                                    ({item.course.totalRatings} reviews)
                                  </span>
                                  <span className="medium text-black ps-3">
                                    {item.course.totalStudents}{" "}
                                    <span className="text-gray">Students</span>
                                  </span>
                                </div>
                                <h6 className="regular fs-14">
                                  Created by{" "}
                                  <span className="text-brown">
                                    {item?.course?.creatorId.fullName}
                                  </span>
                                </h6>
                              </div>
                              <div className="col-md-4  d-sm-flex align-items-center gap-3 ms-auto1 cart-btn-group text-center justify-content-end">
                                <h4 className="text-blue semibold text-nowrap py-md-0 py-2">
                                  ₹ {item?.course?.discountedPrice}
                                </h4>

                                {!item.course.instructors
                                  .map((ins) => ins.id)
                                  .includes(userId) && (
                                  <a
                                    href="#"
                                    className="btn fs-12 py-1 px-2"
                                    onClick={() => {
                                      handleAddToCart(item?.course?.id);
                                    }}
                                  >
                                    Add to Cart{" "}
                                    <img
                                      src="assets/img/icon/icon-cart-white.svg"
                                      width="12"
                                      alt=""
                                    />
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-3 p-lg-0 mt-lg-0 mt-3">
                  <div className="bg-white rounded-10 py-3">
                    <h2 className="medium px-3 pt-2 pb-3 border-bottom fs-22">
                      Cart Summary
                    </h2>
                    <div className="py-3 px-3 fs-14 text-gray mb-2">
                      <span className="medium  d-flex align-items-center justify-content-between">
                        Subtotal{" "}
                        <span className="text-black">
                          ₹
                          {/* {
                                                            cartItems.length > 0 &&
                                                            cartItems.reduce((acc, current) => acc + current.course.price, 0)
                                                        } */}
                          {cartSummary?.overallSubtotal?.toFixed(2)}
                        </span>
                      </span>
                      <span className="medium  d-flex align-items-center justify-content-between py-2">
                        Discount{" "}
                        <span className="text-black">
                          - ₹ {cartSummary?.overallDiscount?.toFixed(2)}
                        </span>
                      </span>
                      <span className="medium d-flex align-items-center justify-content-between  pb-2">
                        Coupon Discount{" "}
                        <span className="text-black">
                          - ₹ {cartSummary?.couponDiscount?.toFixed(2)}
                        </span>
                      </span>
                      <span className="medium  d-flex align-items-center justify-content-between">
                        Tax{" "}
                        <span className="text-black">
                          {cartSummary?.GST} (₹{" "}
                          {cartSummary?.taxAmt?.toFixed(2)})
                        </span>
                      </span>
                      <div className="border-top mt-3 py-3">
                        <h3 className="medium d-flex align-items-center justify-content-between text-black">
                          Total:{" "}
                          <span className="">
                            ₹{cartSummary?.overallTotal?.toFixed(2)}
                          </span>
                        </h3>
                      </div>
                      <Link to="/checkout" className="btn w-100">
                        Go To Checkout{" "}
                        <img
                          src="assets/img/icon/icon-right-arrow.svg"
                          alt=""
                        />
                      </Link>
                    </div>
                    <h2 className="medium px-3 pt-2 pb-3 border-bottom fs-22">
                      Discount Coupon
                    </h2>
                    {CouponStatus ? (
                      <div className=" px-3 py-3 gap-1">
                        <Link onClick={handleRemoveCoupon}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            width="32"
                            height="32"
                            strokeWidth="1.3"
                            stroke="#9e9e9e"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </Link>
                        <span className="text-black fs-18 text-uppercase medium">
                          {Coupon}
                        </span>
                        <span className="fs-14 text-gray"> is applied.</span>

                        {CouponCourseSuccess ? (
                          <div
                            className="form-success"
                            style={{ color: "white", marginTop: "1px" }}
                          >
                            {CouponCourseSuccess}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {CouponStatus === false ? (
                      <div className={CouponStatus ? "px-3" : "px-3 mt-3"}>
                        <form onSubmit={HandleApplyCoupon} className="  ">
                          <div className=" d-flex align-items-center justify-content-between border rounded-2 overflow-hidden">
                            <input
                              type="text"
                              className="border-0 bg-transparent text-gray px-3"
                              placeholder="Enter Coupon"
                              onChange={(e) => {
                                setCoupon(e.target.value);
                              }}
                            />

                            {/* <button className="btn rounded-0">Apply</button> */}
                            <input
                              type="submit"
                              value="Apply"
                              className="btn rounded-0"
                            />
                          </div>
                          {CouponCourseError ? (
                            <div
                              className="form-error"
                              style={{ color: "white", marginTop: "1px" }}
                            >
                              {CouponCourseError}
                            </div>
                          ) : (
                            ""
                          )}
                        </form>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <CartEmpty />
            )}
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
};
export default Cart;
