import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";

export default function LeftStatistics() {
  const [statistics, setStatistics] = useState("");
  const [about, setAbout] = useState("");
  const navigate = useNavigate();
  const param = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCompanyAbout();
  }, []);

  const getCompanyAbout = async () => {
    try {
      let resp = await FetchApi(Endpoints.PageDetail + "?slug=" + param?.slug);
      if (resp && resp.success === true) {
        setStatistics({
          totalEmployees: resp?.data?.totalEmployees,
          totalFollowers: resp?.data?.totalFollowers,
          totalJobs: resp?.data?.totalJobs,
          pagetype: resp?.data?.pageType,
        });
        setAbout(resp?.data);
      }
    } catch (e) {
      setLoading(false);
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
    setLoading(false);
  };

  return (
    !loading &&
    statistics && (
      <div className="col-md-3 comany-dp-left pb-3">
        <div className="sidebar-widget box-shadow">
          <div className="pb-3 d-flex align-items-start">
            <span>
              <img
                src="../assets/img/icon/icon-emplyee.svg"
                alt=""
                width="36"
                height="36"
              />
            </span>
            <div className="ps-2">
              <h4 className="medium">{statistics?.totalEmployees}</h4>
              <h6 className="fs-12 text-darkgray regular">Employees</h6>
            </div>
          </div>

          <div className="pb-3 d-flex align-items-start">
            <span>
              <img
                src="../assets/img/icon/icon-jobs-created.svg"
                alt=""
                width="36"
                height="36"
              />
            </span>
            <div className="ps-2">
              <h4 className="medium">{statistics?.totalJobs}</h4>
              <h6 className="fs-12 text-darkgray regular">Jobs Created</h6>
            </div>
          </div>

          <div className="d-flex align-items-start">
            <span>
              <img
                src="../assets/img/icon/icon-followers.svg"
                alt=""
                width="36"
                height="36"
              />
            </span>
            <div className="ps-2">
              <h4 className="medium">{statistics?.totalFollowers}</h4>
              <h6 className="fs-12 text-darkgray regular">
                {statistics?.pagetype === "Page" ? "Member" : "Followers"}
              </h6>
            </div>
          </div>
        </div>

        <div className="sidebar-widget box-shadow mt-3">
          <h4 className="medium border-bottom pb-2 mb-3">Overview</h4>
          <p className="fs-14">{about?.description}</p>

          <div className="border-top pt-3">
            <div className="d-flex align-items-center gap-2 fs-14 medium text-brown pb-3">
              <img src="../assets/img/icon/icon-phone.svg" alt="" />
              <span>{about?.phone}</span>
            </div>

            <div className="d-flex align-items-center gap-2 fs-14 medium text-brown pb-3">
              <img src="../assets/img/icon/icon-email.svg" alt="" />
              <span>{about?.email}</span>
            </div>
            <div className="d-flex align-items-center gap-2 fs-14 medium text-brown">
              <img src="../assets/img/icon/icon-web-globe.svg" alt="" />
              <Link
                to={{ pathname: `https://${about.website}` }}
                target={"_blank"}
              >
                <span>{about?.website}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
