import React, { useEffect, useState } from "react";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from '../../API/FetchApi';
import {  Link, useNavigate } from "react-router-dom";
import { CommonAPi } from '../../API/CommonApi';

export default function SuggestedGroups() {
    const [groupData, setGroupData] = useState([]);
    const Navigate = useNavigate();
    useEffect(() => {
        getGroup();
    }, []);
    const getGroup = async () => {
        try {
            let resp = await FetchApi(Endpoints.recommendationsUser);
            if (resp && resp.success === true) {
                setGroupData(resp?.data?.groupRecommendations)
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };

    const joinGroup = async (id) => {
        let data = {
            groupId: id
        }
        try {
            let resp = await CommonAPi(Endpoints.JoinGroups, data);
            if (resp && resp.success === true) {
                getGroup();
                ToastMessage.Success(resp.message)
            }
        }
        catch (e) {

            if (e && e.response && e.response.data && e.response.data.message) {

                if (e.response.status === 401) {

                    ToastMessage.Error('Session Expired! Please login again.')
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message)
                }
            }
        }

    };

  return (
    <>
    {groupData?.length > 0 ?  
    <div className="sidebar-widget mt-4">
    <div className="d-flex align-items-center justify-content-between">
      <h4 className="medium">Suggested Groups</h4>
      <Link to={"/groups"} className="text-brown fs-12 medium">
        See all{" "}
        <img src="../assets/img/icon/icon-right-arrow-brown.svg" class="ps-1 text-brown" alt="" />
      </Link>
    </div>

    <div className="pt-3 mt-2 border-top">
      <ul>

        {groupData.map((item, index)=>{
            if(index > 4) return null;
            return (
                <li className="d-flex align-items-center pb-3">
                <div className="position-relative">
                <Link to={"/group-home/" + item?.id}>
                  <img
                    src={Endpoints.baseUrl + item?.logo}
                    alt=""
                    width="58"
                    height="48"
                    className="rounded-circle"
                  />
                  </Link>
                </div>
                <div className="ps-2 w-100 d-flex align-items-center justify-content-between ">
                  <div className="">
                  <Link to={"/group-home/" + item?.id}>
                    <h6 className="medium text-black">
                      {item.groupName}
                    </h6>
                  </Link>
                    <h6 className="fs-12 regular text-darkgray pt-1">
                      {item?.groupType} <span className="px-1">·</span>  {item?.totalMembers}{" "}
                      members
                    </h6>
                  </div>
                {item.isJoined === "N/A" ?
                  <Link onClick={()=>{
                    joinGroup(item.id)
                  }}>
                    <img src="assets/img/icon/btn-join.svg" alt="" />
                  </Link>
                : "" }


                </div>
              </li>
            )
        })}
      

      </ul>
    </div>
    </div>
    : ""}
  </>
  )
}
