import React, { useEffect, useState } from "react";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Profile from "./Profile";
import Sidebar from "./Sidebar";
import { Endpoints } from "../../../API/Endpoints";
import ToastMessage from "../../../Utils/ToastMessage";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FetchApi } from "../../../API/FetchApi";
import { CommonAPi } from "../../../API/CommonApi";
import { DeleteApi } from "../../../API/DeleteApi";
import { PatchApi } from "../../../API/PatchApi";
import { StaticRules } from "../../../constants/StaticRules";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
const $ = window.jQuery;
export default function Rule() {
  const param = useParams();
  const Navigate = useNavigate();
  const [rules, setRules] = useState([]);
  const [rulesTitle, setRulesTitle] = useState("");
  const [rulesDescription, setRulesDescription] = useState("");
  const [rulesId, setRulesId] = useState("");
  const [EditgroupId, setEditGroupId] = useState("");
  const [SelectrulesId, setSelectRulesId] = useState(0);
  const [rulesTitleError, setRulesTitleError] = useState("");
  const [rulesDescriptionError, setRulesDescriptionError] = useState("");

  useEffect(() => {
    GetGroupRule();
  }, []);
  const GetGroupRule = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.GroupRuleAll + "?groupId=" + param?.id
      );
      if (resp && resp.success === true) {
        setRules(resp?.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleDelete = async (id, groupId) => {
    let data = {
      id: id,
      groupId: groupId,
    };
    try {
      let resp = await DeleteApi(Endpoints.DeleteRule, data);
      if (resp && resp.success === true) {
        GetGroupRule();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!rulesTitle) {
      setRulesTitleError("Rules title is required");
    } else {
      setRulesTitleError("");
    }

    if (!rulesDescription) {
      setRulesDescriptionError("Rules description is required");
    } else {
      setRulesDescriptionError("");
    }
    if (rulesDescription && rulesTitle) {
      if (rulesId != "") {
        let data = {
          id: rulesId,
          groupId: EditgroupId,
          title: rulesTitle,
          description: rulesDescription,
        };
        try {
          let resp = await PatchApi(Endpoints.EditRule, data);
          if (resp && resp.success === true) {
            $("#add_rules").modal("hide");
            GetGroupRule();
            setRulesDescription("");
            setRulesTitle("");
            setRulesId("");
            setEditGroupId("");
            ToastMessage.Success(resp.message);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              Navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      } else {
        let data = {
          groupId: param?.id,
          title: rulesTitle,
          description: rulesDescription,
        };
        try {
          let resp = await CommonAPi(Endpoints.CreateRule, data);
          if (resp && resp.success === true) {
            $("#add_rules").modal("hide");
            GetGroupRule();
            setRulesDescription("");
            setRulesTitle("");
            setRulesId("");
            setEditGroupId("");
            ToastMessage.Success(resp.message);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              Navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      }
    }
  };

  const handleEdit = (item) => {
    setRulesDescription(item?.description);
    setRulesTitle(item?.title);
    setRulesId(item?.id);
    setEditGroupId(item?.groupId);
    $("#add_rules").modal("show");
  };

  const handleChangeStaticRules = (item) => {
    setRulesDescription(item?.description);
    setRulesTitle(item?.title);
    setSelectRulesId(item?.id);
  };

  const handleCloseRulesModal = () => {
    if (rulesDescription || rulesTitle || SelectrulesId) {
      Swal.fire({
        text: "You have unsaved changes, do you still want to exit?",
        icon: "question",
        customClass: {
          confirmButton: "btn px-3 mx-2",
          cancelButton: "btn btn-br-blue px-3",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Yes, discard it!",
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          setRulesDescription("");
          setRulesTitle("");
          setSelectRulesId("");
          setRulesTitleError("");
          setRulesDescriptionError("");
          $("#add_rules").modal("hide");
        } else {
          $("#add_rules").modal("show");
        }
      });
    } else {
      setRulesTitleError("");
      setRulesDescriptionError("");
      $("#add_rules").modal("hide");
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Group Rule</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div class="container py-lg-4 py-3">
            <Profile />
            <div class="row">
              <Sidebar />
              <div class="col-lg-9 col-md-8">
                <div class="bg-white box-shadow rounded-15 p-md-3 p-2">
                  <div class="pb-2 d-md-flex align-items-center justify-content-between">
                    <h3 class="medium fs-20">Rules</h3>

                    <a
                      href="#"
                      class="btn btn-br-blue medium px-4 fs-14"
                      data-bs-toggle="modal"
                      data-bs-target="#add_rules"
                    >
                      + Add Rules
                    </a>
                  </div>

                  <div class="pb-2">
                    {rules.length > 0 ? (
                      rules.map((item, index) => {
                        return (
                          <div
                            class="border-bottom pt-3 d-flex align-items-start justify-content-between"
                            key={index}
                          >
                            <div>
                              <h6 class="medium fs-14 text-black pb-2">
                                {item?.title}
                              </h6>
                              <p
                                class="fs-12 text-color"
                                style={{ "--color": "#565656" }}
                              >
                                {item?.description}
                              </p>
                            </div>

                            <div class="position-relative">
                              <a
                                href="#"
                                id="rules1"
                                data-bs-toggle="dropdown"
                                class=""
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  width="22"
                                  height="22"
                                  stroke="#000"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  class="css-i6dzq1"
                                >
                                  <circle cx="12" cy="12" r="1"></circle>
                                  <circle cx="19" cy="12" r="1"></circle>
                                  <circle cx="5" cy="12" r="1"></circle>
                                </svg>
                              </a>

                              <ul
                                class="dropdown-menu dropdown-menu-end shadow fs-12 rounded-3"
                                role="menu"
                                aria-labelledby="rules1"
                              >
                                <li>
                                  <Link
                                    class="dropdown-item py-2 d-flex gap-2"
                                    onClick={() => {
                                      handleEdit(item);
                                    }}
                                  >
                                    <img
                                      src="assets/img/icon/icon-edit.svg"
                                      width="15"
                                      class="me-1"
                                      alt=""
                                    />
                                    Edit
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    onClick={() => {
                                      handleDelete(item.id, item.groupId);
                                    }}
                                    class="dropdown-item py-2 d-flex gap-2"
                                  >
                                    <img
                                      src="assets/img/icon/icon-delete.svg"
                                      width="15"
                                      class="me-1"
                                      alt=""
                                    />
                                    Delete
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div class="border-bottom pt-3 d-flex align-items-start">
                        No rules added
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal" id="add_rules" tabindex="-1">
            <div className="modal-dialog modal-lg px-lg-5 modal-dialog-centered modal-dialog-scrollable modal-md">
              <div className="modal-content">
                <button
                  type="button"
                  className="btn-close ms-auto p-3 position-absolute end-0 "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    handleCloseRulesModal();
                  }}
                ></button>
                <div className="text-center py-4 border-bottom">
                  {rulesId ? (
                    <h5 className="modal-title text-center fs-20 medium text-black">
                      Edit Rules
                    </h5>
                  ) : (
                    <h5 className="modal-title text-center fs-20 medium text-black">
                      Create Rules
                    </h5>
                  )}
                </div>
                <div className="modal-body">
                  <h5 className="fs-18 medium pb-3">Example Rules</h5>
                  <div className="rule-list pb-4 d-flex gap-2 flex-wrap">
                    {StaticRules.map((item, index) => {
                      return (
                        <span key={index}>
                          <input
                            type="radio"
                            name="rules"
                            id={"rules" + index}
                            className="d-none"
                            checked={item?.id === SelectrulesId ? true : false}
                            onChange={() => {
                              handleChangeStaticRules(item);
                            }}
                          />
                          <label
                            for={"rules" + index}
                            className="py-2 px-3 rounded-pill bg-d8d8d8 text-black fs-14 medium"
                          >
                            {item?.title}
                          </label>
                        </span>
                      );
                    })}
                  </div>
                  <form onSubmit={handleSubmit}>
                    <h5 className="fs-18 medium pb-3">Write your own</h5>
                    <input
                      type="text"
                      placeholder="Title"
                      className="fs-14 border rounded-3 py-2 px-3 w-100 mb-2"
                      value={rulesTitle}
                      onChange={(e) => {
                        setRulesTitle(e.target.value);
                      }}
                    />
                    {rulesTitleError ? (
                      <div
                        className="form-error"
                        style={{
                          color: "white",
                          margintop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        {rulesTitleError}
                      </div>
                    ) : (
                      ""
                    )}
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="5"
                      className="fs-14 border rounded-3 py-2 px-3 w-100 mb-2"
                      placeholder="Description"
                      value={rulesDescription}
                      onChange={(e) => {
                        setRulesDescription(e.target.value);
                      }}
                    ></textarea>
                    {rulesDescriptionError ? (
                      <div
                        className="form-error"
                        style={{
                          color: "white",
                          margintop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        {rulesDescriptionError}
                      </div>
                    ) : (
                      ""
                    )}
                    <button type="submit" className="btn fs-14 px-5">
                      {" "}
                      {rulesId ? "Update" : "Create"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
