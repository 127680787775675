import React, { useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import GroupProfile from "./GroupProfile";
import PeopleItem from "./PeopleItem";
import SimilarGroup from "./SimilarGroup";
import moment from "moment";
import ManageGroup from "./ManageGroup";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function GroupPeople() {
  const [totalPeoples, setTotalPeoples] = useState(0);
  const [SearchText, setSearchText] = useState("");
  const [groupsData, setGroupsData] = useState("");
  const [descriptionLimit, setDescriptionLimit] = useState(115);
  const userId = localStorage.getItem("userId");

  return (
    <Container header>
      <Helmet>
        <title>Group People</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-lg-3 py-3">
            <GroupProfile
              {...{
                setGroupsData: setGroupsData,
              }}
            />

            <div className="row">
              <div className="col-md-3 comany-dp-left pb-3">
                <div className="sidebar-widget box-shadow">
                  <div className="pb-3 d-flex align-items-start">
                    <span>
                      <img
                        src="../assets/img/icon/icon-emplyee.svg"
                        alt=""
                        width="36"
                        height="36"
                      />
                    </span>
                    <div className="ps-2">
                      <h4 className="medium">{groupsData?.totalMember}</h4>
                      <h6 className="fs-12 text-darkgray regular">Members</h6>
                    </div>
                  </div>

                  <div className="pb-3 d-flex align-items-start">
                    <span>
                      <img
                        src="../assets/img/icon/icon-jobs-created.svg"
                        alt=""
                        width="36"
                        height="36"
                      />
                    </span>
                    <div className="ps-2">
                      <h4 className="medium">0</h4>
                      <h6 className="fs-12 text-darkgray regular">
                        Admins & Managers
                      </h6>
                    </div>
                  </div>

                  <div className="d-flex align-items-start">
                    <span>
                      <img
                        src="../assets/img/icon/icon-followers.svg"
                        alt=""
                        width="36"
                        height="36"
                      />
                    </span>
                    <div className="ps-2">
                      <h4 className="medium">
                        {moment(groupsData?.groupData?.created_at).format(
                          "MMM YYYY"
                        )}
                      </h4>
                      <h6 className="fs-12 text-darkgray regular">Created</h6>
                    </div>
                  </div>
                </div>

                <div className="sidebar-widget mt-3 box-shadow pb-3">
                  <h5 className="medium border-bottom pb-2 mb-2">
                    About Group
                  </h5>
                  {groupsData?.groupData?.description?.length > descriptionLimit
                    ? groupsData?.groupData?.description.substring(
                        0,
                        descriptionLimit
                      ) + "..."
                    : groupsData?.groupData?.description}
                  {groupsData?.groupData?.description?.length ==
                  descriptionLimit ? (
                    ""
                  ) : (
                    <Link
                      className="btn gray-btn w-100 text-black fs-14 medium mt-3"
                      onClick={() => {
                        setDescriptionLimit(
                          groupsData?.groupData?.description?.length
                        );
                      }}
                    >
                      Read More
                    </Link>
                  )}
                  {/* <a href="#" className="btn bg-d8d8d8 w-100 text-black fs-14 medium">Read More</a> */}
                </div>
              </div>

              <div className="col-md-6  pb-3">
                {groupsData?.isJoin?.status === "PENDING" &&
                groupsData?.groupData?.groupType === "Private" ? (
                  <div className="bg-white box-shadow rounded-15 p-md-3 p-2">
                    <h3>Welcome to {groupsData?.groupData?.groupName} </h3>

                    <p>
                      This is a private group, which means its content and
                      discussions are restricted to approved members only. While
                      you can't view the group's content without joining, here's
                      a brief overview:
                    </p>
                    <h3>Group Purpose:</h3>

                    <p>{groupsData?.groupData?.description}</p>
                    <h3>Privacy:</h3>
                    <p>
                      This group is designed for members who share a common
                      interest or goal. To access the discussions and content,
                      you'll need to request to join.
                    </p>
                    <p>
                      If you're interested in becoming a member and
                      participating in the group's discussions, click the 'Join'
                      button. The group administrators will review your request,
                      and once approved, you'll gain access to the group.
                    </p>
                    <p>
                      Thank you for your interest in{" "}
                      {groupsData?.groupData?.groupName}. We look forward to
                      welcoming you as a member!
                    </p>
                  </div>
                ) : (
                  <div className="bg-white box-shadow rounded-15 p-md-3 p-2">
                    <h3 className="medium fs-20 mb-3">
                      Members{" "}
                      <span className="text-gray">- {totalPeoples}</span>
                    </h3>

                    <form
                      action=""
                      className="bg-eee py-2 mb-4 rounded-pill d-flex flex-row-reverse align-items-center justify-content-end w-100 px-lg-4"
                    >
                      <input
                        type="text"
                        placeholder="Search members"
                        name="search"
                        className="bg-transparent fs-14 medium"
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                      <button
                        type="submit"
                        className="bg-transparent border-0 ps-0"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          stroke="#949494"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="css-i6dzq1"
                        >
                          <circle cx="11" cy="11" r="8"></circle>
                          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                      </button>
                    </form>

                    <PeopleItem
                      {...{
                        type: "friend",
                        setTotalPeoples: setTotalPeoples,
                        totalPeoples: totalPeoples,
                        SearchText: SearchText,
                      }}
                    />

                    <PeopleItem
                      {...{
                        type: "unfriend",
                        setTotalPeoples: setTotalPeoples,
                        totalPeoples: totalPeoples,
                        SearchText: SearchText,
                      }}
                    />
                  </div>
                )}
              </div>
              {groupsData?.groupData?.userId == userId ? (
                <ManageGroup />
              ) : (
                <SimilarGroup />
              )}
            </div>
            <OnlineFriends />
          </div>
        </div>
      </div>
    </Container>
  );
}
