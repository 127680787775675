import React from "react";

export default function TopHeader() {
  return (
    <div className="header  py-md-2 py-3 bg-white border-bottom">
      <div className="container ">
        <div className="row m-0 align-items-center">
          <div className="col-md-3 col-6">
            <div className="site-logo">
              <a href="">
                <img src="../../assets/img/8inNet_logo.svg" alt="" width="90" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
