import React, { useEffect, useState } from "react";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from "../../API/FetchApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import PhotosItem from "./PhotosItem";
export default function Photo() {
  const [image, setImage] = useState([]);
  const [count, setCount] = useState(0);
  const Navigate = useNavigate();
  const param = useParams();

  useEffect(() => {
    getUserImages();
  }, [param?.id]);

  const getUserImages = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.UserPhotoVideo + "?size=12&page=1&type=photos&id=" + param?.id
      );
      if (resp && resp.success === true) {
        setImage(resp?.data?.data);
        setCount(resp?.data?.count);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <div className="sidebar-widget box-shadow">
      <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-3">
        <h4 className="medium">
          Photos <span className="fs-10 text-brown count-number">{count}</span>
        </h4>
        <Link
          to={"/profile-photos/" + param?.id}
          className="text-brown fs-12 medium"
        >
          See all{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-arrow-right feather-16"
          >
            <line x1="5" y1="12" x2="19" y2="12"></line>
            <polyline points="12 5 19 12 12 19"></polyline>
          </svg>
        </Link>
      </div>

      <div className="row px-1 sidebar-photos-gallery">
        {image.map((item, index) => {
          if (index > 5) {
            return null;
          }
          return (
            <PhotosItem
              {...{
                item: item,
                index: index,
                post: item?.post,
              }}
            />
          );
        })}
      </div>

      {/* <div className="d-none">
     
     {image.map((item, index)=>{
      if(index < 6){
        return null;
      }
      return (
      <div className="col-6 mb-2 px-1" key={index}>
        <a
          href={Endpoints.baseUrl + item.filePath}
          data-fancybox="photos-userid"
        >
          <img
            src={Endpoints.baseUrl + item.filePath}
            className="rounded-2"
            alt=""
            width="110"
            height="110"
          />
        </a>
      </div>
      )
     })}
      

     
    </div> */}
    </div>
  );
}
