import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { DeleteApi } from "../../API/DeleteApi";
import { CommonAPi } from "../../API/CommonApi";
import { UpdateAPi } from "../../API/UpdateApi";
import { Month } from "../../constants/Month";
import { EmploymentType } from "../../constants/EmploymentType";

import moment, { months } from "moment";
import DeletePrompt from "../../Components/DeletePrompt/DeletePrompt";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import DiscardPopup from "../../Components/DiscardPopup/DiscardPopup";
const $ = window.jQuery;
export default function Experience() {
  const [experience, setExperience] = useState([]);
  const [id, setId] = useState("");
  const [designation, setDesignation] = useState("");
  const [designationKey, setDesignationKey] = useState("");
  const [designationId, setDesignationId] = useState("");
  const [designationOption, setDesignationOption] = useState([]);
  const [organization, setOrganization] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [organizationData, setOrganizationData] = useState("");
  const [details, setDetails] = useState("");
  const [startingYear, setstartingYear] = useState("");
  const [endingYear, setendingYear] = useState("");
  const [studyingCurrently, setstudyingCurrently] = useState(false);
  const [pageId, setpageId] = useState(0);
  const [options, setOptions] = useState([]);
  const [startingMonth, setstartingMonth] = useState("");
  const [endingMonth, setendingMonth] = useState("");
  const [year, setyear] = useState(new Date().getFullYear());
  const [Totalyear, setTaotalyear] = useState(new Array(50).fill(""));
  const [TotalyearData, setTaotalyearData] = useState([]);
  const [TotalEndingyearData, setTaotalEndingyearData] = useState([]);

  const [designationError, setDesignationError] = useState("");
  const [organizationError, setOrganizationError] = useState("");
  const [detailsError, setDetailsError] = useState("");
  const [startingYearError, setstartingYearError] = useState("");
  const [endingYearError, setendingYearError] = useState("");
  const [startingMonthError, setstartingMonthError] = useState("");
  const [endingMonthError, setendingMonthError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [item, setItem] = useState(null);
  const [pastMonths, setPastMonths] = useState([]);
  const [endingMonths, setEndingMonths] = useState([]);
  const [Search, setSearch] = useState("");
  const [instituteNameKey, setInstituteNameKey] = useState("instituteNameKey");
  const [employment, setEmployment] = useState("");
  const [employmentError, setEmploymentError] = useState("");

  const param = useParams();
  const userId = localStorage.getItem("userId");
  const username = localStorage.getItem("userName");

  const setYear = (year) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    console.log(currentYear, year);
    if (currentYear == year) {
      setPastMonths(Month.filter((month, index) => index <= currentMonth));
    } else {
      setPastMonths(Month);
    }
  };
  const setEndYear = (year) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    if (currentYear == year) {
      setEndingMonths(Month.filter((month, index) => index <= currentMonth));
    } else {
      setEndingMonths(Month);
    }
  };

  const Navigate = useNavigate();
  useEffect(() => {
    if (
      designation !== "" &&
      organization !== "" &&
      details !== "" &&
      startingYear !== "" &&
      startingMonth !== "" &&
      pageId !== "" &&
      employment !== ""
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    designation,
    organization,
    details,
    startingYear,
    startingMonth,
    studyingCurrently,
    pageId,
    employment,
  ]);

  useEffect(() => {
    let data = Totalyear.map((v, i) => {
      return {
        value: year - i,
        label: year - i,
      };
    });
    setTaotalyearData(data);
    if (startingYear) {
      let newdata = Totalyear.map((v, i) => {
        return {
          value: parseInt(startingYear) + parseInt(i),
          label: parseInt(startingYear) + parseInt(i),
        };
      });
      setTaotalEndingyearData(newdata);
    }
  }, []);

  const handleStartingYear = (years) => {
    let newdata = Totalyear.map((v, i) => {
      return {
        value: parseInt(years) + parseInt(i),
        label: parseInt(years) + parseInt(i),
      };
    });
    setTaotalEndingyearData(newdata);
  };

  const getYear = (endingM, endingY, startingM, startingY) => {
    let ending_date = endingY
      ? moment(`${endingM} ${endingY}`, "MMM YYYY")
      : moment().toDate();
    let starting_date = startingY
      ? moment(`${startingM} ${startingY}`, "MMM YYYY")
      : moment().toDate();
    let diff = moment(starting_date).diff(ending_date, "months");
    let str = "";
    let years = Math.floor(diff / 12);
    let months = diff % 12;
    if (diff === 0) {
      str += diff + " Month";
    }
    if (years) {
      str += years + " Years ";
    }
    if (months) {
      str += months + " Months ";
    }
    return str;
  };

  const handleOnSelect = (item) => {
    if (item.length > 0) {
      setpageId(item[0].id);
      setOrganization(item[0].name);
    } else {
      setpageId(0);
      setOrganization("");
    }
  };
  const handleOnSelectDesignation = (item) => {
    if (item.length > 0) {
      if (item[0].createNew === true) {
        handleCreateDesignation(item);
      } else {
        setDesignationId(item[0].id);
        setDesignation(item[0].name);
      }
    } else {
      setDesignationId(0);
      setDesignation("");
    }
  };

  const handleCreateDesignation = async (item) => {
    let data = {
      id: -1,
      designation: item[0].name,
    };
    try {
      let resp = await CommonAPi(Endpoints.createDesignation, data);
      if (resp && resp.message) {
        setDesignationId(resp.data.id);
        setDesignation(resp.data.name);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleOnSearch = async (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setSearch(string);
    setOrganization(string);
    if (string) {
      try {
        let resp = await FetchApi(Endpoints.SearchPages + "?title=" + string);
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                image: item?.pageImage,
                name: item?.title,
              };
            });
            setOptions(newData);
          } else {
            const newData = [
              {
                id: 0,
                image: "",
                name: string,
                createName: string,
              },
            ];
            setOptions(newData);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  const handleOnSearchDesignation = async (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setDesignation(string);
    if (string) {
      try {
        let resp = await FetchApi(Endpoints.searchDesignation + "?q=" + string);
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                name: item?.name,
                createNew: false,
              };
            });
            setDesignationOption(newData);
          } else {
            const newData = [
              {
                id: -1,
                name: string,
                createNew: true,
              },
            ];
            setDesignationOption(newData);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!designation) {
      setDesignationError("Please enter designation ");
    } else if (designation) {
      setDesignationError("");
    }
    if (!organization) {
      setOrganizationError("Please enter organization");
    } else if (organization) {
      setOrganizationError("");
    }
    if (!details) {
      setDetailsError("Please enter details");
    } else if (details) {
      setDetailsError("");
    }
    if (!startingYear) {
      setstartingYearError("Please enter starting year");
    } else if (startingYear) {
      setstartingYearError("");
    }
    if (!startingMonth) {
      setstartingMonthError("Please enter starting month");
    } else if (startingMonth) {
      setstartingMonthError("");
    }

    if (!employment) {
      setEmploymentError("Please select employment type");
    } else if (employment) {
      setEmploymentError("");
    }

    if (studyingCurrently === false) {
      if (!endingYear) {
        setendingYearError("Please enter ending year");
        return false;
      } else if (endingYear) {
        setendingYearError("");
      }
      if (!endingMonth) {
        setendingMonthError("Please enter ending month");
        return false;
      } else if (endingMonth) {
        setendingMonthError("");
      }

      if (endingYear) {
        let ending_date = moment(`${endingMonth} ${endingYear}`, "MMM YYYY");
        let starting_date = moment(
          `${startingMonth} ${startingYear}`,
          "MMM YYYY"
        );
        if (Date.parse(starting_date) >= Date.parse(ending_date)) {
          setendingMonthError("End date can’t be earlier than start date");
          return false;
        }
      }
    }
    if (
      organization &&
      designation &&
      details &&
      startingYear &&
      startingMonth &&
      employment
    ) {
      let data = "";
      if (studyingCurrently === false) {
        data = {
          designation_id: designationId,
          organization: organization,
          details: details,
          starting_year: startingYear,
          ending_year: endingYear,
          employment_type: employment,
          starting_month: startingMonth,
          ending_month: endingMonth,
          working_currently: studyingCurrently,
          page_id: pageId ? pageId : 0,
        };
      } else {
        data = {
          designation_id: designationId,
          organization: organization,
          employment_type: employment,
          details: details,
          starting_month: startingMonth,
          starting_year: startingYear,
          working_currently: studyingCurrently,
          page_id: pageId ? pageId : 0,
        };
      }
      if (id) {
        try {
          let resp = await UpdateAPi(
            Endpoints.UpdateExperience + "/" + id,
            data
          );
          if (resp && resp.message) {
            document.getElementById("experiencemyform").reset();
            setDesignation("");
            setOrganization("");
            setDetails("");
            setstartingYear("");
            setendingYear("");
            setstartingMonth("");
            setOrganizationName("");
            setendingMonth("");
            setOrganizationData("");
            setstudyingCurrently(false);
            setpageId(0);
            setOptions([]);
            setInstituteNameKey(Math.ceil(Math.random() * 999999));
            setDesignationKey(Math.ceil(Math.random() * 999999));
            setDesignationOption("");
            setDesignationId("");
            setId("");
            $("#Experiencemodal").modal("hide");

            getExperience();
            ToastMessage.Success(resp.message);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              Navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      } else {
        try {
          let resp = await CommonAPi(Endpoints.CreateExperience, data);
          if (resp && resp.message) {
            document.getElementById("experiencemyform").reset();
            setDesignation("");
            setOrganization("");
            setDetails("");
            setOrganizationName("");
            setInstituteNameKey(Math.ceil(Math.random() * 999999));
            setDesignationKey(Math.ceil(Math.random() * 999999));
            setDesignationOption("");
            setDesignationId("");
            setOrganizationData("");
            setstartingYear("");
            setendingYear("");
            setstartingMonth("");
            setendingMonth("");
            setstudyingCurrently(false);
            setpageId(0);
            setOptions([]);
            $("#Experiencemodal").modal("hide");
            getExperience();
            ToastMessage.Success(resp.message);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              Navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getExperience();
    }
    return () => {
      mounted = false;
    };
  }, [param?.id]);
  const getExperience = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetExperience + "?id=" + param?.id);
      if (resp && resp.success === true) {
        setExperience(resp?.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleDeleteExperience = async (id) => {
    try {
      let resp = await DeleteApi(Endpoints.DeleteExperience + "/" + id);
      if (resp && resp.success === true) {
        getExperience();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleEditAcademics = (data) => {
    console.log(data);
    setId(data?.id);
    setEmployment(data?.employmentType);
    setDesignation(data?.designation?.name);
    setOrganization(data?.organization);
    setOrganizationName(data?.organization);
    setInstituteNameKey(data?.organization);
    setDetails(data?.details);
    setOptions([
      {
        name: data?.page?.title,
        id: data?.page?.id,
        image: data?.page?.pageImage,
      },
    ]);
    setDesignationKey(Math.ceil(Math.random() * 999999));
    setDesignationOption([
      {
        name: data?.designation?.name,
        id: data?.designation?.id,
        createNew: false,
      },
    ]);
    setDesignationId(data?.designation?.id);
    setpageId(data?.pageId);
    setstartingYear(data?.startingYear);
    setYear(data?.startingYear);
    setstartingMonth(data?.startingMonth);
    setendingYear(data?.endingYear);
    setEndYear(data?.endingYear);
    setendingMonth(data?.endingMonth);
    setstudyingCurrently(data?.workingCurrently);
    $("#Experiencemodal").modal("show");

    if (data?.startingYear) {
      let newdata = Totalyear.map((v, i) => {
        return {
          value: parseInt(data?.startingYear) + parseInt(i),
          label: parseInt(data?.startingYear) + parseInt(i),
        };
      });
      setTaotalEndingyearData(newdata);
    }
  };

  const handleCloseModel = () => {
    console.log();

    if (
      designation !== "" ||
      endingMonth !== "" ||
      startingMonth !== "" ||
      endingYear !== "" ||
      startingYear !== "" ||
      details !== "" ||
      organization !== "" ||
      organizationName !== "" ||
      employment !== ""
    ) {
      $("#discardInfoModal-Experience").modal("show");
    }
  };

  const handleDisCard = () => {
    setId("");
    setDesignation("");
    setOrganization("");
    setDetails("");
    setOrganizationData("");
    setstartingYear("");
    setstartingMonth("");
    setendingYear("");
    setendingMonth("");
    setOptions([]);
    setOrganizationName("");
    setInstituteNameKey("");
    setInstituteNameKey(Math.ceil(Math.random() * 999999));
    setDesignationKey(Math.ceil(Math.random() * 999999));
    setDesignationOption("");
    setDesignationId("");
    setstudyingCurrently(false);
    setendingMonthError("");
    setstartingMonthError("");
    setendingYearError("");
    setstartingYearError("");
    setDetailsError("");
    setOrganizationError("");
    setDesignationError("");
    $("#discardInfoModal-Experience").modal("hide");
    $("#Experiencemodal").modal("hide");
  };

  const handleModel = () => {
    $("#discardInfoModal-Experience").modal("hide");
    $("#Experiencemodal").modal("show");
  };

  const handleValidation = (e, value, field) => {
    e.preventDefault();
    if (!value && field === "designation") {
      setDesignationError("Please enter designation ");
    } else if (value && field === "designation") {
      setDesignationError("");
    }
    if (!value && field === "organization") {
      if (!organizationData) {
        setOrganizationError("Please enter organization");
      } else {
        setOrganizationError("");
      }
    } else if (value && field === "organization") {
      setOrganizationError("");
    }
    if (!value && field === "details") {
      setDetailsError("Please enter details");
    } else if (value && field === "details") {
      setDetailsError("");
    }
    if (!value && field === "startingYear") {
      setstartingYearError("Please enter starting year");
    } else if (value && field === "startingYear") {
      setstartingYearError("");
    }
    if (!value && field === "startingMonth") {
      setstartingMonthError("Please enter starting month");
    } else if (value && field === "startingMonth") {
      setstartingMonthError("");
    }
    if (!value && field === "endingYear") {
      setendingYearError("Please enter ending year");
    } else if (value && field === "endingYear") {
      setendingYearError("");
    }
    if (!value && field === "endingMonth") {
      setendingMonthError("Please enter ending month");
    } else if (value && field === "endingMonth") {
      setendingMonthError("");
    }
    if (!value && field === "Employment") {
      setEmploymentError("Please select employment type");
    } else if (value && field === "Employment") {
      setEmploymentError("");
    }
  };

  const RenderDesignation = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-designation"
        defaultInputValue={designation}
        key={designationKey}
        onSearch={handleOnSearchDesignation}
        onChange={handleOnSelectDesignation}
        onBlur={(e) => {
          handleValidation(e, e.target.value, "designation");
        }}
        options={designationOption}
        placeholder="For Ex: Manager"
        labelKey="name"
        minLength={1}
        emptyLabel={"Searching..."}
        className={
          designationError ? "is-danger text-gray border" : " text-gray border"
        }
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [designation, designationOption, designationError, designationKey]);

  const RenderInstituteName = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example"
        defaultInputValue={organizationName}
        key={instituteNameKey}
        onSearch={handleOnSearch}
        onChange={handleOnSelect}
        onBlur={(e) => {
          handleValidation(e, e.target.value, "organization");
        }}
        // options={options}
        options={options.map((option) => ({
          ...option,
          name: option.name || "Unnamed", // Ensuring there's always a name property
        }))}
        placeholder="For Ex: Reliance Industries"
        labelKey="name"
        minLength={1}
        emptyLabel={"Searching..."}
        className={
          organizationError ? "is-danger text-gray border" : " text-gray border"
        }
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <img
              alt={option.name}
              src={
                option.image
                  ? Endpoints.baseUrl + option.image
                  : "../assets/img/icon/briefcase.png"
              }
              className="rounded-circle"
              style={{
                height: "35px",
                marginRight: "10px",
                width: "35px",
              }}
            />
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [organizationName, options, organizationError, instituteNameKey]);

  return (
    <>
      <div className="academic-section bg-white mb-4 p-3 rounded-15">
        <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-md-5 mb-4">
          <h4 className="medium fs-18">Experience</h4>
          {username === param?.id || userId === param?.id ? (
            <Link
              className="text-brown fs-12 medium"
              data-bs-toggle="modal"
              data-bs-target="#Experiencemodal"
            >
              Add{" "}
              <img src="../assets/img/icon/edit-plus.svg" alt="" width="12" />
            </Link>
          ) : (
            ""
          )}
        </div>
        {experience.length > 0 ? (
          experience.map((item, index) => {
            return (
              <div className="row mb-4">
                <div className="col-md-3 pe-md-0 d-flex">
                  <div className="image-section text-center w-100 d-flex align-items-center justify-content-center flex-column">
                    {item?.page?.pageImage === null ||
                    item?.page === null ||
                    item?.page?.pageImage === "undefined" ? (
                      <img
                        src="../assets/img/icon/briefcase.png"
                        className="mb-2 rounded-circle"
                        width="40"
                        height="40"
                        alt=""
                      />
                    ) : (
                      <img
                        src={Endpoints.baseUrl + item?.page?.pageImage}
                        className="mb-2 rounded-circle"
                        width="96"
                        height="96"
                        alt=""
                      />
                    )}
                    <h4 className="fs-14 text-darkgray pb-1">
                      {item?.startingYear}{" "}
                      <img
                        src="../assets/img/icon/icon-separator.svg"
                        alt=""
                        width="15"
                      />
                      {/* {item?.startingMonth} */}
                      {item?.workingCurrently === true
                        ? " Present"
                        : item?.endingYear}
                    </h4>
                    <h4 className="fs-14 text-darkgray">
                      {getYear(
                        item?.startingMonth,
                        item?.startingYear,
                        item?.endingMonth,
                        item?.endingYear
                      )}
                    </h4>
                  </div>
                </div>
                <div className="col-md-9 ps-md-0 d-flex">
                  <div className="content-section w-100">
                    <div className="d-flex justify-content-between">
                      <h4 className="fs-18 text-blue pb-1">
                        {item?.designation?.name}
                      </h4>
                      {username === param?.id || userId === param?.id ? (
                        <div className="position-relative">
                          <Link
                            id="notification"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <svg
                              viewBox="0 0 24 24"
                              width="22"
                              height="22"
                              stroke="#000"
                              strokeWidth="2"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="css-i6dzq1"
                            >
                              <circle cx="12" cy="12" r="1"></circle>
                              <circle cx="19" cy="12" r="1"></circle>
                              <circle cx="5" cy="12" r="1"></circle>
                            </svg>
                          </Link>
                          <div
                            className="dropdown-menu haspopup fs-12 dropdown-menu-end"
                            aria-labelledby="notification"
                          >
                            <ul>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={() => {
                                    setItem(item.id);
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteInfoModal-Experience"
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="14"
                                    height="14"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="css-i6dzq1"
                                  >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                    <line
                                      x1="10"
                                      y1="11"
                                      x2="10"
                                      y2="17"
                                    ></line>
                                    <line
                                      x1="14"
                                      y1="11"
                                      x2="14"
                                      y2="17"
                                    ></line>
                                  </svg>
                                  &nbsp; Delete
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={() => handleEditAcademics(item)}
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="14"
                                    height="14"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="css-i6dzq1"
                                  >
                                    <polyline points="20 6 9 17 4 12"></polyline>
                                  </svg>
                                  &nbsp; Edit
                                </Link>
                              </li>
                              {/* <li><Link className="dropdown-item" to="#">
                                                        <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line></svg>
                                                        Restricted
                                                    </Link></li> */}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <h5 className="text-brown fs-16 pb-3">
                      {item.organization}
                    </h5>
                    <p className="fs-16 mb-0">{item.details}</p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="mb-4">
            No experience found for your account. Please click on the Add button
            to create it.
          </div>
        )}
        <div
          className="modal fade"
          id="Experiencemodal"
          tabIndex="-1"
          aria-labelledby="Interestsmodal"
          aria-hidden="true"
          data-bs-keyboard="false"
          data-bs-backdrop="static"
        >
          <div className="modal-dialog rounded-10 box-shadow p-lg-2 p-md-2 p-2 bg-white modal-650p">
            <div className="modal-content border-0">
              <div className="d-flex align-items-center justify-content-between pb-3 border-bottom mb-3 w-100">
                <h3 className="medium text-blue">
                  {" "}
                  {id ? "Update" : "Add"} Experience
                </h3>
                <button
                  type="button"
                  className="btn-close kids-close-icon experience cursor-pointer"
                  onClick={handleCloseModel}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="#949494"
                    width="22"
                    height="22"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div className="modal-body p-0">
                <form
                  onSubmit={handleSubmit}
                  className="kids-form generic-form"
                  id="experiencemyform"
                >
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label>Designation</label>
                      {/* <input type="text"
                                                placeholder="Designation"
                                                onBlur={(e) => { handleValidation(e, e.target.value, "designation") }}
                                                className={designationError ?
                                                    "is-danger border"
                                                    :
                                                    "border"
                                                }
                                                value={designation}
                                                onChange={(e) => {
                                                    setDesignation(e.target.value)
                                                }}
                                            /> */}
                      {RenderDesignation()}
                      {designationError && (
                        <div className="form-error">{designationError}</div>
                      )}
                    </div>

                    <div className="col-md-12 mb-3">
                      <label>Organization</label>

                      {RenderInstituteName()}
                      {organizationError && (
                        <div className="form-error">{organizationError}</div>
                      )}
                    </div>

                    <div className="col-md-12 mb-3 ">
                      <label>Employment Type</label>
                      <select
                        value={employment}
                        onChange={(e) => {
                          setEmployment(e.target.value);
                        }}
                        onBlur={(e) => {
                          handleValidation(e, e.target.value, "Employment");
                        }}
                        className={
                          employmentError ? "is-danger border" : "border"
                        }
                      >
                        <option value={""}>Please select</option>
                        {EmploymentType.map((item, i) => {
                          return (
                            <option
                              selected={
                                employment === item.value ? true : false
                              }
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                      {employmentError && (
                        <div className="form-error">{employmentError}</div>
                      )}
                    </div>

                    <div className="col-md-12 mb-3">
                      {/* <label>Description  {details.length}/5000</label> */}
                      <div className="d-flex justify-content-between">
                        <label>Description </label>
                        <small className="text-end">
                          {details.length}/5000
                        </small>
                      </div>
                      <textarea
                        placeholder="Description"
                        maxLength={5000}
                        value={details}
                        onBlur={(e) => {
                          handleValidation(e, e.target.value, "details");
                        }}
                        className={detailsError ? "is-danger border" : "border"}
                        onChange={(e) => {
                          setDetails(e.target.value);
                        }}
                      ></textarea>

                      {detailsError && (
                        <div className="form-error">{detailsError}</div>
                      )}
                      {/* { "Remaining " + parseInt(parseInt(100) - parseInt(details.length) )+ " characters of 100 characters"} */}
                    </div>

                    <div className="col-md-6 mb-3 ">
                      <label>Starting Year</label>
                      <select
                        onChange={(e) => {
                          handleStartingYear(e.target.value);
                          setstartingYear(e.target.value);
                          setYear(e.target.value);
                        }}
                        onBlur={(e) => {
                          handleValidation(e, e.target.value, "startingYear");
                        }}
                        className={
                          startingYearError ? "is-danger border" : "border"
                        }
                      >
                        <option value={""}>Select Starting Year</option>
                        {TotalyearData.map((item, i) => {
                          return (
                            <option
                              selected={
                                Math.round(startingYear) === item.value
                                  ? true
                                  : false
                              }
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                      {startingYearError && (
                        <div className="form-error">{startingYearError}</div>
                      )}
                    </div>

                    <div className="col-md-6 mb-3 ">
                      <label>Starting Month</label>
                      <select
                        onChange={(e) => {
                          setstartingMonth(e.target.value);
                        }}
                        onBlur={(e) => {
                          handleValidation(e, e.target.value, "startingMonth");
                        }}
                        className={
                          startingMonthError ? "is-danger border" : "border"
                        }
                      >
                        <option value={""}>Select Starting Month</option>

                        {pastMonths.map((item) => {
                          return (
                            <option
                              selected={
                                startingMonth === item.value ? true : false
                              }
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                      {startingMonthError && (
                        <div className="form-error">{startingMonthError}</div>
                      )}
                    </div>

                    {studyingCurrently === false ? (
                      <div className="col-md-6 mb-3">
                        <label>Ending Year</label>
                        <select
                          onChange={(e) => {
                            setendingYear(e.target.value);
                            setEndYear(e.target.value);
                          }}
                          onBlur={(e) => {
                            handleValidation(e, e.target.value, "endingYear");
                          }}
                          className={
                            endingYearError ? "is-danger border" : "border"
                          }
                        >
                          <option value={""}>Select Ending Year</option>
                          {TotalEndingyearData.map((item, i) => {
                            return (
                              <>
                                {item.value <= year ? (
                                  <option
                                    selected={
                                      Math.round(endingYear) === item.value
                                        ? true
                                        : false
                                    }
                                    value={item.value}
                                  >
                                    {item.label}
                                  </option>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}
                        </select>
                        {endingYearError && (
                          <div className="form-error">{endingYearError}</div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {studyingCurrently === false ? (
                      <div className="col-md-6 mb-3">
                        <label>Ending Month</label>
                        <select
                          onChange={(e) => {
                            setendingMonth(e.target.value);
                          }}
                          onBlur={(e) => {
                            handleValidation(e, e.target.value, "endingMonth");
                          }}
                          className={
                            endingMonthError ? "is-danger border" : "border"
                          }
                        >
                          <option value={""}>Select Ending Month</option>
                          {endingMonths.map((item) => {
                            return (
                              <option
                                selected={
                                  endingMonth === item.value ? true : false
                                }
                                value={item.value}
                              >
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                        {endingMonthError && (
                          <div className="form-error">{endingMonthError}</div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-md-12 mb-3">
                      <label>Working currently </label>
                      &nbsp; &nbsp;
                      {studyingCurrently === false ? (
                        <input
                          type="checkbox"
                          placeholder=""
                          className="checkbox"
                          onChange={(e) => {
                            setstudyingCurrently(true);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          placeholder=""
                          className="checkbox"
                          checked
                          onChange={(e) => {
                            setstudyingCurrently(false);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-12 text-end">
                      <button
                        type="submit"
                        disabled={isDisabled}
                        className="btn fs-14"
                      >
                        {" "}
                        {id ? "Edit" : "Create"} Experience{" "}
                        <img
                          src="../assets/img/icon/icon-right-arrow.svg"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeletePrompt
        type={"Experience"}
        item={item}
        deleteType={handleDeleteExperience}
      />
      <DiscardPopup
        {...{
          type: "Experience",
          onSuccess: handleModel,
          onDisCard: handleDisCard,
        }}
      />
    </>
  );
}
