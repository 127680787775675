import React, {useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Topbar from "./Topbar";
import { UpdateAPi } from "../../../API/UpdateApi";
import { DeleteApi } from "../../../API/DeleteApi";
import { FetchApi } from "../../../API/FetchApi";
import { Endpoints } from "../../../API/Endpoints";
import ToastMessage from "../../../Utils/ToastMessage";
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import Swal from "sweetalert2";
import {Helmet} from "react-helmet";
export default function PublishCourse() {
    const param = useParams();
    const navigate = useNavigate();
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [congratulationMessage, setCongratulationMessage] = useState('');
    const [Instructors, setInstructors] = useState([]);
    const [InstructorsValue, setInstructorsValue] = useState('');
    const user = JSON.parse(localStorage.getItem('user'));
    const [InstructorsData, setInstructorsData] = useState([{
        id:user.id,
        image:localStorage.getItem('userProfile'),
        usersType: user.usersType,
        name:user.fullName
    }]);
    const [priceType, setPriceType] = useState('Paid');

    const [price, setPrice] = useState('');
    const [discountedPrice, setDiscountedPrice] = useState('');

    const [welcomeMessageError, setWelcomeMessageError] = useState('');
    const [congratulationMessageError, setCongratulationMessageError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [discountedPriceError, setDiscountedPriceError] = useState('');
    const [InstructorsError, setInstructorsError] = useState();
    const [isDisabled, setIsDisabled] = useState(true);
    const [Search, setSearch] = useState('');
    const [InstructorsKey, setInstructorsKey] = useState('InstructorsKey');

    useEffect(() => {
        if (welcomeMessage !== "" &&
            congratulationMessage !== "" &&
            InstructorsData.length > 0 
            ) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true)
        }

    }, [welcomeMessage, congratulationMessage, InstructorsData.length, discountedPrice, price])
    useEffect(() => {
        GetCoursePublish();
    }, []);

    const GetCoursePublish = async () => {
        try {
            let resp = await FetchApi(Endpoints.GetCoursePublish + "/" + param.id);
            if (resp && resp.success === true) {
                setDiscountedPrice(resp.data.discountedPrice);
                setPrice(resp.data.price);
                setCongratulationMessage(resp.data.congratsMessage);
                setWelcomeMessage(resp.data.welcomeMessage);
                setInstructorsValue("");
                setInstructorsKey(Math.ceil(Math.random()*999999));
                const newData = resp.data.instructorslist.map((item, index) => {
                    return ({
                        id: item.id,
                        image: item?.instructorImage,
                        name: item?.fullName,
                        usersType: item.usersType

                    });
                })
                if(newData.length > 0){
                    setInstructorsData(newData);
                }
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.')
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message)
                }

            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let status;
        Swal.fire({
            text: "Are you sure you want to publish this course ?",
            icon: "question",
            customClass: {
              confirmButton: "btn px-3 mx-2",
              cancelButton: "btn btn-br-blue px-3",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }).then((result) => {
            if (result.isConfirmed) {
                handlePubliceCourse();
            }
        });

    };

    const handlePubliceCourse = async()=>{
        if (!welcomeMessage) {
            setWelcomeMessageError("Please enter welcome message");
        } else if (welcomeMessage) {
            setWelcomeMessageError("");
        }
        if (!congratulationMessage) {
            setCongratulationMessageError("Please enter congratulation message");
        } else if (congratulationMessage) {
            setCongratulationMessageError("");
        }
        if(priceType === 'Free'){
            if (!price) {
                setPriceError("Please enter course price");
            } else if (price) {
                setPriceError("");
            }
            if (!discountedPrice) {
                setDiscountedPriceError("Please enter course discounted price");
            } else if (discountedPrice) {
                setDiscountedPriceError("");
            }
        }
        if(priceType === 'Paid'){
            if(price === 0){
                setPriceError("Please enter course price grater then 0");
                return false;
            }else{
                setPriceError("");
            }
            if(price < discountedPrice){
                setDiscountedPriceError("discounted price  will always be less than price");
            }else if (price > discountedPrice) {
                setDiscountedPriceError("");
            }
        }

        if (InstructorsData.length === 0) {
            setInstructorsError("Please add instructors");
        } else if (InstructorsData.length > 0) {
            setInstructorsError("");
        }
       
        if(welcomeMessage && congratulationMessage  && InstructorsData.length > 0 ){
        const newInstructorsData = InstructorsData.map((item) => {
            return (
                item.id
            );
        })
        let data = '';
        if(priceType === 'Free'){
            data = {
                welcome_message: welcomeMessage,
                congrats_message: congratulationMessage,
                status: 1,
                course_type:priceType,
                instructor_id: newInstructorsData,
            }
        }else{
            data = {
                welcome_message: welcomeMessage,
                congrats_message: congratulationMessage,
                price: price ? price : 0,
                status: 1,
                course_type:priceType,
                discounted_price: discountedPrice ? discountedPrice : 0,
                instructor_id: newInstructorsData,
            }
        }
        try {
            let resp = await UpdateAPi(Endpoints.coursePublish + "/" + param.id, data);
            if (resp && resp.success === true) {
                navigate("/mycourses");
                ToastMessage.Success(resp.message)
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {

                    ToastMessage.Error('Session Expired! Please login again.')
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message)
                }

            }
        }
    }
    };

    const handleSave = async (type) => {
       
        if (!welcomeMessage) {
            setWelcomeMessageError("Please enter welcome message");
        } else if (welcomeMessage) {
            setWelcomeMessageError("");
        }
        if (!congratulationMessage) {
            setCongratulationMessageError("Please enter congratulation message");
        } else if (congratulationMessage) {
            setCongratulationMessageError("");
        }
        if(priceType === 'Free'){
            if (!price) {
                setPriceError("Please enter course price");
            } else if (price) {
                setPriceError("");
            }
            if (!discountedPrice) {
                setDiscountedPriceError("Please enter course discounted price");
            } else if (discountedPrice) {
                setDiscountedPriceError("");
            }
        }
        if(priceType === 'Paid'){
            if(price < discountedPrice){
                setDiscountedPriceError("discounted price  will always be less than price");
            }else if (price > discountedPrice) {
                setDiscountedPriceError("");
            }
        }

        
       

     
        if (InstructorsData.length === 0) {
            setInstructorsError("Please add instructors");
        } else if (InstructorsData.length > 0) {
            setInstructorsError("");
        }
       
        
        if(welcomeMessage && congratulationMessage && price && discountedPrice && InstructorsData.length > 0 &&  price > discountedPrice){

        const newInstructorsData = InstructorsData.map((item) => {
            return (
                item.id
            );
        })
        let data = {
            welcome_message: welcomeMessage,
            congrats_message: congratulationMessage,
            price: price,
            status: 1,
            discounted_price: discountedPrice,
            instructor_id: newInstructorsData,

        }
        try {
            let resp = await UpdateAPi(Endpoints.coursePublish + "/" + param.id, data);
            if (resp && resp.success === true) {
                if (type === "close") {
                    navigate("/mycourses");
                }

                if (type === "preview") {
                    // navigate("/course-preview" + '/' + param.id);
                    window.open("/course-preview/" + param.id, '_blank');
                }
                ToastMessage.Success(resp.message)
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {

                    ToastMessage.Error('Session Expired! Please login again.')
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message)
                }

            }
        }
    }
    };



    const handleOnSearch = async (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        setSearch(string);
        if (string) {
        try {
            let resp = await FetchApi(Endpoints.SearchInstructor + "?identity=" + string);
            if (resp && resp.success === true) {
                const newData = resp.data.map((item, index) => {
                    return ({
                        id: item.id,
                        image: item?.user_profile,
                        name: item?.fullName,
                        usersType: item.usersType,
                        username : item.username,
                    });
                })
                setInstructors(newData);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {

                    ToastMessage.Error('Session Expired! Please login again.')
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message)
                }

            }
        }
    }
    };

    const handleOnSelect = (item) => {
        if(item.length > 0){
        if (InstructorsData.filter((ite, index) => ite.id === item[0].id).length > 0) {
            ToastMessage.Info("Already added this intructor");
        } else {
            setInstructorsData([...InstructorsData, ...item]);
        }

        setInstructorsValue("");
        setInstructorsKey(Math.ceil(Math.random()*999999));
        }
    };

    const handleDeleteInstructor = async (id) => {
        const newsetInstructorsData = InstructorsData.filter((item, index) => item.id !== id);
        setInstructorsData(newsetInstructorsData);

        try {
            let resp = await DeleteApi(Endpoints.instructorDelete + "/" + param.id + "/" + id);
            if (resp && resp.success === true) {
                ToastMessage.Success(resp.message)
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {

                    ToastMessage.Error('Session Expired! Please login again.')
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message)
                }

            }
        }

    };

  

    const handleValidation = async (e, value, field) => {
        e.preventDefault();
        if (!value && field === "welcomeMessage") {
            setWelcomeMessageError("Please enter welcome message");
        } else if (value && field === "welcomeMessage") {
            setWelcomeMessageError("");
        }
        if (!value && field === "congratulationMessage") {
            setCongratulationMessageError("Please enter congratulation message");
        } else if (value && field === "congratulationMessage") {
            setCongratulationMessageError("");
        }
        if (!value && field === "price") {
            setPriceError("Please enter course price");
        } else if (value && field === "price") {
            setPriceError("");
        }
        if (!value && field === "discountedprice") {
            setDiscountedPriceError("Please enter course discounted price");
        } else if (value && field === "discountedprice") {
            setDiscountedPriceError("");
        }

        console.log("instructors", value)
        if (!value && field === "instructors") {
            setInstructorsError("Please add instructors");
        } else if (value && field === "instructors") {
            setInstructorsError("");
        }




    };

    const handleValidationInstructors = (item) => {
        console.log('blur', item)
    };

    const RenderInstrctor = useCallback(() => {
        return (
            <AsyncTypeahead
                id="async-pagination-example"
                defaultInputValue={InstructorsValue}
                key={InstructorsKey}
                options={Instructors}
                labelKey="name"
                minLength={1}
                emptyLabel={"Searching..."}
                onSearch={handleOnSearch}
                onChange={handleOnSelect}
                onBlur={handleValidationInstructors}
                placeholder="Search by Username, Full Name, or Email"
                className={InstructorsError ? "is-danger bg-transparent fs-14 medium w-100 rounded-3 border" : "bg-transparent fs-14 medium w-100 rounded-3 border"}
                                      
                renderMenuItemChildren={(option, props) => (
                    <div key={option.id}>
                        <img
                            alt={option.name}
                            src={option.image ? Endpoints.baseUrl + option.image : "../assets/img/icon/briefcase.png"}
                            style={{
                                height: '24px',
                                marginRight: '10px',
                                width: '24px',
                            }}
                        />
                        <Highlighter search={props.text}>
                            {option[props.labelKey]}
                        </Highlighter>
                        ({option.username})
                    </div>

                )}

                useCache={false}
            />
        )
    }, [ Instructors, InstructorsError, InstructorsKey])


    return (


        <Container
            header
        >
              <Helmet>
          <title>Publish Course</title>
      </Helmet>
            <div classNameName="wrapper wrapper-bg">
                <div classNameName="main">
                    <LeftNavigation />
                    <div className="container py-3">
                        <div className="bg-white main-common p-0 py-3">
                            <Topbar />

                            <div className="border-bottom py-lg-4 py-3 px-lg-4 px-3">
                                <div className="d-sm-flex align-items-center justify-content-between">
                                    <h2>Publish Course</h2>
                                    <div className="save-close-btns">
                                        <a href="#" onClick={() => {
                                            handleSave("close")
                                        }} class="btn btn-br-blue py-2">Save & Close</a>
                                        <a href="#" onClick={() => {
                                            handleSave("preview")
                                        }} class="btn btn-br-blue ms-3 py-2">Save & Preview</a>
                                    </div>
                                </div>
                            </div>


                            <form onSubmit={handleSubmit} className="teacher-form generic-form">

                                <div className="p-lg-4 p-3">
                                    <div>
                                        <label for="" className="d-block pb-2 medium text-black fs-18">Welcome Message</label>
                                        <textarea
                                            name=""
                                            id=""
                                            cols="30"
                                            rows="10"
                                            onBlur={(e) => { handleValidation(e, e.target.value, "welcomeMessage") }}
                                            className={welcomeMessageError ? "is-danger border rounded-3 p-2 pb-0 text-lightgray" : "border rounded-3 p-2 pb-0 text-lightgray"}
                                            placeholder="Write welcome message for students"
                                            value={welcomeMessage}
                                            onChange={(e) => {
                                                setWelcomeMessage(e.target.value)
                                            }}
                                        ></textarea>
                                        {welcomeMessageError ?
                                            <div className="form-error" 
                                               style={{ color: "white", margintop: "-10px", marginBottom: "-10px" }}>
                                                {welcomeMessageError}
                                            </div>
                                            : ""}
                                    </div>

                                    <div className="mt-4">
                                        <label for="" className="d-block pb-2 medium text-black fs-18">Congratulation Message</label>
                                        <textarea
                                            name=""
                                            id=""
                                            cols="30"
                                            rows="10"
                                            onBlur={(e) => { handleValidation(e, e.target.value, "congratulationMessage") }}
                                            className={congratulationMessageError ? "is-danger border rounded-3 p-2 pb-0 text-lightgray" : "border rounded-3 p-2 pb-0 text-lightgray"}
                                            placeholder="Write congratulation message for student once they complete the course"
                                            value={congratulationMessage}
                                            onChange={(e) => {
                                                setCongratulationMessage(e.target.value)
                                            }}
                                        ></textarea>
                                        {congratulationMessageError ?
                                            <div className="form-error" style={{ color: "white", margintop: "-10px", marginBottom: "-10px" }}>
                                                {congratulationMessageError}
                                            </div>
                                            : ""}
                                    </div>
                                    <div className="mt-4">
                                        {priceType === 'Paid' ?
                                            <input type="checkbox" 
                                            onChange={(e) => {
                                                setPriceType('Free')
                                            }}
                                            /> 
                                            : 
                                            <input 
                                                type="checkbox" 
                                                checked
                                                onChange={(e) => {
                                                    setPriceType('Paid')
                                                }}
                                            /> 
                                        } Do you want to post this Course as FREE ?
                                    </div>
                                    {priceType === 'Paid' ?
                                    <>
                                    <div className=" mt-4">
                                        <label for="" className="d-block pb-2 medium text-black fs-18">Course Price</label>
                                        <div className="border rounded-3 p-2 d-flex align-items-center justify-content-between gap-2">
                                        <span class="text-gray" >₹</span>
                                        <input type="number"
                                            className={priceError ? "is-danger bg-transparent border-0 w-100 p-0" : "bg-transparent border-0 w-100 p-0"}
                                            onBlur={(e) => { handleValidation(e, e.target.value, "price") }}
                                            placeholder="Course Price"
                                            value={price}
                                            onChange={(e) => {
                                                setPrice(e.target.value)
                                            }}
                                        />
                                        </div>
                                        {priceError ?
                                            <div className="form-error" style={{ color: "white", margintop: "-10px", marginBottom: "-10px" }}>
                                                {priceError}
                                            </div>
                                            : ""}
                                    </div>
                                    <div className="mt-4">
                                        <label for="" className="d-block pb-2 medium text-black fs-18">Course Discounted Price</label>
                                        <div className="border rounded-3 p-2 d-flex align-items-center justify-content-between gap-2">
                                        <span class="text-gray" >₹</span>
                                        <input type="number"
                                            className={discountedPriceError ? "is-danger bg-transparent border-0 w-100 p-0" : "bg-transparent border-0 w-100 p-0"}
                                            placeholder="Course Discounted Price"
                                            value={discountedPrice}
                                            onBlur={(e) => { handleValidation(e, e.target.value, "discountedprice") }}
                                            onChange={(e) => {
                                                setDiscountedPrice(e.target.value)
                                            }}
                                        />
                                        </div>
                                        {discountedPriceError ?
                                            <div className="form-error" style={{ color: "white", margintop: "-10px", marginBottom: "-10px" }}>
                                                {discountedPriceError}
                                            </div>
                                            : ""}
                                    </div>
                                    </>
                                    :""}

                                </div>

                                <div className="p-lg-4 p-3 border-top">
                                    <label for="" className="d-block pb-2 medium text-black fs-18">Add Instructors</label>

                                   

                                        {RenderInstrctor()}

                                    
                                    {InstructorsError ?
                                        <div className="form-error" style={{ color: "white", margintop: "-10px", marginBottom: "-10px" }}>
                                            {InstructorsError}
                                        </div>
                                        :
                                        ""
                                    }



                                    <div className="row ">

                                        {InstructorsData.map((item, index) => {
                                            return (
                                                <div className="col-md-4 py-3">
                                                    <div className="instructor-item d-flex align-items-center gap-2 w-100 p-3">
                                                        {/* <img src="../assets/img/profile2.png" alt="" width="48" height="48" className="rounded-circle" /> */}
                                                        {item.image === null || item.image === '' ?
                                                            <img src="../assets/img/profile2.png" alt="" width="30" height="30" className="rounded-circle" />
                                                            :
                                                            <img src={Endpoints.baseUrl + item.image} alt="" width="30" height="30" className="rounded-circle" />

                                                        }
                                                        <div>
                                                            <h6 className="medium text-black fs-16">{item.name}</h6>
                                                            <h6 className="fs-12 regular text-darkgray">{item.usersType}</h6>

                                                        </div>
                                                        <Link onClick={() => {
                                                            handleDeleteInstructor(item.id)
                                                        }} className="ms-auto">
                                                            <img src="../assets/img/icon/icon-close-cancel.svg" alt="" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}



                                    </div>
                                </div>
                                <div className="p-3 d-flex align-items-center justify-content-between border-top">
                                    <Link to={"/add-courses-curriculum/" + param.id} className="btn light-brown-btn medium  fs-14"><img src="../assets/img/icon/arrow-left-brown.svg" className="pe-1" alt="" /> Previous</Link>
                                    <button disabled={isDisabled} type="submit" className="btn  border-0 medium fs-14">Publish Course <img src="../assets/img/icon/icon-right-arrow.svg" className="ps-1" alt="" /></button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
                <OnlineFriends />
            </div>
        </Container>
    )
}
