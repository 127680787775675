import React, { useEffect, useState, useRef } from "react";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { CommonAPi } from '../../API/CommonApi';
import PostReactions from "../../Components/PostReactions/PostReactions";
import { Link, useNavigate, useParams } from "react-router-dom";
import SingleImagePostmodal from '../../Components/SingleImagePstmodal/SingleImagePostmodal';
const postReactions = [
    { id: "Like", img: "../assets/img/icon/Like.svg" },
    { id: "Love", img: "../assets/img/icon/Love.svg" },
    { id: "Haha", img: "../assets/img/icon/Haha.svg" },
    { id: "Care", img: "../assets/img/icon/Care.svg" },
    { id: "Wow", img: "../assets/img/icon/Wow.svg" },
    { id: "Sad", img: "../assets/img/icon/Sad.svg" },
    { id: "Angry", img: "../assets/img/icon/Angry.svg" },
];

export default function PhotosItem({ item, index, post }) {
    const [postComments, setPostComments] = useState([]);
    const [currentPostId, setCurrentPostId] = useState(null);
    const [clickedImg, setClickedImg] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentPostImages, setCurrentPostImages] = useState([]);
    const [rootComments, setRootComments] = useState([]);
    const [sharePost, setSharePost] = useState(null);
    const [modalState, setModalState] = useState("hide");
    const [totalPostReactions, setTotalPostReactions] = useState([]);
    const [reactionModalOpen, setReactionModalOpen] = useState("hide");

    const [timelinePosts, setTimelinePosts] = useState([]);
    const [postPage, setPostPage] = useState(1);
    const [mouserHovering, setMouserHovering] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasMorePosts, setHasMorePosts] = useState(false);
    const [page, setPage] = useState(1);
    const [mediaComments, setMediaComments] = useState([]);
    const [inputValue, setInputValue] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [likeCount, setLikeCount] = useState(post.totalReactions);
    const [selectedReaction, setSelectedReaction] = useState(post.userReaction);
    const [postSaved, setPostSaved] = useState(post.isSaved);
    const [postSavedCounter, setPostSavedCounter] = useState(post.totalSaves);
    const [commentCounter, setCommentCounter] = useState(post.totalComments);
    const [shareCounter, setShareCounter] = useState(post.totalShares);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const history = useNavigate();

    const [reactionModalVar, setReactionModalVar] = useState(true);
    const [vControl, setVControl] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const param = useParams();


    const showReactionsBox = (event) => {
        setMouserHovering(true);
    };
    const hideReactionsBox = (event) => {
        setTimeout(() => {
            setMouserHovering(false);
        }, 1400);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const timeSince = (date, isCurrent = false) => {
        if (isCurrent) return 0 + " sec";
        let seconds = Math.floor((new Date() - date) / 1000);
        let interval = seconds / 31536000;

        if (interval > 1) return Math.floor(interval) + "y";
        interval = seconds / 2592000;

        if (interval > 1) return Math.floor(interval) + " mon";
        interval = seconds / 86400;

        if (interval > 1) return Math.floor(interval) + "d";
        interval = seconds / 3600;

        if (interval > 1) return Math.floor(interval) + "h";
        interval = seconds / 60;

        if (interval > 1) return Math.floor(interval) + " min";
        return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec" : "0 sec";
    };

    function formatDateTime(postDate) {
        let date = new Date(postDate);
        let options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        let formattedDate = date.toLocaleDateString('en-US', options)
        return formattedDate;

    }

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [allShow, setAllShow] = useState([]);
    const getSinglePostData = async () => {
        let id = post.id;
        try {
            let resp = await CommonAPi(Endpoints.GetSinglePost + id);
            if (resp && resp.success === true) {
                setAllShow([...resp.data.post_medias]);
                console.log("setAllShow data", allShow);
                // setAllShow(resp.data);
                // console.log("setAllShow data", allShow)
            }
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error("Session Expired! Please login again.");
                    localStorage.clear();
                    history("/signin");
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }
    };



    let selectpostArr = allShow;


    useEffect(() => {
        if (reactionModalVar) allReactions(currentPostId);
    }, [page, param?.id]);


    useEffect(() => {
        setRootComments(
            postComments.filter((comment) => {
                return comment.commentId == "0";
            })
        );
    }, [postComments, param?.id]);

    const getUserPosts = async (createPage = null) => {
        if (!hasMorePosts) return;
        try {
            let resp = await CommonAPi(Endpoints.GetFeedPosts + `?page=${postPage}`);
            if (resp && resp.success === true) {
                if (resp.data.length === 0) {
                    setLoading(false);
                    setHasMorePosts(false);
                } else {
                    if (createPage == null) {
                        setTimelinePosts([...timelinePosts, ...resp.data]);
                    } else {
                        setTimelinePosts(resp.data);
                    }
                }
                console.log(timelinePosts);
                setLoading(false);
            }
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    history('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };

    const handlePostReact = async (e, post_id, reaction_type) => {
        if (selectedReaction == null) {
            setLikeCount(likeCount + 1);
            setSelectedReaction(reaction_type);
        } else if (selectedReaction === reaction_type) {
            setSelectedReaction(null);
            setLikeCount(likeCount - 1);
        } else {
            setSelectedReaction(reaction_type);
        }
        try {
            let data = {
                post_id: String(post_id),
                reaction_type: reaction_type,
            };
            // alert("creating..")
            let resp = await CommonAPi(Endpoints.CreatePostReaction, data);
            if (resp && resp.success === true) {
                // getUserPosts();
            }
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error("Session Expired! Please login again.");
                    localStorage.clear();
                    history("/signin");
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }
    };


    // get post reactions
    const allReactions = async (id, reactionType = "") => {
        setModalState("hide");
        if (id == null) return;
        setCurrentPostId(id);
        try {
            let resp = await CommonAPi(
                Endpoints.GetReactionsOfAPost +
                id +
                `?page=${page}&reaction_type=${reactionType}`
            );
            if (resp && resp.success === true) {
                if (resp.data.length === 0) {
                    setLoading(false);
                    setHasMorePosts(false);
                }
                if (reactionType) setTotalPostReactions(resp.data);
                else {
                    setTotalPostReactions([...totalPostReactions, ...resp.data]);
                }
                setLoading(false);
            }
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error("Session Expired! Please login again.");
                    localStorage.clear();
                    history("/signin");
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }
    };

    const handleImgClick = (post, item, index) => {
        setCurrentPostImages(post);
        setCurrentIndex(index);
        setClickedImg(Endpoints.baseUrl + item.filePath);
    };

    const handleNextBtn = () => {
        const totalLength = currentPostImages.post_medias.length;
        if (currentIndex + 1 >= totalLength) {
            setCurrentIndex(0);
            const newUrl = currentPostImages.post_medias[0].filePath;
            setClickedImg(Endpoints.baseUrl + newUrl);
            return;
        }
        const newIndex = currentIndex + 1;
        const newUrl = currentPostImages.post_medias.filter((item) => {
            return currentPostImages.post_medias.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].filePath;
        setClickedImg(Endpoints.baseUrl + newItem);
        setCurrentIndex(newIndex);
        setMediaComments(null);
        setIsEditing(false);
        setInputValue(null);
    };

    const handlePrevBtn = () => {
        const totalLength = currentPostImages.post_medias.length;
        if (currentIndex === 0) {
            setCurrentIndex(totalLength - 1);
            const newUrl = currentPostImages.post_medias[totalLength - 1].filePath;
            setClickedImg(Endpoints.baseUrl + newUrl);
            return;
        }
        const newIndex = currentIndex - 1;
        const newUrl = currentPostImages.post_medias.filter((item) => {
            return currentPostImages.post_medias.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].filePath;
        setClickedImg(Endpoints.baseUrl + newItem);
        setCurrentIndex(newIndex);
        setMediaComments(null);
        setIsEditing(false);
        setInputValue(null);
    };



    useEffect(() => {
        setSharePost("");
        setReactionModalOpen("hide");
    }, []);

    return (
        <>
            <div className="col-6 mb-2 px-1" key={index}>
                <Link
                    // href={Endpoints.baseUrl + item.filePath}
                    // data-fancybox="photos-userid"
                    onClick={() => {
                        openModal();
                        handleImgClick(item.post, item, 0);
                        setIsLoading(true);
                    }}
                >
                    <img
                        src={Endpoints.baseUrl + item.filePath}
                        className="rounded-2"
                        alt=""
                        width="110"
                        height="110"
                    />
                </Link>
                <PostReactions
                handleReact={handlePostReact}
                postReactions={postReactions}
                id={post.id}
                isOpen={mouserHovering}
            />
            {isModalOpen && (
                <div >
                    <SingleImagePostmodal
                        closeModal={closeModal}
                        clickedImg={clickedImg}
                        handleNextBtn={handleNextBtn}
                        setClickedImg={setClickedImg}
                        handlePrevBtn={handlePrevBtn}
                        creator={post.postCreator}
                        title={post.title}
                        postImg={post.profileImg}
                        selectpostArr={selectpostArr}
                        mediaIndex={
                            allShow.length > 0 ? allShow[currentIndex] : null
                        }
                        totalShares={post.totalShares}
                        timeSince={timeSince}
                        formatDateTime={formatDateTime}
                        mouserHovering={mouserHovering}
                        showReactionsBox={showReactionsBox}
                        hideReactionsBox={hideReactionsBox}
                        setMediaComments={setMediaComments}
                        mediaComments={mediaComments}
                        postCreatedAt={post.createdAt}
                        post={post}
                        postReactions={postReactions}
                        getSinglePostData={getSinglePostData}
                        getUserPosts={getUserPosts}
                        setInputValue={setInputValue}
                        inputValue={inputValue}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        setLikeCount={setLikeCount}
                        setSelectedReaction={setSelectedReaction}
                        setMainPostSaved={setPostSaved}
                        setMainPostSavedCounter={setPostSavedCounter}
                        setCommentCounter={setCommentCounter}
                        setMainShareCounter={setShareCounter}
                        selectedReaction={selectedReaction}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        setIsModalOpen={setIsModalOpen}
                        setVControl={setVControl}
                        onSuccess={true}
                    />
                </div>
            )}
            </div>
           
        </>
    )
}
