import Swal from "sweetalert2";
import "./ToastMessage.css";

function Message({ type, text, title = false }) {
  if (type == "Success") {
    Swal.fire({
      text,
      iconHtml:
        '<svg xmlns="http://www.w3.org/2000/svg" width="100" height="80" viewBox="0 0 512 512" id="IconChangeColor"><title>ionicons-v5-e</title><path d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48ZM218,360.38,137.4,270.81l23.79-21.41,56,62.22L350,153.46,374.54,174Z" id="mainIconPathAttribute" fill="#2bc48b"></path></svg>',

      customClass: {
        popup: "swal-custom-zindex",
        icon: "no-border",
        confirmButton: "btn px-5",
      },
      buttonsStyling: false,
    });
  }

  if (type == "Register") {
    Swal.fire({
      title: "Congratulations! Your account has been successfully created.",
      text: "Please check your inbox, we have sent a verification link on your email. Please click on the link to verify your email",
      iconHtml:
        '<svg xmlns="http://www.w3.org/2000/svg" width="100" height="80" viewBox="0 0 512 512" id="IconChangeColor"><title>ionicons-v5-e</title><path d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48ZM218,360.38,137.4,270.81l23.79-21.41,56,62.22L350,153.46,374.54,174Z" id="mainIconPathAttribute" fill="#2bc48b"></path></svg>',
      customClass: {
        icon: "no-border",
        title: "custom-title-class",
        confirmButton: "btn px-5",
      },
      buttonsStyling: false,
      timer: 30000,
      timerProgressBar: true,
    });
  }
  if (type == "Report") {
    Swal.fire({
      title: "Thanks for reporting! <hr />",
      text: "Thank you for bringing that post to our attention. We will review this to improve our processes. We appreciate your help in keeping our platform safe and enjoyable for all users.",
      iconHtml:
        '<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25 0C11.2686 0 0 11.2686 0 25C0 38.7314 11.2686 50 25 50C38.7314 50 50 38.7314 50 25C50 11.2686 38.7314 0 25 0ZM21.9745 36.3711L11.0268 25.4234L15.1695 21.2807L22.1662 28.2773L36.2124 15.5086L40.1549 19.8431L21.9745 36.3711Z" fill="#18CB14"/></svg>',
      customClass: {
        icon: "no-border",
        title: "custom-title-class",
        confirmButton: "btn px-5",
      },
      confirmButtonText: "Done",
      buttonsStyling: false,
      timer: 5000,
      timerProgressBar: true,
    });
  }
  if (type == "Error") {
    Swal.fire({
      text,
      icon: "warning",
      customClass: {
        confirmButton: "btn px-5",
      },
      buttonsStyling: false,
    });
  }
  if (type == "Info") {
    Swal.fire({
      text,
      icon: "info",
      customClass: {
        confirmButton: "btn px-5",
      },
      buttonsStyling: false,
    });
  }
  // alert(message);
}
export default {
  Success: (text) => Message({ type: "Success", text }),
  Error: (text) => Message({ type: "Error", text }),
  Info: (text) => Message({ type: "Info", text }),
  Register: (title, text) => Message({ type: "Register", title, text }),
  Report: (title, text) => Message({ type: "Report", title, text }),
};

// export default ToastMessage;
