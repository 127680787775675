import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Container from '../../Components/Container';
import LeftNavigation from '../../Components/LeftNavigation';
import OnlineFriends from '../../Components/OnlineFriends';
import { Helmet } from "react-helmet";
const PaymentFailed = () => {

    const user = JSON.parse(localStorage.getItem("user"));
    const orderId = localStorage.getItem("orderId")

    return (
        <Container header>
            <Helmet>
                <title>Payment Failed</title>
            </Helmet>
            <div className="wrapper wrapper-bg">
                <div className="main">
                    <LeftNavigation />
                    <div className="container py-3 home ">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-8 col-sm-10 ">
                                <div className="bg-white rounded-10 box-shadow py-4 px-lg-5 px-3 text-center">
                                    <img src="assets/img/checkout-success.svg" alt="" className="pb-lg-4 pb-3 px-lg-4 px-5" />
                                    <img src="assets/img/icon/icon-check-round.svg" alt="" className="pb-lg-4 pb-3 " />
                                    <h1 className="medium pb-lg-3 pb-2">Payment Failed!</h1>
                                    <h4 className="medium pb-lg-3 pb-2">Payment has been failed due to unknown reason.</h4>
                                    <h3 className="medium pb-lg-3 pb-2">Order #: <span className="text-darkgray">{orderId}</span></h3>
                                    <p className="fs-14 text-gray pb-2">You can note this transaction id for future reference {user.email}</p>
                                    <div className="row justify-content-center pb-lg-3 pb-2">
                                        <div className="col-md-7">
                                            <Link to="/courses" className="btn py-2 px-3 mb-3 w-100">Go Back </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OnlineFriends />
                </div>
            </div>
        </Container>
    )
}
export default PaymentFailed