import React, { useEffect, useState } from 'react';
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate, useParams } from 'react-router-dom';
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import Sidebar from '../ProfileUpdate/Sidebar';
import ProfileImage from '../ProfileUpdate/ProfileImage';
import {Helmet} from "react-helmet";
export default function ProfileBlockedUser() {
    const [blockData, setBlockData] = useState([]);
    const [count, setCount] = useState(0);
    const param = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        getBlocked();
    }, [])

    const getBlocked = async () => {
        try {
            let resp = await FetchApi(Endpoints.GetBlockUser);
            if (resp && resp.success === true) {
                setBlockData(resp?.data?.user);
                setCount(resp?.data?.requestCount);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }

    };

    const handleUnblock = async(id) =>{
        let data = {
            blockedUser: id
        }
        try {
            let resp = await CommonAPi(Endpoints.BlockFriend, data);
            if (resp && resp.success === true) {
                getBlocked();
                ToastMessage.Success(resp.message);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }

    };

    const timeSince = (date, isCurrent = false) => {
        if (isCurrent) return 0 + " sec";
        let seconds = Math.floor((new Date() - date) / 1000);
        let interval = seconds / 31536000;

        if (interval > 1) return Math.floor(interval) + " year ago";
        interval = seconds / 2592000;

        if (interval > 1) return Math.floor(interval) + " mon ago";
        interval = seconds / 86400;

        if (interval > 1) return Math.floor(interval) + " days ago";
        interval = seconds / 3600;

        if (interval > 1) return Math.floor(interval) + " h ago";
        interval = seconds / 60;

        if (interval > 1) return Math.floor(interval) + " min ago";
        return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec ago" : "0 sec ago";
    };
    return (
        <Container
            header
        >
            <Helmet>
                <title>Blocked User</title>
            </Helmet>
            <div className="wrapper wrapper-bg">

                <div className="main">

                    <LeftNavigation />
                    <div class="container py-lg-4 py-3">

                        <ProfileImage />
                        <div class="row">
                            <Sidebar />

                            <div class="col-lg-9 col-md-8">
                                <div class="bg-white box-shadow rounded-15 p-md-3 p-2">
                                    <div class="pb-2 d-md-flex align-items-center justify-content-between">
                                        <h3 class="medium fs-20">Blocked <span class="text-gray">- {count}</span></h3>
                                    </div>

                                    <div class="row m-0 pt-3 gx-md-50">
                                        {blockData.map((item, index) => {
                                            return (
                                                <div class="col-md-6  ps-0" key={index}>
                                                    <div class="pb-3 d-flex align-items-start w-100">
                                                        {item?.user?.user_profile?.profileImg === null ? 
                                                            <img src="../assets/img/profile6.png" alt="" width="48" height="48" class="rounded-circle" />
                                                        :
                                                            <img src={Endpoints.baseUrl+ item?.user?.user_profile?.profileImg} alt="" width="48" height="48" class="rounded-circle" />
                                                        }
                                                        <div class="ps-2">
                                                            <h6 class="medium text-black">{item?.user?.fullName}</h6>
                                                            <h6 class="fs-12 py-1 regular text-darkgray">{item?.user_profile?.profileTagline}</h6>
                                                            <h6 class="fs-10 regular text-darkgray">{item.mutualFriends} mutual friends</h6>
                                                        </div>

                                                        <div class="ps-2 text-end">
                                                            <div class="w-100 pb-1">
                                                                <h6 class="fs-10 regular text-darkgray pe-2 text-nowrap">{timeSince(new Date(item?.createdAt))}</h6>
                                                            </div>

                                                            <Link onClick={()=>{
                                                                handleUnblock(item?.blockedUser)
                                                            }} class="btn butn-danger">Unblock</Link>

                                                        </div>
                                                    </div>
                                                </div>

                                            )
                                        })}

                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>

                    <OnlineFriends />
                </div>
            </div>
        </Container >
    )
}
