import React, {useState, useRef} from 'react'
import InputEmoji from "react-input-emoji";
import "./MediaCommentReplyBox.css"

const MediaCommentreplyBox = ({handleSubmit,replyRef, initalText=""}) => {
  const [text, setText] = useState(initalText)
  const[commentMedia, setCommentMedia] = useState("");
  const commentMediaRef = useRef();
  const submitHandler = (e)=>{
    e.preventDefault();   
     if(text === "" && !commentMedia) return; 
    handleSubmit(text, commentMedia);
    setText('');
  }
  const handleCommentImageSelection = (e) => {
      console.log(e.target.files[0]);
      let image = e.target.files[0];
      if(image.type === "image/png" || image.type === "image/jpg" || image.type === "image/jpeg"){
          setCommentMedia(image);
      }      
  }
    const handleCommentChange = ()=>{        
        setText(text)
         if(text === "" && !commentMedia) return;
      handleSubmit(text, commentMedia);
    }
  return (
      <div className={`feed-comment-add pt-3`} onSubmit={submitHandler}>                       
        <form className="feed-add-comment d-flex align-items-center justify-content-between w-100 ms-2 rounded-pill px-3 comment-form">
            {/* <input type="text" placeholder="Your reply..." 
                  value={text} onChange={(e) => setText(e.target.value)} 
                  className="text-lightgray fs-12 bg-transparent"/> */}
                    <InputEmoji
                        value={text}
                        onChange={setText}
                        onEnter={()=>{handleCommentChange()}}
                        cleanOnEnter={true}
                        fontSize={12}
                        ref={replyRef}
                        placeholder="Your Reply"
                    />  
            <span className="d-flex align-items-center">                                        
                <span className="">
                    <input type="file" id="feed-image-comment" 
                        ref={commentMediaRef} 
                        onChange={handleCommentImageSelection}
                        className="d-none"/>
                    <label htmlFor="feed-image-comment"
                      style={{cursor: "pointer"}} className="comment-cam-label">
                        <img src="../assets/img/icon/icon-camera2.svg" alt=""/></label>
                </span>
               
            </span>      
        </form>
             {
                  commentMedia &&  
                    <div className='image-preview'>
                        <img src={URL.createObjectURL(commentMedia)} alt="preview-img" className="post-media mt-1"  />
                        <svg height="20px" id="Layer_1" 
                            style={{cursor: "pointer"}}
                            onClick={()=>{ setCommentMedia(null); commentMediaRef.current.value = ""}}
                            version="1.1"
                            viewBox="0 0 512 512"
                            width="20px">
                            <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"/>
                          </svg> 
                        </div>
                }                                      
    </div> 
  )
}

export default MediaCommentreplyBox