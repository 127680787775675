import React, { useState, useCallback, useEffect } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import { StaticRules } from "../../constants/StaticRules";
import { useNavigate, Link } from "react-router-dom";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import Swal from "sweetalert2";
import Select from "react-select";
import { Helmet } from "react-helmet";
const $ = window.jQuery;
export default function CreateGroups() {
  const [groupName, setGroupName] = useState("");
  const [groupType, setGroupType] = useState("");
  const [Industry, setIndustry] = useState("");
  const [Location, setLocation] = useState("");
  const [logo, setLogo] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [logoName, setLogoName] = useState("");
  const [coverImageName, setCoverImageName] = useState("");
  const [Tagline, setTagline] = useState("");
  const [Description, setDescription] = useState("");
  const [term, setTerm] = useState(false);
  const [IndustryKey, setIndustryKey] = useState(
    Math.ceil(Math.random() * 999999)
  );
  const [IndustryOption, setIndustryOption] = useState([]);
  const [rules, setRules] = useState([]);
  const [rulesId, setRulesId] = useState(0);
  const [rulesTitle, setRulesTitle] = useState("");
  const [rulesDescription, setRulesDescription] = useState("");
  const Navigate = useNavigate();

  const [rulesTitleError, setRulesTitleError] = useState("");
  const [rulesDescriptionError, setRulesDescriptionError] = useState("");

  const [groupNameError, setGroupNameError] = useState("");
  const [groupTypeError, setGroupTypeError] = useState("");
  const [IndustryError, setIndustryError] = useState("");
  const [LocationError, setLocationError] = useState("");
  const [logoError, setLogoError] = useState("");
  const [coverImageError, setCoverImageError] = useState("");
  const [TaglineError, setTaglineError] = useState("");
  const [DescriptionError, setDescriptionError] = useState("");
  const [termError, setTermError] = useState("");
  const [selectedOptions, setSelectedOptions] = useState("");

  const handleSave = async (event) => {
    event.preventDefault();
    if (!groupName) {
      setGroupNameError("Please enter group name");
    } else {
      setGroupNameError("");
    }

    if (!groupType) {
      setGroupTypeError("Please enter group type");
    } else {
      setGroupTypeError("");
    }

    if (!Description) {
      setDescriptionError("Please enter description");
    } else {
      setDescriptionError("");
    }
    if (!Industry) {
      setIndustryError("Please enter Insdustry");
    } else {
      setIndustryError("");
    }
    if (!Tagline) {
      setTaglineError("Please enter tagline");
    } else {
      setTaglineError("");
    }
    if (!Location) {
      setLocationError("Please enter location");
    } else {
      setLocationError("");
    }
    if (!term) {
      setTermError("Please check terms");
    } else {
      setTermError("");
    }
    if (!logo) {
      setLogoError("Please enter logo");
    } else {
      setLogoError("");
    }
    if (!coverImage) {
      setCoverImageError("Please enter cover image");
    } else {
      setCoverImageError("");
    }

    if (
      groupName &&
      groupType &&
      Description &&
      Industry &&
      Tagline &&
      Location &&
      term &&
      logo &&
      coverImage
    ) {
      const formData = new FormData();
      formData.append("name", groupName);
      formData.append("type", groupType);
      formData.append("description", Description);
      formData.append("industry", Industry);
      formData.append("tagline", Tagline);
      formData.append("location", Location);
      formData.append("terms_accepted", term);
      formData.append("logo_image", logo);
      formData.append("cover_image", coverImage);

      try {
        let resp = await CommonAPi(Endpoints.GroupCreate, formData);
        if (resp && resp.success === true) {
          for (var i = 0; i < rules.length; i++) {
            rules[i]["groupId"] = resp?.data?.id;
            saveRules(rules[i], resp.message);
          }

          ToastMessage.Success(resp.message);

          Navigate("/groups");
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const saveRules = async (data, message) => {
    try {
      let resp = await CommonAPi(Endpoints.CreateRule, data);
      if (resp && resp.success === true) {
        ToastMessage.Success(message);
        Navigate("/groups");
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleOnSearchIndustry = async (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string) {
      try {
        let resp = await FetchApi(
          Endpoints.SearchIndustry + "?title=" + string
        );
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                name: item?.value,
              };
            });
            setIndustryOption(newData);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleOnSelectIndustry = (item) => {
    if (item.length > 0) {
      setIndustry(item[0].name);
    } else {
      setIndustry("");
    }
  };

  // const RenderIndustry = useCallback(() => {
  //     return (
  //         <AsyncTypeahead
  //             id="async-pagination-example-StudyArea"
  //             defaultInputValue={Industry}
  //             key={IndustryKey}
  //             onSearch={handleOnSearchIndustry}
  //             onChange={handleOnSelectIndustry}
  //             // onBlur={(e) => { handleValidation(e, e.target.value, "study") }}
  //             options={IndustryOption}
  //             placeholder="For Ex: IT Services and IT Consulting "
  //             labelKey="name"
  //             minLength={1}
  //             emptyLabel={"Searching..."}
  //             className={IndustryError ? "border rounded-2 fs-14 p-2 text-gray" : "border rounded-2 fs-14 p-2 text-gray"}
  //             renderMenuItemChildren={(option, props) => (
  //                 <div key={option.id}>

  //                     <Highlighter search={props.text}>
  //                         {option[props.labelKey]}
  //                     </Highlighter>
  //                 </div>

  //             )}

  //             useCache={false}
  //         />
  //     )
  // }, [IndustryOption, IndustryError,])

  const handleSelected = (item) => {
    if (item) {
      setSelectedOptions(item);
      setIndustry(item?.label);
    }
  };

  useEffect(() => {
    handleGetIndustry();
  }, []);

  const handleGetIndustry = async () => {
    try {
      let resp = await FetchApi(Endpoints.JobsIndustries);
      if (resp && resp.success === true) {
        if (resp.data.length > 0) {
          const newData = resp.data.map((item, index) => {
            return {
              value: item.id,
              label: item?.value,
            };
          });
          setIndustryOption(newData);
        }
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const RenderIndustry = useCallback(() => {
    return (
      <Select
        value={selectedOptions}
        onChange={handleSelected}
        styles={{
          control: (provided, state) => ({
            ...provided,
            boxShadow: "none",
            border: "none",
            padding: "3px",
          }),
        }}
        options={IndustryOption}
        className={
          IndustryError
            ? "is-danger border rounded-2 fs-14 p-1 "
            : "border rounded-2 fs-14 p-1 "
        }
        placeholder="For Ex: IT Services and IT Consulting "
        isClearable={false}
        isSearchable={true}
        isDisabled={false}
        isLoading={false}
        isRtl={false}
        closeMenuOnSelect={true}
      />
    );
  }, [IndustryOption, IndustryError, selectedOptions]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!rulesTitle) {
      setRulesTitleError("Rules title is required");
    } else {
      setRulesTitleError("");
    }

    if (!rulesDescription) {
      setRulesDescriptionError("Rules description is required");
    } else {
      setRulesDescriptionError("");
    }
    if (rulesDescription && rulesTitle) {
      let data = {
        groupId: "",
        title: rulesTitle,
        description: rulesDescription,
      };
      setRules([...rules, data]);
      setRulesDescription("");
      setRulesTitle("");
      $("#add_rules").modal("hide");
    }
  };

  const handleChangeStaticRules = (item) => {
    setRulesDescription(item?.description);
    setRulesTitle(item?.title);
    setRulesId(item?.id);
  };

  const handleCloseRulesModal = () => {
    if (rulesDescription || rulesTitle || rulesId) {
      Swal.fire({
        text: "You have unsaved changes, do you still want to exit?",
        icon: "question",
        customClass: {
          confirmButton: "btn px-3 mx-2",
          cancelButton: "btn btn-br-blue px-3",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Yes, discard it!",
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          setRulesDescription("");
          setRulesTitle("");
          setRulesId("");
          setRulesTitleError("");
          setRulesDescriptionError("");
          $("#add_rules").modal("hide");
        } else {
          $("#add_rules").modal("show");
        }
      });
    } else {
      setRulesTitleError("");
      setRulesDescriptionError("");
      $("#add_rules").modal("hide");
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Create Group</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-lg-3 py-3">
            <div className="bg-white rounded-10 shadow-lg">
              <div className="py-3 px-3 row align-items-center">
                <div className="col-md-3">
                  <Link to={"/groups"} className="btn">
                    <svg
                      viewBox="0 0 24 24"
                      width="16"
                      height="16"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="css-i6dzq1"
                    >
                      <line x1="19" y1="12" x2="5" y2="12"></line>
                      <polyline points="12 19 5 12 12 5"></polyline>
                    </svg>
                    Back
                  </Link>
                </div>

                <div className="col-md-9">
                  <p className="medium  text-gray m-0">
                    Let’s get started with a few details about your group.
                  </p>
                </div>
              </div>

              <div className="border-top py-md-5 px-md-5 p-3">
                <p className="text-darkgray fs-12 medium">
                  * All fields are required.
                </p>

                <form onSubmit={handleSave} className="company-form">
                  <div className="row gx-md-50">
                    <div className="col-md-6 mb-4">
                      <label for="" className="fs-15 medium d-block pb-2">
                        Group Name*
                      </label>
                      <input
                        type="text"
                        placeholder="Add your group name"
                        className="border rounded-2 fs-14 p-3 text-gray"
                        value={groupName}
                        onChange={(e) => {
                          setGroupName(e.target.value);
                        }}
                      />
                      {groupNameError ? (
                        <div
                          className="form-error"
                          style={{ color: "white", margintop: "-10px" }}
                        >
                          {groupNameError}
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <span className="field-error fs-12 text-red">This group name is already in use. Kindly use different name.</span> */}
                    </div>

                    <div className="col-md-6 mb-4">
                      <label for="" className="fs-15 medium d-block pb-2">
                        Group Type *
                      </label>
                      <select
                        name=""
                        className="fs-14 border rounded-2 p-3 text-gray"
                        id=""
                        onChange={(e) => {
                          setGroupType(e.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        <option value="Public">Public</option>
                        <option value="Private">Private</option>
                      </select>
                      {groupTypeError ? (
                        <div
                          className="form-error"
                          style={{ color: "white", margintop: "-10px" }}
                        >
                          {groupTypeError}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 mb-4">
                      <label for="" className="fs-15 medium d-block pb-2">
                        Industry *
                      </label>

                      {RenderIndustry()}
                      {IndustryError ? (
                        <div
                          className="form-error"
                          style={{ color: "white", margintop: "-10px" }}
                        >
                          {IndustryError}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 mb-4">
                      <label for="" className="fs-15 medium d-block pb-2">
                        Location *
                      </label>

                      <input
                        type="text"
                        placeholder="Enter Location"
                        className="border rounded-2 fs-14 p-3 text-gray"
                        value={Location}
                        onChange={(e) => {
                          setLocation(e.target.value);
                        }}
                      />
                      {LocationError ? (
                        <div
                          className="form-error"
                          style={{ color: "white", margintop: "-10px" }}
                        >
                          {LocationError}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 mb-4">
                      <div className="d-flex gap-3 justify-content-between">
                        <div>
                          <label for="" className="fs-15 medium d-block pb-2">
                            Logo *
                          </label>
                          <input
                            type="file"
                            name=""
                            id="logo_upload"
                            className="d-none"
                            accept="image/*,.gif"
                            onChange={(e) => {
                              setLogo(e.target.files[0]);
                              setLogoName(e.target.files[0]?.name);
                            }}
                          />
                          <label
                            for="logo_upload"
                            className=" rounded-2 text-center py-4 px-2 w-100 com-logo"
                          >
                            <img
                              src="assets/img/icon/icon-file-upload.svg"
                              width="25"
                              alt=""
                            />
                            <span className="d-block fs-14 medium pt-2 pb-1 text-black">
                              Upload Logo
                            </span>
                            <span className="d-block fs-14 text-gray">
                              Drag and drop a file or{" "}
                              <span className="text-black text-decoration-underline">
                                browse a file
                              </span>
                            </span>
                          </label>
                          {logoError ? (
                            <div
                              className="form-error"
                              style={{ color: "white", margintop: "-10px" }}
                            >
                              {logoError}
                            </div>
                          ) : (
                            ""
                          )}
                          <p className="fs-12 text-darkgray">
                            200 x 200px recommended Size.
                          </p>
                          {logo ? (
                            <Link
                              to={URL.createObjectURL(logo)}
                              target="_blank"
                              style={{ color: "var(--bs-link-color)" }}
                            >
                              <div className="fs-12">
                                <h6>{logoName}</h6>
                              </div>
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>

                        <div>
                          <label for="" className="fs-15 medium d-block pb-2">
                            Cover Image
                          </label>
                          <input
                            type="file"
                            name=""
                            id="cover_upload"
                            className="d-none"
                            accept="image/*,.gif"
                            onChange={(e) => {
                              setCoverImage(e.target.files[0]);
                              setCoverImageName(e.target.files[0]?.name);
                            }}
                          />
                          <label
                            for="cover_upload"
                            className=" rounded-2 text-center py-4 px-2 w-100 com-logo"
                          >
                            <img
                              src="assets/img/icon/icon-file-upload.svg"
                              width="25"
                              alt=""
                            />
                            <span className="d-block fs-14 medium pt-2 pb-1 text-black">
                              Upload Cover Image
                            </span>
                            <span className="d-block fs-14 text-gray">
                              Drag and drop a file or{" "}
                              <span className="text-black text-decoration-underline">
                                browse a file
                              </span>
                            </span>
                          </label>
                          {coverImageError ? (
                            <div
                              className="form-error"
                              style={{ color: "white", margintop: "-10px" }}
                            >
                              {coverImageError}
                            </div>
                          ) : (
                            ""
                          )}
                          <p className="fs-12 text-darkgray">
                            200 x 200px recommended Size.
                          </p>
                          {coverImage ? (
                            <Link
                              to={URL.createObjectURL(coverImage)}
                              target="_blank"
                              style={{ color: "var(--bs-link-color)" }}
                            >
                              <div className="fs-12">
                                <h6>{coverImageName}</h6>
                              </div>
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mb-4">
                      <label for="" className="fs-15 medium d-block pb-2">
                        Tagline *
                      </label>
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="6"
                        className="border rounded-2 fs-14 p-3 textarea1 text-gray"
                        placeholder="Add Page Tagline"
                        value={Tagline}
                        onChange={(e) => {
                          setTagline(e.target.value);
                        }}
                      ></textarea>
                      {TaglineError ? (
                        <div
                          className="form-error"
                          style={{ color: "white", margintop: "-10px" }}
                        >
                          {TaglineError}
                        </div>
                      ) : (
                        ""
                      )}
                      <div id="count1" className="fs-12 text-darkgray text-end">
                        <span id="current1">{Tagline.length}</span>
                        <span id="maximum1">/ 200</span>
                      </div>
                    </div>

                    <div className="col-md-12 mb-3">
                      <label for="" className="fs-15 medium d-block pb-2">
                        Description *
                      </label>

                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        placeholder="Add about your organization in detail."
                        className="border rounded-2 fs-14 p-3  textarea2 text-gray"
                        value={Description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        maxLength={"5000"}
                      ></textarea>
                      {DescriptionError ? (
                        <div
                          className="form-error"
                          style={{ color: "white", margintop: "-10px" }}
                        >
                          {DescriptionError}
                        </div>
                      ) : (
                        ""
                      )}
                      <div id="count2" className="fs-12 text-darkgray text-end">
                        <span id="current2">{Description.length}</span>
                        <span id="maximum2">/ 5000</span>
                      </div>
                    </div>

                    <div className="col-md-12 mb-4">
                      <a
                        href="#"
                        className="btn medium btn-br-blue fs-14"
                        data-bs-toggle="modal"
                        data-bs-target="#add_rules"
                      >
                        + Add Rules
                      </a>
                    </div>

                    <div className="col-md-12  ">
                      <p className="fs-15 medium border-bottom pb-2 m-0">
                        Rules
                      </p>
                    </div>

                    {rules.length > 0 ? (
                      <div className="col-md-12">
                        {rules.map((item, index) => {
                          return (
                            <div className="border-bottom pt-3" key={index}>
                              <h6 className="medium fs-14 text-black pb-2">
                                {item?.title}
                              </h6>
                              <p
                                className="fs-12 text-color"
                                style={{ "--color": "#565656" }}
                              >
                                {item?.description}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="col-md-12">
                        No rule added, Please add rules
                      </div>
                    )}

                    <div className="col-md-12 my-4">
                      <div className="d-flex align-items-center gap-2">
                        {term === false ? (
                          <input
                            type="checkbox"
                            className="w-auto border"
                            onChange={() => {
                              setTerm(true);
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            className="w-auto border"
                            checked
                            onChange={() => {
                              setTerm(false);
                            }}
                          />
                        )}
                        <label for="" className="text-gray fs-12">
                          I agree to the{" "}
                          <a href="#" className="text-blue medium">
                            terms for Groups.
                          </a>
                        </label>
                      </div>
                      {termError ? (
                        <div
                          className="form-error mt-1"
                          style={{ color: "white", margintop: "-10px" }}
                        >
                          {termError}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-12">
                      <button type="submit" className="btn fs-14 w-auto px-4">
                        Create Group
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal" id="add_rules" tabindex="-1">
              <div className="modal-dialog modal-lg px-lg-5 modal-dialog-centered modal-dialog-scrollable modal-md">
                <div className="modal-content">
                  <button
                    type="button"
                    className="btn-close ms-auto p-3 position-absolute end-0 "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      handleCloseRulesModal();
                    }}
                  ></button>
                  <div className="text-center py-4 border-bottom">
                    <h5 className="modal-title text-center fs-20 medium text-black">
                      Create Rules
                    </h5>
                  </div>
                  <div className="modal-body">
                    <h5 className="fs-18 medium pb-3">Example Rules</h5>
                    <div className="rule-list pb-4 d-flex gap-2 flex-wrap">
                      {StaticRules.map((item, index) => {
                        return (
                          <span key={index}>
                            <input
                              type="radio"
                              name="rules"
                              id={"rules" + index}
                              className="d-none"
                              checked={item?.id === rulesId ? true : false}
                              onChange={() => {
                                handleChangeStaticRules(item);
                              }}
                            />
                            <label
                              for={"rules" + index}
                              className="py-2 px-3 rounded-pill bg-d8d8d8 text-black fs-14 medium"
                            >
                              {item?.title}
                            </label>
                          </span>
                        );
                      })}
                    </div>
                    <form onSubmit={handleSubmit} className="company-form">
                      <h5 className="fs-18 medium pb-3">Write your own</h5>
                      <input
                        type="text"
                        placeholder="Title"
                        className="fs-14 border rounded-3 py-2 px-3 w-100 mb-2"
                        value={rulesTitle}
                        onChange={(e) => {
                          setRulesTitle(e.target.value);
                        }}
                      />
                      {rulesTitleError ? (
                        <div
                          className="form-error"
                          style={{
                            color: "white",
                            margintop: "-10px",
                            marginBottom: "10px",
                          }}
                        >
                          {rulesTitleError}
                        </div>
                      ) : (
                        ""
                      )}
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        className="fs-14 border rounded-3 py-2 px-3 w-100 mb-2"
                        placeholder="Description"
                        value={rulesDescription}
                        onChange={(e) => {
                          setRulesDescription(e.target.value);
                        }}
                      ></textarea>
                      {rulesDescriptionError ? (
                        <div
                          className="form-error"
                          style={{
                            color: "white",
                            margintop: "-10px",
                            marginBottom: "10px",
                          }}
                        >
                          {rulesDescriptionError}
                        </div>
                      ) : (
                        ""
                      )}
                      <button type="submit" className="btn fs-14 px-5">
                        Create
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <OnlineFriends />
          </div>
        </div>
      </div>
    </Container>
  );
}
