import React, {useEffect, useState} from 'react'
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { CommonAPi } from '../../API/CommonApi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FetchApi } from "../../API/FetchApi";
export default function SimilarGroup() {
    const [SimilarGroup, setSimilarGroup] = useState([]);
    const Navigate = useNavigate();
    const param = useParams();
    useEffect(()=>{
        GetSimilarGroup();
    }, []);

    const GetSimilarGroup = async() => {

        try {
            let resp = await FetchApi(Endpoints.SimilarGroup + "?groupId=" + param?.id);
            if (resp && resp.success === true) {
                setSimilarGroup(resp?.data);
            }
        }
        catch (e) {

            if (e && e.response && e.response.data && e.response.data.message) {

                if (e.response.status === 401) {

                    ToastMessage.Error('Session Expired! Please login again.')
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message)
                }
            }
        }
    };

   

    const joinGroup = async (id) => {
        let data = {
            groupId: id
        }
        try {
            let resp = await CommonAPi(Endpoints.JoinGroups, data);
            if (resp && resp.success === true) {
                GetSimilarGroup();
                ToastMessage.Success(resp.message)
            }
        }
        catch (e) {

            if (e && e.response && e.response.data && e.response.data.message) {

                if (e.response.status === 401) {

                    ToastMessage.Error('Session Expired! Please login again.')
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message)
                }
            }
        }

    };
    return (
        <div className="col-md-3 comany-dp-right">
            <div className="sidebar-widget box-shadow">
                <h4 className="medium border-bottom pb-2 mb-3">Similar Groups</h4>
                <div>
                    {
                    SimilarGroup.length > 0 ?
                    SimilarGroup.map((item, index)=>{
                        return (
                            <div className="d-flex align-items-start gap-2 pb-3" key={index}>
                            {item.logo === null ? 
                            <img src="../assets/img/company1.png" width="48" height="48" className="rounded-circle" alt="" />
                            :
                            <img src={Endpoints.baseUrl + item.logo} width="48" height="48" className="rounded-circle" alt="" />
                             }
                            <div>
                                <h6 className="medium fs-14">{item?.groupName}</h6>
                                <h6 className="text-darkgray fs-10 py-1">{item?.industry}</h6>
                                <h6 className="text-darkgray fs-10 pb-1">{item?.totalMembers} Followers</h6>
                                <Link onClick={()=>{
                                    joinGroup(item?.id)
                                }}  className="btn btn-light-blue px-3 medium fs-10 py-1">Join
                                    <img src="../assets/img/icon/follow2.svg" alt="" width="12" />
                                </Link>
                            </div>
                        </div>

                        )
                    })
                    :   
                    <div className="d-flex align-items-start gap-2 pb-3">
                        No Any Similar Groups
                    </div>
                    }
                   

                    
                </div>
            </div>
        </div>

    )
}
