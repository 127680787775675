import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Endpoints } from "../../../API/Endpoints";
import { Tooltip as ReactTooltip } from "react-tooltip";
const $ = window.jQuery;
export default function Profile({ type = null, active }) {
  const location = useLocation();
  const userProfile = localStorage.getItem("userProfile");
  const userProfileCoverImage = localStorage.getItem("userProfileCoverImage");
  const ProfileTagline = localStorage.getItem("ProfileTagline");
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    $("[data-toggle=popover]")
      .popover({
        trigger: "manual",
        html: true,
        content: function () {
          var content = $(this).attr("data-popover-content");
          return $(content).children(".popover-box").html();
        },
      })
      .on("mouseenter", function () {
        var _this = this;
        $(this).popover("show");
        $(".popover").on("mouseleave", function () {
          $(_this).popover("hide");
        });
      })
      .on("mouseleave", function () {
        var _this = this;
        setTimeout(function () {
          if (!$(".popover:hover").length) {
            $(_this).popover("hide");
          }
        }, 300);
      });
  }, []);
  return (
    <div className="profile-banner-top mb-3  rounded-15 overflow-hidden bg-white">
      <div className="position-relative course-dashboard-banner">
        {userProfileCoverImage === null ||
        userProfileCoverImage === "" ||
        userProfileCoverImage === "undefined" ? (
          <img
            src="assets/img/timeline-banner.png"
            alt=""
            className="profile-coverpic"
          />
        ) : (
          <img
            src={Endpoints.baseUrl + userProfileCoverImage}
            alt=""
            className="profile-coverpic"
          />
        )}
        {location.pathname === "/dashboard" ||
        location.pathname === "/mycourses" ||
        location.pathname === "/earnings" ||
        location.pathname === "/teacher/messages" ? (
          <>
            <div
              class="position-absolute bg-white py-1 px-2 bottom-0 end-0 mb-md-5 "
              id="app-title-commission"
            >
              <h6 class="fs-15 fs-sm-10 d-flex align-items-center gap-1">
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="#fff"
                  strokeWidth="2"
                  fill="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  class="css-i6dzq1"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="12" y1="16" x2="12" y2="12"></line>
                  <line x1="12" y1="8" x2="12.01" y2="8"></line>
                </svg>
                5% Commission Charged
              </h6>
            </div>
          </>
        ) : (
          ""
        )}
        <ReactTooltip
          anchorId="app-title-commission"
          place="top"
          content="5% Commission charged on every course sale. For example, If you have listed your course with Rs. 100 then you will get 100 - {5% commission + tax (18% GST)} = Rs. 94.10 "
        />
        <div className="position-absolute start-0 bottom-0 p-3 w-100 mb-md-0 mb-4 ">
          <div className="row mx-0 align-items-center ">
            <div className="col-md-auto px-3">
              <div className="teacher-dp ">
                {userProfile === null ||
                userProfile === "" ||
                userProfile === "undefined" ? (
                  <img
                    src="assets/img/profile-dp.png"
                    width="96"
                    height="96"
                    alt=""
                    className="rounded-circle"
                  />
                ) : (
                  <img
                    src={Endpoints.baseUrl + userProfile}
                    width="96"
                    height="96"
                    alt=""
                    className="rounded-circle"
                  />
                )}
              </div>
            </div>

            <div className="col-md-8 py-2">
              <h3 className="bold pb-2 text-white">{user?.fullName}</h3>
              <h5 className="regular text-white">
                {ProfileTagline ? ProfileTagline : ""}
              </h5>
            </div>
          </div>
        </div>
      </div>

      {type === "student" ? (
        <div className="px-lg-4 px-3 px-xl-5 pt-3">
          <ul className="course-dashboard-menu profile-menu d-flex flex-wrap gap-lg-3 justify-content-between">
            <li className={`${active === "dashboard" && "active"}`}>
              <Link to="/student-dashboard">Dashboard</Link>
            </li>

            <li className={`${active === "courses" && "active"}`}>
              <Link to="/my-courses">Courses</Link>
            </li>

            <li className={`${active === "teacher" && "active"}`}>
              <Link to="/student-teachers">Teachers</Link>
            </li>

            <li className={`${active === "messages" && "active"}`}>
              <Link to="/messages">Messages</Link>
            </li>

            <li className={`${active === "wishlist" && "active"}`}>
              <Link to="/student-wishlist">Wishlist</Link>
            </li>

            <li className={`${active === "purchase" && "active"}`}>
              <Link to="/student-purchase-history">Purchase History</Link>
            </li>
          </ul>
        </div>
      ) : (
        <div className="px-lg-4 px-3 px-xl-5 pt-3">
          <ul className="course-dashboard-menu profile-menu d-flex flex-wrap gap-lg-3 justify-content-between">
            <li className={location.pathname === "/dashboard" ? "active" : ""}>
              <Link to="/dashboard">Dashboard</Link>
            </li>

            <li
              className={
                location.pathname === "/mycourses" ||
                location.pathname.split("/")[1] === "mycourses-detail"
                  ? "active"
                  : ""
              }
            >
              <Link to="/mycourses">My Courses</Link>
            </li>

            <li className={location.pathname === "/earnings" ? "active" : ""}>
              <Link to="/earnings">Earnings</Link>
            </li>

            <li
              className={
                location.pathname === "/teacher/messages" ? "active" : ""
              }
            >
              <Link to="/teacher/messages">Messages</Link>
            </li>
          </ul>
        </div>
      )}

      <div id={"a1"} hidden>
        <div className=" popover-box rounded-15 border bg-white">
          <h6>
            5% Commission charged on every course sale. For example, If you have
            listed your course with Rs. 100 then you will get 100 -{" "}
            {"5% commission + tax (18% GST)"} = Rs. 94.10
          </h6>
        </div>
      </div>
    </div>
  );
}
