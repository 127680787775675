import React, { useEffect, useState } from "react";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { useNavigate } from "react-router-dom";

export default function ProfileImage({ data }) {
  const [profileData, setProfileData] = useState(data || "");
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();

  useEffect(() => {
    getProfile();
  }, [data, userId]);

  const getProfile = async () => {
    if (!data) {
      try {
        const resp = await FetchApi(Endpoints.GetProfile + "?id=" + userId);
        if (resp?.success) {
          setProfileData(resp.data);
        }
      } catch (e) {
        const errorMessage = e?.response?.data?.message;
        if (e?.response?.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else if (errorMessage) {
          ToastMessage.Error(errorMessage);
        }
      }
    }
  };

  const coverImageUrl = profileData?.user_profile?.coverImg
    ? Endpoints.baseUrl + profileData.user_profile.coverImg
    : null;

  const profileImageUrl = profileData?.user_profile?.profileImg
    ? Endpoints.baseUrl + profileData.user_profile.profileImg
    : null;

  return (
    <div className="profile-banner-top mb-4 rounded-15 overflow-hidden">
      <div className="position-relative">
        {coverImageUrl ? (
          <img src={coverImageUrl} alt="Cover" className="profile-coverpic" />
        ) : (
          <div
            className="profile-coverpic"
            style={{ backgroundColor: "white", height: "100", width: "200" }}
          />
        )}
        <div className="position-absolute bottom-0 start-0 m-3">
          <div className="profile-dp user-pro-dp">
            {profileImageUrl ? (
              <img src={profileImageUrl} alt="Profile" />
            ) : (
              <div
                style={{
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                  borderRadius: "50%",
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
