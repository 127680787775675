import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Profile from "../../Dashboard/Teacher/Profile";
import Topbar from "./Topbar";
import { Endpoints } from "../../../API/Endpoints";
import { FetchApi } from "../../../API/FetchApi";
import { DeleteApi } from "../../../API/DeleteApi";
import ToastMessage from "../../../Utils/ToastMessage";
import DeletePrompt from "../../../Components/DeletePrompt/DeletePrompt";
import Moment from "moment";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";
import ReactStars from "react-rating-stars-component";
import PostLoader from "../../../Components/PostLoader/PostLoader";
import { Helmet } from "react-helmet";
export default function CourseDetail() {
  const param = useParams();
  const [course, setCourses] = useState("");

  const [dashboard, setDashboard] = useState();
  const [courseViewsCount, setCourseViewsCount] = useState([]);
  const [courseViewsType, setCourseViewsType] = useState("week");
  const [courseViewsCoursesCount, setCourseViewsCoursesCount] = useState([]);
  const [courseViewsInterval, setCourseViewsInterval] = useState(0);

  const [revenueData, setRevenueData] = useState([]);
  const [revenueType, setRevenueType] = useState("week");
  const [revenueInterval, setRevenueInterval] = useState(0);

  const [ratingsType, setRatingsType] = useState("week");
  const [totalRatingData, setTotalRatingData] = useState();
  const [totalRatingCount, setTotalRatingCount] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [item, setItem] = useState();
  useEffect(() => {
    getCourses();
  }, []);
  const getCourses = async () => {
    setLoading(true);
    try {
      let resp = await FetchApi(
        Endpoints.TeacherCourseDetail +
          "/" +
          param.id +
          "?ratings_by=week&revenue_by=week&course_views_by=week"
      );
      if (resp && resp.success === true) {
        setCourses(resp.data.course);

        let courseViewsCountData = Object.keys(
          resp.data.graphData.courseViewsCount
        ).map((item) => {
          return {
            view: Math.round(resp.data.graphData.courseViewsCount[item]),
            name:
              resp.data.graphData.courseViewsType === "year"
                ? item
                : Moment(item).format("DD MMM"),
          };
        });

        setCourseViewsCount(courseViewsCountData);
        setCourseViewsCoursesCount(resp?.data?.graphData?.courseViewsCount);
        if (resp.data.graphData.courseViewsType === "year") {
          setCourseViewsInterval(0);
        } else if (resp.data.graphData.courseViewsType === "month") {
          setCourseViewsInterval(4);
        } else if (resp.data.graphData.courseViewsType === "week") {
          setCourseViewsInterval(0);
        }

        setCourseViewsType(resp.data.graphData.courseViewsType);

        // if (resp.data.graphData.ratingsCount.length > 0) {

        let ratingData = [];
        if (resp.data.graphData.ratingsType === "month") {
          ratingData = Object.keys(
            resp.data.graphData.ratingsCount[0].ratingsByDay
          ).map((item) => {
            return {
              rating: Math.round(
                resp.data.graphData.ratingsCount[0].ratingsByDay[item]
              ),
              name:
                resp.data.graphData.ratingsType === "year"
                  ? item
                  : Moment(item).format("DD MMM"),
            };
          });
        } else if (resp.data.graphData.ratingsType === "week") {
          ratingData = Object.keys(
            resp.data.graphData.ratingsCount[0].ratingsByWeek
          ).map((item) => {
            return {
              rating: Math.round(
                resp.data.graphData.ratingsCount[0].ratingsByWeek[item]
              ),
              name:
                resp.data.graphData.ratingsType === "year"
                  ? item
                  : Moment(item).format("DD MMM"),
            };
          });
        } else if (resp.data.graphData.ratingsType === "year") {
          ratingData = Object.keys(
            resp.data.graphData.ratingsCount[0].ratingsByYear
          ).map((item) => {
            return {
              rating: Math.round(
                resp.data.graphData.ratingsCount[0].ratingsByYear[item]
              ),
              name:
                resp.data.graphData.ratingsType === "year"
                  ? item
                  : Moment(item).format("DD MMM"),
            };
          });
        }

        setTotalRatingCount(ratingData);
        setTotalRatingData(resp.data.graphData.ratingsCount[0].ratingData);

        setRatingsType(resp.data.graphData.ratingsType);
        setLoading(false);

        let revenudata = Object.keys(resp.data.graphData.revenueCount).map(
          (item) => {
            return {
              "₹": Math.round(resp.data.graphData.revenueCount[item]),
              name:
                resp.data.graphData.revenueType === "year"
                  ? item
                  : Moment(item).format("DD MMM"),
            };
          }
        );

        setRevenueData(revenudata);

        if (resp.data.graphData.revenueType === "year") {
          setRevenueInterval(0);
        } else if (resp.data.graphData.revenueType === "month") {
          setRevenueInterval(4);
        } else if (resp.data.graphData.graphData.revenueType === "week") {
          setRevenueInterval(0);
        }

        setRevenueType(resp.data.revenueType);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoading(false);
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleDeleteCourses = async (Id) => {
    try {
      let resp = await DeleteApi(Endpoints.DeleteTeacherCourses + "/" + Id);
      if (resp && resp.success === true) {
        ToastMessage.Success(resp.message);
        navigate("/mycourses");
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const FormatSeconds = (seconds) => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const remainingSeconds = seconds % 60;
    const parts = [];

    if (days > 0) {
      parts.push(`${days} day${days > 1 ? "s" : ""}`);
    }
    if (hours > 0) {
      parts.push(`${hours} hr${hours > 1 ? "s" : ""}`);
    }
    if (minutes > 0) {
      parts.push(`${minutes} min${minutes > 1 ? "s" : ""}`);
    }
    if (remainingSeconds > 0) {
      parts.push(`${remainingSeconds} sec${remainingSeconds > 1 ? "s" : ""}`);
    }

    if (parts.length === 0) {
      return "0 sec";
    } else {
      return parts.join(", ");
    }
  };

  const handleCourseViewChart = async (value) => {
    // setLoading(true);
    setCourseViewsType(value);

    let url =
      Endpoints.TeacherCourseDetail +
      "/" +
      param.id +
      "?course_views_by=" +
      value;
    try {
      let resp = await FetchApi(url);
      if (resp && resp.success === true) {
        let courseViewsCountData = Object.keys(
          resp.data.graphData.courseViewsCount
        ).map((item) => {
          return {
            view: Math.round(resp.data.graphData.courseViewsCount[item]),
            name:
              resp.data.graphData.courseViewsType === "year"
                ? item
                : Moment(item).format("DD MMM"),
          };
        });

        setCourseViewsCount(courseViewsCountData);

        if (resp.data.graphData.courseViewsType === "year") {
          setCourseViewsInterval(0);
        } else if (resp.data.graphData.courseViewsType === "month") {
          setCourseViewsInterval(4);
        } else if (resp.data.graphData.courseViewsType === "week") {
          setCourseViewsInterval(0);
        }

        setCourseViewsType(resp.data.graphData.courseViewsType);
        setLoading(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoading(false);
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleRevenueChart = async (value) => {
    // setLoading(true);
    setRevenueType(value);
    let url =
      Endpoints.TeacherCourseDetail + "/" + param.id + "?revenue_by=" + value;
    try {
      let resp = await FetchApi(url);
      if (resp && resp.success === true) {
        let revenudata = Object.keys(resp.data.graphData.revenueCount).map(
          (item) => {
            return {
              "₹": Math.round(resp.data.graphData.revenueCount[item]),
              name:
                resp.data.graphData.revenueType === "year"
                  ? item
                  : Moment(item).format("DD MMM"),
            };
          }
        );

        setRevenueData(revenudata);

        if (resp.data.graphData.revenueType === "year") {
          setRevenueInterval(0);
        } else if (resp.data.graphData.revenueType === "month") {
          setRevenueInterval(4);
        } else if (resp.data.graphData.revenueType === "week") {
          setRevenueInterval(0);
        }

        setRevenueType(resp.data.graphData.revenueType);
        setLoading(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoading(false);
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleRatingsChart = async (value) => {
    // setLoading(true);
    setRatingsType(value);

    let url =
      Endpoints.TeacherCourseDetail + "/" + param.id + "?ratings_by=" + value;
    try {
      let resp = await FetchApi(url);
      if (resp && resp.success === true) {
        let ratingData = [];
        if (resp.data.graphData.ratingsType === "month") {
          ratingData = Object.keys(
            resp.data.graphData.ratingsCount[0].ratingsByDay
          ).map((item) => {
            return {
              rating: Math.round(
                resp.data.graphData.ratingsCount[0].ratingsByDay[item]
              ),
              name:
                resp.data.graphData.ratingsType === "year"
                  ? item
                  : Moment(item).format("DD MMM"),
            };
          });
        } else if (resp.data.graphData.ratingsType === "week") {
          ratingData = Object.keys(
            resp.data.graphData.ratingsCount[0].ratingsByWeek
          ).map((item) => {
            return {
              rating: Math.round(
                resp.data.graphData.ratingsCount[0].ratingsByWeek[item]
              ),
              name:
                resp.data.graphData.ratingsType === "year"
                  ? item
                  : Moment(item).format("DD MMM"),
            };
          });
        } else if (resp.data.graphData.ratingsType === "year") {
          ratingData = Object.keys(
            resp.data.graphData.ratingsCount[0].ratingsByYear
          ).map((item) => {
            return {
              rating: Math.round(
                resp.data.graphData.ratingsCount[0].ratingsByYear[item]
              ),
              name:
                resp.data.graphData.ratingsType === "year"
                  ? item
                  : Moment(item).format("DD MMM"),
            };
          });
        }

        setTotalRatingCount(ratingData);
        setTotalRatingData(resp.data.graphData.ratingsCount[0].ratingData);

        setRatingsType(resp.data.graphData.ratingsType);
        setLoading(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoading(false);
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const RenderStar = useCallback(() => {
    return (
      <ReactStars
        count={5}
        edit={false}
        value={parseInt(totalRatingData?.rating)}
        size={24}
        isHalf={true}
        char={<img src="../assets/img/icon/state-null.svg" width="18" alt="" />}
        emptyIcon={
          <img src="../assets/img/icon/state-null.svg" width="18" alt="" />
        }
        halfIcon={
          <img src="../assets/img/icon/state-half.svg" width="18" salt="" />
        }
        filledIcon={
          <img src="../assets/img/icon/State-fill.svg" width="18" alt="" />
        }
        activeColor="#ffc107"
      />
    );
  }, [totalRatingData, totalRatingCount]);

  return (
    <Container header>
      <Helmet>
        <title>Course Detail</title>
      </Helmet>
      {loading ? (
        <PostLoader />
      ) : (
        <div className="wrapper wrapper-bg">
          <div className="main">
            <LeftNavigation />
            <div className="container py-3">
              {/* <div className="bg-white main-common p-0 py-3"> */}

              <Profile />
              <div className="bg-white rounded-10 box-shadow p-3 mb-3">
                <div className="row ">
                  <div className="col-md-4">
                    <div className="feed-video position-relative">
                      {/* <img src={Endpoints.baseUrl + course?.thumbnail} alt=""  className="w-100 h-100" /> */}
                      <img
                        src={Endpoints.baseUrl + course?.thumbnail}
                        className="play-video w-100 h-100 align-items-center justify-content-center top-0"
                      />
                      <img
                        src="../../assets/img/icon/icon-play-video4.svg"
                        style={{
                          position: "absolute",
                          zIndex: 10,
                          left: "40%",
                          top: "40%",
                          cursor: "pointer",
                        }}
                      />
                      {/* <video width="100%" height="260" className="">
                                                <source src={Endpoints.baseUrl + course?.trailor} type="video/mp4" />
                                            </video> */}
                      {/* <a href="#!" className="play-video position-absolute w-100 h-100 align-items-center justify-content-center top-0">
                                                <img src="../../assets/img/icon/icon-play-video4.svg" />
                                            </a> */}

                      {/* <video controls width="100%" height="260">
                                                <source src={Endpoints.baseUrl + course?.trailor} type="video/mp4" />
                                                <source src={Endpoints.baseUrl + course?.trailor} type="video/ogg" />
                                            </video> */}
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="fs-14 pb-3">
                      <span className="text-gray">
                        Published{" "}
                        <span className="text-black medium">
                          {Moment(course?.createdAt).format("MMM D, YYYY")}
                        </span>
                      </span>
                      <span className="text-gray ps-3">
                        Updated:{" "}
                        <span className="medium text-black">
                          {Moment(course?.updatedAt).format("MMM D, YYYY")}
                        </span>
                      </span>
                    </div>

                    <h3 className="medium pb-3">{course?.title}</h3>
                    <h5>{course?.subtitle}</h5>

                    <div className="py-3 d-flex w-100 align-items-center fs-14 ">
                      <span className="text-brown ">{course?.avgRating}</span>
                      <div className="d-flex mx-2">
                        {course?.avgRating !== null ? (
                          <ReactStars
                            count={5}
                            edit={false}
                            value={parseInt(course?.avgRating)}
                            size={24}
                            isHalf={true}
                            char={
                              <img
                                src="../assets/img/icon/state-null.svg"
                                width="18"
                                alt=""
                              />
                            }
                            emptyIcon={
                              <img
                                src="../assets/img/icon/state-null.svg"
                                width="18"
                                alt=""
                              />
                            }
                            halfIcon={
                              <img
                                src="../assets/img/icon/state-half.svg"
                                width="18"
                                salt=""
                              />
                            }
                            filledIcon={
                              <img
                                src="../assets/img/icon/State-fill.svg"
                                width="18"
                                alt=""
                              />
                            }
                            activeColor="#ffc107"
                          />
                        ) : (
                          <ReactStars
                            count={5}
                            edit={false}
                            value={0}
                            size={24}
                            isHalf={true}
                            char={
                              <img
                                src="../assets/img/icon/state-null.svg"
                                width="18"
                                alt=""
                              />
                            }
                            emptyIcon={
                              <img
                                src="../assets/img/icon/state-null.svg"
                                width="18"
                                alt=""
                              />
                            }
                            halfIcon={
                              <img
                                src="../assets/img/icon/state-half.svg"
                                width="18"
                                salt=""
                              />
                            }
                            filledIcon={
                              <img
                                src="../assets/img/icon/State-fill.svg"
                                width="18"
                                alt=""
                              />
                            }
                            activeColor="#ffc107"
                          />
                        )}
                      </div>
                      <span className="text-brown ">
                        ({course?.totalReivews} reviews)
                      </span>
                      <span className="medium text-black ps-3">
                        {course?.totalStudents}{" "}
                        <span className="text-gray">Students</span>
                      </span>
                    </div>

                    <div className="border-top pt-3 d-flex align-items-center justify-content-between">
                      <div className="d-flex gap-4 align-items-start">
                        <div className="course-price">
                          <ins className="text-blue fs-18 semibold">
                            ₹ {course?.discountedPrice}
                          </ins>
                          <span className="text-gray fs-14 d-block">
                            Course Price
                          </span>
                        </div>

                        <div className="course-price">
                          <ins className="text-brown fs-18 semibold">
                            ₹ {course?.totalRevenue}
                          </ins>
                          <span className="text-gray fs-14 d-block">
                            Total Revenue
                          </span>
                        </div>
                      </div>

                      <div>
                        <a
                          href="#"
                          id="teacher_course"
                          data-bs-toggle="dropdown"
                          style={{ background: "#f9f9f9" }}
                          className="d-flex align-items-center justify-content-center rounded-circle p-2"
                        >
                          <svg
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            stroke="#616161"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="css-i6dzq1"
                          >
                            <circle cx="12" cy="12" r="1"></circle>
                            <circle cx="19" cy="12" r="1"></circle>
                            <circle cx="5" cy="12" r="1"></circle>
                          </svg>
                        </a>

                        <div
                          className="dropdown-menu p-0"
                          aria-labelledby="teacher_course"
                        >
                          <ul className="fs-14">
                            <li className="border-bottom ">
                              <Link
                                to={
                                  "/update-courses-basic-information/" +
                                  param.id
                                }
                                className="dropdown-item py-2"
                              >
                                <img
                                  src="../../assets/img/icon/icon-edit.svg"
                                  width="18"
                                  alt=""
                                />{" "}
                                Edit
                              </Link>
                            </li>

                            <li className="">
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#deleteInfoModal-Course"
                                onClick={() => {
                                  setItem(param.id);
                                }}
                                className="dropdown-item py-2"
                              >
                                <img
                                  src="../../assets/img/icon/icon-delete.svg"
                                  width="18"
                                  alt=""
                                />{" "}
                                Delete
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 col-sm-6 mb-3">
                  <div className="job-item  d-flex align-items-center bg-white">
                    <span className="hastag-icon bg-primary">
                      <img
                        src="../../assets/img/icon/icon-video-online-white.svg"
                        alt=""
                      />
                    </span>
                    <div className="ps-3">
                      <h4 className="semibold pb-1">{course?.totalLectures}</h4>
                      <h6 className="fs-14 regular">Lectures</h6>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 mb-3">
                  <div className="job-item  d-flex align-items-center bg-white">
                    <span className="hastag-icon bg-primary">
                      <img src="../../assets/img/icon/icon-files.svg" alt="" />
                    </span>
                    <div className="ps-3">
                      <h4 className="semibold pb-1">
                        {course?.attachtedFiles}
                      </h4>
                      <h6 className="fs-14 regular">Attached Files</h6>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 mb-3">
                  <div className="job-item  d-flex align-items-center bg-white">
                    <span className="hastag-icon bg-primary">
                      <img src="../../assets/img/icon/icon-time.svg" alt="" />
                    </span>
                    <div className="ps-3">
                      <h4 className="regular pb-1">
                        {FormatSeconds(course?.totalTimeLength)}
                      </h4>
                      {/* <h6 className="fs-14 regular">Hours</h6> */}
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 mb-3">
                  <div className="job-item  d-flex align-items-center bg-white">
                    <span className="hastag-icon bg-primary">
                      <img src="../../assets/img/icon/icon-levels.svg" alt="" />
                    </span>
                    <div className="ps-3">
                      <h4 className="semibold pb-1">{course?.level}</h4>
                      <h6 className="fs-14 regular">Course Level</h6>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6 mb-3">
                  <div className="job-item  d-flex align-items-center bg-white">
                    <span className="hastag-icon bg-primary">
                      <img
                        src="../../assets/img/icon/icon-students-white.svg"
                        alt=""
                      />
                    </span>
                    <div className="ps-3">
                      <h4 className="semibold pb-1">{course?.totalStudents}</h4>
                      <h6 className="fs-14 regular">Total Students</h6>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6 mb-3">
                  <div className="job-item  d-flex align-items-center bg-white">
                    <span className="hastag-icon bg-primary">
                      <img
                        src="../../assets/img/icon/icon-views-md.svg"
                        alt=""
                      />
                    </span>
                    <div className="ps-3">
                      <h4 className="semibold pb-1">{course?.studentsViewd}</h4>
                      <h6 className="fs-14 regular">Students Viewed</h6>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6 mb-3">
                  <div className="job-item  d-flex align-items-center bg-white">
                    <span className="hastag-icon bg-primary">
                      <img
                        src="../../assets/img/icon/icon-translate.svg"
                        alt=""
                      />
                    </span>
                    <div className="ps-3">
                      <h4 className="semibold pb-1">
                        {course?.course_language?.language}
                      </h4>
                      <h6 className="fs-14 regular">Course Language</h6>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-3 col-sm-6 mb-3">
                                <div className="job-item  d-flex align-items-center bg-white">
                                    <span className="hastag-icon bg-primary">
                                        <img src="../../assets/img/icon/icon-comment.svg" alt="" />
                                    </span>
                                    <div className="ps-3">
                                        <h4 className="semibold pb-1">70,520</h4>
                                        <h6 className="fs-14 regular">Comments</h6>
                                    </div>
                                </div>
                            </div> */}
              </div>
              <div className="row">
                <div className="col-md-6 my-2">
                  <div className="bg-white rounded-10 py-3">
                    <div className="d-flex align-items-center border-bottom pb-3 pt-2 justify-content-between px-3">
                      <h3 className="fs-22 medium">Revenue</h3>
                      <select
                        name=""
                        className="text-darkgray fs-14 w-auto"
                        id=""
                        onChange={(e) => {
                          handleRevenueChart(e.target.value);
                        }}
                      >
                        <option
                          selected={revenueType === "week" ? true : false}
                          value="week"
                        >
                          This Week{" "}
                        </option>
                        <option
                          selected={revenueType === "month" ? true : false}
                          value="month"
                        >
                          This Month
                        </option>
                        <option
                          selected={revenueType === "year" ? true : false}
                          value="year"
                        >
                          This Year
                        </option>
                      </select>
                    </div>

                    <ResponsiveContainer width="100%" aspect={1.4}>
                      <AreaChart
                        width={530}
                        height={650}
                        data={revenueData}
                        margin={{
                          top: 15,
                          right: 30,
                          left: 15,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="1 1" vertical={false} />
                        <XAxis
                          dataKey="name"
                          tickLine={true}
                          axisLine={true}
                          interval={revenueInterval}
                        />
                        <YAxis tickLine={false} axisLine={false} />
                        <Tooltip />
                        <Area
                          type="monotone"
                          dataKey="₹"
                          stroke="#4E73DF"
                          strokeWidth={4}
                          fill="#F3F7FC"
                          dot={{
                            stroke: "#4E73DF",
                            strokeWidth: 1,
                            r: 4,
                            strokeDasharray: "",
                          }}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>

                <div className="col-md-6 my-2">
                  <div className="bg-white rounded-10 py-3">
                    <div className="d-flex align-items-center border-bottom pb-3 pt-2 justify-content-between px-3">
                      <h3 className="fs-22 medium">Overall Rating</h3>
                      <select
                        name=""
                        className="text-darkgray fs-14 w-auto"
                        id=""
                        onChange={(e) => {
                          handleRatingsChart(e.target.value);
                        }}
                      >
                        <option
                          selected={ratingsType === "week" ? true : false}
                          value="week"
                        >
                          This Week{" "}
                        </option>
                        <option
                          selected={ratingsType === "month" ? true : false}
                          value="month"
                        >
                          This Month
                        </option>
                        <option
                          selected={ratingsType === "year" ? true : false}
                          value="year"
                        >
                          This Year
                        </option>
                      </select>
                    </div>

                    <div className="pt-3 m-0 pb-2 px-2 row">
                      <div className="col-md-4 px-2 pt-3 text-center mb-2 ">
                        <div className="course-rating-box py-2 px-2">
                          <h2 className="bold">
                            {totalRatingData?.rating?.toFixed(1)}
                          </h2>
                          <h6 className="regular fs-14 text-darkgray">
                            Out of 5
                          </h6>

                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">
                            {totalRatingData?.rating === undefined
                              ? RenderStar()
                              : RenderStar()}
                          </div>

                          <h6 className="regular fs-14 text-darkgray">
                            {totalRatingData?.totalRating} Ratings
                          </h6>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <ResponsiveContainer aspect={1.5}>
                          <AreaChart
                            // width={320}
                            // height={100}
                            data={totalRatingCount}
                            margin={{
                              top: 0,
                              right: 5,
                              left: 0,
                              bottom: 5,
                            }}
                          >
                            <Area
                              type="monotone"
                              dataKey="rating"
                              stroke="#FFA000"
                              fill="#FFF5E5"
                              strokeWidth={4}
                              dot={{
                                stroke: "#FFA000",
                                strokeWidth: 1,
                                r: 4,
                                strokeDasharray: "",
                              }}
                            />
                            <Tooltip />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                    </div>

                    <div className="px-2">
                      <div className="pb-1 row align-items-center mx-0">
                        <div className="col-md-5 d-flex align-items-center">
                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="18"
                              alt=""
                            />
                          </div>
                          <span className="text-black text-nowrap ps-1">
                            5 Star Rating
                          </span>
                        </div>
                        <div className="col-md-7">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="progress rounded-pill">
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{
                                  width:
                                    Math.round(
                                      totalRatingData?.fivePercentRating
                                    ) + "%",
                                }}
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <span className="text-black text-nowrap">
                              {Math.round(totalRatingData?.fivePercentRating)}%
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="pb-1 row align-items-center mx-0">
                        <div className="col-md-5 d-flex align-items-center">
                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                          </div>
                          <span className="text-black text-nowrap ps-1">
                            4 Star Rating
                          </span>
                        </div>
                        <div className="col-md-7">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="progress rounded-pill">
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{
                                  width:
                                    Math.round(
                                      totalRatingData?.fourPercentRating
                                    ) + "%",
                                }}
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <span className="text-black text-nowrap">
                              {Math.round(totalRatingData?.fourPercentRating)}%
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="pb-1 row align-items-center mx-0">
                        <div className="col-md-5 d-flex align-items-center">
                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                          </div>
                          <span className="text-black text-nowrap ps-1">
                            3 Star Rating
                          </span>
                        </div>
                        <div className="col-md-7">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="progress rounded-pill">
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{
                                  width:
                                    Math.round(
                                      totalRatingData?.threePercentRating
                                    ) + "%",
                                }}
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <span className="text-black text-nowrap">
                              {Math.round(totalRatingData?.threePercentRating)}%
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="pb-1 row align-items-center mx-0">
                        <div className="col-md-5 d-flex align-items-center">
                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                          </div>
                          <span className="text-black text-nowrap ps-1">
                            2 Star Rating
                          </span>
                        </div>
                        <div className="col-md-7">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="progress rounded-pill">
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{
                                  width:
                                    Math.round(
                                      totalRatingData?.twoPercentRating
                                    ) + "%",
                                }}
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <span className="text-black text-nowrap">
                              {Math.round(totalRatingData?.twoPercentRating)}%
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="pb-1 row align-items-center mx-0">
                        <div className="col-md-5 d-flex align-items-center">
                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">
                            <img
                              src="../assets/img/icon/State-fill.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                          </div>
                          <span className="text-black text-nowrap ps-1">
                            1 Star Rating
                          </span>
                        </div>
                        <div className="col-md-7">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="progress rounded-pill">
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{
                                  width:
                                    Math.round(
                                      totalRatingData?.onePercentRating
                                    ) + "%",
                                }}
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <span className="text-black text-nowrap">
                              {Math.round(totalRatingData?.onePercentRating)}%
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="bg-white rounded-10 py-3 ">
                    <div className="d-flex align-items-center border-bottom pb-3 pt-2 justify-content-between px-3">
                      <h3 className="fs-22 medium">Courses Views</h3>
                      <select
                        name=""
                        className="text-darkgray fs-14 w-auto"
                        id=""
                        onChange={(e) => {
                          handleCourseViewChart(e.target.value);
                        }}
                      >
                        <option
                          selected={courseViewsType === "week" ? true : false}
                          value="week"
                        >
                          This Week{" "}
                        </option>
                        <option
                          selected={courseViewsType === "month" ? true : false}
                          value="month"
                        >
                          This Month
                        </option>
                        <option
                          selected={courseViewsType === "year" ? true : false}
                          value="year"
                        >
                          This Year
                        </option>
                      </select>
                    </div>
                    <ResponsiveContainer width="100%" height={450} aspect={1.5}>
                      <AreaChart
                        width={530}
                        height={300}
                        data={courseViewsCount}
                        margin={{ top: 20, right: 30, left: 0, bottom: 20 }}
                      >
                        <defs>
                          <linearGradient
                            id="colorPv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="0%"
                              stopColor="#E8F0FA"
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="100%"
                              stopColor="#E8F0FA"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <XAxis
                          dataKey="name"
                          height={300}
                          tickLine={false}
                          axisLine={false}
                          interval={courseViewsInterval}
                        />
                        <YAxis
                          height={300}
                          tickFormatter={(value) => parseInt(value)}
                          tickLine={false}
                          axisLine={false}
                          domain={[300, "dataMax"]}
                        />
                        <Tooltip />

                        <Area
                          type="monotone"
                          dataKey={"view"}
                          stroke="#0C64C5"
                          strokeWidth={3}
                          fillOpacity={1}
                          fill="url(#colorPv)"
                          dot={{
                            stroke: "#0C64C5",
                            strokeWidth: 2,
                            r: 4,
                            strokeDasharray: "",
                          }}
                        />

                        {/* <Area type="monotone" dataKey="pv" stroke="#BC7638" strokeWidth={3} fillOpacity={1} fill="url(#colorPv)" dot={{ stroke: '#BC7638', strokeWidth: 2, r: 4, strokeDasharray: '' }} /> */}
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
            <DeletePrompt
              type={"Course"}
              item={item}
              deleteType={handleDeleteCourses}
            />
          </div>
          {/* </div> */}
          <OnlineFriends />
        </div>
      )}
    </Container>
  );
}
