import React, { useEffect, useState } from "react";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Profile from "../Teacher/Profile";
import { Endpoints } from "../../../API/Endpoints";
import { FetchApi } from "../../../API/FetchApi";
import ToastMessage from "../../../Utils/ToastMessage";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import InvoiceBtn from "./InvoiceBtn";
import ReceiptBtn from "./ReceiptBtn";
import { Helmet } from "react-helmet";
export default function Purachase() {
  const [purchase, setPurchase] = useState([]);
  const [expanded, setExpanded] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    getPurchaseHistory();
  }, []);

  const getPurchaseHistory = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.GetPurchaseHistory + "?" + "size=" + 12
      );
      if (resp && resp.success === true) {
        setPurchase(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Purachase</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-3">
            <Profile type="student" active={"purchase"} />

            <div className="rounded-15 box-shadow p-md-4 p-2 bg-white">
              <div className="main-top-row d-md-flex justify-content-between align-items-center pb-3">
                <h3 className="medium">Purchase History</h3>

                {/* <div className="d-sm-flex gap-3 ">
                                    <form action="" className="mainmenu-page-search d-flex flex-row-reverse align-items-center">
                                        <input type="text" placeholder="Search by order number" name="search" className="bg-transparent fs-14 medium  text-darkgray" />
                                        <button type="submit" className="bg-transparent border-0">
                                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="#949494" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                        </button>
                                    </form>

                                    <select name="sortby" id="sortby" className="text-darkgray">
                                        <option value="">Sort by</option>
                                        <option value="asc">Ascending</option>
                                        <option value="desc">Descending</option>
                                    </select>
                                </div> */}
              </div>

              <div className="mainmenu-filter-row pb-3">
                <ul className="filter d-flex flex-nowrap align-items-center gap-2 fs-14 medium">
                  {/* <li className="active " data-filter="all">All Orders</li> */}
                  {/* <li data-filter="mostrated" className="">Refunds <span className="fs-10 text-brown">15</span></li> */}
                </ul>
              </div>

              <div className="py-lg-2 py-2 rounded-10 box-shadow border">
                {purchase.map((item, index) => {
                  return (
                    <div
                      className="border-bottom py-3 px-lg-3 px-2"
                      key={index}
                    >
                      <div className="d-md-flex align-items-center justify-content-between">
                        <div>
                          <h5 className="medium pb-1">
                            Order #: {item.orderId}
                          </h5>
                          <div className="order-meta">
                            <span className="fs-14 text-333">
                              <img
                                src="assets/img/icon/icon-play-video3.svg"
                                alt=""
                              />
                              {item.courses.length} Courses
                            </span>

                            <span className="fs-14 text-333 ps-md-2 ps-1">
                              <img
                                src="assets/img/icon/icon-indian-currency.svg"
                                alt=""
                              />
                              {item.finalPrice}
                            </span>

                            <span className="fs-14 text-333 ps-md-2 ps-1">
                              <img
                                src="assets/img/icon/icon-calendar.svg"
                                alt=""
                              />
                              {/* 5 August, 2022 */}
                              {moment(item.createdAt).format("D MMMM YYYY")}
                            </span>

                            <span className="fs-14 text-333 ps-md-2 ps-1">
                              <img
                                src="assets/img/icon/icon-date-time2.svg"
                                alt=""
                              />
                              {/* 11:00 AM */}
                              {moment(item.createdAt).format("h:m A")}
                            </span>
                          </div>
                        </div>

                        <div>
                          <InvoiceBtn item={item} />
                          <ReceiptBtn item={item} />
                          <a
                            href="#"
                            data-bs-toggle="collapse"
                            data-bs-target={"#purchase" + index}
                            aria-expanded={expanded === 0 ? true : false}
                            aria-controls={"purchase" + index}
                            onClick={() => {
                              setExpanded(index);
                            }}
                            className="bg-eee d-inline-block ms-2 history-icon rounded-circle"
                          >
                            <img src="assets/img/icon/select-down.svg" alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="collapse show" id={"purchase" + index}>
                        <div className="card card-body border-0 p-md-3 py-2 px-1">
                          <div className="row pb-3 border-bottom">
                            {/* <div className="col-md-6">
                                                            <div className="card-data fs-16 medium d-flex align-items-center justify-content-between text-black">
                                                                <div>
                                                                    <img src="assets/img/icon/icon-card-mastercard.svg" alt="" />
                                                                    Master Card: ****1324
                                                                </div>
                                                                Exp. 11/22
                                                            </div>

                                                        </div> */}
                          </div>

                          <div className="py-3">
                            {item.courses.map((courseItem, i) => {
                              return (
                                <div className="row align-items-center mb-3">
                                  <div className="col-md-9 col-12 d-md-flex gap-3 align-items-center">
                                    {courseItem.thumbnail === null ? (
                                      <Link
                                        to={"/course-detail/" + courseItem.slug}
                                        target={"_blank"}
                                      >
                                        <img
                                          src="assets/img/course-thumb.svg"
                                          className="rounded-2 course-img course-image"
                                          alt=""
                                        />
                                      </Link>
                                    ) : (
                                      <Link
                                        to={"/course-detail/" + courseItem.slug}
                                        target={"_blank"}
                                      >
                                        <img
                                          src={
                                            Endpoints.baseUrl +
                                            courseItem.thumbnail
                                          }
                                          className="rounded-2 course-img course-image"
                                          style={{ width: "200px" }}
                                          alt=""
                                        />
                                      </Link>
                                    )}

                                    <div>
                                      <Link
                                        to={"/course-detail/" + courseItem.slug}
                                        target={"_blank"}
                                      >
                                        <h5 className="medium fs-18">
                                          {courseItem.title}
                                        </h5>
                                      </Link>

                                      <div className="py-2 d-flex w-100 align-items-center fs-14">
                                        <span className="text-brown ">
                                          {courseItem.avgRating}
                                        </span>
                                        <div className="d-flex mx-2">
                                          <ReactStars
                                            count={5}
                                            edit={false}
                                            value={courseItem.avgRating}
                                            size={24}
                                            isHalf={true}
                                            char={
                                              <img
                                                src="../assets/img/icon/state-null.svg"
                                                alt=""
                                                width="17"
                                              />
                                            }
                                            emptyIcon={
                                              <img
                                                src="../assets/img/icon/state-null.svg"
                                                alt=""
                                                width="17"
                                              />
                                            }
                                            halfIcon={
                                              <img
                                                src="../assets/img/icon/state-half.svg"
                                                alt=""
                                                width="17"
                                              />
                                            }
                                            filledIcon={
                                              <img
                                                src="../assets/img/icon/State-fill.svg"
                                                alt=""
                                                width="17"
                                              />
                                            }
                                            activeColor="#ffc107"
                                          />
                                        </div>
                                        <span className="text-brown ">
                                          ({courseItem.totalReviews} reviews)
                                        </span>
                                        <span className="medium text-black ps-md-3 ps-1">
                                          {courseItem.totalStudents}{" "}
                                          <span className="text-gray">
                                            Students
                                          </span>
                                        </span>
                                      </div>

                                      <h6 className="regular  fs-14">
                                        Created by{" "}
                                        <span className="text-brown">
                                          {courseItem.creatorId.fullName}
                                        </span>
                                      </h6>
                                    </div>
                                  </div>

                                  <div className="col-md-3 col-12 text-md-end text-center py-md-0 pt-2 pb-3 py-md-0 pt-2 pb-3">
                                    <Link
                                      to={"/start-learning/" + courseItem.id}
                                      className="btn fs-14 py-2"
                                    >
                                      Start Your Course{" "}
                                      <img src="assets/img/icon/play1.svg" />
                                    </Link>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
