import React, { useState, useRef, useMemo, useCallback } from "react";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import ToastMessage from "../../Utils/ToastMessage";
import { Link } from "react-router-dom";
import { EditorState, convertToRaw } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createMentionPlugin from "@draft-js-plugins/mention";
import createEmojiPlugin, { defaultTheme } from "@draft-js-plugins/emoji";
import EmojiPicker from "emoji-picker-react";
import "@draft-js-plugins/mention/lib/plugin.css";
import "@draft-js-plugins/emoji/lib/plugin.css";
import "@draft-js-plugins/linkify/lib/plugin.css";
import EntryComponent from "../../Components/PostShare/EntryComponent";
import SharePostModal from "../Post/SharePostModal";
import PostTitleDraftView from "../Post/PostTitleDraftView";

const $ = window.jQuery;
const postVisibilites = [
  {
    name: "Friends",
    value: "Friends",
    image: "../assets/img/icon/group-friend.svg",
    text: "Your connections on 8inNet",
  },
  {
    name: "Public",
    value: "Public",
    image: "../assets/img/icon/public.svg",
    text: "Anyone on or off 8inNet",
  },
  {
    name: "Friends Specific",
    value: "Friends Specific",
    image: "../assets/img/icon/friend.svg",
    text: "Only Show to some friends",
  },
];

export const CustomHastagComp = (hashtagProps) => {
  console.log({ hashtagProps });
  return (
    <a
      href={hashtagProps.mention.link}
      style={{
        padding: "3px 5px",
        backgroundColor: "white",
        color: "var(--blue)",
      }}
    >
      {hashtagProps.children}
    </a>
  );
};

export default function ShareModel({
  sharePost,
  getUserPosts,
  timeSince,
  key,
  setPostPage,
  setTimelinePosts,
  postPage,
  listInnerRef,
  formatDateTime,
  modalState,
  setModalState,
  setShareCounter,
  setShowModal,
  shareCounter,
  setReactionModalOpen,
  userProfile,
  type,
  mediaIndex,
  post,
}) {
  const [postVisibility, setPostVisibility] = useState("Public");
  const [editorState, setEditorState] = useState(() => {
    return EditorState.createEmpty();
  });
  const user = JSON.parse(localStorage.getItem("user"));
  const [postTitle, setPostTitle] = useState("");
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const emojiRef = useRef(null);
  const EditorinputRef = useRef(null);
  const [specificFrined, setSpecificFrined] = useState([]);
  const [vControl, setVControl] = useState(true);
  const handleContentChange = useCallback(
    (newContent) => {
      setEditorState(newContent);
      setPostTitle(newContent.getCurrentContent().getPlainText());
    },
    [setPostTitle]
  );

  // mention plugin implementation
  const [mentionOpen, setMentionOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  // hashtag plugin implementation
  const [hashtagOpen, setHashtagOpen] = useState(false);
  const [hashtagSuggestions, setHashtagSuggestions] = useState([]);

  const {
    MentionSuggestions,
    plugins,
    EmojiSuggestions,
    EmojiSelect,
    HashtagSuggestions,
  } = useMemo(() => {
    // @ mention
    const mentionPlugin = createMentionPlugin({
      mentionTrigger: ["@"],
      mentionPrefix: "",
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // # hashtag
    const hashtagPlugin = createMentionPlugin({
      mentionTrigger: ["#"],
      mentionComponent: CustomHastagComp,
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions: HashtagSuggestions } = hashtagPlugin;

    const emojiPlugin = createEmojiPlugin({
      theme: {
        ...defaultTheme,
        emojiSelect: "custom-emoji-select",
        emojiSelectButton: "custom-emoji-select-button",
        emojiSelectPopover:
          defaultTheme.emojiSelectPopover + " custom-emoji-select-popover",
      },
      selectButtonContent: (
        <>
          <img
            className="post-image"
            src="../assets/img/icon/emoji_icon.png"
            alt=""
          />
          Emoji
        </>
      ),
    });
    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

    const linkify = createLinkifyPlugin();

    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin, emojiPlugin, hashtagPlugin, linkify];
    return {
      plugins,
      MentionSuggestions,
      EmojiSuggestions,
      EmojiSelect,
      HashtagSuggestions,
    };
  }, []);
  const onOpenChange = useCallback((_open) => {
    setMentionOpen(_open);
  }, []);
  const onSearchChange = useCallback(({ value }) => {
    CommonAPi(Endpoints.MentionSearch + "?mention=" + value).then((res) => {
      if (res.data) {
        // setSuggestions(
        const userFriends = res?.data?.userFriends.map((item) => ({
          name: item.fullName,
          avatar: Endpoints.baseUrl + item.user_profile.profileImg,
          link: `/timeline/${item.username}`,
          id: `${item.id}`,
          type: "profile",
        }));
        const group = res?.data?.group.map((item) => ({
          name: item.groupName,
          avatar: Endpoints.baseUrl + item.logo,
          link: `/group-about/${item.slug}`,
          id: `${item.id}`,
          type: "group",
        }));
        const page = res?.data?.page.map((item) => ({
          name: item.title,
          avatar: Endpoints.baseUrl + item.pageImage,
          link: `/page-profile/${item.slug}`,
          id: `${item.id}`,
          type: "page",
        }));
        setSuggestions([...userFriends, ...group, ...page]);

        // );
      }
    });
  }, []);
  const onHashtagOpenChange = useCallback((_open) => {
    setHashtagOpen(_open);
  }, []);
  const onHashtagSearchChange = useCallback(({ value }) => {
    CommonAPi(Endpoints.searchHashtag + value).then((res) => {
      if (res.data) {
        let mappedResponse = res.data.map((item) => ({
          name: `#${item.tag}`,
          link: `/singlehashtag?tag=${item.tag}`,
        }));
        const index = mappedResponse.findIndex(
          (item) =>
            item.name.trim().slice(1).toLowerCase() ===
            value.trim().toLowerCase()
        );
        if (value && index === -1)
          mappedResponse = [
            { name: `#${value}`, link: `/singlehashtag?tag=${value}` },
            ...mappedResponse,
          ];
        setHashtagSuggestions(mappedResponse);
      }
    });
    // setHashtagSuggestions(defaultSuggestionsFilter(value, hashtags));
  }, []);

  const handleEmojiClick = (emojiData, MouseEvent) => {
    // setSelectedEmoji(emojiData.unified);
    setPostTitle((prev) => prev + emojiData.emoji);
  };

  const handlePrivacyStatus = (status) => {
    setPostVisibility(status);
    if (status === "Friends Specific") {
      $("#FriendsSpecificModal").modal("show");
      setShowModal(false);
    }
  };

  const shareSubmit = async (e) => {
    e.preventDefault();

    console.log("post______post", post);
    console.log("post______sharePost", sharePost);
    const formData = new FormData();
    const editorData = convertToRaw(editorState.getCurrentContent());
    formData.append("title", JSON.stringify(editorData));
    formData.append("postVisibility", postVisibility);
    if (postVisibility === "Friends Specific") {
      specificFrined.forEach((item) => formData.append("specificFriend", item));
    }
    if (type == "multipleImages") {
      formData.append("category", post.category);
      formData.append("post_id", post.id);
      formData.append("is_shared", post.id);
      formData.append("media_id", mediaIndex?.id);
    }
    if (type == "post" || type == "blog") {
      formData.append("category", sharePost.category);
      formData.append("post_id", sharePost.id);
      formData.append("is_shared", sharePost.id);
    }
    if (type == "singalImages") {
      formData.append("category", post.category);
      formData.append("post_id", sharePost.id);
      formData.append("is_shared", sharePost.id);
      formData.append("media_id", sharePost?.id);
    }

    try {
      let resp = await CommonAPi(Endpoints.createPost, formData);
      if (resp && resp.success === true) {
        ToastMessage.Success(resp.message);
        setModalState("hide");
        listInnerRef.current.scrollIntoView({ behavior: "smooth" });
        setTimelinePosts([]);
        setShareCounter(shareCounter + 1);
        if (postPage !== 1) setPostPage(0);
        else getUserPosts(1);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        ToastMessage.Error(e.response.data.message);
      }
    }
  };

  return (
    <div
      className={`modal fade ${modalState}`}
      id="shareModal"
      tabIndex="-1"
      aria-labelledby="kidmodal"
      aria-hidden="true"
      style={{ overflow: "auto" }}
    >
      <div class="modal-dialog rounded-10 box-shadow p-lg-4 p-md-3 p-2 bg-white mt-md-5">
        <div class="modal-content border-0">
          <div class="d-flex align-items-center justify-content-between pb-3 border-bottom mb-3 w-100">
            <h3 class="medium text-blue">Share this post </h3>
            <button
              type="button"
              class="btn-close kids-close-icon cursor-pointer"
              data-bs-dismiss="modal"
              onClick={() => {
                setModalState("hide");
                setReactionModalOpen("hide");
              }}
              aria-label="Close"
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="#949494"
                width="22"
                height="22"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div class="modal-body">
            <form
              action=""
              class="kids-form"
              enctype="multipart/form-data"
              onSubmit={shareSubmit}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <img
                    src={Endpoints.baseUrl + userProfile}
                    alt=""
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                    }}
                  />
                  <span
                    className="fs-14"
                    style={{ fontWeight: "500", marginLeft: "5px" }}
                  >
                    {user.fullName}
                  </span>
                </div>
                <div
                  className="privacy-box fs-12"
                  style={{ marginTop: "-20px" }}
                >
                  <span className="ms-auto d-flex align-items-center">
                    <div className=" w-100">
                      <Link
                        class="dropdown-toggle w-100"
                        type="button"
                        id="defaultDropdown"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="true"
                        aria-expanded="false"
                      >
                        <img
                          src="../assets/img/icon/icon-privacy.svg"
                          alt="privacy"
                        />{" "}
                        {postVisibility}
                      </Link>
                      <ul
                        class="dropdown-menu dropdown-menu-end"
                        aria-labelledby="defaultDropdown"
                      >
                        {postVisibilites.map((postVisible, i) => (
                          <li
                            key={postVisible.name}
                            className={
                              postVisibility == postVisible.value
                                ? "audience-status py-2"
                                : "py-2"
                            }
                          >
                            <Link
                              class="dropdown-item py-2 d-flex gap-2"
                              onClick={() => {
                                handlePrivacyStatus(postVisible.value);
                              }}
                            >
                              <div className="d-flex gap-3">
                                <img
                                  src={postVisible?.image}
                                  alt="privacy"
                                  style={{
                                    width: "32px",
                                  }}
                                />
                                <span className="fs-16">
                                  {postVisible.name}
                                  <br />
                                  <small className="fs-12">
                                    {postVisible.text}
                                  </small>
                                </span>
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </span>
                </div>
              </div>
              <div
                onClick={() => {
                  if (EditorinputRef.current) EditorinputRef.current.focus();
                }}
                className="py-3 post-editor-container postEditor"
                style={{ cursor: "text" }}
              >
                <Editor
                  editorKey={"editor"}
                  editorState={editorState}
                  onChange={handleContentChange}
                  plugins={plugins}
                  ref={EditorinputRef}
                />
                <MentionSuggestions
                  open={mentionOpen}
                  onOpenChange={onOpenChange}
                  suggestions={suggestions}
                  onSearchChange={onSearchChange}
                  entryComponent={EntryComponent}
                  onAddMention={() => {
                    // get the mention object selected
                  }}
                />
                <HashtagSuggestions
                  open={hashtagOpen}
                  onOpenChange={onHashtagOpenChange}
                  suggestions={hashtagSuggestions}
                  onSearchChange={onHashtagSearchChange}
                  entryComponent={EntryComponent}
                  onAddMention={() => {
                    // get the mention object selected
                  }}
                />
                <EmojiSuggestions />
              </div>

              <div className="status-attachment d-flex pb-2 border-top pt-2 fs-12 mb-0 ">
                <span
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <EmojiSelect />

                  {emojiPickerOpen && (
                    <EmojiPicker
                      onEmojiClick={handleEmojiClick}
                      ref={emojiRef}
                      lazyLoadEmojis={true}
                    />
                  )}
                </span>
              </div>

              <div
                className="prviewBox sharePreviewBox mt-3"
                style={{ overflow: "auto", height: "auto" }}
              >
                {type == "post" ? (
                  <SharePostModal
                    post={sharePost}
                    sharePost={sharePost}
                    key={key}
                    formatDateTime={formatDateTime}
                    timeSince={timeSince}
                    type={type}
                    mediaIndex={mediaIndex}
                  />
                ) : (
                  ""
                )}

                {type == "multipleImages" ? (
                  <SharePostModal
                    post={post}
                    sharePost={sharePost}
                    key={key}
                    formatDateTime={formatDateTime}
                    timeSince={timeSince}
                    type={type}
                    mediaIndex={mediaIndex}
                  />
                ) : (
                  ""
                )}
                {type == "singalImages" ? (
                  <SharePostModal
                    post={post}
                    sharePost={sharePost}
                    key={key}
                    formatDateTime={formatDateTime}
                    timeSince={timeSince}
                    type={type}
                    mediaIndex={mediaIndex}
                  />
                ) : (
                  ""
                )}

                {type == "blog" ? (
                  <SharePostModal
                    post={post}
                    sharePost={sharePost}
                    key={key}
                    formatDateTime={formatDateTime}
                    timeSince={timeSince}
                    type={type}
                    mediaIndex={mediaIndex}
                  />
                ) : (
                  ""
                )}
              </div>
              <div class="col-md-12 text-end mt-2">
                <button type="submit" class="btn fs-14 ">
                  Share
                  <img
                    src="../../assets/img/icon/icon-right-arrow.svg"
                    alt=""
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
