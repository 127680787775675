import React from 'react'
import { Link, useParams } from 'react-router-dom'

export default function ManageGroup() {
    const param = useParams();
  return (
    <div class="col-md-3 comany-dp-right">
                    
    <div class="sidebar-widget box-shadow p-0">
        <h4 class="medium border-bottom pb-2 mb-3 px-3 pt-3 pb-2 mb-3">Manage Group</h4>

        <ul class="fs-14 semibold text-gray mb-3 owner-sidebar">
            <li class="px-3 py-2 "><Link to={"/group-member/" + param?.id}  class="text-gray"><img src="../assets/img/icon/pending-posts.svg" class="me-2" />Members</Link></li>
            <li class="px-3 py-2 "><Link to={"/group-request/" + param?.id}  class="text-gray"><img src="../assets/img/icon/icon-request2.svg" class="me-2" />Requests</Link></li>
            {/* <li class="px-3 py-2"><Link class="text-gray"><img src="assets/img/icon/pending-posts.svg" class="me-2" />Pending Posts</Link></li>
            <li class="px-3 py-2"><Link class="text-gray"><img src="assets/img/icon/invited.svg" class="me-2" />Invited</Link></li> */}
            <li class="px-3 py-2"><Link to={"/group-block/" + param?.id}  class="text-gray"><img src="../assets/img/icon/icon-blocked.svg" class="me-2" />Blocked</Link></li>
        </ul>
      
    </div>


</div>
  )
}
