import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate, useLocation } from "react-router-dom";
import JobModel from "./JobModel";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import { Helmet } from "react-helmet";
const $ = window.jQuery;

export default function JobListing() {
  const param = useLocation().search.split("?")[1];
  const [jobData, setJobData] = useState([]);
  const [jobId, setjobId] = useState(0);
  const [limit, setLimit] = useState(9);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const pathName = useLocation();
  const [categoryData, setCategoryData] = useState([]);
  const [location, setLocation] = useState("");
  const [countryKey, setCountryKey] = useState(
    Math.ceil(Math.random() * 999999)
  );
  const [countryOption, setCountryOption] = useState([]);
  const [category, setCategory] = useState("");
  const [searchText, setSearchText] = useState("");

  const [jobtype, setJobtype] = useState("");
  const [date, setDate] = useState("");
  const [workmodel, setWorkmodel] = useState("");
  const [experience, setExperience] = useState("");
  const [education, setEducation] = useState("");
  const [searchKey, setSearchKey] = useState(Math.ceil(Math.random() * 999999));
  const [seacrhOption, setSearchOption] = useState([]);
  const industry = new URLSearchParams(useLocation().search).get("industry");
  const jobType = new URLSearchParams(useLocation().search).get("jobType");
  const featuredScope = new URLSearchParams(useLocation().search).get("scope");
  const Searchtitle = new URLSearchParams(useLocation().search).get("title");
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    getCompanyJobs();
    if (Searchtitle) {
      setSearchText({
        Searchtitle,
      });
    }
  }, [limit, param]);
  const getCompanyJobs = async () => {
    if (jobType) {
      if (pathName.pathname === "/featured-listing") {
        try {
          let resp = await FetchApi(
            Endpoints.JobsSearch + "?size=" + limit + "&" + param
          );
          if (resp && resp.success === true) {
            setJobData(resp?.data?.allJob);
            setCount(resp?.data?.totalRecord);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      } else {
        try {
          let resp = await FetchApi(Endpoints.JobsSearch + "?" + param);
          if (resp && resp.success === true) {
            setJobData(resp?.data?.allJob);
            setCount(resp?.data?.totalRecord);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      }
    }

    if (pathName.pathname === "/internship-listing") {
      if (param) {
        try {
          let resp = await FetchApi(
            Endpoints.JobsSearch + "?scope=" + "Internship" + "&" + param
          );
          if (resp && resp.success === true) {
            setJobData(resp?.data?.allJob);
            setCount(resp?.data?.totalRecord);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      } else {
        try {
          let resp = await FetchApi(
            Endpoints.JobsSearch + "?size=" + limit + "&scope=" + "Internship"
          );
          if (resp && resp.success === true) {
            setJobData(resp?.data?.allJob);
            setCount(resp?.data?.totalRecord);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      }
    }

    if (pathName.pathname === "/jobs-listing") {
      if (param) {
        try {
          let resp = await FetchApi(
            Endpoints.JobsSearch + "?scope=" + "Job" + "&" + param
          );
          if (resp && resp.success === true) {
            setJobData(resp?.data?.allJob);
            setCount(resp?.data?.totalRecord);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      } else {
        try {
          let resp = await FetchApi(Endpoints.JobsSearch + "?scope=" + "Job");
          if (resp && resp.success === true) {
            setJobData(resp?.data?.allJob);
            setCount(resp?.data?.totalRecord);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      }
    }

    if (pathName.pathname === "/featured-listing") {
      try {
        let resp = await FetchApi(
          Endpoints.JobsSearch + "?size=" + limit + "&" + param
        );
        if (resp && resp.success === true) {
          setJobData(resp?.data?.allJob);
          setCount(resp?.data?.totalRecord);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "year ago";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon ago";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + " day ago";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + " h ago";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min ago";
    return Math.floor(seconds) >= 0
      ? Math.floor(seconds) + " sec ago"
      : "0 sec ago";
  };

  const handleApplyJobs = (Id) => {
    setjobId(Id);
    $("#applyjobs").modal("show");
  };

  const handleOnSelectJob = (item) => {
    setSearchText(item[0].name);
  };

  const handleOnSearchJob = async (string) => {
    try {
      let resp = await FetchApi(Endpoints.JobsSearch + "?title=" + string);
      if (resp && resp.success === true) {
        const newData = resp.data?.allJob.map((item, index) => {
          if (index > 9) return null;
          return {
            id: item?.id,
            name: item?.title,
          };
        });
        setSearchOption(newData);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const RenderSearch = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-StudyArea"
        defaultInputValue={""}
        key={searchKey}
        onSearch={handleOnSearchJob}
        onChange={handleOnSelectJob}
        options={seacrhOption}
        placeholder={"Search by title or keyword"}
        labelKey="name"
        minLength={1}
        emptyLabel={"No result found"}
        className="ps-2 py-3 w-100"
        style={{ position: "absolute" }}
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [seacrhOption, searchKey]);
  const JobSave = async (Id) => {
    let data = {
      jobId: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.JobSave, data);
      if (resp && resp.success === true) {
        getCompanyJobs();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  useEffect(() => {
    getCategory();
    // getCountry();
  }, []);

  const getCategory = async () => {
    if (pathName.pathname === "/internship-listing") {
      try {
        let resp = await FetchApi(
          Endpoints.JobsIndustries + "?scope=Internship"
        );
        if (resp && resp.success === true) {
          setCategoryData(resp?.data);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      try {
        let resp = await FetchApi(Endpoints.JobsIndustries + "?scope=Job");
        if (resp && resp.success === true) {
          setCategoryData(resp?.data);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleOnSelectLocation = (item) => {
    if (item.length > 0) {
      setLocation(item[0].id);
    } else {
      setLocation("");
    }
  };

  const handleOnSearchLocation = async (string) => {
    try {
      let resp = await FetchApi(Endpoints.SearchLocation + "?term=" + string);
      if (resp && resp.success === true) {
        const newData = resp.data.map((item, index) => {
          return {
            id: item?.id,
            name: item?.city + ", " + item?.state?.state,
            stateId: item?.state?.id,
            stateName: item?.state?.state,
          };
        });
        setCountryOption(newData);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const RenderCountry = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-StudyArea"
        defaultInputValue={location}
        key={countryKey}
        onSearch={handleOnSearchLocation}
        onChange={handleOnSelectLocation}
        options={countryOption}
        placeholder={"Location"}
        labelKey="name"
        minLength={1}
        emptyLabel={"No result found"}
        className={""}
        style={{ position: "absolute" }}
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [countryOption, countryKey, location]);

  const handleSearch = () => {
    let url = "";
    if (pathName.pathname === "/internship-listing") {
      url = "/internship-listing?size=" + limit;
    }

    if (pathName.pathname === "/jobs-listing") {
      url = "/jobs-listing?size=" + limit;
    }
    if (pathName.pathname === "/featured-listing") {
      if (featuredScope === "featuredJobs") {
        url = "/featured-listing?scope=featuredJobs";
      } else {
        url = "/featured-listing?scope=featuredInternship";
      }
    }

    if (searchText) {
      url = url + "&title=" + searchText;
    }
    if (location) {
      url = url + "&cityId=" + location;
    }
    if (category) {
      url = url + "&industry=" + category;
    }
    if (jobtype) {
      url = url + "&jobType=" + jobtype;
    }
    if (date) {
      url = url + "&recentJobs=" + date;
    }
    if (workmodel) {
      url = url + "&workMode=" + workmodel;
    }
    if (experience) {
      url = url + "&experience=" + experience;
    }
    if (education) {
      url = url + "&qualification=" + education;
    }
    navigate(url);
  };
  const SearchJobType = new URLSearchParams(useLocation().search).get(
    "jobType"
  );
  const SearchRecentJobs = new URLSearchParams(useLocation().search).get(
    "recentJobs"
  );
  const SearchWorkMode = new URLSearchParams(useLocation().search).get(
    "workMode"
  );
  const SearchExperience = new URLSearchParams(useLocation().search).get(
    "experience"
  );
  const SearchQualification = new URLSearchParams(useLocation().search).get(
    "qualification"
  );

  const handleSorting = (type, value) => {
    let url;
    if (pathName.pathname === "/featured-listing") {
      // url = "/featured-listing?"+param;
      if (featuredScope === "featuredJobs") {
        url = "/featured-listing?scope=featuredJobs";
      } else {
        url = "/featured-listing?scope=featuredInternship";
      }
    } else {
      url = pathName.pathname + "?size=" + limit;
    }

    if (searchText) {
      url = url + "&title=" + searchText;
    }
    if (location) {
      url = url + "&cityId=" + location;
    }
    if (category) {
      url = url + "&industry=" + category;
    }

    if (type === "jobtype") {
      if (SearchJobType && SearchJobType === value) {
        setJobtype(jobType);
        url = url + "&jobType=" + SearchJobType;
      } else {
        if (value) {
          setJobtype(value);
          url = url + "&jobType=" + value;
        } else {
          setJobtype("");
          url = url;
        }
      }
    } else {
      if (jobType) {
        setJobtype(jobType);
        url = url + "&jobType=" + SearchJobType;
      }
    }
    if (type === "date") {
      if (SearchRecentJobs && SearchRecentJobs === value) {
        setDate(SearchRecentJobs);
        url = url + "&recentJobs=" + SearchRecentJobs;
      } else {
        if (value) {
          setDate(value);
          url = url + "&recentJobs=" + value;
        } else {
          setDate("");
          url = url;
        }
      }
    } else {
      if (SearchRecentJobs) {
        setDate(SearchRecentJobs);
        url = url + "&recentJobs=" + SearchRecentJobs;
      }
    }
    if (type === "workmodel") {
      if (SearchWorkMode && SearchWorkMode === value) {
        setWorkmodel(SearchWorkMode);
        url = url + "&workMode=" + SearchWorkMode;
      } else {
        if (value) {
          setWorkmodel(value);
          url = url + "&workMode=" + value;
        } else {
          setWorkmodel("");
          url = url;
        }
      }
    } else {
      if (SearchWorkMode) {
        setWorkmodel(SearchWorkMode);
        url = url + "&workMode=" + SearchWorkMode;
      }
    }
    if (type === "experience") {
      if (SearchExperience && SearchExperience === value) {
        setExperience(SearchExperience);
        url = url + "&experience=" + SearchExperience;
      } else {
        if (value) {
          setExperience(value);
          url = url + "&experience=" + value;
        } else {
          setExperience("");
          url = url;
        }
      }
    } else {
      if (SearchExperience) {
        setExperience(SearchExperience);
        url = url + "&experience=" + SearchExperience;
      }
    }

    if (type === "qualification") {
      if (SearchQualification && SearchQualification === value) {
        setEducation(SearchQualification);
        url = url + "&qualification=" + SearchQualification;
      } else {
        if (value) {
          setEducation(value);
          url = url + "&qualification=" + value;
        } else {
          setEducation("");
          url = url;
        }
      }
    } else {
      if (SearchQualification) {
        setEducation(SearchQualification);
        url = url + "&qualification=" + SearchQualification;
      }
    }
    navigate(url);
  };

  const resetFilter = () => {
    // setJobtype('');
    setEducation("");
    setExperience("");
    setWorkmodel("");
    setDate("");
    setSearchText("");
    setCategory("");
    setCountryKey(Math.ceil(Math.random() * 999999));
    setSearchKey(Math.ceil(Math.random() * 999999));
    setCountryOption([]);
    setSearchOption([]);
    setLocation("");
    if (pathName.pathname === "/internship-listing") {
      navigate("/internship-listing?size=9");
    } else if (pathName.pathname === "/jobs-listing") {
      navigate("/jobs-listing?size=9");
    } else {
      if (featuredScope === "featuredJobs") {
        navigate("/featured-listing?scope=featuredJobs");
      } else {
        navigate("/featured-listing?scope=featuredInternship");
      }
    }

    getCompanyJobs();
  };

  const getIndusrtyData = (id) => {
    const result = categoryData.find((item) => item?.id == id);
    if (result) {
      return result?.value;
    } else {
      return null;
    }
  };
  return (
    <Container header>
      <Helmet>
        <title>Listing</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />

          <div className="container py-3">
            <div className="bg-white p-3 rounded-15 box-shadow mb-4 ">
              <div className="job-search-form">
                <div className="row mx-0 bg-white rounded-pill  box-shadow border mb-3">
                  <div className="d-flex align-items-center ps-3 col-md-4">
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      stroke="#949494"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="css-i6dzq1"
                    >
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    {RenderSearch()}
                  </div>

                  <div className="d-flex align-items-center border-start border-end px-3 col-md-3">
                    <img
                      src="../assets/img/icon/icon-location.svg"
                      width="22"
                      alt=""
                    />
                    {RenderCountry()}
                  </div>

                  <div className="col-md-3 d-flex align-items-center ">
                    <select
                      name=""
                      id=""
                      className="py-3 bg-white"
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                    >
                      <option value="">Select Industry</option>
                      {categoryData.map((item, index) => {
                        return (
                          <option
                            value={item?.id}
                            selected={industry == item?.id ? true : false}
                            key={index}
                          >
                            {item?.value}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <button
                    className="btn btn-brown px-4 py-3 rounded-start rounded-pill col-md-2"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>

                <div className="main-top-row gap-lg-3 d-lg-flex">
                  {pathName.pathname === "/internship-listing" ? (
                    <select
                      name="jobtype"
                      id="jobtype"
                      className="text-darkgray mb-md-0 mb-2"
                      onChange={(e) => {
                        setJobtype(e.target.value);
                        handleSorting("jobtype", e.target.value);
                      }}
                    >
                      <option value="">Job Type</option>
                      <option
                        value="Internship"
                        selected={jobtype === "Internship" ? true : false}
                      >
                        Internship
                      </option>
                    </select>
                  ) : (
                    <select
                      name="jobtype"
                      id="jobtype"
                      className="text-darkgray mb-md-0 mb-2"
                      onChange={(e) => {
                        setJobtype(e.target.value);
                        handleSorting("jobtype", e.target.value);
                      }}
                    >
                      <option value="">Job Type</option>
                      <option
                        value="Full-time"
                        selected={jobtype === "Full-time" ? true : false}
                      >
                        Full-time
                      </option>
                      <option
                        value="Part-time"
                        selected={jobtype === "Part-time" ? true : false}
                      >
                        Part-time
                      </option>
                      <option
                        value="Contract"
                        selected={jobtype === "Contract" ? true : false}
                      >
                        {" "}
                        Contract
                      </option>
                      <option
                        value="Temporary"
                        selected={jobtype === "Temporary" ? true : false}
                      >
                        Temporary
                      </option>
                      <option
                        value="Freelance"
                        selected={jobtype === "Freelance" ? true : false}
                      >
                        Freelance
                      </option>
                    </select>
                  )}

                  <select
                    name="date"
                    id="date"
                    className="text-darkgray mb-md-0 mb-2"
                    onChange={(e) => {
                      setDate(e.target.value);
                      handleSorting("date", e.target.value);
                    }}
                  >
                    <option value="">Date Posted</option>
                    <option
                      value="week-ago"
                      selected={date === "week-ago" ? true : false}
                    >
                      Week-ago
                    </option>
                    <option
                      value="month-ago"
                      selected={date === "month-ago" ? true : false}
                    >
                      Month-ago
                    </option>
                  </select>

                  <select
                    name="workmodel"
                    id="workmodel"
                    className="text-darkgray mb-md-0 mb-2"
                    onChange={(e) => {
                      setWorkmodel(e.target.value);
                      handleSorting("workmodel", e.target.value);
                    }}
                  >
                    <option value="">Work Model</option>
                    <option
                      value="On-site"
                      selected={workmodel === "On-site" ? true : false}
                    >
                      On-site
                    </option>
                    <option
                      value="Remote"
                      selected={workmodel === "Remote" ? true : false}
                    >
                      Remote
                    </option>
                    <option
                      value="Hybrid"
                      selected={workmodel === "Hybrid" ? true : false}
                    >
                      Hybrid
                    </option>
                    <option
                      value="Work from home"
                      selected={workmodel === "Work from home" ? true : false}
                    >
                      Work from home
                    </option>
                  </select>

                  <select
                    name="experience"
                    id="experience"
                    className="text-darkgray mb-md-0 mb-2"
                    onChange={(e) => {
                      setExperience(e.target.value);
                      handleSorting("experience", e.target.value);
                    }}
                  >
                    <option value="">Experience Level</option>
                    <option
                      value="1"
                      selected={experience === "1" ? true : false}
                    >
                      1 Year
                    </option>
                    <option
                      value="2"
                      selected={experience === "2" ? true : false}
                    >
                      2 Year
                    </option>
                    <option
                      value="3"
                      selected={experience === "3" ? true : false}
                    >
                      3 Year
                    </option>
                    <option
                      value="4"
                      selected={experience === "4" ? true : false}
                    >
                      4 Year
                    </option>
                    <option
                      value="5"
                      selected={experience === "5" ? true : false}
                    >
                      5 Year
                    </option>
                    <option
                      value="6"
                      selected={experience === "6" ? true : false}
                    >
                      6 Year
                    </option>
                    <option
                      value="7"
                      selected={experience === "7" ? true : false}
                    >
                      7 Year
                    </option>
                    <option
                      value="8"
                      selected={experience === "8" ? true : false}
                    >
                      8 Year
                    </option>
                    <option
                      value="9"
                      selected={experience === "9" ? true : false}
                    >
                      9 Year
                    </option>
                    <option
                      value="10"
                      selected={experience === "10" ? true : false}
                    >
                      10 Year
                    </option>
                  </select>

                  <select
                    name="education"
                    id="education"
                    className="text-darkgray mb-md-0 mb-2"
                    onChange={(e) => {
                      setEducation(e.target.value);
                      handleSorting("qualification", e.target.value);
                    }}
                  >
                    <option value="">Qualification</option>
                    <option
                      value="10th Pass"
                      selected={education === "10th Pass" ? true : false}
                    >
                      10th Pass
                    </option>
                    <option
                      value="12th Pass"
                      selected={education === "12th Pass" ? true : false}
                    >
                      12th Pass
                    </option>
                    <option
                      value="Diploma or Equivalent"
                      selected={
                        education === "Diploma or Equivalent" ? true : false
                      }
                    >
                      Diploma or Equivalent
                    </option>
                    <option
                      value="Associate's Degree"
                      selected={
                        education === "Associate's Degree" ? true : false
                      }
                    >
                      Associate's Degree
                    </option>
                    <option
                      value="Bachelor's Degree"
                      selected={
                        education === "Bachelor's Degree" ? true : false
                      }
                    >
                      Bachelor's Degree
                    </option>
                    <option
                      value="Master's Degree"
                      selected={education === "Master's Degree" ? true : false}
                    >
                      Master's Degree
                    </option>
                    <option
                      value="Ph.D"
                      selected={education === "Ph.D" ? true : false}
                    >
                      Ph.D
                    </option>
                    <option
                      value="Professional Degree"
                      selected={
                        education === "Professional Degree" ? true : false
                      }
                    >
                      Professional Degree
                    </option>
                    <option
                      value="Apprenticeships"
                      selected={education === "Apprenticeships" ? true : false}
                    >
                      Apprenticeships
                    </option>
                    <option
                      value="Internships"
                      selected={education === "Internships" ? true : false}
                    >
                      Internships
                    </option>
                  </select>

                  <button
                    type="reset"
                    className="btn darkgray-btn fs-14 px-4 ms-auto reset-job-filter"
                    onClick={resetFilter}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-white main-common mt-4">
              <div class="d-md-flex align-items-center justify-content-between pb-4">
                <div>
                  {getIndusrtyData(industry) ? (
                    <span class="fs-12 medium text-gray ">
                      {pathName.pathname === "/internship-listing"
                        ? "Internship in"
                        : "Jobs in"}{" "}
                      <span class="text-blue medium text-right">
                        {getIndusrtyData(industry)}{" "}
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <span class="fs-12 text-gray ">
                    <span class="text-blue medium text-right">{count}</span>{" "}
                    results found
                  </span>
                </div>
              </div>
              {jobData.length > 0 ? (
                <div className="row">
                  {jobData.map((item, index) => {
                    return (
                      <div className="col-md-4 mb-3" key={index}>
                        <div className="job-featured-item d-flex gap-2 align-items-start w-100">
                          <span className="circular--landscape pic-size-48-flex">
                            <Link to={"/jobs/" + item?.id}>
                              {item?.pageImage?.pageImage === null ? (
                                <img
                                  src="assets/img/company.svg"
                                  alt=""
                                  width="64"
                                  height="64"
                                  className="rounded-circle"
                                />
                              ) : (
                                <img
                                  src={
                                    Endpoints.baseUrl +
                                    item?.pageImage?.pageImage
                                  }
                                  alt=""
                                  width="64"
                                  height="64"
                                  className="rounded-circle"
                                />
                              )}
                            </Link>
                          </span>
                          <div className="w-100">
                            <div className="d-flex justify-content-between w-100">
                              <Link to={"/jobs/" + item?.id}>
                                <h5 className="semibold">{item?.title}</h5>
                              </Link>
                              {item?.isSaved === "true" ? (
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    JobSave(item?.id);
                                  }}
                                >
                                  <img
                                    src="assets/img/icon/icon-wishlist-fill.svg"
                                    className="cursor-pointer"
                                    width="19"
                                    alt=""
                                  />
                                </span>
                              ) : (
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    JobSave(item?.id);
                                  }}
                                >
                                  <img
                                    src="assets/img/icon/icon-wishlist.svg"
                                    className="cursor-pointer"
                                    width="19"
                                    alt=""
                                  />
                                </span>
                              )}
                            </div>
                            <Link to={"/page-profile/" + item?.pageImage?.slug}>
                              <h5 class="medium text-brown ">
                                {item?.pageImage?.title}
                              </h5>
                            </Link>

                            <div className="d-flex py-2 align-items-center">
                              <span className="fs-10 text-darkgray pe-2 d-flex align-items-center">
                                <img
                                  src="assets/img/icon/icon-location.svg"
                                  alt=""
                                  className="align-middle me-1"
                                />
                                <span>
                                  {item?.city?.city}
                                  {item?.city?.city ? "," : ""}{" "}
                                  {item?.state?.state}
                                </span>
                              </span>

                              <span className="fs-10 text-darkgray d-flex align-items-center">
                                <img
                                  src="assets/img/icon/icon-date-time.svg"
                                  alt=""
                                  className="align-middle me-1"
                                />
                                <span>
                                  {timeSince(new Date(item.createdAt))}
                                </span>
                              </span>
                            </div>

                            <div className="d-flex request-btn gap-2 mb-2">
                              <a
                                href="#"
                                className="btn light-brown-btn px-2 medium"
                              >
                                {item?.jobType}
                              </a>
                              <a
                                href="#"
                                className="btn light-brown-btn px-2 medium"
                              >
                                {item?.workMode}
                              </a>
                            </div>
                            {userId != item.userId ? (
                              item?.alreadyApplied == "true" ? (
                                <div class="btn px-4 py-2 fs-14 gray-btn ">
                                  Applied
                                </div>
                              ) : (
                                <Link
                                  onClick={() => {
                                    setjobId(item?.id);
                                    handleApplyJobs(item?.id);
                                  }}
                                  className="btn px-4 py-2 fs-14 "
                                >
                                  Apply Now{" "}
                                  <img
                                    src="assets/img/icon/icon-briefcase.svg"
                                    className="ps-2"
                                  />
                                </Link>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="row">
                  {pathName.pathname === "/internship-listing"
                    ? "No internship found"
                    : "No Jobs found"}
                </div>
              )}
              {limit < count ? (
                <div className="text-center py-3">
                  <button
                    onClick={() => {
                      setLimit(limit + 9);
                    }}
                    className="btn px-4"
                  >
                    View More Jobs
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
      <JobModel
        {...{
          jobId: jobId,
          onSuccess: getCompanyJobs,
        }}
      />
    </Container>
  );
}
