import moment from "moment";
import { Endpoints } from "../../API/Endpoints";
import { useCallback, useMemo } from "react";
import { CommonAPi } from "../../API/CommonApi";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, Navigate } from "react-router-dom";
import { DeleteApi } from "../../API/DeleteApi";

export default function StoryOverlay({
  showStoryViews,
  stories,
  currentStoriesIndex,
  currentStoryIndex,
  isCurerntUserStory,
  setShowStoryViews,
  activity,
  countDown,
  user,
  handlePlay,
  handlePause,
  playing,
  muted,
  handleMuteUnmute,
  storyDuration,
  videoBuffering,
  onClose,
}) {
  const handleShowStoryViews = useCallback(() => {
    setShowStoryViews(true);
    handlePause();
  }, [handlePause, setShowStoryViews]);

  const handleCloseStoryViews = useCallback(() => {
    setShowStoryViews(false);
    handlePlay();
  }, [setShowStoryViews, handlePlay]);

  const sendFriendRequest = useCallback(async (Id) => {
    let data = {
      receiverId: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.SendFriendRequest, data);
      if (resp?.success) {
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e?.response?.data?.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  }, []);

  const deleteStory = useCallback(async () => {
    handlePause();
    const userStory =
      stories.records[currentStoriesIndex].stories[currentStoryIndex];
    try {
      const res = await DeleteApi(Endpoints.deleteStory + userStory.id);
      if (res?.success) {
        ToastMessage.Success(res.message);
        onClose();
      }
    } catch (e) {
      if (e?.response?.data?.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  }, [currentStoriesIndex, stories, currentStoryIndex, onClose, handlePause]);

  const friendsReactions = useMemo(
    () =>
      activity?.records.filter(
        (filteringUser) =>
          filteringUser.isFriend === "ACCEPTED" && filteringUser.id !== user.id
      ),
    [activity, user]
  );

  const nonFriendsReactions = useMemo(
    () =>
      activity?.records.filter(
        (filteringUser) =>
          filteringUser.isFriend !== "ACCEPTED" && filteringUser.id !== user.id
      ),
    [activity, user]
  );

  const userStory = useMemo(
    () => stories.records[currentStoriesIndex],
    [stories, currentStoriesIndex]
  );

  const viewCount = useMemo(() => {
    const count = activity?.totalRecords;
    const index = activity?.records.findIndex((item) => item.id === user.id);
    if (count > 0 && index !== -1) {
      return count - 1;
    }
    return count;
  }, [activity, user]);

  const reactionGiven = useMemo(() => {
    const arr = [];
    let count = 0;

    activity?.records.forEach((item) => {
      // excluding own reaction
      if (item.id !== user.id && item.reaction) {
        count++;
        const index = arr.findIndex((reaction) => reaction === item.reaction);
        if (index === -1) {
          arr.push(item.reaction);
        }
      }
    });

    return {
      uniqueItem: arr,
      count,
    };
  }, [activity, user]);

  return (
    <>
      {!showStoryViews && (
        <div className="story-content-overlay">
          <div className="story-progress-container">
            {userStory.stories.map((story, index) => {
              let width = 0;
              if (currentStoryIndex > index) width = "100%";
              else if (currentStoryIndex === index)
                width = ((countDown / 1000) * 100) / storyDuration + "%";
              return (
                <div key={story.id} className="story-progress-bar">
                  <div
                    className="story-progress"
                    style={{
                      width,
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className="story-user-info">
          <Link to={"/timeline/" + userStory?.username}>
            <img
              className="profile-img"
              src={Endpoints.baseUrl + userStory.user_profile.profileImg}
              alt="profile"
            />
          </Link>
          
            <div className="d-flex flex-column flex-fill">
            <Link to={"/timeline/" + userStory?.username}>
              <p className="user-name">{userStory.fullName}</p>
              </Link>
              <div className="story-metadata">
                <p className="m-0 time">
                  {moment(
                    userStory.stories[currentStoryIndex].createdAt
                  ).fromNow(true)}
                </p>
                <div className="dot" />
                <img
                  src={process.env.PUBLIC_URL + "/assets/svgs/privacy-icon.svg"}
                  alt="visibility"
                />
              </div>
            </div>
            {isCurerntUserStory && (
              <>
                <button
                  aria-haspopup="true"
                  aria-label="story-actions"
                  data-bs-toggle="dropdown"
                  className="stories-overlay-button"
                  onFocus={handlePause}
                  onBlur={handlePlay}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/svgs/options.svg"}
                    alt="options"
                  />
                </button>
                <ul
                  className="dropdown-menu shadow rounded-1 text-start"
                  role="menu"
                  id="story-actions"
                  aria-hidden="true"
                  style={{ minWidth: "unset" }}
                >
                  <li>
                    <button className="dropdown-item" onClick={deleteStory}>
                      Delete
                    </button>
                  </li>
                </ul>
              </>
            )}
          </div>
          {userStory.stories[currentStoryIndex].storyType === "video" &&
            !videoBuffering && (
              <div className="d-flex flex-column align-items-end">
                <button
                  onClick={playing ? handlePause : handlePlay}
                  className="stories-overlay-button"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/svgs/${
                      playing ? "pause.svg" : "play.svg"
                    }`}
                    alt="play-pause"
                  />
                </button>
                <button
                  onClick={handleMuteUnmute}
                  className="stories-overlay-button"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/svgs/${
                      muted ? "mute.svg" : "volume.svg"
                    }`}
                    alt="play-pause"
                  />
                </button>
              </div>
            )}
          {isCurerntUserStory && (
            <button
              onClick={handleShowStoryViews}
              className="current-user-story"
            >
              {!!reactionGiven.count && (
                <div className="activity">
                  <span>Activity</span>
                  <div className="d-flex">
                    {reactionGiven.uniqueItem.map((reaction) => {
                      return (
                        <div key={reaction} className="reaction">
                          <img
                            src={getReactionImage(reaction)}
                            alt={reaction}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <span>
                    {reactionGiven.count > 100 ? "100+" : reactionGiven.count}
                  </span>
                </div>
              )}
              <div className="views">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/svgs/views.svg`}
                  alt="views"
                />
                <span>{viewCount}</span>
              </div>
            </button>
          )}
          {videoBuffering && (
            <div
              className="position-absolute top-0 bottom-0 start-0 end-0 d-flex justify-content-center align-items-center"
              style={{ background: "rgba(0,0,0,0.4)" }}
            >
              <div class="spinner-border text-light" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="">

    
      {showStoryViews && (
        <div className="story-views-overlay  overflow-auto">
          <div className="story-views-header">
            <p>Views/Reactions</p>
            <button
              onClick={handleCloseStoryViews}
              className="story-views-close-button"
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/svgs/close-modal.svg`}
                alt="close-modal"
              />
            </button>
          </div>
          <div className="story-views-container">
            <div className="story-view-columns">
              <div className="d-flex">
                <p className="m-0">Viewers</p>
                <span className="story-view-viewers-count">{viewCount}</span>
              </div>
              <p>Reactions</p>
            </div>
            {!!friendsReactions?.length && (
              <div className="user-views-section">
                <p className="story-views-section-title">Connections</p>
                {friendsReactions.map((friend) => {
                  return (
                    <div
                      key={friend.id}
                      className="story-views-user-reaction-container"
                    >
                      <div className="story-views-user-info-container">
                        <img
                          src={`${Endpoints.baseUrl}${friend.user_profile.profileImg}`}
                          alt="user"
                        />
                        <div className="story-views-user-info">
                          <p>{friend.fullName}</p>
                          <p>{friend.user_profile?.profileTagline}</p>
                        </div>
                      </div>
                      {friend.reaction && (
                        <img
                          src={getReactionImage(friend.reaction)}
                          alt="reaction"
                          className="story-views-user-reaction"
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            {!!nonFriendsReactions?.length && (
              <div className="user-views-section">
                <p className="story-views-section-title">
                  Not in your friendlist
                </p>
                {nonFriendsReactions.map((nonFriend) => {
                  return (
                    <div
                      key={nonFriend.id}
                      className="story-views-user-reaction-container"
                    >
                      <div className="story-views-user-info-container">
                        <img
                          src={`${Endpoints.baseUrl}${nonFriend.user_profile.profileImg}`}
                          alt="user"
                        />
                        <div className="story-views-user-info">
                          <p>{nonFriend.fullName}</p>
                          <p>{nonFriend.user_profile?.profileTagline}</p>
                        </div>
                      </div>
                      <div className="story-views-add-friend-and-reaction">
                        {nonFriend.id !== user.id && !nonFriend.isFriend && (
                          <button
                            onClick={() => sendFriendRequest(nonFriend.id)}
                          >
                            Add Friend{" "}
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/svgs/add-friend.svg`}
                              alt="add-friend"
                            />
                          </button>
                        )}
                        {nonFriend.reaction && (
                          <img
                            src={getReactionImage(nonFriend.reaction)}
                            alt="reaction"
                            className="story-views-user-reaction"
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
      </div>
    </>
  );
}

const getReactionImage = (reactionText) =>
  `${process.env.PUBLIC_URL}/assets/svgs/${reactionText.toLowerCase()}.svg`;
