import React, { useEffect, useMemo, useState } from "react";
import { Endpoints } from "../../../API/Endpoints";
import "../Student/ChatListItem.css";
import { timeSince } from "../../../Utils/helpers";
const FriendChatListItem = ({ data, online, active }) => {
  const user = useMemo(() => JSON.parse(localStorage.getItem("user")), []);

  const [unreadCount, setUnreadCount] = useState(0);
  const [lastMsg, setLastMsg] = useState("");

  useEffect(() => {
    setUnreadCount(data.unreadMsgCount);
    setLastMsg(data.lastMessage);
  }, [data]);

  const selectedUser = useMemo(
    () =>
      data.chat_room_members.find(
        (searchUser) => searchUser.user.id !== user.id
      ),
    [data, user.id]
  );

  const imageToRender = useMemo(() => {
    if (data.roomName !== null) {
      const person1 = data.chat_room_members[0];
      const person2 = data.chat_room_members[1];

      return (
        <div className="group-chat-image-container">
          <img
            src={`${Endpoints.baseUrl + person1?.user?.user_profile?.profileImg}`}
            alt=""
            width="36"
            height="36"
            className="group-chat-image"
          />
          <img
            src={`${Endpoints.baseUrl + person2?.user?.user_profile?.profileImg}`}
            alt=""
            width="36"
            height="36"
            className="group-chat-image"
          />
        </div>
      );
    }
    if (selectedUser?.user?.user_profile?.profileImg)
      return (
        <img
          src={`${
            Endpoints.baseUrl + selectedUser.user.user_profile.profileImg
          }`}
          alt=""
          width="48"
          height="48"
          className="rounded-circle"
        />
      );
    return (
      <img
        src="/assets/img/picture.png"
        alt=""
        width="48"
        height="48"
        className="rounded-circle"
      />
    );
  }, [data, selectedUser]);

  return (
    <li
      className={`message-item p-3 d-flex align-items-center ${
        active && active === data.id && "active-userchat"
      }`}
    >
      <div className="position-relative">

        {imageToRender}
        {data.roomName === null && online && (
          <span className="user-status">
            <svg
              viewBox="0 0 24 24"
              width="12"
              height="12"
              stroke="#fff"
              stroke-width="1"
              fill="#57CB6C"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <circle cx="12" cy="12" r="10"></circle>
            </svg>
          </span>
        )}
      </div>
      <div className="ps-2 w-100">
        <div className="d-flex align-items-center justify-content-between w-100">
          <h6 className="medium text-black">
            {data?.roomName || selectedUser?.user?.fullName}
          </h6>
          <h6 className="fs-12 regular text-darkgray">
            {lastMsg && timeSince(new Date(lastMsg.createdAt)) + " ago"}
          </h6>
        </div>

        <div className="d-flex align-items-center justify-content-between w-100 mt-1">
          <h6 className="fs-12 regular text-darkgray">
            {lastMsg && lastMsg?.message?.length > 37
              ? lastMsg?.message?.substring(0, 37) + "..."
              : lastMsg?.message}
          </h6>
          {unreadCount > 0 && (
            <span className="user-active " hidden="">
              <span className="msg-count">{unreadCount}</span>
            </span>
          )}
        </div>
      </div>
    </li>
  );
};

export default FriendChatListItem;
