import React, { useEffect, useState } from 'react'
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import GroupProfile from './GroupProfile';
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { CommonAPi } from '../../API/CommonApi';
import { useNavigate, useParams } from 'react-router-dom';
import { FetchApi } from "../../API/FetchApi";
import moment from 'moment';
import SimilarGroup from './SimilarGroup';
import ManageGroup from './ManageGroup';
import {  Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
export default function GroupAbout() {
    const [groupData, setGroupData] =useState('');
    const [groupsData, setGroupsData] =useState('');
    const [groupRules, setGroupRules] =useState([]);
    const [totalMember, setTotalMember] =useState('');
    const [descriptionLimit, setDescriptionLimit] =useState(115);
    const Navigate = useNavigate();
    const param = useParams();
    const userId = localStorage.getItem("userId");
    useEffect(()=>{
        getGroupPeople();
    }, []);
    const getGroupPeople = async() =>{
       
        try {
            let resp = await FetchApi(Endpoints.GetGroupsAbout + "?groupId=" + param?.id);
            if (resp && resp.success === true) {
                setGroupRules(resp?.data?.groupRules);
                setGroupData(resp?.data?.groupData);
                setTotalMember(resp?.data?.totalMember);
            }
        }
        catch (e) {

            if (e && e.response && e.response.data && e.response.data.message) {

                if (e.response.status === 401) {

                    ToastMessage.Error('Session Expired! Please login again.')
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message)
                }
            }
        }
    };

   
    return (
        <Container
            header
        >
        <Helmet>
            <title>Group About</title>
        </Helmet>
            <div className="wrapper wrapper-bg">

                <div className="main">

                    <LeftNavigation />
                    <div className="container py-lg-3 py-3">
                       
                        <GroupProfile {...{
                            setGroupsData:setGroupsData
                        }} />

                        <div className="row">
                            <div className="col-md-3 comany-dp-left pb-3">

                                <div className="sidebar-widget box-shadow">
                                    <div className="pb-3 d-flex align-items-start">
                                        <span>
                                            <img src="../assets/img/icon/icon-emplyee.svg" alt="" width="36" height="36" />
                                        </span>
                                        <div className="ps-2">
                                            <h4 className="medium">{totalMember}</h4>
                                            <h6 className="fs-12 text-darkgray regular">Members</h6>
                                        </div>
                                    </div>

                                    <div className="pb-3 d-flex align-items-start">
                                        <span>
                                            <img src="../assets/img/icon/icon-jobs-created.svg" alt="" width="36" height="36" />
                                        </span>
                                        <div className="ps-2">
                                            <h4 className="medium">0</h4>
                                            <h6 className="fs-12 text-darkgray regular">Admins & Managers</h6>
                                        </div>
                                    </div>


                                    <div className="d-flex align-items-start">
                                        <span>
                                            <img src="../assets/img/icon/icon-followers.svg" alt="" width="36" height="36" />
                                        </span>
                                        <div className="ps-2">
                                            <h4 className="medium">{moment(groupData?.created_at).format('MMM YYYY')}</h4>
                                            <h6 className="fs-12 text-darkgray regular">Created</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="sidebar-widget mt-3 box-shadow pb-3">
                                    <h5 className="medium border-bottom pb-2 mb-2">About Group 222</h5>
                                    {groupData?.description?.length > descriptionLimit ? 
                                        groupData?.description.substring(0, descriptionLimit) + "..." :
                                        groupData?.description
                                    } 
                                  {groupData?.description?.length == descriptionLimit ?
                                    ""  
                                  :
                                    <Link className="btn gray-btn w-100 text-black fs-14 medium mt-3" onClick={()=>{
                                        setDescriptionLimit(groupData?.description?.length)
                                    }}>Read More</Link>
                                }
                                </div>


                            </div>


                            <div className="col-md-6 feed-mid-column1 pb-3">

                                <div className="px-4 py-4 bg-white mb-3 rounded-15 shadow-md">


                                    <div className="fs-14 text-black pb-2">
                                        <h4 className="medium  fs-22 pb-3">
                                            About this Group
                                        </h4>

                                        <p>{groupData?.description} </p>


                                    </div>

                                    <div className="group-abt-user">


                                        <div className="d-flex align-items-start gap-2 pb-3">
                                            <img src="../assets/img/icon/icon-age.svg" />
                                            <div>
                                                <span className="fs-16 medium  d-block text-333">Members</span>
                                                <a href="#" className="text-color fs-14 d-block" style={{"--color":"#666666"}}>{totalMember} members</a>
                                            </div>
                                        </div>


                                        <div className="d-flex align-items-start gap-2 pb-3">
                                            <img src="../assets/img/icon/icon-livesin.svg" />
                                            <div>
                                                <span className="fs-16 medium  d-block text-333">Location</span>
                                                <span className="d-block fs-14 medium text-color" style={{"--color":"#666666"}}>{groupData?.location}</span>
                                                <a href="#" className="text-brown fs-14 d-block medium text-decoration-underline">Get Direction</a>
                                            </div>
                                        </div>


                                        <div className="d-flex align-items-start gap-2 pb-3">
                                            <img src="../assets/img/icon/icon-country.svg" />
                                            <div>
                                                <span className="fs-16 medium  d-block text-333">Country</span>
                                                <span className="text-color fs-14 d-block" style={{"--color":"#666666"}}>India</span>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-start gap-2 pb-3">
                                            <img src="../assets/img/icon/icon-country.svg" />
                                            <div>
                                                <span className="fs-16 medium  d-block text-333">Industry</span>
                                                <span className="text-color fs-14 d-block" style={{"--color":"#666666"}}>
                                                {groupData?.industry}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-start gap-2 pb-3">
                                            <img src="../assets/img/icon/icon-joined.svg" />
                                            <div>
                                                <span className="fs-16 medium  d-block text-333">Created</span>
                                                <span className="text-color fs-14 d-block" style={{"--color":"#666666"}}>{moment(groupData?.createdAt).format('MMM YYYY')}</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                {groupRules.length > 0 ? 
                                <div className="bg-white box-shadow rounded-15 p-md-3 p-2">
                                    <div className="pb-2 d-md-flex align-items-center justify-content-between">
                                        <h3 className="medium fs-20">Group Rules</h3>


                                    </div>


                                    <div className="pb-2">

                                        {groupRules.map((item, index)=>{
                                            return (
                                                <div className="border-bottom pt-3" key={index}>

                                                <h6 className="medium fs-14 text-black pb-2">{item?.title}</h6>
                                                <p className="fs-12 text-color" style={{"--color":"#565656"}}>{item?.description}</p>
                                            </div>
                                            )
                                        })}
                                       

                                     

                                    </div>

                                </div>
                                : "" }

                            </div>

                            {userId == groupData?.userId ? 
                                <ManageGroup />
                            :
                                <SimilarGroup />
                            }
                        </div>

                        <OnlineFriends />
                    </div>
                </div>
            </div>
        </Container>
    )
}
