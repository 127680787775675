export const Endpoints = {
  baseUrl: "https://8innet.dignitech.com/",
  // baseUrl: "http://localhost:5000/",
  register: "user/register",
  login: "user/login",
  loginWithOtp: "user/login-with-otp",
  loginWithOtpVerify: "user/login-with-otp/verify",
  GetProfile: "user/profile/show",
  CreateUpdateProfile: "user/profile/update/",
  Createacademics: "user/academics/create",
  Getacademics: "user/academics/fetch",
  Deleteacademics: "user/academics/delete",
  Updateacademics: "user/academics/update",
  GetCountry: "master/country/get",
  SendMobileOtp: "user/mobile/otp",
  VerifyMobileOtp: "user/mobile/verify",
  ProfilePicture: "user/profile/profile-picture",
  CoverPicture: "user/profile/cover-picture",
  SearchInterests: "master/interest/search",
  CreateInterests: "user/interest/create",
  GetInterests: "user/interest/show",
  GetInterestsCategory: "master/interest/categories",
  GetAllInterests: "master/interest/all",
  DeleteInterests: "user/interest/delete",
  SearchPages: "master/page/search",
  GetUserChilds: "/user/all-childs",
  registerChild: "/user/register/child",
  CreateExperience: "user/experience/create",
  GetExperience: "user/experience/fetch",
  UpdateExperience: "user/experience/update",
  DeleteExperience: "user/experience/delete",
  GetCourseCategory: "course/category/all",
  GetCourseSubCategory: "course/subcategory/all",
  CreateCourseBasicInformation: "course/basic/create",
  GetCourseBasicInformation: "course/basic/show",
  GetLanguage: "master/language/get",
  UpdateCourseBasicInformation: "course/basic/update",
  UpdateCourseAdvanceInformation: "course/advance/update",
  GetCourseAdvanceInformation: "course/advance/show",
  UpdateCourseAdvanceInformationTrailer: "course/advance/update/trailer",
  UpdateCourseAdvanceInformationThumbnail: "course/advance/update/thumbnail",

  CreateCourseSection: "course/section/create",
  UpdateCourseSection: "course/section/update",
  GetCourseSection: "course/section/show/all",
  GetSingalCourseSection: "course/section/show",
  DeleteCourseSection: "course/section/delete",
  UpdateCourseSectionOrder: "course/section/update/order",

  CreateCourseLecture: "course/lecture/create",
  UpdateCourseLecture: "course/lecture/update",
  GetCourseLecture: "course/lecture/show/all",
  GetSingalCourseLecture: "course/lecture/show",
  DeleteCourseLecture: "course/lecture/delete",
  UpdateCourseLectureOrder: "course/lecture/update/order",

  SearchInstructor: "course/instructor/search",
  coursePublish: "course/advance-last/update",
  GetCoursePublish: "course/advance-last/show",
  GetTeacherCourses: "course/teacher/list",
  GetSingleCourses: "course/show",
  GetCoursesPreview: "course/preview",
  DeleteTeacherCourses: "course/delete",
  SendForgotPasswordOtp: "user/password/forgot/send-otp",
  VerifyForgotPasswordOTP: "user/password/forgot/verify",
  ResetPassword: "user/password/reset",
  BecomeInstructor: "user/become-instructor",
  CreateTestScore: "user/test/create",
  GetTestScore: "user/test/list",
  GetSingleTestScore: "user/test/show",
  DeleteTestScore: "user/test/delete",
  UpdateTestScore: "user/test/update",
  CreateHonors: "user/honors/create",
  GetHonors: "user/honors/list",
  DeleteHonors: "user/honors/delete",
  GetSingleHonor: "user/honors/show",
  UpdateHonor: "user/honors/update",

  createPost: "/post/create",
  GetUserPosts: "/post/lists",
  DeletePost: "/post/delete/",
  GetFeedPosts: "/post/feed",
  GetShareUsersOfAPost: "/post/share/list/",
  GetShareUsersOfAMedia: "/post/media/share/list/",
  CreateComment: "/post/comment/create",
  CreatePostReaction: "/post/reaction/create",
  GetCommentsOfAPost: "/post/comment/lists/",
  DeleteComment: "/post/comment/delete/",
  CreateCommentReaction: "post/comment/reaction/create",
  GetRepliesOfComment: "/post/comment/reply/all/",
  GetSinglePost: "/post/show/",
  GetSingleBlog: "/post/blog/show",
  SavePost: "/post/save/",
  UpdatePost: "/post/update",
  GetReactionsOfAPost: "/post/reaction/list/",
  GetReactionsOfAComment: "/post/comment/reaction/list/",
  GoogleAuth: "/user/auth/google",
  facebookAuth: "/user/auth/facebook",
  AppleAuth: "/user/auth/apple",
  LinkedinAuth: "/user/auth/linkedin",
  getMediaComment: "/post/media/comment/list/",
  getMediaComment: "/post/media/comment/list/",
  PostMediaComment: "/post/media/comment/create",
  GetMediaCommentreply: "/post/media/comment/reply/all/",
  CreateModiaReaction: "/post/media/reaction/create",
  GetReactionsOfAMedia: "post/media/reaction/list/",
  CreateModiaCommentReaction: "/post/media/comment/reaction/create",
  GetReactionsOfAMediaComment: "/post/media/comment/reaction/list/",
  GetPostMediaDetails: "/post/media/show/",
  CreateMediaCaption: "/post/media/caption/update",
  UpdatePostTitle: "/post/title/update/",
  SaveSingleMedia: "/post/media/save",
  ShareSingleMedia: "/post/media/share",
  MobileNumberUpdate: "/user/mobile/update",
  checkUser: "/user/check",
  checkEmailVerify: "/user/verify-email",
  instructorDelete: "/course/instructor/delete",
  LinkPreview: "/post/link-preview",
  searchDesignation: "/master/designation/search",
  createDesignation: "/master/designation/create",
  searchStudyArea: "/master/study-area/search",
  createStudyArea: "/master/study-area/create",
  searchDegree: "/master/degree/search",
  createDegree: "/master/degree/create",
  AddToCart: "/course/cart/add",
  GetCartItems: "/course/cart/all",
  GetWishlistItems: "/course/wishlist/all",
  DeleteCartItem: "/course/cart/delete/",
  createOrder: "/course/order/create",
  ReturnURL: "/course/return",

  courseByCategory: "/course/category",
  featuredCourse: "/course/featured",
  recentCourse: "/course/recent",
  topCategory: "/course/category/top",
  topInstructor: "/course/instructor/top-rated",
  courseListing: "/course/all",
  courseTopic: "/course/topics",
  GetUserChats: "/course/chat/user/",
  getUserChatRooms: "/messages/user/",
  getOnlineFriends: (size) =>
    `/messages/online-friends?size=${size ? size : 5}`,
  GetTeacherChats: "/course/chat/teacher/",
  CreateChat: "/course/chat/",
  GetMessages: "/course/chat/message/",
  getChatByRoom: "/messages/all/",
  AddMessage: "/course/chat/message/",
  newMessage: "/messages/create",
  GetUserDetails: "/user/",
  GetChatTeachers: "/course/chat/list",
  AddToCart: "/course/cart/add",
  AddToWishlist: "/course/wishlist/add",
  RemoveToWishlist: "/course/wishlist/delete",
  GetMyCourses: "course/student/my-courses",
  GetMyTeacher: "course/my-teachers",
  GetWishlist: "/course/wishlist/all",
  GetPurchaseHistory: "/course/student/purchase-history",
  GetLeaning: "/course/student/learn",
  GetInstructorDetail: "/course/instructor/details",
  GetInstructorReviews: "/course/instructor/reviews",
  SearchCourse: "/course/search",
  courseProgress: "/course/track",
  ReadUnreadMsgs: "/course/chat/message/read",
  CreateReview: "/course/review/create",
  EnrollCourse: "/course/student/enroll",
  ApplyCoupon: "/course/apply-coupon",
  DownloadReceipt: "course/download-receipt/",
  RemoveCoupon: "/course/remove-coupon",
  DownloadInvoice: "course/download-invoice/",
  TeacherDashboard: "course/instructor/dashboard",
  TeacherCourseDetail: "course/instructor/my-course",
  AddBankAccount: "course/instructor/add-account",
  Earnings: "course/instructor/earnings",
  WithdrawMoney: "course/instructor/withdraw-money",
  GetDropdownTeachers: "course/my-teachers/list",
  DownloadTeacherReceipt: "course/instructor/download-receipt/",
  GetUserProfile: "user/profile/get",
  SendFriendRequest: "/user/friends/request/send",
  recommendationsUser: "/user/profile/recommendations",
  GetFriendRequest: "/user/friends/request/received",
  FriendRequestRespond: "/user/friends/request/respond",
  GetAllFriend: "/user/friends/all",
  GetProfileTeacher: "/user/profile/teachers",
  UserPhotoVideo: "/user/profile/media",
  TopReviewedTeacher: "/course/instructor/top-reviewed",
  pagesTypesAll: "/pages/types/all",
  pagesSizeAll: "/pages/sizes/all",
  pagesCreate: "/pages/create",
  pagesEdit: "/pages/edit",
  pagesDelete: "/pages/delete",
  pagesLocationCreate: "/pages/location/create",
  GetPages: "/pages/all",
  SearchIndustry: "/pages/industries/search",
  SearchCities: "/master/cities/search",
  SearchStates: "/master/states/search",
  PageFollow: "/pages/follow",
  PageUnfollow: "/pages/unfollow",
  PageDetail: "/pages/get",
  PageFollowers: "/pages/followers",
  PagePeople: "/pages/employees",
  PageFeeds: "/pages/feeds",
  PageCoverImage: "/pages/cover",
  PageLogoImage: "/pages/logo",
  CheckPageSlug: "/pages/slug",
  SendGratitude: "/user/gratitude/send",
  GetGratitude: "/user/gratitude/all",
  GroupCreate: "/groups/create",
  GroupCreateMessage: "/messages/group/create",
  getStories: "/story/all",
  createStory: "/story/create",
  captureStoryView: "/story/capture/view/",
  reactToStory: "/story/react",
  getViews: "/story/views/",
  deleteStory: "/story/delete/",
  CreateRule: "/groups/rule/create",
  GetGroups: "/groups/all",
  JoinGroups: "/groups/join",
  GetGroupsPeople: "/groups/people",
  GetGroupsAbout: "/groups/about",
  UpdateGroupsCover: "/groups/cover",
  UpdateGroupsLogo: "/groups/Logo",
  GetGroupPost: "/groups/feeds",
  GetGroupRequest: "/groups/requests",
  PostMediaCapture: "/post/media/capture/view",
  PostStatistics: "/post/feed/statistics",
  GetAlbum: "/user/profile/albums",
  LeaveGroup: "/groups/leave",
  SimilarGroup: "/groups/similar",
  SimilarPage: "/pages/similar",
  ProfileStatistics: "/user/profile/statistics",
  FeedActivity: "/user/profile/feed/activity",
  GroupRequestAction: "/groups/requests/action",
  GroupRuleAll: "/groups/rule/all",
  DeleteRule: "/groups/rule/delete",
  EditRule: "/groups/rule/edit",
  GetBlock: "/groups/blocked/user",
  GroupBlockAction: "/groups/block/action",
  GroupDelete: "/groups/delete",
  GroupUpdate: "/groups/profile",
  searchHashtag: "/hashtags/search?term=",
  searchFriend: "/user/friends/search?term=",
  GetJobs: "/jobs/all",
  GetSingalJobs: "/jobs/view",
  CreateJobs: "/jobs/create",
  JobStatus: "/jobs/status",
  JobDelete: "/jobs/delete",
  JobEdit: "/jobs/edit",
  JobApply: "/jobs/apply",
  JobSave: "/jobs/save",
  JobPreview: "/jobs/preview",
  GetSimilarJob: "/jobs/similar",
  GetJobApplicants: "/jobs/applicants/all",
  JobApplicantsAction: "/jobs/action",
  GetJobPostedAll: "/jobs/posted/all",
  JobsIndustries: "/jobs/industries",
  JobsSearch: "/jobs/search",
  StateSearch: "/master/states/search",
  CourseTopCompany: "/course/top/company",
  DeleteMedia: "/user/profile/media/delete",
  GetSingleJobApplicants: "/jobs/applicants",
  MasterListingSearch: "/master/listing/search",
  HashtagsTop: "/hashtags/top",
  HashtagsUnfollow: "/hashtags/unfollow",
  HashtagsFollow: "/hashtags/follow",
  TopEmployee: "/jobs/top/employer",
  HashtagsFeed: "/hashtags/feed",
  JobReport: "/jobs/report",
  PasswordChange: "/user/password/change",
  PostReport: "/post/report",
  AllNotification: "/user/all/notification",
  AllNotificationCount: "/user/notification/unread/count",
  ShowNotification: "/user/notification/shown",
  RemoveNotification: "/user/notification/remove",
  ReadNotification: "/user/notification/read",
  ReadAllNotification: "/user/notification/read/all",
  ActivityLog: "/user/profile/activity/log",
  NotificationSettingAll: "/user/profile/notification/setting/all",
  UpdateNotificationSetting: "/user/profile/notification/setting",
  GetProfileLog: "/user/profile/logs",
  GetProfileVisibility: "/user/profile/visibility",
  UpdateProfileVisibility: "/user/profile/visibility/edit",
  GetSavedPost: "/post/saved",
  BlockFriend: "/user/friends/block",
  Unfriend: "/user/friends/unfriend",
  GetBlockUser: "/user/friends/blocklist",
  SearchLocation: "/master/location/search",
  MentionSearch: "/master/mention/search",
  MentionSearch: "/master/mention/search",
  GroupChatDelete: "/messages/group/delete",
  GroupChatLeave: "/messages/group/left",
  GetSavedJobs: "/jobs/saved/all",
  GetAppliedJobs: "/jobs/apply/list",
  UpdatePageLocation: "/pages/location/update",
  DeletePageLocation: "/pages/location/delete",
  HidePost: "/post/restriction",
  MessageGroupEdit: "/messages/group/edit",
  ClearMessageChat: "/messages/chat/clear",
};
