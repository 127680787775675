import React, { useEffect, useState } from 'react';
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Profile from '../Teacher/Profile';
import { Endpoints } from "../../../API/Endpoints";
import { FetchApi } from '../../../API/FetchApi';
import ToastMessage from "../../../Utils/ToastMessage";
import { Link, useNavigate } from 'react-router-dom';
import PostLoader from '../../../Components/PostLoader/PostLoader';
import CourseItem from './CourseItem';
import { Helmet } from "react-helmet";
export default function StudentDashboard() {
    const [course, SetCourse] = useState([]);
    const [activeCourse, SetActiveCourse] = useState(0);
    const [completeCourse, SetCompleteCourse] = useState(0);
    const [enrolCcourse, SetEnrollCourse] = useState(0);
    const [loading, SetLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    useEffect(() => {
        getCourse();
    }, []);

    const getCourse = async () => {
        SetLoading(true);
        try {
            let resp = await FetchApi(Endpoints.GetMyCourses + '?' + "size=" + 4);
            if (resp && resp.success === true) {
                SetCourse(resp.data.courses);
                SetEnrollCourse(resp.data.enrolledCourses);
                SetCompleteCourse(resp.data.completedCourses);
                SetActiveCourse(resp.data.activeCourses);
                SetLoading(false);
            } else {
                SetLoading(false);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }


    };

    return (
        <Container
            header
        >
            <Helmet>
                <title>Student Dashboard</title>
            </Helmet>
            <div class="wrapper wrapper-bg">
                <div class="main">
                    <LeftNavigation />
                    <div class="container py-3">
                        <Profile type="student" active={"dashboard"} />
                        {loading ? <PostLoader /> :
                            <>
                                <div class="row">
                                    <div class="col-md-4 col-sm-6 mb-3">
                                        <div class="job-item  d-flex align-items-center bg-white">
                                            <span class="hastag-icon bg-primary">
                                                <img src="assets/img/icon/computer-white.svg" alt="" />
                                            </span>
                                            <div class="ps-3">
                                                <h4 class="semibold pb-1">{enrolCcourse}</h4>
                                                <h6 class="fs-14 regular">Enrolled Courses</h6>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-md-4 col-sm-6 mb-3">
                                        <div class="job-item  d-flex align-items-center bg-white">
                                            <span class="hastag-icon bg-primary">
                                                <img src="assets/img/icon/icon-video-online-white.svg" alt="" />
                                            </span>
                                            <div class="ps-3">
                                                <h4 class="semibold pb-1">{activeCourse}</h4>
                                                <h6 class="fs-14 regular">Active Courses</h6>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-md-4 col-sm-6 mb-3">
                                        <div class="job-item  d-flex align-items-center bg-white">
                                            <span class="hastag-icon bg-primary">
                                                <img src="assets/img/icon/icon-certificate-trophy.svg" alt="" />
                                            </span>
                                            <div class="ps-3">
                                                <h4 class="semibold pb-1">{completeCourse}</h4>
                                                <h6 class="fs-14 regular">Completed Courses</h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div class="rounded-15 box-shadow p-4 bg-white">
                                    <h3 class="bold pb-3">Let’s keep learning, {user.fullName}</h3>
                                    {course.length > 0 ?
                                        <div class="row">

                                            {course.map((item, index) => {
                                                return (
                                                    <CourseItem {...{
                                                        item: item,
                                                        index: index
                                                    }} />
                                                )
                                            })}


                                        </div>
                                        :
                                        ""
                                    }
                                    {course.length === 4 ?
                                        <div class="text-center py-2">
                                            <Link to="/my-courses" class="btn px-4 fs-14">View All Courses</Link>
                                        </div>
                                        : ""}
                                </div>
                            </>
                        }

                    </div>

                    <OnlineFriends />
                </div>
            </div>
        </Container>

    )
}
