import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import GratitudeModel from "./GratitudeModel";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from "../../API/FetchApi";
import GratitudeItem from "./GratitudeItem";

export default function Gratitudes() {
  const [gratitude, setGratitude] = useState([]);
  const Navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const username = localStorage.getItem("userName");
  const param = useParams();

  useEffect(() => {
    GetGratitude();
  }, [param?.id]);
  const GetGratitude = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.GetGratitude + "?type=received&id=" + param?.id
      );
      if (resp && resp.success === true) {
        setGratitude(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <>
      <div className="bg-white p-3 rounded-15">
        <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-4">
          <h4 className="medium fs-18 text-black">Gratitudes</h4>
          <div>
            <Link
              data-bs-toggle="modal"
              data-bs-target="#gratitudemodel"
              className="text-brown fs-12 medium m-0 p-0"
            >
              Send Gratitude{" "}
              <img src="../assets/img/icon/edit-plus.svg" alt="" width="12" />
            </Link>{" "}
            {username == param?.id ? " | " : ""}
            {username == param?.id ? (
              <Link
                to={"/gratitudewall"}
                className="text-brown fs-12 medium m-0 p-0"
              >
                See all{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-arrow-right feather-16"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
        {gratitude.length > 0 ? (
          <div className="row">
            {gratitude.map((item, index) => {
              if (index > 1) return null;
              return (
                <GratitudeItem
                  {...{
                    item: item,
                    index: index,
                    type: "profile",
                    gratitudeType: "received",
                  }}
                />
              );
            })}
          </div>
        ) : (
          <div className="row">No Gratitude received</div>
        )}
      </div>
      <GratitudeModel
        {...{
          onSuccess: GetGratitude,
        }}
      />
    </>
  );
}
