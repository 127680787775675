import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
import { UpdateAPi } from "../../API/UpdateApi";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import TopHeader from "../../Components/TopHeader";
import Welcome from "./Welcome";
import { Helmet } from "react-helmet";
const $ = window.jQuery;
export default function Verification() {
  const userRoleType = localStorage.getItem("userRoleType");
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = localStorage.getItem("userId");
  const [otp, setOtp] = useState("");
  const [showotp, setShowotp] = useState(false);
  const [showMobileInput, setShowMobileInput] = useState(false);
  const [newMobileNumber, setNewMobileNumber] = useState(user.mobileNumber);
  const [mobileNumber, setmobileNumber] = useState("");
  const [newMobileNumberError, setNewMobileNumberError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [greenBorder, setGreenBorder] = useState({ mobile: false });
  const Navigate = useNavigate();
  useEffect(() => {
    var percent = 80;
    percent = percent.toFixed();
    $(".progress-bar").css("width", percent + "%");
  });

  const handleResendOtp = async (event) => {
    event.preventDefault();
    let data = {
      mobile_number: user.mobileNumber,
    };
    try {
      let resp = await CommonAPi(Endpoints.SendMobileOtp, data);
      if (resp && resp.success === true) {
        ToastMessage.Success(resp.message);
        setShowotp(true);
      } else if (resp && resp.message === "MOBILE ALREADY VERIFIED") {
        window.localStorage.setItem("ProfileCompletion", "75.00");
        ToastMessage.Success(resp.message);
        Navigate("/complete-profile-step4" + "/" + userId);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleresendOtp = async () => {
    let data = {
      mobile_number: mobileNumber ? mobileNumber : user.mobileNumber,
    };
    try {
      let resp = await CommonAPi(Endpoints.SendMobileOtp, data);
      if (resp && resp.success === true) {
        ToastMessage.Success(resp.message);
        setShowotp(true);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleSubmitOtp = async (event) => {
    event.preventDefault();
    if (!otp) {
      setOtpError("Please enter otp");
    } else if (otp) {
      setOtpError("");
    }

    if (otp) {
      let data = {
        mobile_number: mobileNumber ? mobileNumber : user.mobileNumber,
        otp: otp,
        isUpdating: true,
      };
      try {
        let resp = await CommonAPi(Endpoints.VerifyMobileOtp, data);
        if (resp && resp.success === true) {
          user.mobileNumber = newMobileNumber;
          localStorage.setItem("user", JSON.stringify(user));
          ToastMessage.Success(resp.message);
          user.isMobileVerified = true;
          localStorage.setItem("user", JSON.stringify(user));
          window.localStorage.setItem("ProfileCompletion", "75.00");
          Navigate("/complete-profile-step4" + "/" + userId);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleSubmitUpdateMobile = async (event) => {
    event.preventDefault();
    if (!newMobileNumber) {
      setNewMobileNumberError("Please enter new mobile number");
    } else if (newMobileNumber) {
      setNewMobileNumberError("");
    }

    if (newMobileNumber) {
      let data = {
        mobile_number: newMobileNumber,
      };
      try {
        let resp = await UpdateAPi(Endpoints.MobileNumberUpdate, data);
        if (resp && resp.success === true) {
          ToastMessage.Success(resp.message);
          setShowotp(true);
          setShowMobileInput(false);
          setmobileNumber(newMobileNumber);
          getProfile();
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const getProfile = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetProfile + "?id=" + userId);
      if (resp && resp.success === true) {
        window.localStorage.setItem("user", JSON.stringify(resp?.data));
        window.localStorage.setItem("parentData", JSON.stringify(resp?.data));
        window.localStorage.setItem(
          "ProfileTagline",
          resp?.data?.user_profile?.profileTagline
        );
        window.localStorage.setItem(
          "userProfile",
          resp?.data?.user_profile?.profileImg
        );
        window.localStorage.setItem(
          "userProfileCoverImage",
          resp?.data?.user_profile?.coverImg
        );
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const checkMobile = async () => {
    let obj = {
      mobile_number: newMobileNumber,
    };
    try {
      let resp = await CommonAPi(Endpoints.checkUser, obj);
      console.log(resp);
      if (resp && resp.success === false) {
        setNewMobileNumberError(resp.message);
      }
      if (resp.success === true) {
        setNewMobileNumberError("");
        setGreenBorder({ ...greenBorder, mobile: true });
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setNewMobileNumberError(e.response.data.message);
      }
    }
  };
  const handleValidation = (e, value, field) => {
    e.preventDefault();
    if (!value && field === "newMobile") {
      setNewMobileNumberError("Please enter new mobile number");
    } else if (value && field === "newMobile") {
      if (value.length > 10) {
        setNewMobileNumberError("Please enter valid mobile number");
      } else {
        checkMobile();
        setNewMobileNumberError("");
      }
    }
    if (!value && field === "otp") {
      setOtpError("Please enter otp");
    } else if (value && field === "otp") {
      setOtpError("");
    }
  };

  const handleUpdateMobileLink = () => {
    setShowMobileInput(true);
    setShowotp(false);
  };

  return (
    <>
      <Helmet>
        <title>Verification</title>
      </Helmet>
      <TopHeader />
      <div className="container-fluid bg-f5f5f5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            <div className="card  bg-f5f5f5">
              <Welcome />

              <div id="msform">
                <ul id="progressbar" style={{ "--value": "20%" }}>
                  <li className="active" id="personal">
                    <strong>Interests</strong>
                  </li>
                  <li className="active" id="personal">
                    <strong>Basic Details</strong>
                  </li>
                  <li className="active" id="payment">
                    <strong>Image Upload</strong>
                  </li>
                  <li className="active" id="account">
                    <strong>Verification</strong>
                  </li>
                  {userRoleType === "child" ? (
                    ""
                  ) : userRoleType === "student" ? (
                    <li id="confirm">
                      <strong>Academics</strong>
                    </li>
                  ) : (
                    <li id="confirm">
                      <strong>Experience</strong>
                    </li>
                  )}
                </ul>
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    80%
                  </div>
                </div>

                <br />
                <fieldset>
                  <div className="form-card">
                    <div className="row">
                      <div className="col-7">
                        <h2 className="fs-title">Verify your phone number:</h2>
                      </div>
                      <div className="col-5">
                        <h2 className="steps">Step 4 - 5</h2>
                      </div>
                    </div>
                    <form onSubmit={handleResendOtp} className="generic-form">
                      <div className="form-field">
                        <label>Mobile Number:</label>
                        <span className="ms-3 d-inline-block">
                          {showMobileInput === false ? (
                            <Link to={"#"} onClick={handleUpdateMobileLink}>
                              <i className="fa fa-pencil"></i>
                            </Link>
                          ) : (
                            ""
                          )}
                        </span>

                        <input
                          type="text"
                          name="mobileNumber"
                          disabled
                          value={
                            mobileNumber ? mobileNumber : user.mobileNumber
                          }
                          className="border"
                        />

                        <div className="d-flex justify-content-end mt-2">
                          {showotp === true ? (
                            <Link
                              to={"#"}
                              onClick={() => {
                                handleresendOtp();
                              }}
                              className="next"
                            >
                              Resend OTP
                            </Link>
                          ) : showMobileInput === false ? (
                            <button
                              type="submit"
                              className="btn light-brown-btn"
                            >
                              Send OTP
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </form>

                    {showMobileInput === true ? (
                      <form
                        onSubmit={handleSubmitUpdateMobile}
                        className="generic-form"
                      >
                        <div className="form-field">
                          <label>New Mobile Number:</label>
                          <input
                            type="number"
                            value={newMobileNumber}
                            onBlur={(e) => {
                              handleValidation(e, e.target.value, "newMobile");
                            }}
                            className={
                              newMobileNumberError
                                ? "is-danger border"
                                : `border ${
                                    greenBorder.mobile ? "greenBorder" : ""
                                  }`
                            }
                            placeholder="New Mobile Number"
                            onChange={(e) => {
                              setNewMobileNumber(e.target.value);
                            }}
                          />
                          {newMobileNumberError && (
                            <div className="form-error">
                              {newMobileNumberError}
                            </div>
                          )}
                        </div>
                        <div className="d-flex justify-content-end">
                          <button type="submit" className="btn light-brown-btn">
                            Update Number
                          </button>
                        </div>
                      </form>
                    ) : (
                      ""
                    )}
                    <br />
                    {showotp === true ? (
                      <form onSubmit={handleSubmitOtp} className="generic-form">
                        <div className="form-field">
                          <label>OTP (One Time Password):</label>
                          <input
                            type="text"
                            value={otp}
                            onBlur={(e) => {
                              handleValidation(e, e.target.value, "otp");
                            }}
                            className={otpError ? "is-danger border" : "border"}
                            placeholder="OTP"
                            onChange={(e) => {
                              setOtp(e.target.value);
                            }}
                          />

                          {otpError && (
                            <div className="form-error">{otpError}</div>
                          )}
                        </div>
                        <div className="d-flex justify-content-end my-3">
                          <button
                            type="submit"
                            name="submit"
                            className="btn light-brown-btn"
                          >
                            Verify OTP
                          </button>
                        </div>
                      </form>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <Link
                      to={"/complete-profile-step2" + "/" + userId}
                      className="previous btn"
                    >
                      Previous
                    </Link>
                    {user.isMobileVerified ? (
                      <Link to={"/complete-profile-step4" + "/" + userId}>
                        <button type="submit" className="btn next px-4">
                          Next
                        </button>
                      </Link>
                    ) : (
                      <button
                        type="button"
                        disabled="true"
                        className="btn next px-4"
                      >
                        Next
                      </button>
                    )}
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
