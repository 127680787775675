import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import ToastMessage from "../../Utils/ToastMessage";
import Post from "../../Components/Post/Post";
import BlogCard from "../../Components/BlogCard/BlogCard";
import PostLoader from "../../Components/PostLoader/PostLoader";
import SharedPost from "../../Components/SharedPost/SharedPost";
import SharedMedia from "../../Components/SharedMedia/SharedMedia";
import SharedBlogCard from "../../Components/SharedBlogCard/SharedBlogCard";
import { Helmet } from "react-helmet";

function MainFeed() {
  const history = useNavigate();
  const [mouserHovering, setMouserHovering] = useState(false);
  const [timelinePosts, setTimelinePosts] = useState([]);
  const [postPage, setPostPage] = useState(1);
  const listInnerRef = useRef();
  const [loading, setLoading] = useState(false);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const containerRef = useRef(null);
  const param = useParams();

  useEffect(()=>{
    getUserPosts();
  }, [param?.id]);
  
  const getUserPosts = async () => {
    setTimelinePosts([]);
    let array  = [];
    try {
      let resp = await CommonAPi(Endpoints.GetSinglePost + param?.id);
      if (resp && resp.success === true) { 
        setTimelinePosts([...array, resp.data]);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const showReactionsBox = (event) => {
    setMouserHovering(true);
  };
  const hideReactionsBox = (event) => {
    setTimeout(() => {
      setMouserHovering(false);
    }, 1400);
  };
  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "y";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + "d";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + "h";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min";
    return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec" : "0 sec";
  };
  function formatDateTime(postDate) {
    let date = new Date(postDate);
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    let formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }
  const onEditSuccess = (editedPost) => {


  }

  return (
    <Container
      header
    >
      <Helmet>
        <title>Post Detail</title>
      </Helmet>

      <div className="wrapper wrapper-bg">
        <div className="main" >
          <LeftNavigation />
  

          <div className="container py-3 home" >
            <div className="row">
             


              <div className="col-md-6 feed-mid-column pb-3 offset-3">
               

                <ul className="timeline-feed feed-list">
                  {timelinePosts.map((post, i) => {
                    if (
                      post.category === "Blog" &&
                      post.isShared === 0 &&
                      post.isMediaShared === 0
                    )
                      return (
                        <BlogCard
                          post={post}
                          key={post.id}
                          getUserPosts={getUserPosts}
                          timeSince={timeSince}
                          setPostPage={setPostPage}
                          postPage={postPage}
                          setTimelinePosts={setTimelinePosts}
                          listInnerRef={listInnerRef}
                          formatDateTime={formatDateTime}
                        />
                      );
                    else if (
                      post.isShared &&
                      post.isMediaShared === 0 &&
                      post.category !== "Blog"
                    )
                      return (
                        <SharedPost
                          post={post}
                          getUserPosts={getUserPosts}
                          key={post.id}
                          timeSince={timeSince}
                        
                          setTimelinePosts={setTimelinePosts}
                          formatDateTime={formatDateTime}
                        />
                      );
                    else if (
                      post.isShared &&
                      post.isMediaShared &&
                      post.category !== "Blog"
                    )
                      return (
                        <SharedMedia
                          post={post}
                          getUserPosts={getUserPosts}
                          key={post.id}
                          timeSince={timeSince}
                          setTimelinePosts={setTimelinePosts}
                          formatDateTime={formatDateTime}
                        />
                      );
                    else if (
                      post.isShared === 0 &&
                      post.isMediaShared === 0 &&
                      post.category !== "Blog"
                    )
                      return (
                        <Post
                          post={post}
                          getUserPosts={getUserPosts}
                          key={post.id}
                          timeSince={timeSince}
                          formatDateTime={formatDateTime}
                          setTimelinePosts={setTimelinePosts}
                          onEditSuccess={getUserPosts}
                        />
                      );
                   
                    else if (post.category === "Blog" && post.isShared !== 0)
                      return (
                        <SharedBlogCard
                          post={post}
                          key={post.id}
                          getUserPosts={getUserPosts}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                        //   setTimelinePosts={getUserPosts}
                        //   setPostPage={setPostPage}
                        //   postPage={postPage}
                          formatDateTime={formatDateTime}
                          listInnerRef={listInnerRef}
                        />
                      );
                  })}
                  {loading === true && hasMorePosts === true && <PostLoader />}
                </ul>
              </div>
         
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}

export default MainFeed;
