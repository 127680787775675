import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import ToastMessage from "../../Utils/ToastMessage";
import Post from "../../Components/Post/Post";
import PostShare from "../../Components/PostShare/PostShare";
import BlogCard from "../../Components/BlogCard/BlogCard";
import PostLoader from "../../Components/PostLoader/PostLoader";
import SharedPost from "../../Components/SharedPost/SharedPost";
import SharedMedia from "../../Components/SharedMedia/SharedMedia";
import SharedBlogCard from "../../Components/SharedBlogCard/SharedBlogCard";
import SuggestedFriends from "../SuggestedFriends/Index";
import FriendRequests from "./FriendRequests";
import Friend from "./Friend";
import Birthday from "./Birthday";
import PopularCourses from "./PopularCourses";
import SuggestedTeachers from "../SuggestedTeachers";
import SuggestedCourses from "../SuggestedCourses";
import AddStoryModal from "../../Components/AddStoryModal";
import Carousel from "../../Components/Carousel";
import StoriesModal from "../../Components/story/StoriesModal";
import PostStatistics from "./PostStatistics";
import SuggestedGroups from "./SuggestedGroups";
import SuggestedGroup from "../SuggestedGroup/Index";
import SuggestedCompany from "../SuggestedCompany/Index";
import SuggestedPage from "../SuggestedPage/Index";
import SuggestedJobs from "../SuggestedJobs";
import SuggestedPages from "./SuggestedPages";
import SuggestedSchools from "./SuggestedSchools";
import SuggestedSchool from "../SuggestedSchool/Index";
import SuggestedJob from "./SuggestedJob";
import { Helmet } from "react-helmet";
const $ = window.jQuery;

function MainFeed() {
  const history = useNavigate();
  const [mouserHovering, setMouserHovering] = useState(false);
  const ProfileCompletion = localStorage.getItem("ProfileCompletion");
  const userId = localStorage.getItem("userId");
  const user = JSON.parse(localStorage.getItem("user"));
  const userProfile = localStorage.getItem("userProfile");
  const userProfileCoverImage = localStorage.getItem("userProfileCoverImage");
  const [timelinePosts, setTimelinePosts] = useState([]);
  const [postPage, setPostPage] = useState(1);
  const listInnerRef = useRef();
  const [loading, setLoading] = useState(false);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const containerRef = useRef(null);
  const [ShowMoreLink, setShowMoreLink] = useState(false);

  const [stories, setStories] = useState({
    records: [],
    totalPages: 0,
    totalRecords: 0,
  });

  const [selectedStoryIndex, setSelectedStoryIndex] = useState(0);

  const [showAddStoryModal, setShowAddStoryModal] = useState(false);

  const [showStories, setShowStories] = useState(false);

  const handleScroll = (e) => {
    if (loading) return;
    const scrollTop = listInnerRef.current.scrollTop;
    const offsetHeight = listInnerRef.current.offsetHeight;
    const scrollHeight = listInnerRef.current.scrollHeight;
    const remaining = scrollHeight - offsetHeight - scrollTop;

    if (remaining < 300) {
      setLoading(true);
      fetchNext();
    }
  };

  useEffect(() => {
    getStories();

    $(document).ready(function () {
      $(".play-video").click(function () {
        ".feed-video video".trigger("play").attr("controls", "");
        $(this).fadeOut();
      });
    });
  }, []);

  useEffect(() => {
    if (!postPage) return;
    getUserPosts();
  }, [postPage]);

  const getUserPosts = async (createPage = null, fetchPage = postPage) => {
    if (!hasMorePosts) return;
    try {
      let resp = await CommonAPi(Endpoints.GetFeedPosts + `?page=${fetchPage}`);
      if (resp && resp.success === true) {
        if (resp.data.length === 0) {
          setLoading(false);
          setHasMorePosts(false);
        } else {
          if (createPage == null) {
            setTimelinePosts([...timelinePosts, ...resp.data]);
          } else {
            setTimelinePosts(resp.data);
          }
        }
        setLoading(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
    setLoading(false);
  };

  const fetchNext = () => {
    setTimeout(() => {
      setPostPage(postPage + 1);
    }, 50);
  };

  const getStories = useCallback(async () => {
    try {
      const res = await CommonAPi(Endpoints.getStories);
      if (res?.success) {
        const sortedResponse = {
          ...res.data,
          records: res.data.records.sort((a, b) => {
            if (a.id === user.id && b.id !== user.id) return -1;
            if (a.id !== user.id && b.id === user.id) return 1;
            return 0;
          }),
        };
        setStories(sortedResponse);
      }
    } catch (e) {}
  }, []);

  const showReactionsBox = (event) => {
    setMouserHovering(true);
  };
  const hideReactionsBox = (event) => {
    setTimeout(() => {
      setMouserHovering(false);
    }, 1400);
  };
  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "y";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + "d";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + "h";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min";
    return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec" : "0 sec";
  };
  const formatDateTime = (postDate) => {
    let date = new Date(postDate);
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    let formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const handleProfileCompletion = () => {
    if (Math.round(ProfileCompletion) < 25) {
      history("/complete-profile-step1" + "/" + userId);
    } else if (Math.round(ProfileCompletion) < 50) {
      history("/complete-profile-step2" + "/" + userId);
    } else if (Math.round(ProfileCompletion) < 75) {
      history("/complete-profile-step3" + "/" + userId);
    } else if (Math.round(ProfileCompletion) < 100) {
      history("/complete-profile-step4" + "/" + userId);
    }
  };

  const handleStoryClick = useCallback(
    (index) => () => {
      setShowStories(true);
      setSelectedStoryIndex(index);
    },
    []
  );

  const onEditSuccess = (editedPost) => {
    console.log(timelinePosts);
    console.log(editedPost);
    const updatedTimlinePost = timelinePosts.map((post) => {
      if (post.id === editedPost.id) {
        return editedPost;
      }
      return post;
    });

    setTimelinePosts(updatedTimlinePost);
    console.log(updatedTimlinePost);
  };

  return (
    <Container
      header
      setLoading={setLoading}
      loading={loading}
      setPostPage={setPostPage}
      postPage={postPage}
      getUserPosts={getUserPosts}
      fromMainfeed={true}
      setTimelinePosts={setTimelinePosts}
      containerRef={containerRef}
    >
      <Helmet>
        <title>MainFeed</title>
      </Helmet>

      <div className="wrapper wrapper-bg">
        <div className="main" onScroll={handleScroll} ref={listInnerRef}>
          <LeftNavigation />
          {/* <?php include 'include/left-navigation.php'; ?> */}

          <div className="container py-3 home" ref={containerRef}>
            <div className="row">
              <div className="col-md-3 feed-left pb-3">
                <div className="sidebar-widget">
                  <div className="profile-widget">
                    <div className="profile-widget-head">
                      {userProfileCoverImage === null ||
                      userProfileCoverImage === "undefined" ||
                      userProfileCoverImage === "" ? (
                        <img
                          src="assets/img/feed-head.png"
                          alt=""
                          height="80"
                          width="100%"
                          style={{ objectFit: "cover" }}
                          className="rounded-2"
                        />
                      ) : (
                        <img
                          src={Endpoints.baseUrl + userProfileCoverImage}
                          alt=""
                          height="80"
                          width="100%"
                          style={{ objectFit: "cover" }}
                          className="rounded-2"
                        />
                      )}
                      <div className="d-flex align-items-end gap-1">
                        <div
                          className="profile-circular-bar position-relative rounded-circle"
                          style={{ "--value": Math.round(ProfileCompletion) }}
                        >
                          <Link to={"/timeline/" + user?.username}>
                            {userProfile === null ||
                            userProfile === "undefined" ||
                            userProfile === "" ? (
                              <img
                                src="assets/img/profile.png"
                                alt=""
                                height="54"
                                width="54"
                                className="rounded-circle"
                              />
                            ) : (
                              <img
                                src={Endpoints.baseUrl + userProfile}
                                alt=""
                                height="54"
                                width="54"
                                className="rounded-circle"
                              />
                            )}
                          </Link>
                        </div>

                        <div className="ps-2">
                          <Link to={"/timeline/" + user?.username}>
                            <h6 className="medium pb-1">{user.fullName}</h6>
                          </Link>

                          <h6 className="fs-12 text-gray">{user?.usersType}</h6>
                        </div>
                      </div>
                      {Math.round(ProfileCompletion) < 99 ? (
                        <div className="border-top pt-3 mt-3">
                          <div className="d-flex justify-content-between pb-1">
                            <h5 className="medium">
                              Profile Progress
                              {Math.round(ProfileCompletion) > 99 ? (
                                <div className="fs-10 d-block text-brown p-2">
                                  Completed
                                </div>
                              ) : (
                                <div
                                  className="fs-10 d-block text-brown p-2"
                                  onClick={handleProfileCompletion}
                                >
                                  Set up now
                                </div>
                              )}
                            </h5>
                            <span className="text-blue medium">
                              {Math.round(ProfileCompletion)}%
                            </span>
                          </div>

                          <div className="profile-progess">
                            <h6
                              className={
                                Math.round(ProfileCompletion) < 25
                                  ? "medium text-gray pb-3 incomplete"
                                  : "medium text-blue pb-3 complete"
                              }
                            >
                              <span className="d-inline-block bg-white">
                                {Math.round(ProfileCompletion) < 25 ? (
                                  <img
                                    src="assets/img/icon/icon-indeterminate.svg"
                                    alt=""
                                    className="me-2"
                                  />
                                ) : (
                                  <img
                                    src="assets/img/icon/icon-checkbox.svg"
                                    alt=""
                                    className="me-2"
                                  />
                                )}
                              </span>
                              Personal Information
                              <span className="fs-12 light ms-auto">25%</span>
                            </h6>

                            <h6
                              className={
                                Math.round(ProfileCompletion) < 50
                                  ? "medium text-gray pb-3 incomplete"
                                  : "medium text-blue pb-3 complete"
                              }
                            >
                              <span className="d-inline-block bg-white">
                                {Math.round(ProfileCompletion) < 50 ? (
                                  <img
                                    src="assets/img/icon/icon-indeterminate.svg"
                                    alt=""
                                    className="me-2"
                                  />
                                ) : (
                                  <img
                                    src="assets/img/icon/icon-checkbox.svg"
                                    alt=""
                                    className="me-2"
                                  />
                                )}
                              </span>
                              Image Upload{" "}
                              <span className="fs-12 light ms-auto">25%</span>
                            </h6>

                            <h6
                              className={
                                Math.round(ProfileCompletion) < 75
                                  ? "medium text-gray pb-3 incomplete"
                                  : "medium text-blue pb-3 complete mediator"
                              }
                            >
                              <span className="d-inline-block bg-white">
                                {Math.round(ProfileCompletion) < 75 ? (
                                  <img
                                    src="assets/img/icon/icon-indeterminate.svg"
                                    alt=""
                                    className="me-2"
                                  />
                                ) : (
                                  <img
                                    src="assets/img/icon/icon-checkbox.svg"
                                    alt=""
                                    className="me-2"
                                  />
                                )}
                              </span>
                              Verifications{" "}
                              <span className="fs-12 light ms-auto">25%</span>
                            </h6>

                            {/* <h6 className="medium text-gray pb-3 incomplete">
                                            <span className="d-inline-block bg-white"><img src="assets/img/icon/icon-indeterminate.svg" alt="" className="me-2"/></span>                                   
                                                <span>Verifications
                                                    <span className="fs-10 d-block text-brown">Verify now</span>
                                                </span>
                                                <span className="fs-12 light ms-auto">20%</span>
                                            </h6> */}

                            {/* <h6 className="medium text-gray pb-3 incomplete">
                                            <span className="d-inline-block bg-white"><img src="assets/img/icon/icon-indeterminate.svg" alt="" className="me-2"/> </span>                                   
                                                <span>Portfolio
                                                    <span className="fs-10 d-block text-brown">Update now</span>
                                                </span>
                                                <span className="fs-12 light ms-auto">20%</span>
                                            </h6> */}

                            <h6
                              className={
                                Math.round(ProfileCompletion) < 100
                                  ? "medium text-gray pb-3 incomplete"
                                  : "medium text-blue pb-3 incomplete"
                              }
                            >
                              <span className="d-inline-block bg-white">
                                {Math.round(ProfileCompletion) < 100 ? (
                                  <img
                                    src="assets/img/icon/icon-indeterminate.svg"
                                    alt=""
                                    className="me-2"
                                  />
                                ) : (
                                  <img
                                    src="assets/img/icon/icon-checkbox.svg"
                                    alt=""
                                    className="me-2"
                                  />
                                )}
                              </span>
                              <span>
                                Academics & Experience
                                {/* <span className="fs-10 d-block text-brown">set up now</span> */}
                              </span>
                              <span className="fs-12 light ms-auto">25%</span>
                            </h6>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <PopularCourses />
                <SuggestedGroups />
                <SuggestedPages />
                <SuggestedSchools />
                <SuggestedJob />
                <Friend />
              </div>

              {/* story */}

              <div className="col-md-6 feed-mid-column pb-3">
                <div className="feed-stories bg-white">
                  <Carousel>
                    <div className="feed-story feed-add">
                      <div className=" position-relative">
                        <img
                          src="assets/img/story-media.png"
                          className="story-img"
                          alt=""
                        />
                        <button
                          onClick={() => setShowAddStoryModal(true)}
                          className=" position-absolute bottom-0 mb-2 start-0 end-0 mx-auto text-center border-0 bg-transparent"
                        >
                          <img
                            src="assets/img/icon/add-story.svg"
                            width="32"
                            height="32"
                            alt=""
                            className="mx-auto story-user-pic rounded-circle"
                          />
                        </button>
                      </div>
                      <h6 className="fs-10 text-center medium">Add Story</h6>
                    </div>
                    {stories.records.map((userStory, index) => {
                      const currentStory = userStory.stories[0];
                      return (
                        <button
                          key={userStory.id}
                          onClick={handleStoryClick(index)}
                          className="feed-story"
                        >
                          <div className="position-relative">
                            <img
                              src={`${Endpoints.baseUrl}${
                                currentStory.storyType === "video"
                                  ? currentStory.thumbnail
                                  : currentStory.media
                              }`}
                              className="story-img"
                              alt=""
                            />
                            <img
                              src={`${Endpoints.baseUrl}${userStory.user_profile.profileImg}`}
                              className="story-user-pic position-absolute bottom-0 mb-2 start-0 end-0 mx-auto rounded-circle"
                              style={{ height: 32, width: 32 }}
                              alt=""
                            />
                          </div>
                          <Link to={"/timeline/" + userStory?.username}>
                            <h6 className="fs-10 text-center medium">
                              {userStory.fullName}
                            </h6>
                          </Link>
                        </button>
                      );
                    })}
                  </Carousel>
                </div>

                {/* status box */}
                <div className="mt-3">
                  <PostShare
                    setPostPage={setPostPage}
                    setTimelinePosts={setTimelinePosts}
                    postPage={postPage}
                    getUserPosts={getUserPosts}
                  />
                </div>

                <ul className="timeline-feed feed-list">
                  {timelinePosts?.map((post, i) => {
                    if (i === 5) {
                      return <SuggestedFriends />;
                    }

                    if (i === 10) {
                      return <SuggestedTeachers />;
                    }

                    if (i === 15) {
                      return <SuggestedCourses />;
                    }
                    if (i === 20) {
                      return <SuggestedGroup />;
                    }
                    if (i === 25) {
                      return <SuggestedCompany />;
                    }
                    if (i === 30) {
                      return <SuggestedPage />;
                    }
                    if (i === 35) {
                      return <SuggestedJobs />;
                    }
                    if (i === 40) {
                      return <SuggestedSchool />;
                    }

                    if (
                      post.category === "Blog" &&
                      post.isShared === 0 &&
                      post.isMediaShared === 0
                    )
                      return (
                        <BlogCard
                          post={post}
                          key={post.id}
                          getUserPosts={getUserPosts}
                          // mouserHovering={mouserHovering}
                          // showReactionsBox={showReactionsBox}
                          // hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          setTimelinePosts={setTimelinePosts}
                          setPostPage={setPostPage}
                          postPage={postPage}
                          listInnerRef={listInnerRef}
                          formatDateTime={formatDateTime}
                        />
                      );
                    else if (
                      post.isShared &&
                      post.isMediaShared === 0 &&
                      post.category !== "Blog"
                    )
                      return (
                        <SharedPost
                          post={post}
                          getUserPosts={getUserPosts}
                          key={post.id}
                          // mouserHovering={mouserHovering}
                          // showReactionsBox={showReactionsBox}
                          // hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          setTimelinePosts={setTimelinePosts}
                          setPostPage={setPostPage}
                          postPage={postPage}
                          listInnerRef={listInnerRef}
                          formatDateTime={formatDateTime}
                        />
                      );
                    else if (
                      post.isShared &&
                      post.isMediaShared &&
                      post.category !== "Blog"
                    )
                      return (
                        <SharedMedia
                          post={post}
                          getUserPosts={getUserPosts}
                          key={post.id}
                          // mouserHovering={mouserHovering}
                          // showReactionsBox={showReactionsBox}
                          // hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          setTimelinePosts={setTimelinePosts}
                          setPostPage={setPostPage}
                          postPage={postPage}
                          listInnerRef={listInnerRef}
                          formatDateTime={formatDateTime}
                        />
                      );
                    else if (
                      post.isShared === 0 &&
                      post.isMediaShared === 0 &&
                      post.category !== "Blog"
                    )
                      return (
                        <Post
                          post={post}
                          getUserPosts={getUserPosts}
                          key={post.id}
                          // mouserHovering={mouserHovering}
                          // showReactionsBox={showReactionsBox}
                          // hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          setTimelinePosts={setTimelinePosts}
                          timelinePosts={timelinePosts}
                          setPostPage={setPostPage}
                          postPage={postPage}
                          listInnerRef={listInnerRef}
                          formatDateTime={formatDateTime}
                          onEditSuccess={onEditSuccess}
                        />
                      );
                    // else if (post.category === "Link")
                    //   return (
                    //     <LinkPost
                    //       post={post}
                    //       key={post.id}
                    //       getUserPosts={getUserPosts}
                    //       mouserHovering={mouserHovering}
                    //       showReactionsBox={showReactionsBox}
                    //       hideReactionsBox={hideReactionsBox}
                    //       timeSince={timeSince}
                    //       setTimelinePosts={setTimelinePosts}
                    //       setPostPage={setPostPage}
                    //       postPage={postPage}
                    //       formatDateTime={formatDateTime}
                    //       listInnerRef={listInnerRef}
                    //     />
                    //   );
                    else if (post.category === "Blog" && post.isShared !== 0)
                      return (
                        <SharedBlogCard
                          post={post}
                          key={post.id}
                          getUserPosts={getUserPosts}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          setTimelinePosts={setTimelinePosts}
                          setPostPage={setPostPage}
                          postPage={postPage}
                          formatDateTime={formatDateTime}
                          listInnerRef={listInnerRef}
                        />
                      );
                  })}
                  {loading === true && hasMorePosts === true && <PostLoader />}
                </ul>
              </div>
              <div className="col-md-3 feed-right">
                <Birthday />

                <FriendRequests />

                <PostStatistics />
                <div className="d-flex useful-link fs-12 text-gray">
                  <Link>About</Link>
                  <Link>Help and support</Link>
                  <Link>Press</Link>
                  <Link>Career</Link>
                </div>
                <div className="d-flex useful-link fs-12 text-gray">
                  <Link>Privacy</Link>
                  <Link>Terms</Link>
                  <Link>Business</Link>
                  {ShowMoreLink ? <Link>Contact Us</Link> : ""}
                  {ShowMoreLink ? <Link>Blog</Link> : ""}
                  {ShowMoreLink ? (
                    ""
                  ) : (
                    <Link
                      onClick={() => {
                        setShowMoreLink(true);
                      }}
                    >
                      More
                    </Link>
                  )}
                </div>
                {ShowMoreLink ? (
                  <div className="d-flex useful-link fs-12 text-gray hidden">
                    <Link>FAQ</Link>
                    <Link>How It Works</Link>
                    <Link
                      onClick={() => {
                        setShowMoreLink(false);
                      }}
                    >
                      Less
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                <div className="m-2 fs-12 text-gray">
                  © 2022 8innet Career Tech Pvt. Ltd.
                </div>
              </div>
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
      {/* <script src="assets/js/footer.js"></script> */}
      {showStories && (
        <StoriesModal
          stories={stories}
          selectedStoryIndex={selectedStoryIndex}
          onClose={() => {
            setShowStories(false);
            getStories();
          }}
        />
      )}
      {showAddStoryModal && (
        <AddStoryModal
          onClose={() => {
            setShowAddStoryModal(false);
            getStories();
          }}
          {...{
            setShowAddStoryModal: setShowAddStoryModal,
          }}
        />
      )}
    </Container>
  );
}

export default MainFeed;
