import React, { useEffect, useState, useCallback, useRef } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import { BloodGroup } from "../../constants/BloodGroup";
import ToastMessage from "../../Utils/ToastMessage";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import ProfileImage from "./ProfileImage";
import { Link, useNavigate } from "react-router-dom";
import { getOrientation } from "get-orientation/browser";
import { getCroppedImg, getRotatedImage } from "../../Utils/Canvas/canvas_util";
import Cropper from "react-easy-crop";
import ProfileCropper from "../../Components/ImageCropperModal/ProfileCropper";
import "./Profile.css";
import Sidebar from "./Sidebar";
import { Helmet } from "react-helmet";
const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

function ProfileUpdate() {
  const [profileData, setProfileData] = useState("");
  const [fullName, setFullName] = useState("");
  const [gender, setGender] = useState("");
  const [nickname, setNickName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [birthplace, setBirthPlace] = useState("");
  const [livesin, setLivesin] = useState("");
  const [country, setCountry] = useState("");
  const [countryName, setCountryName] = useState("");
  const [countryKey, setCountryKey] = useState(
    Math.ceil(Math.random() * 999999)
  );
  const [bloodGroup, setBloodGroup] = useState("");
  const [about, setAbout] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [profileTagline, setProfileTagline] = useState("");
  const [countryData, setCountryData] = useState([]);

  const [genderError, setGenderError] = useState("");
  const [nicknameError, setNickNameError] = useState("");
  const [birthdayError, setBirthdayError] = useState("");
  const [birthplaceError, setBirthPlaceError] = useState("");
  const [livesinError, setLivesinError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [bloodGroupError, setBloodGroupError] = useState("");
  const [aboutError, setAboutError] = useState("");
  const [profileTaglineError, setProfileTaglineError] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const user = localStorage.getItem("user");
  const userId = localStorage.getItem("userId");
  const username = localStorage.getItem("userName");
  const Navigate = useNavigate();
  const [coverImageSrc, setCoverImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [getProfilePicture, setGetProfilePicture] = useState("");
  const [profileImageSrc, setProfileImageSrc] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [show, setShow] = useState(false);
  const [ogCover, setOgCover] = useState("");
  const profileRef = useRef(null);
  const [file, setFile] = useState();
  const [ProfilePicture, setProfilePicture] = useState("");
  const [ProfilePictureError, setProfilePictureError] = useState("");
  const hadnleCoverPicture = async (e) => {
    let imageDataURL = await readFile(e.target.files[0]);
    const orientation = await getOrientation(e.target.files[0]);
    const rotation = ORIENTATION_TO_ANGLE[orientation];
    if (rotation) {
      imageDataURL = await getRotatedImage(imageDataURL, rotation);
    }
    setCoverImageSrc(imageDataURL);
  };
  const uploadCoverPicture = async () => {
    let newFile = new File([file], "image.jpg", { type: "image/jpg" });
    const formData = new FormData();
    formData.append("user-picture", newFile);
    try {
      let resp = await CommonAPi(Endpoints.CoverPicture, formData);
      if (resp && resp.success === true) {
        setCoverImageSrc(null);
        getProfile();
        localStorage.setItem("userProfileCoverImage", resp.data.cover);
        console.log(localStorage);
        // setuserProfileCoverImage(Endpoints.baseUrl + resp.data.cover)
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      const { file, url } = await getCroppedImg(
        coverImageSrc,
        croppedAreaPixels
      );
      // setCroppedImage(url)
      setFile(file);
    },
    [coverImageSrc]
  );

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    getProfile();
    futureDateDisabled();
    getCountry();
  }, [userId]);

  const futureDateDisabled = () => {
    const date = new Date();
    let day = "";
    let days = date.getDate();
    if (days > 9) {
      day = days;
    } else {
      day = "0" + days;
    }
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    setCurrentDate(`${year}-${month}-${day}`);
  };

  const getProfile = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetProfile + "?id=" + userId);
      if (resp && resp.success === true) {
        setProfileImageSrc("");
        window.localStorage.setItem("user", JSON.stringify(resp?.data));
        setProfileData(resp?.data);
        setAbout(resp?.data?.user_profile?.aboutMe);
        setBloodGroup(resp?.data?.user_profile?.bloodGroup);
        setCountry(resp?.data?.user_profile?.countryId);
        setCountryKey(Math.ceil(Math.random() * 999999));
        setCountryName(resp?.data?.user_profile?.country);
        setLivesin(resp?.data?.user_profile?.livesIn);
        setBirthPlace(resp?.data?.user_profile?.birthPlace);
        setFullName(resp?.data?.fullName);
        setBirthday(resp?.data?.user_profile?.birthday);
        setNickName(resp?.data?.user_profile?.nickname);
        setGender(resp?.data?.user_profile?.gender);
        setProfileTagline(resp?.data?.user_profile?.profileTagline);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!fullName) {
      setFullNameError("Please enter full name");
    } else if (fullName) {
      setFullNameError("");
    }
    if (!nickname) {
      setNickNameError("Please enter nickname");
    } else if (nickname) {
      setNickNameError("");
    }
    if (!profileTagline) {
      setProfileTaglineError("Please enter profile tagline");
    } else if (profileTagline) {
      setProfileTaglineError("");
    }
    if (!birthday) {
      setBirthdayError("Please enter date of birth");
    } else if (birthday) {
      setBirthdayError("");
    }
    if (!birthplace) {
      setBirthPlaceError("Please enter birthplace");
    } else if (birthplace) {
      setBirthPlaceError("");
    }
    if (!livesin) {
      setLivesinError("Please enter lives in");
    } else if (livesin) {
      setLivesinError("");
    }
    if (!gender) {
      setGenderError("Please select gender");
    } else if (gender) {
      setGenderError("");
    }
    // if (!bloodGroup) {
    //     setBloodGroupError("Please select blood group");
    // } else if (bloodGroup) {
    //     setBloodGroupError("");
    // }
    if (!country) {
      setCountryError("Please select country");
    } else if (country) {
      setCountryError("");
    }
    if (!about) {
      setAboutError("Please enter about");
    } else if (about) {
      setAboutError("");
    }
    if (
      fullName &&
      nickname &&
      profileTagline &&
      birthday &&
      birthplace &&
      livesin &&
      gender &&
      country &&
      about
    ) {
      let data;
      if (bloodGroup) {
        data = {
          full_name: fullName,
          about_me: about,
          birthday: birthday,
          nickname: nickname,
          profile_tagline: profileTagline,
          birth_place: birthplace,
          lives_in: livesin,
          country: country,
          gender: gender,
          blood_group: bloodGroup,
        };
      } else {
        data = {
          full_name: fullName,
          about_me: about,
          birthday: birthday,
          nickname: nickname,
          profile_tagline: profileTagline,
          birth_place: birthplace,
          lives_in: livesin,
          country: country,
          gender: gender,
          blood_group: bloodGroup,
        };
      }

      try {
        let resp = await CommonAPi(Endpoints.CreateUpdateProfile, data);
        if (resp && resp.success === true) {
          try {
            let resp = await FetchApi(Endpoints.GetProfile + "?id=" + userId);
            if (resp && resp.success === true) {
              window.localStorage.setItem("user", JSON.stringify(resp?.data));
              Navigate("/timeline/" + username);
              ToastMessage.Success("User Profile Updated Successfully");
            }
          } catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
              if (e.response.status === 401) {
                ToastMessage.Error("Session Expired! Please login again.");
                localStorage.clear();
                Navigate("/signin");
              } else {
                ToastMessage.Error(e.response.data.message);
              }
            }
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  const getCountry = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetCountry);
      if (resp && resp.success === true) {
        const newData = resp.data.map((item, index) => {
          return {
            id: item.id,
            name: item.countryName,
          };
        });
        setCountryData(newData);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleValidation = (e, value, field) => {
    e.preventDefault();
    if (!value && field === "fullname") {
      setFullNameError("Please enter full name");
    } else if (value && field === "fullname") {
      setFullNameError("");
    }
    if (!value && field === "nickname") {
      setNickNameError("Please enter nickname");
    } else if (value && field === "nickname") {
      setNickNameError("");
    }
    if (!value && field === "profiletagline") {
      setProfileTaglineError("Please enter profile tagline");
    } else if (value && field === "profiletagline") {
      setProfileTaglineError("");
    }
    if (!value && field === "dateOfBirth") {
      setBirthdayError("Please enter date of birth");
    } else if (value && field === "dateOfBirth") {
      setBirthdayError("");
    }
    if (!value && field === "birthplace") {
      setBirthPlaceError("Please enter birthplace");
    } else if (value && field === "birthplace") {
      setBirthPlaceError("");
    }
    if (!value && field === "livesIn") {
      setLivesinError("Please enter lives in");
    } else if (value && field === "livesIn") {
      setLivesinError("");
    }
    if (!value && field === "gender") {
      setGenderError("Please select gender");
    } else if (value && field === "gender") {
      setGenderError("");
    }
    // if (!value && field === "bloodGroup") {
    //     setBloodGroupError("Please select blood group");
    // } else if (value && field === "bloodGroup") {
    //     setBloodGroupError("");
    // }
    if (!value && field === "country") {
      setCountryError("Please select country");
    } else if (value && field === "country") {
      setCountryError("");
    }
    if (!value && field === "about") {
      setAboutError("Please enter about");
    } else if (value && field === "about") {
      setAboutError("");
    }
  };

  const handleOnSearch = (string) => {
    console.log("string", string);
  };

  const handleOnSelect = (item) => {
    if (item.length > 0) {
      setCountry(item[0].id);
    }
  };

  const handleProfilePicture = async (e) => {
    setShow(true);
    setProfilePictureError("");
    setProfilePicture({
      fileBytes: e.target.files[0],
      fileUrl: URL.createObjectURL(e.target.files[0]),
    });
    let imageDataURL = await readFile(e.target.files[0]);
    const orientation = await getOrientation(e.target.files[0]);
    const rotation = ORIENTATION_TO_ANGLE[orientation];
    if (rotation) {
      imageDataURL = await getRotatedImage(imageDataURL, rotation);
    }
    setProfileImageSrc(imageDataURL);
  };

  const RenderCountryName = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example"
        defaultInputValue={countryName}
        key={countryKey}
        onSearch={handleOnSearch}
        onChange={handleOnSelect}
        onBlur={(e) => {
          handleValidation(e, e.target.value, "country");
        }}
        options={countryData}
        placeholder="Search by Country name"
        labelKey="name"
        minLength={1}
        emptyLabel={"Searching..."}
        className={
          countryError ? "is-danger  text-gray border" : " text-gray border"
        }
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [countryName, countryKey, countryData, countryError]);
  return (
    <Container header>
      <Helmet>
        <title>Edit Profile</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />

          <div className="container py-lg-4 py-3">
            <ProfileImage data={profileData} />

            <div className="row">
              <Sidebar />
              <div className="col-md-9">
                <div className="bg-white box-shadow rounded-15 p-md-3 p-2">
                  <div className="pt-md-2 pb-3 mb-3 px-lg-3 border-bottom">
                    <h3 className="medium">Profile Update</h3>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="position-relative banner"
                        id="profile-cover-container"
                        style={{ height: "13.3rem" }}
                      >
                        {profileData?.user_profile?.coverImg === null ? (
                          <img
                            src="assets/img/cover-pic.png"
                            alt=""
                            className="profile-edit w-100"
                          />
                        ) : (
                          <>
                            {coverImageSrc === null ? (
                              <img
                                src={
                                  Endpoints.baseUrl +
                                  profileData?.user_profile?.coverImg
                                }
                                alt=""
                                className="profile-edit w-100"
                                style={{
                                  height: "13.3rem",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <Cropper
                                image={coverImageSrc}
                                crop={crop}
                                zoom={zoom}
                                aspect={3.84 / 1.1}
                                showGrid={false}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                              />
                            )}
                          </>
                        )}
                        <div className="upload-coverpic position-absolute bottom-0 end-0 m-2">
                          {coverImageSrc ? (
                            <div className="">
                              <label
                                style={{ marginRight: "5px" }}
                                for="coverupload"
                                className="fs-12 medium bg-white rounded-pill px-1 py-1 cursor-pointer"
                                onClick={uploadCoverPicture}
                              >
                                Done
                              </label>
                              <span
                                onClick={() => {
                                  setCoverImageSrc(null);
                                }}
                                className="fs-12 medium bg-danger rounded-pill px-1 py-1 cursor-pointer"
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  width="14"
                                  height="14"
                                  stroke="#fff"
                                  strokeWidth="2"
                                  fill="none"
                                  stroke-linecap="round"
                                  strokeLinejoin="round"
                                  class="css-i6dzq1"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </span>
                            </div>
                          ) : (
                            <>
                              <input
                                type="file"
                                accept="image/*"
                                id="coverupload"
                                className="d-none"
                                onChange={(e) => {
                                  hadnleCoverPicture(e);
                                }}
                              />
                              <label
                                for="coverupload"
                                className="fs-14 medium bg-white rounded-pill px-3 py-2 cursor-pointer"
                              >
                                Update Cover
                                <img
                                  src="../assets/img/icon/upload-icon.svg"
                                  alt=""
                                  className="mx-1"
                                />
                              </label>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="pic-edit">
                        {profileData?.user_profile?.profileImg === null ? (
                          <img
                            src="assets/img/picture.png"
                            alt=""
                            width="96"
                            height="96"
                            className="rounded-circle"
                          />
                        ) : (
                          <img
                            src={
                              profileImageSrc
                                ? profileImageSrc
                                : Endpoints.baseUrl +
                                  profileData?.user_profile?.profileImg
                            }
                            alt=""
                            width="96"
                            height="96"
                            className="rounded-circle"
                          />
                        )}
                        <div>
                          <input
                            type="file"
                            id="profilepic"
                            accept="image/*"
                            ref={profileRef}
                            className="d-none"
                            onChange={(e) => {
                              handleProfilePicture(e);
                              setShow(true);
                            }}
                          />

                          <label for="profilepic" className="cursor-pointer">
                            <img
                              src="assets/img/icon/icon-camera3.svg"
                              alt=""
                              className="d-flex"
                            />
                          </label>
                        </div>
                      </div>
                      {coverImageSrc && (
                        <div className="cover-slider-container">
                          <span>Zoom</span>
                          <div className="cover-slider">
                            <span className="slider-icons">-</span>
                            <input
                              type="range"
                              min="1"
                              max="3"
                              step="0.1"
                              value={zoom}
                              onChange={(e) => setZoom(e.target.value)}
                            />
                            <span className="slider-icons">+</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <form
                    onSubmit={handleSubmit}
                    className="kids-form generic-form"
                  >
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label>Full Name</label>
                        <input
                          type="text"
                          placeholder="Full Name"
                          className={
                            fullNameError
                              ? "is-danger  text-gray px-2 py-2 border"
                              : " text-gray px-2 py-2 border"
                          }
                          value={fullName}
                          onBlur={(e) => {
                            handleValidation(e, e.target.value, "fullname");
                          }}
                          onChange={(e) => {
                            setFullName(e.target.value);
                          }}
                        />
                        {fullNameError ? (
                          <div
                            className="form-error"
                            style={{ color: "white", margintop: "-10px" }}
                          >
                            {fullNameError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label>Nickname</label>
                        <input
                          type="text"
                          placeholder="Nickname"
                          className={
                            nicknameError
                              ? "is-danger  text-gray px-2 py-2 border"
                              : " text-gray px-2 py-2 border"
                          }
                          value={nickname}
                          onBlur={(e) => {
                            handleValidation(e, e.target.value, "nickname");
                          }}
                          onChange={(e) => {
                            setNickName(e.target.value);
                          }}
                        />
                        {nicknameError ? (
                          <div
                            className="form-error"
                            style={{ color: "white", margintop: "-10px" }}
                          >
                            {nicknameError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Profile Tagline</label>
                        <input
                          type="text"
                          placeholder="Profile Tagline"
                          className={
                            profileTaglineError
                              ? "is-danger  text-gray px-2 py-2 border"
                              : " text-gray px-2 py-2 border"
                          }
                          value={profileTagline}
                          onBlur={(e) => {
                            handleValidation(
                              e,
                              e.target.value,
                              "profiletagline"
                            );
                          }}
                          onChange={(e) => {
                            setProfileTagline(e.target.value);
                          }}
                        />
                        {profileTaglineError ? (
                          <div
                            className="form-error"
                            style={{ color: "white", margintop: "-10px" }}
                          >
                            {profileTaglineError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label>Date of Birth</label>
                        <input
                          type="date"
                          placeholder="date"
                          className={
                            birthdayError
                              ? "is-danger  text-gray px-2 py-2 border"
                              : " text-gray px-2 py-2 border"
                          }
                          value={birthday}
                          max={`${currentDate}`}
                          onBlur={(e) => {
                            handleValidation(e, e.target.value, "dateOfBirth");
                          }}
                          onChange={(e) => {
                            setBirthday(e.target.value);
                          }}
                        />
                        {birthdayError ? (
                          <div
                            className="form-error"
                            style={{ color: "white", margintop: "-10px" }}
                          >
                            {birthdayError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label>Birth Place</label>
                        <input
                          type="text"
                          placeholder="Birth Place"
                          className={
                            birthplaceError
                              ? "is-danger  text-gray px-2 py-2 border"
                              : " text-gray px-2 py-2 border"
                          }
                          value={birthplace}
                          onBlur={(e) => {
                            handleValidation(e, e.target.value, "birthplace");
                          }}
                          onChange={(e) => {
                            setBirthPlace(e.target.value);
                          }}
                        />
                        {birthplaceError ? (
                          <div
                            className="form-error"
                            style={{ color: "white", margintop: "-10px" }}
                          >
                            {birthplaceError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label>Lives In</label>
                        <input
                          type="text"
                          placeholder="Enter your City"
                          className={
                            livesinError
                              ? "is-danger  text-gray px-2 py-2 border"
                              : " text-gray px-2 py-2 border"
                          }
                          value={livesin}
                          onBlur={(e) => {
                            handleValidation(e, e.target.value, "livesIn");
                          }}
                          onChange={(e) => {
                            setLivesin(e.target.value);
                          }}
                        />
                        {livesinError ? (
                          <div
                            className="form-error"
                            style={{ color: "white", margintop: "-10px" }}
                          >
                            {livesinError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-6 mb-3 form-field">
                        <label>Gender</label>

                        <select
                          onBlur={(e) => {
                            handleValidation(e, e.target.value, "gender");
                          }}
                          onChange={(e) => {
                            setGender(e.target.value);
                          }}
                          className={
                            genderError
                              ? "is-danger  text-gray px-2 py-2 border"
                              : " text-gray px-2 py-2 border"
                          }
                        >
                          <option value={""}>Please select gender</option>
                          <option
                            selected={gender === "Male" ? true : false}
                            value="Male"
                          >
                            Male
                          </option>
                          <option
                            selected={gender === "Female" ? true : false}
                            value="Female"
                          >
                            Female
                          </option>
                          <option
                            selected={gender === "Other" ? true : false}
                            value={"Other"}
                          >
                            Other
                          </option>
                        </select>
                        {genderError ? (
                          <div
                            className="form-error"
                            style={{ color: "white", margintop: "-10px" }}
                          >
                            {genderError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Blood Group</label>
                        <select
                          onBlur={(e) => {
                            handleValidation(e, e.target.value, "bloodGroup");
                          }}
                          onChange={(e) => {
                            setBloodGroup(e.target.value);
                          }}
                          className={
                            bloodGroupError
                              ? "is-danger  text-gray px-2 py-2 border"
                              : " text-gray px-2 py-2 border"
                          }
                        >
                          <option value={""}>Please select blood group</option>
                          {BloodGroup.map((item) => {
                            return (
                              <option
                                selected={
                                  bloodGroup === item.value ? true : false
                                }
                                value={item.value}
                              >
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                        {bloodGroupError ? (
                          <div
                            className="form-error"
                            style={{ color: "white", margintop: "-10px" }}
                          >
                            {bloodGroupError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Country</label>
                        {RenderCountryName()}
                        {/* < select
        onBlur={(e) => {
            handleValidation(e, e.target.value, "country")
        }}
            onChange={(e) => { setCountry(e.target.value) }}
            className={countryError ?
                "is-danger  text-gray px-2 py-2 border"
                :
                " text-gray px-2 py-2 border"
            } >
            <option value={""}>Please select Country</option>
            <option value={""}>
            <input type="search" id="gsearch" name="gsearch" />
            </option>
            {
                countryData.map((item, index) => {
                    return (
                        <option key={index} selected={parseInt(country) === item.id ? true : false} value={item.id}>{item.countryName}</option>
                    )
                })
            }
        </select > */}
                        {countryError ? (
                          <div
                            className="form-error"
                            style={{ color: "white", margintop: "-10px" }}
                          >
                            {countryError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-12 mb-3">
                        <label>About</label>
                        <textarea
                          value={about}
                          onBlur={(e) => {
                            handleValidation(e, e.target.value, "about");
                          }}
                          onChange={(e) => {
                            setAbout(e.target.value);
                          }}
                          className={
                            aboutError
                              ? "is-danger text-gray px-4 py-4 border"
                              : "text-gray px-4 py-4 border"
                          }
                          placeholder="about me"
                        ></textarea>
                        {aboutError ? (
                          <div
                            className="form-error"
                            style={{ color: "white", margintop: "-10px" }}
                          >
                            {aboutError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-12 text-end">
                        <button type="submit" className="btn fs-14 ">
                          {" "}
                          Update{" "}
                          <img
                            src="../assets/img/icon/icon-right-arrow.svg"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {profileImageSrc && (
          <ProfileCropper
            setShow={setShow}
            show={show}
            profileImageSrc={profileImageSrc}
            profileRef={profileRef}
            onsuccess={getProfile}
          />
        )}

        <OnlineFriends />
      </div>
    </Container>
  );
}

export default ProfileUpdate;
