import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Profile from "./Profile";
import { Endpoints } from "../../../API/Endpoints";
import { FetchApi } from "../../../API/FetchApi";
import { DeleteApi } from "../../../API/DeleteApi";
import ToastMessage from "../../../Utils/ToastMessage";
import DeletePrompt from "../../../Components/DeletePrompt/DeletePrompt";
import CourseItem from "./CourseItem";
import { Helmet } from "react-helmet";

export default function MyCourses() {
  const param = useParams();
  const [courses, setCourses] = useState([]);
  const [publishCourses, setPublishCourses] = useState([]);
  const [droftCourses, setDroftCourses] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [status, setStatus] = useState("publish");
  const [loadStatus, setLoadStatus] = useState(false);
  const [limit, setLimit] = useState(12);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [droftcurrentPage, setdroftCurrentPage] = useState(0);
  const [droftlastPage, setdroftLastPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [item, setItem] = useState();
  useEffect(() => {
    const instructorStatus = localStorage.getItem("instructorStatus");
    if (instructorStatus === "Pending") {
      navigate("/mainfeed");
    }
    getPublishCourses("publish");
    getCategory();
  }, [param.slug]);

  const getPublishCourses = async (ctype) => {
    if (param.slug) {
      try {
        let resp = await FetchApi(
          Endpoints.GetTeacherCourses +
            "?" +
            param.slug +
            "&size=" +
            limit +
            "&ctype=" +
            ctype
        );
        if (resp && resp.message) {
          setCourses(resp.data.course);
          setPublishCourses(resp.data.course);
          setLastPage(resp.data.totalPages);
          setCurrentPage(resp.data.currentPage);
        } else {
          setCourses(resp?.data?.course);
          setPublishCourses(resp?.data?.course);
          setLastPage(resp?.data?.totalPages);
          setCurrentPage(resp?.data?.currentPage);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      try {
        let resp = await FetchApi(
          Endpoints.GetTeacherCourses + "?size=" + limit + "&ctype=" + ctype
        );
        if (resp && resp.message) {
          setCourses(resp.data.course);

          // if (resp.data.course.length > 0) {

          setPublishCourses(resp.data.course);
          setLastPage(resp.data.totalPages);
          setCurrentPage(resp.data.currentPage);
          // }else{

          // }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const getCoursesSorting = async (slug) => {
    try {
      let type = "publish";
      // if(status === true){
      //     type = "publish"
      // }
      // if(status === false){
      //     type = "draft"
      // }
      let resp = await FetchApi(
        Endpoints.GetTeacherCourses +
          "?" +
          slug +
          "&size=" +
          limit +
          "&ctype=" +
          status
      );
      if (resp && resp.success === true) {
        setCourses(resp.data.course);

        setPublishCourses(resp.data.course);
        setLastPage(resp.data.totalPages);
        setCurrentPage(resp.data.currentPage);

        setDroftCourses(resp.data.course);
        setdroftLastPage(resp.data.totalPages);
        setdroftCurrentPage(resp.data.currentPage);
      } else {
        setCourses(resp.data.course);
        if (status == true) {
          setPublishCourses(resp.data.course);
          setLastPage(resp.data.totalPages);
          setCurrentPage(resp.data.currentPage);
        }
        if (status == false) {
          setDroftCourses(resp.data.course);
          setdroftLastPage(resp.data.totalPages);
          setdroftCurrentPage(resp.data.currentPage);
        }
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const sortingOptions = [
    { label: "Sort by", value: "" },
    { label: "Title Ascending", value: "title=asc" },
    { label: "Title Descending", value: "title=desc" },
    { label: "Price Low to High", value: "price=asc" },
    { label: "Price High to Low", value: "price=desc" },
    { label: "Recently", value: "latest=desc" },
    { label: "Last", value: "latest=asc" },
  ];

  const handleSorting = (slug) => {
    navigate("/mycourses/" + slug);
    getCoursesSorting(slug);
  };

  const handleSearch = async (text) => {
    setSearchText(text);
    try {
      let resp = await FetchApi(
        Endpoints.GetTeacherCourses +
          "?keyword=" +
          text +
          "&size=" +
          limit +
          "&ctype=" +
          status
      );
      if (resp && resp.success === true) {
        setCourses(resp.data.course);
        setPublishCourses(resp?.data?.course);
        setLastPage(resp?.data?.totalPages);
        setCurrentPage(resp?.data?.currentPage);
        setDroftCourses(resp?.data?.course);
        setdroftLastPage(resp?.data?.totalPages);
        setdroftCurrentPage(resp?.data?.currentPage);
      } else {
        setCourses(resp.data.course);
        setPublishCourses(resp?.data?.course);
        setLastPage(resp?.data?.totalPages);
        setCurrentPage(resp?.data?.currentPage);
        setDroftCourses(resp?.data?.course);
        setdroftLastPage(resp?.data?.totalPages);
        setdroftCurrentPage(resp?.data?.currentPage);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getCategory = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetCourseCategory);
      if (resp && resp.success === true) {
        const categories = resp?.data?.filter((item) => item.totalCount > 0);
        // console.log("@#@#", resp.data, categories);
        setCategoryData(categories);
        setLastPage(resp.data.totalPages);
        setCurrentPage(resp.data.currentPage);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleDeleteCourses = async (Id) => {
    try {
      let resp = await DeleteApi(Endpoints.DeleteTeacherCourses + "/" + Id);
      if (resp && resp.success === true) {
        getPublishCourses();
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleLoadMore = async (type) => {
    const NewLimit = parseInt(limit) + 12;
    setLimit(NewLimit);
    setLoadStatus(true);
    try {
      let resp = await FetchApi(
        Endpoints.GetTeacherCourses + "?size=" + NewLimit + "&ctype=" + status
      );
      if (resp && resp.success === true) {
        // if(type === "publish"){
        setPublishCourses(resp.data.course);
        setLastPage(resp.data.totalPages);
        setCurrentPage(resp.data.currentPage);
        // }
        // if(type === "draft"){
        //     setDroftCourses(resp.data.course);
        //     setdroftLastPage(resp.data.totalPages);
        //     setdroftCurrentPage(resp.data.currentPage);
        // }
        setLoadStatus(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        ToastMessage.Error(e.response.data.message);
        setLoadStatus(false);
      }
    }
  };
  return (
    <Container header>
      <Helmet>
        <title>my courses</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-3">
            <Profile />

            <div className="rounded-15 box-shadow p-md-4 p-2 bg-white">
              <div className="main-top-row d-lg-flex justify-content-between align-items-center pb-3 row">
                <h3 className="medium col-lg-3 pb-lg-0 pb-3">My Courses</h3>

                <div className="d-md-flex gap-3 col-lg-9 justify-content-lg-end align-items-center">
                  <div className="mainmenu-page-search d-flex flex-row-reverse align-items-center">
                    <input
                      type="text"
                      placeholder="Search by title"
                      name="search"
                      className="bg-transparent fs-14 medium  text-darkgray"
                      value={searchText}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <button type="submit" className="bg-transparent border-0">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="#949494"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="css-i6dzq1"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </button>
                  </div>

                  <select
                    name="sortcatgeory"
                    id="sortcatgeory"
                    className="text-darkgray"
                    onChange={(e) => handleSorting(e.target.value)}
                  >
                    <option value="">Course Category</option>
                    {categoryData.map((item, index) => {
                      return (
                        <option key={index} value={"id=" + item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>

                  <select
                    name="sortby"
                    id="sortby"
                    className="text-darkgray"
                    onChange={(e) => handleSorting(e.target.value)}
                  >
                    {sortingOptions?.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>

                  <Link
                    to="/add-courses-basic-information"
                    className="btn py-2 fs-14 mt-md-0 mt-2 w-25"
                  >
                    Add Course +
                  </Link>
                </div>
              </div>

              <div className="mainmenu-filter-row pb-3">
                <ul className="filter d-flex flex-nowrap align-items-center gap-2 fs-14 medium">
                  <li
                    className={status === "publish" ? "active " : ""}
                    onClick={() => {
                      setStatus("publish");
                      getPublishCourses("publish");
                      setSearchText("");
                    }}
                    data-filter="publish"
                  >
                    Publish Courses
                  </li>

                  <li
                    onClick={() => {
                      setStatus("draft");
                      getPublishCourses("draft");
                      setSearchText("");
                    }}
                    data-filter="draft"
                    className={status === "draft" ? "active " : ""}
                  >
                    Draft Courses
                  </li>
                  <li
                    onClick={() => {
                      setStatus("own");
                      getPublishCourses("own");
                      setSearchText("");
                    }}
                    data-filter="own"
                    className={status === "own" ? "active " : ""}
                  >
                    Own Courses
                  </li>
                  <li
                    onClick={() => {
                      setStatus("collab");
                      getPublishCourses("collab");
                      setSearchText("");
                    }}
                    data-filter="collab"
                    className={status === "collab" ? "active " : ""}
                  >
                    Collab Courses
                  </li>
                </ul>
              </div>
              {status === "publish" ? (
                publishCourses.length > 0 ? (
                  <div className="row">
                    {publishCourses.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <CourseItem
                            {...{
                              item: item,
                              index: index,
                              setItem: setItem,
                            }}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                ) : (
                  <div classNameName="row">
                    <p classNameName="text-center">No course found.</p>
                  </div>
                )
              ) : (
                ""
              )}

              {status === "draft" ? (
                publishCourses.length > 0 ? (
                  <div className="row">
                    {publishCourses.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <CourseItem
                            {...{
                              item: item,
                              index: index,
                              setItem: setItem,
                            }}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                ) : (
                  <div classNameName="row">
                    <p classNameName="text-center">No course found.</p>
                  </div>
                )
              ) : (
                ""
              )}

              {status === "own" ? (
                publishCourses.length > 0 ? (
                  <div className="row">
                    {publishCourses.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <CourseItem
                            {...{
                              item: item,
                              index: index,
                              setItem: setItem,
                            }}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                ) : (
                  <div classNameName="row">
                    <p classNameName="text-center">No course found.</p>
                  </div>
                )
              ) : (
                ""
              )}

              {status === "collab" ? (
                publishCourses.length > 0 ? (
                  <div className="row">
                    {publishCourses.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <CourseItem
                            {...{
                              item: item,
                              index: index,
                              setItem: setItem,
                            }}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                ) : (
                  <div classNameName="row">
                    <p classNameName="text-center">No course found.</p>
                  </div>
                )
              ) : (
                ""
              )}

              {status === true ? (
                publishCourses.length > 0 ? (
                  <div className="text-center">
                    {lastPage === currentPage ? (
                      ""
                    ) : (
                      <Link
                        href="#"
                        className="text-blue"
                        onClick={() => handleLoadMore("publish")}
                      >
                        {loadStatus ? (
                          <img
                            src="../assets/img/icon/icon-loading.svg"
                            alt=""
                          />
                        ) : (
                          "Load More Courses "
                        )}
                      </Link>
                    )}
                  </div>
                ) : (
                  ""
                )
              ) : droftCourses.length > 0 ? (
                <div className="text-center">
                  {droftlastPage === droftcurrentPage ? (
                    ""
                  ) : (
                    <Link
                      href="#"
                      className="text-blue"
                      onClick={() => handleLoadMore("draft")}
                    >
                      {loadStatus ? (
                        <img src="../assets/img/icon/icon-loading.svg" alt="" />
                      ) : (
                        "Load More Courses "
                      )}
                    </Link>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>

            <DeletePrompt
              type={"Course"}
              item={item}
              deleteType={handleDeleteCourses}
            />
          </div>

          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
