import React from "react";
import ReactionsModal from "../ReactionsModal/ReactionsModal";

const PostReactionCounter = ({
  allReactions,
  setModalState,
  selectedReaction,
  post,
  backdropHandle,
  setPage,
  setReactionModalVar,
  setReactionModalOpen,
  setTotalPostReactions,
  reactionsModalRef,
  handleScroll,
  totalPostReactions,
}) => {
  return (
    <div className="reaction-list pe-3 pt-1" style={{ marginTop: "-5px" }}>
      <span
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        onClick={() => {
          allReactions(post?.id);
          setModalState("hide");
        }}
        //    data-bs-toggle="modal1" data-bs-target="#exampleModal123"
      >
        {selectedReaction && (
          <a href="#">
            <img
              style={{ marginRight: "4px" }}
              src={`../assets/img/icon/${selectedReaction}.svg`}
              alt=""
              className="rounded-circle"
            />
          </a>
        )}
        {post?.hahaReactions > 0 && selectedReaction !== "Haha" && (
          <a href="#">
            <img
              style={{ marginRight: "4px" }}
              src={`../assets/img/icon/Haha.svg`}
              alt=""
              className="rounded-circle"
            />
          </a>
        )}
        {post?.likeReactions > 0 && selectedReaction !== "Like" && (
          <a href="#">
            <img
              style={{ marginRight: "4px" }}
              src={`../assets/img/icon/Like.svg`}
              alt=""
              className="rounded-circle"
            />
          </a>
        )}
        {post?.careReactions > 0 && selectedReaction !== "Care" && (
          <a href="#">
            <img
              style={{ marginRight: "4px" }}
              src={`../assets/img/icon/Care.svg`}
              alt=""
              className="rounded-circle"
            />
          </a>
        )}
        {post?.angryReactions > 0 && selectedReaction !== "Angry" && (
          <a href="#">
            <img
              style={{ marginRight: "4px" }}
              src={`../assets/img/icon/Angry.svg`}
              alt=""
              className="rounded-circle"
            />
          </a>
        )}
        {post?.loveReactions > 0 && selectedReaction !== "Love" && (
          <a href="#">
            <img
              style={{ marginRight: "4px" }}
              src={`../assets/img/icon/Love.svg`}
              alt=""
              className="rounded-circle"
            />
          </a>
        )}
        {post?.sadReactions > 0 && selectedReaction !== "Sad" && (
          <a href="#">
            <img
              style={{ marginRight: "4px" }}
              src={`../assets/img/icon/Sad.svg`}
              alt=""
              className="rounded-circle"
            />
          </a>
        )}
        {post?.wowReactions > 0 && selectedReaction !== "Wow" && (
          <a href="#">
            <img
              style={{ marginRight: "4px" }}
              src={`../assets/img/icon/Wow.svg`}
              alt=""
              className="rounded-circle"
            />
          </a>
        )}
      </span>

      <ReactionsModal
        allReactions={allReactions}
        setModalState={setModalState}
        selectedReaction={selectedReaction}
        post={post}
        backdropHandle={backdropHandle}
        setPage={setPage}
        setReactionModalVar={setReactionModalVar}
        setReactionModalOpen={setReactionModalOpen}
        setTotalPostReactions={setTotalPostReactions}
        reactionsModalRef={reactionsModalRef}
        handleScroll={handleScroll}
        totalPostReactions={totalPostReactions}
      />
    </div>
  );
};

export default PostReactionCounter;
