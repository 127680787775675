import React, { useEffect, useState } from "react";
import { CommonAPi } from "../../../API/CommonApi";
import { Endpoints } from "../../../API/Endpoints";
import "./ChatListItem.css";
import { timeSince } from "../../../Utils/helpers";
const ChatListItem = ({ data, currentUser, online, active, receivedMsg }) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [lastMsg, setLastMsg] = useState("");

  useEffect(() => {
    setUnreadCount(data.unReadMsgCount);
    setLastMsg(data.lastMsg);
  }, [data.lastMsg, data]);

  useEffect(() => {
    if (receivedMsg != null && receivedMsg.chat_room_id == data.chatRoomId) {
      // if(active && active !== data.id ) setUnreadCount(prev => prev+1);
      setLastMsg(receivedMsg);
    }
  }, [receivedMsg]);

  return (
    <li
      className={`message-item p-3 d-flex align-items-center ${
        active && active === data.id && "active-userchat"
      }`}
    >
      <div className="position-relative">
        {data.TYPE === "user" ? (
          data?.user?.user_profile?.profileImg ? (
            <img
              src={`${
                Endpoints.baseUrl + data?.user?.user_profile?.profileImg
              }`}
              alt=""
              width="48"
              height="48"
              className="rounded-circle"
            />
          ) : (
            <img
              src="assets/img/picture.png"
              alt=""
              width="48"
              height="48"
              className="rounded-circle"
            />
          )
        ) : data?.thumbnail ? (
          <>
            <img
              src={`${Endpoints.baseUrl + data?.thumbnail}`}
              alt=""
              width="48"
              height="48"
              className="rounded-circle"
            />
          </>
        ) : (
          <img
            src="assets/img/picture.png"
            alt=""
            width="48"
            height="48"
            className="rounded-circle"
          />
        )}

        {
          // data.TYPE !== "course" &&
          online && (
            <span className="user-status">
              <svg
                viewBox="0 0 24 24"
                width="12"
                height="12"
                stroke="#fff"
                strokeWidth="1"
                fill="#57CB6C"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10"></circle>
              </svg>
            </span>
          )
        }
      </div>
      <div className="ps-2 w-100">
        <div className="d-flex align-items-center justify-content-between w-100">
          <h6 className="medium text-black">
            {data?.TYPE === "course"
              ? data?.title?.length > 24
                ? data?.title?.substring(0, 24) + "..."
                : data.title
              : data?.user?.fullName}
          </h6>
          <h6 className="fs-12 regular text-darkgray">
            {lastMsg && timeSince(new Date(lastMsg.createdAt)) + " ago"}
            {data?.TYPE === "course" ? (
              <>
                <div className="fs-10 font-weight-bold bg-primary rounded text-light px-1">
                  Broadcast
                </div>
              </>
            ) : (
              ""
            )}
          </h6>
        </div>

        <div className="d-flex align-items-center justify-content-between w-100 mt-1">
          <h6 className="fs-12 regular text-darkgray">
            {lastMsg && lastMsg?.message?.length > 37
              ? lastMsg?.message?.substring(0, 37) + "..."
              : lastMsg?.message}
          </h6>
          {unreadCount > 0 && (
            <span className="user-active " hidden="">
              <span className="msg-count">{unreadCount}</span>
              {/* <svg viewBox="0 0 24 24" width="12" height="12" stroke="#0C64C5" strokeWidth="2" fill="#0C64C5" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10"></circle>
                    </svg> */}
            </span>
          )}
        </div>
      </div>
    </li>
  );
};

export default ChatListItem;
