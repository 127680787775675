import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Endpoints } from "../API/Endpoints";
import IsOnline from "./IsOnline";
import { FetchApi } from "../API/FetchApi";
import { linkReplace, timeSince } from "../Utils/helpers";
import { CommonAPi } from "../API/CommonApi";
import { StickyChatContext } from "./StickyChatWrapper";
import EmojiPickerFloatingWindow from "./emojiPicker/EmojiPickerFloatingWindow";
import { IoPlayCircle } from "react-icons/io5";
import ImagePreviewModel from "./Post/ImagePreviewModal";

export default function StickyChat({ chat, isOnline, index, onClose }) {
  const user = useMemo(() => JSON.parse(localStorage.getItem("user")), []);

  const { socket, chats, setChats } = useContext(StickyChatContext);

  const chatContainerRef = useRef(null);

  const filePreviewBox = useRef(null);

  const savedScrollPosition = useRef();

  const inputRef = useRef(null);

  const fileRef = useRef();

  const emojiButtonRef = useRef();

  const [newMsg, setNewMsg] = useState("");

  const [messages, setMessages] = useState([]);

  const [file, setFile] = useState(null);
  const [fileSelectionError, setFileSelectionError] = useState("");

  const [sendingStatus, setSendingStatus] = useState("");

  const [currentPage, setCurrentPage] = useState(0);

  const [loadingMessages, setLoadingMessages] = useState(false);

  const [totalPages, setTotalPages] = useState();

  const [showEmojiSelector, setShowEmojiSelector] = useState(false);

  const [show, setShow] = useState(false);
  const [selectedMsg, setSelectedMsg] = useState("");

  const handleNewMsg = async (e) => {
    e.preventDefault();
    if (sendingStatus === "sending") return;
    setFileSelectionError("");
    if (newMsg || file) {
      setSendingStatus("sending");
      const formdata = new FormData();
      formdata.append("sender_id", user.id);
      formdata.append("message", newMsg);
      if (file) formdata.append("file", file.file);
      formdata.append("chat_room_id", chat.id);
      try {
        const resp = await CommonAPi(Endpoints.newMessage, formdata);
        if (resp?.success) {
          setFileSelectionError("");
          setMessages([...messages, resp.data]);
          setNewMsg("");
          setFile("");
          fileRef.current.value = "";

          // sorting chat
          const chatIndex = chats.findIndex(
            (searchElem) => searchElem.id === resp.data.chatRoomId
          );
          const newChats = structuredClone(chats);
          newChats[chatIndex].lastMessage = resp.data;
          newChats.sort(
            (a, b) =>
              new Date(b?.lastMessage?.createdAt) -
              new Date(a?.lastMessage?.createdAt)
          );
          setChats(newChats);
          // to socket
          const arrayOfUsers = chat.chat_room_members
            .filter((searchUser) => searchUser.user.id !== user.id)
            .map((item) => item.user.id);
          socket.emit("subscribe", chat.id, arrayOfUsers);
          socket.emit("message", chat.id, resp.data);

          setSendingStatus("");
        }
      } catch (error) {
        console.log(error);
        setFileSelectionError("");
        setSendingStatus("failed");
      }
    }
  };

  const handleRemove = useCallback(() => {
    onClose(index);
  }, [onClose, index]);

  const handleEmojiSelect = useCallback((emoji) => {
    setShowEmojiSelector(false);
    setNewMsg((currentMsg) => currentMsg + emoji);
    inputRef?.current?.focus();
  }, []);

  const handleEmojiSelectionOpen = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowEmojiSelector(true);
  }, []);

  const handleEmojiSelectionClose = useCallback(() => {
    setShowEmojiSelector(false);
  }, []);

  const handleFileChange = useCallback((e) => {
    setFileSelectionError("");
    const fileType = e.target.files[0].type;
    if (
      fileType.includes("image") ||
      fileType.includes("video") ||
      fileType.includes("pdf") ||
      fileType.includes("doc") ||
      fileType.includes("docx")
    ) {
      setFile({ file: e.target.files[0], type: e.target.files[0].type });
    } else {
      setFileSelectionError("Please select image, pdf, doc files only");
    }
  }, []);

  const getMessages = useCallback(async () => {
    savedScrollPosition.current =
      chatContainerRef.current.scrollHeight -
      chatContainerRef.current.scrollTop;
    const nextPage = currentPage + 1;
    if (totalPages && nextPage > totalPages) return;
    setLoadingMessages(true);
    try {
      const resp = await FetchApi(
        `${Endpoints.getChatByRoom}${chat.id}?page=${nextPage}&size=20`
      );
      setLoadingMessages(false);
      if (resp?.success) {
        if (chats.length > 0) {
          // read un-read message
          const chatIndex = chats.findIndex(
            (searchElem) => searchElem.id === chat.id
          );
          const newChats = structuredClone(chats);
          newChats[chatIndex].unreadMsgCount = 0;
          setChats(newChats);
        }

        setCurrentPage(nextPage);
        setMessages((currentMessages) => [
          ...resp.data.messages.reverse(),
          ...currentMessages,
        ]);
        setTotalPages(resp.data.totalPages);
      } else {
        setMessages([]);
      }
    } catch (error) {
      setLoadingMessages(false);
      console.log(error);
    }
  }, [chat, chats, currentPage, setChats, totalPages]);

  const checkScrollPosition = useCallback(() => {
    if (chatContainerRef.current.scrollTop < 20 && !loadingMessages) {
      getMessages();
    }
  }, [getMessages, loadingMessages]);

  // get user messages
  useEffect(() => {
    getMessages();
    if (inputRef.current) inputRef.current.focus();
  }, [chat]);

  // scrolling to bottom, when message changes
  useEffect(() => {
    if (chatContainerRef.current && currentPage === 1) {
      chatContainerRef.current.scrollTo(
        0,
        chatContainerRef.current.scrollHeight
      );
    }
  }, [messages]);

  // recieve msg from socket
  useEffect(() => {
    const onMessage = (data) => {
      if (data.chatRoomId === chat.id)
        setMessages((currentMessages) => [...currentMessages, data]);
    };
    socket.on("messages", onMessage);
  }, []);

  // refresh message, so that time passed is updated
  useEffect(() => {
    const interval = setInterval(() => {
      setMessages((currentMessages) =>
        JSON.parse(JSON.stringify(currentMessages))
      );
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // restoring chat message position
  useLayoutEffect(() => {
    if (savedScrollPosition.current)
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight - savedScrollPosition.current;
  }, [messages]);

  useLayoutEffect(() => {
    if (filePreviewBox.current) {
      filePreviewBox.current.style.top = `-${filePreviewBox.current.offsetHeight}px`;
    }
  }, [file]);

  const memoizedMessages = useMemo(() => {
    return messages.map((msg, i) => {
      return (
        <div
          key={msg.id}
          className={`msg-item mb-2 ${msg.senderId} ${
            msg.senderId === user.id && "reply-msg"
          }`}
          style={{ maxWidth: "max-content", wordBreak: "break-word" }}
        >
          <div className="pb-2">
            {msg?.new === true ? (
              <div className="msg-file-container">
                {msg?.file &&
                  (msg?.msgType.includes("jpg") ||
                    msg?.msgType.includes("jpeg") ||
                    msg?.msgType.includes("png")) && (
                    <img src={msg?.file} alt="7" />
                  )}
                {msg?.file &&
                  !msg?.msgType.includes("jpg") &&
                  !msg?.msgType.includes("jpeg") &&
                  !msg?.msgType.includes("png") && (
                    <a
                      href={msg?.file}
                      style={{ fontWeight: "bold" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {
                        //    msg?.file.substr(50)
                        msg.msgType.endsWith(".pdf") && (
                          <img
                            src="/assets/img/icon/pdf_icon.png"
                            alt="chat-icon"
                            style={{ width: "100%", height: "100%" }}
                          />
                        )
                      }

                      {
                        //    msg?.file.substr(50)
                        (msg.msgType.endsWith(".doc") ||
                          msg.msgType.endsWith(".docx")) && (
                          <img
                            src="/assets/img/icon/word_icon.png"
                            alt="chat-icon"
                            style={{ width: "100%", height: "100%" }}
                          />
                        )
                      }

                      {
                        //    msg?.file.substr(50)
                        msg.msgType.endsWith(".xlsx") && (
                          <img
                            src="/assets/img/icon/excel_icon.png"
                            alt="chat-icon"
                            style={{ width: "100%", height: "100%" }}
                          />
                        )
                      }
                    </a>
                  )}
              </div>
            ) : (
              <div className="msg-file-container">
                {msg?.course_message_media?.file &&
                  (msg?.course_message_media?.file?.includes("jpg") ||
                    msg?.course_message_media?.file?.includes("jpeg") ||
                    msg?.course_message_media?.file?.includes("png")) && (
                    <img
                      onClick={() => {
                        setShow(true);
                        setSelectedMsg(msg);
                      }}
                      src={Endpoints?.baseUrl + msg?.course_message_media?.file}
                      alt=""
                    />
                  )}
                {msg?.course_message_media?.file &&
                  (msg?.course_message_media?.file?.includes("mp4") ||
                    msg?.course_message_media?.file?.includes("avi") ||
                    msg?.course_message_media?.file?.includes("mov") ||
                    msg?.course_message_media?.file?.includes("ogg") ||
                    msg?.course_message_media?.file?.includes("wmv") ||
                    msg?.course_message_media?.file?.includes("webm")) && (
                    <div>
                      <video
                        style={{
                          height: "8rem",
                          width: "8rem",
                          borderRadius: "8px",
                          objectFit: "cover",
                        }}
                        src={
                          Endpoints?.baseUrl + msg?.course_message_media?.file
                        }
                        alt=""
                        onClick={() => {
                          setShow(true);
                          setSelectedMsg(msg);
                        }}
                      ></video>
                      <div
                        style={{
                          position: "absolute",
                          right: "50%",
                          top: "50%",
                        }}
                      >
                        <IoPlayCircle
                          onClick={() => {
                            setShow(true);
                            setSelectedMsg(msg);
                          }}
                          className="text-white cursor-pointer"
                          size={25}
                        />
                      </div>
                    </div>
                    // <video
                    //   src={Endpoints?.baseUrl + msg?.course_message_media?.file}
                    //   alt=""
                    //   // controls
                    // ></video>
                  )}
                {msg?.course_message_media?.file &&
                  !msg?.course_message_media?.file?.includes("jpg") &&
                  !msg?.course_message_media?.file?.includes("jpeg") &&
                  !msg?.course_message_media?.file?.includes("png") && (
                    <a
                      href={Endpoints.baseUrl + msg?.course_message_media?.file}
                      style={{ fontWeight: "bold" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {msg?.course_message_media?.file.endsWith(".pdf") && (
                        <img
                          src="/assets/img/icon/pdf_icon.png"
                          alt="chat-icon"
                          style={{
                            width: "60%",
                            borderRadius: "0",
                            height: "100%",
                          }}
                        />
                      )}

                      {(msg?.course_message_media?.file.endsWith(".doc") ||
                        msg?.course_message_media?.file.endsWith(".docx")) && (
                        <img
                          src="/assets/img/icon/word_icon.png"
                          alt="chat-icon"
                          style={{
                            width: "60%",
                            borderRadius: "0",
                            height: "100%",
                          }}
                        />
                      )}

                      {msg?.course_message_media?.file.endsWith(".xlsx") && (
                        <img
                          src="/assets/img/icon/excel_icon.png"
                          alt="chat-icon"
                          style={{
                            width: "60%",
                            borderRadius: "0",
                            height: "100%",
                          }}
                        />
                      )}
                    </a>
                  )}
              </div>
            )}

            <p dangerouslySetInnerHTML={{ __html: linkReplace(msg.message) }} />
          </div>
          <div className="text-end">
            <h6 className="fs-12 text-darkgray regular">
              <img src="/assets/img/icon/icon-date-time.svg" alt="" />
              {" " + timeSince(new Date(msg.createdAt))} ago
            </h6>
          </div>
        </div>
      );
    });
  }, [messages, user.id]);

  const otherChatUserInRoom = useMemo(
    () =>
      chat.chat_room_members.find(
        (searchUser) => searchUser.user.id !== user.id
      ),
    [chat, user.id]
  );

  const imageToRender = useMemo(() => {
    if (chat.roomName !== null) {
      const person1 = chat.chat_room_members[0];
      const person2 = chat.chat_room_members[1];

      return (
        <div className="group-chat-image-container">
          <img
            src={`${
              Endpoints.baseUrl + person1?.user?.user_profile?.profileImg
            }`}
            alt=""
            width="36"
            height="36"
            className="group-chat-image"
          />
          <img
            src={`${
              Endpoints.baseUrl + person2?.user?.user_profile?.profileImg
            }`}
            alt=""
            width="36"
            height="36"
            className="group-chat-image"
          />
        </div>
      );
    }
    if (otherChatUserInRoom)
      return (
        <img
          src={`${
            Endpoints.baseUrl +
            otherChatUserInRoom?.user.user_profile.profileImg
          }`}
          alt=""
          width="48"
          height="48"
          className="rounded-circle"
        />
      );
    return (
      <img
        src="/assets/img/picture.png"
        alt=""
        width="48"
        height="48"
        className="rounded-circle"
      />
    );
  }, [chat, otherChatUserInRoom]);

  // const userPicture = useMemo(() => {
  //   if (chat.roomName === null && otherChatUserInRoom)
  //     return `${
  //       Endpoints.baseUrl + otherChatUserInRoom?.user.user_profile.profileImg
  //     }`;
  //   if (chat.thumbnail) return `${Endpoints.baseUrl + chat.thumbnail}`;

  //   if (chat.roomName !== null) {
  //     const person1 = chat.chat_room_members[0];
  //     const person2 = chat.chat_room_members[1];

  //     return (
  //       <div className="group-chat-image-container">
  //         <img
  //           src={`${Endpoints.baseUrl + person1?.user?.user_profile?.profileImg}`}
  //           alt=""
  //           width="36"
  //           height="36"
  //           className="group-chat-image"
  //         />
  //         <img
  //           src={`${Endpoints.baseUrl + person2?.user?.user_profile?.profileImg}`}
  //           alt=""
  //           width="36"
  //           height="36"
  //           className="group-chat-image"
  //         />
  //       </div>
  //     );
  //   }

  //   return `${process.env.PUBLIC_URL}/assets/img/picture.png`;
  // }, [chat, otherChatUserInRoom]);

  return (
    <div
      style={{
        // height: 500,
        width: 330,
        display: "flex",
        flexDirection: "column",
        background: "white",
        boxShadow: "0 0px 20px -5px #bbb",
      }}
    >
      {/* header */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px",
          borderBottom: "1px solid #ddd",
        }}
      >
        <div className="d-flex gap-3 flex-fill">
          <div className="position-relative flex-shrink-0">
            {/* <img
              src={userPicture}
              alt="user-profile"
              style={{
                height: 48,
                width: 48,
                borderRadius: "50%",
                objectFit: "cover",
              }}
            /> */}
            {imageToRender}
            {chat.roomName === null && isOnline && <IsOnline />}
          </div>
          <div className="d-flex flex-column pt-1 flex-fill">
            <p className="medium text-black fs-16 mb-0 text-truncate w-75">
              {chat.roomName === null && otherChatUserInRoom
                ? otherChatUserInRoom?.user.fullName || "Username"
                : chat.roomName}
            </p>
            {chat.roomName === null && (
              <p
                style={{
                  fontSize: 12,
                  color: isOnline ? "green" : "#aaa",
                  fontWeight: isOnline ? 500 : 300,
                  marginBottom: 0,
                }}
              >
                {isOnline ? "Online" : "Offline"}
              </p>
            )}
          </div>
        </div>
        {/* close button */}
        <button
          style={{
            flex: "0 0 auto",
            borderRadius: "50%",
            border: "none",
            background: "#eee",
            height: 40,
            width: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={handleRemove}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/img/icon/icon-close-cancel.svg`}
            alt="close"
            style={{ height: 22, width: 22 }}
          />
        </button>
      </div>
      {/* chat body */}
      <div
        style={{
          height: 350,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          ref={chatContainerRef}
          className="messages overflow-auto h-100 p-2"
          onScroll={checkScrollPosition}
        >
          {loadingMessages && (
            <div className="d-flex justify-content-center my-3">
              <div
                class="spinner-border text-dark spinner-border-sm"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {memoizedMessages}
          {show && (
            <ImagePreviewModel
              show={show}
              onHide={() => setShow(false)}
              type={"friend"}
              message={selectedMsg}
            />
          )}
          {sendingStatus === "failed" && (
            <p
              style={{
                color: "red",
                fontSize: 11,
                fontWeight: 500,
                margin: "15px 0 0px 0",
                textAlign: "center",
              }}
            >
              Failed to send new message
            </p>
          )}
        </div>
      </div>
      {/* input */}
      <form
        className="msg-add-form d-flex align-items-center ps-2 position-relative flex-fill"
        onSubmit={handleNewMsg}
        encType="multipart/form-data"
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            gap: 8,
          }}
        >
          <input
            accept=".pdf, .doc, .docx, .xlsx, .webp, .jpg, .jpeg, .png, video/*"
            type="file"
            name="file"
            id={`sticky-chat-file-${chat.id}`}
            className="d-none"
            ref={fileRef}
            onChange={handleFileChange}
          />
          <label
            htmlFor={`sticky-chat-file-${chat.id}`}
            style={{ cursor: "pointer" }}
            id="message_option"
            // data-bs-toggle="dropdown"
            className="d-flex align-items-center justify-content-center rounded-circle bg-eee flex-fill"
          >
            <img
              src="/assets/img/icon/file icon.svg"
              alt=""
              style={{ height: 16, width: 16, maxWidth: "unset" }}
            />
          </label>
          <button
            type="button"
            ref={emojiButtonRef}
            style={{
              border: "none",
              background: "transparent",
              padding: 0,
              height: 16,
              width: 16,
              display: "flex",
            }}
            onClick={handleEmojiSelectionOpen}
          >
            <img
              class="post-image flex-fill"
              src="../assets/img/icon/emoji_icon.png"
              alt=""
              style={{ height: 16, width: 16 }}
            />
          </button>
        </div>
        {file && (
          <div className="file-preview-box" ref={filePreviewBox}>
            <div
              className="svg-container"
              onClick={() => {
                if (sendingStatus !== "sending") {
                  setFile("");
                  fileRef.current.value = "";
                }
              }}
            >
              <svg
                height="15px"
                width="15px"
                id="Layer_1"
                style={{ cursor: "pointer" }}
                version="1.1"
                viewBox="0 0 512 512"
              >
                <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
              </svg>
            </div>
            <div className="preview-container">
              {file.type.includes("image") && (
                <img src={URL.createObjectURL(file.file)} alt="img-preview" />
              )}
              {(file.file.name.endsWith(".doc") ||
                file.file.name.endsWith(".docx")) && (
                <img
                  src={`/assets/img/icon/word_icon.png`}
                  className="chat-file-icons"
                  alt="img-preview"
                />
              )}
              {file.file.name.endsWith(".xlsx") && (
                <img
                  src={`/assets/img/icon/excel_icon.png`}
                  className="chat-file-icons"
                  alt="img-preview"
                />
              )}

              {file.file.name.endsWith(".pdf") && (
                <img
                  src="/assets/img/icon/pdf_icon.png"
                  className="chat-file-icons"
                  alt="img-preview"
                />
              )}
              {!file.type.includes("image") && (
                <a
                  href={URL.createObjectURL(file.file)}
                  style={{ fontWeight: "bold" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {file.file.name}
                </a>
              )}
              {fileSelectionError && (
                <span className="text-danger fs-12">{fileSelectionError}</span>
              )}
            </div>
          </div>
        )}
        {fileSelectionError && (
          <span
            className="text-danger fs-12"
            style={{
              position: "absolute",
              fontWeight: "600",
              bottom: "2.8rem",
            }}
          >
            {fileSelectionError}
          </span>
        )}

        <input
          disabled={sendingStatus === "sending"}
          type="text"
          ref={inputRef}
          value={newMsg}
          onChange={(e) => {
            setNewMsg(e.target.value);
          }}
          placeholder="Write message here..."
          className="fs-14 py-2 ps-2 pe-3 bg-transparent"
        />

        <button
          type="submit"
          className="d-flex gap-2 btn fs-14 h-100 radius-0 px-3 justify-content-center align-items-center"
          style={{ minWidth: 77 }}
        >
          {sendingStatus === "sending" ? (
            <div
              class="spinner-border text-light spinner-border-sm"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            <>
              <img src="/assets/img/icon/icon-send-message.svg" alt="msg-svg" />{" "}
              Send
            </>
          )}
        </button>
      </form>
      {showEmojiSelector && (
        <EmojiPickerFloatingWindow
          anchorRef={emojiButtonRef}
          onSelect={handleEmojiSelect}
          onClose={handleEmojiSelectionClose}
          width={300}
        />
      )}
    </div>
  );
}
