import React, { useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import CompanyProfiles from "./CompanyProfiles";
import LeftStatistics from "./LeftStatistics";
import PostItem from "./PostItem";
import RightSidebar from "./RightSidebar";
import { Helmet } from "react-helmet";

export default function CompanyPost() {
  const [statistics, setStatistics] = useState("");

  return (
    <Container header>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-lg-4 py-3">
            <CompanyProfiles />

            <div className="row">
              <LeftStatistics
                {...{
                  statistics: statistics,
                }}
              />

              <div className="col-md-6 feed-mid-column1 pb-3">
                <div className="d-none px-4 py-4 bg-white mb-3 rounded-15 shadow-md">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="medium fs-20">All Posts</h4>

                    <div className="">
                      <select
                        name=""
                        id=""
                        onChange={(e) => {
                          // setScope(e.target.value);
                        }}
                        // className={`w-auto px-3 ${
                        //   scope === "Member" && "pe-5"
                        // } rounded-pill bg-eee text-black py-2 posts-profile-select`}
                      >
                        <option value="">All</option>
                        <option value="Admin">Admin Post</option>
                        <option value="Member">Members Post</option>
                        <option value="Hastag">Hastag</option>
                      </select>
                    </div>
                  </div>
                </div>

                <PostItem
                  {...{
                    page: "homePost",
                    setStatistics: setStatistics,
                    showFilters: true,
                  }}
                />
              </div>

              <RightSidebar />
            </div>
          </div>

          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
