import React, { useEffect, useState } from "react";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from "../../API/FetchApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import ProfileNavigation from "../../Components/ProfileNavigation";
import Container from "../../Components/Container";
import Profile from "../ProfileAbout/Profile";
import VideoItem from "./VideoItem";
import CreateVideoItem from "./CreateVideoItem";
import PostLoader from "../../Components/PostLoader/PostLoader";
import { Helmet } from "react-helmet";
export default function Video() {
  const [video, setVideo] = useState([]);
  const [count, setCount] = useState(0);
  const Navigate = useNavigate();
  const param = useParams();
  const [loadStatus, setLoadStatus] = useState(false);
  const [limit, setLimit] = useState(15);
  const [status, setStatus] = useState(false);
  const userId = localStorage.getItem("userId");
  const username = localStorage.getItem("userName");
  useEffect(() => {
    getUserVideo();
  }, [param?.id]);

  const getUserVideo = async () => {
    setStatus(true);

    try {
      let resp = await FetchApi(
        Endpoints.UserPhotoVideo +
          "?size=" +
          limit +
          "&type=videos&id=" +
          param?.id
      );
      setStatus(false);
      if (resp && resp.success === true) {
        setVideo(resp?.data?.data);
        setCount(resp?.data?.count);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setStatus(false);
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleLoadMore = async () => {
    setLoadStatus(true);
    let newLimit = limit + 12;
    setLimit(newLimit);

    try {
      let resp = await FetchApi(
        Endpoints.UserPhotoVideo +
          "?size=" +
          newLimit +
          "&type=videos&id=" +
          param?.id
      );
      if (resp && resp.success === true) {
        setLoadStatus(false);
        setVideo(resp?.data?.data);
        setCount(resp?.data?.count);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoadStatus(false);
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSearch = async (value) => {
    try {
      let resp = await FetchApi(
        Endpoints.UserPhotoVideo +
          "?size=" +
          limit +
          "&type=videos&id=" +
          param?.id +
          "&term=" +
          value
      );
      if (resp && resp.success === true) {
        setLoadStatus(false);
        setVideo(resp?.data?.data);
        setCount(resp?.data?.count);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoadStatus(false);
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleSorting = async (value) => {
    try {
      let resp = await FetchApi(
        Endpoints.UserPhotoVideo +
          "?size=" +
          limit +
          "&type=videos&id=" +
          param?.id +
          "&sort=" +
          value
      );
      if (resp && resp.success === true) {
        setLoadStatus(false);
        setVideo(resp?.data?.data);
        setCount(resp?.data?.count);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoadStatus(false);
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Videos</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-lg-3 py-3">
            <div className="profile-banner-top mb-3  rounded-15 overflow-hidden">
              <Profile />

              <ProfileNavigation />
            </div>
            {status === false ? (
              <div class="bg-white main-common">
                <div class="main-top-row d-md-flex justify-content-between align-items-center pb-3">
                  <h3 class="medium">Videos</h3>
                  {/* {     username === param?.id ? */}
                  <div class="d-sm-flex gap-3 pb-3">
                    <form
                      action=""
                      class="mainmenu-page-search d-flex flex-row-reverse align-items-center"
                    >
                      <input
                        type="text"
                        placeholder="Search for Videos"
                        name="search"
                        class="bg-transparent fs-14 medium  text-darkgray"
                        onChange={(e) => {
                          handleSearch(e.target.value);
                        }}
                      />
                      <button type="submit" class="bg-transparent border-0">
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          stroke="#949494"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          class="css-i6dzq1"
                        >
                          <circle cx="11" cy="11" r="8"></circle>
                          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                      </button>
                    </form>
                    <select
                      name="sortby"
                      id="sortby"
                      class="text-darkgray"
                      onChange={(e) => {
                        handleSorting(e.target.value);
                      }}
                    >
                      <option value="">Sort by</option>
                      <option value="popularity">Popular </option>
                      <option value="recent">Recent</option>
                      <option value="mostViewed">Most Viewed</option>
                    </select>
                  </div>
                  {/* :""} */}
                </div>

                {/* <div class="mainmenu-filter-row pb-3">
                                <ul class="filter d-flex flex-nowrap align-items-center gap-2 fs-14 medium">
                                    <li class="active " data-filter="all">All Videos   </li>
                                    <li data-filter="birthday" class="">Most Viewed <span class="fs-10 text-brown">100</span></li>


                                </ul>
                            </div> */}

                <div class="row mainmenu-filter-conatiner">
                  {username === param?.id ? (
                    <CreateVideoItem
                      {...{
                        onSuccess: getUserVideo,
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {video.length > 0
                    ? video.map((item, index) => {
                        return (
                          <VideoItem
                            {...{
                              item: item,
                              index: index,
                              post: item.post,
                              onSuccess: getUserVideo,
                            }}
                          />
                        );
                      })
                    : "No video Found"}
                </div>
                {video.length >= 8 && limit < count ? (
                  <div className="text-center">
                    <Link
                      href="#"
                      className="text-blue"
                      onClick={() => handleLoadMore()}
                    >
                      {loadStatus ? (
                        <img src="../assets/img/icon/icon-loading.svg" alt="" />
                      ) : (
                        "Load More videos"
                      )}
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <PostLoader />
            )}
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
