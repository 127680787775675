import React, { useEffect, useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import CompanyProfiles from "./CompanyProfiles";
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import { DeleteApi } from "../../API/DeleteApi";
import { PatchApi } from "../../API/PatchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { useNavigate, useParams } from "react-router-dom";
import LeftStatistics from "./LeftStatistics";
import RightSidebar from "./RightSidebar";
import { Link } from "react-router-dom";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
const $ = window.jQuery;

export default function CompanyAbout() {
  const [aboutData, setAboutData] = useState();
  const param = useParams();
  const navigate = useNavigate();
  const [statistics, setStatistics] = useState("");
  const [CountryData, setCountryData] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [IndustryError, setIndustryError] = useState("");

  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [cityKey, setCityKey] = useState(Math.ceil(Math.random() * 999999));
  const [stateKey, setStateKey] = useState(Math.ceil(Math.random() * 999999));
  const [defualtCity, setDefualtCity] = useState("");
  const [defualtState, setDefualtState] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [location, setLocation] = useState("");
  const [pageId, setPageId] = useState("");
  const [locationId, setLocationId] = useState("");
  const userId = localStorage.getItem("userId");

  const [countryError, setCountryError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [postcodeError, setPostcodeError] = useState("");
  const [locationError, setLocationError] = useState("");

  useEffect(() => {
    getCompanyAbout();
    getCountry();
  }, []);

  const getCompanyAbout = async () => {
    try {
      let resp = await FetchApi(Endpoints.PageDetail + "?slug=" + param?.slug);
      if (resp && resp.success === true) {
        setAboutData(resp?.data);
        setStatistics({
          totalEmployees: resp?.data?.totalEmployees,
          totalFollowers: resp?.data?.totalFollowers,
          totalJobs: resp?.data?.totalJobs,
          pagetype: resp?.data?.pageType,
        });
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getCountry = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetCountry);
      if (resp && resp.success === true) {
        // setCountryData(resp.data);
        const newData = resp.data.map((item, index) => {
          return {
            id: item.id,
            name: item.countryName,
          };
        });
        setCountryData(newData);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleOnSearchCity = async (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string) {
      try {
        let resp = await FetchApi(Endpoints.SearchCities + "?term=" + string);
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                name: item?.city,
              };
            });
            setCityOptions(newData);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleOnSearchState = async (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string) {
      try {
        let resp = await FetchApi(Endpoints.SearchStates + "?term=" + string);
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                name: item?.state,
              };
            });
            setStateOptions(newData);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleDeleteLocation = async (PageId, LocationId) => {
    let data = {
      pageId: PageId,
      locationId: LocationId,
    };
    try {
      let resp = await DeleteApi(Endpoints.DeletePageLocation, data);
      if (resp && resp.success === true) {
        getCompanyAbout();
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleEditLocation = (item) => {
    setLocationId(item?.id);
    setLocation(item?.locationName);
    setPostcode(item?.postalCode);
    setAddress(item?.streetAddress);
    setDefualtState(item?.state);
    setDefualtCity(item?.city);
    setStateOptions([
      {
        id: item?.state,
        name: item?.state,
      },
    ]);
    setCityOptions([
      {
        id: item?.city,
        name: item?.city,
      },
    ]);
    setState(item?.state);
    setCity(item?.city);
    setCountry(item?.countryId);
    setPageId(item?.pageId);
    setStateKey(Math.ceil(Math.random() * 999999));
    setCityKey(Math.ceil(Math.random() * 999999));
    $("#EditLocation").modal("show");
  };
  const handleAddLocation = () => {
    setPageId(aboutData?.id);
    $("#EditLocation").modal("show");
  };

  const handleSelectCity = (item) => {
    if (item.length > 0) {
      setCity(item[0]?.name);
      setDefualtCity(item[0]);
    }
  };
  const handleSelectState = (item) => {
    if (item.length > 0) {
      setState(item[0]?.name);
      setDefualtState(item[0]);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!location) {
      setLocationError("Location is required");
    } else {
      setLocationError("");
    }
    if (!postcode) {
      setPostcodeError("Postcode is required");
    } else {
      setPostcodeError("");
    }
    if (!address) {
      setAddressError("Address is required");
    } else {
      setAddressError("");
    }
    if (!state) {
      setStateError("State is required");
    } else {
      setStateError("");
    }
    if (!city) {
      setCityError("City is required");
    } else {
      setCityError("");
    }
    if (!country) {
      setCountryError("Country is required");
    } else {
      setCountryError("");
    }

    if (location && postcode && address && state && city && country) {
      let data = {
        page_id: pageId,
        location_name: location,
        postal_code: postcode,
        street_address: address,
        state: state,
        city: city,
        country_id: country,
      };
      if (locationId) {
        try {
          let resp = await PatchApi(
            Endpoints.UpdatePageLocation + "/" + locationId,
            data
          );
          if (resp && resp.success === true) {
            setLocation("");
            setPostcode("");
            setAddress("");
            setDefualtState("");
            setDefualtCity("");
            setStateOptions([]);
            setCityOptions([]);
            setState("");
            setCity("");
            setCountry("");
            setPageId("");
            setStateKey(Math.ceil(Math.random() * 999999));
            setCityKey(Math.ceil(Math.random() * 999999));
            getCompanyAbout();
            ToastMessage.Success(resp.message);
            $("#EditLocation").modal("hide");
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      } else {
        try {
          let resp = await CommonAPi(Endpoints.pagesLocationCreate, data);
          if (resp && resp.success === true) {
            setLocation("");
            setPostcode("");
            setAddress("");
            setDefualtState("");
            setDefualtCity("");
            setStateOptions([]);
            setCityOptions([]);
            setState("");
            setCity("");
            setCountry("");
            setPageId("");
            setStateKey(Math.ceil(Math.random() * 999999));
            setCityKey(Math.ceil(Math.random() * 999999));
            getCompanyAbout();
            ToastMessage.Success(resp.message);
            $("#EditLocation").modal("hide");
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      }
    }
  };

  const handleCloseModal = () => {
    if (
      location ||
      postcode ||
      address ||
      state ||
      city ||
      country ||
      countryError ||
      cityError ||
      stateError ||
      addressError ||
      postcodeError ||
      locationError
    ) {
      Swal.fire({
        text: "You have unsaved changes, do you still want to exit?",
        icon: "question",
        customClass: {
          confirmButton: "btn px-3 mx-2",
          cancelButton: "btn btn-br-blue px-3",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Yes, discard it!",
      }).then((result) => {
        setCountry("");
        setLocation("");
        setPostcode("");
        setAddress("");
        setDefualtState("");
        setDefualtCity("");
        setStateOptions([]);
        setCityOptions([]);
        setState("");
        setCity("");
        setPageId("");
        setStateKey(Math.ceil(Math.random() * 999999));
        setCityKey(Math.ceil(Math.random() * 999999));
        setCountryError("");
        setCityError("");
        setStateError("");
        setAddressError("");
        setPostcodeError("");
        setLocationError("");
        $("#EditLocation").modal("hide");
      });
    } else {
      $("#EditLocation").modal("hide");
    }
  };
  return (
    <Container header>
      <Helmet>
        <title>About</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-lg-4 py-3">
            <CompanyProfiles />
            <div className="row">
              <LeftStatistics
                {...{
                  statistics: statistics,
                }}
              />

              <div className="col-md-6 feed-mid-column1 pb-3">
                <div className="px-4 py-4 bg-white mb-3 rounded-15 shadow-md">
                  <div className="fs-14 text-333 pb-5">
                    <h4 className="medium fs-20 pb-2">Overview</h4>

                    <p>{aboutData?.description}</p>
                  </div>

                  <div className="abt-pro-company">
                    {aboutData?.website ? (
                      <div className="d-flex align-items-start gap-2 pb-3">
                        <svg
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                          stroke="#fff"
                          strokeWidth="2"
                          fill="#666666"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="css-i6dzq1"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="2" y1="12" x2="22" y2="12"></line>
                          <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                        </svg>
                        <div>
                          <span className="fs-16 medium text-333 d-block">
                            Website
                          </span>
                          <Link
                            to={"https://" + aboutData?.website}
                            target={"_blank"}
                            className="text-brown fs-14 d-block"
                          >
                            {aboutData?.website}
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="d-flex align-items-start gap-2 pb-3">
                      <img src="../assets/img/icon/icon-mobile.svg" />
                      <div>
                        <span className="fs-16 medium  d-block text-333">
                          Phone
                        </span>
                        <a href="#" className="text-brown fs-14 d-block">
                          {aboutData?.phone}{" "}
                        </a>
                      </div>
                    </div>

                    <div className="d-flex align-items-start gap-2 pb-3">
                      <img src="../assets/img/icon/icon-age.svg" />
                      <div>
                        <span className="fs-16 medium  d-block text-333">
                          Company Size
                        </span>
                        <a href="#" className="text-333 fs-14 d-block">
                          {aboutData?.size?.value}
                          {/* | 50 are on 8innet */}
                        </a>
                      </div>
                    </div>

                    <div className="d-flex align-items-start gap-2 pb-3 w-100">
                      <img src="../assets/img/icon/icon-livesin.svg" />
                      <div className="w-100">
                        <div className="d-flex justify-content-between w-100 gap-3">
                          <div>
                            {" "}
                            <span className="fs-16 medium  d-block text-333">
                              Location
                            </span>
                          </div>
                          {aboutData?.userId == userId ? (
                            <div className="text-end">
                              <Link
                                className="btn btn-blue px-3 medium fs-10 py-1"
                                onClick={() => {
                                  handleAddLocation();
                                }}
                              >
                                Add Location
                              </Link>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        {aboutData?.locations?.length > 0 ? (
                          aboutData?.locations?.map((item, index) => {
                            return (
                              <div
                                className="d-flex justify-content-between mt-3"
                                key={index}
                              >
                                <div className="w-75">
                                  <span className="d-block fs-14 medium text-333">
                                    {item?.locationName}
                                  </span>
                                  <span className="d-block fs-14 medium text-333">
                                    {item?.streetAddress +
                                      ", " +
                                      item?.city +
                                      ", " +
                                      item?.state +
                                      ", " +
                                      item?.postalCode}
                                  </span>
                                  <a
                                    href={
                                      "https://www.google.com/maps/dir//" +
                                      item?.streetAddress +
                                      ", " +
                                      item?.city +
                                      ", " +
                                      item?.state +
                                      ", " +
                                      item?.postalCode
                                    }
                                    className="text-brown fs-14 d-block"
                                    target="_blank"
                                  >
                                    Get Direction
                                  </a>
                                </div>{" "}
                                {aboutData?.userId == userId ? (
                                  <div className="text-end ">
                                    <Link
                                      onClick={() => {
                                        handleEditLocation(item);
                                      }}
                                    >
                                      <img
                                        src="../assets/img/icon/edit-location.svg"
                                        alt=""
                                        width="16"
                                      />{" "}
                                    </Link>
                                    <Link
                                      onClick={() => {
                                        handleDeleteLocation(
                                          aboutData?.id,
                                          item?.id
                                        );
                                      }}
                                    >
                                      <img
                                        src="../assets/img/icon/delete-location.svg"
                                        alt=""
                                        width="16"
                                      />
                                    </Link>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <span className="d-block fs-14 medium text-333">
                            NA
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="d-flex align-items-start gap-2 pb-3">
                      <img src="../assets/img/icon/icon-country.svg" />
                      <div>
                        <span className="fs-16 medium  d-block text-333">
                          Country
                        </span>
                        {aboutData?.locations[0] ? (
                          <>
                            <span className="d-block fs-14 medium text-333">
                              {aboutData?.locations[0]?.country?.countryName}
                            </span>
                          </>
                        ) : (
                          <span className="d-block fs-14 medium text-333">
                            NA
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="d-flex align-items-start gap-2 pb-3">
                      <img src="../assets/img/icon/icon-joined.svg" />
                      <div>
                        <span className="fs-16 medium  d-block text-333">
                          Founded
                        </span>
                        <span className="text-333 fs-14 d-block">
                          {aboutData?.yearFounded}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <RightSidebar />
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>

      <div class="modal" id="EditLocation" tabindex="-1">
        <div class="modal-dialog modal-lg px-lg-5 modal-dialog-centered modal-dialog-scrollable modal-md">
          <div class="modal-content">
            <button
              type="button"
              class="btn-close ms-auto p-3 position-absolute end-0 "
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleCloseModal}
            ></button>
            <div class="text-center py-4 border-bottom">
              <h5 class="modal-title text-center fs-20 medium text-black">
                Edit Location
              </h5>
            </div>
            <div class="modal-body">
              <form action="" onSubmit={handleUpdate} class="site-form">
                <span class="text-darkgray medium fs-12 pb-3 d-block">
                  * All fields are required.
                </span>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label for="" className="fs-15 medium d-block pb-2">
                        Country *
                      </label>
                      <select
                        name=""
                        className="fs-14 border rounded-2 p-3 "
                        id=""
                        onChange={(e) => {
                          setCountry(e.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        {CountryData.map((item, index) => {
                          return (
                            <option
                              value={item.id}
                              selected={country == item?.id ? true : false}
                              key={index}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      {countryError ? (
                        <span className="field-error fs-12 text-red">
                          {countryError}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 mb-4">
                      <label for="" className="fs-15 medium d-block pb-2">
                        Address *
                      </label>
                      <input
                        type="text"
                        placeholder="Add complete address"
                        className="border rounded-2 fs-14 p-3 "
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      />
                      {addressError ? (
                        <span className="field-error fs-12 text-red">
                          {addressError}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 mb-4">
                      <label for="" className="fs-15 medium d-block pb-2">
                        City *
                      </label>

                      <AsyncTypeahead
                        id={`city-typeahead`}
                        defaultInputValue={defualtCity}
                        key={cityKey}
                        onSearch={handleOnSearchCity}
                        onChange={handleSelectCity}
                        options={cityOptions}
                        placeholder="Add City"
                        labelKey="name"
                        minLength={1}
                        emptyLabel={"No result found"}
                        className={
                          IndustryError
                            ? " border rounded-2 fs-14 p-2 "
                            : " border rounded-2 fs-14 p-2 "
                        }
                        renderMenuItemChildren={(option, props) => (
                          <div key={option.id}>
                            <Highlighter search={props.text}>
                              {option[props.labelKey]}
                            </Highlighter>
                          </div>
                        )}
                        useCache={false}
                      />
                      {cityError ? (
                        <span className="field-error fs-12 text-red">
                          {cityError}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 mb-4">
                      <label for="" className="fs-15 medium d-block pb-2">
                        State/Province *
                      </label>

                      <AsyncTypeahead
                        id={`state-typeahead`}
                        defaultInputValue={defualtState}
                        key={stateKey}
                        onSearch={handleOnSearchState}
                        onChange={handleSelectState}
                        options={stateOptions}
                        placeholder="Add State"
                        labelKey="name"
                        minLength={1}
                        emptyLabel={"No result found"}
                        className={
                          IndustryError
                            ? " border rounded-2 fs-14 p-2 "
                            : " border rounded-2 fs-14 p-2 "
                        }
                        renderMenuItemChildren={(option, props) => (
                          <div key={option.id}>
                            <Highlighter search={props.text}>
                              {option[props.labelKey]}
                            </Highlighter>
                          </div>
                        )}
                        useCache={false}
                      />
                      {stateError ? (
                        <span className="field-error fs-12 text-red">
                          {stateError}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 mb-4">
                      <label for="" className="fs-15 medium d-block pb-2">
                        Zip/Postal Code *
                      </label>
                      <input
                        type="text"
                        placeholder="Add Zip/Postal Code"
                        className="border rounded-2 fs-14 p-3 "
                        value={postcode}
                        onChange={(e) => {
                          setPostcode(e.target.value);
                        }}
                      />
                      {postcodeError ? (
                        <span className="field-error fs-12 text-red">
                          {postcodeError}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-6 mb-4">
                      <label for="" className="fs-15 medium d-block pb-2">
                        Location Name *
                      </label>
                      <input
                        type="text"
                        placeholder="Add Location Name"
                        className="border rounded-2 fs-14 p-3 "
                        value={location}
                        onChange={(e) => {
                          setLocation(e.target.value);
                        }}
                      />
                      {locationError ? (
                        <span className="field-error fs-12 text-red">
                          {locationError}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <input
                  type="submit"
                  value="Submit"
                  class="btn fs-14 medium px-5"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
