import React, { useEffect, useState } from 'react';
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import CompanyProfiles from './CompanyProfiles';
import RightSidebar from './RightSidebar';
import { Endpoints } from "../../API/Endpoints";
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate, useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
export default function CompanyJobs() {
    const [jobData, setJobData] = useState([]);
    const [limit, setLimit] = useState(9);
    const [count, setCount] = useState(0);
    const [statistics, setStatistics] = useState('');
    const [pageData, setPageData] = useState('');

    const [InternshipData, setInternshipData] = useState([]);
    const [internshipLimit, setInternshipLimit] = useState(9);
    const [Instershipcount, setInstershipCount] = useState(0);

    const param = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getCompanyJobs();
        getInternship();
    }, [])

    const getCompanyJobs = async () => {
        try {
            let resp = await FetchApi(Endpoints.GetJobs + "?slug=" + param?.slug + "&size=" + limit + "&scope=Job");
            if (resp && resp.success === true) {
                setJobData(resp?.data?.allJob);
                setCount(resp?.data?.totalJobs);
                setPageData(resp?.data?.page);
                setStatistics({
                    totalEmployees: resp?.data?.totalEmployees,
                    totalFollowers: resp?.data?.totalFollowers,
                });
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };
    const getInternship = async () => {
        try {
            let resp = await FetchApi(Endpoints.GetJobs + "?slug=" + param?.slug + "&size=" + internshipLimit  + "&scope=Internship");
            if (resp && resp.success === true) {
                setInternshipData(resp?.data?.allJob);
                setInstershipCount(resp?.data?.totalJobs);
                setPageData(resp?.data?.page);

            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };

    const timeSince = (date, isCurrent = false) => {
        if (isCurrent) return 0 + " sec";
        let seconds = Math.floor((new Date() - date) / 1000);
        let interval = seconds / 31536000;

        if (interval > 1) return Math.floor(interval) + "year ago";
        interval = seconds / 2592000;

        if (interval > 1) return Math.floor(interval) + " mon ago";
        interval = seconds / 86400;

        if (interval > 1) return Math.floor(interval) + " day ago";
        interval = seconds / 3600;

        if (interval > 1) return Math.floor(interval) + " h ago";
        interval = seconds / 60;

        if (interval > 1) return Math.floor(interval) + " min ago";
        return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec ago" : "0 sec ago";
    };

    const handleLoadMore = async () => {
        let newLimit = limit + 9;
        setInternshipLimit(newLimit);
        try {
            let resp = await FetchApi(Endpoints.GetJobs + "?slug=" + param?.slug + "&size=" + newLimit  + "&scope=Job");
            if (resp && resp.success === true) {
                setJobData(resp?.data?.allJob);
                setCount(resp?.data?.totalJobs);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };
    const handleLoadMoreInsternship = async () => {
        let newLimit = internshipLimit + 9;
        setLimit(newLimit);
        try {
            let resp = await FetchApi(Endpoints.GetJobs + "?slug=" + param?.slug + "&size=" + newLimit + "&scope=Internship");
            if (resp && resp.success === true) {
                setInternshipData(resp?.data?.allJob);
                setInstershipCount(resp?.data?.totalJobs);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };

    const JobSave = async(Id) =>{
        let data = {
            jobId:Id
        }
        try {
            let resp = await CommonAPi(Endpoints.JobSave, data);
            if (resp && resp.success === true) {
                ToastMessage.Success(resp.message);
                getCompanyJobs();
                getInternship();
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }
    };

    return (
        <Container
            header
        >
             <Helmet>
                <title>Jobs</title>
            </Helmet>
            <div className="wrapper wrapper-bg">
                <div className="main">
                    <LeftNavigation />
                    <div className="container py-lg-4 py-3">
                        <CompanyProfiles />
                        <div className="row">
                            <div className="col-md-3 comany-dp-left pb-3">

                                <div className="sidebar-widget box-shadow">
                                    <div className="pb-3 d-flex align-items-start">
                                        <span>
                                            <img src="../assets/img/icon/icon-emplyee.svg" alt="" width="36" height="36" />
                                        </span>
                                        <div className="ps-2">
                                            <h4 className="medium">{statistics?.totalEmployees}</h4>
                                            <h6 className="fs-12 text-darkgray regular">Employees</h6>
                                        </div>
                                    </div>

                                    <div className="pb-3 d-flex align-items-start">
                                        <span>
                                            <img src="../assets/img/icon/icon-jobs-created.svg" alt="" width="36" height="36" />
                                        </span>
                                        <div className="ps-2">
                                            <h4 className="medium">{count}</h4>
                                            <h6 className="fs-12 text-darkgray regular">Jobs Created</h6>
                                        </div>
                                    </div>


                                    <div className="d-flex align-items-start">
                                        <span>
                                            <img src="../assets/img/icon/icon-followers.svg" alt="" width="36" height="36" />
                                        </span>
                                        <div className="ps-2">
                                            <h4 className="medium">{statistics?.totalFollowers}</h4>
                                            <h6 className="fs-12 text-darkgray regular">Followers</h6>
                                        </div>
                                    </div>
                                </div>



                            </div>

                            <div className="col-md-9 feed-mid-column1 pb-3">
                                <div className="bg-white rounded-15 p-3">
                                    <div className="d-lg-flex align-items-center justify-content-between gap-3 ">
                                        <h4 className="medium">Total {count} Jobs Posted by {pageData?.title}</h4>

                                        <Link to={"/jobs-listing"} className="btn  text-nowrap my-lg-0 my-3">View All Jobs</Link>
                                    </div>

                                    <h5 className="regular pb-3">Recently posted jobs</h5>

                                    <div className="row m-0">
                                        {
                                        jobData.length > 0 ? 
                                            jobData.map((item, index) => {
                                                return (
                                                    <div className="col-md-4 mb-4">
                                                        <div className="border rounded-15 p-3 pt-3">

                                                            <div className="d-flex gap-2 align-items-start justify-content-between pb-3">
                                                                <span>
                                                                    {pageData?.pageImage === null ? 
                                                                    <img src="../assets/img/comany-dp.png" alt="" width="64" height="64" className="rounded-circle" />
                                                                    :
                                                                    <img src={Endpoints.baseUrl + pageData?.pageImage} alt="" width="64" height="64" className="rounded-circle" />
                                                                    }
                                                                    </span>

                                                                {item?.isSaved === "true" ?
                                                                <Link onClick={()=>{
                                                                    JobSave(item?.id);
                                                                }} style={{color: "red"}}><img src="../assets/img/icon/save-fill.svg" width="15" alt="" /></Link>
                                                                :
                                                                <Link onClick={()=>{
                                                                    JobSave(item?.id);
                                                                }} ><img src="../assets/img/icon/save.svg" width="15" alt="" /></Link>
                                                              
                                                                
                                                                }
                                                            </div>
                                                            <div>
                                                            <Link to={"/jobs/" + item?.id}>
                                                                <h5 className="semibold">{item?.title}</h5>
                                                            </Link>

                                                                <h5 className="medium text-brown fs-14 pt-1">{pageData?.title}</h5>
                                                                <div className="d-flex py-2 align-items-center">
                                                                    <span className="fs-10 text-darkgray pe-2 d-flex align-items-center">
                                                                        <img src="../assets/img/icon/icon-location.svg" alt="" className="align-middle me-1" />
                                                                        <span>{item?.state?.state}</span>
                                                                    </span>

                                                                    <span className="fs-10 text-darkgray d-flex align-items-center">
                                                                        <img src="../assets/img/icon/icon-date-time.svg" alt="" className="align-middle me-1" />
                                                                        <span>{timeSince(new Date(item.createdAt))}</span>
                                                                    </span>
                                                                </div>

                                                                <div className="d-flex request-btn gap-2 mb-2">
                                                                    <a href="#" className="btn light-brown-btn px-2 medium">{item?.jobType}</a>
                                                                    <a href="#" className="btn light-brown-btn px-2 medium">{item?.workMode}</a>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        : "No job found"
                                        }



                                    </div>
                                    {count > limit ?
                                        <Link
                                            onClick={() => {
                                                handleLoadMore();
                                            }}
                                            class="btn w-100 bg-eee text-black fs-14 medium"
                                        >View All Jobs</Link>

                                        : ""}

                                </div>

                                <div className="bg-white rounded-15 p-3 mt-3">
                                    <div className="d-lg-flex align-items-center justify-content-between gap-3 ">
                                        <h4 className="medium">Total {Instershipcount} Internships Posted by {pageData?.title}</h4>

                                        <Link to={"/internship-listing"}  className="btn text-nowrap my-lg-0 my-3">View All Internships</Link>
                                    </div>

                                    <h5 className="regular pb-3">Recently posted Internships</h5>

                                    <div className="row m-0">
                                        {
                                        InternshipData.length > 0 ?
                                            InternshipData.map((item, index) => {
                                                return (
                                                    <div className="col-md-4 mb-4" key={index}>
                                                        <div className="border rounded-15 p-3 pt-3">

                                                            <div className="d-flex gap-2 align-items-start justify-content-between pb-3">
                                                                <span>
                                                                {pageData?.pageImage === null ? 
                                                                    <img src="../assets/img/comany-dp.png" alt="" width="64" height="64" className="rounded-circle" />
                                                                    :
                                                                    <img src={Endpoints.baseUrl + pageData?.pageImage} alt="" width="64" height="64" className="rounded-circle" />
                                                                    }
                                                                </span>

                                                                <a href="#"><img src="../assets/img/icon/save.svg" width="15" alt="" /></a>

                                                            </div>
                                                            <div>
                                                                <Link to={"/jobs/" + item?.id}>
                                                                <h5 className="semibold">{item?.title}</h5>
                                                                
                                                                </Link>

                                                                <h5 className="medium text-brown fs-14 pt-1">{pageData?.title}</h5>
                                                                <div className="d-flex py-2 align-items-center">
                                                                    <span className="fs-10 text-darkgray pe-2 d-flex align-items-center">
                                                                        <img src="../assets/img/icon/icon-location.svg" alt="" className="align-middle me-1" />
                                                                        <span>{item?.state?.state}</span>
                                                                    </span>

                                                                    <span className="fs-10 text-darkgray d-flex align-items-center">
                                                                        <img src="../assets/img/icon/icon-date-time.svg" alt="" className="align-middle me-1" />
                                                                        <span>{timeSince(new Date(item.createdAt))}</span>
                                                                    </span>
                                                                </div>

                                                                <div className="d-flex request-btn gap-2 mb-2">
                                                                    <a href="#" className="btn light-brown-btn px-2 medium">{item?.jobType}</a>
                                                                    <a href="#" className="btn light-brown-btn px-2 medium">{item?.workMode}</a>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        : "No internship found"
                                    }



                                    </div>
                                    {Instershipcount > internshipLimit ?
                                        <Link
                                            onClick={() => {
                                                handleLoadMoreInsternship();
                                            }}
                                            class="btn w-100 bg-eee text-black fs-14 medium"
                                        >View all Internships</Link>

                                        : ""}

                                </div>
                            </div>



                            {/* <RightsSidebar /> */}

                        </div>

                    </div>
                    <OnlineFriends />
                </div>
            </div>
        </Container >
    )
}
