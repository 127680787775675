import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar() {
  const location = useLocation();
  // console.log(location);
  const userId = localStorage.getItem("userId");
  return (
    <div className="col-md-3 mb-3">
      <div className="bg-white overflow-hidden rounded-15 box-shadow">
        <h4 className="medium py-2 my-1 border-bottom px-3">Your Profile</h4>

        <div className="">
          <h6 className="fs-14 regular p-3 profile-collapse">
            <a
              data-bs-toggle="collapse"
              href="#profile"
              className="text-mediumgray d-flex align-items-center justify-content-between"
              aria-expanded="true"
            >
              <span className="d-block">
                <img src="../assets/img/icon/icon-edit-profile2.svg" alt="" />
                Profile
              </span>

              <svg
                viewBox="0 0 24 24"
                width="18"
                height="18"
                stroke="#9E9E9E"
                className="transition"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </a>
          </h6>

          <div className="collapse border-0 show" id="profile">
            <ul className="fs-14 pro-side-menu pb-3">
              <li
                className={
                  location?.pathname === "/notifications"
                    ? "px-4 py-2 active"
                    : "px-4 py-2"
                }
              >
                <Link to={"/notifications"}>
                  <img
                    src="../assets/img/icon/icon-notifications.svg"
                    alt=""
                    className="pe-2"
                  />{" "}
                  Notifications
                </Link>
              </li>

              <li
                className={
                  location?.pathname.split("/")[1] === "friend-request"
                    ? "px-4 py-2 active"
                    : "px-4 py-2"
                }
              >
                <Link to={"/friend-request/" + userId}>
                  <img
                    src="../assets/img/icon/icon-friends-request.svg"
                    alt=""
                    className="pe-2"
                  />{" "}
                  Friend Requests
                </Link>
              </li>
              <li
                className={
                  location?.pathname === "/block-user"
                    ? "px-4 py-2 active"
                    : "px-4 py-2"
                }
              >
                <Link to={"/block-user"}>
                  <img
                    src="../assets/img/icon/icon-blocked.svg"
                    alt=""
                    className="pe-2"
                  />{" "}
                  Blocked User
                </Link>
              </li>

              <li
                className={
                  location?.pathname === "/updateprofile"
                    ? "px-4 py-2 active"
                    : "px-4 py-2"
                }
              >
                <Link to={"/updateprofile"}>
                  <img
                    src="../assets/img/icon/icon-pencil.svg"
                    alt=""
                    className="pe-2"
                  />{" "}
                  Profile Update
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-top ">
          <h6 className="fs-14 regular p-3 profile-collapse">
            <a
              data-bs-toggle="collapse"
              href="#setting"
              className="text-mediumgray d-flex align-items-center justify-content-between"
              aria-expanded="true"
            >
              <span className="d-block">
                <img src="../assets/img/icon/icon-setting.svg" alt="" />
                Settings
              </span>

              <svg
                viewBox="0 0 24 24"
                width="18"
                height="18"
                stroke="#9E9E9E"
                className="transition"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </a>
          </h6>

          <div className="collapse border-0 show" id="setting">
            <ul className="fs-14 pro-side-menu pb-3">
              <li
                className={
                  location?.pathname === "/change-password"
                    ? "px-4 py-2 active"
                    : "px-4 py-2"
                }
              >
                <Link to={"/change-password"}>
                  <img
                    src="../assets/img/icon/icon-password.svg"
                    alt=""
                    className="pe-2"
                  />{" "}
                  Change Password
                </Link>
              </li>

              <li
                className={
                  location?.pathname === "/notifications-setting"
                    ? "px-4 py-2 active"
                    : "px-4 py-2"
                }
              >
                <Link to={"/notifications-setting"}>
                  <img
                    src="../assets/img/icon/icon-notifications.svg"
                    alt=""
                    className="pe-2"
                  />{" "}
                  Notification Setttings
                </Link>
              </li>
              <li
                className={
                  location?.pathname === "/profile-visibility"
                    ? "px-4 py-2 active"
                    : "px-4 py-2"
                }
              >
                <Link to={"/profile-visibility"}>
                  <img
                    src="../assets/img/icon/icon-eye.svg"
                    alt=""
                    className="pe-2"
                  />{" "}
                  Profile Visibility
                </Link>
              </li>
              <li
                className={
                  location?.pathname === "/privacy-setting"
                    ? "px-4 py-2 active"
                    : "px-4 py-2"
                }
              >
                <Link to={"/privacy-setting"}>
                  <img
                    src="../assets/img/icon/activity-gray.svg"
                    alt=""
                    className="pe-2"
                  />{" "}
                  Privacy & Logins
                </Link>
              </li>
              <li
                className={
                  location?.pathname === "/activity-log"
                    ? "px-4 py-2 active"
                    : "px-4 py-2"
                }
              >
                <Link to={"/activity-log"}>
                  <img
                    src="../assets/img/icon/activity-gray.svg"
                    alt=""
                    className="pe-2"
                  />{" "}
                  Activity Log
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
