import React from 'react'
import { useState, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';


const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export default function AditionalDragDrop({ setAditionalFiles, filesPreviewStatus, setFilesPreviewStatus, Aditionalfiles }) {

  const [filesPreview, setFilesPreview] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    const chosenFiles = Array.prototype.slice.call(acceptedFiles);
 
    setAditionalFiles([...Aditionalfiles, ...chosenFiles])
    setFilesPreview([...filesPreview, ...chosenFiles, ...Aditionalfiles]);
    setFilesPreviewStatus(true);
  }, [setAditionalFiles, setFilesPreview, filesPreview, Aditionalfiles, setFilesPreviewStatus]);



  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop
  });


  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);



  return (
    <div className="col-md-12 mb-3">

      <section>
        <div {...getRootProps({ style })}>
          <input  {...getInputProps()} />
          <h3 className="upload-lecture-heading">Additional Resource (Optional)</h3>
          <div className="drag">Drag and drop a file or <u>browse a file</u></div>
        </div>
        <aside>
          <br />
          {/* {filesPreviewStatus === true ? */}
            <>
              {Aditionalfiles.map((file, index) =>{ 
                return (
                <div key={index}>
                  {file.name}
                </div>
              );
              })}
            </>
            {/* : ""} */}
        </aside>
      </section>

    </div>
  )
}
