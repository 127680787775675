import React from "react";
import { Link } from "react-router-dom";
const $ = window.jQuery;

function Landing() {
  const handleSidebarIndex = () => {
    /***** LEFT MENU TOGGLE js  *****/
    // $('<span class="toggle-menu text-end pb-3 d-xl-none d-block px-3"><svg viewBox="0 0 24 24" width="24" height="24" stroke="#0c64c5" strokeWidth="2" fill="none" stroke-linecap="round" strokeLinejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg></span>').prependTo(".side-menu");
    $(".side-menu").toggleClass("active-sidemenu");
    $(".head-menu").slideToggle();
  };
  return (
    <div className="wrapper landing">
      <div className="header  py-md-0 py-3">
        <div className="container ">
          <div className="row m-0 align-items-center">
            <div className="col-md-3 col-6">
              <div className="site-logo">
                <Link to="/">
                  <img src="assets/img/8inNet_logo.svg" alt="" />
                </Link>
              </div>
            </div>

            <div className="col-md-9 col-6 text-md-end">
              <a href="#" className="toggle-menu d-md-none text-end d-block">
                <img
                  src="assets/img/icon/icon-menu.svg"
                  onClick={handleSidebarIndex}
                  alt=""
                />
              </a>
              <ul className="head-menu d-md-flex flex-column flex-md-row align-items-center gap-4 justify-content-md-end">
                <li className="active">
                  <a href="#" className=" text-blue">
                    Home
                  </a>
                </li>
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Contact Us</a>
                </li>
                <li>
                  <Link to={"/signin"}> Sign In</Link>
                </li>

                <li className="py-md-0">
                  <Link className="btn btn-brown px-4" to={"/signup"}>
                    Sign Up
                  </Link>
                  {/* <a href="#" className="btn btn-brown px-4">Sign Up</a> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="landing-inner">
        <div className="hero-banner py-5">
          <div className="container py-sm-5">
            <div className="row py-md-5">
              <div className="col-md-5 py-md-5">
                <h1 className="semibold text-white">Connecting</h1>
                <h2 className="text-white regular pb-3">All Your Needs</h2>
                <p className="text-white pe-md-5 light">
                  Lorem ipsum dolor sit amet,consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row py-5 align-items-center">
            <div className="col-md-6">
              <h2 className="medium text-brown pb-2">Why Join Our 8inNet</h2>
              <h2 className="subtitle bold pb-4">Social Network?</h2>
              <p className="light">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida.{" "}
              </p>
            </div>

            <div className="col-md-6 why-col px-md-0">
              <div className="d-flex align-items-center w-100 why-box ">
                <span>
                  <img src="assets/img/Businessman.svg" alt="" />
                </span>
                <div>
                  <h4 className="text-blue semibold pb-2">
                    Meet Professionals
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempoer.
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center w-100 why-box my-5 ms-md-5 ps-md-5">
                <span className="bg-primary">
                  <img src="assets/img/Tuition.svg" alt="" />
                </span>
                <div>
                  <h4 className="text-blue semibold pb-2">Meet Teachers</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempoer.
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center w-100 why-box">
                <span>
                  <img src="assets/img/contact.svg" alt="" />
                </span>
                <div>
                  <h4 className="text-blue semibold pb-2">Meet Companies</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempoer.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row pb-5 align-items-center">
            <div className="col-md-6 pb-md-0 pb-5">
              <img src="assets/img/career.png" alt="" />
            </div>
            <div className="col-md-6">
              <h2 className="medium text-brown pb-2">
                A Platform Where You Can
              </h2>
              <h2 className="subtitle bold pb-4">Plan Your Child's Career</h2>
              <p className="light">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida.{" "}
              </p>
            </div>
          </div>
        </div>

        <div className="pro-bg py-5">
          <div className="container">
            <div className="row align-items-center flex-md-row-reverse ">
              <div className="col-md-7 pb-md-0 pb-5">
                <img src="assets/img/professional.png" alt="" />
              </div>
              <div className="col-md-5">
                <h2 className="medium text-brown pb-2">Interact with other</h2>
                <h2 className="subtitle bold pb-4">Professionals</h2>
                <p className="light">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row align-items-center py-5">
            <div className="col-md-6 pb-md-0 pb-5">
              <img src="assets/img/platform01.png" alt="" />
            </div>
            <div className="col-md-6 px-md-4">
              <h2 className="medium text-brown pb-2">
                Start learning from the
              </h2>
              <h2 className="subtitle bold pb-4">Best Platform</h2>
              <p className="light">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida.{" "}
              </p>
            </div>
          </div>

          <div className="py-5">
            <h2 className="text-center bold subtitle pb-4 pb-md-5">
              How It Works?
            </h2>
            <div className="row">
              <div className="col-md-4 pb-md-0 pb-4">
                <div className="works-col text-center">
                  <span className="number d-flex align-items-center justify-content-center bg-primary text-white bold">
                    1
                  </span>
                  <img src="assets/img/step-1.png" alt="" />
                  <h4 className="text-blue semibold pb-2">Join 8inNet</h4>
                  <p className="light">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed.
                  </p>
                </div>
              </div>

              <div className="col-md-4 pb-md-0 pb-4 work-mid-col">
                <div className="works-col text-center">
                  <span className="number d-flex align-items-center justify-content-center bg-primary text-white bold d-md-none">
                    2
                  </span>
                  <img src="assets/img/step-1.png" alt="" />
                  <h4 className="text-blue semibold pb-2">
                    Setup Your Account
                  </h4>
                  <p className="light">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed.
                  </p>
                  <span className="number d-md-flex d-none align-items-center justify-content-center bg-primary text-white bold">
                    2
                  </span>
                </div>
              </div>

              <div className="col-md-4 pb-md-0 pb-4">
                <div className="works-col text-center">
                  <span className="number d-flex align-items-center justify-content-center bg-primary text-white bold">
                    3
                  </span>
                  <img src="assets/img/step-1.png" alt="" />
                  <h4 className="text-blue semibold pb-2">
                    Connect With People
                  </h4>
                  <p className="light">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="download-bg ">
          <div className="container">
            <div className="row align-items-center flex-md-row-reverse ">
              <div className="col-md-5 pb-md-0 pb-5">
                <img src="assets/img/app-download.png" alt="" />
              </div>
              <div className="col-md-7 pe-md-5">
                <h2 className="medium text-brown pb-2">Download our</h2>
                <h2 className="subtitle bold pb-4">Latest Mobile App</h2>
                <p className="light pb-5">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse ultrices gravida.{" "}
                </p>

                <div>
                  <h4 className=" semibold fs-15">Download Now</h4>

                  <div>
                    <img src="assets/img/google-play.png" alt="" />
                    <img src="assets/img/apple.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-5 container connect-section">
          <div className="text-center pb-5">
            <h2 className="medium text-brown pb-2">Connect with us</h2>
            <h2 className="subtitle bold pb-4">Who As Can Join 8inNet</h2>
          </div>

          <div className="row">
            <div className="col-md-6 mb-5">
              <div className="row align-items-end">
                <div className="col-6 connect-img">
                  <img src="assets/img/parents.png" alt="" />
                </div>

                <div className="col-6 pb-md-5">
                  <h4 className="semibold text-blue pb-2">Parents</h4>
                  <p className="light">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed.
                  </p>
                  <a href="#" className="btn btn-brown px-4 ">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-5">
              <div className="row align-items-end">
                <div className="col-6 connect-img">
                  <img src="assets/img/professional.svg" alt="" />
                </div>

                <div className="col-6 pb-md-5">
                  <h4 className="semibold text-blue pb-2">Professional </h4>
                  <p className="light">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed.
                  </p>
                  <a href="#" className="btn btn-brown px-4 ">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-5">
              <div className="row align-items-end">
                <div className="col-6 connect-img">
                  <img src="assets/img/teacher.png" alt="" />
                </div>

                <div className="col-6 pb-md-5">
                  <h4 className="semibold text-blue pb-2">
                    Teacher/Trainers Tutors
                  </h4>
                  <p className="light">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed.
                  </p>
                  <a href="#" className="btn btn-brown px-4 ">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-5">
              <div className="row align-items-end">
                <div className="col-6 connect-img">
                  <img src="assets/img/educational.png" alt="" />
                </div>

                <div className="col-6 pb-md-5">
                  <h4 className="semibold text-blue pb-2">
                    Educational Institutions{" "}
                  </h4>
                  <p className="light">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed.
                  </p>
                  <a href="#" className="btn btn-brown px-4 ">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-12 mb-5">
              <div className="row align-items-end">
                <div className="col-3 connect-img">
                  <img src="assets/img/company.png" alt="" />
                </div>

                <div className="col-md-9 col-8 pb-md-5">
                  <h4 className="semibold text-blue pb-2">Company</h4>
                  <p className="light">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida.
                  </p>
                  <Link to={"/company"} className="btn btn-brown px-4 ">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cta-section py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-8 text-center text-md-start pb-4 pb-md-0">
                <h2 className="text-white bold">Ready to get started? </h2>
              </div>
              <div className="col-md-4 text-center text-md-end">
                <a
                  href="#"
                  className="btn bg-white rounded-pill text-blue px-4"
                >
                  Join Now <i data-feather="arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer pt-5">
        <div className="container pt-lg-5">
          <div className="row">
            <div className="col-md-3 pb-5 text-md-start text-center">
              <div className="footer-logo pt-lg-5 mt-md-4">
                <a href="/">
                  <img src="assets/img/8inNet_logo.svg" alt="" />
                </a>
              </div>
              <div className="mt-4">
                <a href="#">
                  <img
                    className="footer-social-icon-lp"
                    src="assets/img/icon/facebook1.svg"
                    alt=""
                  />
                </a>
                <a href="#" className="px-4">
                  <img
                    className="footer-social-icon-lp"
                    src="assets/img/icon/twitter.svg"
                    alt=""
                  />
                </a>
                <a href="#">
                  <img
                    className="footer-social-icon-lp"
                    src="assets/img/icon/youtube.svg"
                    alt=""
                  />
                </a>
              </div>
            </div>

            <div className="col-md-3 pb-5 col-6">
              <h4 className="bold text-blue pb-3">Important Links</h4>
              <ul className="footer-menu">
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Contact Us</a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
                <li>
                  <a href="#">Career</a>
                </li>
                <li>
                  <a href="#">Press & News</a>
                </li>
              </ul>
            </div>

            <div className="col-md-3 pb-5 col-6">
              <h4 className="bold text-blue pb-3">Help & Support</h4>
              <ul className="footer-menu">
                <li>
                  <a href="#">FAQ?</a>
                </li>
                <li>
                  <a href="#">Support</a>
                </li>
                <li>
                  <a href="#">How It Works</a>
                </li>
                <li>
                  <a href="#">Terms & Conditions</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
              </ul>
            </div>

            <div className="col-md-3 pb-5">
              <h4 className="bold text-blue pb-3">Download App</h4>

              <a href="#" className="d-block pb-3">
                <img src="assets/img/google-play.png" alt="" />
              </a>
              <a href="#" className="d-block ">
                <img src="assets/img/apple.png" alt="" />
              </a>
            </div>
          </div>

          <div className="pt-3 pb-3 border-top">
            <p className="fs-15 text-center text-blue m-0 medium">
              Copyright© 2022, All rights Reserved by 8inNet
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
