import React, { useCallback, useEffect, useState, useRef } from "react";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from "../../API/FetchApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getOrientation } from "get-orientation/browser";
import { getCroppedImg, getRotatedImage } from "../../Utils/Canvas/canvas_util";
import Cropper from "react-easy-crop";
import ProfileCropper from "../../Components/ImageCropperModal/ProfileCropper";
import "./Profile.css";
import { Button } from "react-bootstrap";
const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};
export default function Profile({ profileTagline }) {
  const Navigate = useNavigate();
  const param = useParams();
  const username = localStorage.getItem("userName");
  const [coverImageSrc, setCoverImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const [zoom, setZoom] = useState(1);
  const [file, setFile] = useState();
  const [userData, setUserData] = useState();

  const [profileImageSrc, setProfileImageSrc] = useState(null);
  const [show, setShow] = useState(false);
  const profileRef = useRef(null);
  const [getProfilePicture, setGetProfilePicture] = useState("");

  const hadnleCoverPicture = async (e) => {
    let imageDataURL = await readFile(e.target.files[0]);
    const orientation = await getOrientation(e.target.files[0]);
    const rotation = ORIENTATION_TO_ANGLE[orientation];
    if (rotation) {
      imageDataURL = await getRotatedImage(imageDataURL, rotation);
    }
    console.log(
      "coverImageSrccoverImageSrccoverImageSrc__coverImageSrc",
      imageDataURL
    );
    setCoverImageSrc(imageDataURL);
  };
  const uploadCoverPicture = async () => {
    let newFile = new File([file], "image.jpg", { type: "image/jpg" });
    const formData = new FormData();
    formData.append("user-picture", newFile);
    // console.log(formData.get("user-picture"));return;
    try {
      let resp = await CommonAPi(Endpoints.CoverPicture, formData);
      if (resp && resp.success === true) {
        setCoverImageSrc(null);
        getUserProfile();
        localStorage.setItem("userProfileCoverImage", resp.data.cover);
        // setuserProfileCoverImage(Endpoints.baseUrl + resp.data.cover)
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      const { file, url } = await getCroppedImg(
        coverImageSrc,
        croppedAreaPixels
      );
      // setCroppedImage(url)
      setFile(file);
    },
    [coverImageSrc]
  );

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    getUserProfile();
  }, [param.id]);

  const getUserProfile = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetUserProfile + "?id=" + param.id);
      if (resp && resp.success === true) {
        setUserData(resp?.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const sendFriendRequest = async (Id) => {
    let data = {
      receiverId: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.SendFriendRequest, data);
      if (resp && resp.message) {
        getUserProfile();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const hadnleProfilePicture = async (e) => {
    setShow(true);

    let imageDataURL = await readFile(e.target.files[0]);
    const orientation = await getOrientation(e.target.files[0]);
    const rotation = ORIENTATION_TO_ANGLE[orientation];
    if (rotation) {
      imageDataURL = await getRotatedImage(imageDataURL, rotation);
    }

    setProfileImageSrc(imageDataURL);
  };

  const FriendRequestResponse = async (Id) => {
    let data = {
      requestId: Id,
      action: "approve",
    };
    try {
      let resp = await CommonAPi(Endpoints.FriendRequestRespond, data);
      if (resp && resp.message) {
        getUserProfile();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <>
      <div
        className="position-relative banner"
        id="profile-cover-container"
        style={{ height: "20.3rem" }}
      >
        {userData?.user_profile?.coverImg === null ||
        userData?.user_profile?.coverImg === "undefined" ? (
          <img
            src="../assets/img/timeline-banner.png"
            alt=""
            className="profile-coverpic"
          />
        ) : (
          <>
            {!coverImageSrc && userData ? (
              <img
                src={Endpoints.baseUrl + userData?.user_profile?.coverImg}
                alt=""
                className="profile-coverpic"
              />
            ) : coverImageSrc ? (
              <Cropper
                image={coverImageSrc}
                crop={crop}
                zoom={zoom}
                aspect={3.84 / 1.1}
                // aspect={16 / 4 }
                // aspect={17 / 5}
                // aspect={18.46 / 3.6}
                showGrid={false}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            ) : (
              <div
                className="profile-coverpic"
                style={{
                  backgroundColor: "white",
                  height: "100",
                  width: "200",
                }}
              />
            )}
          </>
        )}
        <div className="upload-coverpic position-absolute top-0 end-0 m-3">
          <>
            {coverImageSrc ? (
              <div className="">
                <label
                  style={{ marginRight: "5px" }}
                  for="coverupload"
                  className="fs-12 medium bg-white rounded-pill px-1 py-1 cursor-pointer"
                  onClick={uploadCoverPicture}
                >
                  Done
                </label>
                <span
                  onClick={() => {
                    setCoverImageSrc(null);
                  }}
                  className="fs-12 medium bg-danger rounded-pill px-1 py-1 cursor-pointer"
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="14"
                    height="14"
                    stroke="#fff"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    class="css-i6dzq1"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </span>
              </div>
            ) : username === param?.id ? (
              <>
                <input
                  type="file"
                  accept="image/*"
                  id="coverupload"
                  className="d-none"
                  onChange={(e) => {
                    hadnleCoverPicture(e);
                  }}
                />
                <label
                  for="coverupload"
                  className="fs-14 medium bg-white rounded-pill px-3 py-2 cursor-pointer"
                >
                  Update Cover
                  <img
                    src="../assets/img/icon/upload-icon.svg"
                    alt=""
                    className="mx-1"
                  />
                </label>
              </>
            ) : (
              ""
            )}
          </>
        </div>
      </div>
      {/* <input   aria-labelledby="Zoom"
                       type="range"
                                min="1"
                                max="3"
                                step="0.1"
                                value={zoom}
                            onChange={(e, zoom) => {
                                setZoom(zoom);
                                console.log("zooming")
                            }}
                            /> */}

      <div className="row mx-0 align-items-center ">
        <div className="col-md-auto px-3">
          <div className="profile-dp user-pro-dp">
            {!userData?.user_profile?.profileImg ||
            userData?.user_profile?.profileImg === "undefined" ||
            userData?.user_profile?.profileImg === "" ? (
              <img src="../assets/img/picture.png" alt="" />
            ) : (
              <>
                {profileImageSrc === null ? (
                  <img
                    src={Endpoints.baseUrl + userData?.user_profile?.profileImg}
                    alt=""
                  />
                ) : (
                  <img src={profileImageSrc} alt="" />
                )}
              </>
            )}
            <>
              <input
                type="file"
                id="profilepic"
                accept="image/*"
                ref={profileRef}
                className="d-none"
                onChange={(e) => {
                  hadnleProfilePicture(e);
                  setShow(true);
                }}
              />
              <label
                for="profilepic"
                className="cursor-pointer d-flex profile-edit-icon"
              >
                <img
                  src="../assets/img/icon/icon-camera4.svg"
                  alt=""
                  className="w-auto h-auto border-0"
                />
              </label>
            </>
            {/* : ""}  */}
          </div>
        </div>

        <div
          className="col-md-5 py-2 profile-name"
          style={{ display: "grid", width: "auto" }}
        >
          {coverImageSrc && (
            <div className="cover-slider-container">
              <span>Zoom</span>
              <div className="cover-slider">
                <span className="slider-icons">-</span>
                <input
                  type="range"
                  min="1"
                  max="3"
                  step="0.1"
                  value={zoom}
                  onChange={(e) => setZoom(e.target.value)}
                />
                <span className="slider-icons">+</span>
              </div>
            </div>
          )}
          <h3 className="bold pb-2 d-inline">{userData?.fullName}</h3>

          <h5 className="regular text-darkgray d-inline">
            {userData?.user_profile?.profileTagline}
          </h5>
        </div>

        <div className="col-md-4 py-md-2 ms-md-auto profile-cover-btn justify-content-between">
          {userData && (
            <div className="text-end m-0">
              {userData?.instructorStatus === "Complete" &&
                username !== userData?.username && (
                  <Button
                    className="btn px-3 medium fs-14 py-2 me-2"
                    onClick={() => {
                      Navigate(`/instructor-profile/${userData?.username}`);
                    }}
                  >
                    View profile
                  </Button>
                )}
              {username !== userData?.username ? (
                <>
                  {userData?.isFriend === false ? (
                    userData?.friendStatus === "REQUESTED" ? (
                      userData?.connectionStatus === false ? (
                        <Link
                          className="btn px-3 medium fs-14 py-2"
                          onClick={() => {
                            FriendRequestResponse(userData?.requestedId);
                          }}
                        >
                          Accept Friend Request
                        </Link>
                      ) : (
                        <Link className="btn px-3 medium fs-14 py-2">
                          Friend Request Sent
                        </Link>
                      )
                    ) : (
                      <Link
                        className="btn px-3 medium fs-14 py-2"
                        onClick={() => {
                          sendFriendRequest(userData?.id);
                        }}
                      >
                        <img
                          src="../assets/img/icon/icon-add-friend.svg"
                          class="pe-2"
                        />
                        {/* Send Friend Request */}
                        Add Friend
                      </Link>
                    )
                  ) : (
                    <Link
                      to={`/friend/messages?roomId=${userData?.chatRoomId}`}
                      className="btn btn-brown px-3 medium fs-14 py-2"
                    >
                      Send a Message
                      <img
                        src="../assets/img/icon/message.svg"
                        alt=""
                        width="12"
                        className="mx-1"
                      />
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link
                    to={"/updateprofile"}
                    style={{ marginLeft: "3px" }}
                    className="btn bg-white border-brown px-3  medium fs-14 py-2 mx-xl-2"
                  >
                    Edit Profile
                    <img
                      src="../assets/img/icon/edit-profile.svg"
                      alt=""
                      width="12"
                      className="mx-1"
                    />
                  </Link>
                  <Link
                    to={"/notifications-setting"}
                    style={{ marginLeft: "3px" }}
                    className="btn bg-white border-brown px-3  medium fs-14 py-2 mx-xl-2"
                  >
                    Settings
                    <img
                      src="../assets/img/icon/icon-settings-profile.svg"
                      alt=""
                      width="12"
                      style={{
                        color: "#B86D2C",
                      }}
                      className="mx-1"
                    />
                  </Link>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {profileImageSrc && (
        <ProfileCropper
          setShow={setShow}
          show={show}
          profileImageSrc={profileImageSrc}
          setGetProfilePicture={setGetProfilePicture}
          profileRef={profileRef}
        />
      )}
    </>
  );
}
