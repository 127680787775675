import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { Helmet } from "react-helmet";
export default function SelectPageType() {
  const [type, setType] = useState("");
  return (
    <Container header>
      <Helmet>
        <title>Select Page Type</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />

          <div className="container py-3">
            <div className="bg-white rounded-10 py-4 px-3 shadow-lg">
              <h4 className="fs-22 medium text-center pb-2">
                Create 8innet Page
              </h4>

              <p className="medium text-center text-gray">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>

              <div className="row justify-content-center py-lg-5 py-4 mb-md-5 cre-company">
                <div className="col-md-3 col-sm-6 mb-4">
                  <Link
                    to={"/create-page" + "/Company"}
                    onclick={() => {
                      setType("Company");
                    }}
                  >
                    <div
                      className={
                        type === "Company"
                          ? "border border-2 shadow-sm py-5 px-1 text-center rounded-15 selected-company"
                          : "border border-2 shadow-sm py-5 px-1 text-center rounded-15 "
                      }
                    >
                      <img
                        src="assets/img/icon/company.svg"
                        alt=""
                        className="mb-3 mt-4"
                        width="50"
                        height="50"
                      />
                      <h5 className="medium fs-18 pb-1">Company</h5>
                      {/* <p className="fs-13 medium text-gray mb-4">Small, medium and large business</p> */}
                    </div>
                  </Link>
                </div>

                <div className="col-md-3 col-sm-6 mb-4">
                  <Link
                    to={"/create-page" + "/School"}
                    onclick={() => {
                      setType("School");
                    }}
                  >
                    <div
                      className={
                        type === "School"
                          ? "border border-2 shadow-sm py-5 px-1 text-center rounded-15 selected-company"
                          : "border border-2 shadow-sm py-5 px-1 text-center rounded-15 "
                      }
                    >
                      <img
                        src="assets/img/icon/Schools.svg"
                        alt=""
                        className="mb-3 mt-4"
                        width="50"
                        height="50"
                      />
                      <h5 className="medium fs-18 pb-1">Schools</h5>
                      {/* <p className="fs-13 medium text-gray mb-4">Schools, Colleges and Universities</p> */}
                    </div>
                  </Link>
                </div>
                <div className="col-md-3 col-sm-6 mb-4">
                  <Link
                    to={"/create-page" + "/University"}
                    onclick={() => {
                      setType("University");
                    }}
                  >
                    <div
                      className={
                        type === "University"
                          ? "border border-2 shadow-sm py-5 px-1 text-center rounded-15 selected-company"
                          : "border border-2 shadow-sm py-5 px-1 text-center rounded-15 "
                      }
                    >
                      <img
                        src="assets/img/icon/university.svg"
                        alt=""
                        className="mb-3 mt-4"
                        width="50"
                        height="50"
                      />
                      <h5 className="medium fs-18 pb-1">Universities</h5>
                      {/* <p className="fs-13 medium text-gray mb-4">Schools, Colleges and Universities</p> */}
                    </div>
                  </Link>
                </div>
                <div className="col-md-3 col-sm-6 mb-4">
                  <Link
                    to={"/create-page" + "/Page"}
                    onclick={() => {
                      setType("Page");
                    }}
                  >
                    <div
                      className={
                        type === "Page"
                          ? "border border-2 shadow-sm py-5 px-1 text-center rounded-15 selected-company"
                          : "border border-2 shadow-sm py-5 px-1 text-center rounded-15 "
                      }
                    >
                      <img
                        src="assets/img/icon/Pages.svg"
                        alt=""
                        className="mb-3 mt-4"
                        width="50"
                        height="50"
                      />
                      <h5 className="medium fs-18 pb-1">Pages</h5>
                      {/* <p className="fs-13 medium text-gray mb-4">Schools, Colleges and Universities</p> */}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
