import React, { useEffect, useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ProfileImage from "../ProfileUpdate/ProfileImage";
import Sidebar from "../ProfileUpdate/Sidebar";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { logTimeDiff } from "../../Utils/helpers";

export default function PrivacyLog() {
  const [locationData, setLocationData] = useState([]);
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(5);
  const [loadStatus, setLoadStatus] = useState(false);
  const history = useNavigate();
  useEffect(() => {
    getLocationData();
  }, []);

  const getLocationData = async () => {
    try {
      let resp = await CommonAPi(Endpoints.GetProfileLog + "?size=" + limit);
      if (resp && resp.success === true) {
        setLocationData(resp?.data?.userlogs);
        setCount(resp?.data?.totalRecord);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleLoadMore = async () => {
    const newLimit = limit + 5;
    setLimit(newLimit);
    setLoadStatus(true);
    try {
      let resp = await CommonAPi(Endpoints.GetProfileLog + "?size=" + newLimit);
      if (resp && resp.success === true) {
        setLocationData(resp?.data?.userlogs);
        setCount(resp?.data?.totalRecord);
        setLoadStatus(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoadStatus(false);
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Privacy Log</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-lg-4 py-3">
            <ProfileImage />
            <div className="row">
              <Sidebar />
              <div className="col-lg-9 col-md-8">
                <div className="bg-white box-shadow rounded-15 p-md-3 p-2">
                  <div className="pt-md-2 pb-3 mb-3 px-lg-3 border-bottom">
                    <h3 className="medium">Device and Location Details</h3>
                    <h6 className="medium fs-16 pt-2">
                      The list will display information about each active
                      session, including the device type, browser, operating
                      system, IP address, and approximate location.
                    </h6>
                  </div>
                  {locationData?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="row pt-md-2 pb-3 mb-3 px-lg-3 border-bottom"
                      >
                        <div className="col-md-12 ">
                          <span>Last accessed</span>
                          <h6> {logTimeDiff(item?.updatedAt)} </h6>
                        </div>

                        <div className="col-md-12 mt-3">
                          <span>Details</span>
                          <h6>{item?.location ? item?.location : ""}</h6>
                        </div>

                        <div className="col-md-12 mt-3">
                          <h6>{item?.userAgent ? item?.userAgent : ""}</h6>
                        </div>
                        <div className="col-md-12 mt-3">
                          <h6>IP Address:</h6>
                          <h6>{item?.ipAddress}</h6>
                        </div>
                      </div>
                    );
                  })}
                  {locationData.length >= 5 && limit < count ? (
                    <div className="text-center">
                      <Link
                        href="#"
                        className="text-blue"
                        onClick={() => handleLoadMore()}
                      >
                        {loadStatus ? (
                          <img
                            src="../assets/img/icon/icon-loading.svg"
                            alt=""
                          />
                        ) : (
                          "Load More"
                        )}
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <OnlineFriends />
      </div>
    </Container>
  );
}
