export const StaticRules = [
    {
        id: 1,
        title: 'Be Respectful',
        description: `Treat fellow group members with respect and courtesy. Avoid personal attacks, offensive language, and discriminatory behavior. Healthy discussions and debates are encouraged, but maintain a respectful tone.`
    },
    {
        id: 2,
        title: 'Stay on Topic',
        description: `Ensure that your posts and discussions are relevant to the group's purpose and topic. Avoid spamming or posting unrelated content that may distract or detract from the group's focus.`
    },
    {
        id: 3,
        title: 'No Self-Promotion',
        description: `Unless explicitly allowed by the group's guidelines, refrain from excessive self-promotion or advertising. The group is meant for discussions and sharing, not for constant promotion of personal or business interests.`
    },
    {
        id: 4,
        title: 'No Hate Speech or Harassment',
        description: `Hate speech, bullying, or harassment of any kind will not be tolerated. Be mindful of your language and interactions to create a safe and inclusive environment for all group members.`
    },
    {
        id: 5,
        title: 'Use Appropriate Language and Content',
        description: `Maintain a level of professionalism and use appropriate language in your posts and comments. Avoid sharing explicit, offensive, or NSFW (Not Safe for Work) content.`
    },
    {
        id: 6,
        title: 'Provide Value',
        description: `Contribute meaningful and valuable content to the group. Share relevant articles, resources, insights, and engage in constructive discussions that add value to the community.`
    },
    {
        id: 7,
        title: 'Respect Privacy',
        description: `Respect the privacy of others and do not share personal information about fellow group members without their consent. Be cautious when discussing sensitive or confidential topics.`
    },
    {
        id: 8,
        title: 'Report Violations',
        description: `If you come across any posts, comments, or behavior that violate the group's rules or guidelines, report it to the group administrators or moderators. They will take appropriate action to address the issue.`
    },
    {
        id: 9,
        title: 'Follow Platform Guidelines',
        description: `Adhere to the general guidelines and terms of service of the social media platform hosting the group. Familiarize yourself with the platform's policies to ensure compliance.`
    },
    {
        id: 10,
        title: 'Be Responsible',
        description: `Take responsibility for your own actions and words. Remember that your posts and comments reflect on the group as a whole. Think before you post and contribute in a responsible manner.`
    },
    {
        id: 11,
        title: 'Provide Value',
        description: `Contribute meaningful and valuable content to the group. Share relevant articles, resources, insights, and engage in constructive discussions that add value to the community.`
    },
]