import React, { useEffect, useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import ToastMessage from "../../Utils/ToastMessage";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import { FetchApi } from "../../API/FetchApi";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import PostItem from "./PostItem";
import PeopleItem from "./PeopleItem";
import PageItem from "./PageItem";
import GroupItem from "./GroupItem";
import { Helmet } from "react-helmet";

export default function MainSearch() {
  const [courseData, setCourseData] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [internships, setInternships] = useState([]);
  const [pages, setPages] = useState([]);
  const [people, setPeople] = useState([]);
  const [posts, setPosts] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [limit, setLimit] = useState(4);
  const navigate = useNavigate();
  const param = useParams();
  const location = useLocation();
  const SearchText = new URLSearchParams(useLocation().search).get("q");
  const type = new URLSearchParams(useLocation().search).get("type");

  const [courseLimit, setCourseLimit] = useState(4);
  const [jobLimit, setJobLimit] = useState(4);
  const [groupsLimit, setGroupsLimit] = useState(4);
  const [internshipsLimit, setInternshipsLimit] = useState(4);
  const [pagesLimit, setPagesLimit] = useState(4);
  const [peopleLimit, setPeopleLimit] = useState(4);
  const [teachersLimit, setTeachersLimit] = useState(4);

  const [courseCount, setCourseCount] = useState(0);
  const [jobCount, setJobCount] = useState(0);
  const [groupsCount, setGroupsCount] = useState(0);
  const [internshipsCount, setInternshipsCount] = useState(0);
  const [pagesCount, setPagesCount] = useState(0);
  const [peopleCount, setPeopleCount] = useState(0);
  const [postsCount, setPostsCount] = useState(0);
  const [teachersCount, setTeachersCount] = useState(0);
  const username = localStorage.getItem("userName");

  useEffect(() => {
    handleGetSearch();
  }, [activeTab, type, SearchText]);
  const handleGetSearch = async () => {
    setActiveTab(type);
    if (location?.search) {
      try {
        let resp = await FetchApi(
          Endpoints.MasterListingSearch +
            "?q=" +
            SearchText +
            "&type=All" +
            "&size=" +
            limit
        );
        if (resp && resp.success === true) {
          if (resp.data) {
            setCourseData(resp?.data?.courses);
            setJobData(resp?.data?.jobs);
            setGroups(resp?.data?.groups);
            setPeople(resp?.data?.people);
            setInternships(resp?.data?.internships);
            setPages(resp?.data?.pages);
            setPosts(resp?.data?.posts);
            setTeachers(resp?.data?.teachers);

            setCourseCount(resp?.data?.totalCourses);
            setJobCount(resp?.data?.totaljobs);
            setGroupsCount(resp?.data?.totalGroup);
            setInternshipsCount(resp?.data?.totalInternship);
            setPagesCount(resp?.data?.totalPage);
            setPeopleCount(resp?.data?.totalPeople);
            setTeachersCount(resp?.data?.totalTeacher);

            // if (type === "Course") {
            //     setCourseData(resp?.data?.result);
            //     setCourseCount(resp?.data?.totalRecord)
            // }
            // if (type === "Job") {
            //     setJobData(resp?.data?.result);
            //     setJobCount(resp?.data?.totalRecord)
            // }
            // if (type === "Internship") {
            //     setInternships(resp?.data?.result);
            //     setInternshipsCount(resp?.data?.totalRecord)
            // }
            // if (type === "Post") {
            //     setPages(resp?.data?.result);
            // }
            // if (type === "Group") {
            //     setGroups(resp?.data?.result);
            //     setGroupsCount(resp?.data?.totalRecord)
            // }
            // if (type === "Page") {
            //     setPages(resp?.data?.result);
            //     setPagesCount(resp?.data?.totalRecord)
            // }
            // if (type === "Teacher") {
            //     setTeachers(resp?.data?.result);
            //     setTeachersCount(resp?.data?.totalRecord)
            // }
            // if (type === "People") {
            //     setPeople(resp?.data?.result);
            //     setPeopleCount(resp?.data?.totalRecord)
            // }
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const HandleLoadMore = async (loadtype) => {
    let newLimit = 0;

    if (loadtype === "Course") {
      newLimit = courseLimit + 4;
      setCourseLimit(newLimit);
    }

    if (loadtype === "Job") {
      newLimit = jobLimit + 4;
      setJobLimit(newLimit);
    }
    if (loadtype === "Internship") {
      newLimit = internshipsLimit + 4;
      setInternshipsLimit(newLimit);
    }

    if (loadtype === "Group") {
      newLimit = groupsLimit + 4;
      setGroupsLimit(newLimit);
    }
    if (loadtype === "Page") {
      newLimit = pagesLimit + 4;
      setPagesLimit(newLimit);
    }
    if (loadtype === "Teacher") {
      newLimit = teachersLimit + 4;
      setTeachersLimit(newLimit);
    }
    if (loadtype === "People") {
      newLimit = peopleLimit + 4;
      setPeopleLimit(newLimit);
    }

    try {
      let resp = await FetchApi(
        Endpoints.MasterListingSearch +
          "?q=" +
          SearchText +
          "&type=" +
          loadtype +
          "&size=" +
          newLimit
      );
      if (resp && resp.success === true) {
        if (resp.data) {
          if (loadtype === "Course") {
            setCourseData(resp?.data?.result);
            setCourseCount(resp?.data?.totalRecord);
          }

          if (loadtype === "Job") {
            setJobData(resp?.data?.result);
            setJobCount(resp?.data?.totalRecord);
          }
          if (loadtype === "Internship") {
            setInternships(resp?.data?.result);
            setInternshipsCount(resp?.data?.totalRecord);
          }
          if (loadtype === "Post") {
            setPages(resp?.data?.result);
          }
          if (loadtype === "Group") {
            setGroups(resp?.data?.result);
            setGroupsCount(resp?.data?.totalRecord);
          }
          if (loadtype === "Page") {
            setPages(resp?.data?.result);
            setPagesCount(resp?.data?.totalRecord);
          }
          if (loadtype === "Teacher") {
            setTeachers(resp?.data?.result);
            setTeachersCount(resp?.data?.totalRecord);
          }
          if (loadtype === "People") {
            setPeople(resp?.data?.result);
            setPeopleCount(resp?.data?.totalRecord);
          }
        }
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const JobSave = async (Id) => {
    let data = {
      jobId: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.JobSave, data);
      if (resp && resp.success === true) {
        handleGetSearch();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "Year ago";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " Mon ago";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + " Day ago";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + " H ago";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " Min ago";
    return Math.floor(seconds) >= 0
      ? Math.floor(seconds) + " Sec ago"
      : "0 Sec ago";
  };

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
    navigate("/search?q=" + SearchText + "&type=" + tab);
  };
  return (
    <Container header>
      <Helmet>
        <title>Search</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />

          <div class="container py-3 home">
            <div class="row">
              <div class="col-md-3  pb-3">
                <div class="bg-white rounded-15 shadow py-3 ">
                  <h4 class="medium border-bottom pb-2 px-2">Search Results</h4>
                  <div class="py-3">
                    <ul class="fs-14 medium search-sidebar">
                      <li class={activeTab === "All" ? "active" : ""}>
                        <Link
                          onClick={() => {
                            handleActiveTab("All");
                          }}
                          to={"/search?q=" + SearchText + "&type=All"}
                        >
                          <img src="assets/img/icon/all.svg" /> All
                        </Link>
                      </li>
                      {courseData.length > 0 ? (
                        <li class={activeTab === "Course" ? "active" : ""}>
                          <Link
                            onClick={() => {
                              handleActiveTab("Course");
                            }}
                            to={"/search?q=" + SearchText + "&type=Course"}
                          >
                            <img src="assets/img/icon/courses1.svg" /> Courses
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {jobData.length > 0 ? (
                        <li class={activeTab === "Job" ? "active" : ""}>
                          <Link
                            onClick={() => {
                              handleActiveTab("Job");
                            }}
                            to={"/search?q=" + SearchText + "&type=Job"}
                          >
                            <img src="assets/img/icon/jobs1.svg" /> Jobs
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}

                      {internships.length > 0 ? (
                        <li class={activeTab === "Internship" ? "active" : ""}>
                          <Link
                            onClick={() => {
                              handleActiveTab("Internship");
                            }}
                            to={"/search?q=" + SearchText + "&type=Internship"}
                          >
                            <img src="assets/img/icon/internships1.svg" />{" "}
                            Internships
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {posts.length > 0 ? (
                        <li class={activeTab === "Post" ? "active" : ""}>
                          <Link
                            onClick={() => {
                              handleActiveTab("Post");
                            }}
                            to={"/search?q=" + SearchText + "&type=Post"}
                          >
                            <img src="assets/img/icon/posts1.svg" /> Posts
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {people.length > 0 ? (
                        <li class={activeTab === "People" ? "active" : ""}>
                          <Link
                            onClick={() => {
                              handleActiveTab("People");
                            }}
                            to={"/search?q=" + SearchText + "&type=People"}
                          >
                            <img src="assets/img/icon/people1.svg" /> People
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {groups.length > 0 ? (
                        <li class={activeTab === "Group" ? "active" : ""}>
                          <Link
                            onClick={() => {
                              handleActiveTab("Group");
                            }}
                            to={"/search?q=" + SearchText + "&type=Group"}
                          >
                            <img src="assets/img/icon/groups1.svg" /> Groups
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {pages.length > 0 ? (
                        <li class={activeTab === "Page" ? "active" : ""}>
                          <Link
                            onClick={() => {
                              handleActiveTab("Page");
                            }}
                            to={"/search?q=" + SearchText + "&type=Page"}
                          >
                            <img src="assets/img/icon/pages1.svg" /> Pages
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {teachers.length > 0 ? (
                        <li class={activeTab === "Teacher" ? "active" : ""}>
                          <Link
                            onClick={() => {
                              handleActiveTab("Teacher");
                            }}
                            to={"/search?q=" + SearchText + "&type=Teacher"}
                          >
                            <img src="assets/img/icon/teachers1.svg" /> Teachers
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-md-9 pb-3">
                {(activeTab === "Course" && courseData.length > 0) ||
                (activeTab === "All" && courseData.length > 0) ? (
                  <div class="bg-white pt-3 rounded-15 shadow mb-3">
                    <h4 class="fs-20 medium pb-2 border-bottom px-3">
                      Courses
                    </h4>

                    <div class="p-3 pt-3">
                      {courseData.length > 0
                        ? courseData.map((item, index) => {
                            return (
                              <div
                                class="row align-items-center mb-3"
                                key={index}
                              >
                                <div class="col-md-8 d-md-flex gap-2 align-items-center">
                                  {item?.thumbnail === null ? (
                                    <img
                                      src="assets/img/cart-item.png"
                                      class="rounded-2 object-cover"
                                      width="96"
                                      height="96"
                                    />
                                  ) : (
                                    <img
                                      src={Endpoints.baseUrl + item?.thumbnail}
                                      class="rounded-2 object-cover"
                                      width="96"
                                      height="96"
                                    />
                                  )}
                                  <div>
                                    <h5 class="medium">{item?.title}</h5>

                                    <div class="py-2 d-flex w-100 align-items-center fs-14 ">
                                      <span class="text-brown ">
                                        {Math.round(item?.avgRating)}
                                      </span>
                                      &nbsp;
                                      <ReactStars
                                        count={5}
                                        edit={false}
                                        value={item?.avgRating}
                                        size={24}
                                        isHalf={true}
                                        char={
                                          <img
                                            src="../assets/img/icon/state-null.svg"
                                            alt=""
                                            width="17"
                                          />
                                        }
                                        emptyIcon={
                                          <img
                                            src="../assets/img/icon/state-null.svg"
                                            alt=""
                                            width="17"
                                          />
                                        }
                                        halfIcon={
                                          <img
                                            src="../assets/img/icon/state-half.svg"
                                            alt=""
                                            width="17"
                                          />
                                        }
                                        filledIcon={
                                          <img
                                            src="../assets/img/icon/State-fill.svg"
                                            alt=""
                                            width="17"
                                          />
                                        }
                                        activeColor="#ffc107"
                                      />
                                      <span class="text-brown ">
                                        ({item?.totalCourseReview} reviews)
                                      </span>
                                      <span class="medium text-black ps-3">
                                        {item?.totalEnrolledStudent}{" "}
                                        <span class="text-gray">Students</span>
                                      </span>
                                    </div>

                                    <h6 class="regular fs-14">
                                      Created by{" "}
                                      <span class="text-brown">
                                        Ronald Richards, Cameron Williamson
                                      </span>
                                    </h6>
                                  </div>
                                </div>

                                <div class="col-md-4 d-sm-flex align-items-center gap-3 justify-content-end">
                                  <h4 class="text-blue semibold fs-16 text-nowrap py-md-0 py-2">
                                    ₹ {item?.discountedPrice}{" "}
                                    <del class="text-lightgray medium">
                                      ₹ {item?.price}
                                    </del>
                                  </h4>
                                  <div>
                                    <Link
                                      to={"/course-detail/" + item?.slug}
                                      class="btn py-1 fs-12 mb-md-2"
                                    >
                                      View Course
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : "Course not found"}

                      {courseLimit < courseCount ? (
                        <button
                          onClick={() => {
                            HandleLoadMore("Course");
                          }}
                          class="btn w-100 bg-d8d8d8 text-black fs-14 medium mt-3"
                        >
                          See All Courses
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {(activeTab === "Job" && jobData.length > 0) ||
                (activeTab === "All" && jobData.length > 0) ? (
                  <div class="bg-white pt-3 rounded-15 shadow mb-3">
                    <h4 class="fs-20 medium pb-2 border-bottom px-3">Jobs</h4>

                    <div class="p-3 pt-3">
                      {jobData.map((item, index) => {
                        return (
                          <div
                            class="d-flex gap-2 align-items-start  pb-3"
                            key={index}
                          >
                            {item?.page?.pageImage == undefined ||
                            item?.page == null ? (
                              <img
                                src="assets/img/picture.png"
                                alt=""
                                width="64"
                                height="64"
                                class="rounded-circle"
                              />
                            ) : (
                              <img
                                src={Endpoints.baseUrl + item?.page?.pageImage}
                                alt=""
                                width="64"
                                height="64"
                                class="rounded-circle"
                              />
                            )}
                            <div class="d-flex align-items-strat justify-content-between w-100">
                              <div>
                                <h5 class="semibold">{item?.title}</h5>
                                <h5 class="medium text-brown fs-14 pt-1">
                                  {item?.page?.title}
                                </h5>
                                <div class="d-flex py-2 align-items-center">
                                  <span class="fs-10 text-darkgray pe-2 d-flex align-items-center">
                                    <img
                                      src="assets/img/icon/icon-location.svg"
                                      alt=""
                                      class="align-middle me-1"
                                    />
                                    <span>{item?.state?.state}</span>
                                  </span>

                                  <span class="fs-10 text-darkgray d-flex align-items-center">
                                    <img
                                      src="assets/img/icon/icon-date-time.svg"
                                      alt=""
                                      class="align-middle me-1"
                                    />
                                    <span>
                                      {timeSince(new Date(item.createdAt))}
                                    </span>
                                  </span>
                                </div>

                                <div class="d-flex request-btn gap-2 mb-2">
                                  <Link class="btn light-brown-btn px-2 medium">
                                    {item?.jobType}
                                  </Link>
                                  <Link class="btn light-brown-btn px-2 medium">
                                    {item?.workMode}
                                  </Link>
                                </div>
                              </div>
                              <div class="text-end">
                                {item?.isSaved === "false" ? (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      JobSave(item?.id);
                                    }}
                                  >
                                    <img
                                      src="assets/img/icon/save.svg"
                                      width="15"
                                      alt=""
                                    />{" "}
                                  </a>
                                ) : (
                                  ""
                                )}
                                <Link
                                  to={"/jobs/" + item?.id}
                                  class="btn px-lg-4 py-2 fs-12 d-block mt-2"
                                >
                                  View Job
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      {jobLimit < jobCount ? (
                        <button
                          onClick={() => {
                            HandleLoadMore("Job");
                          }}
                          class="btn w-100 bg-d8d8d8 text-black fs-14 medium"
                        >
                          See All Jobs
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {(activeTab === "Internship" && internships.length > 0) ||
                (activeTab === "All" && internships.length > 0) ? (
                  <div class="bg-white pt-3 rounded-15 shadow mb-3">
                    <h4 class="fs-20 medium pb-2 border-bottom px-3">
                      Internships
                    </h4>

                    <div class="p-3 pt-3">
                      {internships.map((item, index) => {
                        return (
                          <div
                            class="d-flex gap-2 align-items-start  pb-3"
                            key={index}
                          >
                            {item?.page?.pageImage == undefined ||
                            item?.page == null ? (
                              <img
                                src="assets/img/picture.png"
                                alt=""
                                width="64"
                                height="64"
                                class="rounded-circle"
                              />
                            ) : (
                              <img
                                src={Endpoints.baseUrl + item?.page?.pageImage}
                                alt=""
                                width="64"
                                height="64"
                                class="rounded-circle"
                              />
                            )}
                            <div class="d-flex align-items-strat justify-content-between w-100">
                              <div>
                                <h5 class="semibold">{item?.title}</h5>
                                <h5 class="medium text-brown fs-14 pt-1">
                                  {item?.page?.title}
                                </h5>
                                <div class="d-flex py-2 align-items-center">
                                  <span class="fs-10 text-darkgray pe-2 d-flex align-items-center">
                                    <img
                                      src="assets/img/icon/icon-location.svg"
                                      alt=""
                                      class="align-middle me-1"
                                    />
                                    <span>{item?.state?.state}</span>
                                  </span>

                                  <span class="fs-10 text-darkgray d-flex align-items-center">
                                    <img
                                      src="assets/img/icon/icon-date-time.svg"
                                      alt=""
                                      class="align-middle me-1"
                                    />
                                    <span>
                                      {timeSince(new Date(item.createdAt))}
                                    </span>
                                  </span>
                                </div>

                                <div class="d-flex request-btn gap-2 mb-2">
                                  <Link class="btn light-brown-btn px-2 medium">
                                    {item?.jobType}
                                  </Link>
                                  <Link class="btn light-brown-btn px-2 medium">
                                    {item?.workMode}
                                  </Link>
                                </div>
                              </div>
                              <div class="text-end">
                                {item?.isSaved === "false" ? (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      JobSave(item?.id);
                                    }}
                                  >
                                    <img
                                      src="assets/img/icon/save.svg"
                                      width="15"
                                      alt=""
                                    />{" "}
                                  </a>
                                ) : (
                                  ""
                                )}
                                <Link
                                  to={"/jobs/" + item?.id}
                                  class="btn px-lg-4 py-2 fs-12 d-block mt-2"
                                >
                                  View Job
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {internshipsLimit < internshipsCount ? (
                        <button
                          onClick={() => {
                            HandleLoadMore("Internship");
                          }}
                          class="btn w-100 bg-d8d8d8 text-black fs-14 medium"
                        >
                          See All Internships
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {(activeTab === "Post" && posts.length > 0) ||
                (activeTab === "All" && posts.length > 0) ? (
                  <div class="bg-white pt-3 rounded-15 shadow mb-3">
                    <h4 class="fs-20 medium pb-2 border-bottom px-3">Posts</h4>

                    <PostItem
                      {...{
                        post: posts,
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}

                {(activeTab === "People" && people.length > 0) ||
                (activeTab === "All" && people.length > 0) ? (
                  <div class="bg-white pt-3 rounded-15 shadow mb-3">
                    <h4 class="fs-20 medium pb-2 border-bottom px-3">People</h4>

                    <div class="p-3 pt-3">
                      {people.map((item, index) => {
                        if (item?.username == username) return null;
                        return (
                          <PeopleItem
                            {...{
                              item: item,
                              index: index,
                              type: "people",
                              onSuccess: handleGetSearch,
                            }}
                          />
                        );
                      })}
                      {peopleLimit < peopleCount ? (
                        <button
                          onClick={() => {
                            HandleLoadMore("People");
                          }}
                          class="btn w-100 bg-d8d8d8 text-white fs-14 medium"
                        >
                          See All People
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {(activeTab === "Teacher" && teachers.length > 0) ||
                (activeTab === "All" && teachers.length > 0) ? (
                  <div class="bg-white pt-3 rounded-15 shadow mb-3">
                    <h4 class="fs-20 medium pb-2 border-bottom px-3">
                      Teachers
                    </h4>
                    <div class="p-3 pt-3">
                      {teachers.map((item, index) => {
                        if (item?.username == username) return null;
                        return (
                          <PeopleItem
                            {...{
                              item: item,
                              index: index,
                              type: "teacher",
                              onSuccess: handleGetSearch,
                            }}
                          />
                        );
                      })}
                      {teachersLimit < teachersCount ? (
                        <button
                          onClick={() => {
                            HandleLoadMore("Teacher");
                          }}
                          class="btn w-100 bg-d8d8d8 text-black fs-14 medium"
                        >
                          See All Teachers
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {(activeTab === "Group" && groups.length > 0) ||
                (activeTab === "All" && groups.length > 0) ? (
                  <div class="bg-white pt-3 rounded-15 shadow mb-3">
                    <h4 class="fs-20 medium pb-2 border-bottom px-3">Groups</h4>
                    <div class="p-3 pt-3">
                      {groups.map((item, index) => {
                        return (
                          <GroupItem
                            {...{
                              item: item,
                              index: index,
                              type: "Groups",
                              onSuccess: handleGetSearch,
                            }}
                          />
                        );
                      })}
                      {groupsLimit < groupsCount ? (
                        <button
                          onClick={() => {
                            HandleLoadMore("Group");
                          }}
                          class="btn w-100 bg-d8d8d8 text-black fs-14 medium"
                        >
                          See All Groups
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {(activeTab === "Page" && pages.length > 0) ||
                (activeTab === "All" && pages.length > 0) ? (
                  <div class="bg-white pt-3 rounded-15 shadow mb-3">
                    <h4 class="fs-20 medium pb-2 border-bottom px-3">Pages</h4>
                    <div class="p-3 pt-3">
                      {pages.map((item, index) => {
                        return (
                          <PageItem
                            {...{
                              item: item,
                              index: index,
                              type: "Pages",
                              onSuccess: handleGetSearch,
                            }}
                          />
                        );
                      })}
                      {pagesLimit < pagesCount ? (
                        <button
                          onClick={() => {
                            HandleLoadMore("Page");
                          }}
                          class="btn w-100 bg-d8d8d8 text-black fs-14 medium"
                        >
                          See All Pages
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
