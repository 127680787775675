import React, { useEffect, useState } from "react";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { CommonAPi } from "../../API/CommonApi";
import { FetchApi } from "../../API/FetchApi";
import { Link, useNavigate } from "react-router-dom";
const $ = window.jQuery;

export default function SuggestedJobs() {
  const Navigate = useNavigate();
  const [jobData, setJobData] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      $(".suggested-teacher").owlCarousel({
        loop: true,
        margin: 15,
        items: 2,
        nav: true,
        dots: true,
        autoplay: false,
        autoplayTimeout: 3000,
        navText: [
          "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' stroke-linecap='round' strokeLinejoin='round' class='feather feather-chevron-left'><polyline points='15 18 9 12 15 6'></polyline></svg>",
          "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' stroke-linecap='round' strokeLinejoin='round' class='feather feather-chevron-right'><polyline points='9 18 15 12 9 6'></polyline></svg>",
        ],
        responsive: {
          0: {
            items: 1,
            margin: 0,
          },
          768: {
            items: 2,
          },
        },
      });
    }, 200);
  }, []);

  useEffect(() => {
    getrecommendationsUser();
  }, []);

  const getrecommendationsUser = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.recommendationsUser);
      if (resp && resp.success === true) {
        setJobData(resp?.data?.jobRecommendations);
        setTimeout(() => {
          $(".suggested-teacher").owlCarousel({
            loop: true,
            margin: 15,
            items: 2,
            nav: true,
            dots: true,
            autoplay: false,
            autoplayTimeout: 3000,
            navText: [
              "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' stroke-linecap='round' strokeLinejoin='round' class='feather feather-chevron-left'><polyline points='15 18 9 12 15 6'></polyline></svg>",
              "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' stroke-linecap='round' strokeLinejoin='round' class='feather feather-chevron-right'><polyline points='9 18 15 12 9 6'></polyline></svg>",
            ],
            responsive: {
              0: {
                items: 1,
                margin: 0,
              },
              768: {
                items: 2,
              },
            },
          });
        }, 200);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "year ago";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon ago";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + " day ago";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + " h ago";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min ago";
    return Math.floor(seconds) >= 0
      ? Math.floor(seconds) + " sec ago"
      : "0 sec ago";
  };

  const JobSave = async (Id) => {
    let data = {
      jobId: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.JobSave, data);
      if (resp && resp.success === true) {
        getrecommendationsUser();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <>
      {jobData.length > 0 ? (
        <div class="sidebar-widget mt-4 mb-4">
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="medium">Suggested Jobs</h4>

            {/* <div class="position-relative">
                    <a href="#" aria-haspopup="true" aria-label="suggested_teacher" data-bs-toggle="dropdown" class="d-flex align-items-center justify-content-center ">
                        <svg viewBox="0 0 24 24" width="22" height="22" stroke="#b86d2c" strokeWidth="2" fill="none" stroke-linecap="round" strokeLinejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                    </a>

                    <ul class="popup-menu text-start shadow rounded-1" role="menu" id="suggested_teacher" aria-hidden="true" tabindex="-1">
                        <li><a class="dropdown-item py-2 d-flex gap-2" href="#">
                            <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" stroke-linecap="round" strokeLinejoin="round" class="css-i6dzq1"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                            Delete
                        </a></li>
                        <li><a class="dropdown-item py-2 d-flex gap-2" href="#">
                            <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" stroke-linecap="round" strokeLinejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line></svg>
                            Restricted
                        </a></li>

                    </ul>
                </div> */}
          </div>

          <div class="pt-3 mt-2 border-top">
            <div class="suggested-teacher slider-item-border owl-theme owl-carousel">
              {jobData.map((item, index) => {
                return (
                  <div className="border rounded-15 p-3 pt-3 m-1" key={index}>
                    <div className="d-flex gap-2 align-items-start justify-content-between pb-3">
                      <span>
                        {item?.page?.pageImage === null ? (
                          <img
                            src="../assets/img/comany-dp.png"
                            alt=""
                            width="48"
                            height="48"
                            className="rounded-circle"
                          />
                        ) : (
                          <img
                            src={Endpoints.baseUrl + item?.page?.pageImage}
                            alt=""
                            width="48"
                            height="48"
                            className="rounded-circle job-suggested-img"
                          />
                        )}
                      </span>
                      {item?.isSaved === "true" ? (
                        <Link
                          onClick={() => {
                            JobSave(item?.id);
                          }}
                        >
                          <img
                            src="../assets/img/icon/save-fill.svg"
                            width="15"
                            alt=""
                          />
                        </Link>
                      ) : (
                        <Link
                          onClick={() => {
                            JobSave(item?.id);
                          }}
                        >
                          <img
                            src="../assets/img/icon/save.svg"
                            width="15"
                            alt=""
                          />
                        </Link>
                      )}
                    </div>
                    <div>
                      <Link to={"/jobs/" + item?.id}>
                        <h5 className="semibold">{item?.title}</h5>
                      </Link>

                      <h5 className="medium text-brown fs-14 pt-1">
                        {item?.page?.title}
                      </h5>
                      <div className="d-flex py-2 align-items-center">
                        <span className="fs-10 text-darkgray pe-2 d-flex align-items-center">
                          <img
                            src="../assets/img/icon/icon-location.svg"
                            alt=""
                            className="align-middle me-1"
                          />
                          <span>{item?.state?.state}</span>
                        </span>

                        <span className="fs-10 text-darkgray d-flex align-items-center">
                          <img
                            src="../assets/img/icon/icon-date-time.svg"
                            alt=""
                            className="align-middle me-1"
                          />
                          <span>{timeSince(new Date(item.createdAt))}</span>
                        </span>
                      </div>

                      <div className="d-flex request-btn gap-2 mb-2">
                        <a href="#" className="btn light-brown-btn px-2 medium">
                          {item?.jobType}
                        </a>
                        <a href="#" className="btn light-brown-btn px-2 medium">
                          {item?.workMode}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
