import React, { useEffect, useState } from "react";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import ProfileNavigation from "../../../Components/ProfileNavigation";
import Container from "../../../Components/Container";
import Profile from "../../ProfileAbout/Profile";
import { Endpoints } from "../../../API/Endpoints";
import ToastMessage from "../../../Utils/ToastMessage";
import { CommonAPi } from "../../../API/CommonApi";
import { FetchApi } from "../../../API/FetchApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactStars from "react-rating-stars-component";
const jQuery = window.jQuery;

function Teachers() {
  const [teacherData, setTeacherData] = useState([]);
  const [activeTab, setActiveTab] = useState("Teacher");
  const [count, setCount] = useState(0);
  const [schoolCount, setSchoolCount] = useState(0);
  const [cityCount, setCityCount] = useState(0);
  const [limit, setLimit] = useState(12);
  const [loadStatus, setLoadStatus] = useState(false);
  const Navigate = useNavigate();
  const param = useParams();
  const userId = localStorage.getItem("userId");
  const username = localStorage.getItem("userName");

  useEffect(() => {
    getTeacher("Teacher");
  }, [param?.id]);

  const getTeacher = async () => {
    setActiveTab("Teacher");
    try {
      let resp = await FetchApi(
        Endpoints.GetProfileTeacher +
          "?size=" +
          limit +
          "&page=1&id=" +
          param?.id
      );
      if (resp && resp.success === true) {
        setTeacherData(resp?.data?.data);
        setCityCount(resp?.data?.cityCount);
        setSchoolCount(resp?.data?.schoolCount);
        setCount(resp?.data?.count);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleGetTeacher = async (value, activeTab) => {
    setActiveTab(activeTab);
    try {
      let resp = await FetchApi(
        Endpoints.GetProfileTeacher +
          "?size=" +
          limit +
          "&page=1&" +
          value +
          "&id=" +
          param?.id
      );
      if (resp && resp.success === true) {
        setTeacherData(resp?.data?.data);
        setCityCount(resp?.data?.cityCount);
        setSchoolCount(resp?.data?.schoolCount);
        setCount(resp?.data?.count);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSearchTeacher = async (value) => {
    let url;
    if (activeTab) {
      url =
        Endpoints.GetProfileTeacher +
        "?size=" +
        limit +
        value +
        "&id=" +
        param?.id +
        "&" +
        activeTab +
        "=true";
    } else {
      url =
        Endpoints.GetProfileTeacher +
        "?size=" +
        limit +
        value +
        "&id=" +
        param?.id;
    }
    try {
      let resp = await FetchApi(url);
      if (resp && resp.success === true) {
        setTeacherData(resp?.data?.data);
        setCityCount(resp?.data?.cityCount);
        setSchoolCount(resp?.data?.schoolCount);
        setCount(resp?.data?.count);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleLoadMore = async () => {
    const newLimit = limit + 12;
    setLimit(newLimit);
    let url;
    if (activeTab) {
      url =
        Endpoints.GetProfileTeacher +
        "?size=" +
        newLimit +
        "&id=" +
        param?.id +
        "&" +
        activeTab +
        "=true";
    } else {
      url =
        Endpoints.GetProfileTeacher + "?size=" + newLimit + "&id=" + param?.id;
    }
    try {
      let resp = await FetchApi(url);
      if (resp && resp.success === true) {
        setTeacherData(resp?.data?.data);
        setCityCount(resp?.data?.cityCount);
        setSchoolCount(resp?.data?.schoolCount);
        setCount(resp?.data?.count);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSearchFriend = async (value) => {
    let url;
    if (activeTab) {
      url =
        Endpoints.GetProfileTeacher +
        "?size=" +
        limit +
        value +
        "&id=" +
        param?.id +
        "&" +
        activeTab +
        "=true";
    } else {
      url =
        Endpoints.GetProfileTeacher +
        "?size=" +
        limit +
        value +
        "&id=" +
        param?.id;
    }
    try {
      let resp = await FetchApi(url);
      if (resp && resp.success === true) {
        setTeacherData(resp?.data?.data);
        setCityCount(resp?.data?.cityCount);
        setSchoolCount(resp?.data?.schoolCount);
        setCount(resp?.data?.count);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleBlockUser = async (Id) => {
    let data = {
      blockedUser: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.BlockFriend, data);
      if (resp && resp.message) {
        getTeacher();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleUnfriend = async (Id) => {
    let data = {
      friend_id: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.Unfriend, data);
      if (resp && resp.message) {
        getTeacher();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Teachers</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-lg-3 py-3">
            <div className="profile-banner-top mb-3  rounded-15 overflow-hidden">
              <Profile />

              <ProfileNavigation />
            </div>
            <div class="bg-white main-common">
              <div class="main-top-row d-md-flex justify-content-between align-items-center pb-3">
                <h3 class="medium">Teachers</h3>
                {/* {username === param?.id ? */}
                <div class="d-sm-flex gap-3 pb-3">
                  <form
                    action=""
                    class="mainmenu-page-search d-flex flex-row-reverse align-items-center"
                  >
                    <input
                      type="text"
                      placeholder="Search for teachers"
                      name="search"
                      class="bg-transparent fs-14 medium  text-darkgray"
                      onChange={(e) => {
                        handleSearchTeacher("&term=" + e.target.value);
                      }}
                    />
                    <button type="submit" class="bg-transparent border-0">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="#949494"
                        strokeWidth="2"
                        fill="none"
                        stroke-linecap="round"
                        strokeLinejoin="round"
                        class="css-i6dzq1"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </button>
                  </form>
                  <select
                    name="sortby"
                    id="sortby"
                    class="text-darkgray"
                    onChange={(e) => {
                      handleSearchFriend("&sort=" + e.target.value);
                    }}
                  >
                    <option value="">Sort by</option>
                    <option value="a-z">Ascending</option>
                    <option value="z-a">Descending</option>
                    <option value="oldest">Oldest</option>
                    <option value="latest">Recently</option>
                  </select>
                </div>
                {/* : ""} */}
              </div>
              {/* {username === param?.id ? */}
              <div class="mainmenu-filter-row pb-3">
                <ul class="filter d-flex flex-nowrap align-items-center gap-2 fs-14 medium">
                  <li
                    class={activeTab === "Teacher" ? "active " : ""}
                    onClick={() => {
                      setActiveTab("");
                      getTeacher();
                    }}
                  >
                    All Teachers
                  </li>
                  {/* <li class={activeTab === "class" ? "active " : ""}
                                            onClick={() => {
                                                handleGetTeacher("class=true", "class")
                                            }}
                                        >Class Teachers <span class="fs-10 text-brown">{classTecherCount}</span></li> */}
                  <li
                    class={activeTab === "school" ? "active " : ""}
                    onClick={() => {
                      handleGetTeacher("school=true", "school");
                    }}
                  >
                    School <span class="fs-10 text-brown">{schoolCount}</span>
                  </li>
                  <li
                    class={activeTab === "location" ? "active " : ""}
                    onClick={() => {
                      handleGetTeacher("location=true", "location");
                    }}
                  >
                    Current City{" "}
                    <span class="fs-10 text-brown">{cityCount}</span>
                  </li>
                </ul>
              </div>
              {/* : ""} */}

              <div class="row mainmenu-filter-conatiner profile-teacher-list">
                {teacherData?.length > 0 ? (
                  teacherData?.map((item, index) => {
                    return (
                      <div class="col-lg-3 col-md-4 col-sm-6 mb-4" key={index}>
                        <div class="mainmenu-page-item">
                          <div class="text-center position-relative">
                            {username === param?.id ? (
                              <div class="mainmenu-item-action position-absolute end-0 top-0 m-2 p-1">
                                <a
                                  href="#"
                                  id="friendDropdown"
                                  data-bs-toggle="dropdown"
                                  class="d-flex align-items-center justify-content-center rounded-circle bg-eee p-1"
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="18"
                                    height="18"
                                    stroke="#000"
                                    strokeWidth="2"
                                    fill="none"
                                    stroke-linecap="round"
                                    strokeLinejoin="round"
                                    class="css-i6dzq1"
                                  >
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="19" cy="12" r="1"></circle>
                                    <circle cx="5" cy="12" r="1"></circle>
                                  </svg>
                                </a>
                                <ul
                                  class="popup-menu shadow rounded-1 dropdown-menu text-start"
                                  aria-labelledby="friendDropdown"
                                >
                                  <li>
                                    <a
                                      class="dropdown-item py-2 d-flex gap-2"
                                      href="#"
                                      onClick={() => {
                                        handleBlockUser(item?.id);
                                      }}
                                    >
                                      {/* <svg viewBox="0 0 24 24" width="15" height="15" stroke="currentColor" strokeWidth="2" fill="none" stroke-linecap="round" strokeLinejoin="round" class="css-i6dzq1"><polyline points="20 6 9 17 4 12"></polyline></svg> */}{" "}
                                      Block
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}

                            {item.user_profile.coverImg === null ? (
                              <img
                                src="../assets/img/pro-banner.png"
                                class="mainmenu-page-banner"
                                alt=""
                              />
                            ) : (
                              <img
                                src={
                                  Endpoints.baseUrl + item.user_profile.coverImg
                                }
                                class="mainmenu-page-banner"
                                alt=""
                              />
                            )}
                            <span class="suggested-user">
                              <Link to={"/timeline/" + item.username}>
                                {item.user_profile.profileImg === null ? (
                                  <img
                                    src="../assets/img/picture.png"
                                    alt=""
                                    width="96"
                                    height="96"
                                    class="rounded-circle"
                                  />
                                ) : (
                                  <img
                                    src={
                                      Endpoints.baseUrl +
                                      item.user_profile.profileImg
                                    }
                                    alt=""
                                    width="96"
                                    height="96"
                                    class="rounded-circle"
                                  />
                                )}
                              </Link>
                            </span>
                            <Link to={"/timeline/" + item.username}>
                              <h5 class="semibold pt-2 pb-2">
                                {item.fullName}
                              </h5>
                            </Link>
                            <h6 class="fs-14 text-darkgray regular pb-1">
                              {item?.user_profile.profileTagline}
                            </h6>
                            {/* <h6 class="fs-14 text-darkgray medium pb-1">Sapphire International School, Noida</h6> */}
                            <h6 class="fs-14 text-darkgray regular ">
                              {item.studentCount} Students
                            </h6>

                            <div class="d-flex justify-content-center align-items-center">
                              <span class="fs-12 medium">
                                {item.averageRating}
                              </span>
                              <div class="d-flex mx-2 course-rating">
                                <ReactStars
                                  count={5}
                                  edit={false}
                                  value={item.averageRating}
                                  size={18}
                                  isHalf={true}
                                  char={
                                    <img
                                      src="../assets/img/icon/state-null.svg"
                                      width="16px"
                                      alt=""
                                    />
                                  }
                                  emptyIcon={
                                    <img
                                      src="../assets/img/icon/state-null.svg"
                                      width="16px"
                                      alt=""
                                    />
                                  }
                                  halfIcon={
                                    <img
                                      src="../assets/img/icon/state-half.svg"
                                      width="16px"
                                      alt=""
                                    />
                                  }
                                  filledIcon={
                                    <img
                                      src="../assets/img/icon/State-fill.svg"
                                      width="16px"
                                      alt=""
                                    />
                                  }
                                  activeColor="#ffd700"
                                  activeBackgroundColor="#ffd700"
                                />
                              </div>
                              <span class="fs-12 medium text-lightgray">
                                ({item.totalReviewsCount})
                              </span>
                            </div>
                          </div>

                          <div class="text-center pt-2 pb-3 px-1">
                            <Link
                              to={`/messages?roomId=${item.chatRoomId}`}
                              class="btn btn-brown w-100 py-2 fs-14 mw-100"
                            >
                              Send a Message
                              <img
                                src="../assets/img/icon/message.svg"
                                class="ps-2"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    No Teacher Found
                  </div>
                )}
              </div>
              {teacherData.length >= 12 && count > limit ? (
                <div className="text-center">
                  <Link
                    href="#"
                    className="text-blue"
                    onClick={() => handleLoadMore()}
                  >
                    {loadStatus ? (
                      <img src="../assets/img/icon/icon-loading.svg" alt="" />
                    ) : (
                      "Load More Teacher "
                    )}
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}

export default Teachers;
