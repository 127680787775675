import React from 'react'

const SharedDeletedPost = () => {
  return (
   <div className="share-post-deleted border p-2 rounded">
          <h5 className="text-secondary">This post is not available</h5>
        </div>
  )
}

export default SharedDeletedPost