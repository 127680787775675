import React, { useEffect, useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { CommonAPi } from "../../API/CommonApi";
import { PatchApi } from "../../API/PatchApi";
import { Endpoints } from "../../API/Endpoints";
import ProfileImage from "../ProfileUpdate/ProfileImage";
import Sidebar from "../ProfileUpdate/Sidebar";
import ToastMessage from "../../Utils/ToastMessage";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function Notification() {
  const [notificationData, setNotificationData] = useState([]);

  const history = useNavigate();
  useEffect(() => {
    getNotification();
  }, []);

  const getNotification = async () => {
    try {
      let resp = await CommonAPi(Endpoints.NotificationSettingAll);
      if (resp && resp.success === true) {
        setNotificationData(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleChange = async (id, status, type) => {
    console.log(type);
    let data = "";
    if (type === "appNotification") {
      data = {
        id: id,
        appNotification: status,
      };
    }
    if (type === "pushNotification") {
      data = {
        id: id,
        pushNotification: status,
      };
    }

    if (type === "emailNotification") {
      data = {
        id: id,
        emailNotification: status,
      };
    }
    try {
      let resp = await PatchApi(Endpoints.UpdateNotificationSetting, data);
      if (resp && resp.success === true) {
        getNotification();
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Notification Setting</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-lg-4 py-3">
            <ProfileImage />
            <div className="row">
              <Sidebar />
              <div class="col-lg-9 col-md-8">
                <div class="bg-white box-shadow rounded-15 p-md-3 p-2">
                  <div class="pt-md-2 pb-3 mb-3 px-lg-3 border-bottom">
                    <h3 class="medium">Notifications Settings</h3>
                    <h6 class="medium fs-16 pt-2">
                      Set your notification preferences.
                    </h6>
                  </div>

                  <div class="accordion px-3" id="notifications">
                    {notificationData?.map((item, index) => {
                      return (
                        <div class="accordion-item border-0 p-0 mb-4">
                          <h2
                            class="accordion-header"
                            id={"notification" + index}
                          >
                            <div
                              class="accordion-button collapsed border-0 bg-transparent p-0 shadow-none"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={"#noti" + index}
                              aria-expanded="false"
                            >
                              <div class="d-flex align-items-start justify-content-between">
                                <div>
                                  <h4 class="medium pb-1 text-black">
                                    {item?.action}
                                  </h4>
                                  <h5 class="medium text-darkgray">
                                    {item?.description}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </h2>
                          <div
                            id={"noti" + index}
                            class="accordion-collapse collapse p-0"
                            aria-labelledby={"notification" + index}
                            data-bs-parent="#notifications"
                          >
                            <div class="accordion-body pb-0">
                              <div class="d-flex align-items-start justify-content-between pb-2">
                                <h5 class="medium text-darkgray">
                                  In App Notifications
                                </h5>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={
                                      item?.appNotification === true
                                        ? true
                                        : false
                                    }
                                    onChange={() => {
                                      handleChange(
                                        item?.id,
                                        item?.appNotification === true
                                          ? false
                                          : true,
                                        "appNotification"
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div class="d-flex align-items-start justify-content-between pb-2">
                                <h5 class="medium text-darkgray">
                                  Push Notifications
                                </h5>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={
                                      item?.pushNotification === true
                                        ? true
                                        : false
                                    }
                                    onChange={() => {
                                      handleChange(
                                        item?.id,
                                        item?.pushNotification === true
                                          ? false
                                          : true,
                                        "pushNotification"
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div class="d-flex align-items-start justify-content-between">
                                <h5 class="medium text-darkgray">
                                  Email Notifications
                                </h5>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={
                                      item?.emailNotification === true
                                        ? true
                                        : false
                                    }
                                    onChange={() => {
                                      handleChange(
                                        item?.id,
                                        item?.emailNotification === true
                                          ? false
                                          : true,
                                        "emailNotification"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* <div class="text-md-end text-center py-2 px-3 mb-lg-2">
                                        <button type="submit" class="btn">Update Settings</button>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <OnlineFriends />
      </div>
    </Container>
  );
}
