import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalDialog,
  ModalHeader,
} from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { Endpoints } from "../../API/Endpoints";
// import { backendUrl, reactBackendUrl } from "../../utils";

export const isImage = (file) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "svg"];
  const extension = file.split(".").pop().toLowerCase();
  return imageExtensions.includes(extension);
};
const ImagePreviewModel = ({ show, onHide, message }) => {
  console.log("1@@", message);
  const formatMessageDateTime = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}/${month}/${year} at ${hours}:${minutes}`;
  };

  return (
    <Modal className="d-flex image-preview-modal" show={show} onHide={onHide}>
      <ModalDialog>
        <ModalHeader className="w-100 d-flex justify-content-between px-4 border-0 pb-2">
          <div
            className={`${""} w-100 d-flex align-items-center gap-2 cursor-pointer p-2 px-3`}
          >
            <img
              src={
                message?.user?.user_profile?.profileImg
                  ? Endpoints.baseUrl + message?.user?.user_profile?.profileImg
                  : "/assets/img/picture.png"
              }
              alt=""
              className="br-50"
              style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            />
            <div className="d-flex flex-column">
              <div className="fw-600">
                {" "}
                {message?.user?.fullName ? message?.user?.fullName : "--"}
              </div>
              <div className="fs-14">
                {formatMessageDateTime(message?.updatedAt)}
              </div>
            </div>
          </div>
          <Button
            onClick={onHide}
            className="bg-transparent border-0 text-black"
          >
            <IoMdClose className="cursor-pointer " size={28} onClick={onHide} />
          </Button>
        </ModalHeader>
        <ModalBody
          className="d-flex pt-0"
          style={{
            justifyContent: "center",
          }}
        >
          {isImage(message?.course_message_media?.file) ? (
            <img
              className="w-100 h-100"
              src={Endpoints.baseUrl + message?.course_message_media?.file}
              alt=""
              style={{ objectFit: "contain" }}
            />
          ) : (
            <video
              className="w-100 h-100 focus-none"
              controls
              controlsList="nodownload"
              src={Endpoints.baseUrl + message?.course_message_media?.file}
              style={{ objectFit: "contain" }}
            />
          )}
        </ModalBody>
      </ModalDialog>
    </Modal>
  );
};

export default ImagePreviewModel;
