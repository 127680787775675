import React, { useEffect, useState, useCallback } from "react";
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";

export default function SearchJob({ type }) {
  const [categoryData, setCategoryData] = useState([]);
  const [location, setLocation] = useState("");
  const [countryKey, setCountryKey] = useState(
    Math.ceil(Math.random() * 999999)
  );
  const [searchKey, setSearchKey] = useState(Math.ceil(Math.random() * 999999));
  const [countryOption, setCountryOption] = useState([]);
  const [seacrhOption, setSearchOption] = useState([]);
  const [category, setCategory] = useState("");
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    getCategory();
    getCountry();
  }, []);

  const getCountry = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetCountry);
      if (resp && resp.success === true) {
        const newData = resp.data.map((item, index) => {
          return {
            id: item.id,
            name: item.countryName,
          };
        });
        setCountryOption(newData);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getCategory = async () => {
    try {
      let resp = await FetchApi(Endpoints.JobsIndustries + "?scope=" + type);
      if (resp && resp.success === true) {
        setCategoryData(resp?.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleOnSelectLocation = (item) => {
    if (item.length > 0) {
      setLocation(item[0].id);
    } else {
      setLocation("");
    }
  };

  const handleOnSearchLocation = async (string) => {
    try {
      let resp = await FetchApi(Endpoints.SearchLocation + "?term=" + string);
      if (resp && resp.success === true) {
        const newData = resp.data.map((item, index) => {
          return {
            id: item?.id,
            name: item?.city + ", " + item?.state?.state,
            stateId: item?.state?.id,
            stateName: item?.state?.state,
          };
        });
        setCountryOption(newData);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const RenderCountry = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-StudyArea"
        defaultInputValue={location}
        key={countryKey}
        onSearch={handleOnSearchLocation}
        onChange={handleOnSelectLocation}
        options={countryOption}
        placeholder={"Location"}
        labelKey="name"
        minLength={1}
        emptyLabel={"No result found"}
        className={""}
        style={{ position: "absolute" }}
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [countryOption]);

  const handleOnSelectJob = (item) => {
    setSearchText(item[0].name);
  };

  const handleOnSearchJob = async (string) => {
    try {
      let resp = await FetchApi(Endpoints.JobsSearch + "?title=" + string);
      if (resp && resp.success === true) {
        const newData = resp.data?.allJob.map((item, index) => {
          if (index > 9) return null;
          return {
            id: item?.id,
            name: item?.title,
          };
        });
        setSearchOption(newData);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const RenderSearch = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-StudyArea"
        defaultInputValue={location}
        key={searchKey}
        onSearch={handleOnSearchJob}
        onChange={handleOnSelectJob}
        options={seacrhOption}
        placeholder={"Search by title or keyword"}
        labelKey="name"
        minLength={1}
        emptyLabel={"No result found"}
        className="ps-2 py-3 w-100"
        style={{ position: "absolute" }}
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [seacrhOption, searchKey]);

  const SearchJob = () => {
    let url = "";
    if (type === "Internship") {
      url = "/internship-listing?";
    } else {
      url = "/jobs-listing?";
    }

    if (searchText) {
      url = url + "title=" + searchText;
    }

    if (location) {
      if (searchText) {
        url = url + "&cityId=" + location;
      } else {
        url = url + "cityId=" + location;
      }
    }

    if (category) {
      if ((searchText && location) || location || searchText) {
        url = url + "&industry=" + category;
      } else {
        url = url + "industry=" + category;
      }
    }

    navigate(url);
  };
  return (
    <div class="job-banner py-lg-5 px-lg-5 p-md-3 p-2">
      {type === "JOb" ? (
        <div class="pb-4 text-center text-white">
          <h1 class="bold pb-3">The Easiest Way to Get Your New Job</h1>
          <h2>Find great job to build your bright career</h2>
        </div>
      ) : (
        <div class="pb-4 text-center text-white">
          <h1 class="bold pb-3">Get ahead, get an internship!</h1>
          <h2>Apply to premium fresher jobs for free</h2>
        </div>
      )}

      <div class="job-search-form">
        <form
          action=""
          class="row justify-content-between m-0 bg-white rounded-pill "
        >
          <div class="d-flex align-items-center ps-3 col-md-4">
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="#949494"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              class="css-i6dzq1"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
            {/* <input 
                    type="text" 
                    placeholder="Search by internship title or keyword" 
                    class="ps-2 py-3" 
                    onChange={(e)=>{
                        setSearchText(e.target.value)
                    }}
                    /> */}
            {RenderSearch()}
          </div>

          <div class="d-flex align-items-center border-start border-end px-3 col-md-3">
            <img src="assets/img/icon/icon-location2.svg" class="me-1" alt="" />
            {/* <input type="text" placeholder="Location" class="py-3" /> */}
            {RenderCountry()}
          </div>

          <div class="col-md-3 d-flex align-items-center ">
            <select
              name=""
              id=""
              class="py-3"
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              <option value="">Select Industry</option>
              {categoryData.map((item, index) => {
                return (
                  <option value={item?.id} key={index}>
                    {item?.value}
                  </option>
                );
              })}
            </select>
          </div>

          <button
            type="submit"
            class="btn btn-brown px-4 py-3 rounded-0 col-md-2 job-search-button"
            onClick={() => {
              SearchJob();
            }}
          >
            Search
          </button>
        </form>
      </div>
    </div>
  );
}
