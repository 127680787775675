import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import { Endpoints } from "../../../API/Endpoints";
import { FetchApi } from "../../../API/FetchApi";
import { CommonAPi } from "../../../API/CommonApi";
import ToastMessage from "../../../Utils/ToastMessage";
import ReactStars from "react-rating-stars-component";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import VideoPlayer from "../../../Components/VideoPlayer";
import { Helmet } from "react-helmet";
const $ = window.jQuery;
export default function Learning() {
  const [learning, setLearning] = useState("");
  const [lectureContent, setLectureContent] = useState("");
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [resourceLinks, setResourceLinks] = useState([]);
  const [courseSections, setCourseSections] = useState([]);
  const [video, setVideo] = useState("");
  const [videoSecond, setVideoSecond] = useState("");
  const [courseId, setCourseId] = useState("");
  const [lectureId, setLectureId] = useState();
  const [lecturelength, setlecturelength] = useState("");
  const [sectionId, setSectionId] = useState();
  const [duration, setDuration] = useState(0);
  const [review, setReview] = useState();
  const [reviewRating, setReviewRating] = useState(0);
  const [activeNavbar, setActiveNavbar] = useState("description");
  const [downLoadText, setDownloadText] = useState("Download File");
  const videoRef = useRef(0);
  const param = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      setDuration((value) => value + 10);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (duration) {
      updateProgress();
    }
  }, [duration]);

  useEffect(() => {
    function animateProgressBar(el, width) {
      el.animate(
        { width: width },
        {
          duration: 2000,
        }
      );
    }
    $(".course-progress").each(function () {
      animateProgressBar($(this).find("div"), learning.percentCompleted + "%");
    });
  }, [learning]);

  useEffect(() => {
    getLearning();
  }, []);

  const FormatSeconds = (seconds) => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const remainingSeconds = seconds % 60;
    const parts = [];

    if (days > 0) {
      parts.push(`${days} day${days > 1 ? "s" : ""}`);
    }
    if (hours > 0) {
      parts.push(`${hours} hr${hours > 1 ? "s" : ""}`);
    }
    if (minutes > 0) {
      parts.push(`${minutes} min${minutes > 1 ? "s" : ""}`);
    }
    if (remainingSeconds > 0) {
      parts.push(`${remainingSeconds} sec${remainingSeconds > 1 ? "s" : ""}`);
    }

    if (parts.length === 0) {
      return "0 sec";
    } else {
      return parts.join(", ");
    }
  };

  const getLearning = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetLeaning + "/" + param?.id);
      if (resp && resp.success === true) {
        setLearning(resp.data);
        console.log(
          "@#@#12",
          resp?.data,
          resp?.data?.course_sections[0]?.course_lectures[0]?.totalTimeLength
        );
        setCourseId(resp.data?.id);

        if (resp?.data?.course_sections.length > 0) {
          setLectureId(resp?.data?.course_sections[0]?.course_lectures[0]?.id);
          setSectionId(resp?.data?.course_sections[0]?.id);
          setlecturelength(
            resp?.data?.course_sections[0]?.course_lectures[0]?.totalTimeLength
          );
          setVideo(
            resp?.data?.course_sections[0]?.course_lectures[0]?.lectureMedia
          );
          setLectureContent(
            resp?.data?.course_sections[0]?.course_lectures[0]
              ?.lectureDescription
          );
          setAttachedFiles(
            resp?.data?.course_sections[0]?.course_lectures[0]?.lectureResource
          );
          setResourceLinks(
            resp?.data?.course_sections[0]?.course_lectures[0]?.resourceLink
          );
        }

        setCourseSections(resp?.data?.course_sections);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getReloadLearning = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetLeaning + "/" + param?.id);
      if (resp && resp.success === true) {
        setLearning(resp.data);
        setCourseId(resp.data?.id);
        setCourseSections(resp?.data?.course_sections);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const updateProgress = async () => {
    const obj = {
      course_id: parseInt(param?.id),
      lecture_id: lectureId,
      progress: ((videoSecond / lecturelength) * 100).toFixed(0),
      seconds_covered: videoSecond,
    };
    console.log(
      "@#@#12",
      videoSecond,
      lecturelength,
      videoSecond / lecturelength,
      obj
    );
    if (lectureId && videoSecond) {
      try {
        let resp = await CommonAPi(Endpoints.courseProgress, obj);
        if (resp && resp.success === true) {
          getReloadLearning();
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  // const handletimeUpdate = (item) => {
  //     setVideoSecond(Math.round(item.currentTarget.currentTime));
  // };

  const handleLectureSelection = (item) => {
    setLectureId(item.id);
    setSectionId(item.courseSectionId);
    console.log("@#@#12 1", item?.totalTimeLength);
    setlecturelength(item.totalTimeLength);
    setVideo(item.lectureMedia);
    setLectureContent(item.lectureDescription);
    if (item.lectureResource !== null) {
      setAttachedFiles(item.lectureResource);
    } else {
      setAttachedFiles([]);
    }
  };

  const findPreviousLecture = () => {
    let prevLecture;
    let sections = learning.course_sections;
    let selectedLectureId = lectureId;

    let lectures_at_section = sections.find(
      (item) => item.id == sectionId
    ).course_lectures;
    let selected_lecture_index = lectures_at_section.findIndex(
      (item) => item.id == selectedLectureId
    );

    // select from within same section when exist
    if (selected_lecture_index - 1 >= 0) {
      prevLecture = lectures_at_section[selected_lecture_index - 1];
    }
    // select from other section
    else {
      let currentSectionIndex = sections.findIndex(
        (item) => item.id == sectionId
      );
      // if selectable section exist within data, select from that
      if (currentSectionIndex - 1 >= 0) {
        let prevSection = sections[currentSectionIndex - 1];
        if (prevSection.course_lectures.length) {
          prevLecture =
            prevSection.course_lectures[prevSection.course_lectures.length - 1];
        }
      }
    }
    if (prevLecture) {
      handleLectureSelection(prevLecture);
    }
  };

  const findNextLecture = () => {
    let nextLecture;
    let sections = learning.course_sections;
    let selectedLectureId = lectureId;

    let lectures_at_section = sections.find(
      (item) => item.id == sectionId
    ).course_lectures;
    let selected_lecture_index = lectures_at_section.findIndex(
      (item) => item.id == selectedLectureId
    );

    if (selected_lecture_index < lectures_at_section.length - 1) {
      nextLecture = lectures_at_section[selected_lecture_index + 1];
    } else {
      let currentSectionIndex = sections.findIndex(
        (item) => item.id == sectionId
      );
      if (currentSectionIndex < sections.length - 1) {
        let nextSection = sections[currentSectionIndex + 1];
        if (nextSection.course_lectures.length) {
          nextLecture = nextSection.course_lectures[0];
        }
      }
    }
    if (nextLecture) {
      handleLectureSelection(nextLecture);
    }
  };

  const handleVideoCompleted = () => {
    updateProgress();
    findNextLecture();
  };

  const ratingChanged = (rating) => {
    setReviewRating(rating);
  };

  const handleSaveRating = async (event) => {
    event.preventDefault();
    const obj = {
      course_id: param?.id,
      rating: reviewRating,
      comment: review,
    };
    try {
      let resp = await CommonAPi(Endpoints.CreateReview, obj);
      if (resp && resp.success === true) {
        ToastMessage.Success(resp.message);
        document.getElementById("myform").reset();
        setReview("");
        setReviewRating(0);
        getReloadLearning();
        $("#ratingmodal").modal("hide");
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleCloseModel = () => {
    document.getElementById("myform").reset();
    setReview("");
    setReviewRating(0);
  };
  const handleCopy = () => {
    const url = window.location.origin + "/course-detail" + "/" + param.id;
    navigator.clipboard.writeText(url);
    ToastMessage.Success("Link copied successfully");
  };

  const downloadFile = (filePath) => {
    if (downLoadText !== "Download File") return;
    setDownloadText("Downloading...");
    fetch(Endpoints.baseUrl + filePath)
      .then((response) => response.blob())
      .then((blob) => {
        const file = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = file;
        link.download = filePath.substring(46);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setDownloadText("Download File");
      })
      .catch((error) => {
        console.error(error);
        setDownloadText("Download File");
      });
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }
  function Remove(str) {
    return str.substr(83);
  }

  const handleShowResource = () => {};

  return (
    <Container header>
      <Helmet>
        <title>Watch Course</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main single-course-enrolled py-5">
          <LeftNavigation />
          <div className="container">
            <div className="bg-white rounded-10 py-3 px-3 mb-3">
              <h4 className="text-blue pb-3">
                {learning.title}{" "}
                <Link to={"/my-courses"}>
                  <img src="../assets/img/icon/cruv-left-arrow.svg" alt="" />
                </Link>
              </h4>
              <div className="d-md-flex align-items-center justify-content-between">
                <div className="d-flex gap-3 pb-md-0 pb-2">
                  <h6 className="fs-14 pb-md-0 pb-2">
                    <img
                      src="../assets/img/icon/folder.svg"
                      className="me-2"
                      alt=""
                    />
                    {learning.totalSections} Sections
                  </h6>
                  <h6 className="fs-14 pb-md-0 pb-2">
                    <img
                      src="../assets/img/icon/play.svg"
                      className="me-2"
                      alt=""
                    />
                    {learning.totalLectures} Total Lectures
                  </h6>
                  <h6 className="fs-14 pb-md-0 pb-2">
                    <img
                      src="../assets/img/icon/time.svg"
                      className="me-2"
                      alt=""
                    />
                    {FormatSeconds(learning.totalTimeLength)} Time Length
                  </h6>
                </div>

                <div className="d-flex gap-2 align-items-center">
                  {learning.isReviewed === false ? (
                    <Link
                      className="btn btn-br-blue fs-14 py-2"
                      data-bs-toggle="modal"
                      data-bs-target="#ratingmodal"
                    >
                      <img src="../assets/img/icon/star-blue.svg" alt="" />{" "}
                      Leave a rating
                    </Link>
                  ) : (
                    ""
                  )}

                  {/* <div className="position-relative">
                    <Link
                      to="#"
                      data-bs-toggle="dropdown"
                      className="d-flex align-items-center justify-content-center rounded-circle bg-eee p-2"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                        stroke="#616161"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="css-i6dzq1"
                      >
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="19" cy="12" r="1"></circle>
                        <circle cx="5" cy="12" r="1"></circle>
                      </svg>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="video-section mb-3">
                  <div className="feed-video position-relative rounded-15 overflow-hidden">
                    <VideoPlayer
                      src={Endpoints.baseUrl + video}
                      onPrevious={findPreviousLecture}
                      onNext={handleVideoCompleted}
                      onComplete={handleVideoCompleted}
                      secondsPassed={setVideoSecond}
                    />
                  </div>
                </div>
                <div className="bg-white rounded-10 box-shadow py-3 px-3 mb-3">
                  <ul className="profile-menu d-flex flex-wrap justify-content-between border-bottom mb-3">
                    <li
                      className={activeNavbar === "description" ? "active" : ""}
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          setActiveNavbar("description");
                        }}
                      >
                        Description
                      </Link>
                    </li>

                    <li className={activeNavbar === "content" ? "active" : ""}>
                      <Link
                        to="#"
                        onClick={() => {
                          setActiveNavbar("content");
                        }}
                      >
                        Lecture Content
                      </Link>
                    </li>

                    <li className={activeNavbar === "files" ? "active" : ""}>
                      <Link
                        to="#"
                        onClick={() => {
                          setActiveNavbar("files");
                        }}
                      >
                        Resources
                      </Link>
                    </li>
                    {/* <li>
                                           <Link to="#">Comments</Link>
                                        </li> */}
                  </ul>
                  {activeNavbar === "description" ? (
                    <div className="mb-3">
                      <h4 className="pb-3">Description</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: learning.description,
                        }}
                      ></p>
                    </div>
                  ) : (
                    ""
                  )}

                  {activeNavbar === "content" ? (
                    <div className=" mb-3">
                      <h4 className="pb-3">Lecture Content</h4>
                      <p
                        dangerouslySetInnerHTML={{ __html: lectureContent }}
                      ></p>
                    </div>
                  ) : (
                    ""
                  )}

                  {activeNavbar === "files" &&
                    (attachedFiles !== null ? (
                      attachedFiles.length > 0 || resourceLinks?.length > 0 ? (
                        <div className="mb-3 pb-3">
                          <h4 className="pb-3 d-flex align-items-center">
                            Resources{" "}
                            <span className="fs-10 text-brown count-number">
                              {attachedFiles?.length + resourceLinks?.length}
                            </span>
                          </h4>

                          <div className="download-item">
                            {attachedFiles?.map((item, index) => {
                              return (
                                <div
                                  className="item-list d-md-flex justify-content-between align-items-center py-3 px-2 px-lg-3 rounded-10 mb-3"
                                  key={index}
                                >
                                  <div className="d-flex align-items-center mb-md-0 mb-3">
                                    <img
                                      src="../assets/img/icon/download-d.svg"
                                      className="me-3 "
                                      alt=""
                                    />
                                    <div>
                                      <h5 className="fs-14 medium">
                                        {Remove(item.resource)}
                                      </h5>
                                      <span className="text-dark-gray fs-12">
                                        {formatBytes(item.size)}
                                      </span>
                                    </div>
                                  </div>
                                  <span
                                    onClick={() => {
                                      downloadFile(item.resource);
                                    }}
                                    className="btn text-white fs-14 py-2"
                                  >
                                    {downLoadText}{" "}
                                    <img
                                      src="../assets/img/icon/download-file.svg"
                                      alt=""
                                    />
                                  </span>
                                </div>
                              );
                            })}
                            {resourceLinks?.map((item, index) => {
                              return (
                                <div
                                  className="item-list d-md-flex justify-content-between align-items-center py-3 px-2 px-lg-3 rounded-10 mb-3"
                                  key={index}
                                >
                                  <div className="d-flex align-items-center mb-md-0 mb-3">
                                    <img
                                      src="../assets/img/icon/download-d.svg"
                                      className="me-3 "
                                      alt=""
                                    />
                                    <div>
                                      <h5 className="fs-14 medium">
                                        {/* {Remove(item.resource)} */}
                                      </h5>
                                      <span className="text-dark-gray fs-12">
                                        {item.title}
                                      </span>
                                    </div>
                                  </div>
                                  <span
                                    onClick={() => {
                                      let url = item?.link;
                                      if (url && !/^https?:\/\//i.test(url)) {
                                        url = `https://${url}`;
                                      }
                                      window.open(url, "_blank");
                                    }}
                                    className="btn text-white fs-14 py-2 "
                                  >
                                    Open Link
                                    <img
                                      src="../assets/img/icon/download-file.svg"
                                      alt=""
                                      className="ms-2"
                                    />
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        "No attached file found"
                      )
                    ) : (
                      "No attached file found"
                    ))}
                </div>
              </div>
              <div className="col-md-4">
                <div className="cd-sidebar">
                  <div className="bg-white rounded-10 py-3 mb-3">
                    <div className="pb-3  px-lg-3 px-2">
                      <div className="d-flex align-items-center justify-content-between pb-2">
                        <h4 className="semibold">Course Content</h4>
                        <h6 className="fs-14 regular complete-text">
                          {learning.percentCompleted >= 100
                            ? 100
                            : learning.percentCompleted}
                          % Complete
                        </h6>
                      </div>
                      <div
                        className="course-progress progress"
                        data-id={learning?.percentCompleted + "%"}
                      >
                        <div className="progress-active"></div>
                      </div>
                    </div>
                    <div id="accordion">
                      {courseSections.map((item, index) => {
                        return (
                          <div className="card border-0 rounded-0" key={index}>
                            <div
                              className="card-header border-bottom p-0 px-lg-3 px-2  bg-white border-0 py-3"
                              id={"heading" + index}
                            >
                              <h4
                                className="pb-2 semibold collapsed"
                                data-bs-toggle={"collapse"}
                                data-bs-target={"#collapse" + index}
                                aria-expanded="true"
                                aria-controls={"collapse" + index}
                              >
                                {item.sectionTitle}
                              </h4>

                              <div className="d-lg-flex d-md-block d-flex justify-content-between ps-md-4">
                                <h6 className="fs-14">
                                  <img
                                    src="../assets/img/icon/play.svg"
                                    className="me-2"
                                    alt=""
                                  />
                                  {item.totalLectures} Lectures
                                </h6>
                                <h6 className="fs-14">
                                  <img
                                    src="../assets/img/icon/time.svg"
                                    className="me-2"
                                    alt=""
                                  />
                                  {FormatSeconds(item.totalTimeLength)}
                                </h6>
                                <h6 className="fs-14">
                                  <img
                                    src="../assets/img/icon/check-blue.svg"
                                    className="me-2"
                                    alt=""
                                  />
                                  0% Finish
                                </h6>
                              </div>
                            </div>
                            <div
                              id={"collapse" + index}
                              className={
                                index == 0
                                  ? "collapse border-bottom show"
                                  : "collapse border-bottom"
                              }
                              aria-labelledby={"heading" + index}
                              data-parent={"#accordion" + index}
                            >
                              <div className="card-body py-lg-3 py-2 p-0">
                                {item.course_lectures.map(
                                  (LectureItem, LectureIndex) => {
                                    return (
                                      <React.Fragment key={index}>
                                        <div
                                          className={
                                            lectureId == LectureItem?.id
                                              ? "py-3 inner-list d-flex justify-content-between learning-active px-2"
                                              : "py-3 inner-list d-flex justify-content-between px-2"
                                          }
                                        >
                                          <div
                                            className={"me-auto"}
                                            onClick={() => {
                                              handleLectureSelection(
                                                LectureItem
                                              );
                                            }}
                                            key={LectureIndex}
                                          >
                                            <div className="px-4 d-flex ">
                                              {/* <input type="checkbox" id="01" className="me-2" /> */}
                                              <label
                                                for="01"
                                                className="fs-16 text-dark-gray medium"
                                              >
                                                {LectureItem.lectureName}
                                              </label>
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content-between ">
                                            <div className="text-end pe-3">
                                              <h4 className="fs-14 text-dark-gray text-nowrap">
                                                <img
                                                  src="../assets/img/icon/gray-play.svg"
                                                  className="me-2 play-gray"
                                                  alt=""
                                                />{" "}
                                                {FormatSeconds(
                                                  LectureItem.totalTimeLength
                                                )}
                                              </h4>
                                            </div>
                                          </div>
                                        </div>
                                        {LectureItem.lectureResource ===
                                        null ? (
                                          ""
                                        ) : (
                                          <Link
                                            className="py-3 inner-list"
                                            onClick={() => {
                                              setActiveNavbar("files");
                                            }}
                                          >
                                            <div
                                              className={
                                                lectureId == LectureItem?.id
                                                  ? "px-4 inner-list learning-active"
                                                  : "px-4 inner-list"
                                              }
                                            >
                                              <img
                                                src="../assets/img/icon/gray-file-icon.svg"
                                                className="me-2 play-gray"
                                                alt=""
                                              />{" "}
                                              Resources
                                            </div>
                                          </Link>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="bg-white py-3 px-3 rounded-10 share-course">
                    <h4 className="fs-18 pb-2 mb-3 border-bottom">
                      Share this course:
                    </h4>
                    <div className="d-lg-flex gap-2 align-items-center">
                      <h5 className="fs-12 regular text-darkgray course-copylink">
                        <Link onClick={handleCopy}>
                          <img
                            src="../assets/img/icon/gray-link.svg"
                            className="me-2"
                            alt=""
                          />
                          Copy Link
                        </Link>
                      </h5>
                      <div className="d-flex justify-content-between course-share-btn gap-1">
                        <FacebookShareButton
                          url={
                            window.location.origin +
                            "/course-detail/" +
                            param.id
                          }
                        >
                          <Link className="px-1">
                            <img
                              src="../assets/img/icon/gray-facebook.svg"
                              width="16"
                              alt=""
                            />
                          </Link>
                        </FacebookShareButton>
                        <LinkedinShareButton
                          url={
                            window.location.origin +
                            "/course-detail/" +
                            param.id
                          }
                        >
                          <Link className="px-1">
                            <img
                              src="../assets/img/icon/gray-in.svg"
                              width="16"
                              alt=""
                            />
                          </Link>
                        </LinkedinShareButton>
                        <EmailShareButton
                          url={
                            window.location.origin +
                            "/course-detail/" +
                            param.id
                          }
                        >
                          <Link className="px-1">
                            <img
                              src="../assets/img/icon/gray-email.svg"
                              width="16"
                              alt=""
                            />
                          </Link>
                        </EmailShareButton>
                        <WhatsappShareButton
                          url={
                            window.location.origin +
                            "/course-detail/" +
                            param.id
                          }
                        >
                          <Link className="px-1">
                            <img
                              src="../assets/img/icon/gray-whatsapp.svg"
                              width="16"
                              alt=""
                            />
                          </Link>
                        </WhatsappShareButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>

      <div
        className="modal fade"
        id="ratingmodal"
        tabIndex="-1"
        aria-labelledby="ratingmodal"
        aria-hidden="true"
      >
        <div className="modal-dialog rounded-10 box-shadow p-lg-4 p-md-3 p-2 bg-white">
          <div className="modal-content border-0">
            <div className="d-flex align-items-center justify-content-between pb-3 border-bottom mb-3 w-100">
              <h3 className="medium text-blue">Add Review</h3>
              <button
                type="button"
                className="btn-close kids-close-icon cursor-pointer"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModel}
              >
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#949494"
                  width="22"
                  height="22"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="modal-body">
              {/* <h4 className="medium text-black pb-3">Leave A rating</h4> */}
              <form
                onSubmit={handleSaveRating}
                className="kids-form generic-form"
                id="myform"
              >
                <div className="row">
                  <div className="col-md-12 mb-3">
                    {reviewRating !== null ? (
                      <ReactStars
                        count={5}
                        edit={true}
                        value={reviewRating}
                        size={30}
                        isHalf={false}
                        onChange={ratingChanged}
                        char={
                          <img
                            src="../assets/img/icon/state-null.svg"
                            width="25px"
                            alt=""
                          />
                        }
                        emptyIcon={
                          <img
                            src="../assets/img/icon/state-null.svg"
                            width="25px"
                            alt=""
                          />
                        }
                        halfIcon={
                          <img
                            src="../assets/img/icon/state-half.svg"
                            width="25px"
                            alt=""
                          />
                        }
                        filledIcon={
                          <img
                            src="../assets/img/icon/State-fill.svg"
                            width="25px"
                            alt=""
                          />
                        }
                        activeColor="#ffd700"
                      />
                    ) : (
                      <ReactStars
                        count={5}
                        edit={true}
                        value={reviewRating}
                        size={30}
                        isHalf={false}
                        onChange={ratingChanged}
                        char={
                          <img
                            src="../assets/img/icon/state-null.svg"
                            width="25px"
                            alt=""
                          />
                        }
                        emptyIcon={
                          <img
                            src="../assets/img/icon/state-null.svg"
                            width="25px"
                            alt=""
                          />
                        }
                        halfIcon={
                          <img
                            src="../assets/img/icon/state-half.svg"
                            width="25px"
                            alt=""
                          />
                        }
                        filledIcon={
                          <img
                            src="../assets/img/icon/State-fill.svg"
                            width="25px"
                            alt=""
                          />
                        }
                        activeColor="#ffd700"
                      />
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label>Add Review</label>
                    <textarea
                      placeholder="Write a review"
                      className="text-gray px-3 py-3 border"
                      onChange={(e) => {
                        setReview(e.target.value);
                      }}
                    >
                      {review}
                    </textarea>
                  </div>

                  <div className="col-md-12 text-end">
                    <button type="submit" className="btn fs-14 ">
                      Add Review{" "}
                      <img
                        src="../assets/img/icon/icon-right-arrow.svg"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
