import React, { useEffect, useState } from "react";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { CommonAPi } from "../../API/CommonApi";
import { FetchApi } from "../../API/FetchApi";
import { Link, useNavigate } from "react-router-dom";
const $ = window.jQuery;
export default function SuggestedGroup() {
  const Navigate = useNavigate();
  const [friendData, setFriendData] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      $(".suggested-friends").owlCarousel({
        loop: true,
        margin: 15,
        items: 2,
        nav: true,
        dots: true,
        autoplay: false,
        autoplayTimeout: 3000,
        navText: [
          "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' stroke-linecap='round' strokeLinejoin='round' class='feather feather-chevron-left'><polyline points='15 18 9 12 15 6'></polyline></svg>",
          "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' stroke-linecap='round' strokeLinejoin='round' class='feather feather-chevron-right'><polyline points='9 18 15 12 9 6'></polyline></svg>",
        ],
        responsive: {
          0: {
            items: 1,
            margin: 0,
          },
          768: {
            items: 2,
          },
        },
      });
    }, 200);
  }, [friendData]);

  useEffect(() => {
    getrecommendationsUser();
  }, []);

  const joinGroup = async (id) => {
    let data = {
      groupId: id,
    };
    try {
      let resp = await CommonAPi(Endpoints.JoinGroups, data);
      if (resp && resp.success === true) {
        getrecommendationsUser();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getrecommendationsUser = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.recommendationsUser);
      if (resp && resp.success === true) {
        setFriendData(resp?.data?.groupRecommendations);
        setTimeout(() => {
          $(".suggested-friends").owlCarousel({
            loop: true,
            margin: 15,
            items: 2,
            nav: true,
            dots: true,
            autoplay: false,
            autoplayTimeout: 3000,
            navText: [
              "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' stroke-linecap='round' strokeLinejoin='round' class='feather feather-chevron-left'><polyline points='15 18 9 12 15 6'></polyline></svg>",
              "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' stroke-linecap='round' strokeLinejoin='round' class='feather feather-chevron-right'><polyline points='9 18 15 12 9 6'></polyline></svg>",
            ],
            responsive: {
              0: {
                items: 1,
                margin: 0,
              },
              768: {
                items: 2,
              },
            },
          });
        }, 200);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <>
      {friendData?.length > 0 ? (
        <li>
          <div class="sidebar-widget mt-4 mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="medium">Suggested Group</h4>

              {/* <div class="position-relative">
                        <a href="#" aria-haspopup="true" aria-label="suggested_friend" data-bs-toggle="dropdown" class="d-flex align-items-center justify-content-center ">
                            <svg viewBox="0 0 24 24" width="22" height="22" stroke="#b86d2c" strokeWidth="2" fill="none" stroke-linecap="round" strokeLinejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                        </a>

                        <ul class="popup-menu text-start shadow rounded-1" role="menu" id="suggested_friend" aria-hidden="true" tabindex="-1">
                            <li><a class="dropdown-item py-2 d-flex gap-2" href="#">
                                <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" stroke-linecap="round" strokeLinejoin="round" class="css-i6dzq1"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                Delete
                            </a></li>
                            <li><a class="dropdown-item py-2 d-flex gap-2" href="#">
                                <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" stroke-linecap="round" strokeLinejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line></svg>
                                Restricted
                            </a></li>

                        </ul>
                    </div> */}
            </div>

            <div class="pt-3 mt-2 border-top">
              <div class="suggested-friends slider-item-border owl-theme owl-carousel ">
                {friendData.map((item, index) => {
                  return (
                    <div class="friends-item text-center" key={index}>
                      <div className="suggested-friend-banner">
                        {item?.coverImage === null ? (
                          <img src="assets/img/friends-banner.png" alt="" />
                        ) : (
                          <img
                            src={Endpoints.baseUrl + item?.coverImage}
                            alt=""
                          />
                        )}
                      </div>

                      <Link to={"/group-home/" + item.id}>
                        <h5 class="semibold pt-3 pb-2">{item.groupName}</h5>
                      </Link>
                      <h6 class="fs-10 text-gray regular pb-2">
                        {item?.groupType}{" "}
                      </h6>
                      <div className="py-2 d-flex justify-content-center group-user">
                        {/* {item?.members.map((membersItem, i) => {
                                if (i > 4) return null;

                                return (
                                    <img src={Endpoints.baseUrl + membersItem.user_profile.profileImg} width="32" height="32" className="rounded-circle" alt="" />
                                )
                            })} */}
                      </div>

                      {/* <h6 className="fs-10 text-mediumgray medium pb-1">{item?.members.map((membersItem, i) => {
                            if (i > 4) return "& 500 others";

                            return (
                                membersItem.fullName 
                            )
                        })} */}
                      {/* </h6> */}

                      <div class="text-center py-3 px-2">
                        <a
                          href="#"
                          class="btn w-100"
                          onClick={() => {
                            joinGroup(item.id);
                          }}
                        >
                          Join{" "}
                          <img
                            src="assets/img/icon/icon-add-friend.svg"
                            class="pe-2"
                          />
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </li>
      ) : (
        ""
      )}
    </>
  );
}
