export default function EntryComponent({
  mention,
  theme,
  searchValue, 
  isFocused, 
  ...parentProps
}) {
  return (
    <div {...parentProps}>
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        {mention.avatar && (
          <div
            style={{
              height: 30,
              width: 30,
              borderRadius: 30,
              overflow: "hidden",
            }}
          >
            <img
              src={mention.avatar}
              alt="user"
              height="100%"
              width="100%"
              style={{ objectFit: "cover" }}
            />
          </div>
        )}
        <div>{mention.name}</div>
      </div>
    </div>
  );
}
