import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
export default function CreateAlbumItem() {
    const Navigate = useNavigate();
    const param = useParams();
    return (
        <div class="col-lg-3 col-md-4 col-sm-6 mb-3" >

            <div class="upload-profile-photos px-md-3 px-2  cursor-pointer"
                onClick={() => {
                    Navigate("/create-albums/" + param?.id)
                }}>
                <div class="text-center" >
                    <input type="file" id="upload-profile" class="d-none" />
                    <label for="upload-profile">
                        <img src="../assets/img/icon/icon-add-image.svg" alt="" class=" cursor-pointer" />
                        <h6 class="fs-14 medium text-brown py-2">Upload an image or album</h6>
                        <h6 class="fs-14 regular text-gray">It’s only take a few seconds!</h6>
                    </label>
                </div>
            </div>

        </div>
    )
}
