import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from "../../API/FetchApi";
import GroupItem from "./GroupItem";
import { Helmet } from "react-helmet";
const jQuery = window.jQuery;

function Groups() {
  const Navigate = useNavigate();
  const [group, setGroup] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalGroupCount, setTotalGroupCount] = useState(0);
  const [totalJoinedGroups, setTotalJoinedGroups] = useState(0);
  const [totalCreatedGroups, setTotalCreatedGroups] = useState(0);
  const [activeTab, setActiveTab] = useState("all");
  const [loadStatus, setLoadStatus] = useState(false);
  const [limit, setLimit] = useState(12);

  useEffect(() => {
    getGroup();
  }, []);
  const getGroup = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.GetGroups + "?scope=" + activeTab + "&size=" + limit
      );
      if (resp && resp.success === true) {
        setGroup(resp.data.groups);
        setTotalJoinedGroups(resp.data.totalJoinedGroups);
        setTotalCreatedGroups(resp.data.totalCreatedGroups);
        setTotalPages(resp.data.totalPages);
        setTotal(resp.data.totalRecords);
        setTotalGroupCount(resp.data.totalGroup);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const getGrouptype = async (type) => {
    setActiveTab(type);
    try {
      let resp = await FetchApi(
        Endpoints.GetGroups + "?scope=" + type + "&size=" + limit
      );
      if (resp && resp.success === true) {
        setGroup(resp.data.groups);
        setTotalJoinedGroups(resp.data.totalJoinedGroups);
        setTotalCreatedGroups(resp.data.totalCreatedGroups);
        setTotalPages(resp.data.totalPages);
        setTotal(resp.data.totalRecords);
        setTotalGroupCount(resp.data.totalGroup);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleLoadMore = async () => {
    const newLimit = limit + 16;
    setLimit(newLimit);
    setLoadStatus(true);
    try {
      let resp = await FetchApi(
        Endpoints.GetGroups + "?scope=" + activeTab + "&size=" + newLimit
      );
      if (resp && resp.success === true) {
        setGroup(resp.data.groups);
        setTotalJoinedGroups(resp.data.totalJoinedGroups);
        setTotalCreatedGroups(resp.data.totalCreatedGroups);
        setTotalPages(resp.data.totalPages);
        setTotal(resp.data.totalRecords);
        setTotalGroupCount(resp.data.totalGroup);
        setLoadStatus(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoadStatus(false);
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleSearch = async (SearchText) => {
    try {
      let resp = await FetchApi(
        Endpoints.GetGroups +
          "?scope=" +
          activeTab +
          "&size=" +
          limit +
          "&term=" +
          SearchText
      );
      if (resp && resp.success === true) {
        setGroup(resp.data.groups);
        setTotalJoinedGroups(resp.data.totalJoinedGroups);
        setTotalCreatedGroups(resp.data.totalCreatedGroups);
        setTotalPages(resp.data.totalPages);
        setTotal(resp.data.totalRecords);
        setTotalGroupCount(resp.data.totalGroup);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleSorting = async (value) => {
    if (value) {
      try {
        let resp = await FetchApi(
          Endpoints.GetGroups +
            "?scope=" +
            activeTab +
            "&size=" +
            limit +
            "&sort=" +
            value
        );
        if (resp && resp.success === true) {
          setGroup(resp.data.groups);
          setTotalJoinedGroups(resp.data.totalJoinedGroups);
          setTotalCreatedGroups(resp.data.totalCreatedGroups);
          setTotalPages(resp.data.totalPages);
          setTotal(resp.data.totalRecords);
          setTotalGroupCount(resp.data.totalGroup);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      try {
        let resp = await FetchApi(
          Endpoints.GetGroups + "?scope=" + activeTab + "&size=" + limit
        );
        if (resp && resp.success === true) {
          setGroup(resp.data.groups);
          setTotalJoinedGroups(resp.data.totalJoinedGroups);
          setTotalCreatedGroups(resp.data.totalCreatedGroups);
          setTotalPages(resp.data.totalPages);
          setTotal(resp.data.totalRecords);
          setTotalGroupCount(resp.data.totalGroup);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  return (
    <Container header>
      <Helmet>
        <title>Group</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-3">
            <div className="bg-white main-common">
              <div className="main-top-row d-md-flex justify-content-between align-items-center pb-3">
                <h3 className="medium">Groups</h3>

                <div className="d-sm-flex gap-3 pb-3">
                  <form
                    action=""
                    className="mainmenu-page-search d-flex flex-row-reverse align-items-center"
                  >
                    <input
                      type="text"
                      placeholder="Search by groups"
                      name="search"
                      className="bg-transparent fs-14 medium  text-darkgray"
                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                    />
                    <button type="submit" className="bg-transparent border-0">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="#949494"
                        strokeWidth="2"
                        fill="none"
                        stroke-linecap="round"
                        strokeLinejoin="round"
                        className="css-i6dzq1"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </button>
                  </form>
                  <select
                    name="sortby"
                    id="sortby"
                    className="text-darkgray"
                    onChange={(e) => {
                      handleSorting(e.target.value);
                    }}
                  >
                    <option value="">Sort by</option>
                    <option value="popular">Popular</option>
                    <option value="recent">Recent</option>
                  </select>
                  <div className="">
                    <Link to={"/create-group"} className="btn btn-br-blue">
                      Create Group +
                    </Link>
                  </div>
                </div>
              </div>

              <div className="mainmenu-filter-row pb-3">
                <ul className="filter d-flex flex-nowrap align-items-center gap-2 fs-14 medium">
                  <li
                    className={activeTab === "all" ? "active " : ""}
                    onClick={() => {
                      getGrouptype("all");
                    }}
                  >
                    All Groups{" "}
                    <span className="fs-10 text-brown">{totalGroupCount}</span>
                  </li>
                  <li
                    className={activeTab === "joined" ? "active " : ""}
                    onClick={() => {
                      getGrouptype("joined");
                    }}
                  >
                    Joined Groups{" "}
                    <span className="fs-10 text-brown">
                      {totalJoinedGroups}
                    </span>
                  </li>

                  <li
                    className={activeTab === "created" ? "active " : ""}
                    onClick={() => {
                      getGrouptype("created");
                    }}
                  >
                    My Groups{" "}
                    <span className="fs-10 text-brown">
                      {totalCreatedGroups}
                    </span>
                  </li>
                </ul>
              </div>

              {group.length > 0 ? (
                <div className="row">
                  {group.map((item, index) => {
                    return (
                      <GroupItem
                        {...{
                          item: item,
                          index: index,
                          onSuccess: getGroup,
                          type: activeTab,
                        }}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className="row">No group added</div>
              )}

              {total > limit ? (
                <div className="text-center">
                  <Link
                    href="#"
                    className="text-blue"
                    onClick={() => handleLoadMore()}
                  >
                    {loadStatus ? (
                      <img src="../assets/img/icon/icon-loading.svg" alt="" />
                    ) : (
                      "Load More " + activeTab
                    )}
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}

export default Groups;
