import React, { useCallback, useEffect, useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { CommonAPi } from "../../API/CommonApi";
import { DeleteApi } from "../../API/DeleteApi";
import { UpdateAPi } from "../../API/UpdateApi";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Grade } from "../../constants/Grade";
import { Link, useNavigate } from "react-router-dom";
import DeletePrompt from "../../Components/DeletePrompt/DeletePrompt";
import "./TestScore.css";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import DiscardPopup from "../../Components/DiscardPopup/DiscardPopup";
import { Helmet } from "react-helmet";
const jQuery = window.jQuery;
const $ = window.jQuery;

function TestScores() {
  const [TestName, setTestName] = useState("");
  const [maxScore, setmaxScore] = useState("");
  const [scoreReceived, setscoreReceived] = useState("");
  const [grade, setGrade] = useState("");
  const [school, setSchool] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [schoolData, setSchoolData] = useState("");
  const [testScoreData, setTestScoreData] = useState([]);
  const [Id, setId] = useState("");
  const [pageId, setpageId] = useState(0);
  const [options, setOptions] = useState([]);
  const Navigate = useNavigate();
  const [TestNameError, setTestNameError] = useState("");
  const [maxScoreError, setmaxScoreError] = useState("");
  const [scoreReceivedError, setscoreReceivedError] = useState("");
  const [gradeError, setGradeError] = useState("");
  const [schoolError, setSchoolError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [item, setItem] = useState();
  const [instituteNameKey, setInstituteNameKey] = useState("instituteNameKey");
  useEffect(() => {
    GetTestScore();
  }, []);
  useEffect(() => {
    if (
      TestName !== "" &&
      school !== "" &&
      maxScore !== "" &&
      scoreReceived !== "" &&
      grade !== "" &&
      pageId !== ""
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [TestName, school, maxScore, scoreReceived, grade, pageId]);

  useEffect(() => {
    var $filters = jQuery(".mainmenu-filter-row .filter [data-filter]"),
      $boxes = jQuery(".mainmenu-filter-conatiner [data-category]");

    $filters.on("click", function (e) {
      e.preventDefault();
      var $this = jQuery(this);
      $filters.removeClass("active");
      $this.addClass("active");

      var $filterColor = $this.attr("data-filter");

      if ($filterColor == "all") {
        $boxes
          .removeClass("is-animated")
          .fadeOut()
          .promise()
          .done(function () {
            $boxes.addClass("is-animated").fadeIn();
          });
      } else {
        $boxes
          .removeClass("is-animated")
          .fadeOut()
          .promise()
          .done(function () {
            $boxes
              .filter('[data-category = "' + $filterColor + '"]')
              .addClass("is-animated")
              .fadeIn();
          });
      }
    });
  }, []);
  const GetTestScore = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetTestScore);
      if (resp && resp.message) {
        setTestScoreData(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleOnSelect = (item) => {
    if (item.length > 0) {
      setpageId(item[0].id);
      setSchool(item[0].name);
    } else {
      setpageId(0);
      setSchool("");
    }
  };

  const handleOnSearch = async (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setSchool(string);
    if (string) {
      try {
        let resp = await FetchApi(Endpoints.SearchPages + "?title=" + string);
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                image: item?.pageImage,
                name: item?.title,
              };
            });
            setOptions(newData);
          } else {
            const newData = [
              {
                id: 0,
                image: "",
                name: string,
                createName: string,
              },
            ];
            setOptions(newData);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleGrade = (grade) => {
    if (grade === "A+") {
      return <img src="assets/img/scoreA+.png" width="100%" alt="" />;
    } else if (grade === "A") {
      return <img src="assets/img/scoreA.png" width="100%" alt="" />;
    } else if (grade === "B+") {
      return <img src="assets/img/scoreB+.png" width="100%" alt="" />;
    } else if (grade === "B") {
      return <img src="assets/img/scoreB.png" width="100%" alt="" />;
    } else if (grade === "C+") {
      return <img src="assets/img/scoreC+.png" width="100%" alt="" />;
    } else if (grade === "C") {
      return <img src="assets/img/scorec.png" width="100%" alt="" />;
    } else if (grade === "D+") {
      return <img src="assets/img/scoreD+.png" width="100%" alt="" />;
    } else if (grade === "D") {
      return <img src="assets/img/scoreD.png" width="100%" alt="" />;
    }
  };
  const handleperformance = (grade) => {
    if (grade === "A+") {
      return <h3 class="bold green-text">Excellent</h3>;
    } else if (grade === "A") {
      return <h3 class="bold green-text">Excellent</h3>;
    } else if (grade === "B+") {
      return <h3 class="bold orange-text">Very Good</h3>;
    } else if (grade === "B") {
      return <h3 class="bold orange-text">Very Good</h3>;
    } else if (grade === "C+") {
      return <h3 class="bold light-red-text">Good</h3>;
    } else if (grade === "C") {
      return <h3 class="bold light-red-text">Good</h3>;
    } else if (grade === "D+") {
      return <h3 class="bold red-text">Average</h3>;
    } else if (grade === "D") {
      return <h3 class="bold red-text">Average</h3>;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!TestName) {
      setTestNameError("Please enter test name ");
    } else if (TestName) {
      setTestNameError("");
    }
    if (!school) {
      setSchoolError("Please enter institute ");
    } else if (school) {
      setSchoolError("");
    }
    if (!maxScore) {
      setmaxScoreError("Please enter max score ");
    } else if (maxScore) {
      setmaxScoreError("");
    }
    if (!scoreReceived) {
      setscoreReceivedError("Please enter score received ");
    } else if (scoreReceived) {
      setscoreReceivedError("");
    }
    if (!grade) {
      setGradeError("Please select grade ");
    } else if (grade) {
      setGradeError("");
    }

    if (TestName && school && maxScore && scoreReceived && grade) {
      let data = {
        test: TestName,
        institute: school,
        max_score: maxScore,
        score_received: scoreReceived,
        grade: grade,
        page_id: pageId,
      };

      try {
        let resp = "";

        if (Id) {
          resp = await UpdateAPi(Endpoints.UpdateTestScore + "/" + Id, data);
        } else {
          resp = await CommonAPi(Endpoints.CreateTestScore, data);
        }
        if (resp && resp.message) {
          $("#Testmodal").modal("hide");
          setSchool("");
          setGrade("");
          setscoreReceived("");
          setmaxScore("");
          setTestName("");
          GetTestScore("");
          setSchoolName("");
          setInstituteNameKey(Math.ceil(Math.random() * 999999));
          setId("");
          setOptions([]);
          setpageId(0);
          setSchoolData("");
          ToastMessage.Success(resp.message);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleCloseModel = () => {
    if (
      schoolName !== "" ||
      school !== "" ||
      grade !== "" ||
      scoreReceived !== "" ||
      maxScore !== "" ||
      TestName !== ""
    ) {
      $("#discardInfoModal-TestScore").modal("show");
    }
  };

  const handleDisCard = () => {
    setSchool("");
    setGrade("");
    setscoreReceived("");
    setmaxScore("");
    setTestName("");
    GetTestScore("");
    setId("");
    setOptions([]);
    setpageId(0);
    setSchoolData("");
    setSchoolName("");
    setInstituteNameKey(Math.ceil(Math.random() * 999999));
    setSchoolError("");
    setGradeError("");
    setscoreReceivedError("");
    setmaxScoreError("");
    setTestNameError("");
    $("#discardInfoModal-TestScore").modal("hide");
    $("#Testmodal").modal("hide");
  };

  const handleModel = () => {
    $("#discardInfoModal-TestScore").modal("hide");
    $("#Testmodal").modal("show");
  };

  const handleDelete = async (Id) => {
    try {
      let resp = await DeleteApi(Endpoints.DeleteTestScore + "/" + Id);
      if (resp && resp.message) {
        GetTestScore();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleEdit = async (Id) => {
    try {
      let resp = await FetchApi(Endpoints.GetSingleTestScore + "/" + Id);
      if (resp && resp.message) {
        setSchool(resp.data.institute);
        setSchoolName(resp.data.institute);
        setInstituteNameKey(resp.data.institute);
        setOptions([
          {
            name: resp.data.page.title,
            id: resp.data.page.id,
            image: resp.data.page.pageImage,
          },
        ]);
        setGrade(resp.data.grade);
        setscoreReceived(resp.data.scoreReceived);
        setmaxScore(resp.data.maxScore);
        setTestName(resp.data.test);
        setId(Id);
        if (resp.data.pageId === null) {
          setpageId(0);
        } else {
          setpageId(resp.data.pageId);
        }
        $("#Testmodal").modal("show");
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleValidation = (e, value, field) => {
    e.preventDefault();
    if (!value && field === "testName") {
      setTestNameError("Please enter test name ");
    } else if (value && field === "testName") {
      setTestNameError("");
    }
    if (!value && field === "institute") {
      if (!schoolData) {
        setSchoolError("Please enter institute ");
      } else {
        setSchoolError("");
      }
    } else if (value && field === "institute") {
      setSchoolError("");
    }
    if (!value && field === "maxScore") {
      setmaxScoreError("Please enter max score ");
    } else if (value && field === "maxScore") {
      setmaxScoreError("");
    }
    if (!value && field === "scoreReceived") {
      setscoreReceivedError("Please enter score received ");
    } else if (value && field === "scoreReceived") {
      setscoreReceivedError("");
    }
    if (!value && field === "grade") {
      setGradeError("Please select grade ");
    } else if (value && field === "grade") {
      setGradeError("");
    }
  };

  const RenderInstituteName = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example"
        defaultInputValue={schoolName}
        key={instituteNameKey}
        onSearch={handleOnSearch}
        onChange={handleOnSelect}
        onBlur={(e) => {
          handleValidation(e, e.target.value, "institute");
        }}
        options={options}
        placeholder="Search by institute"
        labelKey="name"
        minLength={1}
        emptyLabel={"Searching..."}
        className={
          schoolError
            ? "is-danger active text-gray px-1 py-1 border"
            : " active text-gray px-1 py-1 border"
        }
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <img
              alt={option.name}
              src={
                option.image
                  ? Endpoints.baseUrl + option.image
                  : "../assets/img/icon/briefcase.png"
              }
              style={{
                height: "24px",
                marginRight: "10px",
                width: "24px",
              }}
            />
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [schoolName, options, schoolError, instituteNameKey]);

  return (
    <Container header>
      <Helmet>
        <title>Test Scores</title>
      </Helmet>
      <div class="wrapper wrapper-bg">
        <div class="main">
          <LeftNavigation />
          <div class="container py-3">
            <div class="bg-white main-common">
              <div class="main-top-row d-flex justify-content-between align-items-center pb-3">
                <h3 class="medium">Test Scores</h3>

                <div>
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#Testmodal"
                    class="btn btn-br-blue"
                  >
                    Add Test Score +
                  </a>
                </div>
              </div>

              {testScoreData.length > 0 ? (
                <div class="row mainmenu-filter-conatiner">
                  {testScoreData.map((item, index) => {
                    return (
                      <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                        <div class="score-item position-relative">
                          <div className="mainmenu-item-action position-absolute end-0 m-2 p-1 text-right">
                            <Link
                              id="notification"
                              className="d-flex align-items-center justify-content-center rounded-circle bg-white p-1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <svg
                                viewBox="0 0 24 24"
                                width="22"
                                height="22"
                                stroke="#000"
                                strokeWidth="2"
                                fill="none"
                                stroke-linecap="round"
                                strokeLinejoin="round"
                                className="css-i6dzq1"
                              >
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="19" cy="12" r="1"></circle>
                                <circle cx="5" cy="12" r="1"></circle>
                              </svg>
                            </Link>
                            <div
                              className="dropdown-menu haspopup fs-12 dropdown-menu-end"
                              aria-labelledby="notification"
                            >
                              <ul>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteInfoModal-TestScore"
                                    onClick={() => {
                                      setItem(item.id);
                                    }}
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="14"
                                      height="14"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      fill="none"
                                      stroke-linecap="round"
                                      strokeLinejoin="round"
                                      className="css-i6dzq1"
                                    >
                                      <polyline points="3 6 5 6 21 6"></polyline>
                                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                      <line
                                        x1="10"
                                        y1="11"
                                        x2="10"
                                        y2="17"
                                      ></line>
                                      <line
                                        x1="14"
                                        y1="11"
                                        x2="14"
                                        y2="17"
                                      ></line>
                                    </svg>
                                    &nbsp; Delete
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => handleEdit(item.id)}
                                  >
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="14"
                                      height="14"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      fill="none"
                                      stroke-linecap="round"
                                      strokeLinejoin="round"
                                      class="css-i6dzq1"
                                    >
                                      <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                    &nbsp; Edit
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {handleGrade(item.grade)}

                          <div class="px-3 pb-3 text-center">
                            {handleperformance(item.grade)}
                            <h4 class="text-darkgray py-2">
                              <b>{item.scoreReceived}</b> / {item.maxScore}
                            </h4>
                            <p class="m-0 fs-12 pb-1">
                              Total Marks Obtained in
                            </p>
                            <h5 class="semibold pb-1">{item.test}</h5>

                            {/* <div class="d-flex align-items-center justify-content-center py-2">
                              <Link to={"/page-profile/" + item?.page?.slug}>
                                <span
                                  style={{
                                    width: "48px",
                                    height: "48px",
                                    display: "inline-block",
                                  }}
                                >
                                  {item?.page?.page === null ? (
                                    <img
                                      src="../assets/img/icon/briefcase.png"
                                      alt=""
                                      width="48"
                                      height="48"
                                      class="rounded-circle"
                                    />
                                  ) : item?.page.pageImage === null ? (
                                    <img
                                      src="../assets/img/icon/briefcase.png"
                                      alt=""
                                      width="48"
                                      height="48"
                                      class="rounded-circle"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        Endpoints.baseUrl + item?.page.pageImage
                                      }
                                      alt=""
                                      width="48"
                                      height="48"
                                      class="rounded-circle"
                                    />
                                  )}
                                </span>
                              </Link>

                              <div class="ps-2 text-start">
                                <Link to={"/page-profile/" + item?.page?.slug}>
                                  <h5 class="medium fs-14">{item.institute}</h5>
                                </Link>
                                <h6 class="text-darkgray fs-10">
                                  {item?.page?.pageType}
                                </h6>
                              </div>
                            </div> */}
                            <div className="d-flex align-items-center justify-content-center py-2">
                              <Link
                                to={
                                  item?.page
                                    ? "/page-profile/" + item?.page?.slug
                                    : "#"
                                }
                              >
                                <span
                                  style={{
                                    width: "48px",
                                    height: "48px",
                                    display: "inline-block",
                                  }}
                                >
                                  {item?.page?.pageImage ? (
                                    <img
                                      src={
                                        Endpoints.baseUrl +
                                        item?.page?.pageImage
                                      }
                                      alt=""
                                      width="48"
                                      height="48"
                                      className="rounded-circle"
                                    />
                                  ) : (
                                    <img
                                      src="../assets/img/icon/briefcase.png"
                                      alt=""
                                      width="48"
                                      height="48"
                                      className="rounded-circle"
                                    />
                                  )}
                                </span>
                              </Link>

                              <div className="ps-2 text-start">
                                <Link
                                  to={
                                    item?.page
                                      ? "/page-profile/" + item?.page?.slug
                                      : "#"
                                  }
                                >
                                  <h5 className="medium fs-14">
                                    {item?.institute || "Unknown Institute"}
                                  </h5>
                                </Link>
                                <h6 className="text-darkgray fs-10">
                                  {item?.page?.pageType || "Unknown Type"}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="row">No test score added</div>
              )}
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
      <div
        class="modal fade"
        id="Testmodal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog rounded-10 box-shadow p-lg-4 p-md-3 p-2 bg-white modal-650p">
          <div class="modal-content border-0">
            <div class="d-flex align-items-center justify-content-between pb-3 border-bottom mb-3 w-100">
              <h3 class="medium text-blue">
                {" "}
                {Id ? "Edit Test Score" : "Add New Test Score"}
              </h3>

              <button
                type="button"
                class="btn-close kids-close-icon TestScore cursor-pointer"
                onClick={handleCloseModel}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#949494"
                  width="22"
                  height="22"
                >
                  <path
                    stroke-linecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="modal-body">
              <h4 class="medium text-black pb-3">
                Fill the form below to {Id ? "Edit" : "create New "} Test Score
              </h4>

              <form onSubmit={handleSubmit} class="kids-form generic-form">
                <div class="row">
                  <div class="col-md-6 mb-2">
                    <label>Test Name</label>
                    <input
                      type="text"
                      placeholder="Test name"
                      onBlur={(e) => {
                        handleValidation(e, e.target.value, "testName");
                      }}
                      className={
                        TestNameError
                          ? "is-danger  text-gray px-2 py-2 border"
                          : " text-gray px-2 py-2 border"
                      }
                      value={TestName}
                      onChange={(e) => {
                        setTestName(e.target.value);
                      }}
                    />
                    {TestNameError ? (
                      <div
                        className="form-error"
                        style={{ color: "white", margintop: "-10px" }}
                      >
                        {TestNameError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div class="col-md-6 mb-3">
                    <label>Max Score</label>
                    <input
                      type="text"
                      placeholder="Max Score"
                      onBlur={(e) => {
                        handleValidation(e, e.target.value, "maxScore");
                      }}
                      className={
                        maxScoreError
                          ? "is-danger  text-gray px-2 py-2 border"
                          : " text-gray px-2 py-2 border"
                      }
                      value={maxScore}
                      onChange={(e) => {
                        setmaxScore(e.target.value);
                      }}
                    />
                    {maxScoreError ? (
                      <div
                        className="form-error"
                        style={{ color: "white", margintop: "-10px" }}
                      >
                        {maxScoreError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="col-md-6 mb-3">
                    <label>Score Received</label>
                    <input
                      type="text"
                      placeholder="Score Received"
                      onBlur={(e) => {
                        handleValidation(e, e.target.value, "scoreReceived");
                      }}
                      className={
                        scoreReceivedError
                          ? "is-danger  text-gray px-2 py-2 border"
                          : " text-gray px-2 py-2 border"
                      }
                      value={scoreReceived}
                      onChange={(e) => {
                        setscoreReceived(e.target.value);
                      }}
                    />
                    {scoreReceivedError ? (
                      <div
                        className="form-error"
                        style={{ color: "white", margintop: "-10px" }}
                      >
                        {scoreReceivedError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div class="col-md-6 mb-3">
                    <label>Grade</label>
                    <select
                      onBlur={(e) => {
                        handleValidation(e, e.target.value, "grade");
                      }}
                      className={
                        gradeError
                          ? "is-danger  text-gray px-2 py-2 border"
                          : " text-gray px-2 py-2 border"
                      }
                      onChange={(e) => {
                        setGrade(e.target.value);
                      }}
                    >
                      <option value="">Select Grade</option>
                      {Grade.map((item, i) => {
                        return (
                          <option
                            key={i}
                            selected={grade === item.value ? true : false}
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                    {gradeError ? (
                      <div
                        className="form-error"
                        style={{ color: "white", margintop: "-10px" }}
                      >
                        {gradeError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div class="col-md-12 mb-3">
                    <label>Educational Institution</label>

                    {RenderInstituteName()}
                    {schoolError ? (
                      <div
                        className="form-error"
                        style={{ color: "white", margintop: "-10px" }}
                      >
                        {schoolError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="col-md-12 text-end">
                    <button
                      disabled={isDisabled}
                      type="submit"
                      class="btn fs-14 "
                    >
                      {" "}
                      {Id ? "Update" : "Create"} Test Score{" "}
                      <img
                        src="../assets/img/icon/icon-right-arrow.svg"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <DeletePrompt type={"TestScore"} item={item} deleteType={handleDelete} />
      <DiscardPopup
        {...{
          type: "TestScore",
          onSuccess: handleModel,
          onDisCard: handleDisCard,
        }}
      />
    </Container>
  );
}

export default TestScores;
