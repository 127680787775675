import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import Swal from "sweetalert2";
import { CommonAPi } from "../../API/CommonApi";
import { PatchApi } from "../../API/PatchApi";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import ToastMessage from "../../Utils/ToastMessage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link, useNavigate, useParams } from "react-router-dom";
import { EditorState, convertToRaw } from "draft-js";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createMentionPlugin from "@draft-js-plugins/mention";
import createEmojiPlugin, { defaultTheme } from "@draft-js-plugins/emoji";

import "@draft-js-plugins/mention/lib/plugin.css";
import "@draft-js-plugins/emoji/lib/plugin.css";
import "@draft-js-plugins/linkify/lib/plugin.css";
import "./Blog.css";
import FriendsSpecificModal from "../../Components/FriendsSpecificModal";
import EntryComponent from "../../Components/PostShare/EntryComponent";
import "../../Components/PostShare/PostShare.css";
import PostTitleDraftEdit from "../../Components/Post/PostTitleDraftEdit";
import EmojiPicker from "emoji-picker-react";
import { Helmet } from "react-helmet";
const $ = window.jQuery;
const postVisibilites = [
  {
    name: "Friends",
    value: "Friends",
    image: "../assets/img/icon/group-friend.svg",
    text: "Your connections on 8inNet",
  },
  {
    name: "Public",
    value: "Public",
    image: "../assets/img/icon/public.svg",
    text: "Anyone on or off 8inNet",
  },
  {
    name: "Friends Specific",
    value: "Friends Specific",
    image: "../assets/img/icon/friend.svg",
    text: "Only Show to some friends",
  },
];

export const CustomHastagComp = (hashtagProps) => {
  console.log({ hashtagProps });
  return (
    <a
      href={hashtagProps.mention.link}
      style={{
        padding: "3px 5px",
        backgroundColor: "white",
        color: "var(--blue)",
      }}
    >
      {hashtagProps.children}
    </a>
  );
};
const EditBlog = () => {
  const [headline, setHeadline] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [description, setDescription] = useState("");

  const imageRef = useRef();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const inputRef = useRef(null);
  const modalRef = useRef(null);
  const [postVisibility, setPostVisibility] = useState("Friends");
  const [isPublishing, setIsPublishing] = useState(false);
  const [blogCaption, setBlogCaption] = useState("");
  const navigate = useNavigate();
  const [specificFrined, setSpecificFrined] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const param = useParams();
  const userProfile = localStorage.getItem("userProfile");
  const user = JSON.parse(localStorage.getItem("user"));
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [postTitle, setPostTitle] = useState("");
  const [id, setId] = useState("");
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const emojiRef = useRef(null);

  const handleContentChange = (newContent) => {
    // if(newContent){
    //   setEditorState(newContent);
    //   setPostTitle(newContent?.getCurrentContent()?.getPlainText());
    // }
  };

  // mention plugin implementation
  const [mentionOpen, setMentionOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  // hashtag plugin implementation
  const [hashtagOpen, setHashtagOpen] = useState(false);
  const [hashtagSuggestions, setHashtagSuggestions] = useState([]);

  const {
    MentionSuggestions,
    plugins,
    EmojiSuggestions,
    EmojiSelect,
    HashtagSuggestions,
  } = useMemo(() => {
    // @ mention
    const mentionPlugin = createMentionPlugin({
      mentionTrigger: ["@"],
      mentionPrefix: "",
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // # hashtag
    const hashtagPlugin = createMentionPlugin({
      mentionTrigger: ["#"],
      mentionComponent: CustomHastagComp,
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions: HashtagSuggestions } = hashtagPlugin;

    const emojiPlugin = createEmojiPlugin({
      theme: {
        ...defaultTheme,
        emojiSelect: "custom-emoji-select",
        emojiSelectButton: "custom-emoji-select-button",
        emojiSelectPopover:
          defaultTheme.emojiSelectPopover + " custom-emoji-select-popover",
      },
      selectButtonContent: (
        <>
          <img
            className="post-image"
            src="../assets/img/icon/emoji_icon.png"
            alt=""
          />
          Emoji
        </>
      ),
    });
    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

    const linkify = createLinkifyPlugin();

    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin, emojiPlugin, hashtagPlugin, linkify];
    return {
      plugins,
      MentionSuggestions,
      EmojiSuggestions,
      EmojiSelect,
      HashtagSuggestions,
    };
  }, []);
  const onOpenChange = useCallback((_open) => {
    setMentionOpen(_open);
  }, []);
  const onSearchChange = useCallback(({ value }) => {
    CommonAPi(Endpoints.MentionSearch + "?mention=" + value).then((res) => {
      if (res.data) {
        // setSuggestions(
        const userFriends = res?.data?.userFriends.map((item) => ({
          name: item.fullName,
          avatar: Endpoints.baseUrl + item.user_profile.profileImg,
          link: `/timeline/${item.username}`,
          id: `${item.id}`,
          type: "profile",
        }));
        const group = res?.data?.group.map((item) => ({
          name: item.groupName,
          avatar: Endpoints.baseUrl + item.logo,
          link: `/group-about/${item.slug}`,
          id: `${item.id}`,
          type: "group",
        }));
        const page = res?.data?.page.map((item) => ({
          name: item.title,
          avatar: Endpoints.baseUrl + item.pageImage,
          link: `/page-profile/${item.slug}`,
          id: `${item.id}`,
          type: "page",
        }));
        setSuggestions([...userFriends, ...group, ...page]);

        // );
      }
    });
    // setSuggestions(defaultSuggestionsFilter(value, mentions));
  }, []);
  const onHashtagOpenChange = useCallback((_open) => {
    setHashtagOpen(_open);
  }, []);
  const onHashtagSearchChange = useCallback(({ value }) => {
    CommonAPi(Endpoints.searchHashtag + value).then((res) => {
      if (res.data) {
        let mappedResponse = res.data.map((item) => ({
          name: `#${item.tag}`,
          link: `/singlehashtag?tag=${item.tag}`,
        }));
        const index = mappedResponse.findIndex(
          (item) =>
            item.name.trim().slice(1).toLowerCase() ===
            value.trim().toLowerCase()
        );
        if (value && index === -1)
          mappedResponse = [
            { name: `#${value}`, link: `/singlehashtag?tag=${value}` },
            ...mappedResponse,
          ];
        setHashtagSuggestions(mappedResponse);
      }
    });
    // setHashtagSuggestions(defaultSuggestionsFilter(value, hashtags));
  }, []);

  const handleEmojiClick = (emojiData, MouseEvent) => {
    // setSelectedEmoji(emojiData.unified);
    // setPostTitle((prev) => prev + emojiData.emoji);
    console.log("shubham____post___emoji", emojiData?.emoji);
    // setPostTitle((prev) => JSON.parse(value) +  emojiData.emoji);
    // setPostTitle((prev) => console.log("shubham____post___prev",prev));
  };

  useEffect(() => {
    if (headline && description) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [headline, description]);

  useEffect(() => {
    handleGetBlogData();
  }, [param?.id]);

  const handleGetBlogData = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetSinglePost + "/" + param?.id);
      if (resp && resp.success === true) {
        setHeadline(resp?.data?.blogTitle);
        setTitle(resp?.data?.title);
        setPostTitle(resp?.data?.title);
        setDescription(resp?.data?.description);
        setImagePreview(resp?.data?.post_medias[0]?.filePath);
        setBlogCaption(resp?.data?.blogCaption);
        setPostVisibility(resp?.data?.postVisibility);
        setId(resp?.data?.id);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        ToastMessage.Error(e.response.data.message);
      }
    }
  };

  const handleBlogUpload = async (e) => {
    setIsPublishing(true);
    e.preventDefault();
    if (!headline || !description) {
      ToastMessage.Error("PLEASE ENTER BLOG HEADLINE AND DESCRIPTION");
      setIsPublishing(false);
      return;
    } else {
      const formData = new FormData();
      formData.append("blog_title", headline);
      if (image) {
        formData.append("media", image);
      }
      formData.append("title", postTitle);
      //   formData.append("title", title);
      formData.append("blog_caption", blogCaption);
      formData.append("category", "Blog");
      formData.append("description", description);
      formData.append("post_visibility", postVisibility);
      formData.append("id", id);
      if (postVisibility === "Friends Specific") {
        specificFrined.forEach((item) =>
          formData.append("specificFriend", item)
        );
      }
      try {
        let resp = await PatchApi(Endpoints.UpdatePost, formData);
        if (resp && resp.success === true) {
          setHeadline("");
          setImage("");
          modalRef.current.click();
          imageRef.current.value = "";
          setDescription("");
          navigate("/mainfeed");
        }
      } catch (e) {
        setIsPublishing(false);
        console.log(e.response);
        if (e && e.response && e.response.data && e.response.data.message) {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleImageSelection = (e) => {
    let image = e.target.files[0];
    console.log("image+_________", image);
    if (
      image.type === "image/png" ||
      image.type === "image/jpg" ||
      image.type === "image/jpeg" ||
      image.type === "image/webp"
    ) {
      setImage(image);
    }
  };

  const openPopup = () => {
    if (headline) {
      setShowModal(true);
    }
  };

  const handlePrivacyStatus = (status) => {
    setPostVisibility(status);
    if (status === "Friends Specific") {
      $("#FriendsSpecificModal").modal("show");
      setShowModal(false);
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Edit Blog</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />

          <div className="container py-3">
            <div className="bg-white rounded-10 shadow-lg">
              <div className="py-3 px-3 row align-items-center">
                <div className="col-md-3">
                  <Link to={"/mainfeed"} class="btn px-4">
                    <svg
                      viewBox="0 0 24 24"
                      width="16"
                      height="16"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      class="css-i6dzq1"
                    >
                      <line x1="19" y1="12" x2="5" y2="12"></line>
                      <polyline points="12 19 5 12 12 5"></polyline>
                    </svg>{" "}
                    Back
                  </Link>
                </div>
                <div className="col-md-9">
                  <p class="medium" style={{ margin: "0px 0px 0px 200px" }}>
                    Edit Blog
                  </p>
                  {/* <h2 className='text-dark text-center'>Create Blog</h2> */}
                </div>
              </div>

              <div className="border-top py-md-5 px-md-5 p-3">
                <form
                  encType="multipart/form-data"
                  className="d-flex flex-column justify-content-between "
                >
                  <div className="form-group">
                    <label htmlFor="headline" className="blog-label">
                      Headline
                    </label>
                    <input
                      type="text"
                      placeholder="e.g. Blog about your latest products or deals"
                      id="headline"
                      className="form-control p-3"
                      value={headline}
                      onChange={(e) => {
                        setHeadline(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group my-5 editor-container">
                    <label htmlFor="description" className="blog-label">
                      Description
                    </label>

                    <CKEditor
                      data={description}
                      className="editor text-gray px-3 py-3 border"
                      id="description"
                      cols="6"
                      rows="6"
                      onChange={(event, editor) => {
                        setDescription(editor.getData());
                      }}
                      editor={ClassicEditor}
                      config={{
                        toolbar: [
                          "bold",
                          "italic",
                          "underline",
                          "blockQuote",
                          "link",
                          "numberedList",
                          "bulletedList",
                          "|",
                          "undo",
                          "redo",
                        ],
                      }}
                    />
                  </div>
                  <div className="form-group blog-image-wrapper">
                    <div className="blog-image-container d-flex align-items-center justify-content-center">
                      <input
                        type="file"
                        ref={imageRef}
                        onChange={(e) => {
                          handleImageSelection(e);
                        }}
                        className="form-control d-none"
                        accept=".jpg, .jpeg, .png"
                      />
                      {image == null && imagePreview == null ? (
                        <div
                          className="d-flex flex-column blog-image-btn"
                          onClick={() => {
                            imageRef.current.click();
                          }}
                        >
                          <span className="blog-add-image-btn px-2 text-center py-1">
                            Add Cover Image
                          </span>
                          {/* <span className='text-secondary'>Recommended Dimensions 1200 x 780</span> */}
                        </div>
                      ) : (
                        ""
                      )}
                      {image && (
                        <div className="image-preview-blog">
                          <img
                            src={URL.createObjectURL(image)}
                            alt="preview-img"
                            className=""
                          />
                          <svg
                            height="20px"
                            id="Layer_1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setImage(null);
                              setImagePreview(null);
                              imageRef.current.value = "";
                            }}
                            version="1.1"
                            viewBox="0 0 512 512"
                            width="20px"
                          >
                            <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                          </svg>
                        </div>
                      )}

                      {imagePreview && (
                        <div className="image-preview-blog">
                          <img
                            src={Endpoints.baseUrl + imagePreview}
                            alt="preview-img"
                            className=""
                          />
                          <svg
                            height="20px"
                            id="Layer_1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setImagePreview(null);
                              setImage(null);
                              imageRef.current.value = "";
                            }}
                            version="1.1"
                            viewBox="0 0 512 512"
                            width="20px"
                          >
                            <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                  {(image || imagePreview) && (
                    <div className="fs-14 blog-caption">
                      <input
                        type="text"
                        value={blogCaption}
                        onChange={(e) => {
                          setBlogCaption(e.target.value);
                        }}
                        placeholder="Add credit and caption"
                        className="text-center py-2"
                      />
                    </div>
                  )}
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#kidmodal"
                    disabled={isDisabled}
                    onClick={openPopup}
                    className=" blog-publish-btn btn fs-14 w-25 align-self-center my-3 mt-5 publish-btn"
                  >
                    Publish
                  </button>
                </form>
              </div>
            </div>
          </div>
          {/* {showModal ?  */}
          <div
            class="modal fade"
            id="kidmodal"
            tabIndex="-1"
            aria-labelledby="kidmodal"
            aria-hidden="true"
            style={{ overflow: "auto" }}
          >
            <div class="modal-dialog rounded-10 box-shadow p-lg-4 pb-lg-3 p-md-3 p-2 bg-white mt-md-5">
              <div class="modal-content border-0">
                <div class="d-flex align-items-center justify-content-between pb-3 border-bottom mb-1 w-100">
                  <h3 class="medium text-blue">Edit Blog</h3>
                  <button
                    type="button"
                    class="btn-close kids-close-icon cursor-pointer"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={modalRef}
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="#949494"
                      width="22"
                      height="22"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <div class="modal-body pb-0">
                  <form
                    action=""
                    class="kids-form"
                    enctype="multipart/form-data"
                    onSubmit={handleBlogUpload}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <img
                          src={Endpoints.baseUrl + userProfile}
                          alt=""
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                          }}
                        />
                        <span
                          className="fs-14"
                          style={{ fontWeight: "500", marginLeft: "5px" }}
                        >
                          {user.fullName}
                        </span>
                      </div>
                      <div
                        className="privacy-box fs-12"
                        style={{ marginTop: "-20px" }}
                      >
                        <span className="ms-auto d-flex align-items-center">
                          <div className=" w-100">
                            <Link
                              class="dropdown-toggle w-100"
                              type="button"
                              id="defaultDropdown"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="true"
                              aria-expanded="false"
                            >
                              <img
                                src="../assets/img/icon/icon-privacy.svg"
                                alt="privacy"
                              />{" "}
                              {postVisibility}
                            </Link>
                            <ul
                              class="dropdown-menu dropdown-menu-end"
                              aria-labelledby="defaultDropdown"
                            >
                              {postVisibilites.map((postVisible, i) => (
                                <li
                                  key={postVisible.name}
                                  className={
                                    postVisibility == postVisible.value
                                      ? "audience-status py-2"
                                      : "py-2"
                                  }
                                >
                                  <Link
                                    class="dropdown-item py-2 d-flex gap-2"
                                    onClick={() => {
                                      handlePrivacyStatus(postVisible.value);
                                    }}
                                  >
                                    <div className="d-flex gap-3">
                                      <img
                                        src={postVisible?.image}
                                        alt="privacy"
                                        style={{
                                          width: "32px",
                                        }}
                                      />
                                      <span className="fs-16">
                                        {postVisible.name}
                                        <br />
                                        <small className="fs-12">
                                          {postVisible.text}
                                        </small>
                                      </span>
                                    </div>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        if (inputRef.current) inputRef.current.focus();
                      }}
                      className="py-3 post-editor-container postEditor"
                      style={{ cursor: "text" }}
                    >
                      {postTitle ? (
                        <PostTitleDraftEdit
                          title={postTitle}
                          onChange={(value) => {
                            console.log("shubham____value", JSON.parse(value));
                            setPostTitle(value);
                            // handleContentChange(value)
                          }}
                        />
                      ) : (
                        ""
                      )}

                      <MentionSuggestions
                        open={mentionOpen}
                        onOpenChange={onOpenChange}
                        suggestions={suggestions}
                        onSearchChange={onSearchChange}
                        entryComponent={EntryComponent}
                        onAddMention={() => {
                          // get the mention object selected
                        }}
                      />
                      <HashtagSuggestions
                        open={hashtagOpen}
                        onOpenChange={onHashtagOpenChange}
                        suggestions={hashtagSuggestions}
                        onSearchChange={onHashtagSearchChange}
                        entryComponent={EntryComponent}
                        onAddMention={() => {
                          // get the mention object selected
                        }}
                      />
                      <EmojiSuggestions />
                    </div>
                    <div className="status-attachment d-flex pb-2 border-top pt-2 fs-12 mb-0 ">
                      <span
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <EmojiSelect />

                        {emojiPickerOpen && (
                          <EmojiPicker
                            onEmojiClick={handleEmojiClick}
                            ref={emojiRef}
                            lazyLoadEmojis={true}
                          />
                        )}
                      </span>
                    </div>
                    {imagePreview ? (
                      <div className="mt-3">
                        <div className="d-flex w-100 blog-pop-up">
                          <img
                            src={Endpoints.baseUrl + imagePreview}
                            alt="preview-img"
                            className="w-100 rounded"
                          />
                        </div>

                        <div
                          className="blog-title"
                          style={{ wordBreak: "break-all" }}
                        >
                          {headline}
                        </div>
                      </div>
                    ) : (
                      <div className="mt-3">
                        {image && (
                          <div className="d-flex w-100 blog-pop-up">
                            <img
                              src={URL.createObjectURL(image)}
                              alt="preview-img"
                              className="w-100 rounded"
                            />
                          </div>
                        )}

                        <div
                          className="blog-title"
                          style={{ wordBreak: "break-all" }}
                        >
                          {headline}
                        </div>
                      </div>
                    )}

                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        disabled={isPublishing}
                        className="btn btn-primary"
                      >
                        {isPublishing === true ? "Posting.." : "Update Blog"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* : ""} */}

          <OnlineFriends />
        </div>
      </div>
      <FriendsSpecificModal
        {...{
          setShowModal: setShowModal,
          setPostVisibility: setPostVisibility,
          specificFrined: specificFrined,
          setSpecificFrined: setSpecificFrined,
        }}
      />
    </Container>
  );
};

export default EditBlog;
