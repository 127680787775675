import React from "react";
import HonorAwards from "./Pages/HonorAwards";
import Landing from "./Pages/Landing";
import MainFeed from "./Pages/MainFeed";
import ProfileCompany from "./Pages/ProfileCompany";
import ProfileFriend from "./Pages/ProfileFriend/Friend";
import ProfileSchool from "./Pages/ProfileSchool";

import ProfileTimeline from "./Pages/ProfileTimeline";
import ProfileUniversity from "./Pages/ProfileUniversity";
import ProfileUpdate from "./Pages/ProfileUpdate";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signup from "./Pages/Signup";
import Courses from "./Pages/Courses";
import Signin from "./Pages/Signin";
import LoginWithOtp from "./Pages/LoginWithOtp";
import LoginWithPassword from "./Pages/LoginWithPassword";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import VerifyEmail from "./Pages/VerifyEmail";
import TeachersMenu from "./Pages/TeachersMenu";
import PagesMenu from "./Pages/PagesMenu";
import Hashtags from "./Pages/Hashtags";
import Groups from "./Pages/Groups";
import GratitudeWall from "./Pages/GratitudeWall";
import TestScores from "./Pages/TestScores";
import UniversityMenu from "./Pages/UniversityMenu";
import SingleHashtag from "./Pages/Singlehashtag";
import Internship from "./Pages/Internship";
import Jobs from "./Pages/Jobs";
import SchoolsMenu from "./Pages/SchoolsMenu";
import AllNotifications from "./Pages/AllNotifications";
import SelectProfile from "./Pages/SelectProfile";
import CompleteProfile from "./Pages/CompleteProfile";
import ImageUpload from "./Pages//CompleteProfile/ImageUpload";
import Academics from "./Pages/CompleteProfile/Academics";
import Interests from "./Pages/CompleteProfile/Interests";
import Verification from "./Pages/CompleteProfile/Verification";
import ProfileAbout from "./Pages/ProfileAbout";
import BasicInfo from "./Pages/Teachers/Courses/BasicInfo";
import AdvanceInformation from "./Pages/Teachers/Courses/AdvanceInformation";
import Curriculum from "./Pages/Teachers/Courses/Curriculum";
import PublishCourse from "./Pages/Teachers/Courses/PublishCourse";
import TeacherDashboard from "./Pages/Dashboard/Teacher/TeacherDashboard";
import MyCourses from "./Pages/Dashboard/Teacher/MyCourses";
import MyCoursesStudent from "./Pages/Dashboard/Student/MyCourses";
import Earning from "./Pages/Dashboard/Teacher/Earning";
import CourseDetail from "./Pages/Teachers/Courses/CourseDetail";
import CoursesDetail from "./Pages/Dashboard/Teacher/CoursesDetail";
import CoursesPreview from "./Pages/Dashboard/Teacher/CoursesPreview";
import BecomeInstructor from "./Pages/BecomeInstructor";

import Blog from "./Pages/Blog/Blog";
import SingleBlog from "./Pages/BlogPage/SingleBlog";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import Listing from "./Pages/Courses/Listing";
import ReturnURL from "./Pages/Courses/return";
import Cart from "./Pages/Cart/Cart";
import Checkout from "./Pages/Checkout/Checkout";
import CheckoutSuccess from "./Pages/Checkout/CheckoutSuccess";
import PaymentFailed from "./Pages/Checkout/PaymentFailed";
import Messages from "./Pages/Dashboard/Student/Messages";
import StudentDashboard from "./Pages/Dashboard/Student/StudentDashboard";
import Teacher from "./Pages/Dashboard/Student/Teacher";
import Wishlist from "./Pages/Dashboard/Student/Wishlist";
import Purachase from "./Pages/Dashboard/Student/Purachase";
import TeacherProfile from "./Pages/Teachers/TeacherProfile";
import Learning from "./Pages/Dashboard/Student/Learning";
import FriendRequest from "./Pages/FriendRequest";
import Teachers from "./Pages/ProfileFriend/Teachers";
import SelectPageType from "./Pages/CreatePages/SelectPageType";
import PageForm from "./Pages/CreatePages/PageForm";
import CompanyAbout from "./Pages/ProfileCompany/CompanyAbout";
import CompanyJobs from "./Pages/ProfileCompany/CompanyJobs";
import CompanyPost from "./Pages/ProfileCompany/CompanyPost";
import CompanyPeople from "./Pages/ProfileCompany/CompanyPeople";
import CompanyFollowers from "./Pages/ProfileCompany/CompanyFollowers";
import CompanyVideo from "./Pages/ProfileCompany/CompanyVideo";
import CompanyMenu from "./Pages/CompanyMenu";
import Photo from "./Pages/ProfileMedia/Photo";
import Video from "./Pages/ProfileMedia/Video";
import CreateAlbums from "./Pages/ProfileMedia/CreateAlbums";
import CreateGroups from "./Pages/Groups/CreateGroups";
import GroupPeople from "./Pages/Groups/GroupPeople";
import GroupAbout from "./Pages/Groups/GroupAbout";
import GroupHome from "./Pages/Groups/GroupHome";
import Request from "./Pages/Groups/Setting/Request";
import Block from "./Pages/Groups/Setting/Block";
import PhotoAlbum from "./Pages/ProfileMedia/PhotoAlbum";
import FriendMessages from "./Pages/Dashboard/friendMessage/FriendMessage";
import Rule from "./Pages/Groups/Setting/Rule";
import UpdatePage from "./Pages/UpdatePage";
import Member from "./Pages/Groups/Setting/Member";
import UpdateGroup from "./Pages/Groups/UpdateGroup";
import JobDetail from "./Pages/Jobs/JobDetail";
import AdminJobs from "./Pages/ProfileCompany/Admin/AdminJobs";
import AdminViewJobs from "./Pages/ProfileCompany/Admin/AdminViewJobs";
import AdminJobsApplication from "./Pages/ProfileCompany/Admin/AdminJobsApplication";
import JobListing from "./Pages/Jobs/JobListing";
import MainSearch from "./Pages/MainSearch";
import ChangePassword from "./Pages/ChangePassword";
import ActivityLog from "./Pages/Setting/ActivityLog";
import Notification from "./Pages/Setting/Notification";
import PrivacyLog from "./Pages/Setting/PrivacyLog";
import ProfileVisibility from "./Pages/Setting/ProfileVisibility";
import SavePost from "./Pages/SavePost";
import ProfileBlockedUser from "./Pages/ProfileBlockedUser";
import SaveJob from "./Pages/Internship/SaveJob";
import Applied from "./Pages/Internship/Applied";
import EditAlbums from "./Pages/ProfileMedia/EditAlbums";
import EditBlog from "./Pages/Blog/EditBlog";
import PostDetail from "./Pages/PostDetail";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signin/:slug" element={<Signin />} />
        <Route path="/loginwithotp" element={<LoginWithOtp />} />
        <Route path="/loginwithpassword" element={<LoginWithPassword />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/verify-otp/:slug" element={<VerifyEmail />} />
        <Route path="/reset-password/:id" element={<ResetPassword />} />
        <Route
          path="/mainfeed"
          element={<ProtectedRoute element={MainFeed} />}
        />
        <Route
          path="/friend-profile/:id"
          element={<ProtectedRoute element={ProfileFriend} />}
        />
        <Route
          path="/profile-teacher/:id"
          element={<ProtectedRoute element={Teachers} />}
        />
        <Route
          path="/profile-photos/:id"
          element={<ProtectedRoute element={Photo} />}
        />
        <Route
          path="/profile-albums/:id"
          element={<ProtectedRoute element={PhotoAlbum} />}
        />
        <Route
          path="/user-profile-photos/:id"
          element={<ProtectedRoute element={Photo} />}
        />
        <Route
          path="/create-albums/:id"
          element={<ProtectedRoute element={CreateAlbums} />}
        />
        <Route
          path="/profile-videos/:id"
          element={<ProtectedRoute element={Video} />}
        />
        <Route
          path="/friend-request/:id"
          element={<ProtectedRoute element={FriendRequest} />}
        />
        <Route
          path="/updateprofile"
          element={<ProtectedRoute element={ProfileUpdate} />}
        />
        <Route path="/courses" element={<ProtectedRoute element={Courses} />} />
        <Route
          path="/courses/:id"
          element={<ProtectedRoute element={Courses} />}
        />
        <Route
          path="/teachers"
          element={<ProtectedRoute element={TeachersMenu} />}
        />
        <Route
          path="/teachers?:slug"
          element={<ProtectedRoute element={TeachersMenu} />}
        />
        <Route path="/pages" element={<ProtectedRoute element={PagesMenu} />} />
        <Route
          path="/hashtags"
          element={<ProtectedRoute element={Hashtags} />}
        />
        <Route path="/groups" element={<ProtectedRoute element={Groups} />} />
        <Route
          path="/gratitudewall"
          element={<ProtectedRoute element={GratitudeWall} />}
        />
        <Route
          path="/testscores"
          element={<ProtectedRoute element={TestScores} />}
        />
        <Route
          path="/university"
          element={<ProtectedRoute element={UniversityMenu} />}
        />
        <Route
          path="/university/:slug"
          element={<ProtectedRoute element={UniversityMenu} />}
        />
        <Route
          path="/singlehashtag"
          element={<ProtectedRoute element={SingleHashtag} />}
        />
        <Route
          path="/internship"
          element={<ProtectedRoute element={Internship} />}
        />
        <Route path="/jobs" element={<ProtectedRoute element={Jobs} />} />
        <Route
          path="/schools"
          element={<ProtectedRoute element={SchoolsMenu} />}
        />
        <Route
          path="/schools/:slug"
          element={<ProtectedRoute element={SchoolsMenu} />}
        />
        <Route
          path="/notifications"
          element={<ProtectedRoute element={AllNotifications} />}
        />
        <Route
          path="/company"
          element={<ProtectedRoute element={CompanyMenu} />}
        />
        <Route
          path="/company/:slug"
          element={<ProtectedRoute element={CompanyMenu} />}
        />
        <Route
          path="/honors&awards"
          element={<ProtectedRoute element={HonorAwards} />}
        />
        <Route
          path="/timeline/:id"
          element={<ProtectedRoute element={ProfileTimeline} />}
        />
        {/* <Route path="/teachers" element={<ProfileTeacher/>}/> */}
        <Route
          path="/select-profile/:id"
          element={<ProtectedRoute element={SelectProfile} />}
        />
        <Route
          path="/complete-profile-step1/:id"
          element={<ProtectedRoute element={CompleteProfile} />}
        />
        <Route
          path="/complete-profile-step2/:id"
          element={<ProtectedRoute element={ImageUpload} />}
        />
        <Route
          path="/complete-profile-step3/:id"
          element={<ProtectedRoute element={Verification} />}
        />
        <Route
          path="/complete-profile-step4/:id"
          element={<ProtectedRoute element={Academics} />}
        />
        <Route
          path="/select-interests/:id"
          element={<ProtectedRoute element={Interests} />}
        />
        <Route
          path="/profile-about/:id"
          element={<ProtectedRoute element={ProfileAbout} />}
        />
        <Route
          path="/add-courses-basic-information"
          element={<ProtectedRoute element={BasicInfo} />}
        />
        <Route
          path="/update-courses-basic-information/:id"
          element={<ProtectedRoute element={BasicInfo} />}
        />
        <Route
          path="/add-courses-advance-information/:id"
          element={<ProtectedRoute element={AdvanceInformation} />}
        />
        <Route
          path="/add-courses-curriculum/:id"
          element={<ProtectedRoute element={Curriculum} />}
        />
        <Route
          path="/add-courses-publish/:id"
          element={<ProtectedRoute element={PublishCourse} />}
        />
        <Route
          path="/dashboard"
          element={<ProtectedRoute element={TeacherDashboard} />}
        />
        <Route
          path="/mycourses"
          element={<ProtectedRoute element={MyCourses} />}
        />
        <Route
          path="/mycourses/:slug"
          element={<ProtectedRoute element={MyCourses} />}
        />
        <Route
          path="/mycourses-detail/:id"
          element={<ProtectedRoute element={CourseDetail} />}
        />
        <Route
          path="/course-detail/:id"
          element={<ProtectedRoute element={CoursesDetail} />}
        />
        <Route
          path="/course-preview/:id"
          element={<ProtectedRoute element={CoursesPreview} />}
        />
        <Route
          path="/earnings"
          element={<ProtectedRoute element={Earning} />}
        />
        <Route
          path="/become-instructor"
          element={<ProtectedRoute element={BecomeInstructor} />}
        />
        <Route path="/post/blog" element={<ProtectedRoute element={Blog} />} />
        <Route
          path="/page/blog/:id"
          element={<ProtectedRoute element={Blog} />}
        />
        <Route
          path="/group/blog/:id"
          element={<ProtectedRoute element={Blog} />}
        />
        <Route
          path="/blog/:id"
          element={<ProtectedRoute element={SingleBlog} />}
        />
        <Route
          path="/edit-blog/:id"
          element={<ProtectedRoute element={EditBlog} />}
        />
        <Route
          path="/linkedin"
          element={<ProtectedRoute element={LinkedInCallback} />}
        />
        <Route
          path="/course-listing?:slug"
          element={<ProtectedRoute element={Listing} />}
        />
        <Route
          path="/course-listing"
          element={<ProtectedRoute element={Listing} />}
        />
        <Route
          path="/course/return"
          element={<ProtectedRoute element={ReturnURL} />}
        />
        <Route path="/cart" element={<ProtectedRoute element={Cart} />} />
        <Route
          path="/checkout"
          element={<ProtectedRoute element={Checkout} />}
        />
        <Route
          path="/checkout-success"
          element={<ProtectedRoute element={CheckoutSuccess} />}
        />
        <Route
          path="/payment-failed"
          element={<ProtectedRoute element={PaymentFailed} />}
        />
        <Route
          path="/my-courses"
          element={<ProtectedRoute element={MyCoursesStudent} />}
        />
        <Route
          path="/my-courses?:slug"
          element={<ProtectedRoute element={MyCoursesStudent} />}
        />
        <Route
          path="/messages"
          element={<ProtectedRoute element={Messages} />}
        />
        <Route
          path="/teacher/messages"
          element={<ProtectedRoute element={Messages} />}
        />
        <Route
          path="/friend/messages"
          element={<ProtectedRoute element={FriendMessages} />}
        />
        <Route
          path="/student-dashboard"
          element={<ProtectedRoute element={StudentDashboard} />}
        />
        <Route
          path="/student-teachers"
          element={<ProtectedRoute element={Teacher} />}
        />
        <Route
          path="/student-wishlist"
          element={<ProtectedRoute element={Wishlist} />}
        />
        <Route
          path="/student-purchase-history"
          element={<ProtectedRoute element={Purachase} />}
        />
        <Route
          path="/instructor-profile/:id"
          element={<ProtectedRoute element={TeacherProfile} />}
        />
        <Route
          path="/start-learning/:id"
          element={<ProtectedRoute element={Learning} />}
        />
        <Route
          path="/select-page-type"
          element={<ProtectedRoute element={SelectPageType} />}
        />
        <Route
          path="/create-page/:type"
          element={<ProtectedRoute element={PageForm} />}
        />
        <Route
          path="/update-page/:type/:slug"
          element={<ProtectedRoute element={UpdatePage} />}
        />
        <Route
          path="/page-profile/:slug"
          element={<ProtectedRoute element={ProfileCompany} />}
        />
        <Route
          path="/page-about/:slug"
          element={<ProtectedRoute element={CompanyAbout} />}
        />
        <Route
          path="/page-jobs/:slug"
          element={<ProtectedRoute element={CompanyJobs} />}
        />
        <Route
          path="/page-post/:slug"
          element={<ProtectedRoute element={CompanyPost} />}
        />
        <Route
          path="/page-people/:slug"
          element={<ProtectedRoute element={CompanyPeople} />}
        />
        <Route
          path="/page-followers/:slug"
          element={<ProtectedRoute element={CompanyFollowers} />}
        />
        <Route
          path="/page-video/:slug"
          element={<ProtectedRoute element={CompanyVideo} />}
        />
        <Route
          path="/school-profile"
          element={<ProtectedRoute element={ProfileSchool} />}
        />
        <Route
          path="/univeristy-profile"
          element={<ProtectedRoute element={ProfileUniversity} />}
        />
        <Route
          path="/create-group"
          element={<ProtectedRoute element={CreateGroups} />}
        />
        <Route
          path="/update-group/:id"
          element={<ProtectedRoute element={UpdateGroup} />}
        />
        <Route
          path="/group-home/:id"
          element={<ProtectedRoute element={GroupHome} />}
        />
        <Route
          path="/group-about/:id"
          element={<ProtectedRoute element={GroupAbout} />}
        />
        <Route
          path="/group-people/:id"
          element={<ProtectedRoute element={GroupPeople} />}
        />
        <Route
          path="/group-request/:id"
          element={<ProtectedRoute element={Request} />}
        />
        <Route
          path="/group-block/:id"
          element={<ProtectedRoute element={Block} />}
        />
        <Route
          path="/group-rule/:id"
          element={<ProtectedRoute element={Rule} />}
        />
        <Route
          path="/group-member/:id"
          element={<ProtectedRoute element={Member} />}
        />
        <Route
          path="/jobs/:id"
          element={<ProtectedRoute element={JobDetail} />}
        />
        <Route
          path="/admin-jobs-list/:id"
          element={<ProtectedRoute element={AdminJobs} />}
        />
        <Route
          path="/admin-jobs-view/:id"
          element={<ProtectedRoute element={AdminViewJobs} />}
        />
        <Route
          path="/admin-jobs-application/:id"
          element={<ProtectedRoute element={AdminJobsApplication} />}
        />
        <Route
          path="/admin-jobs-shortlisted-application/:id"
          element={<ProtectedRoute element={AdminJobsApplication} />}
        />
        {/* <Route path="/jobs-list?:slug" element={<ProtectedRoute element={JobListing}/>}/> */}
        <Route
          path="/jobs-listing"
          element={<ProtectedRoute element={JobListing} />}
        />
        <Route
          path="/featured-listing"
          element={<ProtectedRoute element={JobListing} />}
        />
        <Route
          path="/internship-listing"
          element={<ProtectedRoute element={JobListing} />}
        />
        <Route
          path="/search"
          element={<ProtectedRoute element={MainSearch} />}
        />
        <Route
          path="/change-password"
          element={<ProtectedRoute element={ChangePassword} />}
        />
        <Route
          path="/activity-log"
          element={<ProtectedRoute element={ActivityLog} />}
        />
        <Route
          path="/notifications-setting"
          element={<ProtectedRoute element={Notification} />}
        />
        <Route
          path="/privacy-setting"
          element={<ProtectedRoute element={PrivacyLog} />}
        />
        <Route
          path="/profile-visibility"
          element={<ProtectedRoute element={ProfileVisibility} />}
        />
        <Route
          path="/save-post/:id"
          element={<ProtectedRoute element={SavePost} />}
        />
        <Route
          path="/block-user"
          element={<ProtectedRoute element={ProfileBlockedUser} />}
        />
        <Route
          path="/save-jobs"
          element={<ProtectedRoute element={SaveJob} />}
        />
        <Route
          path="/applied-jobs"
          element={<ProtectedRoute element={Applied} />}
        />
        <Route
          path="/save-internship"
          element={<ProtectedRoute element={SaveJob} />}
        />
        <Route
          path="/applied-internship"
          element={<ProtectedRoute element={Applied} />}
        />
        <Route
          path="/edit-album/:id"
          element={<ProtectedRoute element={EditAlbums} />}
        />
        <Route
          path="/post-detail/:id"
          element={<ProtectedRoute element={PostDetail} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
