import { useCallback, useEffect, useRef, useState } from "react";

export default function Carousel({
  itemWidth = 200,
  children,
  disableAutoScrollOnChildrenUpdate,
}) {
  const containerRef = useRef();

  const [scrollLeft, setScrollLeft] = useState(0);

  const [scrollEnd, setScrollEnd] = useState(false);

  const checkScrollPosition = useCallback(() => {
    if (containerRef.current) {
      const { scrollWidth, scrollLeft, offsetWidth } = containerRef.current;
      setScrollLeft(scrollLeft);
      if (Math.floor(scrollWidth - scrollLeft) <= offsetWidth) {
        setScrollEnd(true);
      } else {
        setScrollEnd(false);
      }
    }
  }, []);

  const goLeft = useCallback(() => {
    const { scrollLeft } = containerRef.current;
    containerRef.current.scrollTo({
      left: scrollLeft - itemWidth,
      behavior: "smooth",
    });
  }, [itemWidth]);

  const goRight = useCallback(() => {
    const { scrollLeft } = containerRef.current;
    containerRef.current.scrollTo({
      left: scrollLeft + itemWidth,
      behavior: "smooth",
    });
  }, [itemWidth]);

  useEffect(() => {
    if (!disableAutoScrollOnChildrenUpdate) containerRef.current.scrollLeft = 0;
    checkScrollPosition();
  }, [children, disableAutoScrollOnChildrenUpdate]);

  return (
    <div className="custom-carousel-container">
      <div
        ref={containerRef}
        className="custom-carousel"
        onScroll={checkScrollPosition}
      >
        {children}
        {!!scrollLeft && (
          <button onClick={goLeft} className="custom-carousel-button left">
            <img
              src={`${process.env.PUBLIC_URL}/assets/svgs/carousel-right-icon.svg`}
              alt="previous"
            />
          </button>
        )}
        {!scrollEnd && (
          <button onClick={goRight} className="custom-carousel-button right">
            <img
              src={`${process.env.PUBLIC_URL}/assets/svgs/carousel-right-icon.svg`}
              alt="next"
            />
          </button>
        )}
      </div>
    </div>
  );
}
