import React, { useEffect, useState } from "react";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Profiles from "./Profiles";
import { FetchApi } from "../../../API/FetchApi";
import { Endpoints } from "../../../API/Endpoints";
import ToastMessage from "../../../Utils/ToastMessage";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import JobEditModel from "./JobEditModel";
import { Helmet } from "react-helmet";

export default function AdminViewJobs() {
  const [aboutData, setAboutData] = useState();
  const [jobsData, setJobsData] = useState();
  const [totalAppliedUser, setTotalAppliedUser] = useState(0);
  const param = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getCompanyJobs();
  }, []);

  const getCompanyJobs = async () => {
    try {
      let resp = await FetchApi(Endpoints.JobPreview + "/" + param?.id);
      if (resp && resp.success === true) {
        setJobsData(resp?.data?.job);
        setAboutData(resp?.data?.page);
        setTotalAppliedUser(resp?.data?.totalAppliedUser);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "year ago";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon ago";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + " day ago";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + " h ago";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min ago";
    return Math.floor(seconds) >= 0
      ? Math.floor(seconds) + " sec ago"
      : "0 sec ago";
  };
  return (
    <Container header>
      <Helmet>
        <title>Application Detail</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-lg-4 py-3">
            <Profiles
              {...{
                aboutData: aboutData,
              }}
            />
            <div class="row">
              <div class="col-md-12 pb-3">
                <div class="rounded-15 px-md-4 px-3 py-3 bg-white mb-3">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="d-sm-flex pb-4 align-items-center justify-content-between">
                        <Link
                          to={"/admin-jobs-list/" + aboutData?.id}
                          class="btn "
                        >
                          <img
                            src="../assets/img/icon/arrow-left-white.svg"
                            alt=""
                          />{" "}
                          Back
                        </Link>
                        <h4 class="medium text-sm-center pt-sm-0 pt-3">
                          Manage Applications and Job Preview
                        </h4>
                      </div>
                    </div>
                  </div>

                  <ul class="profile-menu d-flex justify-content-center flex-wrap gap-lg-3">
                    <li class="">
                      <Link to={"/admin-jobs-application/" + param?.id}>
                        Job Applications ({totalAppliedUser})
                      </Link>
                    </li>

                    <li class="active">
                      <Link>Job Preview</Link>
                    </li>
                  </ul>

                  <div class="row m-0 border-bottom pb-2 mb-4 align-items-center pt-4">
                    <div class="col-md-6 pb-md-0 pb-2 px-0">
                      <div class="p-lg-3 d-flex gap-2 align-items-center">
                        <img
                          src="../assets/img/icon/digital-ocean.svg"
                          class="rounded-circle"
                          width="60"
                          height="60"
                          alt=""
                        />
                        <div>
                          <h5 class="semibold">{jobsData?.title}</h5>
                          <div class="d-flex py-2 align-items-center">
                            <span class="fs-10 text-darkgray pe-2 d-flex align-items-center">
                              <img
                                src="../assets/img/icon/icon-location.svg"
                                alt=""
                                class="align-middle me-1"
                              />
                              <span>{jobsData?.state?.state}</span>
                            </span>
                            <span class="fs-10 text-darkgray d-flex align-items-center">
                              <img
                                src="../assets/img/icon/icon-date-time.svg"
                                alt=""
                                class="align-middle me-1"
                              />
                              <span>
                                {timeSince(new Date(jobsData?.createdAt))}
                              </span>
                            </span>
                          </div>
                          <div class="d-flex request-btn gap-2 mb-2">
                            <a href="#" class="btn light-brown-btn px-2 medium">
                              {jobsData?.jobType}
                            </a>
                            <a href="#" class="btn light-brown-btn px-2 medium">
                              {jobsData?.workMode}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 text-md-end px-0">
                      <div class="me-md-5 mb-1 pe-md-2">
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#internship"
                          class="btn edit-btn px-3 medium fs-14 py-2 me-md-5"
                        >
                          Edit{" "}
                          {jobsData?.jobType === "Internship"
                            ? "Internship"
                            : "Job"}
                          <img
                            src="../assets/img/icon/edit-profile2.svg"
                            alt=""
                            width="12"
                          />
                        </a>
                      </div>
                      <p class="fs-14 medium">
                        <span class="semibold text-blue">Job Created:</span>{" "}
                        {moment(jobsData?.created_at).format("MMMM DD, YYYY")}
                      </p>
                    </div>
                  </div>

                  <div class="row m-0">
                    <div class="col-md-8 mb-3 ps-md-0">
                      <div class="bg-white rounded-10 box-shadow py-3 px-3">
                        <h6 class="medium fs-16 pb-2"> Job Description</h6>
                        <div class="fs-14 pb-4">
                          <p
                            class="m-0 ck"
                            dangerouslySetInnerHTML={{
                              __html: jobsData?.description,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="bg-white rounded-10 box-shadow py-3 px-lg-3 px-2">
                        <div class="row">
                          <div class="col-6 mb-2 px-1">
                            <div class="single-job-gray rounded-10 text-center px-2 py-2">
                              <h6 class="fs-12 text-gray regular">Job Type</h6>
                              <h5 class="text-black fs-14 medium">
                                {" "}
                                {jobsData?.jobType}
                              </h5>
                            </div>
                          </div>

                          <div class="col-6 mb-2 px-1">
                            <div class="single-job-gray rounded-10 text-center px-2 py-2">
                              <h6 class="fs-12 text-gray regular">
                                Work Level
                              </h6>
                              <h5 class="text-black fs-14 medium text-break">
                                {jobsData?.workLevel}
                              </h5>
                            </div>
                          </div>

                          <div class="col-6 mb-2 px-1">
                            <div class="single-job-gray rounded-10 text-center px-2 py-2">
                              <h6 class="fs-12 text-gray regular">
                                Qualification
                              </h6>
                              <h5 class="text-black fs-14 medium">
                                {jobsData?.qualification}
                              </h5>
                            </div>
                          </div>

                          <div class="col-6 mb-2 px-1">
                            <div class="single-job-gray rounded-10 text-center px-2 py-2">
                              <h6 class="fs-12 text-gray regular">Salary</h6>
                              <h5 class="text-black fs-14 medium">
                                ₹ {jobsData?.minSalary / 1000} k -{" "}
                                {jobsData?.maxSalary / 1000} k/mon
                              </h5>
                            </div>
                          </div>

                          <div class="col-6 mb-2 px-1">
                            <div class="single-job-gray rounded-10 text-center px-2 py-2">
                              <h6 class="fs-12 text-gray regular">
                                Work Model
                              </h6>
                              <h5 class="text-black fs-14 medium">
                                {jobsData?.workMode}
                              </h5>
                            </div>
                          </div>

                          <div class="col-6 mb-2 px-1">
                            <div class="single-job-gray rounded-10 text-center px-2 py-2">
                              <h6 class="fs-12 text-gray regular">Industry</h6>
                              <h5 class="text-black fs-14 medium">
                                {jobsData?.industryData?.value}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <OnlineFriends />
          </div>
        </div>
      </div>
      <JobEditModel
        {...{
          onSuccess: getCompanyJobs,
          title: jobsData?.jobType,
        }}
      />
    </Container>
  );
}
