import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import ToastMessage from "../../Utils/ToastMessage";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import { FetchApi } from "../../API/FetchApi";
import Item from "./Item";
import { Helmet } from "react-helmet";
function Hashtags() {
  const navigate = useNavigate();
  const [topHashtags, setTopHashtags] = useState([]);
  const [trendingHashtags, setTrendingHashtags] = useState([]);
  const [usedHashtags, setUsedHashtags] = useState([]);
  useEffect(() => {
    handleGetHashtags();
  }, []);

  const handleGetHashtags = async () => {
    try {
      let resp = await FetchApi(Endpoints.HashtagsTop);
      if (resp && resp.success === true) {
        if (resp.data) {
          setTopHashtags(resp?.data?.topHashtags);
          setTrendingHashtags(resp?.data?.trendingHashtags);
          setUsedHashtags(resp?.data?.usedHashtags);
        }
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Hashtags</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          {/* <?php include 'include/left-navigation.php'; ?> */}
          <LeftNavigation />

          <div className="container py-lg-5 py-3">
            {topHashtags.length > 0 ? (
              <div className="bg-white main-common mb-3">
                <div className="pb-3">
                  <h3 className="bold pb-2">Top Hashtags</h3>
                  <h5 className="text-darkgray medium">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.s
                  </h5>
                </div>

                <div className="row">
                  {topHashtags.map((item, index) => {
                    return (
                      <Item
                        {...{
                          item: item,
                          index: index,
                          onSuccess: handleGetHashtags,
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="bg-white main-common mb-3">
                <h3 className="bold pb-2">Top Hashtags</h3>
                <p>No Hashtag found</p>
              </div>
            )}

            {trendingHashtags.length > 0 ? (
              <div className="bg-white main-common mb-3">
                <div className="pb-3">
                  <h3 className="bold pb-2">Trending Hashtags</h3>
                  <h5 className="text-darkgray medium">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.s
                  </h5>
                </div>

                <div className="row">
                  {trendingHashtags.map((item, index) => {
                    return (
                      <Item
                        {...{
                          item: item,
                          index: index,
                          onSuccess: handleGetHashtags,
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="bg-white main-common mb-3">
                <h3 className="bold pb-2">Trending Hashtags</h3>
                <p>No Hashtag found</p>
              </div>
            )}

            {usedHashtags.length > 0 ? (
              <div className="bg-white main-common mb-3">
                <div className="pb-3">
                  <h3 className="bold pb-2">Hashtags I Used</h3>
                  <h5 className="text-darkgray medium">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.s
                  </h5>
                </div>

                <div className="row">
                  {usedHashtags.map((item, index) => {
                    return (
                      <Item
                        {...{
                          item: item,
                          index: index,
                          onSuccess: handleGetHashtags,
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="bg-white main-common mb-3">
                <h3 className="bold pb-2">Hashtags I Used</h3>
                <p>No Hashtag found</p>
              </div>
            )}
          </div>

          {/* <?php include 'include/online-friends.php'; ?> */}
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}

export default Hashtags;
