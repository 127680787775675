import React, { useEffect, useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
import { PatchApi } from "../../API/PatchApi";
import { UpdateAPi } from "../../API/UpdateApi";
import { FetchApi } from "../../API/FetchApi";
import ToastMessage from "../../Utils/ToastMessage";
import { Endpoints } from "../../API/Endpoints";
import Resizer from "react-image-file-resizer";
import { Helmet } from "react-helmet";
export default function EditAlbums() {
  const [albumImage, setAlbumImage] = useState([]);
  const [albumMedia, setAlbumMedia] = useState([]);
  const [deleteAlbumMedia, setDeleteAlbumMedia] = useState([]);
  const [AlbumCaption, setAlbumCaption] = useState("");
  const [mediaCaption, setmediaCaption] = useState([]);
  const [mediaUploadCaption, setmediaUploadCaption] = useState([]);
  const [AlbumName, setAlbumName] = useState("");
  const [id, setId] = useState("");
  const [status, setStatus] = useState(false);
  const [activePostCategory, setActivePostCategory] = useState("Status");
  const userId = localStorage.getItem("userId");
  const username = localStorage.getItem("userName");
  const navigate = useNavigate();
  const param = useParams();
  useEffect(() => {
    getPostData();
  }, []);

  const getPostData = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetSinglePost + "/" + param?.id);
      if (resp && resp.success === true) {
        setAlbumMedia(resp?.data?.post_medias);
        setAlbumCaption(resp?.data?.title);
        setAlbumName(resp?.data?.albumTitle);
        setId(resp?.data?.id);
        let media_caption_array = [];
        for (let index = 0; index < resp?.data?.post_medias.length; index++) {
          let data = { value: "" };
          media_caption_array = [...media_caption_array, data];
        }
        setmediaCaption(media_caption_array);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleImage = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    let new_arr = [...albumImage, ...chosenFiles];

    setAlbumImage(new_arr);
    let media_caption_array = [];
    for (let index = 0; index < chosenFiles.length; index++) {
      let data = { value: "" };
      media_caption_array = [...media_caption_array, data];
    }
    setmediaCaption(media_caption_array);
  };
  const removeImage = (e) => {
    const newImageData = albumImage.filter((item, index) => index !== e);
    setAlbumImage(newImageData);
    const newMediaCaption = mediaCaption.filter((item, index) => index !== e);
    setmediaCaption(newMediaCaption);
  };

  const handleChange = (value, index) => {
    let temp = mediaCaption.slice();
    temp[index].value = value;
    setmediaCaption(temp);
  };

  async function compressImage() {
    let promises = [];
    const quality = 70;
    for (const element of albumImage) {
      if (element.type.includes("image")) {
        let maxHeight, maxWidth;
        const image = new Image();
        const url = URL.createObjectURL(element);
        image.src = url;
        image.onload = () => {
          const { naturalWidth, naturalHeight } = image;
          maxHeight = naturalHeight;
          maxWidth = naturalWidth;
          URL.revokeObjectURL(url);
        };
        promises.push(
          new Promise((resolve) => {
            Resizer.imageFileResizer(
              element,
              maxWidth,
              maxHeight,
              "JPEG",
              quality,
              0,
              (file) => {
                // console.log(file)
                resolve(file);
              },
              "file"
            );
          })
        );
      }
    }
    const results = await Promise.all(promises);
    const compressed = [
      ...albumImage.filter((each) => each.type.includes("video")),
      ...results,
    ];
    return compressed;
  }

  const uploadPost = async (e) => {
    setStatus(true);
    e.preventDefault();
    if (AlbumName === "") {
      return ToastMessage.Error("PLEASE UPLOAD A POST OR A TITLE");
    }
    const result = await compressImage();
    const formData = new FormData();
    formData.append("id", id);
    formData.append("album_title", AlbumName);
    formData.append("title", AlbumCaption);
    formData.append("category", activePostCategory);
    formData.append("is_album", true);
    result.forEach((item) => formData.append("media", item));
    let data = [];
    for (var i = 0; i < mediaCaption.length; i++) {
      //
      data[i] = mediaCaption[i].value;
    }
    formData.append("media_caption", JSON.stringify(data));
    formData.append("updated_files", JSON.stringify(mediaUploadCaption));
    formData.append("deleted_files", JSON.stringify(deleteAlbumMedia));
    try {
      let resp = await PatchApi(Endpoints.UpdatePost, formData);
      if (resp && resp.success === true) {
        setStatus(false);
        // if(mediaUploadCaption.length > 0){
        //     for (var i = 0; i < mediaUploadCaption.length; i++) {
        //         let data ={
        //             media_id:mediaUploadCaption[i].media_id,
        //             caption:mediaUploadCaption[i].caption
        //         }
        //         updateCaption(data);
        //     }

        // }else{
        //     navigate("/profile-albums/" + userId)
        // }
        navigate("/profile-albums/" + username);
      }
    } catch (e) {
      setStatus(false);
      if (e?.response?.data?.message) {
        ToastMessage.Error(e.response.data.message);
      }
    }
  };

  const updateCaption = async (data) => {
    try {
      let resp = await UpdateAPi(Endpoints.CreateMediaCaption, data);
      if (resp && resp.success === true) {
        navigate("/profile-albums/" + username);
      }
    } catch (e) {
      setStatus(false);
      if (e?.response?.data?.message) {
        ToastMessage.Error(e.response.data.message);
      }
    }
  };

  const deleteImage = (Id, e) => {
    setDeleteAlbumMedia([...deleteAlbumMedia, Id]);
    const newImageData = albumMedia.filter((item, index) => item?.id !== Id);
    setAlbumMedia(newImageData);

    const newMediaCaption = mediaCaption.filter((item, index) => index !== e);
    setmediaCaption(newMediaCaption);
  };
  const handleChangeUpload = (caption, id) => {
    let data = {
      caption: caption,
      id: id,
    };
    if (mediaUploadCaption.filter((item, index) => item?.id == id).length > 0) {
      const indexData = mediaUploadCaption.findIndex((item) => item.id === id);
      if (indexData !== -1) {
        mediaUploadCaption[indexData].caption = caption;
      }
    } else {
      setmediaUploadCaption([...mediaUploadCaption, data]);
    }
  };
  return (
    <Container header>
      <Helmet>
        <title>Edit Album</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div class="container py-3">
            <div class="bg-white rounded-10 shadow-lg">
              <div class="py-3 px-3 ">
                <Link to={"/profile-albums/" + username} class="btn w-auto ">
                  <svg
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    class="css-i6dzq1"
                  >
                    <line x1="19" y1="12" x2="5" y2="12"></line>
                    <polyline points="12 19 5 12 12 5"></polyline>
                  </svg>
                  Back
                </Link>
              </div>

              <div class="border-top py-md-5  p-3">
                <div class="row m-0">
                  <div class="col-md-3 pb-md-0 pb-4  border-end d-flex flex-column justify-content-between">
                    <form onSubmit={uploadPost}>
                      <div>
                        <h4 class="medium fs-22 text-black m-0 border-bottom pb-2 mb-5">
                          Update Album{" "}
                        </h4>

                        <div class=" mb-4">
                          <label for="" class="fs-14 medium d-block pb-2">
                            Album Name*
                          </label>
                          <input
                            type="text"
                            placeholder="Add your album name"
                            class="border rounded-2 fs-14 px-2 py-3 text-gray"
                            onChange={(e) => {
                              setAlbumName(e.target.value);
                            }}
                            value={AlbumName}
                          />
                        </div>
                        <div class=" mb-4">
                          <label for="" class="fs-14 medium d-block pb-2">
                            Caption*
                          </label>
                          <input
                            type="text"
                            placeholder="Add your album name"
                            class="border rounded-2 fs-14 px-2 py-3 text-gray"
                            onChange={(e) => {
                              setAlbumCaption(e.target.value);
                            }}
                            value={AlbumCaption}
                          />
                        </div>

                        <div>
                          <input
                            type="file"
                            name=""
                            id="photo_upload"
                            class="d-none"
                            accept="image/*"
                            multiple
                            onChange={(e) => {
                              handleImage(e);
                            }}
                          />
                          <label
                            for="photo_upload"
                            class=" rounded-2 text-center py-4 px-2 w-100 com-logo"
                          >
                            <img
                              src="../assets/img/icon/icon-file-upload.svg"
                              width="25"
                              alt=""
                            />
                            <span class="d-block fs-14 medium pt-2 pb-1 text-black">
                              Upload Photos
                            </span>
                          </label>
                        </div>
                      </div>
                      {status === false ? (
                        <div class="mt-5 pt-md-5">
                          <button type="submit" class="btn fs-14 w-100 px-4">
                            Update Post
                          </button>
                        </div>
                      ) : (
                        <div class="mt-5 pt-md-5">
                          <button type="submit" class="btn fs-14 w-100 px-4">
                            Please wait...{" "}
                            <div
                              style={{ width: "1rem", height: "1rem" }}
                              className="spinner-border text-secondary"
                              role="status"
                            >
                              <span className="sr-only"></span>
                            </div>
                          </button>
                        </div>
                      )}
                    </form>
                  </div>

                  <div class="col-md-9">
                    {albumImage.length > 0 ? (
                      <div class="row m-0">
                        <div className="col-md-12">New Uploaded Images</div>
                        {albumImage.map((item, index) => {
                          return (
                            <div
                              class="col-lg-4 col-md-6 col-sm-6 mb-4"
                              key={index}
                            >
                              <div class="border rounded-10 overflow-hidden position-relative">
                                <Link
                                  onClick={() => {
                                    removeImage(index);
                                  }}
                                  class="position-absolute m-2 end-0 d-flex align-items-center justify-content-center rounded-circle bg-eee p-1"
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="18"
                                    height="18"
                                    stroke="#000"
                                    strokeWidth="2.2"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    class="css-i6dzq1"
                                  >
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                  </svg>
                                </Link>
                                <img
                                  src={URL.createObjectURL(item)}
                                  class="w-100 object-cover"
                                  height="180"
                                  alt=""
                                />
                                <div class="p-2 pb-1">
                                  <textarea
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="4"
                                    class="border rounded-2 fs-14 p-2 text-gray fs-12"
                                    placeholder="Write your caption here."
                                    onChange={(e) => {
                                      handleChange(e.target.value, index);
                                    }}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}

                    {albumMedia.length > 0 ? (
                      <div class="row m-0">
                        <div className="col-md-12">Uploaded Images</div>

                        {albumMedia.map((item, index) => {
                          return (
                            <div
                              class="col-lg-4 col-md-6 col-sm-6 mb-4"
                              key={index}
                            >
                              <div class="border rounded-10 overflow-hidden position-relative">
                                <Link
                                  onClick={() => {
                                    deleteImage(item?.id, index);
                                  }}
                                  class="position-absolute m-2 end-0 d-flex align-items-center justify-content-center rounded-circle bg-eee p-1"
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="18"
                                    height="18"
                                    stroke="#000"
                                    strokeWidth="2.2"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    class="css-i6dzq1"
                                  >
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                  </svg>
                                </Link>
                                <img
                                  src={Endpoints.baseUrl + item?.filePath}
                                  class="w-100 object-cover"
                                  height="180"
                                  alt=""
                                />
                                <div class="p-2 pb-1">
                                  <textarea
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="4"
                                    class="border rounded-2 fs-14 p-2 text-gray fs-12"
                                    placeholder="Write your caption here."
                                    onChange={(e) => {
                                      handleChangeUpload(
                                        e.target.value,
                                        item?.id
                                      );
                                    }}
                                  >
                                    {item?.caption}
                                  </textarea>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
