import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { CommonAPi } from "../../API/CommonApi";
import { DeleteApi } from "../../API/DeleteApi";
import { PatchApi } from "../../API/PatchApi";
import { getOrientation } from "get-orientation/browser";
import { getCroppedImg, getRotatedImage } from "../../Utils/Canvas/canvas_util";
import "./Profile.css";
import Cropper from "react-easy-crop";
import GroupProfileCropper from "../../Components/ImageCropperModal/GroupProfileCropper";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

export default function GroupProfile({ setGroupsData }) {
  const param = useParams();
  const [groupData, setGroupData] = useState("");
  const Navigate = useNavigate();
  const location = useLocation();
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [coverImageSrc, setCoverImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [file, setFile] = useState();

  const [getProfilePicture, setGetProfilePicture] = useState("");
  const [profileImageSrc, setProfileImageSrc] = useState(null);
  const [show, setShow] = useState(false);
  const profileRef = useRef(null);
  const [isFollowing, setIsFollowing] = useState("");
  const shareUrl = window.location.origin + "/group-home/" + param?.id;
  const hadnleCoverPicture = async (e) => {
    let imageDataURL = await readFile(e.target.files[0]);
    const orientation = await getOrientation(e.target.files[0]);
    const rotation = ORIENTATION_TO_ANGLE[orientation];
    if (rotation) {
      imageDataURL = await getRotatedImage(imageDataURL, rotation);
    }
    setCoverImageSrc(imageDataURL);
  };
  const uploadCoverPicture = async () => {
    let newFile = new File([file], "image.jpg", { type: "image/jpg" });
    const formData = new FormData();
    formData.append("cover_image", newFile);
    formData.append("groupId", groupData?.id);

    try {
      let resp = await PatchApi(Endpoints.UpdateGroupsCover, formData);
      if (resp && resp.message) {
        setCoverImageSrc(null);
        getGroupAbout();
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      const { file, url } = await getCroppedImg(
        coverImageSrc,
        croppedAreaPixels
      );
      setFile(file);
    },
    [coverImageSrc]
  );

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    getGroupAbout();
  }, []);
  const getGroupAbout = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.GetGroupsAbout + "?groupId=" + param?.id
      );
      if (resp && resp.success === true) {
        setGroupsData(resp?.data);
        setGroupData(resp?.data.groupData);
        setIsFollowing(resp?.data?.isJoin?.status);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const hadnleProfilePicture = async (e) => {
    setShow(true);

    let imageDataURL = await readFile(e.target.files[0]);
    const orientation = await getOrientation(e.target.files[0]);
    const rotation = ORIENTATION_TO_ANGLE[orientation];
    if (rotation) {
      imageDataURL = await getRotatedImage(imageDataURL, rotation);
    }

    setProfileImageSrc(imageDataURL);
  };

  const leaveGroup = async (id) => {
    let data = {
      groupId: id,
    };
    try {
      let resp = await DeleteApi(Endpoints.LeaveGroup, data);
      if (resp && resp.success === true) {
        getGroupAbout();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const joinGroup = async (id, groupType) => {
    let data = {
      groupId: id,
      groupType: groupType,
    };
    try {
      let resp = await CommonAPi(Endpoints.JoinGroups, data);
      if (resp && resp.success === true) {
        getGroupAbout();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(shareUrl);
    ToastMessage.Success("Link copied successfully");
  };

  return (
    <>
      <div className="profile-banner-top mb-3 bg-white rounded-15 overflow-hidden">
        <div
          className="position-relative banner"
          id="profile-cover-container"
          style={{ height: "20.3rem" }}
        >
          {groupData?.coverImage === null ? (
            <img
              src="../assets/img/company-banner.png"
              alt=""
              className="profile-coverpic"
            />
          ) : (
            <img
              src={Endpoints.baseUrl + groupData?.coverImage}
              alt=""
              className="profile-coverpic"
            />
          )}
          <>
            {coverImageSrc === null ? (
              ""
            ) : (
              <Cropper
                image={coverImageSrc}
                crop={crop}
                zoom={zoom}
                aspect={3.84 / 1.1}
                showGrid={false}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            )}
          </>

          <div className="upload-coverpic position-absolute top-0 end-0 m-3">
            {groupData?.userId == userId ? (
              <>
                {coverImageSrc ? (
                  <div className="">
                    <label
                      style={{ marginRight: "5px" }}
                      for="coverupload"
                      className="fs-12 medium bg-white rounded-pill px-1 py-1 cursor-pointer"
                      onClick={uploadCoverPicture}
                    >
                      Done
                    </label>
                    <span
                      onClick={() => {
                        setCoverImageSrc(null);
                      }}
                      className="fs-12 medium bg-danger rounded-pill px-1 py-1 cursor-pointer"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        width="14"
                        height="14"
                        stroke="#fff"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        class="css-i6dzq1"
                      >
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                      </svg>
                    </span>
                  </div>
                ) : (
                  <>
                    <input
                      type="file"
                      accept="image/*"
                      id="coverupload"
                      className="d-none"
                      onChange={(e) => {
                        hadnleCoverPicture(e);
                      }}
                    />
                    <label
                      for="coverupload"
                      className="fs-14 medium bg-white rounded-pill px-3 py-2 cursor-pointer"
                    >
                      Update Cover
                      <img
                        src="../assets/img/icon/upload-icon.svg"
                        alt=""
                        className="mx-1"
                      />
                    </label>
                  </>
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="row mx-0 align-items-center ">
          <div className="col-lg-auto col-md-3 px-3">
            <div className="profile-dp">
              {/* <img src="../assets/img/comany-dp.png" alt="" /> */}

              {groupData?.logo === null ? (
                <img src="../assets/img/comany-dp.png" alt="" />
              ) : (
                <img src={Endpoints.baseUrl + groupData?.logo} alt="" />
              )}
              {userId == groupData?.userId ? (
                <>
                  <input
                    type="file"
                    id="profilepic"
                    accept="image/*"
                    ref={profileRef}
                    className="d-none"
                    onChange={(e) => {
                      hadnleProfilePicture(e);
                      setShow(true);
                    }}
                  />
                  <label
                    for="profilepic"
                    className="cursor-pointer d-flex profile-edit-icon"
                  >
                    <img src="../assets/img/icon/icon-camera4.svg" alt="" />
                  </label>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="col-lg-5 col-md-4 py-2">
            <h3 className="bold pb-2">{groupData?.groupName}</h3>
            <h5 className="regular text-darkgray pb-2">{groupData?.tagline}</h5>
            <Link
              to={"#"}
              className="btn px-2 py-1 medium fs-12 btn-brown rounded-2"
            >
              {groupData?.groupType} Group
            </Link>
          </div>

          <div className="col-lg-4 col-md-5 py-md-2 profile-cover-btn ms-auto">
            {userId == groupData?.userId ? (
              <div className="d-flex align-items-center gap-2 justify-content-md-end justify-content-between">
                <Link
                  to={"/update-group/" + param?.id}
                  class="btn px-3 medium fs-14 py-2 gray-btn gray-border"
                >
                  Edit Profile
                  <img
                    src="../assets/img/icon/icon-edit.svg"
                    class="ms-1"
                    width="15"
                  />
                </Link>

                <a
                  href="#"
                  id="teacher_1"
                  data-bs-toggle="dropdown"
                  class="d-flex align-items-center justify-content-center  bg-eee  p-2  btn btn-br-blue bg-lightblue px-3 medium fs-14 py-2 btn-br-blue"
                  aria-expanded="false"
                >
                  Share{" "}
                  <img
                    src="../assets/img/icon/follow2.svg"
                    class="ms-1"
                    width="15"
                  />
                </a>
                <div class="position-absolute">
                  <ul
                    class="popup-menu dropdown-menu shadow rounded-1 text-start"
                    aria-labelledby="teacher_1"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={handleCopy}
                      >
                        <img
                          src="../assets/img/icon/gray-link.svg"
                          className="me-2"
                          alt=""
                        />
                        Copy Link
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        <FacebookShareButton
                          url={shareUrl}
                          quote={groupData.description}
                        >
                          <img
                            src="../assets/img/icon/gray-facebook.svg"
                            width="15"
                            alt=""
                          />
                          &nbsp; Facebook
                        </FacebookShareButton>
                      </a>
                    </li>

                    <li>
                      <a className="dropdown-item" href="#">
                        <LinkedinShareButton url={shareUrl}>
                          <img
                            src="../assets/img/icon/gray-in.svg"
                            width="15"
                            alt=""
                          />
                          &nbsp; Linkedin
                        </LinkedinShareButton>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        <EmailShareButton url={shareUrl}>
                          <img
                            src="../assets/img/icon/gray-email.svg"
                            width="15"
                            alt=""
                          />
                          &nbsp; Email
                        </EmailShareButton>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        <WhatsappShareButton url={shareUrl}>
                          <img
                            src="../assets/img/icon/gray-whatsapp.svg"
                            width="15"
                            alt=""
                          />
                          &nbsp; Whatsapp
                        </WhatsappShareButton>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center gap-2 justify-content-md-end justify-content-between">
                {isFollowing === false ||
                isFollowing === null ||
                isFollowing === "" ||
                isFollowing === undefined ? (
                  <Link
                    onClick={() => {
                      joinGroup(groupData?.id, groupData?.groupType);
                    }}
                    className="btn px-3 py-2 medium fs-14 "
                  >
                    Join
                    <img
                      src="../assets/img/icon/icon-add-friend.svg"
                      className="ms-1"
                      width="15"
                    />
                  </Link>
                ) : isFollowing === "PENDING" ? (
                  <Link className="btn px-3 py-2 medium fs-14 ">PENDING</Link>
                ) : isFollowing == "REJECTED" ? (
                  ""
                ) : (
                  <Link
                    onClick={() => {
                      leaveGroup(groupData?.id, groupData?.groupType);
                    }}
                    className="btn px-3 py-2 medium fs-14 "
                  >
                    Leave Group
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="px-lg-4 px-3 px-xl-5 pt-2">
          <ul className="profile-menu d-flex flex-wrap gap-lg-3">
            {/* {isFollowing === "PENDING" && groupData?.groupType === "Private" ? "" : */}
            <li
              className={
                location.pathname.split("/")[1] === "group-home" ? "active" : ""
              }
            >
              <Link to={"/group-home/" + param?.id}>Home</Link>
            </li>
            {/* } */}

            <li
              className={
                location.pathname.split("/")[1] === "group-about"
                  ? "active"
                  : ""
              }
            >
              <Link to={"/group-about/" + param?.id}>About</Link>
            </li>
            {(isFollowing === "PENDING" &&
              groupData?.groupType === "Private" &&
              groupData?.userId != userId) ||
            isFollowing === "REJECTED" ? (
              ""
            ) : (
              <li
                className={
                  location.pathname.split("/")[1] === "group-people"
                    ? "active"
                    : ""
                }
              >
                <Link to={"/group-people/" + param?.id}>People</Link>
              </li>
            )}
          </ul>
        </div>
      </div>
      {profileImageSrc && (
        <GroupProfileCropper
          setShow={setShow}
          show={show}
          profileImageSrc={profileImageSrc}
          setGetProfilePicture={setGetProfilePicture}
          profileRef={profileRef}
          groupId={groupData?.id}
          onSuccess={getGroupAbout}
        />
      )}
    </>
  );
}
