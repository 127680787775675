import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { json, Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Topbar from "./Topbar";
import { CommonAPi } from "../../../API/CommonApi";
import { UpdateAPi } from "../../../API/UpdateApi";
import { FetchApi } from "../../../API/FetchApi";
import { Endpoints } from "../../../API/Endpoints";
import { DeleteApi } from "../../../API/DeleteApi";
import ToastMessage from "../../../Utils/ToastMessage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDropzone } from "react-dropzone";
import AditionalDragDrop from "./AditionalDragDrop";
import DiscardPopup from "../../../Components/DiscardPopup/DiscardPopup";
import { Helmet } from "react-helmet";
const $ = window.jQuery;

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  transition: "border .3s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
export default function Curriculum() {
  const param = useParams();
  const [sectionName, setSectionName] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [sectionData, setSectionData] = useState([]);
  const [sectionStatus, setSectionStatus] = useState(false);

  const [lectureName, setLectureName] = useState("");
  const [lectureDescription, setLectureDescription] = useState("");
  const [lectureId, setLectureId] = useState("");
  const [activeSectionId, setActiveSectionId] = useState("");

  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragItemLecture = useRef();
  const dragOverItemLecture = useRef();
  const navigate = useNavigate();
  const [files, setFiles] = useState("");
  const [Aditionalfiles, setAditionalFiles] = useState([]);
  const [filesPreview, setFilesPreview] = useState("");
  const [filesPreviewStatus, setFilesPreviewStatus] = useState(true);
  const [resourceLink, setResourceLink] = useState([
    {
      title: "",
      link: "",
    },
  ]);

  const [sectionNameError, setSectionNameError] = useState("");
  const [lectureNameError, setLectureNameError] = useState("");
  const [lectureDescriptionError, setLectureDescriptionError] = useState("");
  const [resourceLinkError, setResourceLinkError] = useState("");

  const [sectionDisabled, setSectionDisabled] = useState(true);
  const [lectureDisabled, setLectureDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [filesPreviewAdd, setFilesPreviewAdd] = useState([]);

  const closeModel = () => {
    setSectionName("");
    setSectionId("");
  };

  useEffect(() => {
    if (sectionName !== "") {
      setSectionDisabled(false);
    } else {
      setSectionDisabled(true);
    }
  }, [sectionName]);

  useEffect(() => {
    if (
      lectureName !== "" &&
      lectureDescription !== "" &&
      filesPreview !== ""
    ) {
      setLectureDisabled(false);
    } else {
      setLectureDisabled(true);
    }
  }, [lectureName, lectureDescription, filesPreview]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles[0]);
    setFilesPreview("");
    setFilesPreviewStatus(true);
    setFilesPreview(URL.createObjectURL(acceptedFiles[0]));
  }, []);

  useEffect(() => {
    getSection();
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const addResource = () => {
    let data = {
      title: "",
      link: "",
    };
    setResourceLink([...resourceLink, data]);
  };

  const RemoveResourceLink = (index) => {
    if (resourceLink.length > 1) {
      const newresourceLinkData = resourceLink.filter((item, i) => i !== index);
      setResourceLink(newresourceLinkData);
    }
  };

  const handleResourceLink = (val, index, type) => {
    let temp = resourceLink.slice();
    if (type === "title") {
      temp[index].title = val;
    }
    if (type === "link") {
      temp[index].link = val;
    }

    setResourceLink(temp);
  };

  const handleLecture = (Id) => {
    setActiveSectionId(Id);
  };

  const handleSubmitLeacture = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!lectureName) {
      setLectureNameError("Please enter lecture name");
      return false;
    } else if (lectureName) {
      setLectureNameError("");
    }

    const data = new FormData();
    data.append("course_section_id", activeSectionId);
    data.append("lecture_name", lectureName);
    data.append("course_id", param.id);
    data.append("lecture_order", "1");
    data.append("resource_link", JSON.stringify(resourceLink) || null);
    if (lectureDescription) {
      data.append("lecture_description", lectureDescription);
    }
    if (files) {
      data.append("lecture-media", files);
    }

    if (Aditionalfiles) {
      for (var i = 0; i < Aditionalfiles.length; i++) {
        if (Aditionalfiles[i].type !== "response") {
          data.append("lecture-resource", Aditionalfiles[i]);
        }
      }
    }

    if (lectureId) {
      try {
        let resp = await UpdateAPi(
          Endpoints.UpdateCourseLecture + "/" + lectureId,
          data
        );
        if (resp && resp.message) {
          setLoading(false);
          document.getElementById("lectureForm").reset();
          setLectureId("");
          setFilesPreviewStatus(false);
          setLectureDescription("");
          setLectureName("");
          setFilesPreview("");
          setAditionalFiles([]);
          setFiles("");
          $("#Lecturemodal").modal("hide");
          // additionalRef.current.value = ''
          setResourceLink([
            {
              title: "",
              link: "",
            },
          ]);
          getSection();
          ToastMessage.Success(resp.message);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          ToastMessage.Error(e.response.data.message);
          setLoading(false);
        }
      }
    } else {
      try {
        let resp = await CommonAPi(Endpoints.CreateCourseLecture, data);
        if (resp && resp.message) {
          document.getElementById("lectureForm").reset();
          setLectureId("");
          setFilesPreviewStatus(false);
          setLectureDescription("");
          setLectureName("");
          setFilesPreview("");
          setAditionalFiles([]);
          setFiles("");
          getSection();
          setLoading(false);
          setResourceLink([
            {
              title: "",
              link: "",
            },
          ]);
          $("#Lecturemodal").modal("hide");
          ToastMessage.Success(resp.message);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          setLoading(false);
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSubmitSection = async (event) => {
    event.preventDefault();
    setSectionStatus(true);
    if (!sectionName) {
      setSectionNameError("Please enter section name");
    } else if (sectionName) {
      setSectionNameError("");
    }

    if (sectionName) {
      let data = {
        course_id: param.id,
        section_title: sectionName,
        section_order: "1",
      };
      if (sectionId) {
        try {
          let resp = await UpdateAPi(
            Endpoints.UpdateCourseSection + "/" + sectionId,
            data
          );
          if (resp && resp.message) {
            setSectionStatus(false);
            getSection();
            setSectionId("");
            setSectionName("");
            $("#sectionmodal").modal("hide");
            ToastMessage.Success(resp.message);
          }
        } catch (e) {
          setSectionStatus(false);
          if (e && e.response && e.response.data && e.response.data.message) {
            ToastMessage.Error(e.response.data.message);
          }
        }
      } else {
        try {
          let resp = await CommonAPi(Endpoints.CreateCourseSection, data);
          if (resp && resp.message) {
            setSectionStatus(false);
            getSection();
            setSectionName("");
            $("#sectionmodal").modal("hide");
            ToastMessage.Success(resp.message);
          }
        } catch (e) {
          setSectionStatus(false);
          if (e && e.response && e.response.data && e.response.data.message) {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const getSection = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetCourseSection + "/" + param.id);
      if (resp && resp.success === true) {
        setSectionData(resp.data);
      } else {
        setSectionData(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSectionEdit = async (Id) => {
    setSectionId(Id);
    $("#sectionmodal").modal("show");
    try {
      let resp = await FetchApi(Endpoints.GetSingalCourseSection + "/" + Id);
      if (resp && resp.success === true) {
        setSectionName(resp.data.sectionTitle);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSectionDelete = async (Id) => {
    try {
      let resp = await DeleteApi(
        Endpoints.DeleteCourseSection + "/" + Id + "/" + param.id
      );
      if (resp && resp.success === true) {
        getSection();
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleLectureEdit = async (Id, sectionId) => {
    setLectureId(Id);
    setActiveSectionId(sectionId);
    setFilesPreviewStatus(true);
    $("#Lecturemodal").modal("show");
    try {
      let resp = await FetchApi(Endpoints.GetSingalCourseLecture + "/" + Id);
      if (resp && resp.success === true) {
        setLectureName(resp.data.lectureName);
        setLectureDescription(resp.data.lectureDescription);
        setFilesPreview(Endpoints.baseUrl + resp.data.lectureMedia);
        if (resp.data.lectureResource !== null) {
          let newlectureResource = resp.data.lectureResource.map((item) => {
            return {
              name: item.resource.split("/")[1].substr(37),
              type: "response",
            };
          });
          console.log("newlectureResource________", newlectureResource);
          setAditionalFiles(newlectureResource);
        }
        setResourceLink(resp.data.resourceLink);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleLectureDelete = async (Id) => {
    try {
      let resp = await DeleteApi(
        Endpoints.DeleteCourseLecture + "/" + Id + "/" + param.id
      );
      if (resp && resp.success === true) {
        getSection();
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const dragStart = (e, position) => {
    dragItem.current = position;
    // console.log(e.target.innerHTML);
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    // console.log(e.target.innerHTML);
  };
  const dragLectureStart = (e, position) => {
    dragItemLecture.current = position;
    // console.log(e.target.innerHTML);
  };

  const dragLectureEnter = (e, position) => {
    dragOverItemLecture.current = position;
    // console.log(e.target.innerHTML);
  };

  const drop = async (e) => {
    const copyListItems = [...sectionData];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    console.log(copyListItems);
    const newData = copyListItems.map((item, index) => {
      return {
        section_id: item.id,
        section_order: index + 1,
      };
    });

    try {
      let resp = await UpdateAPi(
        Endpoints.UpdateCourseSectionOrder + "/" + param.id,
        newData
      );
      if (resp && resp.success === true) {
        // setLectureName(resp.data.lectureName);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }

    // console.log("update_order", newData);
    setSectionData(copyListItems);
  };
  const dropLecture = async (index) => {
    const copyListItems = sectionData.filter((item, idx) => idx === index)[0]
      .course_lectures;

    const dragItemLectureContent = copyListItems[dragItemLecture.current];
    copyListItems.splice(dragItemLecture.current, 1);
    copyListItems.splice(
      dragOverItemLecture.current,
      0,
      dragItemLectureContent
    );
    dragItemLecture.current = null;
    dragOverItemLecture.current = null;

    const newData = copyListItems.map((item, index) => {
      return {
        lecture_id: item.id,
        lecture_order: index + 1,
      };
    });

    try {
      let resp = await UpdateAPi(
        Endpoints.UpdateCourseLectureOrder + "/" + param.id,
        newData
      );
      if (resp && resp.success === true) {
        getSection();
        // ToastMessage.Success(resp.message)
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }

    // console.log("update_order", newData);
    setSectionData(copyListItems);
  };
  const handleNext = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetCourseSection + "/" + param.id);
      if (resp && resp.success === true) {
        // setSectionData(resp.data);
        if (resp.data.length === 0) {
          ToastMessage.Info("Please add atleast one section");
        }

        if (resp.data.length > 0) {
          let lectureLength = 0;

          resp.data.map((item, i) => {
            lectureLength = lectureLength + item.course_lectures.length;
          });

          if (lectureLength === 0) {
            ToastMessage.Info("Please add atleast one lecture");
          }

          if (lectureLength > 0) {
            navigate("/add-courses-publish" + "/" + param.id);
          }
        }
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleValidation = async (e, value, field) => {
    if (field !== "lectureDescription") {
      e.preventDefault();
    }

    if (!value && field === "sectionName") {
      setSectionNameError("Please enter section name");
    } else if (value && field === "sectionName") {
      setSectionNameError("");
    }
    if (!value && field === "lectureName") {
      setLectureNameError("Please enter lecture name");
    } else if (value && field === "lectureName") {
      setLectureNameError("");
    }
    // if (!value && field === "resourceLink") {
    //     setResourceLinkError("Please enter resource link");
    // } else if (value && field === "resourceLink") {
    //     setResourceLinkError("");
    // }

    if (!value && field === "lectureDescription") {
      setLectureDescriptionError("Please enter lecture description");
    } else if (value && field === "lectureDescription") {
      setLectureDescriptionError("");
    }
  };

  const handleCloseModel = () => {
    if (
      Aditionalfiles.length !== 0 ||
      files !== "" ||
      resourceLink[0].link !== "" ||
      resourceLink[0].title !== "" ||
      lectureDescription !== "" ||
      lectureName !== ""
    ) {
      $("#discardInfoModal-Lecture").modal("show");
    }
  };

  const handleDisCard = () => {
    setLectureName("");
    setLectureDescription("");
    setLectureId("");
    setActiveSectionId("");
    setAditionalFiles([]);
    setFilesPreview("");
    setFiles("");
    setFilesPreviewStatus(false);
    setResourceLink([
      {
        title: "",
        link: "",
      },
    ]);
    $("#discardInfoModal-Lecture").modal("hide");
    $("#Lecturemodal").modal("hide");
  };

  const handleModel = () => {
    $("#discardInfoModal-Lecture").modal("hide");
    $("#Lecturemodal").modal("show");
  };

  return (
    <Container header>
      <Helmet>
        <title>Curriculum</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-3">
            <div className="bg-white main-common p-0 py-3">
              <Topbar />

              <div className="border-bottom py-lg-4 py-3 px-lg-4 px-3">
                <div className="d-sm-flex align-items-center justify-content-between">
                  <h2>Course Curriculum</h2>
                  <div className="save-close-btns">
                    <Link to="/mycourses" className="btn btn-br-blue py-2">
                      Save & Close
                    </Link>
                    <Link
                      to={"/course-preview" + "/" + param.id}
                      target="_blank"
                      className="btn btn-br-blue ms-3 py-2"
                    >
                      Save & Preview
                    </Link>
                  </div>
                </div>
              </div>

              <form action="" className="teacher-form generic-form">
                <div className="p-lg-4 p-1">
                  {sectionData.map((item, index) => {
                    return (
                      <div
                        className="p-3 box-shadow mb-4 rounded-10"
                        onDragStart={(e) => dragStart(e, index)}
                        onDragEnter={(e) => dragEnter(e, index)}
                        onDragEnd={drop}
                        key={index}
                        draggable
                      >
                        <div className="d-flex align-items-center justify-content-between pb-4">
                          <div className="d-flex align-items-center gap-md-3 gap-1">
                            <Link to="#">
                              <img
                                src="../assets/img/icon/icon-drag.svg"
                                alt=""
                              />
                            </Link>
                            <h3 className="medium section-title">
                              {item?.sectionTitle} :
                            </h3>
                            {index + 1 > 9 ? (
                              <h5 className="text-333 regular section-order">
                                Section {index + 1}
                              </h5>
                            ) : (
                              <h5 className="text-333 regular section-order">
                                Section 0{index + 1}
                              </h5>
                            )}
                            {/* <h5 className="text-333 regular">{item?.sectionTitle}</h5> */}
                          </div>

                          <div className="text-end">
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#Lecturemodal"
                              onClick={() => handleLecture(item.id)}
                            >
                              <img
                                src="../assets/img/icon/icon-add.svg"
                                alt=""
                                className="section-imgs"
                              />
                            </Link>
                            <Link
                              to="#"
                              onClick={() => handleSectionEdit(item.id)}
                              className="px-2"
                            >
                              <img
                                src="../assets/img/icon/icon-edit3.svg"
                                alt=""
                                className="section-imgs"
                              />
                            </Link>
                            <Link
                              to="#"
                              onClick={() => handleSectionDelete(item.id)}
                            >
                              <img
                                src="../assets/img/icon/icon-delete.svg"
                                alt=""
                                className="section-imgs"
                              />
                            </Link>
                          </div>
                        </div>
                        {item?.course_lectures?.length > 0 ? (
                          item.course_lectures.map((lectureItem, i) => {
                            return (
                              <div
                                className="mt-3"
                                onDragStart={(e) => dragLectureStart(e, i)}
                                onDragEnter={(e) => dragLectureEnter(e, i)}
                                onDragEnd={() => dropLecture(index)}
                                key={i}
                                draggable
                              >
                                <div className="lecture-item p-md-3 p-1 d-flex align-items-center justify-content-between">
                                  <div
                                    className="d-flex align-items-center gap-md-3 gap-1"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#lecturenote" + i}
                                    aria-expanded="false"
                                    aria-controls="lecturenote2"
                                  >
                                    <a href="#">
                                      <img
                                        src="../assets/img/icon/icon-drag.svg"
                                        alt=""
                                      />
                                    </a>
                                    <h5 className="medium">
                                      {lectureItem.lectureName}
                                    </h5>
                                  </div>

                                  <div>
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        handleLectureEdit(
                                          lectureItem.id,
                                          item.id
                                        )
                                      }
                                      className="px-2"
                                    >
                                      <img
                                        src="../assets/img/icon/icon-edit3.svg"
                                        alt=""
                                      />
                                    </Link>
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        handleLectureDelete(lectureItem.id)
                                      }
                                    >
                                      <img
                                        src="../assets/img/icon/icon-delete.svg"
                                        alt=""
                                      />
                                    </Link>
                                  </div>
                                </div>

                                <div
                                  className="collapse "
                                  id={"lecturenote" + i}
                                >
                                  <div
                                    className="card card-body border-0 fs-14 ck"
                                    dangerouslySetInnerHTML={{
                                      __html: lectureItem.lectureDescription,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="mt-1 p-2">No lecture added</div>
                        )}
                      </div>
                    );
                  })}

                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#sectionmodal"
                    className="btn btn-light-blue rounded-3 medium w-100"
                  >
                    Add Section
                  </Link>
                </div>

                <div className="p-3 d-flex align-items-center justify-content-between border-top">
                  <Link
                    to={"/add-courses-advance-information" + "/" + param.id}
                    className="btn light-brown-btn medium  fs-14"
                  >
                    <img
                      src="../assets/img/icon/arrow-left-brown.svg"
                      className="pe-1"
                      alt=""
                    />{" "}
                    Previous
                  </Link>
                  <a
                    href="#"
                    onClick={handleNext}
                    className="btn  border-0 medium fs-14"
                  >
                    Save & Next{" "}
                    <img
                      src="../assets/img/icon/icon-right-arrow.svg"
                      className="ps-1"
                      alt=""
                    />
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
        <OnlineFriends />
      </div>
      {/* Section model code */}
      <div
        className="modal fade"
        id="sectionmodal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog rounded-10 box-shadow p-lg-4 p-md-3 p-2 bg-white">
          <div className="modal-content border-0">
            <div className="d-flex align-items-center justify-content-between pb-3 border-bottom mb-3 w-100">
              {sectionId === "" || sectionId === null ? (
                <h3 className="medium text-blue">Add New Section</h3>
              ) : (
                <h3 className="medium text-blue">Edit Section</h3>
              )}
              <button
                type="button"
                className="btn-close kids-close-icon section cursor-pointer"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModel}
              >
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#949494"
                  width="22"
                  height="22"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              {sectionId === "" || sectionId === null ? (
                <h4 className="medium text-black pb-3">
                  Fill the form below to create New Section
                </h4>
              ) : (
                <h4 className="medium text-black pb-3">
                  Fill the form below to update Section
                </h4>
              )}
              <form
                onSubmit={handleSubmitSection}
                className="kids-form generic-form"
              >
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input
                      type="text"
                      placeholder="Section name"
                      className={
                        sectionNameError
                          ? "is-danger text-gray px-3 py-3 border"
                          : " text-gray px-3 py-3 border"
                      }
                      value={sectionName}
                      onBlur={(e) => {
                        handleValidation(e, e.target.value, "sectionName");
                      }}
                      onChange={(e) => {
                        setSectionName(e.target.value);
                      }}
                    />
                    {sectionNameError ? (
                      <div
                        className="form-error"
                        style={{
                          color: "white",
                          margintop: "-10px",
                          marginBottom: "-10px",
                        }}
                      >
                        {sectionNameError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {sectionStatus === false ? (
                    <div className="col-md-12 text-end">
                      <button
                        type="submit"
                        disabled={sectionDisabled}
                        className="btn fs-14 "
                      >
                        {" "}
                        {sectionId === "" || sectionId === null
                          ? "Create"
                          : "Update"}{" "}
                        Section{" "}
                        <img
                          src="../assets/img/icon/icon-right-arrow.svg"
                          alt=""
                        />
                      </button>
                    </div>
                  ) : (
                    <button className="btn fs-14 " disabled={loading}>
                      <span className="spinner-grow spinner-grow-sm "></span>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Lecture Model Code */}

      <div
        className="modal fade "
        id="Lecturemodal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog lecture-modal-dialog rounded-10 box-shadow p-lg-4 p-md-3 p-2 bg-white modal-650p">
          <div className="modal-content border-0">
            <div className="d-flex align-items-center justify-content-between pb-3 border-bottom mb-3 w-100">
              <h3 className="medium text-blue">
                {lectureId ? "Update" : "Add"} Lecture
              </h3>
              <button
                type="button"
                className="btn-close kids-close-icon Lecture cursor-pointer"
                onClick={handleCloseModel}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#949494"
                  width="22"
                  height="22"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <h4 className="medium text-black pb-3">
                Fill the form below to create lecture
              </h4>
              <form
                onSubmit={handleSubmitLeacture}
                className="kids-form generic-form"
                id="lectureForm"
              >
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input
                      type="text"
                      placeholder="Lecture name"
                      className={
                        lectureNameError
                          ? "is-danger text-gray px-3 py-3 border"
                          : "text-gray px-3 py-3 border"
                      }
                      value={lectureName}
                      onBlur={(e) => {
                        handleValidation(e, e.target.value, "lectureName");
                      }}
                      onChange={(e) => {
                        setLectureName(e.target.value);
                      }}
                    />
                    {lectureNameError ? (
                      <div
                        className="form-error"
                        style={{
                          color: "white",
                          margintop: "-10px",
                          marginBottom: "-10px",
                        }}
                      >
                        {lectureNameError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <CKEditor
                      data={lectureDescription}
                      className={
                        lectureDescriptionError
                          ? "is-danger editor text-gray px-3 py-3 border"
                          : "editor text-gray px-3 py-3 border"
                      }
                      cols="30"
                      rows="10"
                      onBlur={(event, editor) => {
                        handleValidation(
                          event,
                          editor.getData(),
                          "lectureDescription"
                        );
                      }}
                      onChange={(event, editor) => {
                        setLectureDescription(editor.getData());
                      }}
                      editor={ClassicEditor}
                      config={{
                        toolbar: [
                          "bold",
                          "italic",
                          "link",
                          "blockQuote",
                          "numberedList",
                          "bulletedList",
                          "|",
                          "undo",
                          "redo",
                        ],
                      }}
                    />
                    {lectureDescriptionError ? (
                      <div
                        className="form-error"
                        style={{
                          color: "white",
                          margintop: "-10px",
                          marginBottom: "-10px",
                        }}
                      >
                        {lectureDescriptionError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <section>
                      <div {...getRootProps({ style })}>
                        <input {...getInputProps()} accept="video/*" />
                        <h3 className="upload-lecture-heading">
                          Upload Lecture Video
                        </h3>
                        <div className="drag">
                          Drag and drop a file or <u>browse a file</u>
                        </div>
                      </div>
                      <aside>
                        <span className="d-flex my-2">
                          {filesPreviewStatus === true ? (
                            <>
                              {filesPreview ? (
                                <video
                                  src={filesPreview}
                                  style={{
                                    maxWidth: "400px",
                                    maxHeight: "200px",
                                    margin: "0px auto",
                                  }}
                                  controls
                                  muted
                                  autoPlay={"autoplay"}
                                  preLoad="auto"
                                  loop
                                ></video>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </span>
                      </aside>
                    </section>
                  </div>
                  {Aditionalfiles !== null ? (
                    <AditionalDragDrop
                      {...{
                        setAditionalFiles,
                        filesPreviewStatus,
                        setFilesPreviewStatus,
                        Aditionalfiles,
                        filesPreviewAdd,
                        setFilesPreviewAdd,
                      }}
                    />
                  ) : (
                    <AditionalDragDrop
                      {...{
                        setAditionalFiles,
                        filesPreviewStatus,
                        setFilesPreviewStatus,
                        Aditionalfiles,
                        filesPreviewAdd,
                        setFilesPreviewAdd,
                      }}
                    />
                  )}
                  <div className="text-end border-bottom mb-3">
                    <Link
                      onClick={addResource}
                      className="text-brown medium fs-18 text-end"
                    >
                      Add +
                    </Link>
                  </div>
                  {resourceLink.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={
                            index > 0 ? "col-md-5 mb-3" : "col-md-5 mb-3"
                          }
                        >
                          <input
                            type="text"
                            placeholder="Title"
                            className={
                              resourceLinkError
                                ? "is-danger text-gray px-3 py-3 border"
                                : "text-gray px-3 py-3 border"
                            }
                            value={item.title}
                            // onBlur={(e) => { handleValidation(e, e.target.value, "resourceLink") }}
                            onChange={(e) => {
                              handleResourceLink(
                                e.target.value,
                                index,
                                "title"
                              );
                            }}
                          />
                        </div>
                        <div
                          className={
                            index > 0 ? "col-md-6 mb-3" : "col-md-6 mb-3"
                          }
                        >
                          <input
                            type="text"
                            placeholder="URL- https://www.sampledomain.com"
                            className={
                              resourceLinkError
                                ? "is-danger text-gray px-3 py-3 border"
                                : "text-gray px-3 py-3 border"
                            }
                            value={item.link}
                            onChange={(e) => {
                              handleResourceLink(e.target.value, index, "link");
                            }}
                          />
                        </div>

                        {index > 0 ? (
                          <div className=" col-md-1 mb-3">
                            <Link onClick={() => RemoveResourceLink(index)}>
                              <img src="../assets/img/icon/remove.svg" alt="" />
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    );
                  })}

                  <div className="col-md-12 text-end">
                    {loading === true ? (
                      <button className="btn fs-14 " disabled={loading}>
                        <span className="spinner-grow spinner-grow-sm "></span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        disabled={lectureDisabled}
                        className="btn fs-14 "
                      >
                        {lectureId ? "Update" : "Create"} Lecture{" "}
                        <img
                          src="../assets/img/icon/icon-right-arrow.svg"
                          alt=""
                        />
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <DiscardPopup
        {...{
          type: "Lecture",
          onSuccess: handleModel,
          onDisCard: handleDisCard,
        }}
      />
    </Container>
  );
}
