import React, { useState } from "react";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import PeopleItem from "../PeopleItem";
import Profile from "./Profile";
import Sidebar from "./Sidebar";
import { Helmet } from "react-helmet";
export default function Member() {
  const [totalPeoples, setTotalPeoples] = useState(0);
  const [SearchText, setSearchText] = useState("");

  return (
    <Container header>
      <Helmet>
        <title>Group Member</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div class="container py-lg-4 py-3">
            <Profile />
            <div class="row">
              <Sidebar />

              <div class="col-lg-9 col-md-8">
                <div class="bg-white box-shadow rounded-15 p-md-3 p-2">
                  <div class="pb-3 d-md-flex align-items-center justify-content-between">
                    <h3 class="medium fs-20">
                      Followers <span class="text-gray">- {totalPeoples}</span>
                    </h3>

                    {/* <select name="" id="" class=" w-auto px-3 rounded-pill bg-eee text-black py-1 posts-profile-select">
                                        <option value="">All</option>
                                        <option value="">Posts</option>
                                        <option value="">Photos</option>
                                        <option value="">Videos</option>
                                        <option value="">Pages</option>
                                        <option value="">Groups</option>
                                        <option value="">Links</option>
                                    </select> */}
                  </div>

                  <form
                    action=""
                    class="bg-eee py-2 mb-4 rounded-pill d-flex flex-row-reverse align-items-center justify-content-end w-100 px-lg-4"
                  >
                    <input
                      type="text"
                      placeholder="Search members"
                      name="search"
                      class="bg-transparent fs-14 medium"
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                    <button type="submit" class="bg-transparent border-0 ps-0">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="#949494"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        class="css-i6dzq1"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </button>
                  </form>

                  <PeopleItem
                    {...{
                      type: "friend",
                      setTotalPeoples: setTotalPeoples,
                      totalPeoples: totalPeoples,
                      SearchText: SearchText,
                    }}
                  />

                  <PeopleItem
                    {...{
                      type: "unfriend",
                      setTotalPeoples: setTotalPeoples,
                      totalPeoples: totalPeoples,
                      SearchText: SearchText,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
