import axios from 'axios';
import ToastMessage from '../../Utils/ToastMessage';
import { Endpoints } from '../Endpoints';

export const DeleteApi = (endpoint,data,loader=false) =>{
    return new Promise(async(resolve, reject)=>{

        let options = {
            profile:localStorage.getItem("userId"),
            'Authorization':"Bearer " + localStorage.getItem('token')
        };

        let configs = {
            headers : options,
            method :  'DELETE',
            baseURL : Endpoints.baseUrl,
            url : endpoint,
            data:data,
        }
        
        try{
            let resp = await axios(configs);
            if(resp && resp.data.status==401){
                ToastMessage.Error('Session Expired! Please login again.');
                return;
            }
            if(resp.data.status==210){
                if(resp.data.message){
                    ToastMessage.Error(resp.data.message);
                }
                else if(resp.data.errors && typeof(resp.data.errors)=="string"){
                    ToastMessage.Error(resp.data.errors);
                }
                else if(typeof(resp.data.errors)=="object"){
                    ToastMessage.Error(resp.data.errors.errorCode);
                }
                reject(resp.data);
                return;
            }
            else{
                resolve(resp.data);
            }
        }
        catch(e){
            if(e.response && e.response.status==401){
            }
            if(e.response && e.response.status==500){
                ToastMessage.Error('Server Error. Please Try after Some Time')
            }
            reject(e);
        }
    })
}