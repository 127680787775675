import React, { useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Endpoints } from "../../../API/Endpoints";
const $ = window.jQuery;
export default function CourseItem({ item, index, courseStatus }) {
  useEffect(() => {
    function animateProgressBar(el, width) {
      el.animate(
        { width: width },
        {
          duration: 2000,
          step: function (now, fx) {
            if (fx.prop === "width") {
              // el.html(Math.round(now * 100) / 100 + '%');
            }
          },
        }
      );
    }
    $(".progress").each(function () {
      animateProgressBar($(this).find("div"), $(this).data("width"));
    });
  }, [item, courseStatus]);

  const renderPrograss = useCallback(() => {
    return (
      <>
        <div
          className="progress"
          key={item.id}
          id={"progress_" + item.id}
          data-width={parseInt(item.percentCompleted) + "%"}
        >
          <div className="progress-active"></div>
        </div>
      </>
    );
  }, [item, courseStatus]);
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 mb-3" key={index}>
      <div className="rounded-15 overflow-hidden box-shadow">
        {item.thumbnail === null ? (
          <img
            src={"assets/img/courses1.png"}
            alt=""
            height="125"
            className="w-100"
          />
        ) : (
          <img
            src={Endpoints.baseUrl + item.thumbnail}
            alt=""
            height="125"
            className="w-100"
            style={{ objectFit: "cover" }}
          />
        )}
        <div className="p-2">
          <h5 className="medium pb-2">
            {item?.title?.length < 22
              ? item?.title
              : item?.title.substr(0, 22) + "..."}
          </h5>
          <div className="d-flex">
            {item.instructors.map((ite, ind) => {
              return (
                <h6 className="fs-12 text-brown" key={ind}>
                  {" "}
                  {ite.fullName}
                  {item.instructors.length === parseInt(ind) + 1 ? " " : ", "}
                </h6>
              );
            })}
          </div>

          <div className="video-complete py-2">
            {item.percentCompleted !== null ? renderPrograss() : ""}
            <h6 className="fs-10 pt-1">
              {item.percentCompleted >= 100 ? 100 : item.percentCompleted}%
              Complete
            </h6>
          </div>
          {parseInt(item.percentCompleted) > 0 ? (
            <div className="text-center pb-2">
              <Link
                to={"/start-learning/" + item.id}
                className="btn fs-14 btn-light-blue py-2"
              >
                {" "}
                {item.percentCompleted >= 100
                  ? "Watch Again"
                  : "Continue Watching"}{" "}
                <img src="assets/img/icon/play2.svg" />
              </Link>
            </div>
          ) : (
            <div className="text-center pb-2">
              <Link
                to={"/start-learning/" + item.id}
                className="btn fs-14 py-2"
              >
                Start Course <img src="assets/img/icon/play1.svg" />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
