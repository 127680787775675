import React, { useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import CompanyProfiles from "./CompanyProfiles";
import FollowItem from "./FollowItem";
import { Helmet } from "react-helmet";
import LeftStatistics from "./LeftStatistics";

export default function CompanyFollowers() {
  const [totalFollowers, setTotalFollowers] = useState(0);
  const [SearchText, setSearchText] = useState("");
  const [statistics, setStatistics] = useState("");
  return (
    <Container header>
      <Helmet>
        <title>Follower</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-lg-4 py-3">
            <CompanyProfiles />
            <div className="row">
              <LeftStatistics
                {...{
                  statistics: statistics,
                }}
              />
              <div class="col-lg-9 col-md-8">
                <div class="bg-white box-shadow rounded-15 p-md-3 p-2">
                  <div class="pb-3 d-md-flex align-items-center justify-content-between">
                    <h3 class="medium fs-20">
                      Followers{" "}
                      <span class="text-gray">- {totalFollowers} </span>
                    </h3>
                  </div>

                  <form
                    action=""
                    class="bg-eee py-2 rounded-pill d-flex flex-row-reverse align-items-center justify-content-end w-100 px-lg-4"
                  >
                    <input
                      type="text"
                      placeholder="Search members"
                      name="search"
                      class="bg-transparent fs-14 medium"
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                    <button type="submit" class="bg-transparent border-0 ps-0">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="#949494"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        class="css-i6dzq1"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </button>
                  </form>

                  <FollowItem
                    {...{
                      type: "friend",
                      setTotalFollowers: setTotalFollowers,
                      totalFollowers: totalFollowers,
                      SearchText: SearchText,
                      setStatistics: setStatistics,
                    }}
                  />
                  <FollowItem
                    {...{
                      type: "unfriend",
                      setTotalFollowers: setTotalFollowers,
                      totalFollowers: totalFollowers,
                      SearchText: SearchText,
                      setStatistics: setStatistics,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
