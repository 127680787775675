import { Modal, Button } from "react-bootstrap";

const DetachModal = ({ show, onHide, onClick }) => {
  return (
    <Modal
      show={show}
      className="d-flex justify-content-center align-items-center"
    >
      <div className="w-100 d-flex flex-column justify-content-center align-items-center p-5 gap-10 ">
        <div className=" text-center">
          You are about to remove this child from your profile, making their
          account independent. Please ensure that this account has a valid email
          address, as the user will receive login instructions via email. Do you
          want to proceed?
        </div>
        <hr />
        <div className="d-flex gap-2">
          <Button
            onClick={onHide}
            className="border-0"
            style={{ backgroundColor: "#838383" }}
          >
            Cancel
          </Button>
          <Button onClick={onClick} className="px-4">
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DetachModal;
