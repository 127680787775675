import PostTitleDraftView from "./PostTitleDraftView";
import { Endpoints } from "../../API/Endpoints";
import { Link } from "react-router-dom";

const SharePostModal = ({
    post,
    key,
    formatDateTime,
    timeSince,
    type,
    mediaIndex,
    sharePost
}) => {

    console.log("post________", post);
    return (
        <li
            className={`feed-item-share-post-modal bg-white`}
            key={key}
        >
            <div className="d-flex align-items-start feed-top mb-1 position-relative">
                <div className="circular--landscape pic-size-48-flex">
                    <Link to={"/timeline/" + post?.username}>
                        <img src={Endpoints.baseUrl + post?.profileImg} alt="" />
                    </Link>
                </div>
                <div className="ps-2 pt-1">
                    <Link to={"/timeline/" + post.username}>
                        <h6 className="medium text-black">
                            {post.postCreator}

                        </h6>
                    </Link>

                    <span
                        className="d-flex align-items-center"
                        style={{ height: "14px" }}
                    >
                        <span className="fs-10 regular text-darkgray py-1">
                            {post.profileTagline}
                        </span>
                        <span className="text-gray px-2">·</span>
                        <img src="assets/img/icon/icon-privacy2.svg" alt="" />
                    </span>
                </div>
                <div className="ms-auto d-flex align-items-center">
                    <h6
                        className="fs-12 regular text-darkgray pe-2 post-time"
                        title={formatDateTime(post.createdAt)}
                    >
                        {timeSince(new Date(post.createdAt))} ago
                    </h6>
                </div>
            </div>

            {type == "post" ?
                <>
                    {
                        <div className="feed-desc" style={{ wordBreak: "break-all" }}>
                            <PostTitleDraftView title={post.title} key={post.title} />
                        </div>
                    }

                    {post?.post_medias?.length === 1 ? (
                        <div className="feed-image">
                            {post.post_medias[0].filePath.includes(".mp4") ? (
                                <div className="position-relative">
                                    <video
                                        controls={false}
                                        style={{ maxHeight: "400px", background: "rgba(0,0,0,0.6)" }}
                                        height={"auto"}
                                        width="100%"
                                        controlsList="nodownload"
                                    >
                                        <source
                                            src={Endpoints.baseUrl + post.post_medias[0].filePath}
                                            className="w-100 feed-gal-item"
                                        />
                                    </video>
                                    <div
                                        className="position-absolute top-0 bottom-0 start-0 end-0"

                                    />
                                </div>
                            ) : (
                                <img
                                    src={Endpoints.baseUrl + post.post_medias[0].filePath}
                                    alt="user-post-media"

                                    className="w-100 feed-gal-item"
                                    style={{
                                        minHeight: "20rem",
                                        maxHeight: "45rem",
                                        objectFit: "cover",
                                    }}

                                />
                            )}
                        </div>
                    ) : (
                        <>
                            {post?.post_medias?.length === 3 && (
                                <>
                                    <div className="feed-gallery">
                                        {post.post_medias.map(
                                            (postMedia, i) =>
                                                i <= 3 && (
                                                    <div key={i} className={`feed-gal-item feed-third${i}`}>
                                                        <a href="javascript:void(0)">
                                                            {postMedia.filePath.includes(".mp4") ? (
                                                                <div className="position-relative">
                                                                    <video
                                                                        controls={false}
                                                                        height={"auto"}
                                                                        width="100%"
                                                                        className="embed-responsive-item withVideo"
                                                                        controlsList="nodownload"
                                                                    >
                                                                        <source
                                                                            src={Endpoints.baseUrl + postMedia.filePath}
                                                                        />
                                                                    </video>
                                                                    <div
                                                                        className="position-absolute top-0 bottom-0 start-0 end-0"

                                                                    />
                                                                </div>
                                                            ) : (
                                                                <img
                                                                    src={Endpoints.baseUrl + postMedia.filePath}
                                                                    alt="user-post-media"
                                                                    className="w-100 h-100 img-fluid"

                                                                />
                                                            )}
                                                            {i === 3 && post?.post_medias?.length - i - 1 > 0 && (
                                                                <span
                                                                    className="feed-more-gal"

                                                                >
                                                                    {post?.post_medias?.length - i - 1 + "+"}
                                                                </span>
                                                            )}
                                                        </a>
                                                    </div>
                                                )
                                        )}


                                    </div>

                                </>
                            )}

                            {(post?.post_medias?.length > 3 || post?.post_medias?.length === 2) && (
                                <>
                                    <div className="feed-gallery">
                                        {post.post_medias.map(
                                            (postMedia, i) =>
                                                i <= 3 && (
                                                    <div key={i} className="feed-gal-item">
                                                        <a href="javascript:void(0)">
                                                            {postMedia.filePath.includes(".mp4") ? (
                                                                <div className="position-relative">
                                                                    <video
                                                                        controls={false}
                                                                        width={"100%"}
                                                                        height={"auto"}
                                                                        className={`embed-responsive-item videoTwo withVideo`}
                                                                        controlsList="nodownload"
                                                                    >
                                                                        <source
                                                                            src={Endpoints.baseUrl + postMedia.filePath}
                                                                        />
                                                                    </video>

                                                                </div>
                                                            ) : (
                                                                <img
                                                                    src={Endpoints.baseUrl + postMedia.filePath}
                                                                    alt="user-post-media"
                                                                    className="w-100 h-100 img-fluid"

                                                                />
                                                            )}
                                                            {i === 3 && post?.post_medias?.length - i - 1 > 0 && (
                                                                <span
                                                                    className="feed-more-gal"

                                                                >
                                                                    {post?.post_medias?.length - i - 1 + "+"}
                                                                </span>
                                                            )}
                                                        </a>
                                                    </div>
                                                )
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
                : ""}

            {type == "singalImages" ?
                <>
                    {
                        sharePost ? (
                            <div className="feed-image">
                                {sharePost.filePath.includes(".mp4") ? (
                                    <div className="position-relative">
                                        <video
                                            controls
                                            style={{ maxHeight: "400px", background: "rgba(0,0,0,0.6)" }}
                                            height={"auto"}
                                            width="100%"
                                            controlsList="nodownload"
                                        >
                                            <source
                                                src={Endpoints.baseUrl + sharePost.filePath}
                                                className="w-100 feed-gal-item"
                                            />
                                        </video>

                                    </div>
                                ) : (
                                    <img
                                        src={Endpoints.baseUrl + sharePost.filePath}
                                        alt="user-post-media"

                                        className="w-100 feed-gal-item"
                                        style={{
                                            minHeight: "20rem",
                                            maxHeight: "45rem",
                                            objectFit: "cover",
                                        }}
                                    />
                                )}
                            </div>
                        ) : ""
                        
                    }

                    <span className="mt-2 d-block mb-2">
                        <PostTitleDraftView title={post?.caption} />
                    </span>
                </>
                : ""
            }

            {type == "multipleImages" ?
                <>
                    <div className="feed-image">
                        {sharePost.includes(".mp4") ? (
                            <div className="position-relative">
                                <video

                                    controls
                                    style={{ maxHeight: "400px", background: "rgba(0,0,0,0.6)" }}
                                    height={"auto"}
                                    width="100%"
                                    controlsList="nodownload"
                                >
                                    <source
                                        src={Endpoints.baseUrl + sharePost}
                                        className="w-100 feed-gal-item"
                                    />
                                </video>

                            </div>
                        ) : (

                            <img
                                src={Endpoints.baseUrl + sharePost}
                                alt="user-post-media"

                                className="w-100 feed-gal-item"
                                style={{
                                    minHeight: "20rem",
                                    maxHeight: "45rem",
                                    objectFit: "cover",
                                }}
                            />
                        )}
                    </div>


                    <span className='mt-2 d-block mb-2'>
                        <PostTitleDraftView title={mediaIndex.title} />

                    </span>
                </>
                : ""}
            {type == "blog" ?
                <>
                    {
                        sharePost?.post_medias?.length === 1 ? (
                            <div className="feed-image">
                                {sharePost.post_medias[0].filePath.includes(".mp4") ? (
                                    <div className="position-relative">
                                        <video

                                            controls
                                            style={{ maxHeight: "400px", background: "rgba(0,0,0,0.6)" }}
                                            height={"auto"}
                                            width="100%"
                                            controlsList="nodownload"
                                        >
                                            <source
                                                src={Endpoints.baseUrl + sharePost.post_medias[0].filePath}
                                                className="w-100 feed-gal-item"
                                            />
                                        </video>

                                    </div>
                                ) : (
                                    <img
                                        src={Endpoints.baseUrl + sharePost.post_medias[0].filePath}
                                        alt="user-post-media"

                                        className="w-100 feed-gal-item"
                                        style={{
                                            minHeight: "20rem",
                                            maxHeight: "45rem",
                                            objectFit: "cover",
                                        }}
                                    />
                                )}
                            </div>
                        ) : (
                            <>
                                {sharePost?.post_medias?.length === 3 && (
                                    <>
                                        <div className="feed-gallery">
                                            {sharePost.post_medias.map(
                                                (postMedia, i) =>
                                                    i <= 3 && (
                                                        <div key={i} className={`feed-gal-item feed-third${i}`}>
                                                            <a href="javascript:void(0)">
                                                                {postMedia.filePath.includes(".mp4") ? (
                                                                    <div className="position-relative">
                                                                        <video
                                                                            controls
                                                                            height={"auto"}
                                                                            width="100%"
                                                                            className="embed-responsive-item withVideo"
                                                                            controlsList="nodownload"
                                                                        >
                                                                            <source
                                                                                src={Endpoints.baseUrl + postMedia.filePath}
                                                                            />
                                                                        </video>

                                                                    </div>
                                                                ) : (
                                                                    <img
                                                                        src={Endpoints.baseUrl + postMedia.filePath}
                                                                        alt="user-post-media"
                                                                        className="w-100 h-100 img-fluid"

                                                                    />
                                                                )}
                                                                {i === 3 && sharePost?.post_medias?.length - i - 1 > 0 && (
                                                                    <span
                                                                        className="feed-more-gal"

                                                                    >
                                                                        {sharePost?.post_medias?.length - i - 1 + "+"}
                                                                    </span>
                                                                )}
                                                            </a>
                                                        </div>
                                                    )
                                            )}
                                        </div>
                                    </>
                                )}

                                {(sharePost?.post_medias?.length > 3 || sharePost?.post_medias?.length === 2) && (
                                    <>
                                        <div className="feed-gallery">
                                            {sharePost.post_medias.map(
                                                (postMedia, i) =>
                                                    i <= 3 && (
                                                        <div key={i} className="feed-gal-item">
                                                            <a href="javascript:void(0)">
                                                                {postMedia.filePath.includes(".mp4") ? (
                                                                    <div className="position-relative">
                                                                        <video
                                                                            controls
                                                                            width={"100%"}
                                                                            height={"auto"}
                                                                            className={`embed-responsive-item videoTwo withVideo`}
                                                                            controlsList="nodownload"
                                                                        >
                                                                            <source
                                                                                src={Endpoints.baseUrl + postMedia.filePath}
                                                                            />
                                                                        </video>

                                                                    </div>
                                                                ) : (
                                                                    <img
                                                                        src={Endpoints.baseUrl + postMedia.filePath}
                                                                        alt="user-post-media"
                                                                        className="w-100 h-100 img-fluid"

                                                                    />
                                                                )}
                                                                {i === 3 && sharePost?.post_medias?.length - i - 1 > 0 && (
                                                                    <span
                                                                        className="feed-more-gal"

                                                                    >
                                                                        {sharePost?.post_medias?.length - i - 1 + "+"}
                                                                    </span>
                                                                )}
                                                            </a>
                                                        </div>
                                                    )
                                            )}
                                        </div>

                                    </>
                                )}
                            </>
                        )
                    }

                    <span className="mt-2 d-block mb-2">
                        <PostTitleDraftView title={post.title} />
                    </span>
                    <span
                        className="mt-2 d-block mb-2"
                        dangerouslySetInnerHTML={{
                            __html: post.description
                                ? post.description.substring(0, 70) +
                                ".... Read more"
                                : "",
                        }}
                    ></span>
                </>
                : ""}


        </li>
    )
}

export default SharePostModal;