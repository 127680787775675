import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate } from "react-router-dom";
import JobModel from "./JobModel";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import ReportJobsModel from "./ReportJobsModel";
import { Helmet } from "react-helmet";
const $ = window.jQuery;
export default function JobDetail() {
  const [jobData, setJobData] = useState("");
  const [pageData, setPageData] = useState("");
  const [isAlreadyApllied, setIsAlreadyApllied] = useState("");
  const [totalApplicant, setTotalApplicant] = useState("");
  const [isSaved, setIsSaved] = useState("");
  const [reported, setReported] = useState("");
  const [SimilarJobData, setSimilarJobData] = useState([]);

  const [categoryData, setCategoryData] = useState([]);
  const [location, setLocation] = useState("");
  const [countryKey, setCountryKey] = useState(
    Math.ceil(Math.random() * 999999)
  );
  const [countryOption, setCountryOption] = useState([]);
  const [category, setCategory] = useState("");
  const [searchText, setSearchText] = useState("");

  const [searchKey, setSearchKey] = useState(Math.ceil(Math.random() * 999999));
  const [seacrhOption, setSearchOption] = useState([]);

  const [jobtype, setJobtype] = useState("");
  const [date, setDate] = useState("");
  const [workmodel, setWorkmodel] = useState("");
  const [experience, setExperience] = useState("");
  const [education, setEducation] = useState("");
  const userId = localStorage.getItem("userId");
  const param = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getCompanyJobs();
    getSimilarJobs();
  }, [param]);

  useEffect(() => {
    getCategory();
    getCountry();
  }, []);

  const getCountry = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetCountry);
      if (resp && resp.success === true) {
        const newData = resp.data.map((item, index) => {
          return {
            id: item.id,
            name: item.countryName,
          };
        });
        setCountryOption(newData);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getCategory = async () => {
    if (jobData?.jobType === "Internship") {
      try {
        let resp = await FetchApi(
          Endpoints.JobsIndustries + "?scope=Internship"
        );
        if (resp && resp.success === true) {
          setCategoryData(resp?.data);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      try {
        let resp = await FetchApi(Endpoints.JobsIndustries + "?scope=Job");
        if (resp && resp.success === true) {
          setCategoryData(resp?.data);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleOnSelectLocation = (item) => {
    if (item.length > 0) {
      setLocation(item[0].id);
    } else {
      setLocation("");
    }
  };

  const handleOnSearchLocation = async (string) => {
    try {
      let resp = await FetchApi(Endpoints.SearchLocation + "?term=" + string);
      if (resp && resp.success === true) {
        const newData = resp.data.map((item, index) => {
          return {
            id: item?.id,
            name: item?.city + ", " + item?.state?.state,
            stateId: item?.state?.id,
            stateName: item?.state?.state,
          };
        });
        setCountryOption(newData);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const RenderCountry = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-StudyArea"
        defaultInputValue={location}
        key={countryKey}
        onSearch={handleOnSearchLocation}
        onChange={handleOnSelectLocation}
        options={countryOption}
        placeholder={"Location"}
        labelKey="name"
        minLength={1}
        emptyLabel={"No result found"}
        className={""}
        style={{ position: "absolute" }}
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [countryOption]);

  const handleOnSelectJob = (item) => {
    setSearchText(item[0].name);
  };

  const handleOnSearchJob = async (string) => {
    try {
      let resp = await FetchApi(Endpoints.JobsSearch + "?title=" + string);
      if (resp && resp.success === true) {
        const newData = resp.data?.allJob.map((item, index) => {
          if (index > 9) return null;
          return {
            id: item?.id,
            name: item?.title,
          };
        });
        setSearchOption(newData);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const RenderSearch = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-StudyArea"
        defaultInputValue={location}
        key={searchKey}
        onSearch={handleOnSearchJob}
        onChange={handleOnSelectJob}
        options={seacrhOption}
        placeholder={"Search by title or keyword"}
        labelKey="name"
        minLength={1}
        emptyLabel={"No result found"}
        className="ps-2 py-3 w-100"
        style={{ position: "absolute" }}
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [seacrhOption, searchKey]);

  const handleSearch = () => {
    let url = "";
    if (jobData?.jobType === "Internship") {
      url = "/internship-listing?";
    } else {
      url = "/jobs-listing?";
    }

    if (searchText) {
      url = url + "title=" + searchText;
    }
    if (location) {
      url = url + "&cityId=" + location;
    }
    if (location) {
      url = url + "&industry=" + category;
    }
    if (jobtype) {
      url = url + "&jobType=" + jobtype;
    }
    if (date) {
      url = url + "&recentJobs=" + date;
    }
    if (workmodel) {
      url = url + "&workMode=" + workmodel;
    }
    if (experience) {
      url = url + "&experience=" + experience;
    }
    if (education) {
      url = url + "&qualification=" + education;
    }
    navigate(url);
  };

  const getCompanyJobs = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetSingalJobs + "/" + param?.id);
      if (resp && resp.success === true) {
        setJobData(resp?.data?.job);
        setPageData(resp?.data?.page);
        setIsAlreadyApllied(resp?.data?.isAlreadyApllied);
        setIsSaved(resp?.data?.isSaved);
        setTotalApplicant(resp?.data?.totalApplicant);
        setReported(resp?.data?.isReported);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const getSimilarJobs = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.GetSimilarJob + "?jobId=" + param?.id
      );
      if (resp && resp.success === true) {
        setSimilarJobData(resp?.data?.allJob);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "year ago";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon ago";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + " day ago";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + " h ago";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min ago";
    return Math.floor(seconds) >= 0
      ? Math.floor(seconds) + " sec ago"
      : "0 sec ago";
  };

  const handleApplyJob = () => {
    $("#applyjobs").modal("show");
  };

  const JobSave = async (Id) => {
    let data = {
      jobId: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.JobSave, data);
      if (resp && resp.success === true) {
        getCompanyJobs();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const resetFilter = () => {
    setJobtype("");
    setEducation("");
    setExperience("");
    setWorkmodel("");
    setDate("");
    setSearchText("");
    setCategory("");
    setCountryKey(Math.ceil(Math.random() * 999999));
    countryOption([]);
    setLocation("");
  };

  // const formattedSalary = new Intl.NumberFormat('en-IN', {
  //     style: 'currency',
  //     currency: 'INR',
  //     minimumFractionDigits: 0,
  //     maximumFractionDigits: 2,
  //     notation: 'compact',
  //     compactDisplay: 'short',
  // })

  const formattedSalary = (salary) => {
    if (salary >= 1000 && salary < 100000) {
      return `₹${Math.abs(salary) / 1000}K`;
    }

    if (salary >= 100000 && salary < 10000000) {
      return `₹${Math.abs(salary) / 100000}L`;
    }

    if (salary >= 10000000 && salary < 1000000000) {
      return `₹${Math.abs(salary) / 10000000}Cr`;
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Jobs Detail</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />

          <div className="container py-3">
            <div className="bg-white p-3 rounded-15 box-shadow mb-4 ">
              <div className="job-search-form">
                <div className="row mx-0 bg-white rounded-pill  box-shadow border mb-3">
                  <div className="d-flex align-items-center ps-3 col-md-4">
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      stroke="#949494"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="css-i6dzq1"
                    >
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    {/* <input type="text" placeholder="Search by job title or keyword" className="ps-2 py-3 text-mediumgray"
                                            onChange={(e) => {
                                                setSearchText(e.target.value)
                                            }}
                                        /> */}
                    {RenderSearch()}
                  </div>

                  <div className="d-flex align-items-center border-start border-end px-3 col-md-3">
                    <img
                      src="../assets/img/icon/icon-location.svg"
                      width="22"
                      alt=""
                    />
                    {/* <input type="text" placeholder="Location" className="py-3 text-mediumgray" /> */}
                    {RenderCountry()}
                  </div>

                  <div className="col-md-3 d-flex align-items-center ">
                    <select
                      name=""
                      id=""
                      className="py-3 bg-white"
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                    >
                      <option value="">Select Industry</option>
                      {categoryData.map((item, index) => {
                        return (
                          <option value={item?.id} key={index}>
                            {item?.value}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <button
                    className="btn btn-brown px-4 py-3 rounded-start rounded-pill col-md-2"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>

                <div className="main-top-row gap-lg-3 d-lg-flex">
                  <select
                    name="jobtype"
                    id="jobtype"
                    className="text-darkgray mb-md-0 mb-2"
                    onChange={(e) => {
                      setJobtype(e.target.value);
                    }}
                  >
                    <option value="">Job Type</option>
                    <option
                      value="Full-time"
                      selected={jobtype === "Full-time" ? true : false}
                    >
                      Full-time
                    </option>
                    <option
                      value="Part-time"
                      selected={jobtype === "Part-time" ? true : false}
                    >
                      Part-time
                    </option>
                    <option
                      value="Contract"
                      selected={jobtype === "Contract" ? true : false}
                    >
                      {" "}
                      Contract
                    </option>
                    <option
                      value="Temporary"
                      selected={jobtype === "Temporary" ? true : false}
                    >
                      Temporary
                    </option>
                    <option
                      value="Freelance"
                      selected={jobtype === "Freelance" ? true : false}
                    >
                      Freelance
                    </option>
                    <option
                      value="Internship"
                      selected={jobtype === "Internship" ? true : false}
                    >
                      Internship
                    </option>
                  </select>

                  <select
                    name="date"
                    id="date"
                    className="text-darkgray mb-md-0 mb-2"
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                  >
                    <option value="">Date Posted</option>
                    <option
                      value="week-ago"
                      selected={date === "week-ago" ? true : false}
                    >
                      Week-ago
                    </option>
                    <option
                      value="month-ago"
                      selected={date === "month-ago" ? true : false}
                    >
                      Month-ago
                    </option>
                  </select>

                  <select
                    name="workmodel"
                    id="workmodel"
                    className="text-darkgray mb-md-0 mb-2"
                    onChange={(e) => {
                      setWorkmodel(e.target.value);
                    }}
                  >
                    <option value="">Work Model</option>
                    <option
                      value="On-site"
                      selected={workmodel === "On-site" ? true : false}
                    >
                      On-site
                    </option>
                    <option
                      value="Remote"
                      selected={workmodel === "Remote" ? true : false}
                    >
                      Remote
                    </option>
                    <option
                      value="Hybrid"
                      selected={workmodel === "Hybrid" ? true : false}
                    >
                      Hybrid
                    </option>
                    <option
                      value="Work from home"
                      selected={workmodel === "Work from home" ? true : false}
                    >
                      Work from home
                    </option>
                  </select>

                  <select
                    name="experience"
                    id="experience"
                    className="text-darkgray mb-md-0 mb-2"
                    onChange={(e) => {
                      setExperience(e.target.value);
                    }}
                  >
                    <option value="">Experience Level</option>
                    <option
                      value="1"
                      selected={experience === "1" ? true : false}
                    >
                      1
                    </option>
                    <option
                      value="2"
                      selected={experience === "2" ? true : false}
                    >
                      2
                    </option>
                    <option
                      value="3"
                      selected={experience === "3" ? true : false}
                    >
                      3
                    </option>
                    <option
                      value="4"
                      selected={experience === "4" ? true : false}
                    >
                      4
                    </option>
                    <option
                      value="5"
                      selected={experience === "5" ? true : false}
                    >
                      5
                    </option>
                    <option
                      value="6"
                      selected={experience === "6" ? true : false}
                    >
                      6
                    </option>
                    <option
                      value="7"
                      selected={experience === "7" ? true : false}
                    >
                      7
                    </option>
                    <option
                      value="8"
                      selected={experience === "8" ? true : false}
                    >
                      8
                    </option>
                    <option
                      value="9"
                      selected={experience === "9" ? true : false}
                    >
                      9
                    </option>
                    <option
                      value="10"
                      selected={experience === "10" ? true : false}
                    >
                      10
                    </option>
                  </select>

                  <select
                    name="education"
                    id="education"
                    className="text-darkgray mb-md-0 mb-2"
                    onChange={(e) => {
                      setEducation(e.target.value);
                    }}
                  >
                    <option value="">Qualification</option>
                    <option
                      value="10th Pass"
                      selected={education === "10th Pass" ? true : false}
                    >
                      10th Pass
                    </option>
                    <option
                      value="12th Pass"
                      selected={education === "12th Pass" ? true : false}
                    >
                      12th Pass
                    </option>
                    <option
                      value="Diploma or Equivalent"
                      selected={
                        education === "Diploma or Equivalent" ? true : false
                      }
                    >
                      Diploma or Equivalent
                    </option>
                    <option
                      value="Associate's Degree"
                      selected={
                        education === "Associate's Degree" ? true : false
                      }
                    >
                      Associate's Degree
                    </option>
                    <option
                      value="Bachelor's Degree"
                      selected={
                        education === "Bachelor's Degree" ? true : false
                      }
                    >
                      Bachelor's Degree
                    </option>
                    <option
                      value="Master's Degree"
                      selected={education === "Master's Degree" ? true : false}
                    >
                      Master's Degree
                    </option>
                    <option
                      value="Ph.D"
                      selected={education === "Ph.D" ? true : false}
                    >
                      Ph.D
                    </option>
                    <option
                      value="Professional Degree"
                      selected={
                        education === "Professional Degree" ? true : false
                      }
                    >
                      Professional Degree
                    </option>
                    <option
                      value="Apprenticeships"
                      selected={education === "Apprenticeships" ? true : false}
                    >
                      Apprenticeships
                    </option>
                    <option
                      value="Internships"
                      selected={education === "Internships" ? true : false}
                    >
                      Internships
                    </option>
                  </select>

                  <button
                    type="reset"
                    className="btn darkgray-btn fs-14 px-4 ms-auto reset-job-filter"
                    onClick={resetFilter}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>

            <div className="row flex-md-row-reverse">
              <div className="col-md-9 mb-3">
                <div className="rounded-10 overflow-hidden mb-3 bg-white">
                  {pageData?.coverImage === null ? (
                    <img
                      src="../assets/img/single-job-banner.png"
                      className="w-100"
                      alt=""
                    />
                  ) : (
                    <img
                      src={Endpoints.baseUrl + pageData?.coverImage}
                      className="w-100"
                      alt=""
                    />
                  )}
                  <div className="row align-items-end px-lg-4 px-3 ">
                    <div className="col-md-7 pb-3">
                      <img
                        src={Endpoints.baseUrl + pageData?.pageImage}
                        width="96"
                        height="96"
                        className="rounded-circle job-company-logo"
                        alt=""
                      />
                      <h5 className="semibold pt-2">{jobData?.title}</h5>
                      <h6 className="fs-12 regular text-blue py-1">
                        {pageData?.title}
                      </h6>
                      <h6 className="fs-12 regular text-black">
                        {jobData?.city?.city}
                        {jobData?.city?.city ? ", " : ""}{" "}
                        {jobData?.state?.state}
                      </h6>
                    </div>
                    <div className="col-md-5 text-md-end">
                      {userId == jobData?.userId ? (
                        ""
                      ) : reported === "true" ? (
                        <button className="fs-12 regular text-danger border-0 bg-transparent disabled">
                          <h6 className="fs-12 regular text-danger ">
                            <img
                              src="../assets/img/icon/icon-flag-report.svg"
                              alt=""
                            />{" "}
                            reported
                          </h6>
                        </button>
                      ) : (
                        <button
                          className="fs-12 regular text-danger border-0 bg-transparent"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <h6 className="fs-12 regular text-danger ">
                            <img
                              src="../assets/img/icon/icon-flag-report.svg"
                              alt=""
                            />{" "}
                            Report this job
                          </h6>
                        </button>
                      )}

                      <h6 className="fs-12 regular text-gray py-2">
                        Posted {timeSince(new Date(jobData.createdAt))}
                        <img
                          src="../assets/img/icon/icon-separator-dot.svg"
                          className="px-2"
                          alt=""
                        />
                        <span className="text-blue">
                          {totalApplicant} Applicants
                        </span>
                      </h6>
                      <div className="pb-3">
                        {userId == jobData?.userId ? (
                          ""
                        ) : (
                          <Link
                            className="border rounded-circle d-inline-block text-center like-job"
                            onClick={() => {
                              JobSave(jobData?.id);
                            }}
                          >
                            {isSaved == "true" ? (
                              <img
                                src="../assets/img/icon/icon-wishlist-fill.svg"
                                width="16"
                                alt=""
                              />
                            ) : (
                              <img
                                src="../assets/img/icon/icon-wishlist.svg"
                                width="16"
                                alt=""
                              />
                            )}
                          </Link>
                        )}{" "}
                        {userId == jobData?.userId ? (
                          ""
                        ) : isAlreadyApllied == "true" ? (
                          <div class="btn px-4 py-2 fs-14 gray-btn ">
                            Applied
                          </div>
                        ) : (
                          <Link
                            onClick={() => {
                              handleApplyJob();
                            }}
                            className="btn fs-14 py-2 px-4"
                          >
                            Apply Now{" "}
                            <img
                              src="../assets/img/icon/icon-bag.svg"
                              className="pe-2"
                              alt=""
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-8 mb-3">
                    <div className="bg-white rounded-10 box-shadow py-3 px-3">
                      <h6 className="medium fs-16 pb-2">Description</h6>
                      <div className="fs-14 pb-4 ck">
                        <p
                          className="m-0"
                          dangerouslySetInnerHTML={{
                            __html: jobData?.description,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="bg-white rounded-10 box-shadow py-3 px-lg-3 px-2">
                      <div className="row">
                        <div className="col-6 mb-2 px-1">
                          <div className="single-job-gray rounded-10 text-center px-2 py-2">
                            <h6 className="fs-12 text-gray regular">
                              Job Type
                            </h6>
                            <h5 className="text-black fs-14 medium">
                              {jobData?.jobType}
                            </h5>
                          </div>
                        </div>

                        <div className="col-6 mb-2 px-1">
                          <div className="single-job-gray rounded-10 text-center px-2 py-2">
                            <h6 className="fs-12 text-gray regular">
                              Work Level
                            </h6>
                            <h5 className="text-black fs-14 medium text-break">
                              {jobData?.workLevel}
                            </h5>
                          </div>
                        </div>

                        <div className="col-6 mb-2 px-1">
                          <div className="single-job-gray rounded-10 text-center px-2 py-2">
                            <h6 className="fs-12 text-gray regular">
                              Qualification
                            </h6>
                            <h5 className="text-black fs-14 medium">
                              {jobData?.qualification}
                            </h5>
                          </div>
                        </div>

                        <div className="col-6 mb-2 px-1">
                          <div className="single-job-gray rounded-10 text-center px-2 py-2">
                            <h6 className="fs-12 text-gray regular">Salary</h6>
                            {/* <h5 className="text-black fs-13 medium">₹{jobData?.minSalary / 1000}K - {jobData?.maxSalary / 1000}K/mon</h5> */}
                            <h5 className="text-black fs-13 medium">
                              {formattedSalary(jobData?.minSalary)} -{" "}
                              {formattedSalary(jobData?.maxSalary)}/mon
                            </h5>
                          </div>
                        </div>

                        <div className="col-6 mb-2 px-1">
                          <div className="single-job-gray rounded-10 text-center px-2 py-2">
                            <h6 className="fs-12 text-gray regular">
                              Work Model
                            </h6>
                            <h5 className="text-black fs-14 medium">
                              {jobData?.workMode}
                            </h5>
                          </div>
                        </div>

                        <div className="col-6 mb-2 px-1">
                          <div className="single-job-gray rounded-10 text-center px-2 py-2">
                            <h6 className="fs-12 text-gray regular">
                              Industry
                            </h6>
                            <h5 className="text-black fs-14 medium">
                              {jobData?.industryData?.value}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="bg-white p-3 rounded-15 box-shadow">
                  <h4 className="medium fs-22">
                    Similar{" "}
                    {jobData?.jobType === "Internship" ? "Internship" : "Jobs"}
                  </h4>
                </div>
                {SimilarJobData.map((item, index) => {
                  return (
                    <div
                      className="bg-white p-3 rounded-15 box-shadow mt-3"
                      key={index}
                    >
                      <div className="d-flex gap-2 align-items-start w-100">
                        <span className="circular--landscape pic-size-48-flex">
                          <img
                            src={Endpoints.baseUrl + item?.page?.pageImage}
                            alt=""
                            width="48"
                            height="48"
                          />
                        </span>
                        <div className="w-100">
                          <div className="d-flex justify-content-between w-100 align-items-center">
                            <Link to={"/jobs/" + item.id}>
                              <h5 className="medium fs-14">{item?.title}</h5>
                            </Link>
                            <Link
                              onClick={() => {
                                JobSave(item?.id);
                              }}
                            >
                              {item?.isSaved == "true" ? (
                                <img
                                  src="../assets/img/icon/icon-wishlist-fill.svg"
                                  width="12"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src="../assets/img/icon/icon-wishlist.svg"
                                  width="12"
                                  alt=""
                                />
                              )}
                            </Link>
                            {/* <Link onClick={()=>{
                                                        JobSave(item?.id)
                                                    }}><img src="../assets/img/icon/icon-wishlist.svg" width="12" alt="" /></Link> */}
                          </div>

                          <div className="">
                            <span className="fs-10 text-darkgray pe-2 d-flex align-items-center">
                              <img
                                src="../assets/img/icon/icon-location.svg"
                                alt=""
                                className="align-middle me-1"
                              />
                              <span>{item?.state?.state}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr className="my-2" />
                      <p
                        className="fs-12 text-gray mb-1 ck"
                        dangerouslySetInnerHTML={{
                          __html:
                            item?.description.length > 120
                              ? item?.description.substring(0, 120) + "..."
                              : item?.description,
                        }}
                      ></p>

                      <div className="d-flex request-btn gap-2">
                        <a href="#" className="btn btn-light-blue px-2 medium">
                          {item?.jobType}
                        </a>
                        <a href="#" className="btn btn-light-blue px-2 medium">
                          {item?.workMode}
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
      <JobModel
        {...{
          jobId: param?.id,
          onSuccess: getCompanyJobs,
        }}
      />
      <ReportJobsModel
        {...{
          JobId: param?.id,
          onSuccess: getCompanyJobs,
        }}
      />
    </Container>
  );
}
