import React, { useEffect, useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import ProfileImage from "../ProfileUpdate/ProfileImage";
import Sidebar from "../ProfileUpdate/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import ToastMessage from "../../Utils/ToastMessage";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import { FetchApi } from "../../API/FetchApi";
import { UpdateAPi } from "../../API/UpdateApi";
import { DeleteApi } from "../../API/DeleteApi";
import { Helmet } from "react-helmet";

function AllNotifications() {
  const navigate = useNavigate();
  const [notification, setNotification] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [unreadLimit, setUnreadLimit] = useState(10);
  const [loadStatus, setLoadStatus] = useState(false);
  const [activeTab, setActiveTab] = useState("All");
  const [refreshNotificationCount, setRefreshNotificationCount] =
    useState(false);
  useEffect(() => {
    getAllNotification();
  }, []);

  const getAllNotification = async () => {
    try {
      let resp = await FetchApi(Endpoints.AllNotification + "?size=" + limit);
      if (resp && resp.success === true) {
        setNotification(resp?.data?.notification);
        setNotificationCount(resp?.data?.totalRecord);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleLoadMore = async () => {
    setLoadStatus(true);
    if (activeTab === "All") {
      const newLimit = limit + 10;
      setLimit(newLimit);
      try {
        let resp = await FetchApi(
          Endpoints.AllNotification + "?size=" + newLimit
        );
        if (resp && resp.success === true) {
          setNotification(resp?.data?.notification);
          console.log("@@@", notification);
          setNotificationCount(resp?.data?.totalRecord);
          setLoadStatus(false);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          setLoadStatus(false);
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      const newLimit = unreadLimit + 10;
      setUnreadLimit(newLimit);
      try {
        let resp = await FetchApi(
          Endpoints.AllNotification + "?size=" + newLimit + "&type=unread"
        );
        if (resp && resp.success === true) {
          setNotification(resp?.data?.notification);
          setUnreadNotificationCount(resp?.data?.totalRecord);
          setLoadStatus(false);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          setLoadStatus(false);
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  const handleUnreadNotification = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.AllNotification + "?type=unread" + "&size=" + unreadLimit
      );
      if (resp && resp.success === true) {
        setNotification(resp?.data?.notification);
        setUnreadNotificationCount(resp?.data?.totalRecord);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec ago";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "y ago";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon ago";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + "d ago";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + "h ago";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min ago";
    return Math.floor(seconds) >= 0
      ? Math.floor(seconds) + " sec ago"
      : "0 sec ago";
  };

  const handleReadNotification = async (Id) => {
    let data = {
      id: Id,
    };
    try {
      let resp = await UpdateAPi(Endpoints.ReadNotification, data);
      if (resp && resp.message) {
        getAllNotification();
        setRefreshNotificationCount(Id);
        // ToastMessage.Success(resp.message)
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleRemove = async (Id) => {
    let data = {
      id: Id,
    };
    try {
      let resp = await DeleteApi(Endpoints.RemoveNotification, data);
      if (resp && resp.message) {
        getAllNotification();
        setRefreshNotificationCount(Id);
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleOpen = async (item) => {
    let data = {
      id: item?.id,
    };

    const user = JSON.parse(localStorage.getItem("user"));
    // console.log("#@2", item, item?.metaData?.room_type, user.instructorStatus);
    try {
      let resp = await UpdateAPi(Endpoints.ReadNotification, data);
      if (resp && resp.message) {
        setRefreshNotificationCount(item?.id);
        if (item?.type === "chat") {
          if (
            item?.metaData?.room_type === "teacher" &&
            user.instructorStatus === "Complete"
          ) {
            navigate(
              `/teacher/messages?roomId=${item?.metaData?.chat_room_id}`
            );
          } else if (item?.metaData?.room_type === "teacher") {
            navigate(`/messages?roomId=${item?.metaData?.chat_room_id}`);
          } else {
            navigate(`/friend/messages?roomId=${item?.metaData?.chat_room_id}`);
          }
        }
        if (item?.type === "job_notification") {
          navigate("/jobs/" + item?.metaData?.notificationUser?.jobId);
        }
        if (item?.type === "job_apply") {
          navigate(
            "/admin-jobs-application/" + item?.metaData?.notificationUser?.jobId
          );
        }
        if (item?.type === "Post" || item?.type === "mention") {
          navigate("/timeline/" + item?.username);
        }
        if (item?.type === "FriendRequest") {
          navigate("/friend-request/" + item?.username);
        }

        if (item?.type === "Gratitude") {
          navigate("/gratitudewall");
        }
        if (
          item?.type === "Company" ||
          item?.type === "Page" ||
          item?.type === "Schools" ||
          item?.type === "University"
        ) {
          navigate("/page-profile/" + item?.metaData?.pageData?.slug);
        }
        if (item?.type === "unusual") {
          navigate("/privacy-setting");
        }
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleReadAll = async () => {
    try {
      let resp = await UpdateAPi(Endpoints.ReadAllNotification);
      if (resp && resp.message) {
        setRefreshNotificationCount("all_read");
        getAllNotification();
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const HandleActiveTab = (tab) => {
    setActiveTab(tab);
    if (tab == "All") {
      getAllNotification();
    } else {
      handleUnreadNotification();
    }
  };
  return (
    <Container header refreshNotificationcount={refreshNotificationCount}>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <div class="wrapper wrapper-bg">
        <div class="main">
          <LeftNavigation />
          <div class="container py-lg-4 py-3">
            <ProfileImage />

            <div class="row">
              <Sidebar />

              <div class="col-md-9">
                <div class="bg-white box-shadow rounded-15 p-md-3 p-2">
                  <div class="d-flex align-items-center justify-content-between border-bottom pt-md-2 pb-3 mb-3 px-lg-3">
                    <h3 class="medium">Notifications</h3>
                    <div class="position-relative">
                      <a
                        href="#"
                        id="morenotification1"
                        data-bs-toggle="dropdown"
                        class="d-flex align-items-center justify-content-center rounded-circle p-1"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                          stroke="#000"
                          strokeWidth="2"
                          fill="none"
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          class="css-i6dzq1"
                        >
                          <circle cx="12" cy="12" r="1"></circle>
                          <circle cx="19" cy="12" r="1"></circle>
                          <circle cx="5" cy="12" r="1"></circle>
                        </svg>
                      </a>

                      <ul
                        class="shadow rounded-1 dropdown-menu border-0"
                        aria-labelledby="morenotification1"
                      >
                        <li>
                          <a
                            class="dropdown-item py-2 d-flex gap-2"
                            href="#"
                            onClick={handleReadAll}
                          >
                            <svg
                              viewBox="0 0 24 24"
                              width="15"
                              height="15"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="none"
                              stroke-linecap="round"
                              strokeLinejoin="round"
                              class="css-i6dzq1"
                            >
                              <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>{" "}
                            Mark all as Read
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <ul class="notfication-filter d-flex flex-wrap gap-lg-3 px-lg-3">
                    <li class={activeTab === "All" ? "active" : ""}>
                      <a
                        href="#"
                        onClick={() => {
                          HandleActiveTab("All");
                        }}
                      >
                        All
                      </a>
                    </li>
                    <li class={activeTab === "Unread" ? "active" : ""}>
                      <a
                        href="#"
                        onClick={() => {
                          HandleActiveTab("Unread");
                        }}
                      >
                        Unread
                      </a>
                    </li>
                  </ul>

                  {/* <h4 class="medium pt-4 pb-3 px-lg-3">New</h4> */}

                  <ul class="friend-notifications mt-3">
                    {notification?.length > 0 ? (
                      notification?.map((item, index) => {
                        return (
                          <li
                            class={
                              item?.isRead === "false"
                                ? "notification-item p-3 d-flex align-items-center active"
                                : "notification-item p-3 d-flex align-items-center"
                            }
                            key={index}
                          >
                            {/* <img src={Endpoints.baseUrl + item?.metaData?.notificationUser?.user_profile?.profileImg} alt="" width="48" height="48" class="rounded-circle" /> */}
                            {item?.type === "unusual" ? (
                              item?.metaData?.user_profile?.profileImg ===
                              "user/profile-picture/null" ? (
                                <img
                                  src={"../assets/img/picture.png"}
                                  alt=""
                                  width="48"
                                  height="48"
                                  className="rounded-circle"
                                  onClick={() => {
                                    handleOpen(item);
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    Endpoints.baseUrl +
                                    item?.metaData?.user_profile?.profileImg
                                  }
                                  alt=""
                                  width="48"
                                  height="48"
                                  className="rounded-circle"
                                  onClick={() => {
                                    handleOpen(item);
                                  }}
                                />
                              )
                            ) : item?.type === "job_notification" ||
                              item?.type === "job_apply" ? (
                              item?.metaData?.notificationUser?.pageImage ===
                              "pages/null" ? (
                                <img
                                  src={"../assets/img/picture.png"}
                                  alt=""
                                  width="48"
                                  height="48"
                                  className="rounded-circle"
                                  onClick={() => {
                                    handleOpen(item);
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    item?.metaData?.notificationUser
                                      ?.notificationUser?.user_profile
                                      ?.profileImg
                                      ? Endpoints.baseUrl +
                                        item?.metaData?.notificationUser
                                          ?.notificationUser?.user_profile
                                          ?.profileImg
                                      : item?.metaData?.user_profile?.profileImg
                                      ? Endpoints.baseUrl +
                                        item?.metaData?.user_profile?.profileImg
                                      : "../assets/img/picture.png"
                                  }
                                  alt=""
                                  width="48"
                                  height="48"
                                  className="rounded-circle"
                                  onClick={() => {
                                    handleOpen(item);
                                  }}
                                />
                              )
                            ) : item?.metaData?.notificationUser?.user_profile
                                ?.profileImg === "user/profile-picture/null" ? (
                              <img
                                src={"../assets/img/picture.png"}
                                alt=""
                                width="48"
                                height="48"
                                className="rounded-circle"
                                onClick={() => {
                                  handleOpen(item);
                                }}
                              />
                            ) : (
                              <img
                                src={
                                  Endpoints.baseUrl +
                                  item?.metaData?.notificationUser?.user_profile
                                    ?.profileImg
                                }
                                alt=""
                                width="48"
                                height="48"
                                className="rounded-circle"
                                onClick={() => {
                                  handleOpen(item);
                                }}
                              />
                            )}
                            <div class="d-flex align-items-start justify-content-between w-100 ps-2">
                              <Link
                                onClick={() => {
                                  handleOpen(item);
                                }}
                              >
                                <div>
                                  <h6 class="medium text-black">
                                    {item?.metaData?.notificationUser?.fullName}
                                  </h6>
                                  <h6 class="fs-12 regular text-darkgray">
                                    {item?.message}
                                  </h6>
                                  <h6 class="fs-10 regular text-darkgray">
                                    {" "}
                                    {timeSince(new Date(item.createdAt))}{" "}
                                  </h6>
                                </div>
                              </Link>

                              <div class="position-relative text-end">
                                <a
                                  href="#"
                                  id="user_notification_01"
                                  data-bs-toggle="dropdown"
                                  class="d-flex align-items-center justify-content-center "
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="18"
                                    height="18"
                                    stroke="#000"
                                    strokeWidth="2"
                                    fill="none"
                                    stroke-linecap="round"
                                    strokeLinejoin="round"
                                    class="css-i6dzq1"
                                  >
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="19" cy="12" r="1"></circle>
                                    <circle cx="5" cy="12" r="1"></circle>
                                  </svg>
                                </a>

                                <ul
                                  class="text-start shadow rounded-1 dropdown-menu boder-0"
                                  aria-labelledby="user_notification_01"
                                >
                                  {item?.isRead === "false" ? (
                                    <li className="py-1">
                                      <a
                                        class="dropdown-item py-2 d-flex gap-2 align-items-center"
                                        href="#"
                                        onClick={() => {
                                          handleReadNotification(item?.id);
                                        }}
                                      >
                                        <svg
                                          viewBox="0 0 24 24"
                                          width="15"
                                          height="15"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          fill="none"
                                          stroke-linecap="round"
                                          strokeLinejoin="round"
                                          class="css-i6dzq1"
                                        >
                                          <polyline points="20 6 9 17 4 12"></polyline>
                                        </svg>
                                        Mark as Read
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  <li className="py-1">
                                    <a
                                      class="dropdown-item py-2 d-flex gap-2 align-items-center"
                                      href="#"
                                      onClick={() => {
                                        handleRemove(item?.id);
                                      }}
                                    >
                                      <svg
                                        viewBox="0 0 24 24"
                                        width="14"
                                        height="14"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        stroke-linecap="round"
                                        strokeLinejoin="round"
                                        class="css-i6dzq1"
                                      >
                                        <polyline points="3 6 5 6 21 6"></polyline>
                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                        <line
                                          x1="10"
                                          y1="11"
                                          x2="10"
                                          y2="17"
                                        ></line>
                                        <line
                                          x1="14"
                                          y1="11"
                                          x2="14"
                                          y2="17"
                                        ></line>
                                      </svg>
                                      Delete
                                    </a>
                                  </li>
                                </ul>

                                {item?.isRead === "false" ? (
                                  <span class="user-active ">
                                    <svg
                                      viewBox="0 0 24 24"
                                      class="mt-3"
                                      width="12"
                                      height="12"
                                      stroke="#0C64C5"
                                      strokeWidth="2"
                                      fill="#0C64C5"
                                      stroke-linecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <circle cx="12" cy="12" r="10"></circle>
                                    </svg>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <li class="notification-item p-3 d-flex align-items-center">
                        Notifications not found
                      </li>
                    )}
                  </ul>
                  {activeTab === "All" ? (
                    <>
                      {notification.length >= 8 && limit < notificationCount ? (
                        <div className="text-center">
                          <Link
                            href="#"
                            className="text-blue"
                            onClick={() => handleLoadMore()}
                          >
                            {loadStatus ? (
                              <img
                                src="../assets/img/icon/icon-loading.svg"
                                alt=""
                              />
                            ) : (
                              "Load More Notification"
                            )}
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      {notification.length >= 8 &&
                      unreadLimit < unreadNotificationCount ? (
                        <div className="text-center">
                          <Link
                            href="#"
                            className="text-blue"
                            onClick={() => handleLoadMore()}
                          >
                            {loadStatus ? (
                              <img
                                src="../assets/img/icon/icon-loading.svg"
                                alt=""
                              />
                            ) : (
                              "Load More Notification"
                            )}
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <OnlineFriends />
      </div>
    </Container>
  );
}

export default AllNotifications;
