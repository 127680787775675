import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import Sidebar from "../ProfileUpdate/Sidebar";
import ProfileImage from "../ProfileUpdate/ProfileImage";
import validator from "validator";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Helmet } from "react-helmet";

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [hasOneSplChar, setHasOneSplChar] = useState(false);
  const [hasOneNumber, setHasOneNumber] = useState(false);
  const [isOneLower, setIsOneLower] = useState(false);
  const [isOneUpper, setIsOneUpper] = useState(false);
  const [isEightLength, setIsEightLength] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [newHidden, setNewHidden] = useState(true);
  const [confirmHidden, setConfirmHidden] = useState(true);

  const [NewIsVisible, setNewIsVisible] = useState(false);
  const [NewhasOneSplChar, setNewHasOneSplChar] = useState(false);
  const [NewhasOneNumber, setNewHasOneNumber] = useState(false);
  const [NewisOneLower, setNewIsOneLower] = useState(false);
  const [NewisOneUpper, setNewIsOneUpper] = useState(false);
  const [NewisEightLength, setNewIsEightLength] = useState(false);

  const [ConfirmIsVisible, setConfirmIsVisible] = useState(false);
  const [ConfirmhasOneSplChar, setConfirmHasOneSplChar] = useState(false);
  const [ConfirmhasOneNumber, setConfirmHasOneNumber] = useState(false);
  const [ConfirmisOneLower, setConfirmIsOneLower] = useState(false);
  const [ConfirmisOneUpper, setConfirmIsOneUpper] = useState(false);
  const [ConfirmisEightLength, setConfirmIsEightLength] = useState(false);

  const navigate = useNavigate();
  const handleSave = async (e) => {
    e.preventDefault();
    if (!currentPassword) {
      setCurrentPasswordError("Current password is required");
    } else {
      setCurrentPasswordError("");
    }
    if (!newPassword) {
      setNewPasswordError("New password is required");
    } else {
      setNewPasswordError("");
    }
    if (!confirmPassword) {
      setConfirmPasswordError("Comfirm password is required");
    } else {
      setConfirmPasswordError("");
    }

    if (currentPassword && newPassword && confirmPassword) {
      let data = {
        current_password: currentPassword,
        password: newPassword,
        confirm_password: confirmPassword,
      };
      try {
        let resp = await CommonAPi(Endpoints.PasswordChange, data);
        if (resp && resp.success === true) {
          ToastMessage.Success(resp.message);
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  const validatePassword = () => {
    if (currentPassword.length >= 8) {
      setIsEightLength(true);
    } else {
      setIsEightLength(false);
    }

    if (currentPassword.match(/[a-z]/g)) {
      setIsOneLower(true);
    } else {
      setIsOneLower(false);
    }

    if (currentPassword.match(/[A-Z]/g)) {
      setIsOneUpper(true);
    } else {
      setIsOneUpper(false);
    }

    if (currentPassword.match(/[0-9]/g)) {
      setHasOneNumber(true);
    } else {
      setHasOneNumber(false);
    }

    if (currentPassword.match(/[!@#\$%\^\&*\)\(+=._-]/g)) {
      setHasOneSplChar(true);
    } else {
      setHasOneSplChar(false);
    }
  };

  const validateNewPassword = () => {
    if (newPassword.length >= 8) {
      setNewIsEightLength(true);
    } else {
      setNewIsEightLength(false);
    }

    if (newPassword.match(/[a-z]/g)) {
      setNewIsOneLower(true);
    } else {
      setNewIsOneLower(false);
    }

    if (newPassword.match(/[A-Z]/g)) {
      setNewIsOneUpper(true);
    } else {
      setNewIsOneUpper(false);
    }

    if (newPassword.match(/[0-9]/g)) {
      setNewHasOneNumber(true);
    } else {
      setNewHasOneNumber(false);
    }

    if (newPassword.match(/[!@#\$%\^\&*\)\(+=._-]/g)) {
      setNewHasOneSplChar(true);
    } else {
      setNewHasOneSplChar(false);
    }
  };
  const validateConfirmPassword = () => {
    if (confirmPassword.length >= 8) {
      setConfirmIsEightLength(true);
    } else {
      setConfirmIsEightLength(false);
    }

    if (confirmPassword.match(/[a-z]/g)) {
      setConfirmIsOneLower(true);
    } else {
      setConfirmIsOneLower(false);
    }

    if (confirmPassword.match(/[A-Z]/g)) {
      setConfirmIsOneUpper(true);
    } else {
      setConfirmIsOneUpper(false);
    }

    if (confirmPassword.match(/[0-9]/g)) {
      setConfirmHasOneNumber(true);
    } else {
      setConfirmHasOneNumber(false);
    }

    if (confirmPassword.match(/[!@#\$%\^\&*\)\(+=._-]/g)) {
      setConfirmHasOneSplChar(true);
    } else {
      setConfirmHasOneSplChar(false);
    }
  };

  const handleValidation = async (e, value, field) => {
    e.preventDefault();
    if (!value && field === "NewPassword") {
      setNewPasswordError("Please enter new password");
    } else if (value && field === "NewPassword") {
      if (
        validator.isStrongPassword(value, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setNewPasswordError("");
      } else {
        setNewPasswordError(
          "Your password must be at least 8 characters including a lowercase letter, an uppercase letter, and a number"
        );
      }
    }
    if (!value && field === "confirmPassword") {
      setConfirmPasswordError("Please enter confirm password");
    } else if (value && field === "confirmPassword") {
      if (
        validator.isStrongPassword(value, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setConfirmPasswordError("");
      } else {
        setConfirmPasswordError(
          "Your password must be at least 8 characters including a lowercase letter, an uppercase letter, and a number"
        );
      }
    }
  };
  return (
    <Container header>
      <Helmet>
        <title>Change Password</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        {/* <?php include 'include/header.php'; ?> */}

        <div className="main">
          {/* <?php include 'include/left-navigation.php'; ?> */}
          <LeftNavigation />

          <div className="container py-lg-4 py-3">
            <ProfileImage />

            <div className="row">
              <Sidebar />
              <div className="col-md-9">
                <div className="bg-white box-shadow rounded-15 p-md-3 p-2">
                  <div className="pt-md-2 pb-3 mb-3 px-lg-3 border-bottom">
                    <h3 className="medium">Change Password</h3>
                  </div>

                  <form onSubmit={handleSave} className="profile-form ">
                    <div className="row px-lg-3 pb-4">
                      <div className="col-md-6">
                        <div className="form-field password-container">
                          <label for="" className="fs-18 d-block medium pb-2">
                            Current Password
                          </label>
                          <div
                            className="d-flex align-items-center"
                            style={{ position: "relative" }}
                          >
                            <input
                              type={hidden ? "password" : "text"}
                              placeholder="Password"
                              value={currentPassword}
                              onChange={(e) => {
                                setCurrentPassword(e.target.value);
                              }}
                              onKeyUp={() => {
                                validatePassword();
                              }}
                              onFocus={() => setIsVisible(true)}
                              onBlur={(e) => {
                                handleValidation(
                                  e,
                                  e.target.value,
                                  "NewPassword"
                                );
                                setIsVisible(false);
                              }}
                            />
                            <div
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                right: 10,
                              }}
                              onClick={() => setHidden(!hidden)}
                            >
                              {hidden ? (
                                <AiFillEyeInvisible size={25} />
                              ) : (
                                <AiFillEye size={25} />
                              )}
                            </div>
                          </div>
                          {currentPasswordError ? (
                            <span className="text-danger">
                              {currentPasswordError}
                            </span>
                          ) : (
                            ""
                          )}

                          {isVisible && (
                            <div className="password-tooltip">
                              <h6 className="mb-2">Your password must have:</h6>
                              <p
                                className={`${
                                  isEightLength ? "text-green" : ""
                                }`}
                              >
                                atleast 8 characters
                              </p>
                              <p
                                className={`${isOneUpper ? "text-green" : ""}`}
                              >
                                atleast 1 uppercase
                              </p>
                              <p
                                className={`${isOneLower ? "text-green" : ""}`}
                              >
                                atleast 1 lowercase
                              </p>
                              <p
                                className={`${
                                  hasOneNumber ? "text-green" : ""
                                }`}
                              >
                                atleast 1 number
                              </p>
                              <p
                                className={`${
                                  hasOneSplChar ? "text-green" : ""
                                }`}
                              >
                                atleast 1 speical character
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row px-lg-3">
                      <div className="col-md-6">
                        <div className="form-field password-container">
                          <label for="" className="fs-18 d-block medium pb-2">
                            New Password
                          </label>
                          <div
                            className="d-flex align-items-center"
                            style={{ position: "relative" }}
                          >
                            <input
                              type={newHidden ? "password" : "text"}
                              placeholder="Enter Password"
                              value={newPassword}
                              onChange={(e) => {
                                setNewPassword(e.target.value);
                              }}
                              onKeyUp={() => {
                                validateNewPassword();
                              }}
                              onFocus={() => setNewIsVisible(true)}
                              onBlur={(e) => {
                                handleValidation(
                                  e,
                                  e.target.value,
                                  "NewPassword"
                                );
                                setNewIsVisible(false);
                              }}
                            />
                            <div
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                right: 10,
                              }}
                              onClick={() => setNewHidden(!newHidden)}
                            >
                              {newHidden ? (
                                <AiFillEyeInvisible size={25} />
                              ) : (
                                <AiFillEye size={25} />
                              )}
                            </div>
                          </div>
                          {newPasswordError ? (
                            <span className="text-danger">
                              {newPasswordError}
                            </span>
                          ) : (
                            ""
                          )}

                          {NewIsVisible && (
                            <div className="password-tooltip">
                              <h6 className="mb-2">Your password must have:</h6>
                              <p
                                className={`${
                                  NewisEightLength ? "text-green" : ""
                                }`}
                              >
                                atleast 8 characters
                              </p>
                              <p
                                className={`${
                                  NewisOneUpper ? "text-green" : ""
                                }`}
                              >
                                atleast 1 uppercase
                              </p>
                              <p
                                className={`${
                                  NewisOneLower ? "text-green" : ""
                                }`}
                              >
                                atleast 1 lowercase
                              </p>
                              <p
                                className={`${
                                  NewhasOneNumber ? "text-green" : ""
                                }`}
                              >
                                atleast 1 number
                              </p>
                              <p
                                className={`${
                                  NewhasOneSplChar ? "text-green" : ""
                                }`}
                              >
                                atleast 1 speical character
                              </p>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-field password-container">
                          <label for="" className="fs-18 d-block medium pb-2">
                            Confirm New Password
                          </label>
                          <div
                            className="d-flex align-items-center"
                            style={{ position: "relative" }}
                          >
                            <input
                              type={confirmHidden ? "password" : "text"}
                              placeholder="Enter Password"
                              value={confirmPassword}
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                              }}
                              onKeyUp={() => {
                                validateConfirmPassword();
                              }}
                              onFocus={() => setConfirmIsVisible(true)}
                              onBlur={(e) => {
                                handleValidation(
                                  e,
                                  e.target.value,
                                  "ConfirmPassword"
                                );
                                setConfirmIsVisible(false);
                              }}
                            />
                            <div
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                right: 10,
                              }}
                              onClick={() => setConfirmHidden(!confirmHidden)}
                            >
                              {confirmHidden ? (
                                <AiFillEyeInvisible size={25} />
                              ) : (
                                <AiFillEye size={25} />
                              )}
                            </div>
                          </div>
                          {confirmPasswordError ? (
                            <span className="text-danger">
                              {confirmPasswordError}
                            </span>
                          ) : (
                            ""
                          )}
                          {ConfirmIsVisible && (
                            <div className="password-tooltip">
                              <h6 className="mb-2">Your password must have:</h6>
                              <p
                                className={`${
                                  ConfirmisEightLength ? "text-green" : ""
                                }`}
                              >
                                atleast 8 characters
                              </p>
                              <p
                                className={`${
                                  ConfirmisOneUpper ? "text-green" : ""
                                }`}
                              >
                                atleast 1 uppercase
                              </p>
                              <p
                                className={`${
                                  ConfirmisOneLower ? "text-green" : ""
                                }`}
                              >
                                atleast 1 lowercase
                              </p>
                              <p
                                className={`${
                                  ConfirmhasOneNumber ? "text-green" : ""
                                }`}
                              >
                                atleast 1 number
                              </p>
                              <p
                                className={`${
                                  ConfirmhasOneSplChar ? "text-green" : ""
                                }`}
                              >
                                atleast 1 speical character
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="text-md-end text-center py-2 px-3 mb-lg-2">
                      {/* <Link to={'/updateprofile2'}> */}
                      <button type="submit" className="btn">
                        Update Password
                      </button>
                      {/* </Link> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <?php include 'include/online-friends.php'; ?> */}
        <OnlineFriends />
      </div>
    </Container>
  );
}

export default ChangePassword;
