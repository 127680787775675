import React, { useState, useEffect, useRef } from "react";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import { FetchApi } from "../../API/FetchApi";
import Post from "../../Components/Post/Post";
import BlogCard from "../../Components/BlogCard/BlogCard";
import PostLoader from "../../Components/PostLoader/PostLoader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SharedPost from "../../Components/SharedPost/SharedPost";
import ToastMessage from "../../Utils/ToastMessage";
import SharedBlogCard from "../../Components/SharedBlogCard/SharedBlogCard";
import SharedMedia from "../../Components/SharedMedia/SharedMedia";
import LinkPost from "../../Components/LinkPost/LinkPost";
import PostShare from "../../Components/PostShare/PostShare";
export default function PostItem({ post }) {
    const [timelinePosts, setTimelinePosts] = useState([]);
    const [mouserHovering, setMouserHovering] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasMorePosts, setHasMorePosts] = useState(true);
    const [postPage, setPostPage] = useState(1);

    const listInnerRef = useRef();
    useEffect(()=>{
        setTimelinePosts(post);
    }, [post]);
  
    const showReactionsBox = () => {
        setMouserHovering(true);
    };
    const hideReactionsBox = () => {
        setTimeout(() => {
            setMouserHovering(false);
        }, 1400);
    };
    const timeSince = (date, isCurrent = false) => {
        if (isCurrent) return 0 + " sec";
        let seconds = Math.floor((new Date() - date) / 1000);
        let interval = seconds / 31536000;

        if (interval > 1) return Math.floor(interval) + "y";
        interval = seconds / 2592000;

        if (interval > 1) return Math.floor(interval) + " mon";
        interval = seconds / 86400;

        if (interval > 1) return Math.floor(interval) + "d";
        interval = seconds / 3600;

        if (interval > 1) return Math.floor(interval) + "h";
        interval = seconds / 60;

        if (interval > 1) return Math.floor(interval) + " min";
        return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec" : "0 sec";
    };

    function formatDateTime(postDate) {
        let date = new Date(postDate);
        let options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        };
        let formattedDate = date.toLocaleDateString("en-US", options);
        return formattedDate;
    }
    return (
        <>
            <ul className="timeline-feed feed-list px-3 pt-4 pb-1">
                {timelinePosts.map((post, i) => {
                    console.log({ post });
                    if (
                        post.category === "Blog" &&
                        post.isShared === 0 &&
                        post.isMediaShared === 0
                    ) {
                        console.log("inside first logic");
                        return (
                            <BlogCard
                                post={post}
                                key={post.id}
                                // getUserPosts={getUserPosts}
                                mouserHovering={mouserHovering}
                                showReactionsBox={showReactionsBox}
                                hideReactionsBox={hideReactionsBox}
                                timeSince={timeSince}
                                formatDateTime={formatDateTime}
                            />
                        );
                    } else if (
                        post.isShared &&
                        post.isMediaShared === 0 &&
                        post.category !== "Blog"
                    ) {
                        console.log("inside second logic");
                        return (
                            <SharedPost
                                post={post}
                                // getUserPosts={getUserPosts}
                                key={post.id}
                                mouserHovering={mouserHovering}
                                showReactionsBox={showReactionsBox}
                                hideReactionsBox={hideReactionsBox}
                                timeSince={timeSince}
                                formatDateTime={formatDateTime}
                            />
                        );
                    } else if (
                        post.isShared &&
                        post.isMediaShared &&
                        post.category !== "Blog"
                    ) {
                        console.log("inside third logic");
                        return (
                            <SharedMedia
                                post={post}
                                // getUserPosts={getUserPosts}
                                key={post.id}
                                mouserHovering={mouserHovering}
                                showReactionsBox={showReactionsBox}
                                hideReactionsBox={hideReactionsBox}
                                timeSince={timeSince}
                                formatDateTime={formatDateTime}
                            />
                        );
                    } else if (
                        post.isShared === 0 &&
                        post.isMediaShared === 0 &&
                        post.category !== "Blog" &&
                        post.category !== "Link"
                    ) {
                        // console.log("inside fourth logic");
                        return (
                            <Post
                                post={post}
                                // getUserPosts={getUserPosts}
                                key={post.id}
                                mouserHovering={mouserHovering}
                                showReactionsBox={showReactionsBox}
                                hideReactionsBox={hideReactionsBox}
                                timeSince={timeSince}
                                formatDateTime={formatDateTime}
                            />
                        );
                    } else if (post.category === "Link") {
                        console.log("inside fifth logic");
                        return (
                            <LinkPost
                                post={post}
                                key={post.id}
                                // getUserPosts={getUserPosts}
                                mouserHovering={mouserHovering}
                                showReactionsBox={showReactionsBox}
                                hideReactionsBox={hideReactionsBox}
                                timeSince={timeSince}
                                setTimelinePosts={setTimelinePosts}
                                setPostPage={setPostPage}
                                postPage={postPage}
                                formatDateTime={formatDateTime}
                                listInnerRef={listInnerRef}
                            />
                        );
                    } else if (
                        post.category === "Blog" &&
                        post.isShared !== 0
                    ) {
                        console.log("inside sixth logic");
                        return (
                            <SharedBlogCard
                                post={post}
                                key={post.id}
                                // getUserPosts={getUserPosts}
                                mouserHovering={mouserHovering}
                                showReactionsBox={showReactionsBox}
                                hideReactionsBox={hideReactionsBox}
                                timeSince={timeSince}
                                formatDateTime={formatDateTime}
                            />
                        );
                    }
                })}
                {loading === true && hasMorePosts === true && <PostLoader />}
            </ul>
        </>
    )
}
