import React, { useEffect, useState } from "react";
import { FetchApi } from "../../API/FetchApi";
import { PatchApi } from "../../API/PatchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";

export default function PeopleItem({ type, setTotalPeoples, SearchText }) {
  const [otherPeopleData, setOtherPeopleData] = useState([]);
  const [count, setCount] = useState(0);
  const [friendCount, setFriendCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [groupData, setGroupData] = useState("");
  const param = useParams();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    if (SearchText) {
      getSearchPeople();
    } else {
      getCompanyPeopleOther();
    }

    getAbout();
  }, [SearchText]);

  const getAbout = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.GetGroupsAbout + "?groupId=" + param?.id
      );
      if (resp && resp.success === true) {
        setGroupData(resp?.data.groupData);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getCompanyPeopleOther = async () => {
    if (type === "friend") {
      try {
        let resp = await FetchApi(
          Endpoints.GetGroupsPeople +
            "?groupId=" +
            param?.id +
            "&friends=1" +
            "&size=" +
            limit
        );
        if (resp && resp.success === true) {
          setOtherPeopleData(resp?.data?.Member);
          setFriendCount(resp?.data?.totalFriendCount);
          setTotalPeoples(resp?.data?.totalMember);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      try {
        let resp = await FetchApi(
          Endpoints.GetGroupsPeople + "?groupId=" + param?.id + "&size=" + limit
        );
        if (resp && resp.success === true) {
          setOtherPeopleData(resp?.data?.Member);
          setCount(resp?.data?.totalNonFriendCount);
          setTotalPeoples(resp?.data?.totalMember);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  const getSearchPeople = async () => {
    if (type === "friend") {
      try {
        let resp = await FetchApi(
          Endpoints.GetGroupsPeople +
            "?groupId=" +
            param?.id +
            "&friends=1" +
            "&size=" +
            limit +
            "&term=" +
            SearchText
        );
        if (resp && resp.success === true) {
          setOtherPeopleData(resp?.data?.Member);
          setFriendCount(resp?.data?.totalFriendCount);
          setTotalPeoples(resp?.data?.totalMember);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      try {
        let resp = await FetchApi(
          Endpoints.GetGroupsPeople +
            "?groupId=" +
            param?.id +
            "&size=" +
            limit +
            "&term=" +
            SearchText
        );
        if (resp && resp.success === true) {
          setOtherPeopleData(resp?.data?.Member);
          setCount(resp?.data?.totalNonFriendCount);
          setTotalPeoples(resp?.data?.totalMember);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleLoadMore = async (userType) => {
    const newLimit = limit + 10;
    setLimit(newLimit);
    if (userType === "friend") {
      try {
        let resp = await FetchApi(
          Endpoints.GetGroupsPeople +
            "?groupId=" +
            param?.id +
            "&friends=1" +
            "&size=" +
            newLimit
        );
        if (resp && resp.success === true) {
          setOtherPeopleData(resp?.data?.Member);
          setFriendCount(resp?.data?.totalFriendCount);
          setTotalPeoples(resp?.data?.totalMember);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      try {
        let resp = await FetchApi(
          Endpoints.GetGroupsPeople +
            "?groupId=" +
            param?.id +
            "&size=" +
            newLimit
        );
        if (resp && resp.success === true) {
          setOtherPeopleData(resp?.data?.Member);
          setCount(resp?.data?.totalNonFriendCount);
          setTotalPeoples(resp?.data?.totalMember);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const sendFriendRequest = async (Id) => {
    let data = {
      receiverId: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.SendFriendRequest, data);
      if (resp && resp.message) {
        getCompanyPeopleOther();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleBlock = async (id) => {
    let data = {
      action: "block",
      groupId: param?.id,
      userId: id,
    };
    try {
      let resp = await PatchApi(Endpoints.GroupBlockAction, data);
      if (resp && resp.success === true) {
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  return (
    <>
      <h5 className="medium ">
        {" "}
        {type === "friend" ? "Friends " : "Other Members "}{" "}
        <span className="text-gray">
          - {type === "friend" ? friendCount : count}
        </span>
      </h5>
      <div class="mb-4 mt-3">
        {/* <div className="mb-2 pb-3">
                    {type === "friend" ? "Friends who follow - " + friendCount : "Other Peoples - " + count}
                </div> */}

        {otherPeopleData.length > 0
          ? otherPeopleData.map((item, index) => {
              return (
                <div class="pb-3 d-flex align-items-center" key={index}>
                  <div className="circular--landscape pic-size-48-flex">
                    <Link to={"/timeline/" + item?.username}>
                      {item?.user_profile?.profileImg === null ? (
                        <img src="../assets/img/profile6.png" alt="" />
                      ) : (
                        <img
                          src={
                            Endpoints.baseUrl + item?.user_profile?.profileImg
                          }
                          alt=""
                        />
                      )}
                    </Link>
                  </div>

                  <div class="d-flex align-items-center justify-content-between w-100 ps-2">
                    <div>
                      <Link to={"/timeline/" + item?.username}>
                        <h6 class="medium text-black fs-14">{item.fullName}</h6>
                      </Link>
                      <h6 class="fs-12 regular text-darkgray">
                        {item?.user_profile?.profileTagline}
                      </h6>
                      <h6 class="fs-10 regular text-darkgray">
                        {item?.mutualFriends > 0
                          ? item?.mutualFriends + " mutual friends"
                          : ""}
                      </h6>
                    </div>

                    <div class="position-relative text-end d-flex gap-2 align-items-center">
                      {item.isFriend === "NA" ? (
                        <Link
                          onClick={() => sendFriendRequest(item.id)}
                          class="btn fs-12 py-1 px-3 w-100"
                        >
                          Add Friend
                          <img
                            src="../assets/img/icon/icon-add-friend.svg"
                            class="ms-1"
                            width="10"
                          />
                        </Link>
                      ) : item.isFriend === "ACCEPTED" ? (
                        "FRIEND"
                      ) : (
                        item.isFriend
                      )}
                      {groupData.userId == userId ? (
                        <>
                          <a
                            href="#"
                            id="follower_1"
                            data-bs-toggle="dropdown"
                            class="d-flex align-items-center justify-content-center "
                          >
                            <svg
                              viewBox="0 0 24 24"
                              width="22"
                              height="22"
                              stroke="#000"
                              strokeWidth="2"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              class="css-i6dzq1"
                            >
                              <circle cx="12" cy="12" r="1"></circle>
                              <circle cx="19" cy="12" r="1"></circle>
                              <circle cx="5" cy="12" r="1"></circle>
                            </svg>
                          </a>

                          <ul
                            class="dropdown-menu fs-12 text-start shadow rounded-1"
                            role="menu"
                            id="follower_1"
                            aria-hidden="true"
                          >
                            {/* <li><a class="dropdown-item py-2 d-flex gap-2" href="#">
                                            <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" class="css-i6dzq1"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                            &nbsp; Delete
                                        </a></li> */}
                            <li>
                              <Link
                                class="dropdown-item py-2 d-flex gap-2"
                                onClick={() => {
                                  handleBlock(item?.group_members?.userId);
                                }}
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  width="14"
                                  height="14"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  class="css-i6dzq1"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <line
                                    x1="4.93"
                                    y1="4.93"
                                    x2="19.07"
                                    y2="19.07"
                                  ></line>
                                </svg>
                                &nbsp; Block
                              </Link>
                            </li>
                          </ul>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          : type === "friend"
          ? "No friends People found"
          : "No other People found"}
      </div>

      {type === "friend" ? (
        friendCount > limit ? (
          <Link
            onClick={() => {
              handleLoadMore("friend");
            }}
            class="btn w-100 bg-d8d8d8 text-black fs-14 medium"
          >
            See All Members
          </Link>
        ) : (
          ""
        )
      ) : count > limit ? (
        <Link
          onClick={() => {
            handleLoadMore("unfriend");
          }}
          class="btn w-100 bg-d8d8d8 text-black fs-14 medium"
        >
          See All Members
        </Link>
      ) : (
        ""
      )}
    </>
  );
}
