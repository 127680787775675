import React, { useEffect, useState } from "react";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from '../../API/FetchApi';
import {  Link, useNavigate } from "react-router-dom";
import { CommonAPi } from '../../API/CommonApi';

export default function SuggestedSchools() {
    const [data, setData] = useState([]);
    const Navigate = useNavigate();
    useEffect(() => {
      getrecommendationsUser();
    }, []);
    const getrecommendationsUser = async (e) => {
      try {
          let resp = await FetchApi(Endpoints.recommendationsUser);
          if (resp && resp.success === true) {
            setData(resp?.data?.schoolRecommendations);
          }
      }
      catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
              if (e.response.status === 401) {
                  ToastMessage.Error('Session Expired! Please login again.');
                  localStorage.clear();
                  Navigate('/signin');
              } else {
                  ToastMessage.Error(e.response.data.message);
              }

          }
      }
  };

  const handleFollow = async (Id) => {
    let data = {
      page_id: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.PageFollow, data);
      if (resp && resp.message) {
        getrecommendationsUser();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  return (
    <>
    {data?.length > 0 ?  
    <div className="sidebar-widget mt-4">
    <div className="d-flex align-items-center justify-content-between">
      <h4 className="medium">Suggested School</h4>
      <Link to={"/schools"} className="text-brown fs-12 medium">
        See all{" "}
        <img src="../assets/img/icon/icon-right-arrow-brown.svg" class="ps-1 text-brown" alt="" />
      </Link>
    </div>

    <div className="pt-3 mt-2 border-top">
      <ul>

        {data.map((item, index)=>{
            if(index > 3) return null;
            return (
                <li className="d-flex align-items-center pb-3">
                <div className="position-relative">
                <Link to={"/page-profile/" + item?.slug}>
                  <img
                    src={Endpoints.baseUrl + item?.pageImage}
                    alt=""
                    width="58"
                    height="48"
                    className="rounded-circle"
                  />
                  </Link>
                </div>
                <div className="ps-2 w-100 d-flex align-items-center justify-content-between ">
                  <div className="">
                  <Link to={"/page-profile/" + item?.slug}>
                    <h6 className="medium text-black">
                      {item.title}
                    </h6>
                  </Link>
                    <h6 className="fs-12 regular text-darkgray pt-1">
                      0 Student
                    </h6>
                  </div>
                  {item.isFollow === "false" ?
                  <Link onClick={()=>{
                    handleFollow(item.id)
                  }}>
                    <img src="assets/img/icon/btn-join.svg" alt="" />
                  </Link>
                : "" }


                </div>
              </li>
            )
        })}
      

      </ul>
    </div>
    </div>
    : ""}
  </>
  )
}
