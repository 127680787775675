import React, { useEffect, useState, useCallback } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate, useParams } from "react-router-dom";
import Item from "./Item";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import TeacherItem from "./TeacherItem";
import TopCompany from "./TopCompany";
import { Helmet } from "react-helmet";

function Courses() {
  const [topCategory, setTopCategory] = useState([]);
  const [topInstructor, setTopInstructor] = useState([]);
  const [categoryCourse, setCategoryCourse] = useState([]);
  const [featuredCourse, setFeaturedCourse] = useState([]);
  const [recentCourse, setRecentCourse] = useState([]);
  const [activeCategory, setActiveCategory] = useState([]);
  const [option, setOption] = useState([]);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const parms = useParams();

  const instructorStatus = localStorage.getItem("instructorStatus");
  useEffect(() => {
    handleFeaturedCourse();
    handleRecentCourse();
    getTopCategory();
    getTopInstructor();
    handleCategoryCourse(parms.id);
  }, []);
  // const getCategory = async () => {
  //   try {
  //     let resp = await FetchApi(Endpoints.GetCourseCategory);
  //     if (resp && resp.success === true) {
  //       setCategory(resp.data);
  //     }
  //   } catch (e) {
  //     if (e && e.response && e.response.data && e.response.data.message) {
  //       if (e.response.status === 401) {
  //         ToastMessage.Error("Session Expired! Please login again.");
  //         localStorage.clear();
  //         navigate("/signin");
  //       } else {
  //         ToastMessage.Error(e.response.data.message);
  //       }
  //     }
  //   }
  // };
  const getTopCategory = async () => {
    try {
      let resp = await FetchApi(Endpoints.topCategory);
      if (resp && resp.success === true) {
        // console.log("cate______", resp.data)
        setTopCategory(resp.data);
        if (!parms?.id) {
          handleCategoryCourse(resp.data[0]?.id);
        }
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleCategory = async (Id) => {
    if (activeCategory.filter((item) => item === Id).length > 0) {
      let newActiveCategory = activeCategory.filter((item) => item !== Id);
      setActiveCategory(newActiveCategory);
    } else {
      let data = [];
      data.push(parseInt(Id));
      setActiveCategory(data);
    }

    navigate("/courses/" + Id);
    handleCategoryCourse(Id);
  };

  const handleCategoryCourse = async (Id) => {
    let data = [];
    data.push(parseInt(Id));
    setActiveCategory(data);

    try {
      let resp = await FetchApi(Endpoints.courseByCategory + "?id=" + Id);
      if (resp && resp.success === true) {
        setCategoryCourse(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleFeaturedCourse = async () => {
    try {
      let resp = await FetchApi(Endpoints.featuredCourse + "?size=8");
      if (resp && resp.success === true) {
        setFeaturedCourse(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleRecentCourse = async () => {
    try {
      let resp = await FetchApi(Endpoints.recentCourse);
      if (resp && resp.success === true) {
        setRecentCourse(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getTopInstructor = async () => {
    try {
      let resp = await FetchApi(Endpoints.topInstructor + "?size=8");
      if (resp && resp.success === true) {
        setTopInstructor(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSearch = () => {
    navigate("/course-listing?keyword=" + searchText);
  };

  const handleOnSearch = async (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string) {
      setSearchText(string);
      try {
        let resp = await FetchApi(
          Endpoints.SearchCourse + "?keyword=" + string
        );
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item) => {
              return {
                id: item?.type === "topic" ? "topic" : item.id,
                name:
                  item?.type === "course"
                    ? item.title
                    : item?.type === "topic"
                    ? item.topic.find((ite) =>
                        ite.toLowerCase().includes(string.toLowerCase())
                      )
                    : item.name,
                image:
                  item.type === "course"
                    ? Endpoints.baseUrl + item.thumbnail
                    : "../assets/img/search_gray.png",
                type: item.type,
                string: string,
                slug: item?.slug,
              };
            });

            setOption(newData);
          } else {
            let data = {
              id: null,
              name: "Result not found",
              image: "../assets/img/search_gray.png",
              type: null,
              string: string,
              slug: null,
            };
            let newData = [];
            newData.push(data);
            setOption(newData);
          }
        } else {
          let data = {
            id: null,
            name: "Result not found",
            image: "../assets/img/search_gray.png",
            type: null,
            string: string,
            slug: null,
          };
          let newData = [];
          newData.push(data);
          setOption(newData);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleOnSelect = (item) => {
    if (item[0]?.type === "subcategory") {
      let data = [];
      data.push(item[0]?.id);
      navigate("/course-listing?sid=" + JSON.stringify(data));
    }
    if (item[0]?.type === "category") {
      let data = [];
      data.push(item[0]?.id);
      navigate("/course-listing?cid=" + JSON.stringify(data));
    }
    if (item[0]?.type === "course") {
      console.log(item[0]);
      navigate("/course-detail/" + item[0]?.slug);
    }

    if (item[0]?.type === "topic") {
      let data = [];
      data.push(item[0]?.name);
      navigate("/course-listing?topics=" + JSON.stringify(data));
    }
  };

  const RenderCourseSearch = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-couse-listing"
        // defaultInputValue={studyArea}
        // key={studyAreaKey}
        onSearch={handleOnSearch}
        onChange={handleOnSelect}
        options={option}
        labelKey="string"
        minLength={1}
        emptyLabel={"Searching..."}
        placeholder="Search for courses"
        className="ps-2 py-3 text-mediumgray RenderCourseSearch  w-100"
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <img
              alt={option.name}
              src={option.image}
              style={{
                height: "30px",
                marginRight: "10px",
                width: "30px",
              }}
            />
            <span>{option.name}</span>
          </div>
        )}
        useCache={true}
      />
    );
  }, [option]);

  return (
    <Container header>
      <Helmet>
        <title>Courses</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />

          <div className="container py-3">
            <div className="bg-primary rounded-15 pt-lg-5 px-lg-5 p-3">
              <div className="row">
                <div className="col-md-5 order-md-2 pb-md-0 pb-4">
                  <img src="../assets/img/course-top-img.png" alt="" />
                </div>

                <div className="col-md-7 order-md-1">
                  <div className="pb-lg-5 pb-3 text-white pt-md-4">
                    <h1 className="bold pb-3">
                      Discover a new way of learning
                    </h1>
                    <h5 className="regular">
                      Lorem ipsum dolor sit amet, consec tetur adipiscing elit.
                    </h5>
                  </div>

                  <div className="job-search-form">
                    <form
                      action=""
                      className="row justify-content-between m-0 bg-white rounded-pill"
                    >
                      <div className="col-md-9 d-flex align-items-center ps-3">
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          stroke="#949494"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="css-i6dzq1"
                        >
                          <circle cx="11" cy="11" r="8"></circle>
                          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>

                        {RenderCourseSearch()}
                      </div>

                      <button
                        className="btn btn-brown px-4 py-3  col-md-3"
                        onClick={handleSearch}
                      >
                        Search
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white main-common mt-4">
              <h3 className="bold pb-2">
                A broad selection of courses from top categories
              </h3>
              <h5 className="medium pb-4">
                Choose from 185,000 online video courses with new additions
                published every month
              </h5>

              <div className="mainmenu-filter-row pb-3">
                <ul className="filter d-flex flex-nowrap align-items-center gap-2 fs-14 medium">
                  {topCategory?.map((item, index) => {
                    if (index <= 4) {
                      return (
                        <li
                          className={
                            parseInt(activeCategory[0]) === parseInt(item?.id)
                              ? "active"
                              : ""
                          }
                          key={index}
                          onClick={() => {
                            handleCategory(item?.id);
                          }}
                        >
                          {item?.name}{" "}
                        </li>
                      );
                    }
                  })}

                  <select
                    style={{ fontWeight: "550", width: "auto" }}
                    name=""
                    id=""
                    onChange={(e) => {
                      handleCategory(e.target.value);
                    }}
                  >
                    <option value="">Other Categories</option>
                    {topCategory?.map((item, index) => {
                      if (index > 4) {
                        return (
                          <option
                            className={
                              parseInt(activeCategory[0]) === parseInt(item?.id)
                                ? "active"
                                : ""
                            }
                            key={index}
                            value={parseInt(item?.id)}
                          >
                            {item?.name}
                          </option>
                        );
                      }
                    })}
                  </select>
                </ul>
              </div>
              {categoryCourse?.length > 0 ? (
                <div className="row mainmenu-filter-container">
                  {categoryCourse?.map((item, index) => {
                    return (
                      <Item
                        {...{
                          item: item,
                          index: index,
                          onSuccess: handleCategoryCourse,
                        }}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className="row mainmenu-filter-container">
                  no course found
                </div>
              )}
              {categoryCourse.length > 0 ? (
                <div className="text-center">
                  <Link
                    to={"/course-listing?cid=" + JSON.stringify(activeCategory)}
                    className="btn px-4"
                  >
                    Browse All Courses
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="bg-white main-common mt-4">
              <h3 className="bold pb-2">Featured Courses</h3>
              <h5 className="medium pb-4">
                Hand picked courses for our students
              </h5>

              <div className="row mainmenu-filter-container">
                {featuredCourse.map((item, index) => {
                  return (
                    <Item
                      {...{
                        item: item,
                        index: index,
                        onSuccess: handleFeaturedCourse,
                      }}
                    />
                  );
                })}
              </div>

              <div className="text-center">
                <Link to={"/course-listing?size=12"} className="btn px-4">
                  Browse All Courses
                </Link>
              </div>
            </div>

            <div className="bg-white main-common mt-4">
              <h3 className="bold pb-2">Recently Added Courses</h3>
              <h5 className="medium pb-4">
                Our top instructor recently added courses for the students
              </h5>

              <div className="row mainmenu-filter-container">
                {recentCourse.map((item, index) => {
                  return (
                    <Item
                      {...{
                        item: item,
                        index: index,
                        onSuccess: handleRecentCourse,
                      }}
                    />
                  );
                })}
              </div>

              <div className="text-center">
                <Link
                  to={"/course-listing?newest=" + true}
                  className="btn px-4"
                >
                  Browse All Courses
                </Link>
              </div>
            </div>

            <TopCompany />

            {instructorStatus === "Complete" ? (
              ""
            ) : (
              <div className="bg-primary rounded-15 mt-4">
                <div className="row align-items-center m-0">
                  <div className="col-md-4 text-center order-md-2 py-md-0 py-4 ">
                    <img src="../assets/img/instructor.png" alt="" />
                  </div>

                  <div className="col-md-8 px-4 order-md-1 pb-md-0 pb-4">
                    <h3 className="bold pb-2 text-white">
                      Become an instructor
                    </h3>
                    <h5 className="regular text-white py-3 col-lg-8">
                      Thousands of students waiting for a instructor. Start
                      teaching & earning now!.
                    </h5>

                    <Link
                      to={"/become-instructor"}
                      className="btn text-blue bg-white px-4"
                    >
                      Start teaching today!
                    </Link>
                  </div>
                </div>
              </div>
            )}

            <div className="bg-white main-common mt-4">
              <div className="text-center">
                <h3 className="bold pb-2">Top Instructors</h3>
                <h5 className="medium pb-4">
                  Most famous courses are taught by these instructors
                </h5>
              </div>

              <div className="row">
                {topInstructor.map((item, index) => {
                  return (
                    <TeacherItem
                      {...{
                        item: item,
                        index: index,
                      }}
                    />
                  );
                })}
              </div>

              <div className="text-center">
                <Link to="/teachers" className="btn px-4">
                  Browse All Instructors
                </Link>
              </div>
            </div>
          </div>

          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}

export default Courses;
