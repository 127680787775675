import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { CommonAPi } from "../../../API/CommonApi";
import { Endpoints } from "../../../API/Endpoints";
import { FetchApi } from "../../../API/FetchApi";
import "./ChatBox.css";
import { linkReplace, timeSince } from "../../../Utils/helpers";
import { StickyChatContext } from "../../../Components/StickyChatWrapper";
import EmojiPickerFloatingWindow from "../../../Components/emojiPicker/EmojiPickerFloatingWindow";
import { IoPlayCircle } from "react-icons/io5";
import ImagePreviewModel from "../../../Components/Post/ImagePreviewModal";

const ChatBox = ({
  currentUser,
  chat,
  online,
  receivedMsg,
  getUserChats,
  chats,
  setChats,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const { socket } = useContext(StickyChatContext);

  const chatContainerRef = useRef(null);

  const savedScrollPosition = useRef();

  const fileRef = useRef();

  const filePreviewBox = useRef();

  const emojiButtonRef = useRef();

  const [newMsg, setNewMsg] = useState("");
  const [file, setFile] = useState(null);
  const [messages, setMessages] = useState([]);
  const [fileSelectionError, setFileSelectionError] = useState("");
  const inputRef = useRef();
  const [show, setShow] = useState(false);
  const [selectedMsg, setSelectedMsg] = useState("");

  const [sendingStatus, setSendingStatus] = useState("");

  const [currentPage, setCurrentPage] = useState(0);

  const [loadingMessages, setLoadingMessages] = useState(false);

  const [totalPages, setTotalPages] = useState();

  const [showEmojiSelector, setShowEmojiSelector] = useState(false);

  const handleEmojiSelect = useCallback((emoji) => {
    setShowEmojiSelector(false);
    setNewMsg((currentMsg) => currentMsg + emoji);
    inputRef?.current?.focus();
  }, []);

  const handleEmojiSelectionOpen = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowEmojiSelector(true);
  }, []);

  const handleEmojiSelectionClose = useCallback(() => {
    setShowEmojiSelector(false);
  }, []);

  const getMessages = useCallback(
    async (suppliedPageNumber) => {
      savedScrollPosition.current =
        chatContainerRef.current.scrollHeight -
        chatContainerRef.current.scrollTop;
      const nextPage = suppliedPageNumber ?? currentPage + 1;
      if (!suppliedPageNumber && totalPages && nextPage > totalPages) return;
      setLoadingMessages(true);
      try {
        const resp = await FetchApi(
          `${Endpoints.GetMessages}${chat.id}?page=${nextPage}&size=20`
        );
        setLoadingMessages(false);
        if (resp?.success) {
          // read un-read message
          const chatIndex = chats.findIndex(
            (searchElem) => searchElem.id === chat.id
          );
          const newChats = structuredClone(chats);
          newChats[chatIndex].unReadMsgCount = 0;
          setChats(newChats);

          setCurrentPage(nextPage);
          setMessages((currentMessages) => [
            ...resp.data.messages.reverse(),
            ...currentMessages,
          ]);
          setTotalPages(resp.data.totalPages);
        } else {
          setMessages([]);
        }
      } catch (error) {
        setLoadingMessages(false);
        console.log(error);
      }
    },
    [chat, chats, currentPage, setChats, totalPages]
  );

  const checkScrollPosition = useCallback(() => {
    if (chatContainerRef.current.scrollTop < 20 && !loadingMessages) {
      getMessages();
    }
  }, [getMessages, loadingMessages]);

  useEffect(() => {
    if (chat !== null) {
      setCurrentPage(0);
      setTotalPages(0);
      setMessages([]);
      getMessages(1);
      inputRef?.current?.focus();
    }
    setNewMsg("");
  }, [chat]);

  const handleFileChange = async (e) => {
    console.log(e.target.files[0]);
    setFileSelectionError("");
    if (
      e.target.files[0].type.includes("image") ||
      e.target.files[0].type.includes("video") ||
      e.target.files[0].type.includes("pdf") ||
      e.target.files[0].type.includes("doc") ||
      e.target.files[0].type.includes("docx")
    ) {
      setFile({ file: e.target.files[0], type: e.target.files[0].type });
    } else {
      setFileSelectionError("Please select image, pdf, doc files only");
    }
  };

  const handleNewMsg = async (e) => {
    e.preventDefault();
    if (sendingStatus === "sending") return;
    setFileSelectionError("");
    if (newMsg || file) {
      setSendingStatus("sending");
      const formdata = new FormData();
      formdata.append("sender_id", currentUser);
      formdata.append("message", newMsg);
      if (file) formdata.append("file", file.file);
      formdata.append("chat_room_id", chat.id);

      try {
        const resp = await CommonAPi(Endpoints.AddMessage, formdata);
        if (resp?.success) {
          setFileSelectionError("");
          getUserChats();
          setMessages([...messages, resp.data]);
          setNewMsg("");
          setFile("");
          fileRef.current.value = "";

          // sorting chat
          const chatIndex = chats.findIndex(
            (searchElem) => searchElem.id === resp.data.chatRoomId
          );
          if (chatIndex !== -1) {
            const newChats = structuredClone(chats);
            newChats[chatIndex].lastMessage = resp.data;
            newChats.sort(
              (a, b) =>
                new Date(b?.lastMessage?.createdAt) -
                new Date(a?.lastMessage?.createdAt)
            );
            setChats(newChats);
          }
          // to socket
          const arrayOfUsers = chat.chat_room_members
            .filter((searchUser) => searchUser.userId !== user.id)
            .map((item) => item.userId);
          socket.emit("subscribe", chat.id, arrayOfUsers);
          socket.emit("message", chat.id, resp.data);

          setSendingStatus("");
        }
      } catch (error) {
        console.log(error);
        setFileSelectionError("");
        setSendingStatus("failed");
      }
    }
  };

  useEffect(() => {
    const onMessage = (data) => {
      setMessages((currentMessages) => [...currentMessages, data]);
    };
    socket.on("messages", onMessage);
  }, []);

  useEffect(() => {
    if (chat)
      if (receivedMsg != null && receivedMsg.chat_room_id === chat.id) {
        setMessages([...messages, receivedMsg]);
      }
  }, [receivedMsg]);

  // scrolling to bottom, when message changes
  useEffect(() => {
    if (chatContainerRef.current && currentPage === 1) {
      chatContainerRef.current.scrollTo(
        0,
        chatContainerRef.current.scrollHeight
      );
    }
  }, [messages]);

  // restoring chat message position
  useLayoutEffect(() => {
    if (savedScrollPosition.current)
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight - savedScrollPosition.current;
  }, [messages]);

  useLayoutEffect(() => {
    if (filePreviewBox.current) {
      filePreviewBox.current.style.top = `-${filePreviewBox.current.offsetHeight}px`;
    }
  }, [file]);

  return (
    <div className="col-md-8 px-0">
      {chat ? (
        <>
          <div className="p-3 border-bottom">
            <div className="d-flex align-items-center gap-3 justify-content-between">
              <div className="d-flex align-items-center">
                <div
                  className="position-relative"
                  style={{ marginRight: ".66rem" }}
                >
                  {chat.roomType !== "course" ? (
                    chat?.user?.user_profile?.profileImg ? (
                      <img
                        src={`${
                          Endpoints.baseUrl +
                          chat?.user?.user_profile?.profileImg
                        }`}
                        alt=""
                        width="64"
                        height="64"
                        className="rounded-circle active-userchatpic"
                      />
                    ) : (
                      <img
                        src="/assets/img/picture.png"
                        alt=""
                        width="64"
                        height="64"
                        className="rounded-circle active-userchatpic"
                      />
                    )
                  ) : chat?.thumbnail ? (
                    <img
                      src={`${Endpoints.baseUrl + chat?.thumbnail}`}
                      alt=""
                      width="64"
                      height="64"
                      className="rounded-circle active-userchatpic"
                    />
                  ) : (
                    <img
                      src="/assets/img/picture.png"
                      alt=""
                      width="64"
                      height="64"
                      className="rounded-circle active-userchatpic"
                    />
                  )}

                  {chat.roomType !== "course" && online && (
                    <span className="user-status">
                      <svg
                        viewBox="0 0 24 24"
                        width="16"
                        height="16"
                        stroke="#fff"
                        strokeWidth="1"
                        fill="#57CB6C"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                      </svg>
                    </span>
                  )}
                </div>
                <div>
                  <h6 className="medium text-black fs-16">
                    {chat.roomType === "course" ? (
                      chat.title
                    ) : (
                      <>
                        {chat.user.fullName}
                        {chat.roomType !== "course" &&
                          (online ? (
                            <p className="text-success fs-14">Online</p>
                          ) : (
                            <p className="text-danger fs-14">Offline</p>
                          ))}
                      </>
                    )}
                  </h6>
                  {/* <h6 className="fs-12 regular text-darkgray">Active Now</h6> */}
                  <h6 className="text-brown fs-14 medium">
                    {chat.roomType === "course"
                      ? chat.title
                      : chat.user?.user_profile?.profileTagline}
                  </h6>
                </div>
              </div>
              <div className="position-relative">
                <a
                  href="#"
                  id="message_option"
                  data-bs-toggle="dropdown"
                  className="d-flex align-items-center justify-content-center rounded-circle bg-eee p-2"
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    stroke="#616161"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="css-i6dzq1"
                  >
                    <circle cx="12" cy="12" r="1"></circle>
                    <circle cx="19" cy="12" r="1"></circle>
                    <circle cx="5" cy="12" r="1"></circle>
                  </svg>
                </a>
                <div
                  className="dropdown-menu shadow rounded-1"
                  aria-labelledby="message_option"
                >
                  <ul className="fs-14">
                    <li>
                      <a className="dropdown-item" href="#">
                        <svg
                          viewBox="0 0 24 24"
                          width="15"
                          height="15"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="css-i6dzq1"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                        Edit
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        <svg
                          viewBox="0 0 24 24"
                          width="15"
                          height="15"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="css-i6dzq1"
                        >
                          <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                          <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                        </svg>
                        Delete
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            className="p-3 msg-listing"
            ref={chatContainerRef}
            onScroll={checkScrollPosition}
          >
            <div className="messages">
              {loadingMessages && (
                <div className="d-flex justify-content-center my-3">
                  <div
                    class="spinner-border text-dark spinner-border-sm"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
              {messages.length > 0 &&
                messages.map((msg, i) => (
                  <div
                    key={msg.id}
                    className={`msg-item mb-2 ${msg.senderId} ${
                      msg.senderId === currentUser && "reply-msg"
                    }`}
                    style={{ maxWidth: "max-content", wordBreak: "break-word" }}
                  >
                    <div className="pb-2">
                      {msg?.new === true ? (
                        <div className="msg-file-container">
                          {msg?.file &&
                            (msg?.msgType.includes("jpg") ||
                              msg?.msgType.includes("jpeg") ||
                              msg?.msgType.includes("png")) && (
                              <img src={msg?.file} alt="3" />
                            )}
                          {msg?.file &&
                            !msg?.msgType.includes("jpg") &&
                            !msg?.msgType.includes("jpeg") &&
                            !msg?.msgType.includes("png") && (
                              <a
                                href={msg?.file}
                                style={{ fontWeight: "bold" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {
                                  //    msg?.file.substr(50)
                                  msg.msgType.endsWith(".pdf") && (
                                    <img
                                      src="/assets/img/icon/pdf_icon.png"
                                      alt="chat-icon"
                                      style={{ width: "100%", height: "100%" }}
                                    />
                                  )
                                }

                                {(msg.msgType.endsWith(".doc") ||
                                  msg.msgType.endsWith(".docx")) && (
                                  <img
                                    src="/assets/img/icon/word_icon.png"
                                    alt="chat-icon"
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                )}

                                {msg.msgType.endsWith(".xlsx") && (
                                  <img
                                    src="/assets/img/icon/excel_icon.png"
                                    alt="chat-icon"
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                )}
                              </a>
                            )}
                        </div>
                      ) : (
                        <div className="msg-file-container">
                          {msg?.course_message_media?.file &&
                            (msg?.course_message_media?.file?.includes("jpg") ||
                              msg?.course_message_media?.file?.includes(
                                "jpeg"
                              ) ||
                              msg?.course_message_media?.file?.includes(
                                "png"
                              )) && (
                              <img
                                src={
                                  Endpoints?.baseUrl +
                                  msg?.course_message_media?.file
                                }
                                onClick={() => {
                                  setShow(true);
                                  setSelectedMsg(msg);
                                }}
                                alt="4"
                              />
                            )}
                          {msg?.course_message_media?.file &&
                            (msg?.course_message_media?.file?.includes("mp4") ||
                              msg?.course_message_media?.file?.includes(
                                "avi"
                              ) ||
                              msg?.course_message_media?.file?.includes(
                                "mov"
                              ) ||
                              msg?.course_message_media?.file?.includes(
                                "ogg"
                              ) ||
                              msg?.course_message_media?.file?.includes(
                                "wmv"
                              ) ||
                              msg?.course_message_media?.file?.includes(
                                "webm"
                              )) && (
                              <div>
                                <video
                                  style={{
                                    height: "8rem",
                                    width: "8rem",
                                    borderRadius: "8px",
                                    objectFit: "cover",
                                  }}
                                  src={
                                    Endpoints?.baseUrl +
                                    msg?.course_message_media?.file
                                  }
                                  alt=""
                                  onClick={() => {
                                    setShow(true);
                                    setSelectedMsg(msg);
                                  }}
                                ></video>
                                <div
                                  style={{
                                    position: "absolute",
                                    right: "50%",
                                    top: "50%",
                                  }}
                                >
                                  <IoPlayCircle
                                    onClick={() => {
                                      setShow(true);
                                      setSelectedMsg(msg);
                                    }}
                                    className="text-white cursor-pointer"
                                    size={25}
                                  />
                                </div>
                              </div>
                              // <video
                              //   src={
                              //     Endpoints?.baseUrl +
                              //     msg?.course_message_media?.file
                              //   }
                              //   onClick={() => {
                              //     setShow(true);
                              //     setSelectedMsg(msg);
                              //   }}
                              //   alt=""
                              //   // controls
                              // ></video>
                            )}
                          {msg?.course_message_media?.file &&
                            !msg?.course_message_media?.file?.includes("jpg") &&
                            !msg?.course_message_media?.file?.includes(
                              "jpeg"
                            ) &&
                            !msg?.course_message_media?.file?.includes(
                              "png"
                            ) && (
                              <a
                                href={
                                  Endpoints.baseUrl +
                                  msg?.course_message_media?.file
                                }
                                style={{ fontWeight: "bold" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {msg?.course_message_media?.file.endsWith(
                                  ".pdf"
                                ) && (
                                  <img
                                    src="/assets/img/icon/pdf_icon.png"
                                    alt="chat-icon"
                                    style={{
                                      width: "60%",
                                      borderRadius: "0",
                                      height: "100%",
                                    }}
                                  />
                                )}

                                {(msg?.course_message_media?.file.endsWith(
                                  ".doc"
                                ) ||
                                  msg?.course_message_media?.file.endsWith(
                                    ".docx"
                                  )) && (
                                  <img
                                    src="/assets/img/icon/word_icon.png"
                                    alt="chat-icon"
                                    style={{
                                      width: "60%",
                                      borderRadius: "0",
                                      height: "100%",
                                    }}
                                  />
                                )}

                                {msg?.course_message_media?.file.endsWith(
                                  ".xlsx"
                                ) && (
                                  <img
                                    src="/assets/img/icon/excel_icon.png"
                                    alt="chat-icon"
                                    style={{
                                      width: "60%",
                                      borderRadius: "0",
                                      height: "100%",
                                    }}
                                  />
                                )}
                              </a>
                            )}
                        </div>
                      )}

                      <p
                        dangerouslySetInnerHTML={{
                          __html: linkReplace(msg.message),
                        }}
                      />
                    </div>
                    <div className="text-end">
                      <h6 className="fs-12 text-darkgray regular">
                        <img src="/assets/img/icon/icon-date-time.svg" alt="" />
                        {" " + timeSince(new Date(msg.createdAt))} ago
                      </h6>
                    </div>
                  </div>
                ))}
              {show && (
                <ImagePreviewModel
                  show={show}
                  onHide={() => setShow(false)}
                  type={"chatbox"}
                  message={selectedMsg}
                />
              )}
              {sendingStatus === "failed" && (
                <p
                  style={{
                    color: "red",
                    fontSize: 11,
                    fontWeight: 500,
                    margin: "15px 0 0px 0",
                    textAlign: "center",
                  }}
                >
                  Failed to send new message
                </p>
              )}
            </div>
          </div>

          {chat.roomType !== "course" && (
            <form
              className="msg-add-form d-flex align-items-center ps-4 position-relative"
              onSubmit={handleNewMsg}
              encType="multipart/form-data"
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                <input
                  accept=".pdf, .doc, .docx, .xlsx, .webp, .jpg, .jpeg, .png, video/*"
                  type="file"
                  name="file"
                  id="file"
                  className="d-none"
                  ref={fileRef}
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="file"
                  style={{ cursor: "pointer" }}
                  id="message_option"
                  // data-bs-toggle="dropdown"
                  className="d-flex align-items-center justify-content-center rounded-circle bg-eee flex-fill"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/img/icon/file icon.svg`}
                    alt=""
                    style={{ height: 16, width: 16, maxWidth: "unset" }}
                  />
                </label>
                <button
                  type="button"
                  ref={emojiButtonRef}
                  style={{
                    border: "none",
                    background: "transparent",
                    padding: 0,
                    height: 16,
                    width: 16,
                    display: "flex",
                  }}
                  onClick={handleEmojiSelectionOpen}
                >
                  <img
                    class="post-image flex-fill"
                    src={`${process.env.PUBLIC_URL}/assets/img/icon/emoji_icon.png`}
                    alt=""
                    style={{ height: 16, width: 16 }}
                  />
                </button>
              </div>

              {file && (
                <div className="file-preview-box" ref={filePreviewBox}>
                  <div className="svg-container">
                    <svg
                      height="15px"
                      width="15px"
                      id="Layer_1"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (sendingStatus !== "sending") {
                          setFile("");
                          fileRef.current.value = "";
                        }
                      }}
                      version="1.1"
                      viewBox="0 0 512 512"
                    >
                      <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                    </svg>
                  </div>
                  <div className="preview-container">
                    {file.type.includes("image") && (
                      <img
                        src={URL.createObjectURL(file.file)}
                        alt="img-preview"
                      />
                    )}
                    {(file.file.name.endsWith(".doc") ||
                      file.file.name.endsWith(".docx")) && (
                      <img
                        src={`/assets/img/icon/word_icon.png`}
                        className="chat-file-icons"
                        alt="img-preview"
                      />
                    )}
                    {file.file.name.endsWith(".xlsx") && (
                      <img
                        src={`/assets/img/icon/excel_icon.png`}
                        className="chat-file-icons"
                        alt="img-preview"
                      />
                    )}

                    {file.file.name.endsWith(".pdf") && (
                      <img
                        src="/assets/img/icon/pdf_icon.png"
                        className="chat-file-icons"
                        alt="img-preview"
                      />
                    )}
                    {!file.type.includes("image") && (
                      <a
                        href={URL.createObjectURL(file.file)}
                        style={{ fontWeight: "bold" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {file.file.name}
                      </a>
                    )}
                    {fileSelectionError && (
                      <span className="text-danger fs-12">
                        {fileSelectionError}
                      </span>
                    )}
                  </div>
                </div>
              )}
              {fileSelectionError && (
                <span
                  className="text-danger fs-12"
                  style={{
                    position: "absolute",
                    fontWeight: "600",
                    bottom: "2.8rem",
                  }}
                >
                  {fileSelectionError}
                </span>
              )}

              <input
                disabled={sendingStatus === "sending"}
                type="text"
                ref={inputRef}
                value={newMsg}
                onChange={(e) => {
                  setNewMsg(e.target.value);
                }}
                placeholder="Write message here..."
                className="fs-14 py-3 ps-2 bg-transparent"
              />

              <button
                type="submit"
                className="d-flex gap-2 btn fs-14 radius-0 py-3 px-5 justify-content-center"
                style={{ minWidth: 144 }}
              >
                {sendingStatus === "sending" ? (
                  <div
                    class="spinner-border text-light spinner-border-sm"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>
                    <img
                      src="/assets/img/icon/icon-send-message.svg"
                      alt="msg-svg"
                    />{" "}
                    Send
                  </>
                )}
              </button>
            </form>
          )}

          {chat.roomType === "course" && chat.isTeacher === true && (
            <form
              className="msg-add-form d-flex align-items-center ps-4 position-relative"
              onSubmit={handleNewMsg}
              encType="multipart/form-data"
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                <input
                  accept=".pdf, .doc, .docx, .xlsx, .webp, .jpg, .jpeg, .png, video/*"
                  type="file"
                  name="file"
                  id="file"
                  className="d-none"
                  ref={fileRef}
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="file"
                  style={{ cursor: "pointer" }}
                  id="message_option"
                  // data-bs-toggle="dropdown"
                  className="d-flex align-items-center justify-content-center rounded-circle bg-eee flex-fill"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/img/icon/file icon.svg`}
                    alt=""
                    style={{ height: 16, width: 16, maxWidth: "unset" }}
                  />
                </label>
                <button
                  type="button"
                  ref={emojiButtonRef}
                  style={{
                    border: "none",
                    background: "transparent",
                    padding: 0,
                    height: 16,
                    width: 16,
                    display: "flex",
                  }}
                  onClick={handleEmojiSelectionOpen}
                >
                  <img
                    class="post-image flex-fill"
                    src={`${process.env.PUBLIC_URL}/assets/img/icon/emoji_icon.png`}
                    alt=""
                    style={{ height: 16, width: 16 }}
                  />
                </button>
              </div>
              {file && (
                <div className="file-preview-box" ref={filePreviewBox}>
                  <div className="svg-container">
                    <svg
                      height="15px"
                      width="15px"
                      id="Layer_1"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (sendingStatus !== "sending") {
                          setFile("");
                          fileRef.current.value = "";
                        }
                      }}
                      version="1.1"
                      viewBox="0 0 512 512"
                    >
                      <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                    </svg>
                  </div>
                  <div className="preview-container">
                    {(file.file.name.endsWith(".doc") ||
                      file.file.name.endsWith(".docx")) && (
                      <img
                        src={`/assets/img/icon/word_icon.png`}
                        className="chat-file-icons"
                        alt="img-preview"
                      />
                    )}

                    {file.file.name.endsWith(".pdf") && (
                      <img
                        src="/assets/img/icon/pdf_icon.png"
                        className="chat-file-icons"
                        alt="img-preview"
                      />
                    )}

                    {file.file.name.endsWith(".xlsx") && (
                      <img
                        src={`/assets/img/icon/excel_icon.png`}
                        className="chat-file-icons"
                        alt="img-preview"
                      />
                    )}

                    {file.type.includes("image") && (
                      <img
                        src={URL.createObjectURL(file.file)}
                        alt="img-preview"
                      />
                    )}

                    {!file.type.includes("image") && (
                      <a
                        href={URL.createObjectURL(file.file)}
                        style={{ fontWeight: "bold" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {file.file.name}
                      </a>
                    )}
                  </div>
                </div>
              )}
              {fileSelectionError && (
                <span
                  className="text-danger fs-12"
                  style={{
                    position: "absolute",
                    fontWeight: "600",
                    bottom: "2.8rem",
                  }}
                >
                  {fileSelectionError}
                </span>
              )}

              <input
                disabled={sendingStatus === "sending"}
                type="text"
                value={newMsg}
                ref={inputRef}
                onChange={(e) => {
                  setNewMsg(e.target.value);
                }}
                placeholder="Write message here..."
                className="fs-14 py-3 ps-2 bg-transparent"
              />

              <button
                type="submit"
                className="d-flex gap-2 btn fs-14 radius-0 py-3 px-5 justify-content-center"
                style={{ minWidth: 144 }}
              >
                {sendingStatus === "sending" ? (
                  <div
                    class="spinner-border text-light spinner-border-sm"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>
                    <img
                      src="/assets/img/icon/icon-send-message.svg"
                      alt="msg-svg"
                    />{" "}
                    Send
                  </>
                )}
              </button>
            </form>
          )}
        </>
      ) : (
        <div className="h-100 w-100 d-flex justify-content-center pt-2">
          <h5>Tap on user to chat</h5>
        </div>
      )}
      {showEmojiSelector && (
        <EmojiPickerFloatingWindow
          anchorRef={emojiButtonRef}
          onSelect={handleEmojiSelect}
          onClose={handleEmojiSelectionClose}
        />
      )}
    </div>
  );
};

export default ChatBox;
