import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { useGoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import jwtDecode from "jwt-decode";
import OtpInput from "react-otp-input";
import { Helmet } from "react-helmet";
function LoginWithOtp() {
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState(location?.state?.email);
  const history = useNavigate();
  const [userExistResponse, setUserExistResponse] = useState("");
  const [googleReqData, setGoogleReqData] = useState("");
  const [facebookReqData, setFacebookReqData] = useState("");
  const [linkedinReqData, setLinkedinReqData] = useState("");
  const [username, setUsername] = useState("");
  const [mobile, setMobile] = useState("");
  const [users_type, setUsersType] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otpError, setotpError] = useState("");
  const closeModal = useRef();
  const userDetailsRef = useRef();
  const openPopupRef = useRef();
  const [modalButtonValue, setModalButtonValue] = useState("Save");
  const [loading, setLoading] = useState(true);
  const loginBtn = useRef(null);
  const token = localStorage.getItem("token");
  const [loginBtnText, setLoginBtnText] = useState("Login");
  const [isRemember, setIsRemember] = useState(false);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.exp < Date.now() / 1000) {
        localStorage.clear();
        history("/signin");
      } else {
        history("/mainfeed");
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    try {
      setModalButtonValue("Please wait..");
      e.preventDefault();
      if (username === "" || mobile === "" || users_type === "") {
        ToastMessage.Error("PLEASE ENTER USERNAME AND MOBILE AND USERS_TYPE");
        setModalButtonValue("Save");
        return;
      } else {
        if (googleReqData) {
          setFacebookReqData(null);
          setLinkedinReqData(null);
          const resp = await CommonAPi(Endpoints.GoogleAuth, {
            googleResponse: userExistResponse,
            userdetails: {
              username,
              mobile,
              users_type,
            },
          });
          setGoogleReqData(null);
          setModalButtonValue("Save");
          if (resp.success === false) {
            console.log(resp);
            let errors = "";
            setTimeout(() => {
              for (let i = 0; i < resp.message.length; i++) {
                errors += resp.message[i] + ", ";
              }
              ToastMessage.Error(errors);
            }, 10);
          }
          if (resp.success === true) {
            closeModal.current.click();
            window.localStorage.setItem("token", resp.data.access_token);
            window.localStorage.setItem(
              "user",
              JSON.stringify(resp?.data?.user)
            );
            window.localStorage.setItem(
              "parentData",
              JSON.stringify(resp?.data?.user)
            );
            window.localStorage.setItem(
              "childCount",
              resp.data.user.children.length
            );
            window.localStorage.setItem("userId", resp?.data?.user?.id);
            window.localStorage.setItem("userName", resp?.data?.user?.username);
            window.localStorage.setItem(
              "ProfileCompletion",
              resp?.data?.user?.profileCompleteness
            );
            window.localStorage.setItem(
              "userProfile",
              resp?.data?.user?.user_profile?.profileImg
            );
            window.localStorage.setItem(
              "userProfileCoverImage",
              resp?.data?.user?.user_profile?.coverImg
            );
            if (resp.data.user?.children?.length > 0) {
              history("/select-profile" + "/" + resp.data.user.id);
            } else if (resp.data.user?.hasInterest === false) {
              history("/select-interests" + "/" + resp.data.user.id);
            } else {
              history("/mainfeed");
            }
          }
        } else if (facebookReqData) {
          setGoogleReqData(null);
          setLinkedinReqData(null);
          console.log(facebookReqData);
          const data = await CommonAPi(Endpoints.facebookAuth, {
            userID: facebookReqData.userID,
            accessToken: facebookReqData.accessToken,
            userdetails: {
              username,
              mobile,
              users_type,
            },
          });
          setFacebookReqData(null);
          if (data.success === true) {
            closeModal.current.click();
            window.localStorage.setItem("token", data.data.access_token);
            window.localStorage.setItem(
              "user",
              JSON.stringify(data?.data?.user)
            );
            window.localStorage.setItem(
              "parentData",
              JSON.stringify(data?.data?.user)
            );
            window.localStorage.setItem(
              "childCount",
              data.data.user.children.length
            );
            window.localStorage.setItem("userId", data?.data?.user?.id);
            window.localStorage.setItem("userName", data?.data?.user?.username);
            window.localStorage.setItem(
              "ProfileCompletion",
              data?.data?.user?.profileCompleteness
            );
            window.localStorage.setItem(
              "userProfile",
              data?.data?.user?.user_profile?.profileImg
            );
            window.localStorage.setItem(
              "userProfileCoverImage",
              data?.data?.user?.user_profile?.coverImg
            );
            if (data.data.user?.children?.length > 0) {
              history("/select-profile" + "/" + data.data.user.id);
            } else if (data.data.user?.hasInterest === false) {
              history("/select-interests" + "/" + data.data.user.id);
            } else {
              history("/mainfeed");
            }
          }
        } else if (linkedinReqData) {
          setFacebookReqData(null);
          setGoogleReqData(null);
          console.log(linkedinReqData);
          const data = await CommonAPi(Endpoints.LinkedinAuth, {
            linkedinData: userExistResponse,
            userdetails: {
              username,
              mobile,
              users_type,
            },
          });
          setFacebookReqData(null);
          if (data.success === true) {
            closeModal.current.click();
            window.localStorage.setItem("token", data.data.access_token);
            window.localStorage.setItem(
              "user",
              JSON.stringify(data?.data?.user)
            );
            window.localStorage.setItem(
              "parentData",
              JSON.stringify(data?.data?.user)
            );
            window.localStorage.setItem(
              "childCount",
              data.data.user.children.length
            );
            window.localStorage.setItem("userId", data?.data?.user?.id);
            window.localStorage.setItem("userName", data?.data?.user?.username);
            window.localStorage.setItem(
              "ProfileCompletion",
              data?.data?.user?.profileCompleteness
            );
            window.localStorage.setItem(
              "userProfile",
              data?.data?.user?.user_profile?.profileImg
            );
            window.localStorage.setItem(
              "userProfileCoverImage",
              data?.data?.user?.user_profile?.coverImg
            );
            if (data.data.user?.children?.length > 0) {
              history("/select-profile" + "/" + data.data.user.id);
            } else if (data.data.user?.hasInterest === false) {
              history("/select-interests" + "/" + data.data.user.id);
            } else {
              history("/mainfeed");
            }
          }
        }
      }
    } catch (error) {
      setModalButtonValue("Save");
      console.log(error.message);
      let errors = "";
      for (let i = 0; i < error.response.data.message.length; i++) {
        errors += error.response.data.message[i] + "\n";
      }
      ToastMessage.Error(errors);
    }
  };

  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      setGoogleReqData(tokenResponse);
      if (!tokenResponse) return;
      try {
        const userResponse = await CommonAPi(Endpoints.GoogleAuth, {
          googleResponse: tokenResponse,
        });

        setModalButtonValue("Save");
        if (userResponse.success === false) {
          setTimeout(() => {
            openPopupRef.current.click();
          }, 10);
        }
        // console.log(userResponse);
        if (userResponse.success === true) {
          window.localStorage.setItem("token", userResponse.data.access_token);
          window.localStorage.setItem(
            "user",
            JSON.stringify(userResponse?.data?.user)
          );
          window.localStorage.setItem(
            "parentData",
            JSON.stringify(userResponse?.data?.user)
          );
          window.localStorage.setItem(
            "childCount",
            userResponse.data.user.children.length
          );
          window.localStorage.setItem("userId", userResponse?.data?.user?.id);
          window.localStorage.setItem(
            "userName",
            userResponse?.data?.user?.username
          );
          window.localStorage.setItem(
            "ProfileCompletion",
            userResponse?.data?.user?.profileCompleteness
          );
          window.localStorage.setItem(
            "userProfile",
            userResponse?.data?.user?.user_profile?.profileImg
          );
          window.localStorage.setItem(
            "userProfileCoverImage",
            userResponse?.data?.user?.user_profile?.coverImg
          );
          if (userResponse.data.user.children.length > 0) {
            history("/select-profile" + "/" + userResponse.data.user.id);
          } else if (userResponse.data.user.hasInterest === false) {
            history("/select-interests" + "/" + userResponse.data.user.id);
          } else {
            history("/mainfeed");
          }
        }
        setUserExistResponse(userResponse);
        if (userResponse.success === false) {
          openPopupRef.current.click();
        }
      } catch (e) {
        console.log(e);
        ToastMessage.Error(e.response.data.message);
        setModalButtonValue("Save");
      }
    },
    scope: "openid profile email phone",
  });

  const facebookLogin = async (response) => {
    console.log(response);
    if (!response) return null;
    setFacebookReqData(response);
    try {
      const userResponse = await CommonAPi(Endpoints.facebookAuth, response);
      if (userResponse.success === true) {
        closeModal.current.click();
        window.localStorage.setItem("token", userResponse.data.access_token);
        window.localStorage.setItem(
          "user",
          JSON.stringify(userResponse?.data?.user)
        );
        window.localStorage.setItem(
          "parentData",
          JSON.stringify(userResponse?.data?.user)
        );
        window.localStorage.setItem(
          "childCount",
          userResponse.data.user.children.length
        );
        window.localStorage.setItem("userId", userResponse?.data?.user?.id);
        window.localStorage.setItem(
          "userName",
          userResponse?.data?.user?.username
        );
        window.localStorage.setItem(
          "ProfileCompletion",
          userResponse?.data?.user?.profileCompleteness
        );
        window.localStorage.setItem(
          "userProfile",
          userResponse?.data?.user?.user_profile?.profileImg
        );
        window.localStorage.setItem(
          "userProfileCoverImage",
          userResponse?.data?.user?.user_profile?.coverImg
        );
        if (userResponse.data.user?.children?.length > 0) {
          history("/select-profile" + "/" + userResponse.data.user.id);
        } else if (userResponse.data.user?.hasInterest === false) {
          history("/select-interests" + "/" + userResponse.data.user.id);
        } else {
          history("/mainfeed");
        }
      }
      setUserExistResponse(userResponse);
      if (userResponse.success === false) {
        openPopupRef.current.click();
      }
    } catch (e) {
      console.log(e);
      setModalButtonValue("Save");
      ToastMessage.Error(e.response.data.message);
    }
  };

  const handleAppleResponse = async (response) => {
    console.log(response);
    if (!response) return;
    try {
      if (!response.error) {
        const resp = await CommonAPi(Endpoints.AppleAuth, response);
        if (resp.success) {
          console.log(resp);
        }
      }
    } catch (e) {
      console.log(e);
      setModalButtonValue("Save");
      setFacebookReqData(null);
      setLinkedinReqData(null);
      setGoogleReqData(null);
    }
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: process.env.REACT_APP_LINKEDIN_REDIRECT_URI,
    scope: "r_emailaddress r_liteprofile",
    onSuccess: async (code) => {
      console.log(code);
      setLinkedinReqData(code);
      try {
        const userResponse = await CommonAPi(Endpoints.LinkedinAuth, {
          code,
        });
        if (userResponse.success === true) {
          closeModal.current.click();
          window.localStorage.setItem("token", userResponse.data.access_token);
          window.localStorage.setItem(
            "user",
            JSON.stringify(userResponse?.data?.user)
          );
          window.localStorage.setItem(
            "parentData",
            JSON.stringify(userResponse?.data?.user)
          );
          window.localStorage.setItem(
            "childCount",
            userResponse.data.user.children.length
          );
          window.localStorage.setItem("userId", userResponse?.data?.user?.id);
          window.localStorage.setItem(
            "userName",
            userResponse?.data?.user?.username
          );
          window.localStorage.setItem(
            "ProfileCompletion",
            userResponse?.data?.user?.profileCompleteness
          );
          window.localStorage.setItem(
            "userProfile",
            userResponse?.data?.user?.user_profile?.profileImg
          );
          window.localStorage.setItem(
            "userProfileCoverImage",
            userResponse?.data?.user?.user_profile?.coverImg
          );
          if (userResponse.data.user?.children?.length > 0) {
            history("/select-profile" + "/" + userResponse.data.user.id);
          } else if (userResponse.data.user?.hasInterest === false) {
            history("/select-interests" + "/" + userResponse.data.user.id);
          } else {
            history("/mainfeed");
          }
        }
        setUserExistResponse(userResponse);
        if (userResponse.success === false) {
          openPopupRef.current.click();
        }
      } catch (e) {
        console.log(e);
        setModalButtonValue("Save");
        ToastMessage.Error(e.response.data.message);
        setFacebookReqData(null);
        setLinkedinReqData(null);
        setGoogleReqData(null);
      }
    },
    onError: (error) => {
      console.log(error);
      setModalButtonValue("Save");
    },
  });

  useEffect(() => {
    if (otp.length >= 6) {
      loginBtn.current.click();
    } else {
      setLoading(false);
      setLoginBtnText("Login");
    }
  }, [otp]);

  const handleChange = (element, index) => {
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
      setLoading(false);
    }
  };

  const loginwOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoginBtnText("Please wait...");
    if (!email) {
      setLoginBtnText("Login");
      setLoading(false);
      setEmailError("Please enter mobile number or email address");
    } else if (email) {
      setEmailError("");
    }

    if (otp.length === 0) {
      setLoginBtnText("Login");
      setLoading(false);
      setotpError("Please enter otp");
    } else if (otp) {
      if (otp.length < 6) {
        setotpError("Please enter valid otp");
        setLoginBtnText("Login");
        setLoading(false);
      } else {
        setotpError("");
      }
    }

    if (email && otp.length === 6) {
      let obj = {
        identity: email,
        otp: otp,
        is_remember: isRemember,
      };
      try {
        let resp = await CommonAPi(Endpoints.loginWithOtpVerify, obj);
        console.log("logwotp__", JSON.stringify(resp, null, 4));
        if (resp && resp.message) {
          window.localStorage.setItem("token", resp.data.access_token);
          window.localStorage.setItem("user", JSON.stringify(resp?.data?.user));
          window.localStorage.setItem(
            "parentData",
            JSON.stringify(resp?.data?.user)
          );
          window.localStorage.setItem(
            "ProfileTagline",
            resp?.data?.user?.user_profile?.profileTagline
          );
          window.localStorage.setItem(
            "userProfile",
            resp?.data?.user?.user_profile?.profileImg
          );
          window.localStorage.setItem(
            "childCount",
            resp.data.user.children.length
          );

          window.localStorage.setItem("userId", resp?.data?.user?.id);
          window.localStorage.setItem("userName", resp?.data?.user?.username);
          window.localStorage.setItem(
            "userRoleType",
            resp?.data?.user?.usersType?.toLowerCase()
          );
          window.localStorage.setItem(
            "ProfileCompletion",
            resp?.data?.user?.profileCompleteness
          );
          window.localStorage.setItem(
            "userProfileCoverImage",
            resp?.data?.user?.user_profile?.coverImg
          );
          window.localStorage.setItem(
            "instructorStatus",
            resp?.data?.user?.instructorStatus
          );
          // history("/mainfeed",{replace : true});
          if (resp.data.user.children.length > 0) {
            history("/select-profile" + "/" + resp.data.user.id);
            // } else if (resp.data.user.hasInterest === false) {
            //   history("/select-interests" + "/" + resp.data.user.id);
            // } else if (Math.round(resp?.data?.user?.profileCompleteness) < 25) {
            //   history("/complete-profile-step1" + "/" + resp.data.user.id);
            // } else if (Math.round(resp?.data?.user?.profileCompleteness) < 50) {
            //   history("/complete-profile-step2" + "/" + resp.data.user.id);
            // } else if (Math.round(resp?.data?.user?.profileCompleteness) < 75) {
            //   history("/complete-profile-step3" + "/" + resp.data.user.id);
            // } else if (Math.round(resp?.data?.user?.profileCompleteness) < 100) {
            //   history("/complete-profile-step4" + "/" + resp.data.user.id);
            // } else {
          } else history("/mainfeed");
          // ToastMessage.Success(resp.message);
        }
        setLoginBtnText("Login");
        setLoading(false);
      } catch (e) {
        setLoginBtnText("Login");
        setLoading(false);
        console.log("logwotpErr__", JSON.stringify(e.response, null, 4));
        if (e && e.response && e.response.data && e.response.data.message) {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleResendOtp = async () => {
    let obj = {
      identity: email,
    };
    try {
      let resp = await CommonAPi(Endpoints.loginWithOtp, obj);
      console.log("logwotp__", JSON.stringify(resp, null, 4));
      if (resp && resp.message) {
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      console.log("logwotpErr__", JSON.stringify(e.response, null, 4));
      if (e && e.response && e.response.data && e.response.data.message) {
        ToastMessage.Error(e.response.data.message);
      }
    }
  };

  const handleValidation = async (e, value, field) => {
    e.preventDefault();
    if (!value && field === "email") {
      setEmailError("Please enter mobile number or email address");
    } else if (value && field === "email") {
      setEmailError("");
    }

    if (!value && field === "otp") {
      setotpError("Please enter otp");
    } else if (value && field === "otp") {
      if (otp < 6) {
        setotpError("Please enter valid otp");
      } else {
        setotpError("");
      }
    }
  };

  // console.log("checkotp__", otp.join(""))
  return (
    <div className="wrapper">
      <Helmet>
        <title>Login With Otp</title>
      </Helmet>
      <div className="header fix-header position-fixed top-0 w-100 ">
        <div className="container ">
          <div className="row m-0">
            <div className="col-md-6 col-6">
              <div className="site-logo">
                <Link to="/">
                  <img src="assets/img/8inNet_logo 1.svg" alt="" />
                </Link>
              </div>
            </div>

            <div className="col-md-6 col-6 text-end">
              <div className="d-flex align-items-center justify-content-end">
                <h6 className="font-15 text-white regular pe-3 d-none d-sm-block">
                  Don’t have an account?
                </h6>
                <Link className="btn btn-brown px-4" to={"/signup"}>
                  {" "}
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="signin-bg">
        <div className="container p-0">
          <div className="row m-0">
            <div className="col-md-6">
              <div className="py-5">
                <img src="assets/img/signin.png" alt="" />
              </div>
            </div>

            <div className="col-md-6 bg-primary py-md-5 py-4 signup-right">
              <div className="pt-md-5 px-md-5 my-md-5">
                <h1 className="semibold text-white pb-1">Sign In</h1>
                <h4 className="text-white pb-4">
                  Welcome back! Please enter your details
                </h4>

                <form action="" className="signup-form" onSubmit={loginwOtp}>
                  <div className="form-field">
                    <label className="text-white">
                      Mobile Number or Email Address*
                    </label>
                    <input
                      type="text"
                      placeholder="Mobile Number or Email Address"
                      value={email}
                      className={emailError ? "is-danger" : ""}
                      onBlur={(e) => {
                        handleValidation(e, e.target.value, "email");
                      }}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />

                    {emailError && (
                      <div className="form-error">{emailError}</div>
                    )}
                  </div>

                  <div className="form-field pt-2 mb-2">
                    <p className="fs-13 text-white light">
                      Enter the OTP you received to
                      <b> {location?.state?.email}</b>
                    </p>
                    <label className="text-white">OTP*</label>
                  </div>

                  <div className="d-flex align-items-center gap-3 mb-2">
                    <div className="otp-number d-flex align-items-center gap-3">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        inputStyle={
                          otpError
                            ? {
                                width: "100%",
                                margin: "3px",
                                maxWidth: "70px",
                                height: "65px",
                                border: "1px solid red",
                              }
                            : {
                                width: "100%",
                                margin: "3px",
                                maxWidth: "70px",
                                height: "65px",
                              }
                        }
                        renderInput={(props) => <input {...props} />}
                      />

                      {/* {otp.map((data, index) => {
                        return (
                          <input
                            onChange={(e) => handleChange(e.target, index)}
                            maxLength={"1"}
                            key={index}
                            className={otpError ? "is-danger" : ""}
                            onBlur={(e) => { handleValidation(e, e.target.value, "otp") }}
                            value={data}
                            onFocus={(e) => e.target.select()}
                          />
                        );
                      })} */}
                    </div>
                  </div>
                  {otpError && <div className="form-error">{otpError}</div>}
                  <div className="d-flex justify-content-between align-items-center mt-1">
                    <div className="form-field">
                      <div className="d-flex align-items-center justify-content-between font-15 ">
                        <div className="custom-checkbox">
                          <input
                            type="checkbox"
                            id="remember"
                            className="align-middle d-none"
                            checked={isRemember}
                            onChange={(e) => setIsRemember(e.target.checked)}
                          />
                          <label for="remember" className="fs-15 text-white">
                            Remember me
                          </label>
                        </div>
                      </div>
                    </div>
                    <Link
                      onClick={handleResendOtp}
                      className="forgot-link fs-13 text-white"
                    >
                      Resend OTP
                    </Link>
                  </div>
                  <button
                    type="submit"
                    ref={loginBtn}
                    className="btn btn-brown rounded-pill w-100 mt-3 mb-3"
                    disabled={loading}
                  >
                    {loginBtnText}
                  </button>

                  <p className="text-center fs-13 text-white py-3 light">
                    Or continue with
                  </p>

                  <div className="text center d-flex align-items-center justify-content-center gap-3">
                    <a
                      href="#"
                      className="sign-with-icon"
                      onClick={() => login()}
                    >
                      <img src="assets/img/google.svg" alt="" />
                    </a>
                    <FacebookLogin
                      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                      callback={facebookLogin}
                      render={(renderProps) => (
                        <a
                          href="#"
                          onClick={renderProps.onClick}
                          className="sign-with-icon"
                        >
                          <img src="assets/img/facebook.svg" alt="" />
                        </a>
                      )}
                    />
                    <AppleLogin
                      clientId={process.env.REACT_APP_APPLE_CLIENT_ID}
                      redirectURI={process.env.REACT_APP_APPLE_REDIRECT_URI}
                      callback={handleAppleResponse}
                      onError={(error) => console.error(error)}
                      responseType={"code"}
                      responseMode={"query"}
                      render={(renderProps) => (
                        <a
                          href="#"
                          onClick={renderProps.onClick}
                          className="sign-with-icon"
                        >
                          <img src="assets/img/apple.svg" alt="" />
                        </a>
                      )}
                    />

                    <a href="javascript:void(0)" className="sign-with-icon">
                      <img
                        onClick={linkedInLogin}
                        src="assets/img/linkedin.svg"
                        alt="Sign in with Linked In"
                      />
                    </a>
                  </div>
                </form>
                <div className="d-none">
                  <a
                    href="#"
                    ref={openPopupRef}
                    className="btn btn-br-blue fs-14 bg-transparent"
                    data-bs-toggle="modal"
                    data-bs-target="#kidmodal"
                  >
                    Add Kid Profile{" "}
                    <img src="assets/img/icon/profile.svg" alt="" />
                  </a>
                </div>
                <div
                  className="modal fade"
                  id="kidmodal"
                  tabIndex="-1"
                  aria-labelledby="kidmodal"
                  aria-hidden="true"
                >
                  <div className="modal-dialog rounded-10 box-shadow p-lg-4 p-md-3 p-2 bg-white">
                    <div className="modal-content border-0">
                      <div className="d-flex align-items-center justify-content-between pb-3 border-bottom mb-3 w-100">
                        <h3 className="medium text-blue">Basic information</h3>
                        <button
                          type="button"
                          className="btn-close kids-close-icon cursor-pointer"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          ref={closeModal}
                        >
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="#949494"
                            width="22"
                            height="22"
                          >
                            <path
                              stroke-linecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="modal-body">
                        <h4 className="medium text-black pb-3">
                          Please enter basic details
                        </h4>
                        <form
                          action=""
                          ref={userDetailsRef}
                          className="kids-form"
                          onSubmit={handleSubmit}
                          enctype="multipart/form-data"
                        >
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <input
                                type="text"
                                placeholder="Username"
                                className="rounded-pill text-gray px-3 py-3 border"
                                onChange={(e) => {
                                  setUsername(e.target.value);
                                }}
                                name="username"
                                value={username}
                              />
                            </div>
                            <div className="col-md-12 mb-3">
                              <select
                                className="border px-3 py-3 rounded-pill"
                                onChange={(e) => {
                                  setUsersType(e.target.value);
                                }}
                              >
                                <option>Select User Type</option>
                                <option value="Teacher">Teacher</option>
                                <option value="Student">Student</option>
                                <option value="Professional">
                                  Professional
                                </option>
                                <option value="Parent">Parent</option>
                              </select>
                            </div>
                            <div className="col-md-12 mb-3">
                              <input
                                type="tel"
                                placeholder="Mobile"
                                className="rounded-pill text-gray px-3 py-3 border"
                                onChange={(e) => {
                                  setMobile(e.target.value);
                                }}
                                name="mobile"
                                value={mobile}
                              />
                            </div>

                            <div className="col-md-12 text-end">
                              <button
                                type="submit"
                                disabled={modalButtonValue === "Please wait.."}
                                className="btn fs-14 "
                              >
                                {modalButtonValue}{" "}
                                <img
                                  src="../../assets/img/icon/icon-right-arrow.svg"
                                  alt=""
                                />
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginWithOtp;
