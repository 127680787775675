import React from 'react'
import ToastMessage from "../../Utils/ToastMessage";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import { DeleteApi } from "../../API/DeleteApi";
import { Link, useNavigate } from 'react-router-dom';

export default function PageItem({item, index, type, onSuccess}) {
    const Navigate = useNavigate();
    const handleFollow = async (Id) => {
        let data = {
          page_id: Id,
        };
        try {
          let resp = await CommonAPi(Endpoints.PageFollow, data);
          if (resp && resp.message) {
            onSuccess(true);
            ToastMessage.Success(resp.message);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              Navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      };

      const handleUnfollow = async (Id) => {
        let data = {
          page_id: Id,
        };
        try {
          let resp = await DeleteApi(Endpoints.PageUnfollow, data);
          if (resp.success === true && resp.message) {
            onSuccess(true);
            ToastMessage.Success("Page unfollowed successfully");
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              Navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      };

    return (
        <div class="pb-3 d-flex align-items-center" key={index}>
           <Link to={"/page-profile/" + item?.slug}>
           {item?.pageImage === null  ? 
            <img src="assets/img/profile6.png" alt="" width="64" height="64" class="rounded-circle" />
            :
            <img src={Endpoints.baseUrl + item?.pageImage} alt="" width="64" height="64" class="rounded-circle" />
           }
           </Link>
            <div class="d-flex align-items-center justify-content-between w-100 ps-2">
                <div>
                    <Link to={"/page-profile/" + item?.slug}>
                      <h6 class="medium text-black fs-14">{item?.title}</h6>
                    </Link>

                    <h6 class="fs-12 regular text-darkgray">
                        Public
                        <span>&#183;</span>
                        Category
                        <span>&#183;</span>
                        {item?.totalFollowers} Members
                    </h6>

                </div>

                <div class="">
                  {item?.isFollowing === "false" ? 
                    <a href="#" onClick={()=>{
                        handleFollow(item?.id)
                    }} class="btn fs-12 py-1 px-3 w-100">Follow<img src="assets/img/icon/icon-add-friend.svg" class="ms-1" width="10" /></a>
                  :
                    <a href="#" onClick={()=>{
                      handleUnfollow(item?.id)
                  }}  class="btn fs-12 py-1 px-3 w-100">Unfollow</a>
                  }
                    </div>

            </div>
        </div>
    )
}
