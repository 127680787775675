import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Endpoints } from "../../../API/Endpoints";
import ToastMessage from "../../../Utils/ToastMessage";
import { useNavigate } from "react-router-dom";
import { DeleteApi } from "../../../API/DeleteApi";
export default function Sidebar() {
  const param = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const handleDeleteGroup = async () => {
    let data = {
      groupId: param?.id,
    };
    try {
      let resp = await DeleteApi(Endpoints.GroupDelete, data);
      if (resp && resp.success === true) {
        Navigate("/groups");
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  return (
    <div className="col-lg-3 col-md-4 mb-3">
      <div className="bg-white overflow-hidden rounded-15 box-shadow pb-lg-5">
        <h4 className="medium py-2 my-1 border-bottom px-3">Manage Group</h4>

        <div className="">
          <h6 className="fs-14 regular p-3 profile-collapse">
            <a
              data-bs-toggle="collapse"
              href="#profile"
              className="text-mediumgray d-flex align-items-center justify-content-between"
              aria-expanded="true"
            >
              <span className="d-block">
                <img src="../assets/img/icon/icon-edit-profile2.svg" alt="" />
                {"    "}
                Admin tools
              </span>

              <svg
                viewBox="0 0 24 24"
                width="18"
                height="18"
                stroke="#9E9E9E"
                className="transition"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </a>
          </h6>

          <div className="collapse border-0 show" id="profile">
            <ul className="fs-14 pro-side-menu pb-3">
              <li
                className={
                  location.pathname.split("/")[1] === "group-member"
                    ? "px-4 py-2 active"
                    : "px-4 py-2"
                }
              >
                <Link to={"/group-member/" + param?.id}>
                  <img
                    src="../assets/img/icon/icon-notifications.svg"
                    width="25"
                    alt=""
                    className="pe-2"
                  />{" "}
                  Members
                </Link>
              </li>

              {/* <li className="px-4 py-2">
                        <Link to="#"><img src="../assets/img/icon/icon-friends-request.svg" width="25" alt="" className="pe-2" /> Admins</Link>
                    </li> */}

              <li
                className={
                  location.pathname.split("/")[1] === "group-rule"
                    ? "px-4 py-2 active"
                    : "px-4 py-2"
                }
              >
                <Link to={"/group-rule/" + param?.id}>
                  <img
                    src="../assets/img/icon/icon-rules.svg"
                    width="25"
                    height="18"
                    alt=""
                    className="pe-2"
                  />{" "}
                  Rules
                </Link>
              </li>

              <li
                className={
                  location.pathname.split("/")[1] === "group-request"
                    ? "px-4 py-2 active"
                    : "px-4 py-2"
                }
              >
                <Link to={"/group-request/" + param?.id}>
                  <img
                    src="../assets/img/icon/icon-request3.svg"
                    width="25"
                    alt=""
                    className="pe-2"
                  />{" "}
                  Request
                </Link>
              </li>

              <li
                className={
                  location.pathname.split("/")[1] === "group-block"
                    ? "px-4 py-2 active"
                    : "px-4 py-2"
                }
              >
                <Link to={"/group-block/" + param?.id}>
                  <img
                    src="../assets/img/icon/blocked.svg"
                    width="25"
                    alt=""
                    className="pe-2"
                  />{" "}
                  Blocked
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-top mb-lg-5">
          <h6 className="fs-14 regular p-3 profile-collapse">
            <a
              data-bs-toggle="collapse"
              href="#setting"
              className="text-mediumgray d-flex align-items-center justify-content-between"
              aria-expanded="true"
            >
              <span className="d-block">
                <img src="../assets/img/icon/icon-setting.svg" alt="" />
                {"    "}
                Settings
              </span>

              <svg
                viewBox="0 0 24 24"
                width="18"
                height="18"
                stroke="#9E9E9E"
                className="transition"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </a>
          </h6>

          <div className="collapse border-0 show" id="setting">
            <ul className="fs-14 pro-side-menu pb-3">
              <li className="px-4 py-2 ">
                <Link to={"/update-group/" + param?.id}>
                  <img
                    src="../assets/img/icon/icon-password.svg"
                    width="22"
                    alt=""
                    className="pe-2"
                  />{" "}
                  Edit Group
                </Link>
              </li>

              <li className="px-4 py-2 ">
                <Link
                  onClick={() => {
                    handleDeleteGroup();
                  }}
                >
                  <img
                    src="../assets/img/icon/icon-notifications.svg"
                    width="22"
                    alt=""
                    className="pe-2"
                  />{" "}
                  Delete Group{" "}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
