import { useState, useMemo, useCallback } from "react";
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from '../../API/FetchApi';
import { useNavigate } from "react-router-dom";
import { CommonAPi } from '../../API/CommonApi';
const $ = window.jQuery;
const backgrounds = [
  "linear-gradient(180deg, #FF9A9E 0%, #FAD0C4 100%)",
  "linear-gradient(180deg, #F6D365 0%, #FDA085 100%)",
  "linear-gradient(180deg, #D4FC79 0%, #96E6A1 100%)",
  "linear-gradient(180deg, #FCCB90 0%, #D57EEB 100%)",
  "linear-gradient(180deg, #4FACFE 0%, #00F2FE 100%)",
  "linear-gradient(180deg, #EBBBA7 0%, #CFC7F8 100%)",
  "linear-gradient(180deg, #FFE53B 0%, #FF2525 100%)",
  "url('/assets/img/gratitude-bg-image3.jpg')",
  "url('/assets/img/gratitude-bg-image1.jpg')",
  "url('/assets/img/gratitude-bg-image2.jpg')",
];
export default function GratitudeModel({ onSuccess }) {
  const [background, setBackground] = useState(backgrounds[6]);
  const [message, setMessage] = useState('');
  const [userOption, setUserOption] = useState([]);
  const [user, setUser] = useState('');
  const [userKey, setUserKey] = useState(Math.ceil(Math.random() * 999999));
  const [userError, setUserError] = useState('');
  const [backgroundError, setBackgroundError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const Navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const selectedBackgroundObject = useMemo(() => {
    return {
      backgroundImage: background,
    };
  }, [background]);

  const handleOnSelectUser = (item) => {
    console.log(item);
    setSelectedUser(item[0]);
  };

  const handleOnSearchUser = async (search) => {
    try {
      let resp = await FetchApi(Endpoints.GetAllFriend + "?term=" + search + "&id=" + userId);
      if (resp && resp.success === true) {
        let newdata = resp.data.friends.map((item) => {
          return ({
            id: item.id,
            name: item.fullName,
            image: item?.user_profile?.profileImg,
            tagline: item?.user_profile?.profileTagline,
          })
        });
        setUserOption(newdata);
      }
    }
    catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error('Session Expired! Please login again.');
          localStorage.clear();
          Navigate('/signin');
        } else {
          ToastMessage.Error(e.response.data.message);
        }

      }
    }
  };

  const sendgratitude = async () => {
    if (!selectedUser?.id) {
      setUserError("Please select user")
    }

    if (!background) {
      setBackgroundError("Please select background image")
    }
    if (!message) {
      setMessageError("message is required");
    }
    if (selectedUser?.id && background && message) {
      let data = {
        userId: selectedUser?.id,
        backgroundImage: background,
        text: message
      }
      try {
        let resp = await CommonAPi(Endpoints.SendGratitude, data);
        if (resp && resp.success === true) {
          ToastMessage.Success(resp.message);
          onSuccess(true);
          setMessage('');
          setUser('')
          setUserKey(Math.ceil(Math.random() * 999999));
          $('#gratitudemodel').modal('hide');
        }
      }
      catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error('Session Expired! Please login again.');
            localStorage.clear();
            Navigate('/signin');
          } else {
            ToastMessage.Error(e.response.data.message);
          }

        }
      }
    }
  };

  const Renderuser = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-User"
        defaultInputValue={user}
        key={userKey}
        onSearch={handleOnSearchUser}
        onChange={handleOnSelectUser}
        options={userOption}
        placeholder="Search your connection"
        labelKey="name"
        minLength={1}
        emptyLabel={"Searching your connection..."}
        className={userError ? " is-danger text-gray  px-1 py-1  border" : " text-gray  px-1 py-1  border"}
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>

            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>

        )}

        useCache={false}
      />
    )
  }, [user, userOption, userError, userKey])

  return (

    <div class="modal fade" id="gratitudemodel" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog rounded-10 box-shadow bg-white modal-xl" >
        <div class="modal-content border-0">
          <div className="modal-header p-0">
            <div className="col-md-12">
              <div className="row">
                <div className="border-end col-md-6">
                  <p className="add-gratitude-modal-title">Create Your gratitude</p>
                </div>
                <div className="col-md-6">
                  <div className="add-gratitude-modal-title">
                    <span className="text-center">
                      Preview
                    </span>

                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn-close text-end gratitude-close-btn p-2 fs-14" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div class="modal-body p-0">
            <div className="row">
              <div className="border-end d-flex flex-column col-md-6">

                <div className="d-flex flex-column flex-grow-1 mt-4">
                  <div className="d-flex justify-content-between py-3 px-4">
                    <p className="m-0 text-muted">* All fields are required.</p>

                  </div>
                  <div className="px-4">

                    <p className="fs-15 medium mb-2 text-black">Gratitude for *</p>
                    {/* <input
                    type="text"
                    className=" text-gray px-3 py-3 border"
                    placeholder="Search your connection"
                  /> */}

                    {Renderuser()}
                    {
                      userError ?
                        <div className='form-error' style={{ color: "white", margintop: "-10px" }}>
                          {userError}
                        </div>
                        :
                        ""
                    }
                    <p className="fs-15 medium mb-2 text-black mt-2 ">
                      Message *

                    </p>
                    <textarea
                      className=" text-gray px-3 py-3 border"
                      placeholder="Write message"
                      maxLength={250}
                      onChange={(e) => {
                        setMessage(e.target.value)
                      }}
                    >

                    </textarea>
                    <div className="text-end fs-12">{message.length}/250</div>
                    {
                      messageError ?
                        <div className='form-error' style={{ color: "white", margintop: "-10px" }}>
                          {messageError}
                        </div>
                        :
                        ""
                    }

                    <p className="fs-15 medium mb-2 text-black">Backgrounds *</p>
                    <div className="add-gratitude-bgOrColor-picker">
                      {backgrounds.map((item) => {
                        return (
                          <button
                            key={item}
                            className="add-gratitude-bgOrColor"
                            style={{ background: item }}
                            onClick={() => setBackground(item)}
                          />
                        );
                      })}
                    </div>
                    {
                      backgroundError ?
                        <div className='form-error' style={{ color: "white", margintop: "-10px" }}>
                          {backgroundError}
                        </div>
                        :
                        ""
                    }


                  </div>
                  <button onClick={sendgratitude} className="add-gratitude-button ">
                    Send Gratitude
                  </button>
                </div>
              </div>
              <div className="add-gratitude-preview-container d-flex flex-column align-items-center col-md-6  mt-4">


                <div className="gratitude-item">
                  <div className="gratitude-top-content-preview text-center medium fs-16 py-5 px-4" style={selectedBackgroundObject}>
                    <p className="m-0 mt-5 py-5" style={{ wordBreak: "break-all" }}>{message ? message : "Your message goes here..."}</p>

                  </div>
                  <div className="d-flex align-items-center feed-top p-3">
                    <span className="feed-user-image">
                      {selectedUser?.image === null || selectedUser?.image === undefined ?
                        ""
                        :
                        <img src={selectedUser?.image === null ? "../assets/img/picture.png" : Endpoints.baseUrl + selectedUser?.image} alt="" width="32" height="32" className="rounded-circle" />
                      }
                    </span>
                    <div className="ps-2 pt-1 d-flex align-items-start justify-content-between w-100">
                      <div>
                        <h6 className="fs-14 medium text-black">{selectedUser?.name}</h6>
                        <h6 className="fs-10 regular text-darkgray py-1">{selectedUser?.tagline}</h6>
                      </div>



                    </div>
                  </div>

                </div>


              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  )
}







