import React, { useEffect, useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import { DeleteApi } from "../../API/DeleteApi";
import { UpdateAPi } from "../../API/UpdateApi";
import ToastMessage from "../../Utils/ToastMessage";
import { CommonAPi } from "../../API/CommonApi";
import { Link, useNavigate } from "react-router-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import DeletePrompt from "../../Components/DeletePrompt/DeletePrompt";
const $ = window.jQuery;

export default function Item({
  item,
  setItem,
  setDeleteType,
  setpageId,
  setId,
  setUserId,
  setRecognitionLevelData,
  setInstituteName,
  setRecognitionLevel,
  setclassName,
  setType,
  setAwardName,
  setInstituteNameKey,
  setOptions,
}) {
  const Navigate = useNavigate();

  const handleEdit = async (Id) => {
    try {
      let resp = await FetchApi(Endpoints.GetSingleHonor + "/" + Id);
      if (resp && resp.message) {
        setAwardName(resp.data.awardName);
        setType(resp.data.type);
        setclassName(resp.data.awardFor);
        setRecognitionLevel(resp.data.institute);
        setInstituteName(resp.data.institute);
        setInstituteNameKey(resp.data?.institute);
        setOptions([
          {
            name: resp.data.page.title,
            id: resp.data.page.id,
            image: resp.data.page.pageImage,
          },
        ]);
        setUserId(resp.data.userId);
        setId(Id);
        if (resp.data.pageId === null) {
          setpageId(0);
        } else {
          setpageId(resp.data.pageId);
        }
        $("#Honormodal").modal("show");
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <div className="col-lg-3 col-md-4 mb-4">
      <div className="award-item text-center  position-relative">
        <div className="mainmenu-item-action position-absolute end-0 m-2 p-1 text-right">
          <Link
            id="notification"
            className="d-flex align-items-center justify-content-center border rounded-circle bg-white p-1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <svg
              viewBox="0 0 24 24"
              width="22"
              height="22"
              stroke="#000"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="css-i6dzq1"
            >
              <circle cx="12" cy="12" r="1"></circle>
              <circle cx="19" cy="12" r="1"></circle>
              <circle cx="5" cy="12" r="1"></circle>
            </svg>
          </Link>
          <div
            className="dropdown-menu haspopup fs-12 dropdown-menu-end"
            aria-labelledby="notification"
          >
            <ul>
              <li>
                <Link
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteInfoModal-Honor"
                  onClick={() => {
                    setItem(item.id);
                    setDeleteType("Honors");
                  }}
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="14"
                    height="14"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="css-i6dzq1"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                  &nbsp; Delete
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  onClick={() => handleEdit(item.id)}
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="14"
                    height="14"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="css-i6dzq1"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                  &nbsp; Edit
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <span className="award-img">
          <img src="assets/img/icon/icon-awards.svg" alt="" />
        </span>
        <div className="p-3">
          <h5 className="semibold text-brown pt-2 pb-1">{item.awardName}</h5>
          <p className="fs-12 m-0">
            {item.type === "Honor"
              ? "This honor is proudly presented to"
              : "This award is proudly presented to"}
          </p>

          <div className="d-flex align-items-center justify-content-center py-2">
            <Link
              to={"/timeline/" + item?.user?.username}
              className="d-flex align-items-center justify-content-center py-2"
            >
              {item.user.profileImg === null ? (
                <img
                  src=" assets/img/picture.png"
                  alt=""
                  width="32"
                  height="32"
                  className="rounded-circle"
                />
              ) : (
                <img
                  src={Endpoints.baseUrl + item.user.profileImg}
                  alt=""
                  width="32"
                  height="32"
                  className="rounded-circle"
                />
              )}
              <div className="ps-2 text-start">
                <h5 className="medium fs-14">{item?.user?.fullName}</h5>
                <h6 className="text-darkgray fs-10">{item?.institute}</h6>
              </div>
            </Link>
          </div>

          <p className="fs-12 m-0">For his excellent achievement in</p>
          <h5 className="semibold text-blue">{item?.awardFor}</h5>
        </div>
      </div>
    </div>
  );
}
