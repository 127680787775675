import React, { useCallback, useEffect, useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import Item from "./Item";
import { max } from "moment";
import { Helmet } from "react-helmet";
const $ = window.jQuery;
export default function Listing() {
  // const cid = useParams();
  const parms = useLocation().search.split("?")[1];
  const [courseData, setCourseData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [topicData, setTopicData] = useState([]);
  const [topicCount, setTopicCount] = useState(0);
  const [durationData, setDurationData] = useState([]);
  const [subCategoryFitter, setSubCategoryFitter] = useState([]);
  const [topicFilterData, setTopicFilterData] = useState([]);
  const [levelFilterData, setLevelFilterData] = useState([]);
  const [totalFitter, setTotalFitter] = useState([]);
  const [categoryFitter, setCategoryFitter] = useState([]);
  const [totalPage, setTotalPage] = useState("");
  const [activePage, setActivePage] = useState("");
  const [currentActivePage, setCurrentActivePage] = useState("");
  const [totalCourse, setTotalCourse] = useState(0);
  const [levelData, setLevelData] = useState({});
  const [courseTypeData, setCourseTypeData] = useState({});
  const [priceRange, setPriceRange] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setManPrice] = useState("");
  const [priceRangeStatus, setPriceRangeStatus] = useState(false);
  const [priceFitter, setPriceFitter] = useState([]);
  const [priceFitterData, setPriceFitterData] = useState([]);
  const [durationFitter, setDurationFitter] = useState([]);
  const [ratingFitter, setRatingFitter] = useState([]);
  const [sorting, setSorting] = useState("");
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState([]);
  const [Rating, setRating] = useState("");
  const [size, setSize] = useState(12);
  const location = useLocation();

  const navigate = useNavigate();
  const sid = JSON.parse(new URLSearchParams(useLocation().search).get("sid"));
  const cid = JSON.parse(new URLSearchParams(useLocation().search).get("cid"));
  const topics = JSON.parse(
    new URLSearchParams(useLocation().search).get("topics")
  );
  const level = JSON.parse(
    new URLSearchParams(useLocation().search).get("level")
  );
  const priceType = JSON.parse(
    new URLSearchParams(useLocation().search).get("type")
  );
  const duration = JSON.parse(
    new URLSearchParams(useLocation().search).get("duration")
  );
  const ratings = JSON.parse(
    new URLSearchParams(useLocation().search).get("ratings")
  );
  const NewPriceRangeData = JSON.parse(
    new URLSearchParams(useLocation().search).get("priceRange")
  );

  useEffect(() => {
    if (
      location.search.includes("sid") ||
      location.search.includes("topics") ||
      location.search.includes("level") ||
      location.search.includes("type") ||
      location.search.includes("ratings") ||
      location.search.includes("duration")
    ) {
      let a = JSON.parse(localStorage.getItem("filters"));
      a.length > 0 ? setTotalFitter(a) : setTotalFitter([]);
    }
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(totalFitter));
  }, [totalFitter]);

  useEffect(() => {
    courseListing();
    if (cid !== null) {
      let categoryFitterData = categoryFitter.concat(cid);

      setCategoryFitter(categoryFitterData);
    }
    if (sid !== null) {
      let subcategoryData = subCategoryFitter.concat(sid);

      setSubCategoryFitter(subcategoryData);
    }

    if (topics !== null) {
      let topicFilternewData = topicFilterData.concat(topics);

      setTopicFilterData(topicFilternewData);
    }
    if (level !== null) {
      let NewlevelFilterData = levelFilterData.concat(level);

      setLevelFilterData(NewlevelFilterData);
    }
    if (priceType !== null) {
      let NewPriceTypeData = priceFitter.concat(priceType);
      // setTotalFitter([...totalFitter, priceType]);
      setPriceFitter(NewPriceTypeData);
    }
    if (duration !== null) {
      let NewDurationData = durationFitter.concat(duration);
      // setTotalFitter([...totalFitter, NewDurationData]);
      setDurationFitter(NewDurationData);
    }
    if (ratings !== null) {
      let NewRatingsData = ratingFitter.concat(ratings);
      // setTotalFitter([...totalFitter, NewRatingsData]);
      setRatingFitter(NewRatingsData);
    }
    if (NewPriceRangeData !== null) {
      let NewPriceData = priceRange.concat(NewPriceRangeData);
      // setTotalFitter([...totalFitter, NewPriceData]);
      setPriceRange(NewPriceData);
    }
  }, []);

  const courseListing = async () => {
    setLoading(true);
    try {
      let resp = await FetchApi(Endpoints.courseListing + "?" + parms);
      if (resp && resp.success === true) {
        setLoading(false);
        setCourseData(resp?.data?.course);
        const newCategoryData = [];
        resp?.data?.courseCategories.map((item) => {
          let subcategoryTotalCount = 0;
          return (
            <>
              {item.course_subcategories.map((subItem) => {
                return (subcategoryTotalCount =
                  parseInt(subcategoryTotalCount) +
                  parseInt(subItem.totalCourses));
              })}
              {subcategoryTotalCount > 0 ? newCategoryData.push(item) : ""}
            </>
          );
        });

        setCategoryData(newCategoryData);
        setTotalPage(resp?.data?.totalPages);
        setActivePage(resp?.data?.currentPage);
        setTotalCourse(resp?.data?.totalCourses);

        setCategoryData(newCategoryData);

        setRating(resp?.data?.filters.ratingsFilter);
        setDurationData(resp?.data?.allDurations);
        setLevelData({
          beginner: resp?.data?.filters.beginnerCourses,
          intermediate: resp?.data?.filters.intermediateCourses,
          expert: resp?.data?.filters.expertCourses,
        });

        setPriceFitterData(resp?.data?.filters?.priceRanges);

        setCourseTypeData({
          paid: resp?.data?.filters.paidCourses,
          free: resp?.data?.filters.freeCourses,
        });

        const newTopicData = [];
        resp?.data?.filters?.topics.map((item) => {
          return item.course_topics.length > 0
            ? setTopicData([...newTopicData, item.course_topics])
            : "";
        });
        console.log(newTopicData);
        setTopicData(
          resp?.data?.filters?.topics.filter((item) =>
            newTopicData.concat(item.course_topics)
          )
        );
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoading(false);
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleCourseFilterData = async (url) => {
    // setLoading(true);
    try {
      let resp = await FetchApi(url);
      if (resp && resp.success === true) {
        setLoading(false);
        setCourseData(resp?.data?.course);
        const newCategoryData = [];
        resp?.data?.courseCategories.map((item) => {
          let subcategoryTotalCount = 0;
          return (
            <>
              {item.course_subcategories.map((subItem) => {
                return (subcategoryTotalCount =
                  parseInt(subcategoryTotalCount) +
                  parseInt(subItem.totalCourses));
              })}
              {subcategoryTotalCount > 0 ? newCategoryData.push(item) : ""}
            </>
          );
        });

        setCategoryData(newCategoryData);
        setTotalPage(resp?.data?.totalPages);
        setActivePage(resp?.data?.currentPage);
        setTotalCourse(resp?.data?.totalCourses);

        setCategoryData(newCategoryData);

        setRating(resp?.data?.filters.ratingsFilter);
        setDurationData(resp?.data?.allDurations);
        setLevelData({
          beginner: resp?.data?.filters.beginnerCourses,
          intermediate: resp?.data?.filters.intermediateCourses,
          expert: resp?.data?.filters.expertCourses,
        });

        setPriceFitterData(resp?.data?.filters?.priceRanges);

        setCourseTypeData({
          paid: resp?.data?.filters.paidCourses,
          free: resp?.data?.filters.freeCourses,
        });

        const newTopicData = [];
        resp?.data?.filters?.topics.map((item) => {
          return item.course_topics.length > 0
            ? setTopicData([...newTopicData, item.course_topics])
            : "";
        });
        console.log(newTopicData);
        setTopicData(
          resp?.data?.filters?.topics.filter((item) =>
            newTopicData.concat(item.course_topics)
          )
        );
        setLoading(false);
      } else {
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoading(false);
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleChangeCategory = (categoryId, name) => {
    let fileter_data = totalFitter.slice();
    if (
      totalFitter.filter((item) => item.id === "category-" + categoryId)
        .length === 0
    ) {
      let filterData = {
        CategoryId: categoryId,
        name: name,
        id: "category-" + categoryId,
        filterType: "category",
      };
      setTotalFitter([...totalFitter, filterData]);
      fileter_data = [...totalFitter, filterData];
    } else {
      const filterData = totalFitter.filter(
        (item) => item.id !== "category-" + categoryId
      );
      fileter_data = filterData;
      setTotalFitter(filterData);
    }

    let NewCategoryFitter = categoryFitter.slice();
    if (fileter_data.find((item) => item.CategoryId === categoryId)) {
      if (!categoryFitter.find((item) => item === categoryId)) {
        setCategoryFitter((value) => [...value, categoryId]);
        NewCategoryFitter = [...categoryFitter, categoryId];
        // categoryFitter.push(CategoryId);
      }
    } else {
      let newCategoryFilter = categoryFitter.filter(
        (item) => item !== categoryId
      );
      NewCategoryFitter = newCategoryFilter;
      setCategoryFitter(newCategoryFilter);
    }

    let url = Endpoints.courseListing + "?size=" + size;
    let navigateUrl = "/course-listing?size=" + size;

    if (NewCategoryFitter.length > 0) {
      navigateUrl = navigateUrl + "&cid=" + JSON.stringify(NewCategoryFitter);
      url = url + "&cid=" + JSON.stringify(NewCategoryFitter);
    }
    if (subCategoryFitter.length > 0) {
      navigateUrl = navigateUrl + "&sid=" + JSON.stringify(subCategoryFitter);
      url = url + "&sid=" + JSON.stringify(subCategoryFitter);
    }
    if (levelFilterData.length > 0) {
      navigateUrl = navigateUrl + "&level=" + JSON.stringify(levelFilterData);
      url = url + "&level=" + JSON.stringify(levelFilterData);
    }
    if (topicFilterData.length > 0) {
      navigateUrl = navigateUrl + "&topics=" + JSON.stringify(topicFilterData);
      url = url + "&topics=" + JSON.stringify(topicFilterData);
    }

    if (priceRange.length > 0) {
      navigateUrl = navigateUrl + "&priceRange=" + JSON.stringify(priceRange);
      url = url + "&priceRange=" + JSON.stringify(priceRange);
    }

    if (priceFitter.length > 0) {
      navigateUrl = navigateUrl + "&type=" + JSON.stringify(priceFitter);
      url = url + "&type=" + JSON.stringify(priceFitter);
    }
    if (durationFitter.length > 0) {
      navigateUrl = navigateUrl + "&duration=" + JSON.stringify(durationFitter);
      url = url + "&duration=" + JSON.stringify(durationFitter);
    }
    if (ratingFitter.length > 0) {
      navigateUrl = navigateUrl + "&ratings=" + JSON.stringify(ratingFitter);
      url = url + "&ratings=" + JSON.stringify(ratingFitter);
    }
    if (sorting) {
      navigateUrl = navigateUrl + sorting;
      url = url + sorting;
    }

    if (currentActivePage) {
      navigateUrl = navigateUrl + "&page=" + currentActivePage;
      url = url + "&page=" + currentActivePage;
    }

    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleCheckedSubcategory = async (CategoryId, Id, name) => {
    let fileter_data = totalFitter.slice();
    if (
      totalFitter.filter((item) => item.id === "subcategory-" + Id).length === 0
    ) {
      let filterData = {
        CategoryId: CategoryId,
        name: name,
        id: "subcategory-" + Id,
        filterType: "subcategory",
      };
      setTotalFitter([...totalFitter, filterData]);
      fileter_data = [...totalFitter, filterData];
    } else {
      const filterData = totalFitter.filter(
        (item) => item.id !== "subcategory-" + Id
      );
      fileter_data = filterData;
      setTotalFitter(filterData);
    }

    console.log("totalFitter", totalFitter);
    let NewCategoryFitter = categoryFitter.slice();
    if (fileter_data.find((item) => item.CategoryId === CategoryId)) {
      if (!categoryFitter.find((item) => item === CategoryId)) {
        setCategoryFitter((value) => [...value, CategoryId]);
        NewCategoryFitter = [...categoryFitter, CategoryId];
        // categoryFitter.push(CategoryId);
      }
    } else {
      let newCategoryFilter = categoryFitter.filter(
        (item) => item !== CategoryId
      );
      NewCategoryFitter = newCategoryFilter;
      setCategoryFitter(newCategoryFilter);
    }

    let url = Endpoints.courseListing + "?size=" + size;
    let navigateUrl = "/course-listing?size=" + size;

    if (subCategoryFitter.filter((item) => item === Id).length > 0) {
      const sucategoryFilterData = subCategoryFitter.filter(
        (item) => item !== Id
      );
      setSubCategoryFitter(sucategoryFilterData);
      if (sucategoryFilterData.length === 0) {
        const categoryFitterData = categoryFitter.filter(
          (item) => item !== CategoryId
        );
        setCategoryFitter(categoryFitterData);
      }
      if (NewCategoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&cid=" + JSON.stringify(NewCategoryFitter);
        url = url + "&cid=" + JSON.stringify(NewCategoryFitter);
      }

      if (sucategoryFilterData.length > 0) {
        navigateUrl =
          navigateUrl + "&sid=" + JSON.stringify(sucategoryFilterData);
        url = url + "&sid=" + JSON.stringify(sucategoryFilterData);
      }
      if (levelFilterData.length > 0) {
        navigateUrl = navigateUrl + "&level=" + JSON.stringify(levelFilterData);
        url = url + "&level=" + JSON.stringify(levelFilterData);
      }
      if (topicFilterData.length > 0) {
        navigateUrl =
          navigateUrl + "&topics=" + JSON.stringify(topicFilterData);
        url = url + "&topics=" + JSON.stringify(topicFilterData);
      }

      if (priceRange.length > 0) {
        navigateUrl = navigateUrl + "&priceRange=" + JSON.stringify(priceRange);
        url = url + "&priceRange=" + JSON.stringify(priceRange);
      }

      if (priceFitter.length > 0) {
        navigateUrl = navigateUrl + "&type=" + JSON.stringify(priceFitter);
        url = url + "&type=" + JSON.stringify(priceFitter);
      }
      if (durationFitter.length > 0) {
        navigateUrl =
          navigateUrl + "&duration=" + JSON.stringify(durationFitter);
        url = url + "&duration=" + JSON.stringify(durationFitter);
      }
      if (ratingFitter.length > 0) {
        navigateUrl = navigateUrl + "&ratings=" + JSON.stringify(ratingFitter);
        url = url + "&ratings=" + JSON.stringify(ratingFitter);
      }
      if (sorting) {
        navigateUrl = navigateUrl + sorting;
        url = url + sorting;
      }

      if (currentActivePage) {
        navigateUrl = navigateUrl + "&page=" + currentActivePage;
        url = url + "&page=" + currentActivePage;
      }
    } else {
      subCategoryFitter.push(Id);
      if (NewCategoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&cid=" + JSON.stringify(NewCategoryFitter);
        url = url + "&cid=" + JSON.stringify(NewCategoryFitter);
      }

      if (subCategoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&sid=" + JSON.stringify(subCategoryFitter);
        url = url + "&sid=" + JSON.stringify(subCategoryFitter);
      }
      if (levelFilterData.length > 0) {
        navigateUrl = navigateUrl + "&level=" + JSON.stringify(levelFilterData);
        url = url + "&level=" + JSON.stringify(levelFilterData);
      }
      if (topicFilterData.length > 0) {
        navigateUrl =
          navigateUrl + "&topics=" + JSON.stringify(topicFilterData);
        url = url + "&topics=" + JSON.stringify(topicFilterData);
      }
      if (priceRange.length > 0) {
        navigateUrl = navigateUrl + "&priceRange=" + JSON.stringify(priceRange);
        url = url + "&priceRange=" + JSON.stringify(priceRange);
      }
      if (priceFitter.length > 0) {
        navigateUrl = navigateUrl + "&type=" + JSON.stringify(priceFitter);
        url = url + "&type=" + JSON.stringify(priceFitter);
      }
      if (durationFitter.length > 0) {
        navigateUrl =
          navigateUrl + "&duration=" + JSON.stringify(durationFitter);
        url = url + "&duration=" + JSON.stringify(durationFitter);
      }
      if (ratingFitter.length > 0) {
        navigateUrl = navigateUrl + "&ratings=" + JSON.stringify(ratingFitter);
        url = url + "&ratings=" + JSON.stringify(ratingFitter);
      }
      if (sorting) {
        navigateUrl = navigateUrl + sorting;
        url = url + sorting;
      }
      if (currentActivePage) {
        navigateUrl = navigateUrl + "&page=" + currentActivePage;
        url = url + "&page=" + currentActivePage;
      }
    }
    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const page = [];
  for (let i = 1; i <= totalPage; i++) {
    if (i <= 3) {
      page.push(
        <a
          class={activePage === i ? "page-active" : ""}
          onClick={() => {
            handlePagination(i);
          }}
        >
          {i}
        </a>
      );
    } else {
      page.push(<img src="../assets/img/icon/icon-separator.svg" alt="" />);
      page.push(
        <a
          class={activePage === i ? "page-active" : ""}
          onClick={() => {
            handlePagination(totalPage);
          }}
        >
          {totalPage}
        </a>
      );
    }
  }

  const handlePagination = (Id) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentActivePage(Id);
    let url = Endpoints.courseListing + "?size=" + size;
    let navigateUrl = "/course-listing?size=" + size;

    if (categoryFitter.length > 0) {
      navigateUrl = navigateUrl + "&cid=" + JSON.stringify(categoryFitter);
      url = url + "&cid=" + JSON.stringify(categoryFitter);
    }

    if (subCategoryFitter.length > 0) {
      navigateUrl = navigateUrl + "&sid=" + JSON.stringify(subCategoryFitter);
      url = url + "&sid=" + JSON.stringify(subCategoryFitter);
    }
    if (levelFilterData.length > 0) {
      navigateUrl = navigateUrl + "&level=" + JSON.stringify(levelFilterData);
      url = url + "&level=" + JSON.stringify(levelFilterData);
    }
    if (topicFilterData.length > 0) {
      navigateUrl = navigateUrl + "&topics=" + JSON.stringify(topicFilterData);
      url = url + "&topics=" + JSON.stringify(topicFilterData);
    }
    if (priceRange.length > 0) {
      navigateUrl = navigateUrl + "&priceRange=" + JSON.stringify(priceRange);
      url = url + "&priceRange=" + JSON.stringify(priceRange);
    }

    if (priceFitter.length > 0) {
      navigateUrl = navigateUrl + "&type=" + JSON.stringify(priceFitter);
      url = url + "&type=" + JSON.stringify(priceFitter);
    }
    if (durationFitter.length > 0) {
      navigateUrl = navigateUrl + "&duration=" + JSON.stringify(durationFitter);
      url = url + "&duration=" + JSON.stringify(durationFitter);
    }
    if (ratingFitter.length > 0) {
      navigateUrl = navigateUrl + "&ratings=" + JSON.stringify(ratingFitter);
      url = url + "&ratings=" + JSON.stringify(ratingFitter);
    }
    if (sorting) {
      navigateUrl = navigateUrl + sorting;
      url = url + sorting;
    }
    if (Id) {
      navigateUrl = navigateUrl + "&page=" + Id;
      url = url + "&page=" + Id;
    }

    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleSorting = (value) => {
    let sorting = "";
    if (value === "Newest") {
      sorting = "&newest=" + true;
    } else if (value === "HighestRated") {
      sorting = "&highest=" + true;
    } else if (value === "BestSeller") {
      sorting = "&bestSeller=" + true;
    } else if (value === "MostPopular") {
      sorting = "&mostPopular=" + true;
    }

    setSorting(sorting);
    let url = Endpoints.courseListing + "?size=" + size;
    let navigateUrl = "/course-listing?size=" + size;

    if (categoryFitter.length > 0) {
      navigateUrl = navigateUrl + "&cid=" + JSON.stringify(categoryFitter);
      url = url + "&cid=" + JSON.stringify(categoryFitter);
    }

    if (subCategoryFitter.length > 0) {
      navigateUrl = navigateUrl + "&sid=" + JSON.stringify(subCategoryFitter);
      url = url + "&sid=" + JSON.stringify(subCategoryFitter);
    }
    if (levelFilterData.length > 0) {
      navigateUrl = navigateUrl + "&level=" + JSON.stringify(levelFilterData);
      url = url + "&level=" + JSON.stringify(levelFilterData);
    }
    if (topicFilterData.length > 0) {
      navigateUrl = navigateUrl + "&topics=" + JSON.stringify(topicFilterData);
      url = url + "&topics=" + JSON.stringify(topicFilterData);
    }
    if (priceRange.length > 0) {
      navigateUrl = navigateUrl + "&priceRange=" + JSON.stringify(priceRange);
      url = url + "&priceRange=" + JSON.stringify(priceRange);
    }

    if (priceFitter.length > 0) {
      navigateUrl = navigateUrl + "&type=" + JSON.stringify(priceFitter);
      url = url + "&type=" + JSON.stringify(priceFitter);
    }
    if (durationFitter.length > 0) {
      navigateUrl = navigateUrl + "&duration=" + JSON.stringify(durationFitter);
      url = url + "&duration=" + JSON.stringify(durationFitter);
    }
    if (ratingFitter.length > 0) {
      navigateUrl = navigateUrl + "&ratings=" + JSON.stringify(ratingFitter);
      url = url + "&ratings=" + JSON.stringify(ratingFitter);
    }
    if (sorting) {
      navigateUrl = navigateUrl + sorting;
      url = url + sorting;
    }
    if (currentActivePage) {
      navigateUrl = navigateUrl + "&page=" + currentActivePage;
      url = url + "&page=" + currentActivePage;
    }

    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleTopicsFiltter = (topicName, topicId) => {
    if (
      totalFitter.filter((item) => item.id === "topic-" + topicName).length ===
      0
    ) {
      let filterData = {
        CategoryId: null,
        name: topicName,
        id: "topic-" + topicName,
        filterType: "topic",
      };
      setTotalFitter([...totalFitter, filterData]);
    } else {
      const filterData = totalFitter.filter(
        (item) => item.id !== "topic-" + topicName
      );
      setTotalFitter(filterData);
    }

    console.log("totalFitter", totalFitter);

    let url = Endpoints.courseListing + "?size=" + size;
    let navigateUrl = "/course-listing?size=" + size;

    if (topicFilterData.filter((item) => item === topicName).length > 0) {
      const newtopicFilterData = topicFilterData.filter(
        (item) => item !== topicName
      );
      setTopicFilterData(newtopicFilterData);
      if (subCategoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&sid=" + JSON.stringify(subCategoryFitter);
        url = url + "&sid=" + JSON.stringify(subCategoryFitter);
      }
      if (categoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&cid=" + JSON.stringify(categoryFitter);
        url = url + "&cid=" + JSON.stringify(categoryFitter);
      }

      if (levelFilterData.length > 0) {
        navigateUrl = navigateUrl + "&level=" + JSON.stringify(levelFilterData);
        url = url + "&level=" + JSON.stringify(levelFilterData);
      }
      if (newtopicFilterData.length > 0) {
        navigateUrl =
          navigateUrl + "&topics=" + JSON.stringify(newtopicFilterData);
        url = url + "&topics=" + JSON.stringify(newtopicFilterData);
      }
      if (priceRange.length > 0) {
        navigateUrl = navigateUrl + "&priceRange=" + JSON.stringify(priceRange);
        url = url + "&priceRange=" + JSON.stringify(priceRange);
      }

      if (priceFitter.length > 0) {
        navigateUrl = navigateUrl + "&type=" + JSON.stringify(priceFitter);
        url = url + "&type=" + JSON.stringify(priceFitter);
      }
      if (durationFitter.length > 0) {
        navigateUrl =
          navigateUrl + "&duration=" + JSON.stringify(durationFitter);
        url = url + "&duration=" + JSON.stringify(durationFitter);
      }
      if (ratingFitter.length > 0) {
        navigateUrl = navigateUrl + "&ratings=" + JSON.stringify(ratingFitter);
        url = url + "&ratings=" + JSON.stringify(ratingFitter);
      }
      if (sorting) {
        navigateUrl = navigateUrl + sorting;
        url = url + sorting;
      }

      if (currentActivePage) {
        navigateUrl = navigateUrl + "&page=" + currentActivePage;
        url = url + "&page=" + currentActivePage;
      }
    } else {
      topicFilterData.push(topicName);

      if (subCategoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&sid=" + JSON.stringify(subCategoryFitter);
        url = url + "&sid=" + JSON.stringify(subCategoryFitter);
      }
      if (categoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&cid=" + JSON.stringify(categoryFitter);
        url = url + "&cid=" + JSON.stringify(categoryFitter);
      }

      if (levelFilterData.length > 0) {
        navigateUrl = navigateUrl + "&level=" + JSON.stringify(levelFilterData);
        url = url + "&level=" + JSON.stringify(levelFilterData);
      }
      if (topicFilterData.length > 0) {
        navigateUrl =
          navigateUrl + "&topics=" + JSON.stringify(topicFilterData);
        url = url + "&topics=" + JSON.stringify(topicFilterData);
      }
      if (priceRange.length > 0) {
        navigateUrl = navigateUrl + "&priceRange=" + JSON.stringify(priceRange);
        url = url + "&priceRange=" + JSON.stringify(priceRange);
      }

      if (priceFitter.length > 0) {
        navigateUrl = navigateUrl + "&type=" + JSON.stringify(priceFitter);
        url = url + "&type=" + JSON.stringify(priceFitter);
      }
      if (durationFitter.length > 0) {
        navigateUrl =
          navigateUrl + "&duration=" + JSON.stringify(durationFitter);
        url = url + "&duration=" + JSON.stringify(durationFitter);
      }
      if (ratingFitter.length > 0) {
        navigateUrl = navigateUrl + "&ratings=" + JSON.stringify(ratingFitter);
        url = url + "&ratings=" + JSON.stringify(ratingFitter);
      }

      if (sorting) {
        navigateUrl = navigateUrl + sorting;
        url = url + sorting;
      }

      if (currentActivePage) {
        navigateUrl = navigateUrl + "&page=" + currentActivePage;
        url = url + "&page=" + currentActivePage;
      }
    }
    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handlePrice = (value) => {
    let data = [];

    let priceRangedata = value.min + "-" + value.max;
    data = [...priceRange, priceRangedata];
    if (priceRange.filter((item) => item === priceRangedata).length > 0) {
      let newpriceRange = priceRange.filter((item) => item !== priceRangedata);
      data = newpriceRange;
      setPriceRange(newpriceRange);
    } else {
      priceRange.push(priceRangedata);
    }

    let priceRanged = "Rs " + value.min + "- Rs " + value.max;
    if (
      totalFitter.filter((item) => item.id === "priceRange-" + priceRanged)
        .length === 0
    ) {
      let filterData = {
        CategoryId: null,
        name: priceRanged,
        min: value.min,
        max: value.max,
        id: "priceRange-" + priceRanged,
        filterType: "priceRange",
      };
      setTotalFitter([...totalFitter, filterData]);
    } else {
      const filterData = totalFitter.filter(
        (item) => item.id !== "priceRange-" + priceRanged
      );
      setTotalFitter(filterData);
    }

    let url = Endpoints.courseListing + "?size=" + size;
    let navigateUrl = "/course-listing?size=" + size;

    if (categoryFitter.length > 0) {
      navigateUrl = navigateUrl + "&cid=" + JSON.stringify(categoryFitter);
      url = url + "&cid=" + JSON.stringify(categoryFitter);
    }

    if (subCategoryFitter.length > 0) {
      navigateUrl = navigateUrl + "&sid=" + JSON.stringify(subCategoryFitter);
      url = url + "&sid=" + JSON.stringify(subCategoryFitter);
    }
    if (levelFilterData.length > 0) {
      navigateUrl = navigateUrl + "&level=" + JSON.stringify(levelFilterData);
      url = url + "&level=" + JSON.stringify(levelFilterData);
    }
    if (topicFilterData.length > 0) {
      navigateUrl = navigateUrl + "&topics=" + JSON.stringify(topicFilterData);
      url = url + "&topics=" + JSON.stringify(topicFilterData);
    }

    if (priceFitter.length > 0) {
      navigateUrl = navigateUrl + "&type=" + JSON.stringify(priceFitter);
      url = url + "&type=" + JSON.stringify(priceFitter);
    }
    if (durationFitter.length > 0) {
      navigateUrl = navigateUrl + "&duration=" + JSON.stringify(durationFitter);
      url = url + "&duration=" + JSON.stringify(durationFitter);
    }
    if (ratingFitter.length > 0) {
      navigateUrl = navigateUrl + "&ratings=" + JSON.stringify(ratingFitter);
      url = url + "&ratings=" + JSON.stringify(ratingFitter);
    }

    if (sorting) {
      navigateUrl = navigateUrl + sorting;
      url = url + sorting;
    }

    if (currentActivePage) {
      navigateUrl = navigateUrl + "&page=" + currentActivePage;
      url = url + "&page=" + currentActivePage;
    }
    if (data.length > 0) {
      navigateUrl = navigateUrl + "&priceRange=" + JSON.stringify(data);
      url = url + "&priceRange=" + JSON.stringify(data);
    }

    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleCoursePriceType = (value) => {
    if (
      totalFitter.filter((item) => item.id === "price-" + value).length === 0
    ) {
      let filterData = {
        CategoryId: null,
        name: value,
        id: "price-" + value,
        filterType: "price",
      };
      setTotalFitter([...totalFitter, filterData]);
    } else {
      const filterData = totalFitter.filter(
        (item) => item.id !== "price-" + value
      );
      setTotalFitter(filterData);
    }

    if (value === "Free") {
      if (priceRangeStatus === false) {
        setPriceRangeStatus(true);
      } else {
        setPriceRangeStatus(false);
      }
    } else {
      setPriceRangeStatus(false);
    }

    let url = Endpoints.courseListing + "?size=" + size;
    let navigateUrl = "/course-listing?size=" + size;

    if (priceFitter.filter((item) => item === value).length > 0) {
      const newPriceFitter = priceFitter.filter((item) => item !== value);
      setPriceFitter(newPriceFitter);
      if (categoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&cid=" + JSON.stringify(categoryFitter);
        url = url + "&cid=" + JSON.stringify(categoryFitter);
      }
      if (subCategoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&sid=" + JSON.stringify(subCategoryFitter);
        url = url + "&sid=" + JSON.stringify(subCategoryFitter);
      }
      if (levelFilterData.length > 0) {
        navigateUrl = navigateUrl + "&level=" + JSON.stringify(levelFilterData);
        url = url + "&level=" + JSON.stringify(levelFilterData);
      }
      if (topicFilterData.length > 0) {
        navigateUrl =
          navigateUrl + "&topics=" + JSON.stringify(topicFilterData);
        url = url + "&topics=" + JSON.stringify(topicFilterData);
      }

      if (priceRange.length > 0) {
        navigateUrl = navigateUrl + "&priceRange=" + JSON.stringify(priceRange);
        url = url + "&priceRange=" + JSON.stringify(priceRange);
      }

      if (newPriceFitter.length > 0) {
        navigateUrl = navigateUrl + "&type=" + JSON.stringify(newPriceFitter);
        url = url + "&type=" + JSON.stringify(newPriceFitter);
      }
      if (durationFitter.length > 0) {
        navigateUrl =
          navigateUrl + "&duration=" + JSON.stringify(durationFitter);
        url = url + "&duration=" + JSON.stringify(durationFitter);
      }
      if (ratingFitter.length > 0) {
        navigateUrl = navigateUrl + "&ratings=" + JSON.stringify(ratingFitter);
        url = url + "&ratings=" + JSON.stringify(ratingFitter);
      }
      if (sorting) {
        navigateUrl = navigateUrl + sorting;
        url = url + sorting;
      }

      if (currentActivePage) {
        navigateUrl = navigateUrl + "&page=" + currentActivePage;
        url = url + "&page=" + currentActivePage;
      }
    } else {
      priceFitter.push(value);
      if (categoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&cid=" + JSON.stringify(categoryFitter);
        url = url + "&cid=" + JSON.stringify(categoryFitter);
      }
      if (subCategoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&sid=" + JSON.stringify(subCategoryFitter);
        url = url + "&sid=" + JSON.stringify(subCategoryFitter);
      }
      if (levelFilterData.length > 0) {
        navigateUrl = navigateUrl + "&level=" + JSON.stringify(levelFilterData);
        url = url + "&level=" + JSON.stringify(levelFilterData);
      }
      if (topicFilterData.length > 0) {
        navigateUrl =
          navigateUrl + "&topics=" + JSON.stringify(topicFilterData);
        url = url + "&topics=" + JSON.stringify(topicFilterData);
      }
      if (priceRange.length > 0) {
        navigateUrl = navigateUrl + "&priceRange=" + JSON.stringify(priceRange);
        url = url + "&priceRange=" + JSON.stringify(priceRange);
      }
      if (priceFitter.length > 0) {
        navigateUrl = navigateUrl + "&type=" + JSON.stringify(priceFitter);
        url = url + "&type=" + JSON.stringify(priceFitter);
      }

      if (durationFitter.length > 0) {
        navigateUrl =
          navigateUrl + "&duration=" + JSON.stringify(durationFitter);
        url = url + "&duration=" + JSON.stringify(durationFitter);
      }
      if (ratingFitter.length > 0) {
        navigateUrl = navigateUrl + "&ratings=" + JSON.stringify(ratingFitter);
        url = url + "&ratings=" + JSON.stringify(ratingFitter);
      }
      if (sorting) {
        navigateUrl = navigateUrl + sorting;
        url = url + sorting;
      }

      if (currentActivePage) {
        navigateUrl = navigateUrl + "&page=" + currentActivePage;
        url = url + "&page=" + currentActivePage;
      }
    }
    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleCourseLevel = (level) => {
    if (
      totalFitter.filter((item) => item.id === "level-" + level).length === 0
    ) {
      let filterData = {
        CategoryId: null,
        name: level,
        id: "level-" + level,
        filterType: "level",
      };
      setTotalFitter([...totalFitter, filterData]);
    } else {
      const filterData = totalFitter.filter(
        (item) => item.id !== "level-" + level
      );
      setTotalFitter(filterData);
    }
    let url = Endpoints.courseListing + "?size=" + size;
    let navigateUrl = "/course-listing?size=" + size;
    if (levelFilterData.filter((item) => item === level).length > 0) {
      const newlevelFilterData = levelFilterData.filter(
        (item) => item !== level
      );
      setLevelFilterData(newlevelFilterData);
      if (categoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&cid=" + JSON.stringify(categoryFitter);
        url = url + "&cid=" + JSON.stringify(categoryFitter);
      }
      if (subCategoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&sid=" + JSON.stringify(subCategoryFitter);
        url = url + "&sid=" + JSON.stringify(subCategoryFitter);
      }
      if (newlevelFilterData.length > 0) {
        navigateUrl =
          navigateUrl + "&level=" + JSON.stringify(newlevelFilterData);
        url = url + "&level=" + JSON.stringify(newlevelFilterData);
      }
      if (topicFilterData.length > 0) {
        navigateUrl =
          navigateUrl + "&topics=" + JSON.stringify(topicFilterData);
        url = url + "&topics=" + JSON.stringify(topicFilterData);
      }

      if (priceRange.length > 0) {
        navigateUrl = navigateUrl + "&priceRange=" + JSON.stringify(priceRange);
        url = url + "&priceRange=" + JSON.stringify(priceRange);
      }

      if (priceFitter.length > 0) {
        navigateUrl = navigateUrl + "&type=" + JSON.stringify(priceFitter);
        url = url + "&type=" + JSON.stringify(priceFitter);
      }
      if (durationFitter.length > 0) {
        navigateUrl =
          navigateUrl + "&duration=" + JSON.stringify(durationFitter);
        url = url + "&duration=" + JSON.stringify(durationFitter);
      }
      if (ratingFitter.length > 0) {
        navigateUrl = navigateUrl + "&ratings=" + JSON.stringify(ratingFitter);
        url = url + "&ratings=" + JSON.stringify(ratingFitter);
      }
      if (sorting) {
        navigateUrl = navigateUrl + sorting;
        url = url + sorting;
      }

      if (currentActivePage) {
        navigateUrl = navigateUrl + "&page=" + currentActivePage;
        url = url + "&page=" + currentActivePage;
      }
    } else {
      levelFilterData.push(level);
      if (categoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&cid=" + JSON.stringify(categoryFitter);
        url = url + "&cid=" + JSON.stringify(categoryFitter);
      }
      if (subCategoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&sid=" + JSON.stringify(subCategoryFitter);
        url = url + "&sid=" + JSON.stringify(subCategoryFitter);
      }
      if (levelFilterData.length > 0) {
        navigateUrl = navigateUrl + "&level=" + JSON.stringify(levelFilterData);
        url = url + "&level=" + JSON.stringify(levelFilterData);
      }
      if (topicFilterData.length > 0) {
        navigateUrl =
          navigateUrl + "&topics=" + JSON.stringify(topicFilterData);
        url = url + "&topics=" + JSON.stringify(topicFilterData);
      }
      if (priceRange.length > 0) {
        navigateUrl = navigateUrl + "&priceRange=" + JSON.stringify(priceRange);
        url = url + "&priceRange=" + JSON.stringify(priceRange);
      }

      if (priceFitter.length > 0) {
        navigateUrl = navigateUrl + "&type=" + JSON.stringify(priceFitter);
        url = url + "&type=" + JSON.stringify(priceFitter);
      }
      if (durationFitter.length > 0) {
        navigateUrl =
          navigateUrl + "&duration=" + JSON.stringify(durationFitter);
        url = url + "&duration=" + JSON.stringify(durationFitter);
      }
      if (ratingFitter.length > 0) {
        navigateUrl = navigateUrl + "&ratings=" + JSON.stringify(ratingFitter);
        url = url + "&ratings=" + JSON.stringify(ratingFitter);
      }
      if (sorting) {
        navigateUrl = navigateUrl + sorting;
        url = url + sorting;
      }
      if (currentActivePage) {
        navigateUrl = navigateUrl + "&page=" + currentActivePage;
        url = url + "&page=" + currentActivePage;
      }
    }
    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleDuration = (min, max) => {
    let durationName = "";

    if (max === null) {
      durationName = min + "+";
    } else {
      durationName = min + "-" + max;
    }

    if (
      totalFitter.filter((item) => item.id === "duration-" + durationName)
        .length === 0
    ) {
      let filterData = {
        CategoryId: null,
        name: durationName + " hours",
        min: min,
        max: max,
        id: "duration-" + durationName,
        filterType: "duration",
      };
      setTotalFitter([...totalFitter, filterData]);
    } else {
      const filterData = totalFitter.filter(
        (item) => item.id !== "duration-" + durationName
      );
      setTotalFitter(filterData);
    }

    let url = Endpoints.courseListing + "?size=" + size;
    let navigateUrl = "/course-listing?size=" + size;
    if (durationFitter.filter((item) => item === durationName).length > 0) {
      const newdurationFitter = durationFitter.filter(
        (item) => item !== durationName
      );
      setDurationFitter(newdurationFitter);
      if (categoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&cid=" + JSON.stringify(categoryFitter);
        url = url + "&cid=" + JSON.stringify(categoryFitter);
      }
      if (subCategoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&sid=" + JSON.stringify(subCategoryFitter);
        url = url + "&sid=" + JSON.stringify(subCategoryFitter);
      }
      if (newdurationFitter.length > 0) {
        navigateUrl =
          navigateUrl + "&level=" + JSON.stringify(newdurationFitter);
        url = url + "&level=" + JSON.stringify(newdurationFitter);
      }
      if (topicFilterData.length > 0) {
        navigateUrl =
          navigateUrl + "&topics=" + JSON.stringify(topicFilterData);
        url = url + "&topics=" + JSON.stringify(topicFilterData);
      }

      if (priceRange.length > 0) {
        navigateUrl = navigateUrl + "&priceRange=" + JSON.stringify(priceRange);
        url = url + "&priceRange=" + JSON.stringify(priceRange);
      }

      if (priceFitter.length > 0) {
        navigateUrl = navigateUrl + "&type=" + JSON.stringify(priceFitter);
        url = url + "&type=" + JSON.stringify(priceFitter);
      }
      if (newdurationFitter.length > 0) {
        navigateUrl =
          navigateUrl + "&duration=" + JSON.stringify(newdurationFitter);
        url = url + "&duration=" + JSON.stringify(newdurationFitter);
      }
      if (ratingFitter.length > 0) {
        navigateUrl = navigateUrl + "&ratings=" + JSON.stringify(ratingFitter);
        url = url + "&ratings=" + JSON.stringify(ratingFitter);
      }
      if (sorting) {
        navigateUrl = navigateUrl + sorting;
        url = url + sorting;
      }

      if (currentActivePage) {
        navigateUrl = navigateUrl + "&page=" + currentActivePage;
        url = url + "&page=" + currentActivePage;
      }
    } else {
      durationFitter.push(durationName);
      if (categoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&cid=" + JSON.stringify(categoryFitter);
        url = url + "&cid=" + JSON.stringify(categoryFitter);
      }
      if (subCategoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&sid=" + JSON.stringify(subCategoryFitter);
        url = url + "&sid=" + JSON.stringify(subCategoryFitter);
      }
      if (levelFilterData.length > 0) {
        navigateUrl = navigateUrl + "&level=" + JSON.stringify(levelFilterData);
        url = url + "&level=" + JSON.stringify(levelFilterData);
      }
      if (topicFilterData.length > 0) {
        navigateUrl =
          navigateUrl + "&topics=" + JSON.stringify(topicFilterData);
        url = url + "&topics=" + JSON.stringify(topicFilterData);
      }
      if (priceRange.length > 0) {
        navigateUrl = navigateUrl + "&priceRange=" + JSON.stringify(priceRange);
        url = url + "&priceRange=" + JSON.stringify(priceRange);
      }

      if (priceFitter.length > 0) {
        navigateUrl = navigateUrl + "&type=" + JSON.stringify(priceFitter);
        url = url + "&type=" + JSON.stringify(priceFitter);
      }

      if (durationFitter.length > 0) {
        navigateUrl =
          navigateUrl + "&duration=" + JSON.stringify(durationFitter);
        url = url + "&duration=" + JSON.stringify(durationFitter);
      }

      if (ratingFitter.length > 0) {
        navigateUrl = navigateUrl + "&ratings=" + JSON.stringify(ratingFitter);
        url = url + "&ratings=" + JSON.stringify(ratingFitter);
      }

      if (sorting) {
        navigateUrl = navigateUrl + sorting;
        url = url + sorting;
      }

      if (currentActivePage) {
        navigateUrl = navigateUrl + "&page=" + currentActivePage;
        url = url + "&page=" + currentActivePage;
      }
    }
    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleCourseRating = (rating) => {
    if (
      totalFitter.filter((item) => item.id === "rating-" + rating).length === 0
    ) {
      let filterData = {
        CategoryId: null,
        name: rating + " star",
        id: "rating-" + rating,
        filterType: "rating",
      };
      setTotalFitter([...totalFitter, filterData]);
    } else {
      const filterData = totalFitter.filter(
        (item) => item.id !== "rating-" + rating
      );
      setTotalFitter(filterData);
    }
    let url = Endpoints.courseListing + "?size=" + size;
    let navigateUrl = "/course-listing?size=" + size;
    if (ratingFitter.filter((item) => item === rating).length > 0) {
      const newratingFitterData = ratingFitter.filter(
        (item) => item !== rating
      );
      setRatingFitter(newratingFitterData);
      if (categoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&cid=" + JSON.stringify(categoryFitter);
        url = url + "&cid=" + JSON.stringify(categoryFitter);
      }
      if (subCategoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&sid=" + JSON.stringify(subCategoryFitter);
        url = url + "&sid=" + JSON.stringify(subCategoryFitter);
      }
      if (levelFilterData.length > 0) {
        navigateUrl = navigateUrl + "&level=" + JSON.stringify(levelFilterData);
        url = url + "&level=" + JSON.stringify(levelFilterData);
      }
      if (topicFilterData.length > 0) {
        navigateUrl =
          navigateUrl + "&topics=" + JSON.stringify(topicFilterData);
        url = url + "&topics=" + JSON.stringify(topicFilterData);
      }

      if (priceRange.length > 0) {
        navigateUrl = navigateUrl + "&priceRange=" + JSON.stringify(priceRange);
        url = url + "&priceRange=" + JSON.stringify(priceRange);
      }

      if (priceFitter.length > 0) {
        navigateUrl = navigateUrl + "&type=" + JSON.stringify(priceFitter);
        url = url + "&type=" + JSON.stringify(priceFitter);
      }
      if (durationFitter.length > 0) {
        navigateUrl =
          navigateUrl + "&duration=" + JSON.stringify(durationFitter);
        url = url + "&duration=" + JSON.stringify(durationFitter);
      }
      if (newratingFitterData.length > 0) {
        navigateUrl =
          navigateUrl + "&ratings=" + JSON.stringify(newratingFitterData);
        url = url + "&ratings=" + JSON.stringify(newratingFitterData);
      }

      if (sorting) {
        navigateUrl = navigateUrl + sorting;
        url = url + sorting;
      }

      if (currentActivePage) {
        navigateUrl = navigateUrl + "&page=" + currentActivePage;
        url = url + "&page=" + currentActivePage;
      }
    } else {
      ratingFitter.push(rating);
      if (categoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&cid=" + JSON.stringify(categoryFitter);
        url = url + "&cid=" + JSON.stringify(categoryFitter);
      }
      if (subCategoryFitter.length > 0) {
        navigateUrl = navigateUrl + "&sid=" + JSON.stringify(subCategoryFitter);
        url = url + "&sid=" + JSON.stringify(subCategoryFitter);
      }
      if (levelFilterData.length > 0) {
        navigateUrl = navigateUrl + "&level=" + JSON.stringify(levelFilterData);
        url = url + "&level=" + JSON.stringify(levelFilterData);
      }
      if (topicFilterData.length > 0) {
        navigateUrl =
          navigateUrl + "&topics=" + JSON.stringify(topicFilterData);
        url = url + "&topics=" + JSON.stringify(topicFilterData);
      }
      if (priceRange.length > 0) {
        navigateUrl = navigateUrl + "&priceRange=" + JSON.stringify(priceRange);
        url = url + "&priceRange=" + JSON.stringify(priceRange);
      }

      if (priceFitter.length > 0) {
        navigateUrl = navigateUrl + "&type=" + JSON.stringify(priceFitter);
        url = url + "&type=" + JSON.stringify(priceFitter);
      }
      if (durationFitter.length > 0) {
        navigateUrl =
          navigateUrl + "&duration=" + JSON.stringify(durationFitter);
        url = url + "&duration=" + JSON.stringify(durationFitter);
      }

      if (ratingFitter.length > 0) {
        navigateUrl = navigateUrl + "&ratings=" + JSON.stringify(ratingFitter);
        url = url + "&ratings=" + JSON.stringify(ratingFitter);
      }
      if (sorting) {
        navigateUrl = navigateUrl + sorting;
        url = url + sorting;
      }
      if (currentActivePage) {
        navigateUrl = navigateUrl + "&page=" + currentActivePage;
        url = url + "&page=" + currentActivePage;
      }
    }
    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const removeFilter = (item) => {
    if (item.filterType === "subcategory") {
      handleCheckedSubcategory(
        item.CategoryId,
        parseInt(item.id.split("-")[1]),
        item.name
      );
    } else if (item.filterType === "topic") {
      handleTopicsFiltter(item.name, parseInt(item.id.split("-")[1]));
    } else if (item.filterType === "price") {
      handleCoursePriceType(item.name);
    } else if (item.filterType === "level") {
      handleCourseLevel(item.name);
    } else if (item.filterType === "duration") {
      handleDuration(item.min, item.max);
    } else if (item.filterType === "rating") {
      handleCourseRating(item.name.split(" ")[0]);
    } else if (item.filterType === "priceRange") {
      let value = {
        max: item.max,
        min: item.min,
      };
      handlePrice(value);
    }
  };

  const handleOnSearch = async (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string) {
      try {
        let resp = await FetchApi(
          Endpoints.SearchCourse + "?keyword=" + string
        );
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item) => {
              return {
                id: item?.type === "topic" ? "topic" : item.id,
                name:
                  item?.type === "course"
                    ? item.title
                    : item?.type === "topic"
                    ? item.topic.find((ite) =>
                        ite.toLowerCase().includes(string.toLowerCase())
                      )
                    : item.name,
                image:
                  item.type === "course"
                    ? Endpoints.baseUrl + item.thumbnail
                    : "../assets/img/search_gray.png",
                type: item.type,
                slug: item.slug,
                string: string,
              };
            });

            setOption(newData);
          } else {
            let data = {
              id: null,
              name: "Result not found",
              image: "../assets/img/search_gray.png",
              type: null,
              slug: null,
              string: string,
            };
            let newData = [];
            newData.push(data);
            setOption(newData);
          }
        } else {
          let data = {
            id: null,
            name: "Result not found",
            image: "../assets/img/search_gray.png",
            type: null,
            string: string,
          };
          let newData = [];
          newData.push(data);
          setOption(newData);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleOnSelect = (item) => {
    if (item[0]?.type === "subcategory") {
      let data = [];
      data.push(item[0]?.id);
      navigate("/course-listing?sid=" + JSON.stringify(data));
      handleCourseFilterData(
        Endpoints.courseListing + "?sid=" + JSON.stringify(data)
      );
    }
    if (item[0]?.type === "category") {
      let data = [];
      data.push(item[0]?.id);
      navigate("/course-listing?cid=" + JSON.stringify(data));
      handleCourseFilterData(
        Endpoints.courseListing + "?cid=" + JSON.stringify(data)
      );
    }
    if (item[0]?.type == "course") {
      console.log("item_________item_____item", item);
      navigate("/course-detail/" + item[0]?.slug);
    }

    if (item[0]?.type === "topic") {
      let data = [];
      data.push(item[0]?.name);
      navigate("/course-listing?topics=" + JSON.stringify(data));
      handleCourseFilterData(
        Endpoints.courseListing + "?topics=" + JSON.stringify(data)
      );
    }
  };

  const RenderStudyArea = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-couse-listing"
        onSearch={handleOnSearch}
        onChange={handleOnSelect}
        options={option}
        labelKey="string"
        minLength={1}
        emptyLabel={"Searching..."}
        placeholder="Search for courses"
        className="bg-transparent fs-14 medium  text-darkgray"
        renderMenuItemChildren={(option, props) => (
          <div
            key={option.id}
            style={{
              width: "600px",
            }}
          >
            <img
              alt={option.name}
              src={option.image}
              style={{
                height: "30px",
                marginRight: "10px",
                width: "30px",
              }}
            />
            <span>{option.name}</span>
          </div>
        )}
        useCache={false}
      />
    );
  }, [option]);

  return (
    <Container header>
      <Helmet>
        <title>Courses</title>
      </Helmet>
      <div class="wrapper wrapper-bg">
        <div class="main">
          <LeftNavigation />
          <div class="container py-3">
            <div class="row">
              <div class="col-lg-3 col-md-4 course-listing-sidebar">
                <div class="bg-white rounded-15 mb-3 overflow-hidden">
                  <h5 class="medium border-bottom mx-3 pt-3 pb-2 mb-3 fs-18">
                    Categories
                  </h5>

                  <div class="accordion" id="categories">
                    {categoryData.map((item, index) => {
                      return (
                        <div
                          class={
                            categoryData.length < index + 1
                              ? "border-bottom mb-2"
                              : "mb-2"
                          }
                          key={index}
                        >
                          <h5
                            class={
                              parseInt(categoryFitter) === parseInt(item.id)
                                ? "accordion-button pt-0 pb-2 px-3  text-gray medium"
                                : "accordion-button px-3  collapsed pt-0 pb-2 text-gray medium"
                            }
                            data-bs-toggle="collapse"
                            data-bs-target={"#" + item.name}
                            aria-expanded={
                              parseInt(categoryFitter) === parseInt(item.id)
                                ? true
                                : false
                            }
                            aria-controls={item.name}
                            onClick={() => {
                              handleChangeCategory(item.id, item.name);
                            }}
                          >
                            <img
                              src="../assets/img/icon/icon-cpu-ic.svg"
                              alt=""
                              class="pe-2"
                            />{" "}
                            {item.name}
                          </h5>
                          <div
                            id={item.name}
                            class={
                              parseInt(categoryFitter) === parseInt(item.id)
                                ? "accordion-collapse collapse show"
                                : "accordion-collapse collapse"
                            }
                            aria-labelledby={item.name}
                            data-bs-parent="#categories"
                          >
                            {item.course_subcategories.length > 0 ? (
                              <div class="px-3 pt-3 pb-1 ">
                                {item.course_subcategories.map((ite, idx) => {
                                  return (
                                    <>
                                      {ite.totalCourses > 0 ? (
                                        <div
                                          class="d-flex align-items-center gap-2 pb-2 mb-1"
                                          key={idx}
                                        >
                                          <input
                                            type="checkbox"
                                            id={"tools" + idx}
                                            onChange={() => {
                                              handleCheckedSubcategory(
                                                item.id,
                                                ite.id,
                                                ite.name
                                              );
                                            }}
                                            checked={
                                              subCategoryFitter.filter(
                                                (subcategoryItem) =>
                                                  parseInt(subcategoryItem) ===
                                                  parseInt(ite.id)
                                              ).length === 1
                                                ? true
                                                : false
                                            }
                                          />

                                          <label
                                            for={"tools" + idx}
                                            class="w-100 d-flex align-items-center justify-content-between text-darkgray fs-14"
                                          >
                                            {ite.name}{" "}
                                            <span>({ite.totalCourses})</span>
                                          </label>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div class="bg-white rounded-15 p-3 mb-3">
                  <h5 class="medium border-bottom pb-2 mb-3 fs-18">Topics</h5>

                  <div>
                    {topicData.map((item, index) => {
                      return (
                        <>
                          {item.course_topics.map((ite, ind) => {
                            return (
                              <>
                                {ite.totalCourses > 0 ? (
                                  <div
                                    class="d-flex align-items-center gap-2 pb-2 mb-1"
                                    key={ind}
                                  >
                                    <input
                                      type="checkbox"
                                      id={ite.courseTopic + parseInt(ind + 1)}
                                      checked={
                                        topicFilterData.filter(
                                          (topicItem) =>
                                            topicItem === ite.courseTopic
                                        ).length === 1
                                          ? true
                                          : false
                                      }
                                      onChange={() => {
                                        handleTopicsFiltter(
                                          ite.courseTopic,
                                          ite.id
                                        );
                                      }}
                                    />
                                    <label
                                      for={ite.courseTopic + parseInt(ind + 1)}
                                      class="w-100 d-flex align-items-center justify-content-between text-darkgray fs-14"
                                    >
                                      {ite.courseTopic}
                                      <span>({ite.totalCourses})</span>
                                    </label>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                  </div>
                </div>

                <div class="bg-white rounded-15 p-3 mb-3">
                  <h5 class="medium border-bottom pb-2 mb-3 fs-18">Level</h5>

                  <div>
                    {levelData.beginner > 0 ? (
                      <div class="d-flex align-items-center gap-2 pb-2 mb-1">
                        <input
                          type="checkbox"
                          id="level1"
                          checked={
                            levelFilterData.filter(
                              (item) => item === "beginner"
                            ).length === 1
                              ? true
                              : false
                          }
                          onChange={(e) => handleCourseLevel("beginner")}
                        />
                        <label
                          for="level1"
                          class="w-100 d-flex align-items-center justify-content-between text-darkgray fs-14"
                        >
                          Beginner <span>{levelData.beginner}</span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}

                    {levelData.intermediate > 0 ? (
                      <div class="d-flex align-items-center gap-2 pb-2 mb-1">
                        <input
                          type="checkbox"
                          id="level2"
                          checked={
                            levelFilterData.filter(
                              (item) => item === "intermediate"
                            ).length === 1
                              ? true
                              : false
                          }
                          onChange={(e) => handleCourseLevel("intermediate")}
                        />
                        <label
                          for="level2"
                          class="w-100 d-flex align-items-center justify-content-between text-darkgray fs-14"
                        >
                          Intermediate <span>{levelData.intermediate}</span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    {levelData.expert > 0 ? (
                      <div class="d-flex align-items-center gap-2 pb-2 mb-1">
                        <input
                          type="checkbox"
                          id="level3"
                          checked={
                            levelFilterData.filter((item) => item === "expert")
                              .length === 1
                              ? true
                              : false
                          }
                          onChange={(e) => handleCourseLevel("expert")}
                        />
                        <label
                          for="level3"
                          class="w-100 d-flex align-items-center justify-content-between text-darkgray fs-14"
                        >
                          Expert <span>{levelData.expert}</span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div class="bg-white rounded-15 p-3 mb-3">
                  <h5 class="medium border-bottom pb-2 mb-3 fs-18">Price</h5>

                  <div class="">
                    {courseTypeData.paid > 0 ? (
                      <div class="d-flex align-items-center gap-2 pb-2 mb-1">
                        <input
                          type="checkbox"
                          id="price1"
                          checked={
                            priceFitter.filter((item) => item === "Paid")
                              .length === 1
                              ? true
                              : false
                          }
                          onChange={() => {
                            handleCoursePriceType("Paid");
                          }}
                        />
                        <label
                          for="price1"
                          class="w-100 d-flex align-items-center justify-content-between text-darkgray fs-14"
                        >
                          Paid <span>{courseTypeData.paid}</span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    {courseTypeData.free > 0 ? (
                      <div class="d-flex align-items-center gap-2 pb-2 mb-1">
                        <input
                          type="checkbox"
                          id="price2"
                          checked={
                            priceFitter.filter((item) => item === "Free")
                              .length === 1
                              ? true
                              : false
                          }
                          onChange={() => {
                            handleCoursePriceType("Free");
                          }}
                        />
                        <label
                          for="price2"
                          class="w-100 d-flex align-items-center justify-content-between text-darkgray fs-14"
                        >
                          Free <span>{courseTypeData.free}</span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    {priceRangeStatus === true
                      ? ""
                      : priceFitterData.map((item, index) => {
                          return (
                            <>
                              {item.courseCount > 0 ? (
                                <div
                                  class="d-flex align-items-center gap-2 pb-2 mb-1"
                                  key={index}
                                >
                                  <input
                                    type="checkbox"
                                    id={"price_" + parseInt(index + 2)}
                                    // checked={priceRange.filter((ite)=> ite === `${item.min}-${item.max}`).length === 1 ? true :false}
                                    name={`${item.min}-${item.max}`}
                                    checked={
                                      priceRange.filter(
                                        (ite) =>
                                          ite === `${item.min}-${item.max}`
                                      ).length === 1
                                        ? true
                                        : false
                                    }
                                    onChange={() => {
                                      handlePrice(item);
                                    }}
                                  />
                                  <label
                                    for={"price_" + parseInt(index + 2)}
                                    class="w-100 d-flex align-items-center justify-content-between text-darkgray fs-14"
                                  >
                                    Rs. {item.min} - Rs. {item.max}{" "}
                                    <span>{item.courseCount}</span>
                                  </label>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                  </div>
                </div>

                <div class="bg-white rounded-15 p-3 mb-3">
                  <h5 class="medium border-bottom pb-2 mb-3 fs-18">Rating</h5>

                  <div>
                    <div class="d-flex align-items-center gap-2 pb-2 mb-1">
                      <input
                        type="checkbox"
                        id="rating1"
                        checked={
                          ratingFitter.filter((item) => item === 1).length === 1
                            ? true
                            : false
                        }
                        onChange={() => {
                          handleCourseRating(1);
                        }}
                      />
                      <label
                        for="rating1"
                        class="w-100 d-flex align-items-center justify-content-between text-darkgray fs-14"
                      >
                        <span>
                          <img src="../assets/img/icon/State-fill.svg" alt="" />{" "}
                          1 Stars & up{" "}
                        </span>
                        <span>({Rating.oneAndUp})</span>
                      </label>
                    </div>

                    <div class="d-flex align-items-center gap-2 pb-2 mb-1">
                      <input
                        type="checkbox"
                        id="rating2"
                        checked={
                          ratingFitter.filter((item) => item === 2).length === 1
                            ? true
                            : false
                        }
                        onChange={() => {
                          handleCourseRating(2);
                        }}
                      />
                      <label
                        for="rating2"
                        class="w-100 d-flex align-items-center justify-content-between text-darkgray fs-14"
                      >
                        <span>
                          <img src="../assets/img/icon/State-fill.svg" alt="" />{" "}
                          2 Stars & up{" "}
                        </span>
                        <span>({Rating.twoAndUp})</span>
                      </label>
                    </div>

                    <div class="d-flex align-items-center gap-2 pb-2 mb-1">
                      <input
                        type="checkbox"
                        id="rating3"
                        checked={
                          ratingFitter.filter((item) => item === 3).length === 1
                            ? true
                            : false
                        }
                        onChange={() => {
                          handleCourseRating(3);
                        }}
                      />
                      <label
                        for="rating3"
                        class="w-100 d-flex align-items-center justify-content-between text-darkgray fs-14"
                      >
                        <span>
                          <img src="../assets/img/icon/State-fill.svg" alt="" />{" "}
                          3 Stars & up{" "}
                        </span>
                        <span>({Rating.threeAndUp})</span>
                      </label>
                    </div>

                    <div class="d-flex align-items-center gap-2 pb-2 mb-1">
                      <input
                        type="checkbox"
                        id="rating4"
                        checked={
                          ratingFitter.filter((item) => item === 4).length === 1
                            ? true
                            : false
                        }
                        onChange={() => {
                          handleCourseRating(4);
                        }}
                      />
                      <label
                        for="rating4"
                        class="w-100 d-flex align-items-center justify-content-between text-darkgray fs-14"
                      >
                        <span>
                          <img src="../assets/img/icon/State-fill.svg" alt="" />{" "}
                          4 Stars & ups{" "}
                        </span>
                        <span>({Rating.fourAndUp})</span>
                      </label>
                    </div>

                    <div class="d-flex align-items-center gap-2 pb-2 mb-1">
                      <input
                        type="checkbox"
                        id="rating5"
                        checked={
                          ratingFitter.filter((item) => item === 5).length === 1
                            ? true
                            : false
                        }
                        onChange={() => {
                          handleCourseRating(5);
                        }}
                      />
                      <label
                        for="rating5"
                        class="w-100 d-flex align-items-center justify-content-between text-darkgray fs-14"
                      >
                        <span>
                          <img src="../assets/img/icon/State-fill.svg" alt="" />{" "}
                          5 Stars & up{" "}
                        </span>
                        <span>({Rating.fiveAndUp})</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="bg-white rounded-15 p-3 mb-3">
                  <h5 class="medium border-bottom pb-2 mb-3 fs-18">
                    Video Duration
                  </h5>

                  <div>
                    {durationData.map((item, index) => {
                      return (
                        <>
                          {item.totalCourses > 0 ? (
                            <div class="d-flex align-items-center gap-2 pb-2 mb-1">
                              <input
                                type="checkbox"
                                name={`${item.min}-${item.max}`}
                                checked={
                                  durationFitter.filter(
                                    (ite) => ite === `${item.min}-${item.max}`
                                  ).length === 1
                                    ? true
                                    : false
                                }
                                id={"duration" + index}
                                onChange={(e) => {
                                  handleDuration(item.min, item.max);
                                }}
                              />
                              <label
                                for={"duration" + index}
                                class="w-100 d-flex align-items-center justify-content-between text-darkgray fs-14"
                              >
                                {item.min}
                                {item.max === null ? "+" : "-"}
                                {item.max} Hours{" "}
                                <span>({item.totalCourses})</span>
                              </label>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              {loading ? (
                <div class="col-lg-9 col-md-8">
                  <div class="rounded-15 bg-white p-md-3 p-2">
                    <div class="spinner-border text-primary" role="status">
                      {/* <span class="sr-only">Loading...</span> */}
                    </div>
                  </div>
                </div>
              ) : (
                <div class="col-lg-9 col-md-8">
                  <div class="rounded-15 bg-white p-md-3 p-2">
                    <div class="main-top-row row">
                      <div class="col-lg-2 col-md-3 px-0">
                        <button type="button" class="btn fs-14 py-2">
                          <img
                            src="../assets/img/icon/icon-filter-white.svg"
                            alt=""
                          />{" "}
                          Filters{" "}
                          <span class="filter-count">{totalFitter.length}</span>
                        </button>
                      </div>

                      <div class="col-lg-10 col-md-9">
                        <div class=" d-sm-flex w-100 justify-content-between gap-3 pb-2">
                          <form
                            action=""
                            style={{ flex: "2" }}
                            class="mainmenu-page-search d-flex flex-row-reverse align-items-center col-lg-6 justify-content-end"
                          >
                            {/* <input type="text" placeholder="Search for courses" name="search" class="bg-transparent fs-14 medium  text-darkgray" /> */}
                            {RenderStudyArea()}
                            <button
                              type="submit"
                              class="bg-transparent border-0"
                            >
                              <svg
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                stroke="#949494"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                class="css-i6dzq1"
                              >
                                <circle cx="11" cy="11" r="8"></circle>
                                <line
                                  x1="21"
                                  y1="21"
                                  x2="16.65"
                                  y2="16.65"
                                ></line>
                              </svg>
                            </button>
                          </form>
                          <select
                            name="sortby"
                            id="sortby"
                            onChange={(e) => {
                              handleSorting(e.target.value);
                            }}
                            class="w-auto text-darkgray"
                          >
                            <option value="">Sort by</option>
                            <option value="MostPopular">Most Popular</option>
                            <option value="HighestRated">Highest Rated</option>
                            {/* <option value="BestSeller">Best Seller</option> */}
                            <option value="Newest">Newest</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="d-md-flex align-items-center justify-content-between pb-4">
                      <div class="d-sm-flex gap-3">
                        {totalFitter.map((item, index) => {
                          return (
                            <span class="fs-12 text-blue regular" key={index}>
                              {item.name}
                              &nbsp;
                              <a
                                onClick={() => {
                                  removeFilter(item);
                                }}
                              >
                                <img
                                  src="../assets/img/icon/wrong.svg"
                                  alt=""
                                  width="10"
                                />
                              </a>
                            </span>
                          );
                        })}
                      </div>

                      <div>
                        <span class="fs-12  text-gray">
                          <span class="text-blue medium">{totalCourse}</span>{" "}
                          results found
                        </span>
                      </div>
                    </div>

                    {courseData.length > 0 ? (
                      <div class="row">
                        {courseData.map((item, index) => {
                          return (
                            <Item
                              {...{
                                item: item,
                                index: index,
                                onSuccess: courseListing,
                              }}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <div>
                        <h6>No course Found</h6>
                      </div>
                    )}
                    {courseData.length > 0 ? (
                      <div class="text-center pagination fs-14 d-flex gap-3 regular align-items-center justify-content-center pb-4">
                        {activePage > 1 ? (
                          <span
                            class="next-btn"
                            onClick={() => {
                              handlePagination(parseInt(activePage - 1));
                            }}
                          >
                            <img
                              src="../assets/img/icon/icon-arrow-left.svg"
                              alt=""
                            />
                          </span>
                        ) : (
                          <span class="prev-btn">
                            <img
                              src="../assets/img/icon/icon-arrow-left.svg"
                              alt=""
                            />
                          </span>
                        )}

                        {page}

                        {activePage >= totalPage ? (
                          <span class="prev-btn">
                            <img
                              src="../assets/img/icon/icon-arrow-right.svg"
                              alt=""
                            />
                          </span>
                        ) : (
                          <span
                            class="next-btn"
                            onClick={() => {
                              handlePagination(
                                parseInt(parseInt(activePage) + 1)
                              );
                            }}
                          >
                            <img
                              src="../assets/img/icon/icon-arrow-right.svg"
                              alt=""
                            />
                          </span>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
