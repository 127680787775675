import React, { useEffect, useState } from 'react';
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import ProfileNavigation from '../../Components/ProfileNavigation';
import Interests from "./Interests";
import Academics from "./Academics";
import Profile from "./Profile";
import Experience from './Experience';
import ProfileIntro from './ProfileIntro';
import Gratitudes from './Gratitudes';
import { Helmet } from "react-helmet";
import { FetchApi } from '../../API/FetchApi';
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { useNavigate } from 'react-router-dom';
export default function ProfileAbout() {
    const [profileTagline, setProfileTagline] = useState('');
    const [academic, setAcademics] = useState([]);
    const [experience, setExperience] = useState([]);
    const userRoleType = localStorage.getItem("userRoleType");
    const parentData = JSON.parse(localStorage.getItem("parentData"));
    const Navigate = useNavigate();
    useEffect(()=>{
        if(userRoleType == "child"){
            getacademics();
            getExperience();
        }
    
    }, [userRoleType]);

    const getExperience = async (e) => {
        try {
            let resp = await FetchApi(Endpoints.GetExperience + '?id='+ parentData?.username);
            if (resp && resp.success === true) {
                setExperience(resp?.data);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };


    const getacademics = async (e) => {
        try {
            let resp = await FetchApi(Endpoints.Getacademics+ '?id='+parentData?.username);
            if (resp && resp.success === true) {
                setAcademics(resp?.data);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };

    return (
        <Container
            header
        >
            <Helmet>
                <title>Profile About</title>
            </Helmet>
            <div className="wrapper wrapper-bg">

                <div className="main">

                    <LeftNavigation />
                    <div className="container py-lg-3 py-3">

                        <div className="profile-banner-top mb-3  rounded-15 overflow-hidden">
                            <Profile  {...{
                                profileTagline: profileTagline
                            }} />
                            <ProfileNavigation />

                        </div>

                        <div className="row">
                            <div className="col-md-3 profile-dp-left pb-3 px-md-0">

                                <ProfileIntro {...{
                                    type: "about",
                                    setProfileTagline: setProfileTagline
                                }} />


                                {userRoleType === "child" ?
                                    <div className="sidebar-widget box-shadow mt-3 profile-text">
                                        <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-3">
                                            <h4 className="medium ">Parents Detail</h4>
                                            {/* <a href="#" className="text-brown fs-12">Edit <img src="../assets/img/icon/edit-profile.svg" alt="" width="12" /></a> */}
                                        </div>

                                        {parentData?.user_profile?.gender == "Male" ?
                                            <h6 className="medium text-black fs-16 pb-3">Father:</h6>
                                            :
                                            ""
                                        }
                                        {parentData?.user_profile?.gender == "Female" ?
                                            <h6 className="medium text-black fs-16 pb-3">Mother:</h6>
                                            :
                                            ""
                                        }


                                        <div className="d-flex align-items-start gap-2 pb-3">
                                            <img src="../assets/img/icon/icon-user.svg" alt="" />
                                            <div>
                                                <span className="fs-14 medium text-brown d-block">Full Name</span>
                                                <p className="m-0 fs-14">{parentData?.fullName}</p>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-start gap-2 pb-3">
                                            <img src="../assets/img/icon/icon-qualification.svg" alt="" />
                                            <div>
                                                <span className="fs-14 medium text-brown d-block">Qualification</span>
                                                <p className="m-0 fs-14">{academic?.at(0)?.study_area?.name}</p>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-start gap-2 ">
                                            <img src="../assets/img/icon/icon-designation.svg" alt="" />
                                            <div>
                                                <span className="fs-14 medium text-brown d-block">Designation</span>
                                                <p className="m-0 fs-14">{experience?.at(0)?.designation?.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}


                            </div>

                            <div className="col-md-9 px-md-3">

                                <Academics />
                                <Experience />
                                <Interests page={"profile"} />
                                <Gratitudes />
                            </div>
                        </div>
                        <OnlineFriends />
                    </div>
                </div>
            </div>
        </Container>
    )
}
