import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function Topbar() {
  const location = useLocation();
  return (
    <ul className="teacher-course-menu d-flex">
      <li
        className={
          location.pathname.split("/")[1] === "add-courses-basic-information" ||
          location.pathname.split("/")[1] === "update-courses-basic-information"
            ? "active"
            : location.pathname.split("/")[1] ===
                "add-courses-advance-information" ||
              location.pathname.split("/")[1] === "add-courses-curriculum" ||
              location.pathname.split("/")[1] === "add-courses-publish"
            ? "completed"
            : ""
        }
      >
        <Link to="#" className="d-flex align-items-center w-100">
          <img src="../assets/img/icon/icon-basic-info.svg" className="pe-2" />
          Basic Information
          {location.pathname.split("/")[1] ===
            "add-courses-advance-information" ||
          location.pathname.split("/")[1] === "add-courses-curriculum" ||
          location.pathname.split("/")[1] === "add-courses-publish" ? (
            <img
              src="../assets/img/icon/icon-check-round-blue.svg"
              className="ms-auto"
              alt=""
            />
          ) : (
            ""
          )}
        </Link>
      </li>

      <li
        className={
          location.pathname.split("/")[1] === "add-courses-advance-information"
            ? "active"
            : location.pathname.split("/")[1] === "add-courses-curriculum" ||
              location.pathname.split("/")[1] === "add-courses-publish"
            ? "completed"
            : ""
        }
      >
        <Link to="#" className="d-flex align-items-center w-100">
          {location.pathname.split("/")[1] ===
            "add-courses-advance-information" ||
          location.pathname.split("/")[1] === "add-courses-curriculum" ||
          location.pathname.split("/")[1] === "add-courses-publish" ? (
            <>
              <img
                src="../assets/img/icon/icon-advance-info-blue.svg"
                className="pe-2"
              />
              Advance Information
            </>
          ) : (
            <>
              <img
                src="../assets/img/icon/icon-advance-info.svg"
                className="pe-2"
              />
              Advance Information
            </>
          )}

          {/* <img src="../assets/img/icon/icon-check-round-blue.svg" className="ms-auto" alt="" /> */}
          {location.pathname.split("/")[1] === "add-courses-curriculum" ||
          location.pathname.split("/")[1] === "add-courses-publish" ? (
            <img
              src="../assets/img/icon/icon-check-round-blue.svg"
              className="ms-auto"
              alt=""
            />
          ) : (
            ""
          )}
        </Link>
      </li>

      <li
        className={
          location.pathname.split("/")[1] === "add-courses-curriculum"
            ? "active"
            : location.pathname.split("/")[1] === "add-courses-publish"
            ? "completed"
            : ""
        }
      >
        <Link to="#" className="d-flex align-items-center w-100">
          {location.pathname.split("/")[1] === "add-courses-curriculum" ||
          location.pathname.split("/")[1] === "add-courses-publish" ? (
            <img src="../assets/img/icon/computer-blue.svg" className="pe-2" />
          ) : (
            <img src="../assets/img/icon/computer.svg" className="pe-2" />
          )}
          Curriculum
          {location.pathname.split("/")[1] === "add-courses-publish" ? (
            <img
              src="../assets/img/icon/icon-check-round-blue.svg"
              className="ms-auto"
              alt=""
            />
          ) : (
            ""
          )}
        </Link>
      </li>

      <li
        className={
          location.pathname.split("/")[1] === "add-courses-publish"
            ? "active"
            : ""
        }
      >
        <Link to="#">
          {location.pathname.split("/")[1] === "add-courses-publish" ? (
            <img
              src="../assets/img/icon/icon-video-online-blue.svg"
              className="pe-2"
            />
          ) : (
            <img
              src="../assets/img/icon/icon-video-online.svg"
              className="pe-2"
            />
          )}
          Publish Course
        </Link>
      </li>
    </ul>
  );
}
