import React, { useEffect, useState } from "react";
import { Endpoints } from "../API/Endpoints";
import ToastMessage from "../Utils/ToastMessage";
import { CommonAPi } from "../API/CommonApi";
import { FetchApi } from "../API/FetchApi";
import { Link, useNavigate, useParams } from "react-router-dom";
const $ = window.jQuery;
export default function FriendsSpecificModal({
  setShowModal,
  setPostVisibility,
  setSpecificFrined,
  specificFrined,
}) {
  const [friend, setFriendData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [error, setError] = useState("");
  const Navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    getFriend();
  }, []);
  const getFriend = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.GetAllFriend + "?size=" + limit + "&id=" + userId + "&page=1"
      );
      if (resp && resp.success === true) {
        setFriendData(resp?.data?.friends);
        setCount(resp?.data?.friendsCount);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSearchFriend = async (value) => {
    try {
      let resp = await FetchApi(
        Endpoints.GetAllFriend +
          "?size=" +
          limit +
          value +
          "&id=" +
          userId +
          "&page=1"
      );
      if (resp && resp.success === true) {
        setFriendData(resp?.data?.friends);
        setCount(resp?.data?.friendsCount);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleLoadMore = async () => {
    let newLimit = limit + 10;
    setLimit(newLimit);

    try {
      let resp = await FetchApi(
        Endpoints.GetAllFriend +
          "?size=" +
          newLimit +
          "&id=" +
          userId +
          "&page=1"
      );
      if (resp && resp.success === true) {
        setFriendData(resp?.data?.friends);
        setCount(resp?.data?.friendsCount);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleCloseModel = () => {
    setShowModal(true);
    setPostVisibility("Friends");
  };

  const handleSelectFriend = (e, id) => {
    if (e.target.checked) {
      const newData = [...specificFrined, id];

      setSpecificFrined(newData);
      // setSpecificFrined((previous)=>{
      //   [...previous, id]
      // })
    } else {
      if (specificFrined.filter((item) => item === id).length > 0) {
        const newData = specificFrined.filter((item) => item !== id);
        setSpecificFrined(newData);
      }
    }
  };

  const handleSave = () => {
    if (specificFrined.length > 0) {
      $("#FriendsSpecificModal").modal("hide");
      setShowModal(true);
    } else {
      setError("Please select the friend");
    }
  };
  return (
    <div
      className="modal fade"
      id="FriendsSpecificModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Friends Specific
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleCloseModel}
            ></button>
          </div>
          <div className="modal-body">
            <input
              type={"text"}
              className="bg-transparent fs-14 medium  text-darkgray border form-control"
              onChange={(e) => {
                handleSearchFriend("&term=" + e.target.value);
              }}
              placeholder="Search Friend"
            />
            {error ? <p className="mt-3 text-danger">{error}</p> : ""}

            {friend.map((item, index) => {
              return (
                <div
                  className="d-flex justify-content-between mt-3"
                  key={index}
                >
                  <div>{item?.fullName}</div>
                  <div>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        handleSelectFriend(e, item?.id);
                      }}
                    />
                  </div>
                </div>
              );
            })}
            {limit < count ? (
              <button
                onClick={() => {
                  handleLoadMore();
                }}
                className="btn w-100 bg-d8d8d8 text-black fs-14 medium"
              >
                Lord more
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={handleCloseModel}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
