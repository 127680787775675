import React, { useState, useEffect, useRef } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import ProfileNavigation from "../../Components/ProfileNavigation";
import PostShare from "../../Components/PostShare/PostShare";
import Profile from "../ProfileAbout/Profile";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import Post from "../../Components/Post/Post";
import BlogCard from "../../Components/BlogCard/BlogCard";
import PostLoader from "../../Components/PostLoader/PostLoader";
import { Link, useNavigate, useParams } from "react-router-dom";
import SharedPost from "../../Components/SharedPost/SharedPost";
import ToastMessage from "../../Utils/ToastMessage";
import SharedBlogCard from "../../Components/SharedBlogCard/SharedBlogCard";
import SharedMedia from "../../Components/SharedMedia/SharedMedia";
import "./timeline.css";
import ProfileIntro from "../ProfileAbout/ProfileIntro";
import Photo from "./Photo";
import Video from "./Video";
import { Helmet } from "react-helmet";

function ProfileTimeline() {
  const param = useParams();
  const [timelinePosts, setTimelinePosts] = useState([]);
  const [mouserHovering, setMouserHovering] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [postPage, setPostPage] = useState(1);
  const [profileTagline, setProfileTagline] = useState("");
  const [statistics, setStatistics] = useState("");
  const [ActivityFeed, setActivityFeed] = useState([]);
  const userId = localStorage.getItem("userId");
  const username = localStorage.getItem("userName");
  const listInnerRef = useRef();
  const history = useNavigate();
  const handleScroll = (e) => {
    if (loading) return;
    const scrollTop = listInnerRef.current.scrollTop;
    const offsetHeight = listInnerRef.current.offsetHeight;
    const scrollHeight = listInnerRef.current.scrollHeight;
    const remaining = scrollHeight - offsetHeight - scrollTop;

    if (remaining < 100) {
      setLoading(true);
      fetchNext();
    }
  };
  const fetchNext = () => {
    setTimeout(() => {
      setPostPage(postPage + 1);
    }, 100);
  };

  useEffect(() => {
    getUserPosts();
  }, [postPage]);

  const getUserPosts = async (createPage = null) => {
    if (!hasMorePosts) return;
    try {
      let resp = await CommonAPi(
        Endpoints.GetUserPosts + `?page=${postPage}` + "&id=" + param?.id
      );
      if (resp && resp.success === true) {
        if (resp.data.length === 0) {
          setLoading(false);
          setHasMorePosts(false);
        } else {
          if (createPage == null) {
            setTimelinePosts([...timelinePosts, ...resp.data]);
          } else {
            setTimelinePosts([...resp.data]);
          }
        }
        console.log(timelinePosts);
        setLoading(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  useEffect(() => {
    getStatistics();
    getActivity();
  }, []);
  useEffect(() => {
    getUserAllPosts();
    getStatistics();
    getActivity();
  }, [param?.id]);

  const getStatistics = async () => {
    try {
      let resp = await CommonAPi(
        Endpoints.ProfileStatistics + "?userId=" + param?.id
      );
      if (resp && resp.success === true) {
        setStatistics(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const getActivity = async () => {
    try {
      let resp = await CommonAPi(
        Endpoints.FeedActivity + "?userId=" + param?.id
      );
      if (resp && resp.success === true) {
        setActivityFeed(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getUserAllPosts = async (createPage = null) => {
    if (!hasMorePosts) return;
    setTimelinePosts([]);
    try {
      let resp = await CommonAPi(
        Endpoints.GetUserPosts + `?page=${postPage}` + "&id=" + param?.id
      );
      if (resp && resp.success === true) {
        if (resp.data.length === 0) {
          setLoading(false);
          setHasMorePosts(false);
        } else {
          setTimelinePosts(resp.data);
        }
        setLoading(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const showReactionsBox = () => {
    setMouserHovering(true);
  };
  const hideReactionsBox = () => {
    setTimeout(() => {
      setMouserHovering(false);
    }, 1400);
  };
  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "y";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + "d";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + "h";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min";
    return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec" : "0 sec";
  };
  function formatDateTime(postDate) {
    let date = new Date(postDate);
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    let formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }

  const onEditSuccess = (editedPost) => {
    console.log(timelinePosts);
    console.log(editedPost);
    const updatedTimlinePost = timelinePosts.map((post) => {
      if (post.id === editedPost.id) {
        return editedPost;
      }
      return post;
    });

    setTimelinePosts(updatedTimlinePost);
    console.log(updatedTimlinePost);
  };

  return (
    <Container header>
      <Helmet>
        <title>Timeline</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main" onScroll={handleScroll} ref={listInnerRef}>
          <LeftNavigation />

          <div className="container py-lg-3 py-3">
            <div className="profile-banner-top mb-3  rounded-15 overflow-hidden">
              <Profile
                {...{
                  profileTagline: profileTagline,
                }}
              />
              <ProfileNavigation />
            </div>

            <div className="row">
              <div className="col-md-3 profile-dp-left pb-3">
                <div className="sidebar-widget box-shadow mb-3">
                  <div className="pb-3 d-flex align-items-start">
                    <span>
                      <img
                        src="../assets/img/icon/icon-emplyee.svg"
                        alt=""
                        width="36"
                        height="36"
                      />
                    </span>
                    <div className="ps-2">
                      <h4 className="medium">{statistics?.connection}</h4>
                      <h6 className="fs-12 text-darkgray regular">
                        Connections
                      </h6>
                    </div>
                  </div>

                  <div className="pb-3 d-flex align-items-start">
                    <span>
                      <img
                        src="../assets/img/icon/icon-jobs-created.svg"
                        alt=""
                        width="36"
                        height="36"
                      />
                    </span>
                    <div className="ps-2">
                      <h4 className="medium">{statistics?.hornor}</h4>
                      <h6 className="fs-12 text-darkgray regular">Honors</h6>
                    </div>
                  </div>

                  <div className="d-flex align-items-start">
                    <span>
                      <img
                        src="../assets/img/icon/icon-followers.svg"
                        alt=""
                        width="36"
                        height="36"
                      />
                    </span>
                    <div className="ps-2">
                      <h4 className="medium">{statistics?.awards}</h4>
                      <h6 className="fs-12 text-darkgray regular">Awards</h6>
                    </div>
                  </div>
                </div>

                <ProfileIntro
                  {...{
                    type: "timeline",
                    setProfileTagline: setProfileTagline,
                  }}
                />

                {ActivityFeed.length > 0 && (
                  <div className="sidebar-widget box-shadow mt-3 profile-text">
                    <div className="border-bottom pb-2 mb-3">
                      <h4 className="medium ">Activity Feed</h4>
                    </div>
                    {ActivityFeed.map((item, index) => {
                      return (
                        <div
                          className="d-flex align-items-start gap-2 pb-3"
                          key={index}
                        >
                          <div className="circular--landscape pic-size-48-flex">
                            {item.ProfileImage === null ? (
                              <img src="../assets/img/picture.png" alt="" />
                            ) : (
                              <img
                                src={Endpoints.baseUrl + item.ProfileImage}
                                alt=""
                              />
                            )}
                          </div>

                          <div>
                            <h6 className="fs-14 text-darkgray regular">
                              {userId == item?.userId ? "You" : item?.username}{" "}
                              {item?.TYPE == "comment"
                                ? "commented on"
                                : item?.TYPE == "reaction" &&
                                  item?.reactionType != "Like"
                                ? "reacted to"
                                : "liked"}{" "}
                              <Link to={"/timeline/" + item?.postOwnerUsername}>
                                <span className="text-brown bold">
                                  {item?.postOwner}'s
                                </span>
                              </Link>{" "}
                              <Link to={"/post-detail/" + item?.postId}>
                                post
                              </Link>
                            </h6>
                            <h6 className="text-mediumgray regular fs-10">
                              {timeSince(new Date(item.createdAt))}
                            </h6>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              <div className="col-md-6 feed-mid-column1 pb-3">
                {username === param?.id ? (
                  <PostShare
                    page="timeline"
                    setPostPage={setPostPage}
                    setTimelinePosts={setTimelinePosts}
                    postPage={postPage}
                    getUserPosts={getUserPosts}
                  />
                ) : (
                  ""
                )}

                <ul className="timeline-feed feed-list">
                  {timelinePosts.map((post, i) => {
                    if (
                      post.category === "Blog" &&
                      post.isShared === 0 &&
                      post.isMediaShared === 0
                    ) {
                      return (
                        <BlogCard
                          post={post}
                          key={post.id}
                          getUserPosts={getUserPosts}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          formatDateTime={formatDateTime}
                        />
                      );
                    } else if (
                      post.isShared &&
                      post.isMediaShared === 0 &&
                      post.category !== "Blog"
                    ) {
                      return (
                        <SharedPost
                          post={post}
                          getUserPosts={getUserPosts}
                          key={post.id}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          formatDateTime={formatDateTime}
                        />
                      );
                    } else if (
                      post.isShared &&
                      post.isMediaShared &&
                      post.category !== "Blog"
                    ) {
                      return (
                        <SharedMedia
                          post={post}
                          getUserPosts={getUserPosts}
                          key={post.id}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          formatDateTime={formatDateTime}
                        />
                      );
                    } else if (
                      post.isShared === 0 &&
                      post.isMediaShared === 0 &&
                      post.category !== "Blog"
                    ) {
                      // console.log("inside fourth logic");
                      return (
                        <Post
                          post={post}
                          getUserPosts={getUserPosts}
                          key={post.id}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          formatDateTime={formatDateTime}
                          onEditSuccess={onEditSuccess}
                          setTimelinePosts={setTimelinePosts}
                        />
                      );
                    }
                    // else if (post.category === "Link") {
                    //     console.log("inside fifth logic");
                    //     return (
                    //         <LinkPost
                    //             post={post}
                    //             key={post.id}
                    //             getUserPosts={getUserPosts}
                    //             mouserHovering={mouserHovering}
                    //             showReactionsBox={showReactionsBox}
                    //             hideReactionsBox={hideReactionsBox}
                    //             timeSince={timeSince}
                    //             setTimelinePosts={setTimelinePosts}
                    //             setPostPage={setPostPage}
                    //             postPage={postPage}
                    //             formatDateTime={formatDateTime}
                    //             listInnerRef={listInnerRef}
                    //         />
                    //     );
                    // }
                    else if (post.category === "Blog" && post.isShared !== 0) {
                      return (
                        <SharedBlogCard
                          post={post}
                          key={post.id}
                          getUserPosts={getUserPosts}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          formatDateTime={formatDateTime}
                        />
                      );
                    }
                  })}
                  {loading === true && hasMorePosts === true && <PostLoader />}
                </ul>
              </div>

              <div className="col-md-3 profile-dp-right">
                <Photo />
                <Video />
              </div>
            </div>
          </div>

          {/* <?php include 'include/online-friends.php'; ?> */}
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}

export default ProfileTimeline;
