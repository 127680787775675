import { ModalDialog } from "react-bootstrap";

const CustomAlert = ({ show, onClose, text, type = "success" }) => {
  return (
    <ModalDialog
      className="d-flex bg-white"
      show={show}
      style={{
        zIndex: 1003,
        width: window.innerWidth <= 768 ? "300px" : "600px",
        height: "300px",
        borderRadius: "10px",
      }}
    >
      <div className="d-flex justify-content-center mt-5 w-100">
        {type === "error" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="80"
            viewBox="0 0 512 512"
            id="IconChangeColor"
          >
            <title>Error Icon</title>
            <path
              d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48ZM345.14,345.14a16,16,0,0,1-22.62,0L256,278.63l-66.51,66.51a16,16,0,0,1-22.62-22.62L233.37,256l-66.51-66.51a16,16,0,1,1,22.62-22.62L256,233.37l66.51-66.51a16,16,0,0,1,22.62,22.62L278.63,256l66.51,66.51A16,16,0,0,1,345.14,345.14Z"
              fill="#e74c3c"
            ></path>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="80"
            viewBox="0 0 512 512"
            id="IconChangeColor"
          >
            <title>ionicons-v5-e</title>
            <path
              d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48ZM218,360.38,137.4,270.81l23.79-21.41,56,62.22L350,153.46,374.54,174Z"
              id="mainIconPathAttribute"
              fill="#2bc48b"
            ></path>
          </svg>
        )}
      </div>
      <div className="mt-5 w-100 text-center d-flex flex-column  justify-content-center align-items-center w-100 gap-4">
        <h4 className="regular ">{text}</h4>
        <div>
          <button onClick={onClose} className="btn px-5">
            OK
          </button>
        </div>
      </div>
    </ModalDialog>
  );
};

export default CustomAlert;
