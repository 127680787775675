import { FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa";

const CustomStarRating = ({ stars, ratingPercent, label }) => {
  return (
    <div className="pb-2 row align-items-center">
      <div className="col-md-4 d-flex align-items-center">
        <div className="course-rating d-flex gap-1 py-1 justify-content-center">
          {Array.from({ length: stars }, (_, index) => (
            <FaStar key={index} size={16} color="rgb(255 193 7)" />
          ))}
          {Array.from({ length: 5 - stars }, (_, index) => (
            <FaRegStar key={index} size={16} color="rgb(255 193 7)" />
          ))}
        </div>
        <span
          style={{ fontSize: "15px", marginLeft: "8px", whiteSpace: "nowrap" }}
          className="text-black"
        >
          {label}
        </span>
      </div>
      <div className="col-md-8">
        <div className="d-flex gap-2 align-items-center">
          <div
            className="progress rounded-pill flex-grow-1"
            style={{
              marginLeft: "5px",
            }}
          >
            <div
              className="progress-bar bg-warning"
              role="progressbar"
              style={{ width: `${ratingPercent}%` }}
              aria-valuenow={ratingPercent}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <span
            style={{
              fontSize: "15px",
              whiteSpace: "nowrap",
              minWidth: "50px",
              textAlign: "right",
            }}
            className="text-black"
          >
            {ratingPercent}%
          </span>
        </div>
      </div>
    </div>
  );
};

export default CustomStarRating;
