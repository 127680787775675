import React from "react"
import { Link } from "react-router-dom";
import { Endpoints } from "../../API/Endpoints";
const ReactionsModal = ({totalPostReactions, 
    backdropHandle,
    allReactions,
    post,
    setPage,
    setReactionModalVar,
    setReactionModalOpen,
    setModalState,
    setTotalPostReactions,
    reactionsModalRef,
    handleScroll
})=>{

    return (
    

      <>
       {totalPostReactions.length > 0 && "show" ? (
              <div
                className="modal-backdrop fade show"
                onClick={backdropHandle}
              ></div>
            ) : (
              ""
            )}
            {totalPostReactions.length > 0 && (
              <div
                className={`modal fade show`}
                id="exampleModal123"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{zIndex: "2000"}}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Reactions 
                      </h5>
                      <button
                        type="button"
                        onClick={() => {
                          setPage(1)
                          setReactionModalVar(false);
                          setReactionModalOpen("hide");
                          setTotalPostReactions("");
                          setModalState("hide");
                        }}
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      {/* df */}
                      <div className="d-flex align-items-center">
                        <div>
                          <span
                            style={{ cursor: "pointer", marginRight: "30px" }}
                            onClick={() => {
                              allReactions(post.id, "all");
                            }}
                          >
                            All
                          </span>
                        </div>

                        <div className="d-flex w-100">
                          {totalPostReactions.at(-1).likeReactions > 0 && (
                            <span
                              style={{ cursor: "pointer", width: "3rem" }}
                              className="d-flex align-items-center"
                              onClick={() => {
                                allReactions(post.id, "like");
                              }}
                            >
                              <img
                                src={`../assets/img/icon/Like.svg`}
                                style={{ width: "22px", height: "22px", objectFit: "cover" }}
                                alt="comment-reply-reaction"
                              />
                              <span className="ms-1">{totalPostReactions.at(-1).likeReactions}</span>
                            </span>
                          )}
                          {totalPostReactions.at(-1).hahaReactions > 0 && (
                            <span
                              style={{ cursor: "pointer", width: "3rem" }}
                              className="d-flex align-items-center"
                              onClick={() => {
                                allReactions(post.id, "haha");
                              }}
                            >
                              <img
                                src={`../assets/img/icon/Haha.svg`}
                                style={{ width: "22px", height: "22px" }}
                                alt="comment-reply-reaction"
                              />
                              <span className="ms-1">{totalPostReactions.at(-1).hahaReactions}</span>
                            </span>
                          )}
                          {totalPostReactions.at(-1).loveReactions > 0 && (
                            <span
                              style={{ cursor: "pointer", width: "3rem" }}
                              className="d-flex align-items-center"
                              onClick={() => {
                                allReactions(post.id, "love");
                              }}
                            >
                              <img
                                src={`../assets/img/icon/Love.svg`}
                                style={{ width: "22px", height: "22px" }}
                                alt="comment-reply-reaction"
                              />
                              <span className="ms-1">{totalPostReactions.at(-1).loveReactions}</span>
                            </span>
                          )}
                          {totalPostReactions.at(-1).careReactions > 0 && (
                            <span
                              style={{ cursor: "pointer", width: "3rem" }}
                              className="d-flex align-items-center"
                              onClick={() => {
                                allReactions(post.id, "care");
                              }}
                            >
                              <img
                                src={`../assets/img/icon/Care.svg`}
                                style={{ width: "22px", height: "22px" }}
                                alt="comment-reply-reaction"
                              />
                              <span className="ms-1">{totalPostReactions.at(-1).careReactions}</span>
                            </span>
                          )}
                          {totalPostReactions.at(-1).wowReactions > 0 && (
                            <span
                              style={{ cursor: "pointer", width: "3rem" }}
                              className="d-flex align-items-center"
                              onClick={() => {
                                allReactions(post.id, "wow");
                              }}
                            >
                              <img
                                src={`../assets/img/icon/Wow.svg`}
                                style={{ width: "22px", height: "22px" }}
                                alt="comment-reply-reaction"
                              />
                              <span className="ms-1">{totalPostReactions.at(-1).wowReactions}</span>
                            </span>
                          )}
                          {totalPostReactions.at(-1).sadReactions > 0 && (
                            <span
                              style={{ cursor: "pointer", width: "3rem" }}
                              className="d-flex align-items-center"
                              onClick={() => {
                                allReactions(post.id, "sad");
                              }}
                            >
                              <img
                                src={`../assets/img/icon/Sad.svg`}
                                style={{ width: "22px", height: "22px" }}
                                alt="comment-reply-reaction"
                              />
                              <span className="ms-1">{totalPostReactions.at(-1).sadReactions}</span>
                            </span>
                          )}
                          {totalPostReactions.at(-1).angryReactions > 0 && (
                            <span
                              style={{ cursor: "pointer", width: "3rem" }}
                              className="d-flex align-items-center"
                              onClick={() => {
                                allReactions(post.id, "angry");
                              }}
                            >
                              <img
                                src={`../assets/img/icon/Angry.svg`}
                                style={{ width: "22px", height: "22px" }}
                                alt="comment-reply-reaction"
                              />
                              <span className="ms-1">{totalPostReactions.at(-1).angryReactions}</span>
                            </span>
                          )}
                        </div>
                      </div>

                      <hr />
                      <div>
                        <div className="reactions-box" ref={reactionsModalRef}
                          onScroll={handleScroll}>
                          {totalPostReactions.length > 0 &&
                            totalPostReactions.map((reaction) => (
                              reaction.id > 0 &&
                              <div className="reaction-container my-3">
                              <Link to={"/timeline/" + reaction?.username}>
                                {reaction.userImage ? (
                                  <img
                                    src={Endpoints.baseUrl + reaction.userImage}
                                    style={{
                                      width: "35px",
                                      height: "35px",
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                      marginRight: "8px",
                                    }}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={`../assets/img/picture.svg`}
                                    style={{
                                      width: "35px",
                                      height: "35px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                      marginRight: "8px",
                                    }}
                                    alt=""
                                  />
                                )}

                                <img
                                  src={`../assets/img/icon/${reaction.reactionType}.svg`}
                                  className="user-post-reaction"
                                  alt=""
                                />
                                <span>{reaction.user}</span>
                              </Link>
                              </div>
                            ))}
                        </div>
                      </div>
                      {/* df */}
                    </div>
                    <div class="modal-footer">
                      {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>                                                 */}
                    </div>
                  </div>
                </div>
              </div>
            )}
      </>
    )



}

export default ReactionsModal;