import React, { useState, useEffect } from 'react';
import { Endpoints } from "../../../API/Endpoints";
import ToastMessage from "../../../Utils/ToastMessage";
import { useNavigate, useParams } from 'react-router-dom';
import { FetchApi } from "../../../API/FetchApi";
export default function Profile() {
    const [groupData, setGroupData] =useState('');
    const Navigate = useNavigate();
    const param = useParams();
    useEffect(()=>{
        getGroupPeople();
    }, []);
    const getGroupPeople = async() =>{
       
        try {
            let resp = await FetchApi(Endpoints.GetGroupsAbout + "?groupId=" + param?.id);
            if (resp && resp.success === true) {
                setGroupData(resp?.data.groupData);
            }
        }
        catch (e) {

            if (e && e.response && e.response.data && e.response.data.message) {

                if (e.response.status === 401) {

                    ToastMessage.Error('Session Expired! Please login again.')
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message)
                }
            }
        }
    }

  return (
    <div class="profile-banner-top mb-4  rounded-15 overflow-hidden">
    <div class="position-relative">
        {groupData?.coverImage === null ?
        <img src="../assets/img/timeline-banner.png" alt="" class="profile-coverpic" />
        :
        <img src={Endpoints.baseUrl +  groupData?.coverImage} alt="" class="profile-coverpic" />
        }
        
        <div class=" position-absolute bottom-0 start-0 m-3">
            <div class="profile-dp user-pro-dp">
            {groupData?.logo === null ?
                <img src="../assets/img/profile-dp.png" alt="" />
                :
                <img src={Endpoints.baseUrl + groupData?.logo} alt="" />
            }
            </div>
        </div>
    </div>
</div>
  )
}
