import React, { useEffect, useState } from 'react'
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import Container from "../../Components/Container";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { CommonAPi } from '../../API/CommonApi';
import { FetchApi } from '../../API/FetchApi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../ProfileUpdate/Sidebar';
import {Helmet} from "react-helmet";
export default function FriendRequest() {
    const [FriendRequest, setFriendRequest] = useState([]);
    const [ProfileData, setProfileData] = useState("");
    const [loadStatus, setLoadStatus] = useState(false);
    const [limit, setLimit] = useState(8);
    const Navigate = useNavigate();
    const param =useParams();
    useEffect(() => {
        getFriendRequest();
        getProfile();
    }, []);
    const getFriendRequest = async () => {
        try {
            let resp = await FetchApi(Endpoints.GetFriendRequest+"?size="+limit+"&page=1");
            if (resp && resp.success === true) {
                setFriendRequest(resp?.data?.requests);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };

    const getProfile = async () => {
        try {
            let resp = await FetchApi(Endpoints.GetUserProfile + "?id="+param.id);
            if (resp && resp.success === true) {
                setProfileData(resp?.data);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };

    const FriendRequestResponse = async (Id, action) => {
        let data = {
            requestId: Id,
            action: action
        }
        try {
            let resp = await CommonAPi(Endpoints.FriendRequestRespond, data);
            if (resp && resp.message) {
                getFriendRequest();
                ToastMessage.Success(resp.message)
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }

    };
    const handleLoadMore = async() =>{
        let newLimit = limit + 8;
        setLimit(newLimit);
        setLoadStatus(true);
        try {
            let resp = await FetchApi(Endpoints.GetFriendRequest +"?size="+newLimit+"&page=1");
            if (resp && resp.success === true) {
                setFriendRequest(resp?.data?.requests);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                setLoadStatus(false);
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }

    };


    return (
        <Container
            header
        >
        <Helmet>
            <title>Friend Request</title>
        </Helmet>
            <div className="wrapper wrapper-bg">

                <div className="main">

                    <LeftNavigation />
                    <div className="container py-lg-3 py-3">

                        <div class="profile-banner-top mb-4  rounded-15 overflow-hidden">
                            <div class="position-relative">
                                {ProfileData?.user_profile?.coverImg === null ? 
                                <img src="../assets/img/timeline-banner.png" alt="" class="profile-coverpic" />
                                :
                                <img src={Endpoints.baseUrl + ProfileData?.user_profile?.coverImg } alt="" class="profile-coverpic" />
                                }
                                <div class=" position-absolute bottom-0 start-0 m-3">
                                    <div class="profile-dp user-pro-dp">
                                        {ProfileData?.user_profile?.profileImg === null ? 
                                        <img src="../assets/img/picture.png" alt="" />
                                            :
                                        <img src={Endpoints.baseUrl + ProfileData?.user_profile?.profileImg } alt="" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                           <Sidebar />

                            <div class="col-lg-9 col-md-8">
                                <div class="bg-white box-shadow rounded-15 p-md-3 p-2">
                                    <div class="pt-md-2 pb-3 mb-3 px-lg-3 border-bottom">
                                        <h3 class="medium">Friend Requests</h3>
                                    </div>
                                    {FriendRequest.length > 0 ? 
                                    <ul class="friend-request2">
                                        {FriendRequest.map((item, index) => {
                                            return (
                                                <li class="request-item px-3 py-2 d-flex align-items-start w-100" key={index}>
                                                    <Link to={"/timeline/" + item.username}>
                                                        {item?.user_profile?.profileImg === null ? 
                                                            <img src="../assets/img/picture.png" alt="" width="48" height="48" class="rounded-circle" />
                                                        :
                                                            <img src={Endpoints.baseUrl + item?.user_profile?.profileImg} alt="" width="48" height="48" class="rounded-circle" />
                                                        }
                                                    </Link>
                                                    <div class="ps-2 d-sm-flex align-items-start justify-content-between w-100">
                                                        <div>
                                                        <Link to={"/timeline/" + item.username}>
                                                            <h6 class="medium text-black">{item?.fullName}</h6>
                                                        </Link>
                                                            <h6 class="fs-12 py-1 regular text-darkgray">{item?.user_profile?.profileTagline}</h6>
                                                            <h6 class="fs-10 regular text-darkgray">{item?.mutualFriends} mutual friends</h6>
                                                        </div>

                                                        <div class="d-flex request-btn2 pt-md-3 pt-1 gap-2 ms-auto">
                                                            <Link class="btn fs-12 light-brown-btn" onClick={()=>{
                                                                FriendRequestResponse(item.requestId, "approve")
                                                            }}>Accept</Link>
                                                            <Link class="btn gray-btn" onClick={()=>{
                                                                FriendRequestResponse(item.requestId, "reject")
                                                            }}>Decline</Link>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })}

                                    </ul>

                                    : 
                                    <ul class="friend-request2">
                                         <li class="request-item px-3 py-2 d-flex align-items-start w-100">
                                            No any active request
                                         </li>
                                    </ul>
                                    
                                    }

                            {FriendRequest.length >= 8 ? 
                            <div className="text-center">
                                       
                                       <Link href="#" className="text-blue" onClick={() => handleLoadMore()}>
   
                                           {loadStatus ?
                                               <img src="../assets/img/icon/icon-loading.svg" alt="" />
                                               : "Load More Friend Request "}
   
                                       </Link>
                                        
                            </div>  
                            : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <OnlineFriends />
                </div>
            </div>
        </Container>
    )
}
