import React, { useEffect, useState } from "react";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Profile from "../Teacher/Profile";
import { Endpoints } from "../../../API/Endpoints";
import { FetchApi } from "../../../API/FetchApi";
import ToastMessage from "../../../Utils/ToastMessage";
import { Link, useNavigate } from "react-router-dom";
import Item from "../../HonorAwards/Item";
import ReactStars from "react-rating-stars-component";
import { CommonAPi } from "../../../API/CommonApi";
import { Helmet } from "react-helmet";
export default function Teacher() {
  const [teacher, setTeacher] = useState([]);
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sorting, setSorting] = useState("");
  const [limit, setLimit] = useState(8);
  const [loading, setLoading] = useState(false);
  const userId = parseInt(localStorage.getItem("userId"));
  useEffect(() => {
    getTeacher();
  }, []);

  const getTeacher = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetMyTeacher + "?" + "size=" + 12);
      if (resp && resp.success === true) {
        setTeacher(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleLoadMore = async () => {
    setLoading(true);
    const NewLimit = parseInt(limit) + 12;
    setLimit(NewLimit);

    let url = Endpoints.GetMyTeacher + "?size=" + NewLimit;
    let navigateUrl = "/student-teachers?size=" + NewLimit;

    if (searchKeyword) {
      url = url + "&keyword=" + searchKeyword;
      navigateUrl = navigateUrl + "&keyword=" + searchKeyword;
    }

    if (sorting) {
      url = url + "&sort=" + sorting;
      navigateUrl = navigateUrl + "&sort=" + sorting;
    }

    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleSearch = (value) => {
    let url = Endpoints.GetMyTeacher + "?size=" + limit;
    let navigateUrl = "/student-teachers?size=" + limit;
    setSearchKeyword(value);

    if (value) {
      url = url + "&keyword=" + value;
      navigateUrl = navigateUrl + "&keyword=" + value;
    }

    if (sorting) {
      url = url + "&sort=" + sorting;
      navigateUrl = navigateUrl + "&sort=" + sorting;
    }

    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleSorting = (value) => {
    setSorting(value);
    let url = Endpoints.GetMyTeacher + "?size=" + limit;
    let navigateUrl = "/student-teachers?size=" + limit;

    if (searchKeyword) {
      url = url + "&keyword=" + searchKeyword;
      navigateUrl = navigateUrl + "&keyword=" + searchKeyword;
    }

    if (value) {
      url = url + "&sort=" + value;
      navigateUrl = navigateUrl + "&sort=" + value;
    }

    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleCourseFilterData = async (url) => {
    try {
      let resp = await FetchApi(url);
      if (resp && resp.success === true) {
        setLoading(false);
        setTeacher(resp.data);
      } else {
        setLoading(false);
        setTeacher([]);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        ToastMessage.Error(e.response.data.message);
        setLoading(false);
        // setLoadStatus(false);
      }
    }
  };

  const sendMsg = async (tid) => {
    try {
      const resp = await CommonAPi(Endpoints.CreateChat, {
        student_id: userId,
        teacher_id: tid,
      });
      if (resp) {
        navigate(`/messages?userId=${tid}`);
      }
    } catch (e) {
      console.log(e);
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Teacher</title>
      </Helmet>
      <div class="wrapper wrapper-bg">
        <div class="main">
          <LeftNavigation />
          <div class="container py-3">
            <Profile type="student" active={"teacher"} />

            <div class="bg-white main-common">
              <div class="main-top-row d-md-flex justify-content-between align-items-center pb-3">
                <h3 class="medium">My Teachers</h3>

                <div class="d-sm-flex gap-3">
                  <form
                    action=""
                    class="mainmenu-page-search d-flex flex-row-reverse align-items-center"
                  >
                    <input
                      type="text"
                      placeholder="Search for teachers"
                      name="search"
                      class="bg-transparent fs-14 medium  text-darkgray"
                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                    />
                    <button type="submit" class="bg-transparent border-0">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="#949494"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        class="css-i6dzq1"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </button>
                  </form>
                  <select
                    name="sortby"
                    id="sortby"
                    class="text-darkgray"
                    onChange={(e) => {
                      handleSorting(e.target.value);
                    }}
                  >
                    <option value="">Sort by</option>
                    <option value="a-z">Ascending</option>
                    <option value="z-a">Descending</option>
                    <option value="latest">latest</option>
                    <option value="old">last</option>
                  </select>
                </div>
              </div>

              {teacher.length > 0 ? (
                <div class="row mainmenu-filter-conatiner">
                  {teacher.map((item, index) => {
                    return (
                      <div class="col-lg-3 col-md-4 col-sm-6 mb-4" key={index}>
                        <div class="mainmenu-page-item">
                          <div class="text-center position-relative">
                            {item.user_profile?.coverImg === null ? (
                              <img
                                src="assets/img/teacher-banner.png"
                                class="mainmenu-page-banner"
                                alt=""
                              />
                            ) : (
                              <img
                                src={
                                  Endpoints.baseUrl +
                                  item.user_profile?.coverImg
                                }
                                class="mainmenu-page-banner"
                                alt=""
                                style={{ objectFit: "cover" }}
                              />
                            )}
                            <Link to={"/instructor-profile/" + item.username}>
                              {item.user_profile?.profileImg === null ? (
                                <span class="suggested-user">
                                  <img
                                    src="assets/img/teacher1.png"
                                    alt=""
                                    width="96"
                                    height="96"
                                    class="rounded-circle"
                                  />
                                </span>
                              ) : (
                                <span class="suggested-user">
                                  <img
                                    src={
                                      Endpoints.baseUrl +
                                      item.user_profile?.profileImg
                                    }
                                    alt=""
                                    width="96"
                                    height="96"
                                    class="rounded-circle"
                                  />
                                </span>
                              )}
                            </Link>
                            <Link to={"/instructor-profile/" + item.username}>
                              <h5 class="semibold pt-3 pb-2">
                                {item.fullName}
                              </h5>
                            </Link>
                            <h6 class="fs-14 text-darkgray regular pb-1">
                              {item.user_profile?.profileTagline}
                            </h6>
                            {/* <h6 class="fs-14 text-darkgray medium pb-1">Sapphire International School, Noida</h6> */}
                            <h6 class="fs-14 text-darkgray regular pb-2">
                              {item.instructor_details.totalStudents} Students
                            </h6>

                            <div class="d-flex justify-content-center align-items-center">
                              <span class="fs-12 medium">
                                {item?.instructor_details?.overallRating?.toFixed(
                                  2
                                )}
                              </span>
                              <div class="d-flex mx-2">
                                {item.instructor_details.overallRating !==
                                null ? (
                                  <ReactStars
                                    count={5}
                                    edit={false}
                                    value={
                                      item.instructor_details.overallRating
                                    }
                                    size={18}
                                    isHalf={true}
                                    char={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        alt=""
                                      />
                                    }
                                    emptyIcon={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        alt=""
                                      />
                                    }
                                    halfIcon={
                                      <img
                                        src="../assets/img/icon/state-half.svg"
                                        alt=""
                                      />
                                    }
                                    filledIcon={
                                      <img
                                        src="../assets/img/icon/State-fill.svg"
                                        alt=""
                                      />
                                    }
                                    activeColor="#ffd700"
                                    activeBackgroundColor="#ffd700"
                                  />
                                ) : (
                                  <ReactStars
                                    count={5}
                                    edit={false}
                                    value={0}
                                    size={18}
                                    isHalf={true}
                                    char={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        alt=""
                                      />
                                    }
                                    emptyIcon={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        alt=""
                                      />
                                    }
                                    halfIcon={
                                      <img
                                        src="../assets/img/icon/state-half.svg"
                                        alt=""
                                      />
                                    }
                                    filledIcon={
                                      <img
                                        src="../assets/img/icon/State-fill.svg"
                                        alt=""
                                      />
                                    }
                                    activeColor="#ffd700"
                                    activeBackgroundColor="#ffd700"
                                  />
                                )}
                              </div>
                              <span class="fs-10 medium text-lightgray">
                                ({item.instructor_details.totalReviews})
                              </span>
                            </div>
                          </div>

                          <div class="text-center py-3 px-3">
                            <button
                              class="d-block btn btn-brown w-100 px-4 py-2 mw-100 py-2"
                              onClick={() => {
                                sendMsg(item.id);
                              }}
                            >
                              Send a Message
                              <img
                                src="assets/img/icon/message.svg"
                                class="ps-2"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div class="row mainmenu-filter-conatiner">
                  No teacher found
                </div>
              )}

              {teacher.length > 8 ? (
                <div class="text-center">
                  <Link
                    href="#"
                    class="text-blue"
                    onClick={() => handleLoadMore()}
                  >
                    {loading ? (
                      <img src="../assets/img/icon/icon-loading.svg" alt="" />
                    ) : (
                      "Load More Courses "
                    )}
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
