import EmojiPicker from "emoji-picker-react";
import { useCallback, useLayoutEffect, useRef } from "react";
import "./emojiPickerFloatingWindow.css";
import { createPortal } from "react-dom";

export default function EmojiPickerFloatingWindow({
  anchorRef,
  onSelect = () => {},
  onClose = () => {},
  width,
}) {
  const pickerContainerRef = useRef();

  const handlePickerClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  useLayoutEffect(() => {
    if (pickerContainerRef.current && anchorRef?.current) {
      const anchorBoundingRect = anchorRef.current.getBoundingClientRect();
      pickerContainerRef.current.style.top = `${
        anchorBoundingRect.top - pickerContainerRef.current.offsetHeight - 20
      }px`;
      pickerContainerRef.current.style.left = `${
        anchorBoundingRect.left - 20
      }px`;
    }
  }, [anchorRef]);

  return createPortal(
    <div className="emoji-picker-modal-container" onClick={onClose}>
      <div
        className="emoji-picker-container"
        ref={pickerContainerRef}
        onClick={handlePickerClick}
      >
        <EmojiPicker
          onEmojiClick={(emojiObject) => {
            onSelect(emojiObject.emoji);
          }}
          width={width}
        />
      </div>
    </div>,
    document.body
  );
}
