

// import React, {useEffect, useState} from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import { CommonAPi } from "../../API/CommonApi";
// import { Endpoints } from "../../API/Endpoints";

// const ReturnURL = () => {
//     const location = useLocation();
//     const query = new URLSearchParams(location.search);
//     const navigate = useNavigate();
//     const [order_id, setOrderId] = useState(null);

//     useEffect(() => {
//         const orderId = query.get("order_id");
//          console.log(order_id);
//             return;
//         if (orderId) {
//             setOrderId(orderId);
//         } else {
//             // Wait for 500 milliseconds and try again
//             setTimeout(() => {
//                 const orderId = query.get("order_id");
//                 if (orderId) {
//                     setOrderId(orderId);
//                 } else {
//                     // Order ID is still null, handle the error
//                     navigate("/payment-failed");
//                 }
//             }, 3000);
//         }
//     }, []);

//     async function returnURL(){
//         try {

           
//             const resp = await CommonAPi(Endpoints.ReturnURL+"?order_id="+order_id);
//             if(resp.success){
//                 localStorage.setItem("orderId", resp.data.orderId)
//                 navigate("/checkout-success");
//             } else {
//                 navigate("/payment-failed");
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     }

//     useEffect(() => {
//         if (order_id) {
//             returnURL();
//         }
//     }, [order_id]);

//     return null;
// }

// export default ReturnURL;












import React, {useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
const ReturnURL  = ()=>{
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const order_id = query.get("order_id");
    // console.log(order_id);
    // return;
    useEffect(()=>{
        returnURL();
    },[]);

    async function returnURL(){
        try {
            const resp = await CommonAPi(Endpoints.ReturnURL+"?order_id="+order_id);
            if(resp.success){
                localStorage.setItem("orderId", resp.data.orderId)
                navigate("/checkout-success");
            }else{
                navigate("/payment-failed");
            }
            
        } catch (error) {
            console.log(error);
        }
    }
}
export default ReturnURL;