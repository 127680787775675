import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";

export default function Tooltip({
  title = "Tooltip",
  children,
  className,
  anchor,
}) {
  const timeout = useRef(null);

  const tooltipParent = useRef(null);

  const [show, setShow] = useState(false);

  const cancelTimeout = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  }, []);

  const refreshTimeout = useCallback(() => {
    cancelTimeout();
    timeout.current = setTimeout(() => {
      setShow(false);
    }, 2000);
  }, [cancelTimeout]);

  // auto hide after some time
  useEffect(() => {
    if (show) {
      refreshTimeout();
    }
  }, [show]);

  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const calcultePosition = useCallback(() => {
    if (tooltipParent.current) {
      const el = tooltipParent.current.getBoundingClientRect();
      console.log({ el });
      setTop(parseInt(el.top));
      setLeft(parseInt(el.left));
    }
  }, []);

  const handleShow = useCallback(
    (e) => {
      calcultePosition();
      setShow(true);
    },
    [calcultePosition]
  );
  const handleHide = useCallback(
    (e) => {
      cancelTimeout();
      setShow(false);
    },
    [cancelTimeout]
  );

  const toRightMost = useMemo(() => window.innerWidth - left < 100, [left]);

  return (
    <span
      ref={tooltipParent}
      className={className ? className : "position-relative"}
      onMouseEnter={handleShow}
      onMouseLeave={handleHide}
    >
      {children}
      {show &&
        createPortal(
          <span
            style={{
              position: "fixed",
              top: top - 60,
              left: toRightMost ? undefined : left,
              right: toRightMost ? 10 : undefined,
              zIndex: 500,
              padding: "5px 12px",
              border: "1px solid #ededed",
              background: "black",
              color: "white",
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {title}
          </span>,
          anchor ? anchor : document.body
        )}
    </span>
  );
}
