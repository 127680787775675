import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ToastMessage from "../../Utils/ToastMessage";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import { FetchApi } from "../../API/FetchApi";
import { UpdateAPi } from "../../API/UpdateApi";
import { DeleteApi } from "../../API/DeleteApi";
import PostTitleView from "./PostTitleView";
import "./CustomHeader.css";
import { StickyChatContext } from "../StickyChatWrapper";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import IsOnline from "../IsOnline";
import { truncateString } from "../../Utils/helpers";

const $ = window.jQuery;
function CustomHeader({
  refreshCartcount,
  refreshNotificationcount,
  setPostPage,
  getUserPosts,
  postPage,
  setLoading,
  loading = false,
  fromMainfeed,
  setTimelinePosts,
  containerRef,
}) {
  const { handleAddChat, onlineUsers, chats } = useContext(StickyChatContext);
  const [FriendRequest, setFriendRequest] = useState([]);
  const [notification, setNotification] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const parentData = JSON.parse(localStorage.getItem("parentData"));
  const userProfile = localStorage.getItem("userProfile");
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const instructorStatus = localStorage.getItem("instructorStatus");
  const childCount = localStorage.getItem("childCount");

  const handleProfile = (event) => {
    navigate("/timeline/" + userName);
  };

  const [cart, setCart] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchKey, setSearchKey] = useState(Math.ceil(Math.random() * 999999));
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [dropdownTopOpen, setDropdownTopOpen] = useState(false);
  const username = localStorage.getItem("userName");
  const Navigate = useNavigate();
  const location = useLocation();

  // const handleNavigation = () => {
  //   if (!openMenu) {
  //     setOpenMenu(true);
  //     $(".side-menu").addClass("active-sidemenu");
  //   }
  // };

  useEffect(() => {
    getUserProfile();
  }, [location.pathname]);

  useEffect(() => {
    if (instructorStatus == "Pending") {
      getUserProfile();
    }
  }, [instructorStatus]);

  const getUserProfile = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetUserProfile + "?id=" + username);
      if (resp && resp.success === true) {
        console.log("#@#@5", resp);
        window.localStorage.setItem(
          "userProfile",
          resp?.data?.user_profile?.profileImg
        );
        window.localStorage.setItem(
          "userProfileCoverImage",
          resp?.data?.user_profile?.coverImg
        );

        window.localStorage.setItem("user", JSON.stringify(resp?.data));
        if (resp?.data?.instructorStatus == "Complete") {
          window.localStorage.setItem("instructorStatus", "Complete");
        }
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const toggleDropdown = (dropdownId) => {
    if (dropdownOpen === dropdownId) {
      setDropdownOpen(null);
    } else {
      setDropdownOpen(dropdownId);
      setDropdownTopOpen(false);
    }
  };

  const toggleTopDropdown = () => {
    if (dropdownTopOpen === true) {
      setDropdownTopOpen(false);
    } else {
      setDropdownTopOpen(true);
      setDropdownOpen(null);
    }
  };
  // This effect simulates the jQuery document.ready functionality
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".dropdown-menu")) {
        setDropdownOpen(null);
        setDropdownTopOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleNotificationClick = (id) => {
    toggleDropdown(id);
  };

  // const handleMoreClick = () => {
  //   toggleDropdown("more");
  // };

  useEffect(() => {
    getCartItem();
    if (refreshCartcount === true) {
      getCartItem();
    }
  }, [refreshCartcount]);

  const getCartItem = async () => {
    try {
      const resp = await CommonAPi(Endpoints.GetCartItems);
      if (resp && resp.success === true) {
        setCart(resp.data.cartItems);
      } else {
        setCart(resp.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    ToastMessage.Success("Log Out Successfully");
    navigate("/");
  };

  const handleSidebar = () => {
    /***** LEFT MENU TOGGLE js  *****/

    if (openMenu == true) {
      setOpenMenu(false);
      $(".side-menu").removeClass("active-sidemenu");
    } else {
      setOpenMenu(true);
      $(".side-menu").addClass("active-sidemenu");
      $(".head-menu").slideToggle();
    }
  };

  const refreshFeed = (e) => {
    setLoading(true);
    containerRef?.current?.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      setTimelinePosts([]);
      setPostPage(1);
      // postPage === 1 && setLoading(false);
      getUserPosts(null, 1);
    }, 1000);
  };

  const cartCountRender = useCallback(() => {
    return (
      <>{cart.length > 0 ? <span className="count">{cart.length}</span> : ""}</>
    );
  }, [cart, refreshCartcount]);

  const checkOnlineStatus = useCallback(
    (chat) => {
      if (chat) {
        const member = chat?.chat_room_members?.find(
          (each) => each.user.id !== user.id
        );
        const isOnline =
          !!member &&
          onlineUsers?.find((user) => user.userId === member.user.id);
        return !!isOnline;
      }
    },
    [onlineUsers, user.id]
  );

  const unreadMessageCount = useMemo(
    () => chats.reduce((count, chat) => count + chat.unreadMsgCount, 0),
    [chats]
  );

  const handleOnSearch = async (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setSearchText(string);
    setOptions([]);
    if (string) {
      try {
        let resp = await FetchApi(
          Endpoints.MasterListingSearch + "?q=" + string + "&type=All"
        );
        if (resp && resp.success === true) {
          setOptions([]);
          if (resp.data) {
            setOptions([]);
            const newData = [];

            const courseArray = resp?.data?.courses.map((item, index) => {
              return {
                id: item.id,
                image: item?.thumbnail,
                name: item?.title,
                slug: item?.slug,
                type: "courses",
                searchText: string,
              };
            });

            //  newD  }

            const groupsArray = resp?.data?.groups.map((item, index) => {
              return {
                id: item.id,
                image: item?.logo,
                name: item?.groupName,
                type: "groups",
                searchText: string,
              };
            });

            const internshipsArray = resp?.data?.internships.map(
              (item, index) => {
                return {
                  id: item.id,
                  image: item?.page?.pageImage,
                  name: item?.title,
                  type: "internships",
                  searchText: string,
                };
              }
            );

            const jobsArray = resp?.data?.jobs.map((item, index) => {
              return {
                id: item.id,
                image: item?.page?.pageImage,
                name: item?.title,
                searchText: string,
                type: "jobs",
              };
            });

            const pagesArray = resp?.data?.pages.map((item, index) => {
              return {
                id: item.id,
                image: item?.pageImage,
                name: item?.title,
                type: item?.pageType,
                slug: item?.slug,
                searchText: string,
              };
            });

            const peopleArray = resp?.data?.people.map((item, index) => {
              return {
                id: item.id,
                image: item?.user_profile?.profileImg,
                name: item?.fullName,
                username: item?.username,
                searchText: string,
                type: "people",
              };
            });

            const postsArray = resp?.data?.posts.map((item, index) => {
              return {
                id: item.id,
                image: item?.profileImg,
                name: item?.title,
                username: item?.username,
                searchText: string,
                user_id: item?.userId,
                type: "post",
              };
            });

            const teachersArray = resp?.data?.teachers.map((item, index) => {
              return {
                id: item.id,
                image: item?.user_profile?.profileImg,
                name: item?.fullName,
                username: item?.username,
                searchText: string,
                type: "teachers",
              };
            });
            const SeeAll = [
              {
                id: "0001",
                image: "",
                name: "See All Results",
                searchText: string,
                type: "seeAll",
              },
            ];
            setOptions([]);
            if (
              resp?.data?.totalCourses > 0 ||
              resp?.data?.totalGroup > 0 ||
              resp?.data?.totalInternship > 0 ||
              resp?.data?.totalPage > 0 ||
              resp?.data?.totalPeople > 0 ||
              resp?.data?.totalTeacher > 0 ||
              resp?.data?.totaljobs > 0 ||
              postsArray.length > 0
            ) {
              setOptions([
                ...courseArray,
                ...groupsArray,
                ...internshipsArray,
                ...jobsArray,
                ...pagesArray,
                ...peopleArray,
                ...postsArray,
                ...teachersArray,
                ...SeeAll,
              ]);
            }
          }
          //   const Noresult = [
          //     {
          //       id: "0001",
          //       image: "",
          //       name: "No Search Results Found",
          //       searchText: string,
          //       type: "noFound",
          //     },
          //   ];
          //   setOptions([...Noresult]);
          // }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  const handleOnSelect = (item) => {
    setSearchText(item[0]?.name);
    console.log("item", item);
    if (item[0]?.type === "courses") {
      navigate("/course-detail/" + item[0]?.slug);
    }
    if (item[0]?.type === "groups") {
      navigate("/group-home/" + item[0]?.id);
    }
    if (item[0]?.type === "internships") {
      navigate("/jobs/" + item[0]?.id);
    }
    if (item[0]?.type === "jobs") {
      navigate("/jobs/" + item[0]?.id);
    }
    if (item[0]?.type === "post") {
      navigate("/timeline/" + item[0]?.username);
    }
    if (item[0]?.type === "teachers") {
      navigate("/instructor-profile/" + item[0]?.username);
    }
    if (item[0]?.type === "people") {
      navigate("/timeline/" + item[0]?.username);
    }
    if (
      item[0]?.type === "Company" ||
      item[0]?.type === "Page" ||
      item[0]?.type === "Schools" ||
      item[0]?.type === "University"
    ) {
      navigate("/page-profile/" + item[0]?.slug);
    }
    if (item[0]?.type === "seeAll") {
      navigate("/search?q=" + item[0]?.searchText + "&type=All");
    }
  };
  useEffect(() => {
    console.log(options, process.env.PUBLIC_URL);
  }, [options]);

  const RenderSearchBox = useCallback(() => {
    return (
      <AsyncTypeahead
        id="global-search-top"
        defaultInputValue={""}
        key={searchKey}
        onSearch={handleOnSearch}
        onChange={handleOnSelect}
        options={options}
        placeholder="Search for people, groups & pages"
        labelKey="searchText"
        minLength={1}
        emptyLabel={options.length > 0 ? "Searching..." : "No Result Found"}
        className={"bg-transparent fs-14 medium w-100 global-search"}
        renderMenuItemChildren={(option, props) => (
          <>
            {option.type === "seeAll" ? (
              <div className="search-all" key={option.id}>
                {option.type === "seeAll" ? (
                  ""
                ) : (
                  <>
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      stroke="#949494"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="css-i6dzq1"
                    >
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    &nbsp; &nbsp; &nbsp;
                  </>
                )}
                {option?.type == "post" ? (
                  <Highlighter>
                    {" "}
                    <PostTitleView title={option.name} />
                  </Highlighter>
                ) : option.type === "seeAll" ? (
                  <>{option.name}</>
                ) : (
                  <Highlighter search={props.text}>{option.name}</Highlighter>
                )}
                <br />
                {option.type === "seeAll" ? (
                  ""
                ) : (
                  <small
                    style={{
                      marginLeft: "2.7rem",
                    }}
                  >
                    {option.type.charAt(0).toUpperCase() +
                      option.type.slice(1).toLowerCase()}
                  </small>
                )}
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-between">
                <div key={option.id}>
                  <div className="d-flex">
                    <>
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="#949494"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="css-i6dzq1"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      &nbsp; &nbsp; &nbsp;
                    </>

                    {option?.type == "post" ? (
                      <Highlighter>
                        <PostTitleView title={option.name} />
                      </Highlighter>
                    ) : (
                      <>
                        <Highlighter search={props.text}>
                          {truncateString(option.name, 30)}
                        </Highlighter>
                      </>
                    )}
                  </div>

                  {/* <br /> */}
                  {option.type === "seeAll" ? (
                    ""
                  ) : (
                    <small
                      style={{
                        marginLeft: "2.7rem",
                      }}
                    >
                      {/* {option.type} */}
                      {option.type.charAt(0).toUpperCase() +
                        option.type.slice(1).toLowerCase()}
                    </small>
                  )}
                </div>
                <div>
                  {option?.image && (
                    <img
                      src={
                        option?.image?.includes("user/profile-picture/null")
                          ? "../assets/img/picture.png"
                          : Endpoints.baseUrl + option?.image
                      }
                      style={{
                        height: "30px",
                        width: "30px",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </>
        )}
        useCache={false}
      />
    );
  }, [options, searchKey]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate("/search?q=" + searchText + "&type=All");
  };

  useEffect(() => {
    getFriendRequest();
    getAllNotification();
    getNotificationCount();
  }, [refreshNotificationcount]);
  const getFriendRequest = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetFriendRequest);
      if (resp && resp.success === true) {
        setFriendRequest(resp?.data?.requests);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const getAllNotification = async () => {
    try {
      let resp = await FetchApi(Endpoints.AllNotification);
      if (resp && resp.success === true) {
        setNotification(resp?.data?.notification);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const getNotificationCount = async () => {
    try {
      let resp = await FetchApi(Endpoints.AllNotificationCount);
      if (resp && resp.success === true) {
        setNotificationCount(resp?.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const FriendRequestResponse = async (Id, action) => {
    let data = {
      requestId: Id,
      action: action,
    };
    try {
      let resp = await CommonAPi(Endpoints.FriendRequestRespond, data);
      if (resp && resp.message) {
        getFriendRequest();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec ago";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "y ago";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon ago";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + "d ago";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + "h ago";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min ago";
    return Math.floor(seconds) >= 0
      ? Math.floor(seconds) + " sec ago"
      : "0 sec ago";
  };

  const handleShowNotification = async () => {
    try {
      let resp = await UpdateAPi(Endpoints.ShowNotification);
      if (resp && resp.message) {
        getNotificationCount();
        // ToastMessage.Success(resp.message)
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleRemove = async (Id) => {
    let data = {
      id: Id,
    };
    try {
      let resp = await DeleteApi(Endpoints.RemoveNotification, data);
      if (resp && resp.message) {
        getNotificationCount();
        getAllNotification();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleOpen = async (item) => {
    let data = {
      id: item?.id,
    };
    try {
      let resp = await UpdateAPi(Endpoints.ReadNotification, data);
      if (resp && resp.message) {
        getNotificationCount();
        getAllNotification();
        if (item?.type === "chat") {
          if (
            item?.metaData?.room_type === "teacher" &&
            user.instructorStatus === "Complete"
          ) {
            navigate("/teacher/messages");
            navigate(
              `/teacher/messages?roomId=${item?.metaData?.chat_room_id}`
            );
          } else if (item?.metaData?.room_type === "teacher") {
            navigate(`/messages?roomId=${item?.metaData?.chat_room_id}`);
          } else {
            navigate(`/friend/messages?roomId=${item?.metaData?.chat_room_id}`);
          }
        }
        if (item?.type === "job_apply") {
          navigate(
            "/admin-jobs-application/" + item?.metaData?.notificationUser?.jobId
          );
        }

        if (item?.type === "job_notification") {
          navigate("/jobs/" + item?.metaData?.notificationUser?.jobId);
        }
        if (item?.type === "Post" || item?.type === "mention") {
          navigate("/timeline/" + item?.username);
        }
        if (item?.type === "FriendRequest") {
          navigate("/friend-request/" + item?.userId);
        }
        if (item?.type === "FriendRequest") {
          navigate("/friend-request/" + item?.userId);
        }
        if (item?.type === "Gratitude") {
          navigate("/gratitudewall");
        }
        if (item?.type === "unusual") {
          navigate("/privacy-setting");
        }
        if (
          item?.type === "Company" ||
          item?.type === "Page" ||
          item?.type === "Schools" ||
          item?.type === "University"
        ) {
          navigate("/page-profile/" + item?.metaData?.pageData?.slug);
        }
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleReadAll = async () => {
    try {
      let resp = await UpdateAPi(Endpoints.ReadAllNotification);
      if (resp && resp.message) {
        getNotificationCount();
        getAllNotification();
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleReadNotification = async (Id) => {
    let data = {
      id: Id,
    };
    try {
      let resp = await UpdateAPi(Endpoints.ReadNotification, data);
      if (resp && resp.message) {
        getNotificationCount();
        getAllNotification();
        // ToastMessage.Success(resp.message)
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const toggleSearch = () => {
    let mobSearchElement = document.querySelector(".site-search");
    let currentDisplay = mobSearchElement.style.display;
    console.log(currentDisplay);
    if (currentDisplay === "none" || currentDisplay === "") {
      console.log("Here");
      mobSearchElement.style.display = "block";
    } else {
      mobSearchElement.style.display = "none";
    }
  };

  return (
    <header className="site-header">
      <div className="container-xxl container-fluid position-relative">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-12 site-logo">
            <div className="row">
              <div className="col-lg-4 col-12 justify-content-lg-start justify-content-between d-flex align-items-center">
                <span onClick={handleSidebar}>
                  <a href="#" className="toggle-menu pe-xl-3 pe-2">
                    <img src="/assets/img/icon/icon-menu.svg" alt="" />
                  </a>
                </span>

                <span>
                  <div
                    onClick={() =>
                      fromMainfeed ? refreshFeed() : navigate("/mainfeed")
                    }
                    className="cursor-pointer"
                  >
                    <img
                      src="/assets/img/new_logo.svg"
                      style={{ width: "91px", height: "40px" }}
                      alt="8 innet"
                    />
                  </div>
                </span>
                <span className="d-lg-none">
                  <a
                    href="#"
                    className="toggle-search pe-xl-3 pe-2"
                    onClick={toggleSearch}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      stroke="#949494"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="css-i6dzq1"
                    >
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                  </a>
                </span>
              </div>

              <div className="col-md-8">
                <div className="site-search">
                  <div className="d-flex align-items-center w-100 gap-2">
                    <button
                      className="close-site-search d-lg-none border-0 rounded-circle btn-light-blue"
                      onClick={toggleSearch}
                    >
                      <i className="fa fa-arrow-left" aria-hidden="true"></i>
                    </button>

                    <form
                      // onSubmit={handleSearchSubmit}
                      className="header-search d-flex flex-row-reverse align-items-center justify-content-end w-100 position-relative"
                    >
                      {/* <input
                        type="text"
                        placeholder="Search for people and groups"
                        name="search"
                        className="bg-transparent fs-14 medium"

                      /> */}
                      {RenderSearchBox()}
                      <button
                        type="submit"
                        className="bg-transparent border-0 ps-0"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          stroke="#949494"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="css-i6dzq1"
                        >
                          <circle cx="11" cy="11" r="8"></circle>
                          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-12 notification-area">
            <div className="d-flex align-items-center justify-content-lg-end justify-content-center pt-lg-0 pt-2 mt-lg-0 mt-2">
              <div className="d-flex head-icon-bar gap-xxl-4 gap-xl-3 gap-lg-2 gap-sm-3 gap-2">
                <div className="icon-wrapper header-icons">
                  {/* <a href="#" id="user_notification" className="head-icon" data-bs-toggle="dropdown"> */}

                  <a
                    href="#"
                    id="user_notification"
                    className={
                      notificationCount > 0 ? "head-icon" : "gray-head-icon"
                    }
                    data-bs-toggle="dropdown"
                    // onClick={() => {
                    //   handleShowNotification();
                    // }}
                  >
                    {notificationCount > 0 ? (
                      <img
                        src="../assets/img/icon/icon-notification.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        src="../assets/img/icon/gray-notification.svg"
                        alt=""
                      />
                    )}
                    {notificationCount > 0 ? (
                      <span className="count">{notificationCount}</span>
                    ) : (
                      ""
                    )}
                  </a>

                  <div
                    className="dropdown-menu haspopup"
                    aria-labelledby="user_notification"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="d-flex align-items-center justify-content-between px-3 py-2 border-bottom">
                      <h5 className="semibold">
                        Notifications ({notificationCount})
                      </h5>

                      <div className="position-relative">
                        <a
                          onClick={() => {
                            toggleTopDropdown();
                          }}
                          // data-bs-parent="#user_notification_list"
                          // id="more-notification1"
                          className="d-flex align-items-center justify-content-center rounded-circle bg-eee p-1"
                        >
                          <svg
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                            stroke="#616161"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="css-i6dzq1"
                          >
                            <circle cx="12" cy="12" r="1"></circle>
                            <circle cx="19" cy="12" r="1"></circle>
                            <circle cx="5" cy="12" r="1"></circle>
                          </svg>
                        </a>
                        {dropdownTopOpen === true && (
                          <ul className="dropdown-menu rounded-1 show inner-dropdownmenu ">
                            <li className="py-1">
                              <a
                                className="dropdown-item py-2 d-flex gap-2"
                                onClick={() => {
                                  handleReadAll();
                                }}
                                href="#"
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  width="15"
                                  height="15"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="css-i6dzq1"
                                >
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>
                                Mark all as Read
                              </a>
                            </li>
                            <li className="py-1">
                              <Link
                                className="dropdown-item py-2 d-flex gap-2"
                                to="/notifications"
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  width="15"
                                  height="15"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="css-i6dzq1"
                                >
                                  <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                  <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                </svg>
                                Open all Notifications
                              </Link>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>

                    <ul className="friend-notifications">
                      {notification?.map((item, index) => {
                        if (index > 2) return null;
                        return (
                          <li
                            className={
                              item?.isRead === "false"
                                ? "notification-item p-3 d-flex align-items-center active"
                                : "notification-item p-3 d-flex align-items-center"
                            }
                            key={index}
                          >
                            {item?.type === "unusual" ? (
                              item?.metaData?.user_profile?.profileImg ===
                              "user/profile-picture/null" ? (
                                <img
                                  src={"../assets/img/picture.png"}
                                  alt=""
                                  width="48"
                                  height="48"
                                  className="rounded-circle"
                                  onClick={() => {
                                    handleOpen(item);
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    Endpoints.baseUrl +
                                    item?.metaData?.user_profile?.profileImg
                                  }
                                  alt=""
                                  width="48"
                                  height="48"
                                  className="rounded-circle"
                                  onClick={() => {
                                    handleOpen(item);
                                  }}
                                />
                              )
                            ) : item?.type === "job_notification" ||
                              item?.type === "job_apply" ? (
                              item?.metaData?.notificationUser?.pageImage ===
                              "pages/null" ? (
                                <img
                                  src={"../assets/img/picture.png"}
                                  alt=""
                                  width="48"
                                  height="48"
                                  className="rounded-circle"
                                  onClick={() => {
                                    handleOpen(item);
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    Endpoints.baseUrl +
                                    item?.metaData?.notificationUser?.pageImage
                                  }
                                  alt=""
                                  width="48"
                                  height="48"
                                  className="rounded-circle"
                                  onClick={() => {
                                    handleOpen(item);
                                  }}
                                />
                              )
                            ) : item?.metaData?.notificationUser?.user_profile
                                ?.profileImg === "user/profile-picture/null" ? (
                              <img
                                src={"../assets/img/picture.png"}
                                alt=""
                                width="48"
                                height="48"
                                className="rounded-circle"
                                onClick={() => {
                                  handleOpen(item);
                                }}
                              />
                            ) : (
                              <img
                                src={
                                  Endpoints.baseUrl +
                                  item?.metaData?.notificationUser?.user_profile
                                    ?.profileImg
                                }
                                alt=""
                                width="48"
                                height="48"
                                className="rounded-circle"
                                onClick={() => {
                                  handleOpen(item);
                                }}
                              />
                            )}
                            <div className="d-flex align-items-start justify-content-between w-100 ps-2">
                              <Link
                                onClick={() => {
                                  handleOpen(item);
                                }}
                              >
                                <div>
                                  <h6 className="medium text-black">
                                    {item?.metaData?.notificationUser?.fullName}
                                  </h6>
                                  <h6 className="fs-12 regular text-darkgray">
                                    {item?.message}
                                  </h6>
                                  <h6 className="fs-10 regular text-darkgray">
                                    {timeSince(new Date(item.createdAt))}
                                  </h6>
                                </div>
                              </Link>
                              <div className="position-relative text-end">
                                <a
                                  href="#"
                                  onClick={() => {
                                    handleNotificationClick(item?.id);
                                  }}
                                  className="d-flex align-items-center justify-content-center "
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="18"
                                    height="18"
                                    stroke="#000"
                                    strokeWidth="2"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="css-i6dzq1"
                                  >
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="19" cy="12" r="1"></circle>
                                    <circle cx="5" cy="12" r="1"></circle>
                                  </svg>
                                </a>
                                {dropdownOpen == item?.id && (
                                  <ul className="popup-menu text-start show shadow dropdown-menu rounded-1 w-auto mt-0">
                                    {item?.isRead === "false" ? (
                                      <li className="py-1">
                                        <a
                                          className="dropdown-item py-2 d-flex gap-2"
                                          href="#"
                                          onClick={() => {
                                            handleReadNotification(item?.id);
                                          }}
                                        >
                                          <svg
                                            viewBox="0 0 24 24"
                                            width="15"
                                            height="15"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="css-i6dzq1"
                                          >
                                            <polyline points="20 6 9 17 4 12"></polyline>
                                          </svg>
                                          Mark as Read
                                        </a>
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                    <li className="py-1">
                                      <a
                                        className="dropdown-item py-2 d-flex gap-2"
                                        href="#"
                                        onClick={() => {
                                          handleRemove(item?.id);
                                        }}
                                      >
                                        <svg
                                          viewBox="0 0 24 24"
                                          width="14"
                                          height="14"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          fill="none"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="css-i6dzq1"
                                        >
                                          <polyline points="3 6 5 6 21 6"></polyline>
                                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                          <line
                                            x1="10"
                                            y1="11"
                                            x2="10"
                                            y2="17"
                                          ></line>
                                          <line
                                            x1="14"
                                            y1="11"
                                            x2="14"
                                            y2="17"
                                          ></line>
                                        </svg>
                                        Delete
                                      </a>
                                    </li>
                                  </ul>
                                )}
                                {item?.isRead === "false" ? (
                                  <span className="user-active ">
                                    <svg
                                      viewBox="0 0 24 24"
                                      className="mt-3"
                                      width="12"
                                      height="12"
                                      stroke="#0C64C5"
                                      strokeWidth="2"
                                      fill="#0C64C5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <circle cx="12" cy="12" r="10"></circle>
                                    </svg>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    {notification.length > 0 ? (
                      <div className="border-top pt-3 pb-2 text-center">
                        <Link
                          to={"/notifications"}
                          className="btn fs-12 regular py-2 px-4"
                        >
                          <span className="d-block px-2">
                            See all notifications
                          </span>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="icon-wrapper header-icons">
                  {/* <a href="#" id="user_request" className="head-icon" data-bs-toggle="dropdown"> */}
                  <a
                    href="#"
                    id="user_request"
                    className={
                      FriendRequest.length > 0 ? "head-icon" : "gray-head-icon"
                    }
                    // className="gray-head-icon"

                    // data-bs-toggle={FriendRequest.length > 0 ? "dropdown" : ""}
                    data-bs-toggle={"dropdown"}
                  >
                    {FriendRequest?.length > 0 ? (
                      <img src="../assets/img/icon/icon-request.svg" alt="" />
                    ) : (
                      <img
                        src="../assets/img/icon/gray-users-icon.svg"
                        alt=""
                      />
                    )}
                    {FriendRequest?.length > 0 ? (
                      <span className="count">{FriendRequest?.length}</span>
                    ) : (
                      ""
                    )}
                  </a>
                  {FriendRequest?.length > 0 ? (
                    <div
                      className="dropdown-menu haspopup"
                      aria-labelledby="user_request"
                    >
                      <div className="px-3 py-2 border-bottom">
                        <h5 className="semibold">
                          Connection Requests ({FriendRequest?.length})
                        </h5>
                      </div>

                      <ul className="friend-request">
                        {FriendRequest?.map((item, index) => {
                          if (index > 2) return null;
                          return (
                            <li
                              className="request-item p-3 d-flex align-items-start"
                              key={index}
                            >
                              <Link to={"/timeline/" + item?.username}>
                                {item?.user_profile?.profileImg === null ? (
                                  <img
                                    src="../assets/img/picture.png"
                                    alt=""
                                    width="48"
                                    height="48"
                                    className="rounded-circle"
                                  />
                                ) : (
                                  <img
                                    src={
                                      Endpoints.baseUrl +
                                      item?.user_profile?.profileImg
                                    }
                                    alt=""
                                    width="48"
                                    height="48"
                                    className="rounded-circle"
                                  />
                                )}
                              </Link>
                              <div className="ps-2">
                                <Link to={"/timeline/" + item.username}>
                                  <h6 className="medium text-black">
                                    {item?.fullName}
                                  </h6>
                                </Link>
                                <h6 className="fs-10 regular text-darkgray py-1">
                                  {item?.mutualFriends} mutual friends
                                </h6>

                                <div className="d-flex request-btn gap-2 pt-1">
                                  <Link
                                    className="btn  light-brown-btn"
                                    onClick={() => {
                                      FriendRequestResponse(
                                        item.requestId,
                                        "approve"
                                      );
                                    }}
                                  >
                                    Accept
                                  </Link>
                                  <Link
                                    className="btn gray-btn"
                                    onClick={() => {
                                      FriendRequestResponse(
                                        item.requestId,
                                        "reject"
                                      );
                                    }}
                                  >
                                    Decline
                                  </Link>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                      {FriendRequest.length > 3 ? (
                        <div className="border-top pt-3 pb-2 text-center">
                          <Link
                            to={"/friend-request/" + userId}
                            className="btn fs-12 regular py-2 px-4"
                          >
                            <span className="d-block px-2">
                              See all requests
                            </span>
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div
                      className="dropdown-menu haspopup py-5"
                      aria-labelledby="user_request"
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        No request's available!!
                      </div>
                    </div>
                  )}
                </div>

                <div className="icon-wrapper header-icons">
                  {/* <a href="#" id="user_messages" className="head-icon" data-bs-toggle="dropdown"> */}
                  {/* <a href="#" id="user_messages" className="gray-head-icon" data-bs-toggle="dropdown"> */}
                  <a
                    href="#"
                    id="user_messages"
                    className={
                      unreadMessageCount > 0 ? "head-icon" : "gray-head-icon"
                    }
                    data-bs-toggle="dropdown"
                  >
                    <img
                      src={
                        unreadMessageCount > 0
                          ? `${process.env.PUBLIC_URL}/assets/svgs/message-active.svg`
                          : "/assets/img/icon/gray-chat.svg"
                      }
                      alt=""
                    />
                    {unreadMessageCount > 0 && (
                      <span className="count">{unreadMessageCount}</span>
                    )}
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="user_messages"
                  >
                    <div className="d-flex align-items-center justify-content-between px-3 py-2 border-bottom">
                      <h5 className="semibold">
                        Messages <span>({unreadMessageCount})</span>
                      </h5>
                    </div>

                    <ul className="user-messages">
                      {chats.map((chat, index) => {
                        // rendering only 3
                        if (index > 2) return null;

                        const isOnline =
                          chat.roomType !== "course" && checkOnlineStatus(chat);

                        const otherChatUserInRoom =
                          chat?.chat_room_members?.find(
                            (searchUser) => searchUser.user.id !== user.id
                          );
                        let selectedPicture = `${process.env.PUBLIC_URL}/assets/img/picture.png`;
                        if (chat.roomType !== "course" && otherChatUserInRoom) {
                          selectedPicture =
                            Endpoints.baseUrl +
                            otherChatUserInRoom?.user.user_profile?.profileImg;
                        }

                        if (chat.thumbnail) {
                          selectedPicture = Endpoints.baseUrl + chat.thumbnail;
                        }

                        let groupImage = null;

                        if (chat.roomName !== null) {
                          const person1 = chat.chat_room_members[0];
                          const person2 = chat.chat_room_members[1];

                          groupImage = (
                            <div className="group-chat-image-container">
                              <img
                                src={`${
                                  Endpoints.baseUrl +
                                  person1?.user?.user_profile?.profileImg
                                }`}
                                alt=""
                                width="36"
                                height="36"
                                className="group-chat-image"
                              />
                              <img
                                src={`${
                                  Endpoints.baseUrl +
                                  person2?.user?.user_profile?.profileImg
                                }`}
                                alt=""
                                width="36"
                                height="36"
                                className="group-chat-image"
                              />
                            </div>
                          );
                        }

                        return (
                          <li
                            key={chat.id}
                            className="message-item p-3 d-flex align-items-center cursor-pointer"
                            onClick={() => handleAddChat(chat)}
                          >
                            <div className="position-relative">
                              {chat.roomName !== null ? (
                                groupImage
                              ) : selectedPicture ? (
                                <img
                                  src={selectedPicture}
                                  alt=""
                                  width="48"
                                  height="48"
                                  className="rounded-circle"
                                />
                              ) : (
                                <img
                                  src="/assets/img/picture.png"
                                  alt=""
                                  width="48"
                                  height="48"
                                  className="rounded-circle"
                                />
                              )}
                              {isOnline && <IsOnline />}
                            </div>
                            <div className="ps-2 w-100">
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <h6 className="medium text-black">
                                  {chat.roomName ||
                                    otherChatUserInRoom?.user?.fullName}
                                </h6>
                                {isOnline && (
                                  <h6 className="fs-12 regular text-darkgray">
                                    {" "}
                                    Active Now
                                  </h6>
                                )}
                              </div>

                              <div className="d-flex align-items-center justify-content-between w-100 mt-1">
                                <h6 className="fs-12 regular text-darkgray">
                                  {chat.lastMessage?.message}
                                </h6>
                                {chat.unreadMsgCount > 0 && (
                                  <span className="user-active">
                                    {chat.unreadMsgCount > 100
                                      ? "100+"
                                      : chat.unreadMsgCount}
                                  </span>
                                )}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>

                    <div className="border-top pt-3 pb-2 text-center">
                      <Link
                        to="/friend/messages"
                        className="btn fs-12 regular py-2 px-4"
                      >
                        <span className="d-block px-2">See all messages</span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="icon-wrapper header-icons">
                  <Link
                    to="/cart"
                    id="user_cart"
                    className={
                      cart.length > 0 ? "head-icon " : "gray-head-icon"
                    }
                  >
                    {cart.length > 0 ? (
                      <img src="/assets/img/icon/icon-cart.svg" alt="" />
                    ) : (
                      <img src="/assets/img/icon/cart-gray.svg" alt="" />
                    )}

                    {refreshCartcount === true
                      ? cartCountRender()
                      : cartCountRender()}
                  </Link>
                </div>
              </div>
              {/* {user.usersType === "Teacher" ?
                            <Link to="/dashboard" className="btn btn-light-blue mx-lg-4 mx-sm-3 mx-2  medium dashboard-btn">
                                <img src="/assets/img/icon/icon-dashboard.svg" alt="" className="" /> Dashboard
                            </Link>
                            : 
                            <Link to="/student-dashboard" className="btn btn-light-blue mx-lg-4 mx-sm-3 mx-2  medium dashboard-btn">
                            <img src="/assets/img/icon/icon-dashboard.svg" alt="" className="" /> Dashboard
                            </Link>

                            } */}

              <div className="d-flex align-items-center justify-content-end px-3">
                <a
                  href="#"
                  className=""
                  id="head_profile"
                  data-bs-toggle="dropdown"
                >
                  {userProfile == "null" ||
                  userProfile == null ||
                  userProfile == "" ||
                  userProfile === "undefined" ? (
                    <img
                      src="/assets/img/picture.png"
                      alt=""
                      width="40"
                      height="40"
                      className="rounded-circle"
                    />
                  ) : (
                    <img
                      src={Endpoints.baseUrl + userProfile}
                      alt=""
                      width="40"
                      height="40"
                      className="rounded-circle"
                    />
                  )}
                  <span className="fs-12 medium ps-2 text-black d-none d-xl-inline-block">
                    {user?.fullName}
                  </span>
                  <svg
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    stroke="#000"
                    className="transition"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    // className="css-i6dzq1"
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </a>

                <div
                  className="profile-dropdown dropdown-menu dropdown-menu-end"
                  aria-labelledby="head_profile"
                >
                  <div className="p-2 text-center">
                    <span className="d-block text-center">
                      {/* <img src="../ assets/img/picture.png" className="rounded-circle" width="64" height="64" alt="" /> */}
                      {userProfile == "null" ||
                      userProfile == null ||
                      userProfile == "" ||
                      userProfile === "undefined" ? (
                        <img
                          src="/assets/img/picture.png"
                          className="rounded-circle"
                          width="64"
                          height="64"
                          alt=""
                        />
                      ) : (
                        <img
                          src={Endpoints.baseUrl + userProfile}
                          className="rounded-circle"
                          width="64"
                          height="64"
                          alt=""
                        />
                      )}
                    </span>
                    <h6 className="py-1 medium">{user?.fullName}</h6>
                    <h6 className="pb-2 fs-12 text-darkgray regular">
                      {user?.user_profile?.profileTagline}
                    </h6>
                    <div
                      className="btn regular fs-12 py-1 px-4 mb-1"
                      // to={'/timeline/' + userId}
                      onClick={() => {
                        handleProfile();
                      }}
                    >
                      <span className="px-2">View Profile</span>
                    </div>
                    {/* <a href="#" className="btn regular fs-12 py-1 px-4 mb-1"><span className="px-2">View Profile</span></a> */}
                  </div>

                  <div className="p-3 border-top">
                    <h5 className="medium pb-3">Account</h5>
                    <h6 className="medium pb-3">
                      <Link to="/updateprofile">
                        <img
                          src="/assets/img/icon/icon-edit-profile.svg"
                          className="pe-2"
                          alt=""
                        />
                        Edit Profile
                      </Link>
                    </h6>
                    <h6 className="medium pb-3">
                      <Link to="/profile-visibility">
                        <img
                          src="/assets/img/icon/icon-profile-privacy.svg"
                          className="pe-2"
                          alt=""
                        />
                        Profile Privacy
                      </Link>
                    </h6>
                    <h6 className="medium">
                      <Link to={"/select-profile/" + parentData?.id}>
                        <img
                          src="/assets/img/icon/icon-user-switch.svg"
                          className="pe-2"
                          alt=""
                        />
                        {childCount > 0 ? "Switch" : "Add"} Profile
                      </Link>
                    </h6>
                  </div>

                  <div className="p-3 border-top">
                    <h5 className="medium pb-3">Courses</h5>

                    {instructorStatus === "Complete" ? (
                      <h6 className="medium pb-3">
                        <Link to="/dashboard">
                          <img
                            src="/assets/img/icon/icon-dashboard-black.svg"
                            className="pe-2"
                            alt=""
                          />
                          Teacher Dashboard
                        </Link>
                      </h6>
                    ) : (
                      <h6 className="medium pb-3">
                        <Link to="/student-dashboard">
                          <img
                            src="/assets/img/icon/icon-dashboard-black.svg"
                            className="pe-2"
                            alt=""
                          />
                          Student Dashboard
                        </Link>
                      </h6>
                    )}

                    {instructorStatus === "Complete" ? (
                      <h6 className="medium">
                        <Link to="/student-dashboard">
                          <img
                            src="/assets/img/icon/icon-course.svg"
                            className="pe-2"
                            alt=""
                          />
                          Student Dashboard
                        </Link>
                      </h6>
                    ) : (
                      <h6 className="medium">
                        <Link to="/become-instructor">
                          <img
                            src="/assets/img/icon/icon-course.svg"
                            className="pe-2"
                            alt=""
                          />
                          Become Instructor
                        </Link>
                      </h6>
                    )}
                    <h6 className="medium pt-3">
                      <Link to="/cart">
                        <img
                          src="/assets/img/icon/icon-cart-black.svg"
                          className="pe-2"
                          alt=""
                        />
                        Cart
                      </Link>
                    </h6>
                  </div>

                  <div className="p-3 border-top">
                    <h6 className="medium pb-3">
                      <Link to={"/change-password"}>
                        <img
                          src="/assets/img/icon/icon-settings.svg"
                          className="pe-2"
                          alt=""
                        />
                        Settings
                      </Link>
                    </h6>
                    <h6 className="medium pb-3">
                      <a href="#">
                        <img
                          src="/assets/img/icon/icon-help-support.svg"
                          className="pe-2"
                          alt=""
                        />
                        Help & Support
                      </a>
                    </h6>
                    {/* <h6 className="medium">
                      <a href="#">
                        <img
                          src="/assets/img/icon/icon-feedback.svg"
                          className="pe-2"
                          alt=""
                        />
                        Give Feedback
                      </a>
                    </h6> */}
                  </div>

                  <div className="p-3 border-top">
                    <h6 className="medium">
                      <Link
                        to="/"
                        onClick={handleLogout}
                        className="logout-link"
                      >
                        <img
                          src="/assets/img/icon/icon-logout.svg"
                          className="pe-2"
                          alt=""
                        />
                        Logout
                      </Link>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default CustomHeader;
