import React, { useCallback, useState, useEffect } from "react";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DiscardPopup from "../../Components/DiscardPopup/DiscardPopup";
import Select from "react-select";
const $ = window.jQuery;
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

export default function InterestsModel({ onSuccess, totalInterest }) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [allInterests, setAllInterests] = useState([]);
  const [interestError, setInterestError] = useState("");
  const [interestsError, setinterestsError] = useState("");
  const [interestsName, setinterestsName] = useState("");
  const [interestsKey, setinterestsKey] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [defaultInterest, setDefaultInterest] = useState(-50);
  const Navigate = useNavigate();
  const location = useLocation();
  const param = useParams();

  useEffect(() => {
    if (selectedOptions.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedOptions]);
  useEffect(() => {
    getAllIntersts();
  }, [param?.id]);

  const getAllIntersts = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetAllInterests);
      if (resp && resp.success === true) {
        let ints = [];
        for (let i = 0; i < resp.data.length; i++) {
          ints.push({
            value: resp.data[i].id,
            label: resp.data[i].interestTitle,
          });
        }
        setAllInterests(ints);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSelect = async (e) => {
    console.log(selectedOptions);
    e.preventDefault();
    if (selectedOptions.length === 0) {
      setinterestsError("Please select the interests ");
    } else if (selectedOptions.length > 0) {
      setinterestsError("");
    }
    // if (!category) {
    //     setInterestError("Please select the interests ");
    // } else if (category) {
    //     setInterestError("");

    // }

    if (selectedOptions.length > 0) {
      const interestId = [];
      for (let i = 0; i < selectedOptions.length; i++) {
        interestId.push(selectedOptions[i].value);
      }
      // let interestdata = selectedOptions.map((item) => {
      //     return (
      //         {
      //             id: item.id,
      //             name: item.name,
      //             category: item.category
      //         }
      //     );
      // });
      let data = {
        interestId: interestId,
      };
      try {
        let resp = await CommonAPi(Endpoints.CreateInterests, data);
        if (resp && resp.message) {
          const user = JSON.parse(localStorage.getItem("user"));
          user.hasInterest = true;
          localStorage.setItem("user", JSON.stringify(user));
          $("#react-select-4-input").val(null).trigger("change");
          document.getElementById("interestform").reset();
          setOptions([]);
          setSelectedOptions([]);
          setCategory("");
          setinterestsName("");
          setinterestsKey(Math.ceil(Math.random() * 999999));
          $("#Interestsmodal").modal("hide");
          onSuccess(resp);
          ToastMessage.Success(resp.message);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleValidation = (e, value, field) => {
    e.preventDefault();
    if (!value && field === "category") {
      setInterestError("Please select the interests ");
    } else if (value && field === "category") {
      setInterestError("");
    }
    if (!value && field === "interests") {
      if (selectedOptions.length === 0) {
        setinterestsError("Please select the interests");
      } else {
        setinterestsError("");
      }
    } else if (value && field === "interests") {
      setinterestsError("");
    }
  };

  const handleOnSelect = (item) => {
    if (item.length > 0) {
      setinterestsName(item[0].name);
      if (selectedOptions.filter((ite) => ite.id === item[0].id).length > 0) {
        ToastMessage.Info("Already added this interest");
      } else {
        setSelectedOptions([...selectedOptions, ...item]);
      }
      setinterestsName("");
      setinterestsKey(Math.ceil(Math.random() * 999999));
    }
  };

  const handleOnSearch = async (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log("string____", string);
    if (!category) {
      setInterestError("Please select the interests ");
    } else if (category) {
      setInterestError("");
    }

    if (string && category) {
      try {
        let resp = await FetchApi(
          Endpoints.SearchInterests + "?q=" + string + "&cat=" + category
        );
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                name: item?.interestTitle,
              };
            });
            setOptions(newData);
          } else {
            const newData = [
              {
                id: defaultInterest,
                name: string,

                category: category,
              },
            ];

            setOptions(newData);
            setDefaultInterest(parseInt(defaultInterest) + 1);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleDeleteInterest = (id) => {
    const newselectedOptions = selectedOptions.filter(
      (item, index) => item.id !== id
    );
    setSelectedOptions(newselectedOptions);
  };

  const handleCloseModel = () => {
    if (
      category !== "" ||
      selectedOptions.length !== 0 ||
      interestsName !== ""
    ) {
      $("#discardInfoModal-Interests").modal("show");
    }
  };

  const handleDisCard = () => {
    document.getElementById("interestform").reset();
    setSelectedOptions([]);
    setOptions([]);
    setCategory("");
    setinterestsName("");
    setInterestError("");
    setinterestsError("");
    setinterestsKey(Math.ceil(Math.random() * 999999));
    $("#discardInfoModal-Interests").modal("hide");
    $("#Interestsmodal").modal("hide");
  };

  const handleModel = () => {
    $("#discardInfoModal-Interests").modal("hide");
    $("#Interestsmodal").modal("show");
  };
  return (
    <>
      <div
        className="modal fade"
        id="Interestsmodal"
        tabIndex="-1"
        aria-labelledby="Interestsmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog rounded-10 box-shadow p-lg-2 p-md-2 p-2 bg-white">
          <div className="modal-content border-0">
            <div className="d-flex align-items-center justify-content-between pb-3 border-bottom mb-3 w-100">
              {location.pathname.split("/")[1] === "profile-about" ? (
                <h3 className="medium text-blue">Add Interest</h3>
              ) : (
                <h5 className="medium text-blue">
                  {totalInterest == 0
                    ? "Choose at least 5 interests to proceed"
                    : totalInterest < 5
                    ? `Choose ${5 - totalInterest} more interests to proceed`
                    : "Add more Interests or click on Next to proceed"}
                </h5>
              )}
              <button
                type="button"
                onClick={handleCloseModel}
                className="btn-close kids-close-icon Interests cursor-pointer"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#949494"
                  width="22"
                  height="22"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="modal-body p-0">
              <form
                className="generic-form"
                id="interestform"
                onSubmit={handleSelect}
              >
                <div className="row">
                  <div className="form-field">
                    <label>Select Interests</label>
                    <Select
                      value={selectedOptions}
                      onChange={setSelectedOptions}
                      options={allInterests}
                      isMulti
                      className={"border"}
                      placeholder="Select Interests"
                      closeMenuOnSelect={selectedOptions.length >= 4}
                    />
                    {interestError && (
                      <div className="form-error">{interestError}</div>
                    )}
                  </div>

                  <div className="interests-section">
                    <div className="inner-section mt-2">
                      {/* {selectedOptions?.map((item, index) => {
                                                return (
                                                    <span className="medium fs-14 mb-1 d-inline-block">
                                                        {item.name}
                                                        <Link onClick={() => { handleDeleteInterest(item.id) }} className="closebtn">
                                                            <img src="../assets/img/icon/icon-close-cancel.svg" alt="" />
                                                        </Link>
                                                    </span>
                                                );
                                            })} */}
                    </div>
                  </div>
                  <div className="col-md-12 text-end mt-5">
                    <button
                      disabled={isDisabled}
                      type="submit"
                      className="btn fs-14 "
                    >
                      {" "}
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <DiscardPopup
        {...{
          type: "Interests",
          onSuccess: handleModel,
          onDisCard: handleDisCard,
        }}
      />
    </>
  );
}
