import React, { useState, useEffect, useCallback } from "react";
import { Endpoints } from "../../../API/Endpoints";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import { FetchApi } from "../../../API/FetchApi";
import { CommonAPi } from "../../../API/CommonApi";
import { DeleteApi } from "../../../API/DeleteApi";
import ToastMessage from "../../../Utils/ToastMessage";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import ReviewItem from "../../Teachers/ReviewItem";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import PostLoader from "../../../Components/PostLoader/PostLoader";
import Moment from "moment";
import { Helmet } from "react-helmet";
import CustomStarRating from "../../../Components/CustomStarRating/CustomStarRating";

export default function CoursesDetail() {
  const param = useParams();
  const [data, setData] = useState("");
  const [relatedCourses, setRelatedCourses] = useState([]);
  const [purcahsed, setPurcahsed] = useState(false);
  const [videoStatus, setVideoStatus] = useState(false);
  const [rating, setRating] = useState(null);
  const Navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [activeNavigation, setActiveNavigation] = useState("objective");
  const shareUrl = window.location.origin + "/course-detail/" + param?.id;
  const getCourseDetails = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetSingleCourses + "/" + param?.id);
      if (resp && resp.success === true) {
        setPurcahsed(resp.data.course.hasPurchased);

        if (resp?.data?.relatedCourses.length > 0) {
          setRelatedCourses(resp.data.relatedCourses);
        }
        setRating(resp.data.course.rating.avgRating);
        setData(resp.data.course);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleAddToCart = async (Id) => {
    const obj = {
      course_id: Id,
    };

    try {
      let resp = await CommonAPi(Endpoints.AddToCart, obj);
      if (resp && resp.message) {
        Navigate("/cart");
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else if (
          e.response.data.message === "COURSE ALREADY ADDED THE CART"
        ) {
          Navigate("/cart");
          ToastMessage.Success(e.response.data.message);
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleWishlist = async (Id) => {
    const obj = {
      course_id: Id,
    };

    try {
      let resp = await CommonAPi(Endpoints.AddToWishlist, obj);
      if (resp && resp.message) {
        ToastMessage.Success(resp.message);
        getCourseDetails();
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  useEffect(() => {
    getCourseDetails();
  }, []);

  const handleRemoveWishlist = async (Id) => {
    try {
      let resp = await DeleteApi(Endpoints.RemoveToWishlist + "/" + Id);
      if (resp && resp.message) {
        getCourseDetails();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  function printTime(seconds) {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const remainingSeconds = seconds % 60;
    const parts = [];

    if (days > 0) {
      parts.push(`${days} day${days > 1 ? "s" : ""}`);
    }
    if (hours > 0) {
      parts.push(`${hours} hr${hours > 1 ? "s" : ""}`);
    }
    if (minutes > 0) {
      parts.push(`${minutes} min${minutes > 1 ? "s" : ""}`);
    }
    if (remainingSeconds > 0) {
      parts.push(`${remainingSeconds} sec${remainingSeconds > 1 ? "s" : ""}`);
    }

    if (parts.length === 0) {
      return "0 sec";
    } else {
      return parts.join(", ");
    }
  }

  const handleEnrollFreeCourse = async () => {
    const obj = {
      course_id: data?.id,
    };

    try {
      let resp = await CommonAPi(Endpoints.EnrollCourse, obj);
      if (resp && resp.message) {
        setPurcahsed(true);
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleCopy = () => {
    const url = window.location.origin + "/course-detail" + "/" + param.id;
    navigator.clipboard.writeText(url);
    ToastMessage.Success("Link copied successfully");
  };

  const handleCapyCouponCode = (code) => {
    navigator.clipboard.writeText(code);
    ToastMessage.Success("Coupon code copied successfully");
  };

  return (
    <Container header>
      <Helmet>
        <title>Course Detail</title>
      </Helmet>
      {data ? (
        <div className="wrapper wrapper-bg">
          <div className="main">
            <LeftNavigation />

            {/* {courseDetails.map((data, i) => {
                        return ( */}
            <div className="container py-3">
              <div className="bg-white rounded-10 box-shadow p-3 mb-3">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <div className="feed-video position-relative">
                      {/* <img src={Endpoints.baseUrl + data.thumbnail} alt="" width="48" height="48" className="w-100 h-100" /> */}
                      {videoStatus === false ? (
                        <>
                          <img
                            src={Endpoints.baseUrl + data.thumbnail}
                            className="play-video w-100 h-100 align-items-center justify-content-center top-0"
                          />
                          <img
                            src="../assets/img/icon/icon-play-video4.svg"
                            onClick={() => {
                              setVideoStatus(true);
                            }}
                            style={{
                              position: "absolute",
                              zIndex: 10,
                              left: "40%",
                              top: "40%",
                              cursor: "pointer",
                            }}
                          />
                        </>
                      ) : (
                        <video
                          className="play-video w-100 h-100 align-items-center justify-content-center top-0"
                          controls
                          autoplay
                        >
                          <source
                            src={Endpoints.baseUrl + data.trailor}
                            type="video/mp4"
                          />
                          <source
                            src={Endpoints.baseUrl + data.trailor}
                            type="video/ogg"
                          />
                        </video>
                      )}
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h3
                      className="medium pb-3"
                      style={{
                        wordBreak: "break-all",
                      }}
                    >
                      {data.title}
                    </h3>
                    <h5
                      style={{
                        wordBreak: "break-all",
                      }}
                    >
                      {data.subtitle}
                    </h5>

                    <div className="py-3 d-flex w-100 align-items-center fs-14 ">
                      <Link
                        className="d-flex align-items-center"
                        onClick={() => {
                          setActiveNavigation("rating");
                        }}
                      >
                        <span className="text-brown mt-1 ">{rating}</span>

                        <div className="d-flex mx-2">
                          {data?.rating?.avgRating !== null ? (
                            <ReactStars
                              count={5}
                              edit={false}
                              value={data?.rating?.avgRating}
                              size={24}
                              isHalf={true}
                              char={
                                <img
                                  src="../assets/img/icon/state-null.svg"
                                  width={18}
                                  alt=""
                                />
                              }
                              emptyIcon={
                                <img
                                  src="../assets/img/icon/state-null.svg"
                                  width={18}
                                  alt=""
                                />
                              }
                              halfIcon={
                                <img
                                  src="../assets/img/icon/state-half.svg"
                                  width={18}
                                  alt=""
                                />
                              }
                              filledIcon={
                                <img
                                  src="../assets/img/icon/State-fill.svg"
                                  width={18}
                                  alt=""
                                />
                              }
                              activeColor="#ffc107"
                            />
                          ) : (
                            <ReactStars
                              count={5}
                              edit={false}
                              value={0}
                              size={24}
                              isHalf={true}
                              char={
                                <img
                                  src="../assets/img/icon/state-null.svg"
                                  width={18}
                                  alt=""
                                />
                              }
                              emptyIcon={
                                <img
                                  src="../assets/img/icon/state-null.svg"
                                  width={18}
                                  alt=""
                                />
                              }
                              halfIcon={
                                <img
                                  src="../assets/img/icon/state-half.svg"
                                  width={18}
                                  alt=""
                                />
                              }
                              filledIcon={
                                <img
                                  src="../assets/img/icon/State-fill.svg"
                                  width={18}
                                  alt=""
                                />
                              }
                              activeColor="#ffc107"
                            />
                          )}
                        </div>
                        <span className="text-brown mt-1">
                          ({data?.rating?.totalRating} reviews)
                        </span>
                      </Link>
                      <span className="medium text-black ps-3 mt-2">
                        {data.totalStudents}{" "}
                        <span className="text-gray">Students</span>
                      </span>
                    </div>

                    <h6 className="regular  fs-15">
                      Created by{" "}
                      <span className="text-brown">
                        <Link
                          to={
                            "/instructor-profile/" + data?.creatorId?.username
                          }
                          className="text-brown"
                        >
                          {data?.creatorId?.fullName}
                        </Link>{" "}
                      </span>
                    </h6>
                    <h6 className="regular  fs-15 mt-3">
                      {" "}
                      <i class="fa fa-info-circle"></i> Last updated on{" "}
                      {Moment(data?.updatedAt).format("D MMM, Y")}
                    </h6>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-10 box-shadow pt-3 px-2 mb-3">
                <ul className="course-dashboard-menu profile-menu d-flex flex-wrap justify-content-between">
                  <li
                    className={activeNavigation === "objective" ? "active" : ""}
                  >
                    <Link
                      onClick={() => {
                        setActiveNavigation("objective");
                      }}
                    >
                      What You’ll Learn
                    </Link>
                  </li>

                  <li
                    className={
                      activeNavigation === "description" ? "active" : ""
                    }
                  >
                    <Link
                      onClick={() => {
                        setActiveNavigation("description");
                      }}
                    >
                      Description
                    </Link>
                  </li>

                  <li
                    className={activeNavigation === "audience" ? "active" : ""}
                  >
                    <Link
                      onClick={() => {
                        setActiveNavigation("audience");
                      }}
                    >
                      Who This Course For
                    </Link>
                  </li>

                  <li
                    className={
                      activeNavigation === "requirement" ? "active" : ""
                    }
                  >
                    <Link
                      onClick={() => {
                        setActiveNavigation("requirement");
                      }}
                    >
                      Requirements
                    </Link>
                  </li>

                  <li
                    className={activeNavigation === "content" ? "active" : ""}
                  >
                    <Link
                      onClick={() => {
                        setActiveNavigation("content");
                      }}
                    >
                      Course Content
                    </Link>
                  </li>

                  <li
                    className={
                      activeNavigation === "instructor" ? "active" : ""
                    }
                  >
                    <Link
                      onClick={() => {
                        setActiveNavigation("instructor");
                      }}
                    >
                      Instructor
                    </Link>
                  </li>

                  <li className={activeNavigation === "rating" ? "active" : ""}>
                    <Link
                      onClick={() => {
                        setActiveNavigation("rating");
                      }}
                    >
                      Course Rating
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="row">
                <div className="col-md-9">
                  {activeNavigation === "objective" ? (
                    <div
                      className="bg-white rounded-15 py-3 px-3 mb-3"
                      id="learn"
                    >
                      <h4 className="pb-3 medium">What You’ll Learn</h4>
                      <div className="row listing-arrow">
                        {data?.objective !== null &&
                          data?.objective?.map((item, i) => {
                            return (
                              <React.Fragment key={i}>
                                {i % 2 === 0 ? (
                                  <div className="col-md-6 fs-14">
                                    <ul key={i}>
                                      <li className="d-flex gap-2 align-items-start pb-3">
                                        <img
                                          src="../assets/img/icon/icon-check-round-sm.svg"
                                          alt=""
                                        />
                                        {item}
                                      </li>
                                    </ul>
                                  </div>
                                ) : (
                                  <div className="col-md-6 fs-14">
                                    <ul key={i}>
                                      <li className="d-flex gap-2 align-items-start pb-3">
                                        <img
                                          src="../assets/img/icon/icon-check-round-sm.svg"
                                          alt=""
                                        />
                                        {item}
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {activeNavigation === "description" ? (
                    <div
                      className="bg-white rounded-15 py-3 px-3 mb-3"
                      id="description"
                    >
                      <h4 className="pb-3 medium">Description</h4>
                      <div className="fs-14">
                        <p
                          className="ck"
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {activeNavigation === "audience" ? (
                    <div
                      className="bg-white rounded-15 py-3 px-3 mb-3"
                      id="audience"
                    >
                      <h4 className="pb-3 medium">Who This Course For</h4>
                      <div className="row fs-14 course-listing">
                        {data?.audience !== null &&
                          data?.audience?.map((item, i) => {
                            return (
                              <React.Fragment key={i}>
                                {i % 2 === 0 ? (
                                  <div className="col-md-6">
                                    <ul key={i}>
                                      <li>{item}</li>
                                    </ul>
                                  </div>
                                ) : (
                                  <div className="col-md-6">
                                    <ul key={i}>
                                      <li>{item}</li>
                                    </ul>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {activeNavigation === "requirement" ? (
                    <div
                      className="bg-white rounded-15 py-3 px-3 mb-3"
                      id="requirement"
                    >
                      <h4 className="pb-3">Requirements</h4>
                      <div className="row fs-14 course-listing">
                        {data?.audience !== null &&
                          data?.requirement?.map((item, i) => {
                            return (
                              <React.Fragment key={i}>
                                {i % 2 === 0 ? (
                                  <div className="col-md-6">
                                    <ul key={i}>
                                      <li>{item}</li>
                                    </ul>
                                  </div>
                                ) : (
                                  <div className="col-md-6">
                                    <ul key={i}>
                                      <li>{item}</li>
                                    </ul>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {activeNavigation === "content" ? (
                    <div
                      className="course-content-list bg-white rounded-15 py-3 px-3 mb-3"
                      id="content"
                    >
                      <div className="d-md-flex align-items-center justify-content-between pb-3">
                        <div className="pb-md-0 pb-2">
                          <h4 className="medium">Course Content</h4>
                        </div>
                        <div className="d-flex">
                          <h6 className="fs-14 me-3">
                            <img
                              src="../assets/img/icon/folder.svg"
                              className="me-2"
                              alt=""
                            />
                            {data.totalSections} Sections
                          </h6>
                          <h6 className="fs-14 me-3">
                            <img
                              src="../assets/img/icon/play.svg"
                              className="me-2"
                              alt=""
                            />
                            {data.totalLectures} Total Lectures
                          </h6>
                          <h6 className="fs-14 me-3">
                            <img
                              src="../assets/img/icon/time.svg"
                              className="me-2"
                              alt=""
                            />
                            {printTime(data.totalTimeLength)}
                          </h6>
                        </div>
                      </div>
                      {data?.course_sections !== null &&
                        data?.course_sections?.map((section, index) => {
                          return (
                            <div key={index} className="course-content-list">
                              <div id="accordion">
                                <div className="card border-0 border-bottom rounded-0">
                                  <div
                                    key={index}
                                    className="card-header px-0 d-md-flex justify-content-between bg-white border-0 py-3"
                                    id={"heading" + index}
                                  >
                                    <div>
                                      <h4
                                        className="mb-0 semibold"
                                        data-bs-toggle="collapse"
                                        data-bs-target={"#collapse" + index}
                                        aria-expanded="true"
                                        aria-controls="collapse1"
                                      >
                                        {section.sectionTitle}
                                      </h4>
                                    </div>
                                    <div className="d-flex">
                                      <h6 className="fs-14 me-3">
                                        <img
                                          src="../assets/img/icon/play.svg"
                                          className="me-2"
                                          alt=""
                                        />
                                        {section.totalLectures} Lectures
                                      </h6>
                                      <h6 className="fs-14 ">
                                        <img
                                          src="../assets/img/icon/time.svg"
                                          className="me-2"
                                          alt=""
                                        />
                                        {printTime(section.totalTimeLength)}
                                      </h6>
                                    </div>
                                  </div>
                                  {section?.course_lectures !== null &&
                                    section?.course_lectures?.map(
                                      (lecture, i) => {
                                        return (
                                          <div
                                            key={i}
                                            id={"collapse" + index}
                                            className="collapse show"
                                            aria-labelledby={"heading" + index}
                                            data-parent="#accordion"
                                          >
                                            <div className="card-body pe-0 pt-md-0 ps-md-4 text-darkgray">
                                              {lecture.lectureMedia !== null &&
                                              lecture.lectureMedia.includes(
                                                ".mp4"
                                              ) ? (
                                                <div
                                                  className="d-flex align-items-center justify-content-between pb-md-3 pb-2"
                                                  key={i}
                                                >
                                                  <h6 className="fs-14 me-3 text-darkgray">
                                                    <img
                                                      src="../assets/img/icon/gray-play.svg"
                                                      className="me-2"
                                                      alt=""
                                                    />
                                                    {lecture.lectureName}
                                                  </h6>
                                                  <h4 className="fs-14 text-darkgray">
                                                    {printTime(
                                                      lecture.totalTimeLength
                                                    )}
                                                  </h4>
                                                </div>
                                              ) : (
                                                <div
                                                  className="d-flex align-items-center justify-content-between pb-md-3 pb-2"
                                                  key={i}
                                                >
                                                  <h6 className="fs-14 me-3">
                                                    <img
                                                      src="../assets/img/icon/gray-d.svg"
                                                      className="me-2"
                                                      alt=""
                                                    />
                                                    {lecture.lectureName}
                                                  </h6>
                                                  <h4 className="fs-14">
                                                    {printTime(
                                                      lecture.totalTimeLength
                                                    )}
                                                  </h4>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    ""
                  )}

                  {activeNavigation === "instructor" ? (
                    <div
                      className="bg-white rounded-15 py-3 px-3 mb-3"
                      id="instructor"
                    >
                      <h4 className="medium pb-3">Instructor</h4>
                      {data?.instructors?.map((instruct, i) => {
                        return (
                          <div
                            className={
                              data.instructors.length === i + 1
                                ? "row pb-3"
                                : "row  border-bottom pb-3"
                            }
                          >
                            <div className="col-md-2">
                              {instruct.user_profile === null ? (
                                <img
                                  src="../assets/img/instuctor-img1.png"
                                  width="118"
                                  height="118"
                                  className="rounded-circle"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={
                                    Endpoints.baseUrl +
                                    instruct.user_profile.profileImg
                                  }
                                  width="118"
                                  height="118"
                                  className="rounded-circle"
                                  alt=""
                                />
                              )}
                            </div>
                            <div className="col-md-10">
                              <h4 className="pb-2 semibold">
                                {instruct.fullName}
                              </h4>
                              <h5 className="fs-14 text-darkgray pb-2">
                                {instruct.user_profile.profileTagline}
                              </h5>
                              <div className="d-md-flex align-items-center justify-content-between pb-2">
                                <h4 className="fs-14 regular">
                                  <img
                                    src="../assets/img/icon/star.svg"
                                    className="me-2"
                                    alt=""
                                  />{" "}
                                  {instruct.instructor_details.overallRating ===
                                  null
                                    ? 0
                                    : instruct?.instructor_details?.overallRating?.toFixed(
                                        2
                                      )}{" "}
                                  Instructor Rating
                                </h4>
                                <h4 className="fs-14 regular">
                                  <img
                                    src="../assets/img/icon/review.svg"
                                    className="me-2"
                                    alt=""
                                  />{" "}
                                  {instruct.instructor_details.totalReviews}{" "}
                                  Reviews
                                </h4>
                                <h4 className="fs-14 regular">
                                  <img
                                    src="../assets/img/icon/students.svg"
                                    className="me-2"
                                    alt=""
                                  />{" "}
                                  {instruct.instructor_details.totalStudents}{" "}
                                  Students
                                </h4>
                                <h4 className="fs-14 regular">
                                  <img
                                    src="../assets/img/icon/course-icon.svg"
                                    className="me-2"
                                    alt=""
                                  />{" "}
                                  {instruct.instructor_details.totalCourses}{" "}
                                  Courses
                                </h4>
                              </div>
                              <p className="fs-14 mb-0 pb-2 text-darkgray">
                                {instruct.user_profile?.aboutMe}
                              </p>
                              <Link
                                to={"/instructor-profile/" + instruct?.username}
                                className="text-blue fs-16 semibold"
                              >
                                View Profile
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}

                  {activeNavigation === "rating" ? (
                    <div
                      className="rounded-15 py-3 px-3 bg-white mb-3"
                      id="rating"
                    >
                      <h3 className="medium pb-3 fs-22">Course Ratings</h3>

                      <div>
                        <div className="row rating-box">
                          <div className="col-md-3 mb-md-3">
                            <div className="border px-2 py-3 text-center mb-3">
                              <h4 className="bold fs-1 my-2">
                                {data?.rating?.avgRating}
                              </h4>
                              <h6 className="regular fs-14 text-gray mt-2">
                                Out of 5
                              </h6>

                              <div className="course-rating d-flex gap-1 py-1 justify-content-center my-1">
                                {data?.rating?.avgRating !== null ? (
                                  <ReactStars
                                    count={5}
                                    edit={false}
                                    value={data?.rating?.avgRating}
                                    size={24}
                                    isHalf={true}
                                    char={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        width={18}
                                        alt=""
                                      />
                                    }
                                    emptyIcon={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        width={18}
                                        alt=""
                                      />
                                    }
                                    halfIcon={
                                      <img
                                        src="../assets/img/icon/state-half.svg"
                                        width={18}
                                        alt=""
                                      />
                                    }
                                    filledIcon={
                                      <img
                                        src="../assets/img/icon/State-fill.svg"
                                        width={18}
                                        alt=""
                                      />
                                    }
                                    activeColor="#ffc107"
                                  />
                                ) : (
                                  <ReactStars
                                    count={5}
                                    edit={false}
                                    value={0}
                                    size={24}
                                    isHalf={true}
                                    char={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        width={18}
                                        alt=""
                                      />
                                    }
                                    emptyIcon={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        width={18}
                                        alt=""
                                      />
                                    }
                                    halfIcon={
                                      <img
                                        src="../assets/img/icon/state-half.svg"
                                        width={18}
                                        alt=""
                                      />
                                    }
                                    filledIcon={
                                      <img
                                        src="../assets/img/icon/State-fill.svg"
                                        width={18}
                                        s
                                        alt=""
                                      />
                                    }
                                    activeColor="#ffc107"
                                  />
                                )}
                              </div>

                              <h6 className="regular fs-14 text-gray">
                                {data?.rating?.totalRating} Ratings
                              </h6>
                            </div>
                          </div>

                          <div className="col-md-9">
                            <CustomStarRating
                              stars={5}
                              ratingPercent={data?.rating?.fiveRatingsPercent}
                              label="5 Star Rating"
                            />
                            <CustomStarRating
                              stars={4}
                              ratingPercent={data?.rating?.fourRatingsPercent}
                              label="4 Star Rating"
                            />
                            <CustomStarRating
                              stars={3}
                              ratingPercent={data?.rating?.threeRatingsPercent}
                              label="3 Star Rating"
                            />
                            <CustomStarRating
                              stars={2}
                              ratingPercent={data?.rating?.twoRatingsPercent}
                              label="2 Star Rating"
                            />
                            <CustomStarRating
                              stars={1}
                              ratingPercent={data?.rating?.oneRatingsPercent}
                              label="1 Star Rating"
                            />
                          </div>
                        </div>
                      </div>

                      <h3 className="medium pb-3 fs-22  pt-md-0 pt-3">
                        Student Reviews
                      </h3>

                      <div className="student-reviews">
                        {data?.course_reviews?.map((item, index) => {
                          return (
                            <ReviewItem
                              {...{
                                item: item,
                                index: index,
                                length: data?.course_reviews.length,
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {relatedCourses.length > 0 ? (
                    <div className="bg-white rounded-10 py-3 ">
                      <h2 className="medium pt-2 pb-3 px-3 border-bottom fs-22 mb-3">
                        Related Courses
                      </h2>
                      {relatedCourses?.map((item, index) => {
                        return (
                          <div className="cart-item pb-3 px-3 " key={index}>
                            <div className="row align-items-center">
                              <div className="col-md-auto pb-md-0 pb-2">
                                <Link
                                  to={"/course-detail/" + item.id}
                                  target="_blank"
                                >
                                  <img
                                    src={Endpoints.baseUrl + item.thumbnail}
                                    className="rounded-2 object-cover"
                                    width="96"
                                    height="96"
                                  />
                                </Link>
                              </div>

                              <div className="col-md-6">
                                <Link
                                  to={"/course-detail/" + item.id}
                                  target="_blank"
                                >
                                  <h5 className="medium">{item.title}</h5>
                                </Link>

                                <div className="py-2 d-flex w-100 align-items-center fs-14 ">
                                  <span className="text-brown mt-1">
                                    {item?.rating?.avgRating?.toFixed(2)}
                                  </span>
                                  <div className="d-flex mx-2">
                                    <ReactStars
                                      count={5}
                                      edit={false}
                                      value={item?.rating?.avgRating}
                                      size={24}
                                      isHalf={true}
                                      char={
                                        <img
                                          src="../assets/img/icon/state-null.svg"
                                          alt=""
                                          width="17"
                                        />
                                      }
                                      emptyIcon={
                                        <img
                                          src="../assets/img/icon/state-null.svg"
                                          alt=""
                                          width="17"
                                        />
                                      }
                                      halfIcon={
                                        <img
                                          src="../assets/img/icon/state-half.svg"
                                          alt=""
                                          width="17"
                                        />
                                      }
                                      filledIcon={
                                        <img
                                          src="../assets/img/icon/State-fill.svg"
                                          alt=""
                                          width="17"
                                        />
                                      }
                                      activeColor="#ffc107"
                                    />
                                    {/* <img src="../assets/img/icon/State-fill.svg" alt="" width="17" />
                                                                            <img src="../assets/img/icon/State-fill.svg" alt="" width="17" />
                                                                            <img src="../assets/img/icon/State-fill.svg" alt="" width="17" />
                                                                            <img src="../assets/img/icon/State-fill.svg" alt="" width="17" />
                                                                            <img src="../assets/img/icon/state-half.svg" alt="" width="17" /> */}
                                  </div>
                                  <span className="text-brown mt-1">
                                    ({item.rating.totalReviews} reviews)
                                  </span>
                                  <span className="medium text-black ps-3 mt-2">
                                    {item.rating.totalStudents}{" "}
                                    <span className="text-gray">Students</span>
                                  </span>
                                </div>

                                <h6 className="regular fs-14">
                                  Created by{" "}
                                  <span className="text-brown">
                                    {/* <h6 className="fs-12 text-brown ml-2" > */}
                                    {item.creatorId.fullName}
                                    {/* </h6> */}
                                  </span>
                                </h6>
                              </div>

                              {!item.instructors
                                .map((ins) => ins.id)
                                .includes(parseInt(userId)) ? (
                                item.hasPurchased === false && (
                                  <div className="col-md-3 ms-auto ps-md-0 d-sm-flex align-items-center gap-2  cart-btn-group text-center justify-content-md-end">
                                    {item.courseType === "Paid" ? (
                                      <h4 className="text-blue semibold text-nowrap py-md-0 py-2 cd-price">
                                        ₹ {item.discountedPrice}
                                      </h4>
                                    ) : (
                                      <h4 className="text-blue semibold text-nowrap py-md-0 py-2 cd-price">
                                        Free
                                      </h4>
                                    )}
                                    <div>
                                      {item?.hasPurchased === false ? (
                                        item?.isWishlisted === 0 ? (
                                          <Link
                                            href="#"
                                            onClick={() => {
                                              handleWishlist(item?.id);
                                            }}
                                            className="btn btn-br-blue py-1 fs-12 mb-md-2"
                                          >
                                            Wishlist &nbsp;
                                            <img
                                              src="../assets/img/icon/heart-blue.svg"
                                              alt=""
                                            />
                                          </Link>
                                        ) : (
                                          <Link
                                            href="#"
                                            onClick={() => {
                                              handleRemoveWishlist(
                                                item?.isWishlisted
                                              );
                                            }}
                                            className="btn btn-br-blue py-1 fs-12 mb-md-2"
                                          >
                                            Wishlist &nbsp;
                                            <img
                                              src="../assets/img/icon/icon-wishlist-fill.svg"
                                              alt=""
                                            />
                                          </Link>
                                        )
                                      ) : (
                                        <Link
                                          href={
                                            "start-learning" + "/" + item?.id
                                          }
                                          className="btn btn-br-blue py-1 fs-12 mb-md-2"
                                        >
                                          Start learning
                                        </Link>
                                      )}

                                      <Link
                                        href="#"
                                        className="btn fs-12 py-1 mx-1"
                                        onClick={() =>
                                          handleAddToCart(item?.id)
                                        }
                                      >
                                        Buy Now{" "}
                                        <img
                                          src="../assets/img/icon/icon-right-arrow.svg"
                                          alt=""
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                )
                              ) : (
                                <div className="col-md-3 ms-auto d-sm-flex align-items-center ml-auto cart-btn-group text-center justify-content-end">
                                  {item.courseType === "Paid" ? (
                                    <h4 className="text-blue semibold text-nowrap py-md-0 py-2">
                                      ₹ {item.discountedPrice}
                                    </h4>
                                  ) : (
                                    <h4 className="text-blue semibold text-nowrap py-md-0 py-2">
                                      Free
                                    </h4>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-3">
                  <div className="cd-sidebar">
                    <div className="box-shadow  rounded-15 bg-white ">
                      {/* <div className="d-flex justify-content-between align-items-center pb-2 p-3">
                                        <div className="w-100 pb-3 border-bottom px-3">


                                            {data.courseType === "Paid" ?
                                             <div class="d-flex justify-content-between align-items-center pb-2 ">
                                                    <div className="d-flex ">
                                                        <h4 className="text-blue medium fs-18">₹ {data.discountedPrice}</h4>
                                                        &nbsp;
                                                        <h4 className="medium text-decoration-line-through text-lightgray fs-18 pe-1">₹ {data.price}</h4>
                                                    </div>
                                                    <div>
                                                        <span class="text-brown fs-12 offer">{data.discountPercent.toFixed(2)}% off</span>
                                                    </div>

                                                    {/* {
                                                        data.discountPercent &&
                                                            <span className='badge badge-danger text-danger'>{data.discountPercent.toFixed(2)}% Off</span>
                                                    } */}
                      {/* </div>
                                                :
                                                <div className="d-flex">

                                                    <h4 className="text-blue medium fs-18"> {data.courseType}</h4>
                                                </div>
                                            }
                                        </div> */}

                      {/* </div> */}
                      {data.courseType === "Paid" ? (
                        <div class="d-flex justify-content-between align-items-center pb-2 p-3 border-bottom">
                          <div class="d-flex gap-1">
                            <h4 class="text-blue medium fs-18">
                              ₹ {data.discountedPrice}
                            </h4>
                            <h4 class="medium text-decoration-line-through text-lightgray fs-18 pe-1">
                              ₹ {data.price}
                            </h4>
                          </div>
                          <div>
                            <span class="bg-lightbrown text-brown fs-10 medium py-1 px-2 rounded-pill">
                              {data.discountPercent.toFixed(2)}% off
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div class="d-flex justify-content-between align-items-center pb-2 p-3 border-bottom">
                          <div class="d-flex">
                            <h4 className="text-blue medium fs-18">
                              {" "}
                              {data.courseType}
                            </h4>
                          </div>
                        </div>
                      )}

                      <div className="py-3 border-bottom px-3">
                        {/* <div className="d-flex justify-content-between gap-2 pb-2">
                                            <h5 className="fs-12 text-darkgray regular"><img src="../assets/img/icon/gray-watch.svg" className="me-2" alt="" />Course Duration</h5>
                                            <h5 className="fs-12 text-darkgray regular">{data.duration} days</h5>
                                        </div> */}

                        <div className="d-flex justify-content-between gap-2 pb-2">
                          <h5 className="fs-12 text-darkgray regular">
                            <img
                              src="../assets/img/icon/gray-lavel.svg"
                              className="me-2"
                              alt=""
                            />
                            Course Level
                          </h5>
                          <h5 className="fs-12 text-darkgray regular">
                            {data.level}
                          </h5>
                        </div>

                        <div className="d-flex justify-content-between gap-2 pb-2">
                          <h5 className="fs-12 text-darkgray regular">
                            <img
                              src="../assets/img/icon/gray-student.svg"
                              className="me-2"
                              alt=""
                            />
                            Students Enrolled
                          </h5>
                          <h5 className="fs-12 text-darkgray regular">
                            {data.totalStudents}
                          </h5>
                        </div>

                        <div className="d-flex justify-content-between gap-2 pb-2">
                          <h5 className="fs-12 text-darkgray regular">
                            <img
                              src="../assets/img/icon/gray-lang.svg"
                              className="me-2"
                              alt=""
                            />
                            Language
                          </h5>
                          <h5 className="fs-12 text-darkgray regular">
                            {data?.course_language?.language}
                          </h5>
                        </div>
                        {data?.subtitleLanguageId?.language ? (
                          <div className="d-flex justify-content-between gap-2 pb-2">
                            <h5 className="fs-12 text-darkgray regular">
                              <img
                                src="../assets/img/icon/gray-lang2.svg"
                                className="me-2"
                                alt=""
                              />
                              Subtitle Language
                            </h5>
                            <h5 className="fs-12 text-darkgray regular">
                              {data?.subtitleLanguageId?.language}
                            </h5>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {data?.creatorId?.id == userId ? (
                        ""
                      ) : (
                        <>
                          {data.courseType === "Paid" ? (
                            purcahsed === false ? (
                              <div className="py-3 border-bottom  px-3">
                                <Link
                                  href="#"
                                  onClick={() => handleAddToCart(data?.id)}
                                  className="btn btn-light-blue mb-2 w-100 fs-14 medium dashboard-btn"
                                >
                                  <img
                                    src="../assets/img/icon/blue-cart.svg"
                                    alt=""
                                    className="mx-1"
                                  />
                                  Add To Cart
                                </Link>
                                <Link
                                  href="#"
                                  onClick={() => handleAddToCart(data?.id)}
                                  className="btn btn-blue w-100 fs-14 medium dashboard-btn"
                                >
                                  Buy Now
                                </Link>

                                {data?.coupon?.length > 0
                                  ? data?.coupon?.map((item, index) => {
                                      return (
                                        <div className="coupon-layout rounded-3">
                                          <div className="row m-0 fs-13 px-2 py-3">
                                            <div className="col-md-12 fs-16 bold pb-2">
                                              Offer
                                            </div>
                                            <div className="col-md-12">
                                              use this coupon code :
                                            </div>

                                            <div
                                              className="col-md-12 my-1"
                                              key={index}
                                            >
                                              <div className="d-xl-flex w-100 justify-content-between align-items-center gap-2">
                                                <div className="coupon-code w-100 text-center">
                                                  <h4>{item?.couponCode}</h4>
                                                </div>
                                                <div className="text-end">
                                                  <button
                                                    className="course-btn-brown"
                                                    onClick={() => {
                                                      handleCapyCouponCode(
                                                        item?.couponCode
                                                      );
                                                    }}
                                                  >
                                                    <h6 className="text-dark">
                                                      Copy{" "}
                                                    </h6>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            {item.type === "PERCENTAGE" ? (
                                              <div className="col-md-12">
                                                to avail {item.value} % discount
                                              </div>
                                            ) : (
                                              <div className="col-md-12">
                                                to avail ₹ {item.value} discount
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </div>
                            ) : (
                              <div className="py-3 border-bottom px-3">
                                <Link
                                  to={"/start-learning" + "/" + data?.id}
                                  className="btn btn-blue w-100 fs-14 medium dashboard-btn"
                                >
                                  Start Learning
                                </Link>

                                {data?.coupon?.length > 0
                                  ? data?.coupon?.map((item, index) => {
                                      return (
                                        <div className="coupon-layout rounded-3">
                                          <div className="row m-0 fs-13 px-2 py-3">
                                            <div className="col-md-12 fs-16 bold pb-2">
                                              Offer
                                            </div>
                                            <div className="col-md-12">
                                              use this coupon code :
                                            </div>

                                            <div
                                              className="col-md-12 my-1"
                                              key={index}
                                            >
                                              <div className="d-xl-flex w-100 justify-content-between align-items-center gap-2">
                                                <div className="coupon-code w-100 text-center">
                                                  <h4>{item?.couponCode}</h4>
                                                </div>
                                                <div className="text-end">
                                                  <button
                                                    className="course-btn-brown"
                                                    onClick={() => {
                                                      handleCapyCouponCode(
                                                        item?.couponCode
                                                      );
                                                    }}
                                                  >
                                                    <h6 className="text-dark">
                                                      Copy{" "}
                                                    </h6>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            {item.type === "PERCENTAGE" ? (
                                              <div className="col-md-12">
                                                to avail {item.value} % discount
                                              </div>
                                            ) : (
                                              <div className="col-md-12">
                                                to avail ₹ {item.value} discount
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </div>
                            )
                          ) : (
                            <div className="py-3  border-bottom px-3">
                              {purcahsed === false ? (
                                <Link
                                  href="#"
                                  onClick={handleEnrollFreeCourse}
                                  className="btn btn-blue w-100 fs-14 medium dashboard-btn"
                                >
                                  Enroll
                                </Link>
                              ) : (
                                <Link
                                  to={"/start-learning" + "/" + data?.id}
                                  className="btn btn-blue w-100 fs-14 medium dashboard-btn"
                                >
                                  Start Learning
                                </Link>
                              )}
                              {data?.coupon?.length > 0
                                ? data?.coupon?.map((item, index) => {
                                    return (
                                      <div className="coupon-layout rounded-3">
                                        <div className="row m-0 fs-13 px-2 py-3">
                                          <div className="col-md-12 fs-16 bold pb-2">
                                            Offer
                                          </div>
                                          <div className="col-md-12">
                                            use this coupon code :
                                          </div>

                                          <div
                                            className="col-md-12 my-1"
                                            key={index}
                                          >
                                            <div className="d-xl-flex w-100 justify-content-between align-items-center gap-2">
                                              <div className="coupon-code w-100 text-center">
                                                <h4>{item?.couponCode}</h4>
                                              </div>
                                              <div className="text-end">
                                                <button
                                                  className="course-btn-brown"
                                                  onClick={() => {
                                                    handleCapyCouponCode(
                                                      item?.couponCode
                                                    );
                                                  }}
                                                >
                                                  <h6 className="text-dark">
                                                    Copy{" "}
                                                  </h6>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          {item.type === "PERCENTAGE" ? (
                                            <div className="col-md-12">
                                              to avail {item.value} % discount
                                            </div>
                                          ) : (
                                            <div className="col-md-12">
                                              to avail ₹ {item.value} discount
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                          )}
                        </>
                      )}

                      <div className="py-3 px-2 single-course-btngroup border-bottom d-lg-flex d-md-block d-flex justify-content-center">
                        {/* <Link href="#" className="btn btn-br-brown px-3 regular fs-12 py-1 me-2 mb-lg-0 mb-md-3 mb-0">Gift Course
                                            <img src="../assets/img/icon/brown-gift.svg" alt="" width="12" className='mx-1' />
                                        </Link> */}
                        {data.wishlistId === 0 ? (
                          <Link
                            href="#"
                            onClick={() => {
                              handleWishlist(data?.id);
                            }}
                            className="btn btn-br-brown px-3 regular fs-12 px-3 py-1"
                          >
                            Wishlist
                            <img
                              src="../assets/img/icon/brown-heart.svg"
                              alt=""
                              width="12"
                              className="mx-1"
                            />
                          </Link>
                        ) : (
                          <Link
                            href="#"
                            onClick={() => {
                              handleRemoveWishlist(data?.wishlistId);
                            }}
                            className="btn btn-br-brown px-3 regular fs-12 px-3 py-1"
                          >
                            Wishlist
                            <img
                              src="../assets/img/icon/icon-wishlist-fill.svg"
                              alt=""
                              width="12"
                              className="mx-1"
                            />
                          </Link>
                        )}
                      </div>

                      <div className="py-3 px-3 border-bottom courseinclude-list">
                        <h4 className="fs-14 pb-3 medium text-black">
                          This course includes:
                        </h4>
                        <h5 className="fs-12 regular pb-2">
                          <img
                            src="../assets/img/icon/time.svg"
                            className="me-2"
                            alt=""
                          />
                          Lifetime access
                        </h5>
                        {/* <h5 className="fs-12 regular pb-2"><img src="../assets/img/icon/blue-dolor.svg" className="me-2" alt="" />30-days money-back guarantee</h5> */}
                        {/* <h5 className="fs-12 regular pb-2"><img src="../assets/img/icon/blue-download.svg" className="me-2" alt="" />Free exercises file &amp; downloadable resources</h5> */}
                        <h5 className="fs-12 regular pb-2">
                          <img
                            src="../assets/img/icon/blue-download.svg"
                            className="me-2"
                            alt=""
                          />
                          Free downloadable resources
                        </h5>
                        {/* <h5 className="fs-12 regular pb-2"><img src="../assets/img/icon/blue-trofi.svg" className="me-2" alt="" />Shareable certificate of completion</h5> */}
                        <h5 className="fs-12 regular pb-2">
                          <img
                            src="../assets/img/icon/blue-tv.svg"
                            className="me-2"
                            alt=""
                          />
                          Access on mobile , tablet and TV
                        </h5>
                        {/* <h5 className="fs-12 regular pb-2"><img src="../assets/img/icon/blue-file.svg" className="me-2" alt="" />English subtitles</h5> */}
                        <h5 className="fs-12 regular pb-2">
                          <img
                            src="../assets/img/icon/play2.svg"
                            className="me-2"
                            alt=""
                          />
                          100% online course
                        </h5>
                      </div>

                      <div className="py-3 px-3">
                        <h4 className="fs-14 pb-3">Share this course:</h4>
                        <div className="d-lg-flex justify-content-between align-items-center">
                          <h5 className="fs-12 regular text-darkgray course-copylink">
                            <Link onClick={handleCopy}>
                              <img
                                src="../assets/img/icon/gray-link.svg"
                                className="me-2"
                                alt=""
                              />
                              Copy Link
                            </Link>
                          </h5>
                          <div className="d-flex justify-content-between course-share-btn gap-1">
                            <FacebookShareButton
                              url={shareUrl}
                              quote={data.description}
                            >
                              {/* <Link className='px-1'> */}
                              <img
                                src="../assets/img/icon/gray-facebook.svg"
                                width="16"
                                alt=""
                              />
                              {/* </Link> */}
                            </FacebookShareButton>

                            <LinkedinShareButton url={shareUrl}>
                              <Link className="px-1">
                                <img
                                  src="../assets/img/icon/gray-in.svg"
                                  width="16"
                                  alt=""
                                />
                              </Link>
                            </LinkedinShareButton>
                            <EmailShareButton url={shareUrl}>
                              <Link className="px-1">
                                <img
                                  src="../assets/img/icon/gray-email.svg"
                                  width="16"
                                  alt=""
                                />
                              </Link>
                            </EmailShareButton>
                            <WhatsappShareButton url={shareUrl}>
                              <Link className="px-1">
                                <img
                                  src="../assets/img/icon/gray-whatsapp.svg"
                                  width="16"
                                  alt=""
                                />
                              </Link>
                            </WhatsappShareButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* )
                    })
                    } */}
          </div>
          <OnlineFriends />
        </div>
      ) : (
        <PostLoader />
      )}
    </Container>
  );
}
