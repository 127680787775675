import React from "react";
import { Endpoints } from "../../API/Endpoints";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
import ToastMessage from "../../Utils/ToastMessage";
import { CommonAPi } from "../../API/CommonApi";
import { useNavigate } from "react-router-dom";

export default function TeacherItem({ item, index }) {
  const Navigate = useNavigate();
  const sendFriendRequest = async (Id) => {
    let data = {
      receiverId: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.SendFriendRequest, data);
      if (resp && resp.message) {
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <div
      className="col-lg-3 col-md-4 col-sm-6 mb-4"
      data-category="mostrated"
      key={index}
    >
      <div className="mainmenu-page-item">
        <div className="text-center position-relative">
          {item.user_profile?.coverImg === null ? (
            <img
              src="../assets/img/teacher-banner.png"
              className="mainmenu-page-banner"
              alt=""
            />
          ) : (
            <img
              src={Endpoints.baseUrl + item.user_profile?.coverImg}
              alt=""
              className="mainmenu-page-banner"
              style={{ objectFit: "cover" }}
            />
          )}
          <span className="suggested-user">
            <Link to={"/timeline/" + item?.username}>
              {item.user_profile?.profileImg === null ? (
                <img
                  src="../assets/img/teacher1.png"
                  alt=""
                  width="96"
                  height="96"
                  className="rounded-circle"
                />
              ) : (
                <img
                  src={Endpoints.baseUrl + item.user_profile?.profileImg}
                  alt=""
                  width="96"
                  height="96"
                  className="rounded-circle"
                />
              )}
            </Link>
          </span>
          <Link to={"/timeline/" + item?.username}>
            <h5 className="semibold pt-3 pb-2">{item.fullName}</h5>
          </Link>
          <h6 className="fs-14 text-darkgray regular pb-1">
            {item.user_profile?.profileTagline}
          </h6>
          <h6 className="fs-14 text-darkgray regular pb-2">
            {item.totalStudents} Students
          </h6>
          <div className="d-flex justify-content-center align-items-center">
            <span className="fs-12 medium">{item?.rating}</span>
            <div className="d-flex mx-2 course-rating">
              <ReactStars
                count={5}
                edit={false}
                value={item?.rating}
                size={18}
                isHalf={true}
                char={"★"}
                // char={<img src="../assets/img/icon/state-null.svg" alt="" width="15" />}
                emptyIcon={
                  <img
                    src="../assets/img/icon/state-null.svg"
                    alt=""
                    width="15"
                  />
                }
                halfIcon={
                  <img
                    src="../assets/img/icon/state-half.svg"
                    alt=""
                    width="15"
                  />
                }
                filledIcon={
                  <img
                    src="../assets/img/icon/State-fill.svg"
                    alt=""
                    width="15"
                  />
                }
                activeColor="#ffd700"
              />
            </div>
            <span className="fs-12 medium text-lightgray">
              ({item.totalReviews})
            </span>
          </div>
        </div>
        <div className="text-center py-3 px-2">
          {item.isFriend === false ? (
            <Link
              onClick={() => {
                sendFriendRequest(item.id);
              }}
              className="btn w-100"
            >
              Add Teacher
              <img
                src="../assets/img/icon/icon-add-friend.svg"
                className="ps-2"
              />
            </Link>
          ) : (
            <Link
              to={`/messages?userId=${item.id}`}
              className="btn btn-brown w-100 py-2 fs-14 mw-100"
            >
              Send a Message
              <img src="../assets/img/icon/message.svg" className="ps-2" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
