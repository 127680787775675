import React, { useEffect, useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from '../../API/FetchApi';
import GratitudeItem from '../ProfileAbout/GratitudeItem';
import { Link, useNavigate } from 'react-router-dom';
import GratitudeModel from "../ProfileAbout/GratitudeModel";
import { Helmet } from "react-helmet";
function GratitudeWall() {
    const [gratitude, setGratitude] = useState([]);
    const [activeTab, setActiveTab] = useState('received');
    const Navigate = useNavigate();

    useEffect(() => {
        GetGratitude();
    }, [activeTab]);
    const GetGratitude = async (type) => {



        try {
            let resp = await FetchApi(Endpoints.GetGratitude + '?type=' + activeTab);
            if (resp && resp.success === true) {
                setGratitude(resp.data);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }

    };
    const GetGratitudeType = async (type) => {

        try {
            let resp = await FetchApi(Endpoints.GetGratitude + '?type=' + type);
            if (resp && resp.success === true) {
                setGratitude(resp.data);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }

    };
    return (
        <Container header>
            <Helmet>
                <title>Gratitude Wall</title>
            </Helmet>
            <div className="wrapper wrapper-bg">


                <div className="main">
                    <LeftNavigation />

                    <div className="container py-3">
                        <div className="bg-white main-common">

                            <div className="main-top-row pb-4 d-flex justify-content-between">
                                <h3 className="medium">Gratitude Wall</h3>
                                <div className="">
                                    <Link
                                        data-bs-toggle="modal" data-bs-target="#gratitudemodel"
                                        className="btn btn-br-blue">Send Gratitude +</Link>
                                </div>
                            </div>

                            <div className="mainmenu-filter-row pb-3">
                                <ul className="filter d-flex flex-nowrap align-items-center gap-2 fs-14 medium">
                                    <li className={activeTab === 'received' ? "active" : ""} onClick={() => {
                                        setActiveTab('received');
                                        GetGratitudeType('received');
                                    }}>Gratitude Received</li>
                                    <li onClick={() => {
                                        setActiveTab('sent')
                                        GetGratitudeType('sent');
                                    }} className={activeTab === 'sent' ? "active" : ""}>Gratitude Sent</li>
                                </ul>
                            </div>

                            {gratitude.length > 0 ?
                                <div className="row">
                                    {gratitude.map((item, index) => {
                                        return (
                                            <GratitudeItem
                                                {...{
                                                    item: item,
                                                    index: index,
                                                    type: "wall",
                                                    gratitudeType: activeTab
                                                }} />
                                        )
                                    })}
                                </div>
                                :
                                <div className="row px-4">
                                    No gratitude {activeTab}
                                </div>

                            }

                        </div>
                    </div>
                    <OnlineFriends />
                </div>

                <GratitudeModel
                    {...{
                        onSuccess: GetGratitude
                    }}
                />
            </div>
        </Container>
    )
}

export default GratitudeWall;