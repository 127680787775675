import React, { useState, useEffect } from "react"
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import Loader from "../PostLoader/PostLoader";
import "./PostShareCounterPopup.css"
const PostShareCounterPopup = ({
  postID,
  showShareCounterPopup,
  shareCounter,
  setShowShareCounterPopup,
  isMedia = false,
  mediaID = null
}) => {
  const [postShareUsers, setPostShareUsers] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [page, setPage] = useState(1);
  const shareUsersRef = useRef();

  const handleScroll = (e) => {
    if (loading) return;
    const scrollTop = shareUsersRef.current.scrollTop;
    const offsetHeight = shareUsersRef.current.offsetHeight;
    const scrollHeight = shareUsersRef.current.scrollHeight;
    const remaining = scrollHeight - offsetHeight - scrollTop;
    console.log(remaining);
    if (remaining <= 10) {
      setLoading(true);
      fetchNext();
    }
  };
  const fetchNext = () => {
    setTimeout(() => {
      setPage(page + 1);
    }, 200);
  };


  useEffect(() => {
    if (showShareCounterPopup) {
      allShares(postID)
    }
  }, [page]);


  const allShares = async (id) => {
    try {
      let resp;
      if (isMedia) {
        resp = await CommonAPi(Endpoints.GetShareUsersOfAMedia + id + `/${mediaID}?page=${page}`);
      } else {
        resp = await CommonAPi(Endpoints.GetShareUsersOfAPost + id + `?page=${page}`);
      }
      if (resp && resp.success === true) {
        if (resp.data.length === 0) {
          setLoading(false);
          setHasMorePosts(false);
        } else {
          setPostShareUsers([...postShareUsers, ...resp.data]);
        }
        setLoading(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error('Session Expired! Please login again.');
          localStorage.clear();
          navigate('/signin');
        } else {
          ToastMessage.Error(e.response.data.message);
        }

      }
    }
  };
  return (
    <>
      {

        <>
          {showShareCounterPopup && "show" && (
            <div
              className="modal-backdrop fade show"
            // onClick={backdropHandle}
            ></div>
          )}
          {showShareCounterPopup && (
            <div
              style={{ zIndex: "999999" }}
              className={`modal fade show`}
              id="exampleModal123"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Shares ({shareCounter})
                    </h5>
                    <button
                      type="button"
                      style={{ width: "auto", height: 'auto', background: "rgba()" }}
                      onClick={() => {
                        setShowShareCounterPopup(false);
                        setPostShareUsers([]);
                        setPage(1);
                      }}
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    {
                      <div>
                        <div
                          className="reactions-box"
                          ref={shareUsersRef}
                          onScroll={handleScroll}
                        >

                          {
                            postShareUsers.map((user, i) => (
                              <Link key={user.id} className="d-flex" to={"/timeline/" + user.username}>
                                <img className="share-user-img" src={Endpoints.baseUrl + user?.user_profile?.profileImg} alt="share-user-img" />
                                <p style={{ marginLeft: "6px" }}>{user.fullName}</p>
                              </Link>
                            ))
                          }
                          {/* {
                        loading === true && hasMorePosts ===true && <Loader/>
                       } */}
                        </div>
                      </div>
                    }

                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      }
    </>
  )



}

export default PostShareCounterPopup;