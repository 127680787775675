import React from 'react'
import { Endpoints } from '../../../API/Endpoints';
import { Link } from "react-router-dom";
export default function ViewMember({ member, setShowMemberModal }) {

    console.log(member);
    return (
        <div
            className="modal fade show"
            id="FriendsSpecificModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="FriendsSpecificStoryModal">
                            Members
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                setShowMemberModal(false)
                            }}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            {member.map((item, index) => {
                                return (
                                    <div className="col-md-12" key={index}>
                                        <div className="d-flex mt-3">
                                            <div className="position-relative px-2">
                                            <Link to={"/timeline/" + item?.user?.username}>
                                                <img src={Endpoints.baseUrl + item?.user?.user_profile?.profileImg} alt="" width="58" height="48" className="rounded-circle" />
                                            </Link>
                                            </div>
                                            <div class="ps-2 w-100 mt-3">
                                                <div class="d-flex align-items-center justify-content-between w-100">
                                                    <Link to={"/timeline/" + item?.user?.username}>
                                                        <h6 class="medium text-black">{item?.user?.fullName}</h6>
                                                    </Link>
                                                  
                                                 </div>
                                          
                                            </div>
                                            
                         
                                        </div>
                                    </div>
                                )
                            })}

                        </div>


                    </div>

                </div>
            </div>
        </div>
    )
}
