import React from "react";
import { Endpoints } from "../../API/Endpoints";
import { Link } from "react-router-dom";
export default function GratitudeItem({ item, index, type, gratitudeType }) {
  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "y";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + "d";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + "h";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min";
    return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec" : "0 sec";
  };
  return (
    <div
      className={type === "wall" ? "col-md-4 mb-4" : "col-md-6  mb-4"}
      key={index}
    >
      <div className="gratitude-item">
        <div
          className="gratitude-top-content text-center medium fs-16 py-5 px-4"
          style={{ backgroundImage: item.backgroundImage }}
        >
          <p className="m-0 mt-4 py-4 gratitude-text">{item.text}</p>
        </div>
        {gratitudeType === "received" ? (
          <div className="d-flex align-items-center feed-top p-3">
            <span className="feed-user-image">
              <Link to={`/timeline/${item.sender?.username}`}>
                <img
                  src={
                    Endpoints.baseUrl + item.sender?.user_profile?.profileImg
                  }
                  alt=""
                  width="32"
                  height="32"
                  className="rounded-circle"
                />
              </Link>
            </span>
            <div className="ps-2 pt-1 d-flex align-items-start justify-content-between w-100">
              <div>
                <h6 className="fs-14 medium text-black">
                  <Link to={`/timeline/${item.sender?.username}`}>
                    {item.sender?.fullName}
                  </Link>
                </h6>
                <h6 className="fs-10 regular text-darkgray py-1">
                  {item.sender?.user_profile?.profileTagline}
                </h6>
              </div>
              <div className="ms-auto d-flex align-items-center">
                <h6 className="fs-12 regular text-darkgray pe-2">
                  {timeSince(new Date(item?.createdAt))} ago
                </h6>
                {/* <div className="position-relative">
                    <a href="#" aria-haspopup="true" aria-label="user_feed_1" data-bs-toggle="dropdown" className="d-flex align-items-center justify-content-center ">
                        <svg viewBox="0 0 24 24" width="22" height="22" stroke="#000" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                    </a>

                    <ul className="popup-menu text-start shadow rounded-1" role="menu" id="user_feed_1" aria-hidden="true" tabIndex="-1">
                        <li><a className="dropdown-item" href="#">
                            <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                            Delete
                        </a></li>
                        <li><a className="dropdown-item" href="#">
                            <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line></svg>
                            Restricted
                        </a></li>

                    </ul>
                </div> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center feed-top p-3">
            <span className="feed-user-image">
              <Link to={`/timeline/${item.receiver?.username}`}>
                <img
                  src={
                    Endpoints.baseUrl + item.receiver?.user_profile?.profileImg
                  }
                  alt=""
                  width="32"
                  height="32"
                  className="rounded-circle"
                />
              </Link>
            </span>
            <div className="ps-2 pt-1 d-flex align-items-start justify-content-between w-100">
              <div>
                <h6 className="fs-14 medium text-black">
                  <Link to={`/timeline/${item.receiver?.username}`}>
                    {item.receiver?.fullName}
                  </Link>
                </h6>
                <h6 className="fs-10 regular text-darkgray py-1">
                  {item.receiver?.user_profile?.profileTagline}
                </h6>
              </div>
              <div className="ms-auto d-flex align-items-center">
                <h6 className="fs-12 regular text-darkgray pe-2">
                  {timeSince(new Date(item?.createdAt))} ago
                </h6>
                {/* <div className="position-relative">
                        <a href="#" aria-haspopup="true" aria-label="user_feed_1" data-bs-toggle="dropdown" className="d-flex align-items-center justify-content-center ">
                            <svg viewBox="0 0 24 24" width="22" height="22" stroke="#000" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                        </a>

                        <ul className="popup-menu text-start shadow rounded-1" role="menu" id="user_feed_1" aria-hidden="true" tabIndex="-1">
                            <li><a className="dropdown-item" href="#">
                                <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                Delete
                            </a></li>
                            <li><a className="dropdown-item" href="#">
                                <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line></svg>
                                Restricted
                            </a></li>

                        </ul>
                    </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
