import React from 'react'
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import CompanyProfiles from './CompanyProfiles';

export default function CompanyVideo() {
    return (
        <Container
            header
        >
            <div className="wrapper wrapper-bg">
                <div className="main">
                    <LeftNavigation />
                    <div className="container py-lg-4 py-3">
                        <CompanyProfiles />
                    </div>



                    <OnlineFriends />
                </div>
            </div>
        </Container >
    )
}
