import { createPortal } from "react-dom";
import styles from "./confirmDialog.module.css";

export default function ConfirmDialog({
  show = false,
  header = "Confirm Action!",
  body = "Are you sure, you want to do this operation?",
  danger = false,
  buttonText = "Confirm",
  onConfirm = () => {},
  onCancel = () => {},
}) {
  if (!show) return null;

  return createPortal(
    <div className={styles.container}>
      <div className={styles["dialog-container"]}>
        <p className={styles["dialog-header"]}>{header}</p>
        <p className={styles["dialog-body"]}>{body}</p>
        <div className={styles["dialog-actions"]}>
          <button
            className={`${styles["dialog-button"]} ${styles["alter"]} ${
              danger ? styles["danger"] : ""
            }`}
            onClick={onConfirm}
          >
            {buttonText}
          </button>
          <button
            className={styles["dialog-button"]}
            onClick={onCancel}
            autoFocus
          >
            Cancel
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
}
