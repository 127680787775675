import React, { useState } from "react";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { CommonAPi } from "../../API/CommonApi";
import { DeleteApi } from "../../API/DeleteApi";
import { Link, useNavigate } from "react-router-dom";
import ConfirmDialog from "../../Components/confirmDialog/ConfirmDialog";
export default function GroupItem({ item, index, onSuccess, type }) {
  const Navigate = useNavigate();
  const userId = localStorage.getItem("userId");

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const joinGroup = async (id, groupType) => {
    let data = {
      groupId: id,
      groupType: groupType,
    };
    try {
      let resp = await CommonAPi(Endpoints.JoinGroups, data);
      if (resp && resp.success === true) {
        onSuccess(true);
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const leaveGroup = async (id) => {
    let data = {
      groupId: id,
    };
    try {
      let resp = await DeleteApi(Endpoints.LeaveGroup, data);
      if (resp && resp.success === true) {
        onSuccess(true);
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleDeleteGroup = async (Id) => {
    let data = {
      groupId: item?.slug,
    };
    try {
      let resp = await DeleteApi(Endpoints.GroupDelete, data);
      if (resp && resp.success === true) {
        onSuccess(true);
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e?.response?.data?.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
    setShowDeleteDialog(false);
  };

  return (
    <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={index}>
      <div className="mainmenu-page-item">
        <div className="text-center position-relative">
          <div className="mainmenu-item-action position-absolute end-0 top-0 m-2 p-1">
            {item.userId === parseInt(userId) && (
              <a
                href="#"
                aria-haspopup="true"
                aria-label="university_1"
                data-bs-toggle="dropdown"
                className="d-flex align-items-center justify-content-center rounded-circle bg-white p-1"
              >
                <svg
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  stroke="#000"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="css-i6dzq1"
                >
                  <circle cx="12" cy="12" r="1"></circle>
                  <circle cx="19" cy="12" r="1"></circle>
                  <circle cx="5" cy="12" r="1"></circle>
                </svg>
              </a>
            )}
            <ul
              className="dropdown-menu shadow rounded-1 text-start"
              role="menu"
              id="university_1"
              aria-hidden="true"
              style={{ minWidth: "unset" }}
            >
              <li>
                <Link
                  to={`/update-group/${item.slug}`}
                  className="dropdown-item"
                >
                  Edit
                </Link>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => {
                    // handleDeleteGroup(item?.slug);
                    setShowDeleteDialog(true);
                  }}
                >
                  Delete
                </a>
              </li>
            </ul>
          </div>
          {item?.userGroupMembership === "PENDING" &&
          item?.groupType === "Private" ? (
            <Link to={"/group-about/" + item?.slug}>
              {item?.coverImage === null ? (
                <img
                  src="assets/img/group-banner.png"
                  width="100%"
                  height="147"
                  alt=""
                />
              ) : (
                <img
                  src={Endpoints.baseUrl + item?.coverImage}
                  width="100%"
                  height="147"
                  alt=""
                />
              )}
            </Link>
          ) : (
            <Link to={"/group-home/" + item?.slug}>
              {item?.coverImage === null ? (
                <img
                  src="assets/img/group-banner.png"
                  width="100%"
                  height="147"
                  alt=""
                />
              ) : (
                <img
                  src={Endpoints.baseUrl + item?.coverImage}
                  width="100%"
                  height="147"
                  alt=""
                />
              )}
            </Link>
          )}
          <div className="px-2">
            {item?.userGroupMembership === "PENDING" &&
            item?.groupType === "Private" ? (
              <Link to={"/group-about/" + item?.slug}>
                <h5 className="semibold pt-3 pb-2">{item?.groupName}</h5>
              </Link>
            ) : (
              <Link to={"/group-home/" + item?.slug}>
                <h5 className="semibold pt-3 pb-2">{item?.groupName}</h5>
              </Link>
            )}
            <h6 className="text-brown medium ">{item?.groupType} Group</h6>

            <div className="py-2 d-flex justify-content-center group-user">
              {item?.members.map((membersItem, i) => {
                if (i > 4) return null;

                return (
                  <img
                    src={
                      Endpoints.baseUrl + membersItem.user_profile.profileImg
                    }
                    width="32"
                    height="32"
                    className="rounded-circle"
                    alt=""
                  />
                );
              })}
            </div>

            <h6 className="fs-10 text-mediumgray medium pb-1">
              {item?.members?.length > 0 && "Joined by "}
              {item?.members
                .map((membersItem, i) => {
                  if (i < 3) {
                    return membersItem?.fullName?.split(" ")[0];
                  } else if (i === 3) {
                    return `& ${item.members.length - 3} others`;
                  }
                  return null;
                })
                .filter(Boolean)
                .join(", ")}
            </h6>
          </div>
        </div>

        {userId == item?.userId ? (
          ""
        ) : (
          <div className="text-center py-3 px-2">
            {type === "all" ? (
              item.userGroupMembership === "" ? (
                <Link
                  onClick={() => {
                    joinGroup(item.id, item.groupType);
                  }}
                  className="btn w-100 "
                >
                  Join{" "}
                  <img
                    src="assets/img/icon/icon-add-friend.svg"
                    className="ps-2"
                  />
                </Link>
              ) : (
                <Link className="btn w-100 ">{item.userGroupMembership}</Link>
              )
            ) : (
              <Link
                onClick={() => {
                  leaveGroup(item.slug);
                }}
                className="btn w-100 "
              >
                Leave Group
              </Link>
            )}
          </div>
        )}
        <ConfirmDialog
          show={showDeleteDialog}
          buttonText="Delete"
          body="Are you sure want to delete?"
          danger
          header="Confirm Delete"
          onCancel={() => {
            setShowDeleteDialog(false);
          }}
          onConfirm={handleDeleteGroup}
        />
      </div>
    </div>
  );
}
