import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { CommonAPi } from "../../API/CommonApi";
import { UpdateAPi } from "../../API/UpdateApi";
import { Month } from "../../constants/Month";
import { DeleteApi } from "../../API/DeleteApi";
import moment from "moment";
import DeletePrompt from "../../Components/DeletePrompt/DeletePrompt";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import DiscardPopup from "../../Components/DiscardPopup/DiscardPopup";
const $ = window.jQuery;

export default function Academics() {
  const [academics, setAcademics] = useState([]);
  const [id, setId] = useState("");
  const [studyArea, setStudyArea] = useState("");
  const [studyAreaKey, setStudyAreaKey] = useState("");
  const [studyAreaId, setStudyAreaId] = useState("");
  const [studyAreaOption, setStudyAreaOption] = useState([]);
  const [institute, setInstitute] = useState("");
  const [instituteName, setInstituteName] = useState("");
  const [instituteData, setInstituteData] = useState("");
  const [details, setDetails] = useState("");
  const [startingYear, setstartingYear] = useState("");
  const [endingYear, setendingYear] = useState("");
  const [startingMonth, setstartingMonth] = useState("");
  const [endingMonth, setendingMonth] = useState("");
  const [studyingCurrently, setstudyingCurrently] = useState(false);
  const [pageId, setpageId] = useState(0);
  const [options, setOptions] = useState([]);
  const [year, setyear] = useState(new Date().getFullYear());
  const [Totalyear, setTaotalyear] = useState(new Array(50).fill(""));
  const [TotalyearData, setTaotalyearData] = useState([]);
  const [TotalEndingyearData, setTaotalEndingyearData] = useState([]);

  const [studyAreaError, setStudyAreaError] = useState("");
  const [instituteError, setInstituteError] = useState("");
  const [detailsError, setDetailsError] = useState("");
  const [startingYearError, setstartingYearError] = useState("");
  const [endingYearError, setendingYearError] = useState("");
  const [startingMonthError, setstartingMonthError] = useState("");
  const [endingMonthError, setendingMonthError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const Navigate = useNavigate();
  const [item, setItem] = useState(null);
  const [pastMonths, setPastMonths] = useState([]);
  const [endingMonths, setEndingMonths] = useState([]);
  const [Search, setSearch] = useState("");
  const [instituteNameKey, setInstituteNameKey] = useState("instituteNameKey");
  const [showDiscardPopup, setShowDiscardPopup] = useState(false);

  const [degree, setDegree] = useState("");
  const [degreeError, setDegreeError] = useState("");
  const [degreeKey, setDegreeKey] = useState("");
  const [degreeId, setDegreeId] = useState("");
  const [degreeOption, setDegreeOption] = useState([]);

  const userId = localStorage.getItem("userId");
  const username = localStorage.getItem("userName");
  const param = useParams();

  useEffect(() => {
    if (
      studyArea !== "" &&
      institute !== "" &&
      details !== "" &&
      startingYear !== "" &&
      startingMonth !== "" &&
      pageId !== ""
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [studyArea, institute, details, startingYear, startingMonth, pageId]);

  const setYear = (year) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    console.log(currentYear, year);
    if (currentYear == year) {
      setPastMonths(Month.filter((month, index) => index <= currentMonth));
    } else {
      setPastMonths(Month);
    }
  };
  const setEndYear = (year) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    if (currentYear == year) {
      setEndingMonths(Month.filter((month, index) => index <= currentMonth));
    } else {
      setEndingMonths(Month);
    }
  };

  // // let months = (diff%12);
  const getYear = (endingM, endingY, startingM, startingY) => {
    let ending_date = endingY
      ? moment(`${endingM} ${endingY}`, "MMM YYYY")
      : moment().toDate();
    let starting_date = startingY
      ? moment(`${startingM} ${startingY}`, "MMM YYYY")
      : moment().toDate();
    let diff = moment(starting_date).diff(ending_date, "months");
    let str = "";
    let years = Math.floor(diff / 12);
    let months = diff % 12;
    if (diff === 0) {
      str += diff + " Month";
    }
    if (years) {
      str += years + " Years ";
    }
    if (months) {
      str += months + " Months ";
    }
    return str;
  };

  useEffect(() => {
    let data = Totalyear.map((v, i) => {
      return {
        value: year - i,
        label: year - i,
      };
    });
    setTaotalyearData(data);
    if (startingYear) {
      let newdata = Totalyear.map((v, i) => {
        return {
          value: parseInt(startingYear) + parseInt(i),
          label: parseInt(startingYear) + parseInt(i),
        };
      });
      setTaotalEndingyearData(newdata);
    }
  }, []);

  const handleStartingYear = (years) => {
    let newdata = Totalyear.map((v, i) => {
      return {
        value: parseInt(years) + parseInt(i),
        label: parseInt(years) + parseInt(i),
      };
    });
    setTaotalEndingyearData(newdata);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(institute);
    if (!studyArea) {
      setStudyAreaError("Please enter study area ");
    } else if (studyArea) {
      setStudyAreaError("");
    }
    if (!institute) {
      setInstituteError("Please enter institute");
    } else if (institute) {
      setInstituteError("");
    }
    if (!details) {
      setDetailsError("Please enter details");
    } else if (details) {
      setDetailsError("");
    }
    if (!startingYear) {
      setstartingYearError("Please enter starting year");
    } else if (startingYear) {
      setstartingYearError("");
    }
    if (!startingMonth) {
      setstartingMonthError("Please enter starting month");
    } else if (startingMonth) {
      setstartingMonthError("");
    }

    if (studyingCurrently === false) {
      if (!endingYear) {
        setendingYearError("Please enter ending year");
        return false;
      } else if (endingYear) {
        setendingYearError("");
      }
      if (!endingMonth) {
        setendingMonthError("Please enter ending month");
        return false;
      } else if (endingMonth) {
        setendingMonthError("");
      }

      if (endingYear) {
        let ending_date = moment(`${endingMonth} ${endingYear}`, "MMM YYYY");
        let starting_date = moment(
          `${startingMonth} ${startingYear}`,
          "MMM YYYY"
        );
        if (Date.parse(starting_date) >= Date.parse(ending_date)) {
          // ToastMessage.Info("End date can’t be earlier than start date")
          setendingMonthError("End date can’t be earlier than start date");
          return false;
        }
      }
    }

    if (!degree) {
      setDegreeError("Please select degree");
    } else if (degree) {
      setDegreeError("");
    }

    if (
      studyArea &&
      institute &&
      details &&
      startingYear &&
      startingMonth &&
      degree
    ) {
      let data = "";
      if (studyingCurrently) {
        data = {
          study_area: studyAreaId,
          degree_id: degreeId,
          institute: institute,
          details: details,
          starting_year: startingYear,
          starting_month: startingMonth,
          studying_currently: studyingCurrently,
          page_id: pageId ? pageId : 0,
        };
      } else {
        data = {
          study_area: studyAreaId,
          degree_id: degreeId,
          institute: institute,
          details: details,
          starting_year: startingYear,
          ending_year: endingYear,
          starting_month: startingMonth,
          ending_month: endingMonth,
          studying_currently: studyingCurrently,
          page_id: pageId ? pageId : 0,
        };
      }

      if (id) {
        try {
          let resp = await UpdateAPi(
            Endpoints.Updateacademics + "/" + id,
            data
          );
          if (resp && resp.message) {
            document.getElementById("myform").reset();
            setStudyArea("");
            setInstitute("");
            setDetails("");
            setstartingYear("");
            setendingYear("");
            setstudyingCurrently(false);
            setpageId(0);
            setOptions([]);
            setstartingMonth("");
            setendingMonth("");
            setInstituteName("");
            setInstituteNameKey(Math.ceil(Math.random() * 999999));
            setDegreeKey(Math.ceil(Math.random() * 999999));
            setStudyAreaKey(Math.ceil(Math.random() * 999999));
            setDegreeId("");
            setDegree("");
            setDegreeOption([]);
            setStudyAreaId("");
            setStudyAreaOption([]);

            $("#Academicsmodal").modal("hide");
            getacademics();
            ToastMessage.Success(resp.message);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            ToastMessage.Error(e.response.data.message);
          }
        }
      } else {
        try {
          let resp = await CommonAPi(Endpoints.Createacademics, data);
          if (resp && resp.message) {
            document.getElementById("myform").reset();
            setStudyArea("");
            setInstitute("");
            setDetails("");
            setstartingYear("");
            setendingYear("");
            setstartingMonth("");
            setendingMonth("");
            setstudyingCurrently(false);
            setpageId(0);
            setOptions([]);
            setInstituteName("");
            setInstituteNameKey(Math.ceil(Math.random() * 999999));
            setDegreeKey(Math.ceil(Math.random() * 999999));
            setStudyAreaKey(Math.ceil(Math.random() * 999999));
            $("#Academicsmodal").modal("hide");
            setDegreeId("");
            setDegree("");
            setDegreeOption([]);
            setStudyAreaId("");
            setStudyAreaOption([]);
            getacademics();
            ToastMessage.Success(resp.message);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleOnSelect = (item) => {
    if (item.length > 0) {
      setpageId(item[0].id);
      setInstitute(item[0].name);
    } else {
      setpageId(0);
      setInstitute("");
    }
  };
  const handleOnSelectStudyArea = (item) => {
    if (item.length > 0) {
      if (item[0].createNew === true) {
        handleCreateStudyArea(item);
      } else {
        setStudyAreaId(item[0].id);
        setStudyArea(item[0].name);
      }
    } else {
      setStudyAreaId(0);
      setStudyArea("");
    }
  };

  const handleCreateStudyArea = async (item) => {
    let data = {
      id: -1,
      study_area: item[0].name,
    };
    try {
      let resp = await CommonAPi(Endpoints.createStudyArea, data);
      if (resp && resp.message) {
        setStudyAreaId(resp.data.id);
        setStudyArea(resp.data.name);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleOnSelectDegree = (item) => {
    if (item.length > 0) {
      if (item[0].createNew === true) {
        handleCreateDegree(item);
      } else {
        setDegreeId(item[0].id);
        setDegree(item[0].name);
      }
    } else {
      setStudyAreaId(0);
      setStudyArea("");
    }
  };

  const handleCreateDegree = async (item) => {
    let data = {
      id: -1,
      degree: item[0].name,
    };
    try {
      let resp = await CommonAPi(Endpoints.createDegree, data);
      if (resp && resp.message) {
        setDegreeId(resp.data.id);
        setDegree(resp.data.name);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleOnSearch = async (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setSearch(string);
    setInstitute(string);
    if (string) {
      try {
        let resp = await FetchApi(Endpoints.SearchPages + "?title=" + string);
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            setOptions(
              resp.data.map((item, index) => {
                return {
                  id: item.id,
                  image: item?.pageImage,
                  name: item?.title,
                };
              })
            );
            // setOptions(newData);
          } else {
            // const newData =
            setOptions([
              {
                id: 0,
                image: "",
                name: string,
                createName: string,
              },
            ]);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  const handleOnSearchStudyArea = async (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string) {
      try {
        let resp = await FetchApi(Endpoints.searchStudyArea + "?q=" + string);
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                name: item?.name,
                createNew: false,
              };
            });
            setStudyAreaOption(newData);
          } else {
            const newData = [
              {
                id: -1,
                name: string,
                createNew: true,
              },
            ];
            setStudyAreaOption(newData);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  const handleOnSearchDegree = async (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string) {
      try {
        let resp = await FetchApi(Endpoints.searchDegree + "?q=" + string);
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                name: item?.name,
                createNew: false,
              };
            });
            setDegreeOption(newData);
          } else {
            const newData = [
              {
                id: -1,
                name: string,
                createNew: true,
              },
            ];
            setDegreeOption(newData);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            Navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getacademics();
    }
    return () => {
      mounted = false;
    };
  }, [param?.id]);
  const getacademics = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.Getacademics + "?id=" + param?.id);
      if (resp && resp.success === true) {
        setAcademics(resp?.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleDeleteAcademics = async (id) => {
    try {
      let resp = await DeleteApi(Endpoints.Deleteacademics + "/" + id);
      if (resp && resp.success === true) {
        getacademics();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleEditAcademics = (data) => {
    setId(data?.id);
    setStudyArea(data?.study_area?.name);
    setInstitute(data.institute);
    setpageId(data?.pageId);
    setInstituteName(data?.institute);
    setInstituteNameKey(data?.institute);
    setDetails(data?.details);
    setstartingYear(data?.startingYear);
    setYear(data?.startingYear);
    setstartingMonth(data?.startingMonth);
    setendingYear(data?.endingYear);
    setEndYear(data?.endingYear);
    setendingMonth(data?.endingMonth);
    setstudyingCurrently(data?.studyingCurrently);
    setOptions([
      {
        name: data?.page?.title,
        id: data?.page?.id,
        image: data?.page?.pageImage,
      },
    ]);
    setDegreeKey(Math.ceil(Math.random() * 999999));
    setStudyAreaKey(Math.ceil(Math.random() * 999999));
    setDegreeId(data?.degreeId);
    setDegree(data?.degree?.name);
    setDegreeOption([
      {
        id: data?.degree?.id,
        name: data?.degree?.name,
        createNew: false,
      },
    ]);
    setStudyAreaId(data?.study_area?.id);
    setStudyAreaOption([
      {
        id: data?.study_area?.id,
        name: data?.study_area?.name,
        createNew: false,
      },
    ]);

    $("#Academicsmodal").modal("show");

    if (data?.startingYear) {
      let newdata = Totalyear.map((v, i) => {
        return {
          value: parseInt(data?.startingYear) + parseInt(i),
          label: parseInt(data?.startingYear) + parseInt(i),
        };
      });
      setTaotalEndingyearData(newdata);
    }
  };

  const handleCloseModel = () => {
    if (
      studyArea !== "" ||
      endingMonth !== "" ||
      startingMonth !== "" ||
      endingYear !== "" ||
      startingYear !== "" ||
      details !== "" ||
      institute !== "" ||
      instituteName !== ""
    ) {
      $("#discardInfoModal-Academics").modal("show");
    }
  };

  const handleDisCard = () => {
    setId("");
    setStudyArea("");
    setInstituteData("");
    setInstitute("");
    setInstituteName("");
    setInstituteNameKey(Math.ceil(Math.random() * 999999));
    setDegreeKey(Math.ceil(Math.random() * 999999));
    setStudyAreaKey(Math.ceil(Math.random() * 999999));
    setDegreeId("");
    setDegree("");
    setDegreeOption([]);
    setStudyAreaId("");
    setStudyAreaOption([]);
    setDetails("");
    setstartingYear("");
    setstartingMonth("");
    setendingYear("");
    setendingMonth("");
    setOptions([]);
    setstudyingCurrently(false);
    setendingMonthError("");
    setstartingMonthError("");
    setendingYearError("");
    setstartingYearError("");
    setDetailsError("");
    setInstituteError("");
    setStudyAreaError("");
    $("#discardInfoModal-Academics").modal("hide");
    $("#Academicsmodal").modal("hide");
  };

  const handleModel = () => {
    $("#discardInfoModal-Academics").modal("hide");
    $("#Academicsmodal").modal("show");
  };
  const handleValidation = (e, value, field) => {
    e.preventDefault();
    if (!value && field === "study") {
      setStudyAreaError("Please enter study area ");
    } else if (value && field === "study") {
      setStudyAreaError("");
    }
    if (!value && field === "institute") {
      if (!instituteData) {
        setInstituteError("Please enter institute");
      } else {
        setInstituteError("");
      }
    } else if (value && field === "institute") {
      setInstituteError("");
    }
    if (!value && field === "details") {
      setDetailsError("Please enter details");
    } else if (value && field === "details") {
      setDetailsError("");
    }
    if (!value && field === "startingYear") {
      setstartingYearError("Please enter starting year");
    } else if (value && field === "startingYear") {
      setstartingYearError("");
    }
    if (!value && field === "startingMonth") {
      setstartingMonthError("Please enter starting month");
    } else if (value && field === "startingMonth") {
      setstartingMonthError("");
    }
    if (!value && field === "endingYear") {
      setendingYearError("Please enter ending year");
    } else if (value && field === "endingYear") {
      setendingYearError("");
    }
    if (!value && field === "endingMonth") {
      setendingMonthError("Please enter ending month");
    } else if (value && field === "endingMonth") {
      setendingMonthError("");
    }
    if (!value && field === "degree") {
      setDegreeError("Please select degree");
    } else if (value && field === "degree") {
      setDegreeError("");
    }
  };

  const RenderStudyArea = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-StudyArea"
        defaultInputValue={studyArea}
        key={studyAreaKey}
        onSearch={handleOnSearchStudyArea}
        onChange={handleOnSelectStudyArea}
        onBlur={(e) => {
          handleValidation(e, e.target.value, "study");
        }}
        options={studyAreaOption}
        placeholder="For Ex: Finance"
        labelKey="name"
        minLength={1}
        emptyLabel={"Searching..."}
        className={
          studyAreaError ? "is-danger text-gray border" : " text-gray border"
        }
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [studyArea, studyAreaOption, studyAreaError, studyAreaKey]);

  const RenderDegree = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-Degree"
        defaultInputValue={degree}
        key={degreeKey}
        onSearch={handleOnSearchDegree}
        onChange={handleOnSelectDegree}
        onBlur={(e) => {
          handleValidation(e, e.target.value, "degree");
        }}
        options={degreeOption}
        placeholder="For Ex: Masters"
        labelKey="name"
        minLength={1}
        emptyLabel={"Searching..."}
        className={
          degreeError ? "is-danger text-gray border" : " text-gray border"
        }
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [degree, degreeOption, degreeError, degreeKey]);

  const RenderInstituteName = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example"
        defaultInputValue={instituteName}
        key={instituteNameKey}
        onSearch={handleOnSearch}
        onChange={handleOnSelect}
        onBlur={(e) => {
          handleValidation(e, e.target.value, "institute");
        }}
        // options={options}
        options={options.map((option) => ({
          ...option,
          name: option.name || "Unnamed", // Ensuring there's always a name property
        }))}
        placeholder="Search by institute"
        labelKey="name"
        minLength={1}
        emptyLabel={"Searching..."}
        className={
          instituteError
            ? "is-danger active text-gray px-1 py-1 border"
            : " active text-gray px-1 py-1 border"
        }
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <img
              alt={option.name}
              src={
                option.image
                  ? Endpoints.baseUrl + option.image
                  : "../assets/img/icon/briefcase.png"
              }
              className="rounded-circle"
              style={{
                height: "35px",
                marginRight: "10px",
                width: "35px",
                objectFit: "cover",
              }}
            />
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [instituteName, options, instituteError, instituteNameKey]);

  return (
    <div className="academic-section bg-white mb-4 p-3 rounded-15">
      <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-md-5 mb-4">
        <h4 className="medium fs-18">Academics</h4>
        {username === param?.id || userId === param?.id ? (
          <Link
            className="text-brown fs-12 medium"
            data-bs-toggle="modal"
            data-bs-target="#Academicsmodal"
          >
            Add <img src="../assets/img/icon/edit-plus.svg" alt="" width="12" />
          </Link>
        ) : (
          ""
        )}
        {/* <AcademicsModel /> */}
      </div>
      {academics.length > 0 ? (
        academics.map((item, index) => {
          return (
            <div className="row mb-4">
              <div className="col-md-3 pe-md-0 d-flex">
                <div className="image-section text-center w-100 d-flex align-items-center justify-content-center flex-column">
                  {item?.page?.pageImage === null ||
                  item?.page === null ||
                  item?.page?.pageImage === "undefined" ? (
                    <img
                      src="../assets/img/icon/briefcase.png"
                      className="mb-2 rounded-circle"
                      width="40"
                      height="40"
                      alt=""
                    />
                  ) : (
                    <img
                      src={Endpoints.baseUrl + item?.page?.pageImage}
                      className="mb-2 rounded-circle"
                      width="96"
                      height="96"
                      alt=""
                    />
                  )}
                  <h4 className="fs-14 text-darkgray pb-1">
                    {item?.startingYear}{" "}
                    <img
                      src="../assets/img/icon/icon-separator.svg"
                      alt=""
                      width="15"
                    />{" "}
                    {item?.studyingCurrently === true
                      ? " Present"
                      : item?.endingYear}
                  </h4>
                  <h4 className="fs-14 text-darkgray">
                    {getYear(
                      item?.startingMonth,
                      item?.startingYear,
                      item?.endingMonth,
                      item?.endingYear
                    )}
                  </h4>
                </div>
              </div>
              <div className="col-md-9 ps-md-0 d-flex">
                <div className="content-section w-100">
                  <div className="d-flex justify-content-between">
                    <h4 className="fs-18 text-blue pb-1">
                      {item.study_area.name}
                    </h4>
                    {username === param?.id || userId === param?.id ? (
                      <div className="position-relative">
                        <Link
                          id="notification"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <svg
                            viewBox="0 0 24 24"
                            width="22"
                            height="22"
                            stroke="#000"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="css-i6dzq1"
                          >
                            <circle cx="12" cy="12" r="1"></circle>
                            <circle cx="19" cy="12" r="1"></circle>
                            <circle cx="5" cy="12" r="1"></circle>
                          </svg>
                        </Link>

                        <div
                          className="dropdown-menu haspopup fs-12 dropdown-menu-end"
                          aria-labelledby="notification"
                        >
                          <ul>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  setItem(item.id);
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#deleteInfoModal-Academics"
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  width="14"
                                  height="14"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="css-i6dzq1"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                                &nbsp; Delete
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={() => handleEditAcademics(item)}
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  width="14"
                                  height="14"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="css-i6dzq1"
                                >
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>
                                &nbsp; Edit
                              </Link>
                            </li>
                            {/* <li><Link className="dropdown-item" to="#">
                                                        <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line></svg>
                                                        &nbsp;
                                                        Restricted
                                                    </Link></li> */}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <h5 className="text-brown fs-16 pb-3">{item.institute}</h5>
                  <p className="fs-16 mb-0">{item.details}</p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="row mb-4 text-center">Not Added Academics</div>
      )}

      <div
        className="modal fade"
        id="Academicsmodal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog rounded-10 box-shadow p-lg-4 p-md-3 p-2 bg-white modal-650p">
          <div className="modal-content border-0">
            <div className="d-flex align-items-center justify-content-between pb-3 border-bottom mb-3 w-100">
              <h3 className="medium text-blue"> Academics</h3>
              <button
                type="button"
                className="btn-close kids-close-icon academics cursor-pointer"
                onClick={handleCloseModel}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#949494"
                  width="22"
                  height="22"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="modal-body">
              <h4 className="medium text-black pb-3">
                {" "}
                Fill the form below to {id ? "update" : "create"} academics
              </h4>

              <form
                onSubmit={handleSubmit}
                className="kids-form generic-form"
                id="myform"
              >
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label>Study Area</label>

                    {RenderStudyArea()}
                    {studyAreaError ? (
                      <div
                        className="form-error"
                        style={{ color: "white", margintop: "-10px" }}
                      >
                        {studyAreaError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label>Degree</label>

                    {RenderDegree()}
                    {studyAreaError ? (
                      <div
                        className="form-error"
                        style={{ color: "white", margintop: "-10px" }}
                      >
                        {studyAreaError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 mb-3">
                    <label>School/Institute/University</label>

                    {RenderInstituteName()}

                    {instituteError ? (
                      <div
                        className="form-error"
                        style={{ color: "white", margintop: "-10px" }}
                      >
                        {instituteError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-12 mb-3">
                    <div className="d-flex justify-content-between">
                      <label>Description </label>
                      <small className="text-end">{details.length}/5000</small>
                    </div>

                    <textarea
                      placeholder="Description"
                      maxLength={5000}
                      value={details}
                      onBlur={(e) => {
                        handleValidation(e, e.target.value, "details");
                      }}
                      className={
                        detailsError
                          ? "is-danger  text-gray border"
                          : " text-gray border"
                      }
                      onChange={(e) => {
                        setDetails(e.target.value);
                      }}
                    ></textarea>

                    {detailsError ? (
                      <div
                        className="form-error"
                        style={{ color: "white", margintop: "-10px" }}
                      >
                        {detailsError}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* { "Remaining " + parseInt(parseInt(100) - parseInt(details.length) )+ " characters of 100 characters"} */}
                  </div>

                  <div className="col-md-6 mb-3 ">
                    <label>Starting Year</label>
                    <select
                      onChange={(e) => {
                        handleStartingYear(e.target.value);
                        setstartingYear(e.target.value);
                        setYear(e.target.value);
                      }}
                      onBlur={(e) => {
                        handleValidation(e, e.target.value, "startingYear");
                      }}
                      className={
                        startingYearError
                          ? "is-danger text-gray px-3 py-3 border"
                          : "text-gray px-3 py-3 border"
                      }
                    >
                      <option value={""}>Select starting Year</option>
                      {TotalyearData.map((item, i) => {
                        return (
                          <option
                            selected={
                              Math.round(startingYear) === item.value
                                ? true
                                : false
                            }
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                    {startingYearError ? (
                      <div
                        className="form-error"
                        style={{ color: "white", margintop: "-10px" }}
                      >
                        {startingYearError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-md-6 mb-3 ">
                    <label>Starting Month</label>
                    <select
                      onChange={(e) => {
                        setstartingMonth(e.target.value);
                      }}
                      onBlur={(e) => {
                        handleValidation(e, e.target.value, "startingMonth");
                      }}
                      className={
                        startingMonthError
                          ? "is-danger  text-gray px-3 py-3 border"
                          : " text-gray px-3 py-3 border"
                      }
                    >
                      <option value={""}>Select starting Month</option>

                      {pastMonths.map((item) => {
                        return (
                          <option
                            selected={
                              startingMonth === item.value ? true : false
                            }
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                    {startingMonthError ? (
                      <div
                        className="form-error"
                        style={{ color: "white", margintop: "-10px" }}
                      >
                        {startingMonthError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {studyingCurrently === false ? (
                    <div className="col-md-6 mb-3">
                      <label>Ending Year</label>
                      <select
                        onChange={(e) => {
                          setendingYear(e.target.value);
                          setEndYear(e.target.value);
                        }}
                        onBlur={(e) => {
                          handleValidation(e, e.target.value, "endingYear");
                        }}
                        className={
                          endingYearError
                            ? "is-danger  text-gray px-3 py-3 border"
                            : " text-gray px-3 py-3 border"
                        }
                      >
                        <option value={""}>Select Ending Year</option>
                        {TotalEndingyearData.map((item, i) => {
                          return (
                            <>
                              {item.value <= year ? (
                                <option
                                  selected={
                                    Math.round(endingYear) === item.value
                                      ? true
                                      : false
                                  }
                                  value={item.value}
                                >
                                  {item.label}
                                </option>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </select>
                      {endingYearError ? (
                        <div
                          className="form-error"
                          style={{ color: "white", margintop: "-10px" }}
                        >
                          {endingYearError}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {studyingCurrently === false ? (
                    <div className="col-md-6 mb-3">
                      <label>Ending Month</label>
                      <select
                        onChange={(e) => {
                          setendingMonth(e.target.value);
                        }}
                        onBlur={(e) => {
                          handleValidation(e, e.target.value, "endingMonth");
                        }}
                        className={
                          endingMonthError
                            ? "is-danger  text-gray px-3 py-3 border"
                            : " text-gray px-3 py-3 border"
                        }
                      >
                        <option value={""}>Select Ending Month</option>
                        {endingMonths.map((item) => {
                          return (
                            <option
                              selected={
                                endingMonth === item.value ? true : false
                              }
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                      {endingMonthError ? (
                        <div
                          className="form-error"
                          style={{ color: "white", margintop: "-10px" }}
                        >
                          {endingMonthError}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-md-12 mb-3">
                    <label> Studying currently </label>
                    &nbsp; &nbsp;
                    {studyingCurrently === false ? (
                      <input
                        type="checkbox"
                        placeholder=""
                        className=" text-gray px-3 py-3 border"
                        onChange={(e) => {
                          setstudyingCurrently(true);
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        placeholder=""
                        className=" text-gray px-3 py-3 border"
                        checked
                        onChange={(e) => {
                          setstudyingCurrently(false);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-md-12 text-end">
                    <button
                      type="submit"
                      disabled={isDisabled}
                      className="btn fs-14 "
                    >
                      {" "}
                      {id ? "Update" : "Create"} Academics{" "}
                      <img
                        src="../assets/img/icon/icon-right-arrow.svg"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <DeletePrompt
        type={"Academics"}
        item={item}
        deleteType={handleDeleteAcademics}
      />
      <DiscardPopup
        {...{
          type: "Academics",
          onSuccess: handleModel,
          onDisCard: handleDisCard,
        }}
      />
    </div>
  );
}
