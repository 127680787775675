import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import ReactStars from "react-rating-stars-component";
const $ = window.jQuery;

export default function PopularCourses() {
  const [courseData, setCourseData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    courseListing();
    setTimeout(() => {
      $(".course-slider-single").owlCarousel({
        loop: true,
        margin: 1,
        nav: true,
        dots: true,
        autoplay: false,
        autoplayTimeout: 3000,
        navText: [
          "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' className='feather feather-chevron-left'><polyline points='15 18 9 12 15 6'></polyline></svg>",
          "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' className='feather feather-chevron-right'><polyline points='9 18 15 12 9 6'></polyline></svg>",
        ],
        items: 1,
      });
    }, 2000);
  }, []);

  const courseListing = async () => {
    try {
      let resp = await FetchApi(Endpoints.courseListing + "?size=3");
      if (resp && resp.success === true) {
        setCourseData(resp?.data?.course);
        setTimeout(() => {
          $(".course-slider-single").owlCarousel({
            loop: true,
            margin: 1,
            nav: true,
            dots: true,
            autoplay: false,
            autoplayTimeout: 3000,
            navText: [
              "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' className='feather feather-chevron-left'><polyline points='15 18 9 12 15 6'></polyline></svg>",
              "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' className='feather feather-chevron-right'><polyline points='9 18 15 12 9 6'></polyline></svg>",
            ],
            items: 1,
          });
        }, 100);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  return (
    <>
      {courseData.length > 0 ? (
        <div className="sidebar-widget mt-4">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="medium">Popular Courses</h4>
            <Link to={"/courses"} className="text-brown fs-12 medium">
              See all{" "}
              <img
                src="../assets/img/icon/icon-right-arrow-brown.svg"
                className="ps-1 text-brown"
                alt=""
              />
            </Link>
          </div>

          <div className="pt-3 mt-2 border-top">
            <div className="course-slider-single slider-item-border owl-theme owl-carousel">
              {courseData.map((item, index) => {
                return (
                  <div className="course-item">
                    <div className="course-img popular-course-img">
                      {item.thumbnail === null ? (
                        <img src="../assets/img/course.png" alt="" />
                      ) : (
                        <img src={Endpoints.baseUrl + item.thumbnail} alt="" />
                      )}
                    </div>

                    <div className="p-2">
                      <div className="d-flex align-items-center justify-content-between pt-2">
                        <span className="bg-lightbrown text-brown fs-12 medium py-1 px-3 rounded-pill">
                          {item?.course_category?.name}
                        </span>
                        <div className="d-flex">
                          <span className="course-rating d-flex  w-100">
                            <ReactStars
                              count={5}
                              edit={false}
                              value={item.avgRating}
                              size={18}
                              isHalf={true}
                              char={
                                <img
                                  src="../assets/img/icon/state-null.svg"
                                  width="16px"
                                  alt=""
                                />
                              }
                              emptyIcon={
                                <img
                                  src="../assets/img/icon/state-null.svg"
                                  width="16px"
                                  alt=""
                                />
                              }
                              halfIcon={
                                <img
                                  src="../assets/img/icon/state-half.svg"
                                  width="16px"
                                  alt=""
                                />
                              }
                              filledIcon={
                                <img
                                  src="../assets/img/icon/State-fill.svg"
                                  width="16px"
                                  alt=""
                                />
                              }
                              activeColor="#ffd700"
                              activeBackgroundColor="#ffd700"
                            />
                          </span>
                          <span className="fs-12 medium text-gray">
                            ({item.totalRatings})
                          </span>
                        </div>
                      </div>

                      <h5 className="medium py-3">
                        {item?.title?.length <= 22
                          ? item?.title
                          : item?.title.substr(0, 22) + "..."}
                      </h5>

                      <div className="text-center  pb-2">
                        <div className="border-top course-price py-2 medium fs-18">
                          <ins className="text-blue">
                            ₹{item.discountedPrice}
                          </ins>
                          <del className="text-lightgray px-1">
                            ₹{item?.price}
                          </del>
                        </div>

                        <Link
                          to={"/course-detail/" + item.slug}
                          target={"_blank"}
                          className="btn py-2 fs-14"
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
