import React, { useEffect, useState, useCallback } from "react";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate } from "react-router-dom";

export default function TopCompany() {
    const [company, setCompany] = useState([]);
    const navigate = useState();
    useEffect(()=>{
        getCompany();
    }, []);

    const getCompany = async() =>{
        try {
            let resp = await FetchApi(Endpoints.CourseTopCompany);
            if (resp && resp.success === true) {
                setCompany(resp.data);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };

    return (
        <div className="bg-white main-common mt-4">

            <div className="text-center">
                <h3 className="bold pb-4">Trusted by top companies</h3>

            </div>

            <div className="row">
                <div className="col-md-6 p-0">
                    <div className="row mx-0">
                        {company.map((item, index)=>{
                            if(index > 3) return null;
                            return (
                                <div className="col-md-3 col-sm-6 col-6 text-center pb-md-0 pb-4" key={index}>
                                    <Link to={"/page-profile/" + item?.slug}>
                                   
                                    <img src={Endpoints.baseUrl + item?.pageImage} alt="" style={{
                                        maxWidth:"86px",
                                        minWidth:"86px",
                                        maxHeight:"86px",
                                        minHeight:"86px",
                                        objectFit:"contain"
                                    }} />
                                    <h6 className="fs-14 medium pt-2">{item?.title}</h6>
                                    </Link>
                                </div>
                            )
                        })}
                        
                        
                        
                    </div>
                </div>

                <div className="col-md-6 p-0">
                    <div className="row mx-0">
                       
                        {company.map((item, index)=>{
                            if(index < 3) return null;
                            return (
                                <div className="col-md-3 col-sm-6 col-6 text-center pb-md-0 pb-4" key={index}>
                                    <img src={Endpoints.baseUrl + item?.pageImage} alt="" style={{
                                        maxWidth:"86px",
                                        minWidth:"86px",
                                        maxHeight:"86px",
                                        minHeight:"86px",
                                    }} />
                                    <h6 className="fs-14 medium pt-2">{item?.title}</h6>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

        </div>
    )
}
