import React, { useState, useEffect, useRef } from "react";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import { FetchApi } from "../../API/FetchApi";
import Post from "../../Components/Post/Post";
import BlogCard from "../../Components/BlogCard/BlogCard";
import PostLoader from "../../Components/PostLoader/PostLoader";
import { useNavigate, useParams } from "react-router-dom";
import SharedPost from "../../Components/SharedPost/SharedPost";
import ToastMessage from "../../Utils/ToastMessage";
import SharedBlogCard from "../../Components/SharedBlogCard/SharedBlogCard";
import SharedMedia from "../../Components/SharedMedia/SharedMedia";
import PostShare from "../../Components/PostShare/PostShare";

export default function PostItem({ page, setStatistics, showFilters = false }) {
  const param = useParams();
  const [timelinePosts, setTimelinePosts] = useState([]);
  const [mouserHovering, setMouserHovering] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [postPage, setPostPage] = useState(1);
  const userId = parseInt(localStorage.getItem("userId"));
  const listInnerRef = useRef();
  const history = useNavigate();
  const [aboutData, setAboutData] = useState();
  const [scope, setScope] = useState("All");

  const handleScroll = (e) => {
    if (loading) return;
    const scrollTop = listInnerRef.current.scrollTop;
    const offsetHeight = listInnerRef.current.offsetHeight;
    const scrollHeight = listInnerRef.current.scrollHeight;
    const remaining = scrollHeight - offsetHeight - scrollTop;

    if (remaining < 100) {
      setLoading(true);
      fetchNext();
    }
  };
  const fetchNext = () => {
    setTimeout(() => {
      setPostPage(postPage + 1);
    }, 100);
  };

  const getCompanyAbout = async () => {
    try {
      let resp = await FetchApi(Endpoints.PageDetail + "?slug=" + param?.slug);
      if (resp && resp.success === true) {
        setAboutData(resp?.data);
        setStatistics({
          totalEmployees: resp?.data?.totalEmployees,
          totalFollowers: resp?.data?.totalFollowers,
          totalJobs: resp?.data?.totalJobs,
        });
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  useEffect(() => {
    getUserPosts();
  }, [postPage]);

  const getUserPosts = async (createPage = null) => {
    // if (!hasMorePosts) return;
    setLoading(true);
    try {
      let resp = await CommonAPi(
        Endpoints.PageFeeds + "?slug=" + param?.slug + "&page=" + postPage
      );
      if (resp && resp.success === true) {
        if (resp.data.length === 0) {
          setHasMorePosts(false);
        } else {
          if (createPage == null) {
            setTimelinePosts([...timelinePosts, ...resp.data]);
          } else {
            setTimelinePosts([...resp.data]);
          }
        }
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  useEffect(() => {
    getUserPosts();
    getCompanyAbout();
  }, []);

  const getUserPostsScope = async (createPage = null, fetchScope = scope) => {
    setLoading(true);
    try {
      let resp = await CommonAPi(
        Endpoints.PageFeeds +
          "?slug=" +
          param?.slug +
          "&page=" +
          postPage +
          "&scope=" +
          fetchScope
      );
      if (resp && resp.success === true) {
        setLoading(false);
        setHasMorePosts(false);
        setTimelinePosts(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
    setLoading(false);
  };

  const showReactionsBox = () => {
    setMouserHovering(true);
  };
  const hideReactionsBox = () => {
    setTimeout(() => {
      setMouserHovering(false);
    }, 1400);
  };
  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "y";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + "d";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + "h";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min";
    return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec" : "0 sec";
  };

  const filterOptions = [
    { value: "", label: "All" },
    { value: "Admin", label: "Admin Post" },
    { value: "Hastag", label: "Hastag" },
    { value: "Posts", label: "Posts" },
    { value: "Photos", label: "Photos" },
    { value: "Videos", label: "Videos" },
  ];

  function formatDateTime(postDate) {
    let date = new Date(postDate);
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    let formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }
  return (
    <>
      {showFilters && (
        <div className="px-4 py-4 bg-white mb-3 rounded-15 shadow-md">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="medium fs-20">All Posts</h4>
            <div className="">
              <select
                onChange={(e) => {
                  getUserPostsScope(null, e.target.value);
                  setScope(e.target.value);
                }}
                className={`w-auto px-3 ${
                  scope === "Member" && "pe-5"
                } rounded-pill bg-eee text-black py-2 posts-profile-select`}
              >
                {filterOptions?.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
      {page === "homePage" ? (
        userId === aboutData?.userId ? (
          <PostShare
            page="page"
            setPostPage={setPostPage}
            setTimelinePosts={setTimelinePosts}
            postPage={postPage}
            getUserPosts={getUserPosts}
            pageId={aboutData?.id}
          />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      <ul
        className="timeline-feed feed-list"
        onScroll={handleScroll}
        ref={listInnerRef}
      >
        {timelinePosts.length > 0
          ? timelinePosts.map((post, i) => {
              if (
                post.category === "Blog" &&
                post.isShared === 0 &&
                post.isMediaShared === 0
              ) {
                return (
                  <BlogCard
                    post={post}
                    key={post.id}
                    getUserPosts={getUserPosts}
                    mouserHovering={mouserHovering}
                    showReactionsBox={showReactionsBox}
                    hideReactionsBox={hideReactionsBox}
                    timeSince={timeSince}
                    formatDateTime={formatDateTime}
                  />
                );
              } else if (
                post.isShared &&
                post.isMediaShared === 0 &&
                post.category !== "Blog"
              ) {
                return (
                  <SharedPost
                    post={post}
                    getUserPosts={getUserPosts}
                    key={post.id}
                    mouserHovering={mouserHovering}
                    showReactionsBox={showReactionsBox}
                    hideReactionsBox={hideReactionsBox}
                    timeSince={timeSince}
                    formatDateTime={formatDateTime}
                  />
                );
              } else if (
                post.isShared &&
                post.isMediaShared &&
                post.category !== "Blog"
              ) {
                return (
                  <SharedMedia
                    post={post}
                    getUserPosts={getUserPosts}
                    key={post.id}
                    mouserHovering={mouserHovering}
                    showReactionsBox={showReactionsBox}
                    hideReactionsBox={hideReactionsBox}
                    timeSince={timeSince}
                    formatDateTime={formatDateTime}
                  />
                );
              } else if (
                post.isShared === 0 &&
                post.isMediaShared === 0 &&
                post.category !== "Blog"
              ) {
                // console.log("inside fourth logic");
                return (
                  <Post
                    post={post}
                    getUserPosts={getUserPosts}
                    key={post.id}
                    mouserHovering={mouserHovering}
                    showReactionsBox={showReactionsBox}
                    hideReactionsBox={hideReactionsBox}
                    timeSince={timeSince}
                    formatDateTime={formatDateTime}
                  />
                );
              }

              // else if (post.category === "Link") {
              //     return (
              //         <LinkPost
              //             post={post}
              //             key={post.id}
              //             getUserPosts={getUserPosts}
              //             mouserHovering={mouserHovering}
              //             showReactionsBox={showReactionsBox}
              //             hideReactionsBox={hideReactionsBox}
              //             timeSince={timeSince}
              //             setTimelinePosts={setTimelinePosts}
              //             setPostPage={setPostPage}
              //             postPage={postPage}
              //             formatDateTime={formatDateTime}
              //             listInnerRef={listInnerRef}
              //         />
              //     );
              // }
              else if (post.category === "Blog" && post.isShared !== 0) {
                return (
                  <SharedBlogCard
                    post={post}
                    key={post.id}
                    getUserPosts={getUserPosts}
                    mouserHovering={mouserHovering}
                    showReactionsBox={showReactionsBox}
                    hideReactionsBox={hideReactionsBox}
                    timeSince={timeSince}
                    formatDateTime={formatDateTime}
                  />
                );
              }
            })
          : timelinePosts.length === 0 &&
            !loading && <li className="text-center mt-4 p-5">No post yet</li>}
        {hasMorePosts === true && <PostLoader />}
      </ul>
    </>
  );
}
