import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FetchApi } from "../API/FetchApi";
import { Endpoints } from "../API/Endpoints";
import ToastMessage from "../Utils/ToastMessage";
import { CommonAPi } from "../API/CommonApi";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import Swal from "sweetalert2";
import Container from "../Components/Container";
import LeftNavigation from "../Components/LeftNavigation";
import OnlineFriends from "../Components/OnlineFriends";
import { PatchApi } from "../API/PatchApi";
import { Helmet } from "react-helmet";
export default function UpdatePage() {
  const param = useParams();
  const [pageName, setPageName] = useState("");
  const [pageUserName, setPageUserName] = useState("");
  const [website, setWebsite] = useState("");
  const [websiteStatus, setWebsiteStatus] = useState(false);
  const [Industry, setIndustry] = useState("");
  const [IndustryKey, setIndustryKey] = useState(
    Math.ceil(Math.random() * 999999)
  );
  const [IndustryOption, setIndustryOption] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  // const [schoolOption, setSchoolOption] = useState([
  //     {
  //         id: "Primary",
  //         name: "Primary",
  //     },
  //     {
  //         id: "High School",
  //         name: "High School",
  //     },
  //     {
  //         id: "Intermediate",
  //         name: "Intermediate",
  //     }
  // ]);
  const [OrganizationSize, setOrganizationSize] = useState("");
  const [OrganizationType, setOrganizationtype] = useState("");
  const [logo, setLogo] = useState("");
  const [logoName, setLogoName] = useState("");
  const [Tagline, setTagline] = useState("");
  const [Description, setDescription] = useState("");
  const [mobile, setMobile] = useState("");
  const [Founded, setFounded] = useState("");
  const [OrganizationSizeData, setOrganizationSizeData] = useState([]);
  const [OrganizationTypeData, setOrganizationtypeData] = useState([]);
  const [email, setEmail] = useState("");
  const [pageType, setPageType] = useState("");
  const [status, setStatus] = useState(false);

  // location state
  const [CountryData, setCountryData] = useState([]);
  const [location, setLocation] = useState([]);

  // Error state
  const [pageNameError, setPageNameError] = useState("");
  const [pageUserNameError, setPageUserNameError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [websiteStatusError, setWebsiteStatusError] = useState("");
  const [IndustryError, setIndustryError] = useState("");
  const [OrganizationSizeError, setOrganizationSizeError] = useState("");
  const [OrganizationTypeError, setOrganizationtypeError] = useState("");
  const [logoError, setLogoError] = useState("");
  const [TaglineError, setTaglineError] = useState("");
  const [DescriptionError, setDescriptionError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [FoundedError, setFoundedError] = useState("");

  const [dataLoading, setDataLoading] = useState(false);

  const [pageId, setPageId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [
    pageNameError,
    pageUserNameError,
    websiteError,
    IndustryError,
    OrganizationSizeError,
    OrganizationTypeError,
    logoError,
    TaglineError,
    DescriptionError,
  ]);

  const getOrganizationSizeData = async () => {
    try {
      let resp = await FetchApi(Endpoints.pagesSizeAll);
      if (resp && resp.success === true) {
        setOrganizationSizeData(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getCountry = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetCountry);
      if (resp && resp.success === true) {
        // setCountryData(resp.data);
        const newData = resp.data.map((item, index) => {
          return {
            id: item.id,
            name: item.countryName,
          };
        });
        setCountryData(newData);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getOrganizationTypeData = async () => {
    try {
      let resp = await FetchApi(Endpoints.pagesTypesAll);
      if (resp && resp.success === true) {
        setOrganizationtypeData(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getLogo = useCallback(
    async (url) => {
      const blob = await fetch(`${Endpoints.baseUrl}${url}`).then((res) =>
        res.blob()
      );
      setLogo(blob);
      setLogoName(`${param.type}logo`);
    },
    [param.type]
  );

  const getPage = useCallback(async () => {
    setDataLoading(true);
    try {
      let resp = await FetchApi(Endpoints.PageDetail + "?slug=" + param?.slug);
      setDataLoading(false);
      if (resp && resp.success === true) {
        const data = resp.data;
        setPageId(data.id);
        setPageName(data.title);
        setPageUserName(data.slug);
        setEmail(data.email);
        setWebsite(data.website);
        setWebsiteStatus(!data.website);
        setIndustry(data.industry);
        setOrganizationSize(data.organizationSize);
        setOrganizationtype(data.organizationType);
        setDescription(data.description);
        setTagline(data.tagline);
        setMobile(data.phone);
        setFounded(data.yearFounded);

        getLogo(data.pageImage);
      }
    } catch (e) {
      setDataLoading(false);
      if (e?.response?.data?.message) {
        ToastMessage.Error(e.response.data.message);
      }
    }
  }, [getLogo, param]);

  useEffect(() => {
    getOrganizationSizeData();
    getOrganizationTypeData();
    getCountry();
    getPage();
  }, []);

  const handleImage = (e) => {
    setLogo(e.target.files[0]);
    setLogoName(e.target.files[0]?.name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!pageId) return;
    setStatus(true);
    let processForm = true;
    if (!pageName) {
      setPageNameError("Please enter page name");
      processForm = false;
      setStatus(processForm);
    } else {
      setPageNameError("");
    }
    if (!website && !websiteStatus) {
      setWebsiteError("Please enter website");

      processForm = false;
      setStatus(processForm);
    } else {
      setWebsiteError("");
    }
    if (!Industry) {
      setIndustryError("Please enter industry");

      processForm = false;
      setStatus(processForm);
    } else {
      setIndustryError("");
    }
    if (!OrganizationSize) {
      setOrganizationSizeError("Please enter organization size");

      processForm = false;
      setStatus(processForm);
    } else {
      setOrganizationSizeError("");
    }
    if (!OrganizationType) {
      setOrganizationtypeError("Please enter organization type");

      processForm = false;
      setStatus(processForm);
    } else {
      setOrganizationtypeError("");
    }
    // if (!logo) {
    //   setLogoError("Please choose logo");

    //   processForm = false;
    //   setStatus(processForm);
    // } else {
    //   setLogoError("");
    // }
    if (!Tagline) {
      setTaglineError("Please enter tagline");

      processForm = false;
      setStatus(processForm);
    } else {
      setTaglineError("");
    }
    if (!Description) {
      setDescriptionError("Please enter description");

      processForm = false;
      setStatus(processForm);
    } else {
      setDescriptionError("");
    }
    if (!mobile) {
      setMobileError("Please enter mobile");

      processForm = false;
      setStatus(processForm);
    } else {
      setMobileError("");
    }
    if (!Founded) {
      setFoundedError("Please enter founded");

      processForm = false;
      setStatus(processForm);
    } else {
      setFoundedError("");
    }

    if (processForm) {
      const payload = {
        title: pageName,
        page_type: param.type,
        description: Description,
        industry: Industry,
        phone: mobile,
        email: email,
        website: website,
        organization_size: OrganizationSize,
        organization_type: OrganizationType,
        tagline: Tagline,
        year_founded: Founded,
      };

      let newFile = new File([logo], "image.jpg", { type: "image/jpg" });
      const formData = new FormData();
      formData.append("page_image", newFile);
      formData.append("id", pageId);

      // logo upload
      try {
        let logoUploadRes = await PatchApi(Endpoints.PageLogoImage, formData);
        if (!logoUploadRes.success) {
          ToastMessage.Info(
            "Failed to upload logo. Trying to update other info."
          );
        }
      } catch (e) {
        if (e?.response?.data?.message) {
          setStatus(false);
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }

      try {
        let resp = await PatchApi(`${Endpoints.pagesEdit}/${pageId}`, payload);
        if (resp?.success) {
          // if (location.length > 0) {
          //   for (var i = 0; i < location.length; i++) {
          //     location[i]["page_id"] = resp?.data?.id;
          //     saveLocation(location[i], resp?.data?.pageType);
          //   }
          // } else {
          let pageType = param?.type;
          setStatus(false);
          if (pageType === "University") {
            navigate("/university");
          } else if (pageType === "Company") {
            navigate("/company");
          } else if (pageType === "School") {
            navigate("/schools");
          } else {
            navigate("/pages");
          }
          // }
          ToastMessage.Success(resp.message);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          setStatus(false);
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const saveLocation = async (data, pageType) => {
    try {
      let resp = await CommonAPi(Endpoints.pagesLocationCreate, data);
      if (resp && resp.message) {
        setStatus(false);
        if (pageType === "University") {
          navigate("/university");
        } else if (pageType === "Company") {
          navigate("/schools");
        } else if (pageType === "School") {
          navigate("/schools");
        } else {
          navigate("/pages");
        }
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setStatus(false);
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleAddLocation = () => {
    let data = {
      page_id: "",
      country_id: "",
      location_name: "",
      street_address: "",
      postal_code: "",
      city: "",
      state: "",
    };
    setLocation([...location, data]);
  };

  const handleLocationData = (value, index, indexname) => {
    location[index][indexname] = value;
  };

  const handleTypeaheadLocationData = (key, indexname) => (value) => {
    if (value.length) {
      location[key][indexname] = value[0]?.name;
      console.log(location);
    }

    console.log(`Key: ${key}, Value: ${value}`);
  };

  const handleOnSearchIndustry = async (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setIndustry(string);
    if (string) {
      try {
        let resp = await FetchApi(
          Endpoints.SearchIndustry + "?title=" + string
        );
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                name: item.value,
              };
            });
            setIndustryOption(newData);
          } else {
            const newData = [
              {
                id: -1,
                name: string,
                createNew: true,
              },
            ];
            setIndustryOption(newData);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleOnSearchCity = async (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string) {
      try {
        let resp = await FetchApi(Endpoints.SearchCities + "?term=" + string);
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                name: item?.city,
              };
            });
            setCityOptions(newData);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleOnSearchState = async (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string) {
      try {
        let resp = await FetchApi(Endpoints.SearchStates + "?term=" + string);
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                name: item?.state,
              };
            });
            setStateOptions(newData);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  const handleOnSelectIndustry = (item) => {
    if (item.length > 0) {
      setIndustry(item[0].name);
    } else {
      setIndustry("");
    }
    if (item.length > 0) {
      if (item[0].createNew === true) {
        // handleCreate(item);
      } else {
        setIndustry(item[0].name);
      }
    } else {
      setIndustry("");
    }
  };

  const RenderIndustry = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-industry"
        defaultInputValue={Industry}
        key={IndustryKey}
        onSearch={handleOnSearchIndustry}
        onChange={handleOnSelectIndustry}
        // onBlur={(e) => { handleValidation(e, e.target.value, "study") }}
        options={IndustryOption}
        placeholder={
          param?.type === "School"
            ? "For Ex: High School"
            : "For Ex: IT Services and IT Consulting"
        }
        labelKey="name"
        minLength={1}
        emptyLabel={"No result found"}
        className={
          IndustryError
            ? " border rounded-2 fs-14 p-2 "
            : " border rounded-2 fs-14 p-2 "
        }
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [Industry, IndustryError, IndustryOption]);

  // const checkSpecialChar = (e) => {
  //     if (!/^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/.test(e.target.value)) {
  //         e.preventDefault();
  //         setPageUserNameError("Please no use any special characters ")
  //     }
  // };

  const handlePageNameChange = async (value) => {
    if (value) {
      try {
        if (!/^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/.test(value)) {
          setPageUserNameError("Username can only accept alphanumeric values");
        }

        let resp = await FetchApi(Endpoints.CheckPageSlug + "?title=" + value);
        if (resp && resp.success === true) {
          console.log(resp.data);
          if (resp.data) {
            setPageUserNameError(
              "This username is already in use. Kindly use a different value."
            );
          } else {
            setPageUserNameError("");
          }
        } else {
          setPageUserNameError("");
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleBackButtonClick = () => {
    if (pageName || website) {
      Swal.fire({
        text: "You have unsaved changes, do you still want to exit?",
        icon: "question",
        customClass: {
          confirmButton: "btn px-3 mx-2",
          cancelButton: "btn btn-br-blue px-3",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Yes, discard it!",
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          navigate(-1);
          // navigate("/select-page-type");
        }
      });
    } else {
      navigate(-1);
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>{param.type}</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />

          <div className="container py-3">
            <div className="bg-white rounded-10 shadow-lg">
              <div className="py-3 px-3 row align-items-center">
                <div className="col-md-3">
                  <button className="btn px-4" onClick={handleBackButtonClick}>
                    <svg
                      viewBox="0 0 24 24"
                      width="16"
                      height="16"
                      stroke="currentColor"
                      stroke-width="2"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="css-i6dzq1"
                    >
                      <line x1="19" y1="12" x2="5" y2="12"></line>
                      <polyline points="12 19 5 12 12 5"></polyline>
                    </svg>{" "}
                    Back
                  </button>
                </div>

                <div className="col-md-9">
                  <p className="medium" style={{ margin: "0 0 0 200px" }}>
                    {param.type}
                  </p>
                </div>
              </div>

              {dataLoading && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: 500 }}
                >
                  <div className="d-flex justify-content-center my-3">
                    <div
                      class="spinner-border text-dark spinner-border-sm"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              )}

              {!dataLoading && (
                <div className="border-top py-md-5 px-md-5 p-3">
                  <p className="text-darkgray fs-12 medium">
                    * All fields are required.
                  </p>

                  <form onSubmit={handleSubmit} className="company-form">
                    <div className="row gx-md-50">
                      <div className="col-md-6 mb-4">
                        <label for="" className="fs-15 medium d-block pb-2">
                          {param?.type} Name*
                        </label>
                        <input
                          type="text"
                          placeholder="Add your organizations name"
                          className="border rounded-2 fs-14 p-3 "
                          value={pageName}
                          onChange={(e) => {
                            setPageName(e.target.value);
                          }}
                        />
                        {pageNameError ? (
                          <span className="field-error fs-12 text-red">
                            {pageNameError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-6 mb-4">
                        <label for="" className="fs-15 medium d-block pb-2">
                          {param?.type} username *
                        </label>
                        <input
                          type="text"
                          placeholder="Add your page username"
                          className="border rounded-2 fs-14 p-3 "
                          value={pageUserName}
                          // onBlur={(e) => handlePageNameChange(e.target.value)}
                          readOnly
                          disabled
                        />
                        {pageUserNameError ? (
                          <span className="field-error fs-12 text-red">
                            {pageUserNameError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-6 mb-4">
                        <label for="" className="fs-15 medium d-block pb-2">
                          Website *
                        </label>
                        {!websiteStatus && (
                          <input
                            type="text"
                            placeholder="Add your website link"
                            className="border rounded-2 fs-14 p-3 "
                            value={website}
                            onChange={(e) => {
                              setWebsite(e.target.value);
                            }}
                          />
                        )}
                        {websiteError ? (
                          <span className="field-error fs-12 text-red">
                            {websiteError}
                          </span>
                        ) : (
                          ""
                        )}
                        <div className="d-flex align-items-center gap-2 pt-2">
                          {/* {websiteStatus === false ? (
                            <input
                              type="checkbox"
                              className="w-auto border"
                              onChange={() => {
                                setWebsiteStatus(true);
                                // setWebsiteError("")
                              }}
                            />
                          ) : (
                            <input
                              type="checkbox"
                              className="w-auto border"
                              onChange={() => {
                                setWebsiteStatus(false);
                              }}
                              checked
                            />
                          )} */}
                          <input
                            type="checkbox"
                            className="w-auto border"
                            onChange={() => {
                              const newStatus = !websiteStatus;
                              setWebsiteStatus(newStatus);
                              if (newStatus) setWebsite(""); // Clears the website field when the checkbox is checked
                            }}
                            checked={websiteStatus}
                          />
                          <label for="" className=" fs-12">
                            I don't have the website
                          </label>
                        </div>
                      </div>

                      <div className="col-md-6 mb-4">
                        <label for="" className="fs-15 medium d-block pb-2">
                          Industry / Domain *
                        </label>

                        {RenderIndustry()}
                        {IndustryError ? (
                          <span className="field-error fs-12 text-red">
                            {IndustryError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-6 mb-4">
                        <label for="" className="fs-15 medium d-block pb-2">
                          {param?.type === "School" ||
                          param?.type === "University"
                            ? "Educational Institute"
                            : "Organization"}{" "}
                          Size *
                        </label>
                        <select
                          name=""
                          className="fs-14 border rounded-2 p-3 "
                          id=""
                          value={OrganizationSize}
                          onChange={(e) => {
                            setOrganizationSize(e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          {OrganizationSizeData.map((item, index) => {
                            return (
                              <option
                                select={
                                  OrganizationSize === item.id ? true : false
                                }
                                value={item.id}
                                key={index}
                              >
                                {item?.value}
                              </option>
                            );
                          })}
                        </select>
                        {OrganizationSizeError ? (
                          <span className="field-error fs-12 text-red">
                            {OrganizationSizeError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-6 mb-4">
                        <label for="" className="fs-15 medium d-block pb-2">
                          {param?.type === "School" ||
                          param?.type === "University"
                            ? "Educational Institute"
                            : "Organization"}{" "}
                          Type *
                        </label>
                        <select
                          name=""
                          className="fs-14 border rounded-2 p-3 "
                          id=""
                          value={OrganizationType}
                          onChange={(e) => {
                            setOrganizationtype(e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          {OrganizationTypeData.map((item, index) => {
                            return (
                              <option
                                select={
                                  OrganizationType === item.id ? true : false
                                }
                                value={item.id}
                                key={index}
                              >
                                {item?.value}
                              </option>
                            );
                          })}
                        </select>
                        {OrganizationTypeError ? (
                          <span className="field-error fs-12 text-red">
                            {OrganizationTypeError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-6 mb-4">
                        <label for="" className="fs-15 medium d-block pb-2">
                          Logo *
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          name=""
                          id="logo_upload"
                          className="d-none"
                          onChange={(e) => {
                            handleImage(e);
                          }}
                        />
                        {/* {logo ? 
                                               <label for="logo_upload" className=" rounded-2 text-center py-4 px-2 w-100 com-logo">
                                               <img src={URL.createObjectURL(logo)} width="100" alt="" />
                                               
                                           </label>
                                            : */}

                        <label
                          for="logo_upload"
                          className=" rounded-2 text-center py-4 px-2 w-100 com-logo"
                        >
                          {logo && (
                            <img
                              src={URL.createObjectURL(logo)}
                              alt="page logo"
                              className="w-100"
                              style={{ objectFit: "contain", maxHeight: 76 }}
                            />
                          )}
                          {!logo && (
                            <>
                              <img
                                src="../assets/img/icon/icon-file-upload.svg"
                                width="25"
                                alt=""
                              />
                              <span className="d-block fs-14 medium pt-2 pb-1 text-black">
                                Upload Logo
                              </span>
                              <span className="d-block fs-14 ">
                                Drag and drop a file or{" "}
                                <span className="text-black text-decoration-underline">
                                  browse a file
                                </span>
                              </span>
                            </>
                          )}
                        </label>
                        {/* }  <img src={URL.createObjectURL(logo)} width="100" alt="" />*/}
                        <div className="fs-12 text-darkgray">
                          200 x 200px recommended Size.
                        </div>
                        {logo ? (
                          <Link to={URL.createObjectURL(logo)} target="_blank">
                            <div className="fs-12 text-darkgray">
                              <h6>{logoName}</h6>
                            </div>
                          </Link>
                        ) : (
                          ""
                        )}
                        {logoError ? (
                          <span className="field-error fs-12 text-red">
                            {logoError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-6 mb-4">
                        <label for="" className="fs-15 medium d-block pb-2">
                          Tagline *
                        </label>
                        <textarea
                          name=""
                          value={Tagline}
                          onChange={(e) => {
                            setTagline(e.target.value);
                          }}
                          id=""
                          cols="30"
                          rows="5"
                          className="border rounded-2 fs-14 p-3 textarea1 "
                          placeholder="Add Page Tagline"
                          maxLength={"200"}
                        ></textarea>

                        <div
                          id="count1"
                          className="fs-12 text-darkgray text-end"
                        >
                          <span id="current1">{Tagline?.length}</span>
                          <span id="maximum1">/ 200</span>
                        </div>
                        {TaglineError ? (
                          <span className="field-error fs-12 text-red">
                            {TaglineError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-12 mb-4">
                        <label for="" className="fs-15 medium d-block pb-2">
                          Description *
                        </label>

                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="5"
                          placeholder="Add about your organization in detail."
                          className="border rounded-2 fs-14 p-3  textarea2 "
                          value={Description}
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                          maxLength={"200"}
                        ></textarea>

                        <div
                          id="count2"
                          className="fs-12 text-darkgray text-end"
                        >
                          <span id="current2">{Description?.length}</span>
                          <span id="maximum2">/ 200</span>
                        </div>
                        {DescriptionError ? (
                          <span className="field-error fs-12 text-red">
                            {DescriptionError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-6 mb-4">
                        <label for="" className="fs-15 medium d-block pb-2">
                          Phone/Mobile *
                        </label>
                        <input
                          type="text"
                          placeholder="Add Phone/Mobile No."
                          className="border rounded-2 fs-14 p-3 "
                          value={mobile}
                          onChange={(e) => {
                            setMobile(e.target.value);
                          }}
                        />
                        {mobileError ? (
                          <span className="field-error fs-12 text-red">
                            {mobileError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-6 mb-4">
                        <label for="" className="fs-15 medium d-block pb-2">
                          Year Founded *
                        </label>
                        <input
                          type="text"
                          value={Founded}
                          onChange={(e) => {
                            setFounded(e.target.value);
                          }}
                          placeholder="Add Year"
                          className="border rounded-2 fs-14 p-3 "
                        />
                        {FoundedError ? (
                          <span className="field-error fs-12 text-red">
                            {FoundedError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-12 mb-4">
                        <label for="" className="fs-15 medium d-block pb-2">
                          Email *
                        </label>
                        <input
                          type="text"
                          placeholder="Add Email"
                          className="border rounded-2 fs-14 p-3 "
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                      {/* <div className="col-md-6 mb-4">
                                            <label for="" className="fs-15 medium d-block pb-2">Page Type *</label>
                                            <select name="" onChange={(e)=>{
                                                setPageType(e.target.value)
                                            }} className="fs-14 border rounded-2 p-3 " id="">
                                                <option value="">Select</option>
                                                <option value="School">School</option>
                                                <option value="University">University</option>
                                                <option value="Company">Company</option>
                                                <option value="NGO">NGO</option>
                                                <option value="Page">Page</option>
                                               
                                            </select>
                                        </div> */}

                      {/* <div className="col-md-12 mb-4">
                        <Link
                          onClick={() => {
                            handleAddLocation();
                          }}
                          className="btn btn-br-blue fs-14"
                        >
                          + Add a Location
                        </Link>
                      </div> */}

                      {location.map((item, index) => {
                        return (
                          <div className="col-md-12" key={index}>
                            <div className="row">
                              <div className="col-md-12">
                                <p className="fs-15 medium border-bottom pb-2">
                                  Location Details
                                </p>
                              </div>
                              <div className="col-md-6 mb-4">
                                <label
                                  for=""
                                  className="fs-15 medium d-block pb-2"
                                >
                                  Country *
                                </label>
                                <select
                                  name=""
                                  className="fs-14 border rounded-2 p-3 "
                                  id=""
                                  onChange={(e) => {
                                    handleLocationData(
                                      e.target.value,
                                      index,
                                      "country_id"
                                    );
                                  }}
                                >
                                  <option value="">Select</option>
                                  {CountryData.map((item, index) => {
                                    return (
                                      <option value={item.id} key={index}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>

                              <div className="col-md-6 mb-4">
                                <label
                                  for=""
                                  className="fs-15 medium d-block pb-2"
                                >
                                  Address *
                                </label>
                                <input
                                  type="text"
                                  placeholder="Add complete address"
                                  className="border rounded-2 fs-14 p-3 "
                                  onChange={(e) => {
                                    handleLocationData(
                                      e.target.value,
                                      index,
                                      "street_address"
                                    );
                                  }}
                                />
                              </div>

                              <div className="col-md-6 mb-4">
                                <label
                                  for=""
                                  className="fs-15 medium d-block pb-2"
                                >
                                  City *
                                </label>
                                {/* <input type="text"
                                                                placeholder="Add City"
                                                                className="border rounded-2 fs-14 p-3 "
                                                                onChange={(e) => {
                                                                    handleLocationData(e.target.value, index, "city")
                                                                }}
                                                            /> */}
                                <AsyncTypeahead
                                  id={`city-typeahead-${index}`}
                                  defaultInputValue=""
                                  onSearch={handleOnSearchCity}
                                  onChange={handleTypeaheadLocationData(
                                    index,
                                    "city"
                                  )}
                                  options={cityOptions}
                                  placeholder="Add City"
                                  labelKey="name"
                                  minLength={1}
                                  emptyLabel={"No result found"}
                                  className={
                                    IndustryError
                                      ? " border rounded-2 fs-14 p-2 "
                                      : " border rounded-2 fs-14 p-2 "
                                  }
                                  renderMenuItemChildren={(option, props) => (
                                    <div key={option.id}>
                                      <Highlighter search={props.text}>
                                        {option[props.labelKey]}
                                      </Highlighter>
                                    </div>
                                  )}
                                  useCache={false}
                                />
                              </div>

                              <div className="col-md-6 mb-4">
                                <label
                                  for=""
                                  className="fs-15 medium d-block pb-2"
                                >
                                  State/Province *
                                </label>
                                {/* <input type="text"
                                                                placeholder="Add State"
                                                                className="border rounded-2 fs-14 p-3 "
                                                                onChange={(e) => {
                                                                    handleLocationData(e.target.value, index, "state")
                                                                }}
                                                            /> */}

                                <AsyncTypeahead
                                  id={`state-typeahead-${index}`}
                                  defaultInputValue=""
                                  onSearch={handleOnSearchState}
                                  onChange={handleTypeaheadLocationData(
                                    index,
                                    "state"
                                  )}
                                  options={stateOptions}
                                  placeholder="Add State"
                                  labelKey="name"
                                  minLength={1}
                                  emptyLabel={"No result found"}
                                  className={
                                    IndustryError
                                      ? " border rounded-2 fs-14 p-2 "
                                      : " border rounded-2 fs-14 p-2 "
                                  }
                                  renderMenuItemChildren={(option, props) => (
                                    <div key={option.id}>
                                      <Highlighter search={props.text}>
                                        {option[props.labelKey]}
                                      </Highlighter>
                                    </div>
                                  )}
                                  useCache={false}
                                />
                              </div>

                              <div className="col-md-6 mb-4">
                                <label
                                  for=""
                                  className="fs-15 medium d-block pb-2"
                                >
                                  Zip/Postal Code *
                                </label>
                                <input
                                  type="text"
                                  placeholder="Add Zip/Postal Code"
                                  className="border rounded-2 fs-14 p-3 "
                                  onChange={(e) => {
                                    handleLocationData(
                                      e.target.value,
                                      index,
                                      "postal_code"
                                    );
                                  }}
                                />
                              </div>

                              <div className="col-md-6 mb-4">
                                <label
                                  for=""
                                  className="fs-15 medium d-block pb-2"
                                >
                                  Location Name *
                                </label>
                                <input
                                  type="text"
                                  placeholder="Add Location Name"
                                  className="border rounded-2 fs-14 p-3 "
                                  onChange={(e) => {
                                    handleLocationData(
                                      e.target.value,
                                      index,
                                      "location_name"
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <div className="col-md-12">
                        {status === true ? (
                          <button className="btn fs-14 w-auto px-4">
                            <span className="spinner-grow spinner-grow-sm "></span>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn fs-14 w-auto px-4"
                          >
                            Update Page
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>

          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
