import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import IsOnline from "../IsOnline";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import { StickyChatContext } from "../StickyChatWrapper";
const $ = window.jQuery;

function OnlineFriends() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [chatData, setChatData] = useState([]);

  const { handleAddChat, onlineUsers, chats } = useContext(StickyChatContext);

  useEffect(() => {
    const newCatData = chats.map((item) => {
      const isOnline = checkOnlineStatus(item);
      return {
        item: item,
        isOnline: isOnline,
      };
    });

    const sortedData = newCatData.sort((a, b) => {
      // First, compare the "isOnline" field in descending order
      if (a.isOnline === b.isOnline) {
        // If "isOnline" is the same, compare the "createdAt" field in ascending order
        return a?.item?.createdAt?.localeCompare(b.item.createdAt);
      }
      // Sort by "isOnline" in descending order
      return b.isOnline - a.isOnline;
    });

    const finalData = sortedData.map((item) => {
      return item.item;
    });

    setChatData(finalData);
  }, [chats]);

  // console.log("chat", chats);
  const checkOnlineStatus = useCallback(
    (chat) => {
      if (chat) {
        const member = chat?.chat_room_members?.find(
          (each) => each?.user?.id !== user.id
        );
        const isOnline =
          !!member &&
          onlineUsers?.find((user) => user?.userId === member?.user?.id);
        return !!isOnline;
      }
    },
    [onlineUsers, user]
  );

  return (
    <div className="right-navigation">
      <ul className="online-friends">
        {chatData.map((chat, index) => {
          const isOnline =
            chat.roomType !== "course" && checkOnlineStatus(chat);

          const otherChatUserInRoom = chat?.chat_room_members?.find(
            (searchUser) => searchUser?.user?.id !== user?.id
          );

          let groupImage = null;

          if (chat.roomName !== null) {
            const person1 = chat?.chat_room_members
              ? chat?.chat_room_members[0]
              : null;
            const person2 = chat?.chat_room_members
              ? chat?.chat_room_members[1]
              : null;

            groupImage = (
              <div key={index} className="group-chat-image-container">
                <img
                  src={`${
                    Endpoints.baseUrl + person1?.user?.user_profile?.profileImg
                  }`}
                  alt=""
                  width="36"
                  height="36"
                  className="group-chat-image"
                />
                <img
                  src={`${
                    Endpoints.baseUrl + person2?.user?.user_profile?.profileImg
                  }`}
                  alt=""
                  width="36"
                  height="36"
                  className="group-chat-image"
                />
              </div>
            );
          }
          if (chat.roomName != null) return null;

          if (chat?.chat_room_members?.length > 2) return null;

          return (
            <React.Fragment key={index}>
              <li key={chat.id} className="mb-4">
                <button
                  style={{
                    border: "none",
                    background: "transparent",
                    padding: 0,
                    width: "100%",
                  }}
                  onClick={() => {
                    handleAddChat(chat);
                  }}
                >
                  <div className="position-relative">
                    {chat.roomName !== null ? (
                      groupImage
                    ) : (
                      <img
                        src={
                          otherChatUserInRoom
                            ? `${
                                Endpoints.baseUrl +
                                otherChatUserInRoom?.user?.user_profile
                                  ?.profileImg
                              }`
                            : chat.thumbnail
                            ? `${Endpoints.baseUrl + chat.thumbnail}`
                            : `${process.env.PUBLIC_URL}/assets/img/picture.png`
                        }
                        alt=""
                        width="48"
                        height="48"
                        className="rounded-circle mb-2"
                      />
                    )}
                    {chat.roomName === null && isOnline && <IsOnline />}
                  </div>
                  <p className="fs-12 medium w-100 text-truncate mb-0">
                    {chat.roomName === null && otherChatUserInRoom
                      ? otherChatUserInRoom?.user?.fullName
                      : chat.roomName}
                  </p>
                </button>
              </li>
            </React.Fragment>
          );
        })}
      </ul>

      <div className="add-friend-chat text-center">
        <Link
          to="/friend/messages"
          title="Message with friends"
          className="d-block rounded-circle size48 line48 bg-lightblue border-0"
        >
          <svg
            viewBox="0 0 24 24"
            width="24"
            height="24"
            stroke="#0C64C5"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="css-i6dzq1"
          >
            <line x1="12" y1="5" x2="12" y2="19" />
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </Link>
      </div>
    </div>
  );
}

export default OnlineFriends;
