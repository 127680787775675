import React, { useEffect, useState } from "react";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { CommonAPi } from "../../API/CommonApi";
import { FetchApi } from "../../API/FetchApi";
import { Link, useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
const $ = window.jQuery;

export default function SuggestedCourses() {
  const Navigate = useNavigate();
  const [courseData, setCourseData] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      $(".course-slider-dual").owlCarousel({
        loop: true,
        margin: 15,
        items: 2,
        nav: true,
        dots: true,
        autoplay: false,
        autoplayTimeout: 3000,
        navText: [
          "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' stroke-linecap='round' strokeLinejoin='round' class='feather feather-chevron-left'><polyline points='15 18 9 12 15 6'></polyline></svg>",
          "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' stroke-linecap='round' strokeLinejoin='round' class='feather feather-chevron-right'><polyline points='9 18 15 12 9 6'></polyline></svg>",
        ],
        responsive: {
          0: {
            items: 1,
            margin: 0,
          },
          768: {
            items: 2,
          },
        },
      });
    }, 200);
  }, []);

  useEffect(() => {
    getrecommendationsCourse();
  }, []);

  const getrecommendationsCourse = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.recommendationsUser);
      if (resp && resp.success === true) {
        setCourseData(resp?.data?.courseRecommendations);
        setTimeout(() => {
          $(".course-slider-dual").owlCarousel({
            loop: true,
            margin: 15,
            items: 2,
            nav: true,
            dots: true,
            autoplay: false,
            autoplayTimeout: 3000,
            navText: [
              "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' stroke-linecap='round' strokeLinejoin='round' class='feather feather-chevron-left'><polyline points='15 18 9 12 15 6'></polyline></svg>",
              "<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' stroke-linecap='round' strokeLinejoin='round' class='feather feather-chevron-right'><polyline points='9 18 15 12 9 6'></polyline></svg>",
            ],
            responsive: {
              0: {
                items: 1,
                margin: 0,
              },
              768: {
                items: 2,
              },
            },
          });
        }, 200);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  return (
    <>
      {courseData?.length > 0 ? (
        <div class="sidebar-widget mt-4 mb-4">
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="medium">Suggested Courses</h4>
            {/* 
                <div class="position-relative">
                    <a href="#" aria-haspopup="true" aria-label="suggested_course" data-bs-toggle="dropdown" class="d-flex align-items-center justify-content-center ">
                        <svg viewBox="0 0 24 24" width="22" height="22" stroke="#b86d2c" strokeWidth="2" fill="none" stroke-linecap="round" strokeLinejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                    </a>

                    <ul class="popup-menu text-start shadow rounded-1" role="menu" id="suggested_course" aria-hidden="true" tabindex="-1">
                        <li><a class="dropdown-item py-2 d-flex gap-2" href="#">
                            <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" stroke-linecap="round" strokeLinejoin="round" class="css-i6dzq1"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                            Delete
                        </a></li>
                        <li><a class="dropdown-item py-2 d-flex gap-2" href="#">
                            <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" strokeWidth="2" fill="none" stroke-linecap="round" strokeLinejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line></svg>
                            Restricted
                        </a></li>

                    </ul>
                </div> */}
          </div>

          <div class="pt-3 mt-2 border-top">
            <div class="course-slider-dual slider-item-border owl-theme owl-carousel">
              {courseData.map((item, index) => {
                return (
                  <div className="course-item">
                    <div className="course-img">
                      {item.thumbnail === null ? (
                        <img src="../assets/img/course.png" alt="" />
                      ) : (
                        <img src={Endpoints.baseUrl + item.thumbnail} alt="" />
                      )}
                    </div>
                    <div className="p-2">
                      <div className="d-flex align-items-center justify-content-between pt-2">
                        <span className="bg-lightbrown text-brown fs-12 medium py-1 px-3 rounded-pill">
                          {item?.course_category?.name}
                        </span>
                        <div className="d-flex">
                          <span className="course-rating d-flex  w-100">
                            <ReactStars
                              count={5}
                              edit={false}
                              value={item.avgRating}
                              size={18}
                              isHalf={true}
                              char={
                                <img
                                  src="../assets/img/icon/state-null.svg"
                                  width="16px"
                                  alt=""
                                />
                              }
                              emptyIcon={
                                <img
                                  src="../assets/img/icon/state-null.svg"
                                  width="16px"
                                  alt=""
                                />
                              }
                              halfIcon={
                                <img
                                  src="../assets/img/icon/state-half.svg"
                                  width="16px"
                                  alt=""
                                />
                              }
                              filledIcon={
                                <img
                                  src="../assets/img/icon/State-fill.svg"
                                  width="16px"
                                  alt=""
                                />
                              }
                              activeColor="#ffd700"
                              activeBackgroundColor="#ffd700"
                            />
                          </span>
                          <span className="fs-12 medium text-gray">
                            ({item.totalRatings})
                          </span>
                        </div>
                      </div>

                      <h5 className="medium py-3">
                        {item?.title?.length <= 22
                          ? item?.title
                          : item?.title.substr(0, 22) + "..."}
                      </h5>

                      <div className="text-center  pb-2">
                        <div className="border-top course-price py-2 medium fs-18">
                          <ins className="text-blue">
                            ₹{item.discountedPrice}
                          </ins>
                          <del className="text-lightgray px-1">
                            ₹{item?.price}
                          </del>
                        </div>

                        <Link
                          to={"/course-detail/" + item.slug}
                          target={"_blank"}
                          className="btn py-2 fs-14"
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
