import React, { useState } from 'react'
import { Endpoints } from '../../../API/Endpoints';
import ToastMessage from '../../../Utils/ToastMessage';

const ReceiptBtn = ({item}) => {
    const [text, setText] = useState("Receipt");
     const downloadPdf = async (id, endpoint, time) => {
        try {
            const response = await fetch(endpoint+id+`?time=${time}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                     profile:localStorage.getItem("userId"),
                    'Authorization':"Bearer " + localStorage.getItem('token')
                },

            });
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `receipt.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setText("Receipt"); 
        } catch (e) {
            console.error(e);
            setText("Receipt");     
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    // navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }
    };
    const handleReceipt = async (id, time) => {
        setText("downloading..");
        if(text !== "Receipt") return;   
        try {
            await downloadPdf(id, Endpoints.baseUrl+Endpoints.DownloadTeacherReceipt, time)
        }
        catch (e) {
            setText("Receipt");
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    // navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }
    }
  return (
    <a href='javascript:void(0)' onClick={()=>handleReceipt(item?.id, item.createdAt)} className='badge text-success cursor-pointer text-decoration-underline'>{text}</a>
  )
}

export default ReceiptBtn