import React, { useEffect, useState, useCallback } from 'react'
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Profile from './Profile';
import { Endpoints } from "../../../API/Endpoints";
import { FetchApi } from '../../../API/FetchApi';
import ToastMessage from "../../../Utils/ToastMessage";
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, LineChart, Line, AreaChart, Area, ResponsiveContainer } from "recharts";
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import ReactStars from "react-rating-stars-component";
import PostLoader from '../../../Components/PostLoader/PostLoader';
import { Helmet } from "react-helmet";
export default function TeacherDashboard() {
  const navigate = useNavigate();
  const [dashboard, setDashboard] = useState();
  const [courseViewsCount, setCourseViewsCount] = useState([]);
  const [courseViewsType, setCourseViewsType] = useState('week');
  const [courseViewsCoursesCount, setCourseViewsCoursesCount] = useState([]);
  const [courseViewsInterval, setCourseViewsInterval] = useState(1);
  const [profileViewsCount, setProfileViewsCount] = useState([]);
  const [profileViewsType, setProfileViewsType] = useState('week');
  const [profileViewsInterval, setProfileViewsInterval] = useState(1);
  const [ratingInterval, setRatingInterval] = useState(1);
  const [revenueData, setRevenueData] = useState([]);
  const [revenueType, setRevenueType] = useState('week');
  const [revenueInterval, setRevenueInterval] = useState(1);

  const [ratingsType, setRatingsType] = useState('week');
  const [totalRatingData, setTotalRatingData] = useState();
  const [totalRatingCount, setTotalRatingCount] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDashboard();
  }, []);
  const getDashboard = async () => {
    setLoading(true);
    try {
      let resp = await FetchApi(Endpoints.TeacherDashboard + "?revenue_by=" + revenueType + "&profile_views_by=" + profileViewsType + "&course_views_by=" + courseViewsType + "&ratings_by=" + ratingsType);
      if (resp && resp.success === true) {
        setLoading(false);
        setDashboard({
          totalCourses: resp.data.totalCourses,
          totalReviews: resp.data.totalReviews,
          totalStudents: resp.data.totalStudents,
          totalEarnings: resp.data.totalEarnings,
        });

        let courseViewsCountData = [];
        if (resp.data.courseViewsCountWeb.length > 0) {

          courseViewsCountData = Object.keys(resp.data.courseViewsCountWeb[0].views).map((item) => {
            let obj = {};
            let object = {};
            resp.data.courseViewsCount.forEach((ite, index) => obj["course" + (index + 1)] = ite[item])
            resp.data.courseViewsCount.forEach((ite, index) => object["course_name"+(index +1) ] = resp.data.courseViewsCountWeb.at(index).course.length > 50  ? resp.data.courseViewsCountWeb.at(index).course.substring(0, 50) + "..." : resp.data.courseViewsCountWeb.at(index).course )
            return ({
              name: resp.data.courseViewsType === 'year' ? item : moment(item).format("DDMMM"),
              ...obj,
              ...object
            })
          })
        }
        setCourseViewsCount(courseViewsCountData);
        setCourseViewsCoursesCount(resp.data.courseViewsCount);
        if (resp.data.courseViewsType === 'year') {
          setCourseViewsInterval(0);
        } else if (resp.data.courseViewsType === 'month') {
          setCourseViewsInterval(8);
        } else if (resp.data.courseViewsType === 'week') {
          setCourseViewsInterval(0);
        }

        setCourseViewsType(resp.data.courseViewsType);

        let profileViewsCountData = Object.keys(resp.data.profileViewsCount).map((item) => {
          return ({
            view: Math.round(resp.data.profileViewsCount[item]),
            name: resp.data.profileViewsType === 'year' ? item : moment(item).format("DDMMM"),
          })
        })

        setProfileViewsCount(profileViewsCountData);
        if (resp.data.profileViewsType === 'year') {
          setProfileViewsInterval(0);
        } else if (resp.data.profileViewsType === 'month') {
          setProfileViewsInterval(4);
        } else if (resp.data.profileViewsType === 'week') {
          setProfileViewsInterval(0);
        }
      }
      setProfileViewsType(resp.data.profileViewsType);
      let revenudata = Object.keys(resp.data.revenueData).map((item) => {
        return ({
          '₹': Math.round(resp.data.revenueData[item]),
          name: resp.data.revenueType === 'year' ? item : moment(item).format("DD MMM"),
        })
      })

      setRevenueData(revenudata);

      if (resp.data.revenueType === 'year') {
        setRevenueInterval(0);
      } else if (resp.data.revenueType === 'month') {
        setRevenueInterval(4);
      } else if (resp.data.revenueType === 'week') {
        setRevenueInterval(0);
      }

      setRevenueType(resp.data.revenueType);
      if (resp.data.ratingsCount.length > 0) {
        if (resp.data.ratingsType === 'year') {
          setRatingInterval(1);
        } else if (resp.data.ratingsType === 'month') {
          setRatingInterval(6);
        } else if (resp.data.ratingsType === 'week') {
          setRatingInterval(1);
        }


        let ratingData = [];
        if (resp.data.ratingsType === 'month') {
          ratingData = Object.keys(resp.data.ratingsCount[0].ratingsByDay).map((item) => {
            return ({
              rating: Math.round(resp.data.ratingsCount[0].ratingsByDay[item]),
              name: resp.data.ratingsType === 'year' ? item : moment(item).format("D"),
            })
          })
        } else if (resp.data.ratingsType === 'week') {
          ratingData = Object.keys(resp.data.ratingsCount[0].ratingsByWeek).map((item) => {
            return ({
              rating: Math.round(resp.data.ratingsCount[0].ratingsByWeek[item]),
              name: resp.data.ratingsType === 'year' ? item : moment(item).format("DD MMM"),
            })
          })
        } else if (resp.data.ratingsType === 'year') {
          ratingData = Object.keys(resp.data.ratingsCount[0].ratingsByYear).map((item) => {
            return ({
              rating: Math.round(resp.data.ratingsCount[0].ratingsByYear[item]),
              name: resp.data.ratingsType === 'year' ? item : moment(item).format("D"),
            })
          })
        }
        console.log("ratingData", ratingData);
        setTotalRatingCount(ratingData);
        setTotalRatingData(resp.data.ratingsCount[0].ratingData);

        setRatingsType(resp.data.ratingsType);

      }
    }


    catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoading(false);
        if (e.response.status === 401) {

          ToastMessage.Error('Session Expired! Please login again.')
          localStorage.clear();
          navigate('/signin');
        } else {
          ToastMessage.Error(e.response.data.message)
        }

      }
    }

  }







  const handleProfileViewChart = async (value) => {
    setProfileViewsType(value);
    let url = Endpoints.TeacherDashboard + "?profile_views_by=" + value;
    try {
      let resp = await FetchApi(url);
      if (resp && resp.success === true) {

        let profileViewsCountData = Object.keys(resp.data.profileViewsCount).map((item) => {
          return ({
            view: Math.round(resp.data.profileViewsCount[item]),
            name: resp.data.profileViewsType === 'year' ? item : moment(item).format("DD MMM"),
          })
        })

        setProfileViewsCount(profileViewsCountData);
        if (resp.data.profileViewsType === 'year') {
          setProfileViewsInterval(0);
        } else if (resp.data.profileViewsType === 'month') {
          setProfileViewsInterval(4);
        } else if (resp.data.profileViewsType === 'week') {
          setProfileViewsInterval(0);
        }
        setProfileViewsType(resp.data.profileViewsType);

        setLoading(false);
      }
    }

    catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoading(false);
        if (e.response.status === 401) {

          ToastMessage.Error('Session Expired! Please login again.')
          localStorage.clear();
          navigate('/signin');
        } else {
          ToastMessage.Error(e.response.data.message)
        }

      }
    }
  };
  const handleCourseViewChart = async (value) => {
    // setLoading(true);
    setCourseViewsType(value);
    let url = Endpoints.TeacherDashboard + "?course_views_by=" + value;
    try {
      let resp = await FetchApi(url);
      if (resp && resp.success === true) {
        let courseViewsCountData = [];
        if (resp.data.courseViewsCountWeb.length > 0) {

          courseViewsCountData = Object.keys(resp.data.courseViewsCountWeb[0].views).map((item) => {
            let obj = {};
            let object = {};
            resp.data.courseViewsCount.forEach((ite, index) => obj["course" + (index + 1)] = ite[item])
            resp.data.courseViewsCount.forEach((ite, index) => object["course_name"+(index +1) ] = resp.data.courseViewsCountWeb.at(index).course.length > 50  ? resp.data.courseViewsCountWeb.at(index).course.substring(0, 50) + "..." : resp.data.courseViewsCountWeb.at(index).course )
            return ({
              name: resp.data.courseViewsType === 'year' ? item : moment(item).format("DDMMM"),
              ...obj,
              ...object
            })
          })
        }

        setCourseViewsCount(courseViewsCountData);
        setCourseViewsCoursesCount(resp.data.courseViewsCount);
        setCourseViewsCoursesCount(resp.data.courseViewsCount);
        if (resp.data.courseViewsType === 'year') {
          setCourseViewsInterval(0);
        } else if (resp.data.courseViewsType === 'month') {
          setCourseViewsInterval(4);
        } else if (resp.data.courseViewsType === 'week') {
          setCourseViewsInterval(0);
        }

        setCourseViewsType(resp.data.courseViewsType);
        setLoading(false);
      }
    }

    catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoading(false);
        if (e.response.status === 401) {

          ToastMessage.Error('Session Expired! Please login again.')
          localStorage.clear();
          navigate('/signin');
        } else {
          ToastMessage.Error(e.response.data.message)
        }

      }
    }
  };
  const handleRevenueChart = async (value) => {
    // setLoading(true);
    setRevenueType(value);
    let url = Endpoints.TeacherDashboard + "?revenue_by=" + value;
    try {
      let resp = await FetchApi(url);
      if (resp && resp.success === true) {
        let revenudata = Object.keys(resp.data.revenueData).map((item) => {
          return ({
            '₹': Math.round(resp.data.revenueData[item]),
            name: resp.data.revenueType === 'year' ? item : moment(item).format("DD MMM"),
          })
        })

        setRevenueData(revenudata);

        if (resp.data.revenueType === 'year') {
          setRevenueInterval(0);
        } else if (resp.data.revenueType === 'month') {
          setRevenueInterval(4);
        } else if (resp.data.revenueType === 'week') {
          setRevenueInterval(0);
        }

        setRevenueType(resp.data.revenueType);
        setLoading(false);
      }
    }

    catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoading(false);
        if (e.response.status === 401) {

          ToastMessage.Error('Session Expired! Please login again.')
          localStorage.clear();
          navigate('/signin');
        } else {
          ToastMessage.Error(e.response.data.message)
        }

      }
    }
  };

  const handleRatingsChart = async (value) => {
    // setLoading(true);
    setRatingsType(value);

    let url = Endpoints.TeacherDashboard + "?ratings_by=" + value;
    try {
      let resp = await FetchApi(url);
      if (resp && resp.success === true) {
        if (resp.data.ratingsType === 'year') {
          setRatingInterval(1);
        } else if (resp.data.ratingsType === 'month') {
          setRatingInterval(6);
        } else if (resp.data.ratingsType === 'week') {
          setRatingInterval(1);
        }

        let ratingData = [];
        if (resp.data.ratingsType === 'month') {
          ratingData = Object.keys(resp.data.ratingsCount[0].ratingsByDay).map((item) => {
            return ({
              rating: Math.round(resp.data.ratingsCount[0].ratingsByDay[item]),
              name: resp.data.ratingsType === 'year' ? item : moment(item).format("D MMM"),
            })
          })
        } else if (resp.data.ratingsType === 'week') {
          ratingData = Object.keys(resp.data.ratingsCount[0].ratingsByWeek).map((item) => {
            return ({
              rating: Math.round(resp.data.ratingsCount[0].ratingsByWeek[item]),
              name: resp.data.ratingsType === 'year' ? item : moment(item).format("DD MMM"),
            })
          })
        } else if (resp.data.ratingsType === 'year') {
          ratingData = Object.keys(resp.data.ratingsCount[0].ratingsByYear).map((item) => {
            return ({
              rating: Math.round(resp.data.ratingsCount[0].ratingsByYear[item]),
              name: resp.data.ratingsType === 'year' ? item : moment(item).format("DD MMM"),
            })
          })
        }
        setTotalRatingCount(ratingData);
        setTotalRatingData(resp.data.ratingsCount[0].ratingData);

        setRatingsType(resp.data.ratingsType);
        setLoading(false);
      }
    }

    catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoading(false);
        if (e.response.status === 401) {

          ToastMessage.Error('Session Expired! Please login again.')
          localStorage.clear();
          navigate('/signin');
        } else {
          ToastMessage.Error(e.response.data.message)
        }

      }
    }

  };


  const RenderStar = useCallback(() => {
    return (
      <ReactStars
        count={5}
        edit={false}
        value={totalRatingData?.rating}
        size={24}
        isHalf={true}
        char={<img src="../assets/img/icon/state-null.svg" width="18" alt="" />}
        emptyIcon={<img src="../assets/img/icon/state-null.svg" width="18" alt="" />}
        halfIcon={<img src="../assets/img/icon/state-half.svg" width="18" salt="" />}
        filledIcon={<img src="../assets/img/icon/State-fill.svg" width="18" alt="" />}
        activeColor="#ffc107"
      />
    )
  }, [totalRatingData, totalRatingCount])


  function CustomTooltip({ payload, label, active }) {
    console.log("payload___", payload);
    console.log("label___", label);
    if (active) {
     
      return (
        <div className="card p-2 border">
          <p>{label}</p>
          {payload.map((item, index)=>{
            let newArrayData = [];
            var result = Object.keys(item?.payload).map((key) => newArrayData[key] = item?.payload[key]);
            console.log("newArrayData______", newArrayData);
            if(item){
              return (
                <p className="label" style={{
                  color:item?.color
                }}>{`${newArrayData["course_name" + (index+1)]} : ${item.value}`}</p>
              )
            }
          })}
        
        </div>
      );
    }
  
    return null;
  }


  return (
    <Container
      header
    >
      <Helmet>
        <title>Teacher Dashboard</title>
      </Helmet>
      {loading ?
        <PostLoader />
        :
        <div className="wrapper wrapper-bg">

          <div className="main">
            <LeftNavigation />

            <div className="container py-3">
              <Profile />
              <div className="row">
                <div className="col-md-3 col-sm-6 mb-3 ">
                  <Link className="job-item-hovor">
                    <div className="job-item  d-flex align-items-center bg-white">
                      <span className="hastag-icon bg-primary">
                        <img src="assets/img/icon/computer-white.svg" alt="" />
                      </span>
                      <div className="ps-3">
                        <h4 className="semibold pb-1">{dashboard?.totalCourses}</h4>
                        <h6 className="fs-14 regular">Active Courses</h6>
                      </div>
                    </div>
                  </Link>
                </div>


                <div className="col-md-3 col-sm-6 mb-3 ">
                  <Link className="job-item-hovor">
                    <div className="job-item  d-flex align-items-center bg-white">
                      <span className="hastag-icon bg-primary">
                        <img src="assets/img/icon/icon-students-white.svg" alt="" />
                      </span>
                      <div className="ps-3">
                        <h4 className="semibold pb-1">{dashboard?.totalStudents}</h4>
                        <h6 className="fs-14 regular">Total Students</h6>
                      </div>
                    </div>
                  </Link>
                </div>


                <div className="col-md-3 col-sm-6 mb-3">
                  <Link className="job-item-hovor">
                    <div className="job-item  d-flex align-items-center bg-white">
                      <span className="hastag-icon bg-primary">
                        <img src="assets/img/icon/icon-star.svg" alt="" />
                      </span>
                      <div className="ps-3">
                        <h4 className="semibold pb-1">{dashboard?.totalReviews}</h4>
                        <h6 className="fs-14 regular">Total Reviews</h6>
                      </div>
                    </div>
                  </Link>
                </div>



                <div className="col-md-3 col-sm-6 mb-3">
                  <Link className="job-item-hovor">
                    <div className="job-item  d-flex align-items-center bg-white">
                      <span className="hastag-icon bg-primary">
                        <img src="assets/img/icon/icon-indian-currency-white.svg" alt="" />
                      </span>
                      <div className="ps-3">
                        <h4 className="semibold pb-1">{(dashboard?.totalEarnings.toFixed(2))}</h4>
                        <h6 className="fs-14 regular">Total Earning</h6>
                      </div>
                    </div>
                  </Link>

                </div>

              </div>

              <div className="row">
                <div className="col-md-6 my-2">
                  <div className="bg-white rounded-10 py-3">
                    <div className="d-flex align-items-center border-bottom pb-3 pt-2 justify-content-between px-3">
                      <h3 className="fs-22 medium">Revenue</h3>
                      <select name="" className="text-darkgray fs-14 w-auto" id=""
                        onChange={(e) => {
                          handleRevenueChart(e.target.value)

                        }}
                      >
                        <option selected={revenueType === 'week' ? true : false} value="week">This Week </option>
                        <option selected={revenueType === 'month' ? true : false} value="month">This Month</option>
                        <option selected={revenueType === 'year' ? true : false} value="year">This Year</option>
                      </select>
                    </div>

                    <ResponsiveContainer width="100%" aspect={1.4}>
                      <AreaChart
                        width={530}
                        height={650}
                        data={revenueData}
                        margin={{
                          top: 15,
                          right: 30,
                          left: 15,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="5 5" vertical={false} />
                        <XAxis dataKey="name" tickLine={true} axisLine={true} interval={revenueInterval} />
                        <YAxis tickLine={false} axisLine={false} />
                        <Tooltip />
                        <Area type="monotone" dataKey="₹" stroke="#4E73DF" strokeWidth={4} fill="#F3F7FC" dot={{ stroke: '#4E73DF', strokeWidth: 1, r: 4, strokeDasharray: '' }} />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>


                <div className="col-md-6 my-2">
                  <div className="bg-white rounded-10 py-3">
                    <div className="d-flex align-items-center border-bottom pb-3 pt-2 justify-content-between px-3">
                      <h3 className="fs-22 medium">Profile Views</h3>
                      <select name="" className="text-darkgray fs-14 w-auto" id=""
                        onChange={(e) => {
                          handleProfileViewChart(e.target.value)

                        }}
                      >
                        <option selected={profileViewsType === 'week' ? true : false} value="week">This Week </option>
                        <option selected={profileViewsType === 'month' ? true : false} value="month">This Month</option>
                        <option selected={profileViewsType === 'year' ? true : false} value="year">This Year</option>
                      </select>
                    </div>
                    <ResponsiveContainer width="100%" height={385}>
                      <BarChart width={530} height={350} data={profileViewsCount} barSize={20} margin={{
                        top: 15,
                        right: 30,
                        left: 15,
                        bottom: 5,
                      }}
                        chartConfig={{
                          decimalPlaces: 0,
                        }}

                      >
                        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} axisLine={false} tickLine={false} interval={profileViewsInterval} />
                        <YAxis axisLine={false} tickLine={false} domain={[0, 'dataMax+4']} />
                        <Tooltip cursor={{ fill: 'transparent' }} contentStyle={{ backgroundColor: "black", color: "white", fontWeight: "800" }} />

                        <Bar dataKey="view" fill="#2BC48A" background={{ fill: 'rgba(43, 196, 138, 0.1)' }} />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>


                <div className="col-md-6 my-2">
                  <div className="bg-white rounded-10 py-3">
                    <div className="d-flex align-items-center border-bottom pb-3 pt-2 justify-content-between px-3">
                      <h3 className="fs-22 medium">Courses Views</h3>
                      <select name="" className="text-darkgray fs-14 w-auto" id=""
                        onChange={(e) => {
                          handleCourseViewChart(e.target.value)

                        }}
                      >
                        <option selected={courseViewsType === 'week' ? true : false} value="week">This Week </option>
                        <option selected={courseViewsType === 'month' ? true : false} value="month">This Month</option>
                        <option selected={courseViewsType === 'year' ? true : false} value="year">This Year</option>
                      </select>
                    </div>
                    <ResponsiveContainer width="100%" aspect={1.5}>
                      <AreaChart width={530} height={600} data={courseViewsCount}
                        margin={{ top: 20, right: 30, left: 0, bottom: 20 }} >
                        <defs>
                          {courseViewsCoursesCount.map((item, index) => {
                            return (
                              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor="#E8F0FA" stopOpacity={0.8} />
                                <stop offset="100%" stopColor="#E8F0FA" stopOpacity={0} />
                              </linearGradient>
                            )
                          })}

                          {/* <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#F3F7FC" stopOpacity={0.8} />
                          <stop offset="95%" stopColor="#F3F7FC" stopOpacity={0} />
                        </linearGradient> */}
                        </defs>
                        <XAxis dataKey="name" tickLine={false} axisLine={false} interval={courseViewsInterval} />
                        <YAxis tickLine={false} axisLine={false} domain={[0, 'dataMax+4']} />
                        <Tooltip  content={<CustomTooltip />}/>
                        {courseViewsCoursesCount.map((item, index) => {

                          return (
                            <Area
                              type="monotone"
                              dataKey={'course' + (index + 1)}
                              stroke={index % 2 === 0 ? "#0C64C5" : "#BC7638"}
                              strokeWidth={3}
                              fillOpacity={1}
                              fill="url(#colorPv)"
                              dot={{ stroke: index % 2 === 0 ? "#0C64C5" : "#BC7638", strokeWidth: 2, r: 4, strokeDasharray: '' }} />

                          )
                        })}
                        {/* <Area type="monotone" dataKey="pv" stroke="#BC7638" strokeWidth={3} fillOpacity={1} fill="url(#colorPv)" dot={{ stroke: '#BC7638', strokeWidth: 2, r: 4, strokeDasharray: '' }} /> */}
                      </AreaChart>
                    </ ResponsiveContainer >
                  </div>
                </div>



                <div className="col-md-6 my-2">
                  <div className="bg-white rounded-10 py-3">
                    <div className="d-flex align-items-center border-bottom pb-3 pt-2 justify-content-between px-3">
                      <h3 className="fs-22 medium">Overall Rating</h3>
                      <select name="" className="text-darkgray fs-14 w-auto" id=""
                        onChange={(e) => {
                          handleRatingsChart(e.target.value)

                        }}
                      >
                        <option selected={ratingsType === 'week' ? true : false} value="week">This Week </option>
                        <option selected={ratingsType === 'month' ? true : false} value="month">This Month</option>
                        <option selected={ratingsType === 'year' ? true : false} value="year">This Year</option>
                      </select>
                    </div>

                    <div className='pt-3 m-0 pb-2 px-2 row'>
                      <div className="col-md-4 px-2 pt-3 text-center mb-2 ">
                        <div className="course-rating-box py-2 px-2">
                          <h2 className="bold">{totalRatingData?.rating?.toFixed(1)}</h2>
                          <h6 className="regular fs-14 text-darkgray">Out of 5</h6>

                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">

                            {
                              totalRatingData?.rating === undefined ?
                                RenderStar()
                                :
                                RenderStar()
                            }

                          </div>

                          <h6 className="regular fs-14 text-darkgray">{totalRatingData?.totalRating}  Ratings</h6>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <ResponsiveContainer height={185} aspect={1.5}>
                          <AreaChart
                            // width={320}
                            height={130}
                            data={totalRatingCount}
                            margin={{
                              top: 0,
                              right: 5,
                              left: 0,
                              bottom: 5,
                            }}
                          >
                            <Area type="monotone" height={130} dataKey="rating" stroke="#FFA000" fill="#FFF5E5" strokeWidth={6} dot={{ stroke: '#FFA000', strokeWidth: 1, r: 6, strokeDasharray: '' }} />
                            <XAxis dataKey="name" height={130} scale="point" padding={{ left: 30, right: 20 }} axisLine={false} tickLine={false} interval={ratingInterval} />
                            {/* <YAxis axisLine={false} tickLine={false}  domain={[0,'dataMax+4']}  /> */}
                            <Tooltip />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                    </div>

                    <div className="px-2">

                      <div className="pb-1 row align-items-center mx-0">
                        <div className="col-md-5 d-flex align-items-center">
                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">
                            <img src="assets/img/icon/State-fill.svg" width="18" alt="" />
                            <img src="assets/img/icon/State-fill.svg" width="18" alt="" />
                            <img src="assets/img/icon/State-fill.svg" width="18" alt="" />
                            <img src="assets/img/icon/State-fill.svg" width="18" alt="" />
                            <img src="assets/img/icon/State-fill.svg" width="18" alt="" />
                          </div>
                          <span className="text-black text-nowrap ps-1">5 Star Rating</span>
                        </div>
                        <div className="col-md-7">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="progress rounded-pill">
                              <div className="progress-bar bg-warning" role="progressbar" style={{ width: Math.round(totalRatingData?.fivePercentRating) + '%' }} aria-valuenow='75' aria-valuemin='0' aria-valuemax="100"></div>
                            </div>
                            <span className="text-black text-nowrap">{totalRatingData?.fivePercentRating ? Math.round(totalRatingData?.fivePercentRating) : "0"}%</span>
                          </div>
                        </div>
                      </div>

                      <div className="pb-1 row align-items-center mx-0">
                        <div className="col-md-5 d-flex align-items-center">
                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">
                            <img src="assets/img/icon/State-fill.svg" width="18" alt="" />
                            <img src="assets/img/icon/State-fill.svg" width="18" alt="" />
                            <img src="assets/img/icon/State-fill.svg" width="18" alt="" />
                            <img src="assets/img/icon/State-fill.svg" width="18" alt="" />
                            <img src="assets/img/icon/state-null.svg" width="18" alt="" />
                          </div>
                          <span className="text-black text-nowrap ps-1">4 Star Rating</span>
                        </div>
                        <div className="col-md-7">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="progress rounded-pill">
                              <div className="progress-bar bg-warning" role="progressbar" style={{ width: Math.round(totalRatingData?.fourPercentRating) + '%' }} aria-valuenow='75' aria-valuemin='0' aria-valuemax="100"></div>
                            </div>
                            <span className="text-black text-nowrap">{totalRatingData?.fourPercentRating ? Math.round(totalRatingData?.fourPercentRating) : "0"}%</span>
                          </div>
                        </div>
                      </div>

                      <div className="pb-1 row align-items-center mx-0">
                        <div className="col-md-5 d-flex align-items-center">
                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">
                            <img src="assets/img/icon/State-fill.svg" width="18" alt="" />
                            <img src="assets/img/icon/State-fill.svg" width="18" alt="" />
                            <img src="assets/img/icon/State-fill.svg" width="18" alt="" />
                            <img src="assets/img/icon/state-null.svg" width="18" alt="" />
                            <img src="assets/img/icon/state-null.svg" width="18" alt="" />
                          </div>
                          <span className="text-black text-nowrap ps-1">3 Star Rating</span>
                        </div>
                        <div className="col-md-7">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="progress rounded-pill">
                              <div className="progress-bar bg-warning" role="progressbar" style={{ width: Math.round(totalRatingData?.threePercentRating) + '%' }} aria-valuenow='75' aria-valuemin='0' aria-valuemax="100"></div>
                            </div>
                            <span className="text-black text-nowrap">{totalRatingData?.threePercentRating ? Math.round(totalRatingData?.threePercentRating) : "0"}%</span>
                          </div>
                        </div>
                      </div>

                      <div className="pb-1 row align-items-center mx-0">
                        <div className="col-md-5 d-flex align-items-center">
                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">
                            <img src="assets/img/icon/State-fill.svg" width="18" alt="" />
                            <img src="assets/img/icon/State-fill.svg" width="18" alt="" />
                            <img src="assets/img/icon/state-null.svg" width="18" alt="" />
                            <img src="assets/img/icon/state-null.svg" width="18" alt="" />
                            <img src="assets/img/icon/state-null.svg" width="18" alt="" />
                          </div>
                          <span className="text-black text-nowrap ps-1">2 Star Rating</span>
                        </div>
                        <div className="col-md-7">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="progress rounded-pill">
                              <div className="progress-bar bg-warning" role="progressbar" style={{ width: Math.round(totalRatingData?.twoPercentRating) + '%' }} aria-valuenow='75' aria-valuemin='0' aria-valuemax="100"></div>
                            </div>
                            <span className="text-black text-nowrap">{totalRatingData?.twoPercentRating ? Math.round(totalRatingData?.twoPercentRating) : "0"}%</span>
                          </div>
                        </div>
                      </div>

                      <div className="pb-1 row align-items-center mx-0">
                        <div className="col-md-5 d-flex align-items-center">
                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">
                            <img src="assets/img/icon/State-fill.svg" width="18" alt="" />
                            <img src="assets/img/icon/state-null.svg" width="18" alt="" />
                            <img src="assets/img/icon/state-null.svg" width="18" alt="" />
                            <img src="assets/img/icon/state-null.svg" width="18" alt="" />
                            <img src="assets/img/icon/state-null.svg" width="18" alt="" />
                          </div>
                          <span className="text-black text-nowrap ps-1">1 Star Rating</span>
                        </div>
                        <div className="col-md-7">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="progress rounded-pill">
                              <div className="progress-bar bg-warning" role="progressbar" style={{ width: Math.round(totalRatingData?.onePercentRating) + '%' }} aria-valuenow='75' aria-valuemin='0' aria-valuemax="100"></div>
                            </div>
                            <span className="text-black text-nowrap">{totalRatingData?.onePercentRating ? Math.round(totalRatingData?.onePercentRating) : "0"}%</span>
                          </div>
                        </div>
                      </div>

                    </div>


                  </div>
                </div>

              </div>
            </div>
            <OnlineFriends />
          </div>
        </div>
      }
    </Container>
  )
}
