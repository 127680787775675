import React, { useEffect, useState } from "react";
import EditProfileModel from "./EditProfileModel";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import Moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { truncateString } from "../../Utils/helpers";

export default function ProfileIntro({ type, setProfileTagline }) {
  const userRoleType = localStorage.getItem("userRoleType");
  const [userProfile, setUserProfile] = useState("");
  const [limit, setLimit] = useState(150);
  const Navigate = useNavigate();
  const param = useParams();
  const userId = localStorage.getItem("userId");
  const username = localStorage.getItem("userName");

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getProfile();
    }
    return () => {
      mounted = false;
    };
  }, [param.id]);

  const LongText = ({ content, limit }) => {
    if (content) {
      if (content.length <= limit) {
        // there is nothing more to show
        return <p className="m-0 fs-14">{content}</p>;
      }

      // In the final case, we show a text with ellipsis and a `Read more` button
      const toShow = content.substring(0, limit) + "...";
      return (
        <p className="m-0 fs-14">
          {toShow}
          {/* <button >Read more</button> */}
          <Link
            onClick={() => {
              ReadMore(content.length);
            }}
          >
            Read More
          </Link>
        </p>
      );
    }
  };
  const ReadMore = (limit) => {
    setLimit(limit);
  };

  const getProfile = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetUserProfile + "?id=" + param?.id);
      if (resp && resp.success === true) {
        setUserProfile(resp?.data);
        setProfileTagline(resp?.data.user_profile.profileTagline);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const getAge = (date) => {
    let today = new Date();
    let dob = new Date(date);
    let diff = today.getTime() - dob.getTime();
    //convert milliseconds into years
    let years = Math.floor(diff / 31556736000);
    let response = null;
    if (years > 1) {
      response = `${years} years old`;
    } else {
      response = `${years} year old`;
    }
    return response;
  };

  return (
    <div className="sidebar-widget box-shadow  profile-text">
      <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-3">
        <h4 className="medium ">Profile Intro</h4>
        {username === param?.id ? (
          <Link
            className="text-brown fs-12"
            data-bs-toggle="modal"
            data-bs-target="#editProfilemodal"
          >
            Edit{" "}
            <img src="../assets/img/icon/edit-profile.svg" alt="" width="12" />
          </Link>
        ) : (
          ""
        )}
        <EditProfileModel
          {...{
            onSuccess: getProfile,
          }}
        />
      </div>

      {type === "about" ? (
        <div className="">
          <div className="d-flex align-items-start gap-2  pb-3">
            <img src="../assets/img/icon/icon-user.svg" alt="" />
            <div>
              <span className="fs-14 medium text-brown d-block">About me</span>
              {/* <p className="m-0 fs-14">{userProfile?.user_profile?.aboutMe} </p> */}
              <LongText
                content={userProfile?.user_profile?.aboutMe}
                limit={`${limit}`}
              />
            </div>
          </div>
          {userProfile?.user_profile?.birthday === "hidden" ||
          userProfile?.user_profile?.birthday === "" ? (
            ""
          ) : (
            <div className="d-flex align-items-start gap-2  pb-3">
              <img src="../assets/img/icon/icon-cake.svg" alt="" />
              <div>
                <span className="fs-14 medium text-brown d-block">
                  Birthday
                </span>
                <p className="m-0 fs-14">
                  {userProfile?.user_profile?.birthday &&
                    Moment(userProfile?.user_profile?.birthday).format(
                      "D MMMM YYYY"
                    )}
                </p>
              </div>
            </div>
          )}

          <div className="d-flex align-items-start gap-2  pb-3">
            <img src="../assets/img/icon/icon-nickname.svg" alt="" />
            <div>
              <span className="fs-14 medium text-brown d-block">Nick Name</span>
              <p className="m-0 fs-14">{userProfile?.user_profile?.nickname}</p>
            </div>
          </div>

          {userProfile?.user_profile?.birthday === "hidden" ||
          userProfile?.user_profile?.birthday === "" ? (
            ""
          ) : (
            <div className="d-flex align-items-start gap-2  pb-3">
              <img src="../assets/img/icon/icon-age.svg" alt="" />
              <div>
                <span className="fs-14 medium text-brown d-block">Age</span>
                <p className="m-0 fs-14">
                  {userProfile?.user_profile?.birthday &&
                    getAge(userProfile?.user_profile?.birthday)}
                </p>
              </div>
            </div>
          )}

          {userProfile?.user_profile?.birthPlace === "hidden" ? (
            ""
          ) : (
            <div className="d-flex align-items-start gap-2  pb-3">
              <img src="../assets/img/icon/icon-birthplace.svg" alt="" />
              <div>
                <span className="fs-14 medium text-brown d-block">
                  Birth Place
                </span>
                <p className="m-0 fs-14">
                  {userProfile?.user_profile?.birthPlace}
                </p>
              </div>
            </div>
          )}

          {userProfile?.user_profile?.livesIn === "hidden" ? (
            ""
          ) : (
            <div className="d-flex align-items-start gap-2  pb-3">
              <img src="../assets/img/icon/icon-livesin.svg" alt="" />
              <div>
                <span className="fs-14 medium text-brown d-block">
                  Lives In
                </span>
                <p className="m-0 fs-14">
                  {userProfile?.user_profile?.livesIn}
                </p>
              </div>
            </div>
          )}

          {userProfile?.user_profile?.country === "hidden" ||
          !userProfile?.user_profile?.country ? (
            ""
          ) : (
            <div className="d-flex align-items-start gap-2  pb-3">
              <img src="../assets/img/icon/icon-country.svg" alt="" />
              <div>
                <span className="fs-14 medium text-brown d-block">Country</span>
                <p className="m-0 fs-14">
                  {userProfile?.user_profile?.country}
                </p>
              </div>
            </div>
          )}

          <div className="d-flex align-items-start gap-2  pb-3">
            <img src="../assets/img/icon/icon-gender.svg" alt="" />
            <div>
              <span className="fs-14 medium text-brown d-block">Gender</span>
              <p className="m-0 fs-14">{userProfile?.user_profile?.gender}</p>
            </div>
          </div>
          {userProfile?.email === "hidden" ? (
            ""
          ) : (
            <div className="d-flex align-items-start gap-2  pb-3">
              <img src="../assets/img/icon/icon-email.svg" alt="" />
              <div>
                <span className="fs-14 medium text-brown d-block">Email</span>
                <p className="m-0 fs-14">
                  {truncateString(userProfile?.email, 25)}
                </p>
              </div>
            </div>
          )}
          {userRoleType === "child" || userProfile?.email === "hidden" ? (
            ""
          ) : (
            <div className="d-flex align-items-start gap-2  pb-3">
              <img src="../assets/img/icon/icon-mobile.svg" alt="" />
              <div>
                <span className="fs-14 medium text-brown d-block">Mobile</span>
                <p className="m-0 fs-14">{userProfile?.mobileNumber}</p>
              </div>
            </div>
          )}

          <div className="d-flex align-items-start gap-2  pb-3">
            <img src="../assets/img/icon/icon-blood.svg" alt="" />
            <div>
              <span className="fs-14 medium text-brown d-block">
                Blood Group
              </span>
              <p className="m-0 fs-14">
                {userProfile?.user_profile?.bloodGroup}
              </p>
            </div>
          </div>

          <div className="d-flex align-items-start gap-2  ">
            <img src="../assets/img/icon/icon-joined.svg" alt="" />
            <div>
              <span className="fs-14 medium text-brown d-block">Joined</span>
              <p className="m-0 fs-14">
                {userProfile?.createdAt &&
                  Moment(userProfile?.createdAt).format("D MMMM YYYY")}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="d-flex align-items-start gap-2  pb-3">
            <img src="../assets/img/icon/icon-user.svg" alt="" />
            <div>
              <span className="fs-14 medium text-brown d-block">About me</span>
              {/* <p className="m-0 fs-14">{userProfile?.user_profile?.aboutMe} </p> */}
              <LongText
                content={userProfile?.user_profile?.aboutMe}
                limit={`${limit}`}
              />
            </div>
          </div>
          {userProfile?.user_profile?.birthday === "hidden" ||
          userProfile?.user_profile?.birthday === "" ? (
            ""
          ) : (
            <div className="d-flex align-items-start gap-2  pb-3">
              <img src="../assets/img/icon/icon-cake.svg" alt="" />
              <div>
                <span className="fs-14 medium text-brown d-block">
                  Birthday
                </span>
                <p className="m-0 fs-14">
                  {userProfile?.user_profile?.birthday &&
                    Moment(userProfile?.user_profile?.birthday).format(
                      "D MMMM YYYY"
                    )}
                </p>
              </div>
            </div>
          )}

          <div className="d-flex align-items-start gap-2  pb-3">
            <img src="../assets/img/icon/icon-blood.svg" alt="" />
            <div>
              <span className="fs-14 medium text-brown d-block">
                Blood Group
              </span>
              <p className="m-0 fs-14">
                {userProfile?.user_profile?.bloodGroup}
              </p>
            </div>
          </div>

          <div className="d-flex align-items-start gap-2  ">
            <img src="../assets/img/icon/icon-joined.svg" alt="" />
            <div>
              <span className="fs-14 medium text-brown d-block">Joined</span>
              <p className="m-0 fs-14">
                {userProfile?.createdAt &&
                  Moment(userProfile?.createdAt).format("D MMMM YYYY")}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
