import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { CommonAPi } from "../../API/CommonApi";
import { PatchApi } from "../../API/PatchApi";
import ToastMessage from "../../Utils/ToastMessage";
import { Endpoints } from "../../API/Endpoints";
import { Link, NavLink } from "react-router-dom";
import "./PostShare.css";
import PostDiscardPopup from "../PostDiscardPopup/PostDiscardPopup";
import Loader from "../PostLoader/PostLoader";
import EmojiPicker from "emoji-picker-react";
import Resizer from "react-image-file-resizer";
import { EditorState, convertToRaw } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createMentionPlugin from "@draft-js-plugins/mention";
import createEmojiPlugin, { defaultTheme } from "@draft-js-plugins/emoji";

import "@draft-js-plugins/mention/lib/plugin.css";
import "@draft-js-plugins/emoji/lib/plugin.css";
import "@draft-js-plugins/linkify/lib/plugin.css";
import EntryComponent from "./EntryComponent";
import FriendsSpecificModal from "../FriendsSpecificModal";
import PostTitleDraftEdit from "../Post/PostTitleDraftEdit";

const postVisibilites = [
  {
    name: "Friends",
    value: "Friends",
    image: "../assets/img/icon/group-friend.svg",
    text: "Your connections on 8inNet",
  },
  {
    name: "Public",
    value: "Public",
    image: "../assets/img/icon/public.svg",
    text: "Anyone on or off 8inNet",
  },
  {
    name: "Friends Specific",
    value: "Friends Specific",
    image: "../assets/img/icon/friend.svg",
    text: "Only Show to some friends",
  },
];

const $ = window.jQuery;

export const CustomHastagComp = (hashtagProps) => {
  console.log({ hashtagProps });
  return (
    <a
      href={hashtagProps.mention.link}
      style={{
        padding: "3px 5px",
        backgroundColor: "white",
        color: "var(--blue)",
      }}
    >
      {hashtagProps.children}
    </a>
  );
};

const PostEdit = ({
  setPostPage,
  setTimelinePosts,
  postPage,
  post,
  getUserPosts,
  pageId,
  groupId,
  postId,
  onEditSuccess,
  setEditPostModal,
}) => {
  const previewTimeout = useRef();

  const [postTitle, setPostTitle] = useState("");
  const [postMedia, setPostMedia] = useState([]);
  const [deletedPostMedia, setDeletePostMedia] = useState([]);
  const [editpostId, setEditPostId] = useState("");
  const [UploadedpostMedia, setUploadedPostMedia] = useState([]);
  const [postVisibility, setPostVisibility] = useState("Friends");
  const [activePostCategory, setActivePostCategory] = useState("Status");
  const [isPostCreating, setIsPostCreating] = useState(true);
  const userProfile = localStorage.getItem("userProfile");
  const user = JSON.parse(localStorage.getItem("user"));
  const imageRef = useRef();
  const inputRef = useRef(null);
  const modalRef = useRef(null);
  const [showModal, setShowModal] = useState(true);
  const modalClickRef = useRef();
  const modalOutsideRef = useRef();
  const [showDiscardPopup, setShowDiscardPopup] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [previewDataLoader, setPreviewDataLoader] = useState(false);
  const [type, setType] = useState("");
  const [error, setError] = useState("");
  const [linkError, setLinkError] = useState("");
  const linkImageRef = useRef();
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [specificFrined, setSpecificFrined] = useState([]);
  let compressTimeout = null;
  const emojiRef = useRef(null);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // mention plugin implementation
  const [mentionOpen, setMentionOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  // hashtag plugin implementation
  const [hashtagOpen, setHashtagOpen] = useState(false);
  const [hashtagSuggestions, setHashtagSuggestions] = useState([]);

  const {
    MentionSuggestions,
    plugins,
    EmojiSuggestions,
    EmojiSelect,
    HashtagSuggestions,
  } = useMemo(() => {
    // @ mention
    const mentionPlugin = createMentionPlugin({
      mentionTrigger: ["@"],
      mentionPrefix: "",
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // # hashtag
    const hashtagPlugin = createMentionPlugin({
      mentionTrigger: ["#"],
      mentionComponent: CustomHastagComp,
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions: HashtagSuggestions } = hashtagPlugin;

    const emojiPlugin = createEmojiPlugin({
      theme: {
        ...defaultTheme,
        emojiSelect: "custom-emoji-select",
        emojiSelectButton: "custom-emoji-select-button",
        emojiSelectPopover:
          defaultTheme.emojiSelectPopover + " custom-emoji-select-popover",
      },
      selectButtonContent: (
        <>
          <img
            className="post-image"
            src="../assets/img/icon/emoji_icon.png"
            alt=""
          />
          Emoji
        </>
      ),
    });
    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

    const linkify = createLinkifyPlugin();

    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin, emojiPlugin, hashtagPlugin, linkify];
    return {
      plugins,
      MentionSuggestions,
      EmojiSuggestions,
      EmojiSelect,
      HashtagSuggestions,
    };
  }, []);

  const onOpenChange = useCallback((_open) => {
    setMentionOpen(_open);
  }, []);
  const onSearchChange = useCallback(({ value }) => {
    CommonAPi(Endpoints.MentionSearch + "?mention=" + value).then((res) => {
      if (res.data) {
        // setSuggestions(
        const userFriends = res?.data?.userFriends.map((item) => ({
          name: item.fullName,
          avatar: Endpoints.baseUrl + item.user_profile.profileImg,
          link: `/timeline/${item.username}`,
          id: `${item.id}`,
          type: "profile",
        }));
        const group = res?.data?.group.map((item) => ({
          name: item.groupName,
          avatar: Endpoints.baseUrl + item.logo,
          link: `/group-about/${item.slug}`,
          id: `${item.id}`,
          type: "group",
        }));
        const page = res?.data?.page.map((item) => ({
          name: item.title,
          avatar: Endpoints.baseUrl + item.pageImage,
          link: `/page-profile/${item.slug}`,
          id: `${item.id}`,
          type: "page",
        }));
        setSuggestions([...userFriends, ...group, ...page]);

        // );
      }
    });
    // setSuggestions(defaultSuggestionsFilter(value, mentions));
  }, []);

  const onHashtagOpenChange = useCallback((_open) => {
    setHashtagOpen(_open);
  }, []);
  const onHashtagSearchChange = useCallback(({ value }) => {
    CommonAPi(Endpoints.searchHashtag + value).then((res) => {
      if (res.data) {
        let mappedResponse = res.data.map((item) => ({
          name: `#${item.tag}`,
          link: `/singlehashtag?tag=${item.tag}`,
        }));
        const index = mappedResponse.findIndex(
          (item) =>
            item.name.trim().slice(1).toLowerCase() ===
            value.trim().toLowerCase()
        );
        if (value && index === -1)
          mappedResponse = [
            { name: `#${value}`, link: `/singlehashtag?tag=${value}` },
            ...mappedResponse,
          ];
        setHashtagSuggestions(mappedResponse);
      }
    });
    // setHashtagSuggestions(defaultSuggestionsFilter(value, hashtags));
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutSideEmoji);
    return () => {
      document.removeEventListener("click", handleClickOutSideEmoji);
    };
  }, []);

  function handleClickOutSideEmoji(e) {
    if (emojiRef.current && !emojiRef.current.contains(e.target)) {
      setEmojiPickerOpen(false);
    }
  }

  useEffect(() => {
    if (postTitle !== "" || postMedia.length > 0) {
      setIsPostCreating(false);
    } else {
      setIsPostCreating(true);
    }
  }, [postTitle, postMedia.length]);

  useEffect(() => {
    if (postTitle === "") {
      setPreviewDataLoader(false);
      previewTimeout.current = {
        url: "",
        timeout: null,
      };
    } else {
      const urlRegex = /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;
      const urlArray = urlRegex.exec(postTitle);
      if (urlArray?.length && urlArray[0].length) {
        async function fetchMeta() {
          try {
            const resp = await CommonAPi(Endpoints.LinkPreview, {
              url: urlArray[0],
            });
            if (resp && resp.success) {
              setPreviewData(resp.data);
              setPreviewDataLoader(false);
            }
          } catch (error) {
            setPreviewDataLoader(false);
            setPreviewData(null);
            console.log(error);
          }
        }
        if (
          previewData === null ||
          urlArray[0] !== previewTimeout.current.url
        ) {
          previewTimeout.current = {
            url: urlArray[0],
            timeout: setTimeout(() => {
              setPreviewDataLoader(true);
              fetchMeta();
            }, 1000),
          };
        }
      }
    }
    return () => {
      if (previewTimeout.current.timeout) {
        clearTimeout(previewTimeout.current.timeout);
        previewTimeout.current.timeout = null;
      }
    };
  }, [postTitle]);

  async function compressImage() {
    let promises = [];
    const quality = 70;
    for (const element of postMedia) {
      if (element.type.includes("image")) {
        let maxHeight, maxWidth;
        const image = new Image();
        const url = URL.createObjectURL(element);
        image.src = url;
        image.onload = () => {
          const { naturalWidth, naturalHeight } = image;
          maxHeight = naturalHeight;
          maxWidth = naturalWidth;
          URL.revokeObjectURL(url);
        };
        promises.push(
          new Promise((resolve) => {
            Resizer.imageFileResizer(
              element,
              maxWidth,
              maxHeight,
              "JPEG",
              quality,
              0,
              (file) => {
                // console.log(file)
                resolve(file);
              },
              "file"
            );
          })
        );
      }
    }
    const results = await Promise.all(promises);
    const compressed = [
      ...postMedia.filter((each) => each.type.includes("video")),
      ...results,
    ];
    return compressed;
  }

  const handleEmojiClick = (emojiData, MouseEvent) => {
    setSelectedEmoji(emojiData.unified);
    setPostTitle((prev) => prev + emojiData.emoji);
  };

  const handleImage = (e) => {
    if (e.target.files) {
      let files = [];
      for (const element of e.target.files) {
        let file = element;
        if (
          file.type === "image/png" ||
          file.type === "image/jpg" ||
          file.type === "image/jpeg" ||
          file.type === "image/webp" ||
          file.type === "video/mp4"
        ) {
          files.push(file);
        } else {
          ToastMessage.Error("PELASE CHOOSE VALID FILE TYPES");
          return;
        }
      }
      setPostMedia([...postMedia, ...files]);
      // console.log(postMedia);
    }
  };

  const uploadPost = async (e) => {
    setIsCreating(true);
    e.preventDefault();
    if (postTitle === "" && postMedia.length <= 0) {
      return ToastMessage.Error("PLEASE UPLOAD A POST OR A TITLE");
    }
    const result = await compressImage();
    const formData = new FormData();

    const editorData = convertToRaw(editorState.getCurrentContent());
    formData.append("title", postTitle);
    formData.append("id", editpostId);
    formData.append("post_visibility", postVisibility);
    if (previewData && postMedia.length === 0) {
      formData.append("link_preview_data", JSON.stringify(previewData));
      formData.append("category", "Status");
    } else {
      formData.append("category", activePostCategory);
    }
    if (pageId) {
      formData.append("page_id", pageId);
    }
    if (groupId) {
      formData.append("group_id", groupId);
    }
    if (deletedPostMedia.length > 0) {
      formData.append("deleted_files", JSON.stringify(deletedPostMedia));
    }
    result.forEach((item) => formData.append("media", item));
    if (postVisibility === "Friends Specific") {
      specificFrined.forEach((item) => formData.append("specificFriend", item));
    }

    try {
      setIsPostCreating(true);
      let resp = await PatchApi(Endpoints.UpdatePost, formData);
      if (resp && resp.success === true) {
        setEditorState(EditorState.createEmpty());
        setIsCreating(false);
        setEditPostModal(false);
        setShowModal(false);
        setPostMedia([]);
        setUploadedPostMedia([]);
        setPostTitle("");
        setTimelinePosts([]);
        setPreviewData(null);
        setEmojiPickerOpen(false);
        setLinkError("");
        imageRef.current.value = "";
        ToastMessage.Success(resp.message);
        // if (postPage !== 1) setPostPage(1);
        // else getUserPosts(1);
        // modalRef.current.click();
        // setShowDiscardPopup(false);
        setIsPostCreating(false);
        onEditSuccess?.(resp.data);
      }
    } catch (e) {
      console.log(e);
      setIsCreating(false);
      setIsPostCreating(false);
      if (e?.response?.data?.message) {
        ToastMessage.Error(e.response.data.message);
      }
    }
  };

  const removeSinglePicture = (i) => {
    setPostMedia(postMedia.filter((media, idx) => idx !== i));
    imageRef.current.value = "";
  };
  const removeUploadedFile = (i) => {
    setUploadedPostMedia(
      UploadedpostMedia.filter((media, idx) => media?.id !== i)
    );
    setDeletePostMedia([...deletedPostMedia, i]);
    imageRef.current.value = "";
  };

  const handlePrivacyStatus = (status) => {
    setPostVisibility(status);
    if (status === "Friends Specific") {
      $("#FriendsSpecificModal").modal("show");
      setShowModal(false);
    }
  };

  useEffect(() => {
    GetPostcounterData();
  }, [postId]);

  const GetPostcounterData = async () => {
    try {
      let resp = await CommonAPi(Endpoints.GetSinglePost + postId);
      if (resp && resp.success === true) {
        setPostTitle(resp?.data?.title);
        setEditPostId(resp?.data?.id);
        setUploadedPostMedia(resp?.data?.post_medias);
        setPostVisibility(resp?.data?.postVisibility);
      }
    } catch (e) {
      console.log(e.response);
      if (e && e.response && e.response.data && e.response.data.message) {
        ToastMessage.Error(e.response.data.message);
      }
    }
  };

  return (
    <>
      {/* post modal */}

      <div
        className={`modal-backdrop fade ${showModal ? "show" : "hide"}`}
      ></div>

      <div
        className={`modal fade ${showModal ? "show" : "hide"}`}
        // ref={modalOutsideRef}
        id="kidmodal"
        tabindex="-1"
        aria-labelledby="kidmodal"
        aria-hidden="true"
        style={{ overflow: "auto" }}
      >
        <div
          ref={modalClickRef}
          className="modal-dialog  rounded-10 box-shadow  bg-white mt-md-5 post-share-container"
        >
          <div className="modal-content border-0 p-lg-4 pb-lg-3 p-md-3 p-2">
            <div className="d-flex align-items-center justify-content-between pb-3 border-bottom mb-1 w-100">
              <h3 className="medium text-blue">Edit a post</h3>
              <button
                type="button"
                className="btn-close kids-close-icon cursor-pointer"
                data-bs-dismiss="modal"
                aria-label="Close"
                // ref={modalRef}
                onClick={() => {
                  setShowModal(false);
                  clearTimeout(compressTimeout);
                  // setShowDiscardPopup(true);
                  setEmojiPickerOpen(false);
                  if (postTitle !== "" || postMedia.length > 0) {
                    setShowDiscardPopup(true);
                  } else {
                    setShowDiscardPopup(false);
                  }
                }}
              >
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#949494"
                  width="22"
                  height="22"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="modal-body pb-0">
              <form
                action=""
                className="kids-form"
                encType="multipart/form-data"
                onSubmit={uploadPost}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <img
                          src={Endpoints.baseUrl + userProfile}
                          alt=""
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                          }}
                        />
                        <span
                          className="fs-14"
                          style={{ fontWeight: "500", marginLeft: "5px" }}
                        >
                          {user.fullName}
                        </span>
                      </div>
                      <div
                        className="privacy-box fs-12"
                        style={{ marginTop: "-20px" }}
                      >
                        <span className="ms-auto d-flex align-items-center">
                          {pageId || groupId ? (
                            <Link>
                              <img
                                src="../assets/img/icon/icon-privacy.svg"
                                alt="privacy"
                              />{" "}
                              {postVisibility}
                            </Link>
                          ) : (
                            <div className=" w-100">
                              <Link
                                class="dropdown-toggle w-100"
                                type="button"
                                id="defaultDropdown"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="true"
                                aria-expanded="false"
                              >
                                <img
                                  src="../assets/img/icon/icon-privacy.svg"
                                  alt="privacy"
                                />{" "}
                                {postVisibility}
                              </Link>
                              <ul
                                class="dropdown-menu dropdown-menu-end"
                                aria-labelledby="defaultDropdown"
                              >
                                {postVisibilites.map((postVisible, i) => (
                                  <li
                                    key={postVisible.name}
                                    className={
                                      postVisibility == postVisible.value
                                        ? "audience-status py-2"
                                        : "py-2"
                                    }
                                  >
                                    <Link
                                      class="dropdown-item py-2 d-flex gap-2"
                                      onClick={() => {
                                        handlePrivacyStatus(postVisible.value);
                                      }}
                                    >
                                      <div className="d-flex gap-3">
                                        <img
                                          src={postVisible?.image}
                                          alt="privacy"
                                          style={{
                                            width: "32px",
                                          }}
                                        />
                                        <span className="fs-16">
                                          {postVisible.name}
                                          <br />
                                          <small className="fs-12">
                                            {postVisible.text}
                                          </small>
                                        </span>
                                      </div>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        if (inputRef.current) inputRef.current.focus();
                      }}
                      className="py-3 postEditor"
                      style={{ cursor: "text" }}
                    >
                      {postTitle ? (
                        <PostTitleDraftEdit
                          title={postTitle}
                          onChange={(value) => {
                            setPostTitle(value);
                          }}
                        />
                      ) : (
                        ""
                      )}
                      {/* <PostTitleDraftEdit
                                                title={postTitle}
                                                onChange={(value) => {
                                                    setPostTitle(value)
                                                }}
                                            /> */}
                      <MentionSuggestions
                        open={mentionOpen}
                        onOpenChange={onOpenChange}
                        suggestions={suggestions}
                        onSearchChange={onSearchChange}
                        entryComponent={EntryComponent}
                        onAddMention={() => {
                          // get the mention object selected
                        }}
                      />
                      <HashtagSuggestions
                        open={hashtagOpen}
                        onOpenChange={onHashtagOpenChange}
                        suggestions={hashtagSuggestions}
                        onSearchChange={onHashtagSearchChange}
                        entryComponent={EntryComponent}
                        onAddMention={() => {
                          // get the mention object selected
                        }}
                      />
                      <EmojiSuggestions />
                    </div>

                    {previewDataLoader ? (
                      <div className="link-preview-container prviewBox mt-3">
                        <Loader />
                      </div>
                    ) : (
                      previewData && (
                        <div className="link-preview-container prviewBox mt-3">
                          <NavLink
                            to={`${previewData.url}`}
                            target="_blank"
                            // onClick={handleClick}
                          >
                            <div>
                              {(previewData.image || previewData.icon) && (
                                <img
                                  className="img-fluid text-center mx-auto link-image"
                                  src={previewData.image || previewData.icon}
                                  ref={linkImageRef}
                                  alt=""
                                />
                              )}

                              <h4 className="my-3">{previewData.title}</h4>
                              <p className="fs-14">
                                {previewData.description ||
                                  previewData.description2}
                              </p>
                              <p className="text-uppercase fs-12 font-weight-bold">
                                {previewData.url}
                              </p>
                            </div>
                          </NavLink>
                          <div className="d-flex justify-content-end align-items-start preview-cross">
                            <svg
                              height="20px"
                              width="20px"
                              fill="#777"
                              id="link-preview-cross"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setPreviewData(null);
                              }}
                              version="1.1"
                              viewBox="0 0 512 512"
                            >
                              <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                            </svg>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  {linkError && (
                    <span className="fs-12 text-danger">{linkError}</span>
                  )}

                  <div className="status-attachment d-flex pb-2 border-top pt-2 fs-12 mb-0 ">
                    <span
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <label
                        onClick={() => {
                          setType("image");
                        }}
                        htmlFor="image"
                        className="cursor-pointer add-media-post  px-2 py-1 rounded-15"
                      >
                        <input
                          type="file"
                          id="image"
                          ref={imageRef}
                          className="d-none"
                          onChange={handleImage}
                          multiple
                          accept={"image/*"}
                        />
                        <img
                          src="../assets/img/icon/icon-image.svg"
                          alt="share"
                          className="post-image"
                        />{" "}
                        Image
                      </label>
                      <label
                        onClick={() => {
                          setType("video");
                        }}
                        htmlFor="video"
                        className="cursor-pointer add-media-post  px-2 py-1 rounded-15 mx-3"
                      >
                        <input
                          type="file"
                          id="video"
                          ref={imageRef}
                          className="d-none"
                          onChange={handleImage}
                          multiple
                          accept={"video/mp4"}
                        />
                        <img
                          src="../assets/img/icon/icon-video-stream.svg"
                          alt="share"
                          className="post-image"
                        />{" "}
                        Video
                      </label>
                      <EmojiSelect />

                      {emojiPickerOpen && (
                        <EmojiPicker
                          onEmojiClick={handleEmojiClick}
                          ref={emojiRef}
                        />
                      )}
                    </span>
                  </div>

                  {error && (
                    <div>
                      <p className="text-danger">{error}</p>
                    </div>
                  )}

                  {postMedia?.length >= 1 && (
                    <div
                      className="prviewBox mt-3"
                      style={{ overflow: "auto", height: "300px" }}
                    >
                      <h6>New Uploaded {type}</h6>
                      <div className="d-flex justify-content-end">
                        <svg
                          height="15px"
                          width="15px"
                          id="Layer_1"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setPostMedia("");
                            imageRef.current.value = "";
                          }}
                          version="1.1"
                          viewBox="0 0 512 512"
                        >
                          <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                        </svg>
                      </div>

                      <div className="d-flex gap-2 flex-wrap">
                        {postMedia.map((media, i) => {
                          if (media.type === "video/mp4") {
                            return (
                              <div
                                key={i}
                                className="single-post-images"
                                style={{
                                  flex:
                                    (postMedia.length === 1 ||
                                      postMedia.length === 3) &&
                                    i === 0
                                      ? "1 0 100%"
                                      : i > 0 && i === postMedia.length - 1
                                      ? "0 0 50%"
                                      : "1 0 45%",
                                }}
                              >
                                <video
                                  controls
                                  height="100%"
                                  width="100%"
                                  style={{ objectFit: "cover" }}
                                >
                                  <source src={URL.createObjectURL(media)} />
                                </video>
                                <div className="d-flex justify-content-end mt-1">
                                  <svg
                                    height="15px"
                                    width="15px"
                                    id="Layer_1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      removeSinglePicture(i);
                                    }}
                                    version="1.1"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                                  </svg>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                key={i}
                                className="single-post-images"
                                style={{
                                  flex:
                                    (postMedia.length === 1 ||
                                      postMedia.length === 3) &&
                                    i === 0
                                      ? "1 0 100%"
                                      : i > 0 && i === postMedia.length - 1
                                      ? "0 0 50%"
                                      : "1 0 45%",
                                }}
                              >
                                <img
                                  src={URL.createObjectURL(media)}
                                  alt="preview-img"
                                  className="post-media mt-1 w-100 img-fluid"
                                />
                                <div className="d-flex justify-content-end">
                                  <svg
                                    height="15px"
                                    width="15px"
                                    id="Layer_1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      removeSinglePicture(i);
                                      console.log("HELLO");
                                    }}
                                    version="1.1"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                                  </svg>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  )}

                  {UploadedpostMedia?.length >= 1 && (
                    <div
                      className="prviewBox mt-3"
                      style={{ overflow: "auto", height: "300px" }}
                    >
                      <h6>Uploaded Media</h6>

                      <div className="d-flex gap-2 flex-wrap">
                        {UploadedpostMedia.map((media, i) => {
                          if (
                            media.thumbnail !== "" &&
                            media.thumbnail != null
                          ) {
                            return (
                              <div
                                key={i}
                                className="single-post-images"
                                style={{
                                  flex:
                                    (UploadedpostMedia.length === 1 ||
                                      UploadedpostMedia.length === 3) &&
                                    i === 0
                                      ? "1 0 100%"
                                      : i > 0 &&
                                        i === UploadedpostMedia.length - 1
                                      ? "0 0 49%"
                                      : "1 0 49%",
                                }}
                              >
                                <video
                                  controls
                                  height="100%"
                                  width="100%"
                                  style={{ objectFit: "cover" }}
                                >
                                  <source
                                    src={Endpoints.baseUrl + media?.filePath}
                                  />
                                </video>
                                <div className="d-flex justify-content-end mt-1">
                                  <svg
                                    height="15px"
                                    width="15px"
                                    id="Layer_1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      removeUploadedFile(media?.id);
                                    }}
                                    version="1.1"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                                  </svg>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                key={i}
                                className="single-post-images"
                                style={{
                                  flex:
                                    (UploadedpostMedia.length === 1 ||
                                      UploadedpostMedia.length === 3) &&
                                    i === 0
                                      ? "1 0 100%"
                                      : i > 0 &&
                                        i === UploadedpostMedia.length - 1
                                      ? "0 0 50%"
                                      : "1 0 45%",
                                }}
                              >
                                <img
                                  src={Endpoints.baseUrl + media?.filePath}
                                  alt="preview-img"
                                  className="post-media mt-1 w-100 img-fluid"
                                />
                                <div className="d-flex justify-content-end">
                                  <svg
                                    height="15px"
                                    width="15px"
                                    id="Layer_1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      removeUploadedFile(media?.id);
                                    }}
                                    version="1.1"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                                  </svg>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  )}

                  <div className="col-md-12 text-end">
                    <button
                      type="submit"
                      className="btn fs-14 mt-4"
                      disabled={
                        isPostCreating === true
                          ? true
                          : isCreating === true
                          ? true
                          : false
                      }
                    >
                      {isCreating
                        ? "Please wait..."
                        : activePostCategory === "Status"
                        ? "Update Post"
                        : "Update Project"}

                      {!isCreating ? (
                        <img
                          src="../../assets/img/icon/icon-right-arrow.svg"
                          alt=""
                        />
                      ) : (
                        <div
                          style={{ width: "1rem", height: "1rem" }}
                          className="spinner-border text-secondary"
                          role="status"
                        >
                          <span className="sr-only"></span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {showDiscardPopup && (
        <PostDiscardPopup
          type={activePostCategory}
          title={"Are you sure you want to discard this "}
          modalRef={modalRef}
          showModal={showModal}
          showDiscardPopup={showDiscardPopup}
          setShowDiscardPopup={setShowDiscardPopup}
          setShowModal={setShowModal}
          setPostTitle={setPostTitle}
          setPostMedia={setPostMedia}
          setPreviewData={setPreviewData}
          setLinkError={setLinkError}
          compressTimeout={compressTimeout}
          setError={setError}
          postTitle={postTitle}
          postMedia={postMedia}
          setEditPostModal={setEditPostModal}
        />
      )}

      <FriendsSpecificModal
        {...{
          setShowModal: setShowModal,
          setPostVisibility: setPostVisibility,
          specificFrined: specificFrined,
          setSpecificFrined: setSpecificFrined,
        }}
      />
    </>
  );
};

export default PostEdit;
