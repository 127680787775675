import React, { useEffect, useState, useCallback } from "react";
import { FetchApi } from "../../../API/FetchApi";
import { Endpoints } from "../../../API/Endpoints";
import ToastMessage from "../../../Utils/ToastMessage";
import { CommonAPi } from "../../../API/CommonApi";
import { PatchApi } from "../../../API/PatchApi";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const $ = window.jQuery;

export default function JobEditModel({ onSuccess, title }) {
  console.log(title);
  const [jobTitle, setJobTitle] = useState("");
  const [jobType, setJobType] = useState("");
  const [location, setLocation] = useState("");
  const [locationId, setLocationId] = useState("");

  const [Qualification, setQualification] = useState("");
  const [Description, setDescription] = useState("");
  const [Industry, setIndustry] = useState("");
  const [industryId, setIndustryId] = useState("");
  const [WorkLevel, setWorkLevel] = useState("");
  const [WorkModel, setWorkModel] = useState("");
  const [SalaryMin, setSalaryMin] = useState("");
  const [SalaryMax, setSalaryMax] = useState("");
  const [ExperinceMax, setExperinceMax] = useState("");
  const [ExperinceMin, setExperinceMin] = useState("");
  const [IndustryKey, setIndustryKey] = useState(
    Math.ceil(Math.random() * 999999)
  );
  const [IndustryOption, setIndustryOption] = useState([]);
  const [countryKey, setCountryKey] = useState(
    Math.ceil(Math.random() * 999999)
  );
  const [countryOption, setCountryOption] = useState([]);
  const [id, setId] = useState("");
  const param = useParams();

  const [jobTitleError, setJobTitleError] = useState("");
  const [jobTypeError, setJobTypeError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [QualificationError, setQualificationError] = useState("");
  const [DescriptionError, setDescriptionError] = useState("");
  const [IndustryError, setIndustryError] = useState("");
  const [WorkLevelError, setWorkLevelError] = useState("");
  const [WorkModelError, setWorkModelError] = useState("");
  const [SalaryMinError, setSalaryMinError] = useState("");
  const [SalaryMaxError, setSalaryMaxError] = useState("");
  const [ExperinceMaxError, setExperinceMaxError] = useState("");
  const [ExperinceMinError, setExperinceMinError] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    getCompanyJobs();
  }, []);

  const getCompanyJobs = async () => {
    try {
      let resp = await FetchApi(Endpoints.JobPreview + "/" + param?.id);
      if (resp && resp.success === true) {
        // setJobsData(resp?.data?.job);
        setSalaryMax(resp?.data?.job?.maxSalary);
        setSalaryMin(resp?.data?.job?.minSalary);
        setExperinceMin(resp?.data?.job?.minExperience);
        setExperinceMax(resp?.data?.job?.maxExperience);
        setJobTitle(resp?.data?.job?.title);
        setJobType(resp?.data?.job?.jobType);
        setLocation(resp?.data?.job?.state?.state);
        setLocationId(resp?.data?.job?.state?.id);
        setQualification(resp?.data?.job?.qualification);
        setDescription(resp?.data?.job?.description);
        setIndustry(resp?.data?.job?.industryData?.value);
        setIndustryOption([
          {
            id: resp?.data?.job?.industryData?.id,
            name: resp?.data?.job?.industryData?.value,
          },
        ]);
        setCountryOption([
          {
            id: resp?.data?.job?.state?.id,
            name: resp?.data?.job?.state?.name,
          },
        ]);
        setCountryKey(Math.ceil(Math.random() * 999999));
        setIndustryKey(resp?.data?.job?.industryData?.id);
        setWorkLevel(resp?.data?.job?.workLevel);
        setWorkModel(resp?.data?.job?.workMode);
        setId(resp?.data?.job?.id);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!jobType) {
      setJobTypeError("Job type is required");
    } else {
      setJobTypeError("");
    }
    if (!jobTitle) {
      setJobTitleError("Job title is required");
    } else {
      setJobTitleError("");
    }
    if (!location) {
      setLocationError("Location is required");
    } else {
      setLocationError("");
    }
    if (!Qualification) {
      setQualificationError("Qualification is required");
    } else {
      setQualificationError("");
    }
    if (!Description) {
      setDescriptionError("Description is required");
    } else {
      setDescriptionError("");
    }
    if (!Industry) {
      setIndustryError("Industry is required");
    } else {
      setIndustryError("");
    }
    if (!WorkLevel) {
      setWorkLevelError("Work level is required");
    } else {
      setWorkLevelError("");
    }
    if (!WorkModel) {
      setWorkModelError("Work model is required");
    } else {
      setWorkModelError("");
    }
    if (!SalaryMin) {
      setSalaryMinError("Salary min is required");
    } else {
      setSalaryMinError("");
    }
    if (!SalaryMax) {
      setSalaryMaxError("Salary max  is required");
    } else {
      setSalaryMaxError("");
    }
    if (!ExperinceMax) {
      setExperinceMaxError("Experince max is required");
    } else {
      setExperinceMaxError("");
    }
    if (!ExperinceMin) {
      setExperinceMinError("Experince min is required");
    } else {
      setExperinceMinError("");
    }

    if (SalaryMax) {
      if (SalaryMin > SalaryMax) {
        setSalaryMaxError("Salary max grater then salary min");
        return false;
      } else {
        setSalaryMaxError("");
      }
    }
    if (ExperinceMax) {
      if (ExperinceMin > ExperinceMax) {
        setExperinceMaxError("Experince max grater then experince min");
        return false;
      } else {
        setExperinceMaxError("");
      }
    }

    if (
      jobTitle &&
      jobType &&
      location &&
      Qualification &&
      Description &&
      Industry &&
      WorkLevel &&
      WorkModel &&
      SalaryMin &&
      SalaryMax &&
      ExperinceMax &&
      ExperinceMin &&
      SalaryMin < SalaryMax &&
      ExperinceMin < ExperinceMax
    ) {
      let data = {
        title: jobTitle,
        pageId: param?.id,
        jobType: jobType,
        location: locationId,
        qualification: Qualification,
        description: Description,
        industry: IndustryKey,
        workLevel: WorkLevel,
        workMode: WorkModel,
        minSalary: SalaryMin,
        maxSalary: SalaryMax,
        minExperience: ExperinceMin,
        maxExperience: ExperinceMax,
      };
      if (id) {
        try {
          let resp = await PatchApi(Endpoints.JobEdit + "/" + id, data);
          if (resp && resp.message) {
            $("#internship").modal("hide");
            onSuccess(true);
            setSalaryMax("");
            setSalaryMin("");
            setExperinceMin("");
            setExperinceMax("");
            setJobTitle("");
            setJobType("");
            setLocation("");
            setQualification("");
            setDescription("");
            setIndustry("");
            setIndustryOption([]);
            setCountryKey([]);
            setIndustryKey(Math.ceil(Math.random() * 999999));
            setCountryKey(Math.ceil(Math.random() * 999999));
            setWorkLevel("");
            setWorkModel("");
            ToastMessage.Success(resp.message);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            if (e.response.status === 401) {
              ToastMessage.Error("Session Expired! Please login again.");
              localStorage.clear();
              navigate("/signin");
            } else {
              ToastMessage.Error(e.response.data.message);
            }
          }
        }
      }
    }
  };
  const handleOnSearchIndustry = async (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    if (string) {
      try {
        let resp = await FetchApi(
          Endpoints.SearchIndustry + "?title=" + string
        );
        if (resp && resp.success === true) {
          if (resp.data.length > 0) {
            const newData = resp.data.map((item, index) => {
              return {
                id: item.id,
                name: item?.value,
              };
            });
            setIndustryOption(newData);
          }
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  const handleOnSelectIndustry = (item) => {
    if (item.length > 0) {
      setIndustry(item[0]?.id);
      setIndustryKey(item[0]?.id);
    } else {
      setIndustry("");
    }
  };

  const RenderIndustry = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-StudyArea"
        defaultInputValue={Industry}
        key={IndustryKey}
        onSearch={handleOnSearchIndustry}
        onChange={handleOnSelectIndustry}
        // onBlur={(e) => { handleValidation(e, e.target.value, "study") }}
        options={IndustryOption}
        placeholder={
          param?.type === "School"
            ? "For Ex: High School"
            : "For Ex: IT Services and IT Consulting"
        }
        labelKey="name"
        minLength={1}
        emptyLabel={"No result found"}
        className={" border rounded-2 fs-14 p-2 "}
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [IndustryOption]);

  const handleOnSelectLocation = (item) => {
    if (item.length > 0) {
      setLocation(item[0].id);
      setLocationId(item[0].id);
    } else {
      setLocation("");
    }
  };

  const handleOnSearchLocation = async (string) => {
    try {
      let resp = await FetchApi(Endpoints.StateSearch + "?term=" + string);
      if (resp && resp.success === true) {
        const newData = resp.data.map((item, index) => {
          return {
            id: item.id,
            name: item.state,
          };
        });
        setCountryOption(newData);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const RenderCountry = useCallback(() => {
    return (
      <AsyncTypeahead
        id="async-pagination-example-StudyArea"
        defaultInputValue={location}
        key={countryKey}
        onSearch={handleOnSearchLocation}
        onChange={handleOnSelectLocation}
        options={countryOption}
        placeholder={"Location"}
        labelKey="name"
        minLength={1}
        emptyLabel={"No result found"}
        className={" border rounded-2 fs-14 p-2 "}
        renderMenuItemChildren={(option, props) => (
          <div key={option.id}>
            <Highlighter search={props.text}>
              {option[props.labelKey]}
            </Highlighter>
          </div>
        )}
        useCache={false}
      />
    );
  }, [countryOption]);
  return (
    <div class="modal" id="internship" tabindex="-1">
      <div class="modal-dialog modal-lg px-lg-5 modal-dialog-centered modal-dialog-scrollable modal-md">
        <div class="modal-content">
          <button
            type="button"
            class="btn-close ms-auto p-3 position-absolute end-0 "
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div class="text-center py-4 border-bottom">
            <h5 class="modal-title text-center fs-20 medium text-black">
              Update {title === "Internship" ? "Internship" : "Job"}
            </h5>
          </div>
          <div class="modal-body">
            <form action="" onSubmit={handleSubmit} class="site-form">
              <span class="text-darkgray medium fs-12 pb-3 d-block">
                * All fields are required.
              </span>
              <div class="form-field mb-3">
                <label for="" class="pb-1 fs-14 medium">
                  Job Title *
                </label>
                <input
                  type="text"
                  placeholder="Senior UI/UX Designer"
                  class="fs-14 border rounded-3 py-2 px-3 w-100"
                  value={jobTitle}
                  onChange={(e) => {
                    setJobTitle(e.target.value);
                  }}
                />
                {jobTitleError ? (
                  <span className="field-error fs-12 text-red">
                    {jobTitleError}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div class="form-field mb-3">
                <label for="" class="pb-1 fs-14 medium">
                  Job Type *
                </label>
                <select
                  class=" text-darkgray border rounded-3 py-2 px-3 w-100"
                  onChange={(e) => {
                    setJobType(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  <option
                    value="Full-time"
                    selected={jobType == "Full-time" ? true : false}
                  >
                    Full-time
                  </option>
                  <option
                    value="Part-time"
                    selected={jobType == "Part-time" ? true : false}
                  >
                    Part-time
                  </option>
                  <option
                    value="Contract"
                    selected={jobType == "Contract" ? true : false}
                  >
                    {" "}
                    Contract
                  </option>
                  <option
                    value="Temporary"
                    selected={jobType == "Temporary" ? true : false}
                  >
                    Temporary
                  </option>
                  <option
                    value="Freelance"
                    selected={jobType == "Freelance" ? true : false}
                  >
                    Freelance
                  </option>
                  <option
                    value="Internship"
                    selected={jobType == "Internship" ? true : false}
                  >
                    Internship
                  </option>
                </select>
                {jobTypeError ? (
                  <span className="field-error fs-12 text-red">
                    {jobTypeError}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div class="form-field mb-3">
                <label for="" class="pb-1 fs-14 medium">
                  Location *
                </label>
                {/* <select class="  text-darkgray border rounded-3 py-2 px-3 w-100"
                                                    onChange={(e) => {
                                                        setLocation(e.target.value)
                                                    }}>
                                                    <option value="">Select</option>
                                                    <option value="1">Select1</option>
                                                    <option value="2">Select2</option>
                                                </select> */}
                {RenderCountry()}
                {locationError ? (
                  <span className="field-error fs-12 text-red">
                    {locationError}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div class="form-field mb-3">
                <label for="" class="pb-1 fs-14 medium">
                  Qualification *
                </label>
                <select
                  class=" text-darkgray border rounded-3 py-2 px-3 w-100"
                  onChange={(e) => {
                    setQualification(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  <option
                    value="10th Pass"
                    selected={Qualification == "10th Pass" ? true : false}
                  >
                    10th Pass
                  </option>
                  <option
                    value="12th Pass"
                    selected={Qualification == "12th Pass" ? true : false}
                  >
                    12th Pass
                  </option>
                  <option
                    value="Diploma or Equivalent"
                    selected={
                      Qualification == "Diploma or Equivalent" ? true : false
                    }
                  >
                    Diploma or Equivalent
                  </option>
                  <option
                    value="Associate's Degree"
                    selected={
                      Qualification == "Associate's Degree" ? true : false
                    }
                  >
                    Associate's Degree
                  </option>
                  <option
                    value="Bachelor's Degree"
                    selected={
                      Qualification == "Bachelor's Degree" ? true : false
                    }
                  >
                    Bachelor's Degree
                  </option>
                  <option
                    value="Master's Degree"
                    selected={Qualification == "Master's Degree" ? true : false}
                  >
                    Master's Degree
                  </option>
                  <option
                    value="Ph.D"
                    selected={Qualification == "Ph.D" ? true : false}
                  >
                    Ph.D
                  </option>
                  <option
                    value="Professional Degree"
                    selected={
                      Qualification == "Professional Degree" ? true : false
                    }
                  >
                    Professional Degree
                  </option>
                  <option
                    value="Apprenticeships"
                    selected={Qualification == "Apprenticeships" ? true : false}
                  >
                    Apprenticeships
                  </option>
                  <option
                    value="Internships"
                    selected={Qualification == "Internships" ? true : false}
                  >
                    Internships
                  </option>
                </select>
                {QualificationError ? (
                  <span className="field-error fs-12 text-red">
                    {QualificationError}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div class="form-field mb-3">
                <label for="" class="pb-1 fs-14 medium">
                  Job Description
                </label>
                {/* <textarea name=""
                                                    id=""
                                                    cols="30"
                                                    rows="5"
                                                    class="fs-14 border rounded-3 py-2 px-3 w-100"
                                                    placeholder="Detailed job Description"
                                                    value={Description}
                                                    onChange={(e) => {
                                                        setDescription(e.target.value)
                                                    }}
                                                ></textarea> */}
                <CKEditor
                  data={Description}
                  className={"editor text-gray px-3 py-3 border"}
                  cols="30"
                  rows="10"
                  onChange={(event, editor) => {
                    setDescription(editor.getData());
                  }}
                  editor={ClassicEditor}
                  config={{
                    toolbar: [
                      "bold",
                      "italic",
                      "link",
                      "blockQuote",
                      "numberedList",
                      "bulletedList",
                      "|",
                      "undo",
                      "redo",
                    ],
                  }}
                />
                {DescriptionError ? (
                  <span className="field-error fs-12 text-red">
                    {DescriptionError}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div class="form-field mb-3">
                <label for="" class="pb-1 fs-14 medium">
                  Industry *
                </label>
                {RenderIndustry()}
                {IndustryError ? (
                  <span className="field-error fs-12 text-red">
                    {IndustryError}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div class="form-field mb-3">
                <label for="" class="pb-1 fs-14 medium">
                  Work Level *
                </label>
                <select
                  class=" text-darkgray border rounded-3 py-2 px-3 w-100"
                  onChange={(e) => {
                    setWorkLevel(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  <option
                    value="Entry-Level"
                    selected={WorkLevel == "Entry-Level" ? true : false}
                  >
                    Entry-Level
                  </option>
                  <option
                    value="Junior-Level"
                    selected={WorkLevel == "Junior-Level" ? true : false}
                  >
                    Junior-Level
                  </option>
                  <option
                    value="Mid-Level"
                    selected={WorkLevel == "Mid-Level" ? true : false}
                  >
                    Mid-Level
                  </option>
                  <option
                    value="Senior-Level"
                    selected={WorkLevel == "Senior-Level" ? true : false}
                  >
                    Senior-Level
                  </option>
                  <option
                    value="Managerial/Supervisory"
                    selected={
                      WorkLevel == "Managerial/Supervisory" ? true : false
                    }
                  >
                    Managerial/Supervisory
                  </option>
                  <option
                    value="Director-Level"
                    selected={WorkLevel == "Director-Level" ? true : false}
                  >
                    Director-Level
                  </option>
                  <option
                    value="Executive-Level"
                    selected={WorkLevel == "Executive-Level" ? true : false}
                  >
                    Executive-Level
                  </option>
                </select>
                {WorkLevelError ? (
                  <span className="field-error fs-12 text-red">
                    {WorkLevelError}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div class="form-field mb-3">
                <label for="" class="pb-1 fs-14 medium">
                  Work Model *
                </label>
                <select
                  class=" text-darkgray border rounded-3 py-2 px-3 w-100"
                  onChange={(e) => {
                    setWorkModel(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  <option
                    value="On-site"
                    selected={WorkModel == "On-site" ? true : false}
                  >
                    On-site
                  </option>
                  <option
                    value="Remote"
                    selected={WorkModel == "Remote" ? true : false}
                  >
                    Remote
                  </option>
                  <option
                    value="Hybrid"
                    selected={WorkModel == "Hybrid" ? true : false}
                  >
                    Hybrid
                  </option>
                  <option
                    value="Work from home"
                    selected={WorkModel == "Work from home" ? true : false}
                  >
                    Work from home
                  </option>
                </select>
                {WorkModelError ? (
                  <span className="field-error fs-12 text-red">
                    {WorkModelError}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div>
                <h5 class="fs-12 medium pb-2 ">
                  Salary * <span class="text-lightgray">(per month)</span>
                </h5>
                <div class="row">
                  <div class="col-4">
                    <div class="form-field mb-3">
                      <label for="" class="pb-1 fs-14 medium">
                        Min
                      </label>
                      <input
                        type="text"
                        placeholder="Rs. 10,000"
                        class="fs-14 border rounded-3 py-2 px-3 w-100"
                        value={SalaryMin}
                        onChange={(e) => {
                          setSalaryMin(e.target.value);
                        }}
                      />
                    </div>
                    {SalaryMinError ? (
                      <span className="field-error fs-12 text-red">
                        {SalaryMinError}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="col-4">
                    <div class="form-field mb-3">
                      <label for="" class="pb-1 fs-14 medium">
                        Max
                      </label>
                      <input
                        type="text"
                        placeholder="Rs. 25,000"
                        class="fs-14 border rounded-3 py-2 px-3 w-100"
                        value={SalaryMax}
                        onChange={(e) => {
                          setSalaryMax(e.target.value);
                        }}
                      />
                      {SalaryMaxError ? (
                        <span className="field-error fs-12 text-red">
                          {SalaryMaxError}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h5 class="fs-12 medium pb-2 ">Experience *</h5>
                <div class="row">
                  <div class="col-4">
                    <div class="form-field mb-3">
                      <label for="" class="pb-1 fs-14 medium">
                        Min
                      </label>
                      <input
                        type="text"
                        placeholder="1 Yr"
                        class="fs-14 border rounded-3 py-2 px-3 w-100"
                        value={ExperinceMin}
                        onChange={(e) => {
                          setExperinceMin(e.target.value);
                        }}
                      />
                      {ExperinceMinError ? (
                        <span className="field-error fs-12 text-red">
                          {ExperinceMinError}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-field mb-3">
                      <label for="" class="pb-1 fs-14 medium">
                        Max
                      </label>
                      <input
                        type="text"
                        placeholder="4 Yr"
                        class="fs-14 border rounded-3 py-2 px-3 w-100"
                        value={ExperinceMax}
                        onChange={(e) => {
                          setExperinceMax(e.target.value);
                        }}
                      />
                      {ExperinceMaxError ? (
                        <span className="field-error fs-12 text-red">
                          {ExperinceMaxError}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <input
                type="submit"
                value={`Update ${
                  title === "Internship" ? "Internship" : "Job"
                }`}
                class="btn fs-14 medium px-5"
              />
              {/* <a href="#" class="btn fs-14 medium px-5">Post Job</a> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
