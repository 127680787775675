export const timeSince = (date, isCurrent = false) => {
  if (isCurrent) return 0 + "sec";
  let seconds = Math.floor((new Date() - date) / 1000);
  let interval = seconds / 31536000;

  if (interval > 1) return Math.floor(interval) + "y";
  interval = seconds / 2592000;

  if (interval > 1) return Math.floor(interval) + "mon";
  interval = seconds / 86400;

  if (interval > 1) return Math.floor(interval) + "d";
  interval = seconds / 3600;

  if (interval > 1) return Math.floor(interval) + "h";
  interval = seconds / 60;

  if (interval > 1) return Math.floor(interval) + "min";
  return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec" : "0sec";
};

export const linkReplace = (text) => {
  const linkRegex = /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;
  function replacer(matched) {
    let withProtocol = matched;

    if (!withProtocol.startsWith("http")) {
      withProtocol = "http://" + matched;
    }
    const newStr = `<a href="${withProtocol}" class="link-primary" target="_blank">${matched}</a>`;
    return newStr;
  }

  return text ? text.replaceAll(linkRegex, replacer) : text;
};

export const logTimeDiff = (inputTime) => {
  // accepted time format = 2024-08-15T04:40:37.000Z
  if (inputTime) {
    const inputDate = new Date(inputTime);
    const currentDate = new Date();

    const diffInSeconds = Math.floor((currentDate - inputDate) / 1000);

    const secondsInMinute = 60;
    const secondsInHour = 60 * secondsInMinute;
    const secondsInDay = 24 * secondsInHour;
    const secondsInWeek = 7 * secondsInDay;

    if (diffInSeconds < secondsInMinute) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < secondsInHour) {
      const minutes = Math.floor(diffInSeconds / secondsInMinute);
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < secondsInDay) {
      const hours = Math.floor(diffInSeconds / secondsInHour);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < secondsInWeek) {
      const days = Math.floor(diffInSeconds / secondsInDay);
      return `${days}d ago`;
    } else {
      const weeks = Math.floor(diffInSeconds / secondsInWeek);
      return `${weeks}w ago`;
    }
  } else {
    return "";
  }
};

export const truncateString = (t, size = 20) => {
  if (t) {
    return t.length > size ? t.substring(0, size) + "..." : t;
  }
  return "--";
};
