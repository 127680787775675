import React, { useEffect, useState } from "react";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from '../../API/FetchApi';
import {  useNavigate } from "react-router-dom";

export default function PostStatistics() {
    const [StatisticsData, setStatisticsData] = useState('');
    const Navigate = useNavigate();
    useEffect(() => {
        getStatistics();
    }, []);
    const getStatistics = async () => {
        try {
            let resp = await FetchApi(Endpoints.PostStatistics);
            if (resp && resp.success === true) {
                setStatisticsData(resp.data)
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };

    return (
        <div className="sidebar-widget">
            <h4 className="medium">Reactions Received</h4>
            <div className="pt-3 border-top mt-3">
                <div className="d-flex pb-3">
                    <img
                        src="../assets/img/icon/Like.svg"
                        alt=""
                        width="36"
                        height="36"
                        className="rounded-circle"
                    />
                    <div className="ps-2">
                        <h4 className="medium mb-0 pb-1">{StatisticsData?.weeklyLikes}</h4>
                        <h6 className="fs-12 text-gray">Weekly Likes</h6>
                    </div>
                </div>

                <div className="d-flex pb-3">
                    <img
                        src="../assets/img/icon/Love.svg"
                        alt=""
                        width="36"
                        height="36"
                        className="rounded-circle"
                    />
                    <div className="ps-2">
                        <h4 className="medium mb-0 pb-1">{StatisticsData?.weeklyLoves}</h4>
                        <h6 className="fs-12 text-gray">Weekly Love</h6>
                    </div>
                </div>

                <div className="d-flex pb-3">
                    <img
                        src="../assets/img/icon/Haha.svg"
                        alt=""
                        width="36"
                        height="36"
                        className="rounded-circle"
                    />
                    <div className="ps-2">
                        <h4 className="medium mb-0 pb-1">{StatisticsData?.weeklyHappy}</h4>
                        <h6 className="fs-12 text-gray">Weekly Happy</h6>
                    </div>
                </div>

                <div className="d-flex pb-3">
                    <img
                        src="../assets/img/icon/Care.svg"
                        alt=""
                        width="36"
                        height="36"
                        className="rounded-circle"
                    />
                    <div className="ps-2">
                        <h4 className="medium mb-0 pb-1">{StatisticsData?.weeklyCares}</h4>
                        <h6 className="fs-12 text-gray">Weekly Care</h6>
                    </div>
                </div>

                <div className="d-flex pb-3">
                    <img
                        src="../assets/img/icon/Wow.svg"
                        alt=""
                        width="36"
                        height="36"
                        className="rounded-circle"
                    />
                    <div className="ps-2">
                        <h4 className="medium mb-0 pb-1">{StatisticsData?.weeklyWow}</h4>
                        <h6 className="fs-12 text-gray">Weekly Wow</h6>
                    </div>
                </div>
                <div className="d-flex pb-3">
                    <img
                        src="../assets/img/icon/Sad.svg"
                        alt=""
                        width="36"
                        height="36"
                        className="rounded-circle"
                    />
                    <div className="ps-2">
                        <h4 className="medium mb-0 pb-1">{StatisticsData?.weeklySad}</h4>
                        <h6 className="fs-12 text-gray">Weekly Sad</h6>
                    </div>
                </div>
                <div className="d-flex pb-3">
                    <img
                        src="../assets/img/icon/Angry.svg"
                        alt=""
                        width="36"
                        height="36"
                        className="rounded-circle"
                    />
                    <div className="ps-2">
                        <h4 className="medium mb-0 pb-1">{StatisticsData?.weeklyAngry}</h4>
                        <h6 className="fs-12 text-gray">Weekly Angry</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}
