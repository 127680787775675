import React, { useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Endpoints } from "../../API/Endpoints";
import { FetchApi } from '../../API/FetchApi';
import ToastMessage from "../../Utils/ToastMessage";
import TopHeader from '../../Components/TopHeader';
import Experience from '../ProfileAbout/Experience';
import AcademicsScreen from '../ProfileAbout/Academics';
import Swal from "sweetalert2";
import Welcome from './Welcome';
import {Helmet} from "react-helmet";
const $ = window.jQuery;
export default function Academics() {

  const userRoleType = localStorage.getItem("userRoleType");
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = localStorage.getItem("userId");
  const Navigate = useNavigate();


  useEffect(() => {
    var percent = 100;
    percent = percent.toFixed();
    $(".progress-bar")
      .css("width", percent + "%")
  }, []);

  const handleNext = () => {

    if (user?.usersType === "Student") {
      getAcademics();
    } else {
      getExperience();
    }
  };

  const getAcademics = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.Getacademics);
      if (resp && resp.success === true) {
        if (resp.data.length > 0) {
          getProfile();
        } else {
          ToastMessage.Info("Please add atleast one academics")
        }
      }
    }
    catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.")
          localStorage.clear();
          Navigate('/signin');
        } else {
          ToastMessage.Error(e.response.data.message);
        }

      }
    }
  };
  const getExperience = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetExperience);
      if (resp && resp.success === true) {
        if (resp.data.length > 0) {
          getProfile();
        } else {
          ToastMessage.Info("Please add at least one experience")
        }
      }
    }
    catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error('Session Expired! Please login again.');
          localStorage.clear();
          Navigate('/signin');
        } else {
          ToastMessage.Error(e.response.data.message)
        }

      }
    }
  };

  const getProfile = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetProfile + "?id=" + userId);
      if (resp && resp.success === true) {
        window.localStorage.setItem('user', JSON.stringify(resp?.data));
        window.localStorage.setItem('parentData', JSON.stringify(resp?.data));
        window.localStorage.setItem('ProfileCompletion', "100.00");
        window.localStorage.setItem('ProfileTagline', resp?.data?.user_profile?.profileTagline);
        window.localStorage.setItem('userProfile', resp?.data?.user_profile?.profileImg);
        window.localStorage.setItem('userProfileCoverImage', resp?.data?.user_profile?.coverImg);
        Navigate("/mainfeed");
      }
    }
    catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error('Session Expired! Please login again.');
          localStorage.clear();
          Navigate('/signin');
        } else {
          ToastMessage.Error(e.response.data.message);
        }

      }
    }
  };





  return (
    <>
      <Helmet>
          <title> {userRoleType === 'child' ? "" : userRoleType === 'student' ? "Academics" : "Experience" }</title>
      </Helmet>
      <TopHeader />
      <div className="container-fluid bg-f5f5f5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            <div className="card  bg-f5f5f5">
              <Welcome />

              <div id="msform">

                <ul id="progressbar" className="text-center" style={{ '--value': '20%' }}>
                  <li className="active" id="personal"><strong>Interests</strong></li>
                  <li className="active" id="personal"><strong>Basic Details</strong></li>
                  <li className="active" id="payment"><strong>Image Upload</strong></li>
                  <li className="active" id="account"><strong>Verification</strong></li>
                  {userRoleType === 'child' ? "" : userRoleType === 'student' ? (
                    <li className="active" id="confirm"><strong>Academics</strong></li>
                  ) :
                    <li className="active" id="confirm"><strong>Experience</strong></li>
                  }
                </ul>
                <div className="progress">
                  <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100">100 %</div>
                </div>
                <br />
              </div>
            </div>
            <fieldset>
              {user?.usersType === "Student" ?
                <>

                  <div className="row">
                    <div className="col-7">
                      <h2 className="fs-title">Add Academics:</h2>
                    </div>
                    <div className="col-5">
                      <h2 className="steps">Step 5 - 5</h2>
                    </div>
                  </div>
                  <AcademicsScreen />

                  <div className="d-flex justify-content-between">
                    <Link to={"/complete-profile-step3" + "/" + userId} className="previous btn" >
                      Previous
                    </Link>
                    <button type="submit" className='btn next' onClick={handleNext}>Complete Setup</button>
                  </div>

                </>
                :
                <>

                  <div className="row">
                    <div className="col-7">
                      <h2 className="fs-title">Add Professional Experiences:</h2>
                    </div>
                    <div className="col-5">
                      <h2 className="steps">Step 5 - 5</h2>
                    </div>
                  </div>
                  <Experience />


                  <div className="d-flex justify-content-between">
                    <Link to={"/complete-profile-step3" + "/" + userId} className="previous btn" >
                      Previous
                    </Link>
                    <button type="submit" className='btn next' onClick={handleNext}>Complete Setup</button>
                  </div>

                </>
              }
            </fieldset>

          </div>
        </div>
      </div>
    </>

  )
}
