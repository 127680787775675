import React, { useState, useEffect, useRef } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import ProfileNavigation from "../../Components/ProfileNavigation";
import PostShare from "../../Components/PostShare/PostShare";
import Profile from "../ProfileAbout/Profile";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import Post from "../../Components/Post/Post";
import BlogCard from "../../Components/BlogCard/BlogCard";
import PostLoader from "../../Components/PostLoader/PostLoader";
import { Link, useNavigate, useParams } from "react-router-dom";
import SharedPost from "../../Components/SharedPost/SharedPost";
import ToastMessage from "../../Utils/ToastMessage";
import SharedBlogCard from "../../Components/SharedBlogCard/SharedBlogCard";
import SharedMedia from "../../Components/SharedMedia/SharedMedia";
import "./timeline.css";
import LinkPost from "../../Components/LinkPost/LinkPost";
import ProfileIntro from "../ProfileAbout/ProfileIntro";
import Photo from "../ProfileTimeline/Photo";
import Video from "../ProfileTimeline/Video";
import { Helmet } from "react-helmet";
function SavePost() {
  const param = useParams();
  const [timelinePosts, setTimelinePosts] = useState([]);
  const [mouserHovering, setMouserHovering] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [postPage, setPostPage] = useState(1);
  const [profileTagline, setProfileTagline] = useState("");
  const [statistics, setStatistics] = useState("");
  const [sorting, setSorting] = useState("");
  const [ActivityFeed, setActivityFeed] = useState([]);
  const userId = localStorage.getItem("userId");
  const listInnerRef = useRef();
  const history = useNavigate();
  const handleScroll = (e) => {
    if (loading) return;
    const scrollTop = listInnerRef.current.scrollTop;
    const offsetHeight = listInnerRef.current.offsetHeight;
    const scrollHeight = listInnerRef.current.scrollHeight;
    const remaining = scrollHeight - offsetHeight - scrollTop;

    if (remaining < 100) {
      setLoading(true);
      fetchNext();
    }
  };
  const fetchNext = () => {
    setTimeout(() => {
      setPostPage(postPage + 1);
    }, 100);
  };

  useEffect(() => {
    getUserPosts();
  }, [postPage, param?.id]);

  const getUserPosts = async (createPage = null) => {
    if (!hasMorePosts) return;
    if (sorting) {
      try {
        let resp = await CommonAPi(
          Endpoints.GetSavedPost + `?page=${postPage}` + "&scope=" + sorting
        );
        if (resp && resp.success === true) {
          setTimelinePosts([...timelinePosts, ...resp.data]);
          setLoading(false);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            history("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      try {
        let resp = await CommonAPi(
          Endpoints.GetSavedPost + `?page=${postPage}`
        );
        if (resp && resp.success === true) {
          setTimelinePosts([...timelinePosts, ...resp.data]);
          setLoading(false);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            history("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  const handleSorting = async (value) => {
    setSorting(value);
    try {
      let resp = await CommonAPi(Endpoints.GetSavedPost + "?scope=" + value);
      if (resp && resp.success === true) {
        setTimelinePosts(resp.data);
        setLoading(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  useEffect(() => {
    getStatistics();
    getActivity();
  }, [param?.id]);

  const getStatistics = async () => {
    try {
      let resp = await CommonAPi(
        Endpoints.ProfileStatistics + "?userId=" + param?.id
      );
      if (resp && resp.success === true) {
        setStatistics(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const getActivity = async () => {
    try {
      let resp = await CommonAPi(
        Endpoints.FeedActivity + "?userId=" + param?.id
      );
      if (resp && resp.success === true) {
        console.log(resp);
        setActivityFeed(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  useEffect(() => {
    getUserPosts();
  }, [param?.id]);

  const showReactionsBox = () => {
    setMouserHovering(true);
  };
  const hideReactionsBox = () => {
    setTimeout(() => {
      setMouserHovering(false);
    }, 1400);
  };
  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "y";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + "d";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + "h";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min";
    return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec" : "0 sec";
  };
  function formatDateTime(postDate) {
    let date = new Date(postDate);
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    let formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }
  return (
    <Container header>
      <Helmet>
        <title>Save Post</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main" onScroll={handleScroll} ref={listInnerRef}>
          <LeftNavigation />

          <div className="container py-lg-3 py-3">
            <div className="profile-banner-top mb-3  rounded-15 overflow-hidden">
              <Profile
                {...{
                  profileTagline: profileTagline,
                }}
              />
              <ProfileNavigation />
            </div>

            <div className="row">
              <div className="col-md-3 profile-dp-left pb-3">
                <div className="sidebar-widget box-shadow mb-3">
                  <div className="pb-3 d-flex align-items-start">
                    <span>
                      <img
                        src="../assets/img/icon/icon-emplyee.svg"
                        alt=""
                        width="36"
                        height="36"
                      />
                    </span>
                    <div className="ps-2">
                      <h4 className="medium">{statistics?.connection}</h4>
                      <h6 className="fs-12 text-darkgray regular">
                        Connections
                      </h6>
                    </div>
                  </div>

                  <div className="pb-3 d-flex align-items-start">
                    <span>
                      <img
                        src="../assets/img/icon/icon-jobs-created.svg"
                        alt=""
                        width="36"
                        height="36"
                      />
                    </span>
                    <div className="ps-2">
                      <h4 className="medium">{statistics?.hornor}</h4>
                      <h6 className="fs-12 text-darkgray regular">Honors</h6>
                    </div>
                  </div>

                  <div className="d-flex align-items-start">
                    <span>
                      <img
                        src="../assets/img/icon/icon-followers.svg"
                        alt=""
                        width="36"
                        height="36"
                      />
                    </span>
                    <div className="ps-2">
                      <h4 className="medium">{statistics?.awards}</h4>
                      <h6 className="fs-12 text-darkgray regular">Awards</h6>
                    </div>
                  </div>
                </div>

                <ProfileIntro
                  {...{
                    type: "timeline",
                    setProfileTagline: setProfileTagline,
                  }}
                />

                {ActivityFeed.length > 0 && (
                  <div className="sidebar-widget box-shadow mt-3 profile-text">
                    <div className="border-bottom pb-2 mb-3">
                      <h4 className="medium ">Activity Feed</h4>
                    </div>
                    {ActivityFeed.map((item, index) => {
                      return (
                        <div
                          className="d-flex align-items-start gap-2 pb-3"
                          key={index}
                        >
                          {/* <Link to={"/timeline/" + item?.postOwnerUsername}> */}
                          {item.ProfileImage === null ? (
                            <img
                              src="../assets/img/picture.png"
                              height="48"
                              width="48"
                              className="rounded-circle"
                              alt=""
                            />
                          ) : (
                            <img
                              src={Endpoints.baseUrl + item.ProfileImage}
                              height="48"
                              width="48"
                              className="rounded-circle"
                              alt=""
                            />
                          )}
                          {/* </Link> */}
                          <div>
                            <h6 className="fs-14 text-darkgray regular">
                              {userId == item?.userId ? "You" : item?.username}{" "}
                              {item?.TYPE == "comment"
                                ? "commented on"
                                : item?.TYPE == "reaction" &&
                                  item?.reactionType != "Like"
                                ? "reacted to"
                                : "liked"}{" "}
                              <Link to={"/timeline/" + item?.postOwnerUsername}>
                                <span className="text-brown bold">
                                  {item?.postOwner}'s
                                </span>
                              </Link>{" "}
                              <Link to={"/post-detail/" + item?.postId}>
                                post
                              </Link>
                            </h6>
                            <h6 className="text-mediumgray regular fs-10">
                              {timeSince(new Date(item.createdAt))}
                            </h6>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              <div className="col-md-6 feed-mid-column1 pb-3">
                <div class="px-4 py-4 bg-white mb-3 rounded-15 shadow-md">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4 class="medium fs-20">Saved Posts</h4>

                    <div class="">
                      <select
                        name=""
                        id=""
                        class=" w-auto px-3 rounded-pill bg-eee text-black py-2 posts-profile-select"
                        onChange={(e) => {
                          handleSorting(e.target.value);
                        }}
                      >
                        <option value="All">All</option>
                        <option value="Posts">Posts</option>
                        <option value="Photos">Photos</option>
                        <option value="Videos">Videos</option>
                        <option value="Pages">Pages</option>
                        <option value="Groups">Groups</option>
                        {/* <option value="Links">Links</option> */}
                      </select>
                    </div>
                  </div>
                </div>

                <ul className="timeline-feed feed-list">
                  {timelinePosts.length === 0 && !loading && (
                    <div className="text-center">
                      <p>No Saved Post Found</p>
                    </div>
                  )}
                  {timelinePosts.map((post, i) => {
                    console.log({ post });
                    if (
                      post?.user_post?.category === "Blog" &&
                      post?.user_post?.isShared === 0 &&
                      post?.user_post?.isMediaShared === 0
                    ) {
                      console.log("inside first logic");
                      return (
                        <BlogCard
                          post={post?.user_post}
                          key={post.postId}
                          getUserPosts={getUserPosts}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          formatDateTime={formatDateTime}
                        />
                      );
                    } else if (
                      post?.user_post?.isShared &&
                      post?.user_post?.isMediaShared === 0 &&
                      post?.user_post?.category !== "Blog"
                    ) {
                      console.log("inside second logic");
                      return (
                        <SharedPost
                          post={post?.user_post}
                          key={post.postId}
                          getUserPosts={getUserPosts}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          formatDateTime={formatDateTime}
                        />
                      );
                    } else if (
                      post?.user_post?.isShared &&
                      post?.user_post?.isMediaShared &&
                      post?.user_post?.category !== "Blog"
                    ) {
                      console.log("inside third logic");
                      return (
                        <SharedMedia
                          post={post?.user_post}
                          key={post.postId}
                          getUserPosts={getUserPosts}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          formatDateTime={formatDateTime}
                        />
                      );
                    } else if (
                      post?.user_post?.isShared === 0 &&
                      post?.user_post?.isMediaShared === 0 &&
                      post?.user_post?.category !== "Blog" &&
                      post?.user_post?.category !== "Link"
                    ) {
                      // console.log("inside fourth logic");
                      return (
                        <Post
                          post={post?.user_post}
                          key={post.postId}
                          getUserPosts={getUserPosts}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          formatDateTime={formatDateTime}
                        />
                      );
                    } else if (post?.user_post?.category === "Link") {
                      console.log("inside fifth logic");
                      return (
                        <LinkPost
                          post={post?.user_post}
                          key={post.postId}
                          getUserPosts={getUserPosts}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          setTimelinePosts={setTimelinePosts}
                          setPostPage={setPostPage}
                          postPage={postPage}
                          formatDateTime={formatDateTime}
                          listInnerRef={listInnerRef}
                        />
                      );
                    } else if (
                      post?.user_post?.category === "Blog" &&
                      post?.user_post?.isShared !== 0
                    ) {
                      console.log("inside sixth logic");
                      return (
                        <SharedBlogCard
                          post={post?.user_post}
                          key={post.postId}
                          getUserPosts={getUserPosts}
                          mouserHovering={mouserHovering}
                          showReactionsBox={showReactionsBox}
                          hideReactionsBox={hideReactionsBox}
                          timeSince={timeSince}
                          formatDateTime={formatDateTime}
                        />
                      );
                    }
                  })}
                  {loading === true && hasMorePosts === true && <PostLoader />}
                </ul>
              </div>

              <div className="col-md-3 profile-dp-right">
                <Photo />
                <Video />
              </div>
            </div>
          </div>

          {/* <?php include 'include/online-friends.php'; ?> */}
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}

export default SavePost;
