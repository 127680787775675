import React, { useEffect, useState } from "react";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Profile from "../Teacher/Profile";
import { Endpoints } from "../../../API/Endpoints";
import { FetchApi } from "../../../API/FetchApi";
import { CommonAPi } from "../../../API/CommonApi";
import { DeleteApi } from "../../../API/DeleteApi";
import ToastMessage from "../../../Utils/ToastMessage";
import { Link, useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { Helmet } from "react-helmet";

export default function Wishlist() {
  const [wishlist, setWishlist] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sorting, setSorting] = useState("");
  const [limit, setLimit] = useState(8);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  useEffect(() => {
    getWishlist();
  }, []);

  const getWishlist = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetWishlist + "?" + "size=" + limit);
      if (resp && resp.success === true) {
        setWishlist(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleLoadMore = async () => {
    setLoading(true);
    const NewLimit = parseInt(limit) + 12;
    setLimit(NewLimit);

    let url = Endpoints.GetWishlist + "?size=" + NewLimit;
    let navigateUrl = "/student-wishlist?size=" + NewLimit;

    if (searchKeyword) {
      url = url + "&keyword=" + searchKeyword;
      navigateUrl = navigateUrl + "&keyword=" + searchKeyword;
    }

    if (sorting) {
      url = url + "&sort=" + sorting;
      navigateUrl = navigateUrl + "&sort=" + sorting;
    }

    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleSearch = (value) => {
    let url = Endpoints.GetWishlist + "?size=" + limit;
    let navigateUrl = "/student-wishlist?size=" + limit;
    setSearchKeyword(value);

    if (value) {
      url = url + "&keyword=" + value;
      navigateUrl = navigateUrl + "&keyword=" + value;
    }

    if (sorting) {
      url = url + "&sort=" + sorting;
      navigateUrl = navigateUrl + "&sort=" + sorting;
    }

    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleSorting = (value) => {
    setSorting(value);
    let url = Endpoints.GetWishlist + "?size=" + limit;
    let navigateUrl = "/student-wishlist?size=" + limit;

    if (searchKeyword) {
      url = url + "&keyword=" + searchKeyword;
      navigateUrl = navigateUrl + "&keyword=" + searchKeyword;
    }

    if (value) {
      url = url + "&sort=" + value;
      navigateUrl = navigateUrl + "&sort=" + value;
    }

    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleCourseFilterData = async (url) => {
    try {
      let resp = await FetchApi(url);
      if (resp && resp.success === true) {
        setLoading(false);
        setWishlist(resp.data);
      } else {
        setLoading(false);
        setWishlist([]);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        ToastMessage.Error(e.response.data.message);
        setLoading(false);
        // setLoadStatus(false);
      }
    }
  };

  const handleAddToCart = async (Id) => {
    const obj = {
      course_id: Id,
    };

    try {
      let resp = await CommonAPi(Endpoints.AddToCart, obj);
      if (resp && resp.message) {
        navigate("/cart");
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          getWishlist();
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleRemoveWishlist = async (Id, categoryId) => {
    try {
      let resp = await DeleteApi(Endpoints.RemoveToWishlist + "/" + Id);
      if (resp && resp.message) {
        getWishlist();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Wishlist</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-3">
            <Profile type="student" active={"wishlist"} />

            <div className="bg-white main-common">
              <div className="main-top-row d-md-flex justify-content-between align-items-center pb-3">
                <h3 className="medium">My Wishlist</h3>

                <div className="d-sm-flex gap-3 ">
                  <form
                    action=""
                    class="mainmenu-page-search d-flex flex-row-reverse align-items-center"
                  >
                    <input
                      type="text"
                      placeholder="Search for course title"
                      name="search"
                      class="bg-transparent fs-14 medium  text-darkgray"
                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                    />
                    <button type="submit" class="bg-transparent border-0">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="#949494"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        class="css-i6dzq1"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </button>
                  </form>
                  <select
                    name="sortby"
                    id="sortby"
                    class="text-darkgray"
                    onChange={(e) => {
                      handleSorting(e.target.value);
                    }}
                  >
                    <option value="">Sort by</option>
                    <option value="a-z">Ascending</option>
                    <option value="z-a">Descending</option>
                    <option value="latest">latest</option>
                    <option value="old">last</option>
                  </select>
                </div>
              </div>

              {wishlist.length > 0 ? (
                <div className="row mainmenu-filter-conatiner">
                  {wishlist.map((item, index) => {
                    return (
                      <div
                        className="col-lg-3 col-md-4 col-sm-6 mb-4"
                        key={index}
                      >
                        <div className="course-item mainmenu-page-item">
                          <div className="mainmenu-item-action position-absolute end-0 top-0 m-1 p-1">
                            <a
                              href="#"
                              onClick={() => {
                                handleRemoveWishlist(item.id);
                              }}
                              aria-haspopup="true"
                              aria-label="teacher_1"
                              data-bs-toggle="dropdown"
                              className="d-flex align-items-center justify-content-center rounded-circle bg-eee p-2"
                            >
                              <img
                                src="assets/img/icon/icon-wishlist-fill.svg"
                                width="16"
                                alt=""
                              />
                            </a>
                            <ul
                              className="popup-menu shadow rounded-1 text-start"
                              role="menu"
                              id="teacher_1"
                              aria-hidden="true"
                              tabindex="-1"
                            >
                              <li>
                                <a className="dropdown-item" href="#">
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="15"
                                    height="15"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="css-i6dzq1"
                                  >
                                    <polyline points="20 6 9 17 4 12"></polyline>
                                  </svg>
                                  Add
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="15"
                                    height="15"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="css-i6dzq1"
                                  >
                                    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                    <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                  </svg>
                                  Delete
                                </a>
                              </li>
                            </ul>
                          </div>
                          <Link to={"/course-detail/" + item?.course?.slug}>
                            {item?.course?.thumbnail === null ||
                            item?.course?.thumbnail === "undefined" ? (
                              <img
                                src="assets/img/course.png"
                                alt=""
                                className="course-img course-image w-100"
                              />
                            ) : (
                              <img
                                src={
                                  Endpoints.baseUrl + item?.course?.thumbnail
                                }
                                alt=""
                                className="course-img course-image w-100"
                              />
                            )}
                          </Link>
                          <div className="p-2">
                            <div className="d-flex align-items-center justify-content-between ">
                              <span className="bg-lightbrown text-brown fs-12 medium py-1 px-3 rounded-pill">
                                {item.course.course_category.name}
                              </span>
                            </div>
                            <Link to={"/course-detail/" + item?.course?.slug}>
                              <h5 className="medium py-2 px-0">
                                {item?.course?.title?.length <= 22
                                  ? item?.course?.title
                                  : item?.course?.title.substr(0, 22) + "..."}
                              </h5>
                            </Link>

                            <div className="d-flex gap-1 align-items-center py-1">
                              {item.course.creatorId.user_profile
                                ?.profileImg === null ? (
                                <img
                                  src="assets/img/profile.png"
                                  alt=""
                                  width="16"
                                  height="16"
                                  className="rounded-circle"
                                />
                              ) : (
                                <img
                                  src={
                                    Endpoints.baseUrl +
                                    item.course.creatorId.user_profile
                                      ?.profileImg
                                  }
                                  alt=""
                                  width="16"
                                  height="16"
                                  className="rounded-circle"
                                />
                              )}
                              <span className="fs-12 text-brown">
                                {" "}
                                {item.course.creatorId.fullName}
                              </span>
                            </div>

                            <div className="d-flex justify-content-start py-2 align-items-center">
                              <span className="fs-12 medium text-gray mt-1">
                                {item.course.avgRating !== null
                                  ? item.course.avgRating
                                  : 0}
                              </span>
                              <div className="d-flex mx-2">
                                {item.course.avgRating !== null ? (
                                  <ReactStars
                                    count={5}
                                    edit={false}
                                    value={item.course.avgRating}
                                    size={18}
                                    isHalf={true}
                                    char={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        alt=""
                                      />
                                    }
                                    emptyIcon={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        alt=""
                                      />
                                    }
                                    halfIcon={
                                      <img
                                        src="../assets/img/icon/state-half.svg"
                                        alt=""
                                      />
                                    }
                                    filledIcon={
                                      <img
                                        src="../assets/img/icon/State-fill.svg"
                                        alt=""
                                      />
                                    }
                                    activeColor="#ffd700"
                                    activeBackgroundColor="#ffd700"
                                  />
                                ) : (
                                  <ReactStars
                                    count={5}
                                    edit={false}
                                    value={0}
                                    size={18}
                                    isHalf={true}
                                    char={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        alt=""
                                      />
                                    }
                                    emptyIcon={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        alt=""
                                      />
                                    }
                                    halfIcon={
                                      <img
                                        src="../assets/img/icon/state-half.svg"
                                        alt=""
                                      />
                                    }
                                    filledIcon={
                                      <img
                                        src="../assets/img/icon/State-fill.svg"
                                        alt=""
                                      />
                                    }
                                    activeColor="#ffd700"
                                    activeBackgroundColor="#ffd700"
                                  />
                                )}
                              </div>
                              <span className="fs-10 medium text-lightgray mt-1">
                                ({item.course.totalRatings})
                              </span>
                            </div>
                          </div>
                          <div className="p-2 border-top">
                            <div className="text-center pb-2">
                              <div className=" course-price pb-2 medium fs-18">
                                {item.course.discountedPrice > 0 ? (
                                  <ins className="text-blue">
                                    ₹ {item.course.discountedPrice}
                                  </ins>
                                ) : (
                                  <ins className="text-blue">
                                    ₹ {item.course.price}
                                  </ins>
                                )}
                                {item.course.price > 0 ? (
                                  <del className="text-lightgray">
                                    ₹ {item.course.price}
                                  </del>
                                ) : (
                                  ""
                                )}
                              </div>
                              {userId == item?.course.creatorId.id ? (
                                ""
                              ) : (
                                <a
                                  onClick={() => {
                                    handleAddToCart(item.courseId);
                                  }}
                                  className="btn fs-14 w-100 mw-100"
                                >
                                  <img
                                    src="assets/img/icon/icon-cart-white.svg"
                                    className="pe-2"
                                  />
                                  Add To Cart
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div class="row mainmenu-filter-conatiner">
                  No wishlist found
                </div>
              )}

              {wishlist.length > 8 ? (
                <div class="text-center">
                  <Link
                    href="#"
                    class="text-blue"
                    onClick={() => handleLoadMore()}
                  >
                    {loading ? (
                      <img src="../assets/img/icon/icon-loading.svg" alt="" />
                    ) : (
                      "Load More Courses "
                    )}
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
