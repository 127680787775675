import React, {useEffect, useState} from 'react'
import ToastMessage from "../../Utils/ToastMessage";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import { FetchApi } from '../../API/FetchApi'
import { Link, useNavigate } from "react-router-dom";
export default function TopEmployee() {

    const navigate = useNavigate();
    const [topEmployer, setTopEmployer] = useState([]);
    useEffect(() => {
        handleGetEmployer();
    }, []);

    const handleGetEmployer = async () => {
        try {
            let resp = await FetchApi(Endpoints.TopEmployee);
            if (resp && resp.success === true) {
                if (resp.data) {
                    setTopEmployer(resp?.data);
                   
                }
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {

                    ToastMessage.Error('Session Expired! Please login again.')
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message)
                }

            }
        }
    }

    return (
        <div class="bg-white main-common mt-4">
            <div class="row py-4 align-items-center">
                <div class="col-md-5 pb-md-0 pb-4">
                    <h3 class="bold pb-3">Top Employers</h3>
                    <h5 class="medium col-lg-8 px-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h5>
                </div>

                <div class="col-md-7">

                    <div class="row">
                        {topEmployer.map((item, index)=>{
                            return (
                                <div class="col-lg-3 col-md-4 col-6 mb-2" key={index}>
                                <div>
                                <Link to={"/page-profile/" + item?.slug}  class="job-employee-item job-top-employee-item p-2 text-center">
                                    {item?.pageImage === null ? 
                                    <img src="assets/img/joblogo1.svg" alt="" className="job-top-employee-image" />
                                    :
                                    <img src={Endpoints.baseUrl + item?.pageImage} alt="" className="job-top-employee-image"  />
                                    }
                                </Link>
                                </div>
                            </div>
                            )
                        })}
                       

                       
                    </div>

                </div>
            </div>
        </div>
    )
}
