import React from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import PageTab from "./PageTab";
import { Helmet } from "react-helmet";
function SchoolsMenu() {

    return (
        <Container header>
            <Helmet>
                <title>Schools</title>
            </Helmet>
            <div className="wrapper wrapper-bg">

                <div className="main">
                    <LeftNavigation />
                    <div className="container py-3">
                        <PageTab {...{
                            ActivePage: "School"
                        }} />
                    </div>
                    <OnlineFriends />
                </div>


            </div>
        </Container>
    )
}

export default SchoolsMenu;