import React, { useEffect } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import PageTab from "../SchoolsMenu/PageTab";
import { Helmet } from "react-helmet";

function CompanyMenu() {

    return (
        <Container
            header
        >
            <Helmet>
                <title>Company</title>
            </Helmet>
            <div className="wrapper wrapper-bg">

                <div className="main">
                    <LeftNavigation />
                    <div className="container py-3">
                        <PageTab
                            {...{
                                ActivePage: "Company"
                            }}
                        />
                    </div>
                    <OnlineFriends />
                </div>

            </div>
        </Container>
    )
}

export default CompanyMenu;