import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import InterestsModel from './InterestsModel';
import { FetchApi } from '../../API/FetchApi';
import { DeleteApi } from '../../API/DeleteApi';
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import ToastMessage from "../../Utils/ToastMessage";
import DeletePrompt from "../../Components/DeletePrompt/DeletePrompt"

export default function Interests({page}) {
    const location = useLocation();
    const userId = localStorage.getItem("userId");
    const username = localStorage.getItem("userName");
    const [interestData, setInterestData] = useState([]);
    const [totalInterest, setTotalInterest] = useState(0);
    const [item, setItem] = useState(null);
    const Navigate = useNavigate();
    const param = useParams();
   

    const getInterest = async () => {
        try {
            let resp = await FetchApi(Endpoints.GetInterests+ '?id='+param?.id);
            if (resp && resp.success === true) {
                // console.log("Caling>>>>>>>>>>>>>>>>>>>>>>>>>")
                setInterestData([...resp.data])
                // let TodatIntresetData = 0;

                // const newData = resp.data.map((item, index) => {
                //     return (
                //         TodatIntresetData = parseInt(TodatIntresetData) + parseInt(item.interests.length)
                //     )
                // })
                setTotalInterest(resp?.data.length);

            }
            if(resp && resp.success===false){
                setInterestData([])
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };

    useEffect(() => {
        getInterest();
    }, [param.id])
   
    const notAddedInterest = () => {
        ToastMessage.Info("Please add the interest");
    };



    const handleDeleteInterest = async (id) => {
        try {
            let resp = await DeleteApi(Endpoints.DeleteInterests + '/' + id);
            if (resp && resp.success === true) {
                ToastMessage.Success(resp.message)
                getInterest();
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };

    const handleFiveInterest = () => {
        ToastMessage.Info("Please add at least 5 interests");
    };

    return (
        <div className="interests-section bg-white p-3 rounded-15 mb-4">
            <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-4">
                <h4 className="medium fs-18 text-black">
                   {
                    page != "profile" ?
                       (  totalInterest == 0 ? 'Choose at least 5 interests to proceed' : (totalInterest < 5 ? `Choose ${5 - totalInterest} more interests to proceed` : 'Add more Interests or click on Next to proceed'))
                       : (
                        "My Interests"
                       )
                   }

                </h4>
                {username === param?.id  || userId === param?.id ?
                <Link to="#" data-bs-toggle="modal" data-bs-target="#Interestsmodal" className="text-brown fs-12 medium">Add <img src="../assets/img/icon/edit-plus.svg" alt="" width="12" /></Link>
                :""}
                <InterestsModel
                    {...{
                        onSuccess: getInterest,
                    }}
                    totalInterest={totalInterest}
                />
            </div>
            {interestData?.length > 0 ?
                <div className="inner-section">

                    {interestData?.map((item, index) => {
                        return (            
                            <span className="medium fs-14 mb-1 d-inline-block" key={item.id}>{item?.interestTitle}
                                 {username === param?.id ?
                                <Link to="#" 
                                    onClick={()=>{setItem(item.id)}}
                                            data-bs-toggle="modal" data-bs-target="#deleteInfoModal-Interest"
                                ><img src="../assets/img/icon/wrong.svg" className="ms-2" alt="" width="10" />
                                </Link>
                                :""}
                            </span>                                    
                        );
                    })}

                    <DeletePrompt
                        type={"Interest"} 
                        deleteType={handleDeleteInterest}
                        item={item}
                    />


                    {location.pathname.split('/')[1] === 'select-interests' ?
                        <div className="text-end">
                            {totalInterest >= 5 ?
                                <Link to={"/complete-profile-step1/" + userId} className="btn blue-btn">Next</Link>
                                :
                                <Link onClick={handleFiveInterest} className="btn blue-btn">Next</Link>
                            }
                        </div >
                        : ""}
                </div >
                :
                <div className="inner-section">
                    No interests Found, please click on add button to add interests
                </div>
            }
        </div >
    )
}
