import Editor from "@draft-js-plugins/editor";
import "@draft-js-plugins/emoji/lib/plugin.css";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import "@draft-js-plugins/linkify/lib/plugin.css";
import createMentionPlugin from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import { ContentState, EditorState, convertFromRaw } from "draft-js";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import createEmojiPlugin, { defaultTheme } from "@draft-js-plugins/emoji";
import { CustomHastagComp } from "../PostShare/PostShare";

export default function PostTitleDraftView({ title }) {
  const editorRef = useRef(null);

  const [isTruncated, setIsTrancated] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const [editorState, setEditorState] = useState(() => {
    if (title) {
      if (title.startsWith("{")) {
        return EditorState.createWithContent(convertFromRaw(JSON.parse(title)));
      }
      return EditorState.createWithContent(ContentState.createFromText(title));
    }
    return EditorState.createEmpty();
  });

  const { plugins,  
    EmojiSuggestions,
    EmojiSelect,  } = useMemo(() => {
    // @ mention
    const mentionPlugin = createMentionPlugin({
      mentionPrefix: "@",
    });
    // # hashtag
    const hashtagPlugin = createMentionPlugin({
      mentionTrigger: ["#"],
      mentionComponent: CustomHastagComp,
    });

    const linkify = createLinkifyPlugin({
      target: "_blank",
    });


    const emojiPlugin = createEmojiPlugin({
      theme: {
        ...defaultTheme,
        emojiSelect: "custom-emoji-select",
        emojiSelectButton: "custom-emoji-select-button",
        emojiSelectPopover:
          defaultTheme.emojiSelectPopover + " custom-emoji-select-popover",
      },
      selectButtonContent: (
        <>
          <img
            className="post-image"
            src="../assets/img/icon/emoji_icon.png"
            alt=""
          />
          Emoji
        </>
      ),
    });
    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin, emojiPlugin, hashtagPlugin, linkify];
    // eslint-disable-next-line no-shadow
    // const plugins = [mentionPlugin, hashtagPlugin, linkify];
    return {
      plugins,
    };
  }, []);

  const handleShowToggle = useCallback(() => {
    setShowAll((currentValue) => !currentValue);
  }, []);

  useEffect(() => {
    const rect = editorRef.current
      ?.getEditorRef()
      .editorContainer.getBoundingClientRect();

    if (rect?.height > 160) {
      setIsTrancated(true);
    }
  }, [editorState]);

  return (
    <>
      <div style={{ maxHeight: showAll ? undefined : 160, overflow: "hidden" }}>
        <Editor
          ref={editorRef}
          readOnly
          plugins={plugins}
          editorState={editorState}
          onChange={(newState) => setEditorState(newState)}
        />
      </div>
      {isTruncated && (
        <button
          style={{
            border: "none",
            padding: 0,
            margin: 0,
            background: "transparent",
          }}
          className="text-primary pe-auto"
          onClick={handleShowToggle}
        >
          {showAll ? "Read Less" : "... Read More"}
        </button>
      )}
    </>
  );
}
