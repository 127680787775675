import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { JobReportReson } from "../../constants/JobReportReson";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import Swal from "sweetalert2";
const $ = window.jQuery;
export default function ReportJobsModel({JobId, onSuccess}) {
    const [reason, setReason] = useState('');
    const navigate = useNavigate();

    const handleCheckReason = (item) =>{
        setReason(item);
    };

    const handleSave = async() =>{
        let data = {
            jobId:JobId,
            description:reason?.description,
            heading:reason?.title
        }
        try {
            let resp = await CommonAPi(Endpoints.JobReport, data);
            if (resp && resp.success === true) {
                $("#exampleModal").modal('hide');
                setReason("");
                onSuccess(true);
                ToastMessage.Report(resp.message);
             
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }
    };

    const handleCloseModal = () => {
        if (reason ) {
          Swal.fire({
            text: "You have unsaved changes, do you still want to exit?",
            icon: "question",
            customClass: {
              confirmButton: "btn px-3 mx-2",
              cancelButton: "btn btn-br-blue px-3",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Yes, discard it!",
          }).then((result) => {
            console.log(result);
            if (result.isConfirmed) {
                setReason("")
                $("#exampleModal").modal('hide');
            }else{
                $("#exampleModal").modal('show');
            }
          });
        } else {
            $("#exampleModal").modal('hide');
          
        }
      };

    return (
        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title fw-bold" id="exampleModalLabel">Report JOB/Intership</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{handleCloseModal()}}></button>
                    </div>
                    <div className="modal-body report">
                        <h4 className="px-3 mt-2">Please select the reason</h4>
                        {JobReportReson.map((item, index) => {
                            return (
                                <div className="d-flex justify-content-between report-box mt-2 px-3 py-1" key={index}>
                                    <Link 
                                     onClick={()=>{
                                        handleCheckReason(item)
                                    }} 
                                    >
                                    <div className="job-report text-dark" >
                                        <h5>{item?.title}</h5>
                                        <p className="job-report-description">{item?.description}</p>
                                    </div>
                                    </Link>
                                    <div>
                                    {reason?.id == item?.id ?
                                        <img src="../assets/img/icon/hover-check-dark.svg" alt='' />
                                    :
                                        <input type="radio" name="report" 
                                        onChange={()=>{
                                            handleCheckReason(item)
                                        }}
                                        checked={reason?.id == item?.id ? true : false}
                                        />
                                    }
                                    </div>
                                </div>
                            )
                        })}


                        <div className="text-center mt-3 mb-3">
                            <button type="button" className="btn px-4 py-2 fs-14" onClick={handleSave}>Submit</button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}
