import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Topbar from "./Topbar";
import { UpdateAPi } from "../../../API/UpdateApi";
import { FetchApi } from "../../../API/FetchApi";
import { Endpoints } from "../../../API/Endpoints";
import ToastMessage from "../../../Utils/ToastMessage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Helmet } from "react-helmet";
import axios from "axios";
const $ = window.jQuery;
export default function AdvanceInformation() {
  const param = useParams();
  const navigate = useNavigate();
  const [objective, setObjective] = useState([
    {
      value: "",
    },
  ]);
  const [audience, setAudience] = useState([
    {
      value: "",
    },
  ]);
  const [requirements, setRequirements] = useState([
    {
      value: "",
    },
  ]);

  const [description, setDescription] = useState("");
  const [video, setVideo] = useState("");
  const [image, setImage] = useState("");

  const [videoUrl, setVideoUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [videoStatus, setVideoStatus] = useState("");
  const [imageStatus, setImageStatus] = useState("");

  const [videouploadStatus, setVideoUploadStatus] = useState(false);

  const [descriptionError, setDescriptionError] = useState("");
  // const [objectiveError, setObjectiveError] = useState('')
  const [objectiveErrorList, setObjectiveErrorList] = useState([]);
  // const [objectiveIndexError, setObjectiveIndexError] = useState('')
  // const [audienceError, setAudienceError] = useState('')
  // const [requirementsError, setRequirementsError] = useState('')
  // const [audienceIndexError, setAudienceIndexError] = useState('')
  const [audienceErrorList, setAudienceErrorList] = useState([]);
  const [requirementErrorList, setRequirementErrorList] = useState([]);
  // const [requirementsIndexError, setRequirementsIndexError] = useState('')
  const [videoError, setVideoError] = useState("");
  const [imageError, setImageError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [vControl, setVControl] = useState(true);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  useEffect(() => {
    if (
      description !== "" &&
      objective[0].value !== "" &&
      audience[0].value !== "" &&
      requirements[0].value !== "" &&
      imageUrl !== "" &&
      videoUrl !== ""
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    description,
    objective[0].value,
    audience[0].value,
    requirements[0].value,
    imageUrl,
    videoUrl,
  ]);
  useEffect(() => {
    getAdvanceInfo();
  }, []);

  const getAdvanceInfo = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.GetCourseAdvanceInformation + "/" + param?.id
      );
      if (resp && resp.success === true) {
        let objectiveData = resp?.data?.objective.map((item) => {
          return {
            value: item,
          };
        });
        setObjective(objectiveData);
        let audienceData = resp?.data?.audience.map((item) => {
          return {
            value: item,
          };
        });
        setAudience(audienceData);

        let requirementData = resp?.data?.requirement.map((item) => {
          return {
            value: item,
          };
        });
        setRequirements(requirementData);
        setDescription(resp?.data?.description);
        setVideoStatus("Get");
        setVideoUrl(resp?.data?.trailor);
        setImageStatus("Get");
        setImageUrl(resp?.data?.thumbnail);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const AddObjective = () => {
    let data = {
      value: "",
    };
    setObjective([...objective, data]);
  };

  const RemoveObjective = (index) => {
    if (objective.length > 1) {
      const newObjectiveData = objective.filter((item, i) => i !== index);
      setObjective(newObjectiveData);
    }
  };

  const AddAudience = () => {
    let data = {
      value: "",
    };
    setAudience([...audience, data]);
  };

  const RemoveAudience = (index) => {
    if (audience.length > 1) {
      const newAudienceData = audience.filter((item, i) => i !== index);
      setAudience(newAudienceData);
    }
  };

  const AddRequirements = () => {
    let data = {
      value: "",
    };
    setRequirements([...requirements, data]);
  };

  const RemoveRequirements = (index) => {
    if (requirements.length > 1) {
      const newRequirementsData = requirements.filter((item, i) => i !== index);
      setRequirements(newRequirementsData);
    }
  };

  const onchangeObjectiveInput = (val, index) => {
    let temp = objective.slice();
    temp[index].value = val.target.value;
    setObjective(temp);
  };

  const onchangeAudienceInput = (val, index) => {
    let temp = audience.slice();
    temp[index].value = val.target.value;
    setAudience(temp);
  };
  const onchangeRequirementsInput = (val, index) => {
    let temp = requirements.slice();
    temp[index].value = val.target.value;
    setRequirements(temp);
  };

  const handleUploadVideo = async (e) => {
    setVideoUploadStatus(true);
    setVideo(e.target.files[0]);
    setVideoStatus("upload");
    setVideoUrl(URL.createObjectURL(e.target.files[0]));

    const formData = new FormData();
    if (e.target.files[0]) {
      formData.append("trailor", e.target.files[0]);
    }
    try {
      let resp = await axios.put(
        Endpoints.baseUrl +
          Endpoints.UpdateCourseAdvanceInformationTrailer +
          "/" +
          param.id,
        formData,
        {
          headers: {
            profile: localStorage.getItem("userId"),
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadPercentage(percentage);
          },
        }
      );
      if (resp && resp?.data?.success) {
        getAdvanceInfo();
        setVideoUploadStatus(false);
        setUploadPercentage(0);
        console.log(resp);
        ToastMessage.Success(resp?.data?.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleUploadImage = async (e) => {
    setImage(e.target.files[0]);
    setImageStatus("upload");
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    if (!e.target.files[0]) {
      setImageError("Please enter course thumbnail");
    } else if (e.target.files[0]) {
      setImageError("");
    }
    if (e.target.files[0]) {
      const formData = new FormData();
      if (e.target.files[0]) {
        formData.append("thumbnail", e.target.files[0]);
      }
      try {
        let resp = await UpdateAPi(
          Endpoints.UpdateCourseAdvanceInformationThumbnail + "/" + param.id,
          formData
        );
        if (resp && resp.message) {
          getAdvanceInfo();
          ToastMessage.Success(resp.message);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const commonValidationPassed = () => {
    let validationPassed = true;

    if (!description) {
      setDescriptionError("Please enter course description");
      validationPassed = false;
    }
    if (objective.length > 0) {
      for (var i = 0; i < objective.length; i++) {
        if (objective[i].value === "") {
          let obError = {
            error: "Please enter what you’ll teach in this course",
            key: i,
          };
          setObjectiveErrorList((objectiveErrorList) => [
            ...objectiveErrorList.filter((item) => !(obError.key === item.key)),
            obError,
          ]);
          validationPassed = false;
        }
      }
    }
    if (audience.length > 0) {
      for (var i = 0; i < audience.length; i++) {
        if (audience[i].value === "") {
          let adError = { error: "Please enter Target Audience", key: i };
          setAudienceErrorList((audienceErrorList) => [
            ...audienceErrorList.filter((item) => !(adError.key === item.key)),
            adError,
          ]);
          validationPassed = false;
        }
      }
    }
    if (requirements.length > 0) {
      for (var i = 0; i < requirements.length; i++) {
        if (requirements[i].value === "") {
          let rqError = { error: "Please enter Course Requirements", key: i };
          setRequirementErrorList((requirementErrorList) => [
            ...requirementErrorList.filter((item) => rqError.key !== item.key),
            rqError,
          ]);
          validationPassed = false;
        }
      }
    }
    return validationPassed;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!commonValidationPassed()) {
      return false;
    }

    if (
      description &&
      objective.length > 0 &&
      audience.length > 0 &&
      requirements.length > 0
    ) {
      const objectiveData = objective.map((item) => {
        return item.value;
      });

      const audienceData = audience.map((item) => {
        return item.value;
      });

      const requirementsData = requirements.map((item) => {
        return item.value;
      });

      const formData = {
        description: description,
        objective: objectiveData,
        audience: audienceData,
        requirement: requirementsData,
      };
      try {
        let resp = await UpdateAPi(
          Endpoints.UpdateCourseAdvanceInformation + "/" + param.id,
          formData
        );
        if (resp && resp.message) {
          checkImageVideo();
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const checkImageVideo = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.GetCourseAdvanceInformation + "/" + param?.id
      );
      if (resp && resp.success === true) {
        if (resp?.data?.thumbnail === null) {
          setImageError("Please add Course Thumbnail");
        } else if (resp?.data?.trailor === null) {
          setVideoError("Please add Course Trailer");
        }

        if (resp?.data?.thumbnail !== null && resp?.data?.trailor !== null) {
          navigate("/add-courses-curriculum" + "/" + param.id);
          ToastMessage.Success(resp.message);
        }
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSave = async (type) => {
    if (!commonValidationPassed()) {
      return false;
    }

    if (
      description &&
      objective.length > 0 &&
      audience.length > 0 &&
      requirements.length > 0
    ) {
      const objectiveData = objective.map((item) => {
        return item.value;
      });

      const audienceData = audience.map((item) => {
        return item.value;
      });

      const requirementsData = requirements.map((item) => {
        return item.value;
      });

      const formData = {
        description: description,
        objective: objectiveData,
        audience: audienceData,
        requirement: requirementsData,
      };
      try {
        let resp = await UpdateAPi(
          Endpoints.UpdateCourseAdvanceInformation + "/" + param.id,
          formData
        );
        if (resp && resp.message) {
          if (type === "close") {
            navigate("/mycourses");
          }

          if (type === "preview") {
            window.open("/course-preview/" + param.id, "_blank");
          }
          ToastMessage.Success(resp.message);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleValidation = async (e, value, field, index) => {
    console.log(e);
    // e.preventDefault();
    // e.preventDefault;

    if (!value && field === "description") {
      setDescriptionError("Please enter course description");
    } else if (value && field === "description") {
      setDescriptionError("");
    }
    if (!value && field === "objective") {
      let obError = {
        error: "Please enter what you’ll teach in this course",
        key: index,
      };
      setObjectiveErrorList((objectiveErrorList) => [
        ...objectiveErrorList.filter((item) => !(obError.key === item.key)),
        obError,
      ]);
      // console.log(objectiveErrorList);
    } else if (value && field === "objective") {
      setObjectiveErrorList([]);
    }
    if (!value && field === "audience") {
      let adError = { error: "Please enter Target Audience", key: index };
      setAudienceErrorList((audienceErrorList) => [
        ...audienceErrorList.filter((item) => !(adError.key === item.key)),
        adError,
      ]);
    } else if (value && field === "audience") {
      setAudienceErrorList([]);
    }
    if (!value && field === "requirements") {
      let rqError = { error: "Please enter Course Requirements", key: index };
      setRequirementErrorList((requirementErrorList) => [
        ...requirementErrorList.filter((item) => !(rqError.key === item.key)),
        rqError,
      ]);
    } else if (value && field === "requirements") {
      setRequirementErrorList([]);
    }
    if (!value && field === "image") {
      setImageError("Please enter course thumbnail");
    } else if (value && field === "image") {
      setImageError("");
    }
    if (!value && field === "video") {
      setVideoError("Please enter course trailer");
    } else if (value && field === "video") {
      setVideoError("");
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Advance Information</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-3">
            <div className="bg-white main-common p-0 py-3">
              <Topbar />

              <div className="border-bottom py-lg-4 py-3 px-lg-4 px-3">
                <div className="d-sm-flex align-items-center justify-content-between">
                  <h2>Advance Information</h2>
                  <div className="save-close-btns">
                    <a
                      href="#"
                      onClick={() => {
                        handleSave("close");
                      }}
                      className="btn btn-br-blue py-2"
                    >
                      Save & Close
                    </a>
                    <a
                      href="#"
                      onClick={() => {
                        handleSave("preview");
                      }}
                      className="btn btn-br-blue ms-3 py-2"
                    >
                      Save & Preview
                    </a>
                  </div>
                </div>
              </div>

              <form
                onSubmit={handleSubmit}
                className="teacher-form generic-form"
              >
                <div className="border-bottom px-lg-4 px-3 pt-lg-4 pt-3 pb-2 ">
                  <div className="row">
                    <div className="col-md-6 pb-4">
                      <label
                        for=""
                        className="d-block pb-2 medium text-black fs-18"
                      >
                        Course Thumbnail
                      </label>
                      <div className="row align-items-center">
                        <div className="col-4 ">
                          {/* <a href="#"> */}
                          {imageUrl === null || imageUrl === "" ? (
                            <img
                              src="../assets/img/icon/course-thumb.svg"
                              className={imageError ? "is-danger" : ""}
                              alt=""
                            />
                          ) : imageStatus === "upload" ? (
                            <img src={imageUrl} alt="" />
                          ) : (
                            <img src={Endpoints.baseUrl + imageUrl} alt="" />
                          )}
                          {/* </a> */}
                        </div>
                        <div className="col-8">
                          <p className="text-gray fs-14">
                            Upload your course Thumbnail here. Important
                            guidelines: 1200x800 pixels or 12:8 Ratio. Supported
                            format: .jpg, .jpeg, or .png
                          </p>
                          <div>
                            <input
                              type="file"
                              onMouseOut={(e) => {
                                handleValidation(e, e.target.value, "image");
                              }}
                              id="upload_file"
                              className="d-none"
                              accept="image/*"
                              value=""
                              onChange={(e) => handleUploadImage(e)}
                            />

                            <label
                              for="upload_file"
                              className="btn btn-light-blue medium py-2 px-3"
                            >
                              Upload Image{" "}
                              <img
                                src="../assets/img/icon/icon-upload.svg"
                                className="ps-1"
                                alt=""
                              />
                            </label>
                          </div>
                        </div>
                        {imageError ? (
                          <div
                            className="form-error"
                            style={{
                              color: "white",
                              margintop: "-10px",
                              marginBottom: "-10px",
                            }}
                          >
                            {imageError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 pb-4">
                      <label
                        for=""
                        className="d-block pb-2 medium text-black fs-18"
                      >
                        Course Trailer
                      </label>
                      <div className="row align-items-center">
                        <div className="col-4">
                          {/* <a href="#"> */}
                          {/* <img src="../assets/img/icon/course-video.svg" alt="" /> */}
                          {videoUrl === null || videoUrl === "" ? (
                            <img
                              src="../assets/img/icon/course-video.svg"
                              className={videoError ? "is-danger" : ""}
                              alt=""
                            />
                          ) : videoStatus === "upload" ? (
                            <div className="position-relative">
                              <video
                                src={videoUrl}
                                controls={vControl}
                                style={{
                                  maxHeight: "400px",
                                  background: "rgba(0,0,0,0.6)",
                                }}
                                height={"auto"}
                                width="100%"
                                controlsList="nodownload"
                              ></video>
                              <div
                                className="position-absolute top-0 bottom-0 start-0 end-0"
                                onClick={() => {
                                  $("#CourseTrailerModal").modal("show");
                                  setVControl(false);
                                }}
                              />
                            </div>
                          ) : (
                            <div className="position-relative">
                              <video
                                controls={vControl}
                                style={{
                                  maxHeight: "400px",
                                  background: "rgba(0,0,0,0.6)",
                                }}
                                height={"auto"}
                                width="100%"
                                controlsList="nodownload"
                                src={Endpoints.baseUrl + videoUrl}
                              ></video>
                              <div
                                className="position-absolute top-0 bottom-0 start-0 end-0"
                                onClick={() => {
                                  $("#CourseTrailerModal").modal("show");
                                  setVControl(false);
                                }}
                              />
                            </div>
                          )}

                          {/* </a> */}
                        </div>
                        <div className="col-8">
                          <p className="text-gray fs-14">
                            Students who watch a well-made promo video are 5X
                            more likely to enroll in your course. We've seen
                            that statistic go up to 10X for exceptionally
                            awesome videos.
                          </p>

                          {/* {uploadPercentage > 0 && (
                                                        <div className="mt-3">
                                                        <ProgressBar height={10} percentage={uploadPercentage} />
                                                        <p>{uploadPercentage}%</p>
                                                        </div>
                                                    )} */}

                          {uploadPercentage > 0 && (
                            <div class="progress mb-2">
                              <div
                                class="progress-bar progress-bar-striped"
                                role="progressbar"
                                style={{ width: uploadPercentage + "%" }}
                                aria-valuenow="10"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {uploadPercentage}%
                              </div>
                            </div>
                          )}
                          {videouploadStatus === true ? (
                            <label
                              for="upload_video"
                              className="btn btn-light-blue medium py-2 px-3"
                            >
                              <span className="spinner-grow spinner-grow-sm "></span>
                            </label>
                          ) : (
                            <div>
                              <input
                                type="file"
                                id="upload_video"
                                className="d-none"
                                accept="video/*"
                                onBlur={(e) => {
                                  handleValidation(e, e.target.value, "video");
                                }}
                                onChange={(e) => handleUploadVideo(e)}
                              />

                              <label
                                for="upload_video"
                                className="btn btn-light-blue medium py-2 px-3"
                              >
                                Upload Video{" "}
                                <img
                                  src="../assets/img/icon/icon-upload.svg"
                                  className="ps-1"
                                  alt=""
                                />
                              </label>
                            </div>
                          )}
                        </div>
                        {videoError ? (
                          <div
                            className="form-error"
                            style={{
                              color: "white",
                              margintop: "-20px",
                              marginBottom: "-10px",
                              padding: "0 8px 0 8px",
                            }}
                          >
                            {videoError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-lg-4 p-3 border-bottom">
                  <label
                    for=""
                    className="d-block pb-2 medium text-black fs-18"
                  >
                    Course Description
                  </label>
                  {/* <textarea
                                        name=""
                                        id=""
                                        cols="30"
                                        rows="10"
                                        className={descriptionError ? "is-danger border rounded-3 text-lightgray" : "border rounded-3 text-lightgray"}
                                        value={description}
                                        placeholder="Enter Course Details"
                                        onBlur={(e) => { handleValidation(e, e.target.value, "description") }}
                                        onChange={(e) => {
                                            setDescription(e.target.value)
                                        }}
                                    ></textarea> */}

                  <CKEditor
                    data={description}
                    className={
                      descriptionError
                        ? "is-danger editor text-gray px-3 py-3 border"
                        : "editor text-gray px-3 py-3 border"
                    }
                    cols="30"
                    rows="10"
                    onBlur={(event, editor) => {
                      handleValidation(event, editor.getData(), "description");
                    }}
                    onChange={(event, editor) => {
                      setDescription(editor.getData());
                    }}
                    editor={ClassicEditor}
                    config={{
                      toolbar: [
                        "bold",
                        "italic",
                        "link",
                        "blockQuote",
                        "numberedList",
                        "bulletedList",
                        "|",
                        "undo",
                        "redo",
                      ],
                    }}
                  />
                  {descriptionError ? (
                    <div
                      className="form-error"
                      style={{
                        color: "white",
                        margintop: "-20px",
                        marginBottom: "-10px",
                        padding: "0 8px 0 8px",
                      }}
                    >
                      {descriptionError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="p-lg-4 p-3 border-bottom">
                  <div className="d-flex align-items-center justify-content-between border-bottom mb-3">
                    <label
                      for=""
                      className="d-block pb-2 medium text-black fs-18"
                    >
                      What you’ll teach in this course?
                    </label>
                    <Link
                      onClick={AddObjective}
                      className="text-brown medium fs-18"
                    >
                      Add +
                    </Link>
                  </div>
                  <div className="row">
                    {objective.map((item, index) => {
                      return (
                        <div className="col-md-12" key={index}>
                          <div className="form-field">
                            {/* <label for="" className="d-block pb-2 medium text-black">{index+1}</label> */}
                            <div className="d-flex align-items-center justify-content-between gap-3">
                              <div
                                className={
                                  objectiveErrorList &&
                                  objectiveErrorList.filter(
                                    (e) => e.key === index
                                  ).length > 0
                                    ? "is-danger border rounded-3 d-flex align-items-center justify-content-between w-100"
                                    : "border rounded-3 d-flex align-items-center justify-content-between w-100"
                                }
                              >
                                <input
                                  type="text"
                                  placeholder="Write what you’ll teach in this course"
                                  className="bg-transparent border-0 w-100"
                                  maxLength="120"
                                  onBlur={(e) => {
                                    handleValidation(
                                      e,
                                      e.target.value,
                                      "objective",
                                      index
                                    );
                                  }}
                                  onChange={(e) => {
                                    onchangeObjectiveInput(e, index);
                                  }}
                                  value={item.value}
                                />
                                <span className="text-gray px-1">
                                  {item?.value?.length}/120
                                </span>
                              </div>

                              {index > 0 ? (
                                <Link onClick={() => RemoveObjective(index)}>
                                  <img
                                    src="../assets/img/icon/remove.svg"
                                    alt=""
                                  />
                                </Link>
                              ) : (
                                ""
                              )}
                            </div>

                            {objectiveErrorList &&
                            objectiveErrorList.filter((e) => e.key === index)
                              .length > 0 ? (
                              <div
                                className="form-error"
                                style={index > 0 ? { marginRight: "54px" } : {}}
                              >
                                {objectiveErrorList
                                  .filter((e) => e.key === index)
                                  .map((item) => item.error)}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="p-lg-4 p-3 border-bottom">
                  <div className="d-flex align-items-center justify-content-between border-bottom mb-3">
                    <label
                      for=""
                      className="d-block pb-2 medium text-black fs-18"
                    >
                      Target Audience
                    </label>
                    <Link
                      onClick={AddAudience}
                      className="text-brown medium fs-18"
                    >
                      Add +
                    </Link>
                  </div>
                  <div className="row">
                    {audience.map((item, index) => {
                      return (
                        <div className="col-md-12">
                          <div className="form-field">
                            {/* <label for="" className="d-block pb-2 medium text-black">{index+1}</label> */}
                            <div className="d-flex align-items-center justify-content-between gap-3">
                              <div
                                className={
                                  audienceErrorList &&
                                  audienceErrorList.filter(
                                    (e) => e.key === index
                                  ).length > 0
                                    ? "is-danger border rounded-3  d-flex align-items-center justify-content-between w-100"
                                    : "border rounded-3  d-flex align-items-center justify-content-between w-100"
                                }
                              >
                                <input
                                  type="text"
                                  placeholder="Who this course is for"
                                  className="bg-transparent border-0 w-100"
                                  maxLength="120"
                                  onBlur={(e) => {
                                    handleValidation(
                                      e,
                                      e.target.value,
                                      "audience",
                                      index
                                    );
                                  }}
                                  onChange={(e) => {
                                    onchangeAudienceInput(e, index);
                                  }}
                                  value={item.value}
                                />
                                <span className="text-gray px-1">
                                  {item?.value?.length}/120
                                </span>
                              </div>
                              {index > 0 ? (
                                <Link onClick={() => RemoveAudience(index)}>
                                  <img
                                    src="../assets/img/icon/remove.svg"
                                    alt=""
                                  />
                                </Link>
                              ) : (
                                ""
                              )}
                            </div>

                            {audienceErrorList &&
                            audienceErrorList.filter((e) => e.key === index)
                              .length > 0 ? (
                              <div
                                className="form-error"
                                style={index > 0 ? { marginRight: "54px" } : {}}
                              >
                                {audienceErrorList
                                  .filter((e) => e.key === index)
                                  .map((item) => item.error)}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="p-lg-4 p-3 border-bottom">
                  <div className="d-flex align-items-center justify-content-between border-bottom mb-3">
                    <label
                      for=""
                      className="d-block pb-2 medium text-black fs-18"
                    >
                      Course Requirements
                    </label>
                    <Link
                      onClick={AddRequirements}
                      className="text-brown medium fs-18"
                    >
                      Add +
                    </Link>
                  </div>
                  <div className="row">
                    {requirements.map((item, index) => {
                      return (
                        <div className="col-md-12">
                          <div className="form-field">
                            {/* <label for="" className="d-block pb-2 medium text-black">{index+1}</label> */}
                            <div className="d-flex align-items-center justify-content-between gap-3">
                              <div
                                className={
                                  requirementErrorList &&
                                  requirementErrorList.filter(
                                    (e) => e.key === index
                                  ).length > 0
                                    ? "is-danger border rounded-3 d-flex align-items-center justify-content-between w-100"
                                    : "border rounded-3  d-flex align-items-center justify-content-between w-100"
                                }
                              >
                                <input
                                  type="text"
                                  placeholder="Write your course requirement"
                                  className="bg-transparent border-0 w-100"
                                  maxLength="120"
                                  onBlur={(e) => {
                                    handleValidation(
                                      e,
                                      e.target.value,
                                      "requirements",
                                      index
                                    );
                                  }}
                                  onChange={(e) => {
                                    onchangeRequirementsInput(e, index);
                                  }}
                                  value={item.value}
                                />
                                <span className="text-gray px-1">
                                  {item?.value?.length}/120
                                </span>
                              </div>
                              {index > 0 ? (
                                <Link onClick={() => RemoveRequirements(index)}>
                                  <img
                                    src="../assets/img/icon/remove.svg"
                                    alt=""
                                  />
                                </Link>
                              ) : (
                                ""
                              )}
                            </div>
                            {requirementErrorList &&
                            requirementErrorList.filter((e) => e.key === index)
                              .length > 0 ? (
                              <div
                                className="form-error"
                                style={index > 0 ? { marginRight: "54px" } : {}}
                              >
                                {requirementErrorList
                                  .filter((e) => e.key === index)
                                  .map((item) => item.error)}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="p-3 d-flex align-items-center justify-content-between">
                  <Link
                    to={"/update-courses-basic-information/" + param.id}
                    className="btn light-brown-btn medium  fs-14"
                  >
                    <img
                      src="../assets/img/icon/arrow-left-brown.svg"
                      className="pe-1"
                      alt=""
                    />{" "}
                    Previous
                  </Link>
                  <button
                    type="submit"
                    disabled={isDisabled}
                    className="btn border-0 medium fs-14"
                  >
                    Save & Next{" "}
                    <img
                      src="../assets/img/icon/icon-right-arrow.svg"
                      className="ps-1"
                      alt=""
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <OnlineFriends />
      </div>

      <div
        className="modal fade"
        id="CourseTrailerModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Course Trailer
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setVControl(true);
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="container">
                {videoStatus === "upload" ? (
                  <video
                    controls
                    style={{ maxWidth: "100%" }}
                    className="w-100"
                    src={videoUrl}
                    controlsList="nodownload"
                  ></video>
                ) : (
                  <video
                    style={{ maxWidth: "100%" }}
                    className="w-100"
                    controls
                    src={Endpoints.baseUrl + videoUrl}
                    controlsList="nodownload"
                  ></video>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
