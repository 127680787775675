import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Base64 } from "js-base64";
import OtpInput from "react-otp-input";
import { Helmet } from "react-helmet";
function VerifyEmail() {
  const param = useParams();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [otpError, setotpError] = useState("");
  const btnRef = useRef(null);
  useEffect(() => {
    if (otp.length >= 6) {
      btnRef.current.click();
    }
  }, [otp]);
  const handleChange = (element, index) => {
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const identity = Base64.decode(param.slug);

  const handleResendOtp = async () => {
    let obj = {
      identity: identity,
    };
    try {
      let resp = await CommonAPi(Endpoints.SendForgotPasswordOtp, obj);
      if (resp && resp.message) {
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (otp.length === 0) {
      setotpError("Please enter otp");
    } else if (otp) {
      if (otp.length < 6) {
        setotpError("Please enter valid otp");
      } else {
        setotpError("");
      }
    }

    if (otp.length === 6) {
      let obj = {
        identity: identity,
        otp: otp,
      };
      try {
        let resp = await CommonAPi(Endpoints.VerifyForgotPasswordOTP, obj);
        if (resp && resp.message) {
          navigate("/reset-password" + "/" + Base64.encode(resp.data));
          ToastMessage.Success(resp.message);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleValidation = async (e, value, field) => {
    e.preventDefault();
    if (!value && field === "otp") {
      setotpError("Please enter otp");
    } else if (value && field === "otp") {
      if (otp < 6) {
        setotpError("Please enter valid otp");
      } else {
        setotpError("");
      }
    }
  };

  return (
    <div className="wrapper">
      <Helmet>
        <title>Verify Email</title>
      </Helmet>

      <div className="header fix-header position-fixed top-0 w-100 ">
        <div className="container ">
          <div className="row m-0">
            <div className="col-md-6 col-6">
              <div className="site-logo">
                <Link to="/">
                  <img src="assets/img/8inNet_logo 1.svg" alt="" />
                </Link>
              </div>
            </div>

            <div className="col-md-6 col-6 text-end">
              <div className="d-flex align-items-center justify-content-end">
                <h6 className="font-15 text-white regular pe-3 d-none d-sm-block">
                  Don’t have an account?
                </h6>
                <Link to="/signup" className="btn btn-brown px-4">
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="signin-bg">
        <div className="container p-0">
          <div className="row m-0">
            <div className="col-md-6">
              <div className="py-5">
                <img src="../assets/img/forgot-pwd-email.png" alt="" />
              </div>
            </div>

            <div className="col-md-6 bg-primary py-md-5 py-4 signup-right">
              <div className="pt-md-5 px-md-5 my-md-5">
                <h1 className="semibold text-white pb-2">
                  Verify Email or Mobile
                </h1>

                <form onSubmit={handleSubmit} className="signup-form">
                  <div className="form-field pt-2 mb-2">
                    <p className="fs-13 text-white light">
                      Enter the OTP you received to <b> {identity}</b>
                    </p>
                  </div>

                  <div className="d-flex align-items-center gap-3 mb-2">
                    <div className="otp-number d-flex align-items-center gap-2">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        onBlur={(e) => {
                          handleValidation(e, e.target.value, "otp");
                        }}
                        inputStyle={
                          otpError
                            ? {
                                width: "100%",
                                margin: "3px",
                                maxWidth: "70px",
                                height: "65px",
                                border: "1px solid red",
                              }
                            : {
                                width: "100%",
                                margin: "3px",
                                maxWidth: "70px",
                                height: "65px",
                              }
                        }
                        // containerStyle={otpError ? "is-danger" : ""}
                        onFocus={(e) => e.target.select()}
                        renderInput={(props) => <input {...props} />}
                      />
                      {/* {otp.map((data, index) => {
                                                return (
                                                    <input
                                                        onChange={(e) => handleChange(e.target, index)}
                                                        maxLength={"1"}
                                                        className={otpError ? "is-danger" : ""}
                                                        onBlur={(e) => { handleValidation(e, e.target.value, "otp") }}
                                                        key={index}
                                                        value={data}
                                                        onFocus={(e) => e.target.select()}
                                                    />

                                                );
                                            })} */}
                    </div>
                  </div>
                  <p
                    style={{
                      color: "red",
                      margintop: "-10px",
                      fontWeight: 600,
                    }}
                  >
                    {otpError}
                  </p>
                  <Link
                    onClick={handleResendOtp}
                    className="forgot-link fs-13 text-white"
                  >
                    Resend OTP
                  </Link>

                  <div className="mb-3">
                    <Link
                      to="/forgotpassword"
                      className="forgot-link fs-13 text-white"
                    >
                      Change Email or Mobile
                    </Link>
                  </div>
                  <button
                    type="submit"
                    ref={btnRef}
                    className="btn btn-brown rounded-pill w-100 py-3 mt-3 mb-3"
                  >
                    Continue
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
