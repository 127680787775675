import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";

export default function ProfileNavigation() {
  const location = useLocation();
  const param = useParams();
  const userId = param?.id;
  const loginUserId = localStorage.getItem("userId");
  const [userProfile, setUserProfile] = useState("");
  const Navigate = useNavigate();

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetUserProfile + "?id=" + param?.id);
      if (resp && resp.success === true) {
        setUserProfile(resp?.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <div className="px-lg-4 px-3 px-xl-5 py-2">
      <ul className="user-pro-menu profile-menu d-flex flex-wrap gap-lg-3 justify-content-center">
        <li
          className={
            location.pathname.split("/")[1] === "timeline" ||
            location.pathname.split("/")[1] === "save-post"
              ? "profile-div active"
              : "profile-div"
          }
        >
          <Link to={"/timeline/" + userId}>Timeline</Link>
        </li>

        <li
          className={
            location.pathname.split("/")[1] === "profile-about"
              ? "profile-div active"
              : "profile-div"
          }
        >
          <Link to={"/profile-about/" + userId}>About</Link>
        </li>
        {userProfile?.friendVisibilityStatus === false &&
        loginUserId != userId ? (
          ""
        ) : (
          <li
            className={
              location.pathname.split("/")[1] === "friend-profile"
                ? "profile-div active"
                : "profile-div"
            }
          >
            <Link to={"/friend-profile/" + userId}>Friends</Link>
          </li>
        )}
        {userProfile?.friendVisibilityStatus === false &&
        loginUserId != userId ? (
          ""
        ) : (
          <li
            className={
              location.pathname.split("/")[1] === "profile-teacher"
                ? "profile-div active"
                : "profile-div"
            }
          >
            <Link to={"/profile-teacher/" + userId}>Teachers</Link>
          </li>
        )}

        <li
          className={
            location.pathname.split("/")[1] === "profile-photos" ||
            location.pathname.split("/")[1] === "profile-albums"
              ? "profile-div active"
              : "profile-div"
          }
        >
          <Link to={"/profile-photos/" + userId}>Photos</Link>
        </li>

        <li
          className={
            location.pathname.split("/")[1] === "profile-videos"
              ? "profile-div active"
              : "profile-div"
          }
        >
          <Link to={"/profile-videos/" + userId}>Videos</Link>
        </li>
      </ul>
    </div>
  );
}
