export const Grade = [  
    {
		value: "A+",
		label: "A+"
	},
	{
		value: "A",
		label: "A"
	},
	{
		value: "B+",
		label: "B+"
	},
	{
		value: "B",
		label: "B"
	},
	{
		value: "C+",
		label: "C+"
	},
	{
		value: "C",
		label: "C"
	},
	{
		value: "D+",
		label: "D+"
	},
	{
		value: "D",
		label: "D"
	},
  ];