import React, { useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";

import CompanyProfiles from "./CompanyProfiles";
import LeftStatistics from "./LeftStatistics";
import PostItem from "./PostItem";
import RightSidebar from "./RightSidebar";

function ProfileCompany() {
  const [statistics, setStatistics] = useState("");
  return (
    <Container header>
      <div className="wrapper wrapper-bg">
        <div className="main">
          {/* <?php include 'include/left-navigation.php'; ?> */}
          <LeftNavigation />

          <div className="container py-lg-4 py-3">
            <CompanyProfiles />

            <div className="row">
              <LeftStatistics
                {...{
                  statistics: statistics,
                }}
              />

              <div className="col-md-6 feed-mid-column1 pb-3">
                <PostItem
                  {...{
                    page: "homePage",
                    setStatistics: setStatistics,
                  }}
                />
              </div>

              <RightSidebar />
            </div>
          </div>

          {/* <?php include 'include/online-friends.php'; ?> */}
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}

export default ProfileCompany;
