import React, { useEffect, useState } from "react";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { Endpoints } from "../../API/Endpoints";
import { FetchApi } from "../../API/FetchApi";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate, useParams } from "react-router-dom";
import Item from "../Courses/Item";
import ReviewItem from "./ReviewItem";
import ReactStars from "react-rating-stars-component";
import PostLoader from "../../Components/PostLoader/PostLoader";
import { CommonAPi } from "../../API/CommonApi";
import { Helmet } from "react-helmet";
export default function TeacherProfile() {
  const [profile, setProfile] = useState("");
  const [course, setCourse] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [reviewsData, setReviewsData] = useState("");
  const [rating, setRating] = useState(undefined);
  const [limit, setLimit] = useState(12);
  const param = useParams();
  console.log("@@@", param);
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    getTeacherReview();
    getTeacherProfile();
  }, []);
  const getTeacherProfile = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.GetInstructorDetail +
          "/" +
          param?.id +
          "?limit=" +
          limit +
          "&start=" +
          0
      );
      if (resp && resp.success === true) {
        setProfile(resp.data);
        console.log("@@@", resp?.data);
        setCourse(resp.data.courses);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleLoadMore = async () => {
    let newLimit = parseInt(limit) + 12;
    try {
      let resp = await FetchApi(
        Endpoints.GetInstructorDetail +
          "/" +
          param?.id +
          "?limit=" +
          newLimit +
          "&start=" +
          0
      );
      if (resp && resp.success === true) {
        setProfile(resp.data);
        setCourse(resp.data.courses);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const getTeacherReview = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.GetInstructorReviews + "/" + param?.id
      );
      if (resp && resp.success === true) {
        if (resp.data?.reviews?.length > 0) {
          setReviews(resp.data?.reviews);
        }
        setRating(resp?.data?.overallRating);
        setReviewsData(resp?.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "y";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + "d";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + "h";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min";
    return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec" : "0 sec";
  };

  const sendMsg = async () => {
    try {
      const resp = await CommonAPi(Endpoints.CreateChat, {
        student_id: userId,
        teacher_id: param?.id,
      });
      if (resp) {
        navigate(`/messages?userId=${param?.id}&type=course`);
      }
    } catch (e) {
      console.log(e);
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Teacher Profile</title>
      </Helmet>
      {profile ? (
        <div className="wrapper wrapper-bg">
          <div className="main">
            <LeftNavigation />
            <div className="container py-3">
              <div class="teacher-banner-top mb-3  rounded-15 overflow-hidden bg-white">
                <div class="position-relative">
                  {profile?.user?.user_profile?.coverImg === null ? (
                    <img
                      src="../assets/img/teacher-banner.jpg"
                      alt=""
                      class="profile-coverpic"
                    />
                  ) : (
                    <img
                      src={
                        Endpoints.baseUrl +
                        profile?.user?.user_profile?.coverImg
                      }
                      alt=""
                      class="profile-coverpic"
                    />
                  )}
                </div>

                <div class="row mx-0 align-items-center pb-3">
                  <div class="col-md-auto px-3">
                    <div class="profile-dp teacher-profile-dp rounded-circle">
                      {profile?.user?.user_profile?.profileImg === null ? (
                        <img src="../assets/img/teacher-dp.jpg" alt="" />
                      ) : (
                        <img
                          src={
                            Endpoints.baseUrl +
                            profile?.user?.user_profile?.profileImg
                          }
                          alt=""
                        />
                      )}
                    </div>
                  </div>

                  <div class="col-md-5 py-2">
                    <h3 class="bold pb-2">{profile?.user?.fullName}</h3>
                    <div class="text-darkgray fs-14 medium d-flex align-items-center">
                      <span>
                        <img
                          src="../assets/img/icon/computer.svg"
                          class="pe-sm-1"
                        />{" "}
                        {profile?.totalCourses} Courses
                      </span>
                      <span class="px-2">
                        <img
                          src="../assets/img/icon/icon-students.svg"
                          class="pe-sm-1"
                        />{" "}
                        {profile?.totalStudents} Students
                      </span>
                      <span class="text-brown">
                        <img
                          src="../assets/img/icon/State-fill.svg"
                          width="18"
                          class="me-sm-1"
                        />{" "}
                        {reviewsData.overallRating
                          ? reviewsData?.overallRating?.toFixed(2)
                          : ""}{" "}
                        Reviews
                      </span>
                    </div>
                  </div>

                  <div class="col-md-4 py-md-2 profile-cover-btn">
                    {userId === param?.id ? (
                      ""
                    ) : (
                      <div>
                        {profile?.friendStatus == "ACCEPTED" ||
                        profile?.isEnrolled == true ? (
                          <button
                            class="btn px-3 medium fs-14 "
                            onClick={() => {
                              sendMsg();
                            }}
                          >
                            Send a Message
                            <img
                              src="../assets/img/icon/message.svg"
                              alt=""
                              className="ms-1"
                              width="12"
                            />
                          </button>
                        ) : (
                          ""
                        )}

                        <Link
                          to={"/timeline" + "/" + param?.id}
                          class="btn btn-brown  px-3 medium fs-14 ms-xl-2"
                        >
                          View Main Profile
                          <img
                            src="../assets/img/icon/profile-icon.svg"
                            alt=""
                            width="12"
                            className="ms-1"
                          />
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div class="rounded-15 box-shadow px-md-3 py-md-4 p-2 bg-white mb-3">
                <h3 class="medium pb-3 fs-22">About</h3>
                <p class="fs-14 m-0">
                  {profile?.user?.user_profile?.aboutMe ||
                    profile?.user?.user_profile?.profileTagline}
                </p>
              </div>

              <div class="rounded-15 box-shadow px-md-3 py-md-4 p-2 bg-white mb-3">
                <div class="pb-3 ">
                  <h3 class="medium fs-22">All Courses</h3>
                </div>

                <div class="row">
                  {course?.map((item, index) => {
                    return (
                      <Item
                        {...{
                          item: item,
                          index: index,
                          onSuccess: getTeacherProfile,
                        }}
                      />
                    );
                  })}
                </div>

                {course?.length >= 12 ? (
                  <div class="text-center">
                    <Link onClick={handleLoadMore} class="text-blue fs-14">
                      Load More Courses{" "}
                      <img src="../assets/img/icon/icon-loading.svg" alt="" />
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="rounded-15 py-3 px-3 bg-white mb-3" id="rating">
                <h3 className="medium pb-3 fs-22">Student Ratings</h3>

                <div>
                  <div className="row rating-box">
                    <div className="col-md-3 mb-md-3">
                      <div className="border px-2 py-3 text-center mb-3">
                        <h4 className="bold fs-1 my-2">
                          {reviewsData?.overallRating
                            ? reviewsData?.overallRating?.toFixed(2)
                            : ""}
                        </h4>
                        <h6 className="regular fs-14 text-gray mt-2">
                          Out of 5
                        </h6>

                        <div className="course-rating d-flex gap-1 py-1 justify-content-center my-1">
                          {rating !== undefined ? (
                            <ReactStars
                              count={5}
                              edit={false}
                              value={rating}
                              size={18}
                              isHalf={true}
                              char={
                                <img
                                  src="../assets/img/icon/state-null.svg"
                                  width={18}
                                  alt=""
                                />
                              }
                              emptyIcon={
                                <img
                                  src="../assets/img/icon/state-null.svg"
                                  width={18}
                                  alt=""
                                />
                              }
                              halfIcon={
                                <img
                                  src="../assets/img/icon/state-half.svg"
                                  width={18}
                                  salt=""
                                />
                              }
                              filledIcon={
                                <img
                                  src="../assets/img/icon/State-fill.svg"
                                  width={18}
                                  alt=""
                                />
                              }
                              activeColor="#ffd700"
                            />
                          ) : (
                            <ReactStars
                              count={5}
                              edit={false}
                              value={0}
                              size={18}
                              isHalf={true}
                              char={
                                <img
                                  src="../assets/img/icon/state-null.svg"
                                  width={18}
                                  alt=""
                                />
                              }
                              emptyIcon={
                                <img
                                  src="../assets/img/icon/state-null.svg"
                                  width={18}
                                  alt=""
                                />
                              }
                              halfIcon={
                                <img
                                  src="../assets/img/icon/state-half.svg"
                                  width={18}
                                  alt=""
                                />
                              }
                              filledIcon={
                                <img
                                  src="../assets/img/icon/State-fill.svg"
                                  width={18}
                                  alt=""
                                />
                              }
                              activeColor="#ffd700"
                            />
                          )}
                        </div>

                        <h6 className="regular fs-14 text-gray">
                          {reviewsData?.totalRating} Ratings
                        </h6>
                      </div>
                    </div>

                    <div className="col-md-9">
                      <div className="pb-1 row align-items-center">
                        <div className="col-md-4 d-flex align-items-center">
                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-star-fill text-warning"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-star-fill text-warning"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-star-fill text-warning"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-star-fill text-warning"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-star-fill text-warning"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                          </div>
                          <span className="text-black text-nowrap ps-1">
                            5 Star Rating
                          </span>
                        </div>
                        <div className="col-md-8">
                          <div className="d-flex gap-2 align-items-center">
                            <div class="progress rounded-pill">
                              <div
                                class="progress-bar bg-warning"
                                role="progressbar"
                                style={{
                                  width: reviewsData?.fivePercentRating + "%",
                                }}
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>

                            <span className="text-black text-nowrap">
                              {reviewsData?.fivePercentRating}%
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="pb-1 row align-items-center">
                        <div className="col-md-4 d-flex align-items-center">
                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-star-fill text-warning"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-star-fill text-warning"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-star-fill text-warning"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-star-fill text-warning"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                          </div>
                          <span className="text-black text-nowrap ps-1">
                            4 Star Rating
                          </span>
                        </div>
                        <div className="col-md-8">
                          <div className="d-flex gap-2 align-items-center">
                            <div class="progress rounded-pill">
                              <div
                                class="progress-bar bg-warning"
                                role="progressbar"
                                style={{
                                  width: reviewsData?.fourPercentRating + "%",
                                }}
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <span className="text-black text-nowrap">
                              {reviewsData?.fourPercentRating}%
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="pb-1 row align-items-center">
                        <div className="col-md-4 d-flex align-items-center">
                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-star-fill text-warning"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-star-fill text-warning"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-star-fill text-warning"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                          </div>
                          <span className="text-black text-nowrap ps-1">
                            3 Star Rating
                          </span>
                        </div>
                        <div className="col-md-8">
                          <div className="d-flex gap-2 align-items-center">
                            <div class="progress rounded-pill">
                              <div
                                class="progress-bar bg-warning"
                                role="progressbar"
                                style={{
                                  width: reviewsData?.threePercentRating + "%",
                                }}
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <span className="text-black text-nowrap">
                              {reviewsData?.threePercentRating}%
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="pb-1 row align-items-center">
                        <div className="col-md-4 d-flex align-items-center">
                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-star-fill text-warning"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-star-fill text-warning"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                          </div>
                          <span className="text-black text-nowrap ps-1">
                            2 Star Rating
                          </span>
                        </div>
                        <div className="col-md-8">
                          <div className="d-flex gap-2 align-items-center">
                            <div class="progress rounded-pill">
                              <div
                                class="progress-bar bg-warning"
                                role="progressbar"
                                style={{
                                  width: reviewsData?.twoPercentRating + "%",
                                }}
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <span className="text-black text-nowrap">
                              {reviewsData?.twoPercentRating}%
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="pb-1 row align-items-center">
                        <div className="col-md-4 d-flex align-items-center">
                          <div className="course-rating d-flex gap-1 py-1 justify-content-center ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-star-fill text-warning"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                            <img
                              src="../assets/img/icon/state-null.svg"
                              width="18"
                              alt=""
                            />
                          </div>
                          <span className="text-black text-nowrap ps-1">
                            1 Star Rating
                          </span>
                        </div>
                        <div className="col-md-8">
                          <div className="d-flex gap-2 align-items-center">
                            <div class="progress rounded-pill">
                              <div
                                class="progress-bar bg-warning"
                                role="progressbar"
                                style={{
                                  width: reviewsData?.onePercentRating + "%",
                                }}
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <span className="text-black text-nowrap">
                              {reviewsData?.onePercentRating}%
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h3 className="medium pb-3 fs-22  pt-md-0 pt-3">
                  Student Reviews
                </h3>

                <div className="student-reviews">
                  {reviews.map((reviewItem, ind) => {
                    return (
                      <div
                        className="d-flex align-items-start feed-top mb-3 gap-3"
                        key={ind}
                      >
                        <span className="feed-user-image">
                          <Link to={"/timeline/" + reviewItem?.user?.username}>
                            {reviewItem?.user?.user_profile?.profileImg ===
                            null ? (
                              <img
                                src="../assets/img/profile.png"
                                alt=""
                                width="48"
                                height="48"
                                className="rounded-circle"
                              />
                            ) : (
                              <img
                                src={
                                  Endpoints.baseUrl +
                                  reviewItem?.user?.user_profile?.profileImg
                                }
                                alt=""
                                width="48"
                                height="48"
                                className="rounded-circle"
                              />
                            )}
                          </Link>
                        </span>
                        <div
                          className={
                            reviews?.length === ind + 1
                              ? "pt-1 w-100 "
                              : "pt-1 w-100 border-bottom"
                          }
                        >
                          <div className="d-flex justify-content-between pb-2">
                            <div>
                              <Link
                                to={"/timeline/" + reviewItem?.user?.username}
                              >
                                <h6 className="medium text-black">
                                  {reviewItem?.user?.fullName}
                                </h6>
                              </Link>

                              <h6 className="fs-10 regular text-darkgray py-1">
                                {reviewItem?.user?.user_profile?.profileTagline}
                              </h6>
                              <div className="d-flex w-100 gap-2  align-items-center">
                                <div className="course-rating d-flex gap-1 ">
                                  <ReactStars
                                    count={5}
                                    edit={false}
                                    value={reviewItem.rating.avg}
                                    size={24}
                                    isHalf={true}
                                    char={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        alt=""
                                        width="17"
                                      />
                                    }
                                    emptyIcon={
                                      <img
                                        src="../assets/img/icon/state-null.svg"
                                        alt=""
                                        width="17"
                                      />
                                    }
                                    halfIcon={
                                      <img
                                        src="../assets/img/icon/state-half.svg"
                                        alt=""
                                        width="17"
                                      />
                                    }
                                    filledIcon={
                                      <img
                                        src="../assets/img/icon/State-fill.svg"
                                        alt=""
                                        width="17"
                                      />
                                    }
                                    activeColor="#ffc107"
                                  />
                                </div>

                                <span className="fs-10 medium text-gray d-inline-block">
                                  {reviewItem.rating.avg}
                                </span>
                              </div>
                            </div>
                            <h6 className="fs-12 regular text-darkgray pe-2 text-right">
                              {timeSince(new Date(reviewItem.createdAt))} ago
                            </h6>
                          </div>

                          <p className="fs-14">{reviewItem.comment} </p>
                        </div>
                      </div>
                    );
                  })}
                  {/* {reviews.map((reviewItem, ind)=>{
                                    return (
                                      <ReviewItem 
                                       {...{
                                        item:reviewItem, 
                                        index:ind,
                                        length : reviews.length
                                       }} />
                                    
                                    )
                                })} */}

                  {/* <div className="d-flex align-items-start feed-top mb-3 gap-3">
                                    <span className="feed-user-image">
                                        <img src="../assets/img/profile.png" alt="" width="48" height="48" className="rounded-circle" />
                                    </span>
                                    <div className="pt-1 border-bottom">

                                        <div className="d-flex justify-content-between pb-2">
                                            <div>
                                                <h6 className="medium text-black ">Leslie Alexander</h6>
                                                <h6 className="fs-10 regular text-darkgray py-1">Delhi Public School, Delhi</h6>
                                                <div className="d-flex w-100 gap-2  align-items-center">
                                                    <div className="course-rating d-flex gap-1 ">
                                                        <img src="../assets/img/icon/State-fill.svg" width="14" alt="" />
                                                        <img src="../assets/img/icon/State-fill.svg" width="14" alt="" />
                                                        <img src="../assets/img/icon/State-fill.svg" width="14" alt="" />
                                                        <img src="../assets/img/icon/State-fill.svg" width="14" alt="" />
                                                        <img src="../assets/img/icon/state-half.svg" width="14" alt="" />
                                                    </div>
                                                    <span className="fs-10 medium text-gray d-inline-block">4.5</span>
                                                </div>
                                            </div>
                                            <h6 className="fs-12 regular text-darkgray pe-2">7 minutes ago</h6>
                                        </div>

                                        <p className="fs-14">It's my first time engaging in ux design course, the content here is super amazing and the way Mr. Joe Natoli explained everything bit by bit made it very easy to understand the course.</p>
                                    </div>

                                </div>

                                <div className="d-flex align-items-start feed-top mb-3 gap-3">
                                    <span className="feed-user-image">
                                        <img src="../assets/img/profile.png" alt="" width="48" height="48" className="rounded-circle" />
                                    </span>
                                    <div className="pt-1 border-bottom">

                                        <div className="d-flex justify-content-between pb-2">
                                            <div>
                                                <h6 className="medium text-black ">Leslie Alexander</h6>
                                                <h6 className="fs-10 regular text-darkgray py-1">Delhi Public School, Delhi</h6>
                                                <div className="d-flex w-100 gap-2  align-items-center">
                                                    <div className="course-rating d-flex gap-1 ">
                                                        <img src="../assets/img/icon/State-fill.svg" width="14" alt="" />
                                                        <img src="../assets/img/icon/State-fill.svg" width="14" alt="" />
                                                        <img src="../assets/img/icon/State-fill.svg" width="14" alt="" />
                                                        <img src="../assets/img/icon/State-fill.svg" width="14" alt="" />
                                                        <img src="../assets/img/icon/state-half.svg" width="14" alt="" />
                                                    </div>
                                                    <span className="fs-10 medium text-gray d-inline-block">4.5</span>
                                                </div>
                                            </div>
                                            <h6 className="fs-12 regular text-darkgray pe-2">7 minutes ago</h6>
                                        </div>

                                        <p className="fs-14">It's my first time engaging in ux design course, the content here is super amazing and the way Mr. Joe Natoli explained everything bit by bit made it very easy to understand the course.</p>
                                    </div>

                                </div> */}
                </div>
              </div>
            </div>
            <OnlineFriends />
          </div>
        </div>
      ) : (
        <PostLoader />
      )}
    </Container>
  );
}
