import React, { useEffect, useState } from 'react'
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { CommonAPi } from '../../API/CommonApi';
import { PatchApi } from '../../API/PatchApi';
import { Endpoints } from "../../API/Endpoints";
import ProfileImage from '../ProfileUpdate/ProfileImage';
import Sidebar from '../ProfileUpdate/Sidebar';
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";
export default function ProfileVisibility() {
    const [dob, setDob] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [birthPlace, setBirthPlace] = useState('');
    const [connectionList, setConnectionList] = useState('');
    const history = useNavigate();
    useEffect(() => {
        getProfileVisibility();
    }, []);

    const getProfileVisibility = async () => {

        try {
            let resp = await CommonAPi(Endpoints.GetProfileVisibility);
            if (resp && resp.success === true) {
                setDob(resp?.data[0]?.dob);
                setCity(resp?.data[0]?.city);
                setCountry(resp?.data[0]?.country);
                setMobile(resp?.data[0]?.mobile);
                setEmail(resp?.data[0]?.email);
                setBirthPlace(resp?.data[0]?.birthPlace);
                setConnectionList(resp?.data[0]?.connectionList);
            }
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error("Session Expired! Please login again.");
                    localStorage.clear();
                    history("/signin");
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }
    };

    const handleUpdate = async() =>{
        let data = {
            dob:dob,
            city:city,
            country:country,
            mobile:mobile,
            email:email,
            birthPlace:birthPlace,
            connectionList:connectionList,
        }
        try {
            let resp = await PatchApi(Endpoints.UpdateProfileVisibility, data);
            if (resp && resp.success === true) {
                ToastMessage.Success(resp.message);
                getProfileVisibility();
            }
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error("Session Expired! Please login again.");
                    localStorage.clear();
                    history("/signin");
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }

    };

    return (
        <Container
            header
        >
        <Helmet>
          <title>Profile Visibility Setting</title>
        </Helmet>
            <div className="wrapper wrapper-bg">
                <div className="main">
                    <LeftNavigation />
                    <div className="container py-lg-4 py-3">
                        <ProfileImage />
                        <div className="row">
                            <Sidebar />

                            <div className="col-lg-9 col-md-8">
                                <div className="bg-white box-shadow rounded-15 p-md-3 p-2">
                                    <div className="pt-md-2 pb-3 mb-3 px-lg-3 border-bottom">
                                        <h3 className="medium pb-2">Profile Visibility</h3>
                                        <h5 className="medium">Select who may see your profile details.</h5>
                                    </div>

                                    <ul className="px-xl-3 px-md-2">
                                        <li className="pb-3 border-bottom">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="medium">Date of Birth</h4>

                                                <div id="visibility1" data-bs-toggle="dropdown" className="visibility-title d-flex align-items-center justify-content-between ">
                                                      {dob === "ONLY-ME" ? "ONLY ME" : dob === "FRIENDS-ONLY" ? "FRIENDS ONLY" : dob}
                                                    <img src="assets/img/icon/down-black.svg" className="ms-auto" alt="" />
                                                </div>

                                                <div className="dropdown-menu dropdown-menu-end border rounded-15 shadow py-3 visibility-dropdown overflow-hidden" aria-labelledby="visibility1" onclick="event.stopPropagation()">
                                                    <ul className="" name="" id="">
                                                        <li onClick={()=>{
                                                            setDob("ONLY-ME") 
                                                        }}><input type="radio" name="visible1" value="" className="d-none" checked={dob === "ONLY-ME" ? true : false}/><label>Only Me </label></li>
                                                        <li  onClick={()=>{
                                                            setDob("FRIENDS-ONLY")
                                                        }}><input type="radio" name="visible1" value="" className="d-none" checked={dob === "FRIENDS-ONLY" ? true : false}/><label>Friend Only </label></li>
                                                        <li
                                                        onClick={()=>{
                                                            setDob("EVERYONE")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none" checked={dob === "EVERYONE" ? true : false} /><label>Everyone </label></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="py-3 border-bottom">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="medium ">City</h4>

                                                <div id="visibility2" data-bs-toggle="dropdown" className="visibility-title d-flex align-items-center justify-content-between">
                                                    {city === "ONLY-ME" ? "ONLY ME" : city === "FRIENDS-ONLY" ? "FRIENDS ONLY" : city}
                                                    <img src="assets/img/icon/down-black.svg" className="ms-auto" alt="" />
                                                </div>

                                                <div className="dropdown-menu dropdown-menu-end border rounded-15 shadow py-3 visibility-dropdown overflow-hidden" aria-labelledby="visibility2" onclick="event.stopPropagation()">
                                                    <ul className="" name="" id="">
                                                        <li
                                                        onClick={()=>{
                                                            setCity("ONLY-ME")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none"  checked={city === "ONLY-ME" ? true : false} /><label>Only Me </label></li>
                                                        <li
                                                        onClick={()=>{
                                                            setCity("FRIENDS-ONLY")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none"  checked={city === "FRIENDS-ONLY" ? true : false}/><label>Friend Only </label></li>
                                                        <li
                                                        onClick={()=>{
                                                            setCity("EVERYONE")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none" checked={city === "EVERYONE" ? true : false} /><label>Everyone </label></li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </li>

                                        <li className="py-3 border-bottom">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="medium ">Country</h4>

                                                <div id="visibility3" data-bs-toggle="dropdown" className="visibility-title d-flex align-items-center justify-content-between">
                                                 
                                                    {country === "ONLY-ME" ? "ONLY ME" : country === "FRIENDS-ONLY" ? "FRIENDS ONLY" : country}
                                                    <img src="assets/img/icon/down-black.svg" className="ms-auto" alt="" />
                                                </div>

                                                <div className="dropdown-menu dropdown-menu-end border rounded-15 shadow py-3 visibility-dropdown overflow-hidden" aria-labelledby="visibility3" onclick="event.stopPropagation()">
                                                    <ul className="" name="" id="">
                                                        <li
                                                         onClick={()=>{
                                                            setCountry("ONLY-ME")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none"  checked={country === "ONLY-ME" ? true : false} /><label>Only Me </label></li>
                                                        <li
                                                         onClick={()=>{
                                                            setCountry("FRIENDS-ONLY")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none" checked={country === "FRIENDS-ONLY" ? true : false} /><label>Friend Only </label></li>
                                                        <li
                                                         onClick={()=>{
                                                            setCountry("EVERYONE")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none" checked={country === "EVERYONE" ? true : false} /><label>Everyone </label></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="py-3 border-bottom">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="medium ">Mobile No.</h4>

                                                <div id="visibility4" data-bs-toggle="dropdown" className="visibility-title d-flex align-items-center justify-content-between">
                                                   
                                                    {mobile === "ONLY-ME" ? "ONLY ME" : mobile === "FRIENDS-ONLY" ? "FRIENDS ONLY" : mobile}
                                                    <img src="assets/img/icon/down-black.svg" className="ms-auto" alt="" />
                                                </div>

                                                <div className="dropdown-menu dropdown-menu-end border rounded-15 shadow py-3 visibility-dropdown overflow-hidden" aria-labelledby="visibility4" onclick="event.stopPropagation()">
                                                    <ul className="" name="" id="">
                                                        <li
                                                         onClick={()=>{
                                                            setMobile("ONLY-ME")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none"  checked={mobile === "ONLY-ME" ? true : false} /><label>Only Me </label></li>
                                                        <li
                                                         onClick={()=>{
                                                            setMobile("FRIENDS-ONLY")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none" checked={mobile === "FRIENDS-ONLY" ? true : false} /><label>Friend Only </label></li>
                                                        <li
                                                         onClick={()=>{
                                                            setMobile("EVERYONE")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none" checked={mobile === "EVERYONE" ? true : false} /><label>Everyone </label></li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </li>

                                        <li className="py-3 border-bottom">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="medium ">Email Id</h4>

                                                <div id="visibility5" data-bs-toggle="dropdown" className="visibility-title d-flex align-items-center justify-content-between">
                                                  
                                                    {email === "ONLY-ME" ? "ONLY ME" : email === "FRIENDS-ONLY" ? "FRIENDS ONLY" : email}
                                                    <img src="assets/img/icon/down-black.svg" className="ms-auto" alt="" />
                                                </div>

                                                <div className="dropdown-menu dropdown-menu-end border rounded-15 shadow py-3 visibility-dropdown overflow-hidden" aria-labelledby="visibility5" onclick="event.stopPropagation()">
                                                    <ul className="" name="" id="">
                                                        <li
                                                         onClick={()=>{
                                                            setEmail("ONLY-ME")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none"  checked={email === "ONLY-ME" ? true : false} /><label>Only Me </label></li>
                                                        <li
                                                         onClick={()=>{
                                                            setEmail("FRIENDS-ONLY")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none" checked={email === "FRIENDS-ONLY" ? true : false} /><label>Friend Only </label></li>
                                                        <li
                                                         onClick={()=>{
                                                            setEmail("EVERYONE")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none" checked={email === "EVERYONE" ? true : false} /><label>Everyone </label></li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </li>

                                        <li className="py-3 border-bottom">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="medium ">Birth Place</h4>

                                                <div id="visibility6" data-bs-toggle="dropdown" className="visibility-title d-flex align-items-center justify-content-between">
                                                   
                                                    {birthPlace === "ONLY-ME" ? "ONLY ME" : birthPlace === "FRIENDS-ONLY" ? "FRIENDS ONLY" : birthPlace}
                                                    <img src="assets/img/icon/down-black.svg" className="ms-auto" alt="" />
                                                </div>

                                                <div className="dropdown-menu dropdown-menu-end border rounded-15 shadow py-3 visibility-dropdown overflow-hidden" aria-labelledby="visibility6" onclick="event.stopPropagation()">
                                                    <ul className="" name="" id="">
                                                        <li
                                                         onClick={()=>{
                                                            setBirthPlace("ONLY-ME")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none" checked={birthPlace === "ONLY-ME" ? true : false}  /><label>Only Me </label></li>
                                                        <li
                                                         onClick={()=>{
                                                            setBirthPlace("FRIENDS-ONLY")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none" checked={birthPlace === "FRIENDS-ONLY" ? true : false} /><label>Friend Only </label></li>
                                                        <li
                                                         onClick={()=>{
                                                            setBirthPlace("EVERYONE")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none" checked={birthPlace === "EVERYONE" ? true : false} /><label>Everyone </label></li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </li>

                                        <li className="py-3 border-bottom">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="medium ">Who can see my connections list?</h4>

                                                <div id="visibility7" data-bs-toggle="dropdown" className="visibility-title d-flex align-items-center justify-content-between">
                                                 
                                                   {connectionList === "ONLY-ME" ? "ONLY ME" : connectionList === "FRIENDS-ONLY" ? "FRIENDS ONLY" : connectionList}
                                                    <img src="assets/img/icon/down-black.svg" className="ms-auto" alt="" />
                                                </div>

                                                <div className="dropdown-menu dropdown-menu-end border rounded-15 shadow py-3 visibility-dropdown overflow-hidden" aria-labelledby="visibility7" onclick="event.stopPropagation()">
                                                    <ul className="" name="" id="">
                                                        <li
                                                         onClick={()=>{
                                                            setConnectionList("ONLY-ME")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none" checked={connectionList === "ONLY-ME" ? true : false}  /><label>Only Me </label></li>
                                                        <li
                                                         onClick={()=>{
                                                            setConnectionList("FRIENDS-ONLY")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none" checked={connectionList === "FRIENDS-ONLY" ? true : false}  /><label>Friend Only </label></li>
                                                        <li
                                                         onClick={()=>{
                                                            setConnectionList("EVERYONE")
                                                        }}
                                                        ><input type="radio" name="visible1" value="" className="d-none" checked={connectionList === "EVERYONE" ? true : false} /><label>Everyone </label></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>



                                    </ul>

                                    <div className="text-md-end text-center py-2 px-3 mb-lg-2">
                                        <button type="submit" className="btn" onClick={()=>{
                                            handleUpdate()
                                        }}>Update Settings</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <OnlineFriends />
            </div>
        </Container >
    )
}
