import React, { useEffect, useState } from "react";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from "../../API/FetchApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import VideosItems from "./VideosItems";

export default function Video() {
  const [video, setVideo] = useState([]);
  const [count, setCount] = useState(0);
  const Navigate = useNavigate();
  const param = useParams();
  useEffect(() => {
    getUserVideo();
  }, [param?.id]);

  const getUserVideo = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.UserPhotoVideo + "?size=3&page=1&type=videos&id=" + param?.id
      );
      if (resp && resp.success === true) {
        setVideo(resp?.data?.data);
        setCount(resp?.data?.count);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <div className="sidebar-widget sidebar-videos box-shadow mt-3">
      <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-3">
        <h4 className="medium">
          Videos <span className="fs-10 text-brown count-number">{count}</span>
        </h4>
        <Link
          to={"/profile-videos/" + param?.id}
          className="text-brown fs-12 medium"
        >
          See all{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-arrow-right feather-16"
          >
            <line x1="5" y1="12" x2="19" y2="12"></line>
            <polyline points="12 5 19 12 12 19"></polyline>
          </svg>
        </Link>
      </div>
      {video.map((item, index) => {
        return (
          <VideosItems
            {...{
              item: item,
              index: index,
              post: item.post,
            }}
          />
        );
      })}
    </div>
  );
}
