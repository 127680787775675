import React, { useCallback, useEffect, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import { useNavigate } from "react-router-dom";
import { PatchApi } from "../../API/PatchApi";
import { Endpoints } from "../../API/Endpoints";
import { getCroppedImg } from "../../Utils/Canvas/canvas_util";
import ToastMessage from "../../Utils/ToastMessage";
const GroupProfileCropper = ({
  profileRef,
  show,
  setShow,
  profileImageSrc,
  setProfileImageSrc,
  setGetProfilePicture,
  groupId,
  onSuccess,
}) => {
  const closeModalRef = useRef(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(null);
  const [file, setFile] = useState();
  const navigate = useNavigate();
  const [profileCompleteness, setProfileCompleteness] = useState(
    Math.round(localStorage.getItem("ProfileCompletion"))
  );
  const [btnValue, setBtnValue] = useState("Please wait..");
  // const [initialCroppedArea, setInitialCroppedArea] = useState(undefined)
  const closeModal = () => {
    setShow(false);
    profileRef.current.value = "";
    setTimeout(() => {
      closeModalRef.current.click();
    }, 200);
  };

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      const { file, url } = await getCroppedImg(
        profileImageSrc,
        croppedAreaPixels
      );
      setCroppedImage(url);
      setFile(file);
      // window.localStorage.setItem('croppedAreaPixels', JSON.stringify(croppedAreaPixels))
    },
    [profileImageSrc]
  );

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setBtnValue("Done");
      }, 500);
    }
  }, [show, profileImageSrc]);

  const uploadProfilePicture = async () => {
    setBtnValue("Uploading...");
    let newFile = new File([file], "image.jpg", { type: "image/jpg" });
    const formData = new FormData();
    formData.append("logo_image", newFile);
    formData.append("groupId", groupId);

    try {
      let resp = await PatchApi(Endpoints.UpdateGroupsLogo, formData);
      if (resp && resp.message) {
        onSuccess(true);
        closeModal();
        setBtnValue("Please wait..");
        setShow(false);
        setGetProfilePicture(resp.data.profile);
        setProfileImageSrc(null);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
        setBtnValue("Please wait..");
        setShow(false);
      }
    }
  };
  return (
    <>
      <div className={`modal-backdrop ${show ? "show" : "hide"} `}></div>
      <div>
        <div className="d-none">
          <a
            href="javascript:void(0)"
            className="btn btn-br-blue fs-14 bg-transparent"
            data-bs-toggle="modal"
            data-bs-target="#kidmodal"
          >
            done
            <img src="assets/img/icon/profile.svg" alt="" />
          </a>
        </div>
        <div
          className={`modal ${show ? "show" : "hide"}`}
          id={`profileImageCropperModal`}
          tabindex="-1"
          aria-labelledby={`profileImageCropperModal`}
          aria-hidden="true"
        >
          <div className="modal-dialog rounded-10 box-shadow p-lg-3 p-md-3 p-2 bg-white">
            <div className="modal-content border-0" style={{ height: "30rem" }}>
              <div className="d-flex align-items-center justify-content-between border-bottom w-100">
                <h3 className="medium text-dark" style={{ fontSize: "1rem" }}>
                  Crop Image
                </h3>
                <button
                  type="button"
                  className="btn-close kids-close-icon cursor-pointer"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeModalRef}
                  onClick={() => {
                    setShow(false);
                    profileRef.current.value = "";
                  }}
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="#949494"
                    width="22"
                    height="22"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="modal-body p-0" style={{ height: "30rem" }}>
                <div className="col-md-12 text-end">
                  {profileImageSrc && (
                    <>
                      <div>
                        <Cropper
                          image={profileImageSrc}
                          crop={crop}
                          rotation={rotation}
                          zoom={zoom}
                          aspect={1}
                          cropShape="round"
                          onCropChange={setCrop}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                          // initialCroppedAreaPixels={initialCroppedArea}
                          // initialCroppedAreaPercentages={initialCroppedArea}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="cover-slider-container text-center">
                <span>Zoom</span>
                <div className="cover-slider">
                  <span className="slider-icons">-</span>
                  <input
                    type="range"
                    min="1"
                    max="3"
                    step="0.1"
                    value={zoom}
                    onChange={(e) => setZoom(e.target.value)}
                  />
                  <span className="slider-icons">+</span>
                </div>
              </div>
              <button
                type="button"
                disabled={btnValue === "Done" ? false : true}
                className="btn fs-12 mt-2 w-100"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.localStorage.removeItem("croppedAreaPixels");
                  uploadProfilePicture();
                }}
              >
                {btnValue}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupProfileCropper;
