import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Profiles from './Profiles';
import { FetchApi } from "../../../API/FetchApi";
import { PatchApi } from "../../../API/PatchApi";
import { Endpoints } from "../../../API/Endpoints";
import ToastMessage from "../../../Utils/ToastMessage";
import moment from 'moment';
import ApplicationModel from './ApplicationModel';
import {Helmet} from "react-helmet";
const $ = window.jQuery;

export default function AdminJobsApplication() {
    const [aboutData, setAboutData] = useState();
    const [jobsData, setJobsData] = useState([]);
    const [singleJobsData, setSingalJobsData] = useState('');
    const [count, setCount] = useState([]);
    const [activeTab, setActiveTab] = useState('all');
    const [allCount, setAallCounnt] = useState('');
    const [limit, setLimit] = useState(10);
    const param = useParams();
    const location  = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        getJobsApplication();
    }, [activeTab, limit]);
    useEffect(()=>{
        if(location?.pathname.split("/")[1] == "admin-jobs-shortlisted-application"){
            setActiveTab('shortlisted');
        }
    }, [location]);

    const getJobsApplication = async () => {
        if(activeTab === "all"){
            try {
                let resp = await FetchApi(Endpoints.GetJobApplicants + "?jobId=" + param?.id + "&size="+limit);
                if (resp && resp.success === true) {
                    setJobsData(resp?.data?.allUser);
                    setAboutData(resp?.data?.page);
                    setCount(resp?.data?.totalRecord);
                    setAallCounnt({
                        totalAppliedUser:resp?.data?.allCount,
                        totalRejectedUser:resp?.data?.totalRejectedUser,
                        totalSortlistedUser:resp?.data?.totalSortlistedUser,
                    });
                }
            }
            catch (e) {
                if (e && e.response && e.response.data && e.response.data.message) {
                    if (e.response.status === 401) {
                        ToastMessage.Error('Session Expired! Please login again.');
                        localStorage.clear();
                        navigate('/signin');
                    } else {
                        ToastMessage.Error(e.response.data.message);
                    }
                }
            }
        }else{
            try {
                let resp = await FetchApi(Endpoints.GetJobApplicants + "?jobId=" + param?.id + "&scope=" + activeTab + "&size="+limit);
                if (resp && resp.success === true) {
                    setJobsData(resp?.data?.application);
                    setAboutData(resp?.data?.page);
                    setCount(resp?.data?.totalRecord);
                    setAallCounnt({
                        totalAppliedUser:resp?.data?.allCount,
                        totalRejectedUser:resp?.data?.totalRejectedUser,
                        totalSortlistedUser:resp?.data?.totalSortlistedUser,
                    });
                }
            }
            catch (e) {
                if (e && e.response && e.response.data && e.response.data.message) {
                    if (e.response.status === 401) {
                        ToastMessage.Error('Session Expired! Please login again.');
                        localStorage.clear();
                        navigate('/signin');
                    } else {
                        ToastMessage.Error(e.response.data.message);
                    }
                }
            }
        }
       

    };

    const handleAction = async(Id, userId, action) =>{
        let data = {
            action:action
        }
        try {
            let resp = await PatchApi(Endpoints.JobApplicantsAction + "/"+ Id + "/" + userId, data);
            if (resp && resp.success === true) {
                getJobsApplication();
                ToastMessage.Success(resp.message);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }
    };

    
    const handleShow = async(item) =>{
      
        try {
            let resp = await FetchApi(Endpoints.GetSingleJobApplicants + "/" + item?.applyJobs?.jobId + "/" + item?.applyJobs?.userId );
            if (resp && resp.success === true) {
                setSingalJobsData(resp?.data) 
                $("#applyjobs").modal("show");  
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }

     
    };

    return (
        <Container
            header
        >
             <Helmet>
                <title>Jobs Applications</title>
            </Helmet>
            <div className="wrapper wrapper-bg">
                <div className="main">
                    <LeftNavigation />
                    <div className="container py-lg-4 py-3">
                        <Profiles {...{
                            aboutData: aboutData
                        }} />

                        <div class="row">


                            <div class="col-md-12 pb-3">

                                <div class="rounded-15 px-4 py-3 bg-white mb-3">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="d-flex pb-4 align-items-center justify-content-between">
                                                <Link to={"/admin-jobs-list/" + aboutData?.id} class="btn "><img src="../assets/img/icon/arrow-left-white.svg" alt="" /> Back</Link>
                                                <h4 class="medium text-center">Manage Jobs/Internships</h4>
                                            </div>
                                        </div>
                                    </div>


                                    <ul class="profile-menu d-flex justify-content-center flex-wrap gap-lg-3 pb-4">
                                        <li class="active">
                                            <Link to={"/"}>Job Applications ({allCount?.totalAppliedUser})</Link>
                                        </li>

                                        <li class="" >
                                            <Link to={"/admin-jobs-view/" + param?.id}>Job Preview</Link>
                                        </li>
                                    </ul>
                                    <div class="mainmenu-filter-row pb-3">
                                        <ul class="filter d-flex flex-nowrap align-items-center gap-2 fs-14 medium">
                                            <li class={activeTab === "all" ?"active " : "" } onClick={()=>{
                                                setActiveTab('all')
                                            }}>All Applications  <span class="fs-10 text-blue">{allCount?.totalAppliedUser}</span></li>
                                            <li class={activeTab === "shortlisted" ?"active " : "" } onClick={()=>{
                                                setActiveTab('shortlisted')
                                            }}>Shortlisted <span class="fs-10 text-brown">{allCount?.totalSortlistedUser}</span></li>
                                            <li class={activeTab === "rejected" ?"active " : "" } onClick={()=>{
                                                setActiveTab('rejected')
                                            }}>Rejected <span class="fs-10 text-brown">{allCount?.totalRejectedUser}</span></li>
                                        </ul>
                                    </div>

                                    <table class="table job-table job-view-table">

                                        <thead>
                                            <tr>
                                                <th>Candidate</th>
                                                <th></th>
                                                <th>Applied on</th>
                                                {activeTab === "all" ?
                                                <th>Status</th>
                                                : "" }
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                            jobsData?.length > 0 ? 
                                            jobsData.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td class="p-lg-3 ">
                                                            {item?.user_profile?.profileImg === null ?
                                                            <img src="../assets/img/profile6.png" class="rounded-circle" alt="" />
                                                            :
                                                            <img src={Endpoints.baseUrl + item?.user_profile?.profileImg}
                                                            width="58"
                                                            height="58"
                                                            class="rounded-circle" alt="" />
                                                            }
                                                        </td>
                                                        <td>
                                                            <h6 class="semibold">{item?.fullName}</h6>
                                                            <h6 class="fs-12 text-gray regular">{item?.user_profile?.profileTagline}</h6>
                                                            <span class="fs-10 d-block text-gray regular">{item?.mutualFriends} mutual friends</span>
                                                        </td>
                                                        <td class="p-lg-3">
                                                            <h4 class="fs-15 medium">{moment(item?.applyJobs?.createdAt).format('MMMM DD, YYYY')}</h4>
                                                        </td>
                                                        {activeTab === "all" ?
                                                        <td class="p-lg-3">
                                                            {item?.applyJobs?.status == "SHORTLISTED" ? 
                                                             <h4 class="fs-15 medium text-green">Shortlisted</h4>
                                                            : 
                                                            item?.applyJobs?.status ==  "REJECTED" ?
                                                            <h4 class="fs-15 medium text-danger">Rejected</h4>
                                                            :
                                                            <h4 class="fs-15 medium text-orange">Pending</h4>
                                                            }
                                                           
                                                        </td>
                                                        : "" }
                                                        <td class="action-btn p-lg-3">
                                                            <div class="d-flex gap-2">
                                                                <a href="#" class="rounded-circle border-gray" onClick={()=>{ 
                                                                    handleShow(item)
                                                                }} ><img src="../assets/img/icon/eye.svg" class="icon-gray" width="17" /></a>
                                                                {activeTab === "all" ?
                                                                 
                                                                    item?.applyJobs?.status == "REJECTED"  ||  item?.applyJobs?.status == "PENDING" ?   
                                                                        <Link onClick={()=>{ 
                                                                            handleAction(item?.applyJobs?.jobId, item?.applyJobs?.userId, "SHORTLISTED")
                                                                        }} class="bg-lightblue rounded-circle border-blue"><img src="../assets/img/icon/gray-right.svg" class="icon-blue" /></Link>
                                                                    :
                                                                        ""
                                                                : 
                                                                    activeTab === "rejected" ? 
                                                                    <Link onClick={()=>{ 
                                                                        handleAction(item?.applyJobs?.jobId, item?.applyJobs?.userId, "SHORTLISTED")
                                                                    }} class="bg-lightblue rounded-circle border-blue"><img src="../assets/img/icon/gray-right.svg" class="icon-blue" /></Link>
                                                                    :
                                                                    ""
                                                                }


                                                                {activeTab === "all" ?
                                                                  item?.applyJobs?.status == "SHORTLISTED"  ||  item?.applyJobs?.status == "PENDING" ?    
                                                                    <Link onClick={()=>{
                                                                        handleAction(item?.applyJobs?.jobId, item?.applyJobs?.userId, "REJECTED")
                                                                    }}  class="rounded-circle border-gray"><img src="../assets/img/icon/gray-wrong.svg" class="icon-gray" /></Link>
                                                                    :
                                                                    ""
                                                                :
                                                                    activeTab === "shortlisted" ?
                                                                    <Link onClick={()=>{
                                                                        handleAction(item?.applyJobs?.jobId, item?.applyJobs?.userId, "REJECTED")
                                                                    }}  class="rounded-circle border-gray"><img src="../assets/img/icon/gray-wrong.svg" class="icon-gray" /></Link>
                                                                    : 
                                                                    ""
                                                                
                                                                
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={3}>No application found</td>
                                            </tr>
                                        }





                                        </tbody>
                                    </table>
                                    {limit < count ?
                                    <div class="text-center pt-4">
                                        <Link onClick={()=>{
                                            setLimit(limit+10);
                                        }} class="btn btn-light-blue px-3 medium fs-14 py-2">Load More Jobs <img src="../assets/img/icon/icon-loading.svg" class="ms-1" alt="" /></Link>
                                    </div>
                                    : ""}


                                </div>

                            </div>

                        </div>
                        <OnlineFriends />
                    </div>
                </div>
            </div>
            <ApplicationModel {...{
                singleJobsData:singleJobsData
            }} />
        </Container>
    )
}
