import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import PageTab from "../SchoolsMenu/PageTab";
import { Helmet } from "react-helmet";
function PagesMenu() {

    return (
        <Container header>
            <Helmet>
                <title>Pages</title>
            </Helmet>
            <div className="wrapper wrapper-bg">


                <div className="main">
                    <LeftNavigation />

                    <div className="container py-3">
                        <PageTab {...{
                            ActivePage: "Page"
                        }} />
                    </div>
                    <OnlineFriends />
                </div>


            </div>
        </Container>
    )
}

export default PagesMenu;