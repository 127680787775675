import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import { CommonAPi } from "../../../API/CommonApi";
import { UpdateAPi } from "../../../API/UpdateApi";
import { FetchApi } from "../../../API/FetchApi";
import { Endpoints } from "../../../API/Endpoints";
import ToastMessage from "../../../Utils/ToastMessage";
import Topbar from "./Topbar";
import { CourseLevel } from "../../../constants/CourseLevel";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Select from "react-select";
import { Helmet } from "react-helmet";
import "./BasicInfo.css";

export default function BasicInfo() {
  const param = useParams();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [topicData, setTopicData] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [subcategoryId, setSubCategoryId] = useState("");
  const [title, setTitle] = useState("");
  const [Subtitle, setSubtitle] = useState("");
  const [SubtitleLanguage, setSubtitleLanguage] = useState("");
  const [SubtitleLanguageId, setSubtitleLanguageId] = useState("");
  const [languageId, setLanguageId] = useState("");
  const [languageData, setLanguageData] = useState([]);
  const [duration, setDuration] = useState("");
  const [daysData, setDaysData] = useState(new Array(31).fill(""));
  const [level, setLevel] = useState("");

  const [titleError, setTitleError] = useState("");
  const [SubtitleError, setSubtitleError] = useState("");
  const [SubtitleLanguageError, setSubtitleLanguageError] = useState("");
  const [languageError, setLanguageError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [subcategoryError, setSubCategoryError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [levelError, setLevelError] = useState("");
  const [topicError, setTopicError] = useState("");
  useEffect(() => {
    if (
      languageId !== "" &&
      subcategoryId !== "" &&
      categoryId !== "" &&
      Subtitle !== "" &&
      title !== ""
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [title, Subtitle, categoryId, subcategoryId, languageId]);

  const navigate = useNavigate();
  useEffect(() => {
    getCategory();
    if (param.id) {
      getBasic();
    }
    getLanguage();
  }, []);

  const getBasic = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.GetCourseBasicInformation + "/" + param?.id
      );
      if (resp && resp.success === true) {
        console.log(resp.data);
        // setDuration(resp.data?.duration);
        setSubtitle(resp.data?.subtitle);
        setTitle(resp.data?.title);
        setCategoryId(resp.data?.categoryId);
        setSubCategoryId(resp.data?.subCategoryId);
        getCourseTopic(resp.data?.subCategoryId);
        setSubtitleLanguageId(resp.data?.subtitleLanguageId?.id);
        setLanguageId(resp.data?.languageId);
        const newData = resp.data?.courseTopic.map((item, index) => {
          return {
            value: index + 1,
            label: item,
          };
        });
        setSelectedOptions(newData);
        setLevel(resp.data?.level);
        if (resp.data?.categoryId) {
          getSubcategory(resp.data?.categoryId);
        }
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getCategory = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetCourseCategory);
      if (resp && resp.success === true) {
        setCategoryData(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSelected = (item) => {
    if (item.length <= 10) {
      setSelectedOptions(item);
    }
  };
  const getLanguage = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetLanguage);
      if (resp && resp.success === true) {
        setLanguageData(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleLanguage = (id) => {
    setSubtitleLanguageId(id);
  };
  const getSubcategory = async (categoryId) => {
    setCategoryId(categoryId);
    // const button = document.getElementsByClassName('css-tlfecz-indicatorContainer')[0];
    // button.click();

    try {
      let resp = await FetchApi(
        Endpoints.GetCourseSubCategory + "?cat=" + categoryId
      );
      if (resp && resp.success === true) {
        setSubCategoryData(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const getCourseTopic = async (subcategoryId) => {
    try {
      let resp = await FetchApi(Endpoints.courseTopic + "/" + subcategoryId);
      if (resp && resp.success === true) {
        const newTopicData = resp.data.map((item, index) => {
          return {
            value: item.id,
            label: item.courseTopic,
          };
        });
        setTopicData(newTopicData);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSave = async (type) => {
    if (!title) {
      setTitleError("Please enter course title");
    } else if (title) {
      setTitleError("");
    }
    if (!Subtitle) {
      setSubtitleError("Please enter course subtitle");
    } else if (Subtitle) {
      setSubtitleError("");
    }
    if (!categoryId) {
      setCategoryError("Please select course category");
    } else if (categoryId) {
      setCategoryError("");
    }
    if (!subcategoryId) {
      setSubCategoryError("Please select course subcategory");
    } else if (subcategoryId) {
      setSubCategoryError("");
    }
    // if (!duration) {
    //     setDurationError("Please select course duration");
    // } else if (duration) {
    //     setDurationError("");
    // }
    if (!languageId) {
      setLanguageError("Please select course language");
    } else if (languageId) {
      setLanguageError("");
    }
    // if (!SubtitleLanguageId) {
    //     setSubtitleLanguageError("Please select course Subtitle Language");
    // } else if (SubtitleLanguageId) {
    //     setSubtitleLanguageError("");
    // }
    if (!level) {
      setLevelError("Please select course level");
    } else if (level) {
      setLevelError("");
    }

    if (selectedOptions.length === 0) {
      setTopicError("Please select topic");
    } else if (selectedOptions.length > 0) {
      if (selectedOptions.length < 4) {
        setTopicError("Please select minimum 4 topics");
        return false;
      } else if (selectedOptions.length > 10) {
        setTopicError("Topics allowed muximum 10");
        return false;
      } else {
        setTopicError("");
      }
    }
    if (
      title &&
      Subtitle &&
      categoryId &&
      subcategoryId &&
      languageId &&
      level &&
      selectedOptions.length > 0
    ) {
      const courseTopics = selectedOptions.map((item) => {
        return item.label;
      });
      let data;
      if (SubtitleLanguageId) {
        data = {
          title: title,
          subtitle: Subtitle,
          category_id: categoryId,
          sub_category_id: subcategoryId,
          language_id: languageId,
          subtitle_language_id: SubtitleLanguageId,
          level: level,
          course_topic: courseTopics,
        };
      } else {
        data = {
          title: title,
          subtitle: Subtitle,
          category_id: categoryId,
          sub_category_id: subcategoryId,
          language_id: languageId,
          level: level,
          course_topic: courseTopics,
        };
      }

      if (param.id) {
        try {
          let resp = await UpdateAPi(
            Endpoints.UpdateCourseBasicInformation + "/" + param.id,
            data
          );
          if (resp && resp.message) {
            if (type === "close") {
              navigate("/mycourses");
            }

            if (type === "preview") {
              // navigate("/course-preview" + '/' + param.id);
              window.open("/course-preview/" + param.id, "_blank");
            }
            ToastMessage.Success(resp.message);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            ToastMessage.Error(e.response.data.message);
          }
        }
      } else {
        try {
          let resp = await CommonAPi(
            Endpoints.CreateCourseBasicInformation,
            data
          );
          if (resp && resp.message) {
            console.log("@#@#3", resp);
            if (type === "close") {
              navigate("/mycourses");
            }

            if (type === "preview") {
              navigate("/course-preview" + "/" + resp.data.id, "_blank");
            }
            ToastMessage.Success(resp.message);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!title) {
      setTitleError("Please enter course title");
    } else if (title) {
      setTitleError("");
    }
    if (!Subtitle) {
      setSubtitleError("Please enter course subtitle");
    } else if (Subtitle) {
      setSubtitleError("");
    }

    if (!categoryId) {
      setCategoryError("Please select course category");
    } else if (categoryId) {
      setCategoryError("");
    }
    if (!subcategoryId) {
      setSubCategoryError("Please select course subcategory");
    } else if (subcategoryId) {
      setSubCategoryError("");
    }
    // if (!duration) {
    //     setDurationError("Please select course duration");
    // } else if (duration) {
    //     setDurationError("");
    // }
    if (!languageId) {
      setLanguageError("Please select course language");
    } else if (languageId) {
      setLanguageError("");
    }
    // if (!SubtitleLanguageId) {
    //     setSubtitleLanguageError("Please select course Subtitle Language");
    // } else if (SubtitleLanguageId) {
    //     setSubtitleLanguageError("");
    // }
    if (!level) {
      setLevelError("Please select course level");
    } else if (level) {
      setLevelError("");
    }

    if (selectedOptions.length === 0) {
      setTopicError("Please select topic");
    } else if (selectedOptions.length > 0) {
      if (selectedOptions.length < 4) {
        setTopicError("Please select minimum 4 topics");
        return false;
      } else if (selectedOptions.length > 10) {
        setTopicError("Topics allowed muximum 10");
        return false;
      } else {
        setTopicError("");
      }
    }

    if (
      title &&
      Subtitle &&
      categoryId &&
      subcategoryId &&
      languageId &&
      level &&
      selectedOptions.length > 0
    ) {
      const courseTopics = selectedOptions.map((item) => {
        return item.label;
      });

      let data;
      if (SubtitleLanguageId) {
        data = {
          title: title,
          subtitle: Subtitle,
          category_id: categoryId,
          sub_category_id: subcategoryId,
          language_id: languageId,
          subtitle_language_id: SubtitleLanguageId,
          level: level,
          course_topic: courseTopics,
        };
      } else {
        data = {
          title: title,
          subtitle: Subtitle,
          category_id: categoryId,
          sub_category_id: subcategoryId,
          language_id: languageId,
          level: level,
          course_topic: courseTopics,
        };
      }
      if (param.id) {
        try {
          let resp = await UpdateAPi(
            Endpoints.UpdateCourseBasicInformation + "/" + param.id,
            data
          );
          if (resp && resp.message) {
            console.log(resp);
            navigate("/add-courses-advance-information" + "/" + param.id);
            ToastMessage.Success(resp.message);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            ToastMessage.Error(e.response.data.message);
          }
        }
      } else {
        try {
          let resp = await CommonAPi(
            Endpoints.CreateCourseBasicInformation,
            data
          );
          if (resp && resp.message) {
            console.log(resp.data.id);
            navigate("/add-courses-advance-information" + "/" + resp.data.id);
            ToastMessage.Success(resp.message);
          }
        } catch (e) {
          if (e && e.response && e.response.data && e.response.data.message) {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };
  const handleValidation = async (e, value, field) => {
    e.preventDefault();

    if (!value && field === "title") {
      setTitleError("Please enter course title");
    } else if (value && field === "title") {
      setTitleError("");
    }
    if (!value && field === "Subtitle") {
      setSubtitleError("Please enter course subtitle");
    } else if (value && field === "Subtitle") {
      setSubtitleError("");
    }
    if (!value && field === "category") {
      setCategoryError("Please select course category");
    } else if (value && field === "category") {
      setCategoryError("");
    }
    if (!value && field === "subcategory") {
      setSubCategoryError("Please select course subcategory");
    } else if (value && field === "subcategory") {
      setSubCategoryError("");
    }
    // if (!value && field === "duration") {
    //     setDurationError("Please select course duration");
    // } else if (value && field === "duration") {
    //     setDurationError("");
    // }
    if (!value && field === "language") {
      setLanguageError("Please select course language");
    } else if (value && field === "language") {
      setLanguageError("");
    }
    if (!value && field === "SubtitleLanguage") {
      setSubtitleLanguageError("Please select course Subtitle Language");
    } else if (value && field === "SubtitleLanguage") {
      setSubtitleLanguageError("");
    }
    if (!value && field === "level") {
      setLevelError("Please select course level");
    } else if (value && field === "level") {
      setLevelError("");
    }
    console.log(value);
    if (!value && field === "topic") {
      if (selectedOptions.length === 0) {
        setTopicError("Please select topic");
      } else if (selectedOptions.length > 0) {
        setTopicError("");
      }
    } else if (value && field === "topic") {
      setTopicError("");
    }
  };

  const handleCancel = async () => {
    navigate("/mycourses");
  };
  return (
    <Container header>
      <Helmet>
        <title>Basic Info</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-3">
            <div className="bg-white main-common p-0 py-3">
              <Topbar />

              <div className="border-bottom py-lg-4 py-3 px-3">
                <div className="d-sm-flex align-items-center justify-content-between">
                  <h2>Basic Information</h2>
                  <div className="save-close-btns">
                    <a
                      href="#"
                      onClick={() => {
                        handleSave("close");
                      }}
                      className="btn btn-br-blue py-2"
                    >
                      Save & Close
                    </a>
                    <a
                      href="#"
                      onClick={() => {
                        handleSave("preview");
                      }}
                      className="btn btn-br-blue ms-3 py-2"
                    >
                      Save & Preview
                    </a>
                  </div>
                </div>
              </div>

              <form
                onSubmit={handleSubmit}
                className="teacher-form generic-form"
              >
                <div className="py-3 px-3 border-bottom">
                  <div className="row">
                    <div className="col-md-12 pb-3">
                      <div className="form-field">
                        <label
                          for=""
                          className="d-block pb-2 medium text-black"
                        >
                          Course Title *
                        </label>
                        <div
                          className={
                            titleError
                              ? " is-danger border rounded-3 p-2 d-flex align-items-center justify-content-between"
                              : "border rounded-3 p-2 d-flex align-items-center justify-content-between"
                          }
                        >
                          <input
                            type="text"
                            placeholder="Enter Course Title"
                            className=" bg-transparent border-0 w-100 p-0"
                            onBlur={(e) => {
                              handleValidation(e, e.target.value, "title");
                            }}
                            maxLength="80"
                            value={title}
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                          />
                          <span className="text-gray">{title.length}/80</span>
                        </div>
                        {titleError ? (
                          <div
                            className="form-error"
                            style={{
                              color: "white",
                              margintop: "-10px",
                              marginBottom: "-10px",
                            }}
                          >
                            {titleError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 pb-3">
                      <div className="form-field">
                        <label
                          for=""
                          className="d-block pb-2 medium text-black"
                        >
                          Course Subtitle *
                        </label>
                        <div
                          className={
                            SubtitleError
                              ? "is-danger border rounded-3 p-2 d-flex align-items-center justify-content-between gap-2"
                              : "border rounded-3 p-2 d-flex align-items-center justify-content-between gap-2"
                          }
                        >
                          <input
                            type="text"
                            placeholder="Enter Course Subtitle"
                            className="bg-transparent border-0 w-100 p-0"
                            maxLength="120"
                            value={Subtitle}
                            onBlur={(e) => {
                              handleValidation(e, e.target.value, "Subtitle");
                            }}
                            onChange={(e) => {
                              setSubtitle(e.target.value);
                            }}
                          />
                          <span className="text-gray">
                            {Subtitle.length}/120
                          </span>
                        </div>
                        {SubtitleError ? (
                          <div
                            className="form-error"
                            style={{
                              color: "white",
                              margintop: "-10px",
                              marginBottom: "-10px",
                            }}
                          >
                            {SubtitleError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 pb-3">
                      <div className="form-field">
                        <label
                          for=""
                          className="d-block pb-2 medium text-black"
                        >
                          Course Level *
                        </label>
                        <select
                          name=""
                          className={
                            levelError
                              ? "is-danger border p-2 rounded-3 text-gray"
                              : "border p-2 rounded-3 text-gray"
                          }
                          id=""
                          onBlur={(e) => {
                            handleValidation(e, e.target.value, "level");
                          }}
                          onChange={(e) => {
                            setLevel(e.target.value);
                          }}
                        >
                          <option value="">Select Course Level</option>

                          {CourseLevel.map((item, index) => {
                            return (
                              <option
                                selected={level === item?.value ? true : false}
                                value={item?.value}
                                key={index}
                              >
                                {item?.label}
                              </option>
                            );
                          })}
                        </select>
                        {levelError ? (
                          <div
                            className="form-error"
                            style={{
                              color: "white",
                              margintop: "-10px",
                              marginBottom: "-10px",
                            }}
                          >
                            {levelError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-4 pb-3">
                      <div className="form-field">
                        <label
                          for=""
                          className="d-block pb-2 medium text-black"
                        >
                          Course Category *
                        </label>
                        <select
                          name=""
                          className={
                            categoryError
                              ? "is-danger border p-2 rounded-3 text-gray"
                              : "border p-2 rounded-3 text-gray"
                          }
                          id=""
                          onBlur={(e) => {
                            handleValidation(e, e.target.value, "category");
                          }}
                          onChange={(e) => {
                            getSubcategory(e.target.value);
                          }}
                        >
                          <option value="">Select category</option>
                          {categoryData.map((item, index) => {
                            return (
                              <option
                                selected={categoryId === item.id ? true : false}
                                value={item?.id}
                                key={index}
                              >
                                {item?.name}
                              </option>
                            );
                          })}
                        </select>
                        {categoryError ? (
                          <div
                            className="form-error"
                            style={{
                              color: "white",
                              margintop: "-10px",
                              marginBottom: "-10px",
                            }}
                          >
                            {categoryError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-4 pb-3">
                      <div className="form-field">
                        <label
                          for=""
                          className="d-block pb-2 medium text-black"
                        >
                          Course Subcategory *
                        </label>
                        <select
                          name=""
                          className={
                            subcategoryError
                              ? "is-danger border p-2 rounded-3 text-gray"
                              : "border p-2 rounded-3 text-gray"
                          }
                          onBlur={(e) => {
                            handleValidation(e, e.target.value, "subcategory");
                          }}
                          onChange={(e) => {
                            setSubCategoryId(e.target.value);
                            getCourseTopic(e.target.value);
                          }}
                        >
                          <option value="">Select sub-category</option>
                          {subCategoryData.map((item, index) => {
                            return (
                              <option
                                selected={
                                  subcategoryId === item.id ? true : false
                                }
                                value={item?.id}
                                key={index}
                              >
                                {item?.name}
                              </option>
                            );
                          })}
                        </select>
                        {subcategoryError ? (
                          <div
                            className="form-error"
                            style={{
                              color: "white",
                              margintop: "-10px",
                              marginBottom: "-10px",
                            }}
                          >
                            {subcategoryError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-4 pb-3">
                      <div className="form-field">
                        <div className="d-flex justify-content-between course-topic">
                          <label
                            for=""
                            className="d-block pb-2 medium text-black"
                          >
                            Course Topic *
                          </label>
                          <span className="px-1 py-1 text-end" id="app-title">
                            <i class="fa fa-info-circle"></i>
                          </span>
                          <ReactTooltip
                            anchorId="app-title"
                            place="left"
                            content="Kindly enter the relavent keywords from the list for better reach of your course. A minimum of 4 topics and maximum 10 topics are allowed"
                          />
                        </div>
                        <Select
                          value={selectedOptions}
                          onChange={handleSelected}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              boxShadow: "none",
                              border: "none",
                              padding: "0.8px",
                            }),
                          }}
                          options={topicData}
                          onBlur={(e) => {
                            handleValidation(e, e.target.value, "topic");
                          }}
                          className={
                            topicError
                              ? "is-danger border rounded-3 text-gray"
                              : "border rounded-3 text-gray"
                          }
                          isMulti
                          placeholder="e.g. Marketing Management"
                          isClearable={true}
                          isSearchable={true}
                          isDisabled={false}
                          isLoading={false}
                          isRtl={false}
                          closeMenuOnSelect={false}
                        />
                        {topicError ? (
                          <div
                            className="form-error"
                            style={{
                              color: "white",
                              margintop: "-10px",
                              marginBottom: "-10px",
                            }}
                          >
                            {topicError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 pb-3">
                      <div className="form-field">
                        <label
                          for=""
                          className="d-block pb-2 medium text-black"
                        >
                          Course Language{" "}
                        </label>
                        <select
                          name=""
                          className={
                            languageError
                              ? "is-danger border p-2 rounded-3 text-gray"
                              : "border p-2 rounded-3 text-gray"
                          }
                          onBlur={(e) => {
                            handleValidation(e, e.target.value, "language");
                          }}
                          onChange={(e) => {
                            setLanguageId(e.target.value);
                          }}
                        >
                          <option value="">Select language</option>
                          {languageData.map((item, index) => {
                            return (
                              <option
                                key={index}
                                selected={languageId === item.id ? true : false}
                                value={item.id}
                              >
                                {item.language}
                              </option>
                            );
                          })}
                        </select>
                        {languageError ? (
                          <div
                            className="form-error"
                            style={{
                              color: "white",
                              margintop: "-10px",
                              marginBottom: "-10px",
                            }}
                          >
                            {languageError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 pb-3">
                      <div className="form-field">
                        <label
                          for=""
                          className="d-block pb-2 medium text-black"
                        >
                          Subtitle Language (Optional)
                        </label>
                        <select
                          name=""
                          className={
                            SubtitleLanguageError
                              ? "is-danger border p-2 rounded-3 text-gray"
                              : "border p-2 rounded-3 text-gray"
                          }
                          onChange={(e) => {
                            setSubtitleLanguageId(e.target.value);
                          }}
                        >
                          <option value="">Select Subtitle Language</option>
                          {languageData.map((item, index) => {
                            return (
                              <option
                                key={index}
                                selected={
                                  SubtitleLanguageId === item.id ? true : false
                                }
                                value={item.id}
                              >
                                {item.language}
                              </option>
                            );
                          })}
                        </select>
                        {/* {SubtitleLanguageError ?
                                                    <div className="form-error" style={{ color: "white", margintop: "-10px", marginBottom: "-10px" }}>
                                                        {SubtitleLanguageError}
                                                    </div>
                                                    : ""} */}
                      </div>
                    </div>

                    {/* <div className="col-md-4 pb-3">
                                            <div className="form-field">
                                                <label for="" className="d-block pb-2 medium text-black">Duration *</label>
                                                <div className={durationError ? "is-danger border p-2 rounded-3 text-gray d-flex align-items-center justify-content-between" : "border p-2 rounded-3 text-gray d-flex align-items-center justify-content-between"}>
                                                    Select duration
                                                    <select name=""
                                                        className="border-0 px-2 w-25 text-gray p-0"
                                                        id=""
                                                        onBlur={(e) => { handleValidation(e, e.target.value, "duration") }}
                                                        onClick={(e) => {
                                                            setDuration(e.target.value)
                                                        }}
                                                    >
                                                        <option value="">Day</option>

                                                        {daysData.map((data, index) => {
                                                            return (
                                                                <option selected={duration === index + 1 ? true : false} key={index} value={index + 1}>{index + 1}</option>
                                                            );
                                                        })}
                                                    </select>

                                                </div>
                                                {durationError ?
                                                    <div className="form-error" style={{ color: "white", margintop: "-10px", marginBottom: "-10px" }}>
                                                        {durationError}
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div> */}
                  </div>
                </div>

                <div className="p-3 d-flex align-items-center justify-content-between">
                  <a
                    href="#"
                    className="btn btn-warning py-2 fs-14"
                    onClick={handleCancel}
                  >
                    Cancel
                  </a>
                  <button
                    type="submit"
                    disabled={isDisabled}
                    className="btn py-2 border-0 fs-14"
                  >
                    {" "}
                    Save & Next{" "}
                    <img src="assets/img/icon/icon-right-arrow.svg" alt="" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <OnlineFriends />
      </div>
    </Container>
  );
}
