import React from "react";
import { Endpoints } from "../../API/Endpoints";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";

export default function ReviewItem({ item, index, length }) {
  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "y";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + "d";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + "h";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min";
    return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec" : "0 sec";
  };
  return (
    <div className="d-flex align-items-start feed-top mb-3 gap-3" key={index}>
      <span className="feed-user-image">
        <Link to={"/timeline/" + item?.user?.username}>
          {item?.user?.user_profile?.profileImg === null ? (
            <img
              src="../assets/img/profile.png"
              alt=""
              width="48"
              height="48"
              className="rounded-circle"
            />
          ) : (
            <img
              src={Endpoints.baseUrl + item?.user?.user_profile?.profileImg}
              alt=""
              width="48"
              height="48"
              className="rounded-circle"
            />
          )}
        </Link>
      </span>
      <div
        className={
          length === index + 1 ? "pt-1 w-100 " : "pt-1 w-100 border-bottom"
        }
      >
        <div className="d-flex justify-content-between pb-2">
          <div>
            <Link to={"/timeline/" + item?.user?.username}>
              <h6 className="medium text-black ">{item?.user?.fullName}</h6>
            </Link>
            <h6 className="fs-10 regular text-darkgray py-1">
              {item?.user?.user_profile?.profileTagline}
            </h6>
            <div className="d-flex w-100 gap-2  align-items-center">
              {/* <div className="course-rating d-flex gap-1 ">
                                <img src="../assets/img/icon/State-fill.svg" width="14" alt="" />
                                <img src="../assets/img/icon/State-fill.svg" width="14" alt="" />
                                <img src="../assets/img/icon/State-fill.svg" width="14" alt="" />
                                <img src="../assets/img/icon/State-fill.svg" width="14" alt="" />
                                <img src="../assets/img/icon/state-half.svg" width="14" alt="" />

                            </div> */}
              <div className="course-rating d-flex gap-1 ">
                <ReactStars
                  count={5}
                  edit={false}
                  value={item.rating}
                  size={24}
                  isHalf={true}
                  char={
                    <img
                      src="../assets/img/icon/state-null.svg"
                      alt=""
                      width="17"
                    />
                  }
                  emptyIcon={
                    <img
                      src="../assets/img/icon/state-null.svg"
                      alt=""
                      width="17"
                    />
                  }
                  halfIcon={
                    <img
                      src="../assets/img/icon/state-half.svg"
                      alt=""
                      width="17"
                    />
                  }
                  filledIcon={
                    <img
                      src="../assets/img/icon/State-fill.svg"
                      alt=""
                      width="17"
                    />
                  }
                  activeColor="#ffc107"
                />
              </div>

              <span className="fs-10 medium text-gray d-inline-block">
                {item.rating}
              </span>
            </div>
          </div>
          <h6 className="fs-12 regular text-darkgray pe-2 text-right">
            {timeSince(new Date(item.createdAt))}
          </h6>
        </div>

        <p className="fs-14">{item.comment} </p>
      </div>
    </div>
  );
}
