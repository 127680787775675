import React, { useEffect, useState } from 'react'
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function Category({type}) {
    const [categoryData, setCategoryData] = useState([]);
    const [limitStatus, setLimitStatus] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        getCategory();
    }, [])
    const getCategory = async () => {
        let categoryType = "Job";
        if( type === "JOb"){
            categoryType = "Job";
        }else{
            categoryType = type;
        }

        try {
            let resp = await FetchApi(Endpoints.JobsIndustries +"?scope=" + categoryType );
            if (resp && resp.success === true) {
                setCategoryData(resp?.data);
            
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }

    };


    return (
        <div class="bg-white main-common mt-4">
            <div className="d-flex justify-content-between">
                <div>
                    <h3 class="bold pb-2">Explore {type === "JOb" ? "Job" : type} by Categories</h3>
                    <h5 class="medium text-darkgray pb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h5>
                </div>
                {type === "JOb" ?
                <div className="text-end" >
                    <Link to={"/save-jobs"} class="btn px-4 py-2 fs-14 m-1">SAVED JOBS <img src="assets/img/icon/icon-save.svg" class="ps-2" /></Link>
                    <Link to={"/applied-jobs"} class="btn px-4 py-2 fs-14 m-1">APPLIED JOBS <img src="assets/img/icon/icon-briefcase.svg" class="ps-2" /></Link>
                </div>           
                :
                <div className="text-end" >
                    <Link to={"/save-internship"} class="btn px-4 py-2 fs-14 m-1">SAVED Internship <img src="assets/img/icon/icon-save.svg" class="ps-2" /></Link>
                    <Link to={"/applied-internship"} class="btn px-4 py-2 fs-14 m-1">APPLIED Internship <img src="assets/img/icon/icon-briefcase.svg" class="ps-2" /></Link>
                </div> 
                } 
            </div>
            <div class="row">
                {categoryData.map((item, index)=>{
                    if(limitStatus === false){
                        if(index > 8) return null;
                    }
                    return (
                        <div class="col-md-4 col-sm-6 mb-3">
                        <div class="job-item  d-flex align-items-center">
                            <span class="hastag-icon">
                                <img src={ Endpoints.baseUrl + item?.img} alt="" />
                            </span>
                            <div class="ps-3" >
                                <Link to={type === "Internship" ? "/internship-listing?industry=" +item.id + "&size=9"  : "/jobs-listing?industry=" +item.id + "&size=9"  }>
                                <h4 class="semibold pb-1">{item?.value}</h4>
                                </Link>
                                
                                <h6 class="fs-14 text-darkgray regular">{item?.totalJob} Open Jobs</h6>
                            </div>
                        </div>
                    </div>
                    )
                })}
               

            </div>

            {limitStatus === false ? 
            <div class="text-center py-3">
                <Link onClick={()=>{
                    setLimitStatus(true)
                }} class="btn px-4">Browse All Categories</Link>
            </div>
            : ""}
        </div>
    )
}
