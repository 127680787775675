import React from "react";
import "./PostReaction.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

const PostReactions = ({
  handleReact,
  postReactions,
  id,
  isOpen,
  isCommentReaction,
  setCommentReact,
  setReactionsCount,
  commentReact,
}) => {
  return (
    <div
      className={`post-reactions-container ${isOpen ? "open" : "close"}
       ${isCommentReaction ? "commentReaction" : ""}`}
      key={id}
      id={id}
    >
      {postReactions.map((postReaction, idx) => (
        <span
          key={postReaction.id}
          id={postReaction.id}
          onClick={(e) => {
            handleReact(e, id, postReaction.id);
            if (commentReact === postReaction.id) {
              setCommentReact("React");
              setReactionsCount((prev) => prev - 1);
            } else if (commentReact === "React") {
              setReactionsCount((prev) => prev + 1);
              setCommentReact(postReaction.id);
              console.log(postReaction.id);
            } else if (commentReact !== postReaction.id) {
              setCommentReact(postReaction.id);
            }
          }}
        >
          {/* <div className='badge bg-dark text-white font-weight-light '>{postReaction.id}</div> */}
          <img
            src={postReaction.img}
            id={"Post-" + id + postReaction.id}
            alt="react-icon"
          />
          <ReactTooltip
            anchorId={"Post-" + id + postReaction.id}
            place="top"
            content={postReaction.id}
          />
        </span>
      ))}
    </div>
  );
};

export default PostReactions;
