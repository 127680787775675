import React, { useEffect, useState } from "react";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Profile from "./Profile";
import { Endpoints } from "../../../API/Endpoints";
import ToastMessage from "../../../Utils/ToastMessage";
import { CommonAPi } from "../../../API/CommonApi";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";
import ReceiptBtn from "./ReceiptBtn";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Helmet } from "react-helmet";
const $ = window.jQuery;
export default function Earning() {
  const [accountHolder, setAccountHolder] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [confirmAccountNumber, setConfirmAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [hidden, setHidden] = useState(true);
  const [accountHolderError, setAccountHolderError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [confirmAccountNumberError, setConfirmAccountNumberError] =
    useState("");
  const [ifscCodeError, setIfscCodeError] = useState("");
  const [bankNameError, setBankNameError] = useState("");
  const [bankAddressError, setBankAddressError] = useState("");
  const [dashboard, setDashboard] = useState("");
  const [bank, setBank] = useState([]);
  const [earningGraph, setEarningGraph] = useState([]);
  const [earningType, setEarningType] = useState("week");
  const [earningInterval, setEarningInterval] = useState(0);
  const [activeAccountNumber, setActiveAccountNumber] = useState("");
  const [activeAccountNumberError, setActiveAccountNumberError] = useState("");
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);
  const [withdrawalHistoryType, setWithdrawalHistoryType] = useState("week");

  useEffect(() => {
    getEarning();
  }, []);

  const getEarning = async () => {
    try {
      let resp = await CommonAPi(
        Endpoints.Earnings +
          "?earnings_by=" +
          earningType +
          "&withdrawal_by=" +
          withdrawalHistoryType
      );
      if (resp && resp.message) {
        setDashboard({
          currentBal: resp.data.currentBal,
          todayEarnings: resp.data.todayEarnings,
          totalRevenue: resp.data.totalRevenue,
          totalWithdrawals: resp.data.totalWithdrawals,
        });
        setWithdrawalHistoryType(resp.data.withdrawalType);
        setBank(resp.data.banksList);
        let earningData = Object.keys(resp.data.earningsViewsCount).map(
          (item) => {
            return {
              "₹": Math.round(resp.data.earningsViewsCount[item]),
              name:
                resp.data.earningsType === "year"
                  ? item
                  : moment(item).format("DD MMM"),
            };
          }
        );

        setEarningType(resp.data.earningsType);

        setEarningGraph(earningData);
        if (resp.data.earningsType === "year") {
          setEarningInterval(0);
        } else if (resp.data.earningsType === "month") {
          setEarningInterval(4);
        } else if (resp.data.earningsType === "week") {
          setEarningInterval(0);
        }
        setWithdrawalHistory(resp.data.withdrawalHistory);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        ToastMessage.Error(e.response.data.message);
      }
    }
  };

  const handleValidation = (e, value, field) => {
    e.preventDefault();
    if (!value && field === "accountHolder") {
      setAccountHolderError("Please enter your account holder");
    } else if (value && field === "accountHolder") {
      setAccountHolderError("");
    }
    if (!value && field === "accountNumber") {
      setAccountNumberError("Please enter your account number");
    } else if (value && field === "accountNumber") {
      setAccountNumberError("");
    }
    if (!value && field === "confirmAccountNumber") {
      setConfirmAccountNumberError("Please enter your confirm account number");
    } else if (value && field === "confirmAccountNumber") {
      setConfirmAccountNumberError("");
    }
    if (!value && field === "ifscCode") {
      setIfscCodeError("Please enter your ifsc code");
    } else if (value && field === "ifscCode") {
      setIfscCodeError("");
    }
    if (!value && field === "bankName") {
      setBankNameError("Please enter your bank name");
    } else if (value && field === "bankName") {
      setBankNameError("");
    }
    if (!value && field === "bankAddress") {
      setBankAddressError("Please enter your bank address");
    } else if (value && field === "bankAddress") {
      setBankAddressError("");
    }
  };
  const handleSubmitBank = async (event) => {
    event.preventDefault();

    if (!accountHolder) {
      setAccountHolderError("Please enter your account holder");
    } else if (accountHolder) {
      setAccountHolderError("");
    }
    if (!accountNumber) {
      setAccountNumberError("Please enter your account number");
    } else if (accountNumber) {
      setAccountNumberError("");
    }
    if (!confirmAccountNumber) {
      setConfirmAccountNumberError("Please enter your Confirm account number");
    } else if (confirmAccountNumber) {
      if (accountNumber === confirmAccountNumber) {
        setConfirmAccountNumberError("");
      } else {
        setConfirmAccountNumberError(
          "Confirm account number and Account number must be matched"
        );
      }
    }
    if (!ifscCode) {
      setIfscCodeError("Please enter your ifsc code");
    } else if (ifscCode) {
      setIfscCodeError("");
    }
    if (!bankName) {
      setBankNameError("Please enter your bank name");
    } else if (bankName) {
      setBankNameError("");
    }
    if (!bankAddress) {
      setBankAddressError("Please enter your bank address");
    } else if (bankAddress) {
      setBankAddressError("");
    }

    if (
      accountHolder &&
      accountNumber &&
      ifscCode &&
      bankName &&
      bankAddress &&
      confirmAccountNumber
    ) {
      let data = {
        account_holder: accountHolder,
        account_number: accountNumber,
        confirm_account_number: confirmAccountNumber,
        ifsc_code: ifscCode,
        bank_name: bankName,
        bank_address: bankAddress,
      };
      try {
        let resp = await CommonAPi(Endpoints.AddBankAccount, data);
        if (resp && resp.message) {
          getEarning();
          setBankAddress("");
          setBankName("");
          setIfscCode("");
          setAccountNumber("");
          setAccountHolder("");
          setAccountHolderError("");
          setAccountNumberError("");
          setIfscCodeError("");
          setBankNameError("");
          setBankAddressError("");
          document.getElementById("add-bank-account").reset();
          $("#add_bank").modal("hide");
          ToastMessage.Success(resp.message);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleEarningChart = async (value) => {
    setEarningType(value);
    try {
      let resp = await CommonAPi(Endpoints.Earnings + "?earnings_by=" + value);
      if (resp && resp.message) {
        setDashboard({
          currentBal: resp.data.currentBal,
          todayEarnings: resp.data.todayEarnings,
          totalRevenue: resp.data.totalRevenue,
          totalWithdrawals: resp.data.totalWithdrawals,
        });
        setBank(resp.data.banksList);
        let earningData = Object.keys(resp.data.earningsViewsCount).map(
          (item) => {
            return {
              "₹": Math.round(resp.data.earningsViewsCount[item]),
              name:
                resp.data.earningsType === "year"
                  ? item
                  : moment(item).format("DD MMM"),
            };
          }
        );

        setEarningType(resp.data.earningsType);

        setEarningGraph(earningData);
        if (resp.data.earningsType === "year") {
          setEarningInterval(0);
        } else if (resp.data.earningsType === "month") {
          setEarningInterval(4);
        } else if (resp.data.earningsType === "week") {
          setEarningInterval(0);
        }
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        ToastMessage.Error(e.response.data.message);
      }
    }
  };
  const handleWithdrawal = async () => {
    if (!activeAccountNumber) {
      setActiveAccountNumberError("Please select bank account");
    } else if (activeAccountNumber) {
      setActiveAccountNumberError("");
    }

    let data = {
      amt: dashboard?.currentBal?.toFixed(2),
      bank_id: activeAccountNumber,
    };

    if (activeAccountNumber && dashboard?.currentBal) {
      try {
        let resp = await CommonAPi(Endpoints.WithdrawMoney, data);
        if (resp && resp.message) {
          getEarning();
          ToastMessage.Success(resp.message);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const getWithdrawal = async (value) => {
    setWithdrawalHistoryType(value);
    try {
      let resp = await CommonAPi(
        Endpoints.Earnings + "?withdrawal_by=" + value
      );
      if (resp && resp.message) {
        setWithdrawalHistory(resp.data.withdrawalHistory);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        ToastMessage.Error(e.response.data.message);
      }
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Earning</title>
      </Helmet>
      <div class="wrapper wrapper-bg">
        <div class="main">
          <LeftNavigation />
          <div class="container py-3">
            <Profile />
            <div class="row">
              <div class="col-md-3 col-sm-6 mb-3">
                <div class="job-item  d-flex align-items-center bg-white">
                  <span class="hastag-icon bg-primary">
                    <img src="assets/img/icon/icon-funds-earning.svg" alt="" />
                  </span>
                  <div class="ps-lg-3 ps-md-1 ps-2">
                    <h4 class="semibold pb-1">
                      ₹ {dashboard?.todayEarnings?.toFixed(2)}
                    </h4>
                    <h6 class="fs-14 regular">Today Earning</h6>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 mb-3">
                <div class="job-item  d-flex align-items-center bg-white">
                  <span class="hastag-icon bg-primary">
                    <img src="assets/img/icon/icon-wallet.svg" alt="" />
                  </span>
                  <div class="ps-lg-3 ps-md-1 ps-2">
                    <h4 class="semibold pb-1">
                      ₹ {dashboard?.currentBal?.toFixed(2)}
                    </h4>

                    <h6 class="fs-14 regular">Current Balance</h6>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 mb-3">
                <div class="job-item  d-flex align-items-center bg-white">
                  <span class="hastag-icon bg-primary">
                    <img src="assets/img/icon/icon-revenue-safe.svg" alt="" />
                  </span>
                  <div class="ps-lg-3 ps-md-1 ps-2">
                    <h4 class="semibold pb-1">
                      ₹ {dashboard?.totalRevenue?.toFixed(2)}
                    </h4>
                    <h6 class="fs-14 regular">Total Revenue</h6>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-6 mb-3">
                <div class="job-item  d-flex align-items-center bg-white">
                  <span class="hastag-icon bg-primary">
                    <img src="assets/img/icon/icon-withdrawals.svg" alt="" />
                  </span>
                  <div class="ps-lg-3 ps-md-1 ps-2">
                    <h4 class="semibold pb-1">
                      ₹ {dashboard?.totalWithdrawals?.toFixed(2)}
                    </h4>
                    <h6 class="fs-14 regular">Total Withdrawals</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="bg-white rounded-10 py-3">
                  <div class="d-flex align-items-center border-bottom pb-3 pt-2 justify-content-between px-3">
                    <h3 class="fs-22 medium">Earning statistics</h3>
                    <select
                      name=""
                      className="text-darkgray fs-14 w-auto"
                      id=""
                      onChange={(e) => {
                        handleEarningChart(e.target.value);
                      }}
                    >
                      <option
                        selected={earningType === "week" ? true : false}
                        value="week"
                      >
                        This Week{" "}
                      </option>
                      <option
                        selected={earningType === "month" ? true : false}
                        value="month"
                      >
                        This Month
                      </option>
                      <option
                        selected={earningType === "year" ? true : false}
                        value="year"
                      >
                        This Year
                      </option>
                    </select>
                  </div>

                  <ResponsiveContainer width="100%" aspect={1.4}>
                    <AreaChart
                      width={530}
                      height={650}
                      data={earningGraph}
                      margin={{
                        top: 15,
                        right: 30,
                        left: 15,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="1 1" vertical={false} />
                      <XAxis
                        dataKey="name"
                        tickLine={true}
                        axisLine={true}
                        interval={earningInterval}
                      />
                      <YAxis tickLine={false} axisLine={false} />
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey="₹"
                        stroke="#4E73DF"
                        strokeWidth={4}
                        fill="#F3F7FC"
                        dot={{
                          stroke: "#4E73DF",
                          strokeWidth: 1,
                          r: 4,
                          strokeDasharray: "",
                        }}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <div class="bg-white rounded-10 py-3">
                  <div class="d-flex align-items-center border-bottom pb-3 pt-2 justify-content-between px-3">
                    <h3 class="fs-22 medium">Withdraw Money</h3>
                    <a
                      href="#"
                      class="fs-14 text-brown"
                      data-bs-toggle="modal"
                      data-bs-target="#add_bank"
                    >
                      Add Bank Account +
                    </a>
                  </div>

                  <div
                    class="modal fade"
                    id="add_bank"
                    tabIndex="-1"
                    aria-labelledby="add_bank"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog rounded-10 box-shadow p-0 bg-white">
                      <div class="modal-content border-0 py-2">
                        <div class="d-flex align-items-center justify-content-between px-3 py-3 border-bottom w-100">
                          <h5 class="medium text-black fs-18">
                            Bank Account Detail
                          </h5>
                          <button
                            type="button"
                            class="btn-close cursor-pointer d-flex align-items-center justify-content-between bg-eee rounded-circle"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <img
                              src="assets/img/icon/icon-close-cancel.svg"
                              alt=""
                            />
                          </button>
                        </div>

                        <div class="modal-body">
                          <form
                            onSubmit={handleSubmitBank}
                            class="kids-form generic-form"
                            id="add-bank-account"
                          >
                            <div class="row">
                              <div class="col-md-12 mb-3">
                                <label>Account Holder Name</label>
                                <input
                                  type="text"
                                  placeholder="Account Holder Name"
                                  class=" text-gray px-3 py-3 border"
                                  onBlur={(e) => {
                                    handleValidation(
                                      e,
                                      e.target.value,
                                      "accountHolder"
                                    );
                                  }}
                                  value={accountHolder}
                                  onChange={(e) => {
                                    setAccountHolder(e.target.value);
                                  }}
                                  required
                                />
                                {accountHolderError ? (
                                  <div
                                    className="form-error"
                                    style={{
                                      color: "white",
                                      margintop: "-10px",
                                    }}
                                  >
                                    {accountHolderError}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div class="col-md-12 mb-3">
                                <label>Account Number</label>
                                <input
                                  type="text"
                                  placeholder="Account Number"
                                  class=" text-gray px-3 py-3 border"
                                  onBlur={(e) => {
                                    handleValidation(
                                      e,
                                      e.target.value,
                                      "accountNumber"
                                    );
                                  }}
                                  value={accountNumber}
                                  onChange={(e) => {
                                    setAccountNumber(e.target.value);
                                  }}
                                  required
                                />
                                {accountNumberError ? (
                                  <div
                                    className="form-error"
                                    style={{
                                      color: "white",
                                      margintop: "-10px",
                                    }}
                                  >
                                    {accountNumberError}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div class="col-md-12 mb-3">
                                <label>Confirm Account Number</label>
                                <div
                                  className="d-flex align-items-center"
                                  style={{ position: "relative" }}
                                >
                                  <input
                                    type={hidden ? "password" : "text"}
                                    placeholder="Confirm Account Number"
                                    class=" text-gray px-3 py-3 border"
                                    onBlur={(e) => {
                                      handleValidation(
                                        e,
                                        e.target.value,
                                        "confirmAccountNumber"
                                      );
                                    }}
                                    value={confirmAccountNumber}
                                    onChange={(e) => {
                                      setConfirmAccountNumber(e.target.value);
                                    }}
                                    required
                                  />
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      position: "absolute",
                                      right: 10,
                                    }}
                                    onClick={() => setHidden(!hidden)}
                                  >
                                    {hidden ? (
                                      <AiFillEyeInvisible size={25} />
                                    ) : (
                                      <AiFillEye size={25} />
                                    )}
                                  </div>
                                </div>
                                {accountNumberError ? (
                                  <div
                                    className="form-error"
                                    style={{
                                      color: "white",
                                      margintop: "-10px",
                                    }}
                                  >
                                    {accountNumberError}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div class="col-md-12 mb-3">
                                <label>IFSC Code</label>
                                <input
                                  type="text"
                                  placeholder="IFSC Code"
                                  class=" text-gray px-3 py-3 border"
                                  onBlur={(e) => {
                                    handleValidation(
                                      e,
                                      e.target.value,
                                      "ifscCode"
                                    );
                                  }}
                                  value={ifscCode}
                                  onChange={(e) => {
                                    setIfscCode(e.target.value);
                                  }}
                                  required
                                />
                                {ifscCodeError ? (
                                  <div
                                    className="form-error"
                                    style={{
                                      color: "white",
                                      margintop: "-10px",
                                    }}
                                  >
                                    {ifscCodeError}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div class="col-md-12 mb-3">
                                <label>Bank name</label>
                                <input
                                  type="text"
                                  placeholder="Bank name"
                                  class=" text-gray px-3 py-3 border"
                                  onBlur={(e) => {
                                    handleValidation(
                                      e,
                                      e.target.value,
                                      "bankName"
                                    );
                                  }}
                                  value={bankName}
                                  onChange={(e) => {
                                    setBankName(e.target.value);
                                  }}
                                  required
                                />
                                {bankNameError ? (
                                  <div
                                    className="form-error"
                                    style={{
                                      color: "white",
                                      margintop: "-10px",
                                    }}
                                  >
                                    {bankNameError}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div class="col-md-12 mb-3">
                                <label>Bank address</label>
                                <input
                                  type="text"
                                  placeholder="Bank address"
                                  class=" text-gray px-3 py-3 border"
                                  onBlur={(e) => {
                                    handleValidation(
                                      e,
                                      e.target.value,
                                      "bankAddress"
                                    );
                                  }}
                                  value={bankAddress}
                                  onChange={(e) => {
                                    setBankAddress(e.target.value);
                                  }}
                                  required
                                />
                                {bankAddressError ? (
                                  <div
                                    className="form-error"
                                    style={{
                                      color: "white",
                                      margintop: "-10px",
                                    }}
                                  >
                                    {bankAddressError}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div class="col-md-12 text-end">
                                <button type="submit" class="btn fs-14 ">
                                  Add Bank Account{" "}
                                  <img
                                    src="assets/img/icon/icon-right-arrow.svg"
                                    alt=""
                                  />
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="p-3 pb-0">
                    <div class="border rounded-2 card-list">
                      {bank.map((item, index) => {
                        return (
                          <div class="px-2 py-3 border-bottom d-flex align-items-center  justify-content-between">
                            <div>
                              <input
                                type="radio"
                                name="cardtype"
                                id={"bank" + (index + 1)}
                                onClick={() => {
                                  setActiveAccountNumber(item.id);
                                }}
                              />
                              <label
                                for={"bank" + (index + 1)}
                                class="medium text-black ps-2"
                              >
                                <img
                                  src="assets/img/icon/icon-bank.svg"
                                  alt=""
                                />{" "}
                                {item.bankName} - ({item.accountNumber})
                              </label>
                            </div>
                            {/* <a href="#" class="d-flex align-items-center justify-content-center ">
                                                            <svg viewBox="0 0 24 24" width="22" height="22" stroke="#000" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                                                        </a> */}
                          </div>
                        );
                      })}
                    </div>

                    <div class="d-flex align-items-center justify-content-between pt-3">
                      <div>
                        <h5 class="semibold text-black">
                          ₹ {dashboard?.currentBal?.toFixed(2)}
                        </h5>
                        <h6 class="regular text-black">Current Balance</h6>
                      </div>
                      <div>
                        <Link onClick={handleWithdrawal} class="btn fs-14">
                          Withdraw Money{" "}
                        </Link>
                      </div>
                    </div>
                    {activeAccountNumberError ? (
                      <div
                        className="form-error mt-1"
                        style={{ color: "white" }}
                      >
                        {activeAccountNumberError}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div class="bg-white rounded-15 py-3">
              <div class="d-flex w-100 align-items-center justify-content-between px-3 pt-2 pb-3  border-bottom">
                <h3 class="fs-22 medium ">Withdrawal History</h3>
                <select
                  name=""
                  onChange={(e) => {
                    getWithdrawal(e.target.value);
                  }}
                  id=""
                  class="text-darkgray fs-12 medium w-auto"
                >
                  <option
                    selected={withdrawalHistoryType === "week" ? true : false}
                    value="week"
                  >
                    This Week
                  </option>
                  <option
                    selected={withdrawalHistoryType === "month" ? true : false}
                    value="month"
                  >
                    This Month
                  </option>
                  <option
                    selected={withdrawalHistoryType === "year" ? true : false}
                    value="year"
                  >
                    This Year
                  </option>
                </select>
              </div>

              <div>
                {withdrawalHistory.map((item, index) => {
                  return (
                    <div
                      class="d-md-flex align-items-center justify-content-between py-3 px-3 border-bottom"
                      key={index}
                    >
                      <div>
                        <h5 class="medium pb-1">{item?.bank?.bankName}</h5>
                        <div class="order-meta">
                          <span class="fs-14 text-333">
                            <img
                              src="assets/img/icon/icon-indian-currency.svg"
                              alt=""
                            />
                            {item?.amount}
                          </span>

                          <span class="fs-14 text-333 ps-md-2 ps-1">
                            <img
                              src="assets/img/icon/icon-calendar.svg"
                              alt=""
                            />
                            &nbsp;
                            {moment(item?.createdAt).format("DD MMM YYYY")}
                          </span>

                          <span class="fs-14 text-333 ps-md-2 ps-1">
                            <img
                              src="assets/img/icon/icon-date-time2.svg"
                              alt=""
                            />
                            &nbsp;
                            {moment(item?.createdAt).format("h:m A")}
                          </span>
                          <p className="fs-12 text-333 mt-2 mb-1">
                            Ref Id: {item.id}
                          </p>
                          {item.cancelReason && item.status === "Cancelled" && (
                            <p
                              className="fs-12 text-danger"
                              style={{ fontWeight: "500" }}
                            >
                              <span>Reason:</span>
                              {item.cancelReason}
                            </p>
                          )}
                        </div>
                      </div>

                      <div class="withdraw-edit d-flex align-items-center gap-2 pt-md-0 pt-1">
                        {item?.status === "Pending" ? (
                          <span class="btn-orange fs-15">{item?.status}</span>
                        ) : item?.status === "Completed" ? (
                          <span class="btn-green fs-15">{item?.status}</span>
                        ) : (
                          <span class="btn-red fs-15">{item?.status}</span>
                        )}

                        {/* <span class="btn-pending fs-15">
                                                     {item?.status === "Completed" ? "Paid" :  item?.status}
                                                    </span> */}
                        {item?.status === "Completed" && (
                          <ReceiptBtn item={item} />
                        )}
                        {/* <a href="#" data-bs-toggle="dropdown" class="d-flex align-items-center justify-content-center rounded-circle bg-eee p-1" id="more-messages">
                                                <svg viewBox="0 0 24 24" width="18" height="18" stroke="#616161" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                                            </a> */}
                      </div>
                    </div>
                  );
                })}
                {withdrawalHistory.length == 0 ? (
                  <div class="px-3">
                    <p>No withdrawal history</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
