import moment from 'moment';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import GroupProfile from './GroupProfile';
import ManageGroup from './ManageGroup';
import PostItem from './PostItem';
import SimilarGroup from './SimilarGroup';
import { Helmet } from "react-helmet";
export default function GroupHome() {
    const [groupsData, setGroupsData] = useState('');
    const [descriptionLimit, setDescriptionLimit] = useState(115);
    const userId = localStorage.getItem("userId");
    return (
        <Container
            header
        >
            <Helmet>
                <title>Group Home</title>
            </Helmet>
            <div className="wrapper wrapper-bg">

                <div className="main">

                    <LeftNavigation />
                    <div className="container py-lg-3 py-3">
                        <GroupProfile {...{
                            setGroupsData: setGroupsData
                        }} />
                        <div className="row">
                            <div className="col-md-3 comany-dp-left pb-3">

                                <div className="sidebar-widget box-shadow">
                                    <div className="pb-3 d-flex align-items-start">
                                        <span>
                                            <img src="../assets/img/icon/icon-emplyee.svg" alt="" width="36" height="36" />
                                        </span>
                                        <div className="ps-2">
                                            <h4 className="medium">{groupsData?.totalMember}</h4>
                                            <h6 className="fs-12 text-darkgray regular">Members</h6>
                                        </div>
                                    </div>

                                    <div className="pb-3 d-flex align-items-start">
                                        <span>
                                            <img src="../assets/img/icon/icon-jobs-created.svg" alt="" width="36" height="36" />
                                        </span>
                                        <div className="ps-2">
                                            <h4 className="medium">0</h4>
                                            <h6 className="fs-12 text-darkgray regular">Admins & Managers</h6>
                                        </div>
                                    </div>


                                    <div className="d-flex align-items-start">
                                        <span>
                                            <img src="../assets/img/icon/icon-followers.svg" alt="" width="36" height="36" />
                                        </span>
                                        <div className="ps-2">
                                            <h4 className="medium">{moment(groupsData?.groupData?.created_at).format('MMM YYYY')}</h4>
                                            <h6 className="fs-12 text-darkgray regular">Created</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="sidebar-widget mt-3 box-shadow pb-3">
                                    <h5 className="medium border-bottom pb-2 mb-2">About Group</h5>
                                    {/* <p className="fs-14">{groupsData?.groupData?.description}</p> */}

                                    {groupsData?.groupData?.description?.length > descriptionLimit ?
                                        groupsData?.groupData?.description.substring(0, descriptionLimit) + "..." :
                                        groupsData?.groupData?.description
                                    }
                                    {groupsData?.groupData?.description?.length == descriptionLimit ?
                                        ""
                                        :
                                        <Link className="btn gray-btn w-100 text-black fs-14 medium mt-3" onClick={() => {
                                            setDescriptionLimit(groupsData?.groupData?.description?.length)
                                        }}>Read More</Link>
                                    }

                                    {/* <a href="#" className="btn bg-d8d8d8 w-100 text-black fs-14 medium">Read More</a> */}
                                </div>


                            </div>


                            <div className="col-md-6 feed-mid-column1 pb-3">

                                {groupsData?.isJoin?.status === "PENDING" || groupsData?.isJoin?.status === "REJECTED" || groupsData?.isJoin?.status === undefined && groupsData?.groupData?.groupType === "Private" && groupsData?.groupData?.userId != userId ?
                                    <div className="px-4 py-4 bg-white mb-3 rounded-15 shadow-md">


                                        <h5>Welcome to {groupsData?.groupData?.groupName}</h5>

                                        <p className='fs-13 mt-1'>
                                            This is a private group, which means its content and discussions are restricted to approved members only. While you can't view the group's content without joining, here's a brief overview:
                                        </p>
                                        <h5>
                                            Group Purpose:
                                        </h5>

                                        <p className='fs-13 mt-1'>
                                            {groupsData?.groupData?.description}
                                        </p>
                                        <h5>
                                            Privacy:
                                        </h5>
                                        <p className='fs-13 mt-1'>
                                            This group is designed for members who share a common interest or goal. To access the discussions and content, you'll need to request to join.
                                        </p>
                                        <p className='fs-13 mt-1'>
                                            If you're interested in becoming a member and participating in the group's discussions, click the 'Join' button. The group administrators will review your request, and once approved, you'll gain access to the group.
                                        </p>
                                        <p className='fs-13 mt-1'>
                                            Thank you for your interest in {groupsData?.groupData?.groupName}. We look forward to welcoming you as a member!
                                        </p>

                                    </div>
                                    :
                                    <PostItem
                                        {...{
                                            groupsData: groupsData
                                        }} />
                                }

                            </div>


                            {groupsData?.groupData?.userId == userId ?
                                <ManageGroup />
                                :
                                <SimilarGroup />
                            }

                        </div>
                        <OnlineFriends />
                    </div>
                </div>
            </div>
        </Container>
    )
}
