import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import Tooltip from "./Tooltip";

export default function VideoPlayer({
  src = "",
  height,
  width,
  onPrevious,
  onNext,
  onComplete,
  secondsPassed,
}) {
  const timeout = useRef(null);

  // clear timeout in case of unmount
  useEffect(
    () => () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    },
    []
  );

  const videoContainerRef = useRef(null);

  const playbackRateOptionsRef = useRef(null);

  const videoSettingsRef = useRef(null);

  const playerRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [controlOpacity, setControlOpacity] = useState(1);

  const [playbackRate, setPlaybackRate] = useState(1);

  const [playing, setPlaying] = useState(false);

  const [videoDuration, setVideoDuration] = useState(0);

  const [progress, setProgress] = useState(0);

  const [loaded, setLoaded] = useState(0);

  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(1);

  const [availableVideoResolution, setAvailableVideoResolution] = useState([]);
  const [currentVideoResolution, setCurrentVideoResolution] = useState(-1);

  const [showVolumeSlider, setShowVolumeSlider] = useState(false);

  const [showPlaybackOptions, setShowPlaybackOptions] = useState(false);

  const [showVideoSettings, setShowVideoSettings] = useState(false);

  const cancelTimeout = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  }, []);

  const refreshTimeout = useCallback(() => {
    cancelTimeout();
    timeout.current = setTimeout(() => {
      setControlOpacity(0);
    }, 3000);
  }, [cancelTimeout]);

  useEffect(() => {
    refreshTimeout();
  }, []);

  // auto hide controls
  const handleMouseMove = useCallback(() => {
    setControlOpacity(1);
    refreshTimeout();
  }, [playing, cancelTimeout, refreshTimeout]);

  const handleMouseLeave = useCallback(() => {
    setControlOpacity(0);
  }, [playing, refreshTimeout]);

  const handleBuffer = useCallback(() => {
    setLoading(true);
  }, []);

  const handleBufferEnd = useCallback(() => {
    setLoading(false);
  }, []);

  const handleDuration = useCallback((seconds) => {
    setVideoDuration(seconds);
  }, []);

  const handleProgress = useCallback(
    ({ played, playedSeconds, loaded, loadedSeconds }) => {
      setProgress(playedSeconds);
      setLoaded(loadedSeconds);
      secondsPassed(Math.ceil(playedSeconds));
    },
    [secondsPassed]
  );

  const handleEnd = useCallback(() => {
    setPlaying(false);
    setControlOpacity(1);
    cancelTimeout();
    onComplete();
  }, [cancelTimeout, onComplete]);

  const onPlayPause = useCallback(() => {
    if (playing) {
      setControlOpacity(1);
      cancelTimeout();
    } else refreshTimeout();
    setPlaying(!playing);
  }, [refreshTimeout, cancelTimeout, playing]);

  // listening for spacebar
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.keyCode === 32) {
        e.preventDefault();
        onPlayPause();
      }
    };
    document.addEventListener("keyup", handleKeyPress);
    return () => {
      document.addEventListener("keyup", handleKeyPress);
    };
  }, []);

  const handleSeek = useCallback((e) => {
    if (!playerRef.current) return;
    // seeking to a percent
    playerRef.current.seekTo(e.target.value / 100, "fraction");
  }, []);

  const handleSeekBackward = useCallback(() => {
    if (!playerRef.current) return;
    const seekTo = progress - 5;
    playerRef.current.seekTo(seekTo < 0 ? 0 : seekTo, "seconds");
  }, [progress]);

  const handleSeekForward = useCallback(() => {
    if (!playerRef.current) return;
    const seekTo = progress + 5;
    playerRef.current.seekTo(
      seekTo > videoDuration ? videoDuration : seekTo,
      "seconds"
    );
  }, [progress, videoDuration]);

  const toggleMute = useCallback(() => {
    setMuted((currentValue) => {
      const nextValue = !currentValue;
      // when user unmute and volume is 0, volume set to 30%
      if (nextValue)
        setVolume((currentVolume) =>
          currentVolume < 0.1 ? 0.3 : currentVolume
        );
      return nextValue;
    });
  }, []);

  const handleShowVolumeSlider = useCallback(() => {
    setShowVolumeSlider(true);
  }, []);
  const handleHideVolumeSlider = useCallback(() => {
    setShowVolumeSlider(false);
  }, []);

  const handleVolume = useCallback((e) => {
    const { value } = e.target;
    setVolume(value);
    setMuted(false);
    if (value < 0.05) setMuted(true);
  }, []);

  const handlePlaybackRate = useCallback(
    (value) => () => {
      setPlaybackRate(value);
      setShowPlaybackOptions(false);
    },
    []
  );

  const togglePlaybackOptions = useCallback(() => {
    setShowPlaybackOptions((currentValue) => !currentValue);
  }, []);

  const handleResolution = useCallback(
    (index) => () => {
      // set resolution
      if (playerRef.current) {
        const hls = playerRef.current.getInternalPlayer("hls");
        // as the rendering is reversed, we calculate proper quality that's selected
        const selectedLevel = index === -1 ? -1 : hls.levels.length - 1 - index;
        hls.currentLevel = selectedLevel;
        setCurrentVideoResolution(index);
      }
      setShowVideoSettings(false);
    },
    []
  );

  useEffect(() => {
    if (playerRef.current) {
      const basic = playerRef.current.getInternalPlayer();
      const hls = playerRef.current.getInternalPlayer("hls");
      const dash = playerRef.current.getInternalPlayer("dash");
      console.log({ basic, hls, dash });
      setAvailableVideoResolution(hls ? hls.levels : []);
    }
  }, []);

  const toggleSettingsOptions = useCallback(() => {
    setShowVideoSettings((currentValue) => !currentValue);
  }, []);

  const toggleFullScreen = useCallback(() => {
    if (!videoContainerRef.current) return null;

    // if not in fullscreen
    if (
      !(
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement
      )
    ) {
      if (videoContainerRef.current.requestFullscreen) {
        videoContainerRef.current.requestFullscreen();
      } else if (videoContainerRef.current.webkitRequestFullscreen) {
        /* Safari */
        videoContainerRef.current.webkitRequestFullscreen();
      } else if (videoContainerRef.current.msRequestFullscreen) {
        /* IE11 */
        videoContainerRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }
  }, [cancelTimeout]);

  // hide popup when clicked in any place
  useEffect(() => {
    const handleClick = (e) => {
      if (
        playbackRateOptionsRef.current &&
        !playbackRateOptionsRef.current.contains(e.target)
      ) {
        setShowPlaybackOptions(false);
      }
      if (
        videoSettingsRef.current &&
        !videoSettingsRef.current.contains(e.target)
      ) {
        setShowVideoSettings(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  // calculating and memoizeing values

  const progressPercent = useMemo(() => {
    if (progress === 0 || videoDuration == 0) return 0;
    return (progress * 100) / videoDuration;
  }, [progress, videoDuration]);

  const loadedPercent = useMemo(() => {
    if (loaded === 0 || videoDuration == 0) return 0;
    return (loaded * 100) / videoDuration;
  }, [loaded, videoDuration]);

  const memoizedVideoResolutions = useMemo(() => {
    return availableVideoResolution.reverse().map((quality, index) => {
      return (
        <button
          key={quality.height}
          onClick={handleResolution(index)}
          className="playback-rate-button w-100 py-3"
          style={{
            background: currentVideoResolution === index ? "#ddd" : undefined,
          }}
        >
          {`${quality.height}p`}
        </button>
      );
    });
  }, [availableVideoResolution, handleResolution, currentVideoResolution]);

  return (
    <div
      ref={videoContainerRef}
      className="position-relative h-100 w-100"
      style={{
        height,
        width,
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <ReactPlayer
        ref={playerRef}
        className="react-player"
        url={src}
        playing={playing}
        controls={false}
        playsinline
        volume={volume}
        pip
        muted={muted}
        playbackRate={playbackRate}
        stopOnUnmount
        width="100%"
        height="100%"
        onBuffer={handleBuffer}
        onBufferEnd={handleBufferEnd}
        onReady={handleBufferEnd}
        onDuration={handleDuration}
        onProgress={handleProgress}
        onEnded={handleEnd}
      />
      {/* loading view */}
      {loading && (
        <div
          className="position-absolute top-0 bottom-0 start-0 end-0 d-flex justify-content-center align-items-center"
          style={{ background: "rgba(0,0,0,0.4)" }}
        >
          <div className="spinner-border text-light" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {/* player controls */}
      <div
        className="position-absolute bottom-0 start-0 end-0 d-flex flex-column justify-content-between align-items-center"
        onMouseLeave={handleHideVolumeSlider}
        style={{
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%)",
          opacity: controlOpacity,
          transition: "opacity 0.5s ease-out",
          gap: 10,
          padding: "16px 16px 16px 10px",
        }}
      >
        <div
          className="w-100 position-relative"
          style={{
            height: 4,
            background: "rgba(255, 255, 255, 0.5)",
          }}
        >
          {/* loaded progress */}
          <div
            className="video-progress position-absolute start-0 h-100 rounded"
            style={{
              width: `${loadedPercent}%`,
              background: "rgba(255, 255, 255)",
            }}
          />
          {/* watch progress */}
          <div
            className="video-progress position-absolute start-0 h-100 rounded"
            style={{ width: `calc(${progressPercent}% + 1px)` }}
          />
          <input
            type="range"
            className="video-range-input"
            min={0}
            max={100}
            value={progressPercent}
            onChange={handleSeek}
          />
        </div>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div className="d-flex gap-3 align-items-center learning-tooltip">
            <Tooltip
              anchor={videoContainerRef.current}
              title={playing ? "Pause" : "Play"}
            >
              <button
                className="bg-transparent border-0 d-flex align-items-center text-white"
                onClick={onPlayPause}
              >
                {playing ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/svgs/pause.svg`}
                    alt="play"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/svgs/play.svg`}
                    alt="play"
                  />
                )}
              </button>
            </Tooltip>
            <Tooltip anchor={videoContainerRef.current} title="Rewind 5s">
              <button
                onClick={handleSeekBackward}
                className="bg-transparent border-0 d-flex align-items-center"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/svgs/seek-backward.svg`}
                  alt="seek-backward"
                />
              </button>
            </Tooltip>
            <div ref={playbackRateOptionsRef} className="position-relative">
              <Tooltip
                anchor={videoContainerRef.current}
                title="Set playback rate"
              >
                <button
                  className="playback-rate-button"
                  style={{
                    borderRadius: "4px",
                  }}
                  onClick={togglePlaybackOptions}
                >{`${playbackRate} x`}</button>
              </Tooltip>
              {/* playback rates */}
              {showPlaybackOptions && (
                <div
                  className="position-absolute bottom-0 start-50 end-0 rounded overflow-hidden d-flex align-items-stretch flex-column flex-nowrap"
                  style={{
                    marginBottom: 32,
                    width: 80,
                    transform: "translate(-50%, 0)",
                  }}
                >
                  <button
                    onClick={handlePlaybackRate(5)}
                    className="playback-rate-button w-100 py-3"
                  >
                    5x
                  </button>
                  <button
                    onClick={handlePlaybackRate(2)}
                    className="playback-rate-button w-100 py-3"
                  >
                    2x
                  </button>
                  <button
                    onClick={handlePlaybackRate(1.5)}
                    className="playback-rate-button w-100 py-3"
                  >
                    1.5x
                  </button>
                  <button
                    onClick={handlePlaybackRate(1)}
                    className="playback-rate-button w-100 py-3"
                  >
                    1x
                  </button>
                </div>
              )}
            </div>
            <Tooltip anchor={videoContainerRef.current} title="Forward 5s">
              <button
                onClick={handleSeekForward}
                className="bg-transparent border-0 d-flex align-items-center"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/svgs/seek-forward.svg`}
                  alt="seek-forward"
                />
              </button>
            </Tooltip>
            <p
              className="mb-0"
              style={{
                fontSize: 12,
                lineHeight: "16px",
                fontWeight: 700,
                color: "white",
              }}
            >
              {moment.utc(progress * 1000).format("mm.ss")} /{" "}
              {moment.utc(videoDuration * 1000).format("mm.ss")}
            </p>
          </div>
          <div className="d-flex gap-3 align-items-center">
            <Tooltip anchor={videoContainerRef.current} title="Volume">
              <div
                className="d-flex gap-2 align-items-center"
                onMouseEnter={handleShowVolumeSlider}
              >
                <button
                  onClick={toggleMute}
                  className="bg-transparent border-0 position-relative"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/svgs/volume.svg`}
                    alt="volume"
                  />
                  <div
                    style={{
                      position: "absolute",
                      transform: "rotate(-45deg) translate(-50%, -45%)",
                      transformOrigin: "bottom left",
                      top: "50%",
                      left: "50%",
                      height: 3,
                      width: muted ? "100%" : 0,
                      background: "white",
                      transition: "width 0.2s ease-in",
                    }}
                  />
                </button>
                <div
                  className="progress position-relative"
                  style={{
                    height: "10px",
                    transition: "width 0.3s ease-out",
                    width: showVolumeSlider ? 150 : 0,
                  }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: muted ? 0 : `${volume * 100}%`,
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                  <input
                    type="range"
                    step={0.05}
                    className="video-volume-range"
                    min={0}
                    max={1}
                    value={volume}
                    onChange={handleVolume}
                  />
                </div>
              </div>
            </Tooltip>
            {/* video resolution */}
            <div ref={videoSettingsRef} className="position-relative">
              <Tooltip
                anchor={videoContainerRef.current}
                title="Video Settings"
              >
                <button
                  onClick={toggleSettingsOptions}
                  className="bg-transparent border-0"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/svgs/settings.svg`}
                    alt="change-resolution"
                  />
                </button>
              </Tooltip>
              {showVideoSettings && (
                <div
                  className="position-absolute bottom-0 start-50 end-0 rounded overflow-hidden d-flex align-items-stretch flex-column flex-nowrap"
                  style={{
                    marginBottom: 32,
                    width: 80,
                    transform: "translate(-50%, 0)",
                  }}
                >
                  <button
                    onClick={handleResolution(-1)}
                    className="playback-rate-button w-100 py-3"
                    style={{
                      background:
                        currentVideoResolution === -1 ? "#ddd" : undefined,
                    }}
                  >
                    Auto
                  </button>
                  {memoizedVideoResolutions}
                </div>
              )}
            </div>
            <Tooltip
              anchor={videoContainerRef.current}
              title="Toggle fullscreen"
            >
              <button
                className="bg-transparent border-0"
                onClick={toggleFullScreen}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/svgs/fullscreen.svg`}
                  alt="toggle-fullscreen"
                />
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      {/* next and previous buttons   */}
      {onPrevious && (
        <Tooltip
          anchor={videoContainerRef.current}
          title="Previous"
          className="position-absolute top-50 start-0 translate-middle-y"
        >
          <button
            onClick={onPrevious}
            className="px-3 py-2 border"
            style={{ background: "rgba(0,0,0,0.8)", opacity: controlOpacity }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/svgs/chevron-left.svg`}
              alt="previous"
            />
          </button>
        </Tooltip>
      )}
      {onNext && (
        <Tooltip
          anchor={videoContainerRef.current}
          title="Next"
          className="position-absolute top-50 end-0 translate-middle-y"
        >
          <button
            onClick={onNext}
            className="px-3 py-2 border"
            style={{
              background: "rgba(0,0,0,0.8)",
              opacity: controlOpacity,
              transition: "opacity 0.5s ease-out",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/svgs/chevron-right.svg`}
              alt="next"
            />
          </button>
        </Tooltip>
      )}
    </div>
  );
}
