import React, { useCallback, useEffect, useRef, useState } from "react";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate } from "react-router-dom";
import TopHeader from "../../Components/TopHeader";
import { FetchApi } from "../../API/FetchApi";
import Welcome from "./Welcome";
import { getOrientation } from "get-orientation/browser";
import { getCroppedImg, getRotatedImage } from "../../Utils/Canvas/canvas_util";
import Cropper from "react-easy-crop";
import ProfileCropper from "../../Components/ImageCropperModal/ProfileCropper";
import { Helmet } from "react-helmet";
const $ = window.jQuery;
const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};
export default function ImageUpload() {
  const [ProfilePicture, setProfilePicture] = useState("");
  const [CoverPicture, setCoverPicture] = useState("");
  const [profileCompleteness, setProfileCompleteness] = useState(
    Math.round(localStorage.getItem("ProfileCompletion"))
  );
  const userId = localStorage.getItem("userId");
  const userRoleType = localStorage.getItem("userRoleType");
  const Navigate = useNavigate();

  const [ProfilePictureError, setProfilePictureError] = useState("");
  const [CoverPictureError, setCoverPictureError] = useState("");

  const [getProfilePicture, setGetProfilePicture] = useState("");
  const [getCoverPicture, setGetCoverPicture] = useState(null);
  const [coverImageSrc, setCoverImageSrc] = useState(null);
  const [profileImageSrc, setProfileImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [show, setShow] = useState(false);
  const [ogCover, setOgCover] = useState("");
  const profileRef = useRef(null);
  const [file, setFile] = useState();
  useEffect(() => {
    getImage();
    // var percent = 60;
    if (userRoleType === "child") {
      var percent = 100;
    } else {
      var percent = 60;
    }
    percent = percent.toFixed();
    $(".progress-bar").css("width", percent + "%");
  }, []);

  const getImage = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetProfile + "?id=" + userId);
      if (resp && resp.success === true) {
        setGetProfilePicture(resp.data.user_profile.profileImg);
        setGetCoverPicture(resp.data.user_profile.coverImg);
        setOgCover(resp.data.user_profile.coverImg);
        if (resp.data.user_profile.profileImg) {
          setProfilePicture({
            fileUrl: Endpoints.baseUrl + resp.data.user_profile.profileImg,
          });
        }
        if (resp.data.user_profile.coverImg) {
          setCoverPicture({
            fileUrl: Endpoints.baseUrl + resp.data.user_profile.coverImg,
          });
        }
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const hadnleProfilePicture = async (e) => {
    setShow(true);
    setProfilePictureError("");
    setProfilePicture({
      fileBytes: e.target.files[0],
      fileUrl: URL.createObjectURL(e.target.files[0]),
    });
    let imageDataURL = await readFile(e.target.files[0]);
    const orientation = await getOrientation(e.target.files[0]);
    const rotation = ORIENTATION_TO_ANGLE[orientation];
    if (rotation) {
      imageDataURL = await getRotatedImage(imageDataURL, rotation);
    }

    setProfileImageSrc(imageDataURL);
  };

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      const { file, url } = await getCroppedImg(
        coverImageSrc,
        croppedAreaPixels
      );
      setCroppedAreaPixels(croppedAreaPixels);
      setCroppedImage(url);
      setFile(file);
      setGetCoverPicture(null);
    },
    [coverImageSrc]
  );

  // const showCroppedImage = useCallback(async () => {
  //     try {
  //         const {file, url} = await getCroppedImg(
  //             coverImageSrc,
  //             croppedAreaPixels,
  //             rotation
  //         )
  //         setCroppedImage(url)
  //         setFile(file)
  //         } catch (e) {
  //             console.error(e)
  //         }
  // }, [coverImageSrc, croppedAreaPixels, rotation] )

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const hadnleCoverPicture = async (e) => {
    setCoverPictureError("");
    setCoverPicture({
      fileBytes: e.target.files[0],
      fileUrl: URL.createObjectURL(e.target.files[0]),
    });
    let imageDataURL = await readFile(e.target.files[0]);
    const orientation = await getOrientation(e.target.files[0]);
    const rotation = ORIENTATION_TO_ANGLE[orientation];
    if (rotation) {
      imageDataURL = await getRotatedImage(imageDataURL, rotation);
    }
    setCoverImageSrc(imageDataURL);
  };
  const uploadCoverPicture = async () => {
    let newFile = new File([file], "image.jpg", { type: "image/jpg" });
    const formData = new FormData();
    formData.append("user-picture", newFile);
    console.log(formData.get("user-picture"));
    try {
      let resp = await CommonAPi(Endpoints.CoverPicture, formData);
      if (resp && resp.success === true) {
        setProfileCompleteness(parseFloat(profileCompleteness) + 12.5);
        window.localStorage.setItem(
          "ProfileCompletion",
          parseFloat(profileCompleteness) + 12.5
        );
        setCoverImageSrc(null);
        setGetCoverPicture(resp.data.cover);
        setOgCover(resp.data.cover);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleNext = async (event) => {
    event.preventDefault();
    if (!CoverPicture && !ProfilePicture) {
      setCoverPictureError("Please upload cover and profile picture");
    } else if (!CoverPicture || !getCoverPicture) {
      setCoverPictureError("Please upload cover picture");
    } else if (!ProfilePicture || !getProfilePicture) {
      setProfilePictureError("Please upload profile picture");
    } else if (CoverPicture && ProfilePicture) {
      setCoverPictureError("");
      setProfilePictureError("");
      if (userRoleType === "child") {
        getProfile();
      } else {
        window.localStorage.setItem("ProfileCompletion", profileCompleteness);
        Navigate("/complete-profile-step3" + "/" + userId);
      }
    }
  };

  const getProfile = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetProfile + "?id=" + userId);
      if (resp && resp.success === true) {
        window.localStorage.setItem("user", JSON.stringify(resp?.data));
        window.localStorage.setItem("ProfileCompletion", "100.00");
        window.localStorage.setItem(
          "ProfileTagline",
          resp?.data?.user_profile?.profileTagline
        );
        window.localStorage.setItem(
          "userProfile",
          resp?.data?.user_profile?.profileImg
        );
        window.localStorage.setItem(
          "userProfileCoverImage",
          resp?.data?.user_profile?.coverImg
        );
        Navigate("/mainfeed");
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>Image Upload</title>
      </Helmet>
      <TopHeader />
      <div className="container-fluid bg-f5f5f5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 text-center ">
            <div className="card  bg-f5f5f5">
              <Welcome />

              <div id="msform">
                <ul
                  id="progressbar"
                  style={{
                    "--value": userRoleType === "child" ? "33%" : "20%",
                  }}
                >
                  <li className="active" id="personal">
                    <strong>Interests</strong>
                  </li>
                  <li className="active" id="personal">
                    <strong>Basic Details</strong>
                  </li>
                  <li className="active" id="payment">
                    <strong>Image Upload</strong>
                  </li>
                  {userRoleType === "child" ? (
                    ""
                  ) : (
                    <li id="account">
                      <strong>Verification</strong>
                    </li>
                  )}
                  {userRoleType === "child" ? (
                    ""
                  ) : userRoleType === "student" ? (
                    <li id="confirm">
                      <strong>Academics</strong>
                    </li>
                  ) : (
                    <li id="confirm">
                      <strong>Experience</strong>
                    </li>
                  )}
                </ul>
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {userRoleType === "child" ? "100 % " : "60 %"}
                  </div>
                </div>
                <br />
                <fieldset>
                  <div className="form-card">
                    <div className="row">
                      <div className="col-7">
                        <h2 className="fs-title">
                          Upload profile and cover picture:
                        </h2>
                      </div>
                      <div className="col-5">
                        {userRoleType === "child" ? (
                          <h2 className="steps">Step 3 - 3</h2>
                        ) : (
                          <h2 className="steps">Step 3 - 5</h2>
                        )}
                      </div>
                    </div>
                    <div className=" ">
                      <form action="" className="profile-form">
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="position-relative"
                              id="profile-cover-container"
                            >
                              {coverImageSrc && (
                                <>
                                  <div>
                                    <Cropper
                                      image={coverImageSrc}
                                      crop={crop}
                                      rotation={rotation}
                                      zoom={zoom}
                                      aspect={8 / 2}
                                      showGrid={false}
                                      onCropChange={setCrop}
                                      onRotationChange={setRotation}
                                      onCropComplete={onCropComplete}
                                      onZoomChange={setZoom}
                                    />
                                  </div>
                                </>
                              )}

                              {getCoverPicture ? (
                                <img
                                  src={Endpoints.baseUrl + getCoverPicture}
                                  alt=""
                                  className={
                                    CoverPictureError
                                      ? "is-danger  profile-edit w-100"
                                      : "profile-edit w-100"
                                  }
                                  style={{
                                    height: "200px",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <img
                                  src="../assets/img/cover-pic.png"
                                  alt=""
                                  className={
                                    CoverPictureError
                                      ? "is-danger  profile-edit w-100"
                                      : "profile-edit w-100"
                                  }
                                  style={{
                                    height: "200px",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                              <div className="upload-coverpic position-absolute bottom-0 end-0 m-2">
                                {coverImageSrc ? (
                                  <div>
                                    <label
                                      for=""
                                      onClick={uploadCoverPicture}
                                      className="fs-14 medium bg-white rounded-pill px-2 py-1  cursor-pointer"
                                    >
                                      done
                                    </label>

                                    <span
                                      style={{
                                        borderRadius: "10px",
                                        marginLeft: "7px",
                                      }}
                                      onClick={() => {
                                        setGetCoverPicture(ogCover);
                                        setCoverImageSrc(null);
                                      }}
                                      className="fs-12 medium bg-danger  px-1 py-1 cursor-pointer"
                                    >
                                      <svg
                                        viewBox="0 0 24 24"
                                        width="14"
                                        height="14"
                                        stroke="#fff"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        class="css-i6dzq1"
                                      >
                                        <polyline points="3 6 5 6 21 6"></polyline>
                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                        <line
                                          x1="10"
                                          y1="11"
                                          x2="10"
                                          y2="17"
                                        ></line>
                                        <line
                                          x1="14"
                                          y1="11"
                                          x2="14"
                                          y2="17"
                                        ></line>
                                      </svg>
                                    </span>
                                  </div>
                                ) : (
                                  <>
                                    <input
                                      type="file"
                                      accept="image/*"
                                      id="coverupload"
                                      className="d-none"
                                      onChange={(e) => {
                                        hadnleCoverPicture(e);
                                      }}
                                    />
                                    <label
                                      for="coverupload"
                                      className="fs-14 medium bg-white rounded-pill px-3 py-2 cursor-pointer"
                                    >
                                      Update Cover{" "}
                                      <img
                                        src="../assets/img/icon/upload-icon.svg"
                                        alt=""
                                      />
                                    </label>
                                  </>
                                )}
                              </div>
                            </div>

                            <div className="pic-edit">
                              {getProfilePicture ? (
                                <img
                                  src={Endpoints.baseUrl + getProfilePicture}
                                  alt=""
                                  width="96"
                                  height="96"
                                  className={
                                    ProfilePictureError
                                      ? "is-danger  rounded-circle"
                                      : "rounded-circle"
                                  }
                                />
                              ) : (
                                <img
                                  src="../assets/img/picture.png"
                                  alt=""
                                  width="96"
                                  height="96"
                                  className={
                                    ProfilePictureError
                                      ? "is-danger  rounded-circle"
                                      : "rounded-circle"
                                  }
                                />
                              )}
                              <div>
                                <input
                                  type="file"
                                  id="profilepic"
                                  accept="image/*"
                                  ref={profileRef}
                                  className="d-none"
                                  onChange={(e) => {
                                    hadnleProfilePicture(e);
                                    setShow(true);
                                  }}
                                />
                                <label
                                  for="profilepic"
                                  className="cursor-pointer d-flex"
                                >
                                  <img
                                    src="../assets/img/icon/icon-camera4.svg"
                                    alt=""
                                  />
                                </label>
                              </div>
                            </div>
                            {CoverPictureError && ProfilePictureError ? (
                              <p style={{ color: "red", margintop: "-10px" }}>
                                Please upload cover picture and cover image
                              </p>
                            ) : (
                              <>
                                {CoverPictureError ? (
                                  <p
                                    style={{ color: "red", margintop: "-10px" }}
                                  >
                                    {CoverPictureError}
                                  </p>
                                ) : (
                                  ""
                                )}
                                {ProfilePictureError ? (
                                  <p
                                    style={{ color: "red", margintop: "-10px" }}
                                  >
                                    {ProfilePictureError}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                    {coverImageSrc && (
                      <div className="cover-slider-container">
                        <span>Zoom</span>
                        <div className="cover-slider">
                          <span className="slider-icons">-</span>
                          <input
                            type="range"
                            min="1"
                            max="3"
                            step="0.1"
                            value={zoom}
                            onChange={(e) => setZoom(e.target.value)}
                          />
                          <span className="slider-icons">+</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <Link
                      to={"/complete-profile-step1" + "/" + userId}
                      className="previous btn"
                    >
                      Previous
                    </Link>
                    <button
                      type="submit"
                      disabled={
                        getCoverPicture == null
                          ? true
                          : getProfilePicture == null
                          ? true
                          : false
                      }
                      name="next"
                      className="btn next px-4"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
      {profileImageSrc && (
        <ProfileCropper
          setShow={setShow}
          show={show}
          profileImageSrc={profileImageSrc}
          setGetProfilePicture={setGetProfilePicture}
          profileRef={profileRef}
        />
      )}
    </>
  );
}
