import React, { useEffect, useState } from "react";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import ToastMessage from "../../Utils/ToastMessage";
import AlbumImageModal from "../../Components/PostImageModal/AlbumImageModal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DeleteApi } from "../../API/DeleteApi";
import Swal from "sweetalert2";
const postReactions = [
  { id: "Like", img: "../assets/img/icon/Like.svg" },
  { id: "Love", img: "../assets/img/icon/Love.svg" },
  { id: "Haha", img: "../assets/img/icon/Haha.svg" },
  { id: "Care", img: "../assets/img/icon/Care.svg" },
  { id: "Wow", img: "../assets/img/icon/Wow.svg" },
  { id: "Sad", img: "../assets/img/icon/Sad.svg" },
  { id: "Angry", img: "../assets/img/icon/Angry.svg" },
];

export default function AlbumItem({ albumData, index, post, onSuccess }) {
  const [currentPostId, setCurrentPostId] = useState(null);
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPostImages, setCurrentPostImages] = useState([]);
  const [sharePost, setSharePost] = useState(null);
  const [modalState, setModalState] = useState("hide");
  const [totalPostReactions, setTotalPostReactions] = useState([]);
  const [reactionModalOpen, setReactionModalOpen] = useState("hide");
  const [loading, setLoading] = useState(false);
  const [hasMorePosts, setHasMorePosts] = useState(false);
  const [page, setPage] = useState(1);
  const [mediaComments, setMediaComments] = useState([]);
  const [inputValue, setInputValue] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useNavigate();
  const [reactionModalVar, setReactionModalVar] = useState(true);
  const [showModal, setShowModal] = useState("show");
  const [vControl, setVControl] = useState(true);
  const [mouserHovering, setMouserHovering] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const username = localStorage.getItem("userName");
  const params = useParams();

  const [allShow, setAllShow] = useState([]);
  const getSinglePostData = async () => {
    let id = post.id;
    try {
      let resp = await CommonAPi(Endpoints.GetSinglePost + id);
      if (resp && resp.success === true) {
        setAllShow([...resp.data.post_medias]);
        console.log("setAllShow data", allShow);
        // setAllShow(resp.data);
        // console.log("setAllShow data", allShow)
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  useEffect(() => {
    if (reactionModalVar) allReactions(currentPostId);
  }, [page]);

  // get post reactions
  const allReactions = async (id, reactionType = "") => {
    setModalState("hide");
    if (id == null) return;
    setCurrentPostId(id);
    try {
      let resp = await CommonAPi(
        Endpoints.GetReactionsOfAPost +
          id +
          `?page=${page}&reaction_type=${reactionType}`
      );
      if (resp && resp.success === true) {
        if (resp.data.length === 0) {
          setLoading(false);
          setHasMorePosts(false);
        }
        if (reactionType) setTotalPostReactions(resp.data);
        else {
          setTotalPostReactions([...totalPostReactions, ...resp.data]);
        }
        setLoading(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleImgClick = (post, item, index) => {
    setCurrentPostImages(post);
    setCurrentIndex(index);
    setClickedImg(Endpoints.baseUrl + item.filePath);
  };

  const handleNextBtn = () => {
    const totalLength = currentPostImages.post_medias.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = currentPostImages.post_medias[0].filePath;
      setClickedImg(Endpoints.baseUrl + newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = currentPostImages.post_medias.filter((item) => {
      return currentPostImages.post_medias.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].filePath;
    setClickedImg(Endpoints.baseUrl + newItem);
    setCurrentIndex(newIndex);
    setMediaComments(null);
    setIsEditing(false);
    setInputValue(null);
  };

  const handlePrevBtn = () => {
    const totalLength = currentPostImages.post_medias.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = currentPostImages.post_medias[totalLength - 1].filePath;
      setClickedImg(Endpoints.baseUrl + newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = currentPostImages.post_medias.filter((item) => {
      return currentPostImages.post_medias.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].filePath;
    setClickedImg(Endpoints.baseUrl + newItem);
    setCurrentIndex(newIndex);
    setMediaComments(null);
    setIsEditing(false);
    setInputValue(null);
  };

  useEffect(() => {
    setSharePost("");
    setReactionModalOpen("hide");
  }, []);

  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "y";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + "d";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + "h";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min";
    return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec" : "0 sec";
  };

  function formatDateTime(postDate) {
    let date = new Date(postDate);
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    let formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }
  const showReactionsBox = (event) => {
    setMouserHovering(true);
  };

  const hideReactionsBox = (event) => {
    setTimeout(() => {
      setMouserHovering(false);
    }, 1400);
  };

  const handleDeletePhoto = async (item) => {
    Swal.fire({
      text: "Are you sure you want to delete this post ?",
      icon: "question",
      customClass: {
        confirmButton: "btn px-3 mx-2",
        cancelButton: "btn btn-br-blue px-3",
      },
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(item);
      }
    });
  };

  const handleDelete = async (item) => {
    try {
      let resp = await DeleteApi(
        Endpoints.DeleteMedia + "?postId=" + item?.id + "&type=albums"
      );
      if (resp && resp.success === true) {
        ToastMessage.Success(resp.message);
        onSuccess(true);
        setModalState("hide");
        setReactionModalOpen("hide");
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          history("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <>
      {albumData?.post_medias?.length > 0 ? (
        <div class="col-lg-3 col-md-4 col-sm-6 mb-3" key={index}>
          <Link class="rounded-15 overflow-hidden position-relative w-100">
            <div class="rounded-15 overflow-hidden album-photos position-relative">
              <img
                src={Endpoints.baseUrl + albumData?.post_medias[0]?.filePath}
                alt=""
                onClick={(e) => {
                  handleImgClick(post, albumData?.post_medias[0], 0);
                  getSinglePostData(0);
                  setIsLoading(true);
                  setVControl(false);
                }}
                // className="album-img-2 rounded-15"
                className="rounded-15"
              />
              {username == params?.slug ? (
                <div class="mainmenu-item-action position-absolute end-0 top-0 m-2 p-1">
                  <a
                    href="#"
                    id="teacher_1"
                    data-bs-toggle="dropdown"
                    class="d-flex align-items-center justify-content-center rounded-circle bg-eee p-1"
                    aria-expanded="false"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      stroke="#000"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      class="css-i6dzq1"
                    >
                      <circle cx="12" cy="12" r="1"></circle>
                      <circle cx="19" cy="12" r="1"></circle>
                      <circle cx="5" cy="12" r="1"></circle>
                    </svg>
                  </a>
                  <ul
                    class="popup-menu dropdown-menu shadow rounded-1 text-start"
                    aria-labelledby="teacher_1"
                  >
                    <li>
                      <Link
                        class="dropdown-item py-2 d-flex gap-2"
                        to={"/edit-album/" + albumData?.id}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="15"
                          height="15"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          class="css-i6dzq1"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                        Edit
                      </Link>
                    </li>
                    <li>
                      <Link
                        class="dropdown-item py-2 d-flex gap-2"
                        onClick={() => {
                          handleDeletePhoto(albumData);
                        }}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="15"
                          height="15"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          class="css-i6dzq1"
                        >
                          <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                          <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                        </svg>
                        Delete
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : (
                ""
              )}

              <div
                className={
                  albumData?.albumTitle === null
                    ? "profile-top d-flex align-items-center justify-content-center rounded-15"
                    : " profile-top profile-top-2 d-flex align-items-center justify-content-center rounded-15"
                }
                onClick={(e) => {
                  handleImgClick(post, albumData?.post_medias[0], 0);
                  getSinglePostData(index);
                  setIsLoading(true);
                  setVControl(false);
                }}
              >
                <div className="d-flex align-items-center text-white medium w-100 justify-content-center gap-4">
                  <span className="text-white d-flex align-items-center  ">
                    <img src="../assets/img/icon/icon-love.svg" alt="" />
                    <h3>{albumData?.totalReactions}</h3>
                  </span>

                  <span className="text-white d-flex align-items-center ">
                    <img src="../assets/img/icon/icon-comment2.svg" alt="" />
                    <h3>{albumData?.totalComments}</h3>
                  </span>
                  {albumData?.post_medias.length > 1 ? (
                    <span className="text-white d-flex align-items-center border rounded-pill p-2 ">
                      +<h3>{albumData?.post_medias.length - 1}</h3>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <h6 className="text-center">{albumData?.albumTitle}</h6>
            </div>
          </Link>
        </div>
      ) : (
        ""
      )}

      {clickedImg && allShow.length > 0 && (
        <div>
          <AlbumImageModal
            clickedImg={clickedImg}
            handleNextBtn={handleNextBtn}
            setClickedImg={setClickedImg}
            handlePrevBtn={handlePrevBtn}
            mediaIndex={allShow.length > 0 ? allShow[currentIndex] : null}
            timeSince={timeSince}
            formatDateTime={formatDateTime}
            mouserHovering={mouserHovering}
            showReactionsBox={showReactionsBox}
            hideReactionsBox={hideReactionsBox}
            setMediaComments={setMediaComments}
            mediaComments={mediaComments}
            post={post}
            postReactions={postReactions}
            getSinglePostData={getSinglePostData}
            setInputValue={setInputValue}
            inputValue={inputValue}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setIsModalOpen={setIsModalOpen}
            showModal={showModal}
            setShowModal={setShowModal}
            setVControl={setVControl}
            onSuccess={true}
          />
        </div>
      )}
    </>
  );
}
