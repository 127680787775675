import React, { useEffect, useState } from 'react'
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from '../../API/FetchApi';
import { CommonAPi } from '../../API/CommonApi';
import { Link, useNavigate } from 'react-router-dom';

export default function FriendRequests() {
    const userId = localStorage.getItem("userId");
    const [FriendRequest, setFriendRequest] = useState([]);
    const Navigate = useNavigate();
    useEffect(() => {
        getFriendRequest();
    }, []);
    const getFriendRequest = async () => {
        try {
            let resp = await FetchApi(Endpoints.GetFriendRequest);
            if (resp && resp.success === true) {
                setFriendRequest(resp?.data?.requests);
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }
    };

    
    const FriendRequestResponse = async (Id, action) => {
        let data = {
            requestId: Id,
            action: action
        }
        try {
            let resp = await CommonAPi(Endpoints.FriendRequestRespond, data);
            if (resp && resp.message) {
                getFriendRequest();
                ToastMessage.Success(resp.message)
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }

    };


    return (
        <>
        {
        FriendRequest.length > 0 ?
        <div className="sidebar-widget mb-4">
            <div className="d-flex align-items-center justify-content-between">
                <h4 className="medium">Friend Requests</h4>
                <Link to={"/friend-request/" + userId} className="text-brown fs-12 medium">
                    See all{" "}
                    <i data-feather="arrow-right" className="feather-16"></i>
                </Link>
            </div>
            <div className="pt-3 border-top mt-3">
                <ul>
                    {
                        FriendRequest.length > 0 ?
                            FriendRequest.map((item, index) => {
                                if(index > 2){
                                    return null;
                                }
                                return (
                                    <li className="pb-3 d-flex align-items-start" key={index}>

                                       <span className="circular--landscape pic-size-64-flex">
                                        {item?.user_profile?.profileImg === null ?
                                            <Link to={"/timeline/" + item?.username}>
                                           <img
                                                src="../assets/img/picture.png"
                                                alt=""
                                                width="48"
                                                height="48"
                                                class="rounded-circle"
                                            />
                                            </Link>
                                            :
                                            <Link to={"/timeline/" + item?.username}>
                                            <img
                                                src={Endpoints.baseUrl + item?.user_profile?.profileImg}
                                                alt=""
                                                width="48"
                                                height="48"
                                                class="rounded-circle" />
                                            </Link>
                                        }
                                        </span>
                                        <div className="ps-2">
                                        <Link to={"/timeline/" + item.username}>
                                            <h6 className="medium text-black">{item?.fullName}</h6>
                                        </Link>
                                            <h6 className="fs-10 regular text-darkgray py-1">
                                                {item?.mutualFriends} mutual friends
                                            </h6>

                                            <div className="d-flex request-btn gap-2 pt-1">


                                                <Link class="btn  light-brown-btn" onClick={() => {
                                                    FriendRequestResponse(item.requestId, "approve")
                                                }}>Accept</Link>
                                                <Link class="btn gray-btn" onClick={() => {
                                                    FriendRequestResponse(item.requestId, "reject")
                                                }}>Decline</Link>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                            :
                            <li className="pb-3 d-flex align-items-start">
                                No any friend request
                            </li>
                    }



                </ul>
            </div>
        </div>
        : ""}
        </>
    )
}
