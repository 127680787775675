import { toBlob } from "html-to-image";
import { useEffect } from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";

import { CommonAPi } from "../API/CommonApi";
import { Endpoints } from "../API/Endpoints";
import ToastMessage from "../Utils/ToastMessage";
import FriendsSpecificStoryModal from "./FriendsSpecificStoryModal";
import Moveable from "react-moveable";

export default function AddStoryModal({
  onClose = () => {},
  setShowAddStoryModal,
}) {
  const storyPreviewRef = useRef();
  const storyTextRef = useRef();

  const pos1 = useRef(0);
  const pos2 = useRef(0);
  const pos3 = useRef(0);
  const pos4 = useRef(0);

  const [loading, setLoading] = useState(false);

  const [showDiscardDialog, setShowDiscardDialog] = useState(false);

  const [postVisibility, setPostVisibility] = useState("public");

  const [file, setFile] = useState();

  const [storyType, setStoryType] = useState("Text");

  const [message, setMesssage] = useState("");

  const [textColor, setTextColor] = useState(textColors[textColors.length - 1]);

  const [background, setBackground] = useState(backgrounds[0]);

  const [textFont, setTextFont] = useState("");

  const [textSize, setTextSize] = useState("16px");

  const [specificFrined, setSpecificFrined] = useState([]);

  const [showSpecificFriendModal, setShowSpecificFriendModal] = useState(false);
  const [fileURL, setFileURL] = useState("");

  //
  // const [state, setState] = useState({
  //   width: window.screen.width * 0.15,
  //   height: 100,
  //   top: 100,
  //   left: 100,
  //   rotateAngle: 0,
  // });

  const [state, setState] = useState({
    width: window.screen.width * 0.15,
    height: 100,
    top: window.screen.width * 0.15, // Initialize with 0
    left: window.screen.width * 0.05, // Initialize with 0
    rotateAngle: 0,
  });

  const handleResize = (style, isShiftKey, type) => {
    let { top, left, width, height } = style;
    top = Math.round(top);
    left = Math.round(left);
    width = Math.round(width);
    height = Math.round(height);

    setState({
      ...state,
      top,
      left,
      width,
      height,
    });
  };

  const handleRotate = (rotateAngle) => {
    setState({
      ...state,
      rotateAngle,
    });
  };

  const handleDrag = (deltaX, deltaY) => {
    setState({
      ...state,
      left: state.left + deltaX,
      top: state.top + deltaY,
    });
  };

  const handleFileChange = useCallback((e) => {
    e.preventDefault();
    const { files } = e.target;
    if (files.length > 0) {
      setFile(files[0]);
      setFileURL(URL.createObjectURL(files[0]));
    } else setFile();
  }, []);

  const handleStoryTypeChange = useCallback(
    (item) => () => {
      setStoryType(item.text);
      if (item.text === "Text") {
        setBackground(backgrounds[0]);
      }
      setFile();
    },
    []
  );

  const handleCloseAddStory = useCallback(() => {
    if (message.length > 0) setShowDiscardDialog(true);
    else onClose();
  }, [message.length, onClose]);

  // moving the text inside preview container
  const handleMouseMove = useCallback((e) => {
    e.preventDefault();
    // calculate the new cursor position:
    pos1.current = pos3.current - e.clientX;
    pos2.current = pos4.current - e.clientY;
    pos3.current = e.clientX;
    pos4.current = e.clientY;
    if (storyTextRef.current) {
      // top calculation
      const calculatedTop = storyTextRef.current.offsetTop - pos2.current;
      // check if the text is going out to top
      if (calculatedTop < 0) {
        storyTextRef.current.style.top = "0px";
      }
      // check if the text is going out to bottom
      else if (
        calculatedTop + storyTextRef.current.offsetHeight >
        storyPreviewRef.current.offsetHeight
      ) {
        storyTextRef.current.style.top =
          storyPreviewRef.current.offsetHeight -
          storyTextRef.current.offsetHeight +
          "px";
      }
      // everyting is fine, so assign value directly
      else {
        storyTextRef.current.style.top = calculatedTop + "px";
      }

      // check if the text is going out to left
      const calculatedLeft = storyTextRef.current.offsetLeft - pos1.current;
      if (calculatedLeft < 0) {
        storyTextRef.current.style.left = "0px";
      }
      // check if the text is going out to right
      else if (
        calculatedLeft + storyTextRef.current.offsetWidth >
        storyPreviewRef.current.offsetWidth
      ) {
        storyTextRef.current.style.left =
          storyPreviewRef.current.offsetWidth -
          storyTextRef.current.offsetWidth +
          "px";
      }
      // everyting is fine, so assign value directly
      else {
        storyTextRef.current.style.left = calculatedLeft + "px";
      }
    }
  }, []);

  const handleMouseDown = useCallback(
    (e) => {
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3.current = e.clientX;
      pos4.current = e.clientY;
      document.body.onmousemove = handleMouseMove;
    },
    [handleMouseMove]
  );

  const cancelMoveListener = useCallback((e) => {
    e.preventDefault();
    document.body.onmousemove = null;
  }, []);

  // const showBoundaries = () => {
  //   document.querySelector(".moveable-control-box").style.display;
  // };

  // useEffect(()=>{
  //   interact('.drag-rotate')
  //   .draggable({
  //   onstart: function (event) {
  //     const element = event.target;
  //     const rect = element.getBoundingClientRect();

  //     // store the center as the element has css `transform-origin: center center`
  //     element.dataset.centerX = rect.left + rect.width / 2;
  //     element.dataset.centerY = rect.top + rect.height / 2;
  //     // get the angle of the element when the drag starts
  //     element.dataset.angle = getDragAngle(event);
  //   },
  //   onmove: function (event) {
  //     var element = event.target;
  //     var center = {
  //       x: parseFloat(element.dataset.centerX) || 0,
  //       y: parseFloat(element.dataset.centerY) || 0,
  //     };
  //     var angle = getDragAngle(event);

  //     // update transform style on dragmove
  //     element.style.transform = 'rotate(' + angle + 'rad' + ')';
  //   },
  //   onend: function (event) {
  //     const element = event.target;

  //     // save the angle on dragend
  //     element.dataset.angle = getDragAngle(event);
  //   },
  // })

  // function getDragAngle(event) {
  //   var element = event.target;
  //   var startAngle = parseFloat(element.dataset.angle) || 0;
  //   var center = {
  //     x: parseFloat(element.dataset.centerX) || 0,
  //     y: parseFloat(element.dataset.centerY) || 0,
  //   };
  //   var angle = Math.atan2(center.y - event.clientY,
  //                          center.x - event.clientX);

  //   return angle - startAngle;
  // }
  // }, []);

  const handleCreateStory = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      const fileToSubmit = await toBlob(storyPreviewRef.current);
      const formData = new FormData();
      formData.append("media", fileToSubmit, "story.png");
      formData.append("type", storyType.toLocaleLowerCase());
      formData.append("visibility", postVisibility);
      if (postVisibility === "specific_friends") {
        // specificFrined.forEach((item) =>
        formData.append("specificFriend", JSON.stringify(specificFrined));
        // );
      }
      const res = await CommonAPi(Endpoints.createStory, formData);
      if (res?.success) {
        onClose();
        ToastMessage.Success("Created story successfully!");
      } else ToastMessage.Error("Failed to create story!");
    } catch (error) {
      ToastMessage.Error("Something went wrong, please try again!");
    }
    setLoading(false);
  }, [loading, onClose, storyType, postVisibility, specificFrined]);

  const handleSpecificFriendModal = useCallback(() => {
    setShowSpecificFriendModal(false);
  }, []);

  const calculatedFontWeight = useMemo(() => {
    if (textFont === "medium") return 500;
    if (textFont === "bold") return 700;
    return undefined;
  }, [textFont]);

  const fontClass = useMemo(() => {
    if (textFont === "medium") return "medium";
    if (textFont === "bold") return "fw-bold";
    if (textFont === "italic") return "fst-italic";
    if (textFont === "fancy") return "font-righteous";
    return "";
  }, [textFont]);

  const selectedBackgroundObject = useMemo(() => {
    if (storyType === "Image") return {};
    if (background.startsWith("#")) return { background };
    return {
      backgroundImage: background,
    };
  }, [background, storyType]);

  const fontStyle = useMemo(() => {
    if (textFont === "italic") return "italic";
    return undefined;
  }, [textFont]);

  const fontFamily = useMemo(() => {
    if (textFont === "fancy") return `'Righteous', cursive`;
    return undefined;
  }, [textFont]);

  const createStoryButtonContent = useMemo(() => {
    if (loading)
      return (
        <div class="spinner-border text-light spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      );
    return "Create Story";
  }, [loading]);

  const handlePrivacyStatus = (status) => {
    setPostVisibility(status);
    if (status === "specific_friends") {
      setShowSpecificFriendModal(true);
    }
  };

  const textContainerStyle = {
    color: "white",
    fontSize: 30,
    transform: `rotate(${state.rotateAngle}deg)`,
    position: "absolute",
    top: `${state.top}px`,
    left: `${state.left}px`,
    width: `${state.width}px`, // Use state.width directly
    height: `${state.height}px`, // Use state.height directly
    overflow: "hidden", // Hide content outside the container
    boxSizing: "border-box",
    padding: "10px", // Adjust padding as needed
  };

  return createPortal(
    <>
      <div
        onMouseUp={cancelMoveListener}
        className="position-fixed top-0 start-0 bottom-0 end-0 z-3 add-story-container"
      >
        <div className="overflow-hidden" style={{ borderRadius: 15 }}>
          {!showDiscardDialog && !showSpecificFriendModal && (
            <div className="add-story-modal d-flex flex-column flex-md-row flex-nowrap overflow-auto">
              <div className="position-relative border-end d-flex flex-column align-items-stretch add-story-create-container">
                <p className="add-story-modal-title">Create Your Story</p>
                <button
                  onClick={handleCloseAddStory}
                  className="add-story-close-button d-md-none"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/svgs/close-modal.svg`}
                    alt="close-modal"
                  />
                </button>
                <div className="d-flex flex-column flex-grow-1 overflow-hidden">
                  <div className="d-flex justify-content-between py-3 px-4">
                    <p className="m-0 text-muted">* All fields are required.</p>
                    <div className="privacy-box fs-12">
                      <span className="ms-auto d-flex align-items-center">
                        <div className=" w-100">
                          <Link
                            class="dropdown-toggle w-100 scope-options"
                            type="button"
                            id="defaultDropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="true"
                            aria-expanded="false"
                          >
                            <img
                              src="../assets/img/icon/icon-privacy.svg"
                              alt="privacy"
                            />{" "}
                            {postVisibilites.map((item, i) => {
                              if (item.value === postVisibility)
                                return item.name;
                            })}
                          </Link>
                          <ul
                            class="dropdown-menu dropdown-menu-end"
                            aria-labelledby="defaultDropdown"
                          >
                            {postVisibilites.map((postVisible, i) => (
                              <li
                                key={postVisible.name}
                                className={
                                  postVisibility == postVisible.value
                                    ? "audience-status py-2"
                                    : "py-2"
                                }
                              >
                                <Link
                                  class="dropdown-item py-2 d-flex gap-2"
                                  onClick={() => {
                                    handlePrivacyStatus(postVisible.value);
                                  }}
                                >
                                  <div className="d-flex gap-3">
                                    <img
                                      src={postVisible?.image}
                                      alt="privacy"
                                      style={{
                                        width: "32px",
                                      }}
                                    />
                                    <span
                                      className="fs-16"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {postVisible.name}
                                      <br />
                                      <small className="fs-12">
                                        {postVisible.text}
                                      </small>
                                    </span>
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="flex-fill overflow-auto px-4">
                    <p className="fs-15 medium mb-2 text-black">Story Type *</p>
                    <div className="d-flex flex-nowrap add-story-story-type-container mb-4">
                      {storyTypes.map((item) => {
                        return (
                          <button
                            key={item.text}
                            className={`add-story-type-button ${
                              storyType === item.text ? "active" : ""
                            }`}
                            onClick={handleStoryTypeChange(item)}
                          >
                            {storyType === item.text ? (
                              <img src={item.activeIcon} alt={item.text} />
                            ) : (
                              <img src={item.inactiveIcon} alt={item.text} />
                            )}
                          </button>
                        );
                      })}
                    </div>
                    {storyType === "Image" && (
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ width: "80%", marginBottom: 16 }}
                      />
                    )}
                    <p className="fs-15 medium mb-2 text-black">
                      Message {storyType === "Text" && "*"}
                    </p>
                    <textarea
                      className="add-story-message-input mb-4"
                      rows={7}
                      placeholder="Write message..."
                      value={message}
                      onChange={(e) => setMesssage(e.target.value)}
                    />
                    <div className="add-story-text-size-type-wrapper">
                      <div className="flex-fill">
                        <p className="fs-15 medium mb-2 text-black">
                          Text Type *
                        </p>
                        <select
                          className={`form-select mb-3 ${fontClass}`}
                          value={textFont}
                          onChange={(e) => setTextFont(e.target.value)}
                        >
                          <option value="normal" className="font-poppins">
                            Normal
                          </option>
                          <option
                            value="medium"
                            className="medium font-poppins"
                          >
                            Medium
                          </option>
                          <option value="bold" className="fw-bold font-poppins">
                            Bold
                          </option>
                          <option
                            value="italic"
                            className="fst-italic font-poppins"
                          >
                            Italic
                          </option>
                          <option value="fancy" className="font-righteous">
                            Fancy
                          </option>
                        </select>
                      </div>
                      <div className="flex-fill">
                        <p className="fs-15 medium mb-2 text-black">
                          Text Size *
                        </p>
                        <select
                          className="form-select mb-3"
                          value={textSize}
                          onChange={(e) => setTextSize(e.target.value)}
                        >
                          <option value="12px">12px</option>
                          <option value="14px">14px</option>
                          <option value="16px">16px</option>
                          <option value="18px">18px</option>
                          <option value="20px">20px</option>
                          <option value="22px">22px</option>
                          <option value="24px">24px</option>
                          <option value="26px">26px</option>
                        </select>
                      </div>
                    </div>
                    <p className="fs-15 medium mb-2 text-black">Text Color</p>
                    <div className="add-story-bgOrColor-picker mb-3">
                      {textColors.map((color) => {
                        return (
                          <button
                            key={color}
                            className="add-story-bgOrColor"
                            style={{ background: color }}
                            onClick={() => setTextColor(color)}
                          />
                        );
                      })}
                    </div>
                    {storyType === "Text" && (
                      <>
                        <p className="fs-15 medium mb-2 text-black">
                          Backgrounds *
                        </p>
                        <div className="add-story-bgOrColor-picker">
                          {backgrounds.map((item) => {
                            return (
                              <button
                                key={item}
                                className="add-story-bgOrColor"
                                style={{ background: item }}
                                onClick={() => setBackground(item)}
                              />
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                  <button
                    onClick={handleCreateStory}
                    disabled={(!message && storyType === "Text") || loading}
                    className="add-story-button d-none d-md-block"
                  >
                    {createStoryButtonContent}
                  </button>
                </div>
              </div>
              <div className="position-relative add-story-right-panel d-flex flex-column align-items-center">
                <p className="add-story-modal-title align-self-stretch mb-4">
                  Preview
                </p>
                <button
                  onClick={handleCloseAddStory}
                  className="add-story-close-button d-none d-md-block"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/svgs/close-modal.svg`}
                    alt="close-modal"
                  />
                </button>
                <div className="add-story-preview-container overflow-auto">
                  <div
                    ref={storyPreviewRef}
                    className="add-story-preview"
                    style={selectedBackgroundObject}
                  >
                    {storyType === "Image" && !file && (
                      <p>No image selected!</p>
                    )}
                    {file && (
                      // <img
                      //   src={URL.createObjectURL(file)}
                      //   alt="background"
                      //   className="add-story-preview-custom-img"
                      // />
                      <div
                        style={{
                          backgroundImage: `url(${fileURL})`,
                        }}
                        className="story-bg"
                      ></div>
                    )}
                    {(storyType === "Text" ||
                      (storyType === "Image" &&
                        file &&
                        message.length > 0)) && (
                      <div
                        id="story-container"
                        // className="add-story-preview-text-container"
                      >
                        <p
                          style={{
                            color: textColor,
                            fontSize: textSize,
                            paddingTop: 10,
                            paddingBottom: 10, // Use state.height directly
                            fontStyle,
                            fontFamily,
                            fontWeight: calculatedFontWeight,
                          }}
                          className="target add-story-preview-text"
                          // onMouseOver={}
                        >
                          {message || "Your message goes here..."}
                        </p>
                        <Moveable
                          target={document.querySelector(".target")}
                          container={null}
                          origin={true}
                          edge={true}
                          dragFocusedInput={true}
                          bounds={{
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            position: "css",
                          }}
                          snappable={true}
                          draggable={true}
                          throttleDrag={0}
                          onDragStart={({ target, clientX, clientY }) => {
                            // console.log("onDragStart", target);
                          }}
                          onDrag={({
                            target,
                            beforeDelta,
                            beforeDist,
                            left,
                            top,
                            right,
                            bottom,
                            delta,
                            dist,
                            transform,
                            clientX,
                            clientY,
                          }) => {
                            // console.log("onDrag translate", clientX);
                            target.style.transform = transform;
                          }}
                          onDragEnd={({ target, isDrag, clientX, clientY }) => {
                            // console.log("onDragEnd", target, isDrag);
                          }}
                          keepRatio={false}
                          resizable={true}
                          throttleResize={0}
                          onResize={({
                            target,
                            width,
                            height,
                            dist,
                            delta,
                            direction,
                            clientX,
                            clientY,
                          }) => {
                            delta[0] && (target.style.width = `${width}px`);
                            delta[1] && (target.style.height = `${height}px`);
                          }}
                          scalable={true}
                          throttleScale={0}
                          onScale={({
                            target,
                            scale,
                            dist,
                            delta,
                            transform,
                            clientX,
                            clientY,
                          }) => {
                            target.style.transform = transform;
                          }}
                          rotatable={true}
                          throttleRotate={0}
                          onRotate={({
                            target,
                            delta,
                            dist,
                            transform,
                            clientX,
                            clientY,
                          }) => {
                            // console.log("onRotate", dist);
                            target.style.transform = transform;
                          }}
                          pinchable={true}
                          // onRender={(e) => {
                          //   e.target.style.cssText += e.cssText;
                          // }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <button
                  onClick={handleCreateStory}
                  disabled={(!message && storyType === "Text") || loading}
                  className="add-story-button d-md-none"
                >
                  {createStoryButtonContent}
                </button>
              </div>
            </div>
          )}
        </div>

        {showDiscardDialog && (
          <div className="add-story-discard-container">
            <p>Are you sure want to discard ?</p>
            <div className="discard-button-container">
              <button className="btn btn-danger" onClick={onClose}>
                Discard
              </button>
              <button
                className="btn"
                onClick={() => setShowDiscardDialog(false)}
              >
                No
              </button>
            </div>
          </div>
        )}
        {showSpecificFriendModal && (
          <FriendsSpecificStoryModal
            {...{
              onClose: handleSpecificFriendModal,
              setPostVisibility: setPostVisibility,
              specificFrined: specificFrined,
              setSpecificFrined: setSpecificFrined,
            }}
          />
        )}
      </div>
    </>,
    document.body
  );
}

const postVisibilites = [
  {
    name: "Friends",
    value: "friends",
    image: "../assets/img/icon/group-friend.svg",
    text: "Your connections on 8inNet",
  },
  {
    name: "Public",
    value: "public",
    image: "../assets/img/icon/public.svg",
    text: "Anyone on or off 8inNet",
  },
  {
    name: "Friends Specific",
    value: "specific_friends",
    image: "../assets/img/icon/friend.svg",
    text: "Only Show to some friends",
  },
];

const storyTypes = [
  {
    activeIcon: `${process.env.PUBLIC_URL}/assets/svgs/text-story-active.svg`,
    inactiveIcon: `${process.env.PUBLIC_URL}/assets/svgs/text-story-inactive.svg`,
    text: "Text",
  },
  {
    activeIcon: `${process.env.PUBLIC_URL}/assets/svgs/image-story-active.svg`,
    inactiveIcon: `${process.env.PUBLIC_URL}/assets/svgs/image-story-inactive.svg`,
    text: "Image",
  },
];

const textColors = [
  "#000000",
  "#FF0000",
  "#FFA800",
  "#0FEC25",
  "#0AE1EF",
  "#EFE60A",
  "#0A2FEF",
  "#B40AF0",
  "#F00AD9",
  "#F00A78",
  "#404040",
  "#C4C4C4",
  "#E0E0E0",
  "#FFFFFF",
];

const backgrounds = [
  "linear-gradient(180deg, #0C64C5 0%, #002D60 100%)",
  "linear-gradient(180deg, #FF9A9E 0%, #FAD0C4 100%)",
  "linear-gradient(180deg, #F6D365 0%, #FDA085 100%)",
  "linear-gradient(180deg, #D4FC79 0%, #96E6A1 100%)",
  "linear-gradient(180deg, #FCCB90 0%, #D57EEB 100%)",
  "linear-gradient(180deg, #4FACFE 0%, #00F2FE 100%)",
  "linear-gradient(180deg, #30CFD0 0%, #330867 100%)",
  "linear-gradient(180deg, #6A11CB 0%, #2575FC 100%)",
  "linear-gradient(180deg, #EBBBA7 0%, #CFC7F8 100%)",
  "linear-gradient(180deg, #FFE53B 0%, #FF2525 100%)",
  "#404040",
  "#C4C4C4",
  "#E0E0E0",
  "#FFFFFF",
  "url('/assets/img/gratitude-bg-image1.jpg')",
  "url('/assets/img/gratitude-bg-image2.jpg')",
  "url('/assets/img/gratitude-bg-image3.jpg')",
  "url('/assets/img/gratitude-bg-image4.jpg')",
  "url('/assets/img/gratitude-bg-image5.jpg')",
];
