import React from "react";
import "./PostLoader.css";
const PostLoader = () => {
  return (
    <div className="loader-wheel">
      <i>
        <i>
          <i>
            <i>
              <i>
                <i>
                  <i>
                    <i>
                      <i>
                        <i>
                          <i>
                            <i></i>
                          </i>
                        </i>
                      </i>
                    </i>
                  </i>
                </i>
              </i>
            </i>
          </i>
        </i>
      </i>
    </div>
  );
};

export default PostLoader;
