import React from 'react';
import ToastMessage from "../../Utils/ToastMessage";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import { DeleteApi } from "../../API/DeleteApi";
import { Link, useNavigate } from 'react-router-dom';
export default function GroupItem({ item, index, type, onSuccess }) {
    const userId = localStorage.getItem("userId");
    const Navigate = useNavigate();
    const joinGroup = async (id) => {
        let data = {
            groupId: id,
        }
        try {
            let resp = await CommonAPi(Endpoints.JoinGroups, data);
            if (resp && resp.success === true) {
                onSuccess(true);
                ToastMessage.Success(resp.message)
            }
        }
        catch (e) {

            if (e && e.response && e.response.data && e.response.data.message) {

                if (e.response.status === 401) {

                    ToastMessage.Error('Session Expired! Please login again.')
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message)
                }
            }
        }

    };

    const leaveGroup = async (id) => {
        let data = {
            groupId: id
        }
        try {
            let resp = await DeleteApi(Endpoints.LeaveGroup, data);
            if (resp && resp.success === true) {
                onSuccess(true);
                ToastMessage.Success(resp.message)
            }
        }
        catch (e) {

            if (e && e.response && e.response.data && e.response.data.message) {

                if (e.response.status === 401) {

                    ToastMessage.Error('Session Expired! Please login again.')
                    localStorage.clear();
                    Navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message)
                }
            }
        }

    };

    return (
        <div class="pb-3 d-flex align-items-center" key={index}>
            <Link to={"/group-about/" + item?.slug}>
            {item?.logo === null ?
                <img src="assets/img/profile6.png" alt="" width="64" height="64" class="rounded-circle" />
                :
                <img src={Endpoints.baseUrl + item?.logo} alt="" width="64" height="64" class="rounded-circle" />
            }
            </Link>
            <div class="d-flex align-items-center justify-content-between w-100 ps-2">
                <div>
                <Link to={"/group-about/" + item?.slug}>
                    <h6 class="medium text-black fs-14">{item?.groupName}</h6>
                    </Link>

                    <h6 class="fs-12 regular text-darkgray">University of Delhi</h6>
                    <h6 class="fs-10 regular text-darkgray">{item?.totalMember} mutual friends</h6>
                </div>
                {userId == item?.userId ? "" :
                <div class="">
                    {item?.userStatus === "N/A" ?  
                    <a href='#' onClick={()=>{
                        joinGroup(item?.id)
                    }} class="btn fs-12 py-1 px-3 w-100">Join <img src="assets/img/icon/icon-add-friend.svg" class="ms-1" width="10" />
                   
                    </a>
                     :<a href='#' class="btn fs-12 py-1 px-3 w-100" 
                     onClick={()=>{
                        leaveGroup(item?.id)
                    }}
                     >Leave Group
                   
                    </a>}
                </div>
                }

            </div>
        </div>
    )
}
