import { Base64 } from "js-base64";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import validator from 'validator';
import jwtDecode from "jwt-decode";
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import {Helmet} from "react-helmet";
function ResetPassword() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [hidden, setHidden] = useState(true);
    const [hiddenConfirm, setHiddenConfirm] = useState(true);
    const navigate = useNavigate();
    const param = useParams();
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [hasOneSplChar, setHasOneSplChar] = useState(false);
    const [hasOneNumber, setHasOneNumber] = useState(false);
    const [isOneLower, setIsOneLower] = useState(false);
    const [isOneUpper, setIsOneUpper] = useState(false);
    const [isEightLength, setIsEightLength] = useState(false);

    const forget_password_token = Base64.decode(param.id);

    const token = localStorage.getItem("token");
    useEffect(() => {
        if (token) {
            const decoded = jwtDecode(token);
            if (decoded.exp < Date.now() / 1000) {
                localStorage.clear();
                navigate("/signin");
            } else {
                navigate("/mainfeed")
            }
        }
    }, []);


    const validatePassword = () => {
        if (newPassword.length >= 8) {
            setIsEightLength(true)
        } else {
            setIsEightLength(false)
        }

        if (newPassword.match(/[a-z]/g)) {
            setIsOneLower(true)
        } else {
            setIsOneLower(false)
        }

        if (newPassword.match(/[A-Z]/g)) {
            setIsOneUpper(true)
        } else {
            setIsOneUpper(false)
        }

        if (newPassword.match(/[0-9]/g)) {
            setHasOneNumber(true)
        } else {
            setHasOneNumber(false)
        }

        if (newPassword.match(/[!@#\$%\^\&*\)\(+=._-]/g)) {
            setHasOneSplChar(true)
        } else {
            setHasOneSplChar(false)
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newPassword) {
            setNewPasswordError("Please enter new password");
        } else if (newPassword) {
            if (validator.isStrongPassword(newPassword, {
                minLength: 8, minLowercase: 1,
                minUppercase: 1, minNumbers: 1, minSymbols: 1
            })) {
                setNewPasswordError("");
            } else {
                setNewPasswordError("Your password must be at least 8 characters including a lowercase letter, an uppercase letter, and a number");
            }
        }
        if (!confirmPassword) {
            setConfirmPasswordError("Please enter confirm password");
        } else if (confirmPassword) {
            if (validator.isStrongPassword(confirmPassword, {
                minLength: 8, minLowercase: 1,
                minUppercase: 1, minNumbers: 1, minSymbols: 1
            })) {
                setNewPasswordError("");
            } else {
                setNewPasswordError("Your password must be at least 8 characters including a lowercase letter, an uppercase letter, and a number");
            }

            if (confirmPassword === newPassword) {
                setConfirmPasswordError("");
            } else {
                setConfirmPasswordError("confirm password must be match new password");
            }
        }

        if (newPassword && confirmPassword && confirmPassword === newPassword) {
            let obj = {
                forget_password_token: forget_password_token,
                password: newPassword,
                confirm_password: confirmPassword,
            }
            try {
                let resp = await CommonAPi(Endpoints.ResetPassword, obj);
                if (resp && resp.message) {
                    navigate("/signin");
                    ToastMessage.Success(resp.message)
                }
            }
            catch (e) {
                if (e && e.response && e.response.data && e.response.data.message) {
                    ToastMessage.Error(e.response.data.message)
                }
            }
        }
    };

    const handleValidation = async (e, value, field) => {
        e.preventDefault();
        if (!value && field === "NewPassword") {
            setNewPasswordError("Please enter new password");
        } else if (value && field === "NewPassword") {
            if (validator.isStrongPassword(value, {
                minLength: 8, minLowercase: 1,
                minUppercase: 1, minNumbers: 1, minSymbols: 1
            })) {
                setNewPasswordError("");
            } else {
                setNewPasswordError("Your password must be at least 8 characters including a lowercase letter, an uppercase letter, and a number");
            }
        }
        if (!value && field === "confirmPassword") {
            setConfirmPasswordError("Please enter confirm password");
        } else if (value && field === "confirmPassword") {
            if (validator.isStrongPassword(value, {
                minLength: 8, minLowercase: 1,
                minUppercase: 1, minNumbers: 1, minSymbols: 1
            })) {
                setConfirmPasswordError("");
            } else {
                setConfirmPasswordError("Your password must be at least 8 characters including a lowercase letter, an uppercase letter, and a number");
            }
        }

    };

    return (
        <div className="wrapper">
            <Helmet>
                <title>Reset Password</title>
            </Helmet>
            <div class="header fix-header position-fixed top-0 w-100 ">
                <div class="container ">
                    <div class="row m-0">
                        <div class="col-md-6 col-6">

                            <div class="site-logo">
                                <Link to="/">
                                    <img src="assets/img/8inNet_logo 1.svg" alt="" />
                                </Link>
                            </div>

                        </div>

                        <div class="col-md-6 col-6 text-end">
                            <div class="d-flex align-items-center justify-content-end">
                                <h6 class="font-15 text-white regular pe-3 d-none d-sm-block">Don’t have an account?</h6>
                                <Link to="/signup" class="btn btn-brown px-4">Sign Up</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="signin-bg">
                <div className="container p-0">
                    <div className="row m-0">
                        <div className="col-md-6">

                            <div className="py-5">
                                <img src="../assets/img/reset-password.png" alt="" />
                            </div>

                        </div>

                        <div className="col-md-6 bg-primary py-md-5 py-4 signup-right">
                            <div className="pt-md-5 px-md-5 my-md-5">
                                <h1 className="semibold text-white pb-2">Reset Password</h1>
                                <h6 className="text-white pb-4 mb-md-2 regular ">Your new password must be different from previously used password</h6>

                                <form onSubmit={handleSubmit} className="signup-form">


                                    <div className="form-field password-container">
                                        <label className="text-white">New Password*</label>



                                        <div className='d-flex align-items-center' style={{ position: 'relative' }}>
                                            <input
                                                type={hidden ? "password" : "text"}
                                                placeholder="New Password"
                                                className={newPasswordError ? "is-danger" : ""}
                                                onKeyUp={() => { validatePassword() }}
                                                onFocus={() => setIsVisible(true)}
                                                onBlur={(e) => {
                                                    handleValidation(e, e.target.value, "NewPassword")
                                                    setIsVisible(false)
                                                }}
                                                value={newPassword}
                                                onChange={(e) => {
                                                    setNewPassword(e.target.value)
                                                }}
                                            />

                                            <div style={{
                                                cursor: 'pointer',
                                                position: 'absolute',
                                                right: 10
                                            }} onClick={() => setHidden(!hidden)}>
                                                {
                                                    hidden ?
                                                        <AiFillEyeInvisible size={25} />
                                                        :
                                                        <AiFillEye size={25} />
                                                }
                                            </div>
                                        </div>

                                        {newPasswordError && (
                                            <div className="form-error">
                                                {newPasswordError}
                                            </div>
                                        )}

                                        {isVisible && (
                                            <div className="password-tooltip">
                                                <h6 className="mb-2">Your password must have:</h6>
                                                <p className={`${isEightLength ? "text-green" : ""}`}>atleast 8 characters</p>
                                                <p className={`${isOneUpper ? "text-green" : ""}`}>atleast 1 uppercase</p>
                                                <p className={`${isOneLower ? "text-green" : ""}`}>atleast 1 lowercase</p>
                                                <p className={`${hasOneNumber ? "text-green" : ""}`}>atleast 1 number</p>
                                                <p className={`${hasOneSplChar ? "text-green" : ""}`}>atleast 1 speical character</p>
                                            </div>
                                        )}
                                    </div>

                                    <div className="form-field">
                                        <label className="text-white">Confirm Password*</label>


                                        <div className='d-flex align-items-center' style={{ position: 'relative' }}>
                                            <input
                                                type={hiddenConfirm ? "password" : "text"}
                                                className={confirmPasswordError ? "is-danger" : ""}
                                                onBlur={(e) => { handleValidation(e, e.target.value, "confirmPassword") }}
                                                value={confirmPassword}
                                                onChange={(e) => {
                                                    setConfirmPassword(e.target.value)
                                                }}
                                                placeholder="Confirm Password"
                                            />

                                            <div style={{
                                                cursor: 'pointer',
                                                position: 'absolute',
                                                right: 10
                                            }} onClick={() => setHiddenConfirm(!hiddenConfirm)}>
                                                {
                                                    hiddenConfirm ?
                                                        <AiFillEyeInvisible size={25} />
                                                        :
                                                        <AiFillEye size={25} />
                                                }
                                            </div>
                                        </div>
                                        {confirmPasswordError && (
                                            <div className="form-error">
                                                {confirmPasswordError}
                                            </div>
                                        )}
                                    </div>

                                    <button type="submit" className="btn btn-brown rounded-pill w-100 py-3 mt-4 ">Save</button>


                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}


export default ResetPassword;