/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import { Endpoints } from "../../../API/Endpoints";
import { CommonAPi } from "../../../API/CommonApi";
import PostLoader from "../../../Components/PostLoader/PostLoader";
import { StickyChatContext } from "../../../Components/StickyChatWrapper";
import FriendChatListItem from "./FriendChatListItem";
import FriendChatBox from "./FriendChatBox";
import CustomModal from "../../../Components/CustomModal";
import ToastMessage from "../../../Utils/ToastMessage";
import { Helmet } from "react-helmet";
export default function FriendMessages() {
  const [searchParams] = useSearchParams();

  const { setChats: setStickyChats } = useContext(StickyChatContext);

  const { setActiveChatIdInFullView, onlineUsers } =
    useContext(StickyChatContext);

  const [chats, setChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = localStorage.getItem("userId");
  const [isLoading, setIsLoading] = useState(false);
  const [receivedMsg] = useState(null);
  const [showList, setShowList] = useState(false);
  const [showChatType, setShowChatType] = useState(false);
  const [showChatFriendList, setShowChatFriendList] = useState(false);
  const [active, setActive] = useState(null);
  const [search, setSearch] = useState("");
  const [chatsBackup, setChatsBackup] = useState([]);

  const [friends, setFriends] = useState([]);

  const [friendGroupList, setFriendGroupList] = useState([]);
  const [groupName, setGroupName] = useState("");

  const [searchFriendInput, setSearchFriendInput] = useState("");

  const [searchedFriendList, setSearchedFriendList] = useState([]);

  const [createGroupLoading, setCreateGroupLoading] = useState(false);
  const Navigate = useNavigate();
  const location = useLocation();
  const [activeChatRoom, setActiveChatRoom] = useState(false);

  const getAllFriends = useCallback(async () => {
    try {
      const res = await CommonAPi(Endpoints.GetAllFriend + "?id=" + userId);
      if (res.success) {
        setFriends(res.data.friends);
      }
    } catch (e) {
      console.error("Error getting frineds!");
    }
  }, []);

  useEffect(() => {
    getAllFriends(1);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!searchFriendInput) {
      setSearchedFriendList([]);
    }
    CommonAPi(Endpoints.searchFriend + searchFriendInput).then((res) => {
      if (res.data) {
        setSearchedFriendList(res.data);
      }
    });
  }, [searchFriendInput]);

  // setting id of the open chat to root component
  useEffect(() => {
    if (active) setActiveChatIdInFullView(active);
    // eslint-disable-next-line
  }, [active]);
  // setting id to null when component unmounts
  useEffect(
    () => () => {
      setActiveChatIdInFullView(null);
    },
    // eslint-disable-next-line
    []
  );

  const handleAddRemoveFriendToList = useCallback((id) => {
    setFriendGroupList((currentData) => {
      const newGroupList = structuredClone(currentData);
      const index = newGroupList.findIndex((item) => {
        return item === id;
      });
      if (index !== -1) newGroupList.splice(index, 1);
      else newGroupList.push(id);
      return newGroupList;
    });
  }, []);

  const handleModalClose = useCallback(() => {
    setShowList(false);
  }, []);
  const handleChatModalClose = useCallback(() => {
    setShowChatType(false);
  }, []);
  const handleModalFriendClose = useCallback(() => {
    setShowChatFriendList(false);
  }, []);

  const handleStartChatWithFriend = (chatRoom) => {
    const url = "/friend/messages?roomId=" + chatRoom;
    Navigate(url);
    setShowChatFriendList(false);
  };



  const handleSearch = useCallback(
    (e) => {
      const { value } = e.target;
      setSearch(value);
      if (value) {
        setChats(
          chatsBackup.filter((chat) => {
            const chatUser = chat.chat_room_members.find(
              (searchUser) => searchUser?.user?.id !== user?.id
            );
            return (
              chatUser?.user.fullName
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              // searching chat room name for group
              chat.roomName?.toLowerCase().includes(value.toLowerCase())
            );
          })
        );
      } else {
        setChats(chatsBackup);
      }
    },
    [chatsBackup, user]
  );

  const handleMessageSend = useCallback(
    (id, data) => {
      setChats((currentChats) => {
        const newChats = structuredClone(currentChats);
        const index = newChats.findIndex((item) => item.id === id);
        if (index !== -1) {
          const tempChatData = { ...newChats[index], lastMessage: { ...data } };
          newChats.splice(index, 1);
          newChats.splice(0, 0, tempChatData);
        }
        setStickyChats(newChats);
        return newChats;
      });
    },
    [setStickyChats]
  );

  // NOSONAR
  const getUserChats = useCallback(async () => {
    setIsLoading(true);
    try {
      let resp = await CommonAPi(Endpoints.getUserChatRooms + user.id);
      if (resp?.success) {
        // sorting according to last message
        const sortedChats = resp.data.sort((a, b) => {
          let date1 = new Date().getMilliseconds(),
            date2 = new Date().getMilliseconds();
          if (b?.lastMessage) date1 = Date.parse(b.lastMessage.createdAt);
          else date1 = Date.parse(b.createdAt);
          if (a?.lastMessage) date2 = Date.parse(a.lastMessage.createdAt);
          else date2 = Date.parse(a.createdAt);
          return date1 - date2;
        });

        // selecting a chat to display
        if (sortedChats.length) {
          const userId = searchParams.get("userId");
          const roomId = searchParams.get("roomId");
          const index = sortedChats.findIndex((item) => {
            if (userId) {
              return item.chat_room_members.some(
                (user) => user?.user?.id === parseInt(userId)
              );
            }
            if (roomId) {
              return item.id === parseInt(roomId);
            }
            return false;
          });
          // select chat according to params
          if (index !== -1) {
            const chat = sortedChats[index];
            if (chat.unreadMsgCount > 0) {
              // read un-read message
              sortedChats[index].unreadMsgCount = 0;
              setChats(sortedChats);
              setChatsBackup([...sortedChats]);
            } else {
              setChats(sortedChats);
              setChatsBackup([...sortedChats]);
            }
            setCurrentChat(chat);
            setActive(chat.id);
          }
          // select the first one
          else {
            const chat = sortedChats[0];
            if (chat.unreadMsgCount > 0) {
              // read un-read message
              sortedChats[0].unreadMsgCount = 0;
              setChats(sortedChats);
              setChatsBackup([...sortedChats]);
            } else {
              setChats(sortedChats);
              setChatsBackup([...sortedChats]);
            }
            setCurrentChat(chat);
            setActive(chat.id);
          }
        } else {
          setChats([]);
          setChatsBackup([]);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [searchParams, user.id]);

  // get user chats
  useEffect(() => {
    getUserChats();
    // eslint-disable-next-line
  }, [searchParams, user.id]);

  const createGroupChat = useCallback(async () => {
    setCreateGroupLoading(true);
    try {
      const res = await CommonAPi(Endpoints.GroupCreateMessage, {
        name: groupName,
        members: friendGroupList,
      });
      if (res.success) {
        ToastMessage.Success(res.message);
        setFriendGroupList([]);
        setShowList(false);
        setGroupName("");
        setSearchFriendInput("");
        setSearchedFriendList([]);
        getUserChats();
      } else {
        ToastMessage.Error(res.message);
      }
      setCreateGroupLoading(false);
    } catch (e) {
      setCreateGroupLoading(false);
      console.error("error creating group!");
    }
  }, [friendGroupList, getUserChats, groupName]);

  const checkOnlineStatus = (chat) => {
    if (chat) {
      const currentMember = chat.chat_room_members.find(
        (each) => each?.user?.id !== user.id
      );
      const isOnline = onlineUsers.find(
        (user) => user?.userId === currentMember?.user?.id
      );
      return !!isOnline;
    }
  };

  const handleChatFilter = (type) => {
    if (type === "all") setChats(chatsBackup);
    if (type === "unread")
      setChats(chatsBackup.filter((chat) => chat.unreadMsgCount > 0));
  };

  const createGroupButtonContent = useMemo(() => {
    if (createGroupLoading)
      return (
        <div class="spinner-border text-light spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      );
    return "Create Group";
  }, [createGroupLoading]);

  const friendsToRender = useMemo(() => {
    if (!searchFriendInput && !searchedFriendList.length)
      return friends.slice(0, 4);
    else return searchedFriendList;
  }, [searchedFriendList, friends, searchFriendInput]);

  return (
    <Container header>
      <Helmet>
        <title>Friend Message</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div>
          <LeftNavigation />
          <div className="friend-message-container container py-lg-1 w-75 ">
            <div className="h-100">
              {isLoading ? (
                <PostLoader />
              ) : (
                <div className={`row bg-white mx-0 overflow-hidden h-100`}>
                  <div
                    className={activeChatRoom === true ? "d-none" : `col-md-4 border-end px-md-0 h-100 d-flex flex-column`}
                  >
                    <div className="py-3 px-3 d-flex align-items-center justify-content-between">
                      <h3 className="medium">Messages</h3>
                      <button
                        className="btn btn-light-blue medium py-2 fs-14 px-2"
                        onClick={() => {

                          setShowChatType(true);
                        }}
                      >
                        + Start new chat{" "}
                      </button>
                    </div>
                    <div className="px-3 pb-3 border-bottom d-flex align-items-center gap-2">
                      <form
                        action=""
                        className="message-search d-flex flex-row-reverse align-items-center justify-content-end w-100 "
                      >
                        <input
                          type="text"
                          placeholder="Search for Conversations"
                          name="search"
                          className="bg-transparent fs-14 medium"
                          value={search}
                          onChange={handleSearch}
                        />
                        <button
                          type="submit"
                          className="bg-transparent border-0 ps-0"
                        >
                          <svg
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            stroke="#949494"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="css-i6dzq1"
                          >
                            <circle cx="11" cy="11" r="8"></circle>
                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                          </svg>
                        </button>
                      </form>

                      <div className="position-relative">
                        <a
                          href="#"
                          style={{
                            display: "block",
                            height: "40px",
                            width: "40px",
                          }}
                          className="msg-filter-icon"
                          id="message_option"
                          data-bs-toggle="dropdown"
                        >
                          <img
                            src="/assets/img/icon/icon-filter.svg"
                            alt="filter"
                          />
                        </a>
                        <div
                          className="dropdown-menu shadow rounded-1"
                          aria-labelledby="message_option"
                        >
                          <ul className="fs-14">
                            <li>
                              <a
                                className="dropdown-item px-1"
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleChatFilter("all");
                                }}
                              >
                                <svg
                                  style={{ marginRight: ".55rem" }}
                                  viewBox="0 0 24 24"
                                  width="15"
                                  height="15"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="css-i6dzq1"
                                >
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>
                                All
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item px-1"
                                href=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleChatFilter("unread");
                                }}
                              >
                                <svg
                                  style={{ marginRight: ".55rem" }}
                                  viewBox="0 0 24 24"
                                  width="15"
                                  height="15"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="css-i6dzq1"
                                >
                                  <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                  <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                </svg>
                                Unread
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      className="msg-left-sidebar py-3 overflow-y-auto"
                      style={{
                        height: "unset",
                      }}
                    >
                      <ul className="user-messages d-none d-md-block">

                        {chats.length > 0 &&
                          chats.map((chat, i) => (
                            <div
                              onClick={() => {
                                setCurrentChat(chat);
                                setActive(chat.id);
                                // read un-read message
                                const newChats = structuredClone(chats);
                                newChats[i].unreadMsgCount = 0;
                                setChats(newChats);
                              }}
                              key={chat.id}
                              style={{ cursor: "pointer" }}
                            >
                              <FriendChatListItem
                                data={chat}
                                currentUser={user.id}
                                online={checkOnlineStatus(chat)}
                                active={active}
                                receivedMsg={receivedMsg}
                              />
                            </div>
                          ))}
                      </ul>

                      <ul className={"user-messages d-md-none d-sm-block"}>
                        {chats.length > 0 &&
                          chats.map((chat, i) => (
                            <div
                              onClick={() => {
                                setCurrentChat(chat);
                                setActive(chat.id);
                                setActiveChatRoom(true);
                                // read un-read message
                                const newChats = structuredClone(chats);
                                newChats[i].unreadMsgCount = 0;
                                setChats(newChats);
                              }}
                              key={chat.id}
                              style={{ cursor: "pointer" }}
                            >
                              <FriendChatListItem
                                data={chat}
                                currentUser={user.id}
                                online={checkOnlineStatus(chat)}
                                active={active}
                                receivedMsg={receivedMsg}
                              />
                            </div>
                          ))}
                      </ul>
                    </div>
                  </div>

                  <FriendChatBox
                    currentUser={user.id}
                    chat={currentChat}
                    receivedMsg={receivedMsg}
                    online={checkOnlineStatus(currentChat)}
                    getUserChats={getUserChats}
                    chats={chats}
                    onSendMessage={handleMessageSend}
                    setActiveChatRoom={setActiveChatRoom}
                  />
                </div>
              )}
            </div>
          </div>
          <CustomModal
            modalHeader="Choose Chat Type"
            show={showChatType}
            onClose={handleChatModalClose}
          >
            <button
              className="btn btn-light-blue medium fs-14 px-4 "
              onClick={() => {
                setShowList(true);
                setShowChatType(false);
              }}
            >
              Create Group Chat
            </button>
            {" "}
            <button
              className="btn btn-light-blue medium fs-14 px-4 "
              onClick={() => {
                setShowChatFriendList(true);
                setShowChatType(false);
              }}

            >
              Start New Chat
            </button>

          </CustomModal>

          <CustomModal
            modalHeader="Add Friends to group chat"
            show={showList}
            onClose={handleModalClose}
          >
            <div className="mt-1">
              <label htmlFor="new_group_name" className="ms-2 mb-1 fs-14">
                Group Name
              </label>
              <div className="header-search d-flex flex-row-reverse align-items-center justify-content-end w-100 mb-1">
                <input
                  id="new_group_name"
                  type="text"
                  placeholder="Enter group name..."
                  name="search"
                  className="bg-transparent fs-14 medium py-1"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                />
              </div>

              <label htmlFor="search_friend" className="ms-2 mt-2 mb-1 fs-14">
                Search Friend
              </label>
              <div className="header-search d-flex flex-row-reverse align-items-center justify-content-end w-100 mb-1">
                <input
                  id="search_friend"
                  type="text"
                  placeholder="Enter name..."
                  name="search"
                  className="bg-transparent fs-14 medium py-1"
                  value={searchFriendInput}
                  onChange={(e) => setSearchFriendInput(e.target.value)}
                />
              </div>
              {searchFriendInput && !friendsToRender.length && (
                <div className="d-flex justify-content-center my-3">
                  No Result Found
                </div>
              )}
              {friendsToRender.map((friend) => {
                const exist = friendGroupList.includes(friend.id);
                return (
                  <div key={friend.id} className="modal-friend-list-item">
                    <div className="modal-friend-list-user-info">
                      <div className="modal-friend-list-item-user-image">
                        <img
                          src={
                            friend?.user_profile?.profileImg
                              ? `${Endpoints.baseUrl +
                              friend?.user_profile?.profileImg
                              }`
                              : `${process.env.PUBLIC_URL}/assets/img/picture.png`
                          }
                          alt=""
                          width="64"
                          height="64"
                          className="rounded-circle active-userchatpic"
                        />
                      </div>
                      <p className="modal-friend-list-item-username">
                        {friend.fullName}
                      </p>
                    </div>
                    <button
                      className={`btn  ${exist ? "btn-brown" : "btn-light-blue"
                        }`}
                      onClick={() => handleAddRemoveFriendToList(friend.id)}
                    >
                      {exist ? "Remove" : "Add"}
                    </button>
                  </div>
                );
              })}
              <button
                className="btn btn-light-blue medium py-2 fs-14 px-4 mt-4"
                disabled={
                  friendGroupList.length === 0 || groupName.length === 0
                }
                onClick={createGroupChat}
              >
                {createGroupButtonContent}
              </button>
            </div>
          </CustomModal>

          <CustomModal
            modalHeader="Start chat with Friends"
            show={showChatFriendList}
            onClose={handleModalFriendClose}
          >
            <div className="mt-1">


              <label htmlFor="search_friend" className="ms-2 mt-2 mb-1 fs-14">
                Search Friend
              </label>
              <div className="header-search d-flex flex-row-reverse align-items-center justify-content-end w-100 mb-1">
                <input
                  id="search_friend"
                  type="text"
                  placeholder="Enter name..."
                  name="search"
                  className="bg-transparent fs-14 medium py-1"
                  value={searchFriendInput}
                  onChange={(e) => setSearchFriendInput(e.target.value)}
                />
              </div>
              {searchFriendInput && !friendsToRender.length && (
                <div className="d-flex justify-content-center my-3">
                  No Result Found
                </div>
              )}
              {friendsToRender.map((friend) => {
                return (
                  <div key={friend.id} className="modal-friend-list-item">
                    <div className="modal-friend-list-user-info">
                      <div className="modal-friend-list-item-user-image">
                        <img
                          src={
                            friend?.user_profile?.profileImg
                              ? `${Endpoints.baseUrl +
                              friend?.user_profile?.profileImg
                              }`
                              : `${process.env.PUBLIC_URL}/assets/img/picture.png`
                          }
                          alt=""
                          width="64"
                          height="64"
                          className="rounded-circle active-userchatpic"
                        />
                      </div>
                      <p className="modal-friend-list-item-username">
                        {friend.fullName}
                      </p>
                    </div>
                    <button
                      className={`btn  ${"btn-light-blue"
                        }`}
                      onClick={() => {
                        handleStartChatWithFriend(friend.chatRoomId)
                      }}

                    >
                      Shart Chat
                    </button>
                  </div>
                );
              })}

            </div>
          </CustomModal>
        </div>
      </div>
    </Container>
  );
}
