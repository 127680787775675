import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Profile from "../Teacher/Profile";
import { Endpoints } from "../../../API/Endpoints";
import { FetchApi } from "../../../API/FetchApi";
import ToastMessage from "../../../Utils/ToastMessage";
import CourseItem from "./CourseItem";
const $ = window.jQuery;

export default function MyCourses() {
  const [loadStatus, setLoadStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(8);
  const navigate = useNavigate();
  const [course, setCourse] = useState([]);
  const [courseStatus, setCourseStatus] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sorting, setSorting] = useState("");
  const [dropdownTeachers, setDropdownTeachers] = useState([]);
  const [teacherId, setTeacherId] = useState([]);
  const params = useLocation().search;

  useEffect(() => {
    getCourse();
    getDropdownTeachers();
  }, []);

  const getDropdownTeachers = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetDropdownTeachers);
      if (resp && resp.success === true) {
        setDropdownTeachers(resp.data);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        ToastMessage.Error(e.response.data.message);
      }
    }
  };

  const getCourse = async () => {
    setLoadStatus(true);

    if (params) {
      try {
        let resp = await FetchApi(Endpoints.GetMyCourses + params);
        if (resp && resp.success === true) {
          setCourse(resp.data.courses);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          ToastMessage.Error(e.response.data.message);
          setLoadStatus(false);
        }
      }
    } else {
      try {
        let resp = await FetchApi(Endpoints.GetMyCourses + "?size=" + limit);
        if (resp && resp.success === true) {
          setCourse(resp.data.courses);
        }
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          ToastMessage.Error(e.response.data.message);
          setLoadStatus(false);
        }
      }
    }
  };

  const handleLoadMore = async () => {
    setLoading(true);
    const NewLimit = parseInt(limit) + 12;
    setLimit(NewLimit);

    let url = Endpoints.GetMyCourses + "?size=" + NewLimit;
    let navigateUrl = "/my-courses?size=" + NewLimit;

    if (searchKeyword) {
      url = url + "&keyword=" + searchKeyword;
      navigateUrl = navigateUrl + "&keyword=" + searchKeyword;
    }

    if (courseStatus) {
      url = url + "&type=" + courseStatus;
      navigateUrl = navigateUrl + "&type=" + courseStatus;
    }
    if (sorting) {
      url = url + "&sort=" + sorting;
      navigateUrl = navigateUrl + "&sort=" + sorting;
    }

    if (teacherId) {
      url = url + "&tid=" + teacherId;
      navigateUrl = navigateUrl + "&tid=" + teacherId;
    }

    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleSearch = (value) => {
    let url = Endpoints.GetMyCourses + "?size=" + limit;
    let navigateUrl = "/my-courses?size=" + limit;
    setSearchKeyword(value);

    if (value) {
      url = url + "&keyword=" + value;
      navigateUrl = navigateUrl + "&keyword=" + value;
    }

    if (courseStatus) {
      url = url + "&type=" + courseStatus;
      navigateUrl = navigateUrl + "&type=" + courseStatus;
    }
    if (sorting) {
      url = url + "&sort=" + sorting;
      navigateUrl = navigateUrl + "&sort=" + sorting;
    }

    if (teacherId) {
      url = url + "&tid=" + teacherId;
      navigateUrl = navigateUrl + "&tid=" + teacherId;
    }

    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleCourseStatus = (value) => {
    setCourseStatus(value);

    let url = Endpoints.GetMyCourses + "?size=" + limit;
    let navigateUrl = "/my-courses?size=" + limit;

    if (searchKeyword) {
      url = url + "&keyword=" + searchKeyword;
      navigateUrl = navigateUrl + "&keyword=" + searchKeyword;
    }

    if (value) {
      url = url + "&type=" + value;
      navigateUrl = navigateUrl + "&type=" + value;
    }

    if (sorting) {
      url = url + "&sort=" + sorting;
      navigateUrl = navigateUrl + "&sort=" + sorting;
    }

    if (teacherId) {
      url = url + "&tid=" + teacherId;
      navigateUrl = navigateUrl + "&tid=" + teacherId;
    }

    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleSorting = (value) => {
    setSorting(value);
    let url = Endpoints.GetMyCourses + "?size=" + limit;
    let navigateUrl = "/my-courses?size=" + limit;

    if (searchKeyword) {
      url = url + "&keyword=" + searchKeyword;
      navigateUrl = navigateUrl + "&keyword=" + searchKeyword;
    }

    if (courseStatus) {
      url = url + "&type=" + courseStatus;
      navigateUrl = navigateUrl + "&type=" + courseStatus;
    }

    if (value) {
      url = url + "&sort=" + value;
      navigateUrl = navigateUrl + "&sort=" + value;
    }

    if (teacherId) {
      url = url + "&tid=" + teacherId;
      navigateUrl = navigateUrl + "&tid=" + teacherId;
    }

    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  const handleCourseFilterData = async (url) => {
    try {
      let resp = await FetchApi(url);
      if (resp && resp.success === true) {
        setLoading(false);
        setCourse(resp.data.courses);
      } else {
        setLoading(false);
        setCourse([]);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        ToastMessage.Error(e.response.data.message);
        setLoading(false);
        setLoadStatus(false);
      }
    }
  };

  const handleTeacherChange = (id) => {
    setTeacherId(id);
    let url = Endpoints.GetMyCourses + "?size=" + limit;
    let navigateUrl = "/my-courses?size=" + limit;
    if (searchKeyword) {
      url = url + "&keyword=" + searchKeyword;
      navigateUrl = navigateUrl + "&keyword=" + searchKeyword;
    }
    if (courseStatus) {
      url = url + "&type=" + courseStatus;
      navigateUrl = navigateUrl + "&type=" + courseStatus;
    }
    if (sorting) {
      url = url + "&sort=" + sorting;
      navigateUrl = navigateUrl + "&sort=" + sorting;
    }
    if (id) {
      url = url + "&tid=" + id;
      navigateUrl = navigateUrl + "&tid=" + id;
    }
    navigate(navigateUrl);
    handleCourseFilterData(url);
  };

  return (
    <Container header>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-3">
            <Profile type="student" active={"courses"} />

            <div className="rounded-15 box-shadow p-md-4 p-2 bg-white">
              <div className="main-top-row d-lg-flex justify-content-between align-items-center pb-3">
                <h3 className="medium pb-lg-0 pb-2">My Courses</h3>

                <div className="d-md-flex gap-3 ">
                  <form
                    action=""
                    className="mainmenu-page-search d-flex flex-row-reverse align-items-center"
                  >
                    <input
                      type="text"
                      placeholder="Search by course title"
                      name="search"
                      className="bg-transparent fs-14 medium  text-darkgray"
                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                    />
                    <button type="submit" className="bg-transparent border-0">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="#949494"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="css-i6dzq1"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </button>
                  </form>

                  <select
                    name="sortby"
                    id="sortby"
                    className="text-darkgray status-drop"
                    onChange={(e) => {
                      handleCourseStatus(e.target.value);
                    }}
                  >
                    <option value="">Course Status</option>
                    <option value="completed">Completed</option>
                    <option value="incompleted">Incomplete</option>
                  </select>

                  <select
                    name="sortby"
                    id="sortby"
                    className="text-darkgray"
                    onChange={(e) => {
                      handleTeacherChange(e.target.value);
                    }}
                  >
                    <option value="">All Teacher</option>
                    {dropdownTeachers &&
                      dropdownTeachers?.length > 0 &&
                      dropdownTeachers?.map((teacher, idx) => (
                        <option value={teacher.id} key={teacher.id}>
                          {teacher.fullName}
                        </option>
                      ))}
                  </select>

                  <select
                    name="sortby"
                    id="sortby"
                    className="text-darkgray"
                    onChange={(e) => {
                      handleSorting(e.target.value);
                    }}
                  >
                    <option value="">Sort by</option>
                    <option value="a-z">Ascending</option>
                    <option value="z-a">Descending</option>
                    <option value="latest">latest</option>
                    <option value="old">last</option>
                  </select>
                </div>
              </div>
              {course.length > 0 ? (
                <div className="row">
                  {course.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <CourseItem
                          {...{
                            item: item,
                            index: index,
                            courseStatus: courseStatus,
                          }}
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              ) : (
                // courseStatus === 'incompleted' ?
                // <div className="row">
                //     No incompleted course added
                // </div>
                // :
                // courseStatus === 'completed' ?
                // <div className="row">
                //     No completed course added
                // </div>
                // :
                <div className="text-center mt-2">
                  <div>Oops!! You dont have any course. No, problem</div>
                  <Link
                    to={"/courses"}
                    className="btn fs-12 regular py-2 px-4 mt-2"
                  >
                    Browse Courses
                  </Link>
                </div>
              )}

              {course.length > 8 ? (
                <div class="text-center">
                  <Link
                    href="#"
                    class="text-blue"
                    onClick={() => handleLoadMore()}
                  >
                    {loading ? (
                      <img src="../assets/img/icon/icon-loading.svg" alt="" />
                    ) : (
                      "Load More Courses "
                    )}
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}
