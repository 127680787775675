import React from 'react'
import { Endpoints } from '../../../API/Endpoints';
import ToastMessage from "../../../Utils/ToastMessage";
import { Link, useNavigate } from 'react-router-dom';
import { CommonAPi } from "../../../API/CommonApi";

const $ = window.jQuery;
export default function EditGroupName({ setGroupName, setEditGroupId, EditGroupId, GroupName, setShowEditGroupNameModal, onSuccess }) {
    const navigate = useNavigate();
    const handleSubmit  = async(e) =>{
        e.preventDefault();
          
        if(GroupName && EditGroupId){
            let data = {
                chatRoomId:EditGroupId,
                name:GroupName
            }
        
        try {
            let resp = await CommonAPi(Endpoints.MessageGroupEdit, data);
            if (resp && resp.success === true) {
               
                setEditGroupId("");
                setGroupName("");
                setEditGroupId(false);
                onSuccess(true);
                ToastMessage.Success(resp.message);
            }
        }
        catch (e) {

            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {

                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }


            }
        }
        }
    };
    return (
        <div
            className="modal fade show GroupNameModal"
            id="EditGroupNameModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="EditGroupNameModal">
                            Edit Group Name
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                setShowEditGroupNameModal(false)
                            }}
                        ></button>
                    </div>
                    <div className="modal-body">
                    <form action="" onSubmit={handleSubmit} class="site-form">
                         
                            <div class="form-field mb-3">
                                <label for="" class="pb-1 fs-14 medium">Group Name *</label>
                                <input
                                    type="text"
                                    placeholder="Group name *"
                                    class="fs-14 border rounded-3 py-2 px-3 w-100"
                                    value={GroupName}
                                    onChange={(e) => {
                                        setGroupName(e.target.value)
                                    }}
                                    
                                />
                               
                            </div>
                            
                            <input type="submit"  value="Save" class="btn fs-14 medium px-5" />
                            
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}
