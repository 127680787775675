import React, { useEffect, useState } from "react";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import ProfileNavigation from "../../../Components/ProfileNavigation";
import Container from "../../../Components/Container";
import Profile from "../../ProfileAbout/Profile";
import { Endpoints } from "../../../API/Endpoints";
import ToastMessage from "../../../Utils/ToastMessage";
import { CommonAPi } from "../../../API/CommonApi";
import { FetchApi } from "../../../API/FetchApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const jQuery = window.jQuery;

function Friend() {
  const [loadStatus, setLoadStatus] = useState(false);
  const [friendData, setFriendData] = useState([]);
  const [activeTab, setActiveTab] = useState("Friend");
  const [friendsFromCity, setFriendsFromCity] = useState(0);
  const [friendsFromSchool, setFriendsFromSchool] = useState(0);
  const [friendsFromBirthday, setFriendsFromBirthday] = useState(0);
  const [limit, setLimit] = useState(8);
  const [count, setCount] = useState(0);
  const Navigate = useNavigate();
  const param = useParams();
  const userId = localStorage.getItem("userId");
  const username = localStorage.getItem("userName");

  useEffect(() => {
    getFriend("Friend");
  }, [param?.id]);
  const getFriend = async () => {
    setActiveTab("Friend");
    try {
      let resp = await FetchApi(
        Endpoints.GetAllFriend +
          "?size=" +
          limit +
          "&page=1" +
          "&id=" +
          param?.id
      );
      if (resp && resp.success === true) {
        setFriendData(resp?.data?.friends);
        setCount(resp?.data?.friendsCount);
        setFriendsFromBirthday(resp?.data?.friendsWithBirthday);
        setFriendsFromSchool(resp?.data?.friendsFromSchool);
        setFriendsFromCity(resp?.data?.friendsFromCity);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const sendFriendRequest = async (Id) => {
    let data = {
      receiverId: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.SendFriendRequest, data);
      if (resp && resp.message) {
        getFriend();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleGetFriend = async (value, activeTab) => {
    setActiveTab(activeTab);
    try {
      let resp = await FetchApi(
        Endpoints.GetAllFriend +
          "?size=" +
          limit +
          "&page=1&" +
          value +
          "&id=" +
          param?.id
      );
      if (resp && resp.success === true) {
        setFriendData(resp?.data?.friends);
        setFriendsFromBirthday(resp?.data?.friendsWithBirthday);
        setFriendsFromSchool(resp?.data?.friendsFromSchool);
        setFriendsFromCity(resp?.data?.friendsFromCity);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleSearchFriend = async (value) => {
    try {
      let resp = await FetchApi(
        Endpoints.GetAllFriend +
          "?size=" +
          limit +
          "&page=1" +
          value +
          "&id=" +
          param?.id +
          "&" +
          activeTab +
          "=true"
      );
      if (resp && resp.success === true) {
        setFriendData(resp?.data?.friends);
        setFriendsFromBirthday(resp?.data?.friendsWithBirthday);
        setFriendsFromSchool(resp?.data?.friendsFromSchool);
        setFriendsFromCity(resp?.data?.friendsFromCity);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleLoadMore = async () => {
    let newLimit = limit + 8;
    setLimit(newLimit);
    setLoadStatus(true);
    try {
      let resp = await FetchApi(
        Endpoints.GetAllFriend +
          "?size=" +
          newLimit +
          "&page=1" +
          "&id=" +
          param?.id +
          "&" +
          activeTab +
          "=true"
      );
      if (resp && resp.success === true) {
        setFriendData(resp?.data?.friends);
        setFriendsFromBirthday(resp?.data?.friendsWithBirthday);
        setFriendsFromSchool(resp?.data?.friendsFromSchool);
        setFriendsFromCity(resp?.data?.friendsFromCity);
        setLoadStatus(false);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setLoadStatus(false);
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleBlockUser = async (Id) => {
    let data = {
      blockedUser: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.BlockFriend, data);
      if (resp && resp.message) {
        getFriend();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };
  const handleUnfriend = async (Id) => {
    let data = {
      friend_id: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.Unfriend, data);
      if (resp && resp.message) {
        getFriend();
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <Container header>
      <Helmet>
        <title>Friends</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-lg-3 py-3">
            <div className="profile-banner-top mb-3  rounded-15 overflow-hidden">
              <Profile />

              <ProfileNavigation />
            </div>

            <div class="bg-white main-common">
              <div class="main-top-row d-md-flex justify-content-between align-items-center pb-3">
                <h3 class="medium">Friends</h3>
                {/* {username === param?.id ? */}
                <div class="d-sm-flex gap-3 pb-3">
                  <form
                    action=""
                    class="mainmenu-page-search d-flex flex-row-reverse align-items-center"
                  >
                    <input
                      type="text"
                      placeholder="Search for people"
                      name="search"
                      class="bg-transparent fs-14 medium  text-darkgray"
                      onChange={(e) => {
                        handleSearchFriend("&term=" + e.target.value);
                      }}
                    />
                    <button type="submit" class="bg-transparent border-0">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="#949494"
                        strokeWidth="2"
                        fill="none"
                        stroke-linecap="round"
                        strokeLinejoin="round"
                        class="css-i6dzq1"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </button>
                  </form>
                  <select
                    name="sortby"
                    id="sortby"
                    class="text-darkgray"
                    onChange={(e) => {
                      handleSearchFriend("&sort=" + e.target.value);
                    }}
                  >
                    <option value="">Sort by</option>
                    <option value="a-z">Ascending</option>
                    <option value="z-a">Descending</option>
                    <option value="oldest">Oldest</option>
                    <option value="latest">Recently</option>
                  </select>
                </div>
                {/* :""} */}
              </div>
              {/* {username === param?.id ? */}
              <div class="mainmenu-filter-row pb-3">
                <ul class="filter d-flex flex-nowrap align-items-center gap-2 fs-14 medium">
                  <li
                    class={activeTab === "Friend" ? "active " : ""}
                    onClick={() => {
                      getFriend();
                    }}
                  >
                    All Friends <span class="fs-10 text-brown">{count}</span>
                  </li>
                  <li
                    class={activeTab === "birthday" ? "active " : ""}
                    onClick={() => {
                      handleGetFriend("birthday=true", "birthday");
                    }}
                  >
                    Birthdays{" "}
                    <span class="fs-10 text-brown">{friendsFromBirthday}</span>
                  </li>
                  {/* <li class={activeTab === "classmate" ? "active " : ""}
                                    onClick={()=>{
                                        handleGetFriend("classmate=true", "classmate")
                                    }}
                                    >Classmates <span class="fs-10 text-brown">100</span></li> */}
                  <li
                    class={activeTab === "school" ? "active " : ""}
                    onClick={() => {
                      handleGetFriend("school=true", "school");
                    }}
                  >
                    School{" "}
                    <span class="fs-10 text-brown">{friendsFromSchool}</span>
                  </li>
                  <li
                    class={activeTab === "city" ? "active " : ""}
                    onClick={() => {
                      handleGetFriend("city=true", "city");
                    }}
                  >
                    Current City{" "}
                    <span class="fs-10 text-brown">{friendsFromCity}</span>
                  </li>
                </ul>
              </div>
              {/* :""} */}

              <div class="row mainmenu-filter-conatiner profile-friend-list">
                {friendData?.length > 0 ? (
                  friendData.map((item, index) => {
                    return (
                      <div
                        class="col-lg-3 col-md-4 col-sm-6 mb-4"
                        data-category="birthday"
                        key={index}
                      >
                        <div class="mainmenu-page-item">
                          <div class="text-center position-relative">
                            {username === param?.id ? (
                              <div class="mainmenu-item-action position-absolute end-0 top-0 m-2 p-1">
                                <a
                                  href="#"
                                  id="friendDropdown"
                                  data-bs-toggle="dropdown"
                                  class="d-flex align-items-center justify-content-center rounded-circle bg-eee p-1"
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="18"
                                    height="18"
                                    stroke="#000"
                                    strokeWidth="2"
                                    fill="none"
                                    stroke-linecap="round"
                                    strokeLinejoin="round"
                                    class="css-i6dzq1"
                                  >
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="19" cy="12" r="1"></circle>
                                    <circle cx="5" cy="12" r="1"></circle>
                                  </svg>
                                </a>
                                <ul
                                  class="popup-menu shadow rounded-1 dropdown-menu text-start"
                                  aria-labelledby="friendDropdown"
                                >
                                  <li>
                                    <a
                                      class="dropdown-item py-2 d-flex gap-2"
                                      href="#"
                                      onClick={() => {
                                        handleBlockUser(item?.id);
                                      }}
                                    >
                                      {/* <svg viewBox="0 0 24 24" width="15" height="15" stroke="currentColor" strokeWidth="2" fill="none" stroke-linecap="round" strokeLinejoin="round" class="css-i6dzq1"><polyline points="20 6 9 17 4 12"></polyline></svg> */}{" "}
                                      Block
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      class="dropdown-item py-2 d-flex gap-2"
                                      href="#"
                                      onClick={() => {
                                        handleUnfriend(item?.id);
                                      }}
                                    >
                                      {/* <svg viewBox="0 0 24 24" width="15" height="15" stroke="currentColor" strokeWidth="2" fill="none" stroke-linecap="round" strokeLinejoin="round" class="css-i6dzq1"><polyline points="20 6 9 17 4 12"></polyline></svg> */}{" "}
                                      Unfriend
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                            {item.user_profile.coverImg === null ? (
                              <img
                                src="../assets/img/pro-banner.png"
                                class="mainmenu-page-banner"
                                alt=""
                              />
                            ) : (
                              <img
                                src={
                                  Endpoints.baseUrl + item.user_profile.coverImg
                                }
                                class="mainmenu-page-banner"
                                alt=""
                              />
                            )}
                            <span class="suggested-user">
                              <Link to={"/timeline/" + item.username}>
                                {item.user_profile.profileImg === null ? (
                                  <img
                                    src="../assets/img/picture.png"
                                    alt=""
                                    width="96"
                                    height="96"
                                    class="rounded-circle"
                                  />
                                ) : (
                                  <img
                                    src={
                                      Endpoints.baseUrl +
                                      item.user_profile.profileImg
                                    }
                                    alt=""
                                    width="96"
                                    height="96"
                                    class="rounded-circle"
                                  />
                                )}
                              </Link>
                            </span>
                            <Link to={"/timeline/" + item.username}>
                              <h5 class="semibold pt-2 pb-2">
                                {item.fullName}
                              </h5>
                            </Link>
                            <h6 class="fs-14 text-darkgray regular pb-1">
                              {item.user_profile.profileTagline}
                            </h6>
                            {/* <h6 class="fs-14 text-darkgray medium pb-1">Sapphire International School, Noida</h6> */}
                            <h6 class="fs-14 text-darkgray regular pb-2">
                              {item?.mutualFriends} Mutual Friends
                            </h6>
                          </div>
                          {username === param?.id ? (
                            <div class="text-center pt-2 pb-3 px-3">
                              <Link
                                to={`/friend/messages?roomId=${item.chatRoomId}`}
                                class="btn btn-brown w-100 py-2 fs-14 mw-100"
                              >
                                Send a Message
                                <img
                                  src="../assets/img/icon/message.svg"
                                  class="ps-2"
                                />
                              </Link>
                            </div>
                          ) : item?.friendStatus == "NA" ? (
                            item.id == userId ? (
                              ""
                            ) : (
                              <div class="text-center pt-2 pb-3 px-3">
                                <a
                                  href="#"
                                  class="btn w-100"
                                  onClick={() => {
                                    sendFriendRequest(item.id);
                                  }}
                                >
                                  <img
                                    src="../assets/img/icon/icon-add-friend.svg"
                                    class="pe-2"
                                  />
                                  Add Friend
                                </a>
                              </div>
                            )
                          ) : item?.friendStatus == "ACCEPTED" ? (
                            <div class="text-center pt-2 pb-3 px-3">
                              <Link
                                to={`/friend/messages?roomId=${item.chatRoomId}`}
                                class="btn btn-brown w-100 py-2 fs-14 mw-100"
                              >
                                Send a Message
                                <img
                                  src="../assets/img/icon/message.svg"
                                  class="ps-2"
                                />
                              </Link>
                            </div>
                          ) : (
                            <div class="text-center pt-2 pb-3 px-3">
                              <Link class="btn btn-blue w-100 py-2 fs-14 mw-100">
                                {item?.friendStatus}
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
                    No Friends Found
                  </div>
                )}
              </div>
              {friendData?.length >= 8 && count > limit ? (
                <div className="text-center">
                  <Link
                    href="#"
                    className="text-blue"
                    onClick={() => handleLoadMore()}
                  >
                    {loadStatus ? (
                      <img src="../assets/img/icon/icon-loading.svg" alt="" />
                    ) : (
                      "Load More Friend "
                    )}
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  );
}

export default Friend;
