import React, { useState, useEffect } from 'react';
import InterestsScreen from '../ProfileAbout/Interests';
import TopHeader from '../../Components/TopHeader';
import Welcome from './Welcome';
import {Helmet} from "react-helmet";
const $ = window.jQuery;

export default function Interests() {
    const userRoleType = localStorage.getItem("userRoleType");

    useEffect(() => {
        if (userRoleType === 'child') {
            var percent = 33;
        } else {
            var percent = 20;
        }
        percent = percent.toFixed();
        $(".progress-bar")
            .css("width", percent + "%")

    }, []);

    return (
        <>
             <Helmet>
                <title>Interests</title>
            </Helmet>
            <TopHeader />

            <div className="container-fluid bg-f5f5f5">

                <div className="row justify-content-center">
                    <div className="col-12 col-md-8">
                        <div className=" card  bg-f5f5f5">
                            <Welcome />
                            <ul id="progressbar" className="text-center" style={{ '--value': userRoleType === 'child' ? '33%' : '20%' }}>
                                <li className="active" id="personal"><strong>Interests</strong></li>
                                <li id="personal"><strong>Basic Details</strong></li>
                                <li id="payment"><strong>Image Upload</strong></li>
                                {userRoleType === 'child' ? "" :
                                    <li id="account"><strong>Verification</strong></li>
                                }
                                {userRoleType === 'child' ? "" : userRoleType === 'student' ? (
                                    <li id="confirm"><strong>Academics</strong></li>
                                ) :
                                    <li id="confirm"><strong>Experience</strong></li>
                                }
                            </ul>
                            <div className="progress">
                                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100">
                                    {userRoleType === 'child' ? '33 % ' : '20 %'}
                                </div>
                            </div>
                        </div>
                        <br />
                        <fieldset>
                            <div className="row">
                                <div className="col-7">
                                    <h2 className="fs-title">Choose Your Interests</h2>
                                </div>
                                <div className="col-5">
                                    {userRoleType === 'child' ?
                                        <h2 className="steps">Step 1 - 3</h2>
                                        :
                                        <h2 className="steps">Step 1 - 5</h2>
                                    }
                                </div>
                            </div>
                            <InterestsScreen />
                        </fieldset>
                    </div>
                </div>
            </div>

        </>
    )
}
