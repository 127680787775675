export default function IsOnline() {
  return (
    <span class="user-status">
      <svg
        viewBox="0 0 24 24"
        width="12"
        height="12"
        stroke="#fff"
        stroke-width="1"
        fill="#57CB6C"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <circle cx="12" cy="12" r="10"></circle>
      </svg>
    </span>
  );
}
