import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ToastMessage from "../../Utils/ToastMessage";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import { FetchApi } from '../../API/FetchApi'

export default function Item({item, index, onSuccess}) {
    const navigate = useNavigate();
    const handleFollow = async(id) =>{
        let data = {
            hashtagId: id
        }
        try {
            let resp = await CommonAPi(Endpoints.HashtagsFollow, data);
            if (resp && resp.success === true) {
                onSuccess(true);
                ToastMessage.Success(resp.message)
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {

                    ToastMessage.Error('Session Expired! Please login again.')
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message)
                }

            }
        }
    };
    const handleUnfollow = async(id) =>{
        let data = {
            hashtagId: id
        }
        try {
            let resp = await CommonAPi(Endpoints.HashtagsUnfollow, data);
            if (resp && resp.success === true) {
                onSuccess(true);
                ToastMessage.Success(resp.message)
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {

                    ToastMessage.Error('Session Expired! Please login again.')
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message)
                }

            }
        }
    };
  return (
    <div className="col-md-4 col-sm-6 mb-3" key={index}>
    <div className="hashtag-item d-flex align-items-center">
        <span className="hastag-icon">
            <img src="assets/img/icon/Hashtags.svg" alt=""/>
        </span>
        <div className="ps-3 d-flex align-items-end justify-content-between gap-2 hashtag">
            <div>
                <Link to={"/singlehashtag?tag="+item?.tag}>
                <h4 className="semibold pb-1">#{item?.tag.length > 10 ? item?.tag.substring(0, 8)+"..." : item?.tag }</h4>
                </Link>
                <h6 className="fs-14 text-darkgray regular">{item?.totalPeople} People used this hashtag</h6>
            </div>
            {item?.isFollowing === 0 ? 
            <Link onClick={()=>{
                handleFollow(item?.id)
            }} className="btn py-2 fs-14">Follow</Link>
            :  
            <Link onClick={()=>{
                handleUnfollow(item?.id)
            }} className="follow-hashtag-btn py-2 fs-14">Unfollow</Link>
            }
        </div>
    </div>
</div>
  )
}
