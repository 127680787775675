import React, { useState } from 'react'
import ToastMessage from '../../../Utils/ToastMessage';
import { Endpoints } from '../../../API/Endpoints';
import { useNavigate } from 'react-router-dom';
const InvoiceBtn = ({item}) => {
    const navigate = useNavigate();
    const [invoiceText, setInvoiceText] = useState("Invoice");

    const downloadPdf = async (id, endpoint) => {
        setInvoiceText("Downloading");
        if(invoiceText !== "Invoice") return;
        try {
            const response = await fetch(endpoint+id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                     profile:localStorage.getItem("userId"),
                    'Authorization':"Bearer " + localStorage.getItem('token')
                },

            });
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `invoice.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setInvoiceText("Invoice");

        } catch (e) {
            console.error(e);
            setInvoiceText("Invoice");
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }
    };

    const handleInvoice = async (id) => {
        if(invoiceText !== "Invoice") return;
        try {
            await downloadPdf(id, Endpoints.baseUrl+Endpoints.DownloadInvoice, "Invoice")
        }
        catch (e) {
            setInvoiceText("Invoice");
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }
            }
        }
    }
  return (
   <a href="javascript:void(0)" key={item.id} id={item.id} onClick={(e)=> handleInvoice(item.id)} className="btn gray-btn text-darkgray fs-14 py-2">
                                                        {invoiceText} <img src="assets/img/icon/invoice-icon.svg" alt="" /></a>
  )
}

export default InvoiceBtn