import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import StickyChatWrapper from '../StickyChatWrapper';
import queryString from 'query-string';
const ProtectedRoute = ({ element: Component }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search
  const token = localStorage.getItem("token");
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    if (!token) navigate("/signin");
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.exp < Date.now() / 1000) {
        localStorage.clear();
        navigate("/signin");
      } else {
        setIsValid(true);
      }
    }
    const user = JSON.parse(localStorage.getItem("user"));
    const profileCompleteness = parseFloat(
      localStorage.getItem("ProfileCompletion")
    );
    if (user?.hasInterest === false) {
      navigate("/select-interests" + "/" + user?.id);
    } else if (Math.round(profileCompleteness) < 25) {
      navigate("/complete-profile-step1" + "/" + user?.id);
    } else if (Math.round(profileCompleteness) < 50) {
      navigate("/complete-profile-step2" + "/" + user?.id);
    } else if (Math.round(profileCompleteness) < 75) {
      navigate("/complete-profile-step3" + "/" + user?.id);
    } else if (Math.round(profileCompleteness) < 100) {
      navigate("/complete-profile-step4" + "/" + user?.id);
    } else {
      // setIsValid(true);
      // navigate("/mainfeed");
    
      if(search){
        navigate(location.pathname + search);
      }else{
        navigate(location.pathname);
      }
      
    }

    // if (user?.children?.length > 0) {
    //         navigate("/select-profile" + "/" + user?.id);
    //     } else
  }, []);
  return isValid === true ? (
    <StickyChatWrapper>
      <Component />
    </StickyChatWrapper>
  ) : (
    navigate("/signin")
  );
};

export default ProtectedRoute;
