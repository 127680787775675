import React, { useEffect, useState } from 'react'
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Container from "../../Components/Container";
import LeftNavigation from "../../Components/LeftNavigation";
import OnlineFriends from "../../Components/OnlineFriends";
import { Helmet } from "react-helmet";
import moment from "moment";
export default function Applied() {
  const [jobData, setJobData] = useState([]);
  const [limit, setLimit] = useState(9);
  const [count, setCount] = useState(0);
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const pathName = useLocation();
  useEffect(() => {
    getSavedJob();
  }, [limit]);
  const getSavedJob = async () => {
    if (pathName.pathname === "/applied-internship") {
      try {
        let resp = await FetchApi(Endpoints.GetAppliedJobs + "?scope=Internship" + "&size=" + limit);
        if (resp && resp.success === true) {
          setJobData(resp?.data?.allJob);
          setCount(resp?.data?.totalRecord);
        }
      }
      catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error('Session Expired! Please login again.');
            localStorage.clear();
            navigate('/signin');
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    } else {
      try {
        let resp = await FetchApi(Endpoints.GetAppliedJobs + "?scope=Job" + "&size=" + limit);
        if (resp && resp.success === true) {
          setJobData(resp?.data?.allJob);
          setCount(resp?.data?.totalRecord);
        }
      }
      catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error('Session Expired! Please login again.');
            localStorage.clear();
            navigate('/signin');
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }

  };

  const JobSave = async (Id) => {
    let data = {
      jobId: Id
    }
    try {
      let resp = await CommonAPi(Endpoints.JobSave, data);
      if (resp && resp.success === true) {
        getSavedJob();
        ToastMessage.Success(resp.message);
      }
    }
    catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error('Session Expired! Please login again.');
          localStorage.clear();
          navigate('/signin');
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const timeSince = (date, isCurrent = false) => {
    if (isCurrent) return 0 + " sec";
    let seconds = Math.floor((new Date() - date) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) return Math.floor(interval) + "year ago";
    interval = seconds / 2592000;

    if (interval > 1) return Math.floor(interval) + " mon ago";
    interval = seconds / 86400;

    if (interval > 1) return Math.floor(interval) + " day ago";
    interval = seconds / 3600;

    if (interval > 1) return Math.floor(interval) + " h ago";
    interval = seconds / 60;

    if (interval > 1) return Math.floor(interval) + " min ago";
    return Math.floor(seconds) >= 0 ? Math.floor(seconds) + " sec ago" : "0 sec ago";
  };

  return (
    <Container header>
      <Helmet>
        {pathName.pathname === "/applied-jobs" ?
          <title>Applied Jobs</title>
          :
          <title>Applied Internship</title>
        }
      </Helmet>
      <div class="wrapper wrapper-bg">

        <div class="main">
          <LeftNavigation />

          <div class="container py-3">
            {pathName.pathname === "/applied-jobs" ?
              <div className="text-center">
                <Link to={"/save-jobs"} className="p-2 m-2">Saved Jobs</Link>
                <Link to={"/applied-jobs"} className="p-2 m-2 active-job">Applied Jobs</Link>
              </div>
              :
              <div className="text-center">
                <Link to={"/save-internship"} className="p-2 m-2 ">Saved Internship</Link>
                <Link to={"/applied-internship"} className="p-2 m-2 active-job">Applied Internship</Link>
              </div>
            }
            <div className="bg-white main-common mt-4">
              <div class="d-md-flex align-items-center justify-content-between pb-4">
                <div>

                </div>
                <div>
                  <span class="fs-12 text-gray "><span class="text-blue medium text-right">{count}</span> results found</span>
                </div>
              </div>
              {jobData.length > 0 ?
                <div className="row">
                  {jobData.map((item, index) => {
                    return (
                      <div className="col-md-4 mb-3" key={index}>
                        <div className="job-featured-item d-flex gap-2 align-items-start w-100">
                          <span>
                          <Link to={"/jobs/" + item?.id}>
                            {item?.job?.page?.pageImage === null ?
                              <img src="assets/img/company.svg" alt="" width="64" height="64" className="rounded-circle" />
                              :
                              <img src={Endpoints.baseUrl + item?.job?.page?.pageImage} alt="" width="64" height="64" className="rounded-circle" />
                            }
                            </Link>
                          </span>
                          <div className="w-100">
                            <div className="d-flex justify-content-between w-100">
                              <Link to={"/jobs/" + item?.job?.id}>
                                <h5 className="semibold">{item?.job?.title}</h5>
                              </Link>
                              {item?.isSaved === "true" ?
                                <Link onClick={() => {
                                  JobSave(item?.job?.id)
                                }}><img src="assets/img/icon/save-fill.svg" width="16" alt="" /></Link>
                                :

                                <Link onClick={() => {
                                  JobSave(item?.job?.id)
                                }}><img src="assets/img/icon/save.svg" width="16" alt="" /></Link>


                              }
                            </div>
                            {/* <h5 className="medium text-brown ">{item?.job?.page?.title}</h5> */}
                            <Link to={"/page-profile/" + item?.job?.page?.slug}>
                                <h5 class="medium text-brown ">{item?.job?.page?.title}</h5>
                            </Link>
                            <div className="d-flex py-2 align-items-center">
                              <span className="fs-10 text-darkgray pe-2 d-flex align-items-center">
                                <img src="assets/img/icon/icon-location.svg" alt="" className="align-middle me-1" />
                                <span>{item?.job?.city?.city}{item?.job?.city?.city ? "," : ""} {item?.job?.state?.state}</span>
                              </span>

                              <span className="fs-10 text-darkgray d-flex align-items-center">
                                <img src="assets/img/icon/icon-date-time.svg" alt="" className="align-middle me-1" />
                                <span>{timeSince(new Date(item?.job?.createdAt))}</span>
                              </span>
                            </div>

                            <div className="d-flex request-btn gap-2 mb-2">
                              <a href="#" className="btn light-brown-btn px-2 medium">{item?.job?.jobType}</a>
                              <a href="#" className="btn light-brown-btn px-2 medium">{item?.job?.workMode}</a>
                            </div>
                            <Link to={"/jobs/" + item?.job?.id} className="btn px-4 py-2 fs-14 ">View Job</Link>
                            <div className='d-flex justify-content-between w-100 py-1'>
                                <div></div>
                                <div className="fs-10 text-darkgray d-flex text-end">
                                    <img src="assets/img/icon/icon-date-time.svg" alt="" className="align-middle me-1" />
                                    <span>
                                      Applied on {
                                            moment(item?.job?.createdAty).format("DD MMM, YY")
                                        }</span>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}




                </div>
                :
                <div className="row">
                  {pathName.pathname === "/applied-internship" ? "No applied internship found" : "No applied Jobs found"}
                </div>
              }
              {limit < count ?
                <div className="text-center py-3">
                  <Link onClick={() => {
                    setLimit(limit + 9)
                  }} className="btn px-4" > {pathName.pathname === "/applied-internship" ? 'View More Internship' : 'View More Jobs'}</Link>
                </div>
                : ""}


            </div>
          </div>
          <OnlineFriends />
        </div>
      </div>
    </Container>
  )
}
