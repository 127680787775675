import React, { useContext, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Container from "../../../Components/Container";
import LeftNavigation from "../../../Components/LeftNavigation";
import OnlineFriends from "../../../Components/OnlineFriends";
import Profile from "../Teacher/Profile";
import { Endpoints } from "../../../API/Endpoints";
import { CommonAPi } from "../../../API/CommonApi";
import ChatBox from "./ChatBox";
import ChatListItem from "./ChatListItem";
import PostLoader from "../../../Components/PostLoader/PostLoader";
import TeacherListModal from "./TeacherLIstModel";
import { StickyChatContext } from "../../../Components/StickyChatWrapper";
import { Helmet } from "react-helmet";
export default function Messages() {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const { setActiveChatIdInFullView, onlineUsers } =
    useContext(StickyChatContext);

  const [chats, setChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(false);
  const [receivedMsg, setReceivedMsg] = useState(null);
  const [showList, setShowList] = useState(false);
  const [active, setActive] = useState(null);
  const [search, setSearch] = useState("");
  const [chatsBackup, setChatsBackup] = useState([]);
  const roomId = searchParams.get("roomId");

  // setting id of the open chat to root component
  useEffect(() => {
    if (active) setActiveChatIdInFullView(active);
  }, [active]);
  // setting id to null when component unmounts
  useEffect(
    () => () => {
      setActiveChatIdInFullView(null);
    },
    []
  );

  const handleSearch = (e) => {
    if (search) {
      setChats(
        chats.filter((chat) => {
          if (chat.roomType === "course") {
            return (
              chat.title.toLowerCase().startsWith(search.toLowerCase()) ||
              chat.title.toLowerCase().endsWith(search.toLowerCase()) ||
              chat.title.toLowerCase().includes(search.toLowerCase())
            );
          } else {
            return (
              chat.user.fullName
                .toLowerCase()
                .startsWith(search.toLowerCase()) ||
              chat.user.fullName.toLowerCase().endsWith(search.toLowerCase()) ||
              chat.user.fullName.toLowerCase().includes(search.toLowerCase())
            );
          }
        })
      );
    } else {
      setChats(chatsBackup);
    }
  };

  const getUserChats = async () => {
    try {
      let resp;
      if (location.pathname === "/teacher/messages") {
        resp = await CommonAPi(Endpoints.GetTeacherChats + user.id);
      } else {
        resp = await CommonAPi(Endpoints.GetUserChats + user.id);
      }
      if (resp?.success) {
        const sortedChats = resp.data.sort(
          (a, b) => new Date(b?.msgTimeStamp) - new Date(a?.msgTimeStamp)
        );

        setChats(sortedChats);
        setChatsBackup([...sortedChats]);

        // selecting a chat to display
        if (sortedChats.length) {
          const userId = searchParams.get("userId");
          const roomId = searchParams.get("roomId");
          const type = searchParams.get("type");
          const index = sortedChats.findIndex((item) => {
            if (userId) {
              if (type) {
                return (
                  item.roomType === type &&
                  item.chat_room_members.some(
                    (user) => user.userId === parseInt(userId)
                  )
                );
              }
              return (
                item.roomType !== "course" &&
                item.chat_room_members.some(
                  (user) => user.userId === parseInt(userId)
                )
              );
            }
            if (roomId) {
              return item.id === parseInt(roomId);
            }
          });
          // select chat according to params
          if (index !== -1) {
            const chat = sortedChats[index];
            setCurrentChat(chat);
            setActive(chat.id);
            if (chat.unReadMsgCount > 0) {
              // read un-read message
              const newChats = structuredClone(sortedChats);
              newChats[index].unReadMsgCount = 0;
              setChats(newChats);
            }
          }
          // select the first one
          else {
            const chat = sortedChats[0];
            setCurrentChat(chat);
            setActive(chat.id);
            if (chat.unReadMsgCount > 0) {
              // read un-read message
              const newChats = structuredClone(sortedChats);
              newChats[0].unReadMsgCount = 0;
              setChats(newChats);
            }
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // get user chats
  useEffect(() => {
    setIsLoading(true);
    getUserChats();
  }, [user?.id, roomId]);

  const checkOnlineStatus = (chat) => {
    if (chat) {
      const currentMember = chat.chat_room_members.find(
        (each) => each.userId !== user.id
      );
      const isOnline = onlineUsers.find(
        (user) => user.userId === currentMember.userId
      );
      return !!isOnline;
    }
  };

  const handleChatFilter = (type) => {
    if (type === "all") setChats(chatsBackup);
    if (type === "unread")
      setChats(chatsBackup.filter((chat) => chat.unReadMsgCount > 0));
    if (type === "broadcast")
      setChats(chatsBackup.filter((chat) => chat.roomType === "course"));
  };
  return (
    <Container header>
      <Helmet>
        <title>Message</title>
      </Helmet>
      <div className="wrapper wrapper-bg">
        <div className="main">
          <LeftNavigation />
          <div className="container py-3">
            <Profile
              type={`${
                location.pathname === "/teacher/messages"
                  ? "teacher"
                  : "student"
              }`}
              active={"messages"}
            />
            <div className="">
              {isLoading ? (
                <PostLoader />
              ) : (
                <div className="row bg-white rounded-15 mx-0 overflow-hidden w-100">
                  <div className="col-md-4 border-end px-md-0">
                    <div className="py-3 px-3 d-flex align-items-center justify-content-between">
                      <h3 className="medium">Messages</h3>

                      {location.pathname === "/messages" && (
                        <a
                          href=""
                          data-bs-toggle="modal"
                          data-bs-target="#teacher-list-model"
                          className="btn btn-light-blue medium py-2 fs-14 px-4"
                          onClick={() => {
                            setShowList(true);
                          }}
                        >
                          + Start new chat{" "}
                        </a>
                      )}
                    </div>
                    <div className="px-3 pb-3 border-bottom d-flex align-items-center gap-2">
                      <form
                        action=""
                        className="message-search d-flex flex-row-reverse align-items-center justify-content-end w-100 "
                      >
                        <input
                          type="text"
                          placeholder="Search for Conversations"
                          name="search"
                          className="bg-transparent fs-14 medium"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          onKeyUp={handleSearch}
                        />
                        <button
                          type="submit"
                          className="bg-transparent border-0 ps-0"
                        >
                          <svg
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            stroke="#949494"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="css-i6dzq1"
                          >
                            <circle cx="11" cy="11" r="8"></circle>
                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                          </svg>
                        </button>
                      </form>

                      {/* <a href="#" className="msg-filter-icon" id="message_option" data-bs-toggle="dropdown">
                                    <img src="/assets/img/icon/icon-filter.svg"/>
                                </a> */}

                      <div className="position-relative">
                        <a
                          href="#"
                          style={{
                            display: "block",
                            height: "40px",
                            width: "40px",
                          }}
                          className="msg-filter-icon"
                          id="message_option"
                          data-bs-toggle="dropdown"
                        >
                          <img src="/assets/img/icon/icon-filter.svg" />
                        </a>
                        <div
                          className="dropdown-menu shadow rounded-1"
                          aria-labelledby="message_option"
                        >
                          <ul className="fs-14">
                            <li>
                              <a
                                className="dropdown-item px-1"
                                href=""
                                onClick={() => handleChatFilter("all")}
                              >
                                <svg
                                  style={{ marginRight: ".55rem" }}
                                  viewBox="0 0 24 24"
                                  width="15"
                                  height="15"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="css-i6dzq1"
                                >
                                  <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>
                                All
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item px-1"
                                href=""
                                onClick={() => handleChatFilter("unread")}
                              >
                                <svg
                                  style={{ marginRight: ".55rem" }}
                                  viewBox="0 0 24 24"
                                  width="15"
                                  height="15"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="css-i6dzq1"
                                >
                                  <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                  <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                </svg>
                                Unread
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item px-1"
                                href=""
                                onClick={() => handleChatFilter("broadcast")}
                              >
                                <svg
                                  style={{ marginRight: ".55rem" }}
                                  viewBox="0 0 24 24"
                                  width="15"
                                  height="15"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="css-i6dzq1"
                                >
                                  <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                  <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                </svg>
                                Broadcast
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="msg-left-sidebar py-3">
                      <ul className="user-messages">
                        {chats.length > 0 &&
                          chats.map((chat, i) => (
                            <div
                              onClick={() => {
                                setCurrentChat(chat);
                                setActive(chat.id);
                                // read un-read message
                                const newChats = structuredClone(chats);
                                newChats[i].unReadMsgCount = 0;
                                setChats(newChats);
                              }}
                              key={chat.id}
                              style={{ cursor: "pointer" }}
                            >
                              <ChatListItem
                                data={chat}
                                currentUser={user.id}
                                online={checkOnlineStatus(chat)}
                                active={active}
                                receivedMsg={receivedMsg}
                              />
                            </div>
                          ))}
                      </ul>
                    </div>
                  </div>
                  <ChatBox
                    currentUser={user.id}
                    chat={currentChat}
                    receivedMsg={receivedMsg}
                    online={checkOnlineStatus(currentChat)}
                    getUserChats={getUserChats}
                    chats={chats}
                    setChats={setChats}
                  />
                </div>
              )}
            </div>
          </div>
          <OnlineFriends />
          {showList === true && (
            <TeacherListModal getUserChats={getUserChats} />
          )}
        </div>
      </div>
    </Container>
  );
}
