import React, { useEffect, useState, useRef, useContext } from "react";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import { useParams, useNavigate } from "react-router-dom";
import ToastMessage from "../../Utils/ToastMessage";
import TopHeader from "../../Components/TopHeader";
import { StickyChatContext } from "../../Components/StickyChatWrapper";
import "./SelectProifile.css";
import { Helmet } from "react-helmet";
import DetachModal from "./DetachModal";
import { truncateString } from "../../Utils/helpers";

const MenuDropDown = ({ child, getUserChilds }) => {
  const dropdownRef = useRef(null);
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const toggleDropdown = () => {
    if (open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleDetach = async () => {
    const resp = await CommonAPi(
      `user/detach/child`,
      { id: child?.id },
      false,
      false,
      "PUT"
    );
    if (resp) {
      const user = JSON.parse(localStorage.getItem("user"));
      localStorage.setItem("userId", user?.id);
      console.log("#@@#", localStorage.getItem("userId"));
      await getUserChilds(id);
      ToastMessage.Success(resp?.message);
    }
    setShow(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div
      ref={dropdownRef}
      className=""
      style={{ position: "absolute", right: 10, top: 8 }}
    >
      <a
        onClick={() => {
          toggleDropdown();
        }}
        className="d-flex align-items-center justify-content-end rounded-circle bg-eee p-1"
      >
        <svg
          viewBox="0 0 24 24"
          width="18"
          height="18"
          stroke="#616161"
          strokeWidth="2"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="css-i6dzq1"
        >
          <circle cx="12" cy="12" r="1"></circle>
          <circle cx="19" cy="12" r="1"></circle>
          <circle cx="5" cy="12" r="1"></circle>
        </svg>
      </a>
      {open === true && (
        <ul className="dropdown-menu rounded-1 show inner-dropdownmenu ">
          <li className="py-1">
            <div
              onClick={() => setShow(true)}
              className="d-flex align-items-center dropdown-item gap-2 cursor-pointer"
              href="#"
            >
              <svg
                viewBox="0 0 24 24"
                width="15"
                height="15"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="css-i6dzq1"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              Detach
            </div>
          </li>
        </ul>
      )}
      {show && (
        <DetachModal
          show={show}
          onHide={() => setShow(false)}
          onClick={() => handleDetach(child)}
        />
      )}
    </div>
  );
};

export default function SelectProfile() {
  const { id } = useParams();
  const navigate = useNavigate();
  const closeModal = useRef(null);
  const imageRef = useRef();
  const formRef = useRef();
  const { setChats } = useContext(StickyChatContext);

  const [isSeleccted, setIsSelected] = useState(localStorage.getItem("userId"));
  const [allChilds, setAllChilds] = useState([]);
  const [parent] = useState(JSON.parse(localStorage.getItem("parentData")));
  const [userProfile] = useState(localStorage.getItem("userProfile"));
  const ProfileCompletion = localStorage.getItem("ProfileCompletion");
  const userId = localStorage.getItem("userId");

  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [childImg, setChildImg] = useState("");
  const [childData, setChildData] = useState("");

  const [fullnameError, setFullnameError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [greenBorder, setGreenBorder] = useState({
    username: false,
    email: false,
  });

  const selectProfile = (id) => {
    setIsSelected(id);
    const [child] = allChilds.filter((child, idx) => child.id === id);
    setChildData(child);
    localStorage.setItem("userId", child.id);
    localStorage.setItem("userProfileCoverImage", child?.coverImg);
    localStorage.setItem("userRoleType", "child");
    localStorage.setItem("userProfile", child?.profileImg);
    localStorage.setItem("ProfileCompletion", child?.profileCompleteness);
    localStorage.setItem("user", JSON.stringify(child));
    localStorage.setItem("instructorStatus", child?.instructorStatus);
    localStorage.setItem("userName", child?.username);
  };
  const handleParent = (id) => {
    setIsSelected(id);
    localStorage.setItem("userId", parent.id);
    localStorage.setItem("userRoleType", "parent");
    localStorage.setItem(
      "userProfileCoverImage",
      parent?.user_profile?.coverImg
    );
    localStorage.setItem("userProfile", parent?.user_profile?.profileImg);
    localStorage.setItem("ProfileCompletion", parent?.profileCompleteness);
    localStorage.setItem("user", JSON.stringify(parent));
    localStorage.setItem("instructorStatus", parent?.instructorStatus);
    localStorage.setItem("userName", parent?.username);
  };

  const getUserChilds = async (id) => {
    let options = {
      profile: id,
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    try {
      let resp = await CommonAPi(Endpoints.GetUserChilds, null, options);
      if (resp) {
        window.localStorage.setItem("childCount", resp?.data?.length);
        setAllChilds(resp?.data);
      }
    } catch (e) {
      console.log(e.response?.data);
    }
  };

  const registerChild = async (e) => {
    e.preventDefault();
    if (!fullname) {
      setFullnameError("Please enter kid name ");
    } else if (fullname) {
      setFullnameError("");
    }
    if (!username) {
      setUsernameError("Please enter username ");
    } else if (username) {
      setUsernameError("");
    }
    if (!email) {
      setEmailError("Please enter email ");
    } else if (email) {
      setEmailError("");
    }

    if (email && username && fullname) {
      try {
        let data = {
          full_name: fullname,
          user_name: username,
          email: email,
        };
        let resp = await CommonAPi(Endpoints.registerChild, data);
        if (resp && resp.success === true) {
          formRef.current.reset();
          setFullname("");
          setUsername("");
          setEmail("");
          setMobile("");
          setChildImg("");
          ToastMessage.Success(resp.message);
          getUserChilds(id);
          closeModal.current.click();
        }
        imageRef.current = null;
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.message) {
          if (e.response.status === 401) {
            ToastMessage.Error("Session Expired! Please login again.");
            localStorage.clear();
            navigate("/signin");
          } else {
            ToastMessage.Error(e.response.data.message);
          }
        }
      }
    }
  };

  const handleRedirect = () => {
    setChats([]);
    if (childData?.hasInterest === false) {
      navigate("/select-interests" + "/" + userId);
    } else if (Math.round(childData?.profileCompleteness) < 50) {
      navigate("/complete-profile-step1" + "/" + userId);
    } else if (Math.round(childData?.profileCompleteness) < 100) {
      navigate("/complete-profile-step2" + "/" + userId);
    } else {
      window.parent.location.href = "/mainfeed";
      // navigate('/mainfeed')
    }
  };

  useEffect(() => {
    if (!id) navigate("/");
    getUserChilds(id);
  }, [id, navigate]);

  const handleValidation = (e, value, field) => {
    e.preventDefault();
    if (!value && field === "name") {
      setFullnameError("Please enter kid name ");
    } else if (value && field === "name") {
      setFullnameError("");
    }
    if (!value && field === "username") {
      setUsernameError("Please enter username ");
    } else if (value && field === "username") {
      checkUserName();
      setUsernameError("");
    }
    if (!value && field === "email") {
      setEmailError("Please enter email ");
    } else if (value && field === "email") {
      checkEmail();
      setEmailError("");
    }
  };

  const checkEmail = async () => {
    let obj = {
      email: email,
    };
    try {
      let resp = await CommonAPi(Endpoints.checkUser, obj);
      console.log(resp);
      if (resp && resp.success === false) {
        setEmailError(resp.message);
      }

      if (resp.success === true) {
        setEmailError("");
        setGreenBorder({ ...greenBorder, email: true });
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setEmailError(e.response.data.message);
      }
    }
  };

  const checkUserName = async () => {
    let obj = {
      username: username,
    };
    try {
      let resp = await CommonAPi(Endpoints.checkUser, obj);
      if (resp && resp.success === false) {
        setUsernameError(resp.message);
      }
      if (resp.success === true) {
        setUsernameError("");
        setGreenBorder({ ...greenBorder, username: true });
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        setUsernameError(e.response.data.message);
      }
    }
  };
  return (
    <div className="bg-f5f5f5">
      <Helmet>
        <title>Select Profile</title>
      </Helmet>
      <div className="wrapper ">
        <TopHeader />

        <div className="container py-md-5 py-4">
          <div className="d-md-flex align-items-center justify-content-between pb-lg-4 pb-3">
            <div>
              <h3 className="medium pb-1">Select Your Profile</h3>
              <p>
                Select the profile you want to use, you can always switch
                profiles later
              </p>
            </div>

            <div>
              <a
                href="#"
                className="btn btn-br-blue fs-14 bg-transparent"
                data-bs-toggle="modal"
                data-bs-target="#kidmodal"
              >
                Add Kid Profile <img src="assets/img/icon/profile.svg" alt="" />
              </a>
            </div>

            <div
              className="modal fade"
              id="kidmodal"
              tabIndex="-1"
              aria-labelledby="kidmodal"
              aria-hidden="true"
            >
              <div className="modal-dialog rounded-10 box-shadow p-lg-4 p-md-3 p-2 bg-white">
                <div className="modal-content border-0">
                  <div className="d-flex align-items-center justify-content-between pb-3 border-bottom mb-3 w-100">
                    <h3 className="medium text-blue">Kid's Profile</h3>
                    <button
                      type="button"
                      className="btn-close kids-close-icon cursor-pointer"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      ref={closeModal}
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="#949494"
                        width="22"
                        height="22"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="modal-body">
                    <h4 className="medium text-black pb-3">
                      Fill the form below to create profile
                    </h4>

                    <form
                      action=""
                      ref={formRef}
                      className="kids-form generic-form"
                      onSubmit={registerChild}
                      encType="multipart/form-data"
                    >
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label>Kid Name</label>
                          <input
                            type="text"
                            placeholder="Kid Name"
                            className={
                              fullnameError
                                ? "is-danger  text-gray px-3 py-3 border"
                                : " text-gray px-3 py-3 border"
                            }
                            onBlur={(e) => {
                              handleValidation(e, e.target.value, "name");
                            }}
                            onChange={(e) => {
                              setFullname(e.target.value);
                            }}
                            value={fullname}
                          />
                          {fullnameError ? (
                            <div
                              className="form-error"
                              style={{ color: "white", margintop: "-10px" }}
                            >
                              {fullnameError}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-12 mb-3">
                          <label>Username</label>
                          <input
                            type="text"
                            placeholder="Username"
                            // className="rounded-pill text-gray px-3 py-3 border"
                            className={
                              usernameError
                                ? "is-danger  text-gray px-3 py-3 border"
                                : ` text-gray px-3 py-3 border ${
                                    greenBorder.username ? "greenBorder" : ""
                                  }`
                            }
                            onBlur={(e) => {
                              handleValidation(e, e.target.value, "username");
                            }}
                            onChange={(e) => {
                              setUsername(e.target.value);
                            }}
                            value={username}
                          />
                          {usernameError ? (
                            <div
                              className="form-error"
                              style={{ color: "white", margintop: "-10px" }}
                            >
                              {usernameError}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-12 mb-3">
                          <label>Email</label>
                          <input
                            type="email"
                            placeholder="Email"
                            // className=" text-gray px-3 py-3 border"
                            className={
                              emailError
                                ? "is-danger text-gray px-3 py-3 border"
                                : `text-gray px-3 py-3 border
                                                        ${
                                                          greenBorder.email
                                                            ? "greenBorder"
                                                            : ""
                                                        }`
                            }
                            onBlur={(e) => {
                              handleValidation(e, e.target.value, "email");
                            }}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            value={email}
                          />
                          {emailError ? (
                            <div
                              className="form-error"
                              style={{ color: "white", margintop: "-10px" }}
                            >
                              {emailError}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-12 text-end">
                          {email && username && fullname ? (
                            <button
                              type="submit"
                              className="btn blue-btn kids-form-button-blue"
                            >
                              Create Profile{" "}
                              <img
                                src="../../assets/img/icon/icon-right-arrow.svg"
                                alt=""
                              />
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn kids-form-button-grey fs-14"
                              disabled
                            >
                              Create Profile{" "}
                              <img
                                src="../../assets/img/icon/icon-right-arrow.svg"
                                alt=""
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-6 mb-4">
              <div
                className={`border bg-white rounded-10 py-4 px-3 profile-select-box text-center  ${
                  Math.round(isSeleccted) == Math.round(parent.id) &&
                  "selected-profile"
                }`}
                onClick={() => {
                  handleParent(parent.id);
                }}
              >
                {parent?.user_profile?.profileImg === null ||
                parent?.user_profile === null ? (
                  <img
                    src="../../assets/img/photo.svg"
                    alt=""
                    width="128"
                    height="128"
                    className="rounded-circle"
                  />
                ) : (
                  <img
                    src={Endpoints.baseUrl + parent?.user_profile.profileImg}
                    alt=""
                    width="128"
                    height="128"
                    className="rounded-circle"
                  />
                )}
                <h3 className="medium fs-22 pt-3 pb-2 ">{parent.fullName}</h3>
                {allChilds.length > 0 ? (
                  <>
                    {parent.user_profile?.gender === "Male" ? (
                      <h6 className="medium fs-16">Father</h6>
                    ) : (
                      ""
                    )}

                    {parent.user_profile?.gender === "Female" ? (
                      <h6 className="medium fs-16">Mother</h6>
                    ) : (
                      ""
                    )}

                    {parent.user_profile?.gender === "Other" ? (
                      <h6 className="medium fs-16">Parent</h6>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            {allChilds &&
              allChilds.map((child, index) => (
                <div
                  className={`col-md-3 col-6 mb-4 `}
                  key={index}
                  onClick={() => {
                    selectProfile(child.id);
                  }}
                >
                  <div
                    className={`border bg-white rounded-10 py-4 px-3 profile-select-box text-center ${
                      Math.round(isSeleccted) === Math.round(child.id) &&
                      "selected-profile"
                    } position-relative`}
                  >
                    <MenuDropDown child={child} getUserChilds={getUserChilds} />
                    {child?.profileImg === null ? (
                      <img
                        src="../../assets/img/photo.svg"
                        alt=""
                        width="128"
                        height="128"
                        className="rounded-circle"
                      />
                    ) : (
                      <img
                        src={Endpoints.baseUrl + child?.profileImg}
                        alt=""
                        width="128"
                        height="128"
                        className="rounded-circle"
                      />
                    )}

                    <h3 className="medium fs-22 pt-3 pb-2 ">
                      {truncateString(child.fullName, 18)}
                    </h3>

                    {child.gender === "Male" ? (
                      <h6 className="medium fs-16">Son</h6>
                    ) : child.gender === "Female" ? (
                      <h6 className="medium fs-16">Daughter</h6>
                    ) : (
                      <h6 className="medium fs-16">Kid</h6>
                    )}
                  </div>
                </div>
              ))}
          </div>

          <div className="text-end">
            <a
              href="#"
              className="btn primary-btn fs-14"
              onClick={handleRedirect}
            >
              Continue{" "}
              <img src="../../assets/img/icon/icon-right-arrow.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
