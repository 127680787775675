import React from 'react';
import { Link } from 'react-router-dom';


const CartEmpty = () => {
return (

        <div className="row">
        <div className="col-md-6 offset-md-3">
            <div className="bg-white rounded-10 box-shadow py-4 px-5 text-center">

                <img src="assets/img/icon/cart.svg" alt="" className="pb-4 px-4"/>

            
                <h1 className="medium pb-3">Cart is empty!</h1>

                <p className="fs-14 text-gray ">Add a course to your cart.</p>

                <Link to="/courses" className="btn py-2 px-5 mb-3">Go To Courses 
                <img src="assets/img/icon/course-start.svg" alt="" className="ps-1"/></Link>
                    
            </div>
        </div>
    </div>
)
}
export default CartEmpty