import React, { useCallback, useContext, useEffect, useState } from "react";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { FetchApi } from "../../API/FetchApi";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { StickyChatContext } from "../../Components/StickyChatWrapper";

export default function Birthday() {
  const { handleAddChat, chats } = useContext(StickyChatContext);

  const userId = localStorage.getItem("userId");
  const [friendData, setFriendData] = useState([]);
  const [todayBirthday, setTodayBirthday] = useState("");
  const Navigate = useNavigate();
  useEffect(() => {
    getFriend();
  }, []);
  const getFriend = async () => {
    try {
      let resp = await FetchApi(
        Endpoints.GetAllFriend + "?size=" + 4 + "&page=1" + "&birthday=true" + "&id="+userId
      );
      if (resp && resp.success === true) {
        if(resp?.data?.friends.length === 1){
            if(resp?.data?.friends[0]?.id === resp?.data?.todayBirthday?.id){
              setFriendData([]);
            }else{
              setFriendData(resp?.data?.friends);
            }
      }else{
        const newData =  resp?.data?.friends.map((item)=>{
          if(item.id === resp?.data?.todayBirthday?.id ) return null;
          return (
            item
          )
        });
        setFriendData(newData);
      }
     
        setTodayBirthday(resp?.data?.todayBirthday);
       

      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          Navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  const handleBirthdayCardClick = useCallback(
    (chatRoomId) => {
      const chatToAdd = chats.find((item) => item?.id === chatRoomId);
      if (chatToAdd) handleAddChat(chatToAdd);
    },
    [chats, handleAddChat]
  );

  return (
    <>
      {todayBirthday ? 
        moment().format("DD-MM") ===
        moment(todayBirthday.user_profile?.birthday).format("DD-MM") ? 
          <div
            className="birthday-card bg-white mb-4"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleBirthdayCardClick(todayBirthday.chatRoomId);
            }}
          >
            <div className="birth-card-top">
              <div className="d-flex ">
                <span className="date pe-2 me-2">
                  {moment(todayBirthday.user_profile?.birthday).format("DD")}
                </span>
                <span className="day-date fs-12">
                  {moment(todayBirthday.user_profile?.birthday).format("MMM")}
                  <br />
                  {moment(todayBirthday.user_profile?.birthday).format("dddd")}
                </span>
              </div>
              <img src="assets/img/icon/Birthday cake.svg" />
            </div>

            <div className="birth-card-bottom text-center">
              <span className="birth-friend-pic mb-2 d-block mx-auto">
                <img
                  src={
                    Endpoints.baseUrl + todayBirthday.user_profile?.profileImg
                  }
                  alt=""
                  width="64"
                  height="64"
                  className="rounded-circle"
                />
              </span>

              <h6 className="text-blue regular fs-12">Today is</h6>

              <h4 className="bold text-blue py-1">{todayBirthday.fullName}</h4>
              <h6 className="text-blue medium fs-14 pb-2">Birthday!</h6>

              <h6 className="text-blue fs-12 regular pb-2">
                Leave {todayBirthday.user_profile?.gender === "Male" ? "him" : "her" } a message with your best wishes on {todayBirthday.user_profile?.gender === "Male" ? "his" : "her" } profile.
              </h6>
            </div>
            </div>
            : "" 

            : ""}
           
     
      {friendData.length > 0 ? (
        <div className="sidebar-widget mb-4">
          <h4 className="medium">Upcoming Birthdays</h4>
          <div className="pt-3 border-top mt-3">
            <ul>
              {friendData.length > 0 ? (
                friendData.map((item, index) => {
                  // if (
                  //   index === 0 &&
                  //   moment().format("DD-MM") ===
                  //     moment(item?.user_profile?.birthday).format("DD-MM")
                  // )
                  //   return null;
                  return (
                    <li className="pb-3 d-flex align-items-start " key={index}>
                        <Link to={"/timeline/" + item?.username}>
                      {item?.user_profile?.profileImg === null ? (
                        <img
                          src="../assets/img/picture.png"
                          alt=""
                          width="48"
                          height="48"
                          class="rounded-circle"
                        />
                      ) : (
                        <img
                          src={
                            Endpoints.baseUrl + item?.user_profile?.profileImg
                          }
                          alt=""
                          width="58"
                          height="48"
                          class="rounded-circle"
                        />
                      )}
                      </Link>
                      <div className="ps-2">
                        <Link to={"/timeline/" + item?.username}>
                          <h6 className="medium text-black">
                            {item?.fullName}
                          </h6>
                        </Link>
                        <h6 className="fs-10 regular text-darkgray py-1">
                          {item?.user_profile?.profileTagline}
                        </h6>

                        <div className="request-btn  pt-1">
                          <button
                            className="btn light-brown-btn medium"
                            onClick={() => {
                              handleBirthdayCardClick(item.chatRoomId);
                            }}
                          >
                            Wish Birthday
                          </button>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <li className="pb-3 d-flex align-items-start ">
                  No Any Upcoming Birthdays
                </li>
              )}
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
