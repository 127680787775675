import React, { useEffect, useState } from 'react';
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import { Endpoints } from "../../API/Endpoints";
import ToastMessage from "../../Utils/ToastMessage";
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function RightSidebar() {
    const [SimilarPage, setSimilarPage] = useState([]);
    const [followedPage, setFollowedPage] = useState([]);
    const navigate = useNavigate();
    const param = useParams();

    useEffect(() => {
        getPages();
    }, []);
    const getPages = async () => {

        try {
            let resp = await FetchApi(Endpoints.SimilarPage + "?slug=" + param?.slug);
            if (resp && resp.success === true) {
                setFollowedPage(resp?.data?.alsofollowedPage);
                setSimilarPage(resp?.data?.similarPages);

            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }

    };
    const handleFollow = async (Id) => {
        let data = {
            page_id: Id
        }
        try {
            let resp = await CommonAPi(Endpoints.PageFollow, data);
            if (resp && resp.message) {
                getPages();
                ToastMessage.Success(resp.message)
            }
        }
        catch (e) {
            if (e && e.response && e.response.data && e.response.data.message) {
                if (e.response.status === 401) {
                    ToastMessage.Error('Session Expired! Please login again.');
                    localStorage.clear();
                    navigate('/signin');
                } else {
                    ToastMessage.Error(e.response.data.message);
                }

            }
        }

    };

    return (
        <div className="col-md-3 comany-dp-right">

            {SimilarPage.length > 0 && (
                <div className="sidebar-widget box-shadow">
                    <h4 className="medium border-bottom pb-2 mb-3">Similar Pages</h4>

                    <div>
                        {SimilarPage.map((item, index) => {
                            return (
                                <div className="d-flex align-items-start gap-2 pb-3" key={index}>
                                    {item?.pageImage === null ?
                                        <img src="../assets/img/company1.png" width="48" height="48" className="rounded-circle" alt="" />
                                        :
                                        <img src={Endpoints.baseUrl + item?.pageImage} width="48" height="48" className="rounded-circle" alt="" />
                                    }
                                    <div>
                                        <h6 className="medium fs-14">
                                            <Link to={`/page-about/${item?.slug}`}>
                                                {item?.title}
                                            </Link>
                                        </h6>
                                        <h6 className="text-darkgray fs-10 py-1">{item?.industry}</h6>
                                        <h6 className="text-darkgray fs-10 pb-1">{item?.totalFollowers} Followers</h6>
                                        <Link onClick={() => {
                                            handleFollow(item?.id)
                                        }} className="btn btn-light-blue px-3 medium fs-10 py-1">Follow
                                            <img src="../assets/img/icon/follow2.svg" alt="" width="12" />
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}



                    </div>
                </div>
            )}


            {followedPage.length > 0 && (
                <div className="sidebar-widget box-shadow mt-3">
                    <h4 className="medium border-bottom pb-2 mb-3">People also follow</h4>

                    <div>
                        {followedPage.map((item, index) => {
                            return (
                                <div className="d-flex align-items-start gap-2 pb-3">
                                    {item.pageImage === null ?
                                        <img src="../assets/img/company4.svg" width="48" height="48" className="rounded-circle" alt="" />
                                        :
                                        <img src={Endpoints.baseUrl + item?.pageImage} width="48" height="48" className="rounded-circle" alt="" />
                                    }
                                    <div>
                                        <h6 className="medium fs-14">
                                            <Link to={`/page-about/${item?.slug}`}>
                                                {item?.title}
                                            </Link>
                                        </h6>
                                        <h6 className="text-darkgray fs-10 py-1">{item.industry}</h6>
                                        <h6 className="text-darkgray fs-10 pb-1">{item.totalFollowers} Followers</h6>
                                        <Link onClick={() => {
                                            handleFollow(item?.id)
                                        }} className="btn btn-light-blue px-3 medium fs-10 py-1">Follow
                                            <img src="../assets/img/icon/follow2.svg" alt="" width="12" />
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}


                    </div>
                </div>
            )}

        </div>
    )
}
