import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
const $ = window.jQuery;

function LeftNavigation() {
  const history = useNavigate();
  const location = useLocation();
  const Token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const username = localStorage.getItem("userName");
  const menuRef = useRef();
  // console.log(Token)
  useEffect(() => {
    if (Token == null) {
      localStorage.clear();
      history("/");
    }
  }, []);

  const handleSidebar = () => {
    /***** LEFT MENU TOGGLE js  *****/
    $(".side-menu").toggleClass("active-sidemenu");
    $(".head-menu").slideToggle();
  };

  // useEffect(() => {
  //     document.addEventListener('mousedown', handleClickOutside);

  //     // Cleanup the event listener when the component unmounts
  //     return () => {
  //       document.removeEventListener('mousedown', handleClickOutside);
  //     };
  //   }, []);

  // Event listener to handle clicks outside the menu
  // const handleClickOutside = (event) => {
  //     if (menuRef.current && !menuRef.current.contains(event.target)) {
  //          $('.side-menu').removeClass('active-sidemenu');
  //     }
  // };

  //   const toggleMenu = () => {
  //     $('.side-menu').toggleClass('active-sidemenu');
  //     $('.head-menu').slideToggle();

  // };

  return (
    <div className="left-navigation p-1">
      <ul className="navigation-menu side-menu">
        <span
          className="toggle-menu  text-end pb-3 d-xl-none d-block px-3"
          ref={menuRef}
        >
          <svg
            viewBox="0 0 24 24"
            width="24"
            height="24"
            stroke="#0c64c5"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="css-i6dzq1"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="15" y1="9" x2="9" y2="15"></line>
            <line x1="9" y1="9" x2="15" y2="15"></line>
          </svg>
        </span>
        {/* <ul className="navigation-menu side-menu active-sidemenu"> */}
        <li className={location.pathname === "/mainfeed" ? "active" : ""}>
          <Link to={"/mainfeed"} target={"_blank"}>
            <img src="/assets/img/icon/icon-Newsfeed.svg" alt="" />
            <span>Newsfeed</span>
          </Link>
        </li>

        <li
          className={
            location.pathname.split("/")[1] == "friend-profile" ? "active" : ""
          }
        >
          <Link to={"/friend-profile/" + username}>
            <img src="/assets/img/icon/networks.svg" alt="" />
            <span>My Networks </span>
          </Link>
        </li>

        {/* <li>
                    <a href="/">
                        <img src="/assets/img/icon/classmates.svg" alt=""/>
                        <span>Classmates</span>
                    </a>
                </li> */}

        <li className={location.pathname === "/teachers" ? "active" : ""}>
          <Link to={"/teachers"}>
            <img src="/assets/img/icon/teachers.svg" alt="" />
            <span>Teachers</span>
          </Link>
        </li>

        <li className={location.pathname === "/university" ? "active" : ""}>
          <Link to={"/university"}>
            <img src="/assets/img/icon/university.svg" alt="" />
            <span>Universities</span>
          </Link>
        </li>

        <li className={location.pathname === "/schools" ? "active" : ""}>
          <Link to={"/schools"}>
            <img src="/assets/img/icon/Schools.svg" alt="" />
            <span>Schools</span>
          </Link>
        </li>

        <li className={location.pathname === "/company" ? "active" : ""}>
          <Link to={"/company"}>
            <img src="/assets/img/icon/company.svg" alt="" />
            <span>Company</span>
          </Link>
        </li>

        <li className={location.pathname === "/courses" ? "active" : ""}>
          <Link to={"/courses"}>
            <img src="/assets/img/icon/Course.svg" alt="" />
            <span>Courses</span>
          </Link>
        </li>

        <li className={location.pathname === "/honors&awards" ? "active" : ""}>
          <Link to={"/honors&awards"}>
            <img src="/assets/img/icon/awards.svg" alt="" />
            <span>Honors & Awards</span>
          </Link>
        </li>

        <li className={location.pathname === "/testscores" ? "active" : ""}>
          <Link to={"/testscores"}>
            <img src="/assets/img/icon/Test scores.svg" alt="" />
            <span>Test Scores</span>
          </Link>
        </li>

        <li className={location.pathname === "/gratitudewall" ? "active" : ""}>
          <Link to={"/gratitudewall"}>
            <img src="/assets/img/icon/Gratitude.svg" alt="" />
            <span>Gratitude Wall</span>
          </Link>
        </li>

        <li className={location.pathname === "/internship" ? "active" : ""}>
          <Link to={"/internship"}>
            <img src="/assets/img/icon/Internship.svg" alt="" />
            <span>Internship</span>
          </Link>
        </li>

        <li className={location.pathname === "/jobs" ? "active" : ""}>
          <Link to={"/jobs"}>
            <img src="/assets/img/icon/Jobs.svg" alt="" />
            <span>Jobs</span>
          </Link>
        </li>

        <li className={location.pathname === "/groups" ? "active" : ""}>
          <Link to={"/groups"}>
            <img src="/assets/img/icon/Groups.svg" alt="" />
            <span>Groups</span>
          </Link>
        </li>

        <li className={location.pathname === "/pages" ? "active" : ""}>
          <Link to={"/pages"}>
            <img src="/assets/img/icon/Pages.svg" alt="" />
            <span>Pages</span>
          </Link>
        </li>

        <li className={location.pathname === "/hashtags" ? "active" : ""}>
          <Link to={"/hashtags"}>
            <img src="/assets/img/icon/hashtag.svg" alt="" />
            <span>Hashtag</span>
          </Link>
        </li>

        <li className={location.pathname === "/my-courses" ? "active" : ""}>
          <Link to={"/my-courses"}>
            <img src="/assets/img/icon/icon-teacher.svg" alt="" />
            <span>My Courses</span>
          </Link>
        </li>
        <li
          className={
            location.pathname.split("/")[1] === "save-post" ? "active" : ""
          }
        >
          <Link to={"/save-post/" + userId}>
            <img src="/assets/img/icon/gray-save-icon.svg" alt="" />
            <span>Saved Post</span>
          </Link>
        </li>
      </ul>

      <ul className="navigation-menu p-2">
        <li className={location.pathname === "/mainfeed" ? "active" : ""}>
          <Link to={"/mainfeed"}>
            <ReactTooltip
              anchorId="app-newsfeed"
              place="right"
              content="Newsfeed"
            />
            <img
              src="/assets/img/icon/icon-Newsfeed.svg"
              alt=""
              id="app-newsfeed"
            />
          </Link>
        </li>

        <li
          className={
            location.pathname.split("/")[1] == "friend-profile" ? "active" : ""
          }
        >
          <Link to={"/friend-profile/" + username}>
            <ReactTooltip
              anchorId="app-networks"
              place="right"
              content="My Networks"
            />
            <img src="/assets/img/icon/networks.svg" alt="" id="app-networks" />
          </Link>
        </li>

        {/* <li>
                    <a href="/">
                    <ReactTooltip
                        anchorId="app-classmates"
                        place="right"
                        content="Classmates"
                    />
                        <img src="/assets/img/icon/classmates.svg" alt="" id="app-classmates"/>
                    </a>
                </li> */}

        <li className={location.pathname === "/teachers" ? "active" : ""}>
          <Link to={"/teachers"}>
            <ReactTooltip
              anchorId="app-teachers"
              place="right"
              content="Teachers"
            />
            <img src="/assets/img/icon/teachers.svg" alt="" id="app-teachers" />
          </Link>
        </li>

        <li className={location.pathname === "/university" ? "active" : ""}>
          <Link to={"/university"}>
            <ReactTooltip
              anchorId="app-University"
              place="right"
              content="University"
            />
            <img
              src="/assets/img/icon/university.svg"
              alt=""
              id="app-University"
            />
          </Link>
        </li>

        <li className={location.pathname === "/schools" ? "active" : ""}>
          <Link to={"/schools"}>
            <ReactTooltip
              anchorId="app-Schools"
              place="right"
              content="Schools"
            />
            <img src="/assets/img/icon/Schools.svg" alt="" id="app-Schools" />
          </Link>
        </li>
        <li className={location.pathname === "/company" ? "active" : ""}>
          <Link to={"/company"}>
            <ReactTooltip
              anchorId="app-company"
              place="right"
              content="Company"
            />
            <img src="/assets/img/icon/company.svg" alt="" id="app-company" />
          </Link>
        </li>

        <li className={location.pathname === "/courses" ? "active" : ""}>
          <Link to={"/courses"}>
            <ReactTooltip
              anchorId="app-Course"
              place="right"
              content="Course"
            />
            <img src="/assets/img/icon/Course.svg" alt="" id="app-Course" />
          </Link>
        </li>

        <li className={location.pathname === "/honors&awards" ? "active" : ""}>
          <Link to={"/honors&awards"}>
            <ReactTooltip
              anchorId="app-Awards"
              place="right"
              content="Honors & Awards"
            />
            <img src="/assets/img/icon/awards.svg" alt="" id="app-Awards" />
          </Link>
        </li>

        <li className={location.pathname === "/testscores" ? "active" : ""}>
          <Link to={"/testscores"}>
            <ReactTooltip
              anchorId="app-scores"
              place="right"
              content="Test scores"
            />
            <img
              src="/assets/img/icon/Test scores.svg"
              alt=""
              id="app-scores"
            />
          </Link>
        </li>

        <li className={location.pathname === "/gratitudewall" ? "active" : ""}>
          <Link to={"/gratitudewall"}>
            <ReactTooltip
              anchorId="app-Gratitude"
              place="right"
              content="Gratitude"
            />
            <img
              src="/assets/img/icon/Gratitude.svg"
              alt=""
              id="app-Gratitude"
            />
          </Link>
        </li>

        <li className={location.pathname === "/internship" ? "active" : ""}>
          <Link to={"/internship"}>
            <ReactTooltip
              anchorId="app-Internship"
              place="right"
              content="Internship"
            />
            <img
              src="/assets/img/icon/Internship.svg"
              alt=""
              id="app-Internship"
            />
          </Link>
        </li>

        <li className={location.pathname === "/jobs" ? "active" : ""}>
          <Link to={"/jobs"}>
            <ReactTooltip anchorId="app-Jobs" place="right" content="Jobs" />
            <img src="/assets/img/icon/Jobs.svg" alt="" id="app-Jobs" />
          </Link>
        </li>

        <li className={location.pathname === "/groups" ? "active" : ""}>
          <Link to={"/groups"}>
            <ReactTooltip
              anchorId="app-Groups"
              place="right"
              content="Groups"
            />
            <img src="/assets/img/icon/Groups.svg" alt="" id="app-Groups" />
          </Link>
        </li>

        <li className={location.pathname === "/pages" ? "active" : ""}>
          <Link to={"/pages"}>
            <ReactTooltip anchorId="app-Pages" place="right" content="Pages" />
            <img src="/assets/img/icon/Pages.svg" alt="" id="app-Pages" />
          </Link>
        </li>

        <li className={location.pathname === "/hashtags" ? "active" : ""}>
          <Link to={"/hashtags"}>
            <ReactTooltip
              anchorId="app-hashtag"
              place="right"
              content="hashtag"
            />
            <img src="/assets/img/icon/hashtag.svg" alt="" id="app-hashtag" />
          </Link>
        </li>

        <li className={location.pathname === "/my-courses" ? "active" : ""}>
          <Link to="/my-courses">
            <ReactTooltip
              anchorId="app-mycourses"
              place="right"
              content="my courses"
            />
            <img
              src="/assets/img/icon/icon-teacher.svg"
              alt=""
              id="app-mycourses"
            />
          </Link>
        </li>
        <li
          className={
            location.pathname.split("/")[1] === "save-post" ? "active" : ""
          }
        >
          <Link to={"/save-post/" + userId}>
            <ReactTooltip
              anchorId="app-save-post"
              place="right"
              content="Saved Post"
            />
            <img
              src="/assets/img/icon/gray-save-icon.svg"
              alt=""
              id="app-save-post"
            />
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default LeftNavigation;
