import React from "react";
import ToastMessage from "../../Utils/ToastMessage";
import { Endpoints } from "../../API/Endpoints";
import { CommonAPi } from "../../API/CommonApi";
import { Link, useNavigate } from "react-router-dom";

export default function PeopleItem({ item, index, type, onSuccess }) {
  const navigate = useNavigate();
  const sendFriendRequest = async (Id) => {
    let data = {
      receiverId: Id,
    };
    try {
      let resp = await CommonAPi(Endpoints.SendFriendRequest, data);
      if (resp && resp.message) {
        onSuccess(true);
        ToastMessage.Success(resp.message);
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {
        if (e.response.status === 401) {
          ToastMessage.Error("Session Expired! Please login again.");
          localStorage.clear();
          navigate("/signin");
        } else {
          ToastMessage.Error(e.response.data.message);
        }
      }
    }
  };

  return (
    <div className="pb-3 d-flex align-items-center" key={index}>
      {item?.user_profile?.profileImg === "user/profile-picture/null" ? (
        <Link to={"/timeline/" + item?.username}>
          <img
            src="assets/img/picture.png"
            alt=""
            className="search-circle-image"
          />
        </Link>
      ) : (
        <Link to={"/timeline/" + item?.username}>
          <img
            src={Endpoints.baseUrl + item?.user_profile?.profileImg}
            alt=""
            className="search-circle-image"
          />
        </Link>
      )}
      <div className="d-flex align-items-center justify-content-between w-100 ps-2">
        <div>
          <Link to={"/timeline/" + item?.username}>
            <h6 className="medium text-black fs-14">{item?.fullName}</h6>
          </Link>
          <h6 className="fs-12 regular text-darkgray">University of Delhi</h6>
          <h6 className="fs-10 regular text-darkgray">
            {item?.mutualFriends} mutual friends
          </h6>
        </div>
        {type === "teacher" ? (
          <div className="">
            {item?.isFriend === "NA" || item?.isFriend === "false" ? (
              <a
                href="#"
                onClick={() => {
                  sendFriendRequest(item?.id);
                }}
                className="btn fs-12 py-1 px-3 w-100"
              >
                Add {type === "people" ? "Friend" : "Teacher"}
                <img
                  src="assets/img/icon/icon-add-friend.svg"
                  className="ms-1"
                  width="10"
                />
              </a>
            ) : (
              <a href="#" className="btn fs-12 py-1 px-3 w-100">
                {item?.isFriend}
              </a>
            )}
          </div>
        ) : (
          <div className="">
            {item?.status === "N/A" || item?.status === "false" ? (
              <a
                href="#"
                onClick={() => {
                  sendFriendRequest(item?.id);
                }}
                className="btn fs-12 py-1 px-3 w-100"
              >
                Add {type === "people" ? "Friend" : "Teacher"}
                <img
                  src="assets/img/icon/icon-add-friend.svg"
                  className="ms-1"
                  width="10"
                />
              </a>
            ) : (
              <a
                href={item?.status === "ACCEPTED" ? null : "#"}
                className="btn fs-12 py-1 px-3 w-100"
              >
                {item?.status}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
