import React from 'react';


export default function Interests() {
    const user = JSON.parse(localStorage.getItem("user"));
    return (

        <div>
            <h2 id="heading" className="text-center">Welcome, {user.fullName}</h2>
            <p className="text-center">Congratulations on siging up with us. Before going ahead with your profile, please fill in the following details to complete your profile.</p>
        </div>
    );
}
